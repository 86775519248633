/**
 * @author Umesh Ranthilina
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import 'react-table-hoc-fixed-columns/lib/styles.css'
import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilter from '../../components/GridFilter';
import { getItemData } from '../../../actions/Basic_data/basic_data_action';
import { getDeliveryWarehouses } from "../../../actions/Basic_data/delivery_warehouse_actions";
import { addOutletItemPrice, getOutletItemPrice, updateOutletItemPrice, deleteOutletItemPrice } from "../../../actions/POS/outlet_item_price_action";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

export const ItemOutletPrice = props => {

    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [outletData, setOutletData] = useState([]);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        props.getItemData(props.token);
        props.getDeliveryWarehouses(props);
        props.getOutletItemPrice({ userId: props.userId }, props.token)
    }, [])

    const reload = () => {
        props.getItemData(props.token);
        props.getDeliveryWarehouses(props);
        props.getOutletItemPrice({ userId: props.userId }, props.token)
    }

    useEffect(() => {
        let filterOutlet = props.deliveryWarehouseData.filter(obj => {
            return obj.outlet === true && obj.outlet !== undefined && obj.outlet !== null && obj.outlet !== ''
        })
        setOutletData(filterOutlet)
    }, [props.deliveryWarehouseData])

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('POS', 'POS ITEM PRICE', 'UPDATE', props),
            delPerm: Permission('POS', 'POS ITEM PRICE', 'DELETE', false)
        }) ||
        {};

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined' && content !== '') {
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

        return true;
    };

    const handleSubmit = values => {
        if (deleting === null && editing !== null) {
            let outletData = {
                id: values.positemprice._id,
                outlet_price: Number(values.positemprice.outlet_price),
                outlet_discount: Number(values.positemprice.outlet_discount),
                userId: props.userId
            }
            props.updateOutletItemPrice(outletData, props.token)
        } else if (deleting !== null && editing === null) {
            var data = {
                '_id': values.positemprice._id,
                'userId': props.userId
            }
            props.deleteOutletItemPrice(data, props.token)
        }
    };

    const outletItemPricesColumns = [
        { Header: "Item", accessor: "items.name", ...GridFilter },
        { Header: "Item Code", accessor: "items.itemCode", ...GridFilter },
        { Header: "Outlet", accessor: "outlet.name", ...GridFilter },
        {
            Header: "Price", accessor: "positemprice.outlet_price", ...editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        {
            Header: "Original Price", accessor: "items.originalPrice", getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        {
            Header: "Discount", accessor: "positemprice.outlet_discount", ...editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        {
            Header: "Expire Date", accessor: "positemprice.expire_date", ...GridFilter,
            Cell: props => {
                if (props.original?.positemprice?.expire_date !== undefined) {
                    var date = props.original?.positemprice?.expire_date;
                    return <>{date}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Created By", accessor: "users.name.first", ...GridFilter,
        },
        {
            Header: "Modified By", accessor: "modifiedBy.name.first", ...GridFilter,
        },
        {
            Header: "Created At", accessor: "positemprice.createdAt", ...GridFilter,
            Cell: props => {
                return formatDate(props.original.positemprice.createdAt)
            }
        },
        {
            Header: "Updated At", accessor: "positemprice.updatedAt", ...GridFilter,
            Cell: props => {
                return formatDate(props.original.positemprice.updatedAt)
            }
        },
        {
            Header: "Actions",
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ];

    const formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        if (hours.length < 2) {
            hours = '0' + hours;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        var date = [year, month, day].join('-');
        var time = [hours, minutes, seconds].join(':')

        return [date, time].join(' ');
    }

    return (
        <div className="right_col" role="main" >
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-11">
                            <h4>POS Item Price</h4>
                        </div>
                        <div style={{ float: 'right' }} className="col-md-1">
                            <button className="btn btn-primary" onClick={() => reload()}>
                                <i className="fas fa-sync"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <ErrorBoundary>
                        <FormProvider
                            form="inline"
                            onSubmit={handleSubmit}
                            onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                            initialValues={editing === null ? deleting : editing}
                            enableReinitialize
                        >
                            {formProps => {
                                return (
                                    <form onSubmit={formProps.handleSubmit}>
                                        <Table className="-striped"
                                            columns={outletItemPricesColumns}
                                            data={props.outletItemPrices}
                                            defaultPageSize={10}
                                            defaultFilterMethod={filterCaseInsensitive}
                                            showPageJump={false}
                                        />
                                    </form>
                                );
                            }}
                        </FormProvider>
                    </ErrorBoundary>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        items: state.rBasicData.item_data,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
        userId: state.rLogin.id,
        outletItemPrices: state.rOutletItemPrices.outletItemPrices
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getItemData: (token) => { dispatch(getItemData(token)) },
        getDeliveryWarehouses: (token) => { dispatch(getDeliveryWarehouses(token)) },
        addOutletItemPrice: (data, props) => { dispatch(addOutletItemPrice(data, props)) },
        getOutletItemPrice: (data, token) => { dispatch(getOutletItemPrice(data, token)) },
        updateOutletItemPrice: (data, token) => { dispatch(updateOutletItemPrice(data, token)) },
        deleteOutletItemPrice: (data, token) => { dispatch(deleteOutletItemPrice(data, token)) },
        addOutletItemPrice: (data, props) => { dispatch(addOutletItemPrice(data, props)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemOutletPrice)
