import React, { useState, useEffect } from 'react'
import axios from 'axios';
import moment from "moment";
import { connect } from 'react-redux';
import { getRegionData } from '../../../actions/Basic_data/basic_data_action';
import SkeletonSelectPageTable from '../../components/skeleton/SkeletonSelectPageTable';
import ErrorBoundary from '../../components/ErrorBoundary'
import Table from "react-table";
import { DateNumberFormat } from '../../utils';
import { ExportData } from '../../utils';
import GridFilters from "../../components/GridFilter";

function Customers(props) {

    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [regionData, setRegionData] = useState('')
    const [customerData, setCustomerData] = useState('')
    const [status, setStatus] = useState('hide')
    const [CustomerValue, setCustomerValue] = useState('')
    const [name, setName] = useState('')
    const [month, setMonth] = useState('')

    const number = [1, 2, 3, 4]

    useEffect(() => {
        props.getRegionData(props);
    }, [])

    useEffect(() => {
        getCustomerOrderData();

    }, [selectedDate, regionData])

    const getCustomerOrderData = () => {
        axios.defaults.headers.common['authorization'] = props.token;

        const data = {
            'regionData': regionData,
            'selectedDate': selectedDate
        }

        axios.post('/dashboard/getCustomerOrder', data)
            .then(result => {
                setCustomerData(result.data);
            }).catch(err => {
                console.error(err)
            })
    }

    const formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        return [year, month, day].join('-');
    }

    const columns = [
        { Header: "Customer Name", accessor: "name", ...GridFilters, minWidth: 150 },
        { Header: "Address", accessor: "address", ...GridFilters, minWidth: 150 },
        {
            Header: "Phone", accessor: "telephone", ...GridFilters, minWidth: 150,
        },
        {
            Header: "Last Order Date", accessor: "lastDate", ...GridFilters, minWidth: 150,
            Cell: props => {
                if (props.original?.lastDate !== undefined) {
                    var today = new Date(props.original?.lastDate)
                    var dateTime = DateNumberFormat(today)
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        { Header: "Region", accessor: "region", ...GridFilters, minWidth: 150 },
    ]

    const columnLostTotal = [
        { Header: "Total", accessor: "total", ...GridFilters, minWidth: 150, getProps: () => { return { style: { 'textAlign': 'right' } } } },
    ]

    if (name === "Lost") {
        columns.push(...columnLostTotal)
    }

    const columnsBackToGusta = [
        { Header: "Customer Name", accessor: "name", ...GridFilters, minWidth: 150 },
        { Header: "Address", accessor: "address", ...GridFilters, minWidth: 150 },
        {
            Header: "Phone", accessor: "telephone", ...GridFilters, minWidth: 150,
        },
        {
            Header: "Last Date", accessor: "lastDate", ...GridFilters, minWidth: 150,
            Cell: props => {
                if (props.original?.lastDate !== undefined ) {
                    var today = new Date(props.original?.lastDate)
                    var dateTime = DateNumberFormat(today)
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Last Order Date", accessor: "last Order Date", ...GridFilters, minWidth: 150,
            Cell: props => {
                if (props.original?.lastOrderData !== undefined && props.original?.lastOrderData !== '') {
                    var today = new Date(props.original?.lastOrderData)
                    var dateTime = DateNumberFormat(today)
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        { Header: "Region", accessor: "region", ...GridFilters, minWidth: 150 },
    ]

    const exportDataToExcel = () => {
        if (CustomerValue.length > 0) {
            var excelData = CustomerValue.map(checkData => {

                return {
                    'Customer Name': checkData.name,
                    'Address': checkData.address,
                    'Phone': checkData.telephone,
                    'Last Order Date': formatDate(checkData.lastDate),
                    'Region': checkData.region,
                }
            })

            ExportData(`Customers`, 'Customers', "customers", excelData)
        }
    }

    return (
        <>
            <div className="card shadow my-4 mx-6 rounded-5" style={{ display: "flex", justifyContent: 'space-evenly' }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-3">
                            <h6 className="m-0 font-weight-bold text-primary">Customers</h6>
                        </div>
                        <div className="col-md-9"  style={{ marginTop: "-5px" }}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="form-control form-control-sm" onChange={e => setRegionData(e.target.value)} >
                                        <option value={''}>All Regions</option>
                                        {props.region.map(region => {
                                            return <option key={region._id} value={region._id}>{region.name}</option>
                                        })}
                                    </select>
                                </div>
                                <input style={{ fontSize: '14px', fontWeight: 'bold' }} className="form-control form-control-sm mr-sm-2" type="date" value={selectedDate}
                                    onChange={(e) => {
                                        setSelectedDate(e.target.value);
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorBoundary>
                    <div className="card-body" style={{ marginTop: "-15px" }}>
                        <div className="table-responsive">
                            <table className="table table-hover ">
                                {customerData.length > 0 ? customerData.map((val, key) => {
                                    return (<>
                                        <thead className="thead-dark">
                                            <tr key={key} className="clickable" data-toggle="collapse" id="row1" data-target=".row1" onClick={(event) => { { status === 'hide' ? setStatus('show') : setStatus('hide') }; console.log(status); }}>
                                                <th style={{ textAlign: 'center' }} scope="col">{status === 'hide' ? <i className="fas fa-angle-down icon-3x"></i> : <i className="fas fa-angle-up icon-3x"></i>}</th>
                                                <th scope="col" style={{ textAlign: 'right' }}>{val.Month1[0].Month}</th>
                                                <th scope="col" style={{ textAlign: 'right' }}>{val.Month2[0].Month}</th>
                                                <th scope="col" style={{ textAlign: 'right' }}>{val.Month3[0].Month}</th>
                                                <th scope="col" style={{ textAlign: 'right' }}>{val.Month4[0].Month}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.Year}</td>
                                                <td style={{ textAlign: 'right' }}>{val.Month1[0].Year}</td>
                                                <td style={{ textAlign: 'right' }}>{val.Month2[0].Year}</td>
                                                <td style={{ textAlign: 'right' }}>{val.Month3[0].Year}</td>
                                                <td style={{ textAlign: 'right' }}>{val.Month4[0].Year}</td>
                                            </tr>
                                            <tr key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.TotalOrders}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}> <a
                                                                    data-toggle="modal"
                                                                    data-target="#model_popup"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: 'initial',
                                                                        transition: 'color 0.3s ease'
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.style.color = '#ff0000';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.style.color = 'initial';
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setCustomerValue(val[`${`Month${v}`}`][0].TotalOrderData)
                                                                        setName(val.name.TotalOrders)
                                                                        setMonth(val[`${`Month${v}`}`][0].Month)
                                                                    }}>
                                                                    {val[`${`Month${v}`}`][0].TotalOrders}
                                                                </a></td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.NewCustomer}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}> <a
                                                                    data-toggle="modal"
                                                                    data-target="#model_popup"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: 'initial',
                                                                        transition: 'color 0.3s ease'
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.style.color = '#ff0000';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.style.color = 'initial';
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setCustomerValue(val[`${`Month${v}`}`][0].NewCustomerData)
                                                                        setName(val.name.NewCustomer)
                                                                        setMonth(val[`${`Month${v}`}`][0].Month)
                                                                    }}>
                                                                    {val[`${`Month${v}`}`][0].NewCustomer}
                                                                </a></td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.OneOrder}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}> <a
                                                                    data-toggle="modal"
                                                                    data-target="#model_popup"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: 'initial',
                                                                        transition: 'color 0.3s ease'
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.style.color = '#ff0000';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.style.color = 'initial';
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setCustomerValue(val[`${`Month${v}`}`][0].OneOrder)
                                                                        setName(val.name.OneOrder)
                                                                        setMonth(val[`${`Month${v}`}`][0].Month)
                                                                    }}>
                                                                    {val[`${`Month${v}`}`][0].OneOrder.length}
                                                                </a></td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.TwoOrder}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}> <a
                                                                    data-toggle="modal"
                                                                    data-target="#model_popup"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: 'initial',
                                                                        transition: 'color 0.3s ease'
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.style.color = '#ff0000';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.style.color = 'initial';
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setCustomerValue(val[`${`Month${v}`}`][0].TwoOrder)
                                                                        setName(val.name.TwoOrder)
                                                                        setMonth(val[`${`Month${v}`}`][0].Month)
                                                                    }}>
                                                                    {val[`${`Month${v}`}`][0].TwoOrder.length}
                                                                </a></td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.ThreeOrder}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}> <a
                                                                    data-toggle="modal"
                                                                    data-target="#model_popup"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: 'initial',
                                                                        transition: 'color 0.3s ease'
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.style.color = '#ff0000';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.style.color = 'initial';
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setCustomerValue(val[`${`Month${v}`}`][0].ThreeOrder)
                                                                        setName(val.name.ThreeOrder)
                                                                        setMonth(val[`${`Month${v}`}`][0].Month)
                                                                    }}>
                                                                    {val[`${`Month${v}`}`][0].ThreeOrder.length}
                                                                </a></td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.FourOrder}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}> <a
                                                                    data-toggle="modal"
                                                                    data-target="#model_popup"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: 'initial',
                                                                        transition: 'color 0.3s ease'
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.style.color = '#ff0000';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.style.color = 'initial';
                                                                    }} onClick={(e) => {
                                                                        setCustomerValue(val[`${`Month${v}`}`][0].FourOrder)
                                                                        setName(val.name.FourOrder)
                                                                        setMonth(val[`${`Month${v}`}`][0].Month)
                                                                    }}>
                                                                    {val[`${`Month${v}`}`][0].FourOrder.length}
                                                                </a></td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.MoreThanFour}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}> <a
                                                                    data-toggle="modal"
                                                                    data-target="#model_popup"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: 'initial',
                                                                        transition: 'color 0.3s ease'
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.style.color = '#ff0000';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.style.color = 'initial';
                                                                    }} onClick={(e) => {
                                                                        setCustomerValue(val[`${`Month${v}`}`][0].MoreThanFour)
                                                                        setName(val.name.MoreThanFour)
                                                                        setMonth(val[`${`Month${v}`}`][0].Month)
                                                                    }}>
                                                                    {val[`${`Month${v}`}`][0].MoreThanFour.length}
                                                                </a></td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.Highest}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}>{val[`${`Month${v}`}`][0].Highest}</td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.Lowest}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}>{val[`${`Month${v}`}`][0].Lowest}</td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.bucketSize}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}>{val[`${`Month${v}`}`][0].bucketSize}</td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.Lost}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}> <a
                                                                    data-toggle="modal"
                                                                    data-target="#model_popup"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: 'initial',
                                                                        transition: 'color 0.3s ease'
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.style.color = '#ff0000';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.style.color = 'initial';
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setCustomerValue(val[`${`Month${v}`}`][0].LostData)
                                                                        setName(val.name.Lost)
                                                                        setMonth(val[`${`Month${v}`}`][0].Month)
                                                                    }}>
                                                                    {val[`${`Month${v}`}`][0].Lost}
                                                                </a></td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.BackToGusta}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}> <a
                                                                    data-toggle="modal"
                                                                    data-target="#model_popup"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: 'initial',
                                                                        transition: 'color 0.3s ease'
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.style.color = '#ff0000';
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.style.color = 'initial';
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setCustomerValue(val[`${`Month${v}`}`][0].BackToGusta)
                                                                        setName(val.name.BackToGusta)
                                                                        setMonth(val[`${`Month${v}`}`][0].Month)
                                                                    }}>
                                                                    {val[`${`Month${v}`}`][0].BackToGusta.length}
                                                                </a></td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.Sale}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}>{val[`${`Month${v}`}`][0].Sale}</td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.Cost}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}>{val[`${`Month${v}`}`][0].Cost}</td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                            <tr className="collapse row1" key={key}>
                                                <td style={{ fontWeight: 'bold' }}>{val.name.Margin}</td>
                                                {
                                                    number.map((v) => {
                                                        return (
                                                            <>
                                                                <td style={{ textAlign: 'right' }}>{val[`${`Month${v}`}`][0].Margin}</td>
                                                            </>
                                                        );
                                                    })

                                                }
                                            </tr>
                                        </tbody>
                                    </>

                                    )
                                }) :
                                    <>
                                        <SkeletonSelectPageTable columnsCount={4} dataCount={5} pageSize={7} />
                                    </>
                                }

                            </table>
                        </div>
                    </div>
                </ErrorBoundary>
            </div>

            <div id='model_popup' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate">
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">{`${name} ` + `${month} `}Month Details</h4>
                            <div className="ml-auto">
                                <button disabled={CustomerValue.length === 0} className="btn btn-success" onClick={e => exportDataToExcel()}>Export Data</button>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            {CustomerValue.length > 0 ?
                                <Table className="-striped"
                                    columns={name === "Back To Gusta" ? columnsBackToGusta : columns}
                                    data={CustomerValue}
                                    defaultPageSize={10}
                                />
                                : ''}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


const mapStateToProps = state => ({
    token: state.rLogin.token,
    region: state.rBasicData.region,
    isLoading: state.rLogin.loading,
})

const mapDispatchToProps = dispatch => ({
    getRegionData: props => dispatch(getRegionData(props)),

})

export default connect(mapStateToProps, mapDispatchToProps)(Customers);