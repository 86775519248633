/**
 * @type {Object}
 * @property {array}  data  Supplier data
 */
const initialState = {
    purchaseOrders: [],
    purchaseOrdersHistory: [],
    returnToSupplierData: [],
    returnToSupplierItemsData: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerReturnToSupplier = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_RECEIVED_PURCHASE_ORDERS':
            var { data } = action;

            var modifiedData = [];

            data.map(element => {
                var obj = {
                    '_id': element.purchaseorder._id,
                    'name': element.supplier.name + ' - ' + element.purchaseorder.orderid,
                    'supplier_id': element.supplier._id
                }

                modifiedData.push(obj)
            })

            newState.purchaseOrders = modifiedData;
            break;

        case 'FETCH_RETURN_TO_SUPPLIER_DATA':
            var { data } = action;
            newState.returnToSupplierData = data;
            break;

        case 'FETCH_RETURN_TO_SUPPLIER_ITEMS_DATA':
            var { data } = action;
            newState.returnToSupplierItemsData = data;
            break;

        case 'FETCH_HISTORY_PURCHASE_ORDERS':
            var { data } = action;

            var modifiedData = [];

            data.map(element => {
                var obj = {
                    '_id': element.purchaseorderhistories._id,
                    'name': element.supplier.name + ' - ' + element.purchaseorderhistories.orderid,
                    'supplier_id': element.supplier._id
                }

                modifiedData.push(obj)
            })

            newState.purchaseOrdersHistory = modifiedData;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerReturnToSupplier;