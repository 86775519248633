/**
 * @file This file defined for amount paid modal
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect } from 'react'
import Table from "react-table";
import { toast } from 'react-toastify';

import { getCreditNoteDataByNo } from '../../../../../actions/Delivery/delivery_action';
import SearchableSelectComp from '../../../../components/SearchableSelectComp';
import { PriceFormat } from '../../../../utils';
import accountClasify from '../Func';

const inputHandler = ({ target: { value } }, key, amount, setAmount) => {
    setAmount({ ...amount, [key]: value })
}

const checkBoxHandler = ({ original: { _id, orderId, price } }, balance, amount, setAmount, creditNoteData) => {
    if (document.getElementById(`${_id}`).checked) {
        let filterData = creditNoteData?.filter(ele => ele._id === _id)
        let arr = amount.creditNotes
        arr.push(filterData[0])
        let creditNoteAmountPaid = arr.reduce((total, ele) => { return total + Number(ele.price) }, 0)
        let valAmount = balance - creditNoteAmountPaid
        // setAmount({ ...amount, value: valAmount, creditNotes: arr })
        setAmount({ ...amount, value: valAmount < 0 ? 0 : valAmount, creditNotes: arr, account: null })
    } else {
        let filterData1 = amount?.creditNotes?.filter(ele => ele._id !== _id)
        let filterData = creditNoteData?.filter(ele => ele._id === _id)
        let arr = amount.creditNotes
        arr.push(filterData[0])
        let creditNoteAmountPaid = arr.reduce((total, ele) => { return Number(ele.price) }, 0)
        let valAmount = amount.value + creditNoteAmountPaid
        setAmount({ ...amount, value: arr.length === 2 ? balance : valAmount, creditNotes: filterData1 })
    }
}

const updateAmount = async (id, amount, balance, { setAmount, setViewHandler }, props) => {
    let amountPaid = amount.creditNotes.reduce((total, ele) => { return total + Number(ele.price) }, Number(amount.value))
    amount.id = id
    amount.user = props.loggedUser
    amount.userId = props.userId
    amount.name = 'Orders'
    props.resolveMethod.map((v)=>{
        if(v.method === "Other"){
            amount.methodId = v._id;
        }
    })
    // let isCreateCreditNote = false
    if (balance < amountPaid && (amount?.isCreateCreditNote === undefined))
        amount.isCreateCreditNote = true

    console.log(amount);
    // amount.isCreateCreditNote = isCreateCreditNote
    if ((amount?.account?.length <= 0 && amount?.creditNotes?.length <= 0) || (amount?.account === null && amount?.value !== 0 && amount?.creditNotes?.length <= 0))
        toast.error('please select a account...!')
    else if (amount?.isCreateCreditNote === false && !amount?.depositAccount)
        toast.error('Please select a deposit account...!')
    else
        // console.log(amount);
        await props.updateCustomerAmount(amount, { setAmount, setViewHandler }, props)
}

/**
 * reset field value
 * 
 * @param {Function} setAmount to changed amount
 * @param {Function} setCreditNoteData to changed credit notes
 */
const clearField = (setAmount, setCreditNoteData, setRowInfo) => {
    setAmount({ account: [], paymentMethod: '', value: 0, creditNotes: [] })
    setCreditNoteData([])
    setRowInfo('')
}

const AmountPaid = ({ rowInfo, setRowInfo, props }) => {

    const [creditNoteData, setCreditNoteData] = useState([]);
    const [viewHandler, setViewHandler] = useState({ view: false });
    const [amount, setAmount] = useState({ account: [], paymentMethod: '', value: 0, creditNotes: [] });
    const [totalVal, setTotalVal] = useState(0);

    const id = rowInfo?.orders?._id
    const name = rowInfo?.orders?.name
    const orderId = rowInfo?.orders?.orderId
    const telephone = rowInfo?.orders?.telephone
    const paymentMethod = rowInfo?.orders?.paymentOption
    const amountPaid = rowInfo?.orders?.amountPaid
    const totalPrice = rowInfo?.orders?.totalPrice
    const balance = totalPrice - amountPaid

    useEffect(() => {
        props.getAccountsData(props)
    }, [])

    useEffect(() => {
        console.log('row info', rowInfo, paymentMethod);
        setAmount({ ...amount, paymentMethod: paymentMethod })
        if (telephone !== '') getCreditNoteDataByNo(telephone, setCreditNoteData, props)
        else setCreditNoteData([])
    }, [rowInfo])

    useEffect(() => {
        if (balance > 0) setAmount({ ...amount, value: balance, paymentMethod: paymentMethod })
        else setAmount({ ...amount, paymentMethod: paymentMethod })
    }, [balance]);

    useEffect(() => {
        let amountPaid = amount.creditNotes.reduce((total, ele) => { return total + Number(ele.price) }, Number(amount.value))
        setTotalVal(amountPaid)
        if (balance < amountPaid) inputHandler({ target: { value: true } }, 'isCreateCreditNote', amount, setAmount)
    }, [balance, amount.value, amount.creditNotes])

    const columns = [
        {
            Header: "", accessor: "coName", minWidth: 26,
            Cell: props => <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id={`${props.original._id}`} onClick={() => checkBoxHandler(props, balance, amount, setAmount, creditNoteData)} />
            </div>
        },
        { Header: "Name", accessor: "coName", filterable: true },
        { Header: "Order Id", accessor: "orderId", filterable: true },
        { Header: "amount", accessor: "price", filterable: true },
    ]

    return (
        <div id='amountPaidModal' key='amountPaidModal' className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg ">
                <div className="modal-content animate ">
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase">Update Amount</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={() => clearField(setAmount, setCreditNoteData, setRowInfo)}>&times;</button>
                    </div>
                    <div className='modal-body'>
                        <div>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td>Customer :</td>
                                        <td>{name?.split('-')[1]}</td>
                                        <td style={{ paddingLeft: '10px' }}>Order Id :</td>
                                        <td>{orderId}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Amount :</td>
                                        <td>{PriceFormat(totalPrice)}</td>
                                        <td style={{ paddingLeft: '10px' }}>Amount To Paid:</td>
                                        <td>{PriceFormat(balance)}</td>
                                    </tr>
                                    <tr>
                                        {
                                            amount.value !== 0 ?
                                                <>
                                                    <td>Account :</td>
                                                    <td>
                                                        <SearchableSelectComp options={accountClasify(props.accounts, ['Cash'])} label='acc_name' value={amount.account !==undefined && amount.account !== null ? amount.account._id : ''} onChange={e => setAmount({ ...amount, account: e })} />
                                                    </td>
                                                </>
                                                : ''
                                        }
                                        <td style={{ paddingLeft: amount.value !== 0 ? '10px' : '' }}>Payment Method :</td>
                                        <td>
                                            <select className='form-control' onChange={e => inputHandler(e, 'paymentMethod', amount, setAmount)}>
                                                {props.paymentMethods.map(data => <option key={data._id} value={data._id}>{data.name}</option>)}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                        <td>
                                            <input type="Number" className='form-control' min={0.00} step={'0.01'} value={amount.value} onChange={e => inputHandler(e, 'value', amount, setAmount)} />
                                        </td>
                                        <td>
                                            {amount.creditNotes.length > 0 ? `+ ${amount?.creditNotes?.reduce((total, ele) => { return total + Number(ele.price) }, 0)}` : ''}
                                        </td>
                                        <td>
                                            <button className='btn btn-link' onClick={() => setViewHandler({ view: !viewHandler.view })}>
                                                {creditNoteData.length > 0 ?
                                                    (viewHandler.view ? 'Hide'
                                                        : 'View ') + ' Credit Note'
                                                    : ''
                                                }
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        {balance < totalVal ? <>
                                            <td colSpan={2}>
                                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                    <div className="form-check-inline">
                                                        <label class="form-check-label">
                                                            <input type="radio" className="form-check-input" name="optradio" onClick={() => inputHandler({ target: { value: true } }, 'isCreateCreditNote', amount, setAmount)} checked={amount.isCreateCreditNote} />Create credit note
                                                        </label>
                                                    </div>
                                                    {
                                                        amount.value !== 0 ?
                                                            <div className="form-check-inline">
                                                                <label class="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="optradio" onClick={() => inputHandler({ target: { value: false } }, 'isCreateCreditNote', amount, setAmount)} />Select from gusta accounts
                                                                </label>
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                            </td>
                                            {!amount?.isCreateCreditNote ? <td colSpan={2}>
                                                <div style={{ width: '90%', paddingLeft: '10px' }}>
                                                    <SearchableSelectComp options={accountClasify(props.accounts, ['Payable'])} label='acc_name'value={amount.account !==undefined && amount.account !== null ? amount.account._id : ''} onChange={e => setAmount({ ...amount, depositAccount: e })} />
                                                </div>
                                            </td> : null}
                                        </> : null}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {viewHandler.view ?
                            <div>
                                <Table className="-striped"
                                    columns={columns}
                                    data={creditNoteData}
                                    defaultPageSize={5}
                                />
                            </div>
                            : null}
                        {/* {creditNoteData?.map(ele =>
                            <dl>
                                <dt>{ele.orderId}</dt>
                                <dd>{ele.coName}</dd>
                            </dl>
                        )} */}
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => updateAmount(id, amount, balance, { setAmount, setViewHandler }, props)}>Update Now</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => clearField(setAmount, setCreditNoteData, setRowInfo)}>Close</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AmountPaid
