import React, { Component } from 'react'
import ReactDOM from 'react-dom'
const modalRoot = document.getElementById('modal-root');

export default class Modal extends Component {
    render() {
        return ReactDOM.createPortal(
            <div id="deleteSupplier" className="modal fade" tabIndex='-1' role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Delete supplier</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Do you want to delete this supplier ?
                </div>
                        <div className="modal-footer">
                            <button id="delete_supplier" type="button" className="btn btn-primary" onClick={() => ''}>Yes, Delete it.</button>
                            {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            , modalRoot)
    }
}
