import React, { useState } from 'react';

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, AreaChart, ReferenceLine, Area, Cell } from 'recharts';

const SalesEvolutionGraphModal = ({ currentItem, currentItemCode, currentItemCodType }) => {


    const DataFormater = (number) => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    }

    return (
        <div id="graph" className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content animate" >
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase">Item Code: {currentItemCode}</h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="container" >
                            <div className="lg-row" style={{ display: "flex" }}>
                                <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "normal" }} className="col-md-6">
                                    <ResponsiveContainer width={700} height={350}>
                                        <BarChart
                                            data={currentItem}
                                            margin={{
                                                top: 10,
                                                right: 20,
                                                left: 0,
                                                bottom: 10,
                                            }}
                                            barSize={20}
                                        >
                                            <XAxis style={{ fontSize: '14px' }} dataKey="weekNumber" scale="point" padding={{ left: 5, right: 5 }} tick={{ fill: '#000000' }} />
                                            <YAxis style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                                            <Tooltip formatter={(sale) => new Intl.NumberFormat('en').format(sale)} contentStyle={{ color: '#000000', textAlign: 'left' }} />
                                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                            <Bar dataKey="sale" fill="#8884d8">
                                                {currentItem !== null && currentItem.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.color !== undefined ? (entry.color === "#fff" ? '#8884d8' : entry.color) : ''} />
                                                ))}
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                    {/* <><span style={{ color: '#191350', fontSize: '14px', textAlign: 'center' }} className="m-0 font-weight-bold">Weeks of {selectedMonth}</span></> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SalesEvolutionGraphModal;
