/**
 * @author Umesh Ranthilina
 */

 import { toast } from 'react-toastify';
 var axios = require('axios');
 
 export const stockExpireItemsAsync = data => {
     return { type: 'FETCH_STOCK_EXPIRE_ITEMS', data }
 }

 export const StockExpireIDateWithItemAsync = data => {
    return { type: 'FETCH_STOCK_ITEMS_EXPIRE_DATE_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
 export const getStockExpireItemData = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/getStockExpireItems', { data })
            .then(result => {
                dispatch(stockExpireItemsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const getExpireDateWithItem = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/stock/getExpireDateWithItem', { data })
            .then(result => {
                dispatch(StockExpireIDateWithItemAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}
