/**
 * @file This file defines to item tag actions
 * @author Kavindu Shehan
 */

 import { toast } from 'react-toastify';
 var axios = require('axios');
 
 export const itemTagAsync = data => {
     return { type: 'FETCH_ITEM_TAG_DATA', data}
 }
 
 export const isLoadingAsync = data => {
     return { type: 'IS_LOADING', data }
 }
 
 /**
  * 
  * @param {*} token 
  */
  export const getItemTag = ({ token }) => {
     isLoadingAsync(true)
     return dispatch => {
         axios.defaults.headers.common['authorization'] = token;
         axios.get('/basic_data/itemtag/getItemTag')
             .then(result => {
                 dispatch(itemTagAsync(result.data))
                 isLoadingAsync(false)
             }).catch(err => {
                 toast.error(`${err}`)
             })
     }
 }
 
 /**
  * 
  * @param {*} data 
  * @param {*} token 
  */
 export const addItemTag = (data, { token }) => {
     return dispatch => {
         axios.defaults.headers.common['authorization'] = token;
         axios.post('/basic_data/itemtag/addItemTag', { data })
             .then(result => {
                 toast.success('Added Item Tag...!')
                 dispatch(getItemTag({ token }))
             }).catch(err => {
                 toast.error(`${err}`)
             })
     }
 }
 
 /**
  * 
  * @param {*} data 
  * @param {*} token 
  */
  export const updateItemTag = (data, { token }) => {
     return dispatch => {
         axios.defaults.headers.common['authorization'] = token;
         axios.post('/basic_data/itemtag/updateItemTag', { data })
             .then(result => {
                 toast.success('Successfully Updated Item Tag')
                 dispatch(getItemTag({ token }))
             }).catch(err => {
                 toast.error(`${err}`)
             })
     }
 }
 
 /**
  * 
  * @param {*} data 
  * @param {*} token 
  */
  export const deleteItemTag = (data, { token }) => {
     return dispatch => {
         axios.defaults.headers.common['authorization'] = token;
         axios.post('/basic_data/itemtag/deleteItemTag', { data })
             .then(result => {
                 toast.success('Item Tag Deleted Successfully!')
                 dispatch(getItemTag({ token }))
             }).catch(err => {
                 toast.error(`${err}`)
             })
     }
 }
 