/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const shelfLivesAsync = data => {
    return { type: 'FETCH_SHELF_LIVES_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
export const getShelfLives = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/shelflife/getShelfLives')
            .then(result => {
                dispatch(shelfLivesAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateShelfLife = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/shelflife/updateShelfLife', { data })
            .then(result => {
                toast.success('Successfully Updated ShelfLife!')
                dispatch(getShelfLives(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteShelfLife = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/shelflife/deleteShelfLife', { data })
            .then(result => {
                toast.success('ShelfLife Deleted Successfully!')
                dispatch(getShelfLives(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addShelfLife = ({ state, temperatureUnit, temperature, slug }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/shelflife/addShelfLife', {
            data: {
                state,
                temperatureUnit,
                temperature,
                slug
            }
        })
            .then(result => {
                window.$('#addShelfLife').modal('hide');
                toast.success('Successfully Added ShelfLife');
                dispatch(getShelfLives(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}