/**
 * @author Bhanuka Chathuranga
 */
import axios from 'axios';
import { toast } from 'react-toastify';


/**
 * To get item hierarchy data
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const getItemHierarchy = ({ item }, func, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getItemHierarchy', { headers: { authorization: token }, params: { item } })
            .then(result => {
                // console.log(result);
                func(result.data)
            })
            .catch(err => {
                console.error(err);
                toast.error(err.msg)
            })
    }
}

/**
 * To create item hierarchy data
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const createItemHierarchy = (data, func, { token }) => {
    return dispatch => {
        //console.log(data);
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item/createItemHierarchy', { data })
            .then(result => {
                //console.log(data);
                toast.success('Hierarchy created sucessfully...!')
                dispatch(getItemHierarchy(data, func, { token }))
            })
            .catch(err => {
                console.error(err);
                toast.error(err.msg)
            })
    }
}

export const copyHierarchyToItems = (data, func, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item/copyHierarchyToItems', { data })
            .then(result => {
                
            }).catch(err => {
                console.error(err);
                toast.error(err.msg)
            })
    }
}