/**
 * @file This file defines to order action dispatch
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const receivedPurchaseOrdersAsync = data => {
    return { type: 'FETCH_RECEIVED_PURCHASE_ORDERS', data }
}

export const returnToSupplierDataAsync = data => {
    return { type: 'FETCH_RETURN_TO_SUPPLIER_DATA', data }
}

export const returnToSupplierItemsDataAsync = data => {
    return { type: 'FETCH_RETURN_TO_SUPPLIER_ITEMS_DATA', data }
}

export const historyPurchaseOrdersAsync = data => {
    return { type: 'FETCH_HISTORY_PURCHASE_ORDERS', data }
}

/**
 * 
 * 
 * @param {string} token 
 */
export const getReceivedPurchaseOrders = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getReceivedPurchaseOrders')
            .then(result => {
                dispatch(receivedPurchaseOrdersAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * 
 * @param {string} token 
 */
 export const getHistoryPurchaseOrders = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getHistoryPurchaseOrders')
            .then(result => {
                dispatch(historyPurchaseOrdersAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * 
 * @param {string} token 
 */
 export const getReturnToSupplierData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getReturnToSupplierData')
            .then(result => {
                console.log(result.data)
                dispatch(returnToSupplierDataAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const getReturnToSupplierItemsData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getReturnToSupplierItemsData')
            .then(result => {
                dispatch(returnToSupplierItemsDataAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const addReturnToSupplier = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/purchasing/addReturnToSupplier', data)
            .then(result => {
                window.$('#addReturnToStock').modal('hide');
                dispatch(getReturnToSupplierItemsData(token));
                dispatch(getReturnToSupplierData(token));
                toast.success(result.data.message);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const updateReturnToSupplier = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/purchasing/updateReturnToSupplier', data)
            .then(result => {
                dispatch(getReturnToSupplierItemsData(token))
                toast.success(result.data.message);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const deleteReturnToSupplierItem = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/purchasing/deleteReturnToSupplierItem', data)
            .then(result => {
                dispatch(getReturnToSupplierItemsData(token))
                toast.success(result.data.message);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const deleteReturnToSupplier = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/purchasing/deleteReturnToSupplier', data)
            .then(result => {
                dispatch(getReturnToSupplierItemsData(token));
                dispatch(getReturnToSupplierData(token));
                toast.success(result.data.message);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const completeReturnToSupplier = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/purchasing/completeReturnToSupplier', data)
            .then(result => {
                window.$('#completeReturnToSupplier').modal('hide');
                dispatch(getReturnToSupplierItemsData(token));
                dispatch(getReturnToSupplierData(token));
                toast.success(result.data.message);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const completeReturnToSupplierHistoryPO = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/purchasing/completeReturnToSupplierHistoryPO', data)
            .then(result => {
                window.$('#completeReturnToSupplier').modal('hide');
                dispatch(getReturnToSupplierItemsData(token));
                dispatch(getReturnToSupplierData(token));
                toast.success(result.data.message);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

