import _ from 'lodash';

const initialState = {
    packagingDataActive: [],
    packagingDataHistory: [],
    packagingSendItemsData: [],
    packagingRecvItemsData: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerPackaging = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_PACKAGE_DATA':

            let activeData = _.filter(data, (element) => {
                return element.package.history === false
            })

            let historyData = _.filter(data, (element) => {
                return element.package.history === true
            })

            newState.packagingDataActive = activeData
            newState.packagingDataHistory = historyData

            break;

        case 'FETCH_PACKAGE_SEND_ITEMS_DATA':
            newState.packagingSendItemsData = data;
            break;

        case 'FETCH_PACKAGE_RECV_ITEMS_DATA':
            newState.packagingRecvItemsData = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerPackaging;
