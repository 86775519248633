/**
 * @type {Object}
 * @property {array}  data  Stock data
 */
const initialState = {
    stockItems: [],
    stockItemsWithExpDate: [],
    stockItemsReportData: [],
    stockExpireItemsWithDate: [],
    stockItemsWithExpDateForPackaging: [],
    stockItemsWithExpDateForPackaging: []
};

const customFilter = (arrA, arrB) => {
    return arrA.filter(objA => {
        return !arrB.some(objB => objA._id === objB._id && objA.name === objB.name)
    })
}

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const stockItemReducer = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_STOCK_ITEMS':
            newState.stockItems = data;
            break;

        case 'FETCH_STOCK_ITEMS_EXP_DATES':
            newState.stockItemsWithExpDate = data;
            break;

        case 'FETCH_STOCK_ITEMS_EXP_DATES_PACK':
            let dataForPackaging = [];
            let selectedItem = data.selectedItem

            if (data.data.exp_items_stocks?.length > 0) {
                if (selectedItem?.expItems?.length > 0) {
                    let rest = customFilter(data.data.exp_items_stocks, selectedItem.expItems)

                    rest.map(element => {
                        element.selected_qty = Number(0)
                        dataForPackaging.push(element)
                    })

                    selectedItem.expItems.map(ele => {
                        dataForPackaging.push(ele)
                    })

                } else {
                    data.data.exp_items_stocks.map(element => {
                        element.selected_qty = Number(0)
                        dataForPackaging.push(element)
                    })
                }
            }

            data.data.exp_items_stocks = dataForPackaging

            newState.stockItemsWithExpDateForPackaging = data.data
            break;

        case 'FETCH_STOCK_EXPIRE_ITEMS':
            newState.stockExpireItems = data;
            break;

        case 'FETCH_STOCK_ITEMS_REPORT_DATA':
            newState.stockItemsReportData = data;
            break;

        case 'FETCH_STOCK_ITEMS_EXPIRE_DATE_DATA':
            newState.stockExpireItemsWithDate = data;
            break;

        default:
            break;
    }
    return newState;
}

export default stockItemReducer;