/**
 * @file This file defined for show region details
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";

import ActionsCell from "../../components/ActionCell";
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import ErrorBoundary from '../../components/ErrorBoundary';
import Permission from '../../utils/Permission';
import FormProvider from "../../components/FormProvider";
import { getRegionData } from '../../../actions/Basic_data/basic_data_action';
import { addRegion, deleteRegion, updateFixedDate, updateRegion } from '../../../actions/Basic_data/region_action';

export const Region = props => {
    const [name, setName] = useState('');
    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [id, setId] = useState('');
    const [days, setDays] = useState([])

    useEffect(() => {
        props.getRegionData(props)
    }, [])

    // useEffect(() => {
    //   document.getElementById('monCheckUpdatebox').value =false;
    // }, [days])

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            // mode: (edit === rowProps.original && rowProps.original.orders.status !== 'Delivered') ? "edit" : "view",
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'CITY', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'CITY', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const columns = [
        { Header: "Regions", accessor: "name", ...editableColumnProps },
        {
            Header: "Fix date",
            accessor: "fixedDate",
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#updateFixDate' className='btn btn-link'
                        onClick={() => {
                            if (row.value?.includes('mon')) { document.getElementById('monCheckUpdatebox').checked = true } else { document.getElementById('monCheckUpdatebox').checked = false }
                            if (row.value?.includes('tue')) { document.getElementById('tueCheckUpdatebox').checked = true } else { document.getElementById('tueCheckUpdatebox').checked = false }
                            if (row.value?.includes('wed')) { document.getElementById('wedCheckUpdatebox').checked = true } else { document.getElementById('wedCheckUpdatebox').checked = false }
                            if (row.value?.includes('thu')) { document.getElementById('thuCheckUpdatebox').checked = true } else { document.getElementById('thuCheckUpdatebox').checked = false }
                            if (row.value?.includes('fri')) { document.getElementById('friCheckUpdatebox').checked = true } else { document.getElementById('friCheckUpdatebox').checked = false }
                            if (row.value?.includes('sat')) { document.getElementById('satCheckUpdatebox').checked = true } else { document.getElementById('satCheckUpdatebox').checked = false }
                            if (row.value?.includes('sun')) { document.getElementById('sunCheckUpdatebox').checked = true } else { document.getElementById('sunCheckUpdatebox').checked = false }
                            setDays(row.value);
                            setId(row.original?._id)
                        }}>
                        Fix delivery date
                    </button>
                </>
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const addRegionHandler = () => {
        let val = []
        if (document.getElementById('monCheckbox').checked) val.push('mon')
        if (document.getElementById('tueCheckbox').checked) val.push('tue')
        if (document.getElementById('wedCheckbox').checked) val.push('wed')
        if (document.getElementById('thuCheckbox').checked) val.push('thu')
        if (document.getElementById('friCheckbox').checked) val.push('fri')
        if (document.getElementById('satCheckbox').checked) val.push('sat')
        if (document.getElementById('sunCheckbox').checked) val.push('sun')
        console.log(val);
        props.addRegion({ name, val }, props)
    }

    const updateHandler = () => {
        let val = []
        if (document.getElementById('monCheckUpdatebox').checked) val.push('mon')
        if (document.getElementById('tueCheckUpdatebox').checked) val.push('tue')
        if (document.getElementById('wedCheckUpdatebox').checked) val.push('wed')
        if (document.getElementById('thuCheckUpdatebox').checked) val.push('thu')
        if (document.getElementById('friCheckUpdatebox').checked) val.push('fri')
        if (document.getElementById('satCheckUpdatebox').checked) val.push('sat')
        if (document.getElementById('sunCheckUpdatebox').checked) val.push('sun')
        console.log(val);
        // props.addRegion({ name, val }, props)
        props.updateFixedDate({ id, val }, props)
    }

    const handleSubmit = value => {
        console.info(value)
        if (editing !== null) {
            props.updateRegion(value, props)
        } else if (deleting !== null) {
            props.deleteRegion(value, props)
        }
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'REGION', 'ADD', props)} data-toggle="modal" data-target="#addRegion"
                        onClick={() => setName('')}>
                        + Region
                        </button>
                </div>
                <div className="card-body">
                    <ErrorBoundary>
                        <FormProvider
                            form="inline"
                            onSubmit={handleSubmit}
                            onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                            initialValues={editing === null ? deleting : editing}
                            enableReinitialize
                        >
                            {formProps => {
                                return (
                                    <form onSubmit={formProps.handleSubmit}>
                                        <Table className="-striped"
                                            columns={columns}
                                            data={props.region}
                                            defaultPageSize={10}
                                        />
                                    </form>
                                );
                            }}
                        </FormProvider>
                    </ErrorBoundary>
                </div>
            </div>

            {/* add region */}
            <div id="addRegion" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Add Region</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div style={{ display: 'flex', direction: 'row' }}>
                                    <div style={{ width: '20%' }}>Region :</div>
                                    <div><input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} /></div>
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="monCheckbox" value="mon" />
                                        <label className="form-check-label" for="monCheckbox">Mon</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="tueCheckbox" value="tue" />
                                        <label className="form-check-label" for="tueCheckbox">Tue</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="wedCheckbox" value="wed" />
                                        <label className="form-check-label" for="wedCheckbox">Wed</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="thuCheckbox" value="thu" />
                                        <label className="form-check-label" for="thuCheckbox">Thu</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="friCheckbox" value="fri" />
                                        <label className="form-check-label" for="friCheckbox">Fri</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="satCheckbox" value="sat" />
                                        <label className="form-check-label" for="satCheckbox">Sat</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="sunCheckbox" value="sun" />
                                        <label className="form-check-label" for="sunCheckbox">Sun</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={addRegionHandler}>Add</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* update fix date */}
            <div id="updateFixDate" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Update Fix Delivery Region</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div>
                                    {/* <div>
                                        {days?.map(day => day)}
                                    </div> */}
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="monCheckUpdatebox" value="mon" defaultChecked={days?.includes('mon')} />
                                        <label className="form-check-label" for="monCheckUpdatebox">Mon</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="tueCheckUpdatebox" value="tue" defaultChecked={days?.includes('tue')} />
                                        <label className="form-check-label" for="tueCheckUpdatebox">Tue</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="wedCheckUpdatebox" value="wed" defaultChecked={days?.includes('wed')} />
                                        <label className="form-check-label" for="wedCheckUpdatebox">Wed</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="thuCheckUpdatebox" value="thu" defaultChecked={days?.includes('thu')} />
                                        <label className="form-check-label" for="thuCheckUpdatebox">Thu</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="friCheckUpdatebox" value="fri" defaultChecked={days?.includes('fri')} />
                                        <label className="form-check-label" for="friCheckUpdatebox">Fri</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="satCheckUpdatebox" value="sat" defaultChecked={days?.includes('sat')} />
                                        <label className="form-check-label" for="satCheckUpdatebox">Sat</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="sunCheckUpdatebox" value="sun" defaultChecked={days?.includes('sun')} />
                                        <label className="form-check-label" for="sunCheckUpdatebox">Sun</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={updateHandler}>Update</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        id: state.rLogin.id,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        region: state.rBasicData.region,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRegionData: props => { dispatch(getRegionData(props)) },
        addRegion: (data, props) => { dispatch(addRegion(data, props)) },
        updateRegion: (data, props) => { dispatch(updateRegion(data, props)) },
        deleteRegion: (data, props) => { dispatch(deleteRegion(data, props)) },
        updateFixedDate: (data, props) => { dispatch(updateFixedDate(data, props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Region)
