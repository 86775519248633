/**
 * @file This file defines to purchasing price action dispatch
 * @author Kavindu Shehan
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const priceChangeOrderAsync = data => {
    return { type: 'FETCH_PRICE_CHANGE_ORDER', data }
}

export const priceChangeItemsAsync = data => {
    return { type: 'FETCH_PRICE_CHANGE_ITEMS', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const purchaseOrdersAsync = data => {
    return { type: 'PURCHASE_ORDERS_ASYNC', data }
}

/**
 * get price change order details 
 * 
 * @param {string} token 
 */
export const getPriceChangeOrder = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.get('/purchasing/getPriceChangeItems')
            .then(result => {
                dispatch(isLoadingAsync(false))
                dispatch(priceChangeOrderAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const updatePurchasePrice = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.post('/purchasing/updatePurchasePrice', { data })
            .then(result => {
                dispatch(isLoadingAsync(false))
                dispatch(getPriceChangeOrder(token))
                dispatch(getNewPriceChangeItems(token))
                dispatch(getPurchaseOrders(token))
                toast.success('Purchase price updated successfully')
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

export const getNewPriceChangeItems = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.get('/purchasing/getNewPriceChangeItems')
            .then(result => {
                dispatch(isLoadingAsync(false))
                console.log(result.data);
                dispatch(priceChangeItemsAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const getPurchaseOrders = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.get('/purchasing/getPurchaseOrders')
            .then(result => {
                dispatch(isLoadingAsync(false))
                dispatch(purchaseOrdersAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}








