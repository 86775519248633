/**
 * @file This file defines for navigation bar
 * @author Bhanuka Chathuranga
 */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { loginOutAsync } from '../../actions/login_action';
import user from '../../images/user.png';
import { io } from 'socket.io-client';
import { Howl, Howler } from 'howler';
import bellSound from '../../sounds/bell.mp3'
import '../../css/bell.css'
import { NavLink } from 'react-router-dom';

const timeString = ({ createdAt }) => {
    let time = Math.floor((Math.abs(new Date() - new Date(createdAt)) / 1000) / 60)
    let hour = Math.floor(time / 60)
    return hour >= 1 ? `${hour} hr ${time - (hour * 60)}` : time
}

const sound = new Howl({ src: [bellSound], loop: true })

function Navigation(props) {

    const [message, setMessage] = useState('');

    Howler.volume(0.5)
    const socket = io(process.env.NODE_ENV === 'production' ? '' : `http://${process.env.HOST || 'localhost'}:${process.env.PORT || 4000}`)
    // const socket = io()
    socket.on('connection')
    socket.on('message', data => {
        // console.log(data, message);
        // this.setState({ message: data })
        setMessage(data)
        if (!sound.playing()) {
            sound.play()
        }
        document.querySelector('#bell').classList.toggle('animated')
    })

    socket.on('confiorm', data => {
        // console.log(data);
        sound.stop()
        document.querySelector('#bell').classList.remove('animated')
    })
    const func = () => socket.emit('message', {
        address: "No:26,kondadeniya,katugasthota",
        createdAt: "2021-08-24T03:41:03.728Z",
        name: "Bhanuka",
        orderId: "2021-08-05-9874",
        orderPickUp: false
    })

    var { first, last } = props.name;

    return (
        <div className="top_nav" >
            {/* top navigation */}
            <div className="nav_menu">
                <div className="nav toggle">
                    <a id="menu_toggle"><i className="fa fa-bars"></i></a>
                </div>
                <nav className="nav navbar-nav">
                    <ul className=" navbar-right">
                        <li
                            className="nav-item dropdown open"
                            style={{ paddingLeft: '15px' }}>
                            <a
                                href="#"
                                className="user-profile dropdown-toggle uppercase spacing-1"
                                aria-haspopup="true"
                                id="navbarDropdown"
                                data-toggle="dropdown"
                                aria-expanded="false">
                                {/* <img url="images/img.jpg" alt="" />{first} {last} */}
                                {/* <img src={`${this.props.image.url === undefined ? 'images/img.jpg' : this.props.image.url}`} alt="user_image" />{first} {last} */}
                                {props.image === undefined || props.image === '' || props.image === null ?
                                    <img src={user} alt="" style={{ border: '0.1rem solid' }} />
                                    : <img src={`${props.image.url}`} width={'128'} height={'128'} alt="user_image" />}<>{first}</>
                            </a>
                            <div
                                className="dropdown-menu dropdown-usermenu pull-right"
                                aria-labelledby="navbarDropdown">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', height: '90px', width: '90px', borderRadius: '50%', paddingTop: '5px' }}>
                                        {props.image === undefined || props.image === '' || props.image === null
                                            ? <img src={user} alt="" width={'80px'} height={'80px'} style={{ borderRadius: '50%' }} />
                                            : <img src={`${props.image.url}`} width={'80px'} height={'80px'} alt="user_image" style={{ borderRadius: '50%' }} />}
                                    </div>
                                    <div className='row-center logout-user-menu uppercase spacing-1' onClick={() => first === 'Admin' ? func() : ''}>
                                        {first} {last}
                                        {/* <NavLink to={`/Administrator/user`} className='btn-link'>Change Profile</NavLink> */}
                                    </div>
                                </div>
                                <hr />
                                {/* <a className="dropdown-item" href="#">Help</a> */}
                                <div className="dropdown-item" href="login.html" onClick={() => props.logout()}>
                                    {/* <i className="fa fa-sign-out pull-right" /> */}
                                    Log Out</div>
                            </div>
                        </li>

                        <li role="presentation" className="nav-item dropdown open"
                            onClick={() => {
                                socket.emit('confiorm', 'ok')
                                sound.stop()
                                document.querySelector('#bell').classList.remove('animated')
                            }}
                        >
                            <a href="#" className="dropdown-toggle" id="navbarNotifyDropdown" data-toggle="dropdown" aria-expanded="false">
                                <i id='bell' className='fa fa-bell faa-ring fa-2x'></i>
                            </a>
                            <ul className="dropdown-menu list-unstyled msg_list" role="menu" aria-labelledby="navbarNotifyDropdown">
                                {/* {console.log(message)}
                                {message.length}*/}
                                {message !== '' ?
                                    <>
                                        {/* {message.map(ele => */}
                                        <li className="nav-item" onClick={() => setMessage('')}>
                                            <a className="dropdown-item">
                                                <span className="image">
                                                    <img src={require('../../images/fastDelivery.png')} alt="notify_img" />
                                                </span>
                                                <span>
                                                    <span>{message?.name}</span>
                                                    <span className="time">
                                                        {timeString(message)} mins ago
                                                    </span>
                                                </span>
                                                <span className="message">
                                                    <NavLink to={`/Orders/Order`}> New fast deliver order id is {message?.orderId} and wanted to deliver {message?.address?.split(',').pop()}</NavLink>
                                                </span>
                                            </a>
                                        </li>
                                        {/* )} */}
                                    </>
                                    : <li className="nav-item">
                                        <a className="dropdown-item">
                                            <span className="message">
                                                No new notifications
                                            </span>
                                        </a>
                                    </li>}
                            </ul>
                        </li>

                    </ul>
                </nav>
            </div>
            {/* /top navigation */}
        </div >
    );
}


const mapStateToProps = state => {
    return {
        isLoggedIn: state.rLogin.isLoggedIn,
        name: state.rLogin.name,
        image: state.rLogin.userImage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => { dispatch(loginOutAsync(false)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
