import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { Permission } from '../../../../utils'
import AddUser from './AddUser'
import UsersViewTable from './UsersViewTable';

const DeliveryWarehouseUserModal = ({ props, currentWarehouseId, reloadUserData, userData, filterCaseInsensitive, userIds}) => {
    const [viewHandler, setViewHandler] = useState({ view: true, add: false, update: false });
    const [isClose, setIsClose] = useState(false);
    const [users, setUsers] = useState([]);
    const [addUser, setAddUser] = useState({ users: '' });

    useEffect(() => {
        props.getUsers(props)
    }, [])

    useEffect(() => {
        var data = _.filter(props.users, function (o) {
            if (o.isAdmin) return o
        })
        setUsers(data)
    }, [props.users])

    useEffect(() => {
        if (viewHandler.view) {
            document.getElementById('viewUser').classList.add('show')
            document.getElementById('addUser').classList.remove('show')
        }
        if (viewHandler.add) {
            document.getElementById('addUser').classList.add('show')
            document.getElementById('viewUser').classList.remove('show')
        }
    }, [viewHandler])

    return (
        <div id='addUserPopup' key='addUserPopup' className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg ">
                <div className="modal-content animate ">
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase">Add User to Delivery Warehouse</h4>
                        <button type="button" className="close" data-dismiss="modal" >&times;</button>
                    </div>
                    <div className='card-header'>
                        <button type="button" disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'VIEW', props)} className="btn btn-primary" onClick={() => reloadUserData()}>
                            <i className="fa fa-sync"></i>
                        </button>
                        <button type="button" disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'ADD', props)} className="btn btn-primary" 
                           onClick={e => setViewHandler({ view: false, add: true })}>
                            + Add
                        </button>
                        <button type="button" disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'VIEW', props)} className="btn btn-primary" 
                            onClick={e => setViewHandler({ view: true, add: false })}>
                            View
                        </button>
                    </div>
                    <div className="modal-body">
                        <div class="row">
                            <div class="col">
                                <div>
                                    <div className="collapse multi-collapse" id="addUser">
                                        <div class="card card-body">
                                            <AddUser users={users} setViewHandler={setViewHandler} addUser={addUser} setAddUser={setAddUser}
                                                isClose={isClose} setIsClose={setIsClose} currentWarehouseId={currentWarehouseId} reloadUserData={reloadUserData} userIds={userIds} props={props} />
                                        </div>
                                    </div>
                                    <UsersViewTable  reloadUserData={reloadUserData} viewHandler={viewHandler} setViewHandler={setViewHandler} isClose={isClose} setIsClose={setIsClose} userData={userData} filterCaseInsensitive={filterCaseInsensitive} props={props} />
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { setViewHandler({ view: true, add: false, update: false }); setIsClose(true) }}>Close</button>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default DeliveryWarehouseUserModal