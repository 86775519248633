/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getPaymentMethods, updatePaymentMethod, deletePaymentMethod, addPaymentMethod } from '../../../actions/Basic_data/payment_method_actions';
import SkeletonTable from "../../components/skeleton/SkeletonTable";

const ReactTableFixedColumns = withFixedColumns(Table);

class PaymentMethod extends React.Component {

    boolData = [
        { '_id': 'true', 'name': 'true' },
        { '_id': 'false', 'name': 'false' }
    ];

    userTypeData = [
        { '_id': 'All', 'name': 'All' },
        { '_id': 'Normal', 'name': 'Normal' },
        { '_id': 'Admin', 'name': 'Admin' },
        { '_id': 'Data Operator', 'name': 'Data Operator' },
        { '_id': 'Corporate', 'name': 'Corporate' },
        { '_id': 'Delivery', 'name': 'Delivery' },
    ];

    state = {
        paymentMethods: [],
        name: '',
        isActive: '',
        description:'',
        userType: '',
        slug: '',
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'name', desc: false }],
    };

    componentDidMount() {
        this.props.getPaymentMethods(this.props.token);
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDropdownList,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    props.column.Header === 'User Type' ? this.userTypeData :
                        this.boolData).map(data =>
                            <option key={data._id} value={data._id}>{data.name}</option>
                        )}
            </Field> : <label>{props.column.Header === 'Is Active' ? props.value ? 'TRUE' : 'FALSE' : props.value}</label>)
        }
    };

    setStateForEdit = (column, value) => {
        if (column === 'User Type') {
            this.setState({ userType: value })
        } else if (column === 'Is Active') {
            this.setState({ isActive: value })
        }
    }

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'PAYMENT METHOD', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'PAYMENT METHOD', 'DELETE', this.props)
        }) ||
        {};

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values;

            if (this.state.userType !== '') {
                data.userType = this.state.userType;
            }

            if (this.state.isActive !== '') {
                data.isActive = this.state.isActive;
            }
            this.props.updatePaymentMethod(data, this.props.token)

            this.setState({
                userType: '',
                isActive: '',
            });

        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values._id
            }

            this.props.deletePaymentMethod(data, this.props.token)
        }
    };

    addPaymentMethodHandle = () => {
        var { name, userType, isActive, description } = this.state;

        if (name !== '' && userType !== '' && isActive.trim() !== ''&& description !== '') {

            this.state.slug = name.replace(/ /gi, "-");
            this.state.slug = this.state.slug.replace(/'/gi, "-");

            this.state.isActive = this.state.isActive == 'true' ? true : false;

            this.props.addPaymentMethod(this.state, this.props.token)

            this.setState({
                name: '',
                isActive: '',
                userType: '',
                description:'',
            });

            document.getElementById("addPaymentMethodForm").reset();
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    paymentMethodColumns = [
        { Header: "name", accessor: "name"},
        { Header: "Description", accessor: "description", ...this.editableColumnProps },
        { Header: "Is Active", accessor: "isActive", ...this.selectableColumnProps },
        { Header: "User Type", accessor: "userType", ...this.selectableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'PAYMENT METHOD', 'ADD', this.props)} data-toggle="modal" data-target="#addPaymentMethod">+ Payment Method</button>
                    </div>
                    <div className="card-body">
                        {this.props.isLoading
                            ? <>
                                <SkeletonTable columnsCount={4} dataCount={10} />
                            </>
                            : <React.Fragment>
                                <ErrorBoundary>
                                    <FormProvider
                                        form="inline"
                                        onSubmit={this.handleSubmit}
                                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                        enableReinitialize
                                    >
                                        {formProps => {
                                            return (
                                                <form onSubmit={formProps.handleSubmit}>
                                                    <ReactTableFixedColumns className="-striped"
                                                        sorted={this.state.sortOptions}
                                                        onSortedChange={val => {
                                                            this.setState({ sortOptions: val })
                                                        }}
                                                        columns={this.paymentMethodColumns}
                                                        data={this.props.paymentMethods}
                                                        defaultPageSize={10}
                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                        showPageJump={false}
                                                    />
                                                </form>
                                            );
                                        }}
                                    </FormProvider>
                                </ErrorBoundary>
                            </React.Fragment>}
                    </div>


                    <div id="addPaymentMethod" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Payment Condition</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addPaymentMethodForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ name: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>User Type* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ userType: e.target.value })}>
                                                                <option value={''}>- Select User Type -</option>
                                                                {this.userTypeData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is Active* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ isActive: e.target.value })}>
                                                                <option value={''}>- Is Active? -</option>
                                                                {this.boolData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Description* :</td>
                                                        <td><textarea type="text" className="form-control" onChange={e => this.setState({ description: e.target.value })} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="add_stock" type="button" className="btn btn-primary" onClick={this.addPaymentMethodHandle}>Add</button>
                                    {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        paymentMethods: state.rPaymentMethod.paymentMethods,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPaymentMethods: (token) => { dispatch(getPaymentMethods(token)) },
        updatePaymentMethod: (data, token) => { dispatch(updatePaymentMethod(data, token)) },
        deletePaymentMethod: (data, token) => { dispatch(deletePaymentMethod(data, token)) },
        addPaymentMethod: (data, token) => { dispatch(addPaymentMethod(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);


