import React, { useState } from 'react';
import Table from "react-table";
import DatePicker from "react-datepicker";

import FormProvider from '../../../components/FormProvider';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { DateNumberFormat } from '../../../utils';

const ExpStockView = ({ subColumns, data, filterCaseInsensitive }) => {
    return <>
        <Table className="-striped"
            columns={subColumns}
            data={data}
            defaultPageSize={10}
            defaultFilterMethod={filterCaseInsensitive}
        />
    </>
}


const ItemStockHistory = ({ state, filterStockHistoryData, filterCaseInsensitive, setStartDate, setEndDate, filterStockReportData, detailData }) => {

    const [viewHandler, setViewHandler] = useState({ stockHistory: true, expStock: false });
    const [data, setData] = useState([]);
    const [isActive, setActive] = useState(false);
    const [isView, setView] = useState(true);

    const stockHistoryColumns = [
        { Header: "Stock", accessor: "stock", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Available Stock", accessor: "availableStock", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Reservation Stock", accessor: "reservationStock", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Stock Difference", accessor: "stockDifference", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Remark", accessor: "remark", filterable: true },
        { Header: "Date", accessor: "date", filterable: true },
        { Header: "User", accessor: "user.first", filterable: true },
        {
            Header: "Exp Stock", accessor: "date", Cell: props => <>
                <button className="btn btn-link" disabled={props?.original?.expItems?.length > 0 ? false : true} onClick={() => {
                    setViewHandler({ stockHistory: false, expStock: true });
                    setData(props.original.expItems)
                    setView(false)
                }}>
                    view
                </button>
            </>
        },
    ];

    const subColumns = [
        { Header: "Outlet", accessor: "outletName" },
        { Header: "Stock", accessor: "totalQty", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Difference", accessor: "qty", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Exp. date", accessor: "exp_date", Cell: props => <>{DateNumberFormat(props.value)}</> },
        { Header: "Release date", accessor: "updatedAt", Cell: props => <>{DateNumberFormat(props.value)}</> },
        { Header: "User", accessor: "user", Cell: props => <>{props.value.first}</> },
    ];

    const StockDetailsColumns = [
        { Header: "Change", accessor: "stockDifference", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Cumulative Stock", accessor: "stock", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Remark", accessor: "remark", filterable: true },
        { Header: "Date", accessor: "date", filterable: true },
        { Header: "Price", accessor: "price", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "User", accessor: "user.first", filterable: true },

    ];

    return <div id='itemStockHistory' className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
            <div className="modal-content animate" >
                <div className="modal-header">
                    <h4 className="modal-title text-uppercase">Stock History</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-header">
                    {viewHandler.stockHistory ?
                        <>
                            <span className='pull-left'>
                                Date From:
                                <DatePicker selected={state.startDate} onChange={setStartDate} maxDate={new Date()} />
                            </span>

                            <span className='pull-left'>
                                Date To:
                                <DatePicker selected={state.endDate} onChange={setEndDate} maxDate={new Date()} />
                            </span>
                            <span>
                                <button type="button" className="btn btn-primary" onClick={isActive ? filterStockReportData : filterStockHistoryData}>Filter</button>
                            </span>
                        </>
                        : <>
                            <button className='btn btn-primary' onClick={() => {
                                setViewHandler({ stockHistory: true, expStock: false })
                                setView(true)
                            }}>
                                <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
                            </button>
                        </>
                    }

                </div>
                {isView === true ?
                    <div className="modal-body">
                        <input type='radio' name='history' style={{ marginRight: '5px' }} checked={isActive ? false : true} onChange={e => { setActive(false) }} /><label>Stock Movement</label>
                        <input type='radio' name='history' style={{ marginLeft: '30px', marginRight: '5px' }} onChange={e => { setActive(true) }} /><label>Stock Details</label>
                    </div>
                    : ''}
                <div className="modal-body">
                    {(state.itemStockHistory === null || state.itemStockHistory.length == 0) ?
                        <>
                            No Data Found...!
                        </>
                        :
                        <div>
                            {isActive === true ? <>
                                {viewHandler.stockHistory ? <ErrorBoundary>
                                    {/* <TableViewer columns={stockHistoryColumns} getData={ } props /> */}
                                    <FormProvider
                                        form="inline"
                                    >
                                        {formProps => {
                                            return (
                                                <form>
                                                    <Table className="-striped"
                                                        columns={StockDetailsColumns}
                                                        data={detailData}
                                                        defaultPageSize={10}
                                                        defaultFilterMethod={filterCaseInsensitive}
                                                    />
                                                </form>
                                            )
                                        }}

                                    </FormProvider>
                                </ErrorBoundary>
                                    : null}
                            </> : <>
                                {viewHandler.stockHistory ? <ErrorBoundary>
                                    {/* <TableViewer columns={stockHistoryColumns} getData={ } props /> */}
                                    <FormProvider
                                        form="inline"
                                    >
                                        {formProps => {
                                            return (
                                                <form>
                                                    <Table className="-striped"
                                                        columns={stockHistoryColumns}
                                                        data={state.itemStockHistory}
                                                        defaultPageSize={10}
                                                        defaultFilterMethod={filterCaseInsensitive}
                                                    />
                                                </form>
                                            )
                                        }}

                                    </FormProvider>
                                </ErrorBoundary>
                                    : <ErrorBoundary>
                                        <ExpStockView subColumns={subColumns} data={data} filterCaseInsensitive={filterCaseInsensitive} />
                                    </ErrorBoundary>}
                            </>}
                        </div>

                    }
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setViewHandler({ stockHistory: true, expStock: false })}>Close</button>
                </div>
            </div>
        </div>
    </div>

};

export default ItemStockHistory;
