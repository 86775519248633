/**
 * @file This file defines for sub menu
 * @author Bhanuka Chathuranga
 */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class SubTitle extends Component {
    render() {
        return (
            <li>
                <a><i className={this.props.icon} />
                    {this.props.title}
                    <span className="fa fa-chevron-down" /></a>
                <ul className="nav child_menu">
                    {this.props.subTitle1 === '' || this.props.subTitle1 === null || this.props.subTitle1 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle1}`}>{this.props.subTitle1}</NavLink>
                        </li>
                    }

                    {this.props.subTitle2 === '' || this.props.subTitle2 === null || this.props.subTitle2 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle2}`}>{this.props.subTitle2}</NavLink>
                        </li>
                    }

                    {this.props.subTitle3 === '' || this.props.subTitle3 === null || this.props.subTitle3 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle3}`}>{this.props.subTitle3}</NavLink>
                        </li>
                    }

                    {this.props.subTitle4 === '' || this.props.subTitle4 === null || this.props.subTitle4 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle4}`}>{this.props.subTitle4}</NavLink>
                        </li>
                    }

                    {this.props.subTitle5 === '' || this.props.subTitle5 === null || this.props.subTitle5 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle5}`}>{this.props.subTitle5}</NavLink>
                        </li>
                    }

                    {this.props.subTitle6 === '' || this.props.subTitle6 === null || this.props.subTitle6 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle6}`}>{this.props.subTitle6}</NavLink>
                        </li>
                    }

                    {this.props.subTitle7 === '' || this.props.subTitle7 === null || this.props.subTitle7 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle7}`}>{this.props.subTitle7}</NavLink>
                        </li>
                    }

                    {this.props.subTitle8 === '' || this.props.subTitle8 === null || this.props.subTitle8 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle8}`}>{this.props.subTitle8}</NavLink>
                        </li>
                    }

                    {this.props.subTitle9 === '' || this.props.subTitle9 === null || this.props.subTitle9 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle9}`}>{this.props.subTitle9}</NavLink>
                        </li>
                    }

                    {this.props.subTitle10 === '' || this.props.subTitle10 === null || this.props.subTitle10 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle10}`}>{this.props.subTitle10}</NavLink>
                        </li>
                    }

                    {this.props.subTitle11 === '' || this.props.subTitle11 === null || this.props.subTitle11 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle11}`}>{this.props.subTitle11}</NavLink>
                        </li>
                    }

                    {this.props.subTitle12 === '' || this.props.subTitle12 === null || this.props.subTitle12 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle12}`}>{this.props.subTitle12}</NavLink>
                        </li>
                    }

                    {this.props.subTitle13 === '' || this.props.subTitle13 === null || this.props.subTitle13 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle13}`}>{this.props.subTitle13}</NavLink>
                        </li>
                    }

                    {this.props.subTitle14 === '' || this.props.subTitle14 === null || this.props.subTitle14 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle14}`}>{this.props.subTitle14}</NavLink>
                        </li>
                    }

                    {this.props.subTitle15 === '' || this.props.subTitle15 === null || this.props.subTitle15 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle15}`}>{this.props.subTitle15}</NavLink>
                        </li>
                    }

                    {this.props.subTitle16 === '' || this.props.subTitle16 === null || this.props.subTitle16 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle16}`}>{this.props.subTitle16}</NavLink>
                        </li>
                    }

                    {this.props.subTitle17 === '' || this.props.subTitle17 === null || this.props.subTitle17 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle17}`}>{this.props.subTitle17}</NavLink>
                        </li>
                    }

                    {this.props.subTitle18 === '' || this.props.subTitle18 === null || this.props.subTitle18 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle18}`}>{this.props.subTitle18}</NavLink>
                        </li>
                    }

                    {this.props.subTitle19 === '' || this.props.subTitle19 === null || this.props.subTitle19 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle19}`}>{this.props.subTitle19}</NavLink>
                        </li>
                    }

                    {this.props.subTitle20 === '' || this.props.subTitle20 === null || this.props.subTitle20 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle20}`}>{this.props.subTitle20}</NavLink>
                        </li>
                    }

                    {this.props.subTitle21 === '' || this.props.subTitle21 === null || this.props.subTitle21 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle21}`}>{this.props.subTitle21}</NavLink>
                        </li>
                    }

                    {this.props.subTitle22 === '' || this.props.subTitle22 === null || this.props.subTitle22 === undefined ?
                        null
                        : <li>
                            <NavLink to={`/${this.props.title}/${this.props.subTitle22}`}>{this.props.subTitle22}</NavLink>
                        </li>
                    }

                </ul>
            </li>
        )
    }
}

SubTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle1: PropTypes.string,
    subTitle2: PropTypes.string,
    subTitle3: PropTypes.string,
    subTitle4: PropTypes.string
}
