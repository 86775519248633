import _ from "lodash";
import React from "react";
import "../../components/print.css"
import DateNumberFormat from "../../utils/DateNumberFromat";

class ItemListPrint extends React.Component {

    render() {
        const {
            newSuppliers, newItems, restockedItems, promotionsAndDiscounts, allItems, itemReportHeaderText, itemListMsg
        } = this.props;


        let itemReportHeaderTextVal = "";
        let newSupplierRowCol = "#888d936e";
        let newSupplierEvenCol = "#888d936e";
        let newSupplierOddCol = "#ffffff";
        let newSupplierSpaceCol = "#ffffff";

        let newItemRowCol = "#ffffff";
        let newItemEvenCol = "#888d936e";
        let newItemOddCol = "#ffffff";
        let newItemSpaceCol = "#ffffff";

        let newPromoRowCol = "#ffffff";
        let newPromoEvenCol = "#888d936e";
        let newPromoOddCol = "#ffffff";
        let newPromoSpaceCol = "#ffffff";

        let newAllEvenCol = "#888d936e";
        let newAllOddCol = "#ffffff";
        let headerRowSpan = 1;
        let lineNo = 0;
        let promotionTag = ''
        var allItemsList = [];
        let today = new Date();
        let todayText = today.toISOString().split('T')[0];
        if (itemReportHeaderText != null && itemReportHeaderText.length > 0) {
            itemReportHeaderTextVal = itemReportHeaderText[0].message;
        }

        // filter allItems
        let currentProduct = "";
        let filteredAllItems = [];
        let filteredPromotionsAndDiscounts = [];
        let index = 0;
        if (allItems != null && allItems.length > 0) {
            allItems.forEach((item, arrayIndex, array) => {
                if (item?.brand?.active && item.items?.status === "Active" && item.categories?.status === "Active") {
                    if (currentProduct !== item.categories._id && item.categories._id !== '') {
                        // add product line
                        filteredAllItems.push({ isProduct: true, brand: item.brand, items: { name: item.categories.name, desc: item.categories.desc } });
                        index++;

                        currentProduct = item.categories._id;
                    }
                    let descArr = item.items.desc.split("-");
                    if (descArr.length > 1) {
                        let arr = descArr.splice(-1, 1)
                    }
                    filteredAllItems.push({ ...item, items: { ...item.items, desc: descArr.join(' - ') } });
                    index++;

                }
            });
        }
        // console.log('filtered', filteredAllItems);

        // Filter promotions and discounts
        if (promotionsAndDiscounts != null && promotionsAndDiscounts.length > 0) {
            let sortPromotions = _.sortBy(promotionsAndDiscounts, ['items.itemTag'])
            sortPromotions.forEach((item, arrayIndex, array) => {
                // if (item.items != null && item.items !== undefined && (item.items.originalPrice > item.items.price) && item.items.discountPercentage > 0) {
                //     let newItem = item;
                //     newItem.items.price = (newItem.items.price - newItem.items.price * newItem.items.discountPercentage / 100);
                //     newItem.items.discountPercentage = 0;
                //     filteredPromotionsAndDiscounts.push(newItem);
                // } else 

                if (item.items != null && item.items !== undefined && ((item.items.originalPrice > item.items.price) || item.items.discountPercentage > 0)) {

                    if (promotionTag !== item?.items?.itemTag) {
                        promotionTag = item?.items?.itemTag
                        filteredPromotionsAndDiscounts.push({ isTag: true, tag: (promotionTag === '' || promotionTag === undefined) ? 'WEEKLY OFFERS' : promotionTag })
                    }
                    filteredPromotionsAndDiscounts.push(item);
                } else if (item.seasonaldiscounts != null && item.seasonaldiscounts !== undefined) {
                    filteredPromotionsAndDiscounts.push(item);
                }
            });
        }

        if (newSuppliers != null && newSuppliers.length > 0) {
            lineNo += 1;
            lineNo += newSuppliers.length;
            lineNo += 1;

            if (lineNo % 2 !== 0) {
                newSupplierSpaceCol = "#ffffff";
                // newSupplierSpaceCol = "#888d936e";
            }
        }

        if (newItems != null && newItems.length > 0) {
            lineNo += 1;
            if (lineNo % 2 !== 0) {
                newItemRowCol = "#ffffff";
            }
            lineNo += 1;

            if (lineNo % 2 !== 0) {
                newItemEvenCol = "#ffffff";
                newItemOddCol = "#ffffff";
            } else {
                newItemEvenCol = "#ffffff";
                newItemOddCol = "#ffffff";
            }

            lineNo += newItems.length;

            if (lineNo % 2 !== 0) {
                newItemSpaceCol = "#ffffff";
            }
        }

        if (filteredPromotionsAndDiscounts != null && filteredPromotionsAndDiscounts.length > 0) {
            lineNo += 1;
            if (lineNo % 2 !== 0) {
                newPromoRowCol = "#ffffff";
            }
            lineNo += 1;

            if (lineNo % 2 !== 0) {
                newPromoEvenCol = "#ffffff";
                newPromoOddCol = "#ffffff";
            } else {
                newPromoEvenCol = "#ffffff";
                newPromoOddCol = "#ffffff";
            }
            lineNo += filteredPromotionsAndDiscounts.length;

            if (lineNo % 2 !== 0) {
                newPromoSpaceCol = "#ffffff";
            }
        }

        headerRowSpan = lineNo;
        lineNo += 1;

        if (lineNo % 2 !== 0) {
            newAllEvenCol = "#ffffff";
            newAllOddCol = "#ffffff";
        } else {
            newAllEvenCol = "#ffffff";
            newAllOddCol = "#ffffff";
        }
        let currentCatergory = "";
        let itemsInCatergory = 1;
        index = 0;
        let catergoryIndex = 0;

        if (filteredAllItems != null && filteredAllItems.length > 0) {
            filteredAllItems.forEach((item, arrayIndex, array) => {
                if (item.brand != null && item.brand !== undefined) {

                    if (currentCatergory !== item.brand._id) {

                        if (currentCatergory !== "") {
                            // add empty item
                            allItemsList.push({ isEmpty: true });
                            index++;
                        }
                        currentCatergory = item.brand._id;
                        item.image = item.brand.image
                        item.noOfItems = 0;
                        item.isEmpty = false;

                        if (allItemsList[catergoryIndex] != null && allItemsList[catergoryIndex] !== undefined) {
                            allItemsList[catergoryIndex].noOfItems = itemsInCatergory;
                        }
                        itemsInCatergory = 1;
                        catergoryIndex = index;
                    } else if (filteredAllItems.length === arrayIndex + 1) {
                        itemsInCatergory += 1;
                        item.noOfItems = 0;
                        item.isEmpty = false;
                        allItemsList[catergoryIndex].noOfItems = itemsInCatergory;
                    } else {
                        itemsInCatergory += 1;
                        item.noOfItems = 0;
                        item.isEmpty = false;
                    }

                    allItemsList.push(item);
                    index++;
                }
            });

        }

        lineNo += allItems.length;
        var itemMsg = itemListMsg?.value?.split('\\n')

        return (
            <>
                <body style={{ fontFamily: 'raleway', backgroundColor: '#ffffff' }}>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center", fontSize: 18, fontWeight: "bold", backgroundColor: "#ffffff" }}>
                                    <img src={`${window.location.origin}/images/logo/gusta.png`} style={{ width: '118px' }} alt="GUSTA" />
                                </td>
                            </tr>
                            <tr >
                                <td style={{ textAlign: "center", fontSize: 18, fontWeight: "bold", backgroundColor: "#ffffff" }} >
                                    GUSTA PRODUCT LIST
                                </td>

                            </tr>
                            {this.props.newSuppliers.length === 0 && this.props.newItems.length === 0 && filteredPromotionsAndDiscounts.length === 0 ?
                                <tr>
                                    <td style={{ textAlign: "center", fontSize: 18, fontWeight: "bold" }} >
                                        {itemReportHeaderTextVal}
                                    </td>

                                </tr>
                                : null
                            }
                        </tbody>
                    </table>
                    <table id='items_list_table' cellPadding="4px" style={{ color: "black" }}>
                        <tbody>
                            {filteredPromotionsAndDiscounts.length > 0 ?
                                <tr style={{ backgroundColor: newPromoRowCol }}>
                                    <td
                                        rowSpan={headerRowSpan - 1}
                                        style={{ borderTop: "1px solid #ffffff", borderLeft: "1px solid #ffffff", width: "15%", fontSize: 18, fontWeight: "bold", backgroundColor: "#ffffff", verticalAlign: 'top' }}
                                    >
                                        {itemReportHeaderTextVal}
                                    </td>

                                    {/* <td colSpan={5} style={{ border: "1px solid #888d936e", width: "37%", fontWeight: "bold", fontSize: 16 }}>
                                        PROMOTIONS AND DISCOUNT ITEMS
                                    </td> */}
                                </tr>
                                : null
                            }

                            {filteredPromotionsAndDiscounts.length > 0 ? filteredPromotionsAndDiscounts.map((value, index, total) => {
                                let discountedValue = value?.items?.price * ((100 - value?.items?.discountPercentage) / 100)
                                let discountText = (value?.items?.originalPrice - value?.items?.price) > 0 ? `${value?.items?.price}${value?.items?.priceUnit} insted of ${value?.items?.originalPrice}${value?.items?.priceUnit}` : `${value?.items?.discountPercentage}% Off (${discountedValue}${value?.items?.priceUnit} insted of ${value?.items?.price}${value?.items?.priceUnit})`
                                return (<>
                                    {value?.isTag ?
                                        <tr>
                                            <td colSpan={5} style={{ border: "1px solid #888d936e", width: "37%", fontWeight: "bold", fontSize: 16 }}>
                                                {value?.tag}
                                            </td>
                                        </tr>
                                        : <tr style={{ backgroundColor: (index % 2) ? newAllOddCol : newAllEvenCol }} >
                                            <td style={{ border: "1px solid #888d936e", width: "37%", fontSize: 14 }}>
                                                {value?.seasonaldiscounts?.desc !== '' ? value?.items?.desc : value?.items?.name} - <b>{discountText}</b>
                                            </td>
                                            <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                                {value.seasonaldiscounts != null && value.seasonaldiscounts !== undefined ? null : value.items.itemCode}
                                            </td>
                                            <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                                {value.seasonaldiscounts != null && value.seasonaldiscounts !== undefined ? null : (value.items.discountPercentage > 0 ? discountedValue + value.items.priceUnit : value.items.price + value.items.priceUnit)}
                                            </td>
                                            <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                                {value?.measurement?.note}
                                            </td>

                                        </tr>}
                                </>
                                );
                            }) : null}

                            {filteredPromotionsAndDiscounts.length > 0 && (newSuppliers.length > 0 || newItems.length > 0 || restockedItems.length > 0) ?
                                < tr >
                                    {/* <td></td> */}
                                    <td colSpan={4}
                                        style={{ border: "1px solid #888d936e", width: "37%", fontSize: 14, color: newSupplierSpaceCol, height: '30px' }}>

                                    </td>
                                </tr> : null
                            }

                            {this.props.newSuppliers.length > 0 ?
                                <tr>
                                    {filteredPromotionsAndDiscounts.length === 0 ?
                                        <td rowSpan={headerRowSpan} style={{ borderTop: "1px solid #ffffff", borderLeft: "1px solid #ffffff", width: "20%", fontSize: 18, fontWeight: "bold", backgroundColor: "#ffffff", verticalAlign: 'top' }} >
                                            {itemReportHeaderTextVal}
                                        </td>
                                        : null}
                                    <td style={{ border: "1px solid #888d936e", width: "37%", fontWeight: "bold", fontSize: 16 }}
                                        colSpan={4}
                                    >NEW SUPPLIER</td>
                                </tr>
                                : null
                            }
                            {this.props.newSuppliers.length > 0 ? this.props.newSuppliers.map((value, index, total) => {
                                return (
                                    <tr style={(index % 2) ? { backgroundColor: newSupplierEvenCol } : { backgroundColor: newSupplierOddCol }}>
                                        <td
                                            style={{ border: "1px solid #888d936e", width: "37%", fontSize: 14 }}
                                            colSpan={4}
                                        >
                                            {value.name}
                                        </td>
                                    </tr>
                                );
                            }) : null}

                            {this.props.newSuppliers.length > 0 ?
                                < tr style={{ backgroundColor: newSupplierSpaceCol }}>
                                    <td
                                        style={{ border: "1px solid #888d936e", width: "37%", fontSize: 14, color: newSupplierSpaceCol, height: '30px' }}
                                        colSpan={4}
                                    >

                                    </td>
                                </tr> : null
                            }

                            {this.props.newItems.length > 0 ?
                                <tr
                                // style={{ backgroundColor: newItemRowCol }}
                                >
                                    {newSuppliers.length === 0 && filteredPromotionsAndDiscounts.length === 0 ?
                                        <td rowSpan={headerRowSpan} style={{ borderTop: "1px solid #ffffff", borderLeft: "1px solid #ffffff", width: "15%", fontSize: 18, fontWeight: "bold", backgroundColor: "#ffffff", verticalAlign: 'top' }}>
                                            {itemReportHeaderTextVal}
                                        </td>
                                        : null
                                    }

                                    <td style={{ border: "1px solid #888d936e", width: "37%", fontWeight: "bold", fontSize: 16 }}
                                        colSpan={4}
                                    >NEW ITEMS</td>
                                </tr>
                                : null
                            }

                            {this.props.newItems.length > 0 ? this.props.newItems.map((value, index, total) => {
                                return (
                                    <tr>
                                        <td style={{ border: "1px solid #888d936e", width: "37%", fontSize: 14 }}>
                                            {value.items.name}
                                        </td>
                                        <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                            {value.items.price}{value.items.priceUnit}
                                        </td>
                                        <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                            {value.items.itemCode}
                                        </td>
                                        <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                            {value?.measurement?.note}
                                        </td>
                                    </tr>
                                );
                            }) : null}

                            {this.props.newItems.length > 0 ?
                                < tr style={{ backgroundColor: newItemSpaceCol }}>
                                    <td></td>
                                    <td style={{ border: "1px solid #888d936e", width: "37%", fontSize: 14, color: newSupplierSpaceCol, height: '30px' }} colSpan={4}>
                                    </td>
                                </tr>
                                : null
                            }

                            {restockedItems.length > 0 ?
                                <tr>
                                    {newSuppliers.length === 0 && filteredPromotionsAndDiscounts.length === 0 ?
                                        <td rowSpan={headerRowSpan} style={{ borderTop: "1px solid #ffffff", borderLeft: "1px solid #ffffff", width: "15%", fontSize: 18, fontWeight: "bold", backgroundColor: "#ffffff", verticalAlign: 'top' }}>
                                            {itemReportHeaderTextVal}
                                        </td>
                                        : <td></td>
                                    }

                                    <td style={{ border: "1px solid #888d936e", width: "37%", fontWeight: "bold", fontSize: 16 }}
                                        colSpan={4}
                                    >RESTOCKED ITEMS</td>
                                </tr>
                                : null
                            }

                            {restockedItems.length > 0 ? restockedItems.map((value, index) => {
                                return (
                                    <tr>
                                        <td></td>
                                        <td style={{ border: "1px solid #888d936e", width: "37%", fontSize: 14 }}>
                                            {value.items.name}
                                        </td>
                                        <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                            {value.items.price}{value.items.priceUnit}
                                        </td>
                                        <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                            {value.items.itemCode}
                                        </td>
                                        <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                            {value?.measurement?.note}
                                        </td>
                                    </tr>
                                );
                            }) : null}

                            {filteredPromotionsAndDiscounts.length > 0 || newSuppliers.length > 0 || newItems.length > 0 || restockedItems.length > 0 ?
                                <tr>
                                    <td colSpan={5}
                                        style={{ border: "1px solid #888d936e", width: "37%", fontSize: 14, color: newSupplierSpaceCol, height: '30px' }}>

                                    </td>
                                </tr>
                                : null
                            }

                            {allItemsList.length > 0 ? allItemsList?.map((value, index) => {
                                // console.log(allItemsList);
                                let discountedValue = value?.items?.price * ((100 - value?.items?.discountPercentage) / 100)
                                let discountText = (value.items?.originalPrice - value?.items?.price) > 0 ? `${value?.items?.originalPrice}${value?.items?.priceUnit} insted of ${value?.items?.price}${value?.items?.priceUnit}` : `${value?.items?.discountPercentage}% Off (${discountedValue}${value?.items?.priceUnit} insted of ${value?.items?.price}${value?.items?.priceUnit})`

                                return (
                                    <tr style={{ backgroundColor: (index % 2) ? newAllOddCol : newAllEvenCol }} >
                                        {value.noOfItems > 0 ?
                                            // brand image
                                            <td key={`${value.items._id}`} rowSpan={value.noOfItems}
                                                style={{ border: "1px solid #888d936e", width: "15%", verticalAlign: 'top', textAlign: 'center', backgroundColor: "#ffffff" }}
                                            >
                                                {value.image != null && value.image !== undefined && value.image !== '' ? <img alt={`brand_image`} src={value.image.url} width="150" /> : null}
                                            </td>
                                            : null}

                                        {/* empty row for devied to brands */}
                                        {value.isEmpty ?
                                            <td colSpan="5" style={{ border: "1px solid #888d936e" }}></td>
                                            : null}

                                        {!value.isEmpty && !value.isProduct ?
                                            <td style={{ border: "1px solid #888d936e", width: "50%", fontSize: 14 }}>
                                                {/* {value.items.desc !== "" && value.items.desc !== undefined ?
                                                    value.items.desc
                                                    : value.items.name + " " + (value.items.originalPrice !== undefined && (value.items.originalPrice - value.items.price) > 0 ?
                                                        " - Normal Price " + value.items.originalPrice + value.items.priceUnit
                                                        : value.items.discountPercentage > 0 ?
                                                            value.items.discountPercentage + "% - Normal Price " + value.items.price + value.items.priceUnit
                                                            : "")
                                                } */}
                                                {/* /{value.brand.name} */}
                                                {value?.seasonaldiscounts?.desc !== '' ? value?.items?.desc : value?.items?.name}
                                            </td>
                                            : !value.isEmpty && value.isProduct && value?.items?.desc?.trim() !== "" && value?.items?.name?.trim() !== "" ?
                                                <td colSpan="4" style={{ border: "1px solid #888d936e", width: "50%", fontWeight: "bold", fontSize: 18, textTransform: 'uppercase' }}>
                                                    {value.items.desc !== null && value.items.desc !== undefined && value.items.desc.trim() !== "" ? `${value.items.desc}` : `${value.items.name}`}
                                                </td>
                                                : null}

                                        {!value.isEmpty && !value.isProduct ?
                                            <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                                {value.items.itemCode}
                                            </td>
                                            : null}

                                        {!value.isEmpty && !value.isProduct ?
                                            <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                                {value.items.discountPercentage > 0 ? (value.items.price * ((100 - value.items.discountPercentage) / 100)) : value.items.price}{value.items.priceUnit}
                                            </td>
                                            : null}


                                        {/* {!value.isEmpty && !value.isProduct ?
                                            <td style={{ border: "1px solid #5b9bd5", width: "23%", fontSize: 14, textAlign: "left" }}>
                                                {value.deliverytypes != null && value.deliverytypes !== undefined && value.deliverytypes.weight > 0 ? (value.deliverytypes.weight + " " + value.deliverytypes.weightUnit) :
                                                    value.deliverytypes != null && value.deliverytypes !== undefined && value.deliverytypes.desc != null && value.deliverytypes.desc !== undefined ? value.deliverytypes.desc : null
                                                }
                                            </td>
                                            : null} */}

                                        {!value.isEmpty && !value.isProduct ?
                                            <td style={{ border: "1px solid #888d936e", width: "10%", fontSize: 14, textAlign: "right" }}>
                                                {value?.measurement?.note}
                                            </td>
                                            : null}

                                    </tr>
                                );

                            }) : null}

                        </tbody>
                    </table>
                    <p>
                        {itemMsg?.map(ele => <p>{ele}</p>)}
                        Updated At {DateNumberFormat(new Date())}
                    </p>
                </body>

            </>
        );
    }


}

export default ItemListPrint;