/**
 * @file This file defined for button component
 * @author Bhanuka Chathuranga
 */
import React from 'react'
import _ from 'lodash'

/**
 * 
 * @param {Object} obj
 * @param {String} obj.symbol - or +
 * @param {Object} obj.item item object
 * @param {Function} obj.setQty change qty value
 * @param {Array} obj.cart item of object in cart array
 * @param {Function} obj.setCart change cart value
 * @param {Array} obj.itemsData items data array
 * @returns {Component} button component
 */
function Button({ symbol, item, setQty, cart, setCart, itemsData }) {
    return (
        <button className='btn' style={{ backgroundColor: 'white' }}
            onClick={() => {
                if (symbol === '-') {
                    if (item.qty !== 0) {
                        setQty(i => i - 1)
                        item.qty = item.qty - 1
                        if (item.qty === 0) {
                            var removeToCart = _.remove(cart, function (n) {
                                return n._id === item._id
                            })
                            // console.log(removeToCart);
                            setCart(cart)
                        }
                    }
                } else if (symbol === '+') {
                    setQty(i => i + 1)
                    item.qty = item.qty + 1
                    item.discount = (item.fastDiscountValue.length > 0 && item.is_fast_delivery ) ? item.fastDiscountValue[0] : item.discountPercentage
                    var addToCart = _.uniqBy([...cart, item], '_id')
                    setCart(addToCart)
                    setQty(0)
                }
                itemsData.forEach(element => {
                    if (element._id === item._id) {
                        element.qty = item.qty
                    }
                });
            }}>{symbol === '+' ? <i className="fa fa-plus" aria-hidden="true"></i> : <i className="fa fa-minus" aria-hidden="true"></i>}</button>
    )
}

export default Button
