/**
 * @author Umesh Ranthilina
 */

import React from "react";
import { connect } from 'react-redux';

import Table from "react-table";
import "react-table/react-table.css";
import _ from 'lodash';
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";

import 'react-table-hoc-fixed-columns/lib/styles.css'
import GridFilter from '../../components/GridFilter';
import { DateNumberFormat } from '../../utils';

import Permission from '../../utils/Permission';
import { getErrorLog, updateErrorLog, getErrorLogHistory } from '../../../actions/Basic_data/error_log_action';
import { PriceFormat } from '../../utils';

const ErrorLogHistoryTable = ({ column, props, data, tab, orderColumn }) => {
    return (
        <FormProvider
            form="inline"
        >
            {formProps => {
                return (
                    <Table className="-striped"
                        columns={column !== undefined ? column : []}
                        data={tab === 'Invoice' ? props.errorLogHistory.invoice : tab === 'Payment' ? props.errorLogHistory.payment : props.errorLogHistory.pos}
                        defaultPageSize={10}
                        expanded={data.state.expanded}
                        onExpandedChange={(newExpanded, index, event) => data.handleRowExpanded(newExpanded, index)}
                        defaultFilterMethod={data.filterCaseInsensitive}
                        showPageJump={false}
                        SubComponent={row => {
                            { console.log(row) }
                            return (
                                <React.Fragment>
                                    <ErrorBoundary>
                                        <FormProvider
                                            form="inline"
                                        >
                                            {formProps => {
                                                return (
                                                    <Table className="-striped"
                                                        columns={orderColumn !== undefined ? orderColumn : []}
                                                        data={row.original.errorlog.ordersArr}
                                                        defaultPageSize={10}
                                                        defaultFilterMethod={data.filterCaseInsensitive}
                                                        showPageJump={false}
                                                    />
                                                )
                                            }}

                                        </FormProvider>
                                    </ErrorBoundary>
                                </React.Fragment>
                            );
                        }}
                    />
                );
            }}
        </FormProvider>
    )
}

const ErrorLogTable = ({ column, props, data, tab, orderColumn }) => {
    return (
        <FormProvider
            form="inline"
        >
            {formProps => {
                return (
                    <Table className="-striped"
                        columns={column !== undefined ? column : []}
                        data={tab === 'Invoice' ? props.errorLog.invoice : tab === 'Payment' ? props.errorLog.payment : props.errorLog.pos}
                        defaultPageSize={10}
                        expanded={data.state.expanded}
                        onExpandedChange={(newExpanded, index, event) => data.handleRowExpanded(newExpanded, index)}
                        defaultFilterMethod={data.filterCaseInsensitive}
                        showPageJump={false}
                        SubComponent={row => {
                            { console.log(row) }
                            return (
                                <React.Fragment>
                                    <ErrorBoundary>
                                        <FormProvider
                                            form="inline"
                                        >
                                            {formProps => {
                                                return (
                                                    <Table className="-striped"
                                                        columns={orderColumn !== undefined ? orderColumn : []}
                                                        data={row.original.errorlog.ordersArr}
                                                        defaultPageSize={10}
                                                        defaultFilterMethod={data.filterCaseInsensitive}
                                                        showPageJump={false}
                                                    />
                                                )
                                            }}

                                        </FormProvider>
                                    </ErrorBoundary>
                                </React.Fragment>
                            );
                        }}
                    />
                );
            }}
        </FormProvider>
    )
}

class ErrorHandling extends React.Component {

    state = {
        editing: null,
        deleting: null,
        active: false,
        expanded: {},
    };

    columns = [
        { Header: "Type", accessor: "errorlog.type", ...GridFilter },
        { Header: "Error Message", accessor: "errorlog.errorMessage", ...GridFilter },
        {
            Header: "Invoice Date", accessor: "errorlog.inovoice_date", ...GridFilter,
            Cell: props => {
                if (props.original?.errorlog?.inovoice_date !== undefined) {
                    var today = new Date(props.original?.errorlog?.inovoice_date)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.errorlog?.inovoice_date;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Due Date", accessor: "errorlog.due_date", ...GridFilter,
            Cell: props => {
                if (props.original?.errorlog?.due_date !== undefined) {
                    var today = new Date(props.original?.errorlog?.due_date)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.errorlog?.due_date;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Created Error Date", accessor: "errorlog.createdAt", ...GridFilter,
            Cell: props => {
                if (props.original?.errorlog?.createdAt !== undefined) {
                    var today = new Date(props.original?.errorlog?.createdAt)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.errorlog?.createdAt;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Total Price", accessor: "errorlog.total",
            ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.errorlog.total)}
                </div>
        },
        {
            Header: "Send To Odoo",
            Cell: row =>
                <>
                    <button
                        disabled={Permission('BASIC DATA', 'ERROR LOG', 'ADD', this.props)}
                        hidden={this.state.active == false ? false : true}
                        className='btn btn-primary'
                        style={{ "float": "center" }}
                        onClick={() => {
                            if (window.confirm('Are you sure you wish to confirm this order?')) {
                                row.original.user = this.props.userId
                                const updateData = {
                                    data: row.original,
                                }

                                this.props.updateErrorLog(updateData, this.props.token);
                            }
                        }}
                    >Send To Odoo</button>
                </>
        },
    ];

    historyColumns = [
        { Header: "Type", accessor: "errorlog.type", ...GridFilter },
        { Header: "Error Message", accessor: "errorlog.errorMessage", ...GridFilter },
        {
            Header: "Invoice Date", accessor: "errorlog.inovoice_date", ...GridFilter,
            Cell: props => {
                if (props.original?.errorlog?.inovoice_date !== undefined) {
                    var today = new Date(props.original?.errorlog?.inovoice_date)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.errorlog?.inovoice_date;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Due Date", accessor: "errorlog.due_date", ...GridFilter,
            Cell: props => {
                if (props.original?.errorlog?.due_date !== undefined) {
                    var today = new Date(props.original?.errorlog?.due_date)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.errorlog?.due_date;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Created Error Date", accessor: "errorlog.createdAt", ...GridFilter,
            Cell: props => {
                if (props.original?.errorlog?.createdAt !== undefined) {
                    var today = new Date(props.original?.errorlog?.createdAt)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.errorlog?.createdAt;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Total Price", accessor: "errorlog.total",
            ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.errorlog.total)}
                </div>
        },
        {
            Header: "Confirmed Date", accessor: "errorlog.confirmedDate", ...GridFilter,
            Cell: props => {
                if (props.original?.errorlog?.confirmedDate !== undefined) {
                    var today = new Date(props.original?.errorlog?.confirmedDate)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.errorlog?.confirmedDate;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        { Header: "Confirmed User", accessor: "confirmUser.name.first", ...GridFilter }
    ];

    posOrderColumn = [
        {
            Header: "Order Id", accessor: "posorder.orderId", ...GridFilter,
        },
        {
            Header: "Telephone", accessor: "posorder.telephone", ...GridFilter,
        },
        { Header: "Payment Methods", accessor: "posorder.payment_method", ...GridFilter },
        { Header: "Outlet", accessor: "outlet.name", ...GridFilter },
        { Header: "Status", accessor: "posorder.status", ...GridFilter },
        {
            Header: "Amount Paid", accessor: "posorder.amountPaid",
            ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.posorder.amountPaid)}
                </div>
        },
        {
            Header: "Order Discount %", accessor: "posorder.discount",
            ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {Number(props.original.posorder.discount).toFixed(2)}
                </div>
        },
        {
            Header: "Total Price", accessor: "posorder.totalPrice",
            ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.posorder.totalPrice)}
                </div>
        },
    ]

    PaymentColumn = [
        { Header: "Supplier Name", accessor: "supplier.name", ...GridFilter },
        { Header: "Order Id", accessor: "purchaseorder.orderid", ...GridFilter },
        { Header: "Supplier", accessor: "supplier.name", ...GridFilter },
        {
            Header: "Order Date", id: "purchaseorder.orderdate", accessor: data => DateNumberFormat(data.purchaseorder.orderdate),
            ...GridFilter
        },
        {
            Header: "Paid", accessor: "purchaseorder.paid",
            ...GridFilter,
            Cell: row => {
                return (
                    row.original.purchaseorder.paid !== true ? 'No' : 'Yes'
                )
            }
        },
        {
            Header: "Payment Value",
            accessor: "purchaseorder.paymentvalue",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "right"
                    },
                };
            },
        },
        { Header: "Payment Date", accessor: "purchaseorder.payment_date", filterable: true },
        {
            Header: "Total", accessor: "purchaseorder.total",
            ...GridFilter,
            Cell: row => {
                return (
                    <div style={{ textAlign: "right" }}>{PriceFormat((Number(row.original.total)))}</div>)
            }
        },
        { Header: "Warehouse", accessor: "deliverywarehouse.name" },
    ];


    invoiceColumn = [
        { Header: "Order Id", accessor: "orders.orderId", ...GridFilter },
        { Header: "Customer", accessor: "orders.name", ...GridFilter },
        { Header: "Address", accessor: "orders.address", ...GridFilter },
        { Header: "Region", accessor: "region.name", ...GridFilter },
        { Header: "Rider", accessor: "deliveryPerson.name", ...GridFilter },
        { Header: "Order Status", accessor: "orders.status", ...GridFilter },
        { Header: "Delivery Status", accessor: "orders.deliveryStatus", ...GridFilter },
        { Header: "Payment Method", accessor: "paymentmethods.name", ...GridFilter },
        {
            Header: "Delivery Charges", accessor: "orders.deliveryCharges",
            ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {(props.original.orders.deliveryCharges)}
                </div>
        },
        { Header: "Pick Up", accessor: "pickup", ...GridFilter },
        {
            Header: "Delivered Date/Time", accessor: "orders.deliveryDate", ...GridFilter,
            Cell: props => {
                { console.log(props) }
                if (props.original?.orders?.deliveryDate !== undefined) {
                    var today = new Date(props.original?.orders?.deliveryDate)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.orders?.deliveryDate;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Total Price", accessor: "orders.totalPrice", show: true,
            ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.orders.totalPrice)}
                </div>
        }
    ]
    componentDidMount() {
        this.props.getErrorLog(this.props)
        this.props.getErrorLogHistory(this.props)
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    reload = () => {
        this.props.getErrorLog(this.props)
        this.props.getErrorLogHistory(this.props)
    }
    
    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <div style={{ float: 'left' }}>
                            <input type='radio' name='history' style={{ marginRight: '5px' }} checked={this.state.active ? false : true} onChange={e => this.setState({ active: false })} /><label>Active</label>
                            <input type='radio' name='history' style={{ marginLeft: '30px', marginRight: '5px' }} onChange={e => { this.setState({ active: true }) }} /><label>History</label>
                        </div>
                        <div style={{ float: 'right' }} >
                            <button className="btn btn-primary" onClick={() => this.reload()}>
                                <i className="fas fa-sync"></i>
                            </button>
                        </div>

                    </div>
                    <div className="card-body">
                        {this.state.active === false ?
                            <div>
                                <ul className="nav nav-tabs">
                                    <li className="active"><a data-toggle="tab" href="#invoice" style={{ fontSize: '20px' }} >{'Invoice (' + (this.props.errorLog.invoiceTotal || 0) + ')'}</a></li>
                                    <li><a data-toggle="tab" href="#payment" style={{ fontSize: '20px' }}  >{' Payment (' + (this.props.errorLog.paymentTotal || 0) + ')'}</a></li>
                                    <li><a data-toggle="tab" href="#pos" style={{ fontSize: '20px' }}  >{' POS (' + (this.props.errorLog.posTotal || 0) + ')'}</a></li>
                                </ul>
                                <div className="tab-content">
                                    <div id="invoice" className="tab-pane fade in active show"> {/* Add 'show' class */}
                                        <ErrorLogTable column={this.columns} props={this.props} data={this} tab={"Invoice"} orderColumn={this.invoiceColumn} />
                                    </div>
                                    <div id="payment" className="tab-pane fade">
                                        <ErrorLogTable column={this.columns} props={this.props} data={this} tab={"Payment"} orderColumn={this.PaymentColumn} />
                                    </div>
                                    <div id="pos" className="tab-pane fade">
                                        <ErrorLogTable column={this.columns} props={this.props} data={this} tab={"POS"} orderColumn={this.posOrderColumn} />
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <ul className="nav nav-tabs">
                                    <li className="active"><a data-toggle="tab" href="#invoiceHistory" style={{ fontSize: '20px' }} >Invoice</a></li>
                                    <li><a data-toggle="tab" href="#paymentHistory" style={{ fontSize: '20px' }} >Payment</a></li>
                                    <li><a data-toggle="tab" href="#posHistory" style={{ fontSize: '20px' }} >POS</a></li>
                                </ul>
                                <div className="tab-content">
                                    <div id="invoiceHistory" className="tab-pane fade in active show"> {/* Add 'show' class */}
                                        <ErrorLogHistoryTable column={this.historyColumns} props={this.props} data={this} tab={"Invoice"} orderColumn={this.invoiceColumn} />
                                    </div>
                                    <div id="paymentHistory" className="tab-pane fade">
                                        <ErrorLogHistoryTable column={this.historyColumns} props={this.props} data={this} tab={"Payment"} orderColumn={this.PaymentColumn} />
                                    </div>
                                    <div id="posHistory" className="tab-pane fade">
                                        <ErrorLogHistoryTable column={this.historyColumns} props={this.props} data={this} tab={"POS"} orderColumn={this.posOrderColumn} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="card-footer"></div>
                </div >
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        isLoading: state.rLogin.loading,
        errorLog: state.rErrorLog.errorLog,
        userId: state.rLogin.id,
        errorLogHistory: state.rErrorLog.errorLogHistory,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getErrorLog: props => { dispatch(getErrorLog(props)) },
        updateErrorLog: (data, token) => { dispatch(updateErrorLog(data, token)) },
        getErrorLogHistory: props => { dispatch(getErrorLogHistory(props)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandling);


