import React, { useState, useEffect } from 'react'
import Table from "react-table";

/**
 * 
 * @param {Array} columns columns data
 * @param {Function} getData function for get data 
 * @returns 
 */
const TableViewer = ({ columns, getData, props }) => {

    const [data, setData] = useState([])

    useEffect(() => {
        getData(setData, props)
    }, [])

    return (
        <div className="right_col" role="main">
            <div className='card'>
                <div className='card-body'>
                    <Table className="-striped"
                        columns={columns}
                        data={data}
                    />
                </div>
            </div>
        </div>
    )
}

export default TableViewer
