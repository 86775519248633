/**
 * @type {Object}
 * @property {array}  data  Stock data
 */
const initialState = {
    data: [],
    itemData: [],
    items: [],
    supplierData: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const stockReducer = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_PRODUCTS':
            var { data } = action;
            newState.data = data;
            break;

        case 'FETCH_PRODUCT_ITEMS':
            var { data } = action;
            newState.itemData = data;
            break;

        case 'FETCH_ITEMS':
            var { data } = action;

            data.map(item => {
                item['label'] = item.name + ' - ' + item.itemCode;
                item['value'] = item._id;
            })
            newState.items = data;
            break;

        case 'FETCH_SUPPLIER_DATA':
            var { data } = action;
            newState.supplierData = data;
            break;

        default:
            break;
    }
    return newState;
}

export default stockReducer;