import React, { useState, useEffect } from 'react'
import Table from "react-table";
import ErrorBoundary from '../../components/ErrorBoundary';
import axios from 'axios';
import Select from 'react-select';
import { Field } from "redux-form";
import ActionsCell from '../../components/ActionCell';
import { Permission } from '../../utils';
import GridFilters from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import * as BS from "react-bootstrap";
import _ from 'lodash';
import { toast } from 'react-toastify';
import FormProvider from '../../components/FormProvider';
import $ from 'jquery';

const ReplacementModalPO = ({ itemData, purchaseItem, currentOrderItems, proceed, hideReplcementModel, setPurchaseItemForReplacement,  purchaseItemForReplacement, props }) => {

    
    const [editingReplacement, setEditingReplacement] = useState(null)
    const [removeReplacement, setRemoveReplacement] = useState(null)

    const getActionPropsReplacementPO = (gridState, rowProps) =>
        (rowProps && {
            mode: editingReplacement === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditingReplacement(rowProps.original),
                onCancel: () => setEditingReplacement(null),
                onDelete: () => setRemoveReplacement(rowProps.original)
            },
            updPerm: Permission('ORDERS', 'ORDER', 'UPDATE', props),
            delPerm: Permission('ORDERS', 'ORDER', 'DELETE', props)
        }) ||
        {};

    const editableNumberComponentRep = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component type="number"  {...input} {...rest} min={0} children={children} style={{ maxHeight: edit ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    };

    const editableNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const edit = editingReplacement === props.original;
            const fieldProps = {
                component: editableNumberComponentRep,
                edit,
                props
            };
            return (props.original.orders !== undefined && (props.column.Header !== 'Amount Paid' && props.column.Header !== 'Delivery Charges') && props.original.orders.amountPaid !== 0) ?
                <div style={{ textAlign: 'right' }}>{props.value}</div>
                : <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const orderItemsReplacementColumns = [
        { Header: "Name", accessor: "name", filterable: true },
        { Header: "Item Code", accessor: "itemCode", filterable: true },
        {
            Header: "Price", accessor: "price", ...editableNumberColumnProps
        },
        {
            Header: "Duration", accessor: "duration", filterable: true,
            Cell: props =>
                <div>
                    {props.original.duration} {props.original.durationUnit}
                </div>
        },
        { Header: 'Qty', accessor: "qty", ...editableNumberColumnProps },
        { Header: 'Discount %', accessor: "discount", ...editableNumberColumnProps },
        {
            Header: "",
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionPropsReplacementPO,
            Cell: ActionsCell
        },
    ]

    const handleOrderItemForReplacementSubmit = (values) => {
        console.log('in POOO', values)
        if (editingReplacement !== null) {

            let data = _.filter(purchaseItemForReplacement, { '_id': values._id })
            data[0].price = Number(values.price)
            data[0].qty = Number(values.qty)
            data[0].discount = Number(values.discount)


        } else if (removeReplacement !== null) {
            _.remove(purchaseItemForReplacement, n => { return n._id === values._id })
        }
    }

    const proceedHere = () => {
        let currentOrderIds = []

        currentOrderItems.map(cOItem => {
            currentOrderIds.push(cOItem.orders._id)
        })

        const data = {
            pItemId: purchaseItem._id,
            items: purchaseItemForReplacement,
            orderArr: purchaseItem.orderArr,
            packArr: purchaseItem.packArr
        }

        proceed(data)
    }

    const clear = () => {
        setPurchaseItemForReplacement([])
        hideReplcementModel()
    }

    return (
        <div id='replacementModalPO' key='replacementModalPO' className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg ">
                <div className="modal-content animate ">
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase">Select Order Item</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={e => clear()}>&times;</button>
                    </div>
                    <div className='modal-body'>
                        <Select
                            options={_.filter(itemData, item => { return item._id !== purchaseItem?.item_id })}
                            value={[]}
                            onChange={e => {
                                let addCheckId = _.filter(purchaseItemForReplacement, function (o) {
                                    return o._id === e._id
                                })

                                if (addCheckId.length === 0) {
                                    setPurchaseItemForReplacement([...purchaseItemForReplacement, { ...e, qty: 1, discount: e.discountPercentage === null ? 0 : e.discountPercentage }])
                                } else {
                                    toast.info('Already added this item. Please modify it...!')
                                }
                            }}
                        />

                        {purchaseItemForReplacement.length > 0 ?
                            <ErrorBoundary>
                                <div>
                                    <FormProvider
                                        form="inline"
                                        onSubmit={handleOrderItemForReplacementSubmit}
                                        onSubmitSuccess={() => { setEditingReplacement(null); setRemoveReplacement(null) }}
                                        initialValues={editingReplacement !== null ? editingReplacement : removeReplacement}
                                        enableReinitialize
                                    >
                                        {formProps => {
                                            return (
                                                <form onSubmit={formProps.handleSubmit}>
                                                    <Table
                                                        columns={orderItemsReplacementColumns}
                                                        data={purchaseItemForReplacement.reverse()}
                                                        defaultPageSize={5}
                                                        showPagination={true}
                                                    />
                                                </form>
                                            )
                                        }}
                                    </FormProvider>
                                </div>
                            </ErrorBoundary>
                            : null}
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={e => proceedHere()} >Proceed</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => clear()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReplacementModalPO
