import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";

import ActionsCell from "../../components/ActionCell";
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import { Permission } from '../../utils'
import { getResolveMethodData } from '../../../actions/Basic_data/basic_data_action';
import { addResolveMethod, deleteResolveMethod, updateResolveMethod } from '../../../actions/Basic_data/resolve_method_action';
import AddResolveMethodModel from './Modal/AddResolveMethodModel';

export const ResolveMethods = (props) => {
    const [addResolveMehodData, setAddResolveMehodData] = useState({ method: '', desc: '' })
    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);

    useEffect(() => {
        props.getResolveMethodData(props)
    }, [])

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            // mode: (edit === rowProps.original && rowProps.original.orders.status !== 'Delivered') ? "edit" : "view",
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'RESOLVE METHODS', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'RESOLVE METHODS', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const columns = [
        { Header: "Method", accessor: "method", ...editableColumnProps },
        { Header: "Description", accessor: "desc", ...editableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const inputHandler = ({ target: { value } }, field) => {
        setAddResolveMehodData({ ...addResolveMehodData, [field]: value })
    }

    const addResolveMethodHandler = () => {
        let slug = addResolveMehodData.method.toLowerCase().replaceAll(' ', '-')
        props.addResolveMethodData({ ...addResolveMehodData, slug }, props)
        setAddResolveMehodData({ method: '', desc: '' })
    }

    const handleSubmit = value => {
        console.info(value)
        if (editing !== null) {
            props.updateResolveMethod(value, props)
        } else if (deleting !== null) {
            props.deleteResolveMethod(value, props)
        }
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'RESOLVE METHODS', 'ADD', props)} data-toggle="modal" data-target="#addResolveMethod">
                        + Resolve Method
                    </button>
                </div>
                <div className="card-body">
                    <ErrorBoundary>
                        <FormProvider
                            form="inline"
                            onSubmit={handleSubmit}
                            onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                            initialValues={editing === null ? deleting : editing}
                            enableReinitialize
                        >
                            {formProps => {
                                return (
                                    <form onSubmit={formProps.handleSubmit}>
                                        <Table className="-striped"
                                            columns={columns}
                                            data={props.resolveMethod}
                                            defaultPageSize={10}
                                        />
                                    </form>
                                );
                            }}
                        </FormProvider>
                    </ErrorBoundary>
                </div>
            </div>

            {/* add resolve method */}
            <ErrorBoundary>
                <AddResolveMethodModel addResolveMehodData={addResolveMehodData} inputHandler={inputHandler} addResolveMethodHandler={addResolveMethodHandler} />
            </ErrorBoundary>
        </div>
    )
}

const mapStateToProps = state => ({
    token: state.rLogin.token,
    id: state.rLogin.id,
    isLoading: state.rLogin.loading,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    resolveMethod: state.rBasicData.resolve_method_data,
})

const mapDispatchToProps = dispatch => ({
    getResolveMethodData: props => dispatch(getResolveMethodData(props)),
    addResolveMethodData: (data, props) => dispatch(addResolveMethod(data, props)),
    updateResolveMethod: (data, props) => dispatch(updateResolveMethod(data, props)),
    deleteResolveMethod: (data, props) => dispatch(deleteResolveMethod(data, props))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResolveMethods)
