/**
 * @file This file defines to error log actions
 * @author Umesh Ranthilina
 */

import { toast } from 'react-toastify';
var axios = require('axios');

export const errorLogAsync = data => {
    return { type: 'FETCH_ERROR_LOG', data }
}

export const errorLogHistoryAsync = data => {
    return { type: 'FETCH_ERROR_LOG_HISTORY', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * get error log 
 * 
 * @param {string} token 
 */
export const getErrorLog = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/errorLogs/getErrorLog')
            .then(result => {
                dispatch(errorLogAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get error log history
 * 
 * @param {string} token 
 */
export const getErrorLogHistory = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/errorLogs/getErrorLogHistory')
            .then(result => {
                dispatch(errorLogHistoryAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateErrorLog = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/errorLogs/updateErrorLog', { data })
            .then(result => {
                toast.success('Confirmed Successfully!')
                dispatch(getErrorLog({ token: token }))
                dispatch(getErrorLogHistory({ token: token }))
            }).catch(err => {
                toast.error(`${err.response.data}`)
            })
    }
}
