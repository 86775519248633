/**
 * @file This file defined for skeleton section
 * @author Bhanuka Chathuranga
 */
import React from 'react';
import Skeleton from 'react-loading-skeleton';


function SkeletonLoadingSection({ dataCount }) {
    return (
        <div>
            <Skeleton count={dataCount} height={'60px'} duration={1} />
        </div>
    )
}

export default SkeletonLoadingSection
