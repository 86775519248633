/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const addressesAsync = data => {
    return { type: 'FETCH_ADDRESSES', data }
}

/**
 * @param {*} token 
 */
export const getAddresses = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/administrator/addresses/getAddresses')
            .then(result => {
                dispatch(addressesAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateAddress = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/addresses/updateAddress', { data })
            .then(result => {
                window.$('#addAddress').modal('hide');
                toast.success('Successfully Added / Updated Address!')
                dispatch(getAddresses(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteAddress = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/addresses/deleteAddress', { data })
            .then(result => {
                toast.success('Address Deleted Successfully!')
                dispatch(getAddresses(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addAddress = ({ contactName, email, phone, streetAddress, city, user, zipCode, district, province, isBilling, isDelivery, isPhoneVerified, slug }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/administrator/addresses/addAddress', {
            data: {
                contactName, email, phone, streetAddress, city, user, zipCode, district, province, isBilling, isDelivery, isPhoneVerified, slug
            }
        })
            .then(result => {

                var _id = result.data.insertedId;
                var slug = _id;

                const data = {
                    _id: _id,
                    slug: slug
                }

                dispatch(updateAddress(data, token))

            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}
