import React from "react";
import { connect } from 'react-redux';
import "../../components/paper.css"
import '../../../css/invoice_pdf.css'
// import logo from '../../../images/gusta.png'
// import logo from '../../../../images/logo.png'
import { PriceFormat } from '../../utils'
import axios from 'axios';

class InvoicePrint extends React.Component {

    state = {
        bags: [],
        creditNoteAmount:0
    }

    getBagData = () => {
        axios.defaults.headers.common['authorization'] = this.props.token;
        axios.post('/ordering/getBagData', { id: this.props.order?._id })
            .then(result => {
                // console.log(result)
                this.setState({ bags: result.data })
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }

    componentDidMount() {
        this.getBagData()
    }

    componentDidUpdate(prevProps) {
        // console.log(prevProps)
        if (this.props.order?._id !== prevProps.order?._id) {
            this.getBagData()
        }
        const { creditNoteData } = this.props;
        if (creditNoteData !== prevProps.creditNoteData) {
            this.setState({ creditNoteAmount: creditNoteData })
        }
    }

    render() {
        const {
            order, paymentMethods, orderItemsData, date
        } = this.props;

        var book = [];
        var page = [];
        var currentPage = 0;
        var currentRow = 14; //9
        var noOfLinesInPage = 35; //30
        var noOfLinesInLastPage = 26;
        var noOfCharactersInLine = 40;
        var name = "";
        var noOfFullLines = 0;
        var noOfFooterLine = 2;
        var subTotal = 0;
        var itemDiscounts = 0;
        var noOfItems = 0;

        var noOfFooterLines = 11;
        var remainLines = 8;
        var expectedPages = 0;
        
        if (orderItemsData != null && orderItemsData.length > 0 ) {
            // sort lines
            orderItemsData.sort(function (a, b) {
                var shelfLifeA = a.shelflives?.state;
                var shelfLifeB = b.shelflives?.state;

                if (shelfLifeA === 'Frozen') {
                    shelfLifeA = 3;
                } else if (shelfLifeA === 'Chilling') {
                    shelfLifeA = 2;
                } else {
                    shelfLifeA = 1;
                }

                if (shelfLifeB === 'Frozen') {
                    shelfLifeB = 3;
                } else if (shelfLifeB === 'Chilling') {
                    shelfLifeB = 2;
                } else {
                    shelfLifeB = 1;
                }

                if (shelfLifeA < shelfLifeB) {
                    return -1;
                }
                if (shelfLifeA > shelfLifeB) {
                    return 1;
                }

                return 0;
            });

            orderItemsData.forEach((item, arrayIndex, array) => {
                name = "";
                noOfFullLines = 0;

                if (item.items.desc !== undefined && item.items.desc != "") {
                    name = item.items.desc
                } else {
                    name = item.items.name
                }

                if (name.length % noOfCharactersInLine > 0) {
                    remainLines++;
                }

                noOfFullLines = parseInt(name.length / noOfCharactersInLine);
                if (noOfFullLines > 0) {
                    remainLines = remainLines + noOfFullLines;
                }

            });
        }

        expectedPages = Math.ceil(remainLines / noOfLinesInPage);

        if (orderItemsData != null && orderItemsData.length > 0) {
            orderItemsData.forEach((item, arrayIndex, array) => {
                name = "";
                noOfFullLines = 0;

                if (item.orderitems != null && item.orderitems != undefined && item.orderitems.quantity > 0) {
                    noOfItems += item.orderitems.quantity;

                    if (item.orderitems.originalPrice - item.orderitems.unitPrice > 0) {
                        // subTotal += item.orderitems.quantity * (item.orderitems.unitPrice)
                        itemDiscounts += item.orderitems.quantity * (item.orderitems.originalPrice - item.orderitems.unitPrice)
                    }
                    itemDiscounts += (item.orderitems.quantity * item.orderitems.unitPrice * item.orderitems.discount) / 100;
                    subTotal += (item.orderitems.quantity * (100 - item.orderitems.discount) * item.orderitems.unitPrice / 100);
                }

                page.push(item);
                if (item.items.desc !== undefined && item.items.desc != "") {
                    name = item.items.desc
                } else {
                    name = item.items.name
                }

                if (name.length % noOfCharactersInLine > 0) {
                    currentRow++;
                    remainLines--;
                }

                noOfFullLines = parseInt(name.length / noOfCharactersInLine);
                if (noOfFullLines > 0) {
                    currentRow = currentRow + noOfFullLines;
                    remainLines = remainLines - noOfFullLines;
                }

                if (currentRow >= noOfLinesInLastPage && (currentPage + 1) === expectedPages || (remainLines <= 1 && currentPage > 0 && (noOfFooterLines + currentRow > noOfLinesInPage))) {
                    book[currentPage] = page;
                    currentPage++;
                    page = [];
                    currentRow = 0;
                } else if (currentRow >= noOfLinesInPage || (remainLines <= 1 && currentPage > 0 && (noOfFooterLines + currentRow > noOfLinesInPage))) {
                    book[currentPage] = page;
                    currentPage++;
                    page = [];
                    currentRow = 0;
                }
            });
        }

        if (page.length > 0) {
            book[currentPage] = page;
        }

        return (
            <div id="printSection">
                <html>
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"
                    />
                    <body className="A5" style={{ fontFamily: "Times New Roman", lineHeight: 1 }}>
                        <section class="sheet  padding-10mm">
                            <table style={{
                                width: "100%",
                                textAlign: "center",
                                padding: 0,
                                margin: "auto"
                            }}>
                                <tr>
                                    <td style={{ width: "10%" }}>
                                        <img src={`${window.location.origin}/images/logo/gusta.png`} style={{ width: '118px' }} alt="CIAO" />
                                    </td>

                                    <td style={{ width: "45%" }}>
                                        <table style={{ width: "100%", textAlign: "center", padding: 0, margin: 0 }}>
                                            <tr>
                                                <td style={{ width: "10%", fontSize: 12, textAlign: "left" }}>
                                                    Address :
                                                </td>
                                                <td style={{ width: "40%", fontSize: 12, textAlign: "left" }}>
                                                    No. 5, Diyawanna Gardens,
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: "10%", fontSize: 12, textAlign: "left" }}></td>
                                                <td style={{ width: "40%", fontSize: 12, textAlign: "left" }}>
                                                    Nugegoda, Sri lanka.
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: "10%", fontSize: 12, textAlign: "left" }}>
                                                    Hotline :
                                                </td>
                                                <td style={{ width: "40%", fontSize: 12, textAlign: "left" }}>
                                                    0094771330707
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td
                                                    style={{ width: "10%", textAlign: "left" }}
                                                ></td>
                                                <td
                                                    style={{
                                                        width: "40%",
                                                        fontSize: 12,
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    0094773511693
                                  </td>
                                            </tr> */}
                                        </table>
                                    </td>

                                    <td style={{ width: "42%", valign: "top" }}>
                                        <table style={{ width: "100%", textAlign: "center", padding: 0, margin: 0 }}
                                        >
                                            <tr>
                                                <td style={{ width: "25%", fontSize: 12, textAlign: "left" }}>
                                                    Acc. Name:
                                                </td>
                                                <td style={{ width: "25%", fontSize: 11, textAlign: "left" }}>
                                                    PREMIUM SPECIALTIES (PVT) LTD
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "25%", fontSize: 12, textAlign: "left" }}>
                                                    Account No:
                                                </td>
                                                <td style={{ width: "25%", fontSize: 12, textAlign: "left" }}>
                                                    1000658024
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "25%", fontSize: 12, textAlign: "left" }}>
                                                    Bank Details:
                                                </td>
                                                <td style={{ width: "25%", fontSize: 12, textAlign: "left" }}>
                                                    Commercial Bank
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "25%", fontSize: 12, textAlign: "left" }}></td>
                                                <td style={{ width: "25%", fontSize: 12, textAlign: "left" }}>
                                                    - Colombo 07
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>

                            <table style={{ width: "100%", textAlign: "center" }}>
                                <tr>
                                    <td styles="width:'100%', fontSize: 18">INVOICE</td>
                                </tr>
                            </table>

                            {typeof order !== undefined && order != null ? (
                                <table style={{ width: "100%", textAlign: "left" }}>
                                    <tr>
                                        <td style={{ width: "20%", fontSize: 13 }}>
                                            CLIENT NAME
                                        </td>
                                        <td style={{ width: "45%", fontSize: 13 }}>
                                            {order.name}
                                        </td>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            ORDER NO
                                        </td>
                                        <td style={{ width: "20%", fontSize: 13, textAlign: "right" }}>
                                            {order.orderId}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "20%", fontSize: 13 }}>
                                            MOBILE NO
                                        </td>
                                        <td style={{ width: "45%", fontSize: 13 }}>
                                            {order.telephone}
                                        </td>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            DATE
                                        </td>
                                        <td style={{ width: "20%", fontSize: 13, textAlign: "right" }}>
                                            {new Date(order.createdAt).toISOString().split('T')[0]}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "20%", fontSize: 13 }}>
                                            ADDRESS
                                        </td>
                                        <td style={{ width: "45%", fontSize: 13 }}>
                                            {order.address}
                                        </td>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            PAYMENT
                                        </td>
                                        <td style={{ width: "20%", fontSize: 13, textAlign: "right" }}>
                                            {typeof paymentMethods !== undefined &&
                                                paymentMethods != null
                                                ? paymentMethods.filter(item => {
                                                    return item._id == order.paymentOption;
                                                })[0]?.name
                                                : null}
                                        </td>
                                    </tr>
                                </table>
                            ) : null}

                            {/* Order items */}
                            <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse", marginTop: "10px" }}>
                                {typeof orderItemsData !== undefined && orderItemsData != null ? (
                                    <tr style={{ backgroundColor: "#dddddd" }}>
                                        <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 16 }}>
                                            Qty
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14 }}>
                                            Code
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", fontSize: 16 }}>
                                            Description
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 16 }}>
                                            Rate
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 16 }}>
                                            Amount Rs.
                                        </td>
                                    </tr>) : null}

                                {typeof book[0] !== undefined &&
                                    book[0] != null
                                    ? book[0].map((value, index, total) => {
                                        let name = value.items.desc != null && value.items.desc !== undefined && value.items.desc.trim() !== "" ? value.items.desc : value.items.name
                                        let unit = value.items.unit !== undefined && value.items.unit !== "" && value.items.unit !== null ? "(" + value.items.unit + ")" : null
                                        let discountString = (value.orderitems.originalPrice - value.orderitems.unitPrice > 0 ? "-" + Number(value.orderitems.originalPrice).toFixed(2) : value.orderitems.discount > 0 ? "-" + Number(value.orderitems.unitPrice).toFixed(2) : "")
                                        let rate = PriceFormat(Number(((100 - value.orderitems.discount) * value.orderitems.unitPrice / 100)))
                                        let amount = PriceFormat(Number(value.orderitems.quantity) * Number((100 - value.orderitems.discount) * value.orderitems.unitPrice / 100))
                                        return (
                                            value.shelflives.state === 'Frozen' ?
                                                <tr style={{ backgroundColor: "#dddddd" }}>
                                                    <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                        {value.orderitems.quantity}
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "left" }} >
                                                        {value?.items?.itemCode}
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left" }}>
                                                        {name} {unit}{discountString}
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14, textAlign: "right" }} >
                                                        {rate}
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                        {amount}
                                                    </td>
                                                </tr>
                                                : <tr>
                                                    <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                        {value.orderitems.quantity}
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "left" }} >
                                                        {value?.items?.itemCode}
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left" }} >
                                                        {name} {unit}{discountString}
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14, textAlign: "right" }} >
                                                        {rate}
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                        {amount}
                                                    </td>
                                                </tr>
                                        );
                                    })
                                    : null}

                                {/* render table ending lines */}
                                {((page.length == 0 && currentPage == 1) || currentPage == 0) ? (
                                    <>
                                        {/* Sub Total */}
                                        {order != null ? (
                                            <tr>
                                                <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                    {noOfItems}
                                                </td>
                                                <td colSpan="3" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                    Sub Total
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                    {PriceFormat(Number(subTotal))}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {order != null && order.totalDiscount > 0 ? (
                                            <tr>
                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >

                                                    ({Number(order.totalDiscount)}%)
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                    {PriceFormat(Number(order.subTotal * order.totalDiscount / 100))}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {/* Delivery charge */}
                                        {order != null ? (
                                            <tr>
                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                    Delivery Charge
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                    {PriceFormat(Number(order.deliveryCharges))}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {/* Total */}
                                        {order != null ? (
                                            <tr>
                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                    <div style={{ display: 'flex' }}>
                                                        {order != null && itemDiscounts > 0
                                                            ? (<div style={{ width: '50%' }}>
                                                                Item Discount - {(Number(itemDiscounts)).toFixed(2)}
                                                            </div>)
                                                            : null}
                                                        <div style={{ width: order != null && itemDiscounts > 0 ? '50%' : '100%' }}>({noOfItems}) Payable Total</div>
                                                    </div>
                                                </td>

                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right", color: (subTotal + (order.subTotal * order.totalDiscount / 100) + (order.deliveryCharges)) !== (order.totalPrice) ? 'red' : '' }}>
                                                    {PriceFormat(Number(order.totalPrice))}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {this.state.creditNoteAmount !== null && this.state.creditNoteAmount > 0 ? (
                                            <tr>
                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                    Available Credit 
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                    {PriceFormat(Number(this.state.creditNoteAmount))}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {this.state.creditNoteAmount !== null && this.state.creditNoteAmount > 0 ? (
                                            <tr>
                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                    Final Payable Total
                                                </td>

                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right", color: (subTotal + (order.subTotal * order.totalDiscount / 100) + (order.deliveryCharges)) !== (order.totalPrice) ? 'red' : '' }}>
                                                    {(Number(order.totalPrice) - Number(this.state.creditNoteAmount)) > 0 ? PriceFormat(Number(order.totalPrice) - Number(this.state.creditNoteAmount)) : PriceFormat(Number(0))}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {/* Item Discount */}
                                        {/* {order != null && itemDiscounts > 0 ? (
                                            <tr>
                                                <td
                                                    colSpan="3"
                                                    style={{
                                                        width: "85%",
                                                        border: "1px solid #dddddd",
                                                        fontSize: 14,
                                                        textAlign: "right"
                                                    }}
                                                >
                                                    Item Discounts
                                                                </td>
                                                <td
                                                    style={{
                                                        width: "15%",
                                                        border: "1px solid #dddddd",
                                                        fontSize: 14,
                                                        textAlign: "right"
                                                    }}
                                                >

                                                    {(
                                                        Number(itemDiscounts)
                                                    ).toFixed(2)}
                                                </td>
                                            </tr>
                                        ) : null} */}


                                    </>
                                ) : null}
                            </table>
                            {((page.length == 0 && currentPage == 1) || currentPage == 0) ? (
                                <>
                                    {typeof order !== undefined &&
                                        order != null &&
                                        order.note != null &&
                                        order.note.trim() != "" ? (
                                        <table style={{ width: "100%", textAlign: "left" }}>
                                            <tr>
                                                <td style={{ width: "100%", fontSize: 13 }}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                    Note:{order.note}
                                                </td>
                                            </tr>
                                        </table>
                                    ) : null}
                                    {typeof order !== undefined && order != null && order.paymentOption === "5eb0366a4ce3fb5a1af10214" ?
                                        <table style={{ width: "100%", textAlign: "left" }}>
                                            <tr>
                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                    Payment by Bank Transfer
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                    {" "}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "100%", fontSize: 13, height: 30 }}>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                    ........................................
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                    Signature
                                                </td>
                                            </tr>
                                        </table> : null}
                                    <br />
                                    <table style={{ width: "20%", textAlign: "center", borderCollapse: "collapse" }}>
                                        {this.state.bags.bags?.length !== 0 ?
                                            <tr style={{ backgroundColor: "#dddddd" }}>
                                                <td colSpan="2" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left", padding: "3px" }} >
                                                    Bags
                                                </td>
                                            </tr>
                                            : null}

                                        {this.state.bags.bags?.length !== 0 ?
                                            this.state.bags.bags?.map(value => {
                                                return (
                                                    <tr>
                                                        <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left", padding: "2px" }} >
                                                            {value?.state}
                                                        </td>
                                                        <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right", padding: "2px" }} >
                                                            {value?.qty}
                                                        </td>
                                                    </tr>
                                                )
                                            }) : null}
                                    </table>
                                    <br />
                                    {(this.state.bags.bagNames !== ", ") && (this.state.bags.bagNames !== "") ?
                                        <table style={{ width: "100%", textAlign: "left" }}>
                                            <tr>
                                                <td style={{ width: "100%", fontSize: 14 }}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "100%", fontSize: 14 }}>
                                                    Bag Numbers: {this.state.bags?.bagNames}
                                                </td>
                                            </tr>
                                        </table>
                                        : null}
                                </>
                            ) : null}

                        </section>
                        {/* Other pages */}

                        {typeof book !== undefined &&
                            book != null
                            ? book.map((page, index, total) => {
                                return (
                                    index > 0 ? (
                                        <section class="sheet   padding-10mm">
                                            <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse" }}>
                                                <tr style={{ backgroundColor: "#dddddd" }}>
                                                    <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 16 }} >
                                                        Qty
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14 }} >
                                                        Code
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", fontSize: 16 }} >
                                                        Description
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 16 }} >
                                                        Rate
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 16 }} >
                                                        Amount Rs.
                                                    </td>
                                                </tr>
                                                {page.map((value, index, total) => {
                                                    let name = value.items.desc != null && value.items.desc !== undefined && value.items.desc.trim() != "" ? value.items.desc : value.items.name
                                                    let unit = value.items.unit !== undefined && value.items.unit !== "" && value.items.unit !== null ? "(" + value.items.unit + ")" : null
                                                    let discountString = (value.orderitems.originalPrice - value.orderitems.unitPrice > 0 ? "-" + Number(value.orderitems.originalPrice).toFixed(2) : value.orderitems.discount > 0 ? "-" + Number(value.orderitems.unitPrice).toFixed(2) : "")
                                                    let rate = PriceFormat(Number(((100 - value.orderitems.discount) * value.orderitems.unitPrice / 100)))
                                                    let amount = PriceFormat(Number(value.orderitems.quantity) * Number((100 - value.orderitems.discount) * value.orderitems.unitPrice / 100))
                                                    return (
                                                        value.shelflives.state === 'Frozen' ?
                                                            <tr style={{
                                                                backgroundColor: "#dddddd"
                                                            }}>
                                                                <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                                    {value.orderitems.quantity}
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "left" }} >
                                                                    {value?.items?.itemCode}
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left" }} >
                                                                    {name} {unit}{discountString}
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14, textAlign: "right" }} >
                                                                    {rate}
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                                    {amount}
                                                                </td>
                                                            </tr>
                                                            : <tr>
                                                                <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                                    {value.orderitems.quantity}
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "left" }} >
                                                                    {value?.items?.itemCode}
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left" }} >
                                                                    {name} {unit}{discountString}
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14, textAlign: "right" }} >
                                                                    {rate}
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                                    {amount}
                                                                </td>
                                                            </tr>
                                                    );
                                                })}
                                                {/* Table ending */}
                                                {index == currentPage ?
                                                    <>
                                                        {/* Sub Total */}
                                                        {order != null ? (
                                                            <tr>
                                                                <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                                    {noOfItems}
                                                                </td>
                                                                <td colSpan="3" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }}>
                                                                    Sub Total
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                                    {PriceFormat(Number(subTotal))}
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                        {order != null && order.totalDiscount > 0 ? (
                                                            <tr>
                                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                                    Order Discount
                                                                    ({Number(
                                                                        Number(order.totalDiscount))

                                                                    }%)
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                                    {PriceFormat(Number(order.subTotal * order.totalDiscount / 100))}
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                        {/* Delivery charge */}
                                                        {order != null ? (
                                                            <tr>
                                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                                    Delivery Charge
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                                    {PriceFormat(Number(order.deliveryCharges))}
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                        {/* Total */}
                                                        {order != null ? (
                                                            <tr>
                                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                                    <div style={{ display: 'flex' }}>
                                                                        {order != null && itemDiscounts > 0
                                                                            ? (<div style={{ width: '50%' }}>
                                                                                Item Discount - {(Number(itemDiscounts)).toFixed(2)}
                                                                            </div>)
                                                                            : null}
                                                                        <div style={{ width: order != null && itemDiscounts > 0 ? '50%' : '100%' }}>({noOfItems}) Payable Total</div>
                                                                    </div>
                                                                </td>

                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right", color: (subTotal + (order.subTotal * order.totalDiscount / 100) + (order.deliveryCharges)) === (order.totalPrice) ? 'red' : '' }} >
                                                                    {PriceFormat(Number(order.totalPrice))}
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                         {this.state.creditNoteAmount !== null && this.state.creditNoteAmount > 0 ? (
                                                            <tr>
                                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                                    Available Credit
                                                                </td>
                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                                    {PriceFormat(Number(this.state.creditNoteAmount))}
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                        {this.state.creditNoteAmount !== null && this.state.creditNoteAmount > 0 ? (
                                                            <tr>
                                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                                    Final Payable Total
                                                                </td>

                                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right", color: (subTotal + (order.subTotal * order.totalDiscount / 100) + (order.deliveryCharges)) !== (order.totalPrice) ? 'red' : '' }}>
                                                                    {(Number(order.totalPrice) - Number(this.state.creditNoteAmount)) > 0 ? PriceFormat(Number(order.totalPrice) - Number(this.state.creditNoteAmount)) : PriceFormat(Number(0))}
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                        {/* Item Discount */}
                                                        {/* {order != null && itemDiscounts > 0 ? (
                                                            <tr>
                                                                <td
                                                                    colSpan="3"
                                                                    style={{
                                                                        width: "85%",
                                                                        border: "1px solid #dddddd",
                                                                        fontSize: 14,
                                                                        textAlign: "right"
                                                                    }}
                                                                >
                                                                    Item Discounts
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: "15%",
                                                                        border: "1px solid #dddddd",
                                                                        fontSize: 14,
                                                                        textAlign: "right"
                                                                    }}
                                                                >

                                                                    {(
                                                                        Number(itemDiscounts)
                                                                    ).toFixed(2)}
                                                                </td>
                                                            </tr>
                                                        ) : null} */}
                                                    </> : null}
                                            </table>
                                            {index == currentPage ? (
                                                <>
                                                    {typeof order !== undefined &&
                                                        order != null &&
                                                        order.note != null &&
                                                        order.note.trim() != "" ? (
                                                        <table style={{ width: "100%", textAlign: "left" }}>
                                                            <tr>
                                                                <td style={{ width: "100%", fontSize: 13 }}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                                    Note:{order.note}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    ) : null}
                                                    {typeof order !== undefined && order != null && order.paymentOption === "5eb0366a4ce3fb5a1af10214" ?
                                                        <table style={{ width: "100%", textAlign: "left" }}>
                                                            <tr>
                                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                                    Payment by Bank Transfer
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                                    {" "}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "100%", fontSize: 13, height: 30 }}>

                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                                    ........................................
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "100%", fontSize: 13 }}>
                                                                    Signature
                                                                </td>
                                                            </tr>
                                                        </table> :
                                                        null}
                                                    <br />
                                                    <table style={{ width: "20%", textAlign: "center", borderCollapse: "collapse" }}>
                                                        {this.state.bags.bags?.length !== 0 ?
                                                            <tr style={{ backgroundColor: "#dddddd" }}>
                                                                <td colSpan="2" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left", padding: "3px" }} >
                                                                    Bags
                                                                </td>
                                                            </tr>
                                                            : null}

                                                        {this.state.bags.bags?.length !== 0 ?
                                                            this.state.bags.bags?.map(value => {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left", padding: "2px" }} >
                                                                            {value?.state}
                                                                        </td>
                                                                        <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right", padding: "2px" }} >
                                                                            {value?.qty}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : null}
                                                    </table>
                                                    <br />
                                                    {(this.state.bags.bagNames !== ", ") && (this.state.bags.bagNames !==  "") ? 
                                                        <table style={{ width: "100%", textAlign: "left" }}>
                                                            <tr>
                                                                <td style={{ width: "100%", fontSize: 14 }}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "100%", fontSize: 14 }}>
                                                                    Bag Numbers: {this.state.bags?.bagNames}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    : null}
                                                </>
                                            ) : null}
                                        </section>) : null
                                );
                            })
                            : null}
                    </body>
                </html>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
    }
}

export default connect(mapStateToProps)(InvoicePrint);
