import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import GridFilter from '../../components/GridFilter';
import TableViewer from '../../components/TableViewer';
import { DateNumberFormat, PriceFormat } from '../../utils';

const getData = (setRefundData, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    axios.get('/purchasing/refund')
        .then(result => {
            console.log(result.data);
            setRefundData(result.data)
        }).catch(err => {
            console.error(err);
        })
}

const columns = [
    { Header: "Customer Name", accessor: "coName", ...GridFilter },
    { Header: "Order Id", accessor: "orderId", ...GridFilter },
    { Header: "Item", accessor: "itemName", ...GridFilter },
    {
        Header: "Purchase Price", accessor: "price", ...GridFilter,
        Cell: props =>
            <div style={{ textAlign: 'right' }}>
                {PriceFormat(props.original.price)}
            </div>
    },
    {
        Header: "Bare %", accessor: "bare", ...GridFilter, Cell: props =>
            <div style={{ textAlign: 'right' }}>
                {props.original.bare}
            </div>
    },
    {
        Header: "Bare Price", accessor: "barePrice", ...GridFilter,
        Cell: props =>
            <div style={{ textAlign: 'right' }}>
                {PriceFormat(props.value)}
            </div>
    },
    { Header: "Supplier", accessor: "supplier", ...GridFilter },
    {
        Header: "Defect Qty", accessor: "qty", ...GridFilter, Cell: props =>
            <div style={{ textAlign: 'right' }}>
                {props.original.qty}
            </div>
    },
    {
        Header: "isCompleted", accessor: "isCompleted", ...GridFilter,
        Cell: prop => <>
            {prop.value ? 'Yes' : 'No'}
        </>
    },
    {
        Header: "Created At", accessor: "createdAt", ...GridFilter,
        Cell: prop => <>
            {DateNumberFormat(prop.value)}
        </>
    },
]

const Refund = props => {

    return (
        <TableViewer columns={columns} getData={getData} props={props} />
    )
}

const mapStateToProps = state => ({
    token: state.rLogin.token,
    isLoading: state.rLogin.loading,
    userId: state.rLogin.id,
    loggedUser: state.rLogin.name,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
})

const mapDispatchToProps = dispatch => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(Refund)
