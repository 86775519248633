import React from 'react'

function OptionColumnFilter({ filter, onChange }) {
    return (
        <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
        >
            <option value={"all"}>Show All</option>
            <option value={"Yes"}>YES</option>
            <option value={"Yes-Fast"}>YES-FAST</option>
            <option value={"No"}>NO</option>
        </select>
    )
}

export default OptionColumnFilter
