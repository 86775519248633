/**
 * @file This file define for main reducer
 * @author Bhanuka Chathuranga, 
 */
import { combineReducers } from 'redux';
import { reducer as form } from "redux-form";

//login
import reducerLogin from './ReducerLogin'

//MIS
import reducerCashBox from './mis/reducerCashBox';
import reducerMessagePool from './mis/reducerMessagePool'
import reducerSalesEvolution from './mis/reducerSalesEvolution';

//delivery
import reducerDeliveryBoys from './delivery/reducerDelivery';
import reducerDeliveryNote from './delivery/reducerDeliveryNote';
import reducerMissingOrder from './delivery/reducerMissingOrder';
import reducerCreditNote from './delivery/reducerCreditNote';

//order
import reducerOrdering from './order/reducerOrdering';
import reducerOrderHistory from './order/reducerOrderHistory';
import reducerComplain from './order/reducerComplain';
import reducerCanceledOrders from './order/reducerCanceledOrders';
import reducerSalesPromotion from './order/reducerSalesPromotion';

//purchasing
import {
    reducerSupplier,
    reducerOrder,
    reducerSupplierEvolution,
    reducerPurchasingHistory,
    reducerPurchasePrice,
    reducerReturnToSupplier,
    reducerCreditNotePurchase
} from './puchasing'

//stock
import reducerStock from './stock/reducerStock'
import reducerStockItem from './stock/reducerStockItem'
import reducerMoveStock from './stock/reducerMoveStock';
import reducerMoveStockCompany from './stock/reducerMoveStockCompany';
import reducerStockMoving from './stock/reducerStockMoving';
import reducerPackaging from './stock/reducerPackaging'

//basicData
import reducerBasicData from './basic_data/ReducerBasicData';
import reducerItem from './basic_data/reducerItem';
import reducerProduct from './basic_data/reducerProduct';
import reducerCategory from './basic_data/reducerCategory';
import reducerDeliveryType from './basic_data/reducerDeliveryType';
import reducerDeliveryCondition from './basic_data/reducerDeliveryCondition';
import reducerDeliveryCharges from './basic_data/reducerDeliveryCharges';
import reducerPaymentCondition from './basic_data/reducerPaymentCondition';
import reducerPaymentMethod from './basic_data/reducerPaymentMethod';
import reducerCurrency from './basic_data/reducerCurrency';
import reducerShelfLife from './basic_data/reducerShelfLife';
import reducerItemPrices from './basic_data/reducerItemPrices';
import reducerWorkingCalender from './basic_data/reducerWorkingCalender';
import reducerSeasonalDiscounts from './basic_data/reducerSeasonalDiscounts';
import reducerMeasurement from './basic_data/reducerMeasurement';
import reducerDeliveryWarehouse from './basic_data/reducerDeliveryWarehouse';
import reducerItemTag from './basic_data/reducerItemTag';
import reducerSurveyQuestions from './basic_data/reducerSurveyQuestions';
import reducerErrorLog from './basic_data/reducerErrorLog';

//admin
import reducerUser from './administrator/reducerUser';
import reducerAddresses from './administrator/reducerAddresses';
import reducerNotifications from './administrator/reducerNotifications';
import reducerParameters from './administrator/reducerParameters';
import reducerPermission from './administrator/reducerPermission';


//acc
import reducerAccounts from './acc/reducerAccounts';
import reducerPayments from './acc/reducerPayments';
import reducerPaymentDetail from './acc/reducerPaymentDetail';
import reducerBanks from './acc/reducerBanks';

//Dashboard
import reducerDashboard from './dashboard/reducerDashboard';

//pos
import reducerOutletItemPrices from './pos/reducerOutletItemPrices';
import reducerPOS from './pos/reducerPOS';
import reducerDisposedItems from './pos/reducerDisposedItems';

export const rootReducer = combineReducers({
    // login reducer
    rLogin: reducerLogin,

    // mis
    rCashBox: reducerCashBox,
    rMessagePoolUsers: reducerMessagePool,
    rSalesEvolution: reducerSalesEvolution,

    //delivery
    rDeliveryBoys: reducerDeliveryBoys,
    rDeliveryNotes: reducerDeliveryNote,
    rMissingOrders: reducerMissingOrder,
    rCreditNote: reducerCreditNote,

    //order
    rOrdering: reducerOrdering,
    rOrderHistory: reducerOrderHistory,
    rComplain: reducerComplain,
    rCanceledOrders: reducerCanceledOrders,
    rSalesPromotion: reducerSalesPromotion,

    // purchase
    rSupplier: reducerSupplier,
    rOrder: reducerOrder,
    rSupplierEvolution: reducerSupplierEvolution,
    rPurchasingHistory: reducerPurchasingHistory,
    rPurchasePrice: reducerPurchasePrice,
    rReturnToSupplier: reducerReturnToSupplier,
    rCreditNotePurchase: reducerCreditNotePurchase,

    //stock
    rStock: reducerStock,
    rStockItem: reducerStockItem,
    rMoveStock: reducerMoveStock,
    rMoveStockCompany: reducerMoveStockCompany,
    rStockMoving: reducerStockMoving,
    rPackaging: reducerPackaging,

    //basicData
    rBasicData: reducerBasicData,
    rItem: reducerItem,
    rProduct: reducerProduct,
    rCategory: reducerCategory,
    rDeliveryType: reducerDeliveryType,
    rDeliveryCondition: reducerDeliveryCondition,
    rDeliveryCharges: reducerDeliveryCharges,
    rPaymentCondition: reducerPaymentCondition,
    rPaymentMethod: reducerPaymentMethod,
    rCurrency: reducerCurrency,
    rShelfLife: reducerShelfLife,
    rItemPrices: reducerItemPrices,
    rWorkingCalender: reducerWorkingCalender,
    rSeasonalDiscounts: reducerSeasonalDiscounts,
    rMeasurement: reducerMeasurement,
    rDeliveryWarehouse: reducerDeliveryWarehouse,
    rItemTag: reducerItemTag,
    rSurveyQuestions: reducerSurveyQuestions,
    rErrorLog: reducerErrorLog,

    //admin
    rUser: reducerUser,
    rAddresses: reducerAddresses,
    rNotifications: reducerNotifications,
    rParameters: reducerParameters,
    rPermission: reducerPermission,

    //acc
    rAccounts: reducerAccounts,
    rPayments: reducerPayments,
    rPaymentDetail: reducerPaymentDetail,
    rBanks: reducerBanks,

    //Dashboard
    rDashboard: reducerDashboard,

    //POS
    rOutletItemPrices: reducerOutletItemPrices,
    rPOS: reducerPOS,
    rDisposedItems: reducerDisposedItems,

    form
})