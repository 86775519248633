/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import { toast } from "react-toastify";

import _ from 'lodash';

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";

import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getSalesPromotionData, addSalesPromotionData, deleteSalesPromotionData, updateSalesPromotionData } from "../../../actions/Ordering/sales_promotion_actions";
import { getItems } from '../../../actions/Basic_data/item_actions'
import { getBrandData } from '../../../actions/Basic_data/basic_data_action';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const ReactTableFixedColumns = withFixedColumns(Table);

class SalesPromotion extends React.Component {

    state = {
        isItem: true,
        description: '',
        selectedItem: null,
        selectedBrand: null,
        startDate: null,
        endDate: null,
        activeBrands: [],
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'products.name', desc: false }],
    };

    componentDidMount() {
        this.props.getSalesPromotionData(this.props.token)
        this.props.getItems(this.props.token)
        this.props.getBrandData(this.props)
    }

    componentDidUpdate(prevProps) {
        const { activeItems, brandData } = this.props;
        if (activeItems !== prevProps.activeItems || brandData !== prevProps.brandData) {
            var itemBrands = [];
            if (activeItems.length > 0) {

                activeItems.map(item => {
                    if (item.items.brand && itemBrands.indexOf(item.items.brand.toString()) === -1)
                        itemBrands.push(item.items.brand.toString());
                })
                const filteredBrands = brandData.filter(brand => itemBrands.indexOf(brand._id.toString()) !== -1);
                const activeBrands = filteredBrands.filter(brand => brand.active === true);

                this.setState({
                    activeBrands: activeBrands
                })
            }
        }
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        // var hours = '' + d.getHours();
        // var minutes = '' + d.getMinutes();
        // var seconds = '' + d.getSeconds();

        // if (hours.length < 2) {
        //     hours = '0' + hours;
        // }

        // if (minutes.length < 2) {
        //     minutes = '0' + minutes;
        // }
        // if (seconds.length < 2) {
        //     seconds = '0' + seconds;
        // }

        var date = [year, month, day].join('-');
        // var time = [hours, minutes, seconds].join(':')

        // return [date, time].join(' ');
        return date
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableDateColumnProps = {
        ...GridFilters,
        Cell: Props => {
            const edit = this.state.editing === Props.original;
            return (edit ?
                <DatePicker
                    name={Props.column.id}
                    onChange={e => this.setStateForEdit(Props.column.Header, e)}
                    selected={Props.column.Header === 'Start Date' ? this.state.startDate : this.state.endDate}
                    className="form-control"
                />
                : <label>{this.formatDate(Props.value)}</label>)
        }
    };

    setStateForEdit = (header, value) => {
        if (header === 'Start Date') {
            this.setState({ startDate: value })
        } else {
            this.setState({ endDate: value })
        }
    }

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('ORDERS', 'SALES PROMOTION', 'UPDATE', this.props),
            delPerm: Permission('ORDERS', 'SALES PROMOTION', 'DELETE', this.props)
        }) ||
        {};

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            let startDate = this.state.startDate ? this.state.startDate : values.salespromotion.startDate
            let endDate = this.state.endDate ? this.state.endDate : values.salespromotion.endDate

            if (new Date(startDate) < new Date(endDate)) {
                let data = {
                    startDate: startDate,
                    endDate: endDate,
                    description: values.salespromotion.description,
                    _id: values.salespromotion._id
                }

                this.props.updateSalesPromotionData(this.props.token, data, () => {
                    this.setState({
                        startDate: null,
                        endDate: null
                    })
                })
            } else {
                toast.warn('Please enter an end date greater than or equal to the start date.')
                this.setState({
                    startDate: null,
                    endDate: null
                })
            }

        } else if (this.state.deleting !== null && this.state.editing === null) {
            const data = {
                _id: values.salespromotion._id
            }

            this.props.deleteSalesPromotionData(this.props.token, data)
        }
    };

    addSalesPromotionHandle = () => {
        const { isItem, selectedItem, selectedBrand, description, startDate, endDate } = this.state

        if ((isItem && selectedItem !== null && selectedItem !== '') || (!isItem && selectedBrand !== null && selectedBrand !== '')) {
            if (startDate !== null && endDate !== null && description !== null) {

                let itemIds = [];

                if (isItem) {
                    itemIds.push(selectedItem.value)
                } else {
                    let items = _.filter(this.props.activeItems, item => {
                        return item.items.brand === selectedBrand.value
                    })

                    items.map(item => {
                        itemIds.push(item.items._id)
                    })
                }

                let data = [];

                for (let i = 0; i < itemIds.length; i++) {
                    let obj = {
                        'itemRef': itemIds[i],
                        'description': description,
                        'startDate': new Date(startDate),
                        'endDate': new Date(endDate)
                    }

                    data.push(obj)
                }

                if (new Date(startDate) < new Date(endDate)) {
                    if (data.length > 0) {
                        this.props.addSalesPromotionData(this.props.token, data, () => {
                            document.getElementById("addSalesPromotionForm").reset();
                            window.$('#addSalesPromotion').modal('hide');
                            this.setState({
                                selectedBrand: null,
                                selectedItem: null,
                                description: '',
                                startDate: null,
                                endDate: null,
                                isItem: true
                            })
                        })
                    } else {
                        toast.warn('These branded items are not available.')
                    }
                } else {
                    toast.warn('Please enter an end date greater than or equal to the start date.')
                }
            } else {
                toast.error('Please fill the required fields.')
            }
        } else {
            toast.error('Please fill the required fields...')
        }


    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    setBrandOrItem = (value) => {
        if (this.state.isItem) {
            this.setState({ selectedItem: value })
        } else {
            this.setState({ selectedBrand: value })
        }
    }

    close = () => {
        document.getElementById("addSalesPromotionForm").reset();
        this.setState({
            selectedBrand: null,
            selectedItem: null,
            description: null,
            startDate: null,
            endDate: null,
            isItem: true
        })
    }

    columns = [
        { Header: "Name", accessor: "items.name", filterable: true },
        { Header: "Code", accessor: "items.itemCode", filterable: true },
        { Header: "Supplier", accessor: "supplier.name", filterable: true },
        { Header: "Description", accessor: "salespromotion.description", ...this.editableColumnProps },
        {
            Header: "Start Date",
            accessor: "salespromotion.startDate",
            Cell: ({ value, original }) => {
                return <>{this.formatDate(original.salespromotion.startDate)}</>
            },
            ...this.editableDateColumnProps,
            getProps: () => { return { style: { 'textAlign': 'right' } } }
        },
        {
            Header: "End Date",
            accessor: "salespromotion.endDate",
            Cell: ({ value, original }) => {
                return <>{this.formatDate(original.salespromotion.endDate)}</>
            },
            ...this.editableDateColumnProps,
            getProps: () => { return { style: { 'textAlign': 'right' } } }
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('ORDERS', 'SALES PROMOTION', 'ADD', this.props)} data-toggle="modal" data-target="#addSalesPromotion" onClick={e => this.close()}>+ Sales Promotion</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.columns}
                                                    data={this.props.salesPromotionData}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>



                    <div id="addSalesPromotion" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Sale Promotion</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addSalesPromotionForm'>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Item: &nbsp;
                                                            <input
                                                                checked={this.state.isItem}
                                                                type="radio"
                                                                name="isItem"
                                                                onClick={e => this.setState({ isItem: true })}
                                                            />
                                                        </td>
                                                        <td >
                                                            Brand: &nbsp;
                                                            <input
                                                                type="radio"
                                                                name="isItem"
                                                                onClick={e => this.setState({ isItem: false })}
                                                            />
                                                        </td>
                                                    </tr>

                                                    <br />

                                                    <tr>
                                                        <td style={{ 'width': '25%' }}>{this.state.isItem ? 'Select Item* :' : 'Select Brand* :'}</td>
                                                        <td>
                                                            <Select
                                                                options={this.state.isItem ? this.props.activeItems : this.state.activeBrands}
                                                                theme={theme => ({ ...theme, borderRadius: 0 })}
                                                                onChange={e => this.setBrandOrItem(e)}
                                                                placeholderText={this.state.isItem ? 'Select Items' : 'Select Brand'}
                                                                value={this.state.isItem ? this.state.selectedItem : this.state.selectedBrand}
                                                            />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Description* : &nbsp; </td>
                                                        <td>
                                                            <textarea
                                                                className="form-control"
                                                                type='text'
                                                                name="desc"
                                                                slot="2"
                                                                value={this.state.description}
                                                                onChange={e => this.setState({ description: e.target.value })}
                                                            />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Start Date* : &nbsp; </td>
                                                        <td>
                                                            <DatePicker
                                                                onChange={e => this.setState({ startDate: e })}
                                                                placeholderText="Start Date"
                                                                selected={this.state.startDate}
                                                                className="form-control"
                                                            />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>End Date* : &nbsp; </td>
                                                        <td>
                                                            <DatePicker
                                                                onChange={e => this.setState({ endDate: e })}
                                                                placeholderText="End Date"
                                                                selected={this.state.endDate}
                                                                className="form-control"
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="add_stock" type="button" className="btn btn-primary" onClick={e => this.addSalesPromotionHandle()}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.close()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        salesPromotionData: state.rSalesPromotion.salesPromotionData,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        activeItems: state.rItem.activeItemsData,
        brandData: state.rBasicData.brand
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSalesPromotionData: (token) => { dispatch(getSalesPromotionData(token)) },
        getItems: (token) => { dispatch(getItems(token)) },
        getBrandData: (props) => { dispatch(getBrandData(props)) },
        addSalesPromotionData: (token, data, cb) => { dispatch(addSalesPromotionData(token, data, cb)) },
        updateSalesPromotionData: (token, data, cb) => { dispatch(updateSalesPromotionData(token, data, cb)) },
        deleteSalesPromotionData: (token, data) => { dispatch(deleteSalesPromotionData(token, data)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesPromotion);


