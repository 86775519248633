/**
 * @author Ravindra Pushpa Kumara
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const usersAsync = data => {
    return { type: 'FETCH_USERS', data }
}

/**
 * 
 * @param {*} token 
 */
export const getUsers = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/administrator/user/getUser')
            .then(result => {
                dispatch(usersAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const updateUser = (data, token) => {
    return dispatch => {
        var _id = data._id;

        var images = '';
        if (data.images !== undefined) {
            if (!data.images.path) {
                images = data.images;
                delete data.images;
                delete data.userImage;
            }
        }

        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/user/updateUser', { data })
            .then(result => {
                //upload image
                if (images !== '') {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const data = new FormData();

                    console.log(images)

                    data.append("userImage", images, images.name);

                    axios.post('/administrator/user/uploadImage', data, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const images = {
                                'url': '/images/users/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'users',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }

                            const data = {
                                _id: _id,
                                userImage: images
                            }

                            dispatch(updateImage(data, token))

                        }).catch(err => {
                            console.log(err);
                            if (err.response.data.error === "Error: Images Only!") {
                                toast.error("Invalid Image")
                            } else if (err.response.data.error.code !== undefined && err.response.data.error.code === "LIMIT_FILE_SIZE") {
                                toast.error("Image too large")
                            }
                        })
                } else {
                    dispatch(getUsers(token))
                    toast.success('Successfully Updated User!')
                }

            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const updateImage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/user/updateUserSlug', { data })
            .then(result => {
                toast.success('Successfully Updated User')
                dispatch(getUsers(token))
            }).catch(err => {
                console.log(`${err}`)
                toast.error(`${err}`)
            })
    }
}

export const deleteUser = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/user/deleteUser', { data })
            .then(result => {
                toast.success('User Deleted Successfully!')
                dispatch(getUsers(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addUser = ({ firstName, lastName, email, password, confirmPassword, userType, phone,
    gender, isPhoneVerified, isEmailVerified, isApproved, isAdmin, images, slug }, token) => {
    var name = { first: firstName, last: lastName };

    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/administrator/user/addUser', {
            data: {
                name, email, password, userType, phone, gender, isPhoneVerified, isEmailVerified, isApproved, isAdmin, slug
            }
        })
            .then(result => {

                var _id = result.data.insertedId;

                //upload image
                if (images !== '') {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const data = new FormData();

                    data.append("userImage", images, images.name);

                    axios.post('/administrator/user/uploadImage', data, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const images = {
                                'url': '/images/users/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'users',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }

                            // var slug = _id;

                            const data = {
                                _id: _id,
                                userImage: images
                            }

                            dispatch(updateUserSlugWithImage(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                } else {
                    toast.success('User Added Successfully!')
                    dispatch(getUsers(token))
                }



                //upload image ends

            }).catch(err => {
                console.log(err);
                toast.error(`${err}`)
            })
    }
}

/**
 *
 * @param {*} data
 * @param {*} token
 */
export const updateUserSlugWithImage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/user/updateUserSlug', { data })
            .then(result => {
                toast.success('User Added Successfully!')
                dispatch(getUsers(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}