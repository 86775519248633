/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const deliveryChargesAsync = data => {
    return { type: 'FETCH_DELIVERY_CHARGES', data }
}

/**
 * 
 * @param {*} token 
 */
export const getDeliveryCharges = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/deliverycharges/getDeliveryCharges')
            .then(result => {
                dispatch(deliveryChargesAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addDeliveryCharges = ({ deliveryCharges, thresholdValue, userType, slug, region }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/deliverycharges/addDeliveryCharges', {
            data: {
                deliveryCharges, thresholdValue, userType, slug, region
            }
        })
            .then(result => {
                var _id = result.data.insertedId;
                var slug = _id;

                const data = {
                    _id: _id,
                    slug: slug,
                    thresholdValue,
                    deliveryCharges,
                    region
                }

                dispatch(updateDeliveryCharges(data, token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateDeliveryCharges = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverycharges/updateDeliveryCharges', { data })
            .then(result => {
                window.$('#addDeliveryCharges').modal('hide');
                toast.success('Successfully Added / Updated Delivery Charges')
                dispatch(getDeliveryCharges(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteDeliveryCharges = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverycharges/deleteDeliveryCharges', { data })
            .then(result => {
                toast.success('Delivery Charges Deleted Successfully!')
                dispatch(getDeliveryCharges(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}
