import React from "react";

const editModes = {
  view: props => (
    <React.Fragment>
      <button className="btn btn-link btn-sm" disabled={props.deleteDisable} onClick={props.onDelete}>
        <i className="fas fa-trash"></i>
      </button>
    </React.Fragment>
  ),
};

export default function ActionsCell(props) {
  const {
    mode,
    delPerm = false,
    actions: { onDelete }
  } = props.columnProps.rest;
  const Buttons = editModes[mode];
  return <Buttons
    deleteDisable={delPerm}
    onDelete={() => { if (window.confirm('Are you sure you wish to delete this item?')) onDelete(props.index) }} />;
}
