/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const paymentConditionsAsync = data => {
    return { type: 'FETCH_PAYMENT_CONDITIONS_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
export const getPaymentConditions = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/paymentcondition/getPaymentConditions')
            .then(result => {
                dispatch(paymentConditionsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updatePaymentCondition = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/paymentcondition/updatePaymentCondition', { data })
            .then(result => {
                toast.success('Successfully Updated Payment Condition')
                dispatch(getPaymentConditions(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deletePaymentCondition = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/paymentcondition/deletePaymentCondition', { data })
            .then(result => {
                toast.success('Payment Condition Deleted Successfully!')
                dispatch(getPaymentConditions(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addPaymentCondition = ({ numberOfdays, description }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/paymentcondition/addPaymentCondition', {
            data: {
                numberOfdays,
                description
            }
        })
            .then(result => {
                window.$('#addPaymentCondition').modal('hide');
                toast.success('Successfully Added Payment Condition');
                dispatch(getPaymentConditions(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}