/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getSeasonalDiscounts, addSeasonalDiscount, updateSeasonalDiscount, deleteSeasonalDiscount } from '../../../actions/Basic_data/seasonal_discounts_actions';

const ReactTableFixedColumns = withFixedColumns(Table);

class SeasonalDiscounts extends React.Component {

    boolData = [
        { '_id': 'yes', 'name': 'yes' },
        { '_id': 'no', 'name': 'no' }
    ];

    weekDayData = [
        { '_id': 'Monday', 'name': 'Monday' },
        { '_id': 'Tuesday', 'name': 'Tuesday' },
        { '_id': 'Wednesday', 'name': 'Wednesday' },
        { '_id': 'Thursday', 'name': 'Thursday' },
        { '_id': 'Friday', 'name': 'Friday' },
        { '_id': 'Saturday', 'name': 'Saturday' },
        { '_id': 'Sunday', 'name': 'Sunday' },
    ];

    state = {
        seasonalDiscountsData: [],
        name: '',
        thresholdValue: 0,
        discountPercentage: 0,
        isActive: 'yes',
        startAt: '',
        endAt: '',
        dayOfWeek: '',
        slug: '',
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'name', desc: false }],
    };

    componentDidMount() {
        this.props.getSeasonalDiscounts(this.props.token)
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDropdownList,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    props.column.Header === 'Day Of Week' ? this.weekDayData :
                        this.boolData).map(data =>
                            <option key={data._id} value={data._id}>{data.name}</option>
                        )}
            </Field> : <label>{props.value}</label>)
        }
    };

    setStateForEdit = (column, value) => {
        if (column === 'Day Of Week') {
            this.setState({ dayOfWeek: value })
        } else if (column === 'Is Active') {
            this.setState({ isActive: value });
        }
    }

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        isActive: rowProps.original.isActive,
                        dayOfWeek: rowProps.original.dayOfWeek,
                        startAt: new Date(rowProps.original.startAt),
                        endAt: new Date(rowProps.original.endAt)
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'SEASONAL DISCOUNTS', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'SEASONAL DISCOUNTS', 'DELETE', this.props)
        }) ||
        {};

    editableStartAtColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return (editing ?
                <DatePicker onChange={this.setStartAt} selected={this.state.startAt} className="form-control" minDate={Date.now()} />
                : <label>{props.value}</label>)
        }
    };

    editableEndAtColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return (editing ?
                <DatePicker onChange={this.setEndAt} selected={this.state.endAt} className="form-control" minDate={Date.now()} />
                : <label>{props.value}</label>)
        }
    };

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values;

            data.isActive = this.state.isActive;
            data.startAt = this.formatDate(this.state.startAt);
            data.endAt = this.formatDate(this.state.endAt);
            data.dayOfWeek = this.state.dayOfWeek;

            this.props.updateSeasonalDiscount(data, this.props.token)

        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values._id
            }

            this.props.deleteSeasonalDiscount(data, this.props.token)
        }
    };

    addSeasonalDiscountHandle = () => {
        var { name, thresholdValue, discountPercentage, isActive, startAt, endAt, dayOfWeek } = this.state;

        if (name.trim() !== '' && startAt !== '' && endAt !== '' && dayOfWeek.trim() !== '') {

            this.state.startAt = this.formatDate(this.state.startAt);
            this.state.endAt = this.formatDate(this.state.endAt);

            this.state.slug = name.replace(/ /gi, "-");
            this.state.slug = this.state.slug.replace(/'/gi, "-");

            this.props.addSeasonalDiscount(this.state, this.props.token)

            this.setState({
                name: '',
                thresholdValue: 0,
                discountPercentage: 0,
                isActive: 'yes',
                startAt: '',
                endAt: '',
                dayOfWeek: '',
                slug: ''
            });

            document.getElementById("addSeasonalDiscountForm").reset();
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    setStartAt = date => {
        this.setState({
            startAt: date
        });
    }

    setEndAt = date => {
        this.setState({
            endAt: date
        });
    }

    formatDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    seasonalDiscountColumns = [
        { Header: "Name", accessor: "name", ...this.editableColumnProps },
        { Header: "Threshold Value", accessor: "thresholdValue", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Discount Percentage", accessor: "discountPercentage", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Is Active", accessor: "isActive", ...this.selectableColumnProps },
        { Header: "Start Date", accessor: "startAt", ...this.editableStartAtColumnProps },
        { Header: "End Date", accessor: "endAt", ...this.editableEndAtColumnProps },
        { Header: "Day Of Week", accessor: "dayOfWeek", ...this.selectableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'SEASONAL DISCOUNTS', 'ADD', this.props)} data-toggle="modal" data-target="#addSeasonalDiscount">+ Seasonal Discount</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.seasonalDiscountColumns}
                                                    data={this.props.seasonalDiscountsData}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>


                    <div id="addSeasonalDiscount" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Delivery Condition</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addSeasonalDiscountForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ name: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Threshold Value* :</td>
                                                        <td><input type="number" className="form-control" onChange={e => this.setState({ thresholdValue: Number(e.target.value) })} value={this.state.thresholdValue} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Discount Percentage* :</td>
                                                        <td><input type="number" className="form-control" onChange={e => this.setState({ discountPercentage: Number(e.target.value) })} value={this.state.discountPercentage} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is Active* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ isActive: e.target.value })}>
                                                                <option value={''}>- Is Active? -</option>
                                                                {this.boolData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Start Date* :</td>
                                                        <td><DatePicker onChange={this.setStartAt} placeholderText="Start Date" selected={this.state.startAt} className="form-control" minDate={Date.now()} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>End Date* :</td>
                                                        <td><DatePicker onChange={this.setEndAt} placeholderText="End Date" selected={this.state.endAt} className="form-control" minDate={Date.now()} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Day of Week* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ dayOfWeek: e.target.value })}>
                                                                <option value={''}>- Select Day of Week -</option>
                                                                {this.weekDayData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={this.addSeasonalDiscountHandle}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        seasonalDiscountsData: state.rSeasonalDiscounts.seasonalDiscountsData,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSeasonalDiscounts: (token) => { dispatch(getSeasonalDiscounts(token)) },
        addSeasonalDiscount: (data, token) => { dispatch(addSeasonalDiscount(data, token)) },
        updateSeasonalDiscount: (data, token) => { dispatch(updateSeasonalDiscount(data, token)) },
        deleteSeasonalDiscount: (data, token) => { dispatch(deleteSeasonalDiscount(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeasonalDiscounts);


