// import HighlightCell from "../components/HighlightCell";

function containsInsensitive(filter, row) {
    // console.log(filter)
    // console.log(row)
    return (
        row[filter.id] == null || row[filter.id] !== undefined ?
            String(row[filter.id])
                .toLowerCase()
                .includes(filter.value) : ""
    );
}

function getFiltered(gridState) {
    return { filtered: gridState.filtered };
}

export default {
    // Cell: HighlightCell,
    filterable: true,
    filterMethod: containsInsensitive,
    getProps: getFiltered
};
