/**
 * @type {Object}
 * @property {array}  data  Message Pool Data
 */
 const initialState = {
    messagePoolUsers: [],
    completeMessagePoolUsers: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerMessagePool = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_MESSAGE_POOL_USER':
            var { data } = action;
            newState.messagePoolUsers = data;
            break;

        case 'FETCH_COMPLETE_MESSAGE_POOL_USER':
            var { data } = action;
            newState.completeMessagePoolUsers = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerMessagePool;