import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import Permission from '../../utils/Permission';
import ActionsCell from "../../components/ActionCell";
import { getRegionData } from '../../../actions/Basic_data/basic_data_action';
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import { getCity, addCity, updateCity, deleteCity, updateStatus } from '../../../actions/Basic_data/city_action';
import SearchSelectComponent from "../../components/SearchSelectComponent";

export const City = props => {
    const [addCity, setAddCity] = useState({ region: '', name: '', status: '' });
    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);

    const statusData = [
        { 'value': 'Active', 'label': 'Active' },
        { 'value': 'Inactive', 'label': 'Inactive' }
    ];

    useEffect(() => {
        props.getRegionData(props)
        props.getCityData(props)
    }, [])


    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            // mode: (edit === rowProps.original && rowProps.original.orders.status !== 'Delivered') ? "edit" : "view",
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'CITY', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'CITY', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const selectableColumnProps = {
        ...GridFilter,
        Cell: Props => {
            const edit = editing === Props.original;

            return (edit ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {(statusData).map(({ value, label }) =>
                        <option
                            key={value} value={value}>
                            {label}
                        </option>
                    )}

                </Field> : <label>{Props.value}</label>)
        }
    };

    // const editableSelectComponent = ({ Props }) => {
    //     return <><select defaultValue={Props.value} className='form-control'>
    //         {(Props.column.Header === 'Payment Mehtods' ? props.paymentMethods : status).map(data =>
    //             <option key={Props.column.Header === 'Payment Mehtods' ? data._id : data} value={Props.column.Header === 'Payment Mehtods' ? data._id : data}>{Props.column.Header === 'Payment Mehtods' ? data.name : data}</option>
    //         )}
    //     </select></>
    // }

    // const selectableColumnProps = {
    //     ...GridFilter,
    //     Cell: Props => {
    //         const edit = editing === Props.original;
    //         const fieldProps = {
    //             // component: editableSelectComponent,
    //             editing,
    //             Props
    //         };

    //         return (edit ?
    //             <Field name={Props.column.id} component="select" className='form-control'>
    //                 {props.region.map(obj => <option key={obj._id} value={obj._id}>{obj.name}</option>)}
    //             </Field> : <label>{Props.value}</label>)

    //         // if (editing && Props.original.orders !== undefined && (Props.column.Header !== 'Status' || Props.original.orders.status !== 'Delivered')) {
    //         //     return <Field name={Props.column.id} {...fieldProps} />
    //         // } else { return <label>{Props.value}</label> }
    //     }
    // };

    const handleCheckboxChange = (data,e) =>{
        props.updateStatus({ _id: data.original.cities._id, app_status: e.target.checked }, props)
    }
    
    const searchableColumnProps = {
        ...GridFilter,
        Cell: Props => {
            const edit = editing === Props.original;
            const options = props.region.map(data => ({ label: data.name, value: data._id }));
            return (edit ?
                <Field component={SearchSelectComponent} options={options} name={Props.column.id} value={Props.value} />
                : <label>{Props.value}</label>)
        }
    };

    const columns = [
        { Header: "City", accessor: "cities.name", ...editableColumnProps },
        {
            Header: "Region",
            accessor: "regions.name",
            ...searchableColumnProps,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        cursor: 'pointer',
                        overflow: 'visible'
                    },
                };
            },
        },
        { Header: "Status", accessor: "cities.status", ...selectableColumnProps },
        {
            Header: "Show App", accessor: "cities.app_status", ...searchableColumnProps,
            Cell: row => {
                return (
                    <div class="checkbox-wrapper-14">
                        <input id="s1-14" disabled={Permission('BASIC DATA', 'CITY', 'UPDATE', props)} type="checkbox" class="switch" onClick={(e) => handleCheckboxChange(row, e)} checked={row.original.cities!== undefined && row.original.cities.app_status !== undefined ? row.original.cities.app_status : false} />
                        <label for="s1-14"></label>
                    </div>
                );
            },
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ];


    const addCityHandler = () => {
        // console.log(addCity);
        props.addCity(addCity, props)
    }

    const handleSubmit = value => {
        // console.info(value)
        // console.log(editing);
        if (editing !== null) {
            if (editing.regions.name !== value.regions.name) {
                props.updateCity({ _id: value.cities._id, name: value.cities.name, regions: value.regions.name, status: value.cities.status }, props)
            } else {
                props.updateCity({ _id: value.cities._id, name: value.cities.name, regions: value.cities.region, status: value.cities.status }, props)
            }
        } else if (deleting !== null) {
            props.deleteCity(value, props)
        }
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'CITY', 'ADD', props)} data-toggle="modal" data-target="#addCity">+ City</button>
                </div>
                {props.isLoading
                    ? <div>
                        Loading...!
                    </div>
                    : <div className="card-body">
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                initialValues={editing === null ? deleting : editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                columns={columns}
                                                data={props.city}
                                                defaultPageSize={10}
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </div>}
            </div>

            {/* add city */}
            <div id="addCity" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Add City</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <React.Fragment>
                                <form id='addShelfLifeForm'>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Region :</td>
                                                <td>
                                                    <select className="form-control" onChange={e => setAddCity({ ...addCity, region: e.target.value })}>
                                                        <option value=''>- Select Region -</option>
                                                        {props.region.map(data => <option value={data._id}>{data.name}</option>)}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>City :</td>
                                                <td>
                                                    <input type="text" className="form-control" onChange={e => setAddCity({ ...addCity, name: e.target.value })} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Status:</td>
                                                <td>
                                                    <select className="form-control" onChange={e => setAddCity({ ...addCity, status: e.target.value })}>
                                                        <option value=''>- Select Status -</option>
                                                        {statusData.map(data => <option value={data.value}>{data.label}</option>)}
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </React.Fragment>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={addCityHandler}>Add</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        id: state.rLogin.id,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        city: state.rBasicData.city,
        region: state.rBasicData.region,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRegionData: props => { dispatch(getRegionData(props)) },
        getCityData: ({ token }) => { dispatch(getCity({ token })) },
        addCity: (data, props) => { dispatch(addCity(data, props)) },
        updateCity: (data, props) => { dispatch(updateCity(data, props)) },
        deleteCity: (data, props) => { dispatch(deleteCity(data, props)) },
        updateStatus: (data, props) => { dispatch(updateStatus(data, props)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(City)
