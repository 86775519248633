/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @file This file defined for back order details
 * @author Bhanuka Chathuranga
 */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Table from "react-table";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import _ from 'lodash';

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary';
import GridFilters from "../../components/GridFilter";
import Permission from '../../utils/Permission';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import ExportData from '../../utils/ExportData';
import { DateFormat, selectHandler, TIMEDATA } from '../../utils';

import { getCityData, getRegionData } from '../../../actions/Basic_data/basic_data_action';
import { getOrderData, getPurchaseItemData } from '../../../actions/Purchasing/purchasing_order_action';
// import toast from 'react-toastify';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 * To create purchase order for all suppliers within date range
 * @param {Array} data supplier's and items data
 * @param {Object} state To change state functions
 * @param {Function} state.setIsLoading To change isLoading state
 * @param {Function} state.getData To get new data
 * @param {Object} props props data
 */
const createPurchaseOrder = async (data, date, { setIsLoading, getData, setData, setSupplierData, setOrderItems }, props) => {
    setIsLoading(true)
    let uniqItem = _.uniqBy(data, v => [v.items._id, v.supplier._id, new Date(v.items.edd).toLocaleDateString()].join())
    let sortItem = _.sortBy(uniqItem, ['supplier.name', 'products.name', 'items.name'])
    // console.log(sortItem);
    let itemArr = await Promise.all(sortItem.map(obj => {
        if (obj.items.qty > 0) {
            let element = {
                supplier: obj.supplier._id,
                item_ref: obj.items._id,
                orderqty: obj.items.qty,
                orderArr: obj.items.orderArr,
                packArr: obj.items.packArr,
                isPackagingGroup: obj.items.isPackagingGroup,
                edd: new Date(obj.items.edd).toLocaleDateString(),
                recivqty: 0, balance: obj.items.qty,
                price: obj.items.purchasePrice,
                purchaseDiscount: obj.items.purchaseDiscount || 0
            }
            return element
        } else {
            return ''
        }
    }))
    let removeData = _.remove(itemArr, n => n === '')
    // console.log(itemArr);
    if (itemArr.length > 0) {
        axios.post('/purchasing/createPurchaseOrders', { itemArr })
            .then(result => {
                console.log(result)
                toast.success('success...!')
                getData(date, { setIsLoading, setData, setSupplierData, setOrderItems }, props)
                setIsLoading(false)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
                setIsLoading(false)
            })
    } else {
        setIsLoading(false)
        toast.info('No purchase order to create...!')
    }
}

/**
 * To handle input table value
 * @param {Object} e input object
 * @param {Object} e.target.value input value
 * @param {Object} props props data 
 * @param {Object} props.original props data at table row 
 */
const inputHandler = ({ target: { value } }, { original }) => {
    original.items.qty = Number(value)
}


/**
 * To get data to back order
 * @param {String} date date range to get data
 * @param {Object} state to change state function
 * @param {Function} state.setIsLoading to change isLoading state 
 * @param {Function} state.setData to change data state 
 * @param {Function} state.setSupplierData to change supplierData state 
 * @param {Function} state.setOrderItems to change orderItems state 
 * @param {Object} props props data
 */
const getData = async (date, { setIsLoading, setData, setSupplierData, setOrderItems }, props) => {
    setIsLoading(true)
    props.getOrderData(props.token);
    props.getPurchaseItem(props.token);
    setData([])
    setSupplierData([])
    setOrderItems([])
    axios.defaults.headers.common['authorization'] = props.token;
    axios.post('/purchasing/getBackOrders', { date })
        .then(result => {
            // console.log(result)
            setData(result.data)
            if (result.status === 200) {
                var suppliers = result.data.map(obj => {
                    // console.log(obj.supplier)
                    return { ...obj.supplier, etd: new Date(obj.items.edd).toLocaleDateString() };
                })
                var orderItem = result.data.map(obj => {
                    // console.log(obj.supplier)
                    let filterItem = _.filter(result.data, function (o) { return new Date(o.items.edd).toLocaleDateString() === new Date(obj.items.edd).toLocaleDateString() && o.orderitems.item === obj.orderitems.item })
                    // console.log(filterItem);
                    let ordersArr = filterItem.map(o => o.orders._id)
                    // console.log(ordersArr);
                    let sumQty = _.sumBy(filterItem, 'orderitems.quantity')
                    let packArr = filterItem.map(o => o.orderitems.quantity)
                    obj.items.qty = sumQty
                    obj.items.newQty = sumQty
                    obj.items.orderArr = ordersArr
                    obj.items.packArr = packArr
                    return { ...obj.orderitems, etd: new Date(obj.items.edd).toLocaleDateString(), qty: sumQty };
                })

                setSupplierData(_.uniqWith(suppliers, _.isEqual))
                // setSupplierData(_.uniqBy(suppliers, ['_id', 'etd']))
                setOrderItems(orderItem)
                // setPurchaseItems(purchaseItem)
                toast.success('success...!')
            } else {
                setData([])
                toast.info(result.data);
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            setData([])
            console.error(err)
            toast.error(`${err}`)
        })
}

const getCutOffRegionData = ({ cutOffDate, cutOffTime }, { selected, setSelected }, { region, token }) => {
    axios.defaults.headers.common['authorization'] = token;
    axios.get('/purchasing/getCutOffRegions', { headers: { authorization: token }, params: { cutOffDate, cutOffTime } })
        .then(({ data }) => {
            // console.log(data);
            let uniqRegion = _.uniqWith(data, _.isEqual)
            let regionData = uniqRegion.map(obj => {
                let regionVal = _.filter(region, { _id: obj })
                return regionVal
            })
            var regions = [].concat.apply([], regionData)
            // console.log(regions);
            setSelected(regions)
        }).catch(err => {
            console.error(err);
        })
}

function BackOrder(props) {

    var etd = useQuery().get("etd");
    var cutOffDate = useQuery().get("cutOffDate");
    // var city = useQuery().get("city");

    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);
    const [date, setDate] = useState(
        {
            cutOffDate: cutOffDate !== null ? DateFormat(cutOffDate, '00:00:00') : DateFormat('', '00:00:00'),
            cutOffTime: '00:00:00',
            to: cutOffDate !== null ? DateFormat(cutOffDate, '23:59:59') : DateFormat('', '23:59:59'),
            city: []
        })
    const [supplierData, setSupplierData] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    // const [purchaseItems, setPurchaseItems] = useState([]);
    const [edit, setEdit] = useState(null);
    const [remove, setRemove] = useState(null);
    const [selected, setSelected] = useState(null);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        props.getOrderData(props.token);
        props.getPurchaseItem(props.token);
        props.getCityData(props.token);
        props.getRegionData(props);
    }, [])

    useEffect(() => {
        // console.log(city);
        if (etd !== null && cutOffDate !== null) {
            let promise = Promise.resolve(setDate({ ...date, cutOffDate: cutOffDate, cutOffTime: '00:00:00', to: cutOffDate }))
            promise
                .then(() => {
                    console.log(date);
                    getData(date, { setIsLoading, setData, setSupplierData, setOrderItems }, props)
                    // getData(date, props)
                })
                .catch(e => console.log(e))
        }
    }, [cutOffDate])

    useEffect(() => {
        getCutOffRegionData(date, { selected, setSelected }, props)
    }, [date.cutOffTime])


    /**
     * To export data to excel
     */
    const exportData = async () => {
        if (data.length === 0 || data === null) {
            toast.info('No found data...!')
        } else {
            // console.log(data)
            let uniqItem = _.uniqBy(data, 'items._id')
            let sortItem = _.sortBy(uniqItem, ['supplier.name', 'products.name', 'items.name'])
            let uniqRegions = _.uniqBy(data, v => [v?.regions?._id, v.items._id])
            // let val1 = await data.filter(obj => obj.regions.name === regionObj.regions.name)
            // console.log(data);
            // console.log(uniqRegions);
            let regionData = await Promise.all(await uniqRegions.map(async regionObj => {
                let valData = await data.filter(obj => obj?.regions?.name === regionObj?.regions?.name && obj.items._id === regionObj.items._id)
                let val = _.sumBy(valData, 'orderitems.quantity')
                return {
                    _id: regionObj.items._id,
                    itemCode: regionObj.items.name,
                    name: regionObj?.regions?.name,
                    value: val
                }
            }))
            var checkData = sortItem.map(item => {
                let regionVal = regionData.filter(obj => obj._id === item.items._id)
                // let jsonData = JSON.stringify(regionVal)
                let val = regionVal.reduce((obj, ele) => { return { ...obj, [ele.name]: ele.value } }, {})
                // let val = { ...regionVal }
                let reObj = {
                    Supplier: item.supplier.name,
                    // Product: item.products.name,
                    Item: item.items.desc === null || item.items.desc === undefined || item.items.desc.toString().trim() === "" ?
                        item.items.name
                        : item.items.desc,
                    "Item Code": item.items.itemCode,
                    Qty: _.sumBy(_.filter(orderItems, { item: item.items._id }), 'quantity'),
                    Stock:item.expireStock
                }
                return Object.assign(reObj, val)
            })

            // uniqRegions.map(regions => {
            //     checkData[regions.name] = _.filter(data, { 'regions.name': `${regions.name}` }).length
            // })

            // console.log(regionData);

            var excelData = [];

            for (let i = 0; i < checkData.length; i++) {
                const element = checkData[i];

                if (i === 0 || element.Supplier === checkData[i - 1].Supplier) {
                    excelData.push(element)
                } else {
                    excelData.push({})
                    excelData.push(element)
                }
            }
            // Product: item.products.name,
            // let sortSupplier = _.sortBy(excelData, ['Supplier', 'Product', 'Item'])

            ExportData(`Purchase-Order-Items(${new Date(date.cutOffDate).toLocaleDateString()} - ${new Date(date.to).toLocaleDateString()})`, 'Purchase Item Details', "Purchase Item", excelData)
        }
    }

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const columns = [
        { Header: "Supplier Name", accessor: "name", ...GridFilters },
        { Header: "Expected Delivery Date", accessor: "etd", ...GridFilters },
        {
            Header: "Want to create a purchase order?",
            ...GridFilters,
            Cell: Props => {
                let itemData = _.filter(data, function (o) { return o.supplier._id === Props.original._id && new Date(o.items.edd).toLocaleDateString() === Props.original.etd })
                let uniqItem = _.uniqBy(itemData, 'items._id')
                // var etd = DateFormat(Props.original.etd, '18:00:00')
                var cutOffDate = DateFormat(date.cutOffDate, date.cutOffTime)
                // console.log(cutOffDate);
                if (uniqItem.length > 0) {
                    return <div>
                        <NavLink to={`/purchase/orders?supplier=${Props.original._id}&etd=${Props.original.etd}&cutOffDate=${cutOffDate}&city=${date.city}`} className='btn-link'>Yes</NavLink>
                    </div>
                } else {
                    return <div>No</div>
                }
            }, accessor: "create"
        },
    ];

    const subColumns = [
        {
            Header: "Item Name", accessor: "items.name", ...GridFilters,
            Cell: props =>
                <div>
                    {props.original.items.desc === null || props.original.items.desc === undefined || props.original.items.desc.toString().trim() === "" ?
                        props.original.items.name
                        : props.original.items.desc}
                </div>
        },
        { Header: "Item code", accessor: "items.itemCode", ...GridFilters },
        {
            Header: "Customer Order Qty(Date Rg)", accessor: "items.price",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {_.sumBy(_.filter(orderItems, { item: props.original.items._id }), 'quantity')} */}
                    {/* {_.filter(orderItems, { item: props.original.items._id })[0].qty} */}
                    {props.original.items.newQty}
                </div>
        },
        // {
        //     Header: "Purchase Qty(Date Rg)", accessor: "items.purchaseQty", ...GridFilters,
        //     Cell: Props =>
        //         <div style={{ textAlign: 'right' }}>
        //             {/* {console.log(props)} */}
        //             {Props.original.items.qty}
        //         </div>
        // },
        {
            Header: "Qty", accessor: "items.qty",
            Cell: Props => <input type='number' className='form-control' style={{ textAlign: 'right' }} defaultValue={Props.original.items.qty} onChange={e => inputHandler(e, Props)} />
            // ...editableNumberColumnProps
        },
        {
            Header: "Shortage", accessor: "items.price",
            Cell: Props =>
                <div style={{ textAlign: 'right' }}>
                    {(Props.original.stocks.reservationStock - Props.original.expireStock - (_.sumBy(_.filter(props.purchaseItemData, function (o) { if (o.purchaseitem && o.purchaseitem.item_ref === Props.original.items._id && o.purchaseitem.history === false) { return o } }), 'purchaseitem.balance'))) > 0 ?
                        Props.original.stocks.reservationStock - Props.original.expireStock - (_.sumBy(_.filter(props.purchaseItemData, function (o) { if (o.purchaseitem && o.purchaseitem.item_ref === Props.original.items._id && o.purchaseitem.history === false) { return o } }), 'purchaseitem.balance'))
                        : 0
                    }
                    {/* {
                        Props.original.stocks.reservationStock - Props.original.stocks.stock
                    } */}
                </div>
        },
        {
            Header: "Stock", accessor: "expireStock",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.expireStock}
                </div>
        },
        {
            Header: "Total Reservation stock", accessor: "stocks.reservationStock",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.stocks.reservationStock}
                </div>
        },
        {
            Header: "Total BackOrder Qty", accessor: "items.price",
            Cell: Props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {(Props.original.stocks.reservationStock - Props.original.expireStock) >= 0 ? (Props.original.stocks.reservationStock - Props.original.expireStock) : 0}
                </div>
        },
        {
            Header: "Total Purchase Qty", accessor: "items.price",
            Cell: Props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(Props)} */}
                    {/* {console.log(_.filter(props.purchaseItemData, { "purchaseitem.item_ref": Props.original.items._id }))} */}
                    {/* {console.log(_.filter(props.purchaseItemData, function (o) { if (o.purchaseitem.item_ref === Props.original.items._id && o.purchaseitem.history === false) { return o } }))} */}
                    {_.sumBy(_.filter(props.purchaseItemData, function (o) { if (o.purchaseitem && o.purchaseitem.item_ref === Props.original.items._id && o.purchaseitem.history === false) { return o } }), 'purchaseitem.balance')}
                </div>
        },

    ];

    const groupOptions = [
        { label: 'Region', options: props.region },
        { label: 'City', options: props.city },
    ]

    return (
        <div className="right_col" role="main">
            <div className="card">

                <div className="card-header" style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'inline-flex', paddingRight: '1%' }}>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            Cutoff Date :
                        </div>
                        <div>
                            <input type='date' className='form-control' defaultValue={date.cutOffDate.split('T')[0]} onChange={e => {
                                let cutOffDate = DateFormat(e.target.valueAsDate, '00:00:00')
                                setDate({ ...date, cutOffDate: cutOffDate })
                            }} />
                        </div>
                    </div>
                    {/* <div style={{ display: 'inline-flex', paddingRight: '1%' }}>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            To :
                        </div>
                        <div>
                            <input type='date' className='form-control' defaultValue={date.to.split('T')[0]}
                                onChange={e => {
                                    let to = DateFormat(e.target.valueAsDate, '23:59:59')
                                    setDate({ ...date, to: to })
                                }} />
                        </div>
                    </div> */}
                    <div style={{ display: 'inline-flex', paddingRight: '1%' }}>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            To :
                        </div>
                        <div>
                            <select className='form-control' onChange={e => { let cutOffDate = DateFormat(date.cutOffDate, `${e.target.value}:00`); setDate({ ...date, cutOffDate: cutOffDate, cutOffTime: `${e.target.value}:00` }) }}>
                                {TIMEDATA.map(({ _id, name }) => <option key={_id} value={_id}>{name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div style={{ width: '30%', paddingRight: '1%' }}>
                        {/* City : */}
                        <ErrorBoundary>
                            <Select
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                value={selected}
                                isMulti
                                placeholder={"Select Region or City"}
                                theme={theme => ({ ...theme, borderRadius: 0 })}
                                // options={props.city}
                                options={groupOptions}
                                onChange={e => {
                                    console.log(e)
                                    // selectHandler(e, date, setDate, props)
                                    if (e !== null) {
                                        selectHandler(e, date, setDate, props)
                                        setSelected(e)
                                    } else {
                                        setDate({ ...date, city: [] })
                                    }
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingLeft: '20px' }}>
                        <button className='btn btn-success' disabled={Permission('PURCHASE', 'BACK ORDER', 'VIEW', props) ? true : isLoading ? true : false}
                            onClick={() =>
                                getData(date, { setIsLoading, setData, setSupplierData, setOrderItems }, props)
                            }>
                            {isLoading ? <div><i class="fa fa-spinner fa-pulse" aria-hidden="true"></i> Loading...</div> : 'Show Data'}
                        </button>
                        <button className='btn btn-info' disabled={data.length > 0 ? false : true} onClick={exportData}>Export Data</button>
                        <button className='btn btn-success' disabled={Permission('PURCHASE', 'BACK ORDER', 'ADD', props) ? true : isLoading ? true : false}
                            onClick={() => { if (window.confirm('Do you wish to create purchase order for this date range?')) createPurchaseOrder(data, date, { setIsLoading, getData, setData, setSupplierData, setOrderItems }, props) }
                            }>
                            {isLoading ? <div><i class="fa fa-spinner fa-pulse" aria-hidden="true"></i> Loading...</div> : 'Create Order'}
                        </button>
                    </div>
                </div>

                <div className="card-body">
                    {isLoading
                        ? <>
                            <SkeletonTable columnsCount={2} dataCount={10} />
                        </>
                        : supplierData.length === 0
                            ? <>
                                No Data Found...!
                            </>
                            : <ErrorBoundary>
                                <Table
                                    columns={columns}
                                    data={supplierData}
                                    expanded={expanded}
                                    onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                    SubComponent={row => {
                                        // console.log(supplierData)
                                        // console.log(row.original)
                                        // let itemData = _.filter(data, { supplier: { _id: row.original._id } })
                                        let itemData = _.filter(data, function (o) { return o.supplier._id === row.original._id && new Date(o.items.edd).toLocaleDateString() === row.original.etd })
                                        let uniqItem = _.uniqBy(itemData, 'items._id')
                                        let sortItem = _.sortBy(uniqItem, ['supplier.name', 'products.name', 'items.name'])
                                        // console.log(itemData)
                                        // console.log(uniqItem)
                                        return (
                                            <div>
                                                {/* {itemData.map(d => d.items.name)} */}
                                                <FormProvider
                                                    form="inline"
                                                    // onSubmit={handleOrderSubmit}
                                                    onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                                    initialValues={edit !== null ? edit : remove}
                                                    enableReinitialize
                                                >
                                                    {formProps => {
                                                        return (
                                                            <form onSubmit={formProps.handleSubmit}>
                                                                <Table
                                                                    data={sortItem}
                                                                    columns={subColumns}
                                                                    getTrProps={(state, rowInfo) => {
                                                                        // console.log(state)
                                                                        // console.log(rowInfo)
                                                                        // console.log(rowInfo?.original?.items?._id)
                                                                        if ((rowInfo?.original?.stocks?.reservationStock - rowInfo?.original?.stocks?.stock -
                                                                            (_.sumBy(_.filter(props.purchaseItemData, function (o) { if (o.purchaseitem && o.purchaseitem.item_ref === rowInfo?.original?.items?._id && o.purchaseitem.history === false) { return o } }), 'purchaseitem.balance')) > 0)
                                                                        ) {
                                                                            // console.log(rowInfo.original.items._id)#65e883
                                                                            return {
                                                                                style: {
                                                                                    background: '#eeeb7f'
                                                                                }
                                                                            }
                                                                        } else if ((rowInfo?.original?.stocks?.reservationStock - rowInfo?.original?.stocks?.stock -
                                                                            (_.sumBy(_.filter(props.purchaseItemData, function (o) { if (o.purchaseitem && o.purchaseitem.item_ref === rowInfo?.original?.items?._id && o.purchaseitem.history === false) { return o } }), 'purchaseitem.balance')) < 0)) {
                                                                            return {
                                                                                // style: {
                                                                                //     background: '#65e883'
                                                                                // }
                                                                            }
                                                                        } else {
                                                                            return {}
                                                                        }
                                                                    }}
                                                                />
                                                            </form>)
                                                    }}
                                                </FormProvider>
                                            </div>
                                        )
                                    }}
                                />
                            </ErrorBoundary>}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        purchaseData: state.rOrder.activeData,
        purchaseItemData: state.rOrder.purchase_item_data,
        city: state.rBasicData.city,
        region: state.rBasicData.region,
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderData: token => { dispatch(getOrderData(token)) },
        getPurchaseItem: token => { dispatch(getPurchaseItemData(token)) },
        getCityData: token => { dispatch(getCityData(token)) },
        getRegionData: props => { dispatch(getRegionData(props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOrder);


