/**
 * Format date dd-MMM-YYYY
 * @param {String} date 
 * @returns Date String
 */
const DateNumberFormat = (date = '') => {
    let toDate = date === '' ? new Date() : new Date(date)
    let formatDate = toDate.toLocaleDateString('zh-Hans-CN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll('/', '-')
    return formatDate;
}

export default DateNumberFormat