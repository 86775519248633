import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropWrapper from '../../components/DropWrapper';
import Col from '../../components/Col';
import Item from '../../components/Item';
import axios from 'axios';
import { getOrderingData } from '../../../actions/Ordering/ordering_order_action';
// import './index.css'

// const MovableItem = ({ label, name, setItems }) => {

//     const changeItemColumn = (currentItem, columnName) => {
//         setItems(prevState => {
//             return prevState.map(e => {
//                 return { ...e, column: e.name === currentItem.name ? columnName : e.column }
//             })
//         })
//     }

//     const [{ isDragging }, drag] = useDrag({
//         item: { name: 'o_item', type: 'item' },
//         end: (item, monitor) => {
//             const dropResult = monitor.getDropResult();
//             if (dropResult && dropResult.name === 'Column 1') {
//                 changeItemColumn(item, 'Column 1')
//             } else {
//                 changeItemColumn(item, 'Column 2')
//             }
//         },
//         collect: (monitor) => ({
//             isDragging: monitor.isDragging()
//         }),
//     });
//     const opacity = isDragging ? 0.4 : 1;
//     return (
//         <div ref={drag} className='movable-item' style={{ opacity, height: '20px', backgroundColor: 'aqua' }}>
//             {label || name}
//         </div>
//     )
// }

// const DropableItem = ({ children, title }) => {
//     const [{ canDrop, isOver }, drop] = useDrop({
//         accept: 'item',
//         drop: () => ({ name: title }),
//         // collect: (monitor) => ({
//         //     isOver: monitor.isOver(),
//         //     canDrop: monitor.canDrop(),
//         // })
//     });

//     return (
//         <div className="card" ref={drop} style={{ width: '30%', height: '82vh' }}>
//             <div className="card-header">
//                 {title}
//             </div>
//             <div className="card-body">
//                 {children}
//             </div>
//         </div>
//     )
// }

const data = [{
    id: 1,
    icon: "⭕️",
    status: "open",
    title: "Human Interest Form",
    content: "Fill out human interest distribution form"
}, {
    id: 2,
    icon: "⭕️",
    status: "open",
    title: "Purchase present",
    content: "Get an anniversary gift"
}, {
    id: 3,
    icon: "⭕️",
    status: "open",
    title: "Invest in investments",
    content: "Call the bank to talk about investments"
}, {
    id: 4,
    icon: "⭕️",
    status: "Delivered",
    title: "Daily reading",
    content: "Finish reading Intro to UI/UX"
}];

const statuses = [{
    status: "Delivered",
    icon: "⭕️",
    color: "#EB5A46"
}, {
    status: "Initiated",
    icon: "🔆️",
    color: "#00C2E0"
}, {
    status: "in review",
    icon: "📝",
    color: "#C377E0"
}, {
    status: "done",
    icon: "✅",
    color: "#3981DE"
}];

// export const DeliveryPlan = props => {
//     // const [isFirstColumn, setIsFirstColumn] = useState(true)
//     const [items, setItems] = useState([
//         { id: 1, name: 'Item 1', column: 'Column 1' },
//         { id: 2, name: 'Item 2', column: 'Column 1' },
//         { id: 3, name: 'Item 3', column: 'Column 1' },
//     ])
//     // const Item = <MovableItem setIsFirstColumn={setIsFirstColumn} />
//     const returnItemsForColumn = columnName => {
//         return items.filter(item => item.column === columnName)
//             .map(item => <MovableItem key={item.id} name={item.name} setItems={setItems} />)
//     }


//     return (
//         <div className="right_col" role="main">
//             <DndProvider backend={HTML5Backend}>
//                 <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
//                     <DropableItem title='Column 1'>
//                         {/* {null} */}
//                         {/* {isFirstColumn && Item} */}
//                         {returnItemsForColumn('Column 1')}
//                     </DropableItem>

//                     <DropableItem title='Column 2'>
//                         {returnItemsForColumn('Column 2')}
//                         {/* {<MovableItem label={'order item '} />} */}
//                         {/* {!isFirstColumn && Item} */}
//                     </DropableItem>

//                     {/* <DropableItem>
//                         {!isFirstColumn && Item}
//                     </DropableItem> */}
//                 </div>
//             </DndProvider>
//         </div>
//     )
// }

export const DeliveryPlan = props => {

    const [items, setItems] = useState(data);

    useEffect(() => {
        // props.getOrderingData(props.token) ?
        axios.get('/ordering/getNotDeliveredOrder', { headers: { authorization: props.token } })
            .then(result => {
                console.log(result.data);
                setItems(result.data)
            }).catch(err => console.info(err))
    }, [])

    const onDrop = (item, monitor, status) => {
        const mapping = statuses.find(si => si.status === status);

        setItems(prevState => {
            const newItems = prevState
                .filter(i => i?.orders?._id !== item?.orders?._id)
                .concat({ ...item, "status": status, icon: mapping.icon });
            return [...newItems];
        });
    };

    const moveItem = (dragIndex, hoverIndex) => {
        const item = items[dragIndex];
        setItems(prevState => {
            const newItems = prevState.filter((i, idx) => idx !== dragIndex);
            newItems.splice(hoverIndex, 0, item);
            return [...newItems];
        });
    };

    return (
        <div className="right_col" role="main" >
            <div className="card">
                <div className="card-header">
                    <button onClick={() => console.log(items)}>
                        value
                    </button>
                </div>

                <div className="card-body row">
                    {/* <div style={{ display: 'flex', direction: 'row', justifyContent: 'center' }}> */}
                    {statuses.map(s => {
                        return (
                            <DndProvider backend={HTML5Backend}>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '20px', padding: '20px', backgroundColor: '#f5eaea', borderRadius: '5px' }}>
                                    <h2 style={{ fontSize: '20px', fontWeight: '600', marginBottom: '20px', marginTop: 0 }}>{s.status.toUpperCase()}</h2>
                                    <DropWrapper onDrop={onDrop} status={s.status}>
                                        <Col>
                                            {items
                                                .filter(i => i?.status === s.status)
                                                .map((i, idx) => <Item key={i.id} item={i} index={idx} moveItem={moveItem} status={s} />)
                                            }
                                        </Col>
                                    </DropWrapper>
                                </div>
                            </DndProvider>
                        );
                    })}
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        id: state.rLogin.id,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        data: state.rOrdering.data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getOrderingData: token => { dispatch(getOrderingData(token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPlan)

