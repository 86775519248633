/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const productsAsync = data => {
    return { type: 'FETCH_PRODUCTS', data }
}

export const supplierDataAsync = data => {
    return { type: 'FETCH_SUPPLIER_DATA', data }
}

export const productItemsAsync = data => {
    return { type: 'FETCH_PRODUCT_ITEMS', data }
}

export const itemsAsync = data => {
    return { type: 'FETCH_ITEMS', data }
}

export const stockHistoryAsync = data => {
    return { type: 'FETCH_STOCK_HISTORY', data }
}

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * 
 * @param {*} token 
 */
export const getSupplierData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/stock/getSupplierData')
            .then(result => {
                dispatch(calculateReservationStock(result.data, token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const calculateReservationStock = (suppliers, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/stock/getProductItems')
            .then(result => {
                var items = result.data;
                var finalData = [];

                suppliers.forEach(supplier => {
                    let totalStock = 0;
                    let resStock = 'No';
                    items.forEach(item => {
                        if (item.items.supplier_ref === supplier._id) {
                            if (item.stocks) {
                                totalStock = totalStock + item.stocks.reservationStock;
                            }
                        }
                    });

                    if (totalStock > 0) {
                        resStock = 'Yes';
                    }

                    var obj = {
                        '_id': supplier._id,
                        'name': supplier.name,
                        'reservationStock': resStock
                    }

                    finalData.push(obj);
                });

                dispatch(supplierDataAsync(finalData));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getProductItems = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/stock/getProductItems')
            .then(result => {
                const finalData = [];

                result.data.forEach(element => {

                    let resData;

                    if (element.stocks) {
                        resData = {
                            '_id': element.items._id,
                            'item_name': element.items.name + ' - ' + element.items.itemCode,
                            'item_desc': element.items.desc,
                            'durationUnit': element.items.durationUnit,
                            'duration': element.items.duration,
                            'priceUnit': element.items.priceUnit,
                            'price': element.items.price,
                            'purchasePrice': element.items.purchasePrice,
                            'product': element.items.product,
                            'supplier_ref': element.items.supplier_ref,
                            'stock_id': element.stocks._id,
                            'stock': element.stocks.stock,
                            'previousStock': element.stocks.stock,
                            'availableStock': element.stocks.availableStock,
                            'reservationStock': element.stocks.reservationStock,
                            'stockUnit': element.items.unit,
                            'remark': element.stocks.remark,
                            'reason': element.stocks.reason,
                        };
                    } else {
                        resData = {
                            '_id': element.items._id,
                            'item_name': element.items.name + ' - ' + element.items.itemCode,
                            'item_desc': element.items.desc,
                            'durationUnit': element.items.durationUnit,
                            'duration': element.items.duration,
                            'priceUnit': element.items.priceUnit,
                            'price': element.items.price,
                            'purchasePrice': element.items.purchasePrice,
                            'product': element.items.product,
                            'supplier_ref': element.items.supplier_ref,
                            'stockUnit': element.items.unit,
                            'stock_id': null,
                        };
                    }


                    finalData.push(resData)
                });

                dispatch(productItemsAsync(finalData))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateStocks = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/updateStocks', { data })
            .then(result => {
                //save in stock histories

                var stockId = data._id;
                delete data["_id"];

                var item = data.item_id;
                delete data["item_id"];

                data.stockId = stockId;
                data.stockDifference = Number(data.stock - data.previousStock);

                delete data["previousStock"];

                data.date = formatDate(new Date());
                data.item = item;

                data.slug = '1';

                if (data.stockDifference != 0) {
                    dispatch(updateStockHistory(data, token))
                } else {
                    toast.success('Successfully Updated Stock')
                    dispatch(getSupplierData(token))
                    dispatch(getProductItems(token))
                }
            }).catch(err => {
                toast.error(err)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateStockHistory = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/updateStockHistory', { data })
            .then(result => {
                window.$('#addStock').modal('hide');

                const data = {
                    '_id': result.data.insertedId,
                    'slug': result.data.insertedId
                }

                dispatch(updateStockHistorySlug(data, token))
            }).catch(err => {
                toast.error(err)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateStockHistorySlug = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/updateStockHistorySlug', { data })
            .then(result => {
                toast.success('Successfully Added / Updated Stock')
                dispatch(getSupplierData(token))
                dispatch(getProductItems(token))
            }).catch(err => {
                toast.error(err)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteStock = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/deleteStock', { data })
            .then(result => {
                toast.success('Stock Deleted Successfully!')
                dispatch(getSupplierData(token))
                dispatch(getProductItems(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteStockHistory = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/deleteStockHistory', { data })
            .then(result => {
                toast.success('Stock Deleted Successfully!')
                dispatch(getProductItems(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addStock = ({ stock, reservationStock, item, remark, availableStock, slug, userId }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/addStock', { data: { stock, reservationStock, item, remark, availableStock, slug } })
            .then(result => {
                var stockId = result.data.insertedId;

                const data = {
                    'stock': stock,
                    'reservationStock': reservationStock,
                    'item': item,
                    'remark': remark,
                    'availableStock': availableStock,
                    'stockDifference': 0,
                    'stockId': stockId,
                    'date': formatDate(new Date()),
                    'user': userId
                }

                dispatch(updateSlug(data, token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateSlug = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        const slugData = {
            '_id': data.stockId,
            'slug': data.stockId
        };
        axios.post('/stock/updateSlug', { slugData })
            .then(result => {
                data.slug = '1';
                dispatch(updateStockHistory(data, token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const getItems = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/stock/getProductItems')
            .then(result => {
                const finalData = [];

                result.data.forEach(element => {

                    let resData;

                    if (!element.stocks) {
                        resData = {
                            '_id': element.items._id,
                            'name': element.items.name,
                            'itemCode': element.items.itemCode,
                        };
                    }

                    if (resData) {
                        finalData.push(resData)
                    }
                });

                dispatch(itemsAsync(finalData))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateItemData = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/updateItemData', { data })
            .then(result => {
                toast.success('Item Sale Price / Purchase Price Updated!')
                dispatch(getSupplierData(token))
                dispatch(getProductItems(token))
            }).catch(err => {
                toast.error(err)
            })
    }
}