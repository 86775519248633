/**
 * 
 * @param {Array} accounts accounts data
 * @param {Array} types accounts types
 */
const accountClasify = (accounts, types) => {
    let filterArr = accounts.filter(ele => {
        let isExists = types.includes(ele.type)
        if(isExists){
            return ele
        }
    })
    return filterArr
}

export default accountClasify