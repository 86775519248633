/**
 * @author Ehsan Elahi
 */

import React, { createRef } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";
import Select from 'react-select';

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'
import ImageGallery from 'react-image-gallery';

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import { getProducts, getItems, updateItem, deleteItem, addItem, getNewSuppliers, getNewItems, getPromotionAndDiscountItems, getAllItems, getItemReportHeaderText, saveItemReportText, updateDays, reloadItemsData, getItemListNotifications, getRestockedItems } from '../../../actions/Basic_data/item_actions';
import { getItemTag } from '../../../actions/Basic_data/item_tag_action';
import { getShelfLives, getDeliveryTypes, getSuppliers, getCurrencyData, getBrandData } from '../../../actions/Basic_data/basic_data_action';
import ErrorBoundary from "../../components/ErrorBoundary";
import { string } from "prop-types";
import ReactToPrint from "react-to-print";
import ItemListPrint from "../Basic_Data/ItemListPrint";
import Permission from '../../utils/Permission';
import { getMeasurementData } from "../../../actions/Basic_data/measurement_actions";
import { NavLink } from "react-router-dom";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import { STOCK_UNIT as stockUnit, FROM as from, DateStringFormat, ExportData } from "../../utils";
import * as XLSX from 'xlsx';
import SearchSelectComponent from "../../components/SearchSelectComponent";
import SearchableSelectComp from '../../components/SearchableSelectComp';
import { addOutletItemPrice, getOutletItemPrice } from "../../../actions/POS/outlet_item_price_action";
import { getDeliveryWarehouses } from "../../../actions/Basic_data/delivery_warehouse_actions";
import { getExpireDateWithItem } from '../../../actions/Stock/stock_expire_action';
import moment from "moment";

const ReactTableFixedColumns = withFixedColumns(Table);
// const stockUnit = ['L', 'Kg', 'Pcs']

// const from = ['Local', 'Imported']

class Item extends React.Component {

  myRef = createRef(null)

  statusData = [
    { '_id': 'Active', 'name': 'Active' },
    { '_id': 'Inactive', 'name': 'Inactive' },
    { '_id': 'Out Of Stock', 'name': 'Out Of Stock' }
  ];

  durationUnitData = [
    { '_id': 'Days', 'name': 'Days' },
    { '_id': 'Months', 'name': 'Months' }
  ];

  state = {
    data: [],
    products: [],
    itemsData: [],
    expanded: {},
    deliveryTypes: [],
    suppliers: [],
    shelfLives: [],
    curriencies: [],
    editing: null,
    deleting: null,
    name: '',
    desc: '',
    deliveryType: '',
    supplier_ref: '',
    shelfLife: '',
    product: '',
    durationUnit: '',
    duration: '',
    priceUnit: 'LKR',
    price: '',
    originalPrice: '',
    status: '',
    itemCode: '',
    purchasePrice: '',
    discountPercentage: 0,
    leadtime: 0,
    slug: '',
    unit: '',
    from: '',
    measurement: '',
    images: '',
    updatedAt: Date.now(),
    createdAt: Date.now(),
    sortOptions: [{ id: 'supplier.name', desc: false }, { id: 'items.itemCode', desc: false }, { id: 'items.status', desc: false }],
    currentImages: [],
    reportText: '',
    createdUser: this.props.userId,
    modifiedUser: this.props.userId,
    status: 'active',
    packagingGroup: false,
    id: '',
    brand: '',
    purchaseDiscount: 0,
    minimumQty: '',
    incrementQty: '',
    itemTag: '',
    exportStatus: '',
    exportBrand: '',
    outletItemName: '',
    outletItemId: '',
    outletPrice: '',
    outletDiscount: '',
    outletData: '',
    outlet: [],
    userId: '',
    expireDate: '',
    expireDates: [],
    expireField: false,
    checkValue: false
  };

  // used to data filtering
  dataFilter = status => {
    window.$('#active').removeClass('active')
    window.$('#inActive').removeClass('active')
    window.$('#all').removeClass('active')
    window.$(`#${status}`).addClass('active')
    let data = _.filter(this.props.itemsData, function (o) { return o.items.status.toLowerCase() === status.toLowerCase() })
    this.setState({ data: status === 'all' ? this.props.itemsData : status === 'active' ? this.props.activeItemsData : data, status: status })
    return
  }

  componentDidMount() {
    this.props.getProducts(this.props.token)
    this.props.getItems(this.props.token)
    this.props.getShelfLives(this.props.token)
    this.props.getDeliveryTypes(this.props.token)
    this.props.getSuppliers(this.props.token)
    this.props.getCurrencyData(this.props.token)
    this.props.getMeasurement(this.props)
    this.props.getBrandData(this.props)

    this.props.getNewSuppliers(this.props.token)
    this.props.getNewItems(this.props.token)
    this.props.getRestockedItems(this.props)
    this.props.getPromotionAndDiscountItems(this.props.token)
    this.props.getAllItems(this.props.token)
    this.props.getItemListNotifications(this.props)
    this.props.getItemReportHeaderText(this.props.token)
    this.props.getItemTag(this.props)
    this.props.getDeliveryWarehouses(this.props);
    this.props.getOutletItemPrice({ userId: this.props.userId }, this.props.token)
    // this.dataFilter('active')
  }

  componentDidUpdate(prevProps) {
    const { deliveryWarehouseData, stockExpireItemsWithDate } = this.props;
    if (deliveryWarehouseData !== prevProps.deliveryWarehouseData || stockExpireItemsWithDate !== prevProps.stockExpireItemsWithDate) {

      let filterOutlet = [];

      deliveryWarehouseData.map(obj => {
        if (obj.outlet === true && obj.outlet !== undefined && obj.outlet !== null && obj.outlet !== '') {
          let data = {
            label: obj.name,
            value: obj._id
          }
          filterOutlet.push(data)
        }
      })

      let datesArr = stockExpireItemsWithDate.map(obj => {
          let data = {
            label: obj._id,
            value: obj._id
          }
          return data
      })

      this.setState({
        outlet: filterOutlet,
        userId: this.props.userId,
        expireDates:datesArr
      })
    }
  }

  formatDate = (date) => {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    var hours = '' + d.getHours();
    var minutes = '' + d.getMinutes();
    var seconds = '' + d.getSeconds();

    if (hours.length < 2) {
      hours = '0' + hours;
    }
    if (minutes.length < 2) {
      minutes = '0' + minutes;
    }
    if (seconds.length < 2) {
      seconds = '0' + seconds;
    }

    let newDate = [year, month, day].join('-');
    let time = [hours, minutes, seconds].join(':')

    return [newDate, time].join(' ');
  }

  editableComponent = ({ input, editing, value, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component {...input} {...rest} children={children} />;
  };

  editableColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = this.state.editing === props.original;
      const fieldProps = {
        component: this.editableComponent,
        editing,
        props
      };

      return <Field name={props.column.id} {...fieldProps} />;
    }
  };

  editableSelectComponent = ({ input, editing, value, ...rest }) => {
    const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component {...input} {...rest} children={children} />;
  };

  selectableColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = this.state.editing === props.original;
      // const fieldProps = {
      //   component: this.renderDropdownList,
      //   editing,
      //   props
      // };

      return (editing ? <Field name={props.column.id} component="select" className='form-control' defaultValue={props.value} onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
        {(
          props.column.Header === 'Delivery Type' ? this.props.deliveryTypes :
            props.column.Header === 'Shelf Life' ? this.props.shelfLives :
              props.column.Header === 'Price Unit' ? this.props.curriencies :
                props.column.Header === 'Status' ? this.statusData :
                  props.column.Header === 'Duration Unit' ? this.durationUnitData :
                    props.column.Header === 'Product' ? this.props.products :
                      props.column.Header === 'Stock Unit' ? stockUnit :
                        props.column.Header === 'From' ? from :
                          props.column.Header === 'Item Tag' ? this.props.itemTagData :
                            this.props.brand).map(data =>
                              <option key={data._id} value={props.column.Header === ('Price Unit' || 'From') ? data.code : props.column.Header === 'Product' ? data.products._id : props.column.Header === 'Stock Unit' ? data : props.column.Header === 'Item Tag' ? data.tag : data._id}>
                                {props.column.Header === 'Shelf Life' ? data.state : props.column.Header === 'Delivery Type' ? data.desc : props.column.Header === 'Product' ? data.categories.name + ' - ' + data.products.name : props.column.Header === 'Price Unit' ? data.name + ' - ' + data.code : props.column.Header === 'Stock Unit' ? data : props.column.Header === 'From' ? data : props.column.Header === 'Item Tag' ? data.tag : data.name}
                              </option>
                            )}
      </Field> : <label>{props.value}</label>)
    }
  };

  searchableColumnProps = {
    ...GridFilters,
    Cell: props => {
      let identifer = "Class"
      const editing = this.state.editing === props.original;
      const options = this.props.suppliers.map(data => ({ label: data.name, value: data._id }))

      return (editing ?
        <Field component={SearchSelectComponent} options={options} name={props.column.id} value={props.value} onChange={e => this.setStateForEdit(props.column.Header, e.value)} identifier={identifer} />
        : <label>{props.value}</label>)
    }
  };

  selectColumnProps = {
    ...GridFilters,
    Cell: Props => {
      const editing = this.state.editing === Props.original;
      // const fieldProps = {
      //   component: this.renderDropdownList,
      //   editing,
      //   Props
      // };

      return (editing ? <Field name={Props.column.id} component="select" className='form-control' defaultValue={Props.value} onBlur={e => this.setStateForEdit(Props.column.Header, e.target.value)}>
        {this.props?.mesurement?.map(data => {
          return <option key={data._id} value={data._id}>
            {data.note}
          </option>
        }
        )}
      </Field> : <label>{Props.value}</label>)
    }
  };

  imageColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = this.state.editing === props.original;
      // const fieldProps = {
      //   component: null,
      //   editing,
      //   props
      // };

      return (editing ? <Field name={props.column.id} component="image" className='form-control'>
        <input type="file" key='images' name='images' onChange={e => this.setState({ images: e.target.files[0] })}></input>
      </Field> : <label>{typeof (props.value) === string ? props.value : undefined}</label>)
    }
  };

  getActionProps = (gridState, rowProps) =>
    (rowProps && {
      mode: this.state.editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => {
          this.setState({
            editing: rowProps.original,
            status: rowProps.original.items.status,
            priceUnit: rowProps.original.items.priceUnit,
            durationUnit: rowProps.original.items.durationUnit,
            images: rowProps.original.items.images,
            product: rowProps.original.products?._id,
          })
        },
        onCancel: () => this.setState({ editing: null }),
        onDelete: () => {
          this.setState({ deleting: rowProps.original })
        }
      },
      updPerm: Permission('BASIC DATA', 'ITEM', 'UPDATE', this.props),
      delPerm: Permission('BASIC DATA', 'ITEM', 'DELETE', this.props)
    }) ||
    {};

  setStateForEdit = (column, value) => {
    if (column === 'Shelf Life') {
      this.setState({ shelfLife: value })
    } else if (column === 'Supplier') {
      this.setState({ supplier_ref: value })
    } else if (column === 'Status') {
      this.setState({ status: value })
    } else if (column === 'Duration Unit') {
      this.setState({ durationUnit: value })
    } else if (column === 'Price Unit') {
      this.setState({ priceUnit: value })
    } else if (column === 'Delivery Type') {
      this.setState({ deliveryType: value })
    } else if (column === 'Product') {
      this.setState({ product: value })
    } else if (column === 'Measurement') {
      this.setState({ measurement: value })
    } else if (column === 'Brand') {
      this.setState({ brand: value })
    } else if (column === 'Item Tag') {
      this.setState({ itemTag: value })
    }
  }

  handleSubmit = values => {
    if (this.state.deleting === null && this.state.editing !== null) {

      var data = values.items;
      var prevData = this.state.editing.items;

      data.shelfLife = this.state.shelfLife;
      data.deliveryType = this.state.deliveryType;
      data.supplier_ref = this.state.supplier_ref;
      data.priceUnit = this.state.priceUnit;
      data.durationUnit = this.state.durationUnit;
      data.status = this.state.status;
      data.discountPercentage = Number(data.discountPercentage);
      data.duration = Number(data.duration);
      data.price = Number(data.price);
      data.purchasePrice = Number(data.purchasePrice);
      data.measurement = this.state.measurement;
      data.images = this.state.images;
      // data.product = this.state.product;
      data.brand = this.state.brand;
      data.leadtime = Number(data.leadtime);
      data.originalPrice = Number(data.originalPrice);
      data.updatedAt = this.formatDate(Date.now());
      data.minimumQty = Number(data.minimumQty);
      data.incrementQty = Number(data.incrementQty);
      if (prevData.status === data.status) {
        data.check = null
      } else {
        data.check = true
      }

      this.props.updateItem({ ...data, modifiedUser: this.props.userId }, this.props.token)
      // this.dataFilter(this.state.status)

      this.setState({
        name: '',
        desc: '',
        mobileDescription: '',
        deliveryType: '',
        supplier_ref: '',
        product: '',
        shelfLife: '',
        priceUnit: '',
        price: '',
        durationUnit: '',
        duration: '',
        status: '',
        itemCode: '',
        purchasePrice: '',
        originalPrice: '',
        images: '',
        measurement: '',
        slug: '',
        brand: '',
        minimumQty: '',
        incrementQty: '',
        itemTag: ''
      });

    } else if (this.state.deleting !== null && this.state.editing === null) {
      let data = {
        '_id': values.items._id
      }

      this.props.deleteItem(data, this.props.token)
    }
    this.dataFilter(this.state.status)
  };

  exportItemList = (id) => {

    var tableEle = document.querySelector(id)
    var wb = XLSX.utils.table_to_book(tableEle, { sheet: 'Sheet Js' })
    wb.Props = { Title: `test`, Author: 'This file auto generated from system', Company: '© CIAO Pvt (Ltd).', CreatedDate: new Date() }
    XLSX.writeFile(wb, 'test.xlsx')
    // const wb = XLSX.utils.book_new();
    // wb.Props = { Title: `test`, Author: 'This file auto generated from system', Company: '© CIAO Pvt (Ltd).', CreatedDate: new Date() }
    // const wsAll = XLSX.utils.table_to_book()
    // XLSX.utils.book_append_sheet(wb, wsAll, `test`);
    // XLSX.writeFile(wb, `test.xlsx`);
  }

  saveReportTextHandle = () => {
    var { reportText } = this.state;

    if (reportText.trim() !== null) {

      this.props.saveItemReportText(this.state, this.props.token)

      this.setState({ reportText: '' });

      document.getElementById("itemRoprtTextForm").reset();

    } else {
      toast.error('Please fill up the mandatory fields!')
    }
  }

  addItemHandle = () => {
    var { name,
      // desc,
      deliveryType,
      supplier_ref,
      // product,
      shelfLife,
      priceUnit,
      price,
      durationUnit,
      duration,
      // originalPrice,
      // status,
      // itemCode,
      // purchasePrice,
      // discountPercentage,
      // leadtime,
      // createdAt,
      // updatedAt,
      // images, unit,
      from,
      status } = this.state;

    if (name.trim() !== ''
      && deliveryType !== ''
      && supplier_ref !== ''
      // && product !== ''
      && shelfLife !== ''
      && priceUnit !== ''
      && price !== ''
      && durationUnit !== ''
      && duration !== '' && from !== '' && status !== 'active') {

      this.state.slug = Date.now();
      let isPackagingChecked = document.getElementById('packagingGroupCheckBox').checked
      this.setState({ packagingGroup: isPackagingChecked })

      // var flag = false;
      // this.props.itemsData.forEach(element => {
      //   if (name === element.items.name) {
      //     flag = true;
      //   }
      // });

      // adding duplicate item name
      // if (flag) {
      //   toast.error('"' + name + '" already exists!');
      //   return;
      // }

      this.state.createdAt = this.formatDate(this.state.createdAt);
      this.state.updatedAt = this.formatDate(this.state.updatedAt);

      this.props.addItem(this.state, this.props.token)

      this.setState({
        name: '',
        desc: '',
        mobileDescription: '',
        deliveryType: '',
        supplier_ref: '',
        product: '',
        shelfLife: '',
        priceUnit: '',
        price: '',
        originalPrice: '',
        durationUnit: '',
        duration: '',
        status: '',
        itemCode: '',
        purchasePrice: '',
        discountPercentage: 0,
        leadtime: 0,
        slug: '',
        images: '',
        unit: '',
        from: '',
        measurement: '',
        brand: '',
        createdAt: Date.now(),
        updatedAt: Date.now(),
        minimumQty: '',
        incrementQty: '',
        purchaseDiscount:''
      });

      // document.getElementById("addItemForm").reset();

    } else {
      toast.error('Please fill up the mandatory fields!')
    }
  }

  updateDaysHandle = () => {
    const { _id } = this.state.id.items
    let val = []
    if (document.getElementById('monCheckbox').checked) val.push('mon')
    if (document.getElementById('tueCheckbox').checked) val.push('tue')
    if (document.getElementById('wedCheckbox').checked) val.push('wed')
    if (document.getElementById('thuCheckbox').checked) val.push('thu')
    if (document.getElementById('friCheckbox').checked) val.push('fri')
    if (document.getElementById('satCheckbox').checked) val.push('sat')
    if (document.getElementById('sunCheckbox').checked) val.push('sun')
    this.props.updateDays({ id: _id, val }, this.props)
    this.setState({ id: [] })
    if (val?.includes('mon')) { document.getElementById('monCheckbox').checked = false }
    if (val?.includes('tue')) { document.getElementById('tueCheckbox').checked = false }
    if (val?.includes('wed')) { document.getElementById('wedCheckbox').checked = false }
    if (val?.includes('thu')) { document.getElementById('thuCheckbox').checked = false }
    if (val?.includes('fri')) { document.getElementById('friCheckbox').checked = false }
    if (val?.includes('sat')) { document.getElementById('satCheckbox').checked = false }
    if (val?.includes('sun')) { document.getElementById('sunCheckbox').checked = false }
    return
  }

  handleRowExpanded = (rowsState, index) => {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]],
      },
    });
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined' && content !== '') {
      // filter by text in the table or if it's a object, filter by key
      if (typeof content === 'object' && content !== null && content.key) {
        return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
      } else {
        return String(content).toLowerCase().includes(filter.value.toLowerCase());
      }
    }

    return true;
  };

  ImageHandler = () => {

    const indexImage = this.myRef.current.getCurrentIndex();
    const { id } = this.state;

    var key = this.state.currentImages[indexImage].original;
    axios.post('/basic_data/item/deleteImage', { id, indexImage, key })
      .then(result => {
        window.$('#viewImage').modal('hide');
        this.props.getItems(this.props.token)
      }).catch(err => {
        toast.error(err.msg)
      })
  }

  packagingGroupHandler = (id) => {
    let eleId = `pakg-${id}`
    let isPackagingChecked = document.getElementById(eleId).checked
    axios.post('/basic_data/item/updatePackagingGroup', { id, isPackagingChecked })
      .then(result => {
        this.props.getItems(this.props.token)
      }).catch(err => {
        toast.error(err.msg)
      })
  }

  exportData = () => {
    let filtered = this.props.itemsData;

    if (this.state.exportBrand !== '') {
      filtered = _.filter(filtered, (item) => {
        return item.items.brand?.toString() === this.state.exportBrand.toString()
      })
    }

    if (this.state.exportStatus !== '') {
      filtered = _.filter(filtered, (item) => {
        return item.items.status === this.state.exportStatus
      })
    }

    var excelData = filtered.map(checkData => {
      let total = ((((checkData.items.price - (checkData.items.price * checkData.items.discountPercentage / 100)) - (checkData.items.purchasePrice - (checkData.items.purchasePrice * (checkData.items?.purchaseDiscount || 0) / 100))) / (checkData.items.price - (checkData.items.price * checkData.items.discountPercentage / 100)) * 100 || 0).toFixed(2))

      return {
        'Item Name': checkData.items.name,
        'Item Code': checkData.items.itemCode,
        'Brand': checkData.brand?.name,
        'Description': checkData.items.desc,
        'Price': checkData.items.price,
        'Minimum Quantity': checkData.items.minimumQty,
        'Increment Quantity': checkData.items.incrementQty,
        'Item Tag': checkData.items.itemTag,
        'Original Price': checkData.items.originalPrice,
        'Purchase Price': checkData.items.purchasePrice,
        'Discount Percentage': checkData.items.discountPercentage,
        'Purchase Discount': checkData.items.purchaseDiscount,
        'Margin': total.concat(' %'),
        'Price Unit': checkData.items.priceUnit,
        'Status': checkData.items.status,
        'Supplier': checkData.supplier?.name,
        'Duration Unit': checkData.items.durationUnit,
        'Duration': checkData.items.duration,
        'Delivery Type': checkData.deliverytypes.desc,
        'Stock Unit': checkData.items.unit,
        'From': checkData.items.from,
        'Shelf Life': checkData.shelflives.state,
        'Lead Time': checkData.items.leadtime,
        'Measurement': checkData.measurement?.note,
        'Created At': DateStringFormat(checkData.items.createdAt),
        'Updated At': DateStringFormat(checkData.items.updatedAt),
        'Created User': checkData.users?.name.first,
        'Updated User': checkData.modifiedUser[0]?.name.first,
      }
    })
    ExportData(`Item details`, 'Item details', "Item", excelData)
    this.closeExportModal()
  }

  AddOutletItem = () => {
    var { outletItemId, outletData, outletPrice, outletDiscount, userId, expireDate } = this.state;
    if (outletItemId !== '' && outletData !== '') {
      if (outletPrice !== '') {
        let data = {
          "item_ref": outletItemId,
          "outlet": outletData.value,
          "outlet_price": Number(outletPrice),
          "outlet_discount": Number(outletDiscount === "" ? 0 : outletDiscount),
          "user_ref": userId,
          "expireDate": expireDate.value === "" ? "" : expireDate.value
        }

        let expireDateValue =  expireDate.value !== undefined ?  expireDate.value : null
        let checkId = _.filter(this.props.outletItemPrices, function (val) {
          if (val.positemprice.item === outletItemId && outletData.value === val.positemprice.outlet && val.positemprice.expire_date === expireDateValue)
            return val
        })

        if (checkId.length === 0) {
          this.props.addOutletItemPrice(data, this.props)
        } else {
          toast.info('Already added...!')
        }

        this.setState({
          outletData: '',
          outletPrice: '',
          outletDiscount: '',
          expireDate: '',
          expireDates:[],
          expireField:false
        });

        document.getElementById("addOutletItemPriceModel").reset();

      } else {
        toast.error('Please fill the number!')
      }
    } else {
      toast.error('Please fill up the mandatory fields!')
    }
  }

  closeExportModal = () => {
    document.getElementById("exportForm").reset();
    window.$('#exportItems').modal('hide');
    this.setState({
      exportBrand: '',
      exportStatus: ''
    });
  }

  onSelect = (e, name, id) => {
    if (name === 'outlet') {
      let data = {
        itemId: id,
        warehouse: e.value
      };
      this.props.getExpireDateWithItem(data, this.props)
      this.setState({ outletData: e, expireField: true, expireDate: '' });
    }
    if (name === 'warehouse') {
      this.setState({ expireDate: e });
    }
  };

  toAbsoluteURL = (url) => {
    const absoluteURL = new URL(url).href;

    return absoluteURL;
  }

  productColumns = [
    { Header: "Name", accessor: "name", filterable: true }
  ];

  itemColumns = [
    { Header: "Name", accessor: "items.name", fixed: 'left', minWidth: 230, ...this.editableColumnProps, show: true },
    { Header: "Item Code", accessor: "items.itemCode", fixed: 'left', minWidth: 150, ...this.editableColumnProps, show: true },
    // { Header: "Product", accessor: "products.name", filterable: true, fixed: 'left', minWidth: 150, ...this.selectableColumnProps },
    { Header: "Brand", accessor: "brand.name", filterable: true, fixed: 'left', minWidth: 150, ...this.selectableColumnProps, show: true },
    { Header: "Description", accessor: "items.desc", ...this.editableColumnProps, minWidth: 200, show: true },
    { Header: "Mobile Description", accessor: "items.mobileDescription", ...this.editableColumnProps, minWidth: 200 },
    { Header: "Price", accessor: "items.price", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } }, show: true },
    { Header: "Minimum Quantity", accessor: "items.minimumQty", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Increment Quantity", accessor: "items.incrementQty", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Item Tag", accessor: "items.itemTag", filterable: true, minWidth: 150, ...this.selectableColumnProps, getProps: () => { return { style: { 'text-align': 'center' } } } },
    { Header: "Original Price", accessor: "items.originalPrice", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } }, show: true },
    { Header: "Purchase Price", accessor: "items.purchasePrice", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } }, show: true },
    { Header: "Discount Percentage", accessor: "items.discountPercentage", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } }, show: true },
    { Header: "Purchase Discount", accessor: "items.purchaseDiscount", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } }, show: true },
    {
      Header: "Margin",
      accessor: "item.originalPrice",
      show: true,
      getProps: () => { return { style: { 'text-align': 'right' } } },
      Cell: ({ value, original }) =>
        <>
          {(((original.items.price - (original.items.price * original.items.discountPercentage / 100)) - (original.items.purchasePrice - (original.items.purchasePrice * (original.items?.purchaseDiscount || 0) / 100))) / (original.items.price - (original.items.price * original.items.discountPercentage / 100)) * 100 || 0).toFixed(2)} %
        </>
    },
    { Header: "Price Unit", accessor: "items.priceUnit", ...this.selectableColumnProps },
    {
      Header: "Status", accessor: "items.status", ...this.selectableColumnProps, show: true,
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "Inactive") {
          return row[filter.id] === "Inactive";
        } else if (filter.value === "Active") {
          return row[filter.id] === "Active";
        }else if (filter.value === "Out Of Stock") {
          return row[filter.id] === "Out Of Stock";
        }
      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value={"all"}>Show All</option>
          <option value={"Active"}>Active</option>
          <option value={"Inactive"}>Inactive</option>
          <option value={"Out Of Stock"}>Out Of Stock</option>
        </select>,
      getProps: (state, rowInfo) => {
        if (rowInfo?.original?.items?.status === 'Inactive') {
          return {
            style: {
              background: '#eeeb7f'
            }
          }
        } else {
          return {}
        }
        // return { style: { background: '#eeeb7f' } }
      }
    },
    {
      Header: "Supplier",
      accessor: "supplier.name",
      show: true,
      ...this.searchableColumnProps,
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            cursor: 'pointer',
            overflow: 'visible'
          },
        };
      },
    },
    { Header: "Duration Unit", accessor: "items.durationUnit", ...this.selectableColumnProps },
    { Header: "Duration", accessor: "items.duration", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Delivery Type", accessor: "deliverytypes.desc", ...this.selectableColumnProps },
    { Header: "Stock Unit", accessor: "items.unit", ...this.selectableColumnProps },
    { Header: "From", accessor: "items.from", ...this.selectableColumnProps },
    { Header: "Shelf Life", accessor: "shelflives.state", ...this.selectableColumnProps },
    { Header: "Lead Time", accessor: "items.leadtime", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Measurement", accessor: "measurement.note", ...this.selectColumnProps },
    { Header: "Images", accessor: "items.images.url", ...this.imageColumnProps },
    {
      Header: "Created At", accessor: "items.createdAt", filterable: true,
      Cell: ({ value, original }) =>
        <>{DateStringFormat(original?.items.createdAt)} {new Date(original?.items.createdAt).toLocaleTimeString()}</>
    },
    {
      Header: "Updated At", accessor: "items.updatedAt", filterable: true,
      Cell: ({ value, original }) =>
        <>{DateStringFormat(original?.items.updatedAt)} {new Date(original?.items.updatedAt).toLocaleTimeString()}</>
    },
    { Header: "Created User", accessor: "users.name.first", filterable: true },
    { Header: "Updated User", accessor: "modifiedUser[0].name.first", filterable: true },
    {
      Header: "Hierarchy",
      accessor: "items._id",
      Cell: ({ value, original }) =>
        <>
          <NavLink to={`/basic data/createItemHierarchy?item=${value}&name=${original.items.name}`} className='btn btn-link'>
            {original?.categoryitem?.category?.length > 0 ? 'View' : 'Create'}
          </NavLink>
          {/* <button data-toggle="modal" data-target='#viewImage' className='btn btn-link'
          >Create</button> */}
        </>
    },
    {
      Header: "View Image",
      accessor: "items.images",
      Cell: row =>
        <>
          <button data-toggle="modal" data-target='#viewImage' className='btn btn-link'
            onClick={async () => {
              this.setState({ currentImages: [] })

              let images = row.value?.length === undefined ? [] : await Promise.all(row?.value?.map(ele => {

                var absoluteURL = ele ? ele.url ? this.toAbsoluteURL(ele.url) : '' : '';

                return {
                  original: absoluteURL,
                  thumbnail: absoluteURL
                }
              }))

              this.setState({ id: row.original.items._id, currentImages: images })
            }}
          >{row.value === undefined ? '' : row.value === null ? '' : row.value.length <= 0 ? '' : 'View Image'}</button>
        </>
    },
    {
      Header: "Price Outlet",
      accessor: "",
      Cell: row =>
        <>
          <button data-toggle="modal" data-target='#addOutletItemPrice' className='btn btn-link'
            disabled={row.original.items.status === undefined || row.original.items.status === null || row.original.items.status === "Inactive" ||
            Permission('POS','POS ITEM PRICE','ADD', this.props)}
            onClick={async () => {
              this.setState({ outletItemId: '', outletItemName: '', outletPrice: '', outletDiscount: '', outletData: '', expireDate: '', expireField: false, expireDates:[] })
              this.setState({ outletItemId: row.original.items._id, outletItemName: row.original.items.name })
            }}
          >Price Outlet</button>
        </>
    },
    {
      Header: "Fix delivery date",
      accessor: "items.images.url",
      Cell: row =>
        <>
          <button data-toggle="modal" data-target='#daysItem' className='btn btn-link'
            onClick={() => {
              this.setState({ id: [] })
              this.setState({ id: row.original })
              document.getElementById('monCheckbox').checked = row.original.items?.days?.includes('mon')
              document.getElementById('tueCheckbox').checked = row.original.items?.days?.includes('tue')
              document.getElementById('wedCheckbox').checked = row.original.items?.days?.includes('wed')
              document.getElementById('thuCheckbox').checked = row.original.items?.days?.includes('thu')
              document.getElementById('friCheckbox').checked = row.original.items?.days?.includes('fri')
              document.getElementById('satCheckbox').checked = row.original.items?.days?.includes('sat')
              document.getElementById('sunCheckbox').checked = row.original.items?.days?.includes('sun')
            }}
          >change day</button>
        </>
    },
    {
      Header: 'Packaging details',
      Cell: row => <>
        <div className="custom-control custom-switch">
          <input type="checkbox" id={`pakg-${row.original.items?._id}`} checked={row.original.items?.isPackagingGroup ? true : false}
            onClick={() => { if (window.confirm('Are you sure to change the packaging details for this item?')) this.packagingGroupHandler(row.original.items?._id) }} />
        </div>
      </>
    },
    {
      Header: "Actions",
      fixed: 'right',
      maxWidth: 130,
      show: true,
      minWidth: 130,
      filterable: false,
      getProps: this.getActionProps,
      Cell: ActionsCell
    },
  ];

  visibleColumns = this.itemColumns.filter(column => column.show);

  handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    this.setState({
      checkValue: isChecked ? true : false
    })
  };

  render() {
    let itemReportText = "";
    if (this.props.itemReportHeaderText != null && this.props.itemReportHeaderText.length > 0) {
      itemReportText = this.props.itemReportHeaderText[0].message
    }

    return (
      <div className="right_col" role="main">
        <div className="card">
          <div className="card-header">
            <div style={{ float: 'left' }}>
              <button className="btn btn-primary" onClick={() => this.props.reloadItemsData(this.props)}>
                <i className="fas fa-sync"></i>
              </button>
              <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'ITEM', 'ADD', this.props)} data-toggle="modal" data-target="#addItem">+ Item</button>
              <button className="btn btn-primary" data-toggle="modal" data-target="#itemList">Item List</button>
              <button className="btn btn-success" data-toggle="modal" data-target="#exportItems">Export Data</button>
            </div>
            <div style={{ textAlign: 'right' }} className="checkbox-wrapper-64">
              <label className="switch">
                <input type="checkbox" onClick={(e) => this.handleCheckboxChange(e)} />
                <span className="slider"></span>
              </label>
            </div>
            {/* <button className="btn btn-success" onClick={() => exportItemList(this.props)}>Export ItemList</button> */}
            {/* <div class="btn-group" role="group" aria-label="filter">
              <button id="active" type="button" class="btn btn-secondary" onClick={() => this.dataFilter('active')}>Active</button>
              <button id="inActive" type="button" class="btn btn-secondary" onClick={() => this.dataFilter('inActive')}>In-active</button>
              <button id="all" type="button" class="btn btn-secondary active" onClick={() => this.dataFilter('all')}>All</button>
            </div> */}
            {/* <ReactToPrint

              trigger={() =>
                <button className="btn btn-primary">Print</button>
              }
              content={() => this.componentRef}
            />
            <div style={{ display: "none" }}><ItemListPrint itemReportHeaderText={this.props.itemReportHeaderText} allItems={this.props.allItems} promotionsAndDiscounts={this.props.promotionsAndDiscounts} newItems={this.props.newItems} newSuppliers={this.props.newSuppliers} ref={el => (this.componentRef = el)} /></div> */}
          </div>
          <div className="card-body">
            {this.props.isLoading
              ? <>
                <SkeletonTable columnsCount={9} dataCount={10} />
              </>
              : <React.Fragment>
                <ErrorBoundary>
                  <FormProvider
                    form="inline"
                    onSubmit={this.handleSubmit}
                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null, shelflife: '', deliverytype: '', supplier: '' })}
                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                    enableReinitialize
                  >
                    {formProps => {
                      return (
                        <form onSubmit={formProps.handleSubmit}>
                          <ReactTableFixedColumns className="-striped"
                            sorted={this.state.sortOptions}
                            onSortedChange={val => {
                              this.setState({ sortOptions: val })
                            }}
                            columns={this.state.checkValue === false ? this.visibleColumns : this.itemColumns}
                            data={this.props.itemsData}
                            // data={this.state.status === 'all' ? this.props.itemsData : this.state.status === 'active' ? this.props.activeItemsData : this.state.data}
                            defaultPageSize={10}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            showPageJump={false}
                          />
                        </form>
                      );
                    }}
                  </FormProvider>
                </ErrorBoundary>
              </React.Fragment>}
          </div>

          {/* Items add */}
          <ErrorBoundary>
            <div id="addItem" className="modal fade" role="dialog">
              <div className="modal-dialog modal-xl">
                <div className="modal-content animate" >
                  <div className="modal-header">
                    <h4 className="modal-title text-uppercase">Add Item</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div className="modal-body">
                    <React.Fragment>
                      <form id='addItemForm'>
                        <table>
                          <tbody>

                            <tr>
                              <td>Name* :</td>
                              <td><input type="text" className="form-control" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} /></td>
                              {/* </tr>

                            <tr> */}
                              <td style={{ textAlign: 'right' }}>Description :</td>
                              <td><input type="textarea" className="form-control" value={this.state.desc} onChange={e => this.setState({ desc: e.target.value })} /></td>
                            </tr>

                            <tr>
                              <td>Mobile Description :</td>
                              <td colSpan={3}><textarea type='text' className='form-control' value={this.state.mobileDescription} maxLength={1000} onChange={e => this.setState({ mobileDescription: e.target.value })} /></td>
                            </tr>

                            <tr>
                              <td>Delivery Type* :</td>
                              <td>
                                <select className="form-control" value={this.state.deliveryType}  onChange={e => e.target.value === '' ? null : this.setState({ deliveryType: e.target.value })}>
                                  <option value={''}>- Select Delivery Type -</option>
                                  {this.props.deliveryTypes.map(deliverytype => {
                                    return <option key={deliverytype._id} value={deliverytype._id}>{deliverytype.desc}</option>
                                  })}
                                </select>
                              </td>
                              {/* </tr>

                            <tr> */}
                              {/* <td style={{ textAlign: 'right' }}>Packaging Group :</td> */}
                              <td colSpan={2} style={{ textAlign: 'right' }}>
                                <div class="custom-control custom-switch">
                                  <input type="checkbox" className="custom-control-input" id="packagingGroupCheckBox" />
                                  <label className="custom-control-label" for="packagingGroupCheckBox">Add Packaging Group</label>
                                </div>
                                {/* <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ product: e.target.value })}>
                                  <option value={''}>- Select Product -</option>
                                  {this.props.products.map(product => {
                                    return <option key={product.products._id} value={product.products._id}>{product.categories.name + ' - ' + product.products.name}</option>
                                  })}
                                </select>*/}
                              </td>
                            </tr>

                            <tr>
                              <td>Shelf Life* :</td>
                              <td>
                                <select className="form-control" value={this.state.shelfLife} onChange={e => e.target.value === '' ? null : this.setState({ shelfLife: e.target.value })}>
                                  <option value={''}>- Select Shelf Life -</option>
                                  {this.props.shelfLives.map(shelflife => {
                                    return <option key={shelflife._id} value={shelflife._id}>{shelflife.state}</option>
                                  })}
                                </select>
                              </td>
                              {/* </tr>

                            <tr> */}
                              <td style={{ textAlign: 'right' }}>Supplier* :</td>
                              <td>
                                {/* <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ supplier_ref: e.target.value })}>
                                  <option value={''}>- Select Supplier -</option>
                                  {this.props.suppliers.map(supplier => {
                                    return <option key={supplier._id} value={supplier._id}>{supplier.name}</option>
                                  })}
                                </select> */}
                                <SearchableSelectComp options={this.props.suppliers} value={this.state.supplier_ref} label={'name'} onChange={e => this.setState({ supplier_ref: e.value })} />
                              </td>
                            </tr>

                            <tr>
                              <td>Duration Unit* :</td>
                              <td>
                                <select className="form-control" value={this.state.durationUnit} onChange={e => e.target.value === '' ? null : this.setState({ durationUnit: e.target.value })}>
                                  <option value={''}>- Select Duration Unit -</option>
                                  {this.durationUnitData.map(element => {
                                    return <option key={element._id} value={element._id}>{element.name}</option>
                                  })}
                                </select>
                              </td>
                              {/* </tr>

                            <tr> */}
                              <td style={{ textAlign: 'right' }}>Duration* :</td>
                              <td><input type="number" className="form-control" value={this.state.duration} onChange={e => this.setState({ duration: Number(e.target.value) })} /></td>
                            </tr>

                            <tr>
                              <td>Price Unit* :</td>
                              <td>
                                <select className="form-control" value={this.state.priceUnit} onChange={e => e.target.value === '' ? null : this.setState({ priceUnit: e.target.value })}>
                                  <option value={''}>- Select Price Unit -</option>
                                  {this.props.curriencies.map(curriency => {
                                    if (curriency.code === 'LKR') {
                                      return <option value={curriency.code} selected={true}>{curriency.name} - {curriency.code}</option>
                                    } else {
                                      return <option value={curriency.code}>{curriency.name} - {curriency.code}</option>
                                    }
                                  })}
                                </select>
                              </td>
                              {/* </tr>

                            <tr> */}
                              <td style={{ textAlign: 'right' }}>Price* :</td>
                              <td><input type="number" value={this.state.price} className="form-control" onChange={e => this.setState({
                                price: Number(e.target.value),
                                originalPrice: Number(e.target.value)
                              })} /></td>
                            </tr>

                            <tr>
                              <td>Original Price* :</td>
                              <td><input type="number" className="form-control" disabled="disabled" value={this.state.originalPrice} /></td>
                              {/* </tr>

                            <tr> */}
                              <td style={{ textAlign: 'right' }}>Status* :</td>
                              <td>
                                <select className="form-control" value={this.state.status} onChange={e => e.target.value === '' ? null : this.setState({ status: e.target.value })}>
                                  <option value={''}>- Select Status -</option>
                                  {this.statusData.map(element => {
                                    return <option key={element._id} value={element._id}>{element.name}</option>
                                  })}
                                </select>
                              </td>
                            </tr>

                            <tr>
                              <td>Item Code :</td>
                              <td><input type="text" className="form-control" value={this.state.itemCode} onChange={e => this.setState({ itemCode: e.target.value })} /></td>
                              {/* </tr>

                            <tr> */}
                              <td style={{ textAlign: 'right' }}>Purchase Price :</td>
                              <td><input type="number" className="form-control" value={this.state.purchasePrice} onChange={e => this.setState({ purchasePrice: Number(e.target.value) })} /></td>
                            </tr>

                            <tr>
                              <td>Discount Percentage :</td>
                              <td><input type="number" className="form-control" value={this.state.discountPercentage} onChange={e => this.setState({ discountPercentage: Number(e.target.value) })} /></td>
                              {/* </tr>

                            <tr> */}
                              <td style={{ textAlign: 'right' }}>Lead Time (Hours) :</td>
                              <td><input type="number" className="form-control" value={this.state.leadtime} onChange={e => this.setState({ leadtime: Number(e.target.value) })} /></td>
                            </tr>

                            <tr>
                              <td>Unit :</td>
                              <td>
                                <select className="form-control" value={this.state.unit} onChange={e => e.target.value === '' ? null : this.setState({ unit: e.target.value })}>
                                  <option value={''}>- Select Unit -</option>
                                  {stockUnit.map(element => {
                                    return <option key={element} value={element}>{element}</option>
                                  })}
                                </select>
                              </td>
                              <td style={{ textAlign: 'right' }}>From* :</td>
                              <td>
                                <select className="form-control" value={this.state.from} onChange={e => e.target.value === '' ? null : this.setState({ from: e.target.value })}>
                                  <option value={''}>- Select -</option>
                                  {from.map(element => {
                                    return <option key={element} value={element}>{element}</option>
                                  })}
                                </select>
                              </td>
                            </tr>

                            <tr>
                              <td>Measurement :</td>
                              <td style={{ fontSize: "15px" }}>
                                {/* <select className='form-control' onChange={e => this.setState({ measurement: e.target.value })}>
                                  <option value={''}>- Select Measurement-</option>
                                  {this.props.mesurement.map(ele => {
                                    return <option key={ele._id} value={ele._id}>{ele.note}</option>
                                  })}
                                </select> */}
                                <SearchableSelectComp options={this.props.mesurement} value={this.state.measurement} label={'note'} onChange={e => this.setState({ measurement: e.value })} />
                              </td>
                              <td style={{ textAlign: 'right' }}>Brand :</td>
                              <td>
                                {/* <select className='form-control' onChange={e => this.setState({ brand: e.target.value })}>
                                  <option value={''}>- Select Brand -</option>
                                  {this.props.brand.map(ele => {
                                    return <option key={ele._id} value={ele._id}>{ele.name}</option>
                                  })}
                                </select> */}
                                <SearchableSelectComp options={this.props.brand} label={'name'}  value={this.state.brand} onChange={e => e.value === '' ? null : this.setState({ brand: e.value })} />
                              </td>
                            </tr>

                            <tr>
                              <td>Minimum Quantity :</td>
                              <td><input type="number" className="form-control" value={this.state.minimumQty}  onChange={e => this.setState({ minimumQty: Number(e.target.value) })} /></td>
                              {/* </tr>

                            <tr> */}
                              <td>Increment Quantity :</td>
                              <td><input type="number" className="form-control" value={this.state.incrementQty}  onChange={e => this.setState({ incrementQty: Number(e.target.value) })} /></td>
                            </tr>

                            <tr>
                              <td>Images :</td>
                              <td><input type="file" className="form-control-file" onChange={e => this.setState({ images: e.target.files[0] })} /></td>
                              <td style={{ textAlign: 'right' }}>Purchase Discount :</td>
                              <td>
                                <input type="number" className="form-control" value={this.state.purchaseDiscount}  onChange={e => this.setState({ purchaseDiscount: Number(e.target.value) })} />
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </form>
                    </React.Fragment>
                  </div>
                  <div className="modal-footer">
                    <button id="add_stock" type="button" className="btn btn-primary" onClick={this.addItemHandle}>Add</button>
                    {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </ErrorBoundary>

          <ErrorBoundary>
            <div id="itemList" className="modal fade" role="dialog">
              <div className="modal-dialog  modal-lg">
                <div className="modal-content animate" >
                  <div className="modal-header">
                    <ReactToPrint

                      trigger={() =>
                        <button className="btn btn-primary float-right"> Print</button>
                      }
                      content={() => this.componentRef}
                    />
                    <button className="btn btn-success" onClick={() => this.exportItemList('#items_list_table')}>Export ItemList</button>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>

                  <div className="modal-header">
                    <React.Fragment>
                      <form id='itemRoprtTextForm' style={{ width: '100%' }}>
                        <table style={{ width: '100%' }}>
                          <tbody>

                            <tr>
                              <td style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>Item Report Text :</td>
                              <td style={{ width: '70%' }}>
                                <textarea defaultValue={itemReportText} type="text" className="form-control" onChange={e => this.setState({ reportText: e.target.value })} />
                              </td>
                              <td>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <button id="update_text" type="button" className="btn btn-primary" onClick={this.saveReportTextHandle}>Save</button>
                                </div>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </form>
                    </React.Fragment>
                  </div>
                  <div className="modal-body">
                    <ItemListPrint
                      itemReportHeaderText={this.props.itemReportHeaderText}
                      allItems={this.props.allItems} promotionsAndDiscounts={this.props.promotionsAndDiscounts}
                      newItems={this.props.newItems}
                      newSuppliers={this.props.newSuppliers}
                      itemListMsg={this.props.itemListMsg}
                      restockedItems={this.props.restockedItems}
                      ref={el => (this.componentRef = el)} />
                  </div>

                </div>
              </div>
            </div>
          </ErrorBoundary>

          {/* Item image galleray */}
          <ErrorBoundary>
            <div id="viewImage" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content animate" >
                  <div className="modal-header">
                    <h4 className="modal-title text-uppercase">Image</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div className="modal-body">
                    {/* <input type='file' multiple={'multiple'} onChange={e => this.setState({ currentImages: e.target.files })} /> */}
                    <React.Fragment>
                      {this?.state?.currentImages?.length > 0
                        ? <ImageGallery
                          items={this.state.currentImages}
                          lazyLoad={true}
                          ref={this.myRef}
                          showIndex={true}
                        />
                        : 'No Image Found'
                      }
                    </React.Fragment>
                  </div>
                  <div className="modal-footer">
                    {this?.state?.currentImages?.length > 0
                      ? <button type="button" className="btn btn-primary" onClick={this.ImageHandler}>Delete Image</button>
                      : null}
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </ErrorBoundary>

          {/* Fixed delivery date */}
          <ErrorBoundary>
            <div id="daysItem" className="modal fade" role="dialog">
              <div className="modal-dialog  modal-lg">
                <div className="modal-content animate" >
                  <div className="modal-header">
                    <h4 className="modal-title text-uppercase">Fix delivery date</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div className="modal-body">
                    <div>
                      {_.toString(this.state.id.items?.days)}
                      {/* {this.state.id.items?.days.map(data => data)} */}
                    </div>
                    <div>
                      <div>days:</div>
                      <div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="monCheckbox" value="mon" defaultChecked={this.state.id.items?.days?.includes('mon')} />
                          <label className="form-check-label" htmlFor="monCheckbox">Mon</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="tueCheckbox" value="tue" defaultChecked={this.state.id.items?.days?.includes('tue')} />
                          <label className="form-check-label" htmlFor="tueCheckbox">Tue</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="wedCheckbox" value="wed" defaultChecked={this.state.id.items?.days?.includes('wed')} />
                          <label className="form-check-label" htmlFor="wedCheckbox">Wed</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="thuCheckbox" value="thu" defaultChecked={this.state.id.items?.days?.includes('thu')} />
                          <label className="form-check-label" htmlFor="thuCheckbox">Thu</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="friCheckbox" value="fri" defaultChecked={this.state.id.items?.days?.includes('fri')} />
                          <label className="form-check-label" htmlFor="friCheckbox">Fri</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="satCheckbox" value="sat" defaultChecked={this.state.id.items?.days?.includes('sat')} />
                          <label className="form-check-label" htmlFor="satCheckbox">Sat</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" id="sunCheckbox" value="sun" defaultChecked={this.state.id.items?.days?.includes('sun')} />
                          <label className="form-check-label" htmlFor="sunCheckbox">Sun</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button id="add_stock" type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.updateDaysHandle}>Add</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </ErrorBoundary>

          <ErrorBoundary>
            <div id="exportItems" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content animate" >
                  <div className="modal-header">
                    <h4 className="modal-title text-uppercase">Export Items</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div className="modal-body">
                    <form id="exportForm">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-2">
                            <label className="label">Status:</label>
                          </div>
                          <div className="col-md-10">
                            <select className="form-control" type="text" onChange={e => this.setState({ exportStatus: e.target.value })} >
                              <option value="">Select Status</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">InActive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-2">
                            <label className="label">Brand:</label>
                          </div>
                          <div className="col-md-10">
                            <select className="form-control" type="text" onChange={e => this.setState({ exportBrand: e.target.value })} >
                              <option value="">Select Brand</option>
                              {this.props.brand.map(brnd => {
                                return <option value={brnd._id}>{brnd.name}</option>
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={e => this.exportData()}>Export</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.closeExportModal()}>Close</button>
                  </div>
                </div>
              </div>
            </div>
          </ErrorBoundary>

          {/* Add outlet item */}
          <ErrorBoundary>
            <div id="addOutletItemPrice" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content animate" >
                  <div className="modal-header">
                    <h4 className="modal-title text-uppercase">Add Outlet Item Price</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div className="modal-body">
                    <React.Fragment>
                      <form id='addOutletItemPriceModel'>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Item</label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control form-control" value={this.state.outletItemName} disabled={true} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Outlet*</label>
                          <div className="col-sm-9">
                            <Select
                              options={this.state.outlet}
                              value={this.state.outletData}
                              theme={theme => ({ ...theme, borderRadius: 0 })}
                              onChange={(e) => this.onSelect(e, "outlet", this.state.outletItemId)}
                            />
                          </div>
                        </div>
                        {this.state.expireField === true?
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Expire Date</label>
                            <div className="col-sm-9">
                              <div>
                                <Select
                                  options={this.state.expireDates}
                                  value={this.state.expireDate}
                                  theme={theme => ({ ...theme, borderRadius: 0 })}
                                  onChange={(e) => this.onSelect(e, "warehouse")}
                                />
                              </div>
                            </div>
                          </div>
                          : null
                        }
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Outlet Price*</label>
                          <div className="col-sm-9">
                            <input type="number" className="form-control form-control" value={this.state.outletPrice} placeholder="Outlet Price" onChange={e => this.setState({ outletPrice: e.target.value })} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Discount</label>
                          <div className="col-sm-9">
                            <input type="number" className="form-control form-control" value={this.state.outletDiscount} placeholder="Discount" onChange={e => this.setState({ outletDiscount: e.target.value })} />
                          </div>
                        </div>
                      </form>
                    </React.Fragment>
                  </div>
                  <div className="modal-footer">
                    <button id="" type="button" className="btn btn-primary" onClick={this.AddOutletItem} >Add</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </ErrorBoundary>

        </div >
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.rItem.products,
    token: state.rLogin.token,
    isLoading: state.rLogin.loading,
    userId: state.rLogin.id,
    itemsData: state.rItem.itemsData,
    activeItemsData: state.rItem.activeItemsData,
    shelfLives: state.rBasicData.shelfLives,
    deliveryTypes: state.rBasicData.deliveryTypes,
    suppliers: state.rBasicData.suppliers,
    curriencies: state.rBasicData.currency_data,
    newSuppliers: state.rItem.newSuppliers,
    newItems: state.rItem.newItems,
    restockedItems: state.rItem.restockedItems,
    promotionsAndDiscounts: state.rItem.promotionsAndDiscounts,
    allItems: state.rItem.allItems,
    itemReportHeaderText: state.rItem.itemReportHeaderText,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    mesurement: state.rMeasurement.data,
    brand: state.rBasicData.brand,
    itemTagData: state.rItemTag.itemtags,
    itemListMsg: state.rItem.itemListMsg,
    deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
    outletItemPrices: state.rOutletItemPrices.outletItemPrices,
    stockExpireItemsWithDate: state.rStockItem.stockExpireItemsWithDate,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProducts: (token) => { dispatch(getProducts(token)) },
    reloadItemsData: props => { dispatch(reloadItemsData(props)) },
    getItems: (token) => { dispatch(getItems(token)) },
    updateItem: (data, token) => { dispatch(updateItem(data, token)) },
    deleteItem: (data, token) => { dispatch(deleteItem(data, token)) },
    getShelfLives: (token) => { dispatch(getShelfLives(token)) },
    getDeliveryTypes: (token) => { dispatch(getDeliveryTypes(token)) },
    getSuppliers: (token) => { dispatch(getSuppliers(token)) },
    getCurrencyData: (token) => { dispatch(getCurrencyData(token)) },
    getMeasurement: props => { dispatch(getMeasurementData(props)) },
    getBrandData: props => { dispatch(getBrandData(props)) },
    addItem: (data, token) => { dispatch(addItem(data, token)) },

    getNewSuppliers: (token) => { dispatch(getNewSuppliers(token)) },
    getNewItems: (token) => { dispatch(getNewItems(token)) },
    getRestockedItems: ({ token }) => { dispatch(getRestockedItems(token)) },
    getPromotionAndDiscountItems: (token) => { dispatch(getPromotionAndDiscountItems(token)) },
    getAllItems: (token) => { dispatch(getAllItems(token)) },
    getItemReportHeaderText: (token) => { dispatch(getItemReportHeaderText(token)) },
    getItemListNotifications: props => dispatch(getItemListNotifications(props)),
    saveItemReportText: (data, token) => { dispatch(saveItemReportText(data, token)) },
    updateDays: (days, { token }) => { dispatch(updateDays(days, token)) },
    getItemTag: props => { dispatch(getItemTag(props)) },
    addOutletItemPrice: (data, props) => { dispatch(addOutletItemPrice(data, props)) },
    getDeliveryWarehouses: (token) => { dispatch(getDeliveryWarehouses(token)) },
    getOutletItemPrice: (token) => { dispatch(getOutletItemPrice(token)) },
    getExpireDateWithItem: (data, props) => { dispatch(getExpireDateWithItem(data, props)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Item);


