import React, { useState, useEffect } from 'react'
import Table from "react-table";
import ErrorBoundary from '../../../components/ErrorBoundary';
import axios from 'axios';
import Select from 'react-select';
import { Field } from "redux-form";
import ActionsCell from '../../../components/ActionCell';
import { Permission } from '../../../utils';
import GridFilters from '../../../components/GridFilter';
import HighlightCell from '../../../components/HighlightCell';
import * as BS from "react-bootstrap";
import _ from 'lodash';
import { toast } from 'react-toastify';
import FormProvider from '../../../components/FormProvider';

const ReplacementModal = ({ itemData, orderItemsData, orderItem, props }) => {

    const [orderItemsForReplacement, setOrderItemsForReplacement] = useState([]);
    const [edit, setEdit] = useState(null)
    const [remove, setRemove] = useState(null)

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: edit === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEdit(rowProps.original),
                onCancel: () => setEdit(null),
                onDelete: () => setRemove(rowProps.original)
            },
            updPerm: Permission('ORDERS', 'ORDER', 'UPDATE', props),
            delPerm: Permission('ORDERS', 'ORDER', 'DELETE', props)
        }) ||
        {};

    const editableNumberComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component type="number"  {...input} {...rest} min={0} children={children} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    };

    const editableNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableNumberComponent,
                editing,
                props
            };
            return (props.original.orders !== undefined && (props.column.Header !== 'Amount Paid' && props.column.Header !== 'Delivery Charges') && props.original.orders.amountPaid !== 0) ?
                <div style={{ textAlign: 'right' }}>{props.value}</div>
                : <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const orderItemsReplacementColumns = [
        { Header: "Name", accessor: "name", filterable: true },
        { Header: "Item Code", accessor: "itemCode", filterable: true },
        {
            Header: "Price", accessor: "price", ...editableNumberColumnProps
        },
        {
            Header: "Duration", accessor: "duration", filterable: true,
            Cell: props =>
                <div>
                    {props.original.duration} {props.original.durationUnit}
                </div>
        },
        { Header: 'Qty', accessor: "qty", ...editableNumberColumnProps },
        { Header: 'Discount %', accessor: "discount", ...editableNumberColumnProps },
        {
            Header: "",
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const handleOrderItemForReplacementSubmit = (value) => {
        if (edit !== null) {

            let data = _.filter(orderItemsForReplacement, { '_id': value._id })
            data[0].price = Number(value.price)
            data[0].qty = Number(value.qty)
            data[0].discount = Number(value.discount)


        } else if (remove !== null) {
            _.remove(orderItemsForReplacement, n => { return n._id === value._id })
        }
    }

    const proceed = () => {
        const data = {
            orderItemId: orderItem._id,
            items: orderItemsForReplacement
        }

        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/ordering/replaceOrderItems', { data })
            .then(result => {
                clear()
                props.reloadData(props)
            }).catch(err => {
                console.error(err)
            })
    }

    const clear = () => {
        setOrderItemsForReplacement([])
    }

    return (
        <div id='replacementModal' key='replacementModal' className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg ">
                <div className="modal-content animate ">
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase">Select Order Item</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={e => clear()}>&times;</button>
                    </div>
                    <div className='modal-body'>
                        <Select
                            options={_.filter(itemData, item => { return item._id !== orderItem?.item_id })}
                            value={[]}
                            onChange={e => {
                                let checkId = _.filter(orderItemsData, function (o) {
                                    return o.items._id === e._id
                                })

                                let addCheckId = _.filter(orderItemsForReplacement, function (o) {
                                    return o._id === e._id
                                })

                                if (checkId.length === 0 && addCheckId.length === 0) {
                                    setOrderItemsForReplacement([...orderItemsForReplacement, { ...e, qty: 1, discount: e.discountPercentage === null ? 0 : e.discountPercentage }])
                                } else {
                                    toast.info('Already added this item. Please modify it...!')
                                }
                            }}
                        />

                        {orderItemsForReplacement.length > 0 ?
                            <ErrorBoundary>
                                <div>
                                    <FormProvider
                                        form="inline"
                                        onSubmit={handleOrderItemForReplacementSubmit}
                                        onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                        initialValues={edit !== null ? edit : remove}
                                        enableReinitialize
                                    >
                                        {formProps => {
                                            return (
                                                <form onSubmit={formProps.handleSubmit}>
                                                    <Table
                                                        columns={orderItemsReplacementColumns}
                                                        data={orderItemsForReplacement.reverse()}
                                                        defaultPageSize={5}
                                                        showPagination={true}
                                                    />
                                                </form>
                                            )
                                        }}
                                    </FormProvider>
                                </div>
                            </ErrorBoundary>
                            : null}
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={e => proceed()} >Proceed</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => clear()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReplacementModal
