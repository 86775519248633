/**
 * @author Ravindra Pushpa Kumara
 */

import React from "react";
import { connect } from 'react-redux';
import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
// import _ from 'lodash';
import { toast } from "react-toastify";
import withFixedColumns from 'react-table-hoc-fixed-columns';

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getUsers, updateUser, deleteUser, addUser } from '../../../actions/Administrator/user_actions';

import "react-table/react-table.css";
import 'react-table-hoc-fixed-columns/lib/styles.css';

const ReactTableFixedColumns = withFixedColumns(Table);


class User extends React.Component {

    statusData = [
        { '_id': true, 'name': 'Yes' },
        { '_id': false, 'name': 'No' }
    ];

    genderData = [
        { '_id': 'Male', 'name': 'Male' },
        { '_id': 'Female', 'name': 'Female' }
    ];

    userType = [
        { '_id': 'Admin', 'name': 'Admin' },
        { '_id': 'Data Operator', 'name': 'Data Operator' },
        { '_id': 'Corporate', 'name': 'Corporate' },
        { '_id': 'Normal', 'name': 'Normal' },
        { '_id': 'Delivery', 'name': 'Delivery' }
    ];

    state = {
        users: [],

        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        userType: '',
        phone: '',
        gender: '',
        isPhoneVerified: false,
        isEmailVerified: false,
        isApproved: false,
        isAdmin: false,
        images: '',
        sortOptions: [{ id: 'name.first', desc: false }],

    };

    componentDidMount() {
        this.props.getUsers(this.props.token)
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            const url = "https://api.whatsapp.com/send?phone=" + props.value;
            return props.column.Header === 'Whatsapp' && !editing ? <><u><a href={url} target="_blank" >{props.value}</a></u></> : <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;

            return (editing ?
                <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                    {(
                        props.column.Header === 'Gender' ?
                            this.genderData : props.column.Header === 'User Type' ?
                                this.userType : this.statusData).map(data =>
                                    <option key={data._id} value={data._id}>{data.name}</option>
                                )}
                </Field>
                :
                <label>
                    {
                        (
                            props.column.Header === 'Phone verified' ||
                            props.column.Header === 'Email verified' ||
                            props.column.Header === 'Approved' ||
                            props.column.Header === 'Admin'
                        ) ?
                            (props.value ? "Yes" : "No") : props.value
                    }</label>
            )
        }
    };

    imageColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            // const fieldProps = {
            //     component: null,
            //     editing,
            //     props
            // };

            return (editing ? <Field name={props.column.id} component="image" className='form-control'>
                <input type="file" key='images' name='images' onChange={e => this.setState({ images: e.target.files[0] })}></input>
            </Field> : <label>{props.value}</label>)
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('ADMINISTRATOR', 'USER', 'UPDATE', this.props),
            delPerm: Permission('ADMINISTRATOR', 'USER', 'DELETE', this.props)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === 'Phone verified') {
            if (value == "true") {
                this.setState({ isPhoneVerified: true })
            } else {
                this.setState({ isPhoneVerified: false })
            }
        } else if (column === 'Email verified') {
            if (value == "true") {
                this.setState({ isEmailVerified: true })
            } else {
                this.setState({ isEmailVerified: false })
            }
        } else if (column === 'Approved') {
            if (value == "true") {
                this.setState({ isApproved: true })
            } else {
                this.setState({ isApproved: false })
            }
        } else if (column === 'Admin') {
            if (value == "true") {
                this.setState({ isAdmin: true })
            } else {
                this.setState({ isAdmin: false })
            }
        }
    }

    handleSubmit = values => {
        if ((this.state.deleting === null || this.state.deleting === undefined) && (this.state.editing !== null && this.state.editing !== undefined)) {
            var data = values;
            if (data.name.first.trim() == ''
                || data.email == ''
                || data.phone == ''
                || data.gender == ''
                || data.userType == '') {
                toast.error('Please fill up the mandatory fields!')
            } else {

                if (this.state.isEmailVerified != '') {
                    data.isEmailVerified = this.state.isEmailVerified;
                }

                if (this.state.isPhoneVerified != '') {
                    data.isPhoneVerified = this.state.isPhoneVerified;
                }

                if (this.state.isApproved != '') {
                    data.isApproved = this.state.isApproved;
                }

                if (this.state.isAdmin != '') {
                    data.isAdmin = this.state.isAdmin;
                }

                if (this.state.images != '') {
                    data.images = this.state.images;
                }

                // clear password
                delete data["password"];

                this.props.updateUser(data, this.props.token)

                this.setState({
                    isPhoneVerified: false,
                    isEmailVerified: false,
                    isApproved: false,
                    isAdmin: false,
                    images: ''
                });
            }

        } else if ((this.state.deleting !== null && this.state.deleting !== undefined) && (this.state.editing === null || this.state.editing === undefined)) {
            var data = {
                '_id': this.state.deleting._id
            }

            this.props.deleteUser(data, this.props.token)
        }
    };

    changePasswordHandle = () => {
        if (this.state.passwordChanging !== null && this.state.passwordChanging !== undefined) {
            var data = this.state.passwordChanging;
            if (this.state.password == ''
                || this.state.confirmPassword == ''
            ) {
                toast.error('Please fill up the mandatory fields!')
            } else if (this.state.password != this.state.confirmPassword) {
                toast.error('Passwords must match!')
            } else if (this.state.password.length < 5) {
                toast.error('Password must be longer than 5 characters!')
            } else {
                data.password = this.state.password;
                this.props.updateUser(data, this.props.token)

                this.setState({
                    confirmPassword: '',
                    password: ''
                });
            }
        }
    }
    addUserHandle = () => {
        var { firstName,
            // lastName,
            // isPhoneVerified,
            // isEmailVerified,
            // isApproved,
            // isAdmin,
            // images,
            email,
            password,
            confirmPassword,
            userType,
            phone,
            gender } = this.state;

        if (firstName.trim() !== ''
            && email !== ''
            && password !== ''
            && confirmPassword !== ''
            && phone !== ''
            && gender !== ''
            && userType !== ''
        ) {

            if (confirmPassword !== password) {
                toast.error('Passwords must match!')
            } else if (this.state.password.length < 5) {
                toast.error('Password must be longer than 5 characters!')
            } else {
                this.state.slug = this.state.firstName + '-' + this.state.lastName;

                this.props.addUser(this.state, this.props.token)

                this.setState({
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    userType: 'Admin',
                    phone: '',
                    gender: 'Male',
                    isPhoneVerified: false,
                    isEmailVerified: false,
                    isApproved: false,
                    isAdmin: false,
                    images: ''
                });

                document.getElementById("addUserForm").reset();
            }

        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    userColumns = [
        { Header: "First Name", accessor: "name.first", ...this.editableColumnProps },
        { Header: "Last Name", accessor: "name.last", ...this.editableColumnProps },
        { Header: "Gender", accessor: "gender", ...this.selectableColumnProps },
        { Header: "Email", accessor: "email", ...this.editableColumnProps },
        { Header: "Phone", accessor: "phone", ...this.editableColumnProps },
        { Header: "Whatsapp", accessor: "whatsapp", ...this.editableColumnProps },
        { Header: "Device", accessor: "device", ...this.editableColumnProps },
        { Header: "Device Type", accessor: "deviceType", ...this.editableColumnProps },
        { Header: "User Type", accessor: "userType", ...this.selectableColumnProps },
        {
            Header: "Password", accessor: "password",

            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#changePassword' className='btn btn-link'
                        onClick={() => {
                            this.state.passwordChanging = row.original;
                        }}
                    >Change</button>
                </>
        },
        {
            Header: "Phone verified",
            accessor: "isPhoneVerified",
            filterable: true,
            filterMethod: (filter, row) => {
                if (filter.value === '') {
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) => {
                return <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }
                    }
                    value={filter ? filter.value : ''}
                >
                    <option value={""}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>
            },
            ...this.selectableColumnProps
        },
        {
            Header: "Email verified",
            accessor: "isEmailVerified",
            filterMethod: (filter, row) => {
                if (filter.value === '') {
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) => {
                return <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }
                    }
                    value={filter ? filter.value : ''}
                >
                    <option value={""}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>
            },
            ...this.selectableColumnProps,
        },
        {
            Header: "Approved",
            accessor: "isApproved",
            filterMethod: (filter, row) => {
                if (filter.value === '') {
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) => {
                return <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }
                    }
                    value={filter ? filter.value : ''}
                >
                    <option value={""}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>
            },
            ...this.selectableColumnProps,
        },
        { Header: "Created At", accessor: "createdAt", ...this.selectableColumnProps },
        {
            Header: "Admin",
            accessor: "isAdmin",
            filterMethod: (filter, row) => {
                if (filter.value === '') {
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) => {
                return <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }
                    }
                    value={filter ? filter.value : ''}
                >
                    <option value={""}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>
            },
            ...this.selectableColumnProps,
        },
        { Header: "Image", accessor: "userImage.url", ...this.imageColumnProps },
        {
            Header: "Photo",
            accessor: "userImage.url",
            Cell: row =>
                <>
                    {row.original.userImage !== undefined ?
                        <button data-toggle="modal" data-target='#viewImage' className='btn btn-link'
                            onClick={() => {
                                this.state.currentUserImage = row.value;
                            }}
                        >Photo</button>
                        : null}
                </>
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('ADMINISTRATOR', 'USER', 'ADD', this.props)} data-toggle="modal" data-target="#addUser">+ User</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.userColumns}
                                                    data={this.props.users}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>
                    <div id="viewImage" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Image</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        {(this.state.currentUserImage === undefined) ? 'No Image Found' : <img src={this.state.currentUserImage} width="100%"></img>}

                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="addUser" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add User</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addUserForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>First Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ firstName: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Last Name :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ lastName: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Email* :</td>
                                                        <td><input type="email" className="form-control" onChange={e => this.setState({ email: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Password* :</td>
                                                        <td><input type="password" placeholder="Password" className="form-control" onChange={e => this.setState({ password: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td></td>
                                                        <td><input type="password" placeholder="Confirm Password" className="form-control" onChange={e => this.setState({ confirmPassword: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>User Type* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ userType: e.target.value })}>
                                                                <option value={''}>- Select -</option>
                                                                {this.userType.map(element => {
                                                                    return <option key={element._id} value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Phone* : (+94)(0)</td>
                                                        <td><input value={this.state.phone} type="text" maxLength="9" className="form-control" onChange={e => this.setState({ phone: e.target.value.replace(/\D/, '') })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Gender* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ gender: e.target.value })}>
                                                                <option value={''}>- Select -</option>
                                                                {this.genderData.map(element => {
                                                                    return <option key={element._id} value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Photo :</td>
                                                        <td><input type="file" className="form-control" onChange={e => this.setState({ images: e.target.files[0] })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is Approved</td>
                                                        <td><input value={this.state.isApproved} type="checkbox" onChange={e => this.setState({ isApproved: e.target.checked })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is Email Verified</td>
                                                        <td><input value={this.state.isEmailVerified} type="checkbox" onChange={e => this.setState({ isEmailVerified: e.target.checked })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is Phone Verified</td>
                                                        <td><input value={this.state.isPhoneVerified} type="checkbox" onChange={e => this.setState({ isPhoneVerified: e.target.checked })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is Admin</td>
                                                        <td><input value={this.state.isAdmin} type="checkbox" onChange={e => this.setState({ isAdmin: e.target.checked })} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="add_user" data-dismiss="modal" type="button" className="btn btn-primary" onClick={this.addUserHandle}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="changePassword" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Change Password</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='changePasswordForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Password* :</td>
                                                        <td><input value={this.state.password} type="password" placeholder="Password" className="form-control" onChange={e => this.setState({ password: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td></td>
                                                        <td><input value={this.state.confirmPassword} type="password" placeholder="Confirm Password" className="form-control" onChange={e => this.setState({ confirmPassword: e.target.value })} /></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="change_password" data-dismiss="modal" type="button" className="btn btn-primary" onClick={this.changePasswordHandle}>Change</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        users: state.rUser.users,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        isLoading: state.rLogin.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: (token) => { dispatch(getUsers(token)) },
        updateUser: (data, token) => { dispatch(updateUser(data, token)) },
        deleteUser: (data, token) => { dispatch(deleteUser(data, token)) },
        addUser: (data, token) => { dispatch(addUser(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);


