/**
 * 
 */

import _ from 'lodash';

const initialState = {
    surveyQuestions: [],
    activeSurveyQuestions: [],
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerSurveyQuestions = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_SURVEY_QUESTIONS':
            var { data } = action;
            newState.surveyQuestions = data;

            var activeSurveyQuestions = _.filter(data, function (o) {
                return o.active === 'Yes';
            });

            newState.activeSurveyQuestions = activeSurveyQuestions;

            break;

        default:
            break;
    }
    return newState;
}

export default reducerSurveyQuestions;