/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";

import axios from 'axios';

import { toast } from "react-toastify";
import _ from 'lodash';

import $ from 'jquery';

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';
import SearchableSelectComp from '../../components/SearchableSelectComp'
import { DateNumberFormat } from "../../utils";

import { getCompanies } from "../../../actions/Basic_data/comapny_actions";

import {
    getStockRequestData,
    addMoveStockRequest,
    getStockRequestItemData,
    updateMoveStockRequest,
    deleteMoveStockRequest,
    updateMoveStockRequestItem,
    deleteMoveStockRequestItem,
    approveStockRequest
} from "../../../actions/Stock/move_stock_company_actions";
import { getParameters } from '../../../actions/Administrator/parameters_actions';
import {
    getWarehouseData,
} from "../../../actions/Stock/move_stock_actions";

const ReactTableFixedColumns = withFixedColumns(Table);


class MoveStockCompany extends React.Component {

    state = {
        stockRequestDataActive: [],
        stockRequestDataCompleted: [],
        stockRequestItemData: [],
        selectedStockItems: [],
        checkedStockItems: [],
        expandedActive: {},
        expandedCompleted: {},
        selectedItems: [],
        items: [],
        stockItems: [],
        fromCompany: '',
        totalRequestedQty: 0,
        warehouseData: [],
        fromWarehouseAdd: null,
        fromWarehouse: null,
        toCompany: '',
        description: '',
        active: true,
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'createdAt', desc: false }],
        disabled:false
    };

    componentDidMount() {
        this.props.getStockRequestData(this.props.token)
        this.props.getStockRequestItemData(this.props.token)
        this.props.getCompanies(this.props.token)
        this.props.getWarehouseData(this.props.token)
        this.props.getParameters(this.props.token);
    }

    componentDidUpdate(prevProps) {
        const { parameters } = this.props;
        if (parameters !== prevProps.parameters) {
            if (parameters.length > 0) {
                const companyData = parameters.filter(item => item.name === 'Company' && item.isActive === true);
                this.setState({
                    fromCompany: companyData.length > 0 ? companyData[0].value : ''
                })
            }
        }
    }


    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            if (props.column.Header === 'Recieved Qty') {
                return <Field name={props.column.id} max={props.original.stockrequestcompanyitem.requestedqty} type="Number" {...fieldProps} />;
            } else if (props.column.Header === 'Selected Qty') {
                if (this.state.checkedStockItems.includes(props.original)) {
                    return <Field name={props.column.id} max={props.original.totalVal} type="Number" {...fieldProps} />;
                } else {
                    return <Field disabled name={props.column.id} max={props.original.totalVal} type="Number" {...fieldProps} />;
                }
            }
            else {
                return <Field name={props.column.id} type="Number" {...fieldProps} />;
            }
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };


    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {
                    (props.column.Header === 'Warehouse From' ? this.props.warehouseData : this.props.companies).map(data =>
                        <option key={data._id} value={data._id}>{data.name}</option>
                    )
                }
            </Field> : <label>{props.value}</label>)
        }
    };

    renderDateTimePicker = ({ input, editing, value, props, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        return editing && <Component type="date" {...input} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    }

    selectableDateColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDateTimePicker,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} {...fieldProps} /> : <label>{DateNumberFormat(props.value)}</label>)
        }
    }


    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {

                    this.setState({
                        editing: rowProps.original,
                        status: rowProps.original.status,
                        fromWarehouse: rowProps.original.stockrequestcompany?.fromwarehouse,
                        toCompany: rowProps.original.stockrequestcompany?.tocompany,
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'CATEGORY', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'CATEGORY', 'DELETE', this.props)
        }) ||
        {};

    getActionPropsSES = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'CATEGORY', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'CATEGORY', 'DELETE', false)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === 'Warehouse From') {
            this.setState({ fromWarehouse: value })
        } else if (column === 'Company To') {
            this.setState({ toWarehouse: value })
        }
    }

    reload = () => {
        this.props.getStockRequestData(this.props.token)
        this.props.getStockRequestItemData(this.props.token)
        this.props.getCompanies(this.props.token)
        this.props.getWarehouseData(this.props.token)
        this.props.getParameters(this.props.token);
    }

    handleSubmit = values => {
        if (values.itemCode) { //items in add stock request
            if (this.state.deleting === null && this.state.editing !== null) {

                var index = 0;
                var selectedItemsArray = this.state.selectedItems;
                var newQty = values.requestedQty;

                for (var i = 0; i < selectedItemsArray.length; i++) {
                    if (this.state.selectedItems[i]._id === values._id) {
                        index = i;
                    }
                }

                var item = this.state.selectedItems[index];
                item.requestedQty = Number(newQty);
                selectedItemsArray[index] = item;

                this.setState({ selectedItems: selectedItemsArray })

            } else if (this.state.deleting !== null && this.state.editing === null) {
                var index = 0;
                var selectedItemsArray = this.state.selectedItems;

                for (var i = 0; i < selectedItemsArray.length; i++) {
                    if (this.state.selectedItems[i]._id === values._id) {
                        index = i;
                    }
                }

                selectedItemsArray.splice(index, 1)

                this.setState({ selectedItems: selectedItemsArray })
            }

        } else if (values.stockrequestcompany) { //stock request

            if (this.state.deleting === null && this.state.editing !== null) {

                const data = {
                    _id: values.stockrequestcompany._id,
                    description: values.stockrequestcompany.description,
                    fromwarehouse: this.state.fromWarehouse,
                    tocompany: this.state.toWarehouse,
                }

                this.props.updateMoveStockRequest(this.props.token, data);

                this.setState({
                    fromwarehouse: null,
                    toCompany: null
                })

            } else if (this.state.deleting !== null && this.state.editing === null) {

                const data = {
                    _id: values.stockrequestcompany._id
                }

                this.props.deleteMoveStockRequest(this.props.token, data);
            }
        } else if (values.stockrequestcompanyitem) { //stock request item

            if (this.state.deleting === null && this.state.editing !== null) {

                const data = {
                    _id: values.stockrequestcompanyitem._id,
                    requestedqty: values.stockrequestcompanyitem.requestedqty,
                    receivedqty: values.stockrequestcompanyitem.receivedqty
                }

                this.props.updateMoveStockRequestItem(this.props.token, data);

            } else if (this.state.deleting !== null && this.state.editing === null) {

                const data = {
                    _id: values.stockrequestcompanyitem._id
                }

                this.props.deleteMoveStockRequestItem(this.props.token, data)

            }

        } else if (values.item_id) { //exp stock modal
            if (this.state.deleting === null && this.state.editing !== null) {

                var index = 0;
                var selectedStockItemsArray = [...this.state.selectedStockItems];
                var newQty = values.selectedQty;

                for (var i = 0; i < selectedStockItemsArray.length; i++) {
                    if (selectedStockItemsArray[i]._id === values._id) {
                        index = i;
                    }
                }

                var item = selectedStockItemsArray[index];
                item.selectedQty = Number(newQty);
                selectedStockItemsArray[index] = item;

                this.setState({ selectedStockItems: selectedStockItemsArray })
            }
        }
    };

    addStockRequestHandle = () => {
        var { toCompany, fromCompany } = this.state;

        var selectedItemsArray = [];

        var flag = false;

        this.state.selectedItems.map(item => {
            var obj = {
                itemId: item._id,
                requestedQty: item.requestedQty,
                warehouseId: item.warehouseId
            }

            var checkedArr = _.filter(this.state.checkedStockItems, function (csi) {
                return csi.item_id === item._id
            })

            obj.checkedExpStock = checkedArr;

            var total = 0;

            checkedArr.forEach(ele => {
                total = total + ele.totalVal;
            })

            if (Number(total) < Number(item.requestedQty)) {
                flag = true;
            }

            if (!flag && checkedArr.length > 0) {
                selectedItemsArray.push(obj)
            }
        })

        if (flag) {
            toast.error('Requested qty is greater than the total qty.')
            return
        }

        if (selectedItemsArray.length > 0 && toCompany !== null && fromCompany !== "") {
            const data = {
                user: this.props.userId,
                description: this.state.description,
                selectedItems: selectedItemsArray,
                toCompany: this.state.toCompany,
                fromCompany: this.state.fromCompany
            }
            this.props.addMoveStockRequest(this.props.token, data);
            this.clear();

        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    clear = () => {
        document.getElementById("addStockRequestForm").reset();

        $('.stockExp').prop('checked', false)

        this.setState({
            selectedItems: [],
        })
    }

    handleRowExpandedActive = (rowsState, index) => {
        this.setState({
            expandedActive: {
                [index[0]]: !this.state.expandedActive[index[0]],
            },
        });
    }

    handleRowExpandedCompleted = (rowsState, index) => {
        this.setState({
            expandedCompleted: {
                [index[0]]: !this.state.expandedCompleted[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    formatDate = (date) => {
        if (date === null || date === '') {
            return '';
        }
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    setFromWareHouse = (value) => {
        // this.setState({
        //     selectedItems: [],
        //     selectedStockItems: [],
        //     totalRequestedQty: 0,
        //     checkedStockItems: [],
        //     fromWarehouseAdd: value
        // })

        const data = {
            _id: value
        }

        axios.defaults.headers.common['authorization'] = this.props.token;
        axios.post('/move_stock/getStockItems', { data })
            .then(result => {

                var matchedItems = []
                result.data.map(value => {
                    if (value.stockitems.exp_items !== undefined && value.stockitems.exp_items.length > 0) {
                        matchedItems.push(value)
                    }
                })

                var items = matchedItems.map(element => {
                    var obj = {
                        _id: element.items._id,
                        displayName: element.items.name + '-' + element.items.itemCode,
                        name: element.items.name,
                        itemCode: element.items.itemCode,
                        warehouse: element.deliverywarehouse.name,
                        warehouseId: element.deliverywarehouse._id,
                    }
                    return obj;
                })

                this.setState({
                    stockItems: matchedItems,
                    items: items
                })
            }).catch(err => {
                toast.error(`${err}`)
            })

    }

    activeStockRequestColumns = [
        { Header: "Request Number", accessor: "stockrequestcompany.requestnumber", filterable: true },
        { Header: "Description", accessor: "stockrequestcompany.description", ...this.editableColumnProps },
        // { Header: "From Warehouse", accessor: "fromwarehouse.name", ...this.selectableColumnProps },
        { Header: "Company From", accessor: "stockrequestcompany.fromcompany", filterable: true },
        { Header: "Company To", accessor: "tocompany.name", ...this.selectableColumnProps },
        {
            Header: "Date Requested",
            accessor: "stockrequestcompany.requestdate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequestcompany.requestdate)}</label>
            }
        },
        { Header: "User Requested", accessor: "userrequested.name.first", filterable: true },
        {
            Header: "Date Recieved",
            accessor: "stockrequestcompany.recieveddate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequestcompany.recieveddate)}</label>
            }
        },
        {
            Header: "Approve",
            Cell: row =>
                <>
                    <button
                        className='btn btn-primary'
                        style={{ "float": "center" }}
                        disabled={this.state.disabled}
                        onClick={async () => {
                            if (window.confirm('Are you sure you wish to approve this move stock request?')) {
                                const url = row.original.tocompany.api;

                                this.setState({
                                    disabled: true
                                })

                                var subData = _.filter(this.props.stockRequestItemData, function (o) {
                                    return o.stockrequestcompanyitem.refstockrequest === row.original.stockrequestcompany._id
                                })

                                subData.forEach(async element => {

                                    const data = {
                                        "item_code": element.items.itemCode,
                                        "stock_qty": element.stockrequestcompanyitem.receivedqty,
                                        "company_name": row.original.stockrequestcompany.fromcompany,
                                        'exp_date': this.formatDate(element.stockrequestcompanyitem.checkedExpStock[0].exp_date),
                                        'warehouse': row.original.tocompany.warehouse,
                                        'checkedExpStock': element.stockrequestcompanyitem.checkedExpStock
                                    }

                                    axios.defaults.headers.common['authorization'] = row.original.tocompany.apiToken;
                                    await axios.post(url, { data })
                                        .then(result => {
                                            let itemId = element.items._id;
                                            const approvedData = {
                                                '_id': row.original.stockrequestcompany._id,
                                                'user': this.props.userId,
                                                'itemId': itemId,
                                                "stock_qty": element.stockrequestcompanyitem.receivedqty,
                                                'ToCompany': row.original.tocompany.name
                                            }

                                            this.props.approveStockRequest(this.props.token, approvedData);

                                        }).catch(err => {
                                            toast.error(`${err}`)
                                        })
                                });
                            }
                        }}
                    >Approve</button>
                </>
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        }
    ];

    subColumnsActive = [
        { Header: "From Warehouse", accessor: "deliverywarehouse.name", filterable: true },
        { Header: "Item Code", accessor: "items.itemCode", filterable: true },
        { Header: "Name", accessor: "items.name", filterable: true },
        { Header: "Requested Qty", accessor: "stockrequestcompanyitem.requestedqty", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Recieved Qty", accessor: "stockrequestcompanyitem.receivedqty", ...this.editableNumberColumnProps, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        }
    ];

    subColumnsCompleted = [
        { Header: "From Warehouse", accessor: "deliverywarehouse.name", filterable: true },
        { Header: "Item Code", accessor: "items.itemCode", filterable: true },
        { Header: "Name", accessor: "items.name", filterable: true },
        { Header: "Requested Qty", accessor: "stockrequestcompanyitem.requestedqty", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Recieved Qty", accessor: "stockrequestcompanyitem.receivedqty", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } }
    ];

    completedStockRequestColumns = [
        { Header: "Request Number", accessor: "stockrequestcompany.requestnumber", ...this.editableColumnProps },
        { Header: "Description", accessor: "stockrequestcompany.description", ...this.editableColumnProps },
        { Header: "Company From", accessor: "fromcompany.name", ...this.editableColumnProps },
        { Header: "Company To", accessor: "tocompany.name", ...this.editableColumnProps },
        {
            Header: "Date Requested",
            accessor: "stockrequestcompany.requestdate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequestcompany.requestdate)}</label>
            }
        },
        { Header: "User Requested", accessor: "userrequested.name.first", ...this.editableColumnProps },
        {
            Header: "Date Recieved",
            accessor: "stockrequestcompany.recieveddate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequestcompany.recieveddate)}</label>
            }
        },
        {
            Header: "Date Approved",
            accessor: "stockrequestcompany.approvedate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequestcompany.approvedate)}</label>
            }
        },
        { Header: "User Approved", accessor: "userapproved.name.first", ...this.editableColumnProps },
    ];

    itemsColumns = [
        { Header: "Warehouse", accessor: "warehouse", filterable: true },
        { Header: "Item Code", accessor: "itemCode", filterable: true },
        { Header: "Item Name", accessor: "name", filterable: true },
        { Header: "Requested Qty", accessor: "requestedQty", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
    ];

    expSotckColumns = [
        {
            Header: "Select",
            Cell: row => (
                <div>
                    <input type="checkbox" className="stockExp" id={'stockExp-' + row.original._id} value={row.original} style={{ width: '100%' }} onChange={(e) => { this.setExpStockCheck(row.original) }} />
                </div>
            )
        },
        {
            Header: "Exp Date",
            accessor: "exp_date",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.exp_date)}</label>
            }
        },
        { Header: "Qty", accessor: "totalVal", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Selected Quantity",
            accessor: "selectedQty",
            ...this.editableNumberColumnProps,
            getProps: () => { return { style: { 'textAlign': 'right' } } }
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionPropsSES,
            Cell: ActionsCell
        },
    ];

    addSelectedItem = (e) => {
        e.requestedQty = 0;
        var stockItem = _.filter(this.state.stockItems, function (sItem) {
            return sItem.stockitems.item_ref === e._id
        })

        stockItem = stockItem[0].stockitems;

        if (stockItem.exp_items.length > 0) {

            var unique = [...new Map(stockItem.exp_items.map(item => [`${item['exp_date']}`, item])).values()]

            let arr = unique?.map(ele => {
                ele.item_id = stockItem.item_ref;
                let filterArr = stockItem.exp_items.filter(obj => {
                    return ele.name === obj.name && ele.exp_date === obj.exp_date
                })
                let ids = filterArr?.map(obj => obj._id)
                let value = filterArr?.reduce((total, obj) => { return total + obj.qty }, 0)

                return ({ ...ele, totalVal: value, selectedQty: value, ids: ids })
            })

            if (!this.state.selectedItems.includes(e)) {
                document.getElementById('expStock').style.display = 'block';
                this.setState({
                    selectedItems: [...this.state.selectedItems, e],
                    selectedStockItems: arr
                })
            } else {
                toast.info('Item already added.')
            }



        } else {
            toast.error('No exp items to move.')
        }
    }

    closeExpStockModal = () => {
        var totalRequestedQty = 0;

        this.state.checkedStockItems.map(sItem => {
            totalRequestedQty = Number(totalRequestedQty) + Number(sItem.selectedQty)
        })

        var theIndex = 0;

        this.state.selectedItems.map((sItem, index) => {
            var total = 0;
            this.state.checkedStockItems.map(csItem => {
                if (sItem._id.toString() === csItem.item_id.toString()) {
                    total = total + csItem.selectedQty;
                }
            })

            this.state.selectedItems[index].requestedQty = total;

            var copy = [...this.state.selectedItems];
            var item = copy[index];

            item.requestedQty = total;

            copy[index] = item;

            this.setState({ selectedItems: copy })

        })

        this.setState({
            totalRequestedQty: totalRequestedQty
        })

        $('.stockExp').prop('checked', false)
        document.getElementById('expStock').style.display = 'none';
    }

    setExpStockCheck = (value) => {
        if ($('#stockExp-' + value._id).is(':checked')) {
            this.state.checkedStockItems.push(value);
        } else {
            for (var i = 0; i < this.state.checkedStockItems.length; i++) {
                if (this.state.checkedStockItems[i]._id === value._id) {
                    this.state.checkedStockItems.splice(i, 1);
                }
            }
        }
    }

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6">
                                <input type='radio' name='active' style={{ marginRight: '5px' }} checked={this.state.active ? true : false} onChange={e => { this.setState({ active: true }) }} /><label>Active</label>
                                <input type='radio' name='active' style={{ marginLeft: '30px', marginRight: '5px' }} checked={this.state.active ? false : true} onChange={e => { this.setState({ active: false }) }} /><label>Completed</label>
                            </div>
                            <div className="col-md-6" style={{ textAlign: 'right' }}>
                                <button className="btn btn-primary" disabled={Permission('STOCK', 'MOVE STOCK', 'ADD', this.props)} data-toggle="modal" data-target="#addStockRequest" onClick={e => this.clear()}>+ Stock Request</button>
                                <button className="btn btn-primary" disabled={Permission('STOCK', 'MOVE STOCK', 'VIEW', this.props)} onClick={e => this.reload()}><i className="fa fa-refresh"></i></button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            this.state.active ?
                                <div className="card-body">
                                    <ErrorBoundary>
                                        <FormProvider
                                            form="inline"
                                            onSubmit={this.handleSubmit}
                                            onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                            initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                            enableReinitialize
                                        >
                                            {formProps => {
                                                return (
                                                    <form onSubmit={formProps.handleSubmit}>
                                                        <Table className="-striped"
                                                            sorted={this.state.sortOptions}
                                                            onSortedChange={val => {
                                                                this.setState({ sortOptions: val })
                                                            }}
                                                            columns={this.activeStockRequestColumns}
                                                            data={this.props.stockRequestDataActive}
                                                            defaultPageSize={10}
                                                            defaultFilterMethod={this.filterCaseInsensitive}
                                                            showPageJump={false}
                                                            expanded={this.state.expandedActive}
                                                            onExpandedChange={(newExpanded, index, event) => this.handleRowExpandedActive(newExpanded, index)}
                                                            SubComponent={row => {
                                                                var subData = _.filter(this.props.stockRequestItemData, function (o) {
                                                                    return o.stockrequestcompanyitem.refstockrequest === row.original.stockrequestcompany._id
                                                                })

                                                                return (
                                                                    <div>
                                                                        <React.Fragment>
                                                                            <ErrorBoundary>
                                                                                <FormProvider
                                                                                    form="inline"
                                                                                    onSubmit={this.handleSubmit}
                                                                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                                                    enableReinitialize
                                                                                >
                                                                                    {formProps => {
                                                                                        return (
                                                                                            <form onSubmit={formProps.handleSubmit}>
                                                                                                <Table className="-striped"
                                                                                                    columns={this.subColumnsActive}
                                                                                                    data={subData}
                                                                                                    defaultPageSize={10}
                                                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                                                    showPageJump={false}
                                                                                                />
                                                                                            </form>
                                                                                        )
                                                                                    }}

                                                                                </FormProvider>
                                                                            </ErrorBoundary>
                                                                        </React.Fragment>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </form>
                                                );
                                            }}
                                        </FormProvider>
                                    </ErrorBoundary>
                                </div>
                                :
                                <div className="card-body">
                                    <React.Fragment>
                                        <ErrorBoundary>
                                            <FormProvider
                                                form="inline"
                                                onSubmit={this.handleSubmit}
                                                onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                enableReinitialize
                                            >
                                                {formProps => {
                                                    return (
                                                        <form onSubmit={formProps.handleSubmit}>
                                                            <Table className="-striped"
                                                                sorted={this.state.sortOptions}
                                                                onSortedChange={val => {
                                                                    this.setState({ sortOptions: val })
                                                                }}
                                                                columns={this.completedStockRequestColumns}
                                                                data={this.props.stockRequestDataCompleted}
                                                                defaultPageSize={10}
                                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                                showPageJump={false}
                                                                expanded={this.state.expandedCompleted}
                                                                onExpandedChange={(newExpanded, index, event) => this.handleRowExpandedCompleted(newExpanded, index)}
                                                                SubComponent={row => {
                                                                    var subData = _.filter(this.props.stockRequestItemData, function (o) {
                                                                        return o.stockrequestcompanyitem.refstockrequest === row.original.stockrequestcompany._id
                                                                    })

                                                                    return (
                                                                        <div>
                                                                            <React.Fragment>
                                                                                <ErrorBoundary>
                                                                                    <FormProvider
                                                                                        form="inline"
                                                                                        onSubmit={this.handleSubmit}
                                                                                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                                                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                                                        enableReinitialize
                                                                                    >
                                                                                        {formProps => {
                                                                                            return (
                                                                                                <form onSubmit={formProps.handleSubmit}>
                                                                                                    <Table className="-striped"
                                                                                                        columns={this.subColumnsCompleted}
                                                                                                        data={subData}
                                                                                                        defaultPageSize={10}
                                                                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                                                                        showPageJump={false}
                                                                                                    />
                                                                                                </form>
                                                                                            )
                                                                                        }}

                                                                                    </FormProvider>
                                                                                </ErrorBoundary>
                                                                            </React.Fragment>
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                        </form>
                                                    );
                                                }}
                                            </FormProvider>
                                        </ErrorBoundary>
                                    </React.Fragment>
                                </div>
                        }
                    </div>

                    <div id="addStockRequest" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Stock Request</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.clear()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addStockRequestForm'>
                                            <table style={{ 'width': '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>From Company* :</td>
                                                        <td>
                                                            <input type="text" value={this.state.fromCompany} disabled className="form-control" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>From Warehouse* :</td>
                                                        <td>
                                                            <select className="form-control" onChange={e => e.target.value === '' ? null : this.setFromWareHouse(e.target.value)}>
                                                                <option value={''}>- Select From Warehouse -</option>
                                                                {this.props.warehouseData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>To Company* :</td>
                                                        <td>
                                                            <select className="form-control" onChange={e => e.target.value === '' ? null : this.setState({ toCompany: e.target.value })}>
                                                                <option value={''}>- Select To Company -</option>
                                                                {this.props.companies.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Description :</td>
                                                        <td>
                                                            <textarea className="form-control" onChange={e => this.setState({ description: e.target.value })}></textarea>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Select Item :</td>
                                                        <td>
                                                            <SearchableSelectComp
                                                                options={this.state.items}
                                                                label={'displayName'}
                                                                onChange={e => this.addSelectedItem(e)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Requested Qty :</td>
                                                        <td>
                                                            <input type="text" className="form-control" disabled={true} value={this.state.totalRequestedQty} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>

                                    {this.state.selectedItems.length > 0 ?
                                        <div className="card-body">
                                            <React.Fragment>
                                                <ErrorBoundary>
                                                    <FormProvider
                                                        form="inline"
                                                        onSubmit={this.handleSubmit}
                                                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                        enableReinitialize
                                                    >
                                                        {formProps => {
                                                            return (
                                                                <form onSubmit={formProps.handleSubmit}>
                                                                    <Table className="-striped"
                                                                        columns={this.itemsColumns}
                                                                        data={this.state.selectedItems}
                                                                        defaultPageSize={10}
                                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                                        showPageJump={false}
                                                                    />
                                                                </form>
                                                            );
                                                        }}
                                                    </FormProvider>
                                                </ErrorBoundary>
                                            </React.Fragment>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button id="add_stock" type="button" className="btn btn-primary" onClick={e => { this.addStockRequestHandle() }}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.clear()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="expStock" className="modal" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Select Expiry Stock</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.closeExpStockModal()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    {this.state.selectedStockItems.length > 0 ?
                                        <div className="card-body">
                                            <React.Fragment>
                                                <ErrorBoundary>
                                                    <FormProvider
                                                        form="inline"
                                                        onSubmit={this.handleSubmit}
                                                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                    >
                                                        {formProps => {
                                                            return (
                                                                <form onSubmit={formProps.handleSubmit}>
                                                                    <Table className="-striped"
                                                                        columns={this.expSotckColumns}
                                                                        data={this.state.selectedStockItems}
                                                                        defaultPageSize={10}
                                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                                        showPageJump={false}
                                                                    />
                                                                </form>
                                                            );
                                                        }}
                                                    </FormProvider>
                                                </ErrorBoundary>
                                            </React.Fragment>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={e => this.closeExpStockModal()}>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        stockRequestDataActive: state.rMoveStockCompany.stockRequestDataActive,
        stockRequestDataCompleted: state.rMoveStockCompany.stockRequestDataCompleted,
        companies: state.rBasicData.companies,
        warehouseData: state.rMoveStock.warehouseData,
        stockRequestItemData: state.rMoveStockCompany.stockRequestItemData,
        token: state.rLogin.token,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userId: state.rLogin.id,
        parameters: state.rParameters.parameters,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getStockRequestData: (token) => { dispatch(getStockRequestData(token)) },
        getCompanies: (token) => { dispatch(getCompanies(token)) },
        getWarehouseData: (token) => { dispatch(getWarehouseData(token)) },
        addMoveStockRequest: (token, data) => { dispatch(addMoveStockRequest(token, data)) },
        getStockRequestItemData: (token) => { dispatch(getStockRequestItemData(token)) },
        updateMoveStockRequest: (token, data) => { dispatch(updateMoveStockRequest(token, data)) },
        deleteMoveStockRequest: (token, data) => { dispatch(deleteMoveStockRequest(token, data)) },
        updateMoveStockRequestItem: (token, data) => { dispatch(updateMoveStockRequestItem(token, data)) },
        deleteMoveStockRequestItem: (token, data) => { dispatch(deleteMoveStockRequestItem(token, data)) },
        approveStockRequest: (token, approvedData) => { dispatch(approveStockRequest(token, approvedData)) },
        getParameters: (token) => { dispatch(getParameters(token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveStockCompany);


