/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
// import $ from 'jquery';
import { isLoadingAsync } from '../login_action';
import { getDeliveryWarehouses } from '../Basic_data/delivery_warehouse_actions'
import { getOutletItemPrice } from '../POS/outlet_item_price_action'

var axios = require('axios');

export const productsAsync = data => {
    return { type: 'FETCH_PRODUCTS', data }
}

export const itemsAsync = data => {
    return { type: 'FETCH_ITEMS_DATA', data }
}

export const newSuppliersAsync = data => {
    return { type: 'FETCH_NEW_SUPPLIER_DATA', data }
}

export const newItemsAsync = data => {
    return { type: 'FETCH_NEW_ITEM_DATA', data }
}

export const restockedItemsAsync = data => {
    return { type: 'FETCH_RESTOCKED_ITEM_DATA', data }
}

export const promotionAndDiscountItemsAsync = data => {
    return { type: 'FETCH_PROMO_DISCOUNT_ITEM_DATA', data }
}

export const allItemsAsync = data => {
    return { type: 'FETCH_ALL_ITEM_DATA', data }
}

export const itemReoprtHeaderTextAsync = data => {
    return { type: 'FETCH_ITEM_REPORT_HEADER_TEXT', data }
}

export const itemsForHierarchyAsync = data => {
    return { type: 'FETCH_ITEMS_FOR_HIERARCHY', data }
}

export const itemsWithHierarchyAsync = data => {
    return { type: 'FETCH_ITEMS_WITH_HIERARCHY', data }
}

export const itemListMsg = data => {
    return { type: 'FETCH_ITEMS_LIST_MSG', data }
}

export const reloadItemsData = ({ token, userId }) => {
    return dispatch => {
        dispatch(isLoadingAsync(true))
        dispatch(itemsAsync([]))
        dispatch(getItems(token))
        dispatch(getAllItems(token))
        dispatch(getNewSuppliers(token))
        dispatch(getNewItems(token))
        dispatch(getPromotionAndDiscountItems(token))
        dispatch(getDeliveryWarehouses({ token: token }))
        dispatch(getOutletItemPrice({ userId: userId },token))
    }
}

/**
 * 
 * @param {*} token 
 */
export const getProducts = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getProducts')
            .then(result => {
                dispatch(productsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getItems = token => {
    return dispatch => {
        // dispatch(isLoadingAsync(true))
        // dispatch(itemsAsync([]))
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getItems')
            .then(result => {
                // const finalData = [];

                // result.data.forEach(element => {
                //     var innerArr = {};

                //     innerArr.items = {
                //         '_id': element.items._id,
                //         'slug': element.items.slug,
                //         'deliveryType': element.items.deliveryType,
                //         'shelfLife': element.items.shelfLife,
                //         'desc': element.items.desc,
                //         'name': element.items.name + ' - ' + element.items.itemCode,
                //         'product': element.items.product,
                //         'updatedAt': element.items.updatedAt,
                //         'createdAt': element.items.createdAt,
                //         'durationUnit': element.items.durationUnit,
                //         'duration': element.items.duration,
                //         'priceUnit': element.items.priceUnit,
                //         'price': element.items.price,
                //         'images': element.items.images,
                //         'status': element.items.status,
                //         'itemCode': element.items.itemCode,
                //         'purchasePrice': element.items.purchasePrice,
                //         'discountPercentage': element.items.discountPercentage,
                //         'supplier_ref': element.items.supplier_ref
                //     }

                //     innerArr.deliverytypes = element.deliverytypes;

                //     innerArr.shelflives = element.shelflives;

                //     if (element.supplier !== undefined) {
                //         innerArr.supplier = element.supplier;
                //     }

                //     innerArr.products = element.products;


                //     finalData.push(innerArr);
                // })
                
                dispatch(itemsAsync(result.data))
                dispatch(isLoadingAsync(false))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateItem = (data, token) => {
    return dispatch => {
        var images = '';
        var _id = data._id;
        if (data.images !== undefined) {
            if (!data.images.path) {
                images = data.images;
                delete data.images;
            }
        }

        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item/updateItem', { data })
            .then(result => {

                //upload image

                if (images !== '' && !Array.isArray(images)) {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const data = new FormData();

                    data.append("itemImage", images, images.name);

                    axios.post('/basic_data/item/uploadImage', data, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const images = [{
                                'url': '/images/items/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'items',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }]

                            const data = {
                                _id: _id,
                                images: images
                            }

                            dispatch(updateItemSlugWithImage(data, token));

                        }).catch(err => {
                            console.log(err);
                        })
                } else {
                    toast.success('Successfully Updated Item')
                    dispatch(getItems(token))
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteItem = (data, token) => {
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item/deleteItem', { data })
            .then(result => {
                toast.success('Item Deleted Successfully!')
                dispatch(getItems(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addItem = ({ name, desc, mobileDescription, deliveryType, supplier_ref, product, shelfLife, priceUnit, price, originalPrice, durationUnit, duration, status, itemCode, purchasePrice, discountPercentage, leadtime, slug, createdAt, updatedAt, images, createdUser, modifiedUser, unit, from, measurement, brand, purchaseDiscount, packagingGroup, minimumQty, incrementQty }, token) => {
    return dispatch => {
        dispatch(isLoadingAsync(true))

        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/item/addItem', {
            data: {
                name, desc, mobileDescription, deliveryType,
                supplier_ref, product, shelfLife,
                priceUnit, price, originalPrice,
                durationUnit, duration, status,
                itemCode, purchasePrice, discountPercentage,
                leadtime, slug, createdAt,
                updatedAt, createdUser, modifiedUser, unit, from, measurement, brand, purchaseDiscount, isPackagingGroup: packagingGroup,
                minimumQty, incrementQty
            }
        })
            .then(result => {

                var _id = result.data.insertedId;

                //upload image
                if (images === '') {
                    var slug = _id;

                    const data = {
                        _id: _id,
                        slug: slug
                    }

                    dispatch(updateItemSlugWithImage(data, token))
                } else {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const data = new FormData();

                    data.append("itemImage", images, images.name);

                    axios.post('/basic_data/item/uploadImage', data, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const images = [{
                                'url': '/images/items/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'items',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }]

                            var slug = _id;

                            const data = {
                                _id: _id,
                                slug: slug,
                                images: images
                            }

                            dispatch(updateItemSlugWithImage(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                }



                //upload image ends

            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateItemSlugWithImage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item/updateItemSlug', { data })
            .then(result => {
                window.$('#addItem').modal('hide');

                toast.success('Item Added Successfully!')
                dispatch(getItems(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateImage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item/updateImage', { data })
            .then(result => {
                toast.success('Successfully Updated Item')
                dispatch(getItems(token))
            }).catch(err => {
                console.log(`${err}`)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getNewSuppliers = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getNewSuppliers')
            .then(result => {
                dispatch(newSuppliersAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getNewItems = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getNewItems')
            .then(result => {
                dispatch(newItemsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getRestockedItems = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getRestockedItems')
            .then(result => {
                dispatch(restockedItemsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getPromotionAndDiscountItems = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getPromotionAndDiscountItems')
            .then(result => {
                dispatch(promotionAndDiscountItemsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getAllItems = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getAllItems')
            .then(result => {
                dispatch(allItemsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getItemReportHeaderText = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getItemsReportText')
            .then(result => {
                dispatch(itemReoprtHeaderTextAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const saveItemReportText = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item/saveItemReportText', { data })
            .then(result => {
                dispatch(itemReoprtHeaderTextAsync(result.data))
                toast.success('Successfully Updated Report Text')
            }).catch(err => {
                console.log(`${err}`)
                toast.error(`${err}`)
            })
    }
}

export const updateDays = (days, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item/updateDays', { days })
            .then(result => {
                dispatch(getItems(token))
                toast.success('Fixed delivery date updated Successfully...!')
            }).catch(err => {
                console.log(`${err}`)
                toast.error(`${err}`)
            })
    }
}

export const getItemsForHierarchy = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getItemsForHierarchy')
            .then(result => {
                dispatch(itemsForHierarchyAsync(result.data))
            }).catch(err => {
                console.error(err);
                toast.error(err.msg)
            })
    }
}


export const getItemsWithHierarchy = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getItemsWithHierarchy')
            .then(result => {
                dispatch(itemsWithHierarchyAsync(result.data))
            }).catch(err => {
                console.error(err);
                toast.error(err.msg)
            })
    }
}

export const getItemById = (id, setData, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    axios.get('/basic_data/item/getItemById', { params: { id } })
        .then(result => {
            setData(result.data)
        }).catch(err => {
            console.error(err);
            toast.error(err.msg)
        })
}

export const getItemListNotifications = ({ token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item/getItemListNotifications')
            .then(result => {
                dispatch(itemListMsg(result.data[0]))
            }).catch(err => {
                console.error(err);
                toast.error(err.msg)
            })
    }
}
