/**
 * @file This file defines to ordering action dispatch
 * @author Bhanuka Chathuranga
 */
// import $ from 'jquery';
import { toast } from 'react-toastify';
// import _ from 'lodash';

var axios = require('axios');

export const canceledOrdersAsync = data => {
    return { type: 'FETCH_CANCELED_ORDERS', data }
}

export const canceledOrderItemsAsync = data => {
    return { type: 'FETCH_CANCELED_ORDER_ITEMS', data }
}

export const getCancelAndMissionReportDataAsync = data => {
    return { type: 'FETCH_CANCELED_ORDER_AND_MISSION_ORDER_ITEMS', data }
}

/**
 * 
 * @param {*} token 
 */
export const getCanceledOrders = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/ordering/getCanceledOrders')
            .then(result => {
                dispatch(canceledOrdersAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getCanceledOrderItems = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/ordering/getCanceledOrderItems')
            .then(result => {
                dispatch(canceledOrderItemsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 * @param {*} data 
 */
 export const getCancelAndMissionReportData = (data,callback = "") => {
    let body = {
        from: data.from,
        to: data.to
    }

    let token = data.token;

    console.log(body)
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/getCancelAndMissionReportData', body)
            .then(result => {
                dispatch(getCancelAndMissionReportDataAsync(result.data))
                if(callback){
                    callback("");
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}