/**
 * @file This file defined for Delivery Dashboard controller
 * @author Umesh Ranthilina
 */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getDeliveryWarehousesForCurrentUser } from "../../../actions/Basic_data/delivery_warehouse_actions";



class POS extends Component {
    state = {
        selectOption: '',
        repeat_Request: 0,
        loop: 0,
    }

    componentDidMount() {
        this.props.getDeliveryWarehousesForCurrentUser(this.props)
    }

    onSelect = (e, name) => {
        if (e)
            this.setState({ selectOption: (e.target.value) });
        else
            this.setState({ selectOption: null });
    };

    render() {
        return (
            <div>
                <div className="right_col" role="main">
                    <div className="card">
                        <div className="card-header" style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'inline-flex', paddingRight: '1%' }}>
                                <h4>POS</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div>
                                <div style={{ width: '50%', float: 'left' }}>
                                    <select className='form-control' placeholder='Select...' onChange={(e) => this.onSelect(e, "warehouse")}>
                                        <option value={''}>Select...</option>
                                        {this.props.deliveryWarehouseData.map(ele => <option key={ele._id} value={ele._id}>{ele.name}</option>)}
                                    </select>
                                </div>
                                <div style={{ width: '50%', float: 'right', paddingLeft: 50 }}>
                                    <button disabled={this.state.selectOption !== '' ? false : true} className='btn btn-outline-info' onClick={() => {
                                        const data = {
                                            warehouseId: this.state.selectOption,
                                        };

                                        document.documentElement.requestFullscreen();
                                        this.props.history.push({
                                            pathname: '/postv',
                                            data: data
                                        })

                                    }}>Go to POS</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    token: state.rLogin.token,
    userId: state.rLogin.id,
    deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehousesForUser,
})

const mapDispatchToProps = dispatch => ({
    getDeliveryWarehousesForCurrentUser: (token, user) => { dispatch(getDeliveryWarehousesForCurrentUser(token, user)) },
})


export default connect(mapStateToProps, mapDispatchToProps)(POS);
