/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const parametersAsync = data => {
    return { type: 'FETCH_PARAMETERS', data }
}

/**
 * 
 * @param {*} token 
 */
export const getParameters = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/administrator/parameters/getParameters')
            .then(result => {
                dispatch(parametersAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateParameter = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/parameters/updateParameter', { data })
            .then(result => {
                toast.success('Successfully Updated Parameter!')
                dispatch(getParameters(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteParameter = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/parameters/deleteParameter', { data })
            .then(result => {
                toast.success('Parameter Deleted Successfully!')
                dispatch(getParameters(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addParameter = ({ name, description, message, value, isActive, slug }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/administrator/parameters/addParameter', {
            data: {
                name,
                description,
                message, 
                value,
                isActive,
                slug
            }
        })
            .then(result => {
                window.$('#addParameter').modal('hide');
                toast.success('Successfully Added Parameter!');
                dispatch(getParameters(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}