import HighlightCell from "../components/HighlightCell";

function containsInsensitive(filter, row) {
    console.log(filter, row);
    return (
        row[filter.id] === null || row[filter.id] !== undefined ?
            new Date(row[filter.id])
                .toLocaleDateString()
                .includes(filter.value.toLowerCase()) : ""
    );
}

function getFiltered(gridState) {
    return { filtered: gridState.filtered };
}

export default {
    Cell: HighlightCell,
    filterable: true,
    filterMethod: containsInsensitive,
    getProps: getFiltered
};