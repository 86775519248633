/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import Table from "react-table";
import "react-table/react-table.css";

import { toast } from "react-toastify";
import _, { forEach } from 'lodash';

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css';

import axios from 'axios';

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";
import $ from 'jquery';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getPaymentDetailData, getInnerPaymentDetailData, cancelPayment } from '../../../actions/Acc/payment_detail_actions';
import { getAccountsData } from '../../../actions/Acc/account_actions';
import { Permission } from "../../utils";
import { getBanksData } from '../../../actions/Acc/bank_actions';

import { ExportData } from '../../utils';
import checkboxHOC from "react-table/lib/hoc/selectTable";
const CheckboxTable = checkboxHOC(Table);

class PaymentDetail extends React.Component {

    oneMonthAgo = new Date();

    state = {
        paymentDetailData: [],
        paymentDetailDataUA: [],
        innerPaymentDetailData: [],
        banksData: [],
        expanded: {},
        refundsData: [],
        creditNoteData: [],
        accounts: [],
        currentAccount: '',
        payButtonDisabled: true,
        expandedUA: {},
        selection: [],
        selectAll: false,
        disableMakePaymentButton: true,
        sortOptions: [{ id: 'payments.date', desc: true }],
        sortOptionsIn: [{ id: 'ref_purchase', desc: true }],
        sortOptionsRF: [{ id: 'orderId', desc: true }],
        sortOptionsUA: [{ id: 'payments.date', desc: true }],
        sortOptionsCN: [{ id: 'credit_date', desc: true }],
        reportDataArr: [],
        exportDateTo: '',
        exportDateFrom: '',

    };

    componentDidMount() {
        this.props.getPaymentDetailData(this.props.token);
        this.props.getInnerPaymentDetailData(this.props.token);
        this.props.getAccountsData(this.props.token);
        this.props.getBanksData(this.props.token);
        this.setState({ user: this.props.userId });
        this.oneMonthAgo.setMonth(this.oneMonthAgo.getMonth() - 1)
    }

    toggleSelection = (key, shift, row) => {
        /*
          Implementation of how to manage the selection state is up to the developer.
          This implementation uses an array stored in the component state.
          Other implementations could use object keys, a Javascript Set, or Redux... etc.
        */
        // start off with the existing state
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        console.log(keyIndex);
        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }
        // update the state
        if (selection.length > 0) {
            this.setState({
                disableMakePaymentButton: false
            });
        } else {
            this.setState({
                disableMakePaymentButton: true
            });
        }
        this.setState({ selection });
    };

    toggleAll = () => {
        /*
          'toggleAll' is a tricky concept with any filterable table
          do you just select ALL the records that are in your data?
          OR
          do you only select ALL the records that are in the current filtered data?
          
          The latter makes more sense because 'selection' is a visual thing for the user.
          This is especially true if you are going to implement a set of external functions
          that act on the selected information (you would not want to DELETE the wrong thing!).
          
          So, to that end, access to the internals of ReactTable are required to get what is
          currently visible in the table (either on the current page or any other page).
          
          The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
          ReactTable and then get the internal state and the 'sortedData'. 
          That can then be iterrated to get all the currently visible records and set
          the selection state.
        */
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(item._original._id);
            });
        }
        if (selection.length > 0) {
            this.setState({
                disableMakePaymentButton: false
            });
        } else {
            this.setState({
                disableMakePaymentButton: true
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        return this.state.selection.includes(key);
    };

    logSelection = () => {
        console.log("selection:", this.state.selection);
    };

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        if (hours.length < 2) {
            hours = '0' + hours;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        var date = [year, month, day].join('-');
        var time = [hours, minutes, seconds].join(':')

        return [date, time].join(' ');
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    handleRowExpandedUA = (rowsState, index) => {
        this.setState({
            expandedUA: {
                [index[0]]: !this.state.expandedUA[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        row[id] = row[id] !== undefined ? row[id].toString() : undefined;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toString().toLowerCase())
                :
                true
        );
    }

    formatOnlyDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }


        var date = [year, month, day].join('-');

        return date;
    }

    handleExportData = (props) => {
        if (this.state.exportDateTo !== '' && this.state.exportDateFrom !== '') {
            axios.defaults.headers.common['authorization'] = props.token;

            let data = {
                to: this.state.exportDateTo,
                from: this.state.exportDateFrom
            }

            axios.post('/acc/paymentdetail/report', { data })
                .then(result => {
                    console.log(result)

                    this.setState({
                        reportDataArr: result.data
                    })
                    this.exportData()
                }).catch(err => {
                    console.log(err);
                })
        } else {
            toast.error('please select the date')
        }

    }

    exportData = () => {
        if (this.state.reportDataArr.length > 0) {

            var excelData = this.state.reportDataArr.map(checkData => {

                return {
                    'Description': checkData.description,
                    'Supplier': checkData.supplier,
                    'Amount': checkData.amount,
                    'PayAmount': checkData.payAmount,
                    'EffectiveDate': checkData.effectiveDate,
                    'PaymentDate': checkData.paymentDate,
                }
            })
            ExportData("commercialReport", "Commercial Report", "Commercial Report", excelData)
        }
    }

    paymentDetailColumns = [
        {
            Header: "Date",
            accessor: "payments.date",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{this.formatOnlyDate(row.original.payments.date)}</label>
                </div>
            ),
        },
        {
            Header: "Value",
            accessor: "payments.value",
            Cell: row => (
                <div>
                    <label>{Number(row.original.payments.value).toFixed(2)}</label>
                </div>
            ),
            filterable: true,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        { Header: "Account", accessor: "accounts.acc_name", filterable: true, },
        { Header: "Time", accessor: "payments.time", filterable: true, },
        { Header: "Transaction", accessor: "transactions.reference", filterable: true },
        { Header: "User", accessor: "users.name.first", filterable: true },
        { Header: "Supplier", accessor: "supplier.name", filterable: true },
        { Header: "Payment Number", accessor: "payments.payment_number", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Cancelled", accessor: "payments.cancelled", filterable: true, getProps: () => { return { style: { 'text-transform': 'capitalize' } } } },
        {
            Header: 'Approved',
            accessor: "payments.approved",
            filterable: true,
            getProps: (state, rowInfo) => {
                if (rowInfo?.original?.payments?.approved === "No") {
                    return {
                        style: {
                            background: '#0A1',
                            color: '#FFF'
                        }
                    }
                } else {
                    return {};
                }

            }
        },
        {
            Header: 'Refund Detail',
            Cell: row =>
                <>
                    <a data-toggle={(row.original.payments.refund).length > 0 ? 'modal' : ''} data-target='#refundModal'
                        className='btn btn-link'
                        style={{
                            textDecoration: (row.original.payments.refund).length > 0 ? 'underline' : 'none',
                            cursor: (row.original.payments.refund).length > 0 ? 'pointer' : '',
                            color: (row.original.payments.refund).length > 0 ? '#007bff' : 'gray'
                        }}
                        disabled={(row.original.payments.refund).length === 0 ? true : false}
                        onClick={() => {
                            this.setState({
                                refundsData: row.original.payments.refund,
                            });
                        }}
                    >Refund Detail</a>
                </>
        },
        {
            Header: 'Credit Note Detail',
            Cell: row =>
                <>
                    <a data-toggle={row.original.payments.creditnote?.length > 0 ? 'modal' : ''} data-target='#creditNoteModal'
                        className='btn btn-link'
                        style={{
                            textDecoration: row.original.payments.creditnote?.length > 0 ? 'underline' : 'none',
                            cursor: row.original.payments.creditnote?.length > 0 ? 'pointer' : '',
                            color: row.original.payments.creditnote?.length > 0 ? '#007bff' : 'gray'
                        }}
                        disabled={row.original.payments.creditnote?.length === 0 ? true : false}
                        onClick={() => {
                            this.setState({
                                creditNoteData: row.original.payments.creditnote ? row.original.payments.creditnote : [],
                            });
                        }}
                    >Credit Note</a>
                </>
        },
        {
            Header: 'Cancel Payment',
            Cell: row =>
                <>
                    <a className='btn btn-link'
                        style={{
                            textDecoration:
                                (row.original.accounts.type === 'Payable' && row.original.payments.approved === 'No'
                                    && (row.original.payments.cancelled === undefined || row.original.payments.cancelled === 'no')) ? 'underline' : 'none',
                            cursor: (row.original.accounts.type === 'Payable' && row.original.payments.approved === 'No'
                                && (row.original.payments.cancelled === undefined || row.original.payments.cancelled === 'no')) ? 'pointer' : '',
                            color: (row.original.accounts.type === 'Payable' && row.original.payments.approved === 'No'
                                && (row.original.payments.cancelled === undefined || row.original.payments.cancelled === 'no')) ? '#007bff' : 'gray'
                        }}
                        disabled={(row.original.accounts.type === 'Payable' && row.original.payments.approved === 'No') ? true : false}
                        onClick={() => {
                            if ((row.original.accounts.type === 'Payable' && row.original.payments.approved === 'No'
                                && (row.original.payments.cancelled === undefined || row.original.payments.cancelled === 'no'))) {
                                if (window.confirm('Are you sure you wish to cancel this payment?')) {
                                    if (row.original.payments.acc_to) {
                                        const data = {
                                            'payment_id': row.original.payments._id,
                                            'account_from': row.original.payments.acc_to,
                                            'account_to': row.original.payments.acc_ref,
                                            'amount': row.original.payments.value,
                                            'transaction_amount': row.original.payments.transaction_amount,
                                            'user': this.props.userId,
                                            'refund_data': row.original.payments.refund,
                                            'credit_note': row.original.payments.creditnote ? row.original.payments.creditnote : []
                                        };

                                        axios.defaults.headers.common['authorization'] = this.props.token;
                                        axios.post('/acc/paymentdetail/cancelPayment', { data })
                                            .then(result => {
                                                this.props.getPaymentDetailData(this.props.token);
                                                this.props.getInnerPaymentDetailData(this.props.token);
                                                this.props.getAccountsData(this.props.token);
                                            }).catch(err => {
                                                console.log(err);
                                                toast.error(err.response.data);
                                            })
                                    } else {
                                        toast.info('This feature does not work on older payments!!', { autoClose: 3000 })
                                    }

                                }
                            }
                        }}
                    >Cancel Payment</a>
                </>
        }
    ];

    unapprovedPaymentsColumns = [
        // {
        //     Header: "Select",
        //     Cell: row => (
        //         <div>
        //             <input
        //                 type="checkbox"
        //                 id={'select-' + row.original.payments._id}
        //                 style={{ width: '100%' }}
        //                 disabled={row.original.accounts.type === 'Payable' ? false : true}
        //                 key={row.original.payments._id}
        //                 value={row.original.payments._id}
        //                 onClick={(e) => {
        //                     this.setPaymentCheck(
        //                         row.original.payments._id,
        //                         row.original.payments.value,
        //                         row.original.accounts._id,
        //                         row.original.transactions.reference
        //                     )
        //                 }}
        //             />
        //         </div>
        //     )
        // },
        {
            Header: "Date",
            accessor: "date",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{this.formatOnlyDate(row.original.date)}</label>
                </div>
            ),
        },
        { Header: "Supplier", accessor: "supplier", filterable: true },
        {
            Header: "Value",
            accessor: "value",
            Cell: row => (
                <div>
                    <label>{Number(row.original.value).toFixed(2)}</label>
                </div>
            ),
            filterable: true,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        { Header: "Transaction", accessor: "transaction", filterable: true },
        { Header: "Account", accessor: "acc_name", filterable: true, },
        { Header: "User", accessor: "user", filterable: true },
        { Header: "Time", accessor: "time", filterable: true, },
        { Header: "Bank Account name", accessor: "bank", filterable: true, },

    ];

    innerPaymentDetailColumns = [
        {
            Header: "Purchase No",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{row.original.purchaseorder?.orderid || row.original.purchaseorderhistories?.orderid}</label>
                </div>
            )
        },
        { Header: "Value", accessor: "paymentdetail.value", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Approval User", accessor: "users.name.first", filterable: true },
        {
            Header: "Approval Date",
            accessor: "paymentdetail.completedDate",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{row.original.paymentdetail.completedDate === '' || row.original.paymentdetail.completedDate === null ? '' : this.formatOnlyDate(row.original.paymentdetail.completedDate)}</label>
                </div>
            ),
        },
        {
            Header: "Effective Date",
            accessor: "paymentdetail.effectiveDate",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{this.formatOnlyDate(row.original.paymentdetail.effectiveDate)}</label>
                </div>
            ),
        },
        {
            Header: "Completed",
            accessor: "paymentdetail.isCompleted",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{row.original.paymentdetail.isCompleted ? 'true' : 'false'}</label>
                </div>
            ),
        },
    ];

    innerPaymentDetailColumnsUnapproved = [
        { Header: "Purchase No", accessor: "purchaseorder.orderid", filterable: true },
        { Header: "Value", accessor: "paymentdetail.value", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Remark", accessor: "purchaseorder.remark", filterable: true },
        { Header: "Approval User", accessor: "users.name.first", filterable: true },
        {
            Header: "Approval Date",
            accessor: "paymentdetail.completedDate",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{row.original.paymentdetail.completedDate === '' || row.original.paymentdetail.completedDate === null ? '' : this.formatOnlyDate(row.original.paymentdetail.completedDate)}</label>
                </div>
            ),
        },
        {
            Header: "Effective Date",
            accessor: "paymentdetail.effectiveDate",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{this.formatOnlyDate(row.original.paymentdetail.effectiveDate)}</label>
                </div>
            ),
        },
        {
            Header: "Completed",
            accessor: "paymentdetail.isCompleted",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{row.original.paymentdetail.isCompleted ? 'true' : 'false'}</label>
                </div>
            ),
        },
    ];

    refundColumns = [
        { Header: "Order Number", accessor: "orderId", filterable: true },
        {
            Header: "Bare",
            Cell: row => (
                <div>
                    <span>{row.original.bare + '%'}</span>
                </div>
            ),
            filterable: true,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        { Header: "Purchase Price", accessor: "purchasePrice", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Defect Qty", accessor: "defectQty", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Total", accessor: "total", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
    ];

    creditNoteColumns = [
        // { Header: "Order Number", accessor: "orderId", filterable: true },
        {
            Header: "Value",
            accessor: 'value',
            filterable: true,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        { Header: "Remark", accessor: "remark", filterable: true },
        {
            Header: "Credit Date",
            accessor: "credit_date",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{this.formatOnlyDate(row.original.credit_date)}</label>
                </div>
            ),
        }
        ,
    ];

    setCurrentAccount = (element) => {
        this.setState({
            currentAccount: element
        });
    }

    pay = () => {
        const paymentIds = this.state.selection;

        const checksArr = [];

        this.state.paymentDetailDataUA.forEach(element => {
            for (var i = 0; i < paymentIds.length; i++) {
                if (paymentIds[i] === element._id) {
                    var objToPush = {
                        paymentId: element._id,
                        amount: element.transaction_amount,
                        payableAccountId: element.acc_id,
                        reference: element.transaction
                    };

                    checksArr.push(objToPush);
                }
            }
        });

        const cashAccount = this.state.currentAccount;
        const user = this.state.user;

        if (cashAccount !== '' && checksArr.length > 0) {

            var cashAccountArr = cashAccount.split('-');
            const cashAccountId = cashAccountArr[0];
            const availableAmount = Number(cashAccountArr[1]).toFixed(2);
            const token = this.props.token;
            const props = this.props;

            var totalAmountSelected = 0;

            checksArr.forEach(element => {
                totalAmountSelected = Number(totalAmountSelected) + Number(element.amount);
            });

            if (availableAmount >= totalAmountSelected) {

                checksArr.forEach((item, index) => {
                    setTimeout(function () {

                        var data = {
                            payableAccountId: item.payableAccountId,
                            amount: item.amount,
                            paymentId: item.paymentId,
                            reference: item.reference.trim(),
                            cashAccountId: cashAccountId,
                            user: user
                        };

                        axios.defaults.headers.common['authorization'] = token;
                        axios.post('/acc/paymentdetail/makePayment', { data })
                            .then(result => {
                                props.getPaymentDetailData(token);
                                props.getInnerPaymentDetailData(token);
                                props.getAccountsData(token);
                                toast.success("Payment Added!");

                            }).catch(err => {
                                console.log(err);
                                toast.error(err.response.data);
                            })

                    }, 1000 * (index + 1));
                });

                window.$('#approveModal').modal('hide');
                this.closeApprovelModal();

            } else {
                toast.error(`Selected account doesn't have ` + totalAmountSelected);
            }

        } else {
            toast.error(`Please Select Account and Payments!`);
        }

    }

    closeApprovelModal = () => {
        this.setState({
            currentAccount: '',
            selection: [],
        });

        document.getElementById("approveForm").reset();
    }

    filterPaymentDetailData = () => {
        var filtered = _.filter(this.props.paymentDetailData, function (o) {
            return o.accounts.type === 'Payable' && o.payments.cancelled !== 'yes'
        });

        var finalFiltered = [];

        filtered.forEach(element => {
            var innerPaymentData = _.filter(this.props.innerPaymentDetailData, function (o) {
                return o.paymentdetail.ref_payment == element.payments._id
            });

            for (let i = 0; i < innerPaymentData.length; i++) {
                if (innerPaymentData[i].paymentdetail.isCompleted === false) {

                    var bank = '';

                    this.props.banksData.forEach(bankData => {
                        if (element.supplier.bankDetails) {
                            if (bankData._id === element.supplier.bankDetails[0]?.selectedBank) {
                                bank = element.supplier.bankDetails
                            }
                        }
                    })

                    var objToPush = {
                        '_id': element.payments._id,
                        'date': element.payments.date,
                        'value': element.payments.value,
                        'time': element.payments.time,
                        'supplier': element.supplier.name,
                        'user': element.users.name.first,
                        'acc_name': element.accounts.acc_name,
                        'acc_id': element.accounts._id,
                        'transaction': element.transactions.reference,
                        'bank': bank[0] ? bank[0].accountName : '',
                        'transaction_amount': element.payments.transaction_amount ? element.payments.transaction_amount : element.payments.value
                    };

                    finalFiltered.push(objToPush);
                    break;
                }
            }
        });

        this.setState({
            paymentDetailDataUA: finalFiltered
        });
    }

    setExportDateFrom = date => {
        this.setState({
            exportDateFrom: date
        });
    }

    setExportDateTo = date => {
        this.setState({
            exportDateTo: date
        });
    }

    resetExportDataForm = () => {
        this.setState({
            exportDateFrom: new Date(this.oneMonthAgo),
            exportDateTo: new Date(),
        });

        document.getElementById("exportDataForm").reset();
        window.$('#exportData').modal('hide');
    }
    render() {

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;

        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox"
        };

        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-4">
                                <h4 className="label">Payment Detail</h4>
                            </div>
                            <div className="col-md-1 offset-7">
                                {/* <button
                                    // hidden={Permission('ACC', 'APPROVE COMMERCIAL REPORT', 'VIEW', this.props)}
                                    data-toggle="modal"
                                    className="btn btn-success"
                                    data-target="#exportData"
                                    onClick={() => {

                                        this.state.exportDateTo = new Date();
                                        this.state.exportDateFrom = new Date(this.oneMonthAgo);

                                    }}
                                >
                                    Export Data
                                </button> */}
                                <button
                                    className="btn btn-primary"
                                    disabled={Permission('ACC', 'payment detail{Approve}', 'VIEW', this.props)}
                                    data-toggle="modal" data-target='#approveModal'
                                    data-backdrop="static" data-keyboard="false"
                                    onClick={e => this.filterPaymentDetailData()}
                                >Approve</button>
                            </div>
                        </div>

                    </div>

                    <ErrorBoundary>
                        <div className="card-body">
                            <FormProvider
                                form="inline"
                            >
                                {formProps => {
                                    return (
                                        <form>
                                            <Table className="-striped"
                                                sorted={this.state.sortOptions}
                                                onSortedChange={val => {
                                                    this.setState({ sortOptions: val })
                                                }}
                                                columns={this.paymentDetailColumns}
                                                data={this.props.paymentDetailData}
                                                defaultPageSize={10}
                                                expanded={this.state.expanded}
                                                onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index)}
                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                showPageJump={false}
                                                SubComponent={row => {
                                                    var subData = _.filter(this.props.innerPaymentDetailData, function (o) {
                                                        return o.paymentdetail.ref_payment == row.original.payments._id
                                                    })
                                                    return (
                                                        <React.Fragment>
                                                            <ErrorBoundary>
                                                                <FormProvider
                                                                    form="inline"
                                                                    enableReinitialize
                                                                >
                                                                    {formProps => {
                                                                        return (
                                                                            <form>
                                                                                <Table className="-striped"
                                                                                    sorted={this.state.sortOptionsIn}
                                                                                    onSortedChange={val => {
                                                                                        this.setState({ sortOptionsIn: val })
                                                                                    }}
                                                                                    columns={this.innerPaymentDetailColumns}
                                                                                    data={subData}
                                                                                    defaultPageSize={10}
                                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                                    showPageJump={false}
                                                                                />
                                                                            </form>
                                                                        )
                                                                    }}

                                                                </FormProvider>
                                                            </ErrorBoundary>
                                                        </React.Fragment>
                                                    );
                                                }}
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </div>
                    </ErrorBoundary>

                    <div id="refundModal" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Refunds Detail</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <ErrorBoundary>
                                            <FormProvider
                                                form="inline"
                                            >
                                                {formProps => {
                                                    return (
                                                        <form>
                                                            <Table className="-striped"
                                                                sorted={this.state.sortOptionsRF}
                                                                onSortedChange={val => {
                                                                    this.setState({ sortOptionsRF: val })
                                                                }}
                                                                columns={this.refundColumns}
                                                                data={this.state.refundsData}
                                                                defaultPageSize={10}
                                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                                showPageJump={false}
                                                            />
                                                        </form>
                                                    )
                                                }}

                                            </FormProvider>
                                        </ErrorBoundary>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="creditNoteModal" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Credit Note Detail</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <ErrorBoundary>
                                            <FormProvider
                                                form="inline"
                                            >
                                                {formProps => {
                                                    return (
                                                        <form>
                                                            <Table className="-striped"
                                                                sorted={this.state.sortOptionsCN}
                                                                onSortedChange={val => {
                                                                    this.setState({ sortOptionsCN: val })
                                                                }}
                                                                columns={this.creditNoteColumns}
                                                                data={this.state.creditNoteData}
                                                                defaultPageSize={10}
                                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                                showPageJump={false}
                                                            />
                                                        </form>
                                                    )
                                                }}

                                            </FormProvider>
                                        </ErrorBoundary>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="approveModal" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Approve Payments</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.closeApprovelModal()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <ErrorBoundary>
                                            <form id='approveForm'>
                                                <table style={{ 'width': '100%' }}>
                                                    <tr>
                                                        <td style={{ 'width': '20%' }}>Select Cash Account* :</td>
                                                        <td>
                                                            <select className="form-control" onChange={e => e.target.value === '' ? null : this.setCurrentAccount(e.target.value)}>
                                                                <option value={''}>- Select Account -</option>
                                                                {this.props.accounts.map(element => {
                                                                    if (element.type === 'Cash') {
                                                                        return <option value={element._id + '-' + element.balance}>{element.acc_name}</option>
                                                                    }
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </table>

                                                <CheckboxTable className="-striped"
                                                    ref={(r) => (this.checkboxTable = r)}
                                                    sorted={this.state.sortOptionsUA}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptionsUA: val })
                                                    }}
                                                    columns={this.unapprovedPaymentsColumns}
                                                    data={this.state.paymentDetailDataUA}
                                                    defaultPageSize={10}
                                                    expanded={this.state.expandedUA}
                                                    onExpandedChange={(newExpanded, index, event) => this.handleRowExpandedUA(newExpanded, index)}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                    SubComponent={row => {
                                                        var subData = _.filter(this.props.innerPaymentDetailData, function (o) {
                                                            return o.paymentdetail.ref_payment == row.original._id && o.paymentdetail.isCompleted === false
                                                        })
                                                        console.log(subData)
                                                        return (
                                                            <React.Fragment>
                                                                <ErrorBoundary>
                                                                    <FormProvider
                                                                        form="inline"
                                                                        enableReinitialize
                                                                    >
                                                                        {formProps => {
                                                                            return (
                                                                                <form>
                                                                                    <Table className="-striped"
                                                                                        sorted={this.state.sortOptionsIn}
                                                                                        onSortedChange={val => {
                                                                                            this.setState({ sortOptionsIn: val })
                                                                                        }}
                                                                                        columns={this.innerPaymentDetailColumnsUnapproved}
                                                                                        data={subData}
                                                                                        defaultPageSize={5}
                                                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                                                        showPageJump={false}
                                                                                    />
                                                                                </form>
                                                                            )
                                                                        }}

                                                                    </FormProvider>
                                                                </ErrorBoundary>
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                    {...checkboxProps}
                                                />
                                            </form>

                                        </ErrorBoundary>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={e => this.pay()} disabled={this.state.disableMakePaymentButton}>Make Payment</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.closeApprovelModal()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="exportData" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Export Data</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.resetExportDataForm()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='exportDataForm'>

                                            <div className="row">
                                                <div className="col-lg-6">

                                                    <td>Date From* :</td>
                                                    <td><DatePicker selected={this.state.exportDateFrom} onChange={this.setExportDateFrom} /></td>

                                                </div>
                                                <div className="col-ld-6">

                                                    <td>Date To* :</td>
                                                    <td><td><DatePicker selected={this.state.exportDateTo} onChange={this.setExportDateTo} /></td></td>

                                                </div>
                                            </div>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={e => this.handleExportData(this.props)}>Export</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.resetExportDataForm()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        paymentDetailData: state.rPaymentDetail.paymentDetailData,
        innerPaymentDetailData: state.rPaymentDetail.innerPaymentDetailData,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userId: state.rLogin.id,
        accounts: state.rAccounts.accounts,
        banksData: state.rBanks.banksData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPaymentDetailData: (token) => { dispatch(getPaymentDetailData(token)) },
        getInnerPaymentDetailData: (token) => { dispatch(getInnerPaymentDetailData(token)) },
        getAccountsData: (token) => { dispatch(getAccountsData(token)) },
        getBanksData: (token) => { dispatch(getBanksData(token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetail);


