import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import { getMissingValue } from "../../../actions/Dashboard/dashboard_action"
import Card from '../../components/Card';
import PriceFormat from '../../utils/PriceFormat';
import MissingItem from './MissingItem';
import ActivatedInactivated from './ActivatedInactivated';
import Sales from './Sales';
import POSSales from './PosSales';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Customers from './Customers';

// import PowerbiEmbedded from 'react-powerbi'

// export default class Dashboard extends Component {
//   render() {
//     return (
//       <div className="right_col" role="main">
//         Dashboard
//       </div>
//     )
//   }
// }

function Dashboard(props) {

  // const socket = io(process.env.NODE_ENV === 'production' ? '' : `http://${process.env.HOST || 'localhost'}:${process.env.PORT || 4000}`)
  // // const socket = io()
  // socket.on('connection')

  // socket.on('user_count', data => {
  //   console.log(data);
  //   // this.setState({ message: data })
  //   setCount(data)
  // })

  return (
    <div className="right_col" role="main">
      <div className="card-deck">
        {<MissingItem />}
        {<ActivatedInactivated />}
        {<Sales />}
      </div>
      <div className="card-deck">
        <Customers />
        <POSSales />
        <div className="card">
        </div>
      </div>
    </div>
  )
}

export default Dashboard;
