// import _ from 'lodash';
/**
 * @type {Object}
 * @property {array}  data  Order data
 */
const initialState = {
    data: [],
    orderItems: [],
    orderIds: [],
    backlistCustomers: [],
    allOrderItems: [],
    fastDeliveryDiscountItems: []
};

const orderingReducer = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_ORDERING':
            // newState.data = _.filter(data, function (o) {
            //     return o.orders.status !== 'Delivered'
            // })
            newState.data = data;
            break;

        case 'FETCH_ORDERING_ITEMS':
            // var { data } = action;
            newState.orderItems = data;
            break;

        case 'FETCH_ORDERING_IDS':
            newState.orderIds = data;
            break;

        case 'FETCH_BACKLIST_CUSTOMER':
            newState.backlistCustomers = data;
            break;

        case 'FETCH_ALL_ORDER_ITEMs':
            newState.allOrderItems = data;
            break;

        case 'FETCH_FAST_DELIVERY_DISCOUNT':
            newState.fastDeliveryDiscountItems = data;
            break;

        default:
            break;
    }
    return newState;
}

export default orderingReducer;
