/**
 * @author Umesh Ranthilina
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const orderDataAsync = data => {
    return { type: 'FETCH_POS_ORDER_DATA', data }
}

export const orderingItemsAsync = data => {
    return { type: 'FETCH_POS_ORDER_ITEMs', data }
}

/**
 * 
 * @param {*} token 
 */
export const getOrderData = (user, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/getOrders', user)
            .then(result => {
                dispatch(orderDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * get ordering items details 
 * 
 * @param {Array} items itmes id
 * @param {string} token 
 */
export const getOrderItemsData = (items, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/pos/getOrderItems', items)
            .then(result => {
                dispatch(orderingItemsAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * @param {*} token
 * @param user  
 * @param creditNo
 * @param creditType
 */
export const updatePosOrder = ({ token, data, user, from, to}) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/updatePosOrder', { data })
            .then(result => {
                toast.success('Order is Updated..!.')
                dispatch(getOrderData({ userId: user, from: from, to: to }, token));
            }).catch(err => {
                toast.error(err.response.data)
            })
    }
}