import React from 'react';
import Table from "react-table";
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Permission from '../../../../utils/Permission';

const AccountViewTable = ({ props, accountData, filterCaseInsensitive, reloadData }) => {

    const deliveryWarehouseAccountsColumn = [
        { Header: 'Account Name',accessor: 'accountsData.acc_name'},
        { Header: 'Type',accessor: 'type'},
        {
            Header: 'Action',
            maxWidth: 110,
            Cell: row =>
                <>
                    <button  disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'DELETE', props)} className='btn btn-link'
                        // data-toggle="modal" data-target='#viewImagesPopup' 
                        onClick={() => { props.deleteDeliveryWarehouseAccount({ _id: row?.original?._id, account_id: row?.original?.account }, props); reloadData() }}
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </>
        }
    ];

    return (
        <div className="collapse multi-collapse" id="viewUAccount">
            <div class="card card-body">
                {accountData?.length === 0 ?
                    <>
                        No Data Found...!
                    </>
                    : <>
                        <ErrorBoundary>

                            <Table className="-striped"
                                // sorted={sortOptions}
                                columns={deliveryWarehouseAccountsColumn}
                                data={accountData}
                                defaultPageSize={5}
                                defaultFilterMethod={filterCaseInsensitive}
                            />

                        </ErrorBoundary>
                    </>}
            </div>
        </div>
    )
}

export default AccountViewTable
