import React, { useState, useEffect, PureComponent } from 'react'
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, AreaChart, ReferenceLine, Area, Line, ComposedChart } from 'recharts';
import { connect } from 'react-redux';
import { getDeliveryWarehouses } from "../../../actions/Basic_data/delivery_warehouse_actions";

function PosSales(props) {

    const [outletData, setOutletData] = useState("");
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [composedChartData, setComposedChartData] = useState([]);
    const [areaChartData, setAreaChartData] = useState([]);
    const [outletColors, setOutletColors] = useState([]);
    const [isActiveChart, setActiveChart] = useState(true);

    const years = []

    for (let i = new Date().getFullYear(); i >= 2019; i--) {
        years.push(i)
    }

    const getChartData = () => {
        axios.defaults.headers.common['authorization'] = props.token;

        const data = {
            'selectedYear': Number(selectedYear),
            'outlet': outletData
        }

        console.log(data)
        if (isActiveChart === true) {
            axios.post('dashboard/getPosSale', data)
                .then(result => {
                    setComposedChartData(result.data.salesAndCosts);
                }).catch(err => {
                    console.error(err)
                })
        }
        else if (isActiveChart === false) {
            axios.post('dashboard/getSaleOutletWise', data)
                .then(result => {
                    setAreaChartData(result.data.salesOutletwise);
                    setOutletColors(result.data.outletColors);
                }).catch(err => {
                    console.error(err)
                })
        }
    }

    const setActiveRadio = (value) => {
        setActiveChart(value)
        setOutletData("")
    }

    useEffect(() => {
        getChartData();

    }, [isActiveChart, selectedYear, outletData])

    useEffect(() => {
        props.getDeliveryWarehouses(props)
    }, [])

    // Format number values shown in Y axis of charts
    const DataFormater = (number) => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    }

    // Add percentage sign
    const PercentageFormater = (number) => {
        return number.toString() + "%"
    }


    return (
        <>
            <div className="card shadow my-4 mx-6 rounded-5" style={{ display: "flex", justifyContent: 'space-evenly' }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <h6 className="m-0 font-weight-bold text-primary">POS Sales</h6>
                            <div>
                                <input type='radio' name='dashboard2' style={{ marginRight: '5px' }} checked={isActiveChart ? true : false} onChange={e => { setActiveRadio(true) }} /><label>Composed</label>
                                <input type='radio' name='dashboard2' style={{ marginLeft: '20px', marginRight: '5px' }} onChange={e => { setActiveRadio(false) }} /><label>Area</label>
                            </div>
                        </div>
                        <div className="col-md-6"  >
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    {isActiveChart === true &&

                                        <select className="form-control form-control-sm"
                                            style={{ fontSize: '15px', fontWeight: 'bold', width: '120px' }}
                                            onChange={e => setOutletData(e.target.value)} >
                                            <option value={''}>All Outlet</option>
                                            {props.deliveryWarehouseData.map(outlet => {
                                                return <option key={outlet._id} value={outlet._id}>{outlet.name}</option>
                                            })}
                                        </select>

                                    }
                                </div>
                                <div style={{ marginLeft: '2px' }}>
                                    <select
                                        className="form-control form-control-sm mx-sm-3"
                                        style={{ fontSize: '14px', fontWeight: 'bold', width: '60px' }}
                                        value={selectedYear}
                                        onChange={e => setSelectedYear(e.target.value)}>
                                        {years.map((value) => (
                                            <option value={value} key={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body" >
                    <div style={{ padding: "0px" }}>
                        <div style={{ textAlign: "center", display: "block", justifyContent: "space-around" }}>

                            {
                                isActiveChart === true &&
                                <ResponsiveContainer width='100%' height={278}>
                                    <ComposedChart
                                        data={composedChartData}
                                        margin={{
                                            top: 10,
                                            right: 10,
                                            left: 10,
                                            bottom: 10,
                                        }}
                                    >
                                        <defs>
                                            <linearGradient id="colorPv5" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="10%" stopColor="#6812b9" stopOpacity={1} />
                                                <stop offset="100%" stopColor="#6812b9" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" tick={{ fill: '#000000' }} />
                                        <YAxis yAxisId="left" style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                                        <YAxis yAxisId="right" orientation="right" style={{ fontSize: '14px' }} width={50} tick={{ fill: '#449759' }} tickFormatter={PercentageFormater} />
                                        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left', backgroundColor: '#fff' }} />
                                        <Legend style={{ textShadow: '#a09dcf' }} />
                                        <Bar yAxisId="left" dataKey="sales" barSize={20} fill="url(#colorPv5)" />
                                        <Line yAxisId="left" type="monotone" dataKey="costs" stroke="#dfd317" strokeWidth={4} />
                                        <Line yAxisId="left" type="monotone" dataKey="margin" stroke="#10bd54" strokeWidth={4} />
                                        <Line yAxisId="right" type="monotone" dataKey="marginPercentage" stroke="#f50589" strokeWidth={4} />

                                    </ComposedChart>
                                </ResponsiveContainer>
                            }

                            {
                                isActiveChart === false &&

                                <ResponsiveContainer height={272}>
                                    <AreaChart
                                        data={areaChartData}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 10,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" tick={{ fill: '#000000' }} />
                                        <YAxis style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                                        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left' }} />

                                        {outletColors.map((outletColor) => (
                                            <Area type="monotone" dataKey={outletColor.outlet} stackId="1" stroke={outletColor.color} fill={outletColor.color}
                                                strokeWidth={2} fillOpacity={2} activeDot={{ r: 4 }} />
                                        ))}
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        </div>
                        <center><span style={{ fontSize: '14px', color: '#191350' }} className="m-0 font-weight-bold">Months of Year - {selectedYear}</span></center>
                    </div>
                </div>
            </div >
        </>
    )

}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        id: state.rLogin.id,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDeliveryWarehouses: token => { dispatch(getDeliveryWarehouses(token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PosSales)
