import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Table from "react-table";
import GridFilter from '../../components/GridFilter';
import { DateNumberFormat, PriceFormat } from '../../utils';
import ErrorBoundary from "../../components/ErrorBoundary";
import { getCreditNoteData, transferCreditNote } from '../../../actions/Delivery/delivery_action';
import { getAccountsData } from '../../../actions/Acc/account_actions';
import "react-table/react-table.css";

export const CreditNote = props => {

    const [currentCreditNode, setCurrentCreditNode] = useState({});
    const [selectedAccount, setSelectedAccount] = useState('');

    useEffect(() => {
        props.getCreditNoteData(props.token)
        props.getAccountsData(props.token)
    }, [])

    const transferCreditNote = () => {
        const data = {
            selectedAccount: selectedAccount,
            creditNoteObj: currentCreditNode,
            user: props.userId
        };

        props.transferCreditNote(data, props.token);

        setSelectedAccount('');
        setCurrentCreditNode({})
        document.getElementById("transferCreditNoteForm").reset();
    }

    const columns = [
        { Header: "Customer Name", accessor: "coName", ...GridFilter },
        { Header: "Telephone", accessor: "telephone", ...GridFilter },
        { Header: "Order Id", accessor: "orderId", ...GridFilter },
        {
            Header: "Value", accessor: "price", ...GridFilter, 
            Cell: prop => 
            <div style={{ textAlign: 'right' }}>
                {PriceFormat(prop.original.price)}
            </div>
        },
        { Header: "Remark", accessor: "remark", ...GridFilter },
        { Header: "Used Credit Note", accessor: "usedCreditNote", ...GridFilter },
        {
            Header: "isCompleted", accessor: "isCompletedStatus", ...GridFilter,
            Cell: prop => <>
                {prop.value}
            </>
        },
        {
            Header: "CreditedAt", accessor: "creditedAt", ...GridFilter,
            Cell: prop => <>
                {DateNumberFormat(prop.value)}
            </>
        },
        {
            Header: "CreatedAt", accessor: "createdAt", ...GridFilter,
            Cell: prop => <>
                {DateNumberFormat(prop.value)}
            </>
        },
        {
            Header: "Transfer Credit Node",
            Cell: props =>
                <>
                    <div className="custom-control custom-switch">
                        <a data-toggle="modal" disabled={props.original.isCompleted ? true : false} data-target={props.original.isCompleted ? '' : "#transferCreditNote"}
                            style={{
                                "color": (props.original.isCompleted ? "" : "#007bff"),
                                "textDecoration": (props.original.isCompleted ? "" : "underline"),
                                "cursor": (props.original.isCompleted ? "" : "pointer")
                            }}
                            onClick={(e) => {
                                setCurrentCreditNode(props.original)
                            }}
                        >Transfer Credit Node</a>
                    </div>
                </>
        },
    ]

    return (
        <div className="right_col" role="main" >
            <div className='card-body'>
                <ErrorBoundary>
                    <div className="card-body">
                        <Table className="-striped"
                            columns={columns}
                            data={props.creditNoteData}
                            defaultPageSize={20}
                        />
                    </div>
                </ErrorBoundary>

                <ErrorBoundary>
                    <div id="transferCreditNote" className="modal fade" role="dialog">
                        <div className="modal-dialog model-xl">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Transfer Credit Note</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <form id='transferCreditNoteForm'>
                                        <table>
                                            <tr>
                                                <td>Select Account:</td>
                                                <td>
                                                    <select className="form-control" onChange={e => e.target.value === '' ? null : setSelectedAccount(e.target.value)}>
                                                        <option value={''}>- Select Account -</option>
                                                        {props.accounts.map(element => {
                                                            if (element.type === "Payable") {
                                                                return <option value={element._id}>{element.acc_name}</option>
                                                            }
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>

                                        </table>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" disabled={props.isLoading ? true : false} onClick={transferCreditNote}>Transfer</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userId: state.rLogin.id,
        loggedUser: state.rLogin.name,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        creditNoteData: state.rCreditNote.creditNoteData,
        accounts: state.rAccounts.accounts,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCreditNoteData: (token) => { dispatch(getCreditNoteData(token)) },
        getAccountsData: (token) => { dispatch(getAccountsData(token)) },
        transferCreditNote: (data, token) => { dispatch(transferCreditNote(data, token)) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CreditNote)
