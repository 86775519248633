/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";

import { getAddresses, updateAddress, deleteAddress, addAddress } from '../../../actions/Administrator/addresses_actions';
import { getUserData, getCityData } from '../../../actions/Basic_data/basic_data_action';
import ErrorBoundary from "../../components/ErrorBoundary";
import { element } from "prop-types";
import Permission from '../../utils/Permission';

const ReactTableFixedColumns = withFixedColumns(Table);


class Addresses extends React.Component {

    boolData = [
        { '_id': 'true', 'name': 'true' },
        { '_id': 'false', 'name': 'false' }
    ];

    state = {
        addresses: [],
        cities: [],
        users: [],
        contactName: '',
        email: '',
        phone: '',
        zipCode: '',
        district: '',
        province: '',
        city: '',
        streetAddress: '',
        user: '',
        isBilling: false,
        isDelivery: false,
        isPhoneVerified: false,
        slug: '',
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'addresses.contactName', desc: false }],
    };

    componentDidMount() {
        this.props.getAddresses(this.props.token);
        this.props.getCityData(this.props.token);
        this.props.getUserData(this.props.token);
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDropdownList,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    props.column.Header === 'City' ? this.props.cities :
                        props.column.Header === 'User' ? this.props.users :
                            this.boolData).map(data =>
                                <option key={data._id} value={data._id}>{props.column.Header === 'User' ? data.name.first : data.name}</option>
                            )}
            </Field> : <label>{props.value}</label>)
        }
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        city: rowProps.original.cities._id,
                        isBilling: rowProps.original.addresses.isBilling,
                        isDelivery: rowProps.original.addresses.isDelivery,
                        isPhoneVerified: rowProps.original.addresses.isPhoneVerified,
                    });

                    rowProps.original.users !== undefined ? this.setState({ user: rowProps.original.users._id }) : this.setState({ user: null });

                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('ADMINISTRATOR', 'ADDRESSES', 'UPDATE', this.props),
            delPerm: Permission('ADMINISTRATOR', 'ADDRESSES', 'DELETE', this.props)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === 'City') {
            this.setState({ city: value })
        } else if (column === 'User') {
            this.setState({ user: value })
        } else if (column === 'Is Billing') {
            if (value === 'true') {
                this.setState({ isBilling: true })
            } else {
                this.setState({ isBilling: false })
            }
        } else if (column === 'Is Delivery') {
            if (value === 'true') {
                this.setState({ isDelivery: true })
            } else {
                this.setState({ isDelivery: false })
            }
        } else if (column === 'Is Phone Verified') {
            if (value === 'true') {
                this.setState({ isPhoneVerified: true })
            } else {
                this.setState({ isPhoneVerified: false })
            }
        }
    }

    handleSubmit = values => {

        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values.addresses;

            if (isNaN(data.phone)) {
                toast.error('Phone must be a number!')
                return;
            }

            if (data.phone.toString().length !== 9) {
                toast.error('Invalid Phone Number!')
                return;
            }

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(data.email)) {
                toast.error('Invalid Email!')
                return;
            }

            data.user = this.state.user;
            data.city = this.state.city;
            data.isBilling = this.state.isBilling;
            data.isDelivery = this.state.isDelivery;
            data.isPhoneVerified = this.state.isPhoneVerified;
            data.phone = Number(data.phone);

            this.props.updateAddress(data, this.props.token)

            this.setState({
                user: '',
                city: '',
                isBilling: '',
                isDelivery: '',
                isPhoneVerified: '',
            });

        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values.addresses._id
            }

            this.props.deleteAddress(data, this.props.token)
        }
    };

    addAddressHandle = () => {
        var { contactName,
            email,
            phone,
            streetAddress,
            city,
            user,
            zipCode,
            district,
            province,
            isBilling,
            isDelivery,
            isPhoneVerified } = this.state;

        if (contactName.trim() !== ''
            && email !== ''
            && phone !== ''
            && streetAddress !== ''
            && city !== ''
            && user !== '') {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(email)) {
                toast.error('Invalid Email!')
                return;
            }

            if (isNaN(phone)) {
                toast.error('Phone must be a number!')
                return;
            }

            if (phone.length !== 9) {
                toast.error('Invalid Phone Number!')
                return;
            }

            this.state.phone = Number(this.state.phone);

            this.state.slug = 1;

            this.props.addAddress(this.state, this.props.token)

            this.setState({
                contactName: '',
                email: '',
                phone: '',
                streetAddress: '',
                city: '',
                user: ''
            });

            document.getElementById("addAddressForm").reset();

        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined' && content !== '') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

        return true;
    };

    productColumns = [
        { Header: "Name", accessor: "name", filterable: true }
    ];

    addressColumns = [
        { Header: "Contact Name", accessor: "addresses.contactName", ...this.editableColumnProps },
        { Header: "Email", accessor: "addresses.email", ...this.editableColumnProps },
        { Header: "Phone", accessor: "addresses.phone", ...this.editableColumnProps },
        { Header: "Zip Code", accessor: "addresses.zipCode", ...this.editableColumnProps },
        { Header: "District", accessor: "addresses.district", ...this.editableColumnProps },
        { Header: "Province", accessor: "addresses.province", ...this.editableColumnProps },
        { Header: "City", accessor: "cities.name", ...this.selectableColumnProps },
        { Header: "Street Address", accessor: "addresses.streetAddress", ...this.editableColumnProps },
        { Header: "User", accessor: "users.name.first", ...this.selectableColumnProps },
        { Header: "Is Billing", accessor: "addresses.isBilling", ...this.selectableColumnProps },
        { Header: "Is Delivery", accessor: "addresses.isDelivery", ...this.selectableColumnProps },
        { Header: "Is Phone Verified", accessor: "addresses.isPhoneVerified", ...this.selectableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('ADMINISTRATOR', 'ADDRESSES', 'ADD', this.props)} data-toggle="modal" data-target="#addAddress">+ Address</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.addressColumns}
                                                    data={this.props.addresses}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>


                    <ErrorBoundary>
                        <div id="addAddress" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Add Address</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <React.Fragment>
                                            <form id='addAddressForm'>
                                                <table>
                                                    <tbody>

                                                        <tr>
                                                            <td>Contact Name* :</td>
                                                            <td><input type="text" className="form-control" onBlur={e => this.setState({ contactName: e.target.value })} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td>Email* :</td>
                                                            <td><input type="email" className="form-control" onBlur={e => this.setState({ email: e.target.value })} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td>Phone* :</td>
                                                            <td><input type="number" className="form-control" onBlur={e => this.setState({ phone: e.target.value })} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td>Street Address* :</td>
                                                            <td><input type="textarea" className="form-control" onBlur={e => this.setState({ streetAddress: e.target.value })} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td>City* :</td>
                                                            <td>
                                                                <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ city: e.target.value })}>
                                                                    <option value={''}>- Select City -</option>
                                                                    {this.props.cities.map(element => {
                                                                        return <option value={element._id}>{element.name}</option>
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>User* :</td>
                                                            <td>
                                                                <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ user: e.target.value })}>
                                                                    <option value={''}>- Select User -</option>
                                                                    {this.props.users.map(element => {
                                                                        return <option value={element._id}>{element.name.first + ' ' + element.name.last}</option>
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                    <div className="modal-footer">
                                        <button id="add_stock" type="button" className="btn btn-primary" onClick={this.addAddressHandle}>Add</button>
                                        {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        addresses: state.rAddresses.addresses,
        cities: state.rBasicData.city,
        users: state.rBasicData.users,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        isLoading: state.rLogin.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAddresses: (token) => { dispatch(getAddresses(token)) },
        getUserData: (token) => { dispatch(getUserData(token)) },
        getCityData: (token) => { dispatch(getCityData(token)) },
        updateAddress: (data, token) => { dispatch(updateAddress(data, token)) },
        deleteAddress: (data, token) => { dispatch(deleteAddress(data, token)) },
        addAddress: (data, token) => { dispatch(addAddress(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);


