import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Table from "react-table";
import _ from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from "moment";
// import SearchableSelectComp from '../../components/SearchableSelectComp';
import ReactToPrint from "react-to-print";
import * as XLSX from 'xlsx';

import ErrorBoundary from '../../components/ErrorBoundary';
import { DateNumberFormat, PriceFormat } from '../../utils';
import InvoicePrint from '../Ordering/InvoicePrint';
import FormProvider from "../../components/FormProvider";

import { deliveryInspect, getDeliveryCrew, getOrderReportData } from '../../../actions/Delivery/delivery_action';
import { getOrderIds, getOrderItemFromId } from '../../../actions/Ordering/ordering_order_action';
import { getRegionData, getPaymentMethodData } from '../../../actions/Basic_data/basic_data_action';
import ConvertDecimalPlaces from "../../utils/ConvertDecimalPlaces";


const orderReset = (obj, { setSelectedOrder, selectedOrder, completeData, setCompleteData }, setData, props) => {
    if (selectedOrder) {
        document.getElementById(`div-${selectedOrder?.orderId}`).style.backgroundColor = 'white'
        document.getElementById(`div-${selectedOrder?.orderId}`).style.color = 'black'
        document.getElementById(`etd-${selectedOrder?.orderId}`).style.color = 'red'
        document.getElementById(`name-${selectedOrder?.orderId}`).style.color = 'purple'
    }
    // if (obj) {
    //     document.getElementById(`div-${obj?._id}`).style.backgroundColor = '#667391'
    //     document.getElementById(`div-${obj?._id}`).style.color = '#fff'
    // }
    setSelectedOrder(obj)
    setCompleteData({ ...completeData, orderId: obj._id })
    getOrderItemFromId(obj, setData, props)
}

// const _searchHandler = (obj, reconcilationData, setReconcilationData) => {
//     // let chackVal = _.filter(reconcilationData, o => o.orders._id === obj.orders._id)
//     let chackVal = reconcilationData.filter(o => o.orders._id === obj.orders._id)
//     if (chackVal.length > 0) {
//         toast.info('already added...!')
//     }
//     let val = [...reconcilationData, obj]
//     let uniqArr = _.uniqBy(val, o => o.orders._id)
//     // setReconcilationData(rData => [...rData, obj])
//     setReconcilationData(uniqArr)
// }

const orderDataFilter = (obj, { setSearchData, searchData }, { orderIds }) => {
    let data = orderIds?.filter(ele => ele.deliveryPerson === obj.deliverycrew?._id);
    setSearchData({ ...searchData, orderId: data })
}

const orderItemsHandle = (type, { completeData, setCompleteData, isLoading, setIsLoading }, props, { token }) => {
    let obj = ({ ...props.original, type, orderItemId: props.original._id })
    let uniqArr = _.uniqBy(([...completeData.orderItem, obj]), '_id')
    // console.log('props', props.original);
    let qty = (type === 'missing' ? props.original.missingQty : props.original.quantity)

    setIsLoading({ loading: true, id: props.original._id, type, undo: true })
    axios.defaults.headers.common['authorization'] = token;
    axios.get('/deliveryInspect/stockValidation', { params: { id: obj.orderItemId, item: obj.item, qty: qty, order_ref: obj.order_ref } })
        .then(result => {
            setIsLoading({ ...isLoading, loading: false, type })

            if (type === 'not-available') {
                let notAvailArr = ([...completeData.notAvail, obj]);
                setCompleteData({ ...completeData, orderItem: uniqArr, notAvail: notAvailArr })
            } else if (result.status === 200 || (type === 'missing' && qty === obj.quantity) || ((type === 'missing') && (result.data.stockQty >= (result.data.itemQty - qty)))) {
                if (result.status === 200) toast.success(result.data.msg)

                if (type === 'complete') {
                    let comArr = _.uniqBy(([...completeData.complete, obj]), '_id');
                    setCompleteData({ ...completeData, orderItem: uniqArr, complete: comArr });
                } else if (type === 'missing') {
                    let misArr = ([...completeData.missing, ({ ...obj, misQty: props.original.missingQty })]);
                    setCompleteData({ ...completeData, orderItem: uniqArr, missing: misArr });
                }
                // else {
                //     let notAvailArr = ([...completeData.notAvail, obj]);
                //     setCompleteData({ ...completeData, orderItem: uniqArr, notAvail: notAvailArr })
                // }
            } else {
                toast.warn(result.data.msg)
                setIsLoading({ loading: false, id: '', type, undo: false })
                document.getElementById(`btn-s-${props.original._id}`).disabled = false
                document.getElementById(`btn-m-${props.original._id}`).disabled = false
                document.getElementById(`btn-d-${props.original._id}`).disabled = false
                document.getElementById(`btn-u-${props.original._id}`).style.display = "none"

                // if (isLoading.type === 'complete') {
                document.getElementById(`btn-s-${props.original._id}`).classList.remove('btn-secondary')
                document.getElementById(`btn-s-${props.original._id}`).classList.add('btn-success')
                // }
                // if (isLoading.type === 'missing') {
                document.getElementById(`btn-m-${props.original._id}`).classList.remove('btn-secondary')
                document.getElementById(`btn-m-${props.original._id}`).classList.add('btn-warning')
                // }
                // if (isLoading.type === 'not-available') {
                document.getElementById(`btn-d-${props.original._id}`).classList.remove('btn-secondary')
                document.getElementById(`btn-d-${props.original._id}`).classList.add('btn-danger')
                // }
            }
        }).catch(err => {
            setIsLoading({ ...isLoading, loading: false, type, undo: false })
            console.error(err);
        })

    console.log(type, props, completeData);
}


const removeItemHandle = ({ completeData, setCompleteData, isLoading, setIsLoading }, props) => {


    let _id = props.original._id
    let orderItems = completeData.orderItem
    let completeItems = completeData.complete
    let missingItems = completeData.missing
    let notAvailItems = completeData.notAvail

    // console.log(orderItems, props);
    document.getElementById(`btn-s-${_id}`).disabled = false
    document.getElementById(`btn-m-${_id}`).disabled = false
    document.getElementById(`btn-d-${_id}`).disabled = false
    document.getElementById(`btn-u-${_id}`).style.display = "none"

    var removedItem = _.remove(orderItems, function (n) {
        return n._id === _id;
    })

    // console.log('remove', removedItem, orderItems);

    let [{ type }] = removedItem.filter(ele => ele._id === _id)
    // console.log('type', type);
    if (type === 'complete') {
        document.getElementById(`btn-s-${_id}`).classList.remove('btn-secondary')
        document.getElementById(`btn-s-${_id}`).classList.add('btn-success')
        let comData = _.remove(completeItems, function (n) {
            return n._id == _id;
        })
        setCompleteData({ ...completeData, orderItems: orderItems, complete: completeItems });
    } else if (type === 'missing') {
        document.getElementById(`btn-m-${_id}`).classList.remove('btn-secondary')
        document.getElementById(`btn-m-${_id}`).classList.add('btn-warning')
        let comData = _.remove(missingItems, function (n) {
            return n._id == _id;
        })
        setCompleteData({ ...completeData, orderItem: orderItems, missing: missingItems })
    } else {
        let comData = _.remove(notAvailItems, function (n) {
            return n._id == _id;
        })
        setCompleteData({ ...completeData, orderItem: orderItems, notAvail: notAvailItems })
    }

}

const resetState = (setSearchData, setSelectedOrder, setData, setCancel, setCompleteData, setInput, setIsLoading, setBagData, setBagNames) => {
    setSearchData({ orderId: [], riderName: '' })
    setSelectedOrder(null)
    setData([])
    setCancel(false)
    setCompleteData({ riderName: '', orderId: '', orderItem: [], complete: [], missing: [], notAvail: [] })
    setInput('')
    setIsLoading({ loading: false, id: '', type: '', undo: false })
    setBagData([])
    setBagNames('')
}

export const DeliveryInspect = props => {
    const [searchData, setSearchData] = useState({ orderId: [], riderName: '' });
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [data, setData] = useState([]);
    const [cancel, setCancel] = useState(false);
    const [completeData, setCompleteData] = useState({ riderName: '', orderId: '', orderItem: [], complete: [], missing: [], notAvail: [] });
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState({ loading: false, id: '', type: '', undo: false });
    const [search, setSearch] = useState('')
    const [regionFilter, setRegionFilter] = useState('')
    const [shelfLife, setShelfLife] = useState([]);
    const [bagData, setBagData] = useState([])
    const [printOrder, setPrintOrder] = useState(null);
    const [printOrderItems, setPrintOrderItems] = useState(null);
    const [printOrderItemsData, setPrintOrderItemsData] = useState(null);
    const [isPrintLoading, setIsPrintLoading] = useState(null);
    const [date, setDate] = useState(null);
    const [bagNames, setBagNames] = useState('')
    const [orderCount, setOrderCount] = useState(0)
    const [fromDate, setFromDate] = useState(moment(Date.now()).format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date().setMonth(new Date().getMonth() + 1)).format("YYYY-MM-DD"))
    const [orderQty, setOrderQty] = useState(null);
    const [expireData, setExpireData] = useState([]);

    const componentRef = useRef();

    useEffect(() => {
        if (isLoading.id !== '') {
            document.getElementById(`btn-s-${isLoading.id}`).disabled = true
            document.getElementById(`btn-m-${isLoading.id}`).disabled = true
            document.getElementById(`btn-d-${isLoading.id}`).disabled = true
            document.getElementById(`btn-u-${isLoading.id}`).style.display = "block"

            if (isLoading.type === 'complete') {
                document.getElementById(`btn-s-${isLoading.id}`).classList.add('btn-secondary')
                document.getElementById(`btn-s-${isLoading.id}`).classList.remove('btn-success')
            }
            if (isLoading.type === 'missing') {
                document.getElementById(`btn-m-${isLoading.id}`).classList.add('btn-secondary')
                document.getElementById(`btn-m-${isLoading.id}`).classList.remove('btn-warning')
            }
            if (isLoading.type === 'not-available') {
                document.getElementById(`btn-d-${isLoading.id}`).classList.add('btn-secondary')
                document.getElementById(`btn-d-${isLoading.id}`).classList.remove('btn-danger')
            }
        }
    }, [isLoading])


    const columns = [
        {
            Header: "Name", accessor: "name",
            Cell: Props => {
                let stockExists = Props.original?.stock - Props.original?.quantity
                return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{Props.value}</div>
                        <div>{Props.original.itemCode}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', width: '15%', justifyContent: 'center' }}>
                        <div style={{ width: '10px', height: '10px', borderRadius: '10px', backgroundColor: stockExists > 0 ? 'green' : stockExists === 0 ? 'yellow' : 'red' }}></div>
                    </div>
                </div>)
            }
        },
        {
            Header: "Qty", accessor: "quantity",
            minWidth: 80,
            maxWidth: 100,
            Cell: Props => <div style={{ textAlign: 'right' }}>{Props.value}</div>
        },
        {
            Header: "Price", accessor: "originalPrice",
            minWidth: 80,
            maxWidth: 100,
            Cell: Props => <div style={{ textAlign: 'right' }}>{PriceFormat(Props.value)}</div>
        },
        {
            Header: "Missing", accessor: "_id",
            minWidth: 80,
            maxWidth: 100,
            Cell: Props => {
                let val = _.filter(completeData.missing, { _id: Props.value })
                return <div style={{ textAlign: 'right' }}>{val[0]?.missingQty || 0}</div>
            }
        },
        {
            Header: "", accessor: "_id",
            Cell: Props => {
                let obj = completeData?.orderItem?.filter(e => e._id === Props.original._id)
                let className1 = obj[0]?.type === 'complete' ? 'btn-secondary' : 'btn-success'
                let className2 = obj[0]?.type === 'missing' ? 'btn-secondary' : 'btn-warning'
                let className3 = obj[0]?.type === 'not-available' ? 'btn-secondary' : 'btn-danger'

                return (<div style={{ display: 'flex', flexDirection: 'row' }}>
                    <button id={`btn-s-${Props.value}`} key={`btn-s-${Props.value}`} className={`btn ${className1}`} disabled={obj.length > 0} data-toggle="modal" data-target="#expiredDateModel"
                        onClick={() => {
                            reset(Props.original, Props.original.quantity);
                            setOrderQty(Props.original.quantity);
                            const updatedExpireDates = Props.original.expire_dates.map((value) => {
                                return { ...value, qty: 0 };
                            });
                            let missingData = assignTheDefaultQtyValue(updatedExpireDates, Number(Props.original.quantity))
                            setExpireData({ ...Props.original, expire_dates: missingData });
                        }}
                    // onClick={() => orderItemsHandle('complete', { completeData, setCompleteData, isLoading, setIsLoading }, Props, props)}
                    >
                        {/* <i class="fa fa-check" aria-hidden="true"></i> */}
                        {/* <i class="fa fa-check" aria-hidden="true"></i> */}
                        {isLoading.loading ? <><i className="fa fa-spinner fa-spin fa-fw"></i><span>wait...</span></> : <>Check</>}
                    </button>
                    <button id={`btn-m-${Props.value}`} key={`btn-m-${Props.value}`} className={`btn ${className2}`} disabled={obj.length > 0} data-toggle="modal" data-target="#missing_model"
                        onClick={() => {
                            const updatedExpireDates = Props.original.expire_dates.map((value) => {
                                return { ...value, qty: 0 };
                            });
                            let missingData = assignTheDefaultQtyValue(updatedExpireDates, Number(Props.original.quantity))
                            setInput({ ...Props.original, expire_dates: missingData });
                        }}
                    >
                        {/* <i class="fa fa-bullhorn" aria-hidden="true"></i> */}
                        Missing
                    </button>
                    <button id={`btn-d-${Props.value}`} key={`btn-d-${Props.value}`} className={`btn ${className3}`} disabled={obj.length > 0}
                        onClick={() => orderItemsHandle('not-available', { completeData, setCompleteData, isLoading, setIsLoading }, Props, props)}
                    >
                        {/* <i class="fa fa-dot-circle-o" aria-hidden="true"></i> */}
                        N. avail
                    </button>
                    <button id={`btn-u-${Props.value}`} key={`btn-u-${Props.value}`} className='btn btn-danger' style={{ display: `${obj.length > 0 ? 'block' : 'none'}` }}
                        onClick={() => removeItemHandle({ completeData, setCompleteData, isLoading, setIsLoading }, Props)}
                    >
                        <i className="fa fa-reply" aria-hidden="true"></i>
                    </button>
                </div>)
            }
        },
        // { Header: '', accessor:}
    ]

    useEffect(() => {
        props.getDeliveryCrew(props);
        props.getOrderIds(props);
        props.getRegionData(props);
        props.getPaymentMethodData(props.token)
    }, [])

    useEffect(() => {
        setSearchData({ ...searchData, orderId: props.orderIds })
        setOrderCount(props.orderIds.length)
    }, [props?.orderIds])

    useEffect(() => {
        // props.getOrderItems(orderItems, props.token)
        // console.log(orderItems)
        setPrintOrderItemsData([])
        setIsPrintLoading(true);
        if (printOrderItems !== null) {
            axios.defaults.headers.common['authorization'] = props.token;
            axios.post('/ordering/getOrderItems', { printOrderItems })
                .then(result => {
                    // console.table(result.data)
                    setPrintOrderItemsData(result.data)
                    setIsPrintLoading(false)
                }).catch(err => {
                    console.error(err)
                    // toast.error(`${err}`)
                })
        }
    }, [printOrderItems])

    useEffect(() => {
        if (selectedOrder) {
            document.getElementById(`div-${selectedOrder?.orderId}`).style.backgroundColor = '#667391'
            document.getElementById(`div-${selectedOrder?.orderId}`).style.color = '#fff'
            document.getElementById(`etd-${selectedOrder?.orderId}`).style.color = '#fff'
            document.getElementById(`name-${selectedOrder?.orderId}`).style.color = '#fff'
        }
    }, [selectedOrder])

    useEffect(() => {
        const uniqueValues = [...new Set(data.map(o => o.shelfLife))]
        setShelfLife(uniqueValues)
    }, [data])

    useEffect(() => {
        let val = props.orderIds.filter(obj => {
            if (obj?.orderId?.includes(search) || obj?.name?.toLowerCase()?.includes(search?.toLowerCase()) || obj?.city?.toLowerCase()?.includes(search?.toLowerCase()) || obj?.region?.toLowerCase()?.includes(search?.toLowerCase()) || obj?.etd?.includes(search) || obj?.outlet?.includes(search)) {
                return obj
            }
        })
        const sOrder = selectedOrder
        if (sOrder) {
            document.getElementById(`div-${sOrder?.orderId}`).style.backgroundColor = 'white'
            document.getElementById(`div-${sOrder?.orderId}`).style.color = 'black'
            document.getElementById(`etd-${sOrder?.orderId}`).style.color = 'red'
            document.getElementById(`name-${sOrder?.orderId}`).style.color = 'purple'
        }
        setSearchData({ ...searchData, orderId: val })
        setSelectedOrder(null)
    }, [search])

    useEffect(() => {
        let val = props.orderIds.filter(obj => {
            if (obj?.region === regionFilter) {
                return obj
            } else if (regionFilter === '') {
                return obj
            }
        })
        const sOrder = selectedOrder
        if (sOrder) {
            document.getElementById(`div-${sOrder?.orderId}`).style.backgroundColor = 'white'
            document.getElementById(`div-${sOrder?.orderId}`).style.color = 'black'
            document.getElementById(`etd-${sOrder?.orderId}`).style.color = 'red'
            document.getElementById(`name-${sOrder?.orderId}`).style.color = 'purple'
        }
        setSearchData({ ...searchData, orderId: val })
        setSelectedOrder(null)
        setOrderCount(val.length)
    }, [regionFilter])

    const reload = () => {
        props.getDeliveryCrew(props);
        props.getOrderIds(props);
        props.getRegionData(props);
    }

    const onChangeDateValue = (e, name) => {
        if (name === "fromDate") {
            let fromDateFormatted = moment(e.target.value).format("YYYY-MM-DD");
            setFromDate(fromDateFormatted)
        }
        if (name === "toDate") {
            let ToDateFormatted = moment(e.target.value).format("YYYY-MM-DD");
            setToDate(ToDateFormatted)
        }
    }

    const closeRangePopup = () => {
        setFromDate(moment(Date.now()).format("YYYY-MM-DD"))
        setToDate(moment(new Date().setMonth(new Date().getMonth() + 1)).format("YYYY-MM-DD"))
    }

    const handleSubmit = () => {
        props.getOrderReportData({
            from: fromDate,
            to: toDate,
            token: props.token
        }, (result) => {
            exportData(result)
        })
    }

    const reset = (data, orderQty) => {
        const updatedExpireDates = data.expire_dates.map((value) => {
            const assignedQty = ConvertDecimalPlaces(Math.min(value.totalQty, orderQty)); // Assign qty based on the minimum of totalQty and orderQty
            orderQty -= assignedQty; // Subtract the assignedQty from the orderQty
            return { ...value, qty: ConvertDecimalPlaces(assignedQty)};
        });
        setExpireData({ ...data, expire_dates: updatedExpireDates });
        setInput({ ...data, expire_dates: updatedExpireDates });
    };

    const exportData = (values) => {
        let b = [''];
        const ws = XLSX.utils.json_to_sheet(values);
        const header1 = []
        b = b.concat(header1).concat(XLSX.utils.sheet_to_json(ws, { header1: 1 }))
        const worksheet = XLSX.utils.json_to_sheet(b, { skipHeader: true });
        const new_workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Order")
        XLSX.writeFile(new_workbook, 'Order Data' + '.xlsx');
    }

    const increaseQty = (type, index, arr) => {
        if (type === 'Check') {
            const updatedItems = [...arr.expire_dates];
            updatedItems[index].qty = ConvertDecimalPlaces(Math.min(
                updatedItems[index].qty + 1, updatedItems[index].totalQty)
            );
            setExpireData({ ...arr, expire_dates: updatedItems });
        } else if (type === 'Missing') {
            const updatedItems = [...arr.expire_dates];
            updatedItems[index].qty = ConvertDecimalPlaces(Math.min(
                updatedItems[index].qty + 1, updatedItems[index].totalQty)
            );
            setInput({ ...arr, expire_dates: updatedItems });
        }

    };

    const decreaseQty = (type, index, arr) => {
        if (type === 'Check') {

            const updatedItems = [...arr.expire_dates];
            updatedItems[index].qty = ConvertDecimalPlaces(Math.max(updatedItems[index].qty - 1, 0));
            setExpireData({ ...arr, expire_dates: updatedItems });

        } else if (type === 'Missing') {
            const updatedItems = [...arr.expire_dates];
            updatedItems[index].qty = ConvertDecimalPlaces(Math.max(updatedItems[index].qty - 1, 0));
            setInput({ ...arr, expire_dates: updatedItems });
        }
    };

    const assignTheDefaultQtyValue = (items, missing) => {
        items.sort((a, b) => new Date(a.exp_date) - new Date(b.exp_date));

        //console.log(exp_items)
        let remainingQty = missing;

        // Step 3-5: Iterate over the items and assign qty values
        for (let i = 0; i < items.length; i++) {
            let item = items[i];

            if (remainingQty > item.totalQty) {
                item.qty = item.totalQty;
                remainingQty -= item.totalQty;
            } else {
                item.qty = remainingQty;
                remainingQty = 0;
                break;
            }
        }
        return items
    }

    const resetMissingValue = (input, ele) => {
        if (Number(ele) < input?.quantity) {
            let totalValue = (input?.quantity - ConvertDecimalPlaces(ele))
            const updatedExpireDates = input.expire_dates.map((value) => {
                return { ...value, qty: 0 };
            });
            let missingData = assignTheDefaultQtyValue(updatedExpireDates, ConvertDecimalPlaces(totalValue))
            setInput({ ...input, expire_dates: missingData, missingQty: ConvertDecimalPlaces(ele) });
        }
        else {
            let totalValue = (input?.quantity - ConvertDecimalPlaces(ele))
            const updatedExpireDates = input.expire_dates.map((value) => {
                return { ...value, qty: 0 };
            });
            let missingData = assignTheDefaultQtyValue(updatedExpireDates, ConvertDecimalPlaces(totalValue))
            setInput({ ...input, expire_dates: missingData, missingQty: ConvertDecimalPlaces(ele) });
            // orderItemsHandle('missing', { completeData, setCompleteData, isLoading, setIsLoading }, { original: { ...input, missingQty: Number(ele) } }, props)
        }
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* <div>
                        <div>
                            <button className='btn btn-primary' onClick={() => orderDataFilter({ setSearchData, searchData }, searchData, props)}>Orders</button>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={() => orderDataFilter({ setSearchData, searchData }, searchData, props)}>Missing</button>
                        </div>
                    </div> */}
                    {/* <div className='card'> */}
                    <div className='card-body'>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "250px", display: "flex" }}>
                                <div style={{ marginLeft: "2px", fontSize: "15px" }}>Region:</div>
                                <select className="form-control" onChange={e => setRegionFilter(e.target.value)} style={{ marginLeft: "10px" }}>
                                    <option value={''}>All</option>
                                    {props.region.map(region => {
                                        return <option key={region._id} value={region.name}>{region.name}</option>
                                    })}
                                </select>
                            </div>
                            <button id='btn-complete' className='btn btn-primary' data-toggle="modal" data-target="#bag_model" disabled={((data.length === completeData.orderItem.length && completeData.riderName !== '' && selectedOrder !== null) || (selectedOrder?.pickup === true && data.length === completeData.orderItem.length && selectedOrder !== null)) ? false : true} style={{ marginLeft: "10px" }} onClick={() => {
                                let newDate = new Date();
                                let date = newDate.getDate() + "-" + (Number.parseInt(newDate.getMonth()) + 1) + "-" + newDate.getFullYear();
                                setDate(date);
                            }}>
                                Complete
                            </button>

                            <button className="btn btn-success"
                                data-toggle="modal" data-target='#orderModel'
                                onClick={() => closeRangePopup()}
                            >
                                Export Data
                            </button>

                            <button className="btn btn-primary"
                                disabled={props.isLoading}
                                onClick={() => reload()}
                            >
                                <i className="fas fa-sync"></i>
                            </button>

                            {cancel &&
                                <button className='btn btn-primary' onClick={() => {
                                    props.getOrderIds(props);
                                    bagData.map(o => {
                                        document.getElementById(`${o.state} + ${o.qty}`).disabled = false;
                                        document.getElementById(`${o.state} + ${o.qty}`).classList.add('btn-primary');
                                        document.getElementById(`${o.state} + ${o.qty}`).classList.remove('btn-secondary');
                                    })
                                    // setCancel(false);
                                    resetState(setSearchData, setSelectedOrder, setData, setCancel, setCompleteData, setInput, setIsLoading, setBagData, setBagNames)
                                    // setData([])
                                    // setCompleteData({ riderName: '', orderId: '', orderItem: [], complete: [], missing: [], notAvail: [] })
                                    document.getElementById(`${completeData.riderName}`).disabled = false;
                                    document.getElementById(`${completeData.riderName}`).classList.add('btn-primary');
                                    document.getElementById(`${completeData.riderName}`).classList.remove('btn-secondary');
                                }}>Cancel</button>
                            }
                        </div>
                        <table style={{ width: '100%', marginTop: "5px", fontSize: "15px" }}>
                            <tbody>
                                <tr>
                                    {/* <td>{selectedOrder?.orderId}</td>
                                    <td>Name :</td>
                                    <td>{selectedOrder?.name}</td>  */}

                                    <td style={{ fontWeight: "bold" }}>Orders: {(!regionFilter && orderCount === 0) ? props.orderIds.length : orderCount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* </div> */}
                    {selectedOrder?.pickup !== true ?
                        <div style={{ display: 'flex', width: '40%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
                                    {props?.deliveryCrewData?.map(ele => {
                                        if (ele?.deliverycrew?.status) return <button id={`${ele.deliverycrew?._id}`} key={`${ele.deliverycrew?._id}`} className='btn btn-primary' onClick={() => {
                                            if (completeData.riderName !== '') {
                                                document.getElementById(`${completeData.riderName}`).disabled = false;
                                                document.getElementById(`${completeData.riderName}`).classList.add('btn-primary');
                                                document.getElementById(`${completeData.riderName}`).classList.remove('btn-secondary');
                                            }
                                            orderDataFilter(ele, { setSearchData, searchData }, props)
                                            setCancel(true)
                                            setCompleteData({ ...completeData, riderName: ele.deliverycrew?._id })
                                            document.getElementById(`${ele.deliverycrew?._id}`).disabled = true;
                                            document.getElementById(`${ele.deliverycrew?._id}`).classList.add('btn-secondary');
                                        }}>
                                            {ele.deliverycrew.name}
                                        </button>
                                    })
                                    }
                                </div>
                            </div>
                        </div> : ''
                    }
                </div>
                {<div className="card-body">
                    <div className='card' style={{ marginTop: '10px', flexDirection: 'row', height: '65vh' }} >
                        <div id='search_data' className='content-header' style={{ height: '99%' }}>
                            <input id='search' type="text" className='form-control' placeholder='Search... '
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter' && data.length === 1) {
                                        // _searchHandler(data[0], reconcilationData, setReconcilationData); setSearch('')
                                    }
                                }} />
                            <div>
                                {searchData.orderId?.map(obj =>
                                    <div id={`div-${obj?.orderId}`}
                                        key={`div-${obj?.orderId}`}
                                        style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            padding: '3px 5px',
                                            background: 'white',
                                            margin: '0px',
                                            color: 'black',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            orderReset(obj, { setSelectedOrder, selectedOrder, completeData, setCompleteData }, setData, props)
                                        }}
                                    >
                                        {/* <div>{obj?.orderId} - <p id={`div-${obj?.etd}`} style={{color: "red"}}>{DateNumberFormat(obj?.etd)}</p></div> */}
                                        <div style={{ display: 'flex' }}>
                                            <div>{obj?.orderId} - </div>
                                            <div id={`etd-${obj?.orderId}`} style={{ color: "red", marginLeft: "5px" }}>{DateNumberFormat(obj?.etd)}</div>
                                        </div>
                                        <div id={`name-${obj?.orderId}`} style={{ color: obj?.duplicate === true ? "purple" : "black", fontWeight: "bold" }}>{obj?.name}</div>
                                        <div id={`name-${obj?.orderId}`} >{obj?.outlet !== '' ? 'WH- ' + obj?.outlet : ''}</div>
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            <div>{obj?.city}</div>
                                            <div>{obj?.region}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='content-body' style={{ height: '99%', display: 'inline-block', overflow: 'auto', backgroundColor: '#fff' }}>
                            <Table className="-striped"
                                columns={columns}
                                data={data}
                                defaultPageSize={10}
                                showPagination={true}
                                filterable
                            />
                        </div>
                    </div>
                </div>}
                <div className="card-footer">
                </div>
            </div>

            <ErrorBoundary>
                <div id='missing_model' className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Add Missing Qty</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Select Qty :</td>
                                            <td>
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(ele => {
                                                    if (ele <= input?.quantity) {
                                                        return (<button id={ele} key={ele} className={'btn btn-primary'} style={{ width: "60px", height: "65px" }}
                                                            onClick={() => {
                                                                setInput({ ...input, missingQty: ConvertDecimalPlaces(ele) });
                                                                resetMissingValue(input, ConvertDecimalPlaces(ele))
                                                            }}>
                                                            {ele}
                                                        </button>)
                                                    }
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Qty :</td>
                                            <td>
                                                <input type={'number'} className='form-control' style={{ width: "30%" }} onChange={e => {
                                                    input.expire_dates.map((data) => {
                                                        data.qty = 0
                                                    })
                                                    setInput({ ...input, missingQty: ConvertDecimalPlaces(e.target.value) })
                                                    resetMissingValue(input, ConvertDecimalPlaces(e.target.value))
                                                }} />
                                            </td>
                                        </tr>
                                        {input.missingQty !== undefined && input.missingQty !== '' && input.missingQty !== null && input.missingQty !== 0 ?
                                            (Number(input.missingQty) < Number(input?.quantity) ?
                                                <tr>
                                                    <td><h4>Order Qty :</h4></td>
                                                    <td>
                                                        <h4>{ConvertDecimalPlaces(input.quantity - input.missingQty)}</h4>
                                                    </td>
                                                </tr> : '')
                                            : ''}
                                    </tbody>
                                </table>
                                {input.missingQty !== undefined && input.missingQty !== '' && input.missingQty !== null && input.missingQty !== 0 ?
                                    (Number(input.missingQty) < Number(input?.quantity) ?
                                        (input.expire_dates !== undefined && input.expire_dates.length > 0 ?
                                            <table style={{ width: '100%' }} className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th style={{ paddingLeft: '60px' }} scope='col'>Expire Date</th>
                                                        <th style={{ paddingLeft: '60px' }} scope='col'>Available Qty</th>
                                                        <th style={{ paddingLeft: '60px' }} scope='col'>Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {input.expire_dates.map((item, index) => (
                                                        <>
                                                            <tr key={item.id}>
                                                                <td style={{ paddingLeft: '60px' }} scope='col'>{item.exp_date}</td>
                                                                <td style={{ paddingLeft: '60px' }} scope='col'>{item.totalQty}</td>
                                                                <td style={{ paddingLeft: '60px', display: 'flex', display: 'flex', alignItems: 'center', justifyContent: 'center' }} scope='col'>
                                                                    <button
                                                                        className='btn btn-sm  btn-primary'
                                                                        style={{ marginRight: '4px', padding: '8px 12px', fontSize: '18px', cursor: 'pointer' }}
                                                                        onClick={() => decreaseQty("Missing", index, input)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <span style={{ fontSize: '18px', fontWeight: 'bold', margin: '0 4px' }}>{(item.qty)}</span>
                                                                    <button
                                                                        className='btn btn-sm  btn-primary'
                                                                        style={{ marginLeft: '4px', padding: '8px 12px', fontSize: '18px', cursor: 'pointer' }}
                                                                        onClick={() => increaseQty("Missing", index, input)}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                            : "")
                                        : "")
                                    : ''}
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={(Number((input.expire_dates?.reduce((total, obj) => { return total + obj.qty }, 0)))) + (Number(input.missingQty !== undefined ? input.missingQty : 0)) === Number(input.quantity) && input.missingQty > 0 ? false : true} className="btn btn-primary" data-dismiss="modal" onClick={() => orderItemsHandle('missing', { completeData, setCompleteData, isLoading, setIsLoading }, { original: input }, props)}>
                                    Add
                                </button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

            <ErrorBoundary>
                <div id='bag_model' className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">{bagData ? "Add Bag" : "Print Invoice"}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <table>
                                    <tbody>
                                        {shelfLife.map(obj => {
                                            return (
                                                <tr>
                                                    <td>{obj} :</td>
                                                    <td>
                                                        {[1, 2, 3, 4, 5].map(ele => {
                                                            return (
                                                                <button id={`${obj} + ${ele}`} key={ele} className={'btn btn-primary'} style={{ width: "55px", height: "65px" }}
                                                                    onClick={() => {
                                                                        const newBags = [...bagData];
                                                                        newBags.push({ state: obj, qty: ele })
                                                                        newBags.map(o => {
                                                                            if ((o.state === obj) && (o.qty !== ele)) {
                                                                                document.getElementById(`${o.state} + ${o.qty}`).disabled = false;
                                                                                document.getElementById(`${o.state} + ${o.qty}`).classList.add('btn-primary');
                                                                                document.getElementById(`${o.state} + ${o.qty}`).classList.remove('btn-secondary');
                                                                                newBags.splice(newBags.findIndex(v => v.state === o.state), 1)
                                                                            }
                                                                        })
                                                                        setBagData(newBags)
                                                                        document.getElementById(`${obj} + ${ele}`).disabled = true;
                                                                        document.getElementById(`${obj} + ${ele}`).classList.add('btn-secondary');
                                                                    }}>
                                                                    {ele}
                                                                </button>
                                                            )
                                                        })}

                                                        <button className={'btn btn-danger'} id={`${obj}`} key={obj} style={{ width: "75px", height: "65px" }} onClick={() => {
                                                            bagData.map(o => {
                                                                if (o.state === obj) {
                                                                    document.getElementById(`${o.state} + ${o.qty}`).disabled = false;
                                                                    document.getElementById(`${o.state} + ${o.qty}`).classList.add('btn-primary');
                                                                    document.getElementById(`${o.state} + ${o.qty}`).classList.remove('btn-secondary');
                                                                    bagData.splice(bagData.findIndex(v => v.state === o.state), 1)
                                                                }
                                                            })
                                                        }}>Clear</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <td>Bag Names:</td>
                                        <td><input type="text" className="form-control" onChange={(e) => setBagNames(e.target.value)} /></td>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                {!isLoading.loading && <td>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => {
                                        if ((data.length === completeData.orderItem.length && completeData.riderName !== '') || (data.length === completeData.orderItem.length && selectedOrder?.pickup === true))
                                            props.deliveryInspect({ ...completeData, type: 'inspect', user: props.id, shelfLife: bagData.length === 0 ? '' : bagData, bagNames: bagNames === '' ? '' : bagNames, pickup: selectedOrder?.pickup, outlet: selectedOrder?.outletId === '' ? '' : selectedOrder?.outletId }, resetState(setSearchData, setSelectedOrder, setData, setCancel, setCompleteData, setInput, setIsLoading, setBagData, setBagNames), { setPrintOrder, selectedOrder, setPrintOrderItems }, props)
                                        else
                                            toast.warn('Please check your items & rider...!')
                                    }}>Complete</button>
                                </td>}
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

            <ErrorBoundary>
                <div id="invoicePopup" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content animate">
                            <div className="modal-header">
                                <button disabled={isPrintLoading} className='btn btn-secondary'
                                    onClick={() => {
                                        var DocumentContainer = document.getElementById(
                                            "printSection"
                                        );
                                        var WindowObject = window.open(
                                            "",
                                            "PrintWindow",
                                            "toolbars=no,scrollbars=yes,status=no,resizable=yes"
                                        );
                                        WindowObject.document.writeln(
                                            DocumentContainer.innerHTML
                                        );
                                        WindowObject.document.close();
                                        setTimeout(function () {
                                            WindowObject.focus();
                                            WindowObject.print();
                                            WindowObject.close();
                                        }, 1000);
                                    }}
                                >
                                    PRINT
                                </button>

                                <ReactToPrint
                                    content={() => componentRef.current}
                                />
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <InvoicePrint paymentMethods={props.paymentMethods} date={date} orderItemsData={printOrderItemsData} order={printOrder} ref={componentRef} />
                            </div>

                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
            <ErrorBoundary>
                <div id="orderModel" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Export Order Data</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>From ETD* :</td>
                                            <td><input className="form-control" type="date" value={fromDate}
                                                onChange={e => {
                                                    onChangeDateValue(e, "fromDate")
                                                }} /></td>
                                            <td>To ETD* :</td>
                                            <td><input className="form-control" type="date" value={toDate}
                                                onChange={e => {
                                                    onChangeDateValue(e, "toDate")
                                                }} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button id="" type="button" className="btn btn-primary" onClick={() => handleSubmit()}>Export Data</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => closeRangePopup()}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
            <ErrorBoundary>
                <div id='expiredDateModel' className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <div>
                                    <h4 className="modal-title text-uppercase">Add Expired Dates</h4>
                                    &nbsp;
                                    <h5 >Order Qty: {orderQty}</h5>
                                </div>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                {expireData.expire_dates !== undefined && expireData.expire_dates.length > 0 ? (
                                    <table style={{ width: '100%' }} className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th style={{ paddingLeft: '60px' }} scope='col'>Expire Date</th>
                                                <th style={{ paddingLeft: '60px' }} scope='col'>Available Qty</th>
                                                <th style={{ paddingLeft: '60px' }} scope='col'>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {expireData.expire_dates.map((item, index) => (
                                                <>
                                                    <tr key={index}>
                                                        <td style={{ paddingLeft: '60px' }} scope='col'>{item.exp_date}</td>
                                                        <td style={{ paddingLeft: '60px' }} scope='col'>{item.totalQty}</td>
                                                        <td style={{ paddingLeft: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} scope='col'>
                                                            <button
                                                                className='btn btn-sm btn-primary'
                                                                style={{ marginRight: '4px', padding: '8px 12px', fontSize: '18px', cursor: 'pointer' }}
                                                                onClick={() => decreaseQty("Check", index, expireData)}
                                                            >
                                                                -
                                                            </button>
                                                            <span style={{ fontSize: '18px', fontWeight: 'bold', margin: '0 4px' }}>{item.qty}</span>
                                                            <button
                                                                className='btn btn-sm btn-primary'
                                                                style={{ marginLeft: '4px', padding: '8px 12px', fontSize: '18px', cursor: 'pointer' }}
                                                                onClick={() => increaseQty("Check", index, expireData)}
                                                            >
                                                                +
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : null}
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={(Number((expireData.expire_dates?.reduce((total, obj) => { return total + obj.qty }, 0))) === Number(expireData.quantity)) ? false : true} className="btn btn-primary" data-dismiss="modal" onClick={() => orderItemsHandle('complete', { completeData, setCompleteData, isLoading, setIsLoading }, { original: expireData }, props)}>
                                    Add
                                </button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary >
        </div >
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        id: state.rLogin.id,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        deliveryCrewData: state.rDeliveryBoys.data,
        orderIds: state.rOrdering.orderIds,
        region: state.rBasicData.region,
        paymentMethods: state.rBasicData.payment_method_data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDeliveryCrew: props => { dispatch(getDeliveryCrew(props)) },
        deliveryInspect: (data, fn, obj, props) => dispatch(deliveryInspect(data, fn, obj, props)),
        getOrderIds: props => dispatch(getOrderIds(props)),
        getOrderItemFromId: (obj, setData, props) => dispatch(getOrderItemFromId(obj, setData, props)),
        getRegionData: props => dispatch(getRegionData(props)),
        getPaymentMethodData: token => { dispatch(getPaymentMethodData(token)) },
        getOrderReportData: (data, callback) => { dispatch(getOrderReportData(data, callback)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInspect)
