import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import _ from 'lodash';
import Table from "react-table";
// import * as BS from "react-bootstrap";
// import { Field } from "redux-form";
// import { toast } from "react-toastify";
// import axios from 'axios';
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
// import ActionsCell from "../../components/ActionCell";
// import RTSActionCell from '../../components/RTSActionCell';
import GridFilter from '../../components/GridFilter';
// import HighlightCell from '../../components/HighlightCell';
// import { getUsers } from '../../../actions/Administrator/user_actions';
import { } from "../../../actions/Purchasing/purchasing_price_action";
// import SkeletonTable from '../../components/skeleton/SkeletonTable';
// import SearchableSelectComp from '../../components/SearchableSelectComp';
import { Permission } from "../../utils";

import {
    getCreditNoteData
} from '../../../actions/Purchasing/credit_note_purchase_actions.js';

export const CreditNotePurchase = props => {

    const [sortOptions, setSortOptions] = useState([{ id: 'creditnotepurchase.created_at', desc: true }]);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        props.getCreditNoteData(props.token)
    }, [])

    const reload = () => {
        props.getCreditNoteData(props.token)
    }

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const formatDate = (date) => {
        if (date === null || date === '') {
            return '';
        }
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var hours = d.getHours();
        var minutes = d.getMinutes();
        var seconds = d.getSeconds();

        var formatedDate = [year, month, day].join('-');
        var formatedTime = [hours, minutes, seconds].join(':')

        return [formatedDate, formatedTime].join(' ');
    }

    const creditNoteColumns = [
        { Header: "Supplier", accessor: "supplier.name", ...GridFilter },
        {
            Header: "Order Id",
            ...GridFilter,
            Cell: row => {
                return <label>{row.original.purchaseorder ? row.original.purchaseorder.orderid : row.original.purchaseorderhistories.orderid}</label>
            },
            getProps: () => { return { style: { 'textAlign': 'right' } } }
        },
        { Header: "Value", accessor: "creditnotepurchase.value", ...GridFilter, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Completed", accessor: "creditnotepurchase.completed", ...GridFilter },
        { Header: "Remark", accessor: "creditnotepurchase.remark", ...GridFilter },
        {
            Header: "Credit Date",
            accessor: "creditnotepurchase.credit_date",
            ...GridFilter,
            Cell: row => {
                return <label>{formatDate(row.original.creditnotepurchase.credit_date)}</label>
            }
        },
        {
            Header: "Created Date",
            accessor: "creditnotepurchase.created_at",
            ...GridFilter,
            Cell: row => {
                return <label>{formatDate(row.original.creditnotepurchase.created_at)}</label>
            }
        },
    ]

    return (
        <div className="right_col" role="main" >
            <div className="card">
                <div className='card-header'>
                    <div className="col-md-6">
                        <h4>Credit Note Purchase</h4>
                    </div>
                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                        <button className="btn btn-primary" disabled={Permission('STOCK', 'MOVE STOCK', 'VIEW', props)} onClick={e => reload()}><i className="fa fa-refresh"></i></button>
                    </div>
                </div>
                <div className='card-body'>
                    <React.Fragment>
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form>
                                            <Table
                                                columns={creditNoteColumns}
                                                data={props.creditNoteData}
                                                defaultPageSize={15}
                                                sorted={sortOptions}
                                                defaultFilterMethod={filterCaseInsensitive}
                                                expanded={expanded}
                                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                                onSortedChange={val => {
                                                    setSortOptions(val)
                                                }}
                                            />
                                        </form>
                                    )
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </React.Fragment>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        userId: state.rLogin.id,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        creditNoteData: state.rCreditNotePurchase.creditNoteData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCreditNoteData: (token) => { dispatch(getCreditNoteData(token)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotePurchase);


