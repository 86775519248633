/**
 * @author Ehsan Elahi
 * @type {Object}
 * @property {array}  data  Item data
 */

import _ from 'lodash';

const initialState = {
    activeDisposedItemsData: [],
    completedDisposedItemsData: [],
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerDisposedItems = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_DISPOSED_ITEMS_DATA':
            var { data } = action;

            var active = _.filter(data, (element) => {
                return element.disposeditems?.completed === false;
            });

            var completed = _.filter(data, (element) => {
                return element.disposeditems?.completed === true;
            });

            newState.activeDisposedItemsData = active;
            newState.completedDisposedItemsData = completed;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerDisposedItems;