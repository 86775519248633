/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const seasonalDiscountsAsync = data => {
    return { type: 'FETCH_SEASONAL_DISCOUNTS_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
export const getSeasonalDiscounts = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/seasonaldiscounts/getSeasonalDiscounts')
            .then(result => {
                dispatch(seasonalDiscountsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addSeasonalDiscount = ({ name, thresholdValue, discountPercentage, isActive, startAt, endAt, dayOfWeek, slug }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/seasonaldiscounts/addSeasonalDiscount', {
            data: {
                name,
                thresholdValue,
                discountPercentage,
                isActive,
                startAt,
                endAt,
                dayOfWeek,
                slug
            }
        })
            .then(result => {
                window.$('#addSeasonalDiscount').modal('hide');
                toast.success('Successfully Added Seasonal Discount!');
                dispatch(getSeasonalDiscounts(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateSeasonalDiscount = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/seasonaldiscounts/updateSeasonalDiscount', { data })
            .then(result => {
                toast.success('Successfully Updated Seasonal Discount!')
                dispatch(getSeasonalDiscounts(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteSeasonalDiscount = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/seasonaldiscounts/deleteSeasonalDiscount', { data })
            .then(result => {
                toast.success('Seasonal Discount Deleted Successfully!')
                dispatch(getSeasonalDiscounts(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}