/**
 * @file This file defines for view complaint details
 * @author Umesh Ranthilina
 */

import React, { useState, useEffect } from 'react'
import Table from "react-table";
import FormProvider from "../../../../components/FormProvider";
import GridFilter from '../../../../components/GridFilter';
import ErrorBoundary from '../../../../components/ErrorBoundary'
import { PriceFormat } from '../../../../utils';

const resolveMethodFunc = (resolveMethod, props) => {
    let val = props.resolveMethod?.filter(ele => ele._id === resolveMethod)
    return val[0]?.slug
}

const removeHandler = (setDefectItems, setreturnOrderItem, setPutBackItem, setResolvedMethod, setDiscountItem, setViewHandler) => {
    let divAccErrorEle = document.getElementById('account_error')
    let btnSaveEle = document.getElementById('save_complaint')
    setreturnOrderItem([])
    setPutBackItem([])
    setDefectItems([])
    setDiscountItem([])
    setResolvedMethod('')
    setViewHandler({ view: true, add: false, update: false, show: false })

    if (divAccErrorEle) divAccErrorEle.innerHTML = ""
    btnSaveEle.disabled = false
}

const getReturnOrderColumns = (isShown, returnOrderColumn) => {

    const showColumns = !isShown ? [...returnOrderColumn] : returnOrderColumn

    return showColumns
}

const getPutBackColumns = (isShown, putBackAddColumn) => {

    const showColumns = !isShown ? [...putBackAddColumn] : putBackAddColumn

    return showColumns
}

const getDiscountColumns = (isUpdate, discountAddColumn) => {

    const showColumns = !isUpdate ? [...discountAddColumn] : discountAddColumn

    return showColumns
}

const ViewComplaintsItems = ({ complaintsItemsData, resolvedMethodData, isShown, isClose, setIsClose, setViewHandler, props }) => {

    const [defectItems, setDefectItems] = useState([]);
    const [returnOrderItem, setreturnOrderItem] = useState([]);
    const [putBackItem, setPutBackItem] = useState([]);
    const [discountItem, setDiscountItem] = useState([]);
    const [resolveMethod, setResolveMethod] = useState('');

    useEffect(() => {
        if (isShown) {
            if (resolveMethodFunc(resolveMethod, props) === 'return-order') {
                setreturnOrderItem(complaintsItemsData)
            } else if (resolveMethodFunc(resolveMethod, props) === 'put-back') {
                setPutBackItem(complaintsItemsData)
            } else if (resolveMethodFunc(resolveMethod, props) === 'discount') {
                setDiscountItem(complaintsItemsData)
            } else {
                setDefectItems(complaintsItemsData)
            }
        }
    }, [resolveMethod])

    useEffect(() => {
        if (isShown) setResolveMethod(resolvedMethodData._id)
    }, [isShown && resolvedMethodData])

    useEffect(() => {
        if (isClose) {
            removeHandler(setDefectItems, setreturnOrderItem, setPutBackItem, setDiscountItem, setResolveMethod, setViewHandler)
            setIsClose(false)
        }
    }, [isClose])

    const returnOrderColumn = [
        { Header: "Name", accessor: "name", ...GridFilter },
        { Header: "ItemCode", accessor: "itemCode", ...GridFilter },
        { Header: "Stocks", accessor: "stock", ...GridFilter, Cell: props => <p style={{ textAlign: 'right' }} className='number-cell-format'>{props.value}</p> },
        { Header: "Avail. Stock", accessor: "availableStock", ...GridFilter, Cell: props => <p style={{ textAlign: 'right' }} className='number-cell-format'>{props.value}</p> },
        { Header: "Order Qty", accessor: "quantity", ...GridFilter, Cell: props => <p style={{ textAlign: 'right' }} className='number-cell-format'>{props.value}</p> },
        {
            Header: "Replace Qty", accessor: "quantity",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.quantity}
                </div>
        },
        { Header: "Resolve Type", accessor: "resolveType" },
    ]

    const column = [
        { Header: "Name", accessor: "name", ...GridFilter },
        { Header: "Item Code", accessor: "itemCode", ...GridFilter },
        {
            Header: "Item Qty", accessor: "quantity",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.quantity}
                </div>
        },
        {
            Header: "Defected Qty", accessor: "defectQty",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.defectQty}
                </div>
        },
        {
            Header: "Refund Price", accessor: "defectQty",
            Cell: props => {
                return (<div style={{ textAlign: 'right' }}>
                    {PriceFormat((props.original.unitPrice - (props.original.unitPrice * props.original.discount / 100)) * props.original.defectQty)}
                </div>)
            }
        },
        {
            Header: "Supplier Bare %", accessor: "supplier_bare",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.supplier_bare}
                </div>
        },
    ]

    const putBackAddColumn = [
        { Header: "Name", accessor: "name", ...GridFilter },
        { Header: "Item Code", accessor: "itemCode", ...GridFilter },
        {
            Header: "Item Qty", accessor: "quantity",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.quantity}
                </div>
        },
        {
            Header: "Exp Qty", accessor: "expQty",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.expQty}
                </div>
        },
        {
            Header: "PutBack Qty", accessor: "defectQty",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.defectQty}
                </div>
        },
    ]

    const discountAddColumn = [
        { Header: "Name", accessor: "name", ...GridFilter },
        { Header: "Item Code", accessor: "itemCode", ...GridFilter },
        {
            Header: "Item Qty", accessor: "quantity",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.quantity}
                </div>
        },
        {
            Header: "Discount %", accessor: "discount",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.discount}
                </div>
        },
    ]

    const columns = resolveMethodFunc(resolveMethod, props) === 'return-order' ?
        getReturnOrderColumns(isShown, returnOrderColumn) : resolveMethodFunc(resolveMethod, props) === 'put-back' ?
            getPutBackColumns(isShown, putBackAddColumn) : resolveMethodFunc(resolveMethod, props) === 'discount' ?
                getDiscountColumns(isShown, discountAddColumn) : column

    return (
        <>
            {resolveMethodFunc(resolveMethod, props) === 'refund' || resolveMethodFunc(resolveMethod, props) === 'return-order' || resolveMethodFunc(resolveMethod, props) === 'put-back'
                || resolveMethodFunc(resolveMethod, props) === 'discount' ?
                <ErrorBoundary>
                    <React.Fragment>
                        <form id='addCustomerComplaintForm'>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    {resolveMethod !== '' ?
                                        <tr>
                                            <td colSpan='4'>
                                                <div>
                                                    <ErrorBoundary>
                                                        <FormProvider
                                                            form="inline"
                                                            enableReinitialize
                                                        >
                                                            {formProps => {
                                                                return (
                                                                    <form>
                                                                        <Table className="-striped"
                                                                            data={resolveMethodFunc(resolveMethod, props) === 'return-order' ? returnOrderItem : resolveMethodFunc(resolveMethod, props) === 'put-back' ? putBackItem : resolveMethodFunc(resolveMethod, props) === 'discount' ? discountItem : defectItems}
                                                                            columns={columns}
                                                                            defaultPageSize={5} />
                                                                    </form>)
                                                            }}
                                                        </FormProvider>
                                                    </ErrorBoundary>
                                                </div>
                                            </td>
                                        </tr>
                                        : null}
                                    <tr>
                                        <td colSpan={4}>
                                            <div style={{ float: 'right', paddingTop: '10px' }}>
                                                <button type="button" className="btn btn-info" onClick={e => removeHandler(setDefectItems, setreturnOrderItem, setPutBackItem, setResolveMethod, setDiscountItem, setViewHandler)}>Close</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>

                    </React.Fragment>
                </ErrorBoundary>
                : 'No Data Found!'}
        </>
    )
}

export default ViewComplaintsItems
