import React from 'react'
import Skeleton from 'react-loading-skeleton';

function SkeltonDiv() {
    return (
        <>
            <div style={{ width: '98%', borderRadius: '5px', margin: '4px 0px 4px' }}>
                <Skeleton count={1} height={'60px'} width={'98%'} duration={1} />
            </div>
        </>
    )
}

export default SkeltonDiv
