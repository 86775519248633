import _ from 'lodash';

/**
 * This function used to get cities from region
 * @param {Array} e react select array object
 * @param {Object} date state
 * @param {Function} setDate to change state
 * @param {Array} props property data
 */
const selectHandler = (e, date, setDate, props) => {
    let val = e.map(obj => {
        if (obj.hasOwnProperty('region')) {
            return { _id: obj._id }
        } else {
            let city = props.city.filter(ele => ele.region === obj._id)
            if (city.length > 0) {
                let cities = city.map(obj => { return { _id: obj._id } })
                return cities
            }
        }
    })
    let uniqArr = _.uniqBy(_.flatten(val), '_id')
    setDate({ ...date, city: uniqArr })
}

export default selectHandler