/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';
import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import { toast } from "react-toastify";
import withFixedColumns from 'react-table-hoc-fixed-columns';

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getDeliveryConditions, addDeliveryCondition, updateDeliveryCondition, deleteDeliveryCondition } from '../../../actions/Basic_data/delivery_condition_actions';

import "react-table/react-table.css";
import 'react-table-hoc-fixed-columns/lib/styles.css'

const ReactTableFixedColumns = withFixedColumns(Table);

class DeliveryCondition extends React.Component {

    state = {
        deliveryConditions: [],
        description: '',
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'description', desc: false }],
    };

    componentDidMount() {
        this.props.getDeliveryConditions(this.props.token);
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'DELIVERY CONDITION', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'DELIVERY CONDITION', 'DELETE', this.props)
        }) ||
        {};

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values;

            this.props.updateDeliveryCondition(data, this.props.token)

        } else if (this.state.deleting !== null && this.state.editing === null) {
            let data = {
                '_id': values._id
            }

            this.props.deleteDeliveryCondition(data, this.props.token)
        }
    };

    addDeliveryConditionHandle = () => {
        var { description } = this.state;

        if (description.trim() !== '') {

            this.props.addDeliveryCondition(this.state, this.props.token)

            this.setState({
                description: ''
            });

            document.getElementById("addDeliveryConditionForm").reset();
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    deliveryConditionColumns = [
        { Header: "Description", accessor: "description", ...this.editableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={ Permission('BASIC DATA', 'DELIVERY CONDITION', 'ADD', this.props)} data-toggle="modal" data-target="#addDeliveryCondition">+ Delivery Condition</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.deliveryConditionColumns}
                                                    data={this.props.deliveryConditions}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>


                    <div id="addDeliveryCondition" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Delivery Condition</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addDeliveryConditionForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Description* :</td>
                                                        <td><textarea type="text" className="form-control" onChange={e => this.setState({ description: e.target.value })} ></textarea></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="add_stock" type="button" className="btn btn-primary" onClick={this.addDeliveryConditionHandle}>Add</button>
                                    {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        deliveryConditions: state.rDeliveryCondition.deliveryConditions,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDeliveryConditions: (token) => { dispatch(getDeliveryConditions(token)) },
        addDeliveryCondition: (data, token) => { dispatch(addDeliveryCondition(data, token)) },
        updateDeliveryCondition: (data, token) => { dispatch(updateDeliveryCondition(data, token)) },
        deleteDeliveryCondition: (data, token) => { dispatch(deleteDeliveryCondition(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCondition);


