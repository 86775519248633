/**
 * @type {Object}
 * @property {array}  data  Item data
 */
const initialState = {
    workingCalenderData: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerWorkingCalender = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_WORKING_CALENDER_DATA':
            newState.workingCalenderData = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerWorkingCalender;