import React, { useState } from 'react';

import { BarChart, Bar, Line, LineChart, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, ReferenceLine } from 'recharts';
import { schemeCategory10 } from 'd3-scale'

const SalesEvolutionGraphAll = ({ data, keys }) => {

    const colorArray = schemeCategory10.slice(0, keys.length);

    const DataFormater = (number) => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    }

    return (
        <div id="graphALL" className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg" style={{ "maxWidth": "1200px" }}>
                <div className="modal-content animate" >
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase">Sales</h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="container" >
                            <div className="lg-row" style={{ display: "flex" }}>
                                <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "normal" }} className="col-md-6">
                                    <ResponsiveContainer width={1100} height={500}>
                                        {/* <BarChart
                                            data={data}
                                            margin={{
                                                top: 10,
                                                right: 20,
                                                left: 0,
                                                bottom: 10,
                                            }}
                                            barSize={20}
                                        >
                                            <XAxis style={{ fontSize: '14px' }} dataKey="item" scale="point" padding={{ left: 5, right: 5 }} tick={{ fill: '#000000' }} />
                                            <YAxis style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                                            <Tooltip formatter={(sale) => new Intl.NumberFormat('en').format(sale)} contentStyle={{ color: '#000000', textAlign: 'left' }} />
                                            <Legend />
                                            {keys.map((key, index) => {
                                                return <Bar dataKey={key} fill={colorArray[index]}  />
                                            })}
                                            
                                        </BarChart> */}
                                        <LineChart width={1200} height={400} data={data}
                                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="item" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            {keys.map((key, index) => {
                                                return <Line type="monotone" dataKey={key} stroke={colorArray[index]} />
                                            })}

                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SalesEvolutionGraphAll;
