/**
 * @file This file defines to ordering action dispatch
 */
// import $ from 'jquery';
import { toast } from 'react-toastify';
var axios = require('axios');

export const orderHistoryAsync = data => {
    return { type: 'FETCH_ORDER_HISTORY', data }
}

export const orderHistoryItemsAsync = data => {
    return { type: 'FETCH_ORDER_HISTORY_ITEMS', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * get order History details 
 * 
 * @param {string} token 
 */
export const getOrderHistoryData = (token, date) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        dispatch(orderHistoryAsync([]))
        var url = '/orderHistory/getOrderHistory';

        if (date !== undefined) {
            var params = {
                from: date.from,
                to: date.to
            };
        }

        axios.get(url, {
            params
        }).then(result => {
            console.log(result.data);
            dispatch(isLoadingAsync(false))
            dispatch(orderHistoryAsync(result.data.reverse()))
        }).catch(err => {
            console.error(err)
            toast.error(`${err}`)
        })
    }
}

/**
 * get order history items details 
 * 
 * @param {Array} items itmes id
 * @param {string} token 
 */
export const getOrderHistoryItemsData = (items, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/orderHistory/getOrderItemHistory', { items })
            .then(result => {
                dispatch(orderHistoryItemsAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get order history report details 
 * 
 * @param {Array} data to/from
 * @param {string} token 
 */
export const getOrderHistoryReportData = (data, token, callback) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/orderHistory/getOrderHistoryReportData', { data })
            .then(result => {
                callback(result.data)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}
