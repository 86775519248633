/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import { getParameters, updateParameter, deleteParameter, addParameter } from '../../../actions/Administrator/parameters_actions';
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

const ReactTableFixedColumns = withFixedColumns(Table);

class Parameters extends React.Component {

    boolData = [
        { '_id': 'true', 'name': 'true' },
        { '_id': 'false', 'name': 'false' }
    ];

    state = {
        parameters: [],
        name: '',
        description: '',
        message: '',
        value: '',
        isActive: false,
        slug: '',
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'name', desc: false }],
    };

    componentDidMount() {
        this.props.getParameters(this.props.token);
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDropdownList,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' defaultValue={props.value} onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    this.boolData).map(data =>
                        <option key={data._id} value={data._id}>{data.name}</option>
                    )}
            </Field> : <label>{props.value}</label>)
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        isActive: rowProps.original.isActive
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('ADMINISTRATOR', 'PARAMETERS', 'UPDATE', this.props),
            delPerm: Permission('ADMINISTRATOR', 'PARAMETERS', 'DELETE', this.props)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === "Is Active") {
            value = value === "true" ? true : false;
            this.setState({ isActive: value });
        }
    }

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values;
            data.isActive = this.state.isActive;

            this.props.updateParameter(data, this.props.token)

        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values._id
            }

            this.props.deleteParameter(data, this.props.token)
        }
    };

    addParameterHandle = () => {
        var { name, description, message, value, isActive } = this.state;

        if (name.trim() !== '' || message.trim() !== '' || value.trim() !== '') {

            this.state.isActive = this.state.isActive === "true" ? true : false;
            this.state.slug = name.replace(/ /gi, "-");
            this.state.slug = this.state.slug.replace(/'/gi, "-");
            this.state.slug = this.state.slug.replace(/_/gi, "-");

            this.props.addParameter(this.state, this.props.token)

            this.setState({
                name: '',
                description: '',
                message: '',
                value: '',
                slug: ''
            });

            document.getElementById("addParameterForm").reset();
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined' && content !== '') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

        return true;
    };

    parametersColumns = [
        { Header: "Name", accessor: "name", ...this.editableColumnProps },
        { Header: "Description", accessor: "description", ...this.editableColumnProps },
        { Header: "Message", accessor: "message", ...this.editableColumnProps },
        { Header: "Value", accessor: "value", ...this.editableColumnProps },
        { Header: "Is Active", accessor: "isActive", ...this.selectableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('ADMINISTRATOR', 'PARAMETERS', 'ADD', this.props)} data-toggle="modal" data-target="#addParameter">+ Parameter</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null, shelflife: '', deliverytype: '', supplier: '' })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.parametersColumns}
                                                    data={this.props.parameters}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>

                    <ErrorBoundary>
                        <div id="addParameter" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Add Item Price</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <React.Fragment>
                                            <form id='addParameterForm'>
                                                <table>
                                                    <tbody>

                                                        <tr>
                                                            <td>Name* :</td>
                                                            <td><input type="text" className="form-control" onChange={e => this.setState({ name: e.target.value })} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td>Description :</td>
                                                            <td><input type="textarea" className="form-control" onChange={e => this.setState({ description: e.target.value })} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td>Message* :</td>
                                                            <td><input type="textarea" className="form-control" onChange={e => this.setState({ message: e.target.value })} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td>Value* :</td>
                                                            <td><input type="text" className="form-control" onChange={e => this.setState({ value: e.target.value })} /></td>
                                                        </tr>

                                                        <tr>
                                                            <td>Is Active :</td>
                                                            <td>
                                                                <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ isActive: e.target.value })}>
                                                                    <option value={''}>- Select Is Active -</option>
                                                                    {this.boolData.map(element => {
                                                                        return <option value={element._id}>{element.name}</option>
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                    <div className="modal-footer">
                                        <button id="" type="button" className="btn btn-primary" onClick={this.addParameterHandle}>Add</button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>

                    <div className="card-footer"></div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        parameters: state.rParameters.parameters,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        isLoading: state.rLogin.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getParameters: (token) => { dispatch(getParameters(token)) },
        updateParameter: (data, token) => { dispatch(updateParameter(data, token)) },
        deleteParameter: (data, token) => { dispatch(deleteParameter(data, token)) },
        addParameter: (data, token) => { dispatch(addParameter(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Parameters);


