/**
 * @type {Object}
 * @property {array}  data  Price Change Order data
 */
const initialState = {
    data: [],
    items: [],
    purchaseOrders: [],
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const purchasePriceReducer = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_PRICE_CHANGE_ORDER':
            newState.data = data;
            break;
        case 'FETCH_PRICE_CHANGE_ITEMS':
            newState.items = data;
            break;
        case 'PURCHASE_ORDERS_ASYNC':
            newState.purchaseOrders = data;
            break;
        default:
            break;
    }
    return newState;
}

export default purchasePriceReducer;