/**
 * @file This file defines to order action dispatch
 * @author Ehsan Elahi
 */
 import { toast } from 'react-toastify';
 var axios = require('axios');
 
 export const purchaseHistoryAsync = data => {
     return { type: 'FETCH_PURCHASE_HISTORY_DATA', data }
 }

 export const itemHistoryAsync = data => {
    return { type: 'FETCH_ITEM_HISTORY_DATA', data }
}

 
 /**
  * get purchasing order details 
  * 
  * @param {string} token 
  */
 export const getPurchaseHistoryData = token => {
     axios.defaults.headers.common['authorization'] = token;
     return dispatch => {
         axios.get('/purchasing/getPurchaseHistoryData', { headers: { authorization: token } })
             .then(result => {
                 dispatch(purchaseHistoryAsync(result.data.reverse()))
             }).catch(err => {
                 console.error(err)
                 toast.error(`${err}`)
             })
     }
 }

 export const getItemHistoryData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getItemHistoryData', { headers: { authorization: token } })
            .then(result => {
                console.log(result.data)
                dispatch(itemHistoryAsync(result.data.reverse()))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}