import React from 'react';
import Table from "react-table";
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Permission from '../../../../utils/Permission';

const UsersViewTable = ({ props, userData, filterCaseInsensitive, reloadUserData }) => {

    const deliveryWarehouseUsersColumn = [
        { Header: 'User Name', id: 'name', accessor: e => e.users.name.first + ' ' + e.users.name.last },
        {
            Header: 'Action',
            maxWidth: 110,
            Cell: row =>
                <>
                    <button disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'DELETE', props)} className='btn btn-link'
                        // data-toggle="modal" data-target='#viewImagesPopup' 
                        onClick={() => { props.deleteDeliveryWarehouseUser({ _id: row?.original?.deliverywarehouse?._id, user_id: row?.original?.users?._id }, props); reloadUserData() }}
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </>
        }
    ];

    return (
        <div className="collapse multi-collapse" id="viewUser">
            <div class="card card-body">
                {userData?.length === 0 ?
                    <>
                        No Data Found...!
                    </>
                    : <>
                        <ErrorBoundary>

                            <Table className="-striped"
                                // sorted={sortOptions}
                                columns={deliveryWarehouseUsersColumn}
                                data={userData}
                                defaultPageSize={5}
                                defaultFilterMethod={filterCaseInsensitive}
                            />

                        </ErrorBoundary>
                    </>}
            </div>
        </div>
    )
}

export default UsersViewTable
