/**
 * @type {Object}
 * @property {array}  accounts  Accounts data
 */
const initialState = {
    paymentsData: [],
    monthsData: [],
    totals: [],
    reportData:[],
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerPayments = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_PAYMENTS_DATA':
            var { data } = action;
            newState.paymentsData = data.payments;
            newState.monthsData = data.months;
            newState.totals = data.totals;
            break;

        case 'FETCH_REPORT_DATA':
            var { data } = action;
            newState.reportData = data;
        
            break;

        default:
            break;
    }
    return newState;
}

export default reducerPayments;