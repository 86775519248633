import _ from 'lodash';

const initialState = {
    stockRequestDataActive: [],
    stockRequestDataCompleted: [],
    warehouseData: [],
    stockRequestItemData: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const moveStockReducer = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_STOCK_REQUEST_DATA':
            var active = _.filter(data, (element) => {
                return element.stockrequest?.approved === false;
            });

            var completed = _.filter(data, (element) => {
                return element.stockrequest?.approved === true;
            });

            newState.stockRequestDataActive = active;
            newState.stockRequestDataCompleted = completed;
            break;

        case 'FETCH_WAREHOUSE_DATA':
            // var { data } = action;
            newState.warehouseData = data;
            break;

        case 'FETCH_STOCK_REQUEST_ITEM_DATA':
            // var { data } = action;
            newState.stockRequestItemData = data;
            break;

        default:
            break;
    }
    return newState;
}

export default moveStockReducer;
