/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const companyDataAsync = data => {
    return { type: 'FETCH_COMPANY_DATA', data }
}

/**
 * @param {*} token 
 */
export const getCompanies = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/companies/getCompanies')
            .then(result => {
                dispatch(companyDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateCompany = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/companies/updateCompany', { data })
            .then(result => {
                toast.success('Successfully Updated Company')
                dispatch(getCompanies(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteCompany = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/companies/deleteCompany', { data })
            .then(result => {
                toast.success('Company Deleted Successfully!')
                dispatch(getCompanies(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addCompany = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/companies/addCompany', {data})
            .then(result => {
                window.$('#addCompany').modal('hide');
                toast.success('Successfully Added Company');
                dispatch(getCompanies(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}