/**
 * @file This file defined for toast component
 * @author Bhanuka Chathuranga
 */
import React from 'react'

/**
 * 
 * @param {Object} obj 
 * @param {String} obj.text toast text
 * @returns {Component} ToastHtml component
 */
function ToastHtml({ text }) {
    let line = text.split('\\n')
    // console.log(line);
    // let lineLength = line.length
    return (
        <>
            {line.map((element, i) => {
                return <div key={i}>
                    {element}<br />
                </div>
            })}

        </>
    )
}

export default ToastHtml
