/**
 * @file This file defined for Delivered Overview
 * @author Umesh Ranthilina
 */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getDeliveredData, updateDeliveredOverview } from "../../../actions/Delivery/delivery_action";
import moment from "moment";
import Table from "react-table";

import GridFilter from '../../components/GridFilter';
import FormProvider from "../../components/FormProvider";
import { DateNumberFormat } from '../../utils';
import ActionsCell from "../../components/UpdateActionsCell";
import Permission from "../../utils/Permission";
import { Field } from "redux-form";

import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import { getPaymentMethodData } from '../../../actions/Basic_data/basic_data_action';

export class DeliveredOverview extends Component {

  orderStatus = [
    { '_id': 'Dispatched', 'name': 'Dispatched' },
    { '_id': 'Delivered', 'name': 'Delivered' },
    { '_id': 'DispatchedMissing', 'name': 'DispatchedMissing' },
  ];


  deliveredStatus = [
    { '_id': '', 'name': 'None' },
    { '_id': 'Delivered', 'name': 'Delivered' },
    { '_id': 'OnDelivery', 'name': 'OnDelivery' },
    { '_id': 'ToDeliver', 'name': 'ToDeliver' },
  ];

  state = {
    fromDate: '',
    toDate: '',
    editing: null,
    deleting: null,
    status: '',
    deliveryStatus: '',
    paymentMethod:''
  }

  componentDidMount() {
    this.props.getPaymentMethodData(this.props.token);
    let Dates = new Date();
    this.setState({
      fromDate: moment(Dates.setMonth(Dates.getMonth() - 1)).format("YYYY-MM-DD"),
      toDate: moment(Date.now()).format("YYYY-MM-DD"),
    })
    this.props.getDeliveredData({ from: moment(Dates.setMonth(Dates.getMonth() - 0)).format("YYYY-MM-DD"), to: moment(Date.now()).format("YYYY-MM-DD") }, this.props)
  }

  onChangeDateValue = (e, name) => {
    if (name === "fromDate") {
      let fromDateFormatted = moment(e.target.value).format("YYYY-MM-DD");
      this.setState({
        fromDate: fromDateFormatted,
      })
    }
    if (name === "toDate") {
      let ToDateFormatted = moment(e.target.value).format("YYYY-MM-DD");
      this.setState({
        toDate: ToDateFormatted,
      })
    }
  }

  setStateForEdit = (column, value) => {
    if (column === 'Order Status') {
      this.setState({ status: value })
    } else if (column === 'Delivery Status') {
      this.setState({ deliveryStatus: value })
    }else if (column === 'Payment Method') {
      this.setState({ paymentMethod: value })
    }
  }

  selectableColumnProps = {
    ...GridFilter,
    Cell: props => {
      const editing = this.state.editing === props.original;

      return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
        {
          (
            props.column.Header === 'Order Status' ? this.orderStatus : props.column.Header === 'Payment Method' ? this.props.paymentMethods : this.deliveredStatus
          ).map(data =>
            <option key={data._id} value={data._id}>{data.name}</option>
          )}
      </Field> : <label>{props.value}</label>)
    }
  };

  getActionProps = (gridState, rowProps) =>
    (rowProps && {
      mode: this.state.editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => this.setState({ editing: rowProps.original }),
        onCancel: () => this.setState({ editing: null }),
      },
      updPerm: Permission('DELIVERY', 'DELIVERED OVERVIEW', 'UPDATE', this.props),
    }) ||
    {};


  columns = [
    { Header: "Order Id", accessor: "orders.orderId", ...GridFilter },
    { Header: "Customer", accessor: "orders.name", ...GridFilter },
    { Header: "Address", accessor: "orders.address", ...GridFilter },
    { Header: "Region", accessor: "region.name", ...GridFilter },
    { Header: "Rider", accessor: "deliveryPerson.name", ...GridFilter },
    { Header: "Order Status", accessor: "orders.status", ...this.selectableColumnProps },
    { Header: "Delivery Status", accessor: "orders.deliveryStatus", ...this.selectableColumnProps },
    { Header: "Payment Method", accessor: "paymentmethods.name", ...this.selectableColumnProps },
    { Header: "Pick Up", accessor: "pickup", ...GridFilter },
    { Header: "At Office", accessor: "office", ...GridFilter },
    {
      Header: "Dispatch Date/Time", accessor: "orders.dispatchedDate", ...GridFilter,
      Cell: props => {
        if(props.original?.orders?.dispatchedDate !== undefined){
          var today = new Date(props.original?.orders?.dispatchedDate)
          var time = today.toLocaleTimeString();
          var date = props.original?.orders?.dispatchedDate;
          var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
          return <>{dateTime}</>
        }else{
          return <>{''}</>
        }
      }
    },
    {
      Header: "Delivered Date/Time", accessor: "orders.deliveryDate", ...GridFilter,
      Cell: props => {
        if(props.original?.orders?.deliveryDate !== undefined && props.original?.orders?.deliveryDate !== ''){
          var today = new Date(props.original?.orders?.deliveryDate)
          var time = today.toLocaleTimeString();
          var date = props.original?.orders?.deliveryDate;
          var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
          return <>{dateTime}</>
        }else{
          return <>{''}</>
        }
      }
    },
    {
      Header: "Location",
      Cell: props =>
        <>{props.original.orders.coordinate !== undefined ?
          <button className={props.original.office === "Yes" ? 'btn btn-link text-danger' : 'btn btn-link' } disabled={props.original.orders.coordinate.latitude === undefined || props.original.orders.coordinate.longitude === undefined}
            onClick={() => {
              window.open("https://maps.google.com?q=" + props.original.orders.coordinate.latitude + "," + props.original.orders.coordinate.longitude);
            }}
          > {(props.original.orders.coordinate.latitude === undefined || props.original.orders.coordinate.longitude === undefined) ? "No Location" : "View Location"}</button>
          : <button className='btn btn-link' disabled={props.original.orders.coordinate === undefined}
          >No Location</button>}
        </>
    },
    {
      Header: "Actions",
      maxWidth: 130,
      minWidth: 130,
      filterable: false,
      getProps: this.getActionProps,
      Cell: ActionsCell
    },
  ];

  handleSubmit = values => {

    if (this.state.deleting === null && this.state.editing !== null) {
      var data = {
        '_id': values.orders._id,
        'status': values.orders.status === undefined ? '' : values.orders.status,
        'deliveryStatus': values.orders.deliveryStatus === undefined ? '' : values.orders.deliveryStatus,
        'from': this.state.fromDate,
        'to': this.state.toDate,
        'user': this.props.userId,
        'pickUp': values.orders.orderPickUp,
        'paymentMethod': this.state.paymentMethod !== undefined && this.state.paymentMethod !== '' ? this.state.paymentMethod : values.paymentmethods._id
       };
      this.props.updateDeliveredOverview(data, this.props);
    }
  };


  render() {
    
    return (
      <div className="right_col" role="main">
        <div className="card">
          <div className="card-header">
            <div style={{ display: 'flex' }}>
              <div className="h6">From :</div>
              <div><input className="form-control" type="date" defaultValue={this.state.fromDate}
                onChange={e => {
                  this.onChangeDateValue(e, "fromDate")
                }} />
              </div>
              &nbsp;&nbsp;&nbsp;
              <div className="h6">To :</div>
              <div><input className="form-control" type="date" defaultValue={this.state.toDate}
                onChange={e => {
                  this.onChangeDateValue(e, "toDate")
                }} />
              </div>
              &nbsp;&nbsp;&nbsp;
              <div style={{ display: 'flex' }}>
                <button className='btn btn-primary' onClick={() => this.props.getDeliveredData({ from: this.state.fromDate, to: this.state.toDate }, this.props)}>Search</button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <FormProvider
              form="inline"
              onSubmit={this.handleSubmit}
              onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
              initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
              enableReinitialize

            >
              {formProps => {
                return (
                  <form onSubmit={formProps.handleSubmit}>
                    <Table className="-striped"
                      columns={this.columns}
                      data={this.props.deliveredOverviewData}
                      defaultPageSize={10}
                      defaultFilterMethod={this.filterCaseInsensitive}
                      showPageJump={false}
                    />
                  </form>
                );
              }}
            </FormProvider>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  token: state.rLogin.token,
  deliveredOverviewData: state.rDeliveryBoys.deliveredOverviewData,
  userProgramme: state.rLogin.programme,
  programme: state.rPermission.programme,
  subProgramme: state.rPermission.subProgramme,
  options: state.rPermission.option,
  userId: state.rLogin.id,
  paymentMethods: state.rBasicData.payment_method_data,
})

const mapDispatchToProps = dispatch => ({
  getDeliveredData: (data, props) => { dispatch(getDeliveredData(data, props)) },
  getPaymentMethodData: token => { dispatch(getPaymentMethodData(token)) },
  updateDeliveredOverview: (data, props) => { dispatch(updateDeliveredOverview(data, props)) },
})


export default connect(mapStateToProps, mapDispatchToProps)(DeliveredOverview);
