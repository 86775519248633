/**
 * @file This file defines for supplier details
 * @author Bhanuka Chathuranga
 */
import React from "react";
import { connect } from 'react-redux';
// import set from "lodash/fp/set";
import $ from 'jquery';
import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import { toast } from "react-toastify";
import _ from 'lodash';

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import Modal from '../../components/Modal';

import { getSupplierData, addSupplier, updateSupplier, deleteSupplier } from '../../../actions/Purchasing/purchasing_supplier_action';
import { getCurrencyData, getPaymentMethodData, getCountry, getPaymentCondition, getDeliveryCondition } from '../../../actions/Basic_data/basic_data_action';
import { getAccountsData } from '../../../actions/Acc/account_actions';
import { getBanksData, getBranchesData } from '../../../actions/Acc/bank_actions';
import Permission from "../../utils/Permission";
import ErrorBoundary from "../../components/ErrorBoundary";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import { func } from "prop-types";

class Supplier extends React.Component {

  consignmentOptions = [
    { 'id': 'Yes', 'name': 'Yes' },
    { 'id': 'No', 'name': 'No' }
  ];

  state = {
    data: [],
    name: '',
    callerName: '',
    address: '',
    country: '',
    telephone: '',
    email: '',
    leadTime: 1,
    currency: '',
    payment_method: '',
    payment_conditon: '',
    delivery_conditon: '',
    accounts: [],
    selectedAcc: '',
    whatsappgroup: '',
    editing: null,
    deleting: null,
    currentSupplier: {},
    accountNumber: '',
    accountName: '',
    selectedBank: '',
    selectedBranch: '',
    consignment: '',
    expanded: {},
    banksData: [],
    branchesData: [],
    bankSpecificBranches: [],
    bankSpecificBranchesEdit: [],
    viewBankDetails: true,
    bankIdForEdit: '',
    branchIdForEdit: '',
    currentSupplierBankDetail: [],
    sortOptionsBank: [{ id: 'createdAt', desc: true }],
  };

  componentDidMount() {
    this.props.getSupplierData(this.props.token)
    this.props.getCurrencyData(this.props.token)
    this.props.getPaymentMethodData(this.props.token)
    this.props.getCountry(this.props.token)
    this.props.getPaymentCondition(this.props.token)
    this.props.getDeliveryCondition(this.props.token)
    this.props.getAccountsData(this.props.token)
    this.props.getBanksData(this.props.token);
    this.props.getBranchesData(this.props.token);
  }

  editableComponent = ({ input, editing, value, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component {...input} {...rest} children={children} />;
  };

  editableSelectComponent = ({ input, editing, value, ...rest }) => {
    const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
    // console.log(editing)
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component {...input} {...rest} children={children} />;
  };

  editableColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = this.state.editing === props.original;
      const fieldProps = {
        component: this.editableComponent,
        editing,
        props
      };
      // console.log(fieldProps);
      return <Field name={props.column.id} {...fieldProps} />;
    }
  };

  editableNumberColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = this.state.editing === props.original;
      const fieldProps = {
        component: this.editableNumberComponent,
        editing,
        props
      };
      // console.log(fieldProps);
      return <Field name={props.column.id} {...fieldProps} />;
    }
  };

  editableNumberComponent = ({ input, editing, value, original, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component type="number" {...input} {...rest} children={children} />;
  };

  selectableColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = this.state.editing === props.original;

      return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEditConsignment(props.column.Header, e.target.value)}>
        {(props.column.Header === 'Delivery condition' ? this.props.delivery_conditons
          : props.column.Header === 'Country' ? this.props.country
            : props.column.Header === 'Currency' ? this.props.currency_data
              : props.column.Header === 'Account' ? this.props.accounts
                : props.column.Header === 'Payment condition' ? this.props.payment_conditons
                  : props.column.Header === 'Consignment' ? this.consignmentOptions
                    : this.props.payment_methods).map(data =>
                      <option key={data._id} value={data.name}>
                        {props.column.Header === 'Delivery condition' || props.column.Header === 'Payment condition' ? data.description : props.column.Header === 'Account' ? data.acc_name : data.name}</option>
                    )}
      </Field> : <label>{props.value}</label>)
    }
  };

  selectableColumnPropsBank = {
    ...GridFilters,
    Cell: props => {
      const editing = this.state.editing === props.original;

      return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
        {(props.column.Header === 'Bank Name' ? this.props.banksData
          : this.state.bankSpecificBranchesEdit).map(data =>
            <option key={data._id} value={data._id}>{data.name}</option>
          )}
      </Field> : <label>{props.value}</label>)
    }
  };

  setStateForEditConsignment = (header, value) => {
    if (header === 'Consignment') {
      this.setState({
        consignment: value
      })
    }
  }

  setStateForEdit = (header, value) => {
    if (header === 'Bank Name') {
      var bankSpecificBranchesEdit = _.filter(this.props.branchesData, function (element) {
        return element.bankref === value;
      })

      this.setState({
        bankIdForEdit: value,
        bankSpecificBranchesEdit: bankSpecificBranchesEdit
      });
    } else {
      this.setState({
        branchIdForEdit: value
      });
    }
  }

  getActionProps = (gridState, rowProps) =>
    (rowProps && {
      mode: this.state.editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => this.setState({ editing: rowProps.original }),
        onCancel: () => this.setState({ editing: null }),
        onDelete: () => {
          this.setState({ deleting: rowProps.original });
        }
      },
      updPerm: Permission('PURCHASE', 'SUPPLIERS', 'UPDATE', this.props),
      delPerm: Permission('PURCHASE', 'SUPPLIERS', 'DELETE', this.props),
    }) ||
    {};

  getActionPropsBank = (gridState, rowProps) =>
    (rowProps && {
      mode: this.state.editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => this.setState({
          editing: rowProps.original,
          bankIdForEdit: rowProps.original.bankId,
          branchIdForEdit: rowProps.original.branchId
        }),
        onCancel: () => this.setState({ editing: null }),
        onDelete: () => {
          this.setState({ deleting: rowProps.original });
        }
      },
      updPerm: Permission('PURCHASE', 'SUPPLIERS', 'UPDATE', this.props),
      delPerm: Permission('PURCHASE', 'SUPPLIERS', 'DELETE', this.props),
    }) ||
    {};

  columns = [
    { Header: "Name", accessor: "supplier.name", ...this.editableColumnProps },
    { Header: "Caller Name", accessor: "supplier.callerName", ...this.editableColumnProps },
    { Header: "Address", accessor: "supplier.address", ...this.editableColumnProps },
    { Header: "Phone", accessor: "supplier.telephone", ...this.editableColumnProps },
    { Header: "Email", accessor: "supplier.email", ...this.editableColumnProps },
    { Header: "Lead Time", accessor: "supplier.leadTime", ...this.editableNumberColumnProps },
    { Header: "Currency", accessor: "currency.name", ...this.selectableColumnProps },
    { Header: "Country", accessor: "country.name", ...this.selectableColumnProps },
    { Header: "Payment method", accessor: "paymentmethods.name", ...this.selectableColumnProps },
    { Header: "Payment condition", accessor: "paymentcondition.description", ...this.selectableColumnProps },
    { Header: "Delivery condition", accessor: "deliverycondition.description", ...this.selectableColumnProps },
    { Header: "Consignment", accessor: "supplier.consignment", ...this.selectableColumnProps },
    { Header: "Account", accessor: "accounts.acc_name", ...this.selectableColumnProps },
    { Header: "Whatsapp Group", accessor: "supplier.whatsappgroup", ...this.editableColumnProps },
    {
      Header: "",
      Cell: props => (
        <div style={{ textAlign: "right" }}>
          <button
            data-toggle="modal"
            className="btn btn-link"
            data-target="#linkBank"
            data-backdrop="static" data-keyboard="false"
            onClick={() => {

              var currentSupplierBankDetail = [];

              var bDetails = props.original.supplier.bankDetails;

              if (bDetails) {
                for (var i = 0; i < bDetails.length; i++) {
                  this.props.banksData.forEach(bankData => {
                    if (bankData._id.toString() === bDetails[i].selectedBank) {
                      var bankObj = {
                        index: i,
                        bankId: bankData._id,
                        accountName: bDetails[i].accountName,
                        accountNumber: bDetails[i].accountNumber,
                        bankName: bankData.name,
                      };

                      currentSupplierBankDetail.push(bankObj);
                    }
                  });
                }

                var filteredBranchesData = [];

                for (var k = 0; k < bDetails.length; k++) {
                  this.props.branchesData.forEach(branch => {
                    if (branch._id.toString() === bDetails[k].selectedBranch.toString()) {
                      filteredBranchesData.push(branch);
                    }
                  })
                }

                for (var j = 0; j < currentSupplierBankDetail.length; j++) {
                  filteredBranchesData.forEach(branchData => {
                    if (branchData.bankref === currentSupplierBankDetail[j].bankId && branchData._id.toString() === bDetails[j].selectedBranch) {
                      currentSupplierBankDetail[j].branchName = branchData.name;
                      currentSupplierBankDetail[j].branchTelNum = branchData.telnum;
                      currentSupplierBankDetail[j].branchId = branchData._id;
                    }
                  })
                }
              }

              this.setState({
                currentSupplier: props.original.supplier,
                currentSupplierBankDetail: currentSupplierBankDetail
              });

            }}
          >
            + Link Bank
          </button>
        </div>
      ),
    },
    {
      Header: "",
      maxWidth: 130,
      minWidth: 130,
      filterable: false,
      getProps: this.getActionProps,
      Cell: ActionsCell
    },
  ];

  /**
   * To validation
   */
  addSupplierHandle = () => {
    var phoneno = /^\d{10}$/;
    var emailCheck = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    var { name, address, country, telephone, email, currency, payment_method, payment_conditon, delivery_conditon, selectedAcc, whatsappgroup } = this.state;
    if (name.trim() !== '' && address.trim() !== '' && country !== '0' && currency !== '0' && payment_method !== '0' && payment_conditon !== '0' && delivery_conditon !== '0') {

      if (telephone.trim() !== '' && phoneno.test(telephone) && email.trim() !== '' && emailCheck.test(email)) {
        console.log('telephone email match');
        this.props.addSupplier(this.state, this.props.token)
        window.$('#addSupplier').modal('toggle');
      } else if (telephone.trim() !== '' && phoneno.test(telephone) && email.trim() === '') {
        console.log('telephone match');
        this.props.addSupplier(this.state, this.props.token)
        window.$('#addSupplier').modal('toggle');
      } else if (email.trim() !== '' && emailCheck.test(email) && telephone.trim() === '') {
        console.log('email match');
        this.props.addSupplier(this.state, this.props.token)
        window.$('#addSupplier').modal('toggle');
      } else if (telephone.trim() === '' && email.trim() === '') {
        console.log('empty value');
        toast.info('Empty telephone and email...!')
        // this.props.addSupplier(this.state, this.props.token)
        // window.$('#addSupplier').modal('toggle');
      } else {
        console.log('error');
        toast.info('Invalid Telephone or Password...!')
      }
    } else {

      if (name.trim() === '' && address.trim() === '' && country === '' && currency === '0' && payment_method === '0' && payment_conditon === '0' && delivery_conditon === '0') {
        $('#supplier_name').addClass('parsley-error');
        $('#address').addClass('parsley-error');
        $('#country').addClass('parsley-error');
        $('#currency').addClass('parsley-error');
        $('#payment_method').addClass('parsley-error');
        $('#payment_condition').addClass('parsley-error');
        $('#delivery_condition').addClass('parsley-error');
        toast.info('Check require field...!')
      } else if (name.trim() === '') {
        $('#supplier_name').addClass('parsley-error');
        toast.info('Check require field...!')
      } else if (address.trim() === '') {
        $('#address').addClass('parsley-error');
        toast.info('Check require field...!')
      } else if (country === '0') {
        $('#country').addClass('parsley-error');
        toast.info('Check require field...!')
      } else if (currency === '0') {
        $('#currency').addClass('parsley-error');
        toast.info('Check require field...!')
      } else if (payment_method === '0') {
        $('#payment_method').addClass('parsley-error');
        toast.info('Check require field...!')
      } else if (payment_conditon === '0') {
        $('#payment_condition').addClass('parsley-error');
        toast.info('Check require field...!')
      } else if (delivery_conditon === '0') {
        $('#delivery_condition').addClass('parsley-error');
        toast.info('Check require field...!')
      }
    }
  }

  /**
   * To update and delete handle
   */
  handleSubmit = values => {

    var data = values.supplier;

    if (this.state.deleting === null && this.state.editing !== null) {

      const newData = { ...data }
      newData.currency = this.state.editing.currency.name !== values.currency.name ? _.filter(this.props.currency_data, { name: values.currency.name })[0]._id : values.currency._id
      newData.waypayment_ref = _.filter(this.props.payment_methods, { name: values.paymentmethods.name })[0]._id
      newData.country = _.filter(this.props.country, { name: values.country.name })[0]._id
      newData.delicondition_ref = this.state.editing.deliverycondition.description !== values.deliverycondition.description ? _.filter(this.props.delivery_conditons, { description: values.deliverycondition.description })[0]._id : values.deliverycondition._id
      newData.paycondition_ref = this.state.editing.paymentcondition.description !== values.paymentcondition.description ? _.filter(this.props.payment_conditons, { description: values.paymentcondition.description })[0]._id : values.paymentcondition._id
      newData.account_ref = values.accounts ? _.filter(this.props.accounts, { acc_name: values.accounts.acc_name })[0]._id : '';
      newData.consignment = this.state.consignment;

      this.props.updateSupplier(newData, this.props.token)

      this.setState({
        consignment: ''
      })

    } else if (this.state.deleting !== null && this.state.editing === null) {

      this.props.deleteSupplier(data, this.props.token)

    }
  };

  clearLinkBankForm = () => {
    this.setState({
      currentSupplier: {},
      accountNumber: '',
      accountName: '',
      selectedBank: '',
      selectedBranch: '',
    });

    document.getElementById("linkBankForm").reset();
    window.$('#linkBank').modal('hide');
    this.setState({
      viewBankDetails: true
    });
  }

  linkBankHandle = () => {
    var { accountName,
      accountNumber,
      selectedBank,
      selectedBranch,
      currentSupplier
    } = this.state;

    if (accountName.trim() !== '' && accountNumber.trim() !== '' && selectedBank.trim() !== '' && selectedBranch.trim() !== '') {
      const data = {
        accountName: accountName,
        accountNumber: accountNumber,
        selectedBank: selectedBank,
        selectedBranch: selectedBranch
      };

      if (currentSupplier.bankDetails) {
        currentSupplier.bankDetails.push(data)
      } else {
        currentSupplier.bankDetails = [data];
      }

      this.props.updateSupplier(currentSupplier, this.props.token);
      this.clearLinkBankForm();

    }
  }

  handleRowExpanded = (rowsState, index) => {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]],
      },
    });
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return (
      row[id] !== undefined ?
        String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
        true
    );
  }

  setSelectedBank = (value) => {
    var bankSpecificBranches = _.filter(this.props.branchesData, function (element) {
      return element.bankref === value;
    })

    this.setState({
      selectedBank: value,
      bankSpecificBranches: bankSpecificBranches
    });
  }

  handleSubmitBank = (values) => {
    if (this.state.deleting === null && this.state.editing !== null) {
      const index = values.index;
      this.state.currentSupplier.bankDetails[index].accountName = values.accountName;
      this.state.currentSupplier.bankDetails[index].accountNumber = values.accountNumber;
      this.state.currentSupplier.bankDetails[index].selectedBank = this.state.bankIdForEdit;
      this.state.currentSupplier.bankDetails[index].selectedBranch = this.state.branchIdForEdit;

      this.props.updateSupplier(this.state.currentSupplier, this.props.token);
    } else if (this.state.deleting !== null && this.state.editing === null) {
      const index = values.index;
      this.state.currentSupplier.bankDetails.splice(index, 1);
      this.props.updateSupplier(this.state.currentSupplier, this.props.token);
    }
    this.setState({
      currentSupplier: {},
      accountNumber: '',
      accountName: '',
      selectedBank: '',
      selectedBranch: '',
    });

    window.$('#linkBank').modal('hide');
  }

  bankColumns = [
    { Header: "Account Name", accessor: "accountName", ...this.editableColumnProps },
    { Header: "Account Number", accessor: "accountNumber", ...this.editableColumnProps },
    { Header: "Bank Name", accessor: "bankName", ...this.selectableColumnPropsBank },
    { Header: "Branch Name", accessor: "branchName", ...this.selectableColumnPropsBank },
    { Header: "Branch Tel Num.", accessor: "branchTelNum", filterable: true },
    {
      Header: "",
      fixed: 'right',
      maxWidth: 130,
      minWidth: 130,
      filterable: false,
      getProps: this.getActionPropsBank,
      Cell: ActionsCell
    },
  ];

  render() {
    return (
      <div className="right_col" role="main">
        <div className="card">
          <div className="card-header">
            <button className="btn btn-primary" data-toggle="modal" data-target="#addSupplier"
              disabled={this.props.isLoading ? true : Permission('PURCHASE', 'SUPPLIERS', 'ADD', this.props)}
              onClick={() => {
                this.setState({ name: '', callerName: '', address: '', country: '', telephone: '', email: '', currency: '0', payment_method: '0', payment_conditon: '0', delivery_conditon: '0' })
              }}>+ Supplier</button>
          </div>
          <div className="card-body">
            <ErrorBoundary>
              {this.props.isLoading
                ? <>
                  <SkeletonTable columnsCount={9} dataCount={10} />
                </>
                : <FormProvider
                  form="inline"
                  onSubmit={this.handleSubmit}
                  onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                  initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                  enableReinitialize
                >
                  {formProps => {
                    return (
                      <form onSubmit={formProps.handleSubmit}>
                        <Table className="-striped"
                          columns={this.columns}
                          data={this.props.data.reverse()}
                          defaultPageSize={10}
                        />
                      </form>
                    );
                  }}
                </FormProvider>}
            </ErrorBoundary>
          </div>
          {/* <div className="card-footer"></div> */}
        </div>

        {/* add model */}
        <div id="addSupplier" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content animate" >
              <div className="modal-header">
                <h4 className="modal-title text-uppercase">Add supplier</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <React.Fragment>
                  <table>
                    <tbody>
                      <tr>
                        <td>Name* :</td>
                        <td>
                          <input id='supplier_name' type="text" value={this.state.name} className="form-control" maxLength={100}
                            onChange={e => {
                              $('#supplier_name').removeClass('parsley-error');
                              this.setState({ name: e.target.value })
                            }} />
                        </td>
                        <td>Caller name :</td>
                        <td><input type="text" value={this.state.callerName} className="form-control" maxLength={100} onChange={e => this.setState({ callerName: e.target.value })} /></td>
                      </tr>
                      <tr>
                        <td>Address* :</td>
                        <td>
                          <input id='address' type="text" value={this.state.address} className="form-control" maxLength={200}
                            onChange={e => {
                              $('#address').removeClass('parsley-error');
                              this.setState({ address: e.target.value })
                            }} />
                        </td>
                        {/* </tr>
                      <tr> */}
                        <td>Country* :</td>
                        <td>
                          <select id='country' className="form-control" value={this.state.country}
                            onChange={e => {
                              if (e.target.value === '0') {

                              } else {
                                $('#country').removeClass('parsley-error');
                                this.setState({ country: e.target.value })
                              }
                            }}>
                            <option value={'0'}>- Select country -</option>
                            {this.props.country.map(country => {
                              return <option value={country._id}>{country.name}</option>
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Telephone* :</td>
                        <td><input id='telephone' type="number" value={this.state.telephone} autoComplete='tel' className="form-control" maxLength={10} onChange={e => this.setState({ telephone: e.target.value })} /></td>
                        {/* </tr>
                      <tr> */}
                        <td>Email* :</td>
                        <td><input id='email' type="email" value={this.state.email} className="form-control" maxLength={50} onChange={e => this.setState({ email: e.target.value })} /></td>
                      </tr>
                      <tr>
                        <td>Lead Time :</td>
                        <td style={{ display: 'inline-flex' }}><input type="number" className="form-control" value={this.state.leadTime} min={0} max={24}
                          onChange={e => this.setState({ leadTime: e.target.valueAsNumber })} /> Hours</td>
                      </tr>
                      <tr>
                        <td>Currency* :</td>
                        <td>
                          <select id='currency' className="form-control" value={this.state.currency}
                            onChange={e => {
                              if (e.target.value === '0') {

                              } else {
                                $('#currency').removeClass('parsley-error');
                                this.setState({ currency: e.target.value })
                              }
                            }}>
                            <option value={'0'}>- Select currency -</option>
                            {this.props.currency_data.map(currency => {
                              return <option value={currency._id}>{currency.name} - {currency.code}</option>
                            })}
                          </select>
                        </td>
                        {/* </tr>
                      <tr> */}
                        <td>Payment Method* :</td>
                        <td>
                          <select id='payment_method' className="form-control" value={this.state.payment_method} defaultValue={this.state.payment_method}
                            onChange={e => {
                              if (e.target.value === '0') {

                              } else {
                                $('#payment_method').removeClass('parsley-error');
                                this.setState({ payment_method: e.target.value })
                              }
                            }}>
                            <option value={'0'}>- Select payment method -</option>
                            {this.props.payment_methods.map(payMethod => {
                              if (payMethod.isActive) return <option value={payMethod._id}>{payMethod.name}</option>
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Payment Condition* :</td>
                        <td>
                          <select id='payment_condition' className="form-control" value={this.state.payment_conditon}
                            onChange={e => {
                              if (e.target.value === '0') {

                              } else {
                                $('#payment_condition').removeClass('parsley-error');
                                this.setState({ payment_conditon: e.target.value })
                              }
                            }}>
                            <option value={'0'}>- Select payment condition -</option>
                            {this.props.payment_conditons.map(paymentConditon => {
                              return <option value={paymentConditon._id}>{paymentConditon.numberOfdays} days - {paymentConditon.description}</option>
                            })}
                          </select>
                        </td>
                        {/* </tr>
                      <tr> */}
                        <td>Delivery Condition* :</td>
                        <td>
                          <select id='delivery_condition' className="form-control" value={this.state.delivery_conditon}
                            onChange={e => {
                              if (e.target.value === '0') {

                              } else {
                                $('#delivery_condition').removeClass('parsley-error');
                                this.setState({ delivery_conditon: e.target.value })
                              }
                            }}>
                            <option value={'0'}>- Select delivery condition -</option>
                            {/* {console.table(this.props.delivery_conditons)} */}
                            {this.props.delivery_conditons.map(deliveryConditon => {
                              return <option value={deliveryConditon._id}>{deliveryConditon.description}</option>
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Account* :</td>
                        <td>
                          <select id='account' className="form-control" value={this.state.selectedAcc}
                            onChange={e => {
                              if (e.target.value === '0') {

                              } else {
                                $('#account').removeClass('parsley-error');
                                this.setState({ selectedAcc: e.target.value })
                              }
                            }}>
                            <option value={'0'}>- Select Account -</option>
                            {this.props.accounts.map(account => {
                              return <option value={account._id}>{account.acc_name}</option>
                            })}
                          </select>
                        </td>
                        <td>Whatsapp Group :</td>
                        <td><input id='whatsappgroup' type="text" value={this.state.whatsappgroup} className="form-control" maxLength={50} onChange={e => this.setState({ whatsappgroup: e.target.value })} /></td>
                      </tr>
                    </tbody>
                  </table>
                </React.Fragment>
              </div>
              <div className="modal-footer">
                <button id="add_supplier" type="button" className="btn btn-primary"
                  // data-dismiss="modal"
                  onClick={this.addSupplierHandle}>
                  Add
                </button>
                {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>



        <div id="linkBank" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content animate" >
              <div className="modal-header">
                <button
                  className="btn btn-primary"
                  onClick={e => this.state.viewBankDetails ? this.setState({ viewBankDetails: false }) : this.setState({ viewBankDetails: true })}>
                  {this.state.viewBankDetails ? 'Link' : 'View'}
                </button>
              </div>
              <div className="modal-header">
                <h4 className="modal-title text-uppercase">{this.state.viewBankDetails ? 'Bank Details' : 'Link Bank'}</h4>
              </div>
              <div className="modal-body">
                <ErrorBoundary>
                  {this.state.viewBankDetails ?
                    <div className="card-body">
                      <FormProvider
                        onSubmit={this.handleSubmitBank}
                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                        form="inline"
                      // enableReinitialize
                      >
                        {formProps => {
                          return (
                            <form onSubmit={formProps.handleSubmit}>
                              <Table className="-striped"
                                sorted={this.state.sortOptionsBank}
                                onSortedChange={val => {
                                  this.setState({ sortOptionsBank: val })
                                }}
                                columns={this.bankColumns}
                                data={this.state.currentSupplierBankDetail}
                                defaultPageSize={3}
                                defaultFilterMethod={this.filterCaseInsensitive}
                                showPageJump={false}
                              />
                            </form>
                          );
                        }}
                      </FormProvider>
                    </div>

                    :

                    <form id='linkBankForm'>
                      <table style={{ 'width': '90%' }}>
                        <tr>
                          <td>Account Number: *</td>
                          <td>
                            <input type="text" className="form-control" value={this.state.accountNumber} onChange={e => this.setState({ accountNumber: e.target.value })} />
                          </td>
                        </tr>

                        <tr>
                          <td>Account Name*:</td>
                          <td>
                            <input type="text" className="form-control" value={this.state.accountName} onChange={e => this.setState({ accountName: e.target.value })} />
                          </td>
                        </tr>

                        <tr>
                          <td>Bank*:</td>
                          <td>
                            <select className="form-control" value={this.state.selectedBank} onChange={e => this.setSelectedBank(e.target.value)}>
                              <option value=''>Select Bank</option>
                              {this.props.banksData.map(element => {
                                return <option value={element._id}>{element.name}</option>
                              })}
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Branch*:</td>
                          <td>
                            <select className="form-control" value={this.state.selectedBranch} onChange={e => this.setState({ selectedBranch: e.target.value })}>
                              <option value=''>Select Branch</option>
                              {this.state.bankSpecificBranches.map(element => {
                                return <option value={element._id}>{element.name}</option>
                              })}
                            </select>
                          </td>
                        </tr>
                      </table>
                    </form>
                  }
                </ErrorBoundary>


              </div>
              <div className="modal-footer">
                {
                  !this.state.viewBankDetails ?
                    <div>
                      <button id="" type="button" className="btn btn-primary" onClick={e => this.linkBankHandle()}>Link</button>
                      <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.clearLinkBankForm()}>Close</button>
                    </div>
                    :
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                }


              </div>
            </div>
          </div>
        </div>

        {this.state.deleting !== null && this.state.editing === null ? (<Modal />) : null}

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.rSupplier.data,
    currency_data: state.rBasicData.currency_data,
    payment_methods: state.rBasicData.payment_method_data,
    country: state.rBasicData.country,
    payment_conditons: state.rBasicData.payment_condition_data,
    delivery_conditons: state.rBasicData.delivery_condition_data,
    token: state.rLogin.token,
    isLoading: state.rLogin.loading,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    accounts: state.rAccounts.accounts,
    banksData: state.rBanks.banksData,
    branchesData: state.rBanks.branchesData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSupplierData: token => { dispatch(getSupplierData(token)) },
    getCurrencyData: token => { dispatch(getCurrencyData(token)) },
    getPaymentMethodData: token => { dispatch(getPaymentMethodData(token)) },
    getCountry: token => { dispatch(getCountry(token)) },
    getPaymentCondition: token => { dispatch(getPaymentCondition(token)) },
    getDeliveryCondition: token => { dispatch(getDeliveryCondition(token)) },
    addSupplier: (state, token) => { dispatch(addSupplier(state, token)) },
    updateSupplier: (data, token) => { dispatch(updateSupplier(data, token)) },
    deleteSupplier: (data, token) => { dispatch(deleteSupplier(data, token)) },
    getAccountsData: token => { dispatch(getAccountsData(token)) },
    getBanksData: (token) => { dispatch(getBanksData(token)) },
    getBranchesData: (token) => { dispatch(getBranchesData(token)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);


