/**
 * @file This file defined for Delivered Overview
 * @author Umesh Ranthilina
 */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getDeliveryDataForConfirmation, confirmDelivery, confirmMany, updateConfirm } from "../../../actions/Delivery/delivery_action";
import moment from "moment";
import Table from "react-table";
import { toast } from 'react-toastify';
import Permission from "../../utils/Permission";
import { Field } from "redux-form";
import ActionsCell from "../../components/UpdateActionsCell";
import * as BS from "react-bootstrap";
import HighlightCell from "../../components/HighlightCell";

import GridFilter from '../../components/GridFilter';
import FormProvider from "../../components/FormProvider";
import { DateNumberFormat } from '../../utils';
import { PriceFormat } from '../../utils';

import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";

import checkboxHOC from "react-table/lib/hoc/selectTable";
const CheckboxTable = checkboxHOC(Table);

export class DeliveredConfirmation extends Component {

    creditCardType = [
        { '_id': '', 'name': 'None' },
        { '_id': 'Visa', 'name': 'Visa' },
        { '_id': 'Mastercard', 'name': 'Mastercard' },
        { '_id': 'Other', 'name': 'Other' },
    ];

    state = {
        fromDate: '',
        toDate: '',
        editing: null,
        deleting: null,
        status: '',
        active: false,
        deliveryStatus: '',
        selection: [],
        selectAll: false,
        notConfirmedDeliveredData: [],
        count: 0,
        creditCardType: '',
        creditCardNo: '',
        disableConfirm: false
    }

    toggleSelection = (key, shift, row) => {
        // start off with the existing state
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }
        // update the state
        this.setState({ selection, count: selection.length });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(item._original._id);
            });
        }
        this.setState({ selectAll, selection, count: selection.length });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    logSelection = () => {
        console.log("selection:", this.state.selection);
    };

    componentDidMount() {
        let Dates = new Date();
        this.setState({
            fromDate: moment(Dates.setMonth(Dates.getMonth() - 1)).format("YYYY-MM-DD"),
            toDate: moment(Date.now()).format("YYYY-MM-DD"),
        })
        this.props.getDeliveryDataForConfirmation({ from: moment(Dates.setMonth(Dates.getMonth() - 0)).format("YYYY-MM-DD"), to: moment(Date.now()).format("YYYY-MM-DD") }, this.props)
    }

    onChangeDateValue = (e, name) => {
        if (name === "fromDate") {
            let fromDateFormatted = moment(e.target.value).format("YYYY-MM-DD");
            this.setState({
                fromDate: fromDateFormatted,
            })
        }
        if (name === "toDate") {
            let ToDateFormatted = moment(e.target.value).format("YYYY-MM-DD");
            this.setState({
                toDate: ToDateFormatted,
            })
        }
    }

    confirmDelivery = (orderId) => {
        let data = {
            'orderId': orderId,
            'deliveryConfirmUser': this.props.userId
        }

        let dates = { from: this.state.fromDate, to: this.state.toDate }

        this.props.confirmDelivery(data, dates, this.props.token)
    }

    reload = () => {
        this.props.getDeliveryDataForConfirmation({ from: this.state.fromDate, to: this.state.toDate }, this.props)
        this.filterPaymentDetailData()
        this.setState({disableConfirm: false})
    }

    confirmMany = () => {
        if (this.state.selection.length > 0) {
            this.setState({disableConfirm: true})

            if (this.props.delivereyDataForConfirmationActive.length > 0) {
                const filteredData = this.props.delivereyDataForConfirmationActive.filter(element => {
                    return element.paymentmethods !== undefined && element.paymentmethods.name === 'Credit Card on Delivery';
                });

                const matchingOrders = filteredData.filter(element => this.state.selection.includes(String(element.orders._id)));

                const allOrdersValid = matchingOrders.every(order => {
                    const { creditCardNo, creditCardType } = order.orders;

                    return (
                        creditCardNo !== undefined &&
                        creditCardType !== undefined &&
                        creditCardNo !== '' &&
                        creditCardType !== '' &&
                        creditCardType !== null &&
                        /^\d{4}$/.test(String(creditCardNo))
                    );
                });

                if (allOrdersValid) {
                    let data = {
                        'orderIdsArray': this.state.selection,
                        'orderIdsArray': this.state.selection,
                        'deliveryConfirmUser': this.props.userId,
                        'creditCardNo': this.state.creditCardNo,
                        'creditCardType': this.state.creditCardType
                    }
                    let dates = { from: this.state.fromDate, to: this.state.toDate }
                    this.props.confirmMany(data, dates, this.props.token)
                    this.closeConfirmPopup()
                } else {
                    toast.error('Please check the credit card information...')
                }
            }
        } else {
            toast.error('Please select some to continue...')
        }
    }

    closeConfirmPopup = () => {
        this.setState({
            selection: [],
            count: 0
        })
        let checkboxes = document.querySelectorAll('.confirm-checkbox');

        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });

        window.$('#confrimDelivery').modal('hide');
    }

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => this.setState({ editing: rowProps.original }),
                onCancel: () => this.setState({ editing: null }),
            },
            updPerm: Permission('DELIVERY', 'DELIVERY CONFIRMATION', 'UPDATE', rowProps.original.paymentmethods === 'Credit Card on Delivery' ? this.props : false && this.props),
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === 'Credit Card Type') {
            this.setState({ creditCardType: value })
        }
    }

    selectableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = this.state.editing === props.original;

            return (edit ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {
                    (
                        this.creditCardType
                    ).map(data =>
                        <option key={data._id} value={data._id}>{data.name}</option>
                    )}
            </Field> : <label>{props.value}</label>)
        }
    };

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const paddedValue = value ? String(value).padStart(4, '0') : '';
        const children = (!editing && <HighlightCell value={paddedValue} {...rest} />) || undefined;
        return <Component style={{ textAlign: 'right' }}  type="number" min="0" max="9999" required  {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    columnsActive = [
        { Header: "Order Id", accessor: "orderId", ...GridFilter },
        { Header: "Customer", accessor: "name", ...GridFilter },
        { Header: "Address", accessor: "address", ...GridFilter },
        { Header: "Region", accessor: "region", ...GridFilter },
        { Header: "Rider", accessor: "deliveryPerson", ...GridFilter },
        { Header: "Order Status", accessor: "status", ...GridFilter },
        { Header: "Delivery Status", accessor: "deliveryStatus", ...GridFilter },
        { Header: "Payment Method", accessor: "paymentmethods", ...GridFilter },
        { Header: "Pick Up", accessor: "pickup", ...GridFilter },
        { Header: "At Office", accessor: "office", ...GridFilter },
        {
            Header: "Delivered Date/Time", accessor: "deliveryDate", ...GridFilter,
            Cell: props => {
                if (props.original?.deliveryDate !== undefined) {
                    var today = new Date(props.original?.deliveryDate)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.deliveryDate;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        }, {
            Header: "Total Price", accessor: "totalPrice", show: true,
            ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.totalPrice)}
                </div>
        },
        { Header: "Credit Card No (Last 4 digits)", accessor: "creditCardNo", ...this.editableColumnProps },
        { Header: "Credit Card Type", accessor: "creditCardType", ...this.selectableColumnProps },
        {
            Header: "Actions",
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    columnsHistory = [
        { Header: "Order Id", accessor: "orders.orderId", ...GridFilter },
        { Header: "Customer", accessor: "orders.name", ...GridFilter },
        { Header: "Address", accessor: "orders.address", ...GridFilter },
        { Header: "Region", accessor: "region.name", ...GridFilter },
        { Header: "Rider", accessor: "deliveryPerson.name", ...GridFilter },
        { Header: "Order Status", accessor: "orders.status", ...GridFilter },
        { Header: "Delivery Status", accessor: "orders.deliveryStatus", ...GridFilter },
        { Header: "Payment Method", accessor: "paymentmethods.name", ...GridFilter },
        { Header: "Pick Up", accessor: "pickup", ...GridFilter },
        { Header: "At Office", accessor: "office", ...GridFilter },
        {
            Header: "Delivered Date/Time", accessor: "orders.deliveryDate", ...GridFilter,
            Cell: props => {
                if (props.original?.orders?.deliveryDate !== undefined) {
                    var today = new Date(props.original?.orders?.deliveryDate)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.orders?.deliveryDate;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Total Price", accessor: "orders.totalPrice", show: true,
            ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.orders.totalPrice)}
                </div>
        },
        {
            Header: "Confirmed Date ", accessor: "orders.deliveryConfirmationDate", ...GridFilter,
            Cell: props => {
                if (props.original?.orders?.deliveryConfirmationDate !== undefined) {
                    var today = new Date(props.original?.orders?.deliveryConfirmationDate)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.orders?.deliveryConfirmationDate;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        { Header: "Confirmed User", accessor: "confirmUser.name.first", ...GridFilter },
        { Header: "Credit Card No (Last 4 digits)", accessor: "orders.creditCardNo", ...GridFilter },
        { Header: "Credit Card Type", accessor: "orders.creditCardType", ...GridFilter },
        {
            Header: "IsOdoo", accessor: "orders.isOdooStatus", ...GridFilter,
        },
    ];


    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    filterPaymentDetailData = () => {
        var finalFiltered = [];

        if (this.props.delivereyDataForConfirmationActive.length > 0) {
            this.props.delivereyDataForConfirmationActive.map(element => {
                var objToPush = {
                    '_id': element.orders._id,
                    'orderId': element.orders.orderId,
                    'name': element.orders.name,
                    'address': element.orders.address,
                    'region': element.region !== undefined ? element.region.name : '',
                    'deliveryPerson': element.deliveryPerson !== undefined ? element.deliveryPerson.name : '',
                    'status': element.orders.status,
                    'deliveryStatus': element.orders.deliveryStatus,
                    'paymentmethods': element.paymentmethods !== undefined ? element.paymentmethods.name : '',
                    'pickup': element.pickup,
                    'office': element.office,
                    'deliveryDate': element.orders.deliveryDate !== undefined ? element.orders.deliveryDate : undefined,
                    'totalPrice': element.orders.totalPrice,
                    'creditCardNo': element.orders.creditCardNo,
                    'creditCardType': element.orders.creditCardType
                };

                finalFiltered.push(objToPush);
            })
        }

        this.setState({
            notConfirmedDeliveredData: finalFiltered,
            disableConfirm: false
        });
    }

    handleSubmit = values => {
        if (this.state.editing !== null) {
            this.setState({
                creditCardType: values.creditCardNo,
                creditCardType: values.creditCardType,
            })
            let data = {
                'CardNo': values.creditCardNo,
                'CardType': values.creditCardType,
                'user': this.props.userId,
                '_id': values._id,
                'deliveryStatus': values.deliveryStatus,
                'paymentmethods': values.paymentmethods,
                'status': values.status,
            }

            if (values.creditCardNo == ''
                || values.creditCardType == ''
            ) {
                toast.error('Please fill up the mandatory fields!')
            } else if (values.creditCardNo.length !== 4) {
                toast.error('Credit Card No must be 4 digit number!')
            } else {
                let dates = { from: this.state.fromDate, to: this.state.toDate }
                this.props.updateConfirm(data, dates, this.props.token)
                setTimeout(() => {
                    this.filterPaymentDetailData()
                }, 2000);
            }
        }
    };

    render() {

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;

        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox"
        };

        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <div style={{ display: 'flex' }}>
                                    <div className="h6">From :</div>
                                    <div><input className="form-control" type="date" defaultValue={this.state.fromDate}
                                        onChange={e => {
                                            this.onChangeDateValue(e, "fromDate")
                                        }} />
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="h6">To :</div>
                                    <div><input className="form-control" type="date" defaultValue={this.state.toDate}
                                        onChange={e => {
                                            this.onChangeDateValue(e, "toDate")
                                        }} />
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div style={{ display: 'flex' }}>
                                        <button className='btn btn-primary' onClick={() => this.props.getDeliveryDataForConfirmation({ from: this.state.fromDate, to: this.state.toDate }, this.props)}>Search</button>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div style={{ display: 'flex' }}>
                                        <button
                                            className='btn btn-primary'
                                            data-toggle='modal'
                                            data-target='#confrimDelivery'
                                            onClick={e => this.filterPaymentDetailData()}
                                        >
                                            Confirm
                                        </button>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <FormProvider
                            form="inline"
                            enableReinitialize

                        >
                            {formProps => {
                                return (
                                    <form>
                                        <Table className="-striped"
                                            columns={this.columnsHistory}
                                            data={this.props.delivereyDataForConfirmationHistory}
                                            defaultPageSize={10}
                                            defaultFilterMethod={this.filterCaseInsensitive}
                                            showPageJump={false}
                                            getTrProps={() => ({
                                                style: {
                                                    height: '50px',
                                                },
                                            })}
                                        />
                                    </form>
                                );
                            }}
                        </FormProvider>
                    </div>

                    <div id="confrimDelivery" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" style={{ width: '164%', left: '-32%' }}>
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Confirm Delivery</h4>
                                    <div style={{ float: 'right' }}>
                                        <button className="btn btn-primary" onClick={() => this.reload()}>
                                            <i className="fas fa-sync"></i>
                                        </button>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>


                                </div>
                                <div className="modal-body">
                                    <form id='confrimDeliveryForm'>
                                        {this.state.count > 0 &&
                                            <table style={{ 'width': '100%' }}>
                                                <tr>
                                                    <td style={{ 'width': '15%', 'fontSize': '15px', 'fontWeight': 'bold' }}>{this.state.count + ' Orders Selected'}</td>
                                                </tr>
                                            </table>
                                        }
                                        <div className="card-body">
                                            <FormProvider
                                                form="inline"
                                                onSubmit={this.handleSubmit}
                                                onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                enableReinitialize

                                            >
                                                {formProps => {
                                                    return (
                                                        <form onSubmit={formProps.handleSubmit}>
                                                            <CheckboxTable className="-striped"
                                                                ref={(r) => (this.checkboxTable = r)}
                                                                columns={this.columnsActive}
                                                                data={this.state.notConfirmedDeliveredData}
                                                                defaultPageSize={10}
                                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                                showPageJump={false}
                                                                {...checkboxProps}
                                                            />
                                                        </form>
                                                    );
                                                }}
                                            </FormProvider>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" disabled={this.state.disableConfirm} onClick={e => this.confirmMany()}>Confirm</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.closeConfirmPopup()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
const mapStateToProps = state => ({
    token: state.rLogin.token,
    delivereyDataForConfirmationActive: state.rDeliveryBoys.delivereyDataForConfirmationActive,
    delivereyDataForConfirmationHistory: state.rDeliveryBoys.delivereyDataForConfirmationHistory,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    userId: state.rLogin.id,
    isLoading: state.rLogin.loading,
})

const mapDispatchToProps = dispatch => ({
    getDeliveryDataForConfirmation: (data, props) => { dispatch(getDeliveryDataForConfirmation(data, props)) },
    confirmDelivery: (data, dates, token) => { dispatch(confirmDelivery(data, dates, token)) },
    confirmMany: (data, dates, token) => { dispatch(confirmMany(data, dates, token)) },
    updateConfirm: (data, dates, token) => { dispatch(updateConfirm(data, dates, token)) }
})


export default connect(mapStateToProps, mapDispatchToProps)(DeliveredConfirmation);
