/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const disposedItemsDataAsync = data => {
    return { type: 'FETCH_DISPOSED_ITEMS_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
export const getDisposedItemsData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/pos/getDisposedItemsData')
            .then(result => {
                dispatch(disposedItemsDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const cancelDisposedItem = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/cancelDisposedItem', { data })
            .then(result => {
                toast.success(result.data.message)
                dispatch(getDisposedItemsData())
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const approveDisposedItem = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/approveDisposedItem', { data })
            .then(result => {
                toast.success(result.data.message)
                dispatch(getDisposedItemsData())
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}




