
import _ from 'lodash';

/**
 * @type {Object}
 * @property {array}  accounts  Accounts data
 */
const initialState = {
    accounts: [],
    transactions: [],
    customerPayable: [],
    cashPayableAccounts: [],
    cashExpnsesAccounts: [],
    accountsForTrans: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerAccounts = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_ACCOUNTS_DATA':
            var { data } = action;
            newState.accounts = data;

            var cashPayableAccounts = [];
            var accountsForTrans = [];

            data.map(account => {
                if (account.type === 'Cash' || account.type === 'Payable') {
                    var balance = account.balance < 0 ? '0' : account.balance;
                    var obj = {
                        '_id': account._id + '-' + balance + '-' + account.type,
                        'label': account.acc_name
                    };
                    cashPayableAccounts.push(obj);
                }

                var obj1 = {
                    '_id': account._id + '-' + account.acc_name + ' - ' + account.type,
                    'label': account.acc_name
                }

                accountsForTrans.push(obj1)
            })

            newState.cashPayableAccounts = cashPayableAccounts;
            newState.accountsForTrans = accountsForTrans

            var cashExpnsesAccounts = _.filter(data, (account) => {
                return account.type === 'Cash' || account.type === 'Expenses'
            })

            newState.cashExpnsesAccounts = cashExpnsesAccounts;

            break;

        case 'FETCH_TRANSACTIONS_DATA':
            var { data } = action;
            newState.transactions = data;
            break;

        case 'FETCH_PAYABLE_DATA':
            var { data } = action;
            newState.customerPayable = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerAccounts;