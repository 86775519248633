/**
 * @file This file defined for coRegion model component
 * @author Bhanuka Chathuranga
 */
import React, { useEffect, lazy, Suspense } from 'react';
import { DateStringFormat, WIDTH } from '../../../utils';
const RegionButton = lazy(() => import('./RegionButton'));

const Spinner = () => {
    return <div className="spinner-grow modal-content animate" style={{ display: 'flex', left: '25%', width: '10rem', height: '10rem', color: '#f74b78' }} role="status">
        <span className="sr-only">Loading...</span>
    </div>
}

/**
 * 
 * @param {Object} obj
 * @param {Array} obj.deliveryRegion delivery region data
 * @param {Object} obj.coRegion customer selected region
 * @param {Function} obj.setCoRegion changed customer selected region
 * @param {Boolean} obj.isLoading check data is loding or not 
 * @returns {Component} coRegion model componet
 */
function CoRegionModel({ deliveryRegion, minOrder, coRegion, setCoRegion, deliDate, setDeliDate, isLoading }) {

    // const [deliDate, setDeliDate] = useState([]);

    useEffect(() => {
        let data = coRegion?.delivery?.filter(ele => {
            // let [h, m] = ele.region.cutOffTime.split(':')
            let string = `${ele.region.cutOffDate}T${ele.region.cutOffTime}:00+05:30`
            let cutOff = new Date(string)
            // cutOff.setHours(h, m)
            return new Date() < new Date(cutOff)
        })
        setDeliDate(data)
    }, [coRegion])

    return (
        <div id='customer_region' className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className={WIDTH < 510 ? "modal-dialog modal-lg" : "modal-dialog"}
                style={{ position: WIDTH < 510 ? '' : 'absolute', left: WIDTH < 510 ? '47%' : '50%', top: WIDTH < 510 ? '60%' : '50%', transform: WIDTH < 510 ? 'translate(-50%, -60%)' : 'translate(-50%, -75%)' }}
            > {/* modal-dialog-centered */}

                <Suspense fallback={<Spinner />}>
                    {/* {true ? */}
                    {isLoading ?
                        <Spinner />
                        : <div className="modal-content animate">
                            <div className='modal-header'>
                                <h5 className="modal-title text-uppercase font-style spacing-1" style={{ color: '#333233b5', fontWeight: 'bold' }}>PLEASE SELECT YOUR DELIVERY REGION</h5>
                                {/* <button type="button" className="close" data-dismiss="modal">&times;</button> */}
                            </div>
                            <div className='modal-body'>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                                    <RegionButton deliveryRegion={deliveryRegion} coRegion={coRegion} setCoRegion={setCoRegion} />

                                    {/* region delivery chargers */}
                                    {coRegion ?
                                        <div style={{ fontSize: '16px' }}>
                                            Our minimum order value is Rs. {minOrder?.value}. {(coRegion && coRegion?.deliveryCharges !== 0) ? `A rate of Rs. ${coRegion?.deliveryCharges || 0} will be charged for all deliveries less than Rs.${coRegion?.thresholdValue || 0}.` : ''}
                                        </div>
                                        : null
                                    }

                                    {deliDate ?
                                        deliDate.length > 0 ?
                                            <div style={{ paddingTop: '3%', fontWeight: 'bold', fontSize: '16px' }}>
                                                Sheduled delivery date is {DateStringFormat(deliDate[0]?.day)}
                                            </div>
                                            : <div style={{ paddingTop: '3%' }}>
                                                No delivery date specified for this region
                                            </div>
                                        : null}
                                </div>
                                {coRegion && deliDate ?
                                    deliDate.length > 0 ?
                                        <div className='modal-footer btn purchase' style={{ height: '70px' }}>
                                            <button type="button" className="btn-purchase" disabled={isLoading || coRegion === null} data-dismiss="modal">Order Here...!</button>
                                        </div>
                                        : null
                                    : null
                                }
                            </div>
                        </div>}
                </Suspense>

            </div>
        </div>
    )
}

export default CoRegionModel
