/**
 * @type {Object}
 * @property {array}  data  Product data
 */
 const initialState = {
    missingValue: [],
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerDashboard = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_MISSING_VALUE':
            var { data } = action;
            newState.missingValue = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerDashboard;