/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const notificationsAsync = data => {
    return { type: 'FETCH_NOTIFICATIONS', data }
}

/**
 * 
 * @param {*} token 
 */
export const getNotifications = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/administrator/notifications/getNotifications')
            .then(result => {
                dispatch(notificationsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateNotification = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/notifications/updateNotification', { data })
            .then(result => {
                toast.success('Successfully Updated Notification!')
                dispatch(getNotifications(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteNotification = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/administrator/notifications/deleteNotification', { data })
            .then(result => {
                toast.success('Notification Deleted Successfully!')
                dispatch(getNotifications(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addNotification = ({ name, description, type, message, value, isActive, slug }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/administrator/notifications/addNotification', {
            data: {
                name,
                description,
                type,
                message, 
                value,
                isActive,
                slug
            }
        })
            .then(result => {
                window.$('#addNotification').modal('hide');
                toast.success('Successfully Added Notification!');
                dispatch(getNotifications(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}