/**
 * @file This file defined for create customer order
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import _ from 'lodash';
import './index.css';

import 'react-phone-input-2/lib/bootstrap.css'
import { DateStringFormat, PriceFormat, WIDTH } from '../../utils'
import SkeltonDiv from '../../components/skeleton/SkeltonDiv'
import SkeltonBtn from '../../components/skeleton/SkeltonBtn'
import SkeltonCircle from '../../components/skeleton/SkeltonCircle'

import { ItemCard, ToastHtml, Button, DeliveryRegionModel, CoRegionModel, CartTable } from './components'
const Brand = lazy(() => import('./components/Brand'))
const Item = lazy(() => import('./components/Item'));
const SearchBox = lazy(() => import('./components/SearchBox'))

const createOrder = (item, order, setIsLoading, setNotify, props) => {
    axios.post('/customerOrders/createOrder', { order })
        .then(result => {
            // console.log(result);
            window.$('#customerOrder').modal('toggle')
            localStorage.setItem('order', JSON.stringify(item))

            if (navigator.onLine) {
                if (result.status === 200 && result.statusText === "OK" && result.data === "The order price calculated successfully...!") {
                    // window.location.replace(`${origin}/customerOrder/confirmation`)
                    props.history.push('/onlinepurchase/confirmation')
                    setIsLoading(false)
                } else if (result.status === 200 && result.statusText === "OK" && result.data?.data === 'App is offline') {
                    setNotify(result.data.notify[0])
                    setIsLoading(false)
                }
            }

        })
        .catch(err => {
            setIsLoading(false)
            console.log('error');
            // console.error(err.message);
            if (err.message === 'Network Error') {
                toast.error(err.message)
                props.history.push('/onlinepurchase')
            }
        })
}

const purchaseItem = (item, setIsLoading, setNotify, token, props, statusModel) => {

    let isFastDeliveryStatus = item.orderItems.every(o => o.is_fast_delivery === true)
    if (isFastDeliveryStatus === false) {
        item.orderItems.map(val => {
            if (val.is_fast_delivery) {
                val.discount = val.discountPercentage
            }
        })
    }

    if (navigator.onLine) {
        setIsLoading(true)
        axios.defaults.headers.common['authorization'] = token;
        // console.log(item);
        // var phoneno = /^(7)+\d{8}$/;
        let order = { ...item }
        let tele = item.telephone
        if (tele.length === 13) {
            let valTele = tele.toString().slice(4)
            let valWhatsApp = tele.toString().slice(2)
            order.telephone = `${valTele}`
            order.whatsapp = `+${valWhatsApp}`
        } else if (tele.length === 11) {
            let val = tele.toString().slice(2)
            order.telephone = `${val}`
            order.whatsapp = `+${tele}`
        }
        var phoneno = /^[1-9]\d{10,14}$/;
        if (item.name.toString().trim() !== '' && item.address.trim() !== '' && tele.match(phoneno) && item.paymentOption !== '') {
            if (statusModel) {
                createOrder(item, order, setIsLoading, setNotify, props)
            }
        } else {
            setIsLoading(false)
            if (item.name.toString().trim() === '') {
                window.$('#name').addClass('border-danger')
            }

            if (item.address.trim() === '') {
                window.$('#address').addClass('border-danger')
            }

            // if (item.telephone.length !== 11) {
            //     toast.error('Please check your phone number')
            // }

            if (!item.telephone.match(phoneno)) {
                toast.error('Incorrect phone number...!')
            }

            if (item.paymentOption === '') {
                window.$('#paymentOption').addClass('border-danger')
            }
        }
    } else {
        toast.error('No Internet Connection...!')
    }

}

/**
 * To handle input
 * 
 * @param {Function} setAddOrder to set add order details
 * @param {Object} addOrder order details
 * @param {String} field input field name
 */
const inputHandler = ({ target: { value } }, setAddOrder, addOrder, field) => {
    window.$(`#${field}`).removeClass('border-danger')
    setAddOrder({ ...addOrder, [field]: value })
}

/**
 * To handle purchase 
 * 
 * @param {Array} cart cart items details
 * @param {Object} addOrder add order details
 * @param {Function} setAddOrder set data to add order
 * @param {Object} notify app status
 */
const purchaseHandler = (cart, addOrder, setAddOrder, notify, fastDelivery, fastDeliveryMix, coRegion, fastDeliveryCharge) => {
    // if (navigator.onLine) {
    if (cart.length > 0 && notify.value !== 'YES') {
        let isFastDeliveryStatus = cart.every(o => o.is_fast_delivery === true)

        const total = Number(cart.reduce((total, obj) => { return total + ((obj.price - (obj.price * (((obj.fastDiscountValue.length > 0 && obj.is_fast_delivery && isFastDeliveryStatus === true) ? obj.fastDiscountValue[0] : obj.discountPercentage) / 100))) * obj.qty) }, 0))
        let deliveryCharges = total < coRegion.thresholdValue ? coRegion.deliveryCharges : 0
        let not_fast_delivery = _.filter(cart, obj => !obj.is_fast_delivery)
        var isFastDelivery = not_fast_delivery.length === 0 && coRegion.name === 'Colombo'

        // console.log(not_fast_delivery, cart);
        if (not_fast_delivery.length > 0 && not_fast_delivery.length < cart.length) {
            // let itemString = not_fast_delivery.reduce((total, ele) => { return `${total}${total === '' ? '' : ','} ${ele.name}` }, '')
            toast.error(<ToastHtml text={fastDeliveryMix?.value} />, {
                className: 'background-toast',
                position: "bottom-left",
                autoClose: false,
                closeButton: true,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            })
        } else if (not_fast_delivery.length === 0) {
            toast.error(<ToastHtml text={fastDelivery?.value} />, {
                className: 'background-toast',
                position: "bottom-left",
                autoClose: false,
                closeButton: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
            })

            deliveryCharges = coRegion?.name === 'Colombo' ? fastDeliveryCharge : 0
        }

        let randomNumber = Math.floor(Math.random() * 10000 + 1);
        let orderId = new Date().toISOString().slice(0, 10) + "-" + randomNumber;
        let allCookie = document.cookie;
        // var name, address = ''
        // if(allCookie)
        let [[, name = ''], [, address = ''], [, telephone = ''], ...rest] = allCookie === '' ? [['', ''], ['', ''], ['', '']] : document?.cookie?.split(';')?.map(obj => obj?.split("="))
        // let [, name] = nameId.split("=");
        // console.log(name);
        setAddOrder({ ...addOrder, orderId, name, address, telephone, isFastDelivery, deliveryCharges, orderItems: cart })
    } else {
        setAddOrder({ ...addOrder, orderItems: cart })
    }
    // }
}

/**
 * To filter out search data for dropdown
 * @param {String} value input value
 * @param {Array} itemData items data 
 * @param {Functions} setSearch To change search state
 * @param {Functions} setSearchVal To change searchVal state 
 */
const searchHandler = ({ target: { value } }, { itemData }, { setBrandsItemsData, setBrands, setSearch, setSearchVal, setId, setSearchData }) => {

    // console.log(itemData);
    // value?.toString().split(new RegExp(`(${escapeRegExp(match)})`, "ig"))
    let uniqItem = _.uniqBy(itemData, '_id')
    if (value.trim() !== '') {
        const filterItems = uniqItem.filter(obj => { if (obj.name.toLowerCase().includes(value.toLowerCase()) || obj.desc.toLowerCase().includes(value.toLowerCase())) { return obj } })
        setSearch(filterItems)
        setSearchVal(value)
        if (filterItems.length > 0) {
            dropdownHandler(true)
        } else {
            dropdownHandler(false)
        }
    } else {
        setSearch([])
        setSearchVal('')
        setId('');
        setSearchData({ result: [], brands: [], items: [] });
        dropdownHandler(false)
    }
}

/**
 * To get item according to the input
 * @param {String} value input value
 * @param {Array} itemData items data
 * @param {Functions} setSearchVal To change searchVal state 
 * @param {Functions} setSearchData To change searchData state
 * @param {Functions} setId To change chage id state
 */
const searchOkHandler = (value, { itemData }, { setBrandsItemsData, setBrands, setSearch, setSearchVal, setSearchData, setId }) => {
    const dropdown = document.querySelector('.dropdown-1')
    dropdown.classList.toggle('active-dropdown-1')

    if (value.trim() !== '') {
        const filterItems = itemData.filter(obj => { if (obj.name.toLowerCase().includes(value.toLowerCase()) || obj.desc.toLowerCase().includes(value.toLowerCase())) { return obj } })
        const filterBrands = itemData.filter(obj => { if (obj.brands.toLowerCase().includes(value.toLowerCase())) { return obj } })
        let filterVal = filterItems.concat(filterBrands)
        let uniqBrand = _.uniqBy(filterVal, 'brands')
        let sortBrand = _.sortBy(uniqBrand, ['sortOrder'])
        let uniqItems = _.uniqBy(filterItems, 'itemCode')
        setSearchData({ result: filterVal, brands: sortBrand, items: uniqItems })
        setId('')
        setSearchVal(value)
        dropdownHandler(false)
    }
}

/**
 * To handle dropdown
 * @param {Boolean} show dropdown want to show or not
 */
const dropdownHandler = show => {
    const dropdown = document.querySelector('.dropdown-1');
    if (show) {
        dropdown.classList.add('active-dropdown-1')
    } else {
        dropdown.classList.remove('active-dropdown-1')
    }
}

const tryAgainHandler = (addOrder, cart, setAddOrder) => {
    setAddOrder({ ...addOrder, orderItems: cart })
}

// const width = window.innerWidth;

export const CustomerOrder = props => {

    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isOnline, setIsOnline] = useState(true);
    const [notify, setNotify] = useState('NO');
    const [deiliveryMsg, setDeiliveryMsg] = useState('');
    const [deliveryRegion, setDeliveryRegion] = useState([]);
    const [minOrderValue, setMinOrderValue] = useState();
    const [fastDelivery, setFastDelivery] = useState();
    const [fastDeliveryMix, setFastDeliveryMix] = useState();
    const [fastDeliveryCharge, setFastDeliveryCharge] = useState();
    const [itemData, setItemData] = useState([])
    const [search, setSearch] = useState([]);
    const [searchVal, setSearchVal] = useState('')
    const [searchData, setSearchData] = useState({ result: [], brands: [], items: [] });
    const [brands, setBrands] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [id, setId] = useState('');
    const [brandsItemsData, setBrandsItemsData] = useState([])
    const [cart, setCart] = useState([])
    const [qty, setQty] = useState(0)
    const [selectId, setSelectId] = useState('')
    const [addOrder, setAddOrder] = useState({ orderId: '', address: '', telephone: '', whatsapp: '', name: '', user: '', billingAddress: '5e86e66fd6a6c459caf4a757', orderPickUp: false, deliveryAddress: '5e86e66fd6a6c459caf4a757', city: '', region: '', paymentOption: '', deliveryCharges: 0, orderItems: '', note: '', isFromMobile: true, isFastDelivery: false })
    const [coRegion, setCoRegion] = useState(null);
    const [deliDate, setDeliDate] = useState([]);
    const [popupMessage, setPopUpMessage] = useState([]);
    const [popupMessageValue, setPopUpMessageValue] = useState('');

    useEffect(() => {
        window.$('#customer_region').modal('show');
        setIsLoading(true)
        axios.get('/customerOrders/getDetails')
            .then(result => {
                // console.log(result);
                let { item, message: { address, paymentOption, notify, min_amount, delivery_charge_help, delivery_charges, fast_delivery, fast_delivery_mix, fast_delivery_charge, whatsapp_msg, popup_message } } = result.data
                let uniqBrand = _.uniqBy(result.data.item, 'brands')
                let sortBrand = _.sortBy(uniqBrand, ['sortOrder'])
                let lines = popup_message.value.split(/,|\n/);
                delivery_charges = delivery_charges.sort((obj1, obj2) => obj1.id.length - obj2.id.length)
                setAddOrder({ ...addOrder, billingAddress: address[0]._id, deliveryAddress: address[0]._id })
                setBrands(sortBrand)
                setPaymentMethods(paymentOption)
                setItemData(item)
                setToken(result.headers.authorization)
                setNotify(notify)
                setDeiliveryMsg(delivery_charge_help)
                setMinOrderValue(min_amount)
                setFastDelivery(fast_delivery)
                setFastDeliveryMix(fast_delivery_mix)
                setFastDeliveryCharge(Number(fast_delivery_charge))
                setDeliveryRegion(delivery_charges)
                localStorage.setItem('token', result.headers.authorization)
                localStorage.setItem('msg', whatsapp_msg)
                setIsLoading(false)
                setPopUpMessage(popup_message)
                setPopUpMessageValue(lines)
            }).catch(err => {
                console.error(err);
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        // console.log(navigator.onLine);
        setIsOnline(navigator.onLine)
        if (!navigator.onLine) {
            document.getElementById('btnOrderNow').disabled = true
            toast.info('No Internet connection')
        }
        if (navigator.onLine) document.getElementById('btnOrderNow').disabled = false
    }, [navigator.onLine])

    useEffect(() => {
        if (notify.value === 'YES') {
            toast.error(`${notify.message}`, {
                className: 'background-toast',
                position: "bottom-left",
                autoClose: false,
                closeButton: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            })
        }
    }, [notify])

    useEffect(() => {
        if (id === 'Fast Delivery') {
            toast.error(<ToastHtml text={fastDelivery?.value} />, {
                className: 'background-toast',
                position: "bottom-left",
                autoClose: false,
                closeButton: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
            })
        }


        var itemsData = (searchVal === '' ? itemData : searchData.result).filter(obj => obj.brands === id)

        itemsData.sort((a, b) => {
            const sortDiscountA = a.sortDiscount;
            const sortDiscountB = b.sortDiscount;

            const supplierNameA = a.supplierName.toLowerCase();
            const supplierNameB = b.supplierName.toLowerCase();

            const brandsA = a.itemBrand.toLowerCase();
            const brandsB = b.itemBrand.toLowerCase();

            const isCIAOBrandA = brandsA.includes('ciao');
            const isCIAOBrandB = brandsB.includes('ciao');

            // Display items with "CIAO" first, then other items
            if (sortDiscountA !== sortDiscountB) {
                return sortDiscountA - sortDiscountB;
            } else if (isCIAOBrandA !== isCIAOBrandB) {
                return isCIAOBrandB - isCIAOBrandA;
            } else if (supplierNameA !== supplierNameB) {
                return supplierNameA.localeCompare(supplierNameB);
            } else {
                return brandsA.localeCompare(brandsB);
            }
        });
        // var sortItemsData = _.sortBy(itemsData, ['sortDiscount', 'asc'])
        setBrandsItemsData(itemsData)
    }, [id])

    useEffect(() => {
        setAddOrder({ ...addOrder, region: coRegion?._id })
    }, [coRegion])


    return (
        <>
            <div className='container background' style={{ overflow: 'hidden' }}>
                {/* nav bar */}
                <div>
                    <div className='header-logo' style={{ display: 'flex', flexDirection: WIDTH < 510 ? 'column' : 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                            <div>
                                <img src={require('../../../images/gusta-logo.png')} alt={'gusta logo'} className='logo-img' />
                            </div>

                            {/* order cycle & delivery charges*/}
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px' }}>
                                <div style={{ position: 'relative', width: '40px', height: '40px', background: '#fff', borderRadius: '60px', transition: '0.5s', boxShadow: '0 0 0 1px #333233b5' }}>
                                    <div className='icon' data-toggle="modal" data-target="#delivery_region">
                                        <img alt={'delivery_image'} src={require('../../../images/fastDelivery.png')} style={{ width: '25px', height: '25px', filter: 'grayscale(100%)' }} />
                                    </div>
                                </div>
                            </div>
                            {/* order cycle &delivery charges*/}
                        </div>

                        {WIDTH < 510 ? <div id='splitter' class="w-100 d-block d-md-none"></div> : <></>}
                        <div id='search-section' style={{ display: 'flex' }}>
                            {/* search area */}
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', width: '100%' }}>
                                <Suspense fallback={<SkeltonCircle height={'40px'} width={'40px'} />}>
                                    <SearchBox searchVal={searchVal} itemData={itemData} search={search} dropdownHandler={dropdownHandler}
                                        searchHandler={searchHandler} searchOkHandler={searchOkHandler} setBrandsItemsData={setBrandsItemsData}
                                        setBrands={setBrands} setSearch={setSearch} setSearchVal={setSearchVal} setSearchData={setSearchData} setId={setId} />
                                </Suspense>
                            </div>
                            {/* search area */}
                        </div>

                    </div>

                    <div className='d-none d-sm-block d-block bar'>
                        <div id='showCart' className='cartSection' onClick={() => {
                            // console.log('cart');
                            // window.$('#customerOrder').modal('toggle')
                            document.getElementById('brands').className = 'col-sm d-none d-sm-none d-md-block paper'
                            document.getElementById('cart').className = 'row paper'
                            document.getElementById('showCart').className = 'd-none cartSectionClose'
                            document.getElementById('closeCart').className = 'cartSection'
                            document.getElementById('item-section').classList.remove('row-padding')
                            document.getElementById('search-section').style.display = 'none'
                        }}>
                            <i className="fa fa-shopping-cart" style={{ fontSize: '25px', color: '#333233b5' }}></i>
                            <span className='badge'>{cart.length || 0}</span>
                        </div>

                        <div id='closeCart' className='d-none cartSectionClose' onClick={() => {
                            // console.log('cart');
                            // window.$('#customerOrder').modal('toggle')
                            document.getElementById('cart').className = 'col-sm d-none d-sm-none d-md-block paper'
                            document.getElementById('brands').className = 'paper'
                            document.getElementById('showCart').className = 'cartSection'
                            document.getElementById('closeCart').className = 'd-none cartSectionClose'
                            document.getElementById('item-section').classList.add('row-padding')
                            document.getElementById('search-section').style.display = 'flex'
                        }}>
                            <i className="fa fa-times" style={{ fontSize: '30px', color: '#333233b5' }}></i>
                        </div>
                    </div>


                </div>
                {/* nav bar */}

                <div>
                    <div id='item-section' className='row row-padding'>
                        {/* <div className='col col-sm'> */}
                        {/* items list*/}
                        <div id='brands' className='col-sm paper'>
                            {/* {isLoading ?
                            <div>
                                <Skeleton height={30} count={15}/>
                            </div>
                            :  */}
                            <div className='overflow-scroll'>

                                <div id='main' style={{ flexDirection: 'column', width: '100%' }}>

                                    {/* search item data */}
                                    {searchVal === '' ? [] : searchData.items.map(item => {
                                        return (item.is_fast_delivery && item.brands === 'Fast Delivery') || (!item.is_fast_delivery) ?
                                            <Suspense fallback={<SkeltonDiv />}>
                                                <div className='items-align'>
                                                    <Item item={item} setQty={setQty} cart={cart} setCart={setCart} itemsData={itemData} />
                                                </div>
                                            </Suspense>
                                            : null
                                    })}
                                    {/* search item data */}

                                    {searchVal === '' ? null : <div style={{ height: '10px' }}></div>}

                                    {/* search brand data */}
                                    {searchVal === '' ? [] : searchData.brands.map(obj => <>
                                        {obj.is_fast_delivery && obj.brands === 'Fast Delivery' && coRegion?.name !== 'Colombo' ? null : <>
                                            <Suspense fallback={<SkeltonBtn />}>
                                                <Brand obj={obj} selectId={selectId} setSelectId={setSelectId} setId={setId} />
                                            </Suspense>

                                            <div id={`id${obj._id}`} className="items-align collapse">
                                                {brandsItemsData?.map(item => {
                                                    return <Suspense fallback={<SkeltonDiv />}>
                                                        <Item item={item} setQty={setQty} cart={cart} setCart={setCart} itemsData={itemData} />
                                                    </Suspense>
                                                }
                                                )}
                                            </div>
                                        </>}
                                    </>
                                    )}
                                    {/* search brand data */}

                                    {/* noraml brands data */}
                                    {searchVal !== '' ? null : brands.map(obj => <>
                                        {obj.is_fast_delivery && obj.brands === 'Fast Delivery' && coRegion?.name !== 'Colombo' ? null : <>
                                            <Suspense fallback={<SkeltonBtn />}>
                                                <Brand obj={obj} selectId={selectId} setSelectId={setSelectId} setId={setId} />
                                            </Suspense>

                                            <div id={`id${obj._id}`} className="items-align collapse">
                                                {brandsItemsData?.map(item => {
                                                    return <Suspense fallback={<SkeltonDiv />}>
                                                        <Item item={item} setQty={setQty} cart={cart} setCart={setCart} itemsData={itemData} />
                                                    </Suspense>
                                                }
                                                )}
                                            </div>
                                        </>}
                                    </>
                                    )}
                                </div>

                            </div>

                        </div>
                        {/* items list*/}

                        {/* cart item */}
                        <div id='cart' className='col-sm d-none d-sm-none d-md-block paper'>

                            <div className='overflow-scroll' style={{ height: '76vh' }}>
                                {cart.map(cartItem => {
                                    let isFastDeliveryStatus = cart.every(o => o.is_fast_delivery === true)
                                    cartItem.fastStatus = isFastDeliveryStatus
                                    cartItem.isCartItem = isFastDeliveryStatus ? false : true

                                    let price = Number(cartItem.price - (cartItem.price * ((cartItem.fastDiscountValue.length > 0 && cartItem.is_fast_delivery && isFastDeliveryStatus === true) ? cartItem.fastDiscountValue[0] : cartItem.discountPercentage) / 100))
                                    return <ItemCard item={cartItem} isFastDeliveryStatus={isFastDeliveryStatus}>
                                        <div className='number' style={{ paddingRight: '20px' }}>{cartItem.priceUnit}</div>
                                        <div className='number' style={{ paddingRight: '20px' }}>{PriceFormat(price)}</div>

                                        <Button symbol={'-'} item={cartItem} setQty={setQty} cart={cart} setCart={setCart} itemsData={itemData} />
                                        <div className='number'>{cartItem.qty}</div>
                                        <Button symbol={'+'} item={cartItem} setQty={setQty} cart={cart} setCart={setCart} itemsData={itemData} />

                                        <button className='btn' data-toggle="tooltip" data-placement="top" title="Delete item" onClick={() => {
                                            let newCart = cart
                                            let check = newCart.filter(checkItem => { return checkItem._id !== cartItem._id })
                                            itemData.filter(item => { if (item._id === cartItem._id) item.qty = 0 })
                                            // console.log(check);
                                            // console.log(newCart);
                                            setCart(check)
                                        }}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                            {/* <i className="fa fa-times-circle" style={{color:'#fff'}} aria-hidden="true"></i> */}
                                        </button>
                                    </ItemCard>
                                })}
                            </div>

                            {/* purchase button */}
                            <div className='btn purchase'>
                                {isLoading ?
                                    null
                                    : <button id='btnOrderNow' className='btn-purchase'
                                        // disabled={isOnline ? notify.value === 'YES' ? true : false : true}
                                        disabled={notify.value === 'YES' ? true : false}
                                        data-toggle="modal" data-target="#customerOrder"
                                        onClick={() => purchaseHandler(cart, addOrder, setAddOrder, notify, fastDelivery, fastDeliveryMix, coRegion, fastDeliveryCharge)}
                                    >Order now</button>}
                            </div>

                        </div>
                        {/* cart item */}
                    </div>
                </div>

            </div>

            {/* model box */}
            <div id='customerOrder' className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase font-style spacing-1" style={{ color: '#333233b5', fontWeight: 'bold' }}>Order details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {notify.value === 'YES' || Number(minOrderValue?.value) > Number(cart.reduce((total, obj) => { return total + ((obj.price - (obj.price * (((obj.fastDiscountValue.length > 0 && obj.is_fast_delivery) ? obj.fastDiscountValue[0] : obj.discountPercentage) / 100))) * obj.qty) }, 0)) ?
                                <div>
                                    <div style={{ color: '#f74b78', fontStyle: 'oblique' }}>{notify.value === 'YES' ? `${notify.message}` : `${minOrderValue?.message}`}</div>
                                    {/* <div>For more info, you may contact us via WhatsApp number {MOBILE_NO}</div> */}
                                    <div className="table-responsive" style={{ height: '30vh' }}>
                                        <table className='table table-bordered' style={{ marginBottom: '0px' }}>
                                            <thead>
                                                <tr>
                                                    <th className='thead'></th>
                                                    <th className='thead'>Item Name</th>
                                                    <th className='thead'>Qty ({_.sumBy(cart, 'qty')})</th>
                                                    <th className='thead' style={{ textAlign: 'right' }}>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart.map((objItem, i) =>
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{objItem.desc || objItem.name}</td>
                                                        <td>{objItem.qty}</td>
                                                        <td><div style={{ float: 'right' }}>{(objItem.qty * (objItem.price - (objItem.price * (((objItem.fastDiscountValue.length > 0 && objItem.is_fast_delivery) && cart.every(o => o.is_fast_delivery === true)
                                                            ? objItem.fastDiscountValue[0] : objItem.discountPercentage) / 100)))).toLocaleString('en', { minimumFractionDigits: 2 })}</div></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={3}>Total Price *</td>
                                                    <td><div style={{ float: 'right' }}>{Number(cart.reduce((total, obj) => { return total + ((obj.price - (obj.price * (((obj.fastDiscountValue.length > 0 && obj.is_fast_delivery && cart.every(o => o.is_fast_delivery === true)) ? obj.fastDiscountValue[0] : obj.discountPercentage) / 100))) * obj.qty) }, 0)).toLocaleString('en', { minimumFractionDigits: 2 })}</div></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                : addOrder.orderItems.length === 0 ?
                                    <div>
                                        Please try again...!
                                    </div>
                                    : <div>
                                        <div className='row padding margin'>
                                            <div className='col-12 col-sm-4'>Name :</div>
                                            {/* <div className='column'><input id='name' type='text' className='form-control' autoComplete={'off'} onChange={e => setAddOrder({ ...addOrder, name: e.target.value })} /></div> */}
                                            <div className='col-12 col-sm-8 remove-right-padding'>
                                                <input id='name' type='text' defaultValue={addOrder.name} className='form-control' autoComplete={'off'} onChange={e => inputHandler(e, setAddOrder, addOrder, 'name')} />
                                            </div>
                                        </div>

                                        <div className='row padding margin'>
                                            <div className='col-12 col-sm-4'>Delivery Address :</div>
                                            {/* <div className='column'><textarea id='address' type='text' className='form-control' onChange={e => setAddOrder({ ...addOrder, address: e.target.value })} /></div> */}
                                            <div className='col-12 col-sm-8 remove-right-padding'>
                                                <textarea id='address' type='text' defaultValue={addOrder.address} className='form-control' rows={1} onChange={e => inputHandler(e, setAddOrder, addOrder, 'address')} />
                                            </div>
                                        </div>

                                        {/* <div className='row'>
                                    <div className='column'>Telephone :</div>
                                    <div className='column'><input type='text' className='form-control' onChange={e => setAddOrder({ ...addOrder, telephone: e.target.value })} /></div>
                                </div> */}

                                        <div className='row padding margin'>
                                            <div className='col-12 col-sm-4'>Mobile / WhatsApp :</div>
                                            <div className='col-12 col-sm-8 remove-right-padding'>
                                                <PhoneInput
                                                    inputStyle={{ width: '100%' }}
                                                    country={'lk'}
                                                    theme={theme => ({ ...theme, borderRadius: 0 })}
                                                    // onlyCountries={['lk']}
                                                    disableDropdown={true}
                                                    masks={{ lk: '... ... ... ...' }}
                                                    value={addOrder.telephone}
                                                    onChange={e => setAddOrder({ ...addOrder, telephone: e })}
                                                    // countryCodeEditable={false}
                                                    isValid={(value, country) => {
                                                        // if (value.match(/12345/)) {
                                                        //   return 'Invalid value: '+value+', '+country.name;
                                                        // } else if (value.match(/1234/)) {
                                                        //   return false;
                                                        // } else {
                                                        //   return true;
                                                        // }
                                                        var phoneno = /^[1-9]\d{10,14}$/;
                                                        // console.log(value);
                                                        if (value.match(phoneno)) {
                                                            return true;
                                                        } else {
                                                            if (value.length === 2) {
                                                                return true;
                                                            } else if (value.substr(2, 1) === '0') {
                                                                return 'Please remove ' + value.substr(2, 1) + ' after +' + value.substr(0, 2)
                                                            }
                                                        }
                                                        // return false;
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='row padding margin'>
                                            <div className='col-12 col-sm-4'>Payment  :</div>
                                            <div className='col-12 col-sm-8 remove-right-padding'>
                                                <select id='paymentOption' className='form-control' onChange={e => inputHandler(e, setAddOrder, addOrder, 'paymentOption')}>
                                                    <option key={''} value={''}>- Select Payment Method -</option>
                                                    {paymentMethods?.map(obj => <option key={obj._id} value={obj._id}>{obj.description}</option>)}
                                                </select>
                                            </div>
                                        </div>

                                        <div className='row padding margin'>
                                            <div className='col-12 col-sm-4'>Note :</div>
                                            <div className='col-12 col-sm-8 remove-right-padding'>
                                                <textarea id='note' type='text' className='form-control' rows={1} onChange={e => inputHandler(e, setAddOrder, addOrder, 'note')} /></div>
                                        </div>

                                        <CartTable cart={cart} coRegion={coRegion} fastDeliveryCharge={fastDeliveryCharge} />

                                        <div className='row padding margin'>
                                            <div>
                                                <div style={{ fontWeight: 'bold' }}>Delivery to :  {coRegion.name}</div>
                                                <div style={{ fontWeight: 'bold' }}>Date :     {DateStringFormat(deliDate[0]?.day)}</div>
                                            </div>
                                        </div>

                                    </div>}
                        </div>
                        {/* Delivery to <Region> on <Date> */}
                        <div className='modal-footer'>
                            {addOrder.orderItems.length <= 0 || Number(minOrderValue?.value) > Number(cart.reduce((total, obj) => { return total + ((obj.price - (obj.price * (((obj.fastDiscountValue.length > 0 && obj.is_fast_delivery) ? obj.fastDiscountValue[0] : obj.discountPercentage) / 100))) * obj.qty) }, 0)) ?
                                null
                                : <button type="button" className="btn-purchase"
                                    // disabled={isOnline ? isLoading : true}
                                    style={{ height: '5vh', width: '40%' }}
                                    data-target={(paymentMethods.filter(e => e.slug === "bank-transfer" && String(e._id) === String(addOrder.paymentOption)).length > 0 && popupMessage.isActive === true) ? "#popup_message" : ""} data-toggle="modal"
                                    onClick={() => {
                                        let statusModel = (paymentMethods.filter(e => e.slug === "bank-transfer" && String(e._id) === String(addOrder.paymentOption)).length > 0 && popupMessage.isActive === true) ? false : true
                                        purchaseItem(addOrder, setIsLoading, setNotify, token, props, statusModel)
                                    }}>
                                    Purchase
                                </button>}
                            {/* <button type="button" className="btn btn-secondary" disabled={isLoading} data-dismiss="modal">Close</button> */}
                            {addOrder.orderItems.length === 0 ? <button className="btn-purchase" style={{ height: '5vh', width: '40%' }} onClick={() => tryAgainHandler(addOrder, cart, setAddOrder)}>Try Again</button> : null}
                        </div>
                    </div>
                </div>
            </div>
            {/* model box */}

            <div id='offline_model' className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content animate" >
                        <div>
                            No Internet Connection
                        </div>
                    </div>
                </div>
            </div>

            {/* delivery region data */}
            <DeliveryRegionModel deiliveryMsg={deiliveryMsg} isLoading={isLoading} />
            {/* delivery region data */}

            {/* get customer region */}
            <CoRegionModel deliveryRegion={deliveryRegion} minOrder={minOrderValue} coRegion={coRegion} setCoRegion={setCoRegion} deliDate={deliDate} setDeliDate={setDeliDate} isLoading={isLoading} />
            {/* get customer region */}

            <ToastContainer limit={1} />

            <div id="popup_message" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ border: "2px solid #000" }}> {/* Add the border style here */}
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase font-style spacing-1" style={{ fontSize: '20px', color: '#333233b5', fontWeight: 'bold' }}>{popupMessage.message}</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-2 col-12">
                                    <div className="text-center text-md-left">
                                        <i className="fas fa-exclamation-circle fa-4x text-danger"></i>
                                    </div>
                                </div>
                                <div className="col-md-10 col-12">
                                    {popupMessageValue.length > 0 && popupMessageValue.map((line, index) => (
                                        <span key={index} style={{ fontWeight: 'bold', display: 'block' }}>{line}</span>
                                    ))};
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => { purchaseItem(addOrder, setIsLoading, setNotify, token, props, true) }} style={{ width: '70px' }} data-dismiss="modal" className="btn-purchase me-md-2">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrder)
