/**
 * @file This file defines to ordering action dispatch
 * @author Bhanuka Chathuranga
 */
// import $ from 'jquery';
import { toast } from 'react-toastify';
// import _ from 'lodash';

var axios = require('axios');

export const complainsAsync = data => {
    return { type: 'FETCH_COMPLAINS', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * 
 * @param {*} token 
 */
export const getCustomerComplaintsWithOrders = (token) => {
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/ordering/getCustomerComplaintsWithOrders')
            .then(result => {
                console.log(result.data);
                dispatch(isLoadingAsync(false))
                dispatch(complainsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}