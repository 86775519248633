/**
 * @file This file defined for ordering form
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Field } from "redux-form";
import Table from "react-table";
import $ from 'jquery';
import * as BS from "react-bootstrap";
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import jwt from 'jsonwebtoken';

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormProvider from "../../components/FormProvider";
import OrderActionsCell from "../../components/OrderActionCell";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import Swal from 'sweetalert2'
import "../../components/checkBox.css"

import ErrorBoundary from "../../components/ErrorBoundary";
import {
    getOrderingData,
    getOrderItemsData,
    addOrder,
    updateOrder,
    deleteOrder,
    addOrderItems,
    updateOrderItem,
    deleteOrderItem,
    updateOrderStatus,
    addCustomerComplaint,
    updateCustomerComplaint,
    deleteCustomerComplaint,
    updateDeliveryCharge,
    reloadData,
    updateCustomerAmount,
    getAllOrderItems
} from '../../../actions/Ordering/ordering_order_action';

import { getPaymentMethodData, getAddressData, getItemData, getProductData, getUserData, getCityData, getResolveMethodData, getRegionData } from '../../../actions/Basic_data/basic_data_action';
import { getSurveyQuestionsData } from '../../../actions/Basic_data/survey_questions_actions';
import { getDeliveryWarehouses, getDeliveryWarehousesForCurrentUser } from '../../../actions/Basic_data/delivery_warehouse_actions';


import { toast } from 'react-toastify';
import { getDeliveryCrew } from '../../../actions/Delivery/delivery_action';
import dotenv from 'dotenv'
import NumberGridFilter from '../../components/NumberGridFilter';
import ReactToPrint from "react-to-print";
import InvoicePrint from "../Ordering/InvoicePrint";
import Permission from '../../utils/Permission';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import ExportData from '../../utils/ExportData';
import OptionColumnFilter from '../../components/column_filter/OptionColumnFilter';
import { getAccountsData } from '../../../actions/Acc/account_actions';
import { PriceFormat } from '../../utils';
import CustomerComplaintModal from './Modal/CustomerComplaint/CustomerComplaintModal';
import AmountPaid from './Modal/AmountPaid/AmountPaid';
import DateGridFilter from '../../components/DateGridFilter';
// import SkeletonTable from '../../components/skeleton/SkeletonTable';

import ReplacementModal from './Modal/ReplacementModal';

dotenv.config()
// const status = ['Initiated', 'Processed', 'Dispatched', 'Delivered', 'Delivered-Cash Pay', 'Delivered-Bank Transfer', 'Cancel'];
const status = ['Initiated', 'Processed'];
const bool = [{ value: false, label: 'No' }, { value: true, label: 'Yes' }]
const DeliveredStatus = ['Dispatched', 'Delivered', 'DispatchedMissing']

const customerComplaint = (row, { setCurrentOrderId, setRelatedOrderIds, setCustomerComplaintsData, setOrderItemsData }, props) => {
    axios.defaults.headers.common['authorization'] = props.token;
    const orderId = row.original.orders._id;
    const orderitems = row.original.orders.orderItems
    getOrderItems(orderitems, setOrderItemsData, props)
    setCurrentOrderId(orderId);

    const phoneNumber = row.original.orders.telephone;
    const orderData = props.data;

    var relatedOrders = _.filter(orderData, function (o) {
        return Number(o.orders.telephone) === Number(phoneNumber);
    });

    var relatedIdsArray = [];

    relatedOrders.forEach(relatedOrder => {
        if (relatedOrder.orders.telephone !== '' && relatedOrder.orders.telephone !== 0 && relatedOrder.orders.telephone !== null) {
            relatedIdsArray.push(relatedOrder.orders._id);
        }
    });


    relatedIdsArray.push(orderId);
    setRelatedOrderIds(relatedIdsArray);

    const data = {
        'orderId': relatedIdsArray
    };

    axios.post('/ordering/getCustomerComplaintsByOrderId', { data })
        .then(result => {
            const finalData = [];
            result.data.forEach(element => {
                if (element.customercomplaints.order_ref === orderId) {
                    finalData.push(element);
                }
            });

            result.data.forEach(element => {
                if (element.customercomplaints.order_ref !== orderId && element.customercomplaints.resolved === "No") {
                    finalData.push(element);
                }
            });

            setCustomerComplaintsData([]);
            setCustomerComplaintsData(finalData);
        }).catch(err => {
            console.error(err)
        })
}

const getOrderItems = (orderItems, setOrderItemsData, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    axios.post('/ordering/getOrderItems', { orderItems })
        .then(result => {
            // console.table(result.data)
            setOrderItemsData(result.data)
        }).catch(err => {
            console.error(err)
            // toast.error(`${err}`)
        })
}

const deleteData = async (message) => {

    let inputOptions;

    if (message === 'order') {
        inputOptions = {
            'not available': 'Not Available',
            'duplicate': 'Duplicate',
        }
    } else {
        inputOptions = {
            'not available': 'Not Available',
            'duplicate': 'Duplicate',
            'replacement': 'Replacement'
        }
    }

    const data = await Swal.fire({
        title: `Delete`,
        text: `Do you want to delete this ${message}?`,
        icon: 'question',
        allowEnterKey: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonColor: 'primary',
        cancelButtonColor: 'secondary',
        confirmButtonText: 'Yes',
        input: 'select',
        inputOptions: inputOptions,
        inputPlaceholder: '-Select Reason-',
        showCancelButton: true,
        inputValidator: (data) => {
            return new Promise((resolve) => {
                if (data !== '') {
                    resolve()
                } else {
                    resolve('Please select the reason :)')
                }
            })
        }
    })
    return data
}

function Ordering(props) {

    // const ReactTableFixedColumns = withFixedColumns(Table);

    const toDate = () => {
        let toDate = new Date().toISOString().split('T')
        let splitTime = toDate[1].split('.')
        let timeString = `23:59:59.${splitTime[1]}`
        let newToDate = `${toDate[0]}T${timeString}`
        return newToDate;
    }

    const sortOptions = [{ id: 'customercomplaints.date', desc: true }];

    const [rSelectCustomer, setRSelectCustomer] = useState([]);
    const [total, setTotal] = useState({ qty: 0, value: 0 });
    const [order_id, setOrder_id] = useState(null);
    const [order, setOrder] = useState(null);
    const [printOrder, setPrintOrder] = useState(null);
    const [exportRegion, setExportRegion] = useState(null);
    const [date, setDate] = useState(null);
    const [orderItems, setOrderItems] = useState(null);
    const [printOrderItems, setPrintOrderItems] = useState(null);
    const [printCreditNoteItems, setCreditNoteItems] = useState(null);
    const [orderItemsData, setOrderItemsData] = useState(null);
    const [printOrderItemsData, setPrintOrderItemsData] = useState(null);
    const [addOrderItems, setAddOrderItems] = useState([]);
    const [edit, setEdit] = useState(null)
    const [remove, setRemove] = useState(null)
    const [expanded, setExpanded] = useState({});
    // const [product, setProduct] = useState(null);
    // const [item, setItem] = useState(null)
    const [addOrder, setAddOrder] = useState({ orderId: '', name: '', address: '', paymentOption: '', telephone: '', whatsapp: '', billingAddress: '', orderPickUp: false, deliveryAddress: '', city: '', note: '', user: '', orderItems: [], warehouse: '' });
    const [isWhatsapp, setIsWhatsapp] = useState(true);
    const [exportDate, setExportDate] = useState({ from: new Date().toISOString().split('T')[0], to: toDate() })
    const [rowInfo, setRowInfo] = useState('');
    const [deliveryCharge, setDeliveryCharge] = useState({ id: '', isFixed: false, charge: 0 })
    const [customerComplaintsData, setCustomerComplaintsData] = useState([]);
    const [customerComplaintDate, setCustomerComplaintDate] = useState('');
    const [type, setType] = useState('');
    const [complaint, setComplaint] = useState('');
    const [resolved, setResolved] = useState('No');
    const [resolvedDate, setResolvedDate] = useState('');
    const [resolvedMethod, setResolvedMethod] = useState(null);
    const [complaintItem, setComplaintItem] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [customerComplaintImagesForGallery, setCustomerComplaintImagesForGallery] = useState('');
    const [currentOrderId, setCurrentOrderId] = useState(null);
    const [relatedOrderIds, setRelatedOrderIds] = useState([]);
    const [transferToHistoryFlag, setTransferToHistoryFlag] = useState(false);
    const [exportDataFlag, setExportDataFlag] = useState(false);
    const [isPrintLoading, setIsPrintLoading] = useState(null);
    const [riders, setRiders] = useState(null);
    const [orderItemToBeReplacement, setorderItemToBeReplacement] = useState(null)

    //customer survey
    const [currentOrderIdForCustomerSurvey, setCurrentOrderIdForCustomerSurvey] = useState(null);
    const [customerSurveyData, setCustomerSurveyData] = useState([]);
    const [customerSurveyView, setCustomerSurveyView] = useState(true)
    const [surveyData, setSurveyData] = useState([]);
    const [editSurveyData, setEditSurveyData] = useState(false);
    const [surveyDataEdit, setSurveyDataEdit] = useState([]);
    const [checkValue, setCheckValue] = useState(false); // Checkbox state

    //customer survey end

    useEffect(() => {
        props.reloadData(props)
        // props.getOrderingData(props.token);
        props.getPaymentMethodData(props.token);
        props.getAddresses(props.token);
        props.getItems(props.token);
        props.getProduct(props.token);
        props.getCustomer(props.token);
        props.getCity(props.token);
        props.getRegionData(props)
        props.getResolveMethodData(props)
        props.getDeliveryCrew(props);
        props.getSurveyQuestionsData(props.token)
        props.getAllOrderItems(props.token)
        props.getDeliveryWarehouses(props)
        props.getDeliveryWarehousesForCurrentUser(props)
    }, [])

    useEffect(() => {
        var data = _.filter(props.address, { 'user': rSelectCustomer._id })
        // console.info(data)
        if (data.length > 0) {
            setAddOrder({ ...addOrder, name: '', telephone: '', address: '', billingAddress: data[0]._id, deliveryAddress: data[0]._id, city: '' })
        }
    }, [rSelectCustomer])

    useEffect(() => {
        // props.getOrderItems(orderItems, props.token)
        // console.log(orderItems)
        if (orderItems !== null) {
            getOrderItems(orderItems, setOrderItemsData, props)
        }
    }, [orderItems])

    useEffect(() => {
        if (props.deliveryCrewData !== null) {
            let rider = [];
            props.deliveryCrewData.map((v) => {
                if (v.deliverycrew.status === true) {
                    rider.push(v.deliverycrew)
                }
            })
            setRiders(rider);
        }
    }, [props.deliveryCrewData])

    useEffect(() => {
        // props.getOrderItems(orderItems, props.token)
        // console.log(orderItems)
        setPrintOrderItemsData([])
        setIsPrintLoading(true);
        if (printOrderItems !== null) {
            axios.defaults.headers.common['authorization'] = props.token;
            axios.post('/ordering/getOrderItems', { printOrderItems })
                .then(result => {
                    // console.table(result.data)
                    setPrintOrderItemsData(result.data)
                    setIsPrintLoading(false)
                }).catch(err => {
                    console.error(err)
                    // toast.error(`${err}`)
                })
        }
    }, [printOrderItems])

    useEffect(() => {
        if (isWhatsapp) {
            setAddOrder({ ...addOrder, whatsapp: `+94${addOrder.telephone}` })
        } else if (addOrder.whatsapp === '') {
            setAddOrder({ ...addOrder, whatsapp: '+94' })
        }
    }, [isWhatsapp])

    useEffect(() => {
        if (isWhatsapp && addOrder.telephone.toString().length === 9) {
            setAddOrder({ ...addOrder, whatsapp: `+94${addOrder.telephone.toString()}` })
        }
    }, [addOrder.telephone])

    useEffect(() => {
        if (addOrder.orderItems.length > 0) {
            // console.log(addOrder.orderItems)
            setTotal({
                qty: _.sumBy(addOrder.orderItems, 'qty'), value: _.sumBy(addOrder.orderItems, function (o) {
                    return o.qty * (o.price - (o.price * ((o.discountPercentage === undefined || o.discountPercentage === null) ? 0 : o.discountPercentage / 100)))
                })
            })
        } else if (addOrder.orderItems.length === 0) {
            setTotal({ qty: 0, value: 0 })
        }
    }, [addOrder.orderItems])

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: edit === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEdit(rowProps.original),
                onCancel: () => setEdit(null),
                onDelete: () => setRemove(rowProps.original)
            },
            updPerm: Permission('ORDERS', 'ORDER', 'UPDATE', props),
            delPerm: Permission('ORDERS', 'ORDER', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableNumberComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component type="number"  {...input} {...rest} min={0} children={children} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    };

    const renderDateTimePicker = ({ input, editing, value, Props, ...rest }) => {
        // console.log(value)
        // console.log(editing)
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        return editing && !DeliveredStatus.includes(Props.original.orders.status) ? <Component type="datetime-local" {...input} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />
            : <label style={{ color: Props.original.orders.isEtdChange ? 'red' : '' }}>{new Date(Props.value).toLocaleDateString()} - {new Date(Props.value).toLocaleTimeString()}</label>;

    }

    const editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableComponent,
                editing,
                props
            };
            const url = "https://api.whatsapp.com/send?phone=" + props?.value?.split('+')[1];
            return (props.original.orders !== undefined && (DeliveredStatus.includes(props.original.orders.status) && props.column.Header === 'Address')) ?
                <>{props.value}</>
                : props.column.Header === 'Whatsapp' && !editing ? <><u><a href={url} target="_blank" >{props.value}</a></u></> : <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const editableNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableNumberComponent,
                editing,
                step: props.column.Header !== 'Amount Paid' && props.column.Header !== 'Qty' ? "1" : "0.001",
                props
            };
            return (props.original.orders !== undefined && (props.column.Header !== 'Amount Paid' && props.column.Header !== 'Delivery Charges') && props.original.orders.amountPaid !== 0) ?
                <div style={{ textAlign: 'right' }}>{props.value}</div>
                : <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const editableDecimalNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableNumberComponent,
                editing,
                step: "0.001",
                props
            };
            return (props.original.orders !== undefined && (DeliveredStatus.includes(props.original.orders.status) && props.column.Header !== 'Amount Paid' && props.column.Header !== 'Delivery Charges')) ?
                <div style={{ textAlign: 'right' }}>{props.value}</div>
                : <Field name={props.column.id} {...fieldProps} />;

        }
    };

    const selectableColumnProps = {
        ...GridFilters,
        Cell: Props => {
            const editing = edit === Props.original;

            return ((editing && Props.original.orders !== undefined && (Props.column.Header !== 'Status' || !DeliveredStatus.includes(Props.original.orders.status))) ?
                <Field name={Props.column.id} component="select" className='form-control' disabled={Props.column.Header === 'Rider' && DeliveredStatus[1] === Props.original.orders.status ? true : false}>
                    {(Props.column.Header === 'Payment Mehtods' ? props.paymentMethods : Props.column.Header === 'City' ? props.city : Props.column.Header === 'Rider' ? riders : Props.column.Header === 'Warehouse' ? props.deliveryWarehouseData : status).map(data =>
                        <option
                            key={Props.column.Header === 'Payment Mehtods' || Props.column.Header === 'City' || Props.column.Header === 'Rider' || Props.column.Header === 'Warehouse' ? data._id : data} value={Props.column.Header === 'Payment Mehtods' || Props.column.Header === 'City' || Props.column.Header === 'Rider' || Props.column.Header === 'Warehouse' ? data._id : data}>
                            {Props.column.Header === 'Payment Mehtods' || Props.column.Header === 'City' || Props.column.Header === 'Rider' || Props.column.Header === 'Warehouse' ? data.name : data}
                        </option>
                    )}
                </Field> : <label>{Props.value}</label>)
        }
    };

    const editableSelectComponent = ({ Props }) => {
        return <><select defaultValue={Props.value} className='form-control'>
            {(Props.column.Header === 'Payment Mehtods' ? props.paymentMethods : status).map(data =>
                <option key={Props.column.Header === 'Payment Mehtods' ? data._id : data} value={Props.column.Header === 'Payment Mehtods' ? data._id : data}>{Props.column.Header === 'Payment Mehtods' ? data.name : data}</option>
            )}
        </select></>
    }

    const selectableDateColumnProps = {
        ...DateGridFilter,
        Cell: Props => {
            const editing = edit === Props.original;
            const fieldProps = {
                component: renderDateTimePicker,
                editing,
                Props
            };

            return (<Field name={Props.column.id} {...fieldProps} />)
        }
    };

    const booleanColumnProps = {
        // ...GridFilter,
        Cell: Props => {
            const editing = edit === Props.original;

            return (editing ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {bool.map(data =>
                        <option
                            key={data.value} value={data.value}>
                            {data.label}
                        </option>
                    )}
                </Field> : <label>{Props.value ? 'YES' : 'NO'}</label>)
        }
    }

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                : true
        );
    }

    const editableDateColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;

            return (editing ?
                <DatePicker onChange={setCustomerComplaintDateMethod} selected={customerComplaintDate} className="form-control" />
                : <label>{props.value}</label>)
        }
    };


    const setCustomerComplaintDateMethod = date => {
        setCustomerComplaintDate(date);
    }

    const editableResolvedDateColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;

            return (editing ?
                <DatePicker onChange={setResolvedDateMethod} selected={resolvedDate} className="form-control" />
                : <label>{props.value}</label>)
        }
    };

    const setResolvedDateMethod = date => {
        setResolvedDate(date);
    }

    const getTdProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            return {
                style: {
                    backgroundColor: rowInfo.original.customercomplaints.order_ref === currentOrderId ? '' : 'yellow',
                }
            }
        }
        return {};
    }

    const handleCustomerComplaintSubmit = values => {
        if (edit !== null) {
            var data = values.customercomplaints;
            if (data.resolved === 'Yes') {
                data.resolvedDate = formatDate(Date.now());
            }

            if (values?.items?.name !== edit?.items?.name) {
                data.item = values.items.name
            }

            props.updateCustomerComplaint(data, props.token);

            data = {
                'orderId': relatedOrderIds
            };

            axios.defaults.headers.common['authorization'] = props.token;
            axios.post('/ordering/getCustomerComplaintsByOrderId', { data })
                .then(result => {
                    const finalData = [];
                    result.data.forEach(element => {
                        if (element.customercomplaints.order_ref === currentOrderId) {
                            finalData.push(element);
                        } else if (element.customercomplaints.resolved === "No") {
                            finalData.push(element);
                        }
                    });
                    setCustomerComplaintsData(finalData);
                }).catch(err => {
                    console.error(err)
                })

            setComplaint(null);
            setCustomerComplaintDate(null);
            setType(null);
            setResolved(null);
            setResolvedDate(null)
            setImagePath(null);
            setComplaintItem('');

        } else if (remove !== null) {
            var data = {
                '_id': values.customercomplaints._id
            };

            props.deleteCustomerComplaint(data, props.token);

            data = {
                'orderId': relatedOrderIds
            };

            axios.defaults.headers.common['authorization'] = props.token;
            axios.post('/ordering/getCustomerComplaintsByOrderId', { data })
                .then(result => {
                    const finalData = [];
                    result.data.forEach(element => {
                        if (element.customercomplaints.order_ref === currentOrderId) {
                            finalData.push(element);
                        } else if (element.customercomplaints.resolved === "No") {
                            finalData.push(element);
                        }
                    });
                    setCustomerComplaintsData(finalData);
                }).catch(err => {
                    console.error(err)
                })
        }
    }



    const reloadCustomerComplaintsData = () => {
        const data = {
            'orderId': relatedOrderIds
        };

        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/ordering/getCustomerComplaintsByOrderId', { data })
            .then(result => {
                const finalData = [];
                result.data.forEach(element => {
                    if (element.customercomplaints.order_ref === currentOrderId) {
                        finalData.push(element);
                    } else if (element.customercomplaints.resolved === "No") {
                        finalData.push(element);
                    }
                });

                setCustomerComplaintsData(finalData);
            }).catch(err => {
                console.error(err)
            })
    }

    const formatDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const columns = [
        {
            Header: "Order Id", accessor: "orders.orderId", ...GridFilters, show: true,
            Cell: props =>
                <>
                    {props.original.orders.orderId}
                    <br />
                    {props.original.orders.new_order !== undefined || props.original.backlistCustomer !== undefined ?
                        <div>
                            <span className={props.original.orders.new_order === "Yes" || props.original.backlistCustomer === "Yes" ? "bg-danger text-white w-5 p-1" : ''} >
                                <span className="lg font-weight-bold text-center">{props.original.orders.new_order === "Yes" ? "New" : ''}{props.original.backlistCustomer === "Yes"
                                    && props.original.orders.new_order === "Yes" ? " + " : ''}{props.original.backlistCustomer === "Yes" ? "BL" : ''} </span>
                            </span>
                        </div> : ''}
                </>
        },
        { Header: "Name", accessor: "orders.name", ...GridFilters, show: true, },
        {
            Header: "Telephone", accessor: "orders.telephone", ...editableColumnProps, show: true,
            getProps: (state, row, column) => {
                let val = _.filter(props.data, function (o) { return o?.orders?.status === 'Initiated' && o?.orders?.telephone === row?.original?.orders.telephone })
                return {
                    style: {
                        'color': (row?.original?.orders?.status === 'Initiated' && val?.length > 1 ? 'red' : 'black')
                    }
                }
            }
        },
        { Header: "Whatsapp", accessor: "orders.whatsapp", ...editableColumnProps, show: true, },
        { Header: "Address", accessor: "orders.address", ...editableColumnProps, },
        { Header: "City", accessor: "cities.name", ...selectableColumnProps, show: true, },
        { Header: "Region", accessor: "regions.name", ...GridFilters },
        { Header: "Payment Mehtods", accessor: "paymentmethods.name", ...selectableColumnProps },
        { Header: "Status", accessor: "orders.status", ...selectableColumnProps, show: true, },
        {

            Header: "Pick up",
            accessor: "orders.orderPickUp",
            filterable: true,
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>,
            ...booleanColumnProps
        },
        {
            Header: "Amount Paid", accessor: "orders.amountPaid",
            // ...editableNumberColumnProps
            Cell: props =>
                <button style={{ textAlign: 'right' }} data-toggle="modal"
                    className={'btn btn-link'} disabled={Number(props.original.orders.totalPrice) === Number(props.original.orders.amountPaid) ? true : false}
                    data-target="#amountPaidModal" onClick={() => setRowInfo(props.original)}>
                    {PriceFormat(props.value)}
                </button>
        },
        {
            Header: "Delivery Charges", accessor: "orders.deliveryCharges",
            // ...editableNumberColumnProps
            Cell: props =>
                <button style={{ textAlign: 'right' }} data-toggle="modal"
                    className={`btn ${props.original.orders.isFixedCharged ? 'fixed-delivery' : 'btn-link'}`}
                    data-target="#changeDeliveryCharge" onClick={() => setDeliveryCharge({ ...deliveryCharge, id: props.original.orders._id, order: props.original.orders, isFixed: props.original.orders.isFixedCharged, charge: props.original.orders.isFixedCharged ? props.original.orders.deliveryCharges : 0 })}>
                    {props.original.orders.deliveryCharges}
                </button>
        },
        {
            Header: "Tax", accessor: "orders.tax", show: false, ...editableNumberColumnProps
            // Cell: props =>
            //     <div style={{ textAlign: 'right' }}>
            //         {props.original.orders.deliveryCharges}
            //     </div>
        },
        {
            Header: "Order Discount %", accessor: "orders.totalDiscount", ...editableNumberColumnProps
            // Cell: props =>
            //     <div style={{ textAlign: 'right' }}>
            //         {props.original.orders.deliveryCharges}
            //     </div>
        },
        {
            Header: "Total Price", accessor: "orders.totalPrice", show: true,
            filterable: true,
            ...GridFilters,
            // NumberRangeColumnFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.orders.totalPrice)}
                </div>
        },
        {
            Header: "Etd.", accessor: "orders.etd", ...selectableDateColumnProps, show: true,
        },
        {
            Header: "Note", accessor: "orders.note", ...editableColumnProps
        },
        { Header: "Rider", accessor: "deliverycrew.name", ...selectableColumnProps },
        { Header: "Warehouse", accessor: "deliverywarehouse.name", ...selectableColumnProps },
        {
            Header: 'Customer Complaints',
            accessor: "orders.customerComplaints",
            filterable: true,
            getProps: (state, row, column) => {
                return {
                    style: {
                        backgroundColor: (row && (row.original.orders.customerComplaints === 'Yes' || row.original.orders.customerComplaints === 'Other')) ? 'red' : '',
                    }
                }
            },
            Cell: row =>
                <>
                    <button data-toggle="modal" disabled={Permission('ORDERS', 'COMPLAINS', 'VIEW', props)} data-target='#customerComplaintsPopup' className='btn btn-link'
                        data-backdrop="static" data-keyboard="false"
                        style={{ color: (row && (row.original.orders.customerComplaints === 'Yes' || row.original.orders.customerComplaints === 'Other')) ? 'white' : '' }}
                        onClick={() => customerComplaint(row, { setCurrentOrderId, setRelatedOrderIds, setCustomerComplaintsData, setOrderItemsData }, props)}
                    >{row.value}</button>
                </>
        },
        {
            Header: "IsOdoo", accessor: "orders.isOdooStatus",  show: true, ...GridFilters,
            Cell: props =>
            <div style={{ textAlign: 'Center' }}>
                {props.original.orders.isOdooStatus}
            </div>
        },
        {
            Header: "Survey",
            accessor: "orders.customerSurvey",
            filterable: true,
            Cell: row =>
                <>
                    <button
                        data-toggle="modal"
                        data-target="#customerSurveyPopup"
                        className='btn btn-link'
                        data-backdrop="static" data-keyboard="false"
                        disabled={Permission('ORDERS', 'CUSTOMER SURVEY', 'UPDATE', props)}
                        onClick={() => {
                            if (row.value === 'View') {
                                setCustomerSurveyView(true)
                            } else {
                                setCustomerSurveyView(false);
                            }
                            setCurrentOrderIdForCustomerSurvey(row.original.orders._id)
                            axios.defaults.headers.common['authorization'] = props.token;
                            axios.get('/ordering/getCustomerSurvey', { params: { orderid: row.original.orders._id } })
                                .then(result => {
                                    setCustomerSurveyData(result.data);
                                }).catch(err => {
                                    console.error(err);
                                });
                        }}
                    >{row.value}</button>
                </>
        },
        {
            Header: "Mobile Order",
            accessor: "orders.isFromMobile",
            filterable: true,
            show: true,
            filterMethod: (filter, row) => {
                // console.log(filter, row)
                if (filter.value === "all") {
                    // console.log('all');
                    return true;
                } else if (filter.value === "Yes") {
                    return row[filter.id] === "Yes";
                } else if (filter.value === "Yes-Fast") {
                    return row[filter.id] === "Yes" && row?._original?.orders?.isFastDelivery;
                } else if (filter.value === "No") {
                    return row[filter.id] === "No";
                }
            },
            Filter: ({ filter, onChange }) => <OptionColumnFilter filter={filter} onChange={onChange} />,
            getProps: (state, row, column) => {
                return {
                    style: {
                        backgroundColor: (row && (row.original.orders.isFromMobile === "Yes" || row.original.orders.isFromMobile === "App")) ?
                            row.original.orders.isFastDelivery ?
                                'lightgreen'
                                : row.original.orders.isFromMobile === "App" ?
                                    'orange'
                                    : 'yellow'
                            : '',
                        fontSize: '14px',
                        textAlign: 'center'
                    }
                }
            },
            Cell: row =>
                <div>
                    {row.original.orders.isFromMobile}{row.original.orders.isFastDelivery ? '-Fast' : ''}
                </div>

        },
        {
            Header: 'POS Export',
            Cell: row =>
                <>
                    <button
                        data-toggle="modal"
                        className='btn btn-link'
                        onClick={() => {
                            let orderItemIds = row.original.orders.orderItems;
                            let filtered = [];

                            props.allOrderItems.forEach(orderItem => {
                                for (let i = 0; i < orderItemIds.length; i++) {
                                    if (orderItemIds[i].toString() === orderItem.orderitems._id.toString()) {

                                        let obj = {
                                            'name': orderItem.items.desc,
                                            'price': orderItem.orderitems.unitPrice,
                                            'isEnabled': orderItem.orderitems.quantity
                                        }

                                        filtered.push(obj);
                                    }
                                }
                            });

                            var data = {
                                'filtered': filtered,
                                'orderId': row.original.orders.orderId
                            };

                            exportDataPOS(data);

                        }}
                    >Export</button>
                </>

        },
        {
            Header: "Action",
            maxWidth: 130,
            minWidth: 130,
            show: true,
            Cell: props => (
                <div style={{ textAlign: "right" }}>
                    <button id={`invoice-${props.original.orders.orderId}`}
                        data-toggle="modal"
                        className="btn btn-link"
                        data-target="#invoicePopup"
                        onClick={() => {
                            setPrintOrder();
                            setPrintOrderItems([]);
                            // setPrintOrderItemsData([]);
                            let newDate = new Date();
                            let date = newDate.getDate() + "-" + (Number.parseInt(newDate.getMonth()) + 1) + "-" + newDate.getFullYear();
                            setDate(date);
                            setPrintOrder(props.original.orders);
                            setPrintOrderItems(props.original.orders.orderItems);

                            axios.get('/deliveryCrew/getCreditNote')
                                .then(result => {
                                    let creditNoteData = result.data;
                                    var relatedCreditNote = _.filter(creditNoteData, function (o) {
                                        return Number(props.original.orders.telephone) === Number(o.telephone)
                                            && o.isCompleted === false;
                                    });


                                    const amount = relatedCreditNote.reduce((total, ele) => { return total + ele.price }, 0);
                                    setCreditNoteItems(Number(amount));
                                }).catch(err => {
                                    console.error(err);
                                });
                        }}
                    >
                        Invoice
                    </button>
                </div>
            )
        },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            show: true,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: OrderActionsCell
        },
    ]

    const CSPDOptions = [
        { '_id': 1, 'name': 'Very Satisfied' },
        { '_id': 2, 'name': 'Satisfied' },
        { '_id': 3, 'name': 'Dissatisfied' },
    ];

    const CSHAUOptions = [
        { '_id': 1, 'name': 'By Friend' },
        { '_id': 2, 'name': 'By Internet' },
        { '_id': 3, 'name': 'Other' },
    ];


    const editableColumnPropsCustomerSurvey = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableComponent,
                editing,
                props
            };
            return (editing ? <Field name={props.column.id} {...fieldProps} /> : <label>{props.value}</label>);
        }
    };

    const selectableColumnPropsCustomerSurvey = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableComponent,
                editing,
                props
            };
            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => setStateForEdit(props.column.Header, e.target.value)}>
                {(CSPDOptions).map(data =>
                    <option key={data._id} value={data._id}>{data.name}</option>
                )}
            </Field> :
                <label>
                    {
                        props.value === 1 ? 'Very Satified' : props.value === 2 ? 'Satisfied' : 'Dissatisfied'
                    }
                </label>)
        }
    };

    const selectableColumnPropsCustomerSurvey2 = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableComponent,
                editing,
                props
            };
            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => setStateForEdit(props.column.Header, e.target.value)}>
                {(CSHAUOptions).map(data =>
                    <option key={data._id} value={data._id}>{data.name}</option>
                )}
            </Field> :
                <label>
                    {
                        props.value === 1 ? 'By Friend' : props.value === 2 ? 'By Internet' : 'Other'
                    }
                </label>)
        }
    };

    const setStateForEdit = (column, value) => {
        if (column === 'Delivery Satisfaction') {

        }

        if (column === 'Product Satisfaction') {

        }

        if (column === 'About Gusta') {

        }
    }

    const getActionPropsCustomerSurvey = (gridState, rowProps) =>
        (rowProps && {
            mode: edit === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    setEdit(rowProps.original);
                },
                onCancel: () => {
                    setEdit(null);
                },
                onDelete: () => setRemove(rowProps.original)
            },
            updPerm: Permission('ORDERS', 'CUSTOMER SURVEY', 'UPDATE', props),
            delPerm: Permission('ORDERS', 'CUSTOMER SURVEY', 'DELETE', props),
        }) ||
        {};

    const deleteSurvey = (id) => {
        const data = {
            _id: id
        };

        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/ordering/deleteCustomerSurvey', data)
            .then(result => {
                axios.defaults.headers.common['authorization'] = props.token;
                axios.get('/ordering/getCustomerSurvey', { params: { orderid: currentOrderIdForCustomerSurvey } })
                    .then(result => {
                        setCustomerSurveyData(result.data);
                        props.reloadData(props);
                    }).catch(err => {
                        console.error(err);
                    });
            }).catch(err => {
                console.error(err);
            });
    }

    const editSurvey = (csData) => {
        setEditSurveyData(csData);
        setSurveyDataEdit([...csData.customerordersurvey.surveyData]);
    }

    const updateSurvey = () => {
        const data = {
            surveyData: surveyDataEdit,
            _id: editSurveyData.customerordersurvey._id
        };

        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/ordering/updateCustomerSurvey', data)
            .then(result => {
                axios.defaults.headers.common['authorization'] = props.token;
                axios.get('/ordering/getCustomerSurvey', { params: { orderid: currentOrderIdForCustomerSurvey } })
                    .then(result => {
                        setCustomerSurveyData(result.data);
                        setEditSurveyData(false);
                        setSurveyDataEdit([])
                    }).catch(err => {
                        console.error(err);
                    });
            }).catch(err => {
                console.error(err);
            });
    }

    const setSurveyDataEditMethod = (questionId, selectedOption) => {
        var index = null;
        surveyDataEdit.map((sData, i) => {
            if (sData.questionId === questionId) {
                index = i;
            }
        })

        if (index !== null) {
            surveyDataEdit.splice(index, 1);
        }


        const newSurveyData = [
            ...surveyDataEdit,
            {
                'questionId': questionId,
                'selectedOption': selectedOption
            }
        ]

        setSurveyDataEdit(newSurveyData);
    }

    const setSurveyDataMethod = (questionId, selectedOption) => {

        var index = null;
        surveyData.map((sData, i) => {
            if (sData.questionId === questionId) {
                index = i;
            }
        })

        if (index !== null) {
            surveyData.splice(index, 1);
        }


        const newSurveyData = [
            ...surveyData,
            {
                'questionId': questionId,
                'selectedOption': selectedOption
            }
        ]

        setSurveyData(newSurveyData);
    }

    const addCustomerSurvey = () => {

        if (surveyData.length === props.surveyQuestions.length) {
            const data = {
                order_ref: currentOrderIdForCustomerSurvey,
                user_ref: props.userId,
                surveyData: surveyData
            };

            axios.defaults.headers.common['authorization'] = props.token;
            axios.post('/ordering/addCustomerSurvey', data)
                .then(result => {
                    axios.defaults.headers.common['authorization'] = props.token;
                    axios.get('/ordering/getCustomerSurvey', { params: { orderid: currentOrderIdForCustomerSurvey } })
                        .then(result => {
                            setCustomerSurveyData(result.data);
                            setCustomerSurveyView(true);
                            setSurveyData([]);
                            props.reloadData(props);
                        }).catch(err => {
                            console.error(err);
                        });
                }).catch(err => {
                    console.error(err);
                });
        } else {
            toast.error('Please fill all the questions');
        }



    }

    const closeCustomerSurveyPopup = () => {
        setCurrentOrderIdForCustomerSurvey(null);
        setSurveyData([])
        setSurveyDataEdit([])
        setEditSurveyData(false)
    }

    const subColumns = [
        { Header: "Item Code", accessor: "items.itemCode", ...GridFilters },
        { Header: "Name", accessor: "items.name", ...GridFilters },
        {
            Header: "Per Item Price", accessor: "orderitems.unitPrice", filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.orderitems.unitPrice}
                </div>
        },
        { Header: `Quantity - ${_.sumBy(orderItemsData, 'orderitems.quantity') || 0}`, accessor: "orderitems.quantity", ...editableDecimalNumberColumnProps },
        { Header: "Item Discount %", accessor: "orderitems.discount", ...editableNumberColumnProps },
        {
            Header: "Total Price", accessor: "orderitems.unitPrice", filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.orderitems.price)}
                </div>
        },
    ]

    const action = {
        Header: <button id='addOrderItems' className='btn' data-toggle='modal' data-target='#addOrderItem' style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}
            onClick={() => {
                props.getItems(props.token);
                setAddOrderItems([])
            }}
        >+</button>,
        maxWidth: 130,
        minWidth: 130,
        filterable: false,
        sortable: false,
        getProps: getActionProps,
        Cell: OrderActionsCell
    }

    const addOrderColumns = [
        { Header: "Name", accessor: "name", filterable: true },
        { Header: "Item Code", accessor: "itemCode", filterable: true },
        // { Header: "Description", accessor: "desc", filterable: true },
        {
            Header: "Price", accessor: "price", ...editableNumberColumnProps,
            // Cell: props =>
            //     <div>
            //         {props.original.price} {props.original.priceUnit}
            //     </div>
        },
        {
            Header: "Duration", accessor: "duration", filterable: true,
            Cell: props =>
                <div>
                    {props.original.duration} {props.original.durationUnit}
                </div>
        },
        { Header: 'Qty', accessor: "qty", ...editableNumberColumnProps },
        { Header: 'Discount %', accessor: "discount", ...editableNumberColumnProps },
        // { Header: 'Discount %', accessor: "discountPercentage", ...editableNumberColumnProps },
        {
            Header: "",
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const verification = () => {
        console.log(process.env.JWT_KEY)
        jwt.verify(props.token, 'a97d5873ae4225726b2406ae06beca217e129bba8b765eb49ee879b9546d9915bb1fbe16374', (err, decode) => {
            if (err) { console.log('AUTH ERROR  :' + err); }
            else {
                // console.log(decode);
                var customer = _.filter(props.customers, { _id: decode.userID })
                // var data = _.filter(props.address, { 'user': customer[0]._id })
                // console.log(customer)
                // console.log(data[0])
                // console.info(data)
                // if (data.length > 0) {
                setRSelectCustomer(customer[0])
                // setAddOrder({ ...addOrder, telephone: customer[0].phone, address: customer[0].streetAddress, billingAddress: customer[0]._id, deliveryAddress: customer[0]._id })
                // setAddOrder({ ...addOrder, name: 'test', telephone: customer[0].phone, address: data[0].streetAddress, billingAddress: data[0]._id, deliveryAddress: data[0]._id })
                // }
            }
        })
    }

    /**
     * Edited row values in add order
     * 
     * @param {Object} value 
     */
    const handleSubmit = value => {
        console.info(value)
        if (edit !== null) {
            // console.log(value)
            var data = _.filter(addOrder.orderItems, { '_id': value._id })
            data[0].price = Number(value.price)
            data[0].qty = Number(value.qty)
            data[0].discount = Number(value.discount === undefined ? 0 : value.discount)
            // console.log(data)
            setTotal({ ...total, qty: _.sumBy(addOrder.orderItems, 'qty'), value: _.sumBy(addOrder.orderItems, function (o) { return o.qty * (o.price - (o.price * (o.discount / 100))) }) })
        } else if (remove !== null) {
            // console.log(value)
            var data = _.remove(addOrder.orderItems, n => { return n._id === value._id })
            console.info(data)
            console.info(addOrder)
            setTotal({ ...total, qty: _.sumBy(addOrder.orderItems, 'qty'), value: _.sumBy(addOrder.orderItems, function (o) { return o.qty * (o.price - (o.price * (o.discount / 100))) }) })
        }
    }

    const handleOrderSubmit = value => {
        // console.log('order')
        // console.log(value)
        // console.log(edit)
        if (edit !== null && remove === null) {

            if (value.orders !== undefined) {
                let origionalOrder = _.filter(props.data, function (n) { return n.orders._id === value.orders._id })
                let diffAmountPaid = 0;

                // if amount paid changed where cash payments
                if (value.orders.paymentOption === "5eb036974ce3fb5a1af10216") {
                    diffAmountPaid = Number(value.orders.amountPaid) - Number(origionalOrder[0].orders.amountPaid);
                }
                var orderData = {
                    _id: value.orders._id,
                    orderId: value.orders.orderId,
                    whatsapp: value.orders.whatsapp,
                    telephone: value.orders.telephone,
                    address: value.orders.address,
                    city: edit.cities !== undefined ?
                        value.cities.name !== edit.cities.name ?
                            value.cities.name
                            : edit.cities._id
                        : value.cities !== undefined ?
                            value.cities.name
                            : '',
                    paymentOption: edit.paymentmethods.name !== value.paymentmethods.name ? _.filter(props.paymentMethods, { _id: value.paymentmethods.name })[0]._id : value.orders.paymentOption,
                    status: value.orders.status,
                    isFixedCharged: value.orders.isFixedCharged,
                    deliveryCharges: Number(value.orders.deliveryCharges),
                    orderPickUp: (value.orders.orderPickUp === true || value.orders.orderPickUp === 'true') ? 'true' : 'false',
                    amountPaid: Number(value.orders.amountPaid),
                    tax: Number(value.orders.tax),
                    totalDiscount: Number(value.orders.totalDiscount),
                    orderItems: value.orders.orderItems,
                    etd: new Date(value.orders.etd),
                    note: value.orders.note,
                    name: value.orders.name,
                    totalPrice: value.orders.totalPrice,
                    diffAmountPaid: diffAmountPaid,
                    deliveryPerson: edit.deliverycrew !== undefined ?
                        value.deliverycrew.name !== edit.deliverycrew.name ?
                            value.deliverycrew.name
                            : edit.deliverycrew._id
                        : value.deliverycrew !== undefined ?
                            value.deliverycrew.name
                            : '',
                    // loggedUser: props.loggedUser.first + props.loggedUser.first !== undefined ? " " + props.loggedUser.first : "",
                    loggedUser: props.userId,
                    isEtdChange: value.orders.etd === edit.orders.etd ? value.orders.isEtdChange : true,
                    warehouseId: edit.deliverywarehouse !== undefined ?
                        value.deliverywarehouse.name !== edit.deliverywarehouse.name ?
                            value.deliverywarehouse.name
                            : edit.deliverywarehouse._id
                        : value.deliverywarehouse !== undefined ?
                            value.deliverywarehouse.name
                            : '',
                }
                // console.log('order Data')
                // console.log(orderData)
                if (DeliveredStatus.includes(edit.orders.status) && value.orders.amountPaid !== 0) {
                    toast.info('Order already delivered, you can not change the status...!')
                    let orderStatusData = {
                        _id: value.orders._id,
                        paymentOption: edit.paymentmethods.name !== value.paymentmethods.name ? _.filter(props.paymentMethods, { _id: value.paymentmethods.name })[0]._id : value.orders.paymentOption,
                        // status: value.orders.status,
                        orderId: value.orders.orderId,
                        deliveryCharges: Number(value.orders.deliveryCharges),
                        amountPaid: Number(value.orders.amountPaid),
                        orderItems: edit.orders.orderItems,
                        name: value.orders.name,
                        whatsapp: value.orders.whatsapp,
                        telephone: value.orders.telephone,
                        totalPrice: value.orders.totalPrice,
                        diffAmountPaid: diffAmountPaid,
                        orderPickUp: (value.orders.orderPickUp === true || value.orders.orderPickUp === 'true') ? 'true' : 'false',
                        note: value.orders.note,
                        city: edit.cities !== undefined ?
                            value.cities.name !== edit.cities.name ?
                                value.cities.name
                                : edit.cities._id
                            : value.cities !== undefined ?
                                value.cities.name
                                : '',
                        // loggedUser: props.loggedUser.first + props.loggedUser.first !== undefined ? " " + props.loggedUser.first : ""
                        loggedUser: props.userId,
                        deliveryPerson: edit.deliverycrew !== undefined ?
                            value.deliverycrew.name !== edit.deliverycrew.name ?
                                value.deliverycrew.name
                                : edit.deliverycrew._id
                            : value.deliverycrew !== undefined ?
                                value.deliverycrew.name
                                : '',

                        warehouseId: edit.deliverywarehouse !== undefined ?
                            value.deliverywarehouse.name !== edit.deliverywarehouse.name ?
                                value.deliverywarehouse.name
                                : edit.deliverywarehouse._id
                            : value.deliverywarehouse !== undefined ?
                                value.deliverywarehouse.name
                                : '',

                    }
                    props.updateOrderStatus(orderStatusData, props.token);
                } else {
                    // toast.warning('test')
                    props.updateOrder(orderData, props.token);
                }
            } else if (value.orderitems !== undefined) {
                // console.log('order items')
                // console.log(value)
                let preItem = _.filter(orderItemsData, function (n) { return n.orderitems._id === value.orderitems._id })
                // console.log(preItem)
                let slugArr = value.orderitems.slug.split('_');
                let nameArr = value.orderitems.name.split('_');
                slugArr[1] = value.orderitems.quantity;
                slugArr[2] = value.items.price;
                nameArr[1] = value.orderitems.quantity;
                nameArr[2] = value.items.price;
                let priceWithDis = (Number(value.orderitems.unitPrice) - (Number(value.orderitems.unitPrice) * Number(value.orderitems.discount) / 100)) * Number(value.orderitems.quantity)
                var orderItemData = {
                    _id: value.orderitems._id,
                    ref_order: order_id,
                    item_id: value.items._id,
                    quantity: Number(value.orderitems.quantity),
                    discount: Number(value.orderitems.discount),
                    name: nameArr.join('_'),
                    slug: slugArr.join('_'),
                    orderItems: orderItems,
                    diffQty: Number(value.orderitems.quantity) - Number(preItem[0].orderitems.quantity),
                    price: priceWithDis
                }
                // console.log(orderItemData)
                if (DeliveredStatus.includes(order.status)) {
                    toast.info('Order already delivered...!')
                } else {
                    props.updateOrderItem(orderItemData, props.token);
                }
            }
        } else if (remove !== null && edit === null) {
            // console.log('item remove')
            // console.log(value)
            if (value.orders !== undefined) {
                console.info('order delete')
                if (DeliveredStatus.includes(remove.orders.status)) {
                    toast.info('Order already delivered...!')
                } else if (remove.orders.amountPaid > 0) {
                    toast.info('Amount is already paid...!')
                } else {
                    let data = deleteData("order")
                    data.then(function (result) {
                        value.orders.reason = result.value;
                        if (result.isConfirmed === true) {
                            props.deleteOrder({ ...value.orders, userId: props.userId }, props.token);
                        }
                    })
                }
            } else if (value.orderitems !== undefined) {
                var orderItemObj = {
                    _id: value.orderitems._id,
                    items: orderItems,
                    ref_order: order_id,
                    item_id: value.items._id,
                    qty: value.orderitems.quantity,
                    userId: props.userId
                }

                if (DeliveredStatus.includes(order.status)) {
                    toast.info('Order already delivered...!')
                } else {
                    let data = deleteData("order item")
                    data.then(function (result) {
                        orderItemObj.reason = result.value;
                        setorderItemToBeReplacement(orderItemObj)
                        if (orderItemObj.reason === 'replacement') {
                            const button = document.getElementById("replacementModalButton");
                            button.click();
                        } else {
                            if (result.isConfirmed === true) {
                                props.deleteOrderItem(orderItemObj, props.token)
                            }
                        }
                    })
                }
            }
        }
    }

    /**
     * Edited row value in add order items
     * 
     * @param {Object} value 
     */
    const handleOrderItemSubmit = value => {
        console.info(value)
        if (edit !== null) {
            // console.log(value)
            var data = _.filter(addOrderItems, { '_id': value._id })
            data[0].price = Number(value.price)
            data[0].qty = Number(value.qty)
            data[0].discount = Number(value.discount)
            // console.log(data)
        } else if (remove !== null) {
            // console.log(value)
            var data = _.remove(addOrderItems, n => { return n._id === value._id })
            // console.info(data)
            // console.info(addOrderItems)
        }
    }

    /**
     * Export data to excell
     */
    const exportData = () => {
        // props.getOrderingData(props.token);
        // console.log('export data')
        // console.log(exportDate)
        var exportData = props.data.filter(orderData => {
            if ((exportRegion === 'all') || (exportRegion === null)) {
                // if ((new Date(orderData.orders.createdAt) < new Date(exportDate.to)) && (new Date(orderData.orders.createdAt) > new Date(exportDate.from))) {
                if ((new Date(exportDataFlag ? orderData.orders.etd : orderData.orders.createdAt) >= new Date(exportDate.from)) && ((new Date(exportDataFlag ? orderData.orders.etd : orderData.orders.createdAt) <= new Date(exportDate.to)))) {
                    return orderData
                }
            } else if ((new Date(exportDataFlag ? orderData.orders.etd : orderData.orders.createdAt) >= new Date(exportDate.from)) && ((new Date(exportDataFlag ? orderData.orders.etd : orderData.orders.createdAt) <= new Date(exportDate.to))) && (orderData.regions?.name == exportRegion)) {
                return orderData
            }
        })
        // console.log(exportData)
        var excelData = exportData.map(checkData => {
            return {
                'Order Id': checkData.orders.orderId,
                'Name': checkData.orders.name,
                'Telephone': checkData.orders.telephone,
                'Whatsapp no': checkData.orders.whatsapp,
                'Address': checkData.orders.address,
                'City': checkData.cities !== undefined ? checkData.cities.name : "",
                'Payment Method': checkData.paymentmethods.name,
                'Status': checkData.orders.status,
                'Amount Paid': checkData.orders.amountPaid,
                'Delivery Charge': checkData.orders.deliveryCharges,
                'Tax': checkData.orders.tax,
                'Order Discount': checkData.orders.totalDiscount,
                'Total Price': checkData.orders.totalPrice,
                'Note': checkData.orders.note
            }
        })
        ExportData(`Orders`, 'Orders details', "orders", excelData)
    }

    const exportDataPOS = (data) => {

        var excelData = data.filtered.map(checkData => {
            return {
                'Name': checkData.name,
                'Price': checkData.price,
                'IsEnabled': checkData.isEnabled
            }
        })
        ExportData(data.orderId, 'Item details', data.orderId, excelData)
    }

    const exportDataAll = () => {
        axios.defaults.headers.common['authorization'] = props.token;
        axios.get('/ordering/excelExportOrderManual')
            .then(result => {
                console.log(result.data)
                var excelData = result.data.map(checkData => {
                    return {
                        'Order Id': checkData.orderId,
                        'Name': checkData.name,
                        'Telephone': checkData.telephone,
                        'Whatsapp no': checkData.whatsapp,
                        'Address': checkData.address,
                        'City': checkData.city !== undefined ? checkData.city : "",
                        'Region': checkData.region !== undefined ? checkData.region : "",
                        'Payment Method': checkData.paymentMethod,
                        'Status': checkData.status,
                        'Amount Paid': checkData.amountPaid,
                        'Delivery Charge': checkData.deliveryCharges,
                        'Tax': checkData.tax,
                        'Order Discount': checkData.totalDiscount,
                        'Total Price': checkData.totalPrice,
                    }
                })
                ExportData(`2022-01-01-2022-09-30 order report`, 'Orders details', "orders", excelData)
            }).catch(err => {
                console.error(err);
            });
    }

    const updateDeliveryCharge = () => {
        props.updateDeliveryCharge(deliveryCharge, props)

    }

    const componentRef = useRef();
    const transferToHistory = () => {
        var transferData = props.data.filter(orderData => {
            if (
                (new Date(orderData.orders.createdAt) >= new Date(exportDate.from))
                && ((new Date(orderData.orders.createdAt) <= new Date(exportDate.to)))
                && DeliveredStatus.includes(orderData.orders.status)
                && (orderData.orders.amountPaid >= orderData.orders.totalPrice)) {
                return orderData;
            }
        })

        var orderIdsArr = [];
        var orderItemsIdsArr = [];

        transferData.forEach(element => {
            const order = element.orders;

            orderIdsArr.push(order._id);

            order.orderItems.forEach(orderItemElement => {
                orderItemsIdsArr.push(orderItemElement);
            });
        });

        if (orderIdsArr.length > 0) {

            var i, j, orderIds, orderIdsChunk = 1000;

            for (i = 0, j = orderIdsArr.length; i < j; i += orderIdsChunk) {
                orderIds = orderIdsArr.slice(i, i + orderIdsChunk);

                axios.defaults.headers.common['authorization'] = props.token;
                axios.post('/ordering/transferOrderHistoryData', { orderIds })
                    .then(result => {
                    }).catch(err => {
                    })
            }


            var k, l, orderItemsIds, orderItemsIdsChunk = 1000;

            for (k = 0, l = orderItemsIdsArr.length; k < l; k += orderItemsIdsChunk) {
                orderItemsIds = orderItemsIdsArr.slice(k, k + orderItemsIdsChunk);

                axios.defaults.headers.common['authorization'] = props.token;
                axios.post('/ordering/transferOrderItemHistoryData', { orderItemsIds })
                    .then(result => {
                    }).catch(err => {
                    })

            }

            props.getOrderingData(props.token);
            toast.success('Transfer Completed!');
        } else {
            toast.info('No Records found for the selected date range!');
        }
    }

    const transferByOrderId = () => {

        var transferData = props.data.filter(orderData => {
            if (orderData.orders.orderId === order_id
                && DeliveredStatus.includes(orderData.orders.status)
                && (orderData.orders.amountPaid >= orderData.orders.totalPrice)) {
                return orderData;
            }
        })

        if (transferData.length === 0) {
            toast.info('Either Order ID is invalid or Order is not delivered yet!');
        } else {
            var orderIds = [];
            var orderItemsIds = [];

            transferData.forEach(element => {
                const order = element.orders;

                orderIds.push(order._id);

                order.orderItems.forEach(orderItemElement => {
                    orderItemsIds.push(orderItemElement);
                });
            });

            axios.defaults.headers.common['authorization'] = props.token;
            axios.post('/ordering/transferOrderHistoryData', { orderIds })
                .then(result => {
                }).catch(err => {
                })

            axios.defaults.headers.common['authorization'] = props.token;
            axios.post('/ordering/transferOrderItemHistoryData', { orderItemsIds })
                .then(result => {
                }).catch(err => {
                })

            props.getOrderingData(props.token);
            toast.success('Transfer Completed!');

        }
    }

    const visibleColumns = columns.filter(column => column.show);

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setCheckValue(isChecked ? true : false);
    };

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <div style={{ float: 'left' }}>
                        {/* realod button */}
                        <button className="btn btn-primary" disabled={props.isLoading} onClick={() => props.reloadData(props)} style={{ float: 'left' }}>
                            <i className="fas fa-sync"></i>
                        </button>
                        {/* add order button */}
                        <button className="btn btn-primary" data-toggle="modal" data-target="#addOrdering" style={{ float: 'left' }}
                            disabled={props.isLoading ? true : Permission('ORDERS', 'ORDER', 'ADD', props)}
                            onClick={async () => {
                                $('#billing_address').removeClass('parsley-error');
                                $('#delivery_address').removeClass('parsley-error');
                                $('#payment_option').removeClass('parsley-error');
                                $('#warehouse').removeClass('parsley-error');
                                let randomNumber = Math.floor(Math.random() * 10000 + 1);
                                let orderId = new Date().toISOString().slice(0, 10) + "-" + randomNumber;
                                await setAddOrder({ ...addOrder, orderId })
                                await verification()
                                // await setAddOrder({ orderId: '', name: '', address: '', paymentOption: '', telephone: '', billingAddress: '', deliveryAddress: '', note: '', orderItems: [] })
                                // setRSelectCustomer([])
                            }}>+ Order</button>
                        {/* export data button */}
                        <button className="btn btn-info" disabled={props.isLoading} data-toggle="modal" data-target="#exportData">Export Data</button>
                        {/* <button className="btn btn-info" disabled={props.isLoading} data-toggle="modal" onClick={exportDataAll} >Export Data All</button> */}

                        {/* transfer to history button */}
                        <button className="btn btn-info" disabled={props.isLoading} data-toggle="modal" data-target="#transferToHistory">Transfer To History</button>
                        {/* <div className="form-check form-switch form-check-lg">
                            <input className="form-check-input" type="checkbox" role="switch" value={checkValue} id="checkbox-3" onClick={(e) => handleCheckboxChange(e)} />
                            <label className="form-check-label font-weight-bold" for="checkbox-3">Show Columns</label>
                        </div> */}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <div class="checkbox-wrapper-64">
                            <label class="switch">
                                <input type="checkbox" onClick={(e) => handleCheckboxChange(e)} />
                                <span class="slider"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <ErrorBoundary>
                    <div className="card-body">
                        {props.isLoading
                            ? <>
                                <SkeletonTable columnsCount={9} dataCount={10} />
                            </>
                            : <FormProvider
                                form="inline"
                                onSubmit={handleOrderSubmit}
                                onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                initialValues={edit !== null ? edit : remove}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                columns={checkValue === false ? visibleColumns : columns}
                                                data={props.data}
                                                defaultPageSize={10}
                                                expanded={expanded}
                                                defaultFilterMethod={filterCaseInsensitive}
                                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                                SubComponent={row => {
                                                    setOrder_id(row.original.orders._id)
                                                    setOrder(row.original.orders)
                                                    setOrderItems(row.original.orders.orderItems)

                                                    return (
                                                        <div>
                                                            {orderItemsData !== null ?
                                                                <React.Fragment>
                                                                    <ErrorBoundary>
                                                                        <FormProvider
                                                                            form="inline"
                                                                            enableReinitialize
                                                                        >
                                                                            {formProps => {
                                                                                return (
                                                                                    <form>
                                                                                        <Table
                                                                                            columns={DeliveredStatus.includes(row.original.orders.status) ? subColumns : [...subColumns, action]}
                                                                                            data={orderItemsData}
                                                                                            defaultPageSize={5} />
                                                                                    </form>
                                                                                )
                                                                            }}
                                                                        </FormProvider>
                                                                    </ErrorBoundary>
                                                                </React.Fragment>
                                                                :
                                                                orderItemsData === null ? <div>No found data...!</div> : <div>Loading...!</div>}
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </form>)
                                }}
                            </FormProvider>}
                    </div>
                </ErrorBoundary>

                {/* Add new order */}
                <ErrorBoundary>
                    <div id="addOrdering" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Order</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                        onClick={() => {
                                            setRSelectCustomer([])
                                            setAddOrder({ ...addOrder, orderId: '', name: '', address: '', paymentOption: '', telephone: '', billingAddress: '', deliveryAddress: '', orderItems: [] })
                                        }}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Order Id :</td>
                                                    <td>{addOrder.orderId}</td>
                                                    <td style={{ paddingLeft: '10px' }}>Customer :</td>
                                                    <td>
                                                        <ErrorBoundary>
                                                            <Select
                                                                options={props.customers}
                                                                value={rSelectCustomer}
                                                                theme={theme => ({ ...theme, borderRadius: 0 })}
                                                                onChange={e => {
                                                                    setRSelectCustomer(e)
                                                                    // console.info(e)
                                                                    setAddOrder({ ...addOrder, user: e._id })
                                                                    // var data = _.filter(props.address, { 'user': e._id })
                                                                    // console.info(data)
                                                                    //     if (data.length > 0) {
                                                                    //         setAddOrder({ ...addOrder, name: e.label, telephone: data[0].phone, address: data[0].streetAddress, billingAddress: data[0]._id, deliveryAddress: data[0]._id })
                                                                    //     }
                                                                }}
                                                            />
                                                        </ErrorBoundary>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Name :</td>
                                                    <td><input type='text' className='form-control' value={addOrder.name} onChange={e => setAddOrder({ ...addOrder, name: e.target.value })} /></td>
                                                    <td style={{ paddingLeft: '10px' }}>Telephone :</td>
                                                    <td>
                                                        <input type='number' className='form-control' value={addOrder.telephone} maxLength={9}
                                                            onChange={e => {
                                                                var val = _.find(props.data, function (o) {
                                                                    // console.log(o)
                                                                    // eslint-disable-next-line eqeqeq
                                                                    return o.orders.telephone == e.target.valueAsNumber
                                                                })
                                                                // console.log(val)
                                                                if (val !== undefined) {
                                                                    setIsWhatsapp(false)
                                                                    setAddOrder({
                                                                        ...addOrder,
                                                                        name: val?.orders?.name.split('-').pop(),
                                                                        telephone: e.target.value,
                                                                        address: val.orders.address,
                                                                        whatsapp: val.orders.whatsapp,
                                                                        city: val.orders.city
                                                                    })
                                                                } else {
                                                                    setAddOrder({ ...addOrder, telephone: e.target.value })
                                                                }
                                                            }} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input type="checkbox" checked={isWhatsapp} className="form-check-input" id="checkWhatsapp"
                                                                onClick={() => {
                                                                    setIsWhatsapp(!isWhatsapp)
                                                                }} />
                                                            <label className="form-check-label" htmlFor="exampleCheck1">Use telephone no as whatsapp no</label>
                                                        </div>
                                                    </td>
                                                    {!isWhatsapp ? <td colSpan={3}>
                                                        <PhoneInput
                                                            country={'lk'}
                                                            value={addOrder.whatsapp}
                                                            onChange={phone => { console.log(phone); setAddOrder({ ...addOrder, whatsapp: phone }) }}
                                                        />
                                                    </td> : null}
                                                </tr>
                                                <tr>
                                                    <td>Address :</td>
                                                    <td><textarea type='text' className='form-control' value={addOrder.address} onChange={e => setAddOrder({ ...addOrder, address: e.target.value })} /></td>

                                                    <td style={{ paddingLeft: '10px' }}>Billing Address* :</td>
                                                    <td>
                                                        <select id='billing_address' className='form-control' value={addOrder.billingAddress}
                                                            onChange={e => {
                                                                $('#billing_address').removeClass('parsley-error');
                                                                setAddOrder({ ...addOrder, billingAddress: e.target.value })
                                                            }}>
                                                            <option value={''}>- Select Billing Address -</option>
                                                            {props.address.map(data => {
                                                                // if (data.id === addOrder.billingAddress) {
                                                                //     return <option selected="selected" value={data._id}>{data.streetAddress}</option>
                                                                // }
                                                                return <option key={data._id} value={data._id}>{data.streetAddress}</option>
                                                            })}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        <div className="form-check">
                                                            <input type="checkbox" checked={addOrder.orderPickUp} className="form-check-input" id="checkOrderPickUp"
                                                                onClick={() => {
                                                                    setAddOrder(prevState => {
                                                                        return { ...prevState, orderPickUp: !prevState.orderPickUp }
                                                                    })
                                                                }} />
                                                            <label className="form-check-label" for="exampleCheck1">Order pick up at office</label>
                                                        </div>
                                                    </td>
                                                    {
                                                        addOrder.orderPickUp ? null :
                                                            <>
                                                                <td>Delivery Address* :</td>
                                                                <td>
                                                                    <select id='delivery_address' className='form-control' value={addOrder.deliveryAddress}
                                                                        onChange={e => {
                                                                            $('#delivery_address').removeClass('parsley-error');
                                                                            setAddOrder({ ...addOrder, deliveryAddress: e.target.value })
                                                                        }}>
                                                                        <option value={''}>- Select Delivery Address -</option>
                                                                        {props.address.map(data => {
                                                                            return <option value={data._id} key={data._id}>{data.streetAddress}</option>
                                                                        })}
                                                                    </select>
                                                                </td>
                                                            </>
                                                    }
                                                </tr>
                                                <tr>
                                                    {/* <td>Delivery Address* :</td>
                                                    <td>
                                                        <select id='delivery_address' className='form-control' value={addOrder.deliveryAddress}
                                                            onChange={e => {
                                                                $('#delivery_address').removeClass('parsley-error');
                                                                setAddOrder({ ...addOrder, deliveryAddress: e.target.value })
                                                            }}>
                                                            <option value={''}>- Select Delivery Address -</option>
                                                            {props.address.map(data => {
                                                                return <option value={data._id}>{data.streetAddress}</option>
                                                            })}
                                                        </select>
                                                    </td> */}

                                                    <td style={{ width: '16%', paddingLeft: '10px' }}>Payment Option* :</td>
                                                    <td>
                                                        <select id='payment_option' className='form-control' value={addOrder.paymentOption}
                                                            onChange={e => {
                                                                $('#payment_option').removeClass('parsley-error');
                                                                setAddOrder({ ...addOrder, paymentOption: e.target.value })
                                                            }}>
                                                            <option value={''}>- Select Payment Option -</option>
                                                            {props.paymentMethods.map(paymentMethod => {
                                                                return <option key={paymentMethod._id} value={paymentMethod._id}>{paymentMethod.description}</option>
                                                            })}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Note :</td>
                                                    <td><input type='text' value={addOrder.note} className='form-control' onChange={e => setAddOrder({ ...addOrder, note: e.target.value })} /></td>
                                                    <td style={{ paddingLeft: '10px' }}>City :</td>
                                                    <td>
                                                        <ErrorBoundary>
                                                            <select value={addOrder.city} className='form-control' onChange={e => setAddOrder({ ...addOrder, city: e.target.value })}>
                                                                <option value={''}>- Select City -</option>
                                                                {props.city.map(city => {
                                                                    return <option key={city._id} value={city._id}>{city.name}</option>
                                                                })}
                                                            </select>
                                                        </ErrorBoundary>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {/* <td>Product :</td>
                                                    <td>
                                                        <Select
                                                            options={props.productData}
                                                            onChange={e => setProduct(e._id)}
                                                        />
                                                    </td>
                                                    {product !== null ?
                                                        <> */}
                                                    <td>Item :</td>
                                                    <td>
                                                        <td style={{ width: '270px' }}>
                                                            <Select
                                                                options={props.itemData}
                                                                value={[]}
                                                                theme={theme => ({ ...theme, borderRadius: 0 })}
                                                                onChange={e => {
                                                                    // console.log(e)
                                                                    let order_items = addOrder.orderItems.filter(items => { return items._id === e._id })
                                                                    if (order_items.length === 0) {
                                                                        setAddOrder({
                                                                            ...addOrder,
                                                                            orderItems: [
                                                                                ...addOrder.orderItems,
                                                                                {
                                                                                    ...e,
                                                                                    qty: 1,
                                                                                    discount: (e.discountPercentage === null || e.discountPercentage === undefined) ?
                                                                                        0
                                                                                        : e.discountPercentage,
                                                                                    discountPercentage: (e.discountPercentage === null || e.discountPercentage === undefined) ?
                                                                                        0
                                                                                        : e.discountPercentage
                                                                                }]
                                                                        })
                                                                        // setTotal({ ...total, qty: total.qty + 1, value: Number(total.value + Number(e.price)) })
                                                                    } else if (order_items.length > 0) {
                                                                        toast.info('Items already added...!')
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ width: '20px' }}>
                                                            <button className="btn btn-sm" onClick={() => props.getItems(props.token)}>
                                                                <i className="fas fa-sync"></i>
                                                            </button>
                                                        </td>
                                                    </td>
                                                    <td style={{ paddingLeft: '10px' }}>Total qty : {total.qty}</td>
                                                    <td>Total Value : {total.value}</td>
                                                    {/* </>
                                                        : null} */}
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Warehouse*:
                                                    </td>
                                                    <td>
                                                        <select value={addOrder.warehouse} id='warehouse' className='form-control' onChange={e => {
                                                            $('#warehouse').removeClass('parsley-error');
                                                            setAddOrder({ ...addOrder, warehouse: e.target.value })
                                                        }}>
                                                            <option value=''>Select Warehouse</option>
                                                            {
                                                                props.deliveryWarehousesForUser.map(dWarehouse => {
                                                                    return <option value={dWarehouse._id}>{dWarehouse.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {addOrder.orderItems.length > 0 ?
                                            <ErrorBoundary>
                                                <div>
                                                    <FormProvider
                                                        form="inline"
                                                        onSubmit={handleSubmit}
                                                        onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                                        initialValues={edit !== null ? edit : remove}
                                                        enableReinitialize
                                                    >
                                                        {formProps => {
                                                            return (
                                                                <form onSubmit={formProps.handleSubmit}>
                                                                    <Table
                                                                        columns={addOrderColumns}
                                                                        data={addOrder.orderItems}
                                                                        defaultPageSize={5}
                                                                        showPagination={addOrder.orderItems.length < 5 ? false : true}
                                                                    />
                                                                </form>
                                                            )
                                                        }}
                                                    </FormProvider>
                                                </div>
                                            </ErrorBoundary>
                                            : null}
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="add_order" type="button" disabled={(edit === null && addOrder.orderItems.length > 0) ? false : true} className="btn btn-primary"
                                        // data-dismiss="modal" 
                                        onClick={() => {
                                            // console.log(addOrder)
                                            if (addOrder.paymentOption !== '' && addOrder.billingAddress !== '' && (addOrder.orderPickUp || addOrder.deliveryAddress !== '') && addOrder.warehouse !== '') {
                                                props.addOrder(addOrder, props.token)
                                                window.$('#addOrdering').modal('toggle');
                                                setRSelectCustomer([])
                                                setAddOrder({ orderId: '', name: '', address: '', paymentOption: '', telephone: '', billingAddress: '', deliveryAddress: '', city: '', note: '', orderItems: [], user: '', warehouse: '' })
                                            } else {
                                                // $('#addOrdering').modal('toggle');
                                                if (addOrder.paymentOption === '' && addOrder.billingAddress === '' && addOrder.deliveryAddress === '') {
                                                    $('#billing_address').addClass('parsley-error');
                                                    $('#delivery_address').addClass('parsley-error');
                                                    $('#payment_option').addClass('parsley-error');
                                                } else if (addOrder.billingAddress === '') {
                                                    $('#billing_address').addClass('parsley-error');
                                                } else if (addOrder.deliveryAddress === '') {
                                                    $('#delivery_address').addClass('parsley-error');
                                                } else if (addOrder.paymentOption === '') {
                                                    $('#payment_option').addClass('parsley-error');
                                                } else if (addOrder.warehouse === '') {
                                                    $('#warehouse').addClass('parsley-error');
                                                }
                                                toast.warn('Check your address, payment option or warehouse...!')
                                            }
                                        }}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                        onClick={() => {
                                            setRSelectCustomer([])
                                            setAddOrder({ orderId: '', name: '', address: '', paymentOption: '', telephone: '', billingAddress: '', deliveryAddress: '', city: '', note: '', orderItems: [], warehouse: '' })
                                        }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>

                {/* Add new order item */}
                <ErrorBoundary>
                    <div id='addOrderItem' className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg" role='document'>
                            <div className="modal-content animate">
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Order Item</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                        onClick={() => setAddOrderItems([])}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <Select
                                        options={props.itemData}
                                        value={[]}
                                        onChange={e => {
                                            let checkId = _.filter(orderItemsData, function (o) {
                                                return o.items._id === e._id
                                            })

                                            let addCheckId = _.filter(addOrderItems, function (o) {
                                                return o._id === e._id
                                            })
                                            // console.log(checkId)
                                            // console.log(addCheckId)
                                            if (checkId.length === 0 && addCheckId.length === 0) {
                                                setAddOrderItems([...addOrderItems, { ...e, qty: 1, discount: e.discountPercentage === null ? 0 : e.discountPercentage }])
                                            } else {
                                                toast.info('Already add this item. Please modify it...!')
                                            }
                                        }}
                                    />
                                </div>
                                {addOrderItems.length > 0 ?
                                    <ErrorBoundary>
                                        <div>
                                            <FormProvider
                                                form="inline"
                                                onSubmit={handleOrderItemSubmit}
                                                onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                                initialValues={edit !== null ? edit : remove}
                                                enableReinitialize
                                            >
                                                {formProps => {
                                                    return (
                                                        <form onSubmit={formProps.handleSubmit}>
                                                            <Table
                                                                columns={addOrderColumns}
                                                                data={addOrderItems.reverse()}
                                                                defaultPageSize={5}
                                                                showPagination={true}
                                                            // addOrder.orderItems.length < 5 ? false : 
                                                            />
                                                        </form>
                                                    )
                                                }}
                                            </FormProvider>
                                        </div>
                                    </ErrorBoundary>
                                    : null}
                                <div className="modal-footer">
                                    <button id="add_order" type="button" className="btn btn-primary" disabled={edit !== null ? true : addOrderItems.length === 0 ? true : false} data-dismiss="modal" onClick={() => {
                                        console.log(addOrderItems)
                                        props.addOrderItems({ orderId: order_id, orderItems: addOrderItems, orderItemsId: orderItems }, props.token)
                                    }}>Add Order Items</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                        onClick={() => setAddOrderItems([])}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>

                {/* update delivery charge */}
                <ErrorBoundary>
                    <div id='changeDeliveryCharge' className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg" role='document'>
                            <div className="modal-content animate">
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Change delivery charge</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                        onClick={() => setAddOrderItems([])}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {(deliveryCharge?.order?.orderPickUp === true || DeliveredStatus.includes(deliveryCharge?.order?.status) && deliveryCharge?.order?.amountPaid > 0)
                                        ? <>
                                            office pickup order or already delivered order. You can not add a delivery charge
                                        </>
                                        : <table>
                                            <thead>
                                                <th colSpan='2'>
                                                    <div className="form-check">
                                                        <input type="checkbox" checked={deliveryCharge.isFixed} className="form-check-input" id="checkDeliveryChange"
                                                            onClick={() => {
                                                                setDeliveryCharge(prevState => { return { ...prevState, isFixed: !prevState.isFixed } })
                                                            }} />
                                                        <label class="form-check-label" for="exampleCheck1">Want to fixed delivery change </label>
                                                    </div>
                                                </th>
                                            </thead>
                                            {deliveryCharge.isFixed
                                                ? <tr>
                                                    <td>
                                                        Delivery Charge :
                                                    </td>
                                                    <td>
                                                        <input type='number' className='form-control' onChange={e => setDeliveryCharge({ ...deliveryCharge, charge: Number(e.target.value) })} />
                                                    </td>
                                                </tr>
                                                : null}
                                        </table>}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" disabled={(deliveryCharge?.order?.orderPickUp === true || DeliveredStatus.includes(deliveryCharge?.order?.status)) && deliveryCharge?.order?.amountPaid > 0 ? true : false} data-dismiss="modal" onClick={updateDeliveryCharge}>Update now</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>

                <ErrorBoundary>
                    <div id="invoicePopup" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate">
                                {/* <div className="modal-header">
                                    <button
                                        onClick={() => {
                                            var DocumentContainer = document.getElementById(
                                                "invoice"
                                            );
                                            var WindowObject = window.open(
                                                "",
                                                "PrintWindow",
                                                "width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes"
                                            );
                                            WindowObject.document.writeln(
                                                DocumentContainer.innerHTML
                                            );
                                            WindowObject.document.close();
                                            setTimeout(function () {
                                                WindowObject.focus();
                                                WindowObject.print();
                                                WindowObject.close();
                                            }, 1000);
                                        }}
                                    >
                                        PRINT
                  </button>
                                    <button type="button" className="close" data-dismiss="modal">
                                        &times;
                  </button>
                                </div> */}
                                <div className="modal-header">
                                    <button disabled={isPrintLoading} className='btn btn-secondary'
                                        onClick={() => {
                                            var DocumentContainer = document.getElementById(
                                                "printSection"
                                            );
                                            var WindowObject = window.open(
                                                "",
                                                "PrintWindow",
                                                "toolbars=no,scrollbars=yes,status=no,resizable=yes"
                                            );
                                            WindowObject.document.writeln(
                                                DocumentContainer.innerHTML
                                            );
                                            WindowObject.document.close();
                                            setTimeout(function () {
                                                WindowObject.focus();
                                                WindowObject.print();
                                                WindowObject.close();
                                            }, 1000);
                                        }}
                                    >
                                        PRINT
                                    </button>

                                    <ReactToPrint
                                        content={() => componentRef.current}
                                    />
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <InvoicePrint paymentMethods={props.paymentMethods} date={date} orderItemsData={printOrderItemsData} order={printOrder} ref={componentRef} creditNoteData={printCreditNoteItems} />
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>

                {/* Export order data to excel */}
                <ErrorBoundary>
                    <div id="exportData" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Export data</h4>
                                    <button type="button" className="close" data-dismiss="modal"> &times;</button>
                                </div>
                                <div className="modal-body">
                                    <input type="radio" style={{ marginLeft: '10px', marginRight: '5px' }} checked={exportDataFlag ? false : true} onChange={e => { setExportDataFlag(false) }} /><label>Order Date</label>
                                    <input type="radio" style={{ marginLeft: "10px", marginRight: '5px' }} checked={exportDataFlag ? true : false} onChange={e => { setExportDataFlag(true) }} /> <label> Etd Date </label>
                                    <div className='card'>
                                        <div className='card-header' style={{ display: 'inline-flex' }}>
                                            <div>From :</div><input type='date' className='form-control' style={{ width: '40%' }} defaultValue={exportDate.from}
                                                onChange={e => {
                                                    // console.log(e)
                                                    setExportDate({ ...exportDate, from: new Date(e.target.valueAsDate) })
                                                }} />
                                            <div>To : </div><input type='date' className='form-control' style={{ width: '40%' }} defaultValue={exportDate.to.split('T')[0]}
                                                onChange={e => {
                                                    // console.log(e)
                                                    let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                                    let splitTime = toDate[1].split('.')
                                                    let timeString = `23:59:59.${splitTime[1]}`
                                                    let newToDate = `${toDate[0]}T${timeString}`
                                                    setExportDate({ ...exportDate, to: newToDate })
                                                }} />
                                        </div>
                                        <div className='card-header' style={{ display: "inline-flex" }}>
                                            <div>Region :</div>
                                            <select value={exportRegion} className='form-control' onChange={e => setExportRegion(e.target.value)} style={{ width: "30%" }}>
                                                <option value={'all'}>All</option>
                                                {props.region.map(region => {
                                                    return <option key={region._id} value={region.name}>{region.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={exportData}>Export now</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>

                <ErrorBoundary>
                    <div id="transferToHistory" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Transfer To History</h4>
                                    <button type="button" className="close" data-dismiss="modal"> &times;</button>
                                </div>
                                <div className="modal-body">
                                    <input type="radio" style={{ marginRight: '5px' }} checked={transferToHistoryFlag ? false : true} onChange={e => { setTransferToHistoryFlag(false) }} /> <label> Transfer by Order ID</label>
                                    <input type="radio" style={{ marginLeft: '30px', marginRight: '5px' }} checked={transferToHistoryFlag ? true : false} onChange={e => { setTransferToHistoryFlag(true) }} /><label>Tranfer By Date Range</label>
                                    <div className='card'>
                                        <div className='card-header' style={{ display: 'inline-flex' }}>
                                            {transferToHistoryFlag ?
                                                <ErrorBoundary>
                                                    <div>
                                                        From :
                                                    </div>
                                                    <input type='date' name="dateFrom" className='form-control' style={{ width: '40%' }} defaultValue={exportDate.from}
                                                        onChange={e => {
                                                            setExportDate({ ...exportDate, from: new Date(e.target.valueAsDate) })
                                                        }} />
                                                    <div>
                                                        To :
                                                    </div>
                                                    <input type='date' name="dateTo" className='form-control' style={{ width: '40%' }} defaultValue={exportDate.to.split('T')[0]}
                                                        onChange={e => {
                                                            let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                                            let splitTime = toDate[1].split('.')
                                                            let timeString = `23:59:59.${splitTime[1]}`
                                                            let newToDate = `${toDate[0]}T${timeString}`
                                                            setExportDate({ ...exportDate, to: newToDate })
                                                        }} />
                                                </ErrorBoundary>
                                                :
                                                <ErrorBoundary>
                                                    <div>Order ID :</div>
                                                    <input type='text' name="transferByOrderId" className='form-control' style={{ width: '40%' }} defaultValue=''
                                                        onChange={e => {
                                                            setOrder_id(e.target.value);
                                                        }} />
                                                </ErrorBoundary>
                                            }

                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={transferToHistoryFlag ? transferToHistory : transferByOrderId}>Transfer Now</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>

                {/* Add customer complaint */}
                <ErrorBoundary>
                    <CustomerComplaintModal orderItemsData={orderItemsData}
                        orderData={props.data}
                        // complaintTypeData={complaintTypeData} resolvedData={resolvedData}
                        complaintItem={complaintItem} edit={edit}
                        remove={remove} sortOptions={sortOptions} filterCaseInsensitive={filterCaseInsensitive} getTdProps={getTdProps}
                        customerComplaintsData={customerComplaintsData} setEdit={setEdit} setRemove={setRemove}
                        // setImagePath={setImagePath} setComplaint={setComplaint} setType={setType} setResolved={setResolved} setResolvedMethod={setResolvedMethod} customerComplaintsColumns={customerComplaintsColumns}
                        reloadCustomerComplaintsData={reloadCustomerComplaintsData} currentOrderId={currentOrderId}
                        handleCustomerComplaintSubmit={handleCustomerComplaintSubmit} props={props} />
                </ErrorBoundary>


                <div id='viewImagesPopup' className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Images</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                {(customerComplaintImagesForGallery === '' || customerComplaintImagesForGallery === null) ?
                                    <>
                                        No Data Found
                                    </>
                                    :

                                    <ImageGallery items={customerComplaintImagesForGallery} />
                                }

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close Images</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ErrorBoundary>
                    <div id="customerSurveyPopup" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Customer Survey</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => closeCustomerSurveyPopup()}> &times;</button>
                                </div>
                                <div className="modal-body">
                                    <div className='card'>
                                        <div className='card-header'>

                                            <button className='btn btn-primary' onClick={e => { console.log(customerSurveyData); setCustomerSurveyView(true); setEditSurveyData(false); setSurveyData([]); setSurveyDataEdit([]) }}>View</button>
                                            {
                                                !editSurveyData ?
                                                    <button className='btn btn-primary' onClick={e => { setCustomerSurveyView(false); setEditSurveyData(false) }}>Add Survey</button>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        {customerSurveyView && !editSurveyData ?
                                            (<div className='card-body'>
                                                <React.Fragment>
                                                    <ErrorBoundary>
                                                        <table className='table table-striped'>
                                                            <tbody>
                                                                <tr>
                                                                    <th>Created At</th>
                                                                    <th>Creater</th>
                                                                    {
                                                                        props.surveyQuestions.map(surveyQuestion => {
                                                                            return <th>{surveyQuestion.question}</th>
                                                                        })
                                                                    }
                                                                    <th>Actions</th>
                                                                </tr>
                                                                {customerSurveyData.map(csData => {
                                                                    return <tr>
                                                                        <td>{formatDate(csData.customerordersurvey.created_at)}</td>
                                                                        <td>{csData.users.name.first}</td>
                                                                        {props.surveyQuestions.map(surveyQuestion => {
                                                                            var sOption = _.filter(csData.customerordersurvey.surveyData, function (sData) {
                                                                                return sData.questionId === surveyQuestion._id
                                                                            })

                                                                            var optionText = _.filter(surveyQuestion.options, function (sqOption) {
                                                                                return Number(sOption[0].selectedOption) === Number(sqOption.value);
                                                                            })

                                                                            return <td>{optionText[0].text}</td>
                                                                        })
                                                                        }
                                                                        <td>
                                                                            <a
                                                                                onClick={e => editSurvey(csData)} style={{ 'cursor': 'pointer', 'marginRight': '10%' }}>
                                                                                <i className='fas fa-edit'></i>
                                                                            </a>
                                                                            <a
                                                                                onClick={e => {
                                                                                    if (window.confirm('Are you sure you wish to delete this item?'))
                                                                                        deleteSurvey(csData.customerordersurvey._id)
                                                                                }}
                                                                                style={{ 'cursor': 'pointer' }}>
                                                                                <i className='fas fa-trash'></i>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </ErrorBoundary>
                                                </React.Fragment>
                                            </div>)
                                            : editSurveyData ?
                                                <div className='card-body'>
                                                    <React.Fragment>
                                                        <ErrorBoundary>
                                                            <form id='updateCustomerSurvey'>
                                                                <table style={{ 'width': '100%' }}>
                                                                    <tbody>
                                                                        {
                                                                            props.surveyQuestions.map(surveyQuestion => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{surveyQuestion.question}</td>
                                                                                        <td>
                                                                                            {

                                                                                                surveyQuestion.options.map(option => {
                                                                                                    var sOption = _.filter(surveyDataEdit, function (sDE) {
                                                                                                        return sDE.questionId === surveyQuestion._id
                                                                                                    })

                                                                                                    return <span>
                                                                                                        <input
                                                                                                            onClick={e => setSurveyDataEditMethod(surveyQuestion._id, option.value)}
                                                                                                            name={surveyQuestion.name}
                                                                                                            checked={Number(sOption[0].selectedOption) === Number(option.value) ? 'checked' : ''}
                                                                                                            type='radio' />
                                                                                                        {option.text}
                                                                                                    </span>


                                                                                                })
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                        <tr>
                                                                            <td></td>
                                                                            <td><button type='button' className='btn btn-primary' style={{ "marginTop": "20px", "float": "right" }} onClick={e => updateSurvey()}>Update</button></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </form>
                                                        </ErrorBoundary>
                                                    </React.Fragment>
                                                </div>
                                                :
                                                <div className='card-body'>
                                                    <React.Fragment>
                                                        <ErrorBoundary>
                                                            <form id='addCustomerSurvey'>
                                                                <table style={{ 'width': '100%' }}>
                                                                    <tbody>
                                                                        {
                                                                            props.surveyQuestions.map(surveyQuestion => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{surveyQuestion.question} :</td>
                                                                                        <td>
                                                                                            {
                                                                                                surveyQuestion.options.map(option => {
                                                                                                    return <span><input onClick={e => setSurveyDataMethod(surveyQuestion._id, option.value)} name={surveyQuestion.name} type='radio' style={{ "marginLeft": "10px" }} /> {option.text}</span>
                                                                                                })
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                        <tr>
                                                                            <td></td>
                                                                            <td><button type='button' className='btn btn-primary' style={{ "marginTop": "20px", "float": "right" }} onClick={e => addCustomerSurvey()}>Submit</button></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </form>
                                                        </ErrorBoundary>
                                                    </React.Fragment>
                                                </div>
                                        }

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => closeCustomerSurveyPopup()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>

                <button id="replacementModalButton" data-toggle='modal' data-target="#replacementModal" style={{ visibility: 'hidden' }}></button>

                <ErrorBoundary>
                    <ReplacementModal itemData={props.itemData} orderItemsData={props.orderItemsData} orderItem={orderItemToBeReplacement} props={props} />
                </ErrorBoundary>

                <ErrorBoundary>
                    <AmountPaid rowInfo={rowInfo} setRowInfo={setRowInfo} props={props} />
                </ErrorBoundary>

            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    data: state.rOrdering.data,
    allOrderItems: state.rOrdering.allOrderItems,
    orderItems: state.rOrdering.orderItems,
    customerComplaintsData: state.rOrdering.customerComplaintsData,
    productData: state.rBasicData.product_data,
    itemData: state.rBasicData.item_data,
    paymentMethods: state.rBasicData.payment_method_data,
    resolveMethod: state.rBasicData.resolve_method_data,
    address: state.rBasicData.addresses,
    customers: state.rBasicData.users,
    city: state.rBasicData.city,
    token: state.rLogin.token,
    isLoading: state.rLogin.loading,
    userId: state.rLogin.id,
    loggedUser: state.rLogin.name,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    accounts: state.rAccounts.accounts,
    region: state.rBasicData.region,
    deliveryCrewData: state.rDeliveryBoys.data,
    surveyQuestions: state.rSurveyQuestions.activeSurveyQuestions,
    deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
    deliveryWarehousesForUser: state.rDeliveryWarehouse.deliveryWarehousesForUser
})

const mapDispatchToProps = dispatch => ({
    reloadData: props => { dispatch(reloadData(props)) },
    getOrderingData: token => { dispatch(getOrderingData(token)) },
    getOrderItems: (items, token) => { dispatch(getOrderItemsData(items, token)) },
    getAddresses: token => { dispatch(getAddressData(token)) },
    getResolveMethodData: props => dispatch(getResolveMethodData(props)),
    getPaymentMethodData: token => { dispatch(getPaymentMethodData(token)) },
    getProduct: token => { dispatch(getProductData(token)) },
    getItems: token => { dispatch(getItemData(token)) },
    getCity: token => { dispatch(getCityData(token)) },
    addOrder: (orderData, token) => { dispatch(addOrder(orderData, token)) },
    getCustomer: token => { dispatch(getUserData(token)) },
    updateOrder: (order, token) => { dispatch(updateOrder(order, token)) },
    updateOrderStatus: (order, token) => { dispatch(updateOrderStatus(order, token)) },
    updateCustomerAmount: (amount, { setAmount, setViewHandler }, props) => { dispatch(updateCustomerAmount(amount, { setAmount, setViewHandler }, props)) },
    deleteOrder: (order, token) => { dispatch(deleteOrder(order, token)) },
    updateOrderItem: (orderItem, token) => { dispatch(updateOrderItem(orderItem, token)) },
    updateDeliveryCharge: (data, props) => { dispatch(updateDeliveryCharge(data, props)) },
    deleteOrderItem: (orderItem, token) => { dispatch(deleteOrderItem(orderItem, token)) },
    addOrderItems: (orderItems, token) => { dispatch(addOrderItems(orderItems, token)) },
    addCustomerComplaint: (data, token) => { dispatch(addCustomerComplaint(data, token)) },
    updateCustomerComplaint: (data, token) => { dispatch(updateCustomerComplaint(data, token)) },
    deleteCustomerComplaint: (data, token) => { dispatch(deleteCustomerComplaint(data, token)) },
    getAccountsData: ({ token }) => dispatch(getAccountsData(token)),
    getRegionData: props => { dispatch(getRegionData(props)) },
    getDeliveryCrew: props => { dispatch(getDeliveryCrew(props)) },
    getSurveyQuestionsData: token => { dispatch(getSurveyQuestionsData(token)) },
    getAllOrderItems: token => { dispatch(getAllOrderItems(token)) },
    getDeliveryWarehouses: props => { dispatch(getDeliveryWarehouses(props)) },
    getDeliveryWarehousesForCurrentUser: props => { dispatch(getDeliveryWarehousesForCurrentUser(props)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(Ordering);

