/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const itemPricesAsync = data => {
    return { type: 'FETCH_ITEM_PRICES', data }
}

/**
 * 
 * @param {*} token 
 */
export const getItemPrices = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/item_prices/getItemPrices')
            .then(result => {
                dispatch(itemPricesAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateItemPrice = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item_prices/updateItemPrice', { data })
            .then(result => {
                toast.success('Successfully Added / Updated User Discount!')
                dispatch(getItemPrices(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteItemPrice = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item_prices/deleteItemPrice', { data })
            .then(result => {
                toast.success('User Discount Deleted Successfully!')
                dispatch(getItemPrices(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addItemPrice = ({ priceUnit, discountPercentage, item, user, slug }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/item_prices/addItemPrice', {
            data: {
                priceUnit,
                discountPercentage,
                item,
                user,
                slug,
            }
        })
            .then(result => {
                window.$('#addItemPrice').modal('hide');

                var _id = result.data.insertedId;
                var slug = _id;

                const data = {
                    _id: _id,
                    slug: slug
                }

                dispatch(updateItemPrice(data, token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}