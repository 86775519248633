import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import moment from "moment";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, AreaChart, ReferenceLine, Area } from 'recharts';
import { getMissingValue } from "../../../actions/Dashboard/dashboard_action"
import { connect } from 'react-redux';

function MissingItem(props) {

  console.log(props.missingValue.weeklyData)

  let monthlyData = []
  let weeklyData = []
  let yearlyData = []

  monthlyData = props.missingValue.monthlyData
  weeklyData = props.missingValue.weeklyData
  yearlyData = props.missingValue.yearlyData
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [weekMonthData, setWeekMonthData] = useState([]);
  const [weekMonthSelectedWeek, setWeekMonthSelectedWeek] = useState(1);
  const [weekMonthSelectedYear, setWeekMonthSelectedYear] = useState(new Date().getFullYear());
  const [monthYearSelectedMonth, setMonthYearSelectedMonth] = useState(1);
  const [monthYearData, setMonthYearData] = useState([]);
  const [isActive, setActive] = useState(true);


  useEffect(() => {
    props.getMissingValue({
      selectedDate: selectedDate,
      token: props.token
    });

  }, [selectedDate])

  useEffect(() => {
    getweekMonthData();

  }, [weekMonthSelectedWeek, weekMonthSelectedYear])


  useEffect(() => {
    getMonthYearData();

  }, [monthYearSelectedMonth])

  const selectedYear = new Date(selectedDate).getFullYear()
  const selectedMonth = new Date(selectedDate).toLocaleString('default', { month: 'long' })
  const weekMonthYears = []

  for (let i = new Date().getFullYear(); i >= 2019; i--) {
    weekMonthYears.push(i)
  }

  const getweekMonthData = () => {
    axios.defaults.headers.common['authorization'] = props.token;

    const data = {
      'selectedYear': Number(weekMonthSelectedYear),
      'selectedWeek': Number(weekMonthSelectedWeek)
    }

    axios.post('/ordering/getCancelAndMissingWeekly', data)
      .then(result => {
        setWeekMonthData(result.data.weekMonthData);
      }).catch(err => {
        console.error(err)
      })
  }

  const getMonthYearData = () => {
    axios.defaults.headers.common['authorization'] = props.token;

    const data = {
      'selectedMonth': Number(monthYearSelectedMonth)
    }

    axios.post('/ordering/getCancelAndMissingMonthly', data)
      .then(result => {
        setMonthYearData(result.data.monthYearData);
      }).catch(err => {
        console.error(err)
      })
  }


  // Format number values shown in Y axis of charts
  const DataFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + 'B';
    } else if (number > 1000000) {
      return (number / 1000000).toString() + 'M';
    } else if (number > 1000) {
      return (number / 1000).toString() + 'K';
    } else {
      return number.toString();
    }
  }

  return (
    <>
      <div className="card shadow my-4 mx-6 rounded-5" style={{ display: "flex", justifyContent: 'space-evenly' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-md-6">
              <h6 className="m-0 font-weight-bold text-primary">Missing Value</h6>
              <div>
                <input type='radio' name='dashboard' style={{ marginRight: '5px' }} checked={isActive ? true : false} onChange={e => { setActive(true) }} /><label>Month</label>
                <input type='radio' name='dashboard' style={{ marginLeft: '20px', marginRight: '5px' }} onChange={e => { setActive(false) }} /><label>Comparison</label>
              </div>
            </div>
            <div className="col-md-6">
              <input style={{ fontSize: '14px', fontWeight: 'bold' }} className="form-control form-control-sm mr-sm-2" type="date" value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                }} />
            </div>
          </div>
        </div>
        <div className="card-body" >
          {isActive ?
            <div>
              <ResponsiveContainer width='100%' height={260}>
                <AreaChart data={monthlyData}
                  margin={{
                    top: 10,
                    right: 40,
                    left: 0,
                    bottom: 10,
                  }}>
                  <defs>
                    <linearGradient id="colorPv3" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="10%" stopColor="#b063c5" stopOpacity={1} />
                      <stop offset="100%" stopColor="#b063c5" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv8" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="10%" stopColor="#A70D2A" stopOpacity={1} />
                      <stop offset="100%" stopColor="#A70D2A" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis style={{ fontSize: '14px' }} dataKey="_id" scale="point" padding={{ left: 10, right: 10 }} tick={{ fill: '#000000' }} />
                  <YAxis style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                  <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left' }} />
                  <Area type='monotoneX' dataKey='value' stroke='#b063c5' strokeWidth={2} fillOpacity={1}
                    fill="url(#colorPv3)" activeDot={{ r: 8 }} />
                  <Area type='monotoneX' dataKey='replacement' stroke='#A70D2A' strokeWidth={2} fillOpacity={1}
                    fill="url(#colorPv8)" activeDot={{ r: 8 }} />
                </AreaChart>
              </ResponsiveContainer>
              <center><span style={{ fontSize: '14px', color: '#191350' }} className="m-0 font-weight-bold">Months of Year - {selectedYear}</span></center>
            </div>
            :
            <div>
              <div className="container" >
                <div className="md-row" style={{ display: "flex" }}>
                  <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "normal" }} className="col-md-6">
                    <ResponsiveContainer width='100%' height={205}>
                      <BarChart
                        data={weeklyData}
                        margin={{
                          top: 10,
                          right: 20,
                          left: 0,
                          bottom: 10,
                        }}
                        barSize={10}
                      >
                        <XAxis style={{ fontSize: '14px' }} dataKey="_id" scale="point" padding={{ left: 5, right: 5 }} tick={{ fill: '#000000' }} />
                        <YAxis style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left' }} />
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <Bar dataKey="value" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                    <><span style={{ color: '#191350', fontSize: '14px', textAlign: 'center' }} className="m-0 font-weight-bold">Weeks of {selectedMonth}</span></>
                  </div>
                  <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "normal" }} className="col-md-6">
                    <ResponsiveContainer width='100%' height={205}>
                      <BarChart
                        data={yearlyData}
                        margin={{
                          top: 10,
                          right: 20,
                          left: 0,
                          bottom: 10,
                        }}
                        barSize={10}
                      >
                        <XAxis style={{ fontSize: '14px' }} dataKey="_id" scale="point" padding={{ left: 5, right: 5 }} tick={{ fill: '#000000' }} />
                        <YAxis style={{ fontSize: '14px' }} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left' }} />
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <Bar dataKey="value" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                    <><span style={{ color: '#191350', fontSize: '14px', textAlign: 'center' }} className="m-0 font-weight-bold">Years</span></>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {isActive === false ?
          <div className="card-footer text-center">
            <div>
              <button className="btn btn-primary" data-toggle="modal" data-target="#showWeekComparison" style={{ width: "45%" }}
                onClick={getweekMonthData}
              >
                Week Comparison
              </button>

              <button className="btn btn-success" data-toggle="modal" data-target="#showMonthComparison" style={{ width: "45%" }}
                onClick={getMonthYearData}
              >
                Month Comparison
              </button>
            </div>
          </div>
          : ''}
      </div>


      {/* Weekly Comparison Modal */}
      <div id="showWeekComparison" className="modal fade" role="dialog" >
        <div className="modal-dialog modal-lg">
          <div className="modal-content animate" >
            <div className="modal-header">
              <h4 className="modal-title text-uppercase">Weekly Value Comparison</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">

              <label for="weeks">Week: </label>
              <select
                value={weekMonthSelectedWeek}
                onChange={(e) => setWeekMonthSelectedWeek(e.target.value)}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>

              </select>

              <select
                value={weekMonthSelectedYear}
                onChange={e => setWeekMonthSelectedYear(e.target.value)}>
                {weekMonthYears.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </select>

              <BarChart
                width={800}
                height={240}
                data={weekMonthData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={15}
              >
                <XAxis style={{ fontSize: '14px' }} dataKey="_id" scale="point" padding={{ left: 10, right: 10 }} tick={{ fill: '#000000' }} angle="-40" />
                <YAxis style={{ fontSize: '14px' }} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: 'black', textAlign: 'left' }} />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>

            </div>
          </div>
        </div>
      </div>


      {/* Monthly Comparison Modal */}
      <div id="showMonthComparison" className="modal fade" role="dialog" >
        <div className="modal-dialog modal-lg">
          <div className="modal-content animate" >
            <div className="modal-header">
              <h4 className="modal-title text-uppercase">Monthly Value Comparison</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">

              <label for="months">Month: </label>
              <select
                value={monthYearSelectedMonth}
                onChange={(e) => setMonthYearSelectedMonth(e.target.value)}>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">Mar</option>
                <option value="4">Apr</option>
                <option value="5">May</option>
                <option value="6">Jun</option>
                <option value="7">Jul</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>

              </select>


              <BarChart
                width={800}
                height={240}
                data={monthYearData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={15}
              >
                <XAxis style={{ fontSize: '14px' }} dataKey="_id" scale="point" padding={{ left: 10, right: 10 }} tick={{ fill: '#000000' }} angle="-40" />
                <YAxis style={{ fontSize: '14px' }} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: 'black', textAlign: 'left' }} />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>

            </div>
          </div>
        </div>
      </div>
    </>


  )

}


const mapStateToProps = state => ({
  token: state.rLogin.token,
  missingValue: state.rDashboard.missingValue
})

const mapDispatchToProps = dispatch => ({
  getMissingValue: (data, callback) => { dispatch(getMissingValue(data, callback)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(MissingItem);