/**
 * @file This file defines to delivery note action dispatch
 * @author Kavindu Shehan
 */
var axios = require('axios');

export const deliveryNoteAsync = data => {
    return { type: 'FETCH_DELIVERY_NOTES', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const getDeliveryNoteData = ({ token }) => {
    isLoadingAsync(true)
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/deliveryNote/getDeliveryNote')
            .then(result => {
                console.log(result.data);
                dispatch(deliveryNoteAsync(result.data))
                isLoadingAsync(false)
            }).catch(err => {
                console.error(err);
            })
    }
}
