/**
 * @type {Object}
 * @property {array}  programme  programme data
 * @property {array}  subProgramme sub programme data
 */
const initialState = {
    programme: [],
    subProgramme: [],
    option: [],
    userProgramme: []
};

const permissionReducer = (state = initialState, action) => {
    const newState = { ...state }
    let { data } = action;
    switch (action.type) {
        case 'FETCH_PROGRAMME':
            newState.programme = data;
            break;

        case 'FETCH_SUB_PROGRAMME':
            // let { data } = action;
            newState.subProgramme = data;
            break;

        case 'FETCH_OPTIONS':
            // let { data } = action;
            newState.option = data;
            break;

        case 'FETCH_USER_PROGRAMME':
            newState.userProgramme = data;
            break;

        default:
            break;
    }
    return newState;
}

export default permissionReducer;