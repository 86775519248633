/**
 * @file This file defines to permission action dispatch
 * @author Bhanuka Chathuranga
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const programmeAsync = data => {
    return { type: 'FETCH_PROGRAMME', data }
}

export const subProgrammeAsync = data => {
    return { type: 'FETCH_SUB_PROGRAMME', data }
}

export const optionsAsync = data => {
    return { type: 'FETCH_OPTIONS', data }
}

export const userProgrammeAsync = data => {
    return { type: 'FETCH_USER_PROGRAMME', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * get programme details 
 * 
 * @param {string} token 
 */
export const getProgrammeData = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/administrator/permission/getProgramme', { headers: { authorization: token } })
            .then(result => {
                dispatch(programmeAsync(result.data))
                dispatch(getSubProgrammeData({ token }))
                // console.log(result);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get sub programmes details 
 * 
 * @param {string} token 
 */
export const getSubProgrammeData = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/administrator/permission/getSubProgramme', { headers: { authorization: token } })
            .then(result => {
                dispatch(subProgrammeAsync(result.data))
                dispatch(getOptionData({ token }))
                // console.log(result);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get sub programmes details 
 * 
 * @param {string} token 
 */
export const getOptionData = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/administrator/permission/getOptions', { headers: { authorization: token } })
            .then(result => {
                dispatch(optionsAsync(result.data))
                // console.log(result);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get user programmes details 
 * 
 * @param {string} id   user id
 * @param {string} token 
 */
export const getUserProgrammeData = ({ id, token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        dispatch(userProgrammeAsync([]))
        axios.get('/administrator/permission/getUserProgramme', { headers: { authorization: token }, params: { id } })
            .then(result => {
                dispatch(userProgrammeAsync(result.data))
                dispatch(isLoadingAsync(false))
                // console.log(result);
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
* To change programme permissions
*
* @param {Object} original To get prop.original data 
* @param {Boolean} isAdd Is add permission ?
*/
export const updateProPermission = ({ original }, { id, token }, isAdd) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(userProgrammeAsync([]))
        axios.post('/administrator/permission/updateProgramme', { id, original, isAdd })
            .then(result => {
                // console.table(result)
                dispatch(getUserProgrammeData({ id, token }))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    };
}

/**
* To change sub programme permissions
*
* @param {Object} original To get prop.original data 
*/
export const updateSubProPermission = ({ original }, { id, token }, option, isAdd) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/administrator/permission/updateSubProgramme', { id, original, option, isAdd })
            .then(result => {
                // console.table(result.data)
                dispatch(getUserProgrammeData({ id, token }))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    };
}

/**
* To change sub programme permissions
*
* @param {Object} original To get prop.original data 
*/
export const copyPermission = (users, { _id }, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/administrator/permission/copyPermissions', { _id, users })
            .then(result => {
                console.table(result.data)
                toast.success('Copy permission added successfully')
                // dispatch(getUserProgrammeData({ id, token }))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    };
}


