import React from "react";
import "../../components/paper.css"
// import logo from '../../../images/gusta.png'
// import logo1 from '../../../images/gusta-b.png'
import { DateStringFormat, PriceFormat } from "../../utils";

class PurchaseOrderPrint extends React.Component {


    render() {
        const {
            order, paymentcondition, deliverycondition, supplier, purchaseOrderItems
        } = this.props;

        var book = [];
        var page = [];
        var currentPage = 0;
        var currentRow = 10;
        var noOfLinesInPage = 53;
        var noOfCharactersInLine = 60;
        var name = "";
        var noOfFullLines = 0;
        var noOfFooterLine = 2;
        var subTotal = 0;
        var noOfItems = 0;

        if (purchaseOrderItems != null && purchaseOrderItems.length > 0) {
            purchaseOrderItems.forEach((item, arrayIndex, array) => {
                name = "";
                noOfFullLines = 0;
                if (item.purchaseitem !== null && item.purchaseitem !== undefined && item.purchaseitem.history === false) {

                    if (item.purchaseitem != null && item.purchaseitem != undefined && item.purchaseitem.orderqty > 0) {
                        noOfItems += item.purchaseitem.orderqty;
                        subTotal += item.purchaseitem.orderqty * (Number(item.purchaseitem.price) - (Number(item.purchaseitem.price) * Number(item.purchaseitem?.purchaseDiscount || 0) / 100))
                    }

                    page.push(item);
                    if (item.items.desc !== undefined && item.items.desc != "") {
                        name = item.items.desc
                    } else {
                        name = item.items.name
                    }

                    if (name.length % noOfCharactersInLine > 0) {
                        currentRow++;
                    }

                    noOfFullLines = parseInt(name.length / noOfCharactersInLine);
                    if (noOfFullLines > 0) {
                        currentRow = currentRow + noOfFullLines;
                    }

                    if (currentRow == noOfLinesInPage) {
                        book[currentPage] = page;
                        currentPage++;
                        page = [];
                        currentRow = 0;
                    }
                }
            });
        }

        if (page.length > 0) {
            book[currentPage] = page;
        }

        // console.log(book);
        return (
            <div id="printSection">
                <html>
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"
                    />
                    <body class="A4" style={{ fontFamily: "Times New Roman", lineHeight: 1 }}>
                        <section class="sheet  padding-10mm">
                            <table>

                                <tr>
                                    <td style={{ width: "10%" }}>
                                        <img src={`${window.location.origin}/images/logo/gusta.png`} style={{ width: '118px' }} alt="CIAO" />
                                    </td>

                                    <td style={{ width: "45%", textAlign: "center", fontSize: 25 }}>
                                        Purchase Order
                                    </td>

                                    <td style={{ width: "45%" }}>
                                        <table style={{ width: "100%", textAlign: "left", padding: 0, margin: 0 }} >
                                            <tr>
                                                <td style={{ width: "10%", fontSize: 12, textAlign: "left" }} >
                                                    Address :
                                                </td>
                                                <td style={{ width: "40%", fontSize: 12, textAlign: "left" }} >
                                                    No. 5, Diyawanna Gardens,
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: "20%", fontSize: 12, textAlign: "left" }} ></td>
                                                <td style={{ width: "80%", fontSize: 12, textAlign: "left" }} >
                                                    Nugegoda, Sri lanka.
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: "20%", fontSize: 12, align: "left" }} >
                                                    Contact :
                                                </td>
                                                <td style={{ width: "80%", fontSize: 12, textAlign: "left" }} >
                                                    0094771330707
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "20%", textAlign: "left" }}></td>
                                                <td style={{ width: "80%", fontSize: 12, textAlign: "left" }} >
                                                    0094762951376
                                                </td>
                                            </tr>
                                        </table>
                                    </td>

                                </tr>
                            </table>

                            {typeof supplier !== undefined && supplier != null ? (
                                <table style={{ width: "100%", textAlign: "left" }}>
                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            PURCHASE FROM
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.name}
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13 }}>
                                            ORDER NO
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13, textAlign: "right" }} >
                                            {order.orderid}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            REPRESENTATIVE
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.callerName}
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13 }}>
                                            ORDER DATE
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13, textAlign: "right" }} >
                                            {order.orderdate != null && order.orderdate !== undefined ? DateStringFormat(order.orderdate) : ""}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            ADDRESS
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.address}
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13 }}>
                                            EXPECTED DELIVERY DATE
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13, textAlign: "right" }} >
                                            {order.expReceiveDate != null && order.expReceiveDate !== undefined ? DateStringFormat(order.expReceiveDate) : ""}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            TELEPHONE
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.telephone}
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13 }}>
                                            PAYMENT CONDITION
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13, textAlign: "right" }} >
                                            {paymentcondition != null && paymentcondition.description != "" ? paymentcondition.description : ""}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            EMAIL
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.email}
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13 }}>
                                            DELIVERY CONDITION
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13, textAlign: "right" }} >
                                            {deliverycondition != null && deliverycondition.description != "" ? deliverycondition.description : ""}
                                        </td>
                                    </tr>
                                </table>
                            ) : null}
                            {/* Perchase order items */}
                            <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse", marginTop: "10px" }}>
                                {typeof purchaseOrderItems !== undefined && purchaseOrderItems != null ? (
                                    <tr style={{ backgroundColor: "#dddddd" }}>
                                        <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 16 }} >
                                            Qty
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 16 }} >
                                            Item Code
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", fontSize: 16 }} >
                                            Description
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 16 }} >
                                            Rate
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "20%", fontSize: 16 }} >
                                            Amount Rs.
                                        </td>
                                    </tr>) : null}

                                {typeof book[0] !== undefined &&
                                    book[0] != null
                                    ? book[0].map((value, index, total) => {
                                        let name = value.items.desc != null && value.items.desc !== undefined && value.items.desc.trim() !== "" ? value.items.desc : value.items.name
                                        let dis = (value.purchaseitem.purchaseDiscount !== undefined && value.purchaseitem.purchaseDiscount !== null) && value.purchaseitem.purchaseDiscount != 0 ? ` - ${value.purchaseitem.purchaseDiscount} %` : ''
                                        let purchasePriceWithDiscount = Number(value.purchaseitem.price) - (Number(value.purchaseitem.price) * Number(value.purchaseitem?.purchaseDiscount || 0) / 100)
                                        let totalPrice = Number(value.purchaseitem.orderqty) * purchasePriceWithDiscount
                                        return (
                                            <tr>
                                                <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                    {value.purchaseitem.orderqty}
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14 }}>
                                                    {value.items.itemCode}
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left" }} >
                                                    {name}{dis}
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14, textAlign: "right" }} >
                                                    {PriceFormat(purchasePriceWithDiscount)}
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "20%", fontSize: 14, textAlign: "right" }} >
                                                    {PriceFormat(totalPrice)}
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : null}

                                {/* render table ending lines */}
                                {((page.length == 0 && currentPage == 1) || currentPage == 0) ? (
                                    <>
                                        {/* Sub Total */}
                                        <tr>
                                            <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                {noOfItems}
                                            </td>
                                            <td colSpan="3" style={{ border: "1px solid #dddddd", width: "79%", fontSize: 14, textAlign: "right" }} >
                                                Sub Total
                                            </td>
                                            <td style={{ border: "1px solid #dddddd", width: "20%", fontSize: 14, textAlign: "right" }} >
                                                {PriceFormat(subTotal)}
                                            </td>
                                        </tr>
                                        {/* Total */}
                                        <tr>
                                            <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                ({noOfItems}) Total
                                            </td>

                                            <td style={{ border: "1px solid #dddddd", width: "20%", fontSize: 14, textAlign: "right" }} >
                                                {PriceFormat(subTotal)}
                                            </td>
                                        </tr>
                                    </>



                                ) : null}
                            </table>
                            {((page.length == 0 && currentPage == 1) || currentPage == 0) ?
                                <div style={{ paddingTop: '6px' }}>Package details :-
                                    <ul>
                                        {book[0] != null
                                            ? book[0].map((value, index, total) => {
                                                return <>
                                                    {(value.items?.isPackagingGroup || value.purchaseitem?.isPackagingGroup) ? <li>{value.items?.desc} - {value.purchaseitem?.packArr?.join(', ')}</li> : null}
                                                </>
                                            })
                                            : null}
                                    </ul>
                                </div>
                                : null}

                        </section>
                        {/* Other pages */}

                        {typeof book !== undefined &&
                            book != null
                            ? book.map((page, index, total) => {
                                return (
                                    index > 0 ? (
                                        <section class="sheet   padding-10mm">
                                            <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse" }}>
                                                <tr style={{ backgroundColor: "#dddddd" }}>
                                                    <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 16 }} >
                                                        Qty
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 16 }} >
                                                        Item Code
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", fontSize: 16 }} >
                                                        Description
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 16 }} >
                                                        Rate
                                                    </td>
                                                    <td style={{ border: "1px solid #dddddd", width: "20%", fontSize: 16 }} >
                                                        Amount Rs.
                                                    </td>
                                                </tr>
                                                {page.map((value, index, total) => {
                                                    let name = value.items.desc != null && value.items.desc !== undefined && value.items.desc.trim() !== "" ? value.items.desc : value.items.name
                                                    let dis = (value.purchaseitem.purchaseDiscount !== undefined && value.purchaseitem.purchaseDiscount !== null) && value.purchaseitem.purchaseDiscount != 0 ? ` - ${value.purchaseitem.purchaseDiscount} %` : ''
                                                    let purchasePriceWithDiscount = Number(value.purchaseitem.price) - (Number(value.purchaseitem.price) * Number(value.purchaseitem?.purchaseDiscount || 0) / 100)
                                                    let totalPrice = Number(value.purchaseitem.orderqty) * purchasePriceWithDiscount
                                                    return (

                                                        <tr>
                                                            <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                                {value.purchaseitem.orderqty}
                                                            </td>
                                                            <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14 }} >
                                                                {value.items.itemCode}
                                                            </td>
                                                            <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left" }} >
                                                                {name}{dis}
                                                            </td>
                                                            <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14, textAlign: "right" }} >
                                                                {PriceFormat(purchasePriceWithDiscount)}
                                                            </td>
                                                            <td style={{ border: "1px solid #dddddd", width: "20%", fontSize: 14, textAlign: "right" }} >
                                                                {PriceFormat(totalPrice)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                {/* Table ending */}
                                                {index == currentPage ?
                                                    <>
                                                        {/* Sub Total */}
                                                        <tr>
                                                            <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                                {noOfItems}
                                                            </td>
                                                            <td colSpan="3" style={{ border: "1px solid #dddddd", width: "79%", fontSize: 14, textAlign: "right" }} >
                                                                Sub Total
                                                            </td>
                                                            <td style={{ border: "1px solid #dddddd", width: "20%", fontSize: 14, textAlign: "right" }} >
                                                                {PriceFormat(subTotal)}
                                                            </td>
                                                        </tr>
                                                        {/* Total */}
                                                        <tr>
                                                            <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                                ({noOfItems}) Total
                                                            </td>

                                                            <td style={{ border: "1px solid #dddddd", width: "20%", fontSize: 14, textAlign: "right" }} >
                                                                {PriceFormat(subTotal)}
                                                            </td>
                                                        </tr>
                                                    </> : null}
                                            </table>
                                        </section>) : null
                                );
                            })
                            : null}
                    </body>
                </html>
            </div>
        );
    }


}

export default PurchaseOrderPrint;