import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";
import { toast } from "react-toastify";
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import Permission from '../../utils/Permission';
import ActionsCell from "../../components/ActionCell";
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import { getItemTag, addItemTag, updateItemTag, deleteItemTag } from '../../../actions/Basic_data/item_tag_action';
import SkeletonTable from '../../components/skeleton/SkeletonTable';

export const ItemTag = props => {

    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [itemTags, setItemTags] = useState({ tag: '' });

    useEffect(() => {
        props.getItemTag(props)
    }, [])

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'ITEM TAG', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'ITEM TAG', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const columns = [
        { Header: "Tag", accessor: "tag", ...editableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const addItemTagHandler = () => {
        if (itemTags.tag == '') {
            toast.error('Please fill item tag');
        } else {
            props.addItemTag(itemTags, props)
        }
    }

    const handleSubmit = value => {
        if (editing !== null) {
            props.updateItemTag(value, props)
        } else if (deleting !== null) {
            props.deleteItemTag(value, props)
        }
    }

    return (
        <div className="right_col" role="main" >
            <div className="card">
                <div className="card-header">
                    <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'ITEM TAG', 'ADD', props)} data-toggle="modal" data-target="#addItemTag"
                        onClick={() => setItemTags({ tag: '' })}
                    >
                        + Item Tag
                    </button>
                </div>
                {props.isLoading
                    ? <div className="card-body">
                        <SkeletonTable columnsCount={5} dataCount={10} />
                    </div>
                    : <div className="card-body">
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                initialValues={editing === null ? deleting : editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                columns={columns}
                                                data={props.itemTagData}
                                                defaultPageSize={10}
                                                filterable
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </div>}

                <div id="addItemTag" className="modal fade" role="dialog" >
                    <div className="modal-dialog">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Add Item Tag</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>Item Tag:</td>
                                                <td>
                                                    <input className='form-control' value={itemTags.tag} autoComplete={false} 
                                                    onChange={e => setItemTags({ ...itemTags, tag: e.target.value })} 
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={addItemTagHandler}>Add</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userId: state.rLogin.id,
        users: state.rUser.users,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        itemTagData: state.rItemTag.itemtags,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getItemTag: props => { dispatch(getItemTag(props)) },
        addItemTag: (data, props) => { dispatch(addItemTag(data, props)) },
        updateItemTag: (data, props) => { dispatch(updateItemTag(data, props)) },
        deleteItemTag: (data, props) => { dispatch(deleteItemTag(data, props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemTag);


