import React from 'react'
import ToastHtml from './ToastHtml'


function DeliveryRegionModel({ deiliveryMsg, isLoading }) {
    return (
        <div id='delivery_region' className="modal fade" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content animate" >
                    <div className='modal-header'>
                        <h5 className="modal-title text-uppercase font-style spacing-1" style={{ color: '#333233b5', fontWeight: 'bold' }}>ORDER CYCLE AND DELIVERY CHARGES</h5>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className='modal-body'>
                        {/* add to notification */}
                        <div style={{ fontSize: '16px' }}>{deiliveryMsg !== '' ? <ToastHtml text={deiliveryMsg?.value} /> : null}</div>
                        {/* <div style={{ fontSize: '16px' }}>{deiliveryMsg?.value}</div> */}
                        {/* <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Region</th>
                                <th>Order value below(Rs.)</th>
                                <th>Charges(Rs.)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deliveryRegion.map(ele => {
                                return <tr>
                                    <td>{ele.name}</td>
                                    <td style={{ 'textAlign': 'right' }}>{PriceFormat(ele.thresholdValue)}</td>
                                    <td style={{ 'textAlign': 'right' }}>{PriceFormat(ele.deliveryCharges)}</td>
                                </tr>
                            })}
                        </tbody>
                    </table> */}
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-secondary" disabled={isLoading} data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliveryRegionModel
