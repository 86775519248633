/**
 * @file This file defines to purchasing action dispatch
 * @author Bhanuka Chathuranga
 */
// import $ from 'jquery';
import { toast } from 'react-toastify';
var axios = require('axios');

export const supplierAsync = data => {
    return { type: 'FETCH_SUPPLIER', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * get Supplier details 
 * 
 * @param {string} token 
 */
export const getSupplierData = token => {
    // axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        dispatch(supplierAsync([]))
        axios.get('/purchasing/getSuppliers', { headers: { authorization: token } })
            .then(result => {
                dispatch(isLoadingAsync(false))
                dispatch(supplierAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To add supplier
 * 
 * @param {Object} {state} supplier state
 * @param {string} token 
 */
export const addSupplier = ({ name, callerName, address, country, telephone, leadTime, email, currency, payment_method, payment_conditon, delivery_conditon, selectedAcc, whatsappgroup }, token) => {

    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/addSuppliers', { data: { name, callerName, address, leadTime, country, telephone, email, currency, "waypayment_ref": payment_method, "paycondition_ref": payment_conditon, "delicondition_ref": delivery_conditon, "account_ref": selectedAcc, whatsappgroup } })
            .then(result => {
                console.table(result.data)
                toast.success('Added supplier...!')
                // $('#addSupplier').modal('toggle')
                dispatch(getSupplierData(token))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
        // axios.request({
        //     method: "post", 
        //     url: "/purchasing/addSuppliers", 
        //     data:  [{ name, address, country, telephone, email, currency, "waypayment_ref": payment_method, "paycondition_ref": payment_conditon, "delicondition_ref": delivery_conditon }], 
        //     onUploadProgress: p => {
        //       const progress = p.loaded / p.total;

        //       // check if we already displayed a toast
        //       if(toastId.current === null){
        //           toastId = toast('Upload in Progress', {
        //           progress: progress
        //         });
        //       } else {
        //         toast.update(toastId.current, {
        //           progress: progress
        //         })
        //       }
        //     }
        //   }).then(data => {
        //     // Upload is done! 
        //     // The remaining progress bar will be filled up
        //     // The toast will be closed when the transition end
        //     toast.done(toastId.current);
        //   })
    }
}

/**
 * To update supplier data
 * 
 * @param {Object} data update data
 * @param {string} token 
 */
export const updateSupplier = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/updateSuppliers', { data })
            .then(result => {
                console.table(result.data)
                toast.success('Update supplier...!')
                // $('#addSupplier').fadeToggle()
                dispatch(getSupplierData(token))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To delete supplier data
 * 
 * @param {Object} data delete data
 * @param {string} token 
 */
export const deleteSupplier = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/deleteSuppliers', { data })
            .then(result => {
                console.table(result.data)
                toast.success('Delete supplier...!')
                // $('#addSupplier').fadeToggle()
                dispatch(getSupplierData(token))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}