import _ from 'lodash';
/**
 * @type {Object}
 * @property {array}  country        country data
 * @property {array}  currency_data  Currency data
 * @property {array}  payment_condition_data    payment condition data
 * @property {array}  delivery_condition_data   delivery conditon data
 */
const initialState = {
    country: [],
    currency_data: [],
    payment_method_data: [],
    payment_condition_data: [],
    delivery_condition_data: [],
    product_data: [],
    item_data: [],
    addresses: [],
    users: [],
    shelfLives: [],
    deliveryTypes: [],
    suppliers: [],
    categories: [],
    city: [],
    region: [],
    brand: [],
    resolve_method_data: [],
    companies: [],
    brandData:[]
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const basicDataReducer = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_CURRENCY':
            newState.currency_data = data;
            break;

        case 'FETCH_PAYMENT_METHODS':
            newState.payment_method_data = _.filter(data, { isActive: true });
            break;

        case 'FETCH_COUNTRY':
            newState.country = data;
            break;

        case 'FETCH_PAYMENT_CONDITION':
            newState.payment_condition_data = data;
            break;

        case 'FETCH_DELIVERY_CONDITION':
            newState.delivery_condition_data = data;
            break;

        case 'FETCH_PRODUCT':
            data.map(item => {
                item['label'] = item.name
            })
            newState.product_data = data;
            break;

        case 'FETCH_ITEM':
            data.map(product => {
                product['label'] = `${product.itemCode} - ${product.name} (${product.price}${product.priceUnit})`
            })
            newState.item_data = _.filter(data, { status: "Active" });
            break;

        case 'FETCH_ADDRESS':
            newState.addresses = data;
            break;

        case 'FETCH_USERS':
            data.map(user => {
                user['label'] = `${user.name.first} ${user.name.last}`
            })
            newState.users = data;
            break;

        case 'FETCH_SHELF_LIVES':
            newState.shelfLives = data;
            break;

        case 'FETCH_DELIVERY_TYPES':
            newState.deliveryTypes = data;
            break;

        case 'FETCH_SUPPLIERS':
            newState.suppliers = data;
            break;

        case 'FETCH_CATEGORIES':
            newState.categories = data;
            break;

        case 'FETCH_CITY':
            data.map(city => {
                city['value'] = `${city._id}`
                city['label'] = `${city.name}`;
            })
            newState.city = data;
            break;

        case 'FETCH_REGION':
            data.map(region => {
                region['value'] = `${region._id}`
                region['label'] = `${region.name}`
            })
            newState.region = data;
            break;

        case 'FETCH_BRAND':
            data.map(brand => {
                brand['value'] = `${brand._id}`
                brand['label'] = `${brand.name}`
            })
            newState.brand = data;
            break;

        case 'FETCH_RESOLVE_METHODS':
            newState.resolve_method_data = data;
            break;

        case 'FETCH_COMPANY_DATA':
            newState.companies = data;
            
        case 'FETCH_BRAND_DATA':
            var { data } = action;
            newState.brandData = data;
            break;

        default:
            break;
    }
    return newState;
}

export default basicDataReducer;