import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
// import _ from 'lodash';
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";
import { toast } from "react-toastify";
import axios from 'axios';
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import Permission from '../../utils/Permission';
import ActionsCell from "../../components/ActionCell";
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import { getUsers } from '../../../actions/Administrator/user_actions';
import { getDeliveryWarehouses, addDeliveryWarehouse, updateDeliveryWarehouse, deleteDeliveryWarehouse, addDeliveryWarehouseUser, deleteDeliveryWarehouseUser, addDeliveryWarehouseAccount, deleteDeliveryWarehouseAccount, getDeliveryWarehousesForCurrentUser } from '../../../actions/Basic_data/delivery_warehouse_actions';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import DeliveryWarehouseUserModal from './Modal/DeliveryWarehouseUser/DeliveryWarehouseUserModal';
import DeliveryWarehouseAccountModal from './Modal/DeliveryWarehouseAccount/DeliveryWarehouseAccountModal';
import { BOOL } from '../../utils';
import { getAccountsData } from '../../../actions/Acc/account_actions';

export const DeliveryWarehouse = props => {

    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [currentWarehouseId, setCurrentWarehouseId] = useState(null);
    const [deliveryWarehouses, setDeliveryWarehouses] = useState({ name: '' });
    const [userData, setUserData] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [accountData, setAccountData] = useState([]);
    const [accountIds, setAccountIds] = useState([]);

    useEffect(() => {
        props.getDeliveryWarehousesForCurrentUser(props)
        props.getDeliveryWarehouses(props)
    }, [])

    const deliveryWarehouseUser = (row, { setCurrentWarehouseId }, props) => {
        axios.defaults.headers.common['authorization'] = props.token;
        const warehouseId = row.original._id;
        setCurrentWarehouseId(warehouseId);

        axios.post('/basic_data/deliverywarehouse/getUsersByDeliveryWarehouseId', { _id: warehouseId })
            .then(result => {
                setUserData(result.data.result)
                setUserIds(result.data.allUserIdArr)
                // console.log(result.data.allUserIdArr)
            }).catch(err => {
                console.error(err)
            })
    }

    const deliveryWarehouseAccount = (row, { setCurrentWarehouseId }, props) => {
        axios.defaults.headers.common['authorization'] = props.token;
        const warehouseId = row.original._id;
        setCurrentWarehouseId(warehouseId);
        axios.post('/basic_data/deliverywarehouse/getAccountsByDeliveryWarehouseId', { _id: warehouseId, user: props.userId })
            .then(result => {
                setAccountData(result.data.result)
                setAccountIds(result.data.accountIds)
            }).catch(err => {
                console.error(err)
            })
    }

    const reloadUserData = () => {
        axios.defaults.headers.common['authorization'] = props.token;

        axios.post('/basic_data/deliverywarehouse/getUsersByDeliveryWarehouseId', { _id: currentWarehouseId })
            .then(result => {
                setUserData(result.data.result)
            }).catch(err => {
                console.error(err)
            })
    }

    const reloadData = () => {
        axios.defaults.headers.common['authorization'] = props.token;

        axios.post('/basic_data/deliverywarehouse/getAccountsByDeliveryWarehouseId', { _id: currentWarehouseId })
            .then(result => {
                setAccountData(result.data.result)
            }).catch(err => {
                console.error(err)
            })
    }

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const selectableColumnProps = {
        Cell: props => {
            const edit = editing === props.original;
            return (edit ?
                <Field name={props.column.id} component="select" className='form-control'>
                    {(BOOL).map(({ value, label }) =>
                        <option
                            key={value} value={value}>
                            {label}
                        </option>
                    )}

                </Field> : <label>{props.value ? 'YES' : 'NO'}</label>)
        }
    };

    const columns = [
        { Header: "Name", accessor: "name", ...editableColumnProps },
        {
            Header: 'Users',
            filterable: true,
            Cell: row => {
                return (
                    <>
                        <button data-toggle="modal" disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'VIEW', props)} data-target='#addUserPopup' className='btn btn-link'
                            data-backdrop="static" data-keyboard="false"
                            // style={{ textAlign: "center"}}
                            onClick={() => deliveryWarehouseUser(row, { setCurrentWarehouseId }, props)}
                        >+ Add User</button>
                    </>
                )
            }
        },
        {
            Header: 'Accounts',
            filterable: true,
            Cell: row => {
                let arr = []
                if (props.deliveryWarehousesForUser.length > 0) {
                    props.deliveryWarehousesForUser.map((value) => {
                        if (String(value._id) === String(row.original._id)) {
                            arr.push(value)
                        }
                    })
                }
                return (
                    <>
                        <button data-toggle="modal" disabled={props.deliveryWarehousesForUser.length > 0 ? arr.length === 0 : true || Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'VIEW', props)} data-target='#addAccountPopup' className='btn btn-link'
                            data-backdrop="static" data-keyboard="false"
                            // style={{ textAlign: "center"}}
                            onClick={() => deliveryWarehouseAccount(row, { setCurrentWarehouseId }, props)}
                        >+ Add Account</button>
                    </>
                )
            }
        },
        { Header: "Outlet", accessor: "outlet", ...selectableColumnProps },
        { Header: "Telephone", accessor: "telephone", ...editableColumnProps },
        { Header: "Google Location", accessor: "location", ...editableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const addDeliveryWarehouseHandler = () => {
        if (deliveryWarehouses.name == '') {
            toast.error('Please fill name');
        } else {
            props.addDeliveryWarehouse(deliveryWarehouses, props)
        }
    }

    const handleSubmit = value => {
        if (editing !== null) {
            value.outlet = value.outlet === 'true' || value.outlet === true ? true : false
            props.updateDeliveryWarehouse(value, props)
        } else if (deleting !== null) {
            props.deleteDeliveryWarehouse(value, props)
        }
    }

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                : true
        );
    }

    return (
        <div className="right_col" role="main" >
            <div className="card">
                <div className="card-header">
                    <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'ADD', props)} data-toggle="modal" data-target="#addDeliveryWarehouse"
                        onClick={() => setDeliveryWarehouses({ name: '' })}
                    >
                        + Delivery Warehouse
                    </button>
                </div>
                {props.isLoading
                    ? <div className="card-body">
                        <SkeletonTable columnsCount={5} dataCount={10} />
                    </div>
                    : <div className="card-body">
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                initialValues={editing === null ? deleting : editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                columns={columns}
                                                data={props.deliveryWarehouseData}
                                                defaultPageSize={10}
                                                filterable
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </div>}

                <div id="addDeliveryWarehouse" className="modal fade" role="dialog" >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Add Delivery Warehouse</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>Name :</td>
                                                <td>
                                                    <input className='form-control' value={deliveryWarehouses.name} autoComplete={false} onChange={e => setDeliveryWarehouses({ ...deliveryWarehouses, name: e.target.value })} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={addDeliveryWarehouseHandler}>Add</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div >

                <ErrorBoundary>
                    <DeliveryWarehouseUserModal currentWarehouseId={currentWarehouseId} reloadUserData={reloadUserData} filterCaseInsensitive={filterCaseInsensitive} userData={userData} userIds={userIds} props={props} />
                </ErrorBoundary>

                <ErrorBoundary>
                    <DeliveryWarehouseAccountModal currentWarehouseId={currentWarehouseId} reloadData={reloadData} filterCaseInsensitive={filterCaseInsensitive} userData={userData} accountData={accountData} accountIds={accountIds} props={props} />
                </ErrorBoundary>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userId: state.rLogin.id,
        users: state.rUser.users,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
        accounts: state.rAccounts.accounts,
        deliveryWarehousesForUser: state.rDeliveryWarehouse.deliveryWarehousesForUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: ({ token }) => { dispatch(getUsers(token)) },
        getDeliveryWarehouses: props => { dispatch(getDeliveryWarehouses(props)) },
        addDeliveryWarehouse: (data, props) => { dispatch(addDeliveryWarehouse(data, props)) },
        updateDeliveryWarehouse: (data, props) => { dispatch(updateDeliveryWarehouse(data, props)) },
        deleteDeliveryWarehouse: (data, props) => { dispatch(deleteDeliveryWarehouse(data, props)) },
        addDeliveryWarehouseUser: (data, props) => { dispatch(addDeliveryWarehouseUser(data, props)) },
        deleteDeliveryWarehouseUser: (data, props) => { dispatch(deleteDeliveryWarehouseUser(data, props)) },
        getAccountsData: (token) => { dispatch(getAccountsData(token)) },
        addDeliveryWarehouseAccount: (data, props) => { dispatch(addDeliveryWarehouseAccount(data, props)) },
        deleteDeliveryWarehouseAccount: (data, props) => { dispatch(deleteDeliveryWarehouseAccount(data, props)) },
        getDeliveryWarehousesForCurrentUser: props => { dispatch(getDeliveryWarehousesForCurrentUser(props)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryWarehouse);


