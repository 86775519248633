import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { Permission } from '../../../../utils'
import AddAccount from './AddAccount'
import AccountViewTable from './AccountViewTable'

const DeliveryWarehouseAccountModal = ({ props, currentWarehouseId, reloadData, filterCaseInsensitive, userData, accountData, accountIds }) => {
    const [viewHandler, setViewHandler] = useState({ view: true, add: false, update: false });
    const [isClose, setIsClose] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [addAccount, setAddAccount] = useState({ accounts: '', type:'' });

    useEffect(() => {
        props.getUsers(props)
        props.getAccountsData(props.token)
    }, [])

    useEffect(() => {
        if (props.accounts.length > 0) {
            const posAccounts = props.accounts.filter(account => account.acc_name.startsWith('POS'));
            if (posAccounts.length > 0) {
                posAccounts.map((v) => {
                    v.label = v.acc_name
                    v.value = v._id
                })
                setAccounts(posAccounts)
            }
        }
    }, [props.accounts])

    useEffect(() => {
        if (viewHandler.view) {
            document.getElementById('viewUAccount').classList.add('show')
            document.getElementById('addAccount').classList.remove('show')
        }
        if (viewHandler.add) {
            document.getElementById('addAccount').classList.add('show')
            document.getElementById('viewUAccount').classList.remove('show')
        }
    }, [viewHandler])

    return (
        <div id='addAccountPopup' key='addAccountPopup' className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg ">
                <div className="modal-content animate ">
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase">Add Account to Delivery Warehouse</h4>
                        <button type="button" className="close" data-dismiss="modal" >&times;</button>
                    </div>
                    <div className='card-header'>
                        <button type="button" disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'VIEW', props)} className="btn btn-primary"  onClick={() => reloadData()}>
                            <i className="fa fa-sync"></i>
                        </button>
                        <button type="button" disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'ADD', props)} className="btn btn-primary"
                            onClick={e => setViewHandler({ view: false, add: true })}>
                            + Add
                        </button>
                        <button type="button" disabled={Permission('BASIC DATA', 'DELIVERY WAREHOUSE', 'VIEW', props)} className="btn btn-primary"
                            onClick={e => setViewHandler({ view: true, add: false })}>
                            View
                        </button>
                    </div>
                    <div className="modal-body">
                        <div class="row">
                            <div class="col">
                                <div>
                                    <div className="collapse multi-collapse" id="addAccount">
                                        <div class="card card-body">
                                            <AddAccount accounts={accounts} setViewHandler={setViewHandler} addAccount={addAccount} setAddAccount={setAddAccount}
                                                isClose={isClose} setIsClose={setIsClose} currentWarehouseId={currentWarehouseId} reloadData={reloadData} accountIds={accountIds} props={props} />
                                        </div>
                                    </div>
                                    <AccountViewTable  reloadData={reloadData} viewHandler={viewHandler} setViewHandler={setViewHandler} isClose={isClose} setIsClose={setIsClose} accountData={accountData} filterCaseInsensitive={filterCaseInsensitive} props={props} />
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { setViewHandler({ view: true, add: false, update: false }); setIsClose(true) }}>Close</button>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default DeliveryWarehouseAccountModal