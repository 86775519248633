/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const deliveryConditionsAsync = data => {
    return { type: 'FETCH_DELIVERY_CONDITIONS', data }
}

/**
 * 
 * @param {*} token 
 */
export const getDeliveryConditions = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/deliverycondition/getDeliveryConditions')
            .then(result => {
                dispatch(deliveryConditionsAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addDeliveryCondition = ({ description }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/deliverycondition/addDeliveryCondition', {
            data: {
                description
            }
        })
            .then(result => {
                window.$('#addDeliveryCondition').modal('hide');
                toast.success('Successfully Added Delivery Condition');
                dispatch(getDeliveryConditions(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateDeliveryCondition = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverycondition/updateDeliveryCondition', { data })
            .then(result => {
                toast.success('Successfully  Updated Delivery Condition')
                dispatch(getDeliveryConditions(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteDeliveryCondition = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverycondition/deleteDeliveryCondition', { data })
            .then(result => {
                toast.success('Delivery Condition Deleted Successfully!')
                dispatch(getDeliveryConditions(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}