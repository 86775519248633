/**
 * @author Umesh Ranthilina
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const outletItemPricesAsync = data => {
    return { type: 'FETCH_OUTLET_ITEM_PRICES', data }
}

/**
 * 
 * @param {*} token 
 */
export const getOutletItemPrice = (data,token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/getOutletItemPrice', data)
            .then(result => {
                console.log(result.data)
                dispatch(outletItemPricesAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateOutletItemPrice = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/updateOutletItemPrice', { data })
            .then(result => {
                let dataValue = {
                    'userId': data.userId,
                    'token': token
                }
                toast.success('Successfully Added / Updated Discount and Price!')
                dispatch(getOutletItemPrice(dataValue))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteOutletItemPrice = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/deleteOutletItemPrice', { data })
            .then(result => {
                let dataValue = {
                    'userId': data.userId,
                    'token': token
                }
                toast.success('Outlet Deleted Successfully!')
                dispatch(getOutletItemPrice(dataValue))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addOutletItemPrice = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/addOutletItemPrice', { data })
            .then(result => {
                let dataValue = {
                    'userId': data.user_ref,
                    'token': token
                }
                toast.success('Added Outlet Item Price...!')
                dispatch(getOutletItemPrice(dataValue))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}