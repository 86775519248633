import React from 'react';

const Footer = () => {
  return (
    <footer>
      {/* footer content */}
      <div className="pull-right">
        {'Copyright © CIAO'}
      </div>
      <div className="clearfix" />
      {/* /footer content */}
    </footer>
  );
};

export default Footer;