/**
 * @author  Kavindu Shehan
 */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
// import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Table from "react-table";
// import ReactDatePicker from 'react-datepicker';
import _ from 'lodash';
import ErrorBoundary from '../../components/ErrorBoundary';
import GridFilters from "../../components/GridFilter";
import { getCityData, getRegionData } from '../../../actions/Basic_data/basic_data_action';
import { getDeliveryWarehousesForCurrentUser } from "../../../actions/Basic_data/delivery_warehouse_actions";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Permission from '../../utils/Permission';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { ExportData, PriceFormat, DateNumberFormat } from '../../utils';

// import toast from 'react-toastify';
/**
 * @param {Object} props
 */
function POSPAndL(props) {

    const toDate = () => {
        let toDate = new Date().toISOString().split('T')
        let splitTime = toDate[1].split('.')
        let timeString = `23:59:59.${splitTime[1]}`
        let newToDate = `${toDate[0]}T${timeString}`
        return newToDate;
    }

    const [data, setData] = useState([]);
    const [date, setDate] = useState({ from: new Date().toISOString().split('T')[0], to: toDate(), outlet: [], dateStatus: "Order Date" })
    const [supplierData, setSupplierData] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disable, setDisable] = useState(true);
    const [expanded, setExpanded] = useState({});
    const [stockCostTotalValue, setStockCostTotalValue] = useState(0);
    const [stockLostData, setStockLostData] = useState({});

    useEffect(() => {
        // getData()
        props.getCityData(props.token);
        props.getRegionData(props)
        props.getDeliveryWarehousesForCurrentUser(props)
    }, [])

    const getData = () => {
        console.log('coming')
        setIsLoading(true)
        setSupplierData([])
        setOrderItems([])
        setStockCostTotalValue(0)
        setStockLostData({})
        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/pos/getPOSPandL', { date, userId: props.userId })
            .then(result => {
                var pandlData = result.data;

                console.log(pandlData)

                setData(pandlData)
                if (!result.data.message) {
                    var suppliers = result.data.itemsData.map(obj => {
                        return obj.supplier;
                    })

                    result.data.itemsData.map((obj) => {
                        obj.posorderitems.purchasePrice = (Number(obj.posorderitems.purchasePrice) - (Number(obj.posorderitems.purchasePrice) * Number(obj.items.purchaseDiscount || 0) / 100))
                    })

                    var orderItem = result.data.itemsData.map(obj => {
                        return ({ ...obj.posorderitems, orderDiscount: obj.posorder.discount });
                    })

                    var orderDataArr = result.data.orders.map(obj => {
                        return obj.posorder;
                    })

                    suppliers.map(supplier => {

                        var val = result.data.itemsData.filter(obj => obj.supplier._id === supplier._id)
                        //sale
                        var sale = val.reduce((total, valData) => {
                            return total + Number((valData.posorderitems.price * valData.posorderitems.qty))
                        }, 0).toFixed(1)

                        supplier.sale = Number(sale);

                        // cost
                        var cost = val.reduce((total, valData) => {
                            return total + (Number(valData.posorderitems.purchasePrice) * valData.posorderitems.qty)
                        }, 0).toFixed(1)
                        supplier.cost = Number(cost);

                        // profit
                        var profit = (sale - cost).toFixed(1);
                        supplier.profit = Number(profit);

                        // profitPercentage
                        var profitPercentage = (((sale - cost) / (sale)) * 100).toFixed(1);
                        supplier.profitPercentage = Number(profitPercentage);
                    });

                    if (orderItem.length > 0) {
                        orderItem.map(item => {
                            if (item.returnedItems && item.returnedItems.length > 0) {
                                const disposeItems = item.returnedItems.filter(
                                    returnedItem => returnedItem.returntype === 'dispose'
                                );

                                var disposeReturnItemsValue = disposeItems.reduce((total, valData) => {
                                    return total + (valData.returnqty * item.price)
                                }, 0).toFixed(1)

                                item.returnedValue = Number(disposeReturnItemsValue);
                            } else {
                                item.returnedValue = 0;
                            }
                        })
                    }

                    // orderDataArr.map(orders => {
                    //     // var valCredit = result.data.creditnote.filter(obj => String(obj.order_ref) === String(orders._id))
                    //     // var valRefund = result.data.refund.filter(obj => String(obj.order_ref) === String(orders._id))

                    //     // var creditNoteValue = valCredit.reduce((total, valData) => {
                    //     //     return total + valData.price
                    //     // }, 0).toFixed(1)

                    //     // var refundValue = valRefund.reduce((total, valData) => {
                    //     //     return total + valData.barePrice
                    //     // }, 0).toFixed(1)

                    //     // orderCreditNoteTotalValue
                    //     orders.creditNoteValue = Number(creditNoteValue);

                    //     // orderRefundTotalValue
                    //     orders.refundValue = Number(refundValue);
                    // })

                    setSupplierData(_.uniqWith(suppliers, _.isEqual));
                    setOrderItems(orderItem);
                    setIsLoading(false);
                    setDisable(false)

                } else {
                    setData([])
                    toast.info(result.data);
                    setIsLoading(false)
                    setDisable(true)
                }

                axios.post('/pos/getPOS_PAndLLostStock', { date })
                    .then(result => {
                        const pandlCostData = result.data;
                        setStockLostData(pandlCostData);
                    })

                axios.post('/pos/getPOS_PAndLCostStock', { date })
                    .then(result => {
                        const pandlLostData = result.data;
                        setStockCostTotalValue(pandlLostData)
                    })

            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
                setIsLoading(false)
                setDisable(true)
            })
    }

    // /**
    //  * To re-calculate the prices
    //  * @author ravindra
    //  */
    // const recalculatePrice = () => {
    //     axios.defaults.headers.common['authorization'] = props.token;
    //     axios.post('/managment_info_data/recalulateOrderItemPurchasePrice', {})
    //         .then(result => {
    //             toast.success('success...!')
    //         }).catch(err => {
    //             console.error(err)
    //         })
    // }

    /**
     * To handle row expansion in react-table
     * @param {Object} rowsState 
     * @param {Array} index 
     */
    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const stockHistoryReasonColumns = [
        { Header: "Item Name", accessor: "itemName", ...GridFilters },
        { Header: "Item Code", accessor: "itemCode", ...GridFilters },
        { Header: "Supplier", accessor: "supplier", ...GridFilters },
        { Header: "Outlet", accessor: "outlet", ...GridFilters },
        { Header: "Reason", accessor: "reason", ...GridFilters },
        {
            Header: "Created Date", accessor: "createdAt", ...GridFilters,
            Cell: props => {
                if (props.original?.createdAt !== undefined) {
                    var dateTime = DateNumberFormat(props.original?.createdAt)
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        { Header: "User", accessor: "user", ...GridFilters },
        { Header: "Quantity", accessor: "stock", ...GridFilters, getProps: () => { return { style: { 'text-align': 'right' } } } },
        {
            Header: `Cost Price`,
            accessor: "Price", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.price)}
                </div>
        },
        {
            Header: `Total Price`,
            accessor: "totalPrice", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.totalPrice)}
                </div>
        },
    ];

    /**
     * columns defined for react-table columns 
     * @type {Array}
     */
    const columns = [
        { Header: "Supplier Name", accessor: "name", ...GridFilters },
        {
            Header: `Sale ( ${_.sumBy(data.orders, function (o) { return o.posorder.totalPrice }).toLocaleString('en', { minimumFractionDigits: 2 })})`, accessor: "sale",
            Cell: props => {
                var val = data.itemsData.filter(obj => obj.supplier._id === props.original._id)
                return <div style={{ textAlign: 'right' }}>
                    {val.reduce((total, valData) => {
                        return total + (((valData.posorderitems.price) - (valData.posorderitems.price * valData.posorder.discount / 100)) * valData.posorderitems.qty)
                    }, 0).toFixed(1)}
                </div>
            }
        },
        {
            Header: `Cost (${((_.sumBy(orderItems, function (o) { return Number(o.purchasePrice) * o.qty })) + (_.sumBy(orderItems, function (o) { return Number(o.returnedValue) }))).toLocaleString('en', { minimumFractionDigits: 2 })})`, accessor: "cost",
            Cell: props => {
                var val = data.itemsData.filter(obj => obj.supplier._id === props.original._id)
                return <div style={{ textAlign: 'right' }}>
                    {(val.reduce((total, valData) => {
                        return total + (Number(valData.posorderitems.purchasePrice) * valData.posorderitems.qty)
                    }, 0)).toFixed(1)}
                </div>
            }
        },
        {
            Header: `Profit (${((_.sumBy(data.orders, function (o) { return o.posorder.totalPrice })) - (_.sumBy(orderItems, function (o) { return Number(o.purchasePrice) * o.qty })) - (_.sumBy(orderItems, function (o) { return Number(o.returnedValue) }))).toLocaleString('en', { minimumFractionDigits: 2 })})`,
            accessor: "profit",
            Cell: props => {
                var val = data.itemsData.filter(obj => obj.supplier._id === props.original._id)
                var sale = val.reduce((total, valData) => {
                    return total + (((valData.posorderitems.price) - (valData.posorderitems.price * valData.posorder.discount / 100)) * valData.posorderitems.qty)
                }, 0)
                var cost = val.reduce((total, valData) => {
                    return total + (Number(valData.posorderitems.purchasePrice) * valData.posorderitems.qty)
                }, 0)
                return <div style={{ textAlign: 'right' }}>
                    {(sale - cost).toFixed(1)}
                </div>
            }
        },
        {
            Header: `Profit % (${_.sumBy(data.orders, function (o) { return o.posorder.totalPrice }) === 0 ? '-0' : ((((_.sumBy(data.orders, function (o) { return o.posorder.totalPrice })) - (_.sumBy(orderItems, function (o) { return Number(o.purchasePrice) * o.qty })) - (_.sumBy(orderItems, function (o) { return Number(o.returnedValue) }))) / _.sumBy(data.orders, function (o) { return o.posorder.totalPrice })) * 100).toFixed(1)})`,
            accessor: "profitPercentage",
            Cell: props => {
                var val = data.itemsData.filter(obj => obj.supplier._id === props.original._id)
                var sale = val.reduce((total, valData) => {
                    return total + (((valData.posorderitems.price) - (valData.posorderitems.price * valData.posorder.discount / 100))  * valData.posorderitems.qty)
                }, 0)
                var cost = val.reduce((total, valData) => {
                    return total + (Number(valData.posorderitems.purchasePrice) * valData.posorderitems.qty)
                }, 0)
                return <div style={{ textAlign: 'right' }}>
                    {(((sale - cost) / (sale)) * 100).toFixed(1)}
                </div>
            }
        },
    ];

    /**
     * sub columns defined for react-table sub component table
     * @type {Array}
     */
    const subColumns = [
        {
            Header: "Item Name", accessor: "items.name", filterable: true, ...GridFilters,
            Cell: props =>
                <>
                    <button data-toggle="modal" data-target='#orderDetailsPopup' className='btn btn-link'
                        onClick={() => {
                            axios.defaults.headers.common['authorization'] = props.token;
                            setOrderDetails([]);
                            var relatedOrderItems = _.filter(orderItems, function (o) {
                                return o.itemId === props.original.items._id;
                            });

                            for (var index = 0; index < relatedOrderItems.length; index++) {
                                let relatedOrderItem = relatedOrderItems[index];
                                data.orders.forEach(relatedOrder => {
                                    relatedOrder.posorderitems.forEach(orderItemId => {
                                        if (orderItemId._id === relatedOrderItem._id) {
                                            relatedOrderItems[index].orderId = relatedOrder.posorder.orderId;
                                            relatedOrderItems[index].name = relatedOrder.posorder.name;
                                            relatedOrderItems[index].telephone = relatedOrder.posorder.telephone;
                                            relatedOrderItems[index].status = relatedOrder.posorder.status;
                                        }
                                    });
                                });
                            }
                            setOrderDetails(relatedOrderItems);
                        }}
                    >
                        <div style={{ textAlign: 'right' }}>
                            {props.original.items.name}
                        </div>
                    </button>
                </>
        },
        { Header: "Item code", accessor: "items.itemCode", filterable: true, ...GridFilters, },
        {
            Header: "Item Qty", accessor: "qty", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {(_.sumBy(_.filter(orderItems, { itemId: props.original.items._id }), 'qty')).toFixed(1)}
                </div>
        },
        {
            Header: "Item sale", accessor: "sale", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {console.log(props)}
                    {(_.sumBy(_.filter(orderItems, { itemId: props.original.items._id }), function (o) { return ((Number(o.price) - Number(o.price * o.orderDiscount / 100)) * Number(o.qty)) })).toFixed(1)}
                </div>
        },
        {
            Header: "Item cost", accessor: "cost", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {(_.sumBy(_.filter(orderItems, { itemId: props.original.items._id }), function (o) { return Number(o.purchasePrice) * o.qty })).toFixed(1)}
                </div>
        },
        {
            Header: "Profit", accessor: "profit", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {((_.sumBy(_.filter(orderItems, { itemId: props.original.items._id }), function (o) { return ((Number(o.price) - Number(o.price * o.orderDiscount / 100)) * Number(o.qty)) })) - (_.sumBy(_.filter(orderItems, { itemId: props.original.items._id }), function (o) { return Number(o.purchasePrice) * o.qty }))).toFixed(1)}
                </div>
        },
        {
            Header: "Profit %", accessor: "profitPercentage", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {(((_.sumBy(_.filter(orderItems, { itemId: props.original.items._id }), function (o) { return ((Number(o.price) - Number(o.price * o.orderDiscount / 100)) * Number(o.qty)) })) - (_.sumBy(_.filter(orderItems, { itemId: props.original.items._id }), function (o) { return Number(o.purchasePrice) * o.qty }))) / (_.sumBy(_.filter(orderItems, { itemId: props.original.items._id }), function (o) { return o.price - (o.price * o.orderDiscount / 100) })) * 100).toFixed(1)}
                </div>
        },
    ];

    const orderColumns = [
        { Header: "Orders Name", accessor: "posorder.name", ...GridFilters },
        { Header: "Order Id", accessor: "posorder.orderId", ...GridFilters },
        { Header: "Telephone", accessor: "posorder.telephone", ...GridFilters },
        // {
        //     Header: "Whatsapp",
        //     accessor: "orders.whatsapp",
        //     ...GridFilters,
        //     filterable: true,
        //     Cell: row => {
        //         const url = "https://api.whatsapp.com/send?phone=" + row.original.orders.whatsapp;
        //         return <u><a href={url} target="_blank">{row.original.orders.whatsapp}</a></u>
        //     }

        // },
        // { Header: "Address", accessor: "orders.address", ...GridFilters },
        { Header: "Status", accessor: "posorder.status", ...GridFilters },
        // { Header: "Total Price", accessor: "orders.tax", ...GridFilters },
        // { Header: "Total Price", accessor: "orders.deliveryCharges", ...GridFilters },
        // { Header: "Total Price", accessor: "orders.totalDiscount", ...GridFilters },
        {
            Header: "Total Price", accessor: "posorder.totalPrice", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.posorder.totalPrice)}
                </div>
        },
        {
            Header: "Paid Amount", accessor: "posorder.amountPaid", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.posorder.amountPaid)}
                </div>
        },
    ];

    const orderDetailColumns = [
        { Header: "Orders Name", accessor: "name", ...GridFilters },
        { Header: "Order Id", accessor: "orderId", ...GridFilters },
        { Header: "Telephone", accessor: "telephone", ...GridFilters },
        { Header: "Status", accessor: "status", ...GridFilters },
        {
            Header: "Quantity", accessor: "quantity", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.qty}
                </div>
        },
    ];

    const groupOptions = [
        { label: 'Outlets', options: props.deliveryWarehouseData },
    ]

    const exportData = () => {
        let itemData = _.filter(data.itemsData)
        let uniqItem = _.uniqBy(itemData, 'items._id')

        uniqItem.forEach(value => {
            var data = _.filter(itemData, { items: value.items });
            var qty = (_.sumBy(data, 'posorderitems.qty')).toFixed(1)

            value.qty = Number(qty);

            // var sale = (_.sumBy(data, function (o) { return Number(o.posorderitems.price * o.posorderitems.qty) - (o.posorderitems.price * o.posorderitems.orderDiscount / 100) })).toFixed(1)
            var sale = (_.sumBy(_.filter(orderItems, { itemId: value.items._id }), function (o) { return ((Number(o.price) - Number(o.price * o.orderDiscount / 100)) * Number(o.qty)) })).toFixed(1)

            value.sale = Number(sale);

            // var cost = (_.sumBy(data, function (o) { return Number(o.posorderitems.purchasePrice) * o.posorderitems.qty })).toFixed(1);
            var cost = (_.sumBy(_.filter(orderItems, { itemId: value.items._id }), function (o) { return Number(o.purchasePrice) * o.qty })).toFixed(1)

            value.cost = Number(cost);

            value.profit = value.sale - value.cost;

            value.profitPercentage = (value.sale - value.cost) * 100 / value.sale;
            value.profitPercentage = (((_.sumBy
                (_.filter(orderItems, { itemId: value.items._id }),
                    function (o) { return ((Number(o.price) - Number(o.price * o.orderDiscount / 100)) * Number(o.qty)) })
            ) - (_.sumBy
                (_.filter(orderItems, { itemId: value.items._id }), function (o) { return Number(o.purchasePrice) * o.qty }
                ))) / (_.sumBy
                    (_.filter(orderItems, { itemId: value.items._id }),
                        function (o) { return o.price - (o.price * o.orderDiscount / 100) })) * 100)
                .toFixed(1)
        })

        var excelData = uniqItem.map(checkData => {
            return {
                'Supplier Name': checkData.supplier.name,
                'Item Name': checkData.items.name,
                'Item Code': checkData.items.itemCode,
                'Item Qty': checkData.qty,
                'Item Sale': checkData.sale,
                'Item Cost': checkData.cost,
                'Profit': checkData.profit,
                'Profit %': Number(checkData.profitPercentage).toFixed(2),
            }
        })

        ExportData(`Items details`, 'Items details', "items", excelData)
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                {/* gather needed data for search */}
                <div className="card-header" style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div style={{ width: '100%' }}>From :</div>
                        <div style={{ width: '100%' }}>
                            <input type='date' className='form-control' style={{ width: '100%' }} defaultValue={date.from} onChange={e => {
                                // console.log(e)
                                setDate({ ...date, from: new Date(e.target.valueAsDate) })
                            }} />
                        </div>
                    </div>
                    <div style={{ display: 'inline-flex' }}>
                        <div style={{ width: '100%' }}>To :</div>
                        <div style={{ width: '100%' }}>
                            <ErrorBoundary>
                                <input type='date' className='form-control' style={{ width: '100%' }} defaultValue={date.to.split('T')[0]}
                                    onChange={e => {
                                        let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                        let splitTime = toDate[1].split('.')
                                        let timeString = `23:59:59.${splitTime[1]}`
                                        let newToDate = `${toDate[0]}T${timeString}`
                                        setDate({ ...date, to: newToDate })
                                    }} />
                            </ErrorBoundary>
                        </div>
                    </div>

                    <div style={{ width: '30%' }}>
                        <ErrorBoundary>
                            <Select
                                closeMenuOnSelect={false}
                                theme={theme => ({ ...theme, borderRadius: 0 })}
                                components={makeAnimated()}
                                isMulti
                                placeholder={"Select Outlet"}
                                options={groupOptions}
                                onChange={e => {
                                    if (e !== null) {
                                        let val = e.map(obj => {
                                            let outlet = props.deliveryWarehouseData.filter(ele => ele._id === obj._id)
                                            if (outlet.length > 0) {
                                                let outlets = outlet.map(obj => { return { _id: obj._id } })
                                                return outlets
                                            }
                                        })
                                        let uniqArr = _.uniqBy(_.flatten(val), '_id')
                                        setDate({ ...date, outlet: uniqArr })
                                    } else {
                                        setDate({ ...date, outlet: [] })
                                    }
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                    <div style={{ minWidth: '10%', paddingLeft: '20px' }}>
                        <button className='btn btn-success' disabled={isLoading} onClick={getData}>{isLoading ? <div><i class="fa fa-spinner fa-pulse" aria-hidden="true"></i> Loading...</div> : 'Show Data'}</button>
                    </div>
                    <div style={{ minWidth: '10%', paddingLeft: '20px' }}>
                        <button className='btn btn-success' disabled={disable} onClick={exportData}>Export Data</button>
                    </div>
                    {/* <div style={{ minWidth: '10%' }}>
                        <button className='btn btn-success' disabled={Permission('MIS', 'P and L{Price Recal}', 'VIEW', props)} onClick={() => { if (window.confirm('Do you want  to recalculate all purchase prices?')) { recalculatePrice() }; }}>Price Recal</button>
                    </div> */}
                    {/* <div style={{ minWidth: '10%', paddingLeft: '20px' }}>
                        <button className='btn btn-success' disabled={disable} onClick={exportData}>Export Data</button>
                    </div> */}
                </div>

                {/* check data availability */}
                {isLoading
                    ? <div className="card-body">
                        <SkeletonTable columnsCount={5} dataCount={20} />
                    </div>
                    : data.length === 0
                        ? <div className="card-body">
                            No Data Found!
                        </div>
                        : <div className="card-body">
                            <div>
                                <table className='table table-bordered'>
                                    <tr>
                                        <td className="table-secondary">No of Orders :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            {data.orders.length}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="table-secondary"></td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            {/* <a data-toggle="modal" data-target="#didNotDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}> */}
                                            {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === `Didn't say` ? o.orders.totalPrice : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                            {/* {PriceFormat(_.sumBy(data.orders, function (o) { return o.posorder.payment_method === `Didn't say` ? o.posorder.totalPrice : 0 }) || 0)} */}
                                            {/* </a> */}
                                        </td>
                                        <td className="table-secondary">Paid</td>
                                        <td className="table-secondary">Unpaid</td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary">Net Total :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{_.sumBy(data.orders, function (o) { return ((o.posorder.totalPrice === undefined || o.posorder.totalPrice === null) ? 0 : o.posorder.totalPrice) }).toLocaleString('en', { minimumFractionDigits: 2 })}</td>
                                        <td className="table-secondary">Credit Card :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#bankDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Bank Transfer' ? o.orders.totalPrice : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.posorder.payment_method === 'Credit Card' ? o.posorder.totalPrice : 0 }))}
                                            </a>
                                        </td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Bank Transfer' ? o.orders.amountPaid : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                            {PriceFormat(_.sumBy(data.orders, function (o) { return o.posorder.payment_method === 'Credit Card' ? o.posorder.amountPaid : 0 }))}
                                        </td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#bankUnpaidDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Bank Transfer' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.posorder.payment_method === 'Credit Card' ? (o.posorder.totalPrice - o.posorder.amountPaid) : 0 }))}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary">Return :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            {PriceFormat(_.sumBy(
                                                data.orders,
                                                order => _.sumBy(
                                                    order.posorderitems,
                                                    item => {
                                                        if (item.returnedItems && item.returnedItems.length) {
                                                            const disposeItems = item.returnedItems.filter(
                                                                returnedItem => returnedItem.returntype === 'dispose'
                                                            );
                                                            const disposeSum = _.sumBy(
                                                                disposeItems,
                                                                disposeItem => disposeItem.returnqty * item.price
                                                            );
                                                            return disposeSum;
                                                        } else {
                                                            return 0;
                                                        }
                                                    }
                                                )
                                            )
                                            )}
                                        </td>
                                        <td className="table-secondary">Cash :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#creditDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery' ? o.orders.totalPrice : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.posorder.payment_method === 'Cash' ? o.posorder.totalPrice : 0 }))}
                                            </a>
                                        </td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{_.sumBy(data.orders, function (o) { return o.posorder.payment_method === 'Cash' ? o.posorder.amountPaid : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })}</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#cashUnpaidDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.posorder.payment_method === 'Cash' ? (o.posorder.totalPrice - o.posorder.amountPaid) : 0 }))}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        {/* <td className="table-secondary">Credit Note + Refund :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{"-"}{_.sumBy(data.orders, function (o) { return Number(o.orders.creditNoteValue + o.orders.refundValue) }).toLocaleString('en', { minimumFractionDigits: 2 })}</td>
                                        <td className="table-secondary">Credit :</td> */}
                                        {/* <td className="table-info" style={{ textAlign: 'right' }}> */}
                                        {/* <a data-toggle="modal" data-target="#creditCardDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}> */}
                                        {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? o.orders.totalPrice : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                        {/* {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? o.orders.totalPrice : 0 }))} */}
                                        {/* </a> */}
                                        {/* </td> */}
                                        {/* <td className="table-info" style={{ textAlign: 'right' }}>{_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? o.orders.amountPaid : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })}</td> */}
                                        {/* <td className="table-info" style={{ textAlign: 'right' }}> */}
                                        {/* <a data-toggle="modal" data-target="#creditCardUnpaidDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}> */}
                                        {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                        {/* {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }))} */}
                                        {/* </a> */}
                                        {/* </td> */}
                                    </tr>
                                    <tr>
                                        <td className="table-secondary">Total Lost Value :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#stockReasonPopup" href="#missing" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {stockLostData.stockLostValue !== undefined? PriceFormat(stockLostData.stockLostValue.total): 0.00}
                                            </a>
                                        </td>
                                        <td colspan="2" className="table-secondary">Total :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{PriceFormat(_.sumBy(data.orders, function (o) { return o.posorder.amountPaid }))}</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{PriceFormat(_.sumBy(data.orders, function (o) { return (o.posorder.totalPrice - o.posorder.amountPaid) }))}</td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary">Total Sale :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{PriceFormat(_.sumBy(data.orders, function (o) { return o.posorder.totalPrice}))}</td>
                                        <td className="table-secondary">Total Stock Cost :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{stockCostTotalValue.totalStockValue !==undefined ? PriceFormat(stockCostTotalValue.totalStockValue): 0.00}</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}></td>
                                        <td className="table-info" style={{ textAlign: 'right' }}></td>
                                    </tr>
                                    <tr>
                                    </tr>
                                </table>
                            </div>
                            {/* <ErrorBoundary> */}
                            <Table
                                columns={columns}
                                expanded={expanded}
                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                data={supplierData}
                                SubComponent={row => {
                                    let itemData = _.filter(data.itemsData, { supplier: { _id: row.original._id } })
                                    let uniqItem = _.uniqBy(itemData, 'items._id')

                                    uniqItem.forEach(value => {
                                        var data = _.filter(itemData, { items: value.items });
                                        var qty = (_.sumBy(data, 'posorderitems.qty')).toFixed(1)

                                        value.qty = Number(qty);

                                        var sale = (_.sumBy(data, function (o) { return Number(o.posorderitems.price) * o.posorderitems.qty })).toFixed(1)

                                        value.sale = Number(sale);

                                        var cost = (_.sumBy(data, function (o) { return Number(o.posorderitems.purchasePrice) * o.posorderitems.qty })).toFixed(1);

                                        value.cost = Number(cost);

                                        value.profit = value.sale - value.cost;

                                        value.profitPercentage = (value.sale - value.cost) * 100 / value.sale;
                                    });

                                    return (
                                        <div>
                                            {/* {itemData.map(d => d.items.name)} */}
                                            <Table
                                                data={uniqItem}
                                                columns={subColumns}
                                            />
                                        </div>
                                    )
                                }}
                            />
                            {/* </ErrorBoundary> */}
                        </div>}
            </div>

            {/* Did not say order details */}
            {/* <div id='didNotDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if (o.paymentmethods.name === "Didn't say") return o.orders }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if (o.paymentmethods.name === "Didn't say") return o.orders })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Bank transfer order details */}
            <div id='bankDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Card Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {/* <table>
                                {_.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery') return o.orders }).map(data => {
                                    // console.log(data)
                                    return (<tr>
                                        <td>{data.orders.name}</td>
                                    </tr>)
                                })}
                            </table> */}
                            {
                                _.filter(data.orders, function (o) { if (o.posorder.payment_method === 'Credit Card') return o.posorder }).length > 0 ?
                                    <Table
                                        columns={orderColumns}
                                        data={_.filter(data.orders, function (o) { if (o.posorder.payment_method === 'Credit Card') return o.posorder })}
                                        defaultPageSize={10}
                                    />
                                    : <div>No Data found...!</div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Credit transfer details */}
            <div id='creditDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if (o.posorder.payment_method === 'Cash') return o.posorder }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if (o.posorder.payment_method === 'Cash') return o.posorder })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Credit card transfer details */}
            {/* <div id='creditCardDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Card Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Credit Card on Delivery') return o.orders }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Credit Card on Delivery') return o.orders })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Bank unpaid order details */}
            <div id='bankUnpaidDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Card Unpaid Order Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if (o.posorder.payment_method === "Credit Card" && (o.posorder.totalPrice - o.posorder.amountPaid) > 0) return o.posorder }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if (o.posorder.payment_method === "Credit Card" && (o.posorder.totalPrice - o.posorder.amountPaid) > 0) return o.posorder })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Cash unpaid order details */}
            <div id='cashUnpaidDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Cash Unpaid Order Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if ((o.posorder.payment_method === "Cash") && (o.posorder.totalPrice - o.posorder.amountPaid) > 0) return o.posorder }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if ((o.posorder.payment_method === "Cash") && (o.posorder.totalPrice - o.posorder.amountPaid) > 0) return o.posorder })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Credit card unpaid order details */}
            {/* <div id='creditCardUnpaidDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Card Unpaid Order Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if ((o.paymentmethods.name === "Credit Card on Delivery") && (o.orders.totalPrice - o.orders.amountPaid) > 0) return o.orders }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if ((o.paymentmethods.name === "Credit Card on Delivery") && (o.orders.totalPrice - o.orders.amountPaid) > 0) return o.orders })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Order details popup */}
            <div id='orderDetailsPopup' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Order Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {orderDetails.length > 0 ?
                                <Table
                                    columns={orderDetailColumns}
                                    data={orderDetails}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* stock reason popup */}
            <div id='stockReasonPopup' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Stock Lost Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div class="container">
                                <ul class="nav nav-tabs">
                                    <li class="active"><a data-toggle="tab" href="#missing" style={{ fontSize: '20px' }} >Missing: <span style={{ fontSize: '15px' }} >{stockLostData.stockLostValue !== undefined ? `(${PriceFormat(stockLostData.stockLostValue.missingTotal)})` : 0}</span></a></li>
                                    <li><a data-toggle="tab" href="#damage" style={{ fontSize: '20px' }} >Damage: <span style={{ fontSize: '15px' }} >{stockLostData.stockLostValue !== undefined ? `(${PriceFormat(stockLostData.stockLostValue.damageTotal)})` : 0}</span></a></li>
                                    <li><a data-toggle="tab" href="#expired" style={{ fontSize: '20px' }} >Expired: <span style={{ fontSize: '15px' }} >{stockLostData.stockLostValue !== undefined ? `(${PriceFormat(stockLostData.stockLostValue.expireTotal)})` : 0}</span></a></li>
                                </ul>
                                {stockLostData.stockLostValue !== undefined ?
                                    <>
                                        <div className="tab-content">
                                            <div id="missing" className="tab-pane fade in active show"> {/* Add 'show' class */}
                                                {stockLostData.stockLostValue.missing.length > 0 ?
                                                    <Table
                                                        columns={stockHistoryReasonColumns}
                                                        data={stockLostData.stockLostValue.missing}
                                                        defaultPageSize={10}
                                                    />
                                                    : <div>No Data found...!</div>}
                                            </div>
                                            <div id="damage" className="tab-pane fade">
                                                {stockLostData.stockLostValue.damage.length > 0 ?
                                                    <Table
                                                        columns={stockHistoryReasonColumns}
                                                        data={stockLostData.stockLostValue.damage}
                                                        defaultPageSize={10}
                                                    />
                                                    : <div>No Data found...!</div>}
                                            </div>
                                            <div id="expired" className="tab-pane fade">
                                                {stockLostData.stockLostValue.expire.length > 0 ?
                                                    <Table
                                                        columns={stockHistoryReasonColumns}
                                                        data={stockLostData.stockLostValue.expire}
                                                        defaultPageSize={10}
                                                    />
                                                    : <div>No Data found...!</div>}
                                            </div>
                                        </div>
                                    </>
                                    : <div>No Data found...!</div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        userId: state.rLogin.id,
        city: state.rBasicData.city,
        region: state.rBasicData.region,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehousesForUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCityData: token => { dispatch(getCityData(token)) },
        getRegionData: props => { dispatch(getRegionData(props)) },
        getDeliveryWarehousesForCurrentUser: (token, user) => { dispatch(getDeliveryWarehousesForCurrentUser(token, user)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POSPAndL);


