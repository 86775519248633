/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const currenciesAsync = data => {
    return { type: 'FETCH_CURRENCIES', data }
}

/**
 * 
 * @param {*} token 
 */
export const getCurrencies = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/currency/getCurrencies')
            .then(result => {
                dispatch(currenciesAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateCurrency = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/currency/updateCurrency', { data })
            .then(result => {
                toast.success('Successfully Updated Currency')
                dispatch(getCurrencies(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteCurrency = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/currency/deleteCurrency', { data })
            .then(result => {
                toast.success('Currency Deleted Successfully!')
                dispatch(getCurrencies(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addCurrency = ({ name, code }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/currency/addCurrency', {
            data: {
                name,
                code
            }
        })
            .then(result => {
                window.$('#addCurrency').modal('hide');
                toast.success('Successfully Added Currency');
                dispatch(getCurrencies(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}