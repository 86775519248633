// import _ from 'lodash';
/**
 * @type {Object}
 * @property {array}  data  Order data
 */
const initialState = {
    orderHistory: [],
    orderHistoryItems: [],
};

const reducerOrderHistory = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_ORDER_HISTORY':
            newState.orderHistory = data;
            break;

        case 'FETCH_ORDER_HISTORY_ITEMS':
            // var { data } = action;
            newState.orderHistoryItems = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerOrderHistory;