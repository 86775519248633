/**
 * @file This file defined for supplier evalution
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field } from "redux-form";
import ReactTable from 'react-table';
import _ from 'lodash';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as BS from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import FormProvider from '../../components/FormProvider';
import GridFilter from '../../components/GridFilter';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import HighlightCell from '../../components/HighlightCell';
import ErrorBoundary from '../../components/ErrorBoundary';
import ActionsCell from '../../components/ActionCell';
import { getSupplierEvolutionData, addSupplierEvolutionData, updateSupplierEvolutionData, deleteSupplierEvolutionData } from '../../../actions/Purchasing/purchasing_supplier_evolution_action';
import { getSupplierData } from '../../../actions/Purchasing/purchasing_supplier_action';
import Permission from '../../utils/Permission';

import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import ExportData from '../../utils/ExportData';


const type = ['Product', 'Communication', 'Delivery', 'Pricing']
const priority = ['Low', 'Medium', 'High']
const bool = [{ value: false, label: 'No' }, { value: true, label: 'Yes' }]

function SupplierEvolution(props) {

    const toDate = () => {
        let toDate = new Date().toISOString().split('T')
        let splitTime = toDate[1].split('.')
        let timeString = `23:59:59.${splitTime[1]}`
        let newToDate = `${toDate[0]}T${timeString}`
        return newToDate;
    }

    const fromDate = () => {
        let today = new Date()
        let pastYear = today.getFullYear() - 1
        today.setFullYear(pastYear)
        return today.toISOString().split('T')[0];
    }

    const [dateRange, setDateRange] = useState({ to: toDate(), from: fromDate() })
    const [rSelectSupplier, setRSelectSupplier] = useState([])
    const [addEvolution, setAddEvolution] = useState({ user: props.id, date: new Date(), type: 'Product', priority: 'Low', comment: '', cust_informed: false, cust_feedback: '', resolved: false, imagepath: '', supplier_ref: '', createdAt: '', updatedAt: '' });
    const [expanded, setExpanded] = useState({})
    const [edit, setEdit] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [imagesForGallery, setImagesForGallery] = useState('')

    useEffect(() => {
        props.getSupplierEvolutionData({ ...props, dateRange })
        props.getSupplierData(props)
        // return () => {
        //     cleanup
        // }
    }, [])

    const addSupplierEvolution = () => {
        // console.log('add evalution');
        // console.log(addEvolution);
        if (addEvolution.supplier_ref === '') {
            toast.error('select a supplier')
        } else {
            props.addSupplierEvolutionData(addEvolution, { ...props, dateRange })
            window.$('#addEvolution').modal('toggle')
            setAddEvolution({ user: props.id, date: new Date(), type: 'Product', priority: 'Low', comment: '', cust_informed: false, cust_feedback: '', resolved: false, imagepath: '', supplier_ref: '', createdAt: '', updatedAt: '' })
        }
        // props.addSupplierEvolutionData(props)
    }

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            // mode: (edit === rowProps.original && rowProps.original.orders.status !== 'Delivered') ? "edit" : "view",
            mode: edit === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEdit(rowProps.original),
                onCancel: () => setEdit(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('PURCHASE', 'SUPPLIER EVOLUTION', 'UPDATE', props),
            delPerm: Permission('PURCHASE', 'SUPPLIER EVOLUTION', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableComponent,
                editing,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const selectableColumnProps = {
        // ...GridFilter,
        Cell: Props => {
            // console.log('original', Props.original)
            // console.log('edit', edit)
            const editing = edit === Props.original;
            // const fieldProps = {
            //     component: editableSelectComponent,
            //     editing,
            //     Props
            // };
            // console.log(fieldProps);
            // return <Field name={props.column.id} {...fieldProps} />;
            // console.log(Props)

            return (editing ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {(Props.column.Header === 'Type' ? type : Props.column.Header === 'Priority' ? priority : bool).map(data =>
                        <option
                            key={Props.column.Header === 'Type' || Props.column.Header === 'Priority' ? data : data.value} value={Props.column.Header === 'Type' || Props.column.Header === 'Priority' ? data : data.value}>
                            {Props.column.Header === 'Type' || Props.column.Header === 'Priority' ? data : data.label}
                        </option>
                    )}
                    {/* {(Props.column.Header === 'In' ? priority : []).map(data =>
                        <option
                            key={data} value={data}>
                            {data}
                        </option>
                    )} */}
                </Field> : <label>{Props.column.Header === 'Informed ?' || Props.column.Header === 'Resolved ?' ? Props.value ? 'YES' : 'NO' : Props.value}</label>)

            // if (editing && Props.original.orders !== undefined && (Props.column.Header !== 'Status' || Props.original.orders.status !== 'Delivered')) {
            //     return <Field name={Props.column.id} {...fieldProps} />
            // } else { return <label>{Props.value}</label> }
        }
    };

    const columns = [
        { Header: "Supplier", accessor: "name", ...GridFilter },
        {
            Header: "Product",
            Cell: Props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {_.filter(props.SupplierEvolutionData, function (o) {
                        return o.supplier._id === Props.original?._id && o.supplierevolution.type === 'Product'
                    }).length || 0}
                </div>
        },
        {
            Header: "Communication",
            Cell: Props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {_.filter(props.SupplierEvolutionData, function (o) {
                        return o.supplier._id === Props.original?._id && o.supplierevolution.type === 'Communication'
                    }).length || 0}
                </div>
        },
        {
            Header: "Delivery",
            Cell: Props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {_.filter(props.SupplierEvolutionData, function (o) {
                        return o.supplier._id === Props.original?._id && o.supplierevolution.type === 'Delivery'
                    }).length || 0}
                </div>
        },
        {
            Header: "Pricing",
            Cell: Props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {_.filter(props.SupplierEvolutionData, function (o) {
                        return o.supplier._id === Props.original?._id && o.supplierevolution.type === 'Pricing'
                    }).length || 0}
                </div>
        },
    ]

    const subColumns = [
        { Header: "User", accessor: "users.name.first", ...GridFilter },
        {
            Header: "Date", accessor: "supplierevolution.createdAt", ...GridFilter,
            Cell: row =>
                <>
                    {new Date(row.original.supplierevolution.date).toLocaleDateString()}
                </>
        },
        { Header: "Type", accessor: "supplierevolution.type", ...GridFilter, ...selectableColumnProps },
        { Header: "Priority", accessor: "supplierevolution.priority", ...GridFilter, ...selectableColumnProps },
        { Header: "Comment", accessor: "supplierevolution.comment", ...editableColumnProps },
        {
            Header: "Informed ?", accessor: "supplierevolution.cust_informed",
            filterMethod: (filter, row) => {
                // console.log(filter, row)
                if (filter.value === "all") {
                    // console.log('all');
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>,
            ...selectableColumnProps,
        },
        { Header: "feedback", accessor: "supplierevolution.cust_feedback", ...editableColumnProps },
        {
            Header: "Resolved ?", accessor: "supplierevolution.resolved",
            // Cell: row => (row.original.supplierevolution.resolved ? "Yes" : "No"),
            filterMethod: (filter, row) => {
                // console.log(filter, row)
                if (filter.value === "all") {
                    // console.log('all');
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>,
            ...selectableColumnProps,
        },
        {
            Header: 'View Images',
            maxWidth: 110,
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#viewImagesPopup' className='btn btn-link'
                        onClick={e => {
                            e.preventDefault();
                            setImagesForGallery('');
                            var imagesArr = [];
                            if (row.original.supplierevolution?.imagePath) {
                                row.original.supplierevolution.imagePath.forEach(image => {
                                    const imageObj = {
                                        original: image[0].url,
                                        thumbnail: image[0].url,
                                        originalClass: 'imgGallOriginal',
                                        thumbnailClass: 'imgGallThumb'
                                    };

                                    imagesArr.push(imageObj);
                                });
                            }
                            setImagesForGallery(imagesArr);

                        }}
                    >{row.original.supplierevolution?.imagePath === undefined ? '' : 'View Images'}</button>
                </>
        },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const handleSubmit = value => {
        // console.log(value)
        if (edit !== null && deleting === null) {
            // let newVal = value;
            if (edit !== value) {
                // if (edit.supplierevolution.cust_informed !== value.supplierevolution.cust_informed) {
                //     let newVal = ({ ...value.supplierevolution, cust_informed: value.supplierevolution[0].value })
                // console.log(value.supplierevolution[0].value);
                // console.log(newVal);
                // }
                // console.log('value')
                // console.log(value?.supplierevolution[0]?.value);

                // props.updateSupplierEvolutionData({ ...value.supplierevolution, user: props.id }, props)
                props.updateSupplierEvolutionData(value, { ...props, dateRange })
            } else {
                toast.info('Not modified...!')
            }
        } else if (edit === null && deleting !== null) {
            console.log('delete');
            props.deleteSupplierEvolutionData(value, { ...props, dateRange })
        }
    }

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const exportData = () => {
        var dataVal = _.sortBy(props.SupplierEvolutionData, ['supplier.name', 'supplierevolution.date', 'supplierevolution.type', 'supplierevolution.priority'])
        var excelData = dataVal.map(checkData => {
            return {
                'User': checkData?.users?.name?.first,
                'Date': checkData?.supplierevolution.date,
                'Suppler': checkData.supplier.name,
                'Type': checkData.supplierevolution.type,
                'Priority': checkData.supplierevolution.priority,
                'Comment': checkData.supplierevolution.comment,
                'Supplier Informed': checkData.supplierevolution.cust_informed ? 'Yes' : 'No',
                'Supplier Feedback': checkData.supplierevolution.cust_feedback,
                'Resolved': checkData.supplierevolution.resolved ? 'Yes' : 'No',
            }
        })

        // var excelData = [];

        // for (let i = 0; i < checkData.length; i++) {
        //     const element = checkData[i];

        //     if (i === 0 || element.Supplier === checkData[i - 1].Supplier) {
        //         excelData.push(element)
        //     } else {
        //         excelData.push({})
        //         excelData.push(element)
        //     }
        // }
        ExportData('Supplier_Evolution', 'Supplier Evolution', 'evolution', excelData)
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div>From   :</div>
                        <div>
                            <input className="form-control" type="date" defaultValue={dateRange.from}
                                onChange={e => setDateRange({ ...dateRange, from: e.target.valueAsDate })} />
                        </div>

                        <div>To :</div>
                        <div>
                            <input className="form-control" type="date" defaultValue={dateRange.to.split('T')[0]}
                                onChange={e => {
                                    let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                    let splitTime = toDate[1].split('.')
                                    let timeString = `23:59:59.${splitTime[1]}`
                                    let newToDate = `${toDate[0]}T${timeString}`
                                    setDateRange({ ...dateRange, to: newToDate })
                                }} />
                        </div>
                        <div>
                            <button className="btn btn-success" onClick={() => props.getSupplierEvolutionData({ ...props, dateRange })}
                                disabled={props.isLoading ? true : false}
                            >
                                {props.isLoading ? <div><i class="fa fa-spinner fa-pulse" aria-hidden="true"></i> Loading...</div> : 'Find'}
                            </button>
                        </div>
                        <div>
                            <button className="btn btn-primary" data-toggle="modal" data-target="#addEvolution"
                                disabled={props.isLoading ? true : false}
                                onClick={() => {
                                    // var imgUpload = window.$('#uploadImage')
                                    // window.$('#uploadImage').replaceWith(imgUpload.val('').clone(true))
                                    window.$('#uploadImage').val('')
                                    setRSelectSupplier([])
                                    setAddEvolution({ user: props.id, date: new Date(), type: 'Product', priority: 'Low', comment: '', cust_informed: false, cust_feedback: '', resolved: false, imagepath: '', supplier_ref: '', createdAt: '', updatedAt: '' })
                                }}
                            >
                                + Evalution
                            </button>
                            <button className="btn btn-primary" data-toggle="modal" data-target="#exportSupplierEvolution"
                                disabled={props.isLoading ? true : false}
                                onClick={exportData}
                            >
                                Export data
                            </button>
                        </div>
                    </div>
                </div>
                {props.isLoading
                    ? <div className="card-body">
                        <SkeletonTable columnsCount={8} dataCount={10} />
                    </div>
                    : <div className="card-body">
                        <ErrorBoundary>
                            {/* <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEdit(null); setDeleting(null) }}
                                initialValues={edit === null ? deleting : edit}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}> */}
                            <ReactTable
                                className="-striped"
                                columns={columns}
                                data={props.evolutionSuppliers}
                                defaultPageSize={10}
                                expanded={expanded}
                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)} 
                                SubComponent={row => {
                                    console.log(row.original);
                                    var subData = _.filter(props.SupplierEvolutionData, function (o) {
                                        return (o.supplier._id === row.original._id)
                                    })
                                    return (
                                        <ErrorBoundary>
                                            <FormProvider
                                                form="inline"
                                                onSubmit={handleSubmit}
                                                onSubmitSuccess={() => { setEdit(null); setDeleting(null) }}
                                                initialValues={edit === null ? deleting : edit}
                                                enableReinitialize
                                            >
                                                {formProps => {
                                                    return (
                                                        <form onSubmit={formProps.handleSubmit}>
                                                            <ReactTable
                                                                className="-striped"
                                                                columns={subColumns}
                                                                data={subData}
                                                                defaultPageSize={10}
                                                                filterable
                                                            />
                                                        </form>

                                                    );
                                                }}
                                            </FormProvider>
                                        </ErrorBoundary>
                                    )
                                }}
                            />
                            {/* </form>
                                    )
                                }}
                            </FormProvider> */}
                        </ErrorBoundary>
                    </div>}
            </div>

            {/* add supplier evalution */}
            <ErrorBoundary>
                <div id="addEvolution" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Add supplier evalution</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div style={{ display: 'flex', paddingBottom: '5px' }}>
                                        <div style={{ width: '25%' }}>Supplier :</div>
                                        <div style={{ width: '40%' }}>
                                            <Select
                                                options={props.supplier}
                                                value={rSelectSupplier}
                                                // className='form-control'
                                                theme={theme => ({ ...theme, borderRadius: 0 })}
                                                onChange={e => {
                                                    // console.log(e);
                                                    setRSelectSupplier(e)
                                                    setAddEvolution({ ...addEvolution, supplier_ref: e.value })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingBottom: '5px' }}>
                                        <div style={{ width: '50%' }}>Type :</div>
                                        <div style={{ width: '50%' }}>
                                            <select className="form-control" value={addEvolution.type} onChange={e => setAddEvolution({ ...addEvolution, type: e.target.value })}>
                                                {type.map(data => <option>{data}</option>)}
                                            </select>
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>Priority :</div>
                                        <div style={{ width: '50%' }}>
                                            <select className="form-control" value={addEvolution.priority} onChange={e => setAddEvolution({ ...addEvolution, priority: e.target.value })}>
                                                {priority.map(data => <option>{data}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingBottom: '5px' }}>
                                        <div style={{ width: '33%' }}>Comment :</div>
                                        <div style={{ width: '100%' }}>
                                            <textarea type="text" className="form-control" value={addEvolution.comment} onChange={e => setAddEvolution({ ...addEvolution, comment: e.target.value })} />
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingBottom: '5px' }}>
                                        <div style={{ width: '50%' }}>Supplier informed :</div>
                                        <div style={{ width: '50%' }}>
                                            <select className="form-control" value={addEvolution.cust_informed} onChange={e => setAddEvolution({ ...addEvolution, cust_informed: Boolean(e.target.value) })}>
                                                {bool.map(data => <option value={data.value}>{data.label}</option>)}
                                            </select>
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>Resolved :</div>
                                        <div style={{ width: '50%' }}>
                                            <select className="form-control" value={addEvolution.resolved} onChange={e => setAddEvolution({ ...addEvolution, resolved: Boolean(e.target.value) })}>
                                                {bool.map(data => <option value={data.value}>{data.label}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '5px' }}>
                                        <div style={{ width: '33%' }}>Supplier feedback :</div>
                                        <div style={{ width: '100%' }}>
                                            <textarea className="form-control" value={addEvolution.cust_feedback} onChange={e => setAddEvolution({ ...addEvolution, cust_feedback: e.target.value })} />
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '5px' }}>
                                        <div style={{ width: '33%' }}>Image path :</div>
                                        <div style={{ width: '100%' }}>
                                            <input id="uploadImage" type="file" className="form-control-file" multiple={true} accept="image/*"
                                                onChange={e => setAddEvolution({ ...addEvolution, imagepath: e.target.files })} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button id="add_supplier" type="button" className="btn btn-primary"
                                    // data-dismiss="modal"
                                    onClick={addSupplierEvolution}>
                                    Add
                                </button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

            {/* show supplier evalution images */}
            <div id='viewImagesPopup' className="modal fade" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Images</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            {(imagesForGallery === '' || imagesForGallery === null) ?
                                <>
                                    No Data Found
                                </>
                                :

                                <ImageGallery items={imagesForGallery} />
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close Images</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        id: state.rLogin.id,
        isLoading: state.rLogin.loading,
        token: state.rLogin.token,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        supplier: state.rSupplier.data,
        SupplierEvolutionData: state.rSupplierEvolution.data,
        evolutionSuppliers: state.rSupplierEvolution.evolutionSuppliers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSupplierData: ({ token }) => { dispatch(getSupplierData(token)) },
        getSupplierEvolutionData: props => { dispatch(getSupplierEvolutionData(props)) },
        addSupplierEvolutionData: (addData, props) => { dispatch(addSupplierEvolutionData(addData, props)) },
        updateSupplierEvolutionData: (updateData, props) => { dispatch(updateSupplierEvolutionData(updateData, props)) },
        deleteSupplierEvolutionData: (deleteData, props) => { dispatch(deleteSupplierEvolutionData(deleteData, props)) },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SupplierEvolution)
