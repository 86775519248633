import React, { useState } from 'react'
import $ from 'jquery';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ErrorBoundary from '../../components/ErrorBoundary';
import Select from 'react-select';

const AddPurchaseOrderModel = ({ supplierData, supplier, currency, remark, data, paymentCondition, deliveryCondition, etd, cutOffDate, city, orderId, setData, setSupplier, setPurchase, setPaymentCondition, setCurrency, setRemark, setOrderId, setDeliveryCondition, setWarehouse, warehouse, props }) => {
    const [etdVal, setEtdVal] = useState(null);
    return (
        <ErrorBoundary>
            <div id="addOrder" className="modal fade" role="dialog">
                <div className={`modal-dialog ${supplierData !== null ? 'modal-xl' : 'modal-lg'}`}>
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Add Purchase Order</h4>
                            {etd !== null && cutOffDate !== null ?
                                <button type="button" className="btn btn-outline-secondary" style={{ marginRight: '15px' }}
                                    onClick={() => {
                                        // console.log(props)
                                        window.$('#addOrder').modal('toggle');
                                        // props.history.goBack()
                                        props.history.push(`/purchase/Back Order?etd=${etd}&cutOffDate=${cutOffDate}&city=${city}`)
                                    }}>
                                    <i class="fas fa-arrow-left"></i> Back
                                </button>
                                // <NavLink to={`/purchase/Back Order`} className='btn btn-outline-secondary'><i class="fas fa-arrow-left"></i> Back</NavLink>
                                : <button type="button" className="close" data-dismiss="modal">&times;</button>}
                        </div>
                        <div className="modal-body">
                            <React.Fragment>
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ whiteSpace: 'nowrap' }}>Order Id :</td>
                                            <td style={{ width: '25%' }}>
                                                {/* <input id='order_id' type="text" className="form-control" value={orderId} maxLength={100} onChange={e => { $('#order_id').removeClass('parsley-error'); setOrderId(e.target.value) }} /> */}
                                                {orderId}
                                            </td>

                                            <td style={{ whiteSpace: 'nowrap' }}>Supplier :</td>
                                            <td style={{ width: '30%' }} >
                                                {/* <select className="form-control" style={{ minWidth: '10%', maxWidth: '75%' }} value={supplier} onChange={e => {
                                                    setData([])
                                                    setSupplier(e.target.value)
                                                    var objSupplier = _.find(props.supplierData, { supplier: { _id: e.target.value } })
                                                    setPurchase(objSupplier.paymentmethods._id)
                                                    setPaymentCondition(objSupplier.paymentcondition._id)
                                                    setCurrency(objSupplier.currency._id)
                                                }}>
                                                    <option value={''}>- Select Supplier -</option>
                                                    {props.supplierData.map(data => {
                                                        return <option value={data.supplier._id}>{data.supplier.name}</option>
                                                    })}
                                                </select> */}
                                                <Select
                                                    options={props.supplierData}
                                                    value={props.supplierData.filter(function (option) {
                                                        return option.value === supplier;
                                                    })}
                                                    onChange={e => {
                                                        console.log(e)
                                                        setData([])
                                                        setSupplier(e.value)
                                                        var objSupplier = _.find(props.supplierData, { supplier: { _id: e.value } })
                                                        setPurchase(objSupplier.paymentmethods._id)
                                                        setPaymentCondition(objSupplier.paymentcondition._id)
                                                        setCurrency(objSupplier.currency._id)
                                                    }}
                                                    styles={{
                                                        container: provided => ({
                                                            ...provided,
                                                            zIndex: 10000
                                                        }),
                                                        menu: provided => ({
                                                            ...provided,
                                                            zIndex: 10000
                                                        }),
                                                        dropdownIndicator: provided => ({
                                                            ...provided,
                                                            zIndex: 20000
                                                        })
                                                    }} />

                                            </td>
                                            <td style={{ whiteSpace: 'nowrap', paddingLeft: "50px" }}>Edd :</td>
                                            <td>{etd === null ? <input type='date' className="form-control" onChange={e => setEtdVal(e.target.value)} /> : new Date(etd).toLocaleDateString()}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                {supplierData !== null ?
                                    <>
                                        <div style={{ fontSize: '16px', paddingTop: '21px' }}>
                                            Supplier Details<hr />
                                        </div>

                                        <div>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '25%' }}>Currency:</td>
                                                        <td style={{ width: '25%' }}>
                                                            {/* {console.log(currency)} */}
                                                            <select className="form-control" value={currency} onChange={e => setCurrency(e.target.value)}>
                                                                {props.currency_data.map(data => {
                                                                    if (data.name === supplierData.supplier.currency.name) {
                                                                        // setCurrency(data._id)
                                                                        return <option selected="selected" value={data._id}>{supplierData.supplier.currency.name}</option>
                                                                    }
                                                                    return <option value={data._id}>{data.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                        <td style={{ width: '25%' }}>Pay Condition:</td>
                                                        <td style={{ width: '25%' }}>
                                                            <select className="form-control" onChange={e => setPaymentCondition(e.target.value)}>
                                                                {props.payment_conditons.map(data => {
                                                                    // console.log(data)
                                                                    // console.log(supplierData)
                                                                    // setPaymentCondition(data._id)
                                                                    if (data._id === supplierData.supplier.paymentcondition._id) {
                                                                        // setPaymentCondition(data._id)
                                                                        return <option selected="selected" value={data._id}>{supplierData.supplier.paymentcondition.description}</option>
                                                                    }
                                                                    return <option value={data._id}>{data.description}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '25%' }}>Remark :</td>
                                                        <td colSpan={3}><input type='text' className="form-control" value={remark} onChange={e => setRemark(e.target.value)} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '25%' }}>Warehouse :</td>
                                                        <td style={{ width: '30%' }} >
                                                            <Select
                                                                options={props.deliveryWarehouseData}
                                                                value={props.deliveryWarehouseData.filter(function (option) {
                                                                    return option.value === warehouse;
                                                                })}
                                                                theme={theme => ({ ...theme, borderRadius: 0 })}
                                                                onChange={e => {
                                                                    setWarehouse(e.value)
                                                                }}
                                                                styles={{
                                                                    container: provided => ({
                                                                        ...provided,
                                                                        zIndex: 9999
                                                                    }),
                                                                    menu: provided => ({
                                                                        ...provided,
                                                                        zIndex: 9999
                                                                    }),
                                                                    dropdownIndicator: provided => ({
                                                                        ...provided,
                                                                        zIndex: 10000
                                                                    })
                                                                }} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div style={{ fontSize: '16px', paddingTop: '21px' }}>
                                            Add Items<hr />
                                        </div>

                                        <div id="accordion" >
                                            {/* To order items */}
                                            <div className="card">
                                                <span className="card-header collapsed card-link uppercase" data-toggle="collapse" href="#collapsePackage" style={{ cursor: 'pointer', color: '##212529', letterSpacing: '1px' }}>
                                                    {'To Order Items'}
                                                </span>
                                            </div>

                                            <div id="collapsePackage" data-parent="#accordion" className='collapsed'>
                                                <div class="card-body" style={{ padding: '0' }}>
                                                    <table style={{ width: '100%' }} className='table table-striped'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Item</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>{etd === null ? 'Resv. qty' : 'Custu. qty'}</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Available Stock</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Purchased qty</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>To Order</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Qty</th>
                                                                <th scope='col'></th>
                                                                <th scope='col'>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* check stock data is available */}
                                                            {supplierData.stockData.length > 0 ?
                                                                supplierData.stockData.map(itemData => {

                                                                    let order_qty = _.sumBy(itemData.orderItem, "quantity")
                                                                    let balance = _.sumBy(itemData.purchaseItem, 'balance')
                                                                    let to_order_condition = (itemData.stock !== null && itemData.stock.availableStock < 0 && (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) > 0 && etd === null) || (etd !== null && order_qty > 0)
                                                                    if (to_order_condition) {

                                                                        let purchase_qty = _.sumBy(itemData.purchaseItem, obj => {
                                                                            if (new Date(etd) <= new Date(obj.createdAt)) {
                                                                                return obj.balance
                                                                            }
                                                                            return 0
                                                                        })

                                                                        return <tr>
                                                                            {/* item name or description with item code */}
                                                                            <td>{itemData.item.desc === undefined || itemData.item.desc === null || itemData.item.desc === '' ? itemData.item.name : itemData.item.desc} - {itemData.item.itemCode}</td>
                                                                            {/* resevation stock or customer qty(date range) */}
                                                                            <td style={{ textAlign: 'right', paddingRight: '60px' }}>
                                                                                {etd === null ? itemData.stock.reservationStock : itemData.orderItem.length === 0 ? 0 : order_qty}
                                                                            </td>
                                                                            {/* available stock in stocks */}
                                                                            <td style={{ textAlign: 'right', paddingRight: '60px' }}>{itemData.stock === null ? 0 : Number.isInteger(itemData.stock.availableStock) ? Number(itemData.stock.availableStock) : Number(itemData.stock.availableStock).toFixed(2)}</td>
                                                                            {/* purchase item => if date range exists, only shows that purchased item within the date range */}
                                                                            <td style={{ textAlign: 'right', paddingRight: '60px' }}>{etd === null ? balance : purchase_qty}</td>
                                                                            {/* total order qty to purchase*/}
                                                                            <td style={{ textAlign: 'right', paddingRight: '60px' }}>
                                                                                {itemData.stock !== null ?
                                                                                    itemData.stock.availableStock > 0 ?
                                                                                        0
                                                                                        : (Math.abs(itemData.stock.availableStock) - balance)
                                                                                    : 0}
                                                                            </td>
                                                                            {/* To purchesed qty */}
                                                                            <td>
                                                                                <input type='number' className="form-control" min={0} style={{ width: '100px', textAlign: 'right' }}
                                                                                    defaultValue={etd === null ?
                                                                                        itemData.stock !== null ?
                                                                                            itemData.stock.availableStock > 0 ?
                                                                                                0
                                                                                                : (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) > 0 ?
                                                                                                    (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance')))
                                                                                                    : 0
                                                                                            : 0
                                                                                        : (itemData.orderItem.length === 0 ?
                                                                                            0
                                                                                            : order_qty)
                                                                                        // (order_qty - purchase_qty) > 0 ?
                                                                                        //   (order_qty - purchase_qty)
                                                                                        //   : 0)
                                                                                    }

                                                                                    onBlur={e => {
                                                                                        // console.log(e.target.value)
                                                                                        var valData = data;
                                                                                        if (e.target.value !== "0") {
                                                                                            var newArr = _.filter(data, function (n) {
                                                                                                return n.item_ref === itemData.item._id
                                                                                            })

                                                                                            // setData(newArr)
                                                                                            if (newArr.length > 0) {
                                                                                                var checkVal = _.find(data, function (n) {
                                                                                                    if (n.item_ref === itemData.item._id) {
                                                                                                        n.orderqty = Number(e.target.value)
                                                                                                        n.balance = Number(e.target.value)
                                                                                                    }
                                                                                                    return n.item_ref === itemData.item._id
                                                                                                })
                                                                                                // {...checkVal,{}}
                                                                                                // console.log(checkVal)
                                                                                            } else {
                                                                                                valData.push({
                                                                                                    purchase_ref: '',
                                                                                                    item_ref: itemData.item._id,
                                                                                                    orderqty: Number(e.target.value),
                                                                                                    recivqty: 0,
                                                                                                    balance: Number(e.target.value),
                                                                                                    price: Number(itemData.item.purchasePrice || 0),
                                                                                                    purchaseDiscount: Number(itemData.item.purchaseDiscount || 0),
                                                                                                    packArr: itemData.item?.packArr,
                                                                                                    isPackagingGroup: itemData.item?.isPackagingGroup,
                                                                                                    history: false
                                                                                                })
                                                                                                setData(valData)
                                                                                            }
                                                                                        } else if (e.target.value === "0" || Number(e.target.value) === 0) {
                                                                                            var newArr = _.remove(data, function (n) {
                                                                                                return n.item_ref !== itemData.item._id
                                                                                            })
                                                                                            setData(newArr)
                                                                                        }
                                                                                    }} />
                                                                            </td>
                                                                            {/* #1ddc35 #f2ff84  #f35545 */}
                                                                            <td>
                                                                                <div style={{
                                                                                    borderRadius: '100px', width: '18px', height: '18px',
                                                                                    backgroundColor: itemData.stock !== null ?
                                                                                        itemData.stock.availableStock >= 0 || (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) === 0 ?
                                                                                            '#1ddc35'
                                                                                            : (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) > 0 ?
                                                                                                '#f35545'
                                                                                                : (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) < 0 ?
                                                                                                    '#1ddc35'
                                                                                                    : (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) === 0 ?
                                                                                                        (Math.abs(itemData.stock.availableStock) === (_.sumBy(itemData.purchaseItem, 'balance'))) ?
                                                                                                            '#f2ff84'
                                                                                                            : '#1ddc35'
                                                                                                        : '#1ddc35'
                                                                                        : '#b5b2b2'
                                                                                }}></div>
                                                                            </td>
                                                                            <td style={{
                                                                                color: itemData.item.purchasePrice === null || itemData.item.purchasePrice === undefined ? '#d61427' : null,
                                                                                fontWeight: itemData.item.purchasePrice === null || itemData.item.purchasePrice === undefined ? '800' : null
                                                                            }}>{itemData.item.purchasePrice} {itemData.item.priceUnit}</td>
                                                                        </tr>
                                                                    }

                                                                })
                                                                : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div style={{ paddingTop: '10px' }}></div>

                                            {/* other items */}
                                            <div className="card">
                                                <span class="card-header collapsed card-link uppercase" data-toggle="collapse" href="#collapsePackage1" style={{ cursor: 'pointer', color: '#212529', letterSpacing: '1px' }}>
                                                    {'Other Items'}
                                                </span>
                                            </div>

                                            <div id="collapsePackage1" className="collapse" data-parent="#accordion">
                                                <div class="card-body" style={{ padding: '0' }}>
                                                    <table style={{ width: '100%' }} className='table table-striped'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Item</th>
                                                                <th>{etd === null ? 'Resv.' : 'cust'}</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Available Stock</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Purchased qty</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>To Order</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Qty</th>
                                                                <th scope='col'></th>
                                                                <th scope='col'>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {supplierData.stockData.length > 0 ?
                                                                supplierData.stockData.map(itemData => {
                                                                    let order_qty = _.sumBy(itemData.orderItem, "quantity")
                                                                    let other_item_condition = ((itemData?.stock?.availableStock >= 0 || (itemData?.stock?.availableStock < 0 && (Math.abs(itemData?.stock?.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) <= 0)) && (etd === null)) || (etd !== null && order_qty === 0)
                                                                    if (other_item_condition) {
                                                                        return <tr>
                                                                            <td>{itemData.item.desc === undefined || itemData.item.desc === null || itemData.item.desc === '' ? itemData.item.name : itemData.item.desc} - {itemData.item.itemCode}</td>
                                                                            <td>{etd === null ? itemData.stock.reservationStock : itemData.orderItem.length === 0 ? 0 : _.sumBy(itemData.orderItem, "quantity")}</td>
                                                                            <td style={{ textAlign: 'right', paddingRight: '60px' }}>{itemData.stock === null ? 0 : Number.isInteger(itemData.stock.availableStock) ? Number(itemData.stock.availableStock) : Number(itemData.stock.availableStock).toFixed(2)}</td>
                                                                            <td style={{ textAlign: 'right', paddingRight: '60px' }}>{_.sumBy(itemData.purchaseItem, 'balance')}</td>
                                                                            <td style={{ textAlign: 'right', paddingRight: '60px' }}>
                                                                                {itemData.stock !== null ?
                                                                                    itemData.stock.availableStock > 0 ?
                                                                                        0
                                                                                        : (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance')))
                                                                                    : 0}
                                                                            </td>
                                                                            <td>
                                                                                <input type='number' className="form-control" min={0} style={{ width: '100px', textAlign: 'right' }}
                                                                                    defaultValue={itemData.stock !== null ?
                                                                                        itemData?.stock?.availableStock > 0 ?
                                                                                            0
                                                                                            : (Math.abs(itemData?.stock?.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) > 0 ?
                                                                                                (Math.abs(itemData?.stock?.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance')))
                                                                                                : 0
                                                                                        : 0}

                                                                                    onBlur={e => {
                                                                                        // console.log(e.target.value)
                                                                                        var valData = data;
                                                                                        if (e.target.value !== "0") {
                                                                                            var newArr = _.filter(data, function (n) {
                                                                                                return n.item_ref === itemData.item._id
                                                                                            })

                                                                                            // setData(newArr)
                                                                                            if (newArr.length > 0) {
                                                                                                var checkVal = _.find(data, function (n) {
                                                                                                    if (n.item_ref === itemData.item._id) {
                                                                                                        n.orderqty = Number(e.target.value)
                                                                                                        n.balance = Number(e.target.value)
                                                                                                    }
                                                                                                    return n.item_ref === itemData.item._id
                                                                                                })
                                                                                                // {...checkVal,{}}
                                                                                                // console.log(checkVal)
                                                                                            } else {
                                                                                                valData.push({
                                                                                                    purchase_ref: '',
                                                                                                    item_ref: itemData.item._id,
                                                                                                    orderqty: Number(e.target.value),
                                                                                                    recivqty: 0,
                                                                                                    balance: Number(e.target.value),
                                                                                                    price: Number(itemData.item.purchasePrice || 0),
                                                                                                    purchaseDiscount: Number(itemData.item.purchaseDiscount || 0),
                                                                                                    packArr: itemData.item?.packArr,
                                                                                                    isPackagingGroup: itemData.item?.isPackagingGroup,
                                                                                                    history: false
                                                                                                })
                                                                                                setData(valData)
                                                                                            }
                                                                                        } else if (e.target.value === "0" || Number(e.target.value) === 0) {
                                                                                            var newArr = _.remove(data, function (n) {
                                                                                                return n.item_ref !== itemData.item._id
                                                                                            })
                                                                                            setData(newArr)
                                                                                        }
                                                                                    }} />
                                                                            </td>
                                                                            {/* #1ddc35 #f2ff84  #f35545 */}
                                                                            <td>
                                                                                <div style={{
                                                                                    borderRadius: '100px', width: '18px', height: '18px',
                                                                                    backgroundColor: itemData.stock !== null ?  //check stock
                                                                                        itemData.stock.availableStock >= 0 ?  //check available stock > 0 
                                                                                            '#1ddc35'
                                                                                            : (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) > 0 ? //if To order > 0
                                                                                                '#f35545'
                                                                                                : (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) < 0 ?
                                                                                                    '#1ddc35'
                                                                                                    : (Math.abs(itemData.stock.availableStock) - (_.sumBy(itemData.purchaseItem, 'balance'))) === 0 ?
                                                                                                        (Math.abs(itemData.stock.availableStock) === (_.sumBy(itemData.purchaseItem, 'balance'))) ?
                                                                                                            '#f2ff84'
                                                                                                            : '#1ddc35'
                                                                                                        : '#1ddc35'
                                                                                        : '#b5b2b2'
                                                                                }}></div>
                                                                            </td>
                                                                            <td style={{
                                                                                color: itemData.item.purchasePrice === null || itemData.item.purchasePrice === undefined ? '#d61427' : null,
                                                                                fontWeight: itemData.item.purchasePrice === null || itemData.item.purchasePrice === undefined ? '800' : null
                                                                            }}>{itemData.item.purchasePrice} {itemData.item.priceUnit}</td>
                                                                        </tr>
                                                                    }

                                                                })
                                                                : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null}

                            </React.Fragment>
                        </div>

                        <div className="modal-footer">
                            <button id="add_order" type="button" className="btn btn-primary" disabled={supplierData === null ? true : false}
                                onClick={() => {
                                    // console.log(data)
                                    // console.log(typeof orderId)
                                    if (orderId.trim() !== '' && (etd || etdVal) && warehouse) {
                                        window.$('#addOrder').modal('toggle');
                                        var newData = _.remove(data, function (n) { return n.orderqty === 0 || n.orderqty < 0 })
                                        // var newData = _.remove(data, function (n) { return  n.recivqty <= 0 })
                                        // console.log(newData)
                                        // console.log(data)
                                        props.addOrder({ orderId, supplier, data, currency, paymentCondition, deliveryCondition, remark, etd: etd !== null ? etd : etdVal, cutOffDate: etd !== null ? cutOffDate : '', city, warehouse }, props.token)
                                        setOrderId(null); setSupplier(''); setDeliveryCondition(null);
                                        setCurrency(null); setData(null); setPaymentCondition(null); setRemark('');
                                        if (etd !== null && cutOffDate !== null) {
                                            props.history.push(`/purchase/Back Order?etd=${etd}&cutOffDate=${cutOffDate}&city=${city}`)
                                        }
                                    } else {
                                        $('#order_id').addClass('parsley-error');
                                        toast.info('Order id is empty...!')
                                    }
                                }}>Add</button>
                            {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                            {etd !== null && cutOffDate !== null ?
                                <button type="button" className="btn btn-outline-secondary" style={{ marginRight: '15px' }}
                                    onClick={() => {
                                        // console.log(props)
                                        window.$('#addOrder').modal('toggle');
                                        // props.history.goBack()
                                        props.history.push(`/purchase/Back Order?etd=${etd}&cutOffDate=${cutOffDate}&city=${city}`)
                                    }}>
                                    <i class="fas fa-arrow-left"></i> Back
                                </button>
                                : <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={() => {
                                        setOrderId(null); setCurrency(null); setDeliveryCondition(null);
                                        setData(null); setPaymentCondition(null); setRemark('');
                                    }}
                                >Close</button>}
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default AddPurchaseOrderModel
