/**
 * @author Kavindu Shehan
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const messagePoolUserAsync = data => {
    return { type: 'FETCH_MESSAGE_POOL_USER', data }
}

export const completeMessagePoolUserAsync = data => {
    return { type: 'FETCH_COMPLETE_MESSAGE_POOL_USER', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * @param {*} token 
 */
export const getMessagePoolUsers = (token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.get('/mis/messagepool/getMessagePoolUsers')
            .then(result => {
                dispatch(isLoadingAsync(false))
                dispatch(messagePoolUserAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * @param {*} token 
 */
 export const getCompleteMessagePoolUsers = ({ dateRange, token }) => {
  
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.get('/mis/messagepool/getCompleteMessagePoolUsers', { headers: { authorization: token }, params: { dateRange } })
            .then(result => {
                console.table(result.data)
                dispatch(isLoadingAsync(false))
                dispatch(completeMessagePoolUserAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}


export const deleteMessagePool = (data, { token } ) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/mis/messagepool/deleteMessagePool', { data })
            .then(result => {
                toast.success('Message Deleted Successfully!')
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

