/**
 * @file This file defines to delivery action dispatch
 * @author Bhanuka Chathuranga
 */
import { toast } from 'react-toastify';
import { getOrderIds } from '../Ordering/ordering_order_action';
import { getMissingOrderData } from './missing_order_action';
var axios = require('axios');

export const deliveryBoysAsync = data => {
    return { type: 'FETCH_DELIVERY_BOYS', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const creditNoteDataAsync = data => {
    return { type: 'FEETCH_CREDIT_NOTE_DATA', data }
}

export const deliveredOverviewAsync = data => {
    return { type: 'FETCH_DELIVERY_OVERVIEW_DATA', data }
}

export const deliveryDashboardAsync = data => {
    return { type: 'FETCH_DELIVERY_DASHBOARD_DATA', data }
}

export const deliveryDataForConfirmationAsync = data => {
    return { type: 'FETCH_DELIVERY_DATA_FOR_CONFIRMATION', data }
}

/**
 * get delivery crew details 
 * 
 * @param {Array} items itmes id
 * @param {string} token 
 */
export const getDeliveryCrew = ({ token }) => {
    isLoadingAsync(true)
    deliveryBoysAsync([])
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/deliveryCrew/getDeliveryCrew')
            .then(result => {
                console.table(result.data)
                dispatch(deliveryBoysAsync(result.data))
                isLoadingAsync(false)
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

/**
 * add delivery crew details 
 * 
 * @param {Array} items itmes id
 * @param {string} token 
 */
export const addDeliveryCrew = (data, { token }) => {
    isLoadingAsync(true)
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/deliveryCrew/addDeliveryCrew', { data })
            .then(result => {
                var _id = result.data.insertedId;
                console.table(result.data)
                if (data.image === '') {
                    dispatch(getDeliveryCrew({ token }))
                } else {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const formData = new FormData();

                    formData.append("deliveryCrewImage", data.image, data.image.name);
                    formData.append("id", _id)

                    axios.post('/deliveryCrew/uploadImage', formData, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const images = {
                                'url': '/images/delivery-crew/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'delivery-crew',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }

                            // var slug = _id;

                            const data = {
                                _id: _id,
                                // slug: slug,
                                image: images
                            }

                            dispatch(updateImageData(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                }
                toast.success(result.data)
                // dispatch(getDeliveryCrew({ token }))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateImageData = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/deliveryCrew/updateImageSlug', { data })
            .then(result => {
                // window.$('#addItem').modal('hide');

                toast.success('Item Added Successfully!')
                dispatch(getDeliveryCrew({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * update delivery crew details 
 * 
 * @param {Array} items itmes id
 * @param {string} token 
 */
export const updateDeliveryCrew = (data, { token }) => {
    isLoadingAsync(true)
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/deliveryCrew/updateDeliveryCrew', { data })
            .then(result => {
                console.table(result.data)
                const _id = data._id
                if (data.image === '') {
                    dispatch(getDeliveryCrew({ token }))
                } else {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const formData = new FormData();

                    formData.append("deliveryCrewImage", data.image, data.image.name);

                    axios.post('/deliveryCrew/uploadImage', formData, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const images = {
                                'url': '/images/delivery-crew/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'delivery-crew',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }

                            // var slug = _id;

                            const data = {
                                _id: _id,
                                // slug: slug,
                                image: images
                            }

                            dispatch(updateImageData(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                }
                toast.success(result.data)
                // dispatch(getDeliveryCrew({ token }))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * update delivery crew details 
 * 
 * @param {Array} items itmes id
 * @param {string} token 
 */
export const deleteDeliveryCrew = (data, { token }) => {
    isLoadingAsync(true)
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/deliveryCrew/deleteDeliveryCrew', { data })
            .then(result => {
                //console.table(result.data)
                dispatch(getDeliveryCrew({ token }))
                toast.success(result.data)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

export const getCreditNoteData = (token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/deliveryCrew/getCreditNote')
            .then(result => {
                dispatch(creditNoteDataAsync(result.data))
            }).catch(err => {
                console.error(err);
            })
    }
}

export const getCreditNoteDataByNo = (mobileNo, setCreditNoteData, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    axios.get('/deliveryCrew/getCreditNoteByMobileNo', { params: { id: mobileNo } })
        .then(result => {
            setCreditNoteData(result.data)
        }).catch(err => {
            console.error(err);
        })
}

export const deliveryInspect = (data, reset, { setPrintOrder, selectedOrder, setPrintOrderItems }, { token }) => {
    if (data.type !== 'missed')
        document.getElementById('btn-complete').disabled = true

    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/deliveryInspect/inspect', { data })
            .then(result => {
                toast.success(result.data)
                if (data.type !== 'missed') {
                    dispatch(getOrderIds({ token }))

                    if (data.pickup !== true) {
                        document.getElementById('btn-complete').disabled = true
                        document.getElementById(`${data.riderName}`).disabled = false;
                        document.getElementById(`${data.riderName}`).classList.add('btn-primary');
                        document.getElementById(`${data.riderName}`).classList.remove('btn-secondary');
                    }

                    axios.defaults.headers.common['authorization'] = token;
                    axios.post('/deliveryInspect/getPrintState', { selectedOrder })
                        .then(result => {
                            result.data.map(o => {
                                setPrintOrder(o)
                                setPrintOrderItems(o.orderItems)
                            })

                            if (window.confirm("Do you want to print invoice?")) {
                                window.$("#invoicePopup").modal('toggle')
                            }
                        }).catch(err => {
                            console.error(err)
                            // toast.error(`${err}`)
                        })

                } else {
                    dispatch(getMissingOrderData({ token }))
                }
            }).catch(err => {
                console.error(err);
                toast.error(`${err.response.data}`)
            })
    }
}

export const transferCreditNote = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/deliveryCrew/transferCreditNote', { data })
            .then(result => {
                window.$('#transferCreditNote').modal('hide');
                toast.success(result.data);
                dispatch(getCreditNoteData(token));
            }).catch(err => {
                console.error(err);
            })
    }
}

export const getDeliveredData = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/deliveryCrew/getDeliveredData', { data })
            .then(result => {
                dispatch(deliveredOverviewAsync(result.data))
            }).catch(err => {
                console.error(err);
            })
    }
}

export const getDeliveryDashboard = (data, { token }) => {

    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/deliveryCrew/getDeliveryDashboard', data)
            .then(result => {
                dispatch(deliveryDashboardAsync(result.data))
            }).catch(err => {
                console.error(err);
            })
    }
}

export const updateDeliveredOverview = (data, { token }) => {

    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/deliveryCrew/updateDeliveredData', { data })
            .then(result => {
                toast.success("Update SuccessFully");

                let newData = {
                    from: data.from,
                    to: data.to
                }

                dispatch(getDeliveredData(newData, { token: token }));
            }).catch(err => {
                toast.danger("Update Failed");
                console.error(err);
            })
    }
}

export const getOrderReportData = (data, callback = "") => {
    let body = {
        from: data.from,
        to: data.to
    }

    return dispatch => {
        axios.defaults.headers.common['authorization'] = data.token;
        axios.post('/deliveryCrew/getOrderReportData', body)
            .then(result => {
                if (callback) {
                    callback(result.data);
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const resetPassword = (data, token, cb) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/deliveryCrew/resetPassword', { data })
            .then(result => {
                window.$('#resetPassword').modal('hide');
                toast.success('Password Updated Successfully!')
                cb()
                dispatch(getDeliveryCrew({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const getDeliveryDataForConfirmation = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/deliveryCrew/getDeliveryDataForConfirmation', { data })
            .then(result => {
                dispatch(deliveryDataForConfirmationAsync(result.data))
            }).catch(err => {
                console.error(err);
            })
    }
}


export const confirmDelivery = (data, dates, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/deliveryCrew/confirmDelivery', { data })
            .then(result => {
                toast.success('Delivery Confirmed.')
                dispatch(getDeliveryDataForConfirmation(dates, { token }))
            }).catch(err => {
                toast.error('Something went wrong')
                console.error(err);
            })
    }
}

export const confirmMany = (data, dates, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.post('/deliveryCrew/confirmMany', { data })
            .then(result => {
                toast.success('Delivery Confirmed.')
                dispatch(isLoadingAsync(false))
                dispatch(getDeliveryDataForConfirmation(dates, { token }))
            }).catch(err => {
                toast.error('Something went wrong')
                console.error(err);
            })
    }
}

export const updateConfirm = (data, dates, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.post('/deliveryCrew/updateDeliveryDataForConfirmation', { data })
            .then(result => {
                toast.success('Delivery Order Updated.')
                dispatch(isLoadingAsync(false))
                dispatch(getDeliveryDataForConfirmation(dates, { token }))
            }).catch(err => {
                toast.error(err.response.data)
                console.error(err);
            })
    }
}



