/**
 * @type {Object}
 * @property {array}  data  Item data
 */
const initialState = {
    currencies: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerCurrency = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_CURRENCIES':
            var { data } = action;
            newState.currencies = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerCurrency;