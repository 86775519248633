import React, { useState, useEffect } from 'react'
import Table from "react-table";
import ImageGallery from 'react-image-gallery';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import AddComplaint from './AddComplaint';
import ViewComplaintsItems from './ViewComplaintsItems';
import axios from 'axios';

const ImageHandler = (row, setImageGallery) => {
    setImageGallery('');
    var imagesArr = [];
    if (row.original?.customercomplaints?.imagePath) {
        row.original.customercomplaints.imagePath.forEach(image => {
            const imageObj = {
                original: image[0].url,
                thumbnail: image[0].url,
                originalClass: 'imgGallOriginal',
                thumbnailClass: 'imgGallThumb'
            };

            imagesArr.push(imageObj);
        });
    }
    setImageGallery(imagesArr);
}

const ComplaintViewTable = ({ customerComplaintsData, orderItemsData, orderData, edit, remove, setEdit, setRemove, sortOptions, filterCaseInsensitive, getTdProps, reloadCustomerComplaintsData, viewHandler, setViewHandler, items, addComplaintItem, isClose, setIsClose, activeStatus, setActiveStatus, setStockData, props }) => {

    const [imageView, setImageView] = useState({ view: false });
    const [imageGallery, setImageGallery] = useState('');
    const [rowInfo, setRowInfo] = useState(null);
    const [complaintsItemsData, setComplaintsItems] = useState([]); 
    const [resolvedMethodData, setResolvedMethod] = useState('');
    const [updateComplaint, setUpdateComplaint] = useState({
        'complain': '',
        'type': '',
        'resolved': 'No',
        'resolvedMethod': '',
        'order_ref': '',
        'item': [],
        'images': '',
        'account': { isAccount: false, name: '' }
    });

    useEffect(() => {
        setImageView({ view: false });
        setImageGallery('');
        setRowInfo(null);
    }, [])

    useEffect(() => {
        let { complain = '', type, resolvedMethod, resolved } = rowInfo?.original?.customercomplaints || {}
        setUpdateComplaint({ ...updateComplaint, complain: complain, type: type, resolvedMethod: resolvedMethod, resolved: resolved })
    }, [rowInfo])

    const reset = () => {
        setUpdateComplaint({
            'complain': '',
            'type': '',
            'resolved': 'No',
            'resolvedMethod': '',
            'order_ref': '',
            'item': [],
            'images': '',
            'account': { isAccount: false, name: '' }
        })
    }

    const customerComplaintsColumns = [
        { Header: 'Order ID', accessor: 'orders.orderId', filterable: true, maxWidth: 115 },
        { Header: 'Date', accessor: 'customercomplaints.date', filterable: true, maxWidth: 80 },
        { Header: 'Type', accessor: 'customercomplaints.type', maxWidth: 100 },
        { Header: 'Complain', accessor: 'customercomplaints.complain' },
        { Header: 'Item', accessor: 'items.name' },
        { Header: 'Resolved', accessor: 'customercomplaints.resolved', maxWidth: 67 },
        { Header: 'Resolved Date', accessor: 'customercomplaints.resolvedDate', filterable: true, maxWidth: 80 },
        { Header: 'Resolved Method', accessor: 'resolvemethods.method', maxWidth: 100 },
        {
            Header: 'View Images',
            maxWidth: 110,
            Cell: row =>
                <>
                    <button className='btn btn-link'
                        // data-toggle="modal" data-target='#viewImagesPopup' 
                        onClick={e => { ImageHandler(row, setImageGallery); setImageView({ view: true }); }}
                    >{row?.original?.customercomplaints?.imagePath?.length > 0 ? 'View Images' : ''}</button>
                </>
        },
        {
            Header: 'Show', maxWidth: 100,
            Cell: row =>
                <>
                    <button data-toggle='modal' disabled={row?.original?.customercomplaints?.resolved === 'Yes' ? false : true} className='btn btn-link'
                        onClick={() => {
                            const data = {
                                'customercomplaintId': row?.original?.customercomplaints?._id
                            };
                            axios.defaults.headers.common['authorization'] = props.token;
                            axios.post('/ordering/getComplaintsItems', { data })
                                .then(result => {
                                    setResolvedMethod('')
                                    result.data.map((value) => {
                                        if (value.customercomplaints.item !== undefined && value.customercomplaints.item.length > 0) {
                                            setComplaintsItems(value.customercomplaints.item);
                                            setResolvedMethod(value.resolvemethods)
                                        }
                                    })
                                }).catch(err => {
                                    console.error(err)
                                })

                            setRowInfo(row);
                            setViewHandler({ view: true, add: false, update: false, show: true })
                        }}

                    >Show</button>
                </>
        },
        {
            Header: 'Action',
            maxWidth: 110,
            Cell: row =>
                <>
                    <button className='btn btn-link'
                        // data-toggle="modal" data-target='#viewImagesPopup' 
                        disabled={row?.original?.customercomplaints?.resolved === 'Yes' ? true : false}
                        onClick={() => { setRowInfo(row); setViewHandler({ view: true, add: false, update: true, show: false }); reset(); }}
                    >
                        <i className="fas fa-edit"></i>
                    </button>
                    <button className='btn btn-link'
                        // data-toggle="modal" data-target='#viewImagesPopup' 
                        disabled={row?.original?.customercomplaints?.resolved === 'Yes' ? true : false}
                        onClick={() => { props.deleteCustomerComplaint({ _id: row?.original?.customercomplaints?._id }, props.token); reloadCustomerComplaintsData() }}
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </>
        }
    ];

    return (
        <div className="collapse multi-collapse" id="viewComplaint">
            <div class="card card-body">
                {customerComplaintsData?.length === 0 ?
                    <>
                        No Data Found...!
                    </>
                    : <>
                        <ErrorBoundary>
                            {imageView.view ?
                                <div style={{ width: '30vw', height: '10%' }}>
                                    <div>
                                        <button className='btn btn-secondary' onClick={() => setImageView({ view: false })}>back</button>
                                    </div>
                                    <div>
                                        <ImageGallery items={imageGallery} />
                                    </div>
                                </div>
                                : <>
                                    {activeStatus ?
                                        <Table className="-striped"
                                            sorted={sortOptions}
                                            onSortedChange={val => {
                                                this.setState({ sortOptions: val })
                                            }}
                                            columns={customerComplaintsColumns}
                                            data={customerComplaintsData}
                                            defaultPageSize={5}
                                            defaultFilterMethod={filterCaseInsensitive}
                                            getTdProps={getTdProps}
                                        /> : null}

                                    {rowInfo && viewHandler.view && viewHandler.update ?
                                        <div className='card card-body' style={{ marginTop: '1.5vh' }}>
                                            <AddComplaint addcomplaint={updateComplaint} edit={edit} remove={remove} setAddcomplaint={setUpdateComplaint} setEdit={setEdit} setRemove={setRemove}
                                                // complaintTypeData={complaintTypeData} resolvedData={resolvedData}
                                                addComplaintItem={addComplaintItem}
                                                orderItemsData={orderItemsData}
                                                orderData = {orderData}
                                                complaintItem={rowInfo?.original?.customercomplaints?.item} items={items}
                                                currentOrderId={rowInfo?.original?.orders?._id} isUpdate={true} complaintId={rowInfo?.original?.customercomplaints?._id}
                                                reloadCustomerComplaintsData={reloadCustomerComplaintsData} setViewHandler={setViewHandler} isClose={isClose} setIsClose={setIsClose}
                                                activeStatus={activeStatus} setActiveStatus={setActiveStatus}
                                                setStockData={setStockData}
                                                props={props} />
                                        </div>
                                        : null}

                                    {rowInfo && viewHandler.view && viewHandler.show && activeStatus ?
                                        <div className='card card-body' style={{ marginTop: '1.5vh' }}>
                                            <ViewComplaintsItems
                                                complaintsItemsData={complaintsItemsData}
                                                resolvedMethodData={resolvedMethodData}
                                                isShown={true} isClose={isClose} setIsClose={setIsClose}
                                                setViewHandler={setViewHandler}
                                                props={props} />
                                        </div>
                                        : null}
                                </>}
                        </ErrorBoundary>
                    </>}
            </div>
        </div>
    )
}

export default ComplaintViewTable
