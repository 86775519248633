/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const categoryDataAsync = data => {
    return { type: 'FETCH_CATEGORY_DATA', data }
}

/**
 * @param {*} token 
 */
export const getCategoryData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/category/getCategoryData')
            .then(result => {
                dispatch(categoryDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addCategory = ({ name, desc, userType, image, status, sortOrder, slug, createdAt, updatedAt }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/category/addCategory', {
            data: {
                name, desc, userType, status, sortOrder, slug, createdAt, updatedAt
            }
        })
            .then(result => {

                var _id = result.data.insertedId;

                //upload image
                if (image === '') {
                    var slug = _id;

                    const data = {
                        _id: _id,
                        slug: slug
                    }

                    dispatch(updateCategorySlugWithImage(data, token))
                } else {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const data = new FormData();

                    data.append("categoryImage", image, image.name);

                    axios.post('/basic_data/category/uploadImage', data, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const image = {
                                'url': '/images/categories/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'categories',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }

                            var slug = _id;

                            const data = {
                                _id: _id,
                                slug: slug,
                                image: image
                            }

                            dispatch(updateCategorySlugWithImage(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                }

                //upload image ends

            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateCategorySlugWithImage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/category/updateCategorySlug', { data })
            .then(result => {
                window.$('#addCategory').modal('hide');
                toast.success('Category Added Successfully!')
                dispatch(getCategoryData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateCategory = (data, token) => {
    return dispatch => {
        var image = '';
        var _id = data._id;
        if (data.image !== undefined) {
            if (!data.image.path) {
                image = data.image;
                delete data.image;
            }
        }

        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/category/updateCategory', { data })
            .then(result => {

                //upload image
                if (image !== '') {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const data = new FormData();

                    data.append("categoryImage", image, image.name);

                    axios.post('/basic_data/category/uploadImage', data, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const image = {
                                'url': '/images/categories/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'categories',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }

                            const data = {
                                _id: _id,
                                image: image
                            }

                            dispatch(updateImage(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                } else {
                    toast.success('Successfully Updated Category')
                    dispatch(getCategoryData(token))
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateImage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/category/updateImage', { data })
            .then(result => {
                toast.success('Successfully Updated Category')
                dispatch(getCategoryData(token))
            }).catch(err => {
                console.log(`${err}`)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteCategory = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/category/deleteCategory', { data })
            .then(result => {
                toast.success('Category Deleted Successfully!')
                dispatch(getCategoryData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}