/**
 * @type {Object}
 * @property {array}  deliverynotes  Missing Order data
 */
const initialState = {
    missingorders: [],
    missingCompleteOrders: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerDeliveryNote = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_MISSING_ORDER':
            var { data } = action;
            newState.missingorders = data;
            break;

        case 'FETCH_MISSING_COMPLETE_ORDER':
            var { data } = action;
            newState.missingCompleteOrders = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerDeliveryNote;