import React, { Fragment, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
// import ITEM_TYPE from "../data/types";

const Item = ({ item, index, moveItem, status }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'item',
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return
            }

            const hoveredRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
            const mousePosition = monitor.getClientOffset();
            const hoverClientY = mousePosition.y - hoveredRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'item', ...item, index },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(ref));

    return (
        <Fragment>
            <div
                ref={ref}
                style={{ opacity: isDragging ? 0 : 1 }}
                style={{ fontSize: '12px', marginBottom: '10px', padding: '10px', borderRadius: '5px', zIndex: 1, backgroundColor: 'white' }}
            >
                <div style={{ backgroundColor: status.color, width: '30px', height: '10px', borderRadius: '5px' }} />
                <p style={{ fontWeight: '600', fontSize: '16px' }}>{item?.orderId}</p>
                <p style={{ fontWeight: '600', fontSize: '16px' }}>{item?.name}</p>
                <p style={{ textAlign: 'right' }}>{item.icon}</p>
            </div>
        </Fragment>
    );
};

export default Item;
