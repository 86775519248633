import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Table from 'react-table';
import axios from 'axios';
import GridFilter from '../../components/GridFilter';


const getData = (setRefundData, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    axios.get('/deliveryCrew/getReturnOrders')
        .then(result => {
            console.log(result.data);
            setRefundData(result.data)
        }).catch(err => {
            console.error(err);
        })
}

const columns = [
    { Header: "Customer Name", accessor: "coName", ...GridFilter },
    { Header: "Order Id", accessor: "orderId", ...GridFilter },
    { Header: "Complain", accessor: "complain", ...GridFilter },
    { Header: "Resolved", accessor: "resolved", ...GridFilter },
]

const subColumns = [
    { Header: "Item Name", accessor: "name", ...GridFilter },
    { Header: "Item Code", accessor: "itemCode", ...GridFilter },
    { Header: "Resolve Type", accessor: "resolveType", ...GridFilter, Cell: prop => <>{prop.value === 0 ? 'Create purchase order' : 'Get from stock'}</> },
]

const ReturnOrder = props => {

    const [returnOrderData, setReturnOrderData] = useState([]);

    useEffect(() => {
        getData(setReturnOrderData, props)
    }, [])

    return (
        <div className="right_col" role="main">
            <div className='card'>
                <div className='card-body'>
                    <Table className="-striped"
                        columns={columns}
                        data={returnOrderData}
                        SubComponent={row => {

                            return (
                                <Table
                                    columns={subColumns}
                                    data={row.original.item}
                                    // expanded={{ [row.index]: true }}
                                    defaultPageSize={5} />
                            )
                        }
                        }
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    token: state.rLogin.token,
    isLoading: state.rLogin.loading,
    userId: state.rLogin.id,
    loggedUser: state.rLogin.name,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnOrder)
