import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";
import { toast } from 'react-toastify';
import _, { filter, forEach } from 'lodash';

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import Permission from '../../utils/Permission';
import ActionsCell from "../../components/ActionCell";
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';

import SearchableSelectComp from '../../components/SearchableSelectComp';
import { DateNumberFormat } from '../../utils';
import { ExportData } from '../../utils'

import { getItems } from '../../../actions/Basic_data/item_actions';
import { getExpDateStockPack } from '../../../actions/Stock/stock_item_actions';
import { getSupplierData } from '../../../actions/Purchasing/purchasing_supplier_action';
import { getDeliveryWarehouses } from '../../../actions/Basic_data/delivery_warehouse_actions';
import {
    createPackage,
    getPackageData,
    getPackageSendItemsData,
    deletePackage,
    recvPackage,
    getPackageRecvItemsData
} from '../../../actions/Stock/packaging_actions';


export const Packaging = (props) => {

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        const currentDate = `${year}-${month}-${day}`;

        return currentDate
    }

    const [editing, setEditing] = useState(null)
    const [deleting, setDeleting] = useState(null)
    const [expanded, setExpanded] = useState({})
    const [innerExpanded, setInnerExpanded] = useState({})
    const [active, setActive] = useState(true)
    const [sortOptions, setSortOptions] = useState([{ id: 'createdAt', desc: true }])
    const [filteredItems, setFilteredItems] = useState([])
    const [filteredItemsForCreate, setFilteredItemsForCreate] = useState([])

    const [currentItem, setCurrentItem] = useState(null)
    const [selectedItems, setSelectedItems] = useState([])
    const [dueDate, setDueDate] = useState(null)
    const [note, setNote] = useState(null)
    const [supplier, setSupplier] = useState(null)
    const [recvData, setRecvData] = useState({
        ref_package: null,
        ref_packagesenditem: null,
        ref_item: null,
        qty: 0,
        purchase_price: 0,
        is_completed: true,
        ref_warehouse: null,
        exp_date: ''
    })

    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: ''
    })

    const [isSendReport, setIsSendReport] = useState(true)

    useEffect(() => {
        props.getItems(props.token)
        props.getSupplierData(props.token)
        props.getPackageData(props.token)
        props.getPackageSendItemsData(props.token)
        props.getPackageRecvItemsData(props.token)
        props.getDeliveryWarehouses(props)
    }, [])


    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const handleRowInnerExpanded = (rowsState, index) => {
        setInnerExpanded({
            [index[0]]: !innerExpanded[index[0]],
        })
    }

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => { setEditing(rowProps.original) },
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('STOCK', 'PACKAGING', 'UPDATE', props),
            delPerm: Permission('STOCK', 'PACKAGING', 'DELETE', false)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableNumberColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };

            return <Field name={props.column.id} type="Number" step="0.001" min={0} max={props.original.qty} {...fieldProps} />;
        }
    };

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const packagingColumnsActive = [
        { Header: "Package ID", accessor: "package.package_id", filterable: true, getProps: () => { return { style: { textAlign: 'right' } } } },
        { Header: "Package Supplier", accessor: "supplier.name", filterable: true },
        {
            Header: "Creation Date",
            accessor: "package.creation_date",
            filterable: true,
            Cell: row => {
                return <div>{formatDate(row.original.package.creation_date)}</div>
            },
            getProps: () => { return { style: { textAlign: 'right' } } }
        },
        {
            Header: "Due Date",
            accessor: "package.due_date",
            filterable: true,
            Cell: row => {
                return <div>{formatDate(row.original.package.due_date)}</div>
            },
            getProps: () => { return { style: { textAlign: 'right' } } }
        },
        {
            Header: "Recv Date",
            accessor: "package.recv_date",
            filterable: true,
            Cell: row => {
                return <div>{row.original.package.recv_date ? formatDate(row.original.package.recv_date) : ''}</div>
            },
            getProps: () => { return { style: { textAlign: 'right' } } }
        },
        { Header: "Note", accessor: "package.note", filterable: true },
    ]

    const packagingColumnsHistory = [
        { Header: "Package ID", accessor: "package.package_id", filterable: true, getProps: () => { return { style: { textAlign: 'right' } } } },
        { Header: "Package Supplier", accessor: "supplier.name", filterable: true },
        {
            Header: "Creation Date",
            accessor: "package.creation_date",
            filterable: true,
            Cell: row => {
                return <div>{formatDate(row.original.package.creation_date)}</div>
            },
            getProps: () => { return { style: { textAlign: 'right' } } }
        },
        {
            Header: "Due Date",
            accessor: "package.due_date",
            filterable: true,
            Cell: row => {
                return <div>{formatDate(row.original.package.due_date)}</div>
            },
            getProps: () => { return { style: { textAlign: 'right' } } }
        },
        {
            Header: "Recv Date",
            accessor: "package.received_date",
            filterable: true,
            Cell: row => {
                return <div>{row.original.package.received_date ? formatDate(row.original.package.received_date) : ''}</div>
            },
            getProps: () => { return { style: { textAlign: 'right' } } }
        },
        { Header: "Note", accessor: "package.note", filterable: true },
    ]

    const getPackagingSendItemsColumns = () => {
        const columns = [
            { Header: "Item", accessor: "items.itemCode", filterable: true },
            { Header: "Purchase Price", accessor: "packagesenditems.purchase_price", filterable: true, getProps: () => { return { style: { textAlign: 'right' } } } },
            { Header: "Qty", accessor: "packagesenditems.qty", filterable: true, getProps: () => { return { style: { textAlign: 'right' } } } },
            { Header: "Received Qty", accessor: "packagesenditems.received_qty", filterable: true, getProps: () => { return { style: { textAlign: 'right' } } } },
        ]

        if (active) {
            let obj = {
                Header: "",
                maxWidth: 130,
                minWidth: 130,
                Cell: row => {
                    return <button
                        type='button'
                        className='btn btn-link'
                        data-toggle="modal"
                        data-target="#recvItem"
                        data-backdrop="static" data-keyboard="false"
                        disabled={row.original.packagesenditems.completed === true ? true : false}
                        onClick={e => {
                            setRecvData({
                                ...recvData,
                                ref_package: row.original.packagesenditems.ref_package,
                                ref_packagesenditem: row.original.packagesenditems._id
                            })

                            let filteredItems = _.filter(props.itemsData, item => {
                                return row.original.items.brand === item.brand
                            })

                            setFilteredItems(filteredItems)
                        }}
                    >
                        Receive Item
                    </button>
                }
            }

            columns.push(obj)
        }

        return columns;
    }

    const packagingSendItemsColumns = getPackagingSendItemsColumns()

    const packagingRecvItemsColumns = [
        { Header: "Item", accessor: "items.itemCode", filterable: true },
        { Header: "Purchase Price", accessor: "packagereceiveitems.purchase_price", filterable: true, getProps: () => { return { style: { textAlign: 'right' } } } },
        { Header: "Qty", accessor: "packagereceiveitems.qty", filterable: true, getProps: () => { return { style: { textAlign: 'right' } } } },
        {
            Header: "Received Date",
            Cell: prop => <>{DateNumberFormat(prop.value)}</>,
            accessor: "packagereceiveitems.recv_date",
            filterable: true,
            getProps: () => { return { style: { textAlign: 'right' } } }
        },
    ]

    const stockExpColumns = [
        { Header: "Warehouse", accessor: "name", ...GridFilter, Cell: prop => <>{prop.value}</> },
        { Header: "Exp. Date", accessor: "exp_date", filterable: true, Cell: prop => <>{DateNumberFormat(prop.value)}</> },
        { Header: "Qty", accessor: "qty", filterable: true },
        { Header: "Selected Qty", accessor: "selected_qty", ...editableNumberColumnProps },
        { Header: "User", accessor: "user.first", filterable: true },
        { Header: "CreatedAt", accessor: "createdAt", filterable: true, Cell: prop => <>{DateNumberFormat(prop.value)}</> },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        }
    ]

    const setSupplierValue = (value) => {
        setSupplier(value)

        let filteredItems = _.filter(props.itemsData, item => {
            return item.supplier === value
        })

        setFilteredItemsForCreate(filteredItems)
    }

    const handleStockExpSubmit = (value) => {
        selectedItems.map(sItem => {
            if (sItem._id === currentItem._id) {
                let flag = false;
                sItem.expItems.map((xpItem, index) => {
                    if (xpItem._id === value._id && xpItem.name === value.name) {
                        sItem.expItems.splice(index, 1)
                        flag = true
                    }
                })
                sItem.expItems.push(value)
            }

            let totalQty = sItem.expItems.reduce((sum, currentObj) => sum + Number(currentObj.selected_qty), 0)
            sItem.totalQty = Number(totalQty)
        })

        updateStockExpData(value)
    }

    const updateStockExpData = (value) => {

        props.stockItemData.exp_items_stocks.map(eis => {
            if (eis._id.toString() === value._id.toString() && eis.name === value.name) {
                eis.selected_qty = value.selected_qty
            }
        })
    }

    const fetchExpItmsData = (e, selected = false) => {
        let obj = {
            '_id': e._id,
            'label': e.label,
            'expItems': [],
            'totalQty': 0,
        }

        setCurrentItem(obj)
        setSelectedItems(prevItems => {
            const itemExists = prevItems.some((prevItem) => prevItem._id === obj._id);
            if (!itemExists) {
                return [...prevItems, obj];
            }

            return prevItems;
        })

        let data = {
            "item": e._id,
            "warehouse": "",
            "from": "",
            "to": ""
        };

        if (selected) {
            let filtered = _.filter(selectedItems, item => {
                return item._id === e._id
            })

            props.getExpDateStockPack(data, props, filtered[0])
        } else {
            props.getExpDateStockPack(data, props, {})
        }
    }

    const createPackage = () => {
        if (dueDate === null || dueDate === '') {
            toast.error('Due date cannot be empty.');
            return
        }

        if (selectedItems.length < 1) {
            toast.error('Please select a few items.');
            return
        }

        let data = {
            selectedItems,
            dueDate,
            note,
            supplier
        }

        props.createPackage(data, props.token, clear)
    }

    const clear = () => {
        setCurrentItem(null)
        setSelectedItems([])
        setSupplier('')
        document.getElementById("createPackageForm").reset()
    }

    const recvItem = () => {
        if (
            recvData.ref_item !== null
            && recvData.qty > 0
            && recvData.purchase_price > -1
            && recvData.ref_warehouse !== null
            && recvData.exp_date !== ''
        ) {
            props.recvPackage(recvData, props.token, clearRecvItem)
        } else {
            toast.error('Please fill all the fields.')
        }
    }

    const clearRecvItem = () => {
        document.getElementById("recvItemForm").reset()
        setRecvData({
            ref_package: null,
            ref_packagesenditem: null,
            ref_item: null,
            qty: 0,
            purchase_price: 0,
            is_completed: true,
            ref_warehouse: null,
            exp_date: ''
        })
    }

    const clearReportPopup = () => {
        document.getElementById("reportPopupForm").reset()
        setDateRange({
            startDate: '',
            endDate: ''
        })
    }

    const report = () => {
        if(dateRange.startDate !== '' && dateRange.endDate !== '') {
            let items = []

            if(isSendReport) {
                let filteredData = _.filter(props.packagingDataActive, pack => {
                    return formatDate(pack.package.creation_date) >= dateRange.startDate &&
                        formatDate(pack.package.creation_date) <= dateRange.endDate
                })
        
                filteredData.map(fData => {
                    props.packagingSendItemsData.map(item => {
                        if(item.packagesenditems.ref_package === fData.package._id) {
                            let obj = {
                                'Date': formatDate(fData.package.creation_date),
                                'Item': item.items.itemCode,
                                'Qty': item.packagesenditems.qty,
                                'Completed': item.packagesenditems.completed ? 'Yes' : 'No'
                            }
        
                            items.push(obj)
                        }
                    })
                })

                ExportData("sendItemsReport", "Send Items Report", "Send Items Report", items)
            } else {
                let filteredData = _.filter(props.packagingRecvItemsData, pack => {
                    return formatDate(pack.packagereceiveitems.recv_date) >= dateRange.startDate &&
                        formatDate(pack.packagereceiveitems.recv_date) <= dateRange.endDate
                })

                filteredData.map(element => {
                    let obj = {
                        'Date': formatDate(element.packagereceiveitems.recv_date),
                        'Item': element.items.itemCode,
                        'Purchase Price': element.packagereceiveitems.purchase_price,
                        'Qty': element.packagereceiveitems.qty
                    }

                    items.push(obj)
                })

                ExportData("receivedItemsReport", "Received Items Report", "Received Items Report", items)
            }            
        } else {
            toast.error('Please select date range.')
        }        
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <div className='row'>
                        <div className='col-lg-7 col-md-7'>
                            <button
                                className="btn btn-primary"
                                disabled={Permission('STOCK', 'PACKAGING', 'ADD', props)}
                                data-toggle="modal"
                                data-target="#createPackage"
                                data-backdrop="static" data-keyboard="false"
                            >+ Create</button>
                              <button
                                className="btn btn-success"
                                data-toggle="modal"
                                data-target="#reportPopup"
                                data-backdrop="static" data-keyboard="false"
                                onClick={e => { setIsSendReport(true) }}
                            >Send Items Report</button>
                            <button
                                data-target="#reportPopup"
                                data-toggle="modal"
                                data-backdrop="static" data-keyboard="false"
                                className='btn btn-success'
                                onClick={e => { setIsSendReport(false) }}
                            >Receive Items Report</button>
                        </div>
                        <div className='col-lg-5 col-md-5' style={{ textAlign: 'right' }}>
                            <input type='radio' name='active' style={{ marginRight: '5px' }} checked={active ? true : false} onChange={e => { setActive(true) }} /><label>Active</label>
                            <input type='radio' name='active' style={{ marginLeft: '30px', marginRight: '5px' }} checked={active ? false : true} onChange={e => { setActive(false) }} /><label>Completed</label>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {
                        active ?
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <Table className="-striped"
                                                    columns={packagingColumnsActive}
                                                    data={props.packagingDataActive}
                                                    sorted={sortOptions}
                                                    onSortedChange={val => {
                                                        setSortOptions(val)
                                                    }}
                                                    defaultPageSize={10}
                                                    expanded={expanded}
                                                    onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                                    defaultFilterMethod={filterCaseInsensitive}
                                                    showPageJump={false}
                                                    SubComponent={row => {
                                                        let subData = _.filter(props.packagingSendItemsData, (o) => {
                                                            return o.packagesenditems.ref_package === row.original.package._id
                                                        })
                                                        return (
                                                            <React.Fragment>
                                                                <ErrorBoundary>
                                                                    <FormProvider
                                                                        form="inline"
                                                                        enableReinitialize
                                                                    >
                                                                        {formProps => {
                                                                            return (
                                                                                <Table className="-striped"
                                                                                    columns={packagingSendItemsColumns}
                                                                                    data={subData}
                                                                                    defaultPageSize={10}
                                                                                    expanded={innerExpanded}
                                                                                    onExpandedChange={(newExpanded, index, event) => handleRowInnerExpanded(newExpanded, index)}
                                                                                    defaultFilterMethod={filterCaseInsensitive}
                                                                                    showPageJump={false}
                                                                                    SubComponent={row => {
                                                                                        let innerSubData = _.filter(props.packagingRecvItemsData, (element) => {
                                                                                            return element.packagereceiveitems.ref_packagesenditem === row.original.packagesenditems._id
                                                                                        })

                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                <ErrorBoundary>
                                                                                                    <FormProvider
                                                                                                        form="inline"
                                                                                                        enableReinitialize
                                                                                                    >
                                                                                                        {formProps => {
                                                                                                            return (
                                                                                                                <Table className="-striped"
                                                                                                                    columns={packagingRecvItemsColumns}
                                                                                                                    data={innerSubData}
                                                                                                                    defaultPageSize={10}
                                                                                                                    defaultFilterMethod={filterCaseInsensitive}
                                                                                                                    showPageJump={false}
                                                                                                                />
                                                                                                            )
                                                                                                        }}

                                                                                                    </FormProvider>
                                                                                                </ErrorBoundary>
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }}

                                                                    </FormProvider>
                                                                </ErrorBoundary>
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                            :
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form >
                                                <Table className="-striped"
                                                    columns={packagingColumnsHistory}
                                                    data={props.packagingDataHistory}
                                                    sorted={sortOptions}
                                                    onSortedChange={val => {
                                                        setSortOptions(val)
                                                    }}
                                                    defaultPageSize={10}
                                                    expanded={expanded}
                                                    onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                                    defaultFilterMethod={filterCaseInsensitive}
                                                    showPageJump={false}
                                                    SubComponent={row => {
                                                        let subData = _.filter(props.packagingSendItemsData, (o) => {
                                                            return o.packagesenditems.ref_package === row.original.package._id
                                                        })
                                                        return (
                                                            <React.Fragment>
                                                                <ErrorBoundary>
                                                                    <FormProvider
                                                                        form="inline"
                                                                        enableReinitialize
                                                                    >
                                                                        {formProps => {
                                                                            return (
                                                                                <Table className="-striped"
                                                                                    columns={packagingSendItemsColumns}
                                                                                    data={subData}
                                                                                    defaultPageSize={10}
                                                                                    expanded={innerExpanded}
                                                                                    onExpandedChange={(newExpanded, index, event) => handleRowInnerExpanded(newExpanded, index)}
                                                                                    defaultFilterMethod={filterCaseInsensitive}
                                                                                    showPageJump={false}
                                                                                    SubComponent={row => {
                                                                                        let innerSubData = _.filter(props.packagingRecvItemsData, (element) => {
                                                                                            return element.packagereceiveitems.ref_packagesenditem === row.original.packagesenditems._id
                                                                                        })

                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                <ErrorBoundary>
                                                                                                    <FormProvider
                                                                                                        form="inline"
                                                                                                        enableReinitialize
                                                                                                    >
                                                                                                        {formProps => {
                                                                                                            return (
                                                                                                                <Table className="-striped"
                                                                                                                    columns={packagingRecvItemsColumns}
                                                                                                                    data={innerSubData}
                                                                                                                    defaultPageSize={10}
                                                                                                                    defaultFilterMethod={filterCaseInsensitive}
                                                                                                                    showPageJump={false}
                                                                                                                />
                                                                                                            )
                                                                                                        }}

                                                                                                    </FormProvider>
                                                                                                </ErrorBoundary>
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }}

                                                                    </FormProvider>
                                                                </ErrorBoundary>
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                    }

                </div>
            </div>

            <div id="createPackage" className="modal fade" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Create Package</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={e => clear()}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <React.Fragment>
                                <form id="createPackageForm">
                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Due Date
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <input min={getCurrentDate()} type='date' className='form-control' onChange={e => setDueDate(e.target.value)} onBlur={e => setDueDate(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Note
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <textarea className='form-control' onChange={e => setNote(e.target.value)}></textarea>
                                        </div>
                                    </div>

                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Supplier
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <SearchableSelectComp
                                                className='form-control'
                                                options={props.suppliers}
                                                label={'label'}
                                                value={supplier}
                                                onChange={e => {
                                                    setSupplierValue(e.value)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Selected Items
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <div className='row'>
                                                {
                                                    selectedItems.length > 0 ?
                                                        selectedItems.map(sItem => {
                                                            return <div
                                                                className='col-md-4'
                                                                style={{ marginBottom: '3%', cursor: 'pointer' }}
                                                                key={sItem._id}
                                                                onClick={e => {
                                                                    fetchExpItmsData(sItem, true)
                                                                }}
                                                            >
                                                                <div class="card h-100 d-flex flex-column">
                                                                    <div className='card-body'>
                                                                        <button
                                                                            type="button"
                                                                            className="close"
                                                                            aria-label="Remove"
                                                                            onClick={e => {
                                                                                e.stopPropagation()
                                                                                setSelectedItems(prevItems => {
                                                                                    return prevItems.filter(item => item._id !== sItem._id)
                                                                                })
                                                                                props.stockItemData.exp_items_stocks = []
                                                                                setCurrentItem(null)
                                                                            }}>
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                        {
                                                                            sItem._id === currentItem?._id ?
                                                                                <p style={{ fontWeight: 'bold', color: '#008F8F' }}>{sItem.label}</p>
                                                                                :
                                                                                <p>{sItem.label}</p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }) : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-lg-2 col-md-2'>
                                            Select Item
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <SearchableSelectComp
                                                className='form-control'
                                                options={filteredItemsForCreate}
                                                label={'label'}
                                                value={currentItem}
                                                onChange={e => {
                                                    fetchExpItmsData(e)
                                                }}
                                            />
                                        </div>

                                    </div>
                                </form>
                            </React.Fragment>
                        </div>

                        <hr />


                        {
                            currentItem !== null && selectedItems.length > 0 && props.stockItemData?.exp_items_stocks?.length > 0 ?
                                <FormProvider
                                    form="inline"
                                    onSubmit={value => handleStockExpSubmit(value)}
                                    onSubmitSuccess={() => { setEditing(null); setDeleting(null) }}
                                    initialValues={editing === null ? deleting : editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <Table
                                                    columns={stockExpColumns}
                                                    data={props.stockItemData.exp_items_stocks}
                                                    defaultPageSize={5} />
                                            </form>)
                                    }
                                    }
                                </FormProvider>
                                :
                                <div className='row' style={{ marginLeft: "45%" }}>
                                    <p>No Data available.</p>
                                </div>
                        }


                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={e => createPackage()}>Create</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => clear()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="recvItem" className="modal fade" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Receive Item</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={e => clearRecvItem()}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <React.Fragment>
                                <form id="recvItemForm">
                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Select Item *
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <SearchableSelectComp
                                                className='form-control'
                                                options={filteredItems}
                                                label={'label'}
                                                value={recvData.ref_item}
                                                onChange={e => {
                                                    setRecvData({
                                                        ...recvData,
                                                        ref_item: e.value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Qty *
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <input
                                                type='number'
                                                className='form-control'
                                                onChange={e => setRecvData({ ...recvData, qty: e.target.value })}
                                                value={recvData.qty}
                                            />
                                        </div>
                                    </div>
                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Purchase Price *
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                onChange={e => setRecvData({ ...recvData, purchase_price: e.target.value })}
                                                value={recvData.purchase_price}
                                            />
                                        </div>
                                    </div>

                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Warehouse *
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <select onChange={e => setRecvData({ ...recvData, ref_warehouse: e.target.value })} className='form-control'>
                                                <option value=''>Select Warehouse</option>
                                                {
                                                    props.deliveryWarehouseData.map(warehouse => {
                                                        return <option key={warehouse._id} value={warehouse._id}>{warehouse.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Expiry Date
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <input type='date' className='form-control' onChange={e => setRecvData({ ...recvData, exp_date: e.target.value })} onBlur={e => setRecvData({ ...recvData, exp_date: e.target.value })} />
                                        </div>
                                    </div>

                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Complete
                                        </div>
                                        <div className='col-lg-10 col-md-10'>
                                            <input
                                                type='checkbox'
                                                checked={recvData.is_completed}
                                                onChange={e => setRecvData({ ...recvData, is_completed: recvData.is_completed === true ? false : true })}
                                            />
                                        </div>
                                    </div>


                                </form>
                            </React.Fragment>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={e => recvItem()}>Receive Item</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => clearRecvItem()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="reportPopup" className="modal fade" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Export Item Report</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={e => clearReportPopup()}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <React.Fragment>
                                <form id="reportPopupForm">
                                    <div className='row' style={{ 'marginBottom': '3%' }}>
                                        <div className='col-lg-2 col-md-2'>
                                            Date Range
                                        </div>
                                        <div className='col-lg-5 col-md-5'>
                                            <input
                                                type='date'
                                                placeholder='Start Date'
                                                className='form-control'
                                                onChange={e => setDateRange({ ...dateRange, startDate: e.target.value })}
                                                value={dateRange.startDate}
                                            />
                                        </div>
                                        <div className='col-lg-5 col-md-5'>
                                            <input
                                                type='date'
                                                placeholder='End Date'
                                                className='form-control'
                                                onChange={e => setDateRange({ ...dateRange, endDate: e.target.value })}
                                                value={dateRange.endDate}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </React.Fragment>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={e => report()}>Get Report</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => clearReportPopup()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        id: state.rLogin.id,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        itemsData: state.rItem.itemsForSelect,
        stockItemData: state.rStockItem.stockItemsWithExpDateForPackaging,
        suppliers: state.rSupplier.supplierDataForSelect,
        packagingDataActive: state.rPackaging.packagingDataActive,
        packagingDataHistory: state.rPackaging.packagingDataHistory,
        packagingSendItemsData: state.rPackaging.packagingSendItemsData,
        packagingRecvItemsData: state.rPackaging.packagingRecvItemsData,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getItems: (token) => { dispatch(getItems(token)) },
        getExpDateStockPack: (data, props, selectedItem) => { dispatch(getExpDateStockPack(data, props, selectedItem)) },
        getSupplierData: (token) => { dispatch(getSupplierData(token)) },
        createPackage: (data, token, clear) => { dispatch(createPackage(data, token, clear)) },
        getPackageData: (token) => { dispatch(getPackageData(token)) },
        getPackageSendItemsData: (token) => { dispatch(getPackageSendItemsData(token)) },
        deletePackage: (data, token) => { dispatch(deletePackage(data, token)) },
        recvPackage: (data, token, cb) => { dispatch(recvPackage(data, token, cb)) },
        getPackageRecvItemsData: (token) => { dispatch(getPackageRecvItemsData(token)) },
        getDeliveryWarehouses: props => { dispatch(getDeliveryWarehouses(props)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Packaging)
