import { toast } from 'react-toastify';
import { getResolveMethodData } from './basic_data_action';
var axios = require('axios');

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addResolveMethod = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/resolvemethod/addResolveMethod', { data })
            .then(result => {
                toast.success('Successfully Added resolve method...!')
                dispatch(getResolveMethodData({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
 export const updateResolveMethod = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/resolvemethod/updateResolveMethod', { data })
            .then(result => {
                toast.success('Successfully Updated resolve method...!')
                dispatch(getResolveMethodData({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
 export const deleteResolveMethod = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/resolvemethod/deleteResolveMethod', { data })
            .then(result => {
                toast.success('Successfully Deleted resolve method...!')
                dispatch(getResolveMethodData({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}