/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const workingCalenderDataAsync = data => {
    return { type: 'FETCH_WORKING_CALENDER_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
export const getWorkingCalenderData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/workingcalender/getWorkingCalenderData')
            .then(result => {
                var { data, regionData } = result.data
                var arr = data?.map(obj => {
                    var regionArr = obj?.region.map(ele => {
                        let val = regionData.filter(regionObj => regionObj._id === ele.region)
                        return ({ ...ele, name: val[0].name })
                    })
                    return ({ ...obj, region: regionArr })
                })
                dispatch(workingCalenderDataAsync(arr))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addWorkingCalender = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/workingcalender/addWorkingCalender', { data })
            .then(result => {
                dispatch(getWorkingCalenderData(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateWorkingCalender = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/workingcalender/updateWorkingCalender', { data })
            .then(result => {
                dispatch(getWorkingCalenderData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteWorkingCalender = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/workingcalender/deleteWorkingCalender', { data })
            .then(result => {
                toast.success('Successfully Deleted Working Calender!')
                dispatch(getWorkingCalenderData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addRegionToDeliveryCalender = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/workingcalender/addRegionToDeliveryCalender', { data })
            .then(result => {
                toast.success('Successfully Added Region Delivery Calender!')
                dispatch(getWorkingCalenderData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const updateRegionDeliveryCalender = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/workingcalender/updateRegionDeliveryCalender', { data })
            .then(result => {
                toast.success('Successfully Updated Region Delivery Calender!')
                dispatch(getWorkingCalenderData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const deleteRegionDeliveryCalender = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/workingcalender/deleteRegionDeliveryCalender', { data })
            .then(result => {
                toast.success('Successfully Deleted Region Delivery Calender!')
                dispatch(getWorkingCalenderData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}