// import _ from 'lodash';

const initialState = {
    data: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerMeasurement = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_MESUREMENT':
            var { data } = action;
            newState.data = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerMeasurement;