/**
 * @file This file defines to basic data action dispatch
 * @author Bhanuka Chathuranga
 */
// import $ from 'jquery';
import { toast } from 'react-toastify';
import { isLoadingAsync } from '../login_action';
var axios = require('axios');

export const currencyAsync = data => {
    return { type: 'FETCH_CURRENCY', data }
}

export const paymentMethodsAsync = data => {
    return { type: 'FETCH_PAYMENT_METHODS', data }
}

export const countryAsync = data => {
    return { type: 'FETCH_COUNTRY', data }
}

export const paymentConditonAsync = data => {
    return { type: 'FETCH_PAYMENT_CONDITION', data }
}

export const deliveryConditonAsync = data => {
    return { type: 'FETCH_DELIVERY_CONDITION', data }
}

export const productAsync = data => {
    return { type: 'FETCH_PRODUCT', data }
}

export const itemAsync = data => {
    return { type: 'FETCH_ITEM', data }
}

export const addressAsync = data => {
    return { type: 'FETCH_ADDRESS', data }
}

export const usersAsync = data => {
    return { type: 'FETCH_USERS', data }
}

export const shelfLivesAsync = data => {
    return { type: 'FETCH_SHELF_LIVES', data }
}

export const deliveryTypesAsync = data => {
    return { type: 'FETCH_DELIVERY_TYPES', data }
}

export const suppliersAsync = data => {
    return { type: 'FETCH_SUPPLIERS', data }
}

export const categoriesAsync = data => {
    return { type: 'FETCH_CATEGORIES', data }
}

export const cityAsync = data => {
    return { type: 'FETCH_CITY', data }
}

export const regionAsync = data => {
    return { type: 'FETCH_REGION', data }
}

export const brandAsync = data => {
    return { type: 'FETCH_BRAND', data }
}

export const resolveMethosdAsync = data => {
    return { type: 'FETCH_RESOLVE_METHODS', data }
}


/**
 * get currency details 
 * 
 * @param {string} token 
 */
export const getCurrencyData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getCurrency', { headers: { authorization: token } })
            .then(result => {
                dispatch(currencyAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get payment method details 
 * 
 * @param {string} token 
 */
export const getPaymentMethodData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getPaymentMethod', { headers: { authorization: token } })
            .then(result => {
                dispatch(paymentMethodsAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get country details 
 * 
 * @param {string} token 
 */
export const getCountry = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getCountry', { headers: { authorization: token } })
            .then(result => {
                dispatch(countryAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get payment condition details 
 * 
 * @param {string} token 
 */
export const getPaymentCondition = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getPaymentCondition', { headers: { authorization: token } })
            .then(result => {
                dispatch(paymentConditonAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get delivery condition details 
 * 
 * @param {string} token 
 */
export const getDeliveryCondition = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getDeliveryCondition', { headers: { authorization: token } })
            .then(result => {
                dispatch(deliveryConditonAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get product details 
 * 
 * @param {string} token 
 */
export const getProductData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/stock/getProducts', { headers: { authorization: token } })
            .then(result => {
                dispatch(productAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get item details 
 * 
 * @param {string} token 
 */
export const getItemData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getItem', { headers: { authorization: token } })
            .then(result => {
                dispatch(itemAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get addresses details 
 * 
 * @param {string} token 
 */
export const getAddressData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getAddress', { headers: { authorization: token } })
            .then(result => {
                dispatch(addressAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get user details 
 * 
 * @param {string} token 
 */
export const getUserData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getUsers', { headers: { authorization: token } })
            .then(result => {
                dispatch(usersAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getShelfLives = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getShelfLives')
            .then(result => {
                dispatch(shelfLivesAsync(result.data))
            }).catch(err => {
                console.error(err)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getDeliveryTypes = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getDeliveryTypes')
            .then(result => {
                dispatch(deliveryTypesAsync(result.data))
            }).catch(err => {
                console.error(err)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getSuppliers = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getSuppliers')
            .then(result => {
                dispatch(suppliersAsync(result.data))
            }).catch(err => {
                console.error(err)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getCategories = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getCategories')
            .then(result => {
                dispatch(categoriesAsync(result.data))
            }).catch(err => {
                console.error(err)
            })
    }
}

/**
 * get cities details 
 * 
 * @param {string} token 
 */
export const getCityData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getCity', { headers: { authorization: token } })
            .then(result => {
                dispatch(cityAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get cities details 
 * 
 * @param {string} token 
 */
export const getRegionData = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getRegion', { headers: { authorization: token } })
            .then(result => {
                dispatch(regionAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get brand details 
 * 
 * @param {string} token 
 */
export const getBrandData = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getBrand', { headers: { authorization: token } })
            .then(result => {
                dispatch(brandAsync(result.data))
                dispatch(isLoadingAsync(false))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get resolve methods 
 * 
 * @param {string} token 
 */
export const getResolveMethodData = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/basic_data/getResolveMethods', { headers: { authorization: token } })
            .then(result => {
                //console.log(result);
                dispatch(resolveMethosdAsync(result.data))
                dispatch(isLoadingAsync(false))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}
