/**
 * @file This file defined for create node element
 * @author Bhanuka Chathuranga 
 */
import React, { Component } from 'react'
import { DragSource } from 'react-dnd';
import { externalNodeType } from '../utils/ConstData';

const externalNodeSpec = {
    // This needs to return an object with a property `node` in it.
    // Object rest spread is recommended to avoid side effects of
    // referencing the same object in different trees.
    beginDrag: componentProps => ({ node: { ...componentProps.node } }),
};

class externalNodeBaseComponent extends Component {
    render() {
        const { connectDragSource, node } = this.props;

        return connectDragSource(
            <div key={node._id}
                style={{
                    display: 'inline-block',
                    width: '100%',
                    padding: '3px 5px',
                    background: 'white',
                    margin: '0px',
                    color: 'black',
                }}
            >
                {node.title}
            </div>,
            { dropEffect: 'copy' }
        );
    }
}

const externalNodeCollect = (connect /* , monitor */) => ({
    connectDragSource: connect.dragSource(),
    // Add props via react-dnd APIs to enable more visual
    // customization of your component
    // isDragging: monitor.isDragging(),
    // didDrop: monitor.didDrop(),
});

const ExternalNodeComponent = DragSource(
    externalNodeType,
    externalNodeSpec,
    externalNodeCollect
)(externalNodeBaseComponent);

export default ExternalNodeComponent;