/**
 * @file This file defined for TV Dashboard controller
 * @author Umesh Ranthilina
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDeliveryDashboard } from '../../../actions/Delivery/delivery_action';

export class TV_Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            riders: [],
            tableData0: [],
            interval_Var: {},
            warehouseId: '',
            repeat_Request: 0,
            loop: 0,
        }
    }

    componentDidMount() {
        const abc = () => {
            const data = {
                warehouseId: this.state.warehouseId,
                repeat_Request: this.state.repeat_Request === 3 ? 0 : this.state.repeat_Request,
                loop: this.state.loop === 3 ? 0 : this.state.loop
            };

            this.props.getDeliveryDashboard(data, this.props)
        }
        this.state.interval_Var = setInterval(abc, 30 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.state.interval_Var);

    }

    getColumn = (data) => {
        let columnData = [];
        for (let index = 0; index < this.state.riders.length; index++) {
            var tvShow = [<td style={{
                height: '3vh', width: '13%', textTransform: 'uppercase', color: '#ff4d00', fontWeight: 'bold', fontSize: 24, fontWeight: 'bold',
                textAlign: 'left', borderRight: '5px solid black', borderBottom: '2px solid black',
                paddingTop: 0, paddingBottom: 0, paddingRight: '2%'
            }}> {data[index] !== undefined ?
                <div>
                    {data[index].orderStatus === "Delivered" ?
                        <>
                            <span style={{
                                color: 'white',
                                fontWeight: 'bold',
                                lineHeight: 1.6,
                                width: '100%',
                                display: 'block',
                                border: '1px solid',
                                margin: '5px',
                                padding: '5px 10px 5px 40px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: '10px center',
                                backgroundColor: 'green'
                            }}>
                                {data[index].orders !== undefined ? data[index].orders : ''}
                            </span>

                            <span style={{
                                fontSize: 20,
                                height: '5vh',
                                borderLeft: '10px solid green',
                                color: 'black',
                                margin: '10px',
                                marginLeft: '10px',
                                fontWeight: 'bold',
                                alignContent: 'right',
                                alignItems: 'right',
                                width: '50%'
                            }}>
                            </span>
                            <span style={{
                                fontSize: 20,
                                height: '5vh',
                                color: 'black',
                                margin: '10px',
                                marginLeft: '10px',
                                fontWeight: 'bold',
                                alignContent: 'right',
                                alignItems: 'right',
                                width: '50%'
                            }}>
                                {data[index].city !== undefined ? data[index].city : ''}
                                <span style={{
                                    color: 'black',
                                    fontWeight: 'bold',
                                    float: 'right',
                                    margin: '5px',
                                }}>
                                    {data[index].time !== undefined ? data[index].time : ''}
                                </span>
                            </span>
                        </> : ''}
                    <>
                        {data[index].orderStatus === "onDelivered" ?
                            <>
                                <span style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    lineHeight: 1.6,
                                    width: '100%',
                                    display: 'block',
                                    border: '1px solid',
                                    margin: '5px',
                                    padding: '5px 10px 5px 40px',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: '10px center',
                                    backgroundColor: 'red'
                                }}>
                                    {data[index].orders !== undefined ? data[index].orders : ''}
                                </span>
                                <span style={{
                                    fontSize: 20,
                                    height: '5vh',
                                    borderLeft: '10px solid red',
                                    color: 'black',
                                    margin: '10px',
                                    marginLeft: '10px',
                                    fontWeight: 'bold',
                                    alignContent: 'right',
                                    alignItems: 'right',
                                    width: '50%'
                                }}>
                                </span>
                                <span style={{
                                    fontSize: 20,
                                    height: '5vh',
                                    color: 'black',
                                    margin: '10px',
                                    marginLeft: '10px',
                                    fontWeight: 'bold',
                                    alignContent: 'right',
                                    alignItems: 'right',
                                    width: '50%'
                                }}>
                                    {data[index].city !== undefined ? data[index].city : ''}
                                    <span style={{
                                        color: 'black',
                                        fontWeight: 'bold',
                                        float: 'right',
                                        margin: '5px',
                                    }}>
                                        {data[index].time !== undefined ? data[index].time : ''}
                                    </span>
                                </span>
                            </> : ''}
                    </>
                    <>
                        {data[index].orderStatus === "toDelivered" ?
                            <>
                                <span style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    lineHeight: 1.6,
                                    width: '100%',
                                    display: 'block',
                                    border: '1px solid',
                                    margin: '5px',
                                    padding: '5px 10px 5px 40px',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: '10px center',
                                    backgroundColor: '#FFC000'
                                }}>
                                    {data[index].orders !== undefined ? data[index].orders : ''}
                                </span>
                                <span style={{
                                    fontSize: 20,
                                    height: '5vh',
                                    borderLeft: '10px solid #FFC000',
                                    color: 'black',
                                    margin: '10px',
                                    marginLeft: '10px',
                                    fontWeight: 'bold',
                                    alignContent: 'right',
                                    alignItems: 'right',
                                    width: '50%'
                                }}>
                                </span>
                                <span style={{
                                    fontSize: 20,
                                    height: '5vh',
                                    color: 'black',
                                    margin: '10px',
                                    marginLeft: '10px',
                                    fontWeight: 'bold',
                                    alignContent: 'right',
                                    alignItems: 'right',
                                    width: '50%'
                                }}>
                                    {data[index].city !== undefined ? data[index].city : ''}
                                </span>
                            </> : ''}
                    </>
                </div>

                : ''}
            </td >]
            columnData.push(tvShow)
        }
        return columnData;
    }

    
    getRow = () => {
        let arr = this.state.tableData0.map((v) => {
            return (
                <tr>
                    {this.getColumn(v)}
                </tr>
            )
        })
        return arr;
    }

    componentDidUpdate(prevProps) {
        const { deliveryDashboardData } = this.props;
        if (deliveryDashboardData !== prevProps.deliveryDashboardData) {
            this.setState({
                riders: deliveryDashboardData.riders,
                tableData0: deliveryDashboardData.tableData,
                warehouseId: deliveryDashboardData.warehouseId,
                loop: deliveryDashboardData.loop,
                repeat_Request: deliveryDashboardData.repeat_Request,
            })
        }
    }

    render() {
        return (
            <div style={{ width: '100%', height: '300%', overflow: 'hidden', overflowX: 'hidden', overflowY: 'hidden' }}>
                <div style={{ width: '100%', height: '300%' }}>
                    <table style={{ width: '100%', backgroundColor: 'white', }}>
                        <thead style={{ width: '100%', backgroundColor: '#4d4d52', }}>
                            <tr key={0} style={{ width: '100%', backgroundColor: '#d7dade', maxHeight: window.innerHeight / 20, maxWidth: window.innerWidth }}>
                                {this.state.riders.map((v) =>
                                    <th style={{ textTransform: 'uppercase', color: '#white', fontWeight: 'bold', fontSize: '2.0vw', maxHeight: window.innerHeight / 20, padding: 0, textAlign: 'center', borderRight: '5px solid white', width: '5%', }}> <span onClick={() => {
                                        if (document.fullscreenElement) {
                                            document.exitFullscreen();
                                        } else {
                                        }
                                        this.props.history.push('/delivery/delivery dashboard');
                                    }}>{v.name}</span></th>
                                )}
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: 'white', }}>
                            {this.getRow()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userId: state.rLogin.id,
        deliveryDashboardData: state.rDeliveryBoys.deliveryDashboardData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDeliveryDashboard: (data, props) => { dispatch(getDeliveryDashboard(data, props)) },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TV_Dashboard)
