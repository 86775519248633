/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const packageDataAsync = data => {
    return { type: 'FETCH_PACKAGE_DATA', data }
}

export const packageSendItemsDataAsync = data => {
    return { type: 'FETCH_PACKAGE_SEND_ITEMS_DATA', data }
}

export const packageRecvItemsDataAsync = data => {
    return { type: 'FETCH_PACKAGE_RECV_ITEMS_DATA', data }
}

/**
 * 
 * @param {*} token 
 */

export const createPackage = (data, token, cb) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/packaging/createPackage', { data })
            .then(result => {
                if (result.data.message === 'Package Created.') {
                    window.$('#createPackage').modal('hide')
                    toast.success(result.data.message)
                    cb()
                    dispatch(getPackageData(token))
                    dispatch(getPackageSendItemsData(token))
                } else {
                    toast.warning(result.data.message)
                }

            }).catch(err => {
                toast.error(err)
            })
    }
}

export const getPackageData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/packaging/getPackageData')
            .then(result => {
                dispatch(packageDataAsync(result.data))
            }).catch(err => {
                toast.error(err)
            })
    }
}

export const getPackageSendItemsData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/packaging/getPackageSendItemsData')
            .then(result => {
                dispatch(packageSendItemsDataAsync(result.data))
            }).catch(err => {
                toast.error(err)
            })
    }
}

export const getPackageRecvItemsData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/packaging/getPackageRecvItemsData')
            .then(result => {
                dispatch(packageRecvItemsDataAsync(result.data))
            }).catch(err => {
                toast.error(err)
            })
    }
}

export const deletePackage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/packaging/deletePackage', { data })
            .then(result => {
                toast.success('Package Deleted.')
                dispatch(getPackageData(token))
                dispatch(getPackageSendItemsData(token))
            }).catch(err => {
                toast.error(err)
            })
    }
}

export const recvPackage = (data, token, cb) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/packaging/recvPackage', { data })
            .then(result => {
                axios.defaults.headers.common['authorization'] = token;
                axios.post('/packaging/sendPackageToHistory', { data })
                    .then(result => {
                        cb()
                        window.$('#recvItem').modal('hide')
                        toast.success('Package Deleted.')
                        dispatch(getPackageData(token))
                        dispatch(getPackageSendItemsData(token))
                        dispatch(getPackageRecvItemsData(token))
                    }).catch(err => {
                        toast.error(err)
                    })

            }).catch(err => {
                toast.error(err)
            })
    }
}