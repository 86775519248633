import React, { useState, useEffect, PureComponent } from 'react'
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, AreaChart, ReferenceLine, Area, Line, ComposedChart } from 'recharts';
import { connect } from 'react-redux';
import { ExportData } from '../../utils';

function Sales(props) {

    const [selectedType, setSelectedType] = useState("ComposedChart");
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [composedChartData, setComposedChartData] = useState([]);
    const [areaChartData, setAreaChartData] = useState([]);
    const [regionColors, setRegionColors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [exportData, setExportData] = useState([])
    const years = []

    for (let i = new Date().getFullYear(); i >= 2019; i--) {
        years.push(i)
    }

    const getChartData = () => {
        axios.defaults.headers.common['authorization'] = props.token;

        const data = {
            'selectedYear': Number(selectedYear)
        }

        if (selectedType === "ComposedChart") {
            axios.post('dashboard/getSales', data)
                .then(result => {
                    setComposedChartData(result.data.salesAndCosts);
                }).catch(err => {
                    console.error(err)
                })
        }
        else if (selectedType === "AreaChart") {
            axios.post('dashboard/getSalesRegionWise', data)
                .then(result => {
                    setAreaChartData(result.data.salesRegionwise);
                    setRegionColors(result.data.regionColors);
                }).catch(err => {
                    console.error(err)
                })
        }
    }

    useEffect(() => {
        getChartData();
    }, [selectedType, selectedYear])

    useEffect(() => {
        exportSalesData();
    }, [selectedYear])

    const exportSalesData = () => {
        setIsLoading(true)
        axios.defaults.headers.common['authorization'] = props.token;

        let data = {
            'selectedYear': selectedYear
        }

        axios.post('dashboard/getExportSalesData', data)
            .then(result => {
                setIsLoading(false)
                setExportData(result.data)
            }).catch(err => {
                console.error(err)
            })
    }

    const exportSData = () => {
        ExportData(`Monthly Data ${selectedYear}`, `Monthly Data ${selectedYear}`, selectedYear, exportData)
    }

    // Format number values shown in Y axis of charts
    const DataFormater = (number) => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    }

    // Add percentage sign
    const PercentageFormater = (number) => {
        return number.toString() + "%"
    }


    return (
        <>
            <div className="card shadow my-4 mx-6 rounded-5" style={{ justifyContent: 'space-evenly' }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-2">
                            <h6 className="m-0 font-weight-bold text-primary">Sales</h6>
                        </div>
                        <div className="col-md-10">
                            <div className='row'>
                                <div className='col-lg-2 col-md-2 mr-2'>
                                    <button
                                        className='btn btn-primary'
                                        style={{ height: '30px', paddingTop: '2px' }}
                                        disabled={isLoading}
                                        onClick={e => {
                                            exportSData()
                                        }}
                                    >{isLoading ? 'Loading...': 'Export'}</button>
                                </div>
                                <div className='col-lg-6 col-md-6 ml-3'>
                                    <div className="input-group-prepend" style={{ width: '130%' }}>
                                        <select
                                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                                            className="form-control form-control-sm mx-sm-3"
                                            value={selectedType}
                                            onChange={(e) => setSelectedType(e.target.value)}>
                                            <option value="ComposedChart">Composed Chart</option>
                                            <option value="AreaChart">Area Chart</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-3'>
                                    <select
                                        className="form-control form-control-sm mx-sm-3"
                                        style={{ fontSize: '14px', fontWeight: 'bold' }}
                                        value={selectedYear}
                                        onChange={e => setSelectedYear(e.target.value)}>
                                        {years.map((value) => (
                                            <option value={value} key={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body" >
                    <div style={{ padding: "0px" }}>
                        <div style={{ textAlign: "center", display: "block", justifyContent: "space-around" }}>

                            {
                                selectedType === "ComposedChart" &&
                                <ResponsiveContainer width='100%' height={275}>
                                    <ComposedChart
                                        data={composedChartData}
                                        margin={{
                                            top: 10,
                                            right: 10,
                                            left: 10,
                                            bottom: 10,
                                        }}
                                    >
                                        <defs>
                                            <linearGradient id="colorPv4" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="10%" stopColor="#0088FE" stopOpacity={1} />
                                                <stop offset="100%" stopColor="#0088FE" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" tick={{ fill: '#000000' }} />
                                        <YAxis yAxisId="left" style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                                        <YAxis yAxisId="right" orientation="right" style={{ fontSize: '14px' }} width={50} tick={{ fill: '#449759' }} tickFormatter={PercentageFormater} />
                                        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left', backgroundColor: '#fff' }} />
                                        <Legend style={{ textShadow: '#a09dcf' }} />
                                        <Bar yAxisId="left" dataKey="sales" barSize={20} fill="url(#colorPv4)" />
                                        <Line yAxisId="left" type="monotone" dataKey="costs" stroke="#f51040" strokeWidth={4} />
                                        <Line yAxisId="left" type="monotone" dataKey="margin" stroke="#f5b90c" strokeWidth={4} />
                                        <Line yAxisId="right" type="monotone" dataKey="marginPercentage" stroke="#10bd54" strokeWidth={4} />

                                    </ComposedChart>
                                </ResponsiveContainer>
                            }

                            {
                                selectedType === "AreaChart" &&

                                <ResponsiveContainer height={260}>
                                    <AreaChart
                                        data={areaChartData}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 10,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" tick={{ fill: '#000000' }} />
                                        <YAxis style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} tickFormatter={DataFormater} />
                                        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left' }} />

                                        {regionColors.map((regionColor) => (
                                            <Area type="monotone" dataKey={regionColor.region} stackId="1" stroke={regionColor.color} fill={regionColor.color}
                                                strokeWidth={2} fillOpacity={2} activeDot={{ r: 4 }} />
                                        ))}
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        </div>
                        <center><span style={{ fontSize: '14px', color: '#191350' }} className="m-0 font-weight-bold">Months of Year - {selectedYear}</span></center>
                    </div>
                </div>
            </div>
        </>
    )

}

const mapStateToProps = state => ({
    token: state.rLogin.token
})


export default connect(mapStateToProps)(Sales);