/**
 * @type {Object}
 * @property {array}  data  Item data
 */
const initialState = {
    orderData: [],
    orderItemsData: [],
    accountData: [],
    stockExpireData: [],
    posRunningData: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerPOS = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_POS_ORDER_DATA':
            var { data } = action;
            newState.orderData = data;
            break;

        case 'FETCH_POS_ORDER_ITEMs':
            var { data } = action;
            newState.orderItemsData = data;
            break;

        case 'FETCH_ACCOUNT_DATA':
            var { data } = action;
            newState.accountData = data;
            break;

        case 'FETCH_ALL_EXPIRE_ITEMS_DATA':
            var { data } = action;
            newState.stockExpireData = data;
            break;

        case 'FETCH_POS_RUNNING_DATA':
            var { data } = action;
            newState.posRunningData = data;
            break;

        default: 
            break;
    }
    return newState;
}

export default reducerPOS;