import React from "react";

const editModes = {
  view: props => (
    <React.Fragment>
      <button className="btn btn-link btn-sm" disabled={props.updateDisable} onClick={props.onEdit}>
        <i className="fas fa-edit"></i>
      </button>

      <button className="btn btn-link btn-sm" disabled={props.deleteDisable} onClick={props.onDelete}>
        <i className="fas fa-trash"></i>
      </button>
    </React.Fragment>
  ),
  edit: props => (
    <React.Fragment>
      <button className="btn btn-success btn-sm">
        <i className="fas fa-save"></i>
      </button>

      <button className="btn btn-danger btn-sm" onClick={props.onCancel}>
        <i className="fas fa-undo"></i>
      </button>
    </React.Fragment>
  )
};

export default function ActionsCell(props) {
  const {
    mode,
    updPerm = false,
    delPerm = false,
    actions: { onEdit, onDelete, onCancel }
  } = props.columnProps.rest;
  const Buttons = editModes[mode];
  return <Buttons
    updateDisable={updPerm}
    deleteDisable={delPerm}
    onEdit={() => onEdit(props.index)}
    onCancel={onCancel}
    onDelete={() => { if (window.confirm('Are you sure you wish to delete this item?')) onDelete(props.index) }} />;
}
