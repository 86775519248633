/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const stockRequestDataAsync = data => {
    return { type: 'FETCH_STOCK_REQUEST_DATA', data }
}

export const warehouseDataAsync = data => {
    return { type: 'FETCH_WAREHOUSE_DATA', data }
}

export const stockRequestItemDataAsync = data => {
    return { type: 'FETCH_STOCK_REQUEST_ITEM_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
export const getStockRequestData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/move_stock/getStockRequestData')
            .then(result => {
                dispatch(stockRequestDataAsync(result.data.reverse()))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const getWarehouseData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/move_stock/getWarehouseData')
            .then(result => {
                dispatch(warehouseDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addMoveStockRequest = (token, data) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/move_stock/addMoveStockRequest', { data })
            .then(result => {
                window.$('#addStockRequest').modal('hide');
                toast.success('Stock Request Added!')
                dispatch(getStockRequestData(token))
                dispatch(getStockRequestItemData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const getStockRequestItemData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/move_stock/getStockRequestItemData')
            .then(result => {
                dispatch(stockRequestItemDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const updateMoveStockRequest = (token, data) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/move_stock/updateMoveStockRequest', { data })
            .then(result => {
                toast.success('Stock Request Updated!')
                dispatch(getStockRequestData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const deleteMoveStockRequest = (token, data) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/move_stock/deleteMoveStockRequest', { data })
            .then(result => {
                toast.success('Stock Request Deleted!')
                dispatch(getStockRequestData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const updateMoveStockRequestItem = (token, data) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/move_stock/updateMoveStockRequestItem', { data })
            .then(result => {
                toast.success('Stock Item Updated!')
                dispatch(getStockRequestItemData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const deleteMoveStockRequestItem = (token, data) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/move_stock/deleteMoveStockRequestItem', { data })
            .then(result => {
                toast.success('Stock Item Deleted!')
                dispatch(getStockRequestItemData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const approveStockRequest = (token, data) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/move_stock/approveStockRequest', { data })
            .then(result => {
                toast.success('Stock Request Approved!')
                dispatch(getStockRequestData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}