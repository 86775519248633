/**
 * @file This file defines to supplier evolution action dispatch
 * @author Bhanuka Chathuranga
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const supplierEvolutionAsync = data => {
    return { type: 'FETCH_SUPPLIER_EVOLUTION', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * get Supplier evolution details 
 * 
 * @param {string} token 
 */
export const getSupplierEvolutionData = ({ dateRange, token }) => {
    // axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        dispatch(supplierEvolutionAsync([]))
        axios.get('/purchasing/getSupplierEvolution', { headers: { authorization: token }, params: { dateRange } })
            .then(result => {
                console.table(result.data)
                dispatch(isLoadingAsync(false))
                dispatch(supplierEvolutionAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To add Supplier evolution 
 * 
 * @param {Object} addData To add data
 * @param {string} token 
 */
export const addSupplierEvolutionData = (addData, { id, dateRange, token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        const imagePath = addData.imagepath;
        delete addData.imagepath;
        console.log(imagePath);
        // let formData = new FormData()
        // formData.set('addData', imagePath)
        axios.post('/purchasing/addSupplierEvolution', { addData })
            .then(result => {
                console.log(result);
                console.table('test', result.data.insertedId)
                var _id = result.data.insertedId;

                console.log('image Path', imagePath);
                if (imagePath && imagePath.length > 0) {

                    Array.from(imagePath).forEach(imagePath => {
                        const config = {
                            headers: {
                                "authorization": token,
                                "content-type": "multipart/form-data",
                            },
                        };

                        var data = new FormData();
                        data.append("customerComplaintImage", imagePath, imagePath.name);

                        axios.post('/purchasing/uploadSupplierEvolutionImage', data, config)
                            .then(result => {
                                console.log(result);
                                const uploadedFile = result.data.uploadedFile;

                                const image = [
                                    {
                                        'url': '/images/supplierEvolution/' + uploadedFile.filename,
                                        'size': uploadedFile.size,
                                        'path': 'supplierEvolution',
                                        'filename': uploadedFile.filename,
                                        'mimetype': uploadedFile.mimetype
                                    }
                                ];

                                console.log(image);

                                const data = {
                                    _id: _id,
                                    imagePath: image
                                }

                                dispatch(updateSupplierEvolutionWithImages(data, token));
                                dispatch(getSupplierEvolutionData({ dateRange, token }))
                            }).catch(err => {
                                console.log(err);
                                toast.error(err);
                            })
                    })
                } else {
                    dispatch(getSupplierEvolutionData({ dateRange, token }))
                }
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {Object} data image data
 * @param {string} token 
 */
export const updateSupplierEvolutionWithImages = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/updateSupplierEvolutionWithImages', { data })
            .then(result => {
                toast.success('Supplier evolution added successfully...!')
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * To update Supplier evolution details 
 * 
 * @param {string} token 
 */
export const updateSupplierEvolutionData = ({ supplierevolution }, { dateRange, token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/purchasing/updateSupplierEvolution', { supplierevolution })
            .then(result => {
                // console.table(result.data)
                dispatch(getSupplierEvolutionData({ dateRange, token }))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To delete Supplier evolution details 
 * 
 * @param {string} token 
 */
export const deleteSupplierEvolutionData = ({ supplierevolution }, { dateRange, token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/purchasing/deleteSupplierEvolution', { supplierevolution })
            .then(result => {
                // console.table(result.data)
                dispatch(getSupplierEvolutionData({ dateRange, token }))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}