import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toast } from "react-toastify";
import { getRegionData } from '../../../actions/Basic_data/basic_data_action';
import { addWorkingCalender } from '../../../actions/Basic_data/working_calender_actions';
import ErrorBoundary from '../../components/ErrorBoundary';

import { Permission, TYPE_DATA as typeData, WEEKDAYDATA as weekDayData, TIMEDATA as timeDropDownData } from '../../utils';

const getWeekDay = dayNum => {
    switch (dayNum) {
        case 1:
            return 'Monday';
            break;
        case 2:
            return 'Tuesday';
            break;
        case 3:
            return 'Wednesday';
            break;
        case 4:
            return 'Thursday';
            break;
        case 5:
            return 'Friday';
            break;
        case 6:
            return 'Saturday';
            break;
        case 0:
            return 'Sunday';
            break;
        default:
            break;
    }
}

const getDayType = dayNum => {
    if (dayNum === 0 || dayNum === 6) {
        return 'Holiday';
    } else {
        return 'Working Day';
    }
}

const addOneDayInDate = date => {
    var date = new Date(date);
    date.setDate(date.getDate() + 1);
    return date;
}

const setStartDateWithType = (date, days, { setDays }) => {
    var d = new Date(date);
    var dayNum = d.getDay();
    var w2Day = addOneDayInDate(date);
    var w3Day = addOneDayInDate(w2Day);
    var w4Day = addOneDayInDate(w3Day);
    var w5Day = addOneDayInDate(w4Day);
    var w6Day = addOneDayInDate(w5Day);
    var w7Day = addOneDayInDate(w6Day);

    setDays({
        ...days, startDate: date,
        w1Day: date, w1WeekDay: getWeekDay(dayNum), w1Type: getDayType(dayNum),
        w2Day: w2Day, w2WeekDay: getWeekDay(new Date(w2Day).getDay()), w2Type: getDayType(new Date(w2Day).getDay()),
        w3Day: w3Day, w3WeekDay: getWeekDay(new Date(w3Day).getDay()), w3Type: getDayType(new Date(w3Day).getDay()),
        w4Day: w4Day, w4WeekDay: getWeekDay(new Date(w4Day).getDay()), w4Type: getDayType(new Date(w4Day).getDay()),
        w5Day: w5Day, w5WeekDay: getWeekDay(new Date(w5Day).getDay()), w5Type: getDayType(new Date(w5Day).getDay()),
        w6Day: w6Day, w6WeekDay: getWeekDay(new Date(w6Day).getDay()), w6Type: getDayType(new Date(w6Day).getDay()),
        w7Day: w7Day, w7WeekDay: getWeekDay(new Date(w7Day).getDay()), w7Type: getDayType(new Date(w7Day).getDay())
    })
}

const setEndDateWithType = (date, days, { setDays }) => {
    setDays({ ...days, endDate: date })
}

const calculateRangeHours = (days, setDays) => {
    var w1Hours = calculateTimeDifference(days.w1StartTime, days.w1EndTime);
    var w2Hours = calculateTimeDifference(days.w2StartTime, days.w2EndTime);
    var w3Hours = calculateTimeDifference(days.w3StartTime, days.w3EndTime);
    var w4Hours = calculateTimeDifference(days.w4StartTime, days.w4EndTime);
    var w5Hours = calculateTimeDifference(days.w5StartTime, days.w5EndTime);
    var w6Hours = calculateTimeDifference(days.w6StartTime, days.w6EndTime);
    var w7Hours = calculateTimeDifference(days.w7StartTime, days.w7EndTime);

    setDays({
        ...days,
        w1Hours: w1Hours,
        w2Hours: w2Hours,
        w3Hours: w3Hours,
        w4Hours: w4Hours,
        w5Hours: w5Hours,
        w6Hours: w6Hours,
        w7Hours: w7Hours,
    })

    setSaturdayType(w1Hours, 1, days, setDays);
    setSaturdayType(w2Hours, 2, days, setDays);
    setSaturdayType(w3Hours, 3, days, setDays);
    setSaturdayType(w4Hours, 4, days, setDays);
    setSaturdayType(w5Hours, 5, days, setDays);
    setSaturdayType(w6Hours, 6, days, setDays);
    setSaturdayType(w7Hours, 7, days, setDays);

    // this.setState({ disabledButton: false });

}

const calculateTimeDifference = (startTime, endTime) => {
    var startTimeArr = startTime.split(":");
    var endTimeArr = endTime.split(":");

    var hours = endTimeArr[0] - startTimeArr[0];
    var minutes = endTimeArr[1] - startTimeArr[1];

    if (minutes === -30) {
        minutes = 0.5;
        hours = hours - 1;
    }

    if (minutes === 30) {
        minutes = 0.5;
    }
    return Number(hours) + Number(minutes);
}

const setStartTimeRange = (time, days, { setDays }) => {
    if (days.w1WeekDay !== '') {
        if (days.w1WeekDay !== 'Saturday' && days.w1WeekDay !== 'Sunday') {

            setDays(pre => ({ ...pre, w1StartTime: time }))
        }

        if (days.w2WeekDay !== 'Saturday' && days.w2WeekDay !== 'Sunday') {

            setDays(pre => ({ ...pre, w2StartTime: time }))

        }

        if (days.w3WeekDay !== 'Saturday' && days.w3WeekDay !== 'Sunday') {

            setDays(pre => ({ ...pre, w3StartTime: time }))

        }

        if (days.w4WeekDay !== 'Saturday' && days.w4WeekDay !== 'Sunday') {

            setDays(pre => ({ ...pre, w4StartTime: time }))
        }

        if (days.w5WeekDay !== 'Saturday' && days.w5WeekDay !== 'Sunday') {

            setDays(pre => ({ ...pre, w5StartTime: time }))

        }

        if (days.w6WeekDay !== 'Saturday' && days.w6WeekDay !== 'Sunday') {

            setDays(pre => ({ ...pre, w6StartTime: time }))

        }

        if (days.w7WeekDay !== 'Saturday' && days.w7WeekDay !== 'Sunday') {

            setDays(pre => ({ ...pre, w7StartTime: time }))

        }
    } else {
        toast.info('Please fill start date and end date first!');
    }
}

const setEndTimeRange = (time, days, { setDays }) => {
    if (days.w1WeekDay !== '') {
        if (days.w1WeekDay !== 'Saturday' && days.w1WeekDay !== 'Sunday') {

            // setDays({ ...days, w6StartTime: time })
            setDays(pre => ({ ...pre, w1EndTime: time }))
        }

        if (days.w2WeekDay !== 'Saturday' && days.w2WeekDay !== 'Sunday') {

            // setDays({ ...days, w2EndTime: time })
            setDays(pre => ({ ...pre, w2EndTime: time }))

        }

        if (days.w3WeekDay !== 'Saturday' && days.w3WeekDay !== 'Sunday') {

            // setDays({ ...days, w3EndTime: time })
            setDays(pre => ({ ...pre, w3EndTime: time }))

        }

        if (days.w4WeekDay !== 'Saturday' && days.w4WeekDay !== 'Sunday') {

            // setDays({ ...days, w4EndTime: time })
            setDays(pre => ({ ...pre, w4EndTime: time }))

        }

        if (days.w5WeekDay !== 'Saturday' && days.w5WeekDay !== 'Sunday') {

            // setDays({ ...days, w5EndTime: time })
            setDays(pre => ({ ...pre, w5EndTime: time }))

        }

        if (days.w6WeekDay !== 'Saturday' && days.w6WeekDay !== 'Sunday') {

            // setDays({ ...days, w6StartTime: time })
            setDays(pre => ({ ...pre, w6EndTime: time }))

        }

        if (days.w7WeekDay !== 'Saturday' && days.w7WeekDay !== 'Sunday') {

            // setDays({ ...days, w6EndTime: time })
            setDays(pre => ({ ...pre, w7EndTime: time }))

        }
    } else {
        toast.info('Please fill start date and end date first!');
    }
}


const setSaturdayType = (hours, index, days, setDays) => {

    switch (index) {
        case 1:
            if (Number(hours) > 0 && days.w1WeekDay === 'Saturday') {
                // this.setState({
                //     w1Type: 'Working Day'
                // });
                setDays(pre => ({ ...pre, w1Type: 'Working Day' }))
            } else if (days.w1WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w1Type: 'Holiday' }))

            }
            break;
        case 2:
            if (Number(hours) > 0 && days.w2WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w2Type: 'Working Day' }))

            } else if (days.w2WeekDay === 'Saturday') {
                setDays(pre => ({ ...pre, w2Type: 'Holiday' }))

            }
            break;
        case 3:
            if (Number(hours) > 0 && days.w3WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w3Type: 'Working Day' }))
            } else if (days.w3WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w3Type: 'Holiday' }))

            }
            break;
        case 4:
            if (Number(hours) > 0 && days.w4WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w4Type: 'Working Day' }))
            } else if (days.w4WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w4Type: 'Holiday' }))

            }
            break;
        case 5:
            if (Number(hours) > 0 && days.w5WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w5Type: 'Working Day' }))
            } else if (days.w5WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w5Type: 'Holiday' }))

            }
            break;
        case 6:
            if (Number(hours) > 0 && days.w6WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w6Type: 'Working Day' }))
            } else if (days.w6WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w6Type: 'Holiday' }))

            }
            break;
        case 7:
            if (Number(hours) > 0 && days.w7WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w7Type: 'Working Day' }))
            } else if (days.w7WeekDay === 'Saturday') {

                setDays(pre => ({ ...pre, w7Type: 'Holiday' }))

            }
            break;
        default:
            break
    }

}


const setStartTime = (time, index, days, { setDays }) => {
    switch (index) {
        case 1:
            // this.setState({ w1StartTime: time });
            setDays({ ...days, w1StartTime: time })
            break;
        case 2:
            // this.setState({ w2StartTime: time });
            setDays({ ...days, w2StartTime: time })
            break;
        case 3:
            // this.setState({ w3StartTime: time });
            setDays({ ...days, w3StartTime: time })
            break;
        case 4:
            // this.setState({ w4StartTime: time });
            setDays({ ...days, w4StartTime: time })
            break;
        case 5:
            // this.setState({ w5StartTime: time });
            setDays({ ...days, w5StartTime: time })
            break;
        case 6:
            // this.setState({ w6StartTime: time });
            setDays({ ...days, w6StartTime: time })
            break;
        case 7:
            setDays({ ...days, w7StartTime: time })
            break;
        default:
            break;
    }

    // this.setState({ disabledButton: true });
}

const setEndTime = (time, index, days, { setDays }) => {
    switch (index) {
        case 1:
            // this.setState({ w1EndTime: time });
            setDays({ ...days, w1EndTime: time })
            break;
        case 2:
            // this.setState({ w2EndTime: time });
            setDays({ ...days, w2EndTime: time })
            break;
        case 3:
            // this.setState({ w3EndTime: time });
            setDays({ ...days, w3EndTime: time })
            break;
        case 4:
            // this.setState({ w4EndTime: time });
            setDays({ ...days, w4EndTime: time })
            break;
        case 5:
            // this.setState({ w5EndTime: time });
            setDays({ ...days, w5EndTime: time })
            break;
        case 6:
            // this.setState({ w6EndTime: time });
            setDays({ ...days, w6EndTime: time })
            break;
        case 7:
            // this.setState({ w7EndTime: time });
            setDays({ ...days, w7EndTime: time })
            break;
        default:
            break;
    }

    // this.setState({ disabledButton: true });
}


const setRegionStartTime = (value, region, regionData, { setRegionData }, props) => {
    if (value !== '') {
        let regionArr = [...regionData]
        let regionObj = { region: region._id, cutOffTime: value, create_user:props.loggedUser,create_date: new Date, userId: props.userId }
        let index = regionArr.findIndex(i => i.region === region._id)
        console.log(index);
        if (index < 0) {
            regionArr.push(regionObj)
        } else {
            regionArr[index].cutOffTime = value
        }
        console.log(regionArr);
        setRegionData(regionArr)
    }
}

const setStartDateRegion = (value, region, regionData, { setRegionData }, props) => {
    let regionArr = [...regionData]
    let regionObj = { region: region._id, cutOffDate: value, create_user:props.loggedUser,create_date: new Date, userId: props.userId }
    let index = regionArr.findIndex(i => i.region === region._id)
    if (index < 0) {
        regionArr.push(regionObj)
    } else {
        regionArr[index].cutOffDate = value
    }
    console.log(regionArr);
    setRegionData(regionArr)
}

// did not use
const addWorkingCalenderHandle = () => {
    var { startDate, endDate } = this.state;

    if (startDate !== '' && endDate !== '' && this.state.w1Day !== "" && this.state.w2Day !== "" && this.state.w3Day !== "" && this.state.w4Day !== "" && this.state.w5Day !== "" && this.state.w6Day !== "" && this.state.w7Day !== "") {

        var weekData = [
            { 'day': this.formatDate(this.state.w1Day), 'type': this.state.w1Type, 'weekDay': this.state.w1WeekDay, 'hours': this.state.w1Hours, 'startTime': this.state.w1StartTime, 'endTime': this.state.w1EndTime },
            { 'day': this.formatDate(this.state.w2Day), 'type': this.state.w2Type, 'weekDay': this.state.w2WeekDay, 'hours': this.state.w2Hours, 'startTime': this.state.w2StartTime, 'endTime': this.state.w2EndTime },
            { 'day': this.formatDate(this.state.w3Day), 'type': this.state.w3Type, 'weekDay': this.state.w3WeekDay, 'hours': this.state.w3Hours, 'startTime': this.state.w3StartTime, 'endTime': this.state.w3EndTime },
            { 'day': this.formatDate(this.state.w4Day), 'type': this.state.w4Type, 'weekDay': this.state.w4WeekDay, 'hours': this.state.w4Hours, 'startTime': this.state.w4StartTime, 'endTime': this.state.w4EndTime },
            { 'day': this.formatDate(this.state.w5Day), 'type': this.state.w5Type, 'weekDay': this.state.w5WeekDay, 'hours': this.state.w5Hours, 'startTime': this.state.w5StartTime, 'endTime': this.state.w5EndTime },
            { 'day': this.formatDate(this.state.w6Day), 'type': this.state.w6Type, 'weekDay': this.state.w6WeekDay, 'hours': this.state.w6Hours, 'startTime': this.state.w6StartTime, 'endTime': this.state.w6EndTime },
            { 'day': this.formatDate(this.state.w7Day), 'type': this.state.w7Type, 'weekDay': this.state.w7WeekDay, 'hours': this.state.w7Hours, 'startTime': this.state.w7StartTime, 'endTime': this.state.w7EndTime }
        ];

        weekData.forEach(element => {
            var type = '';
            if (element.weekDay === 'Saturday' && Number(element.hours) > 0) {
                type = 'Working Day';
            } else {
                type = element.type;
            }

            var startTime = '';
            var endTime = '';
            if (Number(element.hours) === 0 || element.weekDay === 'Sunday') {
                startTime = null;
                endTime = null;
                element.hours = 0;
            } else {
                startTime = element.startTime;
                endTime = element.endTime;
            }

            var data = {
                day: element.day,
                type: type,
                weekDay: element.weekDay,
                hours: Number(element.hours),
                startTime: startTime,
                endTime: endTime
            }

            var _id = this.dateAlreadyExists(data.day);

            if (_id) {
                data._id = _id;
                this.props.updateWorkingCalender(data, this.props.token);
            } else {
                this.props.addWorkingCalender(data, this.props.token);
            }
        });

        var restDataStartDate = this.state.w7Day;
        var differenceinDays = this.getDifferenceInDays(restDataStartDate, endDate) - 1;
        var restData = this.getRestData(weekData);
        var currentDate = this.addOneDayInDate(restDataStartDate);

        for (var i = 0; i <= differenceinDays; i++) {
            var dayNum = new Date(currentDate).getDay();
            var currentWeekDay = this.getWeekDay(dayNum);

            if (currentWeekDay === 'Monday') {
                var data = {
                    day: this.formatDate(currentDate),
                    type: this.getDayType(dayNum),
                    weekDay: 'Monday',
                    hours: Number(restData.mondayHours),
                    startTime: restData.mondayStartTime,
                    endTime: restData.mondayEndTime
                };

                var _id = this.dateAlreadyExists(data.day);

                if (_id) {
                    data._id = _id;
                    this.props.updateWorkingCalender(data, this.props.token);
                } else {
                    this.props.addWorkingCalender(data, this.props.token);
                }

            } else if (currentWeekDay === 'Tuesday') {
                var data = {
                    day: this.formatDate(currentDate),
                    type: this.getDayType(dayNum),
                    weekDay: 'Tuesday',
                    hours: Number(restData.tuesdayHours),
                    startTime: restData.tuesdayStartTime,
                    endTime: restData.tuesdayEndTime
                };

                var _id = this.dateAlreadyExists(data.day);

                if (_id) {
                    data._id = _id;
                    this.props.updateWorkingCalender(data, this.props.token);
                } else {
                    this.props.addWorkingCalender(data, this.props.token);
                }

            } else if (currentWeekDay === 'Wednesday') {
                var data = {
                    day: this.formatDate(currentDate),
                    type: this.getDayType(dayNum),
                    weekDay: 'Wednesday',
                    hours: Number(restData.wednesdayHours),
                    startTime: restData.wednesdayStartTime,
                    endTime: restData.wednesdayEndTime
                };

                var _id = this.dateAlreadyExists(data.day);

                if (_id) {
                    data._id = _id;
                    this.props.updateWorkingCalender(data, this.props.token);
                } else {
                    this.props.addWorkingCalender(data, this.props.token);
                }

            } else if (currentWeekDay === 'Thursday') {
                var data = {
                    day: this.formatDate(currentDate),
                    type: this.getDayType(dayNum),
                    weekDay: 'Thursday',
                    hours: Number(restData.thursdayHours),
                    startTime: restData.thursdayStartTime,
                    endTime: restData.thursdayEndTime
                };

                var _id = this.dateAlreadyExists(data.day);

                if (_id) {
                    data._id = _id;
                    this.props.updateWorkingCalender(data, this.props.token);
                } else {
                    this.props.addWorkingCalender(data, this.props.token);
                }

            } else if (currentWeekDay === 'Friday') {
                var data = {
                    day: this.formatDate(currentDate),
                    type: this.getDayType(dayNum),
                    weekDay: 'Friday',
                    hours: Number(restData.fridayHours),
                    startTime: restData.fridayStartTime,
                    endTime: restData.fridayEndTime
                };

                var _id = this.dateAlreadyExists(data.day);

                if (_id) {
                    data._id = _id;
                    this.props.updateWorkingCalender(data, this.props.token);
                } else {
                    this.props.addWorkingCalender(data, this.props.token);
                }

            } else if (currentWeekDay === 'Saturday') {
                var type = '';
                if (restData.saturdayType) {
                    type = restData.saturdayType;
                } else {
                    type = this.getDayType(dayNum);
                }
                var data = {
                    day: this.formatDate(currentDate),
                    type: type,
                    weekDay: 'Saturday',
                    hours: Number(restData.saturdayHours),
                    startTime: restData.saturdayStartTime,
                    endTime: restData.saturdayEndTime
                };

                var _id = this.dateAlreadyExists(data.day);

                if (_id) {
                    data._id = _id;
                    this.props.updateWorkingCalender(data, this.props.token);
                } else {
                    this.props.addWorkingCalender(data, this.props.token);
                }

            } else if (this.getWeekDay(new Date(currentDate).getDay()) === 'Sunday') {
                var data = {
                    day: this.formatDate(currentDate),
                    type: this.getDayType(new Date(currentDate).getDay()),
                    weekDay: 'Sunday',
                    hours: Number(restData.sundayHours),
                    startTime: restData.sundayStartTime,
                    endTime: restData.sundayEndTime
                };

                var _id = this.dateAlreadyExists(data.day);

                if (_id) {
                    data._id = _id;
                    this.props.updateWorkingCalender(data, this.props.token);
                } else {
                    this.props.addWorkingCalender(data, this.props.token);
                }
            }

            currentDate = this.addOneDayInDate(currentDate);
        }

        this.setState({
            startDate: '',
            endDate: '',
            w1Day: '',
            w1Type: '',
            w1Hours: '',
            w1StartTime: '',
            w1EndTime: '',
            w1WeekDay: '',
            w2Day: '',
            w2Type: '',
            w2Hours: '',
            w2StartTime: '',
            w2EndTime: '',
            w2WeekDay: '',
            w3Day: '',
            w3Type: '',
            w3Hours: '',
            w3StartTime: '',
            w3EndTime: '',
            w3WeekDay: '',
            w4Day: '',
            w4Type: '',
            w4Hours: '',
            w4StartTime: '',
            w4EndTime: '',
            w4WeekDay: '',
            w5Day: '',
            w5Type: '',
            w5Hours: '',
            w5StartTime: '',
            w5EndTime: '',
            w5WeekDay: '',
            w6Day: '',
            w6Type: '',
            w6Hours: '',
            w6StartTime: '',
            w6EndTime: '',
            w6WeekDay: '',
            w7Day: '',
            w7Type: '',
            w7Hours: '',
            w7StartTime: '',
            w7EndTime: '',
            w7WeekDay: '',
        });

        toast.success('Successfully Added Working Calender!');
        document.getElementById("addWorkingCalenderForm").reset();
        window.$('#addWorkingCalender').modal('hide');
    } else {
        toast.error('Please fill up Start Time, End Time and all week dates!')
    }
}

const formatDate = date => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const addRegionHandler = (regionData, days, day, { setDays, setRegionData }) => {
    setDays({ ...days, [`region${day}`]: regionData })
    setRegionData([])
}

const addDeliveryCalender = (days, props) => {
    // if (startDate !== '' && endDate !== '' && this.state.w1Day !== "" && this.state.w2Day !== "" && this.state.w3Day !== "" && this.state.w4Day !== "" && this.state.w5Day !== "" && this.state.w6Day !== "" && this.state.w7Day !== "") {
    // calculateRangeHours(days, setDays)
    var weekData = [
        { 'day': formatDate(days.w1Day), 'type': days.w1Type, 'weekDay': days.w1WeekDay, 'hours': days.w1Hours, 'startTime': days.w1StartTime, 'endTime': days.w1EndTime, 'region': days.region1 },
        { 'day': formatDate(days.w2Day), 'type': days.w2Type, 'weekDay': days.w2WeekDay, 'hours': days.w2Hours, 'startTime': days.w2StartTime, 'endTime': days.w2EndTime, 'region': days.region2 },
        { 'day': formatDate(days.w3Day), 'type': days.w3Type, 'weekDay': days.w3WeekDay, 'hours': days.w3Hours, 'startTime': days.w3StartTime, 'endTime': days.w3EndTime, 'region': days.region3 },
        { 'day': formatDate(days.w4Day), 'type': days.w4Type, 'weekDay': days.w4WeekDay, 'hours': days.w4Hours, 'startTime': days.w4StartTime, 'endTime': days.w4EndTime, 'region': days.region4 },
        { 'day': formatDate(days.w5Day), 'type': days.w5Type, 'weekDay': days.w5WeekDay, 'hours': days.w5Hours, 'startTime': days.w5StartTime, 'endTime': days.w5EndTime, 'region': days.region5 },
        { 'day': formatDate(days.w6Day), 'type': days.w6Type, 'weekDay': days.w6WeekDay, 'hours': days.w6Hours, 'startTime': days.w6StartTime, 'endTime': days.w6EndTime, 'region': days.region6 },
        { 'day': formatDate(days.w7Day), 'type': days.w7Type, 'weekDay': days.w7WeekDay, 'hours': days.w7Hours, 'startTime': days.w7StartTime, 'endTime': days.w7EndTime, 'region': days.region7 }
    ];
    // }
    let create_user = props.loggedUser
    let userId = props.userId
    props.addDeliveryCalender({ days, weekData, create_user, userId }, props)
    props.history.push('/basic data/delivery calender')
}

function AddRegionWorkingCalender(props) {

    // const [startDate, setStartDate] = useState('')
    const [day, setDay] = useState('')
    const [days, setDays] = useState({
        startDate: '', endDate: '',
        w1Day: '', w1Type: '', w1Hours: '', w1StartTime: '', w1EndTime: '', w1WeekDay: '', region1: [],
        w2Day: '', w2Type: '', w2Hours: '', w2StartTime: '', w2EndTime: '', w2WeekDay: '', region2: [],
        w3Day: '', w3Type: '', w3Hours: '', w3StartTime: '', w3EndTime: '', w3WeekDay: '', region3: [],
        w4Day: '', w4Type: '', w4Hours: '', w4StartTime: '', w4EndTime: '', w4WeekDay: '', region4: [],
        w5Day: '', w5Type: '', w5Hours: '', w5StartTime: '', w5EndTime: '', w5WeekDay: '', region5: [],
        w6Day: '', w6Type: '', w6Hours: '', w6StartTime: '', w6EndTime: '', w6WeekDay: '', region6: [],
        w7Day: '', w7Type: '', w7Hours: '', w7StartTime: '', w7EndTime: '', w7WeekDay: '', region7: [],
    })
    const [regionData, setRegionData] = useState([])

    useEffect(() => {
        props.getRegionData(props)
        // setRegionData(props.region)
    }, [])

    useEffect(() => {
        console.log(regionData);
    }, [regionData])

    return (
        <div className="right_col" role="main">
            <form id='addWorkingCalenderForm'>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                <NavLink to={`/basic data/delivery calender`} className={'btn btn-secondary'}>
                                    <i className="fa fa-arrow-circle-left" aria-hidden="true"></i> Back
                                </NavLink>
                            </td>
                            <td>Date Range* :</td>
                            <td><DatePicker onChange={e => setStartDateWithType(e, days, { setDays })} placeholderText="Start Date" selected={days.startDate} className="form-control" minDate={Date.now()} /></td>
                            <td>To</td>
                            <td><DatePicker onChange={e => setEndDateWithType(e, days, { setDays })} placeholderText="End Date" selected={days.endDate} className="form-control" minDate={Date.now()} /></td>
                            <td>
                                <button type="button" className="btn btn-primary" disabled={days.endDate === '' || days.w1Hours === '' || days.w2Hours === '' || days.w3Hours === '' || days.w4Hours === '' || days.w5Hours === '' || days.w6Hours === '' || days.w7Hours === ''} onClick={() => addDeliveryCalender(days, props)}>Delivery Calender Process</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr></hr>
                <table>
                    <tbody>
                        <tr>
                            <td>Time Range* :</td>
                            <td>
                                <select className="form-control" onChange={e => e.target.value === '' ? null : setStartTimeRange(e.target.value, days, { setDays })}>
                                    <option value={''}>- Select Start Time -</option>
                                    {timeDropDownData.map(element => {
                                        return <option key={element._id} value={element._id}>{element.name}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <select className="form-control" onChange={e => e.target.value === '' ? null : setEndTimeRange(e.target.value, days, { setDays })}>
                                    <option value={''}>- Select End Time -</option>
                                    {timeDropDownData.map(element => {
                                        return <option key={element._id} value={element._id}>{element.name}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <button type="button" className="btn btn-primary" disabled={days.startDate === '' || days.endDate === ''} onClick={() => calculateRangeHours(days, setDays)}>
                                    <i className="fa fa-calculator" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr></hr>

                {days.startDate === '' ?
                    null
                    : <table className="-striped table table-bordered">
                        <thead>
                            <tr>
                                <th style={{ zIndex: 0 }}>Day</th>
                                <th style={{ zIndex: 0 }}>Week Day</th>
                                <th style={{ zIndex: 0 }}>Type</th>
                                <th style={{ zIndex: 0 }}>Hours</th>
                                <th style={{ zIndex: 0 }}>Region</th>
                                <th style={{ zIndex: 0 }}>Start Time</th>
                                <th style={{ zIndex: 0 }}>End Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><DatePicker selected={days.w1Day} className="form-control" disabled="disabled" /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w1WeekDay} /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w1Type} /></td>
                                <td><input type="number" className="form-control" disabled="disabled" value={days.w1Hours} /></td>
                                <td>
                                    {days.w1Type !== 'Holiday' ?
                                        <a className='btn link-button' data-toggle="modal" data-target='#region' onClick={() => { setDay(1); setRegionData(days.region1) }}>Region</a>
                                        : null
                                    }
                                </td>
                                <td>
                                    <select className="form-control" value={days.w1StartTime} onChange={e => e.target.value === '' ? null : setStartTime(e.target.value, 1, days, { setDays })}>
                                        <option value={''}>- Select Start Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control" value={days.w1EndTime} onChange={e => e.target.value === '' ? null : setEndTime(e.target.value, 1, days, { setDays })}>
                                        <option value={''}>- Select End Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><DatePicker selected={days.w2Day} className="form-control" disabled="disabled" /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w2WeekDay} /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w2Type} /></td>
                                <td><input type="number" className="form-control" disabled="disabled" value={days.w2Hours} /></td>
                                <td>
                                    {days.w2Type !== 'Holiday' ?
                                        <a className='btn link-button' data-toggle="modal" data-target='#region' onClick={() => { setDay(2); setRegionData(days.region2) }}>Region</a>
                                        : null}
                                </td>
                                <td>
                                    <select className="form-control" value={days.w2StartTime} onChange={e => e.target.value === '' ? null : setStartTime(e.target.value, 2, days, { setDays })}>
                                        <option value={''}>- Select Start Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control" value={days.w2EndTime} onChange={e => e.target.value === '' ? null : setEndTime(e.target.value, 2, days, { setDays })}>
                                        <option value={''}>- Select End Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><DatePicker selected={days.w3Day} className="form-control" disabled="disabled" /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w3WeekDay} /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w3Type} /></td>
                                <td><input type="number" className="form-control" disabled="disabled" value={days.w3Hours} /></td>
                                <td>
                                    {days.w3Type !== 'Holiday' ?
                                        <a className='btn link-button' data-toggle="modal" data-target='#region' onClick={() => { setDay(3); setRegionData(days.region3) }}>Region</a>
                                        : null}
                                </td>
                                <td>
                                    <select className="form-control" value={days.w3StartTime} onChange={e => e.target.value === '' ? null : setStartTime(e.target.value, 3, days, { setDays })}>
                                        <option value={''}>- Select Start Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control" value={days.w3EndTime} onChange={e => e.target.value === '' ? null : setEndTime(e.target.value, 3, days, { setDays })}>
                                        <option value={''}>- Select End Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><DatePicker selected={days.w4Day} className="form-control" disabled="disabled" /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w4WeekDay} /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w4Type} /></td>
                                <td><input type="number" className="form-control" disabled="disabled" value={days.w4Hours} /></td>
                                <td>
                                    {days.w4Type !== 'Holiday' ?
                                        <a className='btn link-button' data-toggle="modal" data-target='#region' onClick={() => { setDay(4); setRegionData(days.region4) }}>Region</a>
                                        : null}
                                </td>
                                <td>
                                    <select className="form-control" value={days.w4StartTime} onChange={e => e.target.value === '' ? null : setStartTime(e.target.value, 4, days, { setDays })}>
                                        <option value={''}>- Select Start Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control" value={days.w4EndTime} onChange={e => e.target.value === '' ? null : setEndTime(e.target.value, 4, days, { setDays })}>
                                        <option value={''}>- Select End Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><DatePicker selected={days.w5Day} className="form-control" disabled="disabled" /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w5WeekDay} /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w5Type} /></td>
                                <td><input type="number" className="form-control" disabled="disabled" value={days.w5Hours} /></td>
                                <td>
                                    {days.w5Type !== 'Holiday' ?
                                        <a className='btn link-button' data-toggle="modal" data-target='#region' onClick={() => { setDay(5); setRegionData(days.region5) }}>Region</a>
                                        : null}
                                </td>
                                <td>
                                    <select className="form-control" value={days.w5StartTime} onChange={e => e.target.value === '' ? null : setStartTime(e.target.value, 5, days, { setDays })}>
                                        <option value={''}>- Select Start Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control" value={days.w5EndTime} onChange={e => e.target.value === '' ? null : setEndTime(e.target.value, 5, days, { setDays })}>
                                        <option value={''}>- Select End Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><DatePicker selected={days.w6Day} className="form-control" disabled="disabled" /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w6WeekDay} /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w6Type} /></td>
                                <td><input type="number" className="form-control" disabled="disabled" value={days.w6Hours} /></td>
                                <td>
                                    {days.w6Type !== 'Holiday' ?
                                        <a className='btn link-button' data-toggle="modal" data-target='#region' onClick={() => { setDay(6); setRegionData(days.region6) }}>Region</a>
                                        : null}
                                </td>
                                <td>
                                    <select className="form-control" value={days.w6StartTime} onChange={e => e.target.value === '' ? null : setStartTime(e.target.value, 6, days, { setDays })}>
                                        <option value={''}>- Select Start Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control" value={days.w6EndTime} onChange={e => e.target.value === '' ? null : setEndTime(e.target.value, 6, days, { setDays })}>
                                        <option value={''}>- Select End Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><DatePicker selected={days.w7Day} className="form-control" disabled="disabled" /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w7WeekDay} /></td>
                                <td><input type="text" className="form-control" disabled="disabled" value={days.w7Type} /></td>
                                <td><input type="number" className="form-control" disabled="disabled" value={days.w7Hours} /></td>
                                <td>
                                    {days.w7Type !== 'Holiday' ?
                                        <a className='btn link-button' data-toggle="modal" data-target='#region' onClick={() => { setDay(7); setRegionData(days.region7) }}>Region</a>
                                        : null}
                                </td>
                                <td>
                                    <select className="form-control" value={days.w7StartTime} onChange={e => e.target.value === '' ? null : setStartTime(e.target.value, 7, days, { setDays })}>
                                        <option value={''}>- Select Start Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select className="form-control" value={days.w7EndTime} onChange={e => e.target.value === '' ? null : setEndTime(e.target.value, 7, days, { setDays })}>
                                        <option value={''}>- Select End Time -</option>
                                        {timeDropDownData.map(element => {
                                            return <option key={element._id} value={element._id}>{element.name}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </form>

            <ErrorBoundary>
                <div id="region" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Add Region Delivery Calender</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => setRegionData([])}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <React.Fragment>
                                    {(day === 1 && days.w1Day.toString()) || (day === 2 && days.w2Day.toString()) || (day === 3 && days.w3Day.toString()) || (day === 4 && days.w4Day.toString()) || (day === 5 && days.w5Day.toString()) || (day === 6 && days.w6Day.toString()) || (day === 7 && days.w7Day.toString())}
                                    <table className="-striped table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Region</th>
                                                <th>Cut of Date</th>
                                                <th>Cut of Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {regionData.length >= 0 && props.region.map(data => {
                                                var i = regionData.findIndex(i => i.region === data._id)
                                                // console.log(regionData.length > 0 && i > 0 ? regionData[i]?.cutOffDate : 'dta');
                                                return <tr key={data._id}>
                                                    <td>{data.name}</td>
                                                    <td>
                                                        <DatePicker onChange={e => setStartDateRegion(e, data, regionData, { setRegionData }, props)} placeholderText="Cut Of Date"
                                                            selected={regionData[i]?.cutOffDate}
                                                            className="form-control" maxDate={(day === 1 && days.w1Day) || (day === 2 && days.w2Day) || (day === 3 && days.w3Day) || (day === 4 && days.w4Day) || (day === 5 && days.w5Day) || (day === 6 && days.w6Day) || (day === 7 && days.w7Day)} />
                                                    </td>
                                                    {/* <td>{i}</td> */}
                                                    <td>
                                                        <select className="form-control" value={regionData[i]?.cutOffTime || ''} onChange={e => setRegionStartTime(e.target.value, data, regionData, { setRegionData }, props)}>
                                                            <option value={''}>- Select Start Time -</option>
                                                            {timeDropDownData.map(element => {
                                                                return <option key={element._id} value={element._id}>{element.name}</option>
                                                            })}
                                                        </select>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => addRegionHandler(regionData, days, day, { setDays, setRegionData })}>Add</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setRegionData([])}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        id: state.rLogin.id,
        isLoading: state.rLogin.loading,
        loggedUser: state.rLogin.name,
        region: state.rBasicData.region,
        userId: state.rLogin.id,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRegionData: props => { dispatch(getRegionData(props)) },
        addDeliveryCalender: (data, props) => { dispatch(addWorkingCalender(data, props)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRegionWorkingCalender);
