/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';
import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import { toast } from "react-toastify";
import _ from 'lodash';

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getBanksData, getBranchesData, addBank, addBranch, updateBank, deleteBank, updateBranch, deleteBranch } from '../../../actions/Acc/bank_actions';

import "react-table/react-table.css";
class Bank extends React.Component {

    state = {
        banksData: [],
        branchesData: [],
        createdAt: Date.now(),
        flag: false,

        //add bank
        bankName: '',
        bankCode: '',
        headOfficeTel: '',
        headOfficeCity: '',
        //add bank end

        //add branch
        currentBankId: '',
        branchCode: '',
        swiftCode: '',
        branchName: '',
        branchCity: '',
        branchTelNum: '',
        //add branch end

        expanded: {},
        editing: null,
        deleting: null,
        sortOptionsBank: [{ id: 'createdAt', desc: true }],
        sortOptionsBranch: [{ id: 'createdAt', desc: true }],

    };

    componentDidMount() {
        this.props.getBanksData(this.props.token);
        this.props.getBranchesData(this.props.token);
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        if (hours.length < 2) {
            hours = '0' + hours;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        let newDate = [year, month, day].join('-');
        let time = [hours, minutes, seconds].join(':')

        if (year === 'NaN' || month === 'NaN' || day === 'NaN' || hours === 'NaN' || minutes === 'NaN' || seconds === 'NaN') {
            return '';
        }

        return [newDate, time].join(' ');
    }

    formatOnlyDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        let newDate = [year, month, day].join('-');

        return newDate;
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        flag: false
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original, flag: false })
                }
            },
            updPerm: Permission('ACC', 'BANK', 'UPDATE', this.props),
            delPerm: Permission('ACC', 'BANK', 'DELETE', this.props)
        }) ||
        {};

    getActionPropsBranches = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        flag: true
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original, flag: true })
                }
            },
            updPerm: Permission('ACC', 'BANK', 'UPDATE', this.props),
            delPerm: Permission('ACC', 'BANK', 'DELETE', this.props)
        }) ||
        {};

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    bankColumns = [
        { Header: "Bank Name", accessor: "name", ...this.editableColumnProps },
        { Header: "Code", accessor: "code", ...this.editableColumnProps,getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Head Office Tel.", accessor: "headofficetel", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Head Office City", accessor: "headofficecity", ...this.editableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 230,
            minWidth: 230,
            Cell: props => (
                <div style={{ textAlign: "right" }}>
                    <button
                        data-toggle="modal"
                        className="btn btn-link"
                        data-target="#addBranch"
                        data-backdrop="static" data-keyboard="false"
                        onClick={() => {
                            this.setState({
                                currentBankId: props.original._id
                            });
                        }}
                    >
                        + Branch
                    </button>
                </div>
            )
        },
        {
            Header: "",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    branchesColumns = [
        {
            Header: "Branch Code",
            accessor: "code",
            ...this.editableColumnProps,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        { Header: "Swift Code", accessor: "swiftcode", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Name", accessor: "name", ...this.editableColumnProps },
        { Header: "City", accessor: "city", ...this.editableColumnProps },
        { Header: "Telephone No.", accessor: "telnum", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionPropsBranches,
            Cell: ActionsCell
        },
    ];


    clearAddBankForm = () => {
        this.setState({
            bankName: '',
            bankCode: '',
            headOfficeTel: '',
            headOfficeCity: '',
        });

        document.getElementById("addBankForm").reset();
    }

    addBankHandle = () => {
        var { bankName,
            bankCode,
            headOfficeTel,
            headOfficeCity
        } = this.state;

        if (bankName.trim() !== '' && bankCode.trim() !== '') {

            var data = {};

            data.createdAt = this.formatDate(Date.now());
            data.name = this.state.bankName;
            data.code = this.state.bankCode;
            data.headofficetel = this.state.headOfficeTel;
            data.headofficecity = this.state.headOfficeCity;

            this.props.addBank(data, this.props.token);

            this.clearAddBankForm();

        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    addBranchHandle = () => {
        var { branchCode,
            swiftCode,
            branchName,
            branchCity,
            branchTelNum
        } = this.state;

        if (branchCode.trim() !== '' && swiftCode.trim() !== '' && branchName.trim() !== '' && branchCity.trim() !== '') {

            var data = {};

            data.createdAt = this.formatDate(Date.now());
            data.code = this.state.branchCode;
            data.swiftcode = this.state.swiftCode;
            data.name = this.state.branchName;
            data.city = this.state.branchCity;
            data.telnum = this.state.branchTelNum;
            data.bankref = this.state.currentBankId;

            this.props.addBranch(data, this.props.token);

            this.clearAddBranchForm();

        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    clearAddBranchForm = () => {
        this.setState({
            branchCode: '',
            swiftCode: '',
            branchName: '',
            branchCity: '',
            branchTelNum: '',
            bankref: '',
        });

        document.getElementById("addBranchForm").reset();
    }


    handleSubmit = values => {
        if (this.state.flag) {
            this.handleSubmitBranch(values);
        } else {
            if (this.state.deleting === null && this.state.editing !== null) {
                var data = values;
                delete (data.createdAt)

                if (data.name !== '' && data.code !== '' && data.headofficetel !== '' && data.headofficecity !== '') {
                    this.props.updateBank(data, this.props.token);
                } else {
                    toast.error('Please fill up the mandatory fields!')
                }


            } else if (this.state.deleting !== null && this.state.editing === null) {
                var data = {
                    '_id': values._id
                }

                this.props.deleteBank(data, this.props.token)
            }
        }
    };

    handleSubmitBranch = (values) => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values;
            delete (data.createdAt)

            if (data.name !== '' && data.code !== '' && data.swiftcode !== '' && data.city !== '' && data.telnum !== '') {
                this.props.updateBranch(data, this.props.token);
            } else {
                toast.error('Please fill up the mandatory fields!')
            }
        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values._id
            }

            this.props.deleteBranch(data, this.props.token)
        }
    }


    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button
                            className="btn btn-primary"
                            disabled={Permission('ACC', 'BANK', 'ADD', this.props)}
                            data-toggle="modal"
                            data-target="#addBank"
                            data-backdrop="static" data-keyboard="false"
                        >
                            + Bank
                        </button>
                    </div>
                    <ErrorBoundary>
                        <div className="card-body">
                            <FormProvider
                                form="inline"
                                onSubmit={this.handleSubmit}
                                onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                sorted={this.state.sortOptions}
                                                onSortedChange={val => {
                                                    this.setState({ sortOptions: val })
                                                }}
                                                columns={this.bankColumns}
                                                data={this.props.banksData}
                                                defaultPageSize={10}
                                                expanded={this.state.expanded}
                                                onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index)}
                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                showPageJump={false}
                                                SubComponent={row => {
                                                    var subData = _.filter(this.props.branchesData, function (o) {
                                                        return o.bankref == row.original._id
                                                    })
                                                    return (
                                                        <React.Fragment>
                                                            <ErrorBoundary>
                                                                <FormProvider
                                                                    form="inline"
                                                                    onSubmit={this.handleSubmitBranch}
                                                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                                    enableReinitialize
                                                                >
                                                                    {formProps => {
                                                                        return (
                                                                            <form onSubmit={formProps.handleSubmitBranch}>
                                                                                <Table className="-striped"
                                                                                    sorted={this.state.sortOptionsBranch}
                                                                                    onSortedChange={val => {
                                                                                        this.setState({ sortOptionsBranch: val })
                                                                                    }}
                                                                                    columns={this.branchesColumns}
                                                                                    data={subData}
                                                                                    defaultPageSize={10}
                                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                                    showPageJump={false}
                                                                                />
                                                                            </form>
                                                                        )
                                                                    }}

                                                                </FormProvider>
                                                            </ErrorBoundary>
                                                        </React.Fragment>
                                                    );
                                                }}
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </div>
                    </ErrorBoundary>

                    <div id="addBank" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Bank</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.clearAddBankForm()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addBankForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Bank Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ bankName: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Code* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ bankCode: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Head Office Tel. :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ headOfficeTel: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Head Office City :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ headOfficeCity: e.target.value })} /></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={this.addBankHandle}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.clearAddBankForm()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="addBranch" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Branch</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.clearAddBranchForm()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addBranchForm'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Branch Code* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ branchCode: e.target.value })} value={this.state.branchCode} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Swift Code* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ swiftCode: e.target.value })} value={this.state.swiftCode} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ branchName: e.target.value })} value={this.state.branchName} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Branch City* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ branchCity: e.target.value })} value={this.state.branchCity} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Telephone No. :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ branchTelNum: e.target.value })} value={this.state.branchTelNum} /></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={e => this.addBranchHandle()}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.clearAddBranchForm()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        banksData: state.rBanks.banksData,
        branchesData: state.rBanks.branchesData,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userId: state.rLogin.id
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBanksData: (token) => { dispatch(getBanksData(token)) },
        getBranchesData: (token) => { dispatch(getBranchesData(token)) },
        addBank: (data, token) => { dispatch(addBank(data, token)) },
        addBranch: (data, token) => { dispatch(addBranch(data, token)) },
        updateBank: (data, token) => { dispatch(updateBank(data, token)) },
        deleteBank: (data, token) => { dispatch(deleteBank(data, token)) },
        updateBranch: (data, token) => { dispatch(updateBranch(data, token)) },
        deleteBranch: (data, token) => { dispatch(deleteBranch(data, token)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bank);


