/**
 * @file This file defined for cartTable component
 * @author Bhanuka Chathuranga
 */
import React from 'react';
import _ from 'lodash';
import { PriceFormat } from '../../../utils';

/**
 * 
 * @param {Object} obj
 * @param {Array} obj.cart cart data
 * @param {Object} coRegion customer selected region
 * @param {Number} fastDeliveryCharge fast delivery charge
 * @returns {Component} cartTable component
 */
function CartTable({ cart, coRegion, fastDeliveryCharge }) {

    let isFastDeliveryStatus = cart.every(o => o.is_fast_delivery === true)
    const Total = Number(cart.reduce((total, obj) => { return total + ((obj.price - (obj.price * ((obj.fastDiscountValue.length > 0 && obj.is_fast_delivery  && isFastDeliveryStatus === true ? obj.fastDiscountValue[0] : obj.discountPercentage) / 100))) * obj.qty) }, 0))
    let not_fast_delivery = _.filter(cart, obj => !obj.is_fast_delivery)
    var isFastDelivery = not_fast_delivery.length === 0
    const deliveryRegion = isFastDelivery && coRegion?.name === 'Colombo' ?
        fastDeliveryCharge
        : Number(Total) < coRegion.thresholdValue ?
            coRegion.deliveryCharges
            : 0

    return (
        <div className="table-responsive" style={{ height: '30vh' }}>
            <table className='table table-bordered' style={{ marginBottom: '0px' }}>
                <thead>
                    <tr>
                        <th className='thead'></th>
                        <th className='thead'>Item Name</th>
                        <th className='thead'>Qty ({_.sumBy(cart, 'qty')})</th>
                        <th className='thead' style={{ textAlign: 'center' }}>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {cart.map((objItem, i) => {
                        let itemPrice = objItem.qty * (objItem.price - (objItem.price * ((objItem.fastDiscountValue.length > 0 && objItem.is_fast_delivery && isFastDeliveryStatus === true ? objItem.fastDiscountValue[0] : objItem.discountPercentage)  / 100)))
                        return <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{objItem.desc || objItem.name}</td>
                            <td>{objItem.qty}</td>
                            <td><div style={{ float: 'right' }}>{PriceFormat(itemPrice)}</div></td>
                        </tr>
                    }
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={3}>Delivery Charges *</td>
                        <td>
                            <div style={{ float: 'right' }}>
                                {PriceFormat(deliveryRegion)}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>Total Price *</td>
                        <td><div style={{ float: 'right' }}>{PriceFormat(Total + deliveryRegion)}</div></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default CartTable
