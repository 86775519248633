/**
 * @file This file defines to ordering action dispatch
 * @author Bhanuka Chathuranga
 */
import { toast } from 'react-toastify';

var axios = require('axios');

export const salesPromotionAsync = data => {
    return { type: 'FETCH_SALES_PROMOTION_DATA', data }
}


/**
 * 
 * @param {*} token 
 */
export const getSalesPromotionData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/ordering/getSalesPromotionData')
            .then(result => {
                dispatch(salesPromotionAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addSalesPromotionData = (token, data, cb) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/addSalesPromotionData', { data })
            .then(result => {
                cb()
                toast.success(result.data)
                dispatch(getSalesPromotionData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const deleteSalesPromotionData = (token, data) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/deleteSalesPromotionData', data)
            .then(result => {
                toast.success(result.data)
                dispatch(getSalesPromotionData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const updateSalesPromotionData = (token, data) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/updateSalesPromotionData', data)
            .then(result => {
                toast.success(result.data)
                dispatch(getSalesPromotionData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}