import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Table from "react-table";
import * as BS from "react-bootstrap";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import axios from 'axios';
import withFixedColumns from 'react-table-hoc-fixed-columns';

import HighlightCell from "../../components/HighlightCell";
import InvoicePrint from "../Ordering/InvoicePrint";
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary';
import { DateNumberFormat } from "../../utils";
import GridFilter from "../../components/GridFilter";
import SearchableSelectComp from '../../components/SearchableSelectComp';

import { getMissingOrderData, updateMissingOrderQty, getMissingCompleteOrder } from '../../../actions/Delivery/missing_order_action'
import { getPaymentMethodData } from '../../../actions/Basic_data/basic_data_action'
import { deliveryInspect, getDeliveryCrew } from '../../../actions/Delivery/delivery_action';
import ConvertDecimalPlaces from "../../utils/ConvertDecimalPlaces";

const ReactTableFixedColumns = withFixedColumns(Table);

const reasonData = [
    { '_id': 'Not available', 'description': 'Not available' },
    { '_id': 'Permanently Not available', 'description': 'Permanently Not available' },
    { '_id': 'Delivery issues', 'description': 'Delivery issues' },
    { '_id': 'Customer Cancel', 'description': 'Customer Cancel' },
    { '_id': 'Customer Add', 'description': 'Customer Add' },
    { '_id': 'Cancel by Gusta', 'description': 'Cancel by Gusta' },
    { '_id': 'Add by Gusta', 'description': 'Add by Gusta' },
]

const getMissingOrderItemsById = (expandMissingorderId, setMissingOrderItems, props) => {
    axios.defaults.headers.common['authorization'] = props.token;
    axios.post('/missingOrder/getMissingOrderItemsById', { id: expandMissingorderId })
        .then(result => {
            setMissingOrderItems(result.data)
        }).catch(err => {
            console.error(err)
            toast.error(`${err}`)
        })
}

export const MissingOrder = props => {
    const [expanded, setExpanded] = useState({});
    // const [edit, setEdit] = useState(null);
    const [qty, setQty] = useState(null);
    const [currentQty, setCurrentQty] = useState(null);
    const [reason, setReason] = useState(null);
    const [updateMissingOrder, setUpdateMissingOrder] = useState(null);
    const [expandMissingorderId, setExpandMissingorderId] = useState(null);
    const [missingOrderItems, setMissingOrderItems] = useState([]);
    const [isPrintLoading, setIsPrintLoading] = useState(null);
    const [date, setDate] = useState(null);
    const [orderData, setOrderData] = useState({ rider: '', orderId: '' });
    const [summaryData, setSummaryData] = useState([]);
    const [printOrder, setPrintOrder] = useState(null);
    const [printOrderItems, setPrintOrderItems] = useState(null);
    const [printOrderItemsData, setPrintOrderItemsData] = useState(null);
    const [shelfLife, setShelfLife] = useState([]);
    const [bagData, setBagData] = useState([]);
    const [bagNames, setBagNames] = useState('');
    const [input, setInput] = useState('');
    const [disableCompleted, setDisableCompleted] = useState(false);

    const [isActive, setActive] = useState(false);
    const componentRef = useRef();

    const getMissingItemsSummary = () => {
        axios.defaults.headers.common['authorization'] = props.token;
        axios.get('/missingOrder/getMissingOrderSummary')
            .then(result => {
                setSummaryData(result.data)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }

    useEffect(() => {
        props.getMissingOrderData(props)
        props.getPaymentMethodData(props.token);
        props.getDeliveryCrew(props);
        props.getMissingCompleteOrder(props);
        getMissingItemsSummary();
    }, [])

    // const getMissingOrderItemsById = (expandMissingorderId, setMissingOrderItems, props) => {
    //     axios.defaults.headers.common['authorization'] = props.token;
    //     axios.post('/missingOrder/getMissingOrderItemsById', { id: expandMissingorderId })
    //         .then(result => {
    //             setMissingOrderItems(result.data)
    //         }).catch(err => {
    //             console.error(err)
    //             toast.error(`${err}`)
    //         })
    // }


    const editableNumberComponent = ({ input, editing, value, original, ...rest }) => {
        // console.log(input)
        // console.log(original);
        // console.log(editing);
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component type="number"
            //  defaultValue={original.purchaseitem.balance}   
            // {...input}
            onChange={input.onChange}
            {...rest} children={children} min={0} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    };

    useEffect(() => {
        getMissingOrderItemsById(expandMissingorderId, setMissingOrderItems, props)
    }, [expandMissingorderId])

    useEffect(() => {
        // props.getOrderItems(orderItems, props.token)
        // console.log(orderItems)
        setPrintOrderItemsData([])
        setIsPrintLoading(true);
        if (printOrderItems !== null) {
            axios.defaults.headers.common['authorization'] = props.token;
            axios.post('/ordering/getOrderItems', { printOrderItems })
                .then(result => {
                    // console.table(result.data)
                    setPrintOrderItemsData(result.data)
                    setIsPrintLoading(false)
                }).catch(err => {
                    console.error(err)
                    // toast.error(`${err}`)
                })
        }
    }, [printOrderItems])

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const reload = () => {
        props.getMissingOrderData(props)
        props.getMissingCompleteOrder(props);
        getMissingOrderItemsById(expandMissingorderId, setMissingOrderItems, props)
        setDisableCompleted(false)
    }

    const clearField = () => {
        bagData.map(o => {
            document.getElementById(`${o.state} + ${o.qty}`).disabled = false;
            document.getElementById(`${o.state} + ${o.qty}`).classList.add('btn-primary');
            document.getElementById(`${o.state} + ${o.qty}`).classList.remove('btn-secondary');
        })
        setBagData([])
        setBagNames('')
    }

    const orderItemsHandle = (type, qty, data, input, props) => {
        let obj = ({ ...input, type, orderItemId: input.orderitems._id, order_ref: input.orderitems.order_ref })
        
        if (Number(qty) !== 0) {
            axios.defaults.headers.common['authorization'] = props.token;
            axios.get('/deliveryInspect/stockValidation', { params: { id: obj.orderItemId, item: obj.items._id, qty: qty, order_ref: obj.order_ref } })
                .then(result => {
                    if (result.status === 200 || (type === 'missing' && qty === 0) || ((type === 'missing') && (result.data.stockQty >= qty))) {
                        data.missingorderitems.qty = Number(qty)
                        props.getMissingOrderData(props)
                        props.updateMissingOrderQty(data, reason, { getMissingOrderItemsById, expandMissingorderId, setMissingOrderItems }, props.token)
                    } else {
                        toast.warn(result.data.msg)
                    }
                }).catch(err => {
                    console.error(err);
                })
        } else {
            data.missingorderitems.qty = Number(qty)
            props.getMissingOrderData(props)
            props.updateMissingOrderQty(data, reason, { getMissingOrderItemsById, expandMissingorderId, setMissingOrderItems }, props.token)
        }
    }

    const updateMissingOrderQty = () => {
        if (updateMissingOrder !== null && updateMissingOrder !== undefined) {
            var data = updateMissingOrder;
            if (Number(qty) === 0  || data.expire_dates.length > 0 ) {
                let totalExpValue = data.expire_dates?.reduce((total, obj) => { return total + obj.qty }, 0)
                if (Number(data.missingorderitems.missingQty) >= Number(totalExpValue)) {
                    if ((reason == null && qty < data.missingorderitems.missingQty)) {
                        toast.error('Please fill up the mandatory fields!')
                    } else if (typeof qty === 'string' && qty?.trim() === '') {
                        toast.error('Please fill quantity field')
                    } else {
                        if (qty <= data.missingorderitems.missingQty) {
                            orderItemsHandle('missing', qty, data, input, props)
                        } else {
                            toast.error('Check your values...!')
                        }
                    }
                } else {
                    toast.error('Please check correct quantity values')
                }
            } else {
                toast.error('Please check expire dates')
            }
            props.getMissingOrderData(props)
            reload()
        }
    }

    const getDerliveryCrewData = ({ deliveryCrewData }) => {
        let activeCrew = deliveryCrewData.filter(ele => (ele.deliverycrew.status === true))
        let data = activeCrew.map(ele => (ele.deliverycrew))
        return data
    }

    const columns = [
        { Header: "Order Id", accessor: "orders.orderId", ...GridFilter },
        { Header: "Cust. Name", accessor: "orders.name", ...GridFilter },
        { Header: "Region", accessor: "regions.name", ...GridFilter },
        {
            Header: "Etd", accessor: "orders.etd", ...GridFilter,
            Cell: prop => <>
                {DateNumberFormat(prop.value)}
            </>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        { Header: "Created User", accessor: "users.slug", ...GridFilter },
        {
            Header: "Completed",
            id: "isCompleted",
            accessor: data => data.missingorder.isCompleted === false ? "No" : "Yes",
            filterable: true,
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else if (filter.value === "Yes") {
                    return row[filter.id] === "Yes";
                } else if (filter.value === "No") {
                    return row[filter.id] === "No";
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Created Date", accessor: "missingorder.createdAt", ...GridFilter,
            Cell: prop => <>
                {DateNumberFormat(prop.value)}
            </>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Completed Date", accessor: "missingorder.completedAt", ...GridFilter,
            Cell: prop => <>
                {DateNumberFormat(prop.value)}
            </>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "",
            id: 'missingorder.isCompleted',
            Cell: row => <>
                <button className='btn btn-link' data-target={row.original.deliveryQtyStatus === true ? "#completePopup" : "#newCompletePopup"} data-toggle="modal"
                    disabled={row.original.missingorder.isCompleted || row.original.status === true || row.original.expireDateStatus === true}
                    onClick={() => {
                        // console.log(row)
                        clearField()
                        axios.defaults.headers.common['authorization'] = props.token;
                        axios.post('/missingOrder/getMissingOrderItemsById', { id: row.original.missingorder._id })
                            .then(result => {
                                const uniqueValues = [...new Set(result.data.map(o => o.shelflives.state))]
                                setShelfLife(uniqueValues)
                            }).catch(err => {
                                console.error(err)
                                toast.error(`${err}`)
                            })
                        setOrderData({ ...orderData, orderId: row.original.missingorder._id })
                        // props.deliveryInspect({ riderName: '', orderId: row.original.orders._id }, {}, props)
                    }}>
                    Complete
                </button>
            </>
        },
        {
            Header: "Action",
            maxWidth: 130,
            minWidth: 130,
            Cell: props => (
                <div style={{ textAlign: "right" }}>
                    <button id={`invoice-${props.original.missingorder._id}`}
                        data-toggle="modal"
                        className="btn btn-link"
                        data-target="#invoicePopup"
                        disabled={!props.original.missingorder.isCompleted}
                        onClick={() => {
                            setPrintOrder();
                            setPrintOrderItems([]);
                            // setPrintOrderItemsData([]);
                            let newDate = new Date();
                            let date = newDate.getDate() + "-" + (Number.parseInt(newDate.getMonth()) + 1) + "-" + newDate.getFullYear();
                            setDate(date);
                            setPrintOrder(props.original.orders);
                            setPrintOrderItems(props.original.orders.orderItems);
                        }}
                    >
                        Invoice
                    </button>
                </div>
            )
        },
        // {
        //     Header: "",
        //     maxWidth: 110,
        //     Cell: row =>
        //         <>
        //             <button className='btn btn-link'
        //             // data-toggle="modal" data-target='#viewImagesPopup' 
        //             // onClick={() => { props.deleteDeliveryWarehouseUser({ _id: row?.original?.deliverywarehouse?._id, user_id: row?.original?.users?._id }, props); reloadUserData() }}
        //             >
        //                 <i className="fas fa-sync"></i>
        //             </button>
        //         </>,
        //     getProps: (state, rowInfo, column) => {
        //         return {
        //             style: {
        //                 textAlign: "center"
        //             },
        //         };
        //     },
        // },
    ]

    const subColumns = [
        { Header: "Item", accessor: "items.name", ...GridFilter },
        { Header: "Supplier", accessor: "supplier.name", ...GridFilter },
        {
            Header: "Cust. Quantity", accessor: "missingorderitems.orderQty",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "right"
                    },
                };
            },
        },
        {
            Header: "Missing Quantity", accessor: "missingorderitems.currentQty",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "right"
                    },
                };
            },
        },
        {
            Header: "Delivered Quantity",
            accessor: "missingorderitems.qty",
            Cell: row => {
                return <>
                    <button data-toggle="modal" data-target='#updateMissingOrderQty' className='btn btn-link  float-right pull-right'
                        disabled={isActive ? row.original.missingorderitems.isCompleted : null}
                        onClick={() => {
                            // console.log(row.original)
                            setQty(row.original.missingorderitems.currentQty)
                            setCurrentQty(row.original.missingorderitems.currentQty)
                            setUpdateMissingOrder(row.original)
                            setInput(row.original)
                        }}
                    >{row.original.missingorderitems.qty || 0}</button>
                </>
            },
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "right"
                    },
                };
            },
            // ...GridFilter
        },
        {
            Header: "Price",
            maxWidth: 300,
            accessor: "missingorderitems.price",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "isCompleted",
            maxWidth: 250,
            id: "completed",
            accessor: data => data.missingorderitems.isCompleted === false ? "No" : "Yes",
            filterable: true,
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else if (filter.value === "Yes") {
                    return row[filter.id] === "Yes";
                } else if (filter.value === "No") {
                    return row[filter.id] === "No";
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        // {
        //     Header: "",
        //     maxWidth: 110,
        //     Cell: row =>
        //         <>
        //             <button className='btn btn-link'
        //             // data-toggle="modal" data-target='#viewImagesPopup' 
        //             // onClick={() => { props.deleteDeliveryWarehouseUser({ _id: row?.original?.deliverywarehouse?._id, user_id: row?.original?.users?._id }, props); reloadUserData() }}
        //             >
        //                 <i className="fas fa-sync"></i>
        //             </button>
        //         </>,
        //     getProps: (state, rowInfo, column) => {
        //         return {
        //             style: {
        //                 textAlign: "center"
        //             },
        //         };
        //     },
        // }
    ]

    const summaryColumn = [
        { Header: "Item", accessor: "_id", ...GridFilter },
        {
            Header: "Missing Quantity",
            accessor: "MissingTotalQty",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
    ]

    const assignTheDefaultQtyValue = (items, missing) => {
        items.sort((a, b) => new Date(a.exp_date) - new Date(b.exp_date));

        let remainingQty = missing;

        // Step 3-5: Iterate over the items and assign qty values
        for (let i = 0; i < items.length; i++) {
            let item = items[i];

            if (remainingQty > item.totalQty) {
                item.qty = item.totalQty;
                remainingQty -= item.totalQty;
            } else {
                item.qty = remainingQty;
                remainingQty = 0;
                break;
            }
        }
        return items
    }

    const resetMissingValue = (input, ele) => {
        if (Number(ele) <= input?.missingorderitems.missingQty) {
            let totalValue = Number(ele)
            const updatedExpireDates = input.expire_dates.map((value) => {
                return { ...value, qty: 0 };
            });
            let missingData = assignTheDefaultQtyValue(updatedExpireDates, Number(totalValue))
            setUpdateMissingOrder({ ...input, expire_dates: missingData });
        }
    }
    const increaseQty = (type, index, arr) => {
        if (type === 'Missing') {
            const updatedItems = [...arr.expire_dates];
            updatedItems[index].qty = ConvertDecimalPlaces(Math.min(
                updatedItems[index].qty + 1, updatedItems[index].totalQty)
            );
            setUpdateMissingOrder({ ...arr, expire_dates: updatedItems });
            const total = updatedItems.reduce((total, ele) => { return total + ele.qty }, 0)
            setQty(total)
        }

    };

    const decreaseQty = (type, index, arr) => {
        if (type === 'Missing') {
            const updatedItems = [...arr.expire_dates];
            updatedItems[index].qty = ConvertDecimalPlaces(Math.max(updatedItems[index].qty - 1, 0));
            setUpdateMissingOrder({ ...arr, expire_dates: updatedItems });
            const total = updatedItems.reduce((total, ele) => { return total + ele.qty }, 0)
            setQty(total)
        }
    };

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <input type='radio' name='history' style={{ marginRight: '5px' }} checked={isActive ? false : true} onChange={e => { setActive(false) }} /><label>Active</label>
                    <input type='radio' name='history' style={{ marginLeft: '30px', marginRight: '5px' }} onChange={e => { setActive(true); reload() }} /><label>Complete</label>
                    <div style={{ float: 'right' }}>
                        <button className="btn btn-primary"
                            disabled={props.isLoading}
                            onClick={() => reload()}
                        >
                            <i className="fas fa-sync"></i>
                        </button>
                        <button className="btn btn-primary"
                            data-toggle="modal" data-target='#summaryModal'
                            onClick={() => getMissingItemsSummary()}
                        >
                            SUMMARY
                        </button>
                    </div>
                </div>
                <div>
                    {isActive ?
                        <div>
                            <FormProvider form="inline">
                                {formProps => {
                                    return (
                                        <Table className="-striped"
                                            columns={columns}
                                            data={props.missingCompleteOrderData}
                                            defaultPageSize={10}
                                            getTrProps={(state, rowInfo) => {
                                                return {
                                                    style: {
                                                        fontSize: '14px'
                                                    }
                                                }
                                            }}
                                            expanded={expanded}
                                            onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                            SubComponent={row => {
                                                setExpandMissingorderId(row.original.missingorder._id)
                                                return (
                                                    <React.Fragment>
                                                        <ErrorBoundary>
                                                            <FormProvider form="inline">
                                                                {formProps => {
                                                                    return (
                                                                        <ReactTableFixedColumns
                                                                            columns={subColumns}
                                                                            data={missingOrderItems}
                                                                            getTrProps={(state, rowInfo) => {
                                                                                return {
                                                                                    style: {
                                                                                        fontSize: '14px',
                                                                                    }
                                                                                }
                                                                            }}
                                                                            defaultPageSize={10} />
                                                                    )
                                                                }}
                                                            </FormProvider>
                                                        </ErrorBoundary>
                                                    </React.Fragment>
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </FormProvider>
                        </div>
                        : <div>
                            <FormProvider form="inline">
                                {formProps => {
                                    return (
                                        <Table className="-striped"
                                            columns={columns}
                                            data={props.missingOrderData}
                                            defaultPageSize={10}
                                            getTrProps={(state, rowInfo) => {
                                                return {
                                                    style: {
                                                        fontSize: '14px'
                                                    }
                                                }
                                            }}
                                            expanded={expanded}
                                            onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                            SubComponent={row => {
                                                setExpandMissingorderId(row.original.missingorder._id)
                                                return (
                                                    <React.Fragment>
                                                        <ErrorBoundary>
                                                            <FormProvider form="inline">
                                                                {formProps => {
                                                                    return (
                                                                        <ReactTableFixedColumns
                                                                            columns={subColumns}
                                                                            data={missingOrderItems}
                                                                            getTrProps={(state, rowInfo) => {
                                                                                return {
                                                                                    style: {
                                                                                        fontSize: '14px',
                                                                                    }
                                                                                }
                                                                            }}
                                                                            defaultPageSize={10} />
                                                                    )
                                                                }}
                                                            </FormProvider>
                                                        </ErrorBoundary>
                                                    </React.Fragment>
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </FormProvider>
                        </div>
                    }
                    <ErrorBoundary>
                        <div id='completePopup' className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Complete Missing Order</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <table>
                                            <tbody>
                                                {shelfLife.map(obj => {
                                                    return (
                                                        <tr>
                                                            <td>{obj} :</td>
                                                            <td>
                                                                {[1, 2, 3, 4, 5].map(ele => {
                                                                    return (
                                                                        <button id={`${obj} + ${ele}`} key={ele} className={'btn btn-primary'} style={{ width: "50px", height: "50px" }}
                                                                            onClick={() => {
                                                                                const newBags = [...bagData];
                                                                                newBags.push({ state: obj, qty: ele })
                                                                                newBags.map(o => {
                                                                                    if ((o.state === obj) && (o.qty !== ele)) {
                                                                                        document.getElementById(`${o.state} + ${o.qty}`).disabled = false;
                                                                                        document.getElementById(`${o.state} + ${o.qty}`).classList.add('btn-primary');
                                                                                        document.getElementById(`${o.state} + ${o.qty}`).classList.remove('btn-secondary');
                                                                                        newBags.splice(newBags.findIndex(v => v.state === o.state), 1)
                                                                                    }
                                                                                })
                                                                                setBagData(newBags)
                                                                                document.getElementById(`${obj} + ${ele}`).disabled = true;
                                                                                document.getElementById(`${obj} + ${ele}`).classList.add('btn-secondary');
                                                                            }}>
                                                                            {ele}
                                                                        </button>
                                                                    )
                                                                })}

                                                                <button className={'btn btn-danger'} id={`${obj}`} key={obj} style={{ width: "70px", height: "50px" }} onClick={() => {
                                                                    bagData.map(o => {
                                                                        if (o.state === obj) {
                                                                            document.getElementById(`${o.state} + ${o.qty}`).disabled = false;
                                                                            document.getElementById(`${o.state} + ${o.qty}`).classList.add('btn-primary');
                                                                            document.getElementById(`${o.state} + ${o.qty}`).classList.remove('btn-secondary');
                                                                            bagData.splice(bagData.findIndex(v => v.state === o.state), 1)
                                                                        }
                                                                    })
                                                                }}>Clear</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <td>Bag Names:</td>
                                                <td><input type="text" className="form-control" value={bagNames} autoComplete={false} onChange={(e) => setBagNames(e.target.value)} /></td>
                                                <tr>
                                                    <td>Rider Name:</td>
                                                    <td>
                                                        <SearchableSelectComp options={getDerliveryCrewData(props)} label={'name'} value={orderData.rider._id} onChange={e => setOrderData({ ...orderData, rider: e })} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='modal-footer'>
                                        <button data-dismiss="modal" type="button" className="btn btn-primary" disabled={disableCompleted}
                                            onClick={() => {
                                                if (orderData?.rider !== '') {
                                                    props.deliveryInspect({ ...orderData, riderName: orderData?.rider?._id, type: 'missed', user: props.userId, shelfLife: bagData.length === 0 ? '' : bagData, bagNames: bagNames === '' ? '' : bagNames }, setOrderData, {}, props)
                                                    bagData.map(o => {
                                                        document.getElementById(`${o.state} + ${o.qty}`).disabled = false;
                                                        document.getElementById(`${o.state} + ${o.qty}`).classList.add('btn-primary');
                                                        document.getElementById(`${o.state} + ${o.qty}`).classList.remove('btn-secondary');
                                                    })
                                                    setBagData([])
                                                    setBagNames('')
                                                    setDisableCompleted(true)
                                                } else
                                                    toast.warn('Please select a rider.....!')
                                            }}
                                        >
                                            Complete
                                        </button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                                            bagData.map(o => {
                                                document.getElementById(`${o.state} + ${o.qty}`).disabled = false;
                                                document.getElementById(`${o.state} + ${o.qty}`).classList.add('btn-primary');
                                                document.getElementById(`${o.state} + ${o.qty}`).classList.remove('btn-secondary');
                                            })
                                            setBagData([])
                                            setBagNames('')
                                        }}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <div id='newCompletePopup' className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Complete Missing Order</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <table>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='modal-footer'>
                                        <button data-dismiss="modal" type="button" className="btn btn-primary"
                                            onClick={() => {
                                                props.deliveryInspect({ ...orderData, type: 'missed', user: props.userId }, setOrderData, {}, props)
                                                setBagData([])
                                                setBagNames('')
                                            }}
                                        >
                                            Yes
                                        </button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                                            setBagData([])
                                            setBagNames('')
                                        }}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <div id="updateMissingOrderQty" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Update Delivery Qty</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <form id='updateOrderQtyForm'>
                                                    <table>
                                                        <tbody>

                                                            <tr>
                                                                <td>Quantity* :</td>
                                                                <td><input type='number' id='missing_order_qty' className="form-control" min={0} value={qty} onChange={e => {
                                                                    setQty(e.target.value)
                                                                    resetMissingValue(input, Number(e.target.value))
                                                                }
                                                                } /></td>
                                                            </tr>
                                                            {/* {updateMissingOrder?.missingorderitems?.missingQty < qty ?  */}
                                                            <tr>
                                                                <td>Reason* :</td>
                                                                <td>
                                                                    <select className="form-control" disabled={updateMissingOrder?.missingorderitems?.missingQty <= qty}
                                                                        onBlur={e => e.target.value === '' ? null : setReason(e.target.value)}
                                                                    >
                                                                        <option value={''}>- Select Reason -</option>
                                                                        {reasonData.map(element => {
                                                                            return <option key={element._id} value={element._id}>{element.description}</option>
                                                                        })}
                                                                    </select>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </form>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                        {updateMissingOrder?.missingorderitems?.missingQty !== undefined && updateMissingOrder?.missingorderitems?.missingQty !== '' && updateMissingOrder?.missingorderitems?.missingQty !== null && input.missingQty !== 0 ?
                                            (Number(updateMissingOrder?.missingorderitems?.missingQty) >= Number(qty) ?
                                                (updateMissingOrder.expire_dates !== undefined && updateMissingOrder.expire_dates.length > 0 ?
                                                    <table style={{ width: '100%' }} className='table table-striped'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Expire Date</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Available Qty</th>
                                                                <th style={{ paddingLeft: '60px' }} scope='col'>Quantity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {updateMissingOrder.expire_dates.map((item, index) => (
                                                                <>
                                                                    <tr key={item.id}>
                                                                        <td style={{ paddingLeft: '60px' }} scope='col'>{item.exp_date}</td>
                                                                        <td style={{ paddingLeft: '60px' }} scope='col'>{item.totalQty}</td>
                                                                        <td style={{ paddingLeft: '60px', display: 'flex', display: 'flex', alignItems: 'center', justifyContent: 'center' }} scope='col'>
                                                                            <button
                                                                                className='btn btn-sm  btn-primary'
                                                                                style={{ marginRight: '4px', padding: '8px 12px', fontSize: '18px', cursor: 'pointer' }}
                                                                                onClick={() => decreaseQty("Missing", index, input)}
                                                                            >
                                                                                -
                                                                            </button>
                                                                            <span style={{ fontSize: '18px', fontWeight: 'bold', margin: '0 4px' }}>{item.qty}</span>
                                                                            <button
                                                                                className='btn btn-sm  btn-primary'
                                                                                style={{ marginLeft: '4px', padding: '8px 12px', fontSize: '18px', cursor: 'pointer' }}
                                                                                onClick={() => increaseQty("Missing", index, input)}
                                                                            >
                                                                                +
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    : "")
                                                : "")
                                            : ''}
                                    </div>
                                    <div className="modal-footer">
                                        <button id="update_order_qty" data-dismiss="modal" type="button" className="btn btn-primary" disabled={updateMissingOrder?.missingorderitems?.missingQty < qty} onClick={() => { updateMissingOrderQty(); reload(); }}>Update</button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { setReason('') }}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <div id="invoicePopup" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content animate">
                                    <div className="modal-header">
                                        <button disabled={isPrintLoading} className='btn btn-secondary'
                                            onClick={() => {
                                                var DocumentContainer = document.getElementById(
                                                    "printSection"
                                                );
                                                var WindowObject = window.open(
                                                    "",
                                                    "PrintWindow",
                                                    "toolbars=no,scrollbars=yes,status=no,resizable=yes"
                                                );
                                                WindowObject.document.writeln(
                                                    DocumentContainer.innerHTML
                                                );
                                                WindowObject.document.close();
                                                setTimeout(function () {
                                                    WindowObject.focus();
                                                    WindowObject.print();
                                                    WindowObject.close();
                                                }, 1000);
                                            }}
                                        >
                                            PRINT
                                        </button>

                                        <ReactToPrint
                                            content={() => componentRef.current}
                                        />
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <InvoicePrint paymentMethods={props.paymentMethods} date={date} orderItemsData={printOrderItemsData} order={printOrder} ref={componentRef} />
                                    </div>

                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <div id="summaryModal" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">SUMMARY</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <FormProvider form="inline">
                                                    {formProps => {
                                                        return (
                                                            <ReactTableFixedColumns
                                                                columns={summaryColumn}
                                                                data={summaryData}
                                                                getTrProps={(state, rowInfo) => {
                                                                    return {
                                                                        style: {
                                                                            fontSize: '14px',
                                                                        }
                                                                    }
                                                                }}
                                                                defaultPageSize={10} />
                                                        )
                                                    }}
                                                </FormProvider>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userId: state.rLogin.id,
        loggedUser: state.rLogin.name,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        paymentMethods: state.rBasicData.payment_method_data,
        missingOrderData: state.rMissingOrders.missingorders,
        missingCompleteOrderData: state.rMissingOrders.missingCompleteOrders,
        deliveryCrewData: state.rDeliveryBoys.data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMissingOrderData: props => { dispatch(getMissingOrderData(props)) },
        getMissingCompleteOrder: props => { dispatch(getMissingCompleteOrder(props)) },
        updateMissingOrderQty: (data, reason, { getMissingOrderItemsById, expandMissingorderId, setMissingOrderItems }, token) => { dispatch(updateMissingOrderQty(data, reason, { getMissingOrderItemsById, expandMissingorderId, setMissingOrderItems }, token)) },
        getPaymentMethodData: token => { dispatch(getPaymentMethodData(token)) },
        getDeliveryCrew: props => { dispatch(getDeliveryCrew(props)) },
        deliveryInspect: (data, fn, obj, props) => { dispatch(deliveryInspect(data, fn, obj, props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MissingOrder);


