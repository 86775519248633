/**
 * @file This file defines to delivery warehouse actions
 * @author Kavindu Shehan
 */

import { toast } from 'react-toastify';
var axios = require('axios');

export const deliveryWarehouseAsync = data => {
    return { type: 'FETCH_DELIVERY_WAREHOUSE_DATA', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const deliveryWarehouseAccountUserAsync = data => {
    return { type: 'FETCH_DELIVERY_WAREHOUSE_ACCOUNT_USER_DATA', data }
}

export const deliveryWarehouseCurrentUserAsync = data => {
    return { type: 'FETCH_DELIVERY_WAREHOUSE_CURRENT_USER_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
export const getDeliveryWarehouses = ({ token }) => {
    isLoadingAsync(true)
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/deliverywarehouse/getDeliveryWarehouses')
            .then(result => {
                dispatch(deliveryWarehouseAsync(result.data))
                isLoadingAsync(false)
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getDeliveryWarehousesForCurrentUser = ({ token, userId }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverywarehouse/getDeliveryWarehousesForCurrentUser', { userId })
            .then(result => {
                dispatch(deliveryWarehouseCurrentUserAsync(result.data))
                isLoadingAsync(false)
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addDeliveryWarehouse = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverywarehouse/addDeliveryWarehouse', { data })
            .then(result => {
                toast.success('Added Warehouse...!')
                dispatch(getDeliveryWarehouses({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateDeliveryWarehouse = (data, { token, userId }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverywarehouse/updateDeliveryWarehouse', { data })
            .then(result => {
                toast.success('Successfully Updated Delivery Warehouse')
                dispatch(getDeliveryWarehouses({ token }))
                dispatch(getDeliveryWarehousesForCurrentUser({token:token,userId:userId}))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteDeliveryWarehouse = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverywarehouse/deleteDeliveryWarehouse', { data })
            .then(result => {
                toast.success('Delivery Warehouse Deleted Successfully!')
                dispatch(getDeliveryWarehouses({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addDeliveryWarehouseUser = (data, { token, userId }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverywarehouse/addDeliveryWarehouseUser', { data })
            .then(result => {
                toast.success('Successfully Added User')
                dispatch(getDeliveryWarehouses({ token }))
                dispatch(getDeliveryWarehousesForCurrentUser({ token: token, userId: userId }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const deleteDeliveryWarehouseUser = (data, { token, userId }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverywarehouse/deleteDeliveryWarehouseUser', { data })
            .then(result => {
                toast.success('Delivery Warehouse User Deleted Successfully!')
                dispatch(getDeliveryWarehouses({ token }))
                dispatch(getDeliveryWarehousesForCurrentUser({ token: token, userId: userId }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addDeliveryWarehouseAccount = (data, { token, userId }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverywarehouse/addDeliveryWarehouseAccount', { data })
            .then(result => {
                toast.success('Successfully Added Account')
                dispatch(getDeliveryWarehouses({ token }))
                dispatch(getDeliveryWarehousesForCurrentUser({ token: token, userId: userId }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const deleteDeliveryWarehouseAccount = (data, { token, userId }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverywarehouse/deleteDeliveryWarehouseAccount', { data })
            .then(result => {
                toast.success('Delivery Warehouse Account Deleted Successfully!')
                dispatch(getDeliveryWarehouses({ token }))
                dispatch(getDeliveryWarehousesForCurrentUser({ token: token, userId: userId }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}
