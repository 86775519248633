import React, { useEffect } from 'react'
import Select from 'react-select';
import { toast } from 'react-toastify';
import _ from 'lodash'
import ErrorBoundary from '../../../../components/ErrorBoundary'

const addAccountHandle = ({ accounts, type }, accountIds, currentWarehouseId, { setAddAccount, setViewHandler }, reloadData, props) => {

    var data = {
        _id: currentWarehouseId,
        account: accounts,
        type: type,
    };

    var checkId = _.find(accountIds, function (o) { return o === accounts })

    if (data.accounts != ''&& data.type != '') {
        if ((!checkId) || (checkId == [])) {
            props.addDeliveryWarehouseAccount(data, props)

            reloadData()
            removeHandler(setAddAccount, setViewHandler)
            document.getElementById("addAccountForm").reset();
        } else {
            toast.error('Account already in another warehouse', { autoClose: 2000 });
        }
    } else {
        toast.error('Please fill all mandatory fields!');
    }
}

const removeHandler = (setAddAccount, setViewHandler) => {
    let btnSaveEle = document.getElementById('save_account')
    setAddAccount({
        accounts: '',
        type:''
    })
    setViewHandler({ view: true, add: false, update: false })

    btnSaveEle.disabled = false
}

const AddAccount = ({ accounts, currentWarehouseId, setViewHandler, addAccount, setAddAccount, isClose, setIsClose, props, accountIds, reloadData }) => {

    const accountTypes = [{ value: 'Income Cash', label: 'Income Cash' }, { value: 'Income Credit', label: 'Income Credit' }, { value: 'Normal', label: 'Normal' }]

    useEffect(() => {
        if (isClose) {
            removeHandler(setAddAccount, setViewHandler)
            setIsClose(false)
        }
    }, [isClose])

    return (
        <ErrorBoundary>
            <React.Fragment>
                <form id='addAccountForm'>
                    <table style={{ width: '40%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '20%', fontSize: "14px" }}>Account* :</td>
                                <td style={{ width: '100%' }}>
                                    <div>
                                        <Select
                                            options={accounts}
                                            value={accounts.filter((option) => { return option.value === addAccount.accounts })}
                                            theme={theme => ({ ...theme, borderRadius: 0 })}
                                            onChange={(e) => setAddAccount({ ...addAccount, accounts: e._id })}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '20%', fontSize: "14px" }}>Type* :</td>
                                <td style={{ width: '100%' }}>
                                    <div>
                                        <Select
                                            options={accountTypes}
                                            value={addAccount.type}
                                            theme={theme => ({ ...theme, borderRadius: 0 })}
                                            onChange={(e) => setAddAccount({ ...addAccount, type: e })}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <div style={{ float: 'right', paddingTop: '10px' }}>
                                        <button id='save_account' type="button" className="btn btn-primary" onClick={() => addAccountHandle(addAccount, accountIds, currentWarehouseId, { setAddAccount, setViewHandler }, reloadData, props)} style={{ marginLeft: "50px" }}>Save</button>
                                        <button type="button" className="btn btn-info" onClick={e => removeHandler(setAddAccount, setViewHandler)}>Close</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </React.Fragment>
        </ErrorBoundary>
    )
}

export default AddAccount
