/**
 * @file This file defines to login action dispatch
 * @author Bhanuka Chathuranga
 */
import $ from 'jquery';
import { getProgrammeData } from './Administrator/permission_action';
var axios = require('axios');

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const loginAsync = data => {
    return { type: 'LOG_IN', data }
}


export const loginOutAsync = data => {
    return { type: 'LOG_OUT', data }
}

/**
 * validate details 
 * 
 * @param {string} email 
 * @param {string} password 
 */
export const login = (email, password) => {

    return dispatch => {
        axios.post('/login/userValidation', { email, password },
            {
                // headers: {
                //     'CSRF-Token': document.cookie
                // }
            }
        )
            .then(res => {
                $('#error').hide()
                dispatch(getProgrammeData({ token: res.headers.authorization }))
                dispatch(loginAsync({ ...res.data, token: res.headers.authorization }));
            })
            .catch(error => {
                if (error.response !== undefined) {
                    document.getElementById('error').innerText = error.response.data;
                    document.getElementById('email').focus()
                    $('#error').show();
                } else {
                    document.getElementById('error').innerText = error.data;
                }
            })
    }
}

export default {
    isLoadingAsync
}