/**
 * @file This file defined for customer order controller
 * @author Umesh Ranthilina
 */
import React from 'react';
import { Route } from 'react-router-dom';
import CustomerInvoice from '../container/views/CustomerInvoice/CustomerInvoice';

export const CustomerInvoiceController = props => {

    return (
        <>
            <Route path="/onlineinvoice/:id" exact component={CustomerInvoice} />
        </>
    )
}

export default CustomerInvoiceController
