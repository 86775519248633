/**
 * @file This file defined for order reconcilation
 * @author Bhanuka Chathuranga
 */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';

import { reloadData } from '../../../actions/Ordering/ordering_order_action'
import { getDeliveryCrew } from '../../../actions/Delivery/delivery_action';
import ErrorBoundary from '../../components/ErrorBoundary';
import SkeletonLoadingSection from '../../components/skeleton/SkeletonLoadingSection';
import SearchableSelectComp from '../../components/SearchableSelectComp';
import { getAccountsData } from '../../../actions/Acc/account_actions';
import accountClasify from './Modal/Func';
import { PriceFormat } from '../../utils';
// import { Permission } from '../../utils';

/**
 * 
 * @param {Object} obj data
 * @param {Array} reconcilationData reconcilation data
 * @param {Function} setReconcilationData set reconcilation data
 */
const _searchHandler = (obj, reconcilationData, setReconcilationData) => {
    // let chackVal = _.filter(reconcilationData, o => o.orders._id === obj.orders._id)
    let chackVal = reconcilationData.filter(o => o.orders._id === obj.orders._id)
    if (chackVal.length > 0) {
        toast.info('already added...!')
    }
    let val = [...reconcilationData, obj]
    let uniqArr = _.uniqBy(val, o => o.orders._id)
    // setReconcilationData(rData => [...rData, obj])
    setReconcilationData(uniqArr)
}

/**
 * remove orders from reconcilation list
 * 
 * @param {Object} obj order
 * @param {Array} reconcilationData add data to deliverd
 * @param {Function} setReconcilationData change to state
 */
const trashBinHandler = (obj, reconcilationData, setReconcilationData) => {
    setReconcilationData([])
    let array = [...reconcilationData];
    let val = _.remove(array, n => obj.orders.orderId === n.orders.orderId)
    setReconcilationData(array)
}

/**
 * 
 * @param {Object} data reconcilation data, payment change data and deliver rider data
 * @param {Function} setReconcilationData change reconcilation state data
 * @param {Object} props 
 */
const updateReconciliation = (data, { setReconcilationData, setSearch, setIsLoading }, { getOrderingData, token }) => {
    //console.log(data);
    let { reconcilationData, paymentChange, accountDetails } = data
    if (reconcilationData.length === 0) {
        var val = false;
        toast.info('Please add data to reconcilation...!')
    } else if (paymentChange.id === 'none') {
        var val = window.confirm('Payment Option is not selected.\nContinue the process?')
    } else if (paymentChange.id === 'none') {
        var val = window.confirm('Payment Option is not selected.\nContinue the process?')
    } else if (accountDetails?._id === undefined) {
        var val = window.alert('Account is not selectded.\nPlease select a account')
    } else {
        var val = true
    }


    if (val) {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/updateReconciliation', { data: data })
            .then(result => {
                // console.log(result);
                setSearch('')
                getOrderingData({ token })
                setReconcilationData([])
                toast.success('Updated successfully...!')
                setIsLoading(false)
            }).catch(err => {
                console.error(err);
                toast.error('Something went wrong...!')
                setIsLoading(false)
            })
    } else {
        setIsLoading(false)
    }
}

/**
 * 
 * @param {Object} data reconcilations data and payment change data
 * @param {Function} setReconcilationData change reconcilation state data
 * @param {Object} props 
 */
const updatePaymentChange = (data, { setReconcilationData, setIsLoading }, { getOrderingData, token }) => {
    let { reconcilationData, paymentChange } = data
    if (reconcilationData.length === 0) {
        toast.info('Please add data to reconcilation...!')
        setIsLoading(false)
    } else if (paymentChange.id === 'none') {
        toast.error('Please change the payment method')
        setIsLoading(false)
    } else {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/updatePaymentMethod', { data: data })
            .then(result => {
                // console.log(result);
                getOrderingData({ token })
                setReconcilationData([])
                toast.success('Updated successfully...!')
                setIsLoading(false)
            }).catch(err => {
                console.error(err);
                getOrderingData({ token })
                toast.error('Something went wrong...!')
                setIsLoading(false)
            })
    }
}

const changeDataHandler = (action, { reconcilationData, paymentChange, accountDetails }, { setReconcilationData, setSearch, setIsLoading }, props) => {
    let { name, userId } = props
    setIsLoading(true)
    if (action === 'clear') { setReconcilationData([]); setIsLoading(false) }
    if (action === 'reconcilation') updateReconciliation({ reconcilationData, paymentChange, name, userId, accountDetails }, { setReconcilationData, setSearch, setIsLoading }, props)
    if (action === 'payment_change') updatePaymentChange({ reconcilationData, paymentChange }, { setReconcilationData, setIsLoading }, props)
}

const OrderReconciliation = props => {

    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [reconcilationData, setReconcilationData] = useState([]);
    const [paymentChange, setPaymentChange] = useState({ id: 'none', value: false })
    const [accountDetails, setAccountDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        props.getOrderingData(props)
        props.getDeliveryCrew(props)
        props.getAccountsData(props)
        setData(props.data)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        let val = props.data.filter(obj => {
            if (obj.orders.orderId.includes(search) && (Number(obj.orders.totalPrice) - Number(obj.orders.amountPaid)) !== 0) {
                return obj
            }
        })
        setData(val)
    }, [search])

    // const columns = [
    //     { Header: "Name", accessor: "orders.name" },

    // ]

    return (
        <div className="right_col" role="main">
            <div className='card' >
                {/* card header */}
                <div className='card-header' style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', padding: '5px', border: '1px solid gray', borderRadius: '5px' }}>
                        <fieldset id='payment_group' style={{ display: 'flex' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '15vw' }}>
                                    <div style={{ display: 'flex' }}>
                                        <input id='cash_on_delivery' name='payment_group' type='radio' onClick={() => setPaymentChange({ id: 'cash-on-delivery' })} />
                                        <label for="cash_on_delivery" style={{ paddingLeft: '10px' }}>Cash On Delivery</label>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <input id='credit_card_on_delivery' name='payment_group' type='radio' onClick={() => setPaymentChange({ id: 'credit' })} />
                                        <label for="credit_card_on_delivery" style={{ paddingLeft: '10px' }}>Credit Card On Delivery</label>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '10vw' }}>
                                    <div style={{ display: 'flex' }}>
                                        <input id='bank_transfer' name='payment_group' type='radio' onClick={() => setPaymentChange({ id: 'bank-transfer' })} />
                                        <label for="bank_transfer" style={{ paddingLeft: '10px' }}>Bank Transfer</label>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <input id='none' name='payment_group' type='radio' onClick={() => setPaymentChange({ id: 'none' })} defaultChecked={paymentChange.id === 'none' ? true : false} />
                                        <label for="none" style={{ paddingLeft: '10px' }}>None</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div style={{ display: 'flex', width: '50%', padding: '1vw', zIndex: 10 }}>
                        <SearchableSelectComp options={accountClasify(props.accounts, ['Cash', 'Payable'])} value={accountDetails._id} label='acc_name' onChange={e => setAccountDetails(e)} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
                        <div>
                            <button id='btnReconcilation' className='btn btn-primary'
                                // data-toggle="modal" data-target="#reconcilation"
                                disabled={props.isLoading || isLoading}
                                onClick={() => changeDataHandler('reconcilation', { reconcilationData, paymentChange, accountDetails }, { setReconcilationData, setSearch, setIsLoading }, props)}
                            >{props.isLoading || isLoading ? <><i class="fa fa-spinner fa-pulse"></i> Loading...</> : <>Reconcilation</>}</button>
                            <button id='btnClear' className='btn btn-primary' onClick={() => changeDataHandler('clear', reconcilationData, { setReconcilationData, setSearch, setIsLoading }, props)}>Clear</button>
                            <button id='btnPaymentChange' className='btn btn-primary'
                                disabled={props.isLoading || isLoading}
                                onClick={() => changeDataHandler('payment_change', { reconcilationData, paymentChange }, { setReconcilationData, setSearch, setIsLoading }, props)}
                            >{props.isLoading || isLoading ? <><i class="fa fa-spinner fa-pulse"></i> Loading...</> : <>Payment Change</>}
                            </button>
                        </div>
                    </div>
                </div>
                {/* card header */}
            </div>

            <div className='card' style={{ flexDirection: 'row', height: '83vh' }}>
                <div id='search_data' className='content-header' style={{ height: '99%' }}>
                    <input id='search' type="text" className='form-control' placeholder='Search... ' value={search}
                        onChange={e => setSearch(e.target.value)}
                        onKeyDown={e => { if (e.key === 'Enter' && data.length === 1) { _searchHandler(data[0], reconcilationData, setReconcilationData); setSearch('') } }} />
                    {props.isLoading ?
                        <>
                            <SkeletonLoadingSection dataCount={Math.round(document.querySelector('#search_data').offsetHeight / 60) - 1} />
                        </>
                        : <div>
                            {(search === '' ? props.data : data)?.map(obj => (Number(obj.orders.totalPrice) - Number(obj.orders.amountPaid)) !== 0 ?
                                <div
                                    style={{
                                        display: 'inline-block',
                                        width: '100%',
                                        padding: '3px 5px',
                                        background: 'white',
                                        margin: '0px',
                                        color: 'black',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => _searchHandler(obj, reconcilationData, setReconcilationData)}
                                >
                                    <div>{obj.orders.orderId}</div>
                                    <div>{obj.orders.name}</div>
                                    <div>{obj.orders.status}</div>
                                </div>
                                : null)}
                        </div>}
                </div>

                <div className='content-body' style={{ height: '99%', display: 'inline-block', overflow: 'auto' }}>
                    {/* {reconcilationData.map(obj => )} */}
                    <div style={{ overflowY: 'auto' }}>
                        <table className='table-bordered' style={{ width: '100%' }}>
                            {reconcilationData.length > 0 ?
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='text-align'>OrderId</th>
                                        <th className='text-align'>Name</th>
                                        <th className='text-align' style={{ width: '16%' }}>Total Amount ({PriceFormat(_.sumBy(reconcilationData, o => o.orders.totalPrice))})</th>
                                        <th className='text-align'>Amount Paid ({PriceFormat(_.sumBy(reconcilationData, o => o.orders.amountPaid))})</th>
                                        <th className='text-align'>To Paid ({PriceFormat(_.sumBy(reconcilationData, o => Number(o.orders.totalPrice) - Number(o.orders.amountPaid)))})</th>
                                        <th className='text-align'>Payment Method</th>
                                    </tr>
                                </thead>
                                : null}
                            <tbody>
                                {reconcilationData.map(obj => <tr style={{ background: '#fff' }}
                                    onClick={() => {
                                        // let array = reconcilationData;
                                        // let val = _.remove(array, n => obj.orders.orderId === n.orders.orderId)
                                        // setReconcilationData(array)
                                    }}>
                                    <td><i className="fas fa-trash" onClick={() => trashBinHandler(obj, reconcilationData, setReconcilationData)}></i></td>
                                    <td>{obj.orders.orderId}</td>
                                    <td>{obj.orders.name}</td>
                                    <td className='number-cell-format'>{PriceFormat(obj.orders.totalPrice)}</td>
                                    <td className='number-cell-format'>{PriceFormat(obj.orders.amountPaid)}</td>
                                    <td className='number-cell-format'>{PriceFormat(Number(obj.orders.totalPrice) - Number(obj.orders.amountPaid))}</td>
                                    <td className='text-align'>{obj.paymentmethods.name}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <ErrorBoundary>
                <div id='reconcilation' className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Reconcilation confoirmation</h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body"></div>
                            <div className="modal-footer">
                                <button id="add_order" type="button" className="btn btn-primary">confoirm reconcilation</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

        </div>
    )
}

const mapStateToProps = state => ({
    data: state.rOrdering.data,
    deliverCrewData: state.rDeliveryBoys.data,
    isLoading: state.rLogin.loading,
    name: state.rLogin.name,
    token: state.rLogin.token,
    accounts: state.rAccounts.accounts,
    userId: state.rLogin.id,
})

const mapDispatchToProps = dispatch => {
    return {
        getOrderingData: props => { dispatch(reloadData(props)) },
        getDeliveryCrew: props => { dispatch(getDeliveryCrew(props)) },
        getAccountsData: ({ token }) => dispatch(getAccountsData(token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderReconciliation)
