import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import Table from "react-table";
import { Field } from "redux-form";
import { toast } from 'react-toastify';
import axios from 'axios';

import FormProvider from "../../../../components/FormProvider";
import GridFilter from '../../../../components/GridFilter';
import editableComponent from '../../../../components/EditableComponent';
import ErrorBoundary from '../../../../components/ErrorBoundary'
import SearchableSelectComp from '../../../../components/SearchableSelectComp'
import { complaintTypeData, DateNumberFormat, Permission, PriceFormat, resolvedData } from '../../../../utils';
import ActionsCell from '../../../../components/ActionCell';
import UpdateActionsCell from '../../../../components/UpdateActionsCell';

import accountClasify from '../Func';
import * as BS from "react-bootstrap";
import HighlightCell from "../../../../components/HighlightCell";

const inputHandler = (e, type, addcomplaint, setAddcomplaint) => {
    if (type === 'imagePath') {
        setAddcomplaint({ ...addcomplaint, [type]: e.target.files })
    } else {
        setAddcomplaint({ ...addcomplaint, [type]: e.target.value })
    }
}

const resolveMethodFunc = (resolveMethod, props) => {
    let val = props.resolveMethod?.filter(ele => ele._id === resolveMethod)
    return val[0]?.slug
}

const addReturnOrderItem = (e, defectItems, orderItemsData, setDefectItems) => {
    let index = defectItems?.findIndex(obj => obj._id === e._id)
    if (index < 0) {
        let [obj, ...rest] = orderItemsData?.filter(ele => ele._id === e._id)
        let items = [...defectItems, obj]
        setDefectItems(items)
    } else {
        toast.info('Already added...!')
    }
}

const handleReturOrderSubmit = (value, edit, remove, returnOrderItem, setreturnOrderItem) => {
    if (edit) {
        value.resolveType = Number(value.resolveType)
        let index = returnOrderItem.findIndex(e => e._id === edit._id)
        let defect_items = returnOrderItem
        defect_items[index] = value
        setreturnOrderItem(defect_items)
    } else if (remove) {
        let defect_items = returnOrderItem.filter(e => e._id !== remove._id)
        setreturnOrderItem(defect_items)
    }
}

const handleSubmit = (value, edit, remove, defectItems, setDefectItems, isUpdate) => {

    if (edit) {
        let isDefectCheck = isUpdate ?
            (Number(value.defectQty) !== edit.defectQty) && (Number(value.defectQty) <= edit.quantity)
            : (Number(value.orderitems.defectQty) !== edit.orderitems.defectQty) && (Number(value.orderitems.defectQty) <= edit.orderitems.quantity)
        let isSupplierBareCheck = isUpdate ?
            (Number(value.supplier_bare) !== edit.supplier_bare) && (Number(value.supplier_bare) <= 100)
            : (Number(value.orderitems.supplier_bare) !== edit.orderitems.supplier_bare) && (Number(value.orderitems.supplier_bare) <= 100)

        // console.log(isDefectCheck, isSupplierBareCheck);
        if (isDefectCheck || isSupplierBareCheck) {
            // console.log('value changed');
            // let index = -1
            // if (isUpdate) {
            //     index = defectItems.findIndex(e => e._id === edit._id)
            // } else {
            //     index = defectItems.findIndex(e => e.items._id === edit.items._id)
            // }
            let index = defectItems.findIndex(e => isUpdate ? e._id === edit._id : e.items._id === edit.items._id)
            let defect_items = defectItems
            defect_items[index] = value
            setDefectItems(defect_items)
        } else {
            toast.info('check your values...!')
        }
    } else if (remove) {
        //console.log('remove');
        let defect_items = defectItems.filter(e => isUpdate ? e._id !== remove._id : e.items._id !== remove.items._id)
        setDefectItems(defect_items)
    }
}

const handlePutBackSubmit = (value, edit, remove, putBackItem, setPutBackItem, isUpdate) => {

    if (edit) {
        let isDefectCheck = isUpdate ?
            (Number(value.defectQty) !== edit.defectQty) && (Number(value.defectQty) <= edit.quantity)
            : (Number(value.orderitems.defectQty) !== edit.orderitems.defectQty) && (Number(value.orderitems.defectQty) <= edit.orderitems.quantity)

        if (isDefectCheck) {
            //console.log('value changed');

            let index = putBackItem.findIndex(e => isUpdate ? e._id === edit._id : e.items._id === edit.items._id)
            let defect_items = putBackItem
            defect_items[index] = value
            setPutBackItem(defect_items)
        } else {
            toast.info('check your values...!')
        }
    } else if (remove) {
        //console.log('remove');
        let defect_items = putBackItem.filter(e => isUpdate ? e._id !== remove._id : e.items._id !== remove.items._id)
        defect_items.map(e => {
            let status = isUpdate ? e._id !== remove._id : e.items._id !== remove.items._id
            if (status) {
                e.exp_items = []
            }
        })
        setPutBackItem(defect_items)
    }
}

const handleDiscountSubmit = (value, edit, remove, discountItem, setDiscountItem, isUpdate) => {
    if (edit) {
        let index = discountItem.findIndex(e => isUpdate ? e._id === edit._id : e.items._id === edit.items._id)
        let discount_items = discountItem
        discount_items[index] = value
        setDiscountItem(discount_items)
    } else if (remove) {
        let discount_items = discountItem.filter(e => isUpdate ? e._id !== remove._id : e.items._id !== remove.items._id)
        setDiscountItem(discount_items)
    }
}

const formatDate = date => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const addCustomerComplaintHandle = ({ complain, type, resolved, resolvedMethod, imagePath, supplier_bare, account, total, note }, currentOrderId, orderData, defectItems, { setAddcomplaint, setDefectItems, setreturnOrderItem, setPutBackItem, setDiscountItem, setResolveMethod, setViewHandler, setAccount }, isUpdate, complaintId, reloadCustomerComplaintsData, props) => {

    var image = [];
    let items = [];
    var order = [];
    var innerObj = {};
    const method = resolveMethodFunc(resolvedMethod, props) === 'return-order'
    const isRefund = resolveMethodFunc(resolvedMethod, props) === 'refund'
    const PutBack = resolveMethodFunc(resolvedMethod, props) === 'put-back'
    const Other = resolveMethodFunc(resolvedMethod, props) === 'other'
    const discount = resolveMethodFunc(resolvedMethod, props) === 'discount'

    order = orderData?.filter(ele => ele.orders._id === currentOrderId)
    innerObj = order[0].orders;

    if (method) {
        items = defectItems
    } else {
        if (isUpdate) {
            items = defectItems
        } else {
            items = defectItems?.map(ele => ({ ...ele.orderitems, supplier_ref: ele.items.supplier_ref, exp_items: ele.exp_items }))
        }

    }

    if (imagePath !== null && imagePath?.length > 0) {
        for (var i = 0; i < imagePath.length; i++) {
            image.push(imagePath.item(i));
        }
    }

    var data = {
        'complain': complain,
        'type': type,
        'resolved': resolved,
        'resolvedMethod': resolvedMethod,
        'order_ref': currentOrderId,
        'item': items,
        'imagePath': image,
        'supplier_bare': supplier_bare,
        'user': props.userId
    };

    if (method) {
        data.method = 'Return order'
    }
    if (PutBack) {
        data.method = 'Put Back'
    }
    if (Other) {
        data.method = 'Other'
    }
    if (discount) {
        data.method = 'Discount'
    }

    if (data.resolved !== 'Yes') {
        data.resolved = 'No';
    } else {
        data.account = account
    }

    if (total) {
        data.total = Number(total)
    }

    if (note) {
        data.note = note
    }

    if (innerObj) {
        data.amountPaid = innerObj.amountPaid;
        data.totalPrice = innerObj.totalPrice;
    }

    //console.log(data);

    if (data.complain !== '' && data.type !== '' && (!account.isAccount || (isRefund && account.isAccount && account.name !== ''))) {
        data.resolvedDate = data.resolved === 'Yes' ? data.resolvedDate = formatDate(Date.now()) : '';
        data.date = DateNumberFormat(Date.now());

        if (isUpdate) {
            data._id = complaintId
            if (PutBack || method) {
                let total = data.item.reduce((total, ele) => { return total + Number(ele.expQty) }, 0)
                let TotalQty = PutBack ? data.item.reduce((total, ele) => { return total + Number(ele.defectQty) }, 0) : data.item.reduce((total, ele) => { return total + Number(ele.quantity) }, 0)
                let checkExpire = data.item.every(o => o.exp_items.length > 0)

                if (checkExpire) {
                    if (Number(total) === Number(TotalQty)) {
                        props.updateCustomerComplaint(data, props.token);
                        reloadCustomerComplaintsData()
                        removeHandler(setAddcomplaint, setDefectItems, setreturnOrderItem, setPutBackItem, setDiscountItem, setResolveMethod, setViewHandler, setAccount)

                        document.getElementById("addCustomerComplaintForm").reset();
                    } else {
                        toast.error('Exp Qty Not Equals Total Qty')
                    }
                } else {
                    toast.error('Please Check Expire Items')
                }
            } else {
                props.updateCustomerComplaint(data, props.token);
            }
        } else {
            if (PutBack || method) {
                let total = data.item.reduce((total, ele) => { return total + Number(ele.expQty) }, 0)
                let TotalQty = PutBack ? data.item.reduce((total, ele) => { return total + Number(ele.defectQty) }, 0) : data.item.reduce((total, ele) => { return total + Number(ele.quantity) }, 0)
                let checkExpire = data.item.every(o => o.exp_items.length > 0)

                if (checkExpire) {
                    if (Number(total) === Number(TotalQty)) {

                        props.addCustomerComplaint(data, props.token);
                        reloadCustomerComplaintsData()
                        removeHandler(setAddcomplaint, setDefectItems, setreturnOrderItem, setPutBackItem, setDiscountItem, setResolveMethod, setViewHandler, setAccount)

                        document.getElementById("addCustomerComplaintForm").reset();
                    } else {
                        toast.error('Exp Qty Not Equals Total Qty')
                    }
                } else {
                    toast.error('Please Check Expire Items')
                }

            } else {
                props.addCustomerComplaint(data, props.token);
            }
        }

        data = {
            'orderId': currentOrderId
        };

        // axios.defaults.headers.common['authorization'] = props.token;
        // axios.post('/ordering/getCustomerComplaintsByOrderId', { data })
        //     .then(result => {
        //         const finalData = [];
        //         result.data.forEach(element => {
        //             if (element.customercomplaints.order_ref === currentOrderId) {
        //                 finalData.push(element);
        //             } else if (element.customercomplaints.resolved === "No") {
        //                 finalData.push(element);
        //             }
        //         });
        //         // setCustomerComplaintsData(finalData);
        //     }).catch(err => {
        //         console.error(err)
        //     })
        if (!PutBack && !method) {
            reloadCustomerComplaintsData()
            removeHandler(setAddcomplaint, setDefectItems, setreturnOrderItem, setPutBackItem, setDiscountItem, setResolveMethod, setViewHandler, setAccount)

            document.getElementById("addCustomerComplaintForm").reset();
        }
    } else {
        toast.error('Please fill all mandatory fields!');
    }
}

const removeHandler = (setAddcomplaint, setDefectItems, setreturnOrderItem, setPutBackItem, setDiscountItem, setResolveMethod, setViewHandler, setAccount) => {
    let divAccErrorEle = document.getElementById('account_error')
    let btnSaveEle = document.getElementById('save_complaint')
    setAddcomplaint({
        'complain': '',
        'type': '',
        'resolved': 'No',
        'resolvedMethod': '',
        'order_ref': '',
        'item': [],
        'images': '',
        'account': {
            isAccount: false,
            name: ''
        }
    })
    setreturnOrderItem([])
    setPutBackItem([])
    setDiscountItem([])
    setAccount({})
    setDefectItems([])
    setResolveMethod('')
    setViewHandler({ view: true, add: false, update: false })

    if (divAccErrorEle) divAccErrorEle.innerHTML = ""
    btnSaveEle.disabled = false
}

const disabledCheck = (edit, addcomplaint) => {

    // console.log(addcomplaint);
    let isDisabled = edit !== null ?
        true
        : addcomplaint.account.isAccount ?
            addcomplaint.account.isAccount === '' ?
                true
                : false
            : false
    return isDisabled
}

const addItems = (e, defectItems, orderItemsData, setDefectItems) => {
    // console.log(e, defectItems, orderItemsData);
    let index = defectItems?.findIndex(obj => obj.item === e._id)
    if (index < 0) {
        let [ele, ...rest] = orderItemsData?.filter(ele => ele.items._id === e._id)
        let obj = ele.orderitems
        obj.defectQty = ele.orderitems.quantity
        obj.supplier_bare = 100
        obj.itemCode = ele.items.itemCode
        let items = [...defectItems, obj]
        setDefectItems(items)
    } else {
        toast.info('Already added...!')
    }
}

const addPutBackItems = (e, putBackItem, orderItemsData, setPutBackItem) => {
    // console.log(e, defectItems, orderItemsData);
    let index = putBackItem?.findIndex(obj => obj.item === e._id)
    if (index < 0) {
        let [ele, ...rest] = orderItemsData?.filter(ele => ele.items._id === e._id)
        let obj = ele.orderitems
        obj.defectQty = ele.orderitems.quantity
        obj.expQty = 0
        obj.itemCode = ele.items.itemCode
        let items = [...putBackItem, obj]
        setPutBackItem(items)
    } else {
        toast.info('Already added...!')
    }
}

const addDiscountItems = (e, discountItem, orderItemsData, setDiscountItem) => {
    let index = discountItem?.findIndex(obj => obj.item === e._id)
    if (index < 0) {
        let [ele, ...rest] = orderItemsData?.filter(ele => ele.items._id === e._id)
        let obj = ele.orderitems
        obj.discount = ele.orderitems.discount
        obj.itemCode = ele.items.itemCode
        let items = [...discountItem, obj]
        setDiscountItem(items)
    } else {
        toast.info('Already added...!')
    }
}

const resolveFunc = [
    { id: 0, value: 'Create purchase order' },
    { id: 1, value: 'Get from stock' },
]

const itemTotal = (defectItems, isUpdate, type) => {
    let totalVal = 0
    if (isUpdate)
        totalVal = defectItems.reduce((total, ele) => { return total + (Number(ele.unitPrice) - (Number(ele.unitPrice) * Number(ele.discount) / 100)) * ele.defectQty }, 0)
    else
        totalVal = defectItems.reduce((total, ele) => { return total + (Number(ele.orderitems.unitPrice) - (Number(ele.orderitems.unitPrice) * Number(ele.orderitems.discount) / 100)) * ele.orderitems.defectQty }, 0)
    return type === 'string' ? PriceFormat(totalVal) : totalVal
}

const getStocksData = (orderId, setStockData, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    axios.get('/ordering/getStocksWithOrderItems', { headers: { authorization: token }, params: { id: orderId } })
        .then(result => {
            // console.log(result);
            setStockData(result.data)
        }).catch(err => {
            console.error(err);
        })
}

const getReturnOrderColumns = (isUpdate, returnOrderColumn, action) => {

    const updatedColumns = !isUpdate ? [...returnOrderColumn, action] : returnOrderColumn

    return updatedColumns
}

const getPutBackColumns = (isUpdate, putBackColumn, putBackAddColumn) => {

    const updatedColumns = !isUpdate ? [...putBackAddColumn] : putBackColumn

    return updatedColumns
}

const getDiscountColumns = (isUpdate, discountColumn, discountAddColumn) => {

    const updatedColumns = !isUpdate ? [...discountAddColumn] : discountColumn

    return updatedColumns
}

const putBackItems = (putBackItem, items, isUpdate) => {

    let newItems = [];
    let newPutBackItem = [];

    items.map((o) => {
        newItems.push(String(o._id))
    })

    if (isUpdate) {
        putBackItem.map((o) => {
            newPutBackItem.push(String(o.item))
        })
    } else {
        putBackItem.map((o) => {
            newPutBackItem.push(String(o.items._id))
        })
    }

    if (putBackItem.length > 0) {
        let Itemdata = []
        newItems.map((v) => {
            if (!newPutBackItem.includes(String(v))) {
                const found = items.find(obj => {
                    return String(obj._id) === String(v);
                });
                Itemdata.push(found)
            }
        })
        return Itemdata;

    } else {
        return items;
    }
}

const trColorChanged = (state, rowInfo, resolveMethod, props) => {
    if (resolveMethodFunc(resolveMethod, props) === 'return-order' && rowInfo?.original?.stock <= 0) {
        return {
            style: {
                background: '#eeeb7f'
            }
        }
    } else {
        return {}
    }
}

const AddComplaint = ({ addcomplaint, edit, remove, orderItemsData, orderData, items, complaintItem, addComplaintItem, currentOrderId, setAddcomplaint, setViewHandler, setEdit, setRemove, isUpdate, complaintId, reloadCustomerComplaintsData, isClose, setIsClose, activeStatus, setActiveStatus, props }) => {

    const [defectItems, setDefectItems] = useState([]);
    const [returnOrderItem, setreturnOrderItem] = useState([]);
    const [putBackItem, setPutBackItem] = useState([]);
    const [discountItem, setDiscountItem] = useState([]);
    const [resolveMethod, setResolveMethod] = useState('');
    const [stockData, setStockData] = useState([]);
    const [account, setAccount] = useState({});
    const [expireDates, setExpireDates] = useState([]);
    const [dataValue, setDataValue] = useState({});
    const [itemId, setItemId] = useState({});
    const [expire_dates, setExpDates] = useState([]);

    useEffect(() => {
        setAddcomplaint({ ...addcomplaint, 'resolved': 'No', 'account': { isAccount: false, name: '' } })
        if (resolveMethodFunc(resolveMethod, props) === 'return-order') {
            getStocksData(currentOrderId, setStockData, props)
        }

        if (isUpdate) {
            if (resolveMethodFunc(resolveMethod, props) === 'return-order') {
                setreturnOrderItem(complaintItem)
                getStocksData(currentOrderId, setStockData, props)
            } else if (resolveMethodFunc(resolveMethod, props) === 'put-back') {
                setPutBackItem(complaintItem)
            } else if (resolveMethodFunc(resolveMethod, props) === 'discount') {
                setDiscountItem(complaintItem)
            } else {
                setDefectItems(complaintItem)
            }
        }
    }, [resolveMethod])

    useEffect(() => {
        if (isUpdate) setResolveMethod(addcomplaint.resolvedMethod)
    }, [isUpdate && addcomplaint])

    useEffect(() => {
        let itemsValue = itemTotal(defectItems, isUpdate, '')
        let divAccErrorEle = document.getElementById('account_error')
        let btnSaveEle = document.getElementById('save_complaint')

        if (account.type && (account.type !== 'Payable' && Number(itemsValue) > Number(account.balance))) {
            divAccErrorEle.innerHTML = "Check account balance"
            btnSaveEle.disabled = true
        } else if (account.type && (account.type === 'Payable' || Number(itemsValue) <= account.balance)) {
            if (divAccErrorEle) divAccErrorEle.innerHTML = ""
            btnSaveEle.disabled = false
        } else if (addcomplaint.account.isAccount && addcomplaint.account.name === '') {
            divAccErrorEle.innerHTML = "Please select your account"
            btnSaveEle.disabled = true
        } else if (!addcomplaint.account.isAccount) {
            if (divAccErrorEle) divAccErrorEle.innerHTML = ""
            btnSaveEle.disabled = false
        }
    }, [account, defectItems, addcomplaint.account])

    useEffect(() => {
        if (isClose) {
            removeHandler(setAddcomplaint, setDefectItems, setreturnOrderItem, setPutBackItem, setDiscountItem, setResolveMethod, setViewHandler, setAccount)
            setIsClose(false)
        }
    }, [isClose])

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableComponent,
                editing,
                props
            };
            return !editing ? <p className='number-cell-format'>{Number(props.value) !== NaN ? Number(props.value).toFixed(3) : props.value}</p> : <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const editableNumberComponentQty = ({ input, editing, value, original, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
          (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component type="number"
          onChange={input.onChange}
          {...rest} children={children} min={0} step="0.001" style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
      };

    const editableColumnPropsPutBack = {
        ...GridFilter,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableNumberComponentQty,
                editing,
                props
            };
            return !editing ? <p className='number-cell-format'>{Number(props.value) !== NaN ? Number(props.value).toFixed(3) : props.value}</p> : <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            // mode: (edit === rowProps.original && rowProps.original.orders.status !== 'Delivered') ? "edit" : "view",
            mode: edit === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEdit(rowProps.original),
                onCancel: () => setEdit(null),
                onDelete: () => setRemove(rowProps.original)
            },
            updPerm: Permission('ORDERS', 'ORDER', 'UPDATE', props),
            delPerm: Permission('ORDERS', 'ORDER', 'DELETE', props)
        }) ||
        {};

    const getActionExpireDateProps = (gridState, rowProps) =>
        (rowProps && {
            // mode: (edit === rowProps.original && rowProps.original.orders.status !== 'Delivered') ? "edit" : "view",
            mode: edit === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEdit(rowProps.original),
                onCancel: () => setEdit(null),
                onDelete: () => setRemove(rowProps.original)
            },
            updPerm: Permission('ORDERS', 'ORDER', 'UPDATE', props),
            delPerm: expireDates.length > 1 ? (rowProps.original.isDelete === true ? false : true) : false
        }) ||
        {};

    const selectableColumnProps = {
        ...GridFilter,
        Cell: Props => {
            const editing = edit === Props.original;
            let val = resolveFunc.filter(ele => ele.id === Props.value)
            return (editing && !isUpdate ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {resolveFunc.map(data => <option key={data.id} value={data.id}>{data.value}</option>)}
                </Field>
                : <label>{val[0]?.value}</label>)
        }
    };

    const assignTheDefaultQtyValue = (items, orderQty) => {
        items.sort((a, b) => new Date(a.exp_date) - new Date(b.exp_date));

        let remainingQty = orderQty;

        // Step 3-5: Iterate over the items and assign qty values
        for (let i = 0; i < items.length; i++) {
            let item = items[i];

            if (remainingQty > item.totalQty) {
                item.qty = item.totalQty;
                remainingQty -= item.totalQty;
            } else {
                item.qty = remainingQty;
                remainingQty = 0;
                break;
            }
        }
        return items
    }

    const returnOrderColumn = [
        { Header: "Name", accessor: "name" },
        { Header: "ItemCode", accessor: "itemCode" },
        { Header: "Stocks", accessor: "stock", ...GridFilter, Cell: props => <p className='number-cell-format'>{props.value}</p> },
        { Header: "Avail. Stock", accessor: "availableStock", ...GridFilter, Cell: props => <p className='number-cell-format'>{props.value}</p> },
        {
            Header: "Exp Qty",
            accessor: "expQty",
            Cell: row =>
                <button
                    className='btn btn-link  float-right pull-right'
                    data-toggle="modal"
                    data-target='#addReturnExpDatesModel'
                    onClick={() => {
                        setDataValue({})
                        setItemId({})
                        setItemId(row.original.itemId)

                        let order = orderData?.filter(ele => String(row.original.order_ref) === String(ele.orders._id))
                        let warehouseName = order.length > 0 ? order[0].deliverywarehouse.name : ''
                        let warehouseId = order.length > 0 ? order[0].deliverywarehouse._id : ''

                        setActiveStatus(false)

                        row.original.warehouse = warehouseName;
                        row.original.warehouseId = warehouseId;
                        row.original.expQty = 0;

                        row.original.exp_items.map(value => {
                            value.quantity = row.original.quantity
                            value.qty = 0
                        })
                        let expireDates = assignTheDefaultQtyValue(row.original.exp_items, row.original.quantity)
                        row.original.exp_items = expireDates;

                        setExpDates(row.original.exp_items)
                        setDataValue(row.original)
                    }}
                > {row.original.expQty}</button >
        },
        { Header: "Order Qty", accessor: "quantity", ...GridFilter, Cell: props => <p className='number-cell-format'>{props.value}</p> },
        { Header: "Replace Qty", accessor: "quantity", ...editableColumnProps },
        { Header: "Resolve Type", accessor: "resolveType", ...selectableColumnProps },
    ]

    const column = [
        { Header: "Name", accessor: "items.name" },
        { Header: "Item Code", accessor: "items.itemCode" },
        { Header: "Item Qty", accessor: "orderitems.quantity", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Defected Qty", accessor: "orderitems.defectQty", ...editableColumnProps },
        {
            Header: "Refund Price", accessor: "orderitems.defectQty",
            Cell: row => {
                let totalCal = (row.original.orderitems.unitPrice - (row.original.orderitems.unitPrice * row.original.orderitems.discount / 100)) * row.original.orderitems.defectQty
                return (<div style={{ float: 'right' }}>{PriceFormat(totalCal)}</div>)
            }
        },
        { Header: "Supplier Bare %", accessor: "orderitems.supplier_bare", ...editableColumnProps },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const isUpdateColumn = [
        { Header: "Name", accessor: "name" },
        { Header: "Item Code", accessor: "itemCode" },
        { Header: "Item Qty", accessor: "quantity", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Defected Qty", accessor: "defectQty", ...editableColumnProps },
        {
            Header: "Refund Price", accessor: "orderitems.defectQty",
            Cell: row => {
                let totalCal = (row.original.unitPrice - (row.original.unitPrice * row.original.discount / 100)) * row.original.defectQty
                return (<div style={{ float: 'right' }}>{PriceFormat(totalCal)}</div>)
            }
        },
        { Header: "Supplier Bare %", accessor: "supplier_bare", ...editableColumnProps },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const putBackAddColumn = [
        { Header: "Name", accessor: "items.name" },
        { Header: "Item Code", accessor: "items.itemCode" },
        { Header: "Item Qty", accessor: "orderitems.quantity", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Exp Qty",
            accessor: "orderitems.expQty",
            Cell: props =>
                <button
                    className='btn btn-link  float-right pull-right'
                    data-toggle="modal"
                    data-target='#addExpDatesModel'
                    onClick={() => {
                        setDataValue({})
                        setItemId({})
                        setItemId(props.original.items._id)
                        let order = orderData?.filter(ele => String(props.original.orderitems.order_ref) === String(ele.orders._id))
                        let warehouseName = order.length > 0 ? order[0].deliverywarehouse.name : ''
                        let warehouseId = order.length > 0 ? order[0].deliverywarehouse._id : ''

                        setActiveStatus(false)
                        props.original.warehouse = warehouseName;
                        props.original.warehouseId = warehouseId;
                        props.original.defectQty = props.original.orderitems.defectQty;
                        setDataValue(props.original)

                        let expDateArray = [];
                        putBackItem.map(item => {
                            if (item.exp_items !== undefined && item.exp_items.length > 0 && String(props.original.items._id) === String(item.items._id)) {
                                expDateArray.push(...item.exp_items)
                            }
                        })

                        let expData = expDateArray?.filter(ele => String(props.original.items._id) === String(ele.itemId))

                        if (expData.length > 0) {
                            setExpireDates(expData)
                        } else {
                            let data = [{
                                warehouse: warehouseName,
                                warehouseId: warehouseId,
                                itemId: props.original.items._id,
                                itemName: props.original.items.name,
                                _id: 0,
                                exp_date: '',
                                putBackQty: props.original.orderitems.defectQty,
                                originalPutBackQty: props.original.orderitems.defectQty,
                                exp_qty: 0,
                                isAdd: true,
                                isDelete: false
                            }]
                            setExpireDates(data)
                        }
                    }}
                > {Number(props.original.orderitems.expQty).toFixed(3)}</button >
        },
        { Header: "PutBack Qty", accessor: "orderitems.defectQty", ...editableColumnPropsPutBack },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const putBackColumn = [
        { Header: "Name", accessor: "name" },
        { Header: "Item Code", accessor: "itemCode" },
        { Header: "Item Qty", accessor: "quantity", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Exp Qty",
            accessor: "expQty",
            Cell: props =>
                <button
                    className='btn btn-link  float-right pull-right'
                    data-toggle="modal"
                    data-target='#addExpDatesModel'
                    onClick={() => {
                        setDataValue({})
                        setItemId({})
                        setItemId(props.original._id)
                        let order = orderData?.filter(ele => String(props.original.order_ref) === String(ele.orders._id))
                        let warehouseName = order.length > 0 ? order[0].deliverywarehouse.name : ''
                        let warehouseId = order.length > 0 ? order[0].deliverywarehouse._id : ''

                        setActiveStatus(false)
                        props.original.warehouse = warehouseName;
                        props.original.warehouseId = warehouseId;

                        setDataValue(props.original)

                        let expData = []
                        let expireData = props.original.exp_items !== undefined ? props.original.exp_items : []
                        expData.push(...expireData)

                        if (expData.length > 0) {
                            setExpireDates(expData)
                        } else {
                            let data = [{
                                warehouse: warehouseName,
                                warehouseId: warehouseId,
                                itemId: props.original.item,
                                itemName: props.original.name,
                                _id: 0,
                                exp_date: '',
                                putBackQty: props.original.defectQty,
                                originalPutBackQty: props.original.defectQty,
                                exp_qty: 0,
                                isAdd: true,
                                isDelete: false
                            }]
                            setExpireDates(data)
                        }
                    }}
                > {props.original.expQty}</button >
        },
        { Header: "PutBack Qty", accessor: "defectQty", ...editableColumnProps },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const discountAddColumn = [
        { Header: "Name", accessor: "items.name", ...GridFilter },
        { Header: "Item Code", accessor: "items.itemCode" },
        { Header: "Item Qty", accessor: "orderitems.quantity", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Discount %", accessor: "orderitems.discount", ...editableColumnProps },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const discountColumn = [
        { Header: "Name", accessor: "name", ...GridFilter },
        { Header: "Item Code", accessor: "itemCode" },
        { Header: "Item Qty", accessor: "quantity", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Discount %", accessor: "discount", ...editableColumnProps },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const action = {
        Header: '',
        maxWidth: 130,
        minWidth: 130,
        filterable: false,
        fixed: "right",
        getProps: getActionProps,
        Cell: ActionsCell
    }

    const columns = resolveMethodFunc(resolveMethod, props) === 'return-order' ? getReturnOrderColumns(isUpdate, returnOrderColumn, action) : resolveMethodFunc(resolveMethod, props) === 'put-back' ? getPutBackColumns(isUpdate, putBackColumn, putBackAddColumn, setActiveStatus) : resolveMethodFunc(resolveMethod, props) === 'discount' ? getDiscountColumns(isUpdate, discountColumn, discountAddColumn) : isUpdate ? isUpdateColumn : column

    const data = resolveMethodFunc(resolveMethod, props) === 'put-back' ? putBackItems(putBackItem, items, isUpdate) : defectItems

    const renderDateTimePickerExpireDate = ({ input, editing, value, props, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        return editing && <Component type="date" {...input} style={{ maxHeight: edit ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    }

    const selectableDateColumnPropsExpDate = {
        ...GridFilter,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: renderDateTimePickerExpireDate,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} {...fieldProps} /> : props.value !== undefined && props.value !== '' ? <label>{DateNumberFormat(props.value)}</label> : '')
        }
    };

    const editableNumberComponentRecQty = ({ input, editing, value, original, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component type="number"
            onChange={input.onChange}
            {...rest} children={children} step="0.001" style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    };

    const editableNumberColumnReceiveQtyProps = {
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableNumberComponentRecQty,
                editing,
                props
            };
            return <Field name={props.column.id} max={props.original.putBackQty} defaultValue={props.original.exp_qty} {...fieldProps} />;
        }
    };

    const editableNumberColumnReturnQtyProps = {
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableNumberComponentRecQty,
                editing,
                props
            };
            return <Field name={props.column.id} max={props.original.totalQty} defaultValue={props.original.qty} {...fieldProps} />;
        }
    };

    const handleAddItem = (item) => {

        let totalId = expireDates.reduce((total, ele) => { return item._id + Number(ele._id) }, 0)
        let total = expireDates.reduce((total, ele) => { return total + Number(ele.exp_qty) }, 0)

        let checkArr = [];

        expireDates.map(val => {
            if (val.exp_date === undefined && val.exp_date === "" || val.exp_qty === 0) {
                checkArr.push(val)
            }
        })

        if (Number(item.putBackQty) - Number(total) > 0) {
            if (checkArr.length == 0) {
                let newData = {
                    warehouse: item.warehouse,
                    warehouseId: item.warehouseId,
                    itemId: item.itemId,
                    itemName: item.itemName,
                    _id: totalId + 1,
                    exp_date: '',
                    putBackQty: Number(item.putBackQty) - Number(total),
                    originalPutBackQty: item.originalPutBackQty,
                    exp_qty: 0,
                    isAdd: false,
                    isDelete: true
                }
                let data = [...expireDates, newData]
                setExpireDates(data)

            } else {
                toast.error("Please Fill the Fields")
            }
        } else {
            toast.warn("PutBack Qty equals Exp Qty")
        }
    }

    const expColumns = [
        {
            Header: "",
            maxWidth: 70,
            minWidth: 70,
            Cell: row => {
                const item = row.original;
                return (
                    <div>
                        <button
                            hidden={row.original.isAdd === false ? true : false}
                            className='btn btn-primary btn-sm'
                            onClick={() => handleAddItem(item)}
                        >
                            <i className="fa fa-plus-square" style={{ fontSize: "20px", color: "dark blue" }}></i>
                        </button>
                    </div>
                );
            }
        },
        { Header: "Item Name", accessor: "itemName", ...GridFilter },
        { Header: "Outlet", accessor: "warehouse", ...GridFilter, },
        { Header: "Expire Date", accessor: "exp_date", ...GridFilter, ...selectableDateColumnPropsExpDate },
        { Header: "PutBack Qty", accessor: "putBackQty", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Expire Qty", accessor: "exp_qty", ...editableNumberColumnReceiveQtyProps },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionExpireDateProps,
            Cell: ActionsCell
        }
    ]

    const expDatesColumns = [
        { Header: "Item Name", accessor: "itemName", ...GridFilter },
        { Header: "Outlet", accessor: "warehouseName", ...GridFilter, },
        { Header: "Expire Date", accessor: "exp_date", ...GridFilter, },
        { Header: "Total Expire Qty", accessor: "totalQty", getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Expire Qty", accessor: "qty", ...editableNumberColumnReturnQtyProps },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: UpdateActionsCell
        }
    ]

    const handleExpQtySubmit = (value) => {
        if (edit !== null && remove === null) {
            if (value.exp_qty !== "" && value.exp_qty > 0 && value.exp_date !== "" && value.exp_date !== undefined) {

                expireDates.map(val => {
                    if (Number(val._id) === Number(value._id)) {
                        val.exp_date = value.exp_date
                        val.exp_qty = value.exp_qty
                    }
                })

                let total = expireDates.reduce((total, ele) => { return total + Number(ele.exp_qty) }, 0)

                if (Number(value.originalPutBackQty) >= Number(total)) {
                    setExpireDates(expireDates)
                } else {
                    toast.warn("PutBack Qty Not Equals Exp Qty")
                }
            } else {
                toast.error('Please Fill Fields')
            }
        } else if (remove != null && edit === null) {
            let arr = expireDates.filter(item => String(item._id) !== String(value._id));
            setExpireDates(arr)
        }
    }

    const handleReturnExpQtySubmit = (value) => {
        if (edit !== null && remove === null) {
            if (value.qty !== "") {

                expire_dates.map(val => {
                    if (String(val._id) === String(value._id)) {
                        val.qty = value.qty
                    }
                })
                let total = expire_dates.reduce((total, ele) => { return total + Number(ele.qty) }, 0)
                if (Number(value.quantity) >= total) {
                    setExpDates(expire_dates)
                } else {
                    toast.warn("Return Qty Not Equals Exp Qty")
                }
            } else {
                toast.error('Please Fill Fields')
            }
        }
    }

    const addExpDates = (isUpdate) => {
        let checkArr = [];
        expireDates.map(val => {
            if (val.exp_date === undefined && val.exp_date === "" || val.exp_qty === 0) {
                checkArr.push(val)
            }
        })
        let total = expireDates.reduce((total, ele) => { return total + Number(ele.exp_qty) }, 0)
        if (checkArr.length === 0) {
            if (putBackItem.length > 0) {
                if (isUpdate) {
                    putBackItem.map(val => {

                        let ExpItems = expireDates?.filter(ele => String(val.item) === String(ele.itemId))
                        if (String(val._id) === String(itemId)) {
                            val.expQty = Number(total)
                            val.exp_items = ExpItems
                        }
                    })
                    setPutBackItem(putBackItem)

                } else {
                    putBackItem.map(val => {
                        let ExpItems = expireDates?.filter(ele => String(val.items._id) === String(ele.itemId))
                        if (String(val.items._id) === String(itemId)) {
                            val.orderitems.expQty = Number(total)
                            val.exp_items = ExpItems
                        }
                    })
                    setPutBackItem(putBackItem)
                }
                setActiveStatus(true)
            }
        } else {
            toast.error("Please Fill the Fields")
        }
    }

    const addReturnExpDates = (isUpdate) => {

        let total = expire_dates.reduce((total, ele) => { return total + Number(ele.qty) }, 0)
        if (returnOrderItem.length > 0) {
            if (isUpdate) {
                returnOrderItem.map(val => {
                    let ExpItems = expire_dates?.filter(ele => String(val.itemId) === String(ele.itemId))
                    if (String(val._id) === String(itemId)) {
                        val.expQty = Number(total)
                        val.exp_items = ExpItems
                    }
                })
                setreturnOrderItem(returnOrderItem)

            } else {
                returnOrderItem.map(val => {
                    let ExpItems = expire_dates?.filter(ele => String(val.itemId) === String(ele.itemId))
                    if (String(val.itemId) === String(itemId)) {
                        val.expQty = Number(total)
                        val.exp_items = ExpItems
                    }
                })
                setreturnOrderItem(returnOrderItem)
            }
            setActiveStatus(true)
        }
    }
    return (
        <ErrorBoundary>
            <React.Fragment>
                {activeStatus ?
                    <form id='addCustomerComplaintForm'>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td>Complaint* :</td>
                                    <td colSpan={3}>
                                        <textarea type="text" className="form-control" value={addcomplaint.complain} onChange={e => inputHandler(e, 'complain', addcomplaint, setAddcomplaint)}></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Type* :</td>
                                    <td>
                                        <select className="form-control" value={addcomplaint.type} onChange={e => inputHandler(e, 'type', addcomplaint, setAddcomplaint)}>
                                            <option value={''}>- Select Complaint Type -</option>
                                            {complaintTypeData.map(element => {
                                                return <option key={element._id} value={element._id}>{element.name}</option>
                                            })}
                                        </select>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>Images :</td>
                                    <td>
                                        <input type="file" className="form-control-file" multiple={true} accept="image/*" onChange={e => inputHandler(e, 'imagePath', addcomplaint, setAddcomplaint)} />
                                    </td>
                                </tr>

                                <tr>
                                    <td>Resolved Method* :</td>
                                    <td>
                                        <select className='form-control' disabled={isUpdate} value={addcomplaint.resolvedMethod} onChange={e => { inputHandler(e, 'resolvedMethod', addcomplaint, setAddcomplaint); setResolveMethod(e.target.value) }}>
                                            <option>- Resolve method? -</option>
                                            {props.resolveMethod?.map(ele => <option key={ele._id} value={ele._id}>{ele.method} - {ele.desc}</option>)}
                                        </select>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>Resolved* :</td>
                                    <td>
                                        <select className="form-control" defaultValue="No" value={addcomplaint.resolved} onChange={e => inputHandler(e, 'resolved', addcomplaint, setAddcomplaint)}>
                                            <option value={''}>- Resolved? -</option>
                                            {resolvedData.map(element => {
                                                return <option key={element._id} value={element._id}>{element.name}</option>
                                            })}
                                        </select>
                                    </td>
                                </tr>

                                {!isUpdate && resolveMethodFunc(resolveMethod, props) === 'return-order' ?
                                    <tr>
                                        <td>Items:</td>
                                        <td>
                                            <ErrorBoundary>
                                                <Select
                                                    options={stockData}
                                                    value={[]}
                                                    theme={theme => ({ ...theme, borderRadius: 0 })}
                                                    onChange={e => addReturnOrderItem(e, returnOrderItem, stockData, setreturnOrderItem)}
                                                />
                                            </ErrorBoundary>
                                        </td>
                                    </tr>
                                    : null}

                                {addcomplaint.resolved === 'Yes' && resolveMethodFunc(resolveMethod, props) === 'refund' ?
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" className="form-check-input" name="optradio" onChange={e => setAddcomplaint({ ...addcomplaint, account: { ...addcomplaint.account, isAccount: false, name: '' } })} checked={!addcomplaint.account.isAccount} />Create credit note
                                                    </label>
                                                </div>
                                                <div class="form-check-inline">
                                                    <label class="form-check-label">
                                                        <input type="radio" className="form-check-input" name="optradio" onChange={e => setAddcomplaint({ ...addcomplaint, account: { ...addcomplaint.account, isAccount: true } })} checked={addcomplaint.account.isAccount} />Select from gusta accounts
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        {addcomplaint.account.isAccount ?
                                            <td colSpan={2}>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', width: '100%' }}>
                                                        Select Account* :
                                                        <SearchableSelectComp options={accountClasify(props.accounts, ['Cash', 'Payable'])} label={'acc_name'} value={addcomplaint.account.name} onChange={e => { setAccount(e); setAddcomplaint({ ...addcomplaint, account: { isAccount: true, name: e._id } }) }} />
                                                    </div>
                                                    <div>
                                                        {account.type === 'Payable' ?
                                                            <></>
                                                            : <span style={{
                                                                color: account.balance <= 0 ?
                                                                    'red'
                                                                    : itemTotal(defectItems, isUpdate, '') <= account.balance ?
                                                                        ''
                                                                        : 'red',
                                                                padding: '10px'
                                                            }}>
                                                                {PriceFormat(account.balance)}
                                                            </span>}
                                                    </div>
                                                </div>
                                            </td>
                                            : null}
                                    </tr>
                                    : null}

                                {resolveMethodFunc(resolveMethod, props) === 'refund' ?
                                    <tr>
                                        <td>Items :</td>
                                        <td>
                                            <ErrorBoundary>
                                                <Select
                                                    options={items}
                                                    value={complaintItem}
                                                    theme={theme => ({ ...theme, borderRadius: 0 })}
                                                    onChange={e => isUpdate ? addItems(e, defectItems, orderItemsData, setDefectItems) : addComplaintItem(e, defectItems, orderItemsData, setDefectItems)}
                                                // onChange={e => addComplaintItem(e, defectItems, orderItemsData, setDefectItems)}
                                                />
                                            </ErrorBoundary>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>Total value :</td>
                                        <td>
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <div>{itemTotal(defectItems, isUpdate, 'string')}</div>
                                                <div id='account_error' style={{ color: 'red' }}></div>
                                            </div>
                                        </td>
                                    </tr>
                                    : null}

                                {resolveMethodFunc(resolveMethod, props) === 'put-back' ?
                                    <tr>
                                        <td>Items :</td>
                                        <td>
                                            <ErrorBoundary>
                                                <Select
                                                    options={data}
                                                    value={complaintItem}
                                                    theme={theme => ({ ...theme, borderRadius: 0 })}
                                                    onChange={e => isUpdate ? addPutBackItems(e, putBackItem, orderItemsData, setPutBackItem) : addComplaintItem(e, putBackItem, orderItemsData, setPutBackItem)}
                                                />
                                            </ErrorBoundary>
                                        </td>
                                    </tr>
                                    : null}

                                {resolveMethodFunc(resolveMethod, props) === 'discount' ?
                                    <tr>
                                        <td>Items :</td>
                                        <td>
                                            <ErrorBoundary>
                                                <Select
                                                    options={items}
                                                    value={complaintItem}
                                                    theme={theme => ({ ...theme, borderRadius: 0 })}
                                                    onChange={e => isUpdate ? addDiscountItems(e, discountItem, orderItemsData, setDiscountItem) : addComplaintItem(e, discountItem, orderItemsData, setDiscountItem)}
                                                />
                                            </ErrorBoundary>
                                        </td>
                                    </tr>
                                    : null}

                                {addcomplaint.resolved === 'Yes' && resolveMethodFunc(resolveMethod, props) === 'credit-note' ?
                                    <tr>
                                        <td>
                                            <div>Total value :</div>
                                        </td>
                                        <td>
                                            <div><input type="number" className="form-control" onChange={e => inputHandler(e, 'total', addcomplaint, setAddcomplaint)} /></div>
                                        </td>
                                    </tr>
                                    : null}

                                {resolveMethodFunc(resolveMethod, props) === 'other' ?
                                    <tr>
                                        <td>
                                            <div>Note :</div>
                                        </td>
                                        <td>
                                            <div><textarea type="text" className="form-control" onChange={e => inputHandler(e, 'note', addcomplaint, setAddcomplaint)} /></div>
                                        </td>
                                    </tr>
                                    : null}

                                {resolveMethod !== '' ?
                                    <tr>
                                        <td colSpan='4'>
                                            {resolveMethodFunc(resolveMethod, props) === 'refund' || resolveMethodFunc(resolveMethod, props) === 'return-order' || resolveMethodFunc(resolveMethod, props) === 'put-back'
                                                || resolveMethodFunc(resolveMethod, props) === 'discount' ?
                                                <div>
                                                    <ErrorBoundary>
                                                        <FormProvider
                                                            form="inline"
                                                            onSubmit={e => resolveMethodFunc(resolveMethod, props) === 'return-order' ? handleReturOrderSubmit(e, edit, remove, returnOrderItem, setreturnOrderItem, isUpdate)
                                                                : resolveMethodFunc(resolveMethod, props) === 'put-back' ? handlePutBackSubmit(e, edit, remove, putBackItem, setPutBackItem, isUpdate)
                                                                    : resolveMethodFunc(resolveMethod, props) === 'discount' ? handleDiscountSubmit(e, edit, remove, discountItem, setDiscountItem, isUpdate)
                                                                        : handleSubmit(e, edit, remove, defectItems, setDefectItems, isUpdate)}
                                                            onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                                            initialValues={edit !== null ? edit : remove}
                                                            enableReinitialize
                                                        >
                                                            {formProps => {
                                                                return (
                                                                    <form onSubmit={formProps.handleSubmit}>

                                                                        <Table className="-striped"
                                                                            data={resolveMethodFunc(resolveMethod, props) === 'return-order' ? returnOrderItem : resolveMethodFunc(resolveMethod, props) === 'put-back' ? putBackItem : resolveMethodFunc(resolveMethod, props) === 'discount' ? discountItem : defectItems}
                                                                            columns={columns}
                                                                            getTrProps={(state, rowInfo) => trColorChanged(state, rowInfo, resolveMethod, props)}
                                                                            defaultPageSize={5} />
                                                                    </form>)
                                                            }}
                                                        </FormProvider>
                                                    </ErrorBoundary>
                                                </div>
                                                : null}
                                        </td>
                                    </tr>
                                    : null}

                                <tr>
                                    <td colSpan={4}>
                                        <div style={{ float: 'right', paddingTop: '10px' }}>
                                            <button id='save_complaint' type="button" className="btn btn-primary" disabled={disabledCheck(edit, addcomplaint)} onClick={() => addCustomerComplaintHandle(addcomplaint, currentOrderId, orderData, resolveMethodFunc(resolveMethod, props) === 'return-order' ? returnOrderItem : resolveMethodFunc(resolveMethod, props) === 'put-back' ? putBackItem : resolveMethodFunc(resolveMethod, props) === 'discount' ? discountItem : defectItems, { setAddcomplaint, setDefectItems, setreturnOrderItem, setPutBackItem, setDiscountItem, setResolveMethod, setViewHandler, setAccount }, isUpdate, complaintId, reloadCustomerComplaintsData, props)}>Save</button>
                                            {/* <button type="button" className="btn btn-primary" onClick={() => console.log(props)}>Save</button> */}
                                            <button type="button" className="btn btn-info" onClick={e => removeHandler(setAddcomplaint, setDefectItems, setreturnOrderItem, setPutBackItem, setDiscountItem, setResolveMethod, setViewHandler, setAccount)}>Close</button>
                                        </div>
                                    </td>
                                </tr>



                            </tbody>
                        </table>
                    </form>
                    : resolveMethodFunc(resolveMethod, props) === 'put-back' ?
                        <form id='addExpDatesModel'>
                            <div v className="form-group row">
                                <h5>{`PutBack Qty:- ${dataValue.defectQty}`}</h5>
                            </div>
                            {expireDates.length > 0 ?
                                <div>
                                    <React.Fragment>
                                        <ErrorBoundary>
                                            <FormProvider
                                                form="inline"
                                                onSubmit={handleExpQtySubmit}
                                                onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                                initialValues={edit !== null ? edit : remove}
                                                enableReinitialize
                                            >
                                                {formProps => {
                                                    return (
                                                        <form onSubmit={formProps.handleSubmit}>
                                                            <Table className="-striped"
                                                                columns={expColumns}
                                                                data={expireDates}
                                                                defaultPageSize={10}
                                                                showPageJump={false}
                                                            />
                                                        </form>
                                                    );
                                                }}
                                            </FormProvider>
                                        </ErrorBoundary>
                                    </React.Fragment>

                                </div>
                                : ""}
                            <div style={{ float: 'right', paddingTop: '10px' }}>
                                <button id='save_complaint' disabled={(expireDates.reduce((total, ele) => { return total + Number(ele.exp_qty) }, 0) === Number(dataValue.defectQty) && expireDates.reduce((total, ele) => { return total + Number(ele.exp_qty) }, 0) > 0) ? false : true} type="button" className="btn btn-primary" onClick={() => addExpDates(isUpdate)}>Done</button>
                                <button type="button" className="btn btn-info" onClick={e => setActiveStatus(true)}>Close</button>
                            </div>
                        </form>
                        : <form id='addReturnExpDatesModel'>
                            <div v className="form-group row">
                                <h5>{`Return Qty:- ${dataValue.quantity}`}</h5>
                            </div>
                            {expire_dates.length > 0 ?
                                <div>
                                    <React.Fragment>
                                        <ErrorBoundary>
                                            <FormProvider
                                                form="inline"
                                                onSubmit={handleReturnExpQtySubmit}
                                                onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                                initialValues={edit !== null ? edit : remove}
                                                enableReinitialize
                                            >
                                                {formProps => {
                                                    return (
                                                        <form onSubmit={formProps.handleSubmit}>
                                                            <Table className="-striped"
                                                                columns={expDatesColumns}
                                                                data={expire_dates}
                                                                defaultPageSize={10}
                                                                showPageJump={false}
                                                            />
                                                        </form>
                                                    );
                                                }}
                                            </FormProvider>
                                        </ErrorBoundary>
                                    </React.Fragment>

                                </div>
                                : ""}
                            <div style={{ float: 'right', paddingTop: '10px' }}>
                                <button id='save_complaint' disabled={(expire_dates.reduce((total, ele) => { return total + Number(ele.qty) }, 0) === Number(dataValue.quantity)) ? false : true} type="button" className="btn btn-primary" onClick={() => addReturnExpDates(isUpdate)}>Done</button>
                                <button type="button" className="btn btn-info" onClick={e => setActiveStatus(true)}>Close</button>
                            </div>
                        </form>
                }
            </React.Fragment>
        </ErrorBoundary >
    )
}

export default AddComplaint
