import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Table from "react-table";
import { toast } from "react-toastify";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { Field } from 'redux-form';
import axios from 'axios';
import * as BS from "react-bootstrap";

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import GridFilter from "../../components/GridFilter";
import DateGridFilter from '../../components/DateGridFilter';
import { PriceFormat } from '../../utils';
import { getDeliveryNoteData } from '../../../actions/Delivery/delivery_note_action'

const ReactTableFixedColumns = withFixedColumns(Table);

export const DeliveryNote = props => {
    const [expanded, setExpanded] = useState({});
    const [expandDeliverynoteId, setExpandDeliverynoteId] = useState(null);
    const [deliveryNoteItems, setDeliveryNoteItems] = useState([])
    const [bagsData, setBagsData] = useState([])
    const [bagNames, setBagsNames] = useState('')

    useEffect(() => {
        props.getDeliveryNoteData(props)
    }, [])


    const getDeliveryNoteItemsById = () => {
        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/deliveryNote/getDeliveryNoteItemsById', { id: expandDeliverynoteId })
            .then(result => {
                setDeliveryNoteItems(result.data)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }

    const renderDateTimePicker = ({ input, editing, value, Props, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        return (
            <label>{new Date(Props.value).toLocaleDateString()} - {new Date(Props.value).toLocaleTimeString()}</label>
        )
    }

    useEffect(() => {
        getDeliveryNoteItemsById()
    }, [expandDeliverynoteId])

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const reload = () => {
        props.getDeliveryNoteData(props)
        getDeliveryNoteItemsById()
    }


    const columns = [
        { Header: "Delivery Note Number", accessor: "deliverynote.dn_num", ...GridFilter },
        { Header: "Order Id", accessor: "orders.orderId", ...GridFilter },
        { Header: "Customer", id: "customer", accessor: "orders.name", ...GridFilter },
        { Header: "Rider", accessor: "deliverycrew.name", ...GridFilter },
        {
            Header: "Bag Details",
            accessor: "",
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#deliverynotebags' className='btn btn-link'
                        onClick={() => {
                            console.log(row.original.deliverynote.bagNames)
                            setBagsData(row.original.deliverynote.bags)
                            setBagsNames(row.original.deliverynote.bagNames)
                        }}
                    >Bag Details</button>
                </>
        },
        {
            Header: "Completed",
            id: "completed",
            accessor: data => data.deliverynote.isCompleted === false ? "No" : "Yes",
            filterable: true,
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else if (filter.value === "Yes") {
                    return row[filter.id] === "Yes";
                } else if (filter.value === "No") {
                    return row[filter.id] === "No";
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Created Date",
            accessor: "deliverynote.createdAt",
            ...DateGridFilter,
            Cell: Props => {
                const fieldProps = {
                    component: renderDateTimePicker,
                    Props
                };

                return (<Field name={Props.column.id} {...fieldProps} />)
            }
        },
        {
            Header: "Created User", accessor: "users.name.first", ...GridFilter,
            Cell: props => <>
                {props.original.users.name.first + " " + props.original.users.name.last}
            </>
        },
    ]

    const subColumns = [
        { Header: "Item", accessor: "items.name", ...GridFilter },
        {
            Header: "Quantity", accessor: "deliverynoteitems.qty", ...GridFilter,
            Cell: prop =>
                <div style={{ textAlign: 'right' }}>
                    {prop.original.deliverynoteitems.qty}
                </div>
        },
        {
            Header: "Price", accessor: "deliverynoteitems.price", ...GridFilter,
            Cell: prop =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(prop.original.deliverynoteitems.price)}
                </div>
        },
    ]
    const bagColumns = [
        {
            Header: "Name",
            accessor: "state",
            ...GridFilter,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Quantity",
            accessor: "qty",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
    ]


    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <div style={{ float: 'right' }}>
                        <button className="btn btn-primary"
                            disabled={props.isLoading}
                            onClick={() => reload()}
                        >
                            <i className="fas fa-sync"></i>
                        </button>
                    </div>
                </div>
                <div>
                    <FormProvider form="inline">
                        {formProps => {
                            return (
                                <Table className="-striped"
                                    columns={columns}
                                    data={props.deliveryNoteData}
                                    defaultPageSize={10}
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            style: {
                                                fontSize: '14px'
                                            }
                                        }
                                    }}
                                    expanded={expanded}
                                    onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                    SubComponent={row => {
                                        setExpandDeliverynoteId(row.original.deliverynote._id)
                                        return (
                                            <React.Fragment>
                                                <ErrorBoundary>
                                                    <FormProvider form="inline">
                                                        {formProps => {
                                                            return (
                                                                <ReactTableFixedColumns
                                                                    columns={subColumns}
                                                                    data={deliveryNoteItems}
                                                                    getTrProps={(state, rowInfo) => {
                                                                        return {
                                                                            style: {
                                                                                fontSize: '14px',
                                                                            }
                                                                        }
                                                                    }}
                                                                    defaultPageSize={10} />
                                                            )
                                                        }}
                                                    </FormProvider>
                                                </ErrorBoundary>
                                            </React.Fragment>
                                        );
                                    }}
                                />
                            );
                        }}
                    </FormProvider>

                    <div id='deliverynotebags' className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Bag Details</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    {bagNames !== null && bagNames !== "" ? <h6>Bag Numbers: {bagNames}</h6> : null}
                                    {(bagsData === null || bagsData == 0) ?
                                        <>
                                            No Data Found...!
                                        </>
                                        :
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <FormProvider
                                                    form="inline"
                                                >
                                                    {formProps => {
                                                        return (
                                                            <form>
                                                                <Table className="-striped"
                                                                    columns={bagColumns}
                                                                    data={bagsData}
                                                                    defaultPageSize={4}
                                                                />
                                                            </form>
                                                        )
                                                    }}

                                                </FormProvider>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setBagsData([])}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userId: state.rLogin.id,
        loggedUser: state.rLogin.name,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        deliveryNoteData: state.rDeliveryNotes.deliverynotes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDeliveryNoteData: props => { dispatch(getDeliveryNoteData(props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryNote);


