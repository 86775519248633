/**
 * @file This file defines to missing order action dispatch
 * @author Kavindu Shehan
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const missingOrderAsync = data => {
    return { type: 'FETCH_MISSING_ORDER', data }
}

export const missingOrderCompleteAsync = data => {
    return { type: 'FETCH_MISSING_COMPLETE_ORDER', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const getMissingOrderData = ({ token }) => {
    isLoadingAsync(true)
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/missingOrder/getMissingOrder')
            .then(result => {
                dispatch(missingOrderAsync(result.data))
                isLoadingAsync(false)
            }).catch(err => {
                console.error(err);
            })
    }
}

export const updateMissingOrderQty = (data, reason, { getMissingOrderItemsById, expandMissingorderId, setMissingOrderItems }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/missingOrder/updateMissingOrderQty', { data, reason })
            .then(result => {
                toast.success("Quantity updated successfully")
                dispatch(getMissingOrderItemsById(expandMissingorderId, setMissingOrderItems, { token }))
                dispatch(getMissingOrderData({ token }))
            }).catch(err => {
                console.error(err)
            })
    }
}

export const getMissingCompleteOrder = ({ token }) => {
    isLoadingAsync(true)
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/missingOrder/getMissingCompleteOrder')
            .then(result => {
                dispatch(missingOrderCompleteAsync(result.data))
                isLoadingAsync(false)
            }).catch(err => {
                console.error(err);
            })
    }
}
