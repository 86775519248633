import React, { useState, useEffect } from 'react'
import axios from 'axios';
import moment from "moment";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, AreaChart, ReferenceLine, Area } from 'recharts';
import { connect } from 'react-redux';

function ActivatedInactivated(props) {

    const [monthlyData, setMonthlyData] = useState([]);
    const [weeklyData, setWeeklyData] = useState([]);
    const [yearlyData, setYearlyData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [selectedType, setSelectedType] = useState("Items");
    const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [activatedItems, setActivatedItems] = useState([]);
    const [inactivatedItems, setInactivatedItems] = useState([]);
    const [outOfStockItems, setOutOfStockItems] = useState([]);
    const [activatedBrands, setActivatedBrands] = useState([]);
    const [inactivatedBrands, setInactivatedBrands] = useState([]);
    const [totalActiveItemsCount, setTotalActiveItemsCount] = useState(0);
    const [totalInactiveItemsCountWithin3Months, setTotalInactiveItemsCountWithin3Months] = useState(0);
    const [isActiveItem, setActiveItem] = useState(true);

    const selectedYear = new Date(selectedDate).getFullYear()
    const selectedMonth = new Date(selectedDate).toLocaleString('default', { month: 'long' })

    useEffect(() => {
        getChartData();

    }, [selectedType, selectedDate])

    useEffect(() => {
        getItemsBrandsList();

    }, [fromDate, toDate])

    const getChartData = () => {
        axios.defaults.headers.common['authorization'] = props.token;

        const data = {
            'selectedDate': selectedDate
        }

        if (selectedType === "Items") {
            axios.post('dashboard/getItemsCount', data)
                .then(result => {
                    setMonthlyData(result.data.monthlyData);
                    setWeeklyData(result.data.weeklyData);
                    setYearlyData(result.data.yearlyData);
                }).catch(err => {
                    console.error(err)
                })
        }
        else if (selectedType === "Brands") {
            axios.post('dashboard/getBrandsCount', data)
                .then(result => {
                    setMonthlyData(result.data.monthlyData);
                    setWeeklyData(result.data.weeklyData);
                    setYearlyData(result.data.yearlyData);
                }).catch(err => {
                    console.error(err)
                })
        }



    }

    const getItemsBrandsList = () => {
        axios.defaults.headers.common['authorization'] = props.token;

        const data = {
            'fromDate': fromDate,
            'toDate': toDate
        }

        axios.post('dashboard/getItemsBrandsList', data)
            .then(result => {
                setActivatedItems(result.data.activatedItems);
                setInactivatedItems(result.data.inactivatedItems);
                setActivatedBrands(result.data.activatedBrands);
                setInactivatedBrands(result.data.inactivatedBrands);
                setTotalActiveItemsCount(result.data.totalActiveItemsCount);
                setTotalInactiveItemsCountWithin3Months(result.data.totalInactiveItemsCountWithin3Months);
                setOutOfStockItems(result.data.outOfStockItems);
            }).catch(err => {
                console.error(err)
            })

    }


    let activatedItemslistItems = activatedItems.map((activatedItem) => {
        return (activatedItem.status === "Active" ? <li style={{ color: '#5e9c68' }}><b>{activatedItem.name}</b></li> :
            activatedItem.status === "Out Of Stock" ? <li style={{ color: '#f55a42' }}><b>{activatedItem.name}</b></li> : <li>{activatedItem.name}</li>);
    });
    if (activatedItems.length == 0) {
        activatedItemslistItems = <li>No Activated Items</li>
    }

    let inactivatedItemslistItems = inactivatedItems.map((inactivatedItem) => {
        return (inactivatedItem.status === "Active" ? <li style={{ color: '#5e9c68' }}><b>{inactivatedItem.name}</b></li> :
            inactivatedItem.status === "Out Of Stock" ? <li style={{ color: '#f55a42' }}><b>{inactivatedItem.name}</b></li> :
                <li>{inactivatedItem.name}</li>);
    });
    if (inactivatedItems.length == 0) {
        inactivatedItemslistItems = <li>No Inactivated Items</li>
    }

    let outOfStockItemslistItems = outOfStockItems.map((outOfStockItem) => {
        return (outOfStockItem.status === "Active" ? <li style={{ color: '#5e9c68' }}><b>{outOfStockItem.name}</b></li> :
            outOfStockItem.status === "Out Of Stock" ? <li style={{ color: '#f55a42' }}><b>{outOfStockItem.name}</b></li> :
                <li>{outOfStockItem.name}</li>);
    });
    if (outOfStockItems.length == 0) {
        outOfStockItemslistItems = <li>No Out Of Stock Items</li>
    }

    let activatedBrandslistItems = activatedBrands.map((activatedBrand) => {
        return (activatedBrand.active === true ? <li style={{ color: '#5e9c68' }}><b>{activatedBrand.name}</b></li> :
            <li>{activatedBrand.name}</li>);
    });
    if (activatedBrands.length == 0) {
        activatedBrandslistItems = <li>No Activated Brands</li>
    }

    let inactivatedBrandslistItems = inactivatedBrands.map((inactivatedBrand) => {
        return (inactivatedBrand.active === true ? <li style={{ color: '#5e9c68' }}><b>{inactivatedBrand.name} </b></li> :
            <li>{inactivatedBrand.name}</li>);
    });
    if (inactivatedBrands.length == 0) {
        inactivatedBrandslistItems = <li>No Inactivated Brands</li>
    }

    return (
        <>
            <div className="card shadow my-4 mx-6 rounded-5" style={{ display: "flex", justifyContent: 'space-evenly' }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <h6 className="m-0 font-weight-bold text-primary">Activated / Inactivated</h6>
                            <div>
                                <input type='radio' name='dashboard1' style={{ marginRight: '5px' }} checked={isActiveItem ? true : false} onChange={e => { setActiveItem(true) }} /><label>Month</label>
                                <input type='radio' name='dashboard1' style={{ marginLeft: '20px', marginRight: '5px' }} onChange={e => { setActiveItem(false) }} /><label>Comparison</label>
                            </div>
                        </div>
                        <div className="col-md-6"  >
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <select className="form-control form-control-sm"
                                        style={{ padding: "1px", fontSize: '15px', fontWeight: 'bold' }}
                                        value={selectedType}
                                        onChange={(e) => setSelectedType(e.target.value)}>
                                        <option value="Items">Items</option>
                                        <option value="Brands">Brands</option>
                                    </select>
                                </div>
                                <div>
                                    <input style={{ fontSize: '15px', fontWeight: 'bold' }} className="form-control form-control-sm" type="date" value={selectedDate}
                                        onChange={(e) => {
                                            setSelectedDate(e.target.value);
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body" >
                    {isActiveItem ?
                        <div>
                            <ResponsiveContainer width='100%' height={275}>
                                <AreaChart data={monthlyData}
                                    margin={{
                                        top: 10,
                                        right: 40,
                                        left: 0,
                                        bottom: 10,
                                    }}>
                                    <defs>
                                        <linearGradient id="colorPv1" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="10%" stopColor="#00C49F" stopOpacity={1} />
                                            <stop offset="100%" stopColor="#00C49F" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <defs>
                                        <linearGradient id="colorPv2" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="10%" stopColor="#d8196d" stopOpacity={1} />
                                            <stop offset="100%" stopColor="#d8196d" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <defs>
                                        <linearGradient id="colorPv7" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="10%" stopColor="#F09819" stopOpacity={1} />
                                            <stop offset="100%" stopColor="#F09819" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis style={{ fontSize: '14px' }} dataKey="_id" tick={{ fill: '#000000' }} />
                                    <YAxis style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} />
                                    <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left' }} />
                                    <Legend />
                                    <Area type='monotoneX' dataKey='activated' stroke='#00C49F' strokeWidth={2} fillOpacity={1}
                                        fill="url(#colorPv1)" activeDot={{ r: 8 }} />
                                    <Area type='monotoneX' dataKey='inactivated' stroke='#d8196d' strokeWidth={2} fillOpacity={1}
                                        fill="url(#colorPv2)" activeDot={{ r: 8 }} />
                                    <Area type='monotoneX' dataKey='outOfStock' stroke='#F09819' strokeWidth={2} fillOpacity={1}
                                        fill="url(#colorPv7)" activeDot={{ r: 8 }} />
                                </AreaChart>
                            </ResponsiveContainer>
                            <center><span style={{ fontSize: '14px', color: '#191350' }} className="text-left m-0 font-weight-bold">Months of Year - {selectedYear}</span></center>
                        </div>
                        :
                        <div>
                            <div className="container">
                                <div className="md-row" style={{ display: "flex" }}>
                                    <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "normal" }} className="col-md-6">
                                        <ResponsiveContainer width='100%' height={205}>
                                            <BarChart
                                                margin={{
                                                    top: 10,
                                                    right: 20,
                                                    left: 0,
                                                    bottom: 10,
                                                }}
                                                data={weeklyData}
                                                barSize={10}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis style={{ fontSize: '14px' }} dataKey="_id" tick={{ fill: '#000000' }} />
                                                <YAxis style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} />
                                                <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left' }} />
                                                <Bar dataKey="activated" fill="#00C49F" />
                                                <Bar dataKey="inactivated" fill="#d8196d" />
                                                <Bar dataKey="outOfStock" fill="#F09819" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <><span className="m-0 font-weight-bold" style={{ extAlign: 'center', fontSize: '14px', color: '#191350' }} >Weeks of {selectedMonth}</span></>
                                    </div>
                                    <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "normal" }} className="col-md-6">
                                        <ResponsiveContainer width='100%' height={205}>
                                            <BarChart
                                                margin={{
                                                    top: 10,
                                                    right: 20,
                                                    left: 0,
                                                    bottom: 10,
                                                }}
                                                data={yearlyData}
                                                barSize={10}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis style={{ fontSize: '14px' }} dataKey="_id" tick={{ fill: '#000000' }} />
                                                <YAxis style={{ fontSize: '14px' }} width={50} tick={{ fill: '#000000' }} />
                                                <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} contentStyle={{ color: '#000000', textAlign: 'left' }} />
                                                <Bar dataKey="activated" fill="#00C49F" />
                                                <Bar dataKey="inactivated" fill="#d8196d" />
                                                <Bar dataKey="outOfStock" fill="#F09819" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <><span className="m-0 font-weight-bold" style={{ textAlign: 'center', fontSize: '14px', color: '#191350' }}>Years</span></>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    {isActiveItem === false ?
                        <div className="card-footer text-center">
                            <button className="btn btn-primary" data-toggle="modal" data-target="#showItems"
                                onClick={getItemsBrandsList}
                            >
                                Show Items / Brands
                            </button>
                        </div>
                        : ''}
                </div>
            </div>

            {/* Show Items Modal */}
            <div id="showItems" className="modal fade" role="dialog" >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Activated / Inactivated Items & Brands</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">

                            <span>Currently Active Items Count: <span style={{ color: "green" }}> {totalActiveItemsCount}</span> </span> <br />
                            <span>Currently Inactive Items Count (Inactivated within last 3 months): <span style={{ color: "red" }}> {totalInactiveItemsCountWithin3Months} </span></span><br /><br />

                            <div className="row">
                                <div className="col-2 d-flex justify-content-start">
                                    <div style={{ width: '15px', height: '15px', borderRadius: '10px', background: '#5e9c68' }}></div>&nbsp;<span>Active</span>
                                </div>
                                <div className="col-2 d-flex justify-content-start">
                                    <div style={{ width: '15px', height: '15px', borderRadius: '10px', background: '#00003f' }}></div>&nbsp;<span>Inactive</span>
                                </div>
                                <div className="col-2 d-flex justify-content-start">
                                    <div style={{ width: '15px', height: '15px', borderRadius: '10px', background: '#f55a42' }}></div>&nbsp;<span>Out Of Stock</span>
                                </div>
                            </div>

                            &nbsp; &nbsp; &nbsp;

                            <div className="row">
                                <div className="col-3 d-flex justify-content-start">
                                    <label for="from">From:</label>

                                    <input type="date" className="form-control form-control-sm" id="from" name="from"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    ></input>
                                </div>
                                <div className="col-3 d-flex justify-content-start">
                                    <label for="to">To:</label>

                                    <input type="date" className="form-control form-control-sm" id="to" name="to"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    ></input>
                                </div>
                            </div>

                            &nbsp; &nbsp; &nbsp;

                            <div className='container' style={{ display: 'flex' }}>

                                <div className='col-md-6'>

                                    <h5>Activated Items</h5>
                                    <ul>{activatedItemslistItems}</ul>

                                    <h5>Inactivated Items</h5>
                                    <ul>{inactivatedItemslistItems}</ul>

                                </div>

                                <div className='col-md-6'>
                                    <h5>Activated Brands</h5>
                                    <ul>{activatedBrandslistItems}</ul>

                                    <h5>Inactivated Brands</h5>
                                    <ul>{inactivatedBrandslistItems}</ul>

                                    <h5>Out Of Stock Items</h5>
                                    <ul>{outOfStockItemslistItems}</ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

const mapStateToProps = state => ({
    token: state.rLogin.token
})


export default connect(mapStateToProps)(ActivatedInactivated);