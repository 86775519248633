import * as XLSX from 'xlsx'
/**
 * To export data to excel
 * @param {String} file_name file name for excel
 * @param {String} title title for excel
 * @param {String} sheet_name work sheet name
 * @param {Array} excelData data
 */
const ExportData = (file_name, title, sheet_name, excelData) => {
    const wb = XLSX.utils.book_new();
    wb.Props = { Title: `${title}`, Author: 'This file auto generated from system', Company: '© CIAO Pvt (Ltd).', CreatedDate: new Date() }
    const wsAll = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, wsAll, `${sheet_name}`);
    XLSX.writeFile(wb, `${file_name}.xlsx`);
}

export default ExportData;