import React from "react";

const editModes = {
  view: props => (
    <React.Fragment>
      <button style={{ marginLeft : '40px'}} className="btn btn-link btn-sm" disabled={props.updateDisable} onClick={props.onEdit}>
        <i className="fas fa-edit"></i>
      </button>
    </React.Fragment>
  ),
  edit: props => (
    <React.Fragment>
      <button  style={{ marginLeft : '20px'}} className="btn btn-success btn-sm">
        <i className="fas fa-save"></i>
      </button>

      <button className="btn btn-danger btn-sm" onClick={props.onCancel}>
        <i className="fas fa-undo"></i>
      </button>
    </React.Fragment>
  )
};

export default function UpdateActionsCell(props) {
  const {
    mode,
    updPerm = false,
    actions: { onEdit, onCancel }
  } = props.columnProps.rest;
  const Buttons = editModes[mode];
  return <Buttons
    updateDisable={updPerm}
    onEdit={() => onEdit(props.index)}
    onCancel={onCancel} />;
}
