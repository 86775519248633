import React from 'react'
import Skeleton from 'react-loading-skeleton';

function SkeltonCircle({ height, width }) {
    return (
        <>
            <Skeleton circle={true} height={height} width={width} duration={1} />
        </>
    )
}

export default SkeltonCircle
