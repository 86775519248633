import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import { Field } from "redux-form";

import Permission from '../../utils/Permission';
import ErrorBoundary from '../../components/ErrorBoundary';
import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import { addMeasurementData, getMeasurementData, updateMeasurementData, deleteMeasurementData } from '../../../actions/Basic_data/measurement_actions';
import SkeletonTable from '../../components/skeleton/SkeletonTable';

const unit = ['pcs', 'box', 'kg']

export const Measurement = props => {
    const [addMeasurement, setAddMeasurement] = useState({ note: '', createdAt: new Date(), updatedAt: new Date() })
    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);

    useEffect(() => {
        props.getMeasurement(props)
    }, [])

    const addMeasurementHandler = () => {
        if (addMeasurement.note.trim() !== '') {
            props.addMesurementData(addMeasurement, props)
        }
    }

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'MEASUREMENT', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'MEASUREMENT', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const handleSubmit = value => {
        console.log(value);
        if (editing !== null) {
            props.updateMesurementData(value, props)
        } else if (deleting !== null) {
            props.deleteMesurementData(value, props)
        }
    }

    const columns = [
        { Header: "Note", accessor: "note", ...editableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'MEASUREMENT', 'ADD', props)} data-toggle="modal" data-target="#addMeasurement"
                        onClick={() => setAddMeasurement({ note: '', createdAt: new Date(), updatedAt: new Date() })}
                    >+ Measurement</button>
                </div>
                {props.isLoading
                    ? <>
                        <SkeletonTable columnsCount={2} dataCount={10} />
                    </>
                    : <div className="card-body">
                        <>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={handleSubmit}
                                    onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                    initialValues={editing === null ? deleting : editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTable
                                                    className="-striped"
                                                    columns={columns}
                                                    data={props.data}
                                                    defaultPageSize={10}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </>
                    </div>}
            </div>

            {/* add measurement */}
            <ErrorBoundary>
                <div id="addMeasurement" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Add Measurement</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body" style={{ display: 'flex', flexDirection: 'column' }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Note :</td>
                                            <td colSpan={3}>
                                                <textarea className="form-control" maxLength={200} value={addMeasurement.note} onChange={e => setAddMeasurement({ ...addMeasurement, note: e.target.value })} />
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>1</td>
                                            <td>
                                                <select className='form-control'>
                                                    {unit.map(ele => {
                                                        return <option>{ele}</option>
                                                    })}
                                                </select>
                                            </td>
                                            <td>
                                                <input className="form-control" type='number' min={0} />
                                            </td>
                                            <td>
                                                <select className='form-control'>
                                                    {unit.map(ele => {
                                                        return <option>{ele}</option>
                                                    })}
                                                </select>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button id="" type="button" className="btn btn-primary" disabled={addMeasurement.note.trim() !== '' ? false : true} data-dismiss="modal" onClick={addMeasurementHandler}>Add</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isLoading: state.rLogin.loading,
        token: state.rLogin.token,
        userId: state.rLogin.id,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        data: state.rMeasurement.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMeasurement: props => { dispatch(getMeasurementData(props)) },
        addMesurementData: (data, props) => { dispatch(addMeasurementData(data, props)) },
        updateMesurementData: (data, props) => { dispatch(updateMeasurementData(data, props)) },
        deleteMesurementData: (data, props) => { dispatch(deleteMeasurementData(data, props)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Measurement)
