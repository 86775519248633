import { toast } from 'react-toastify';
var axios = require('axios');

export const getIncomeHistoryData = (setIncomeHistory, { token }) => {
    // return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/accounts/getIncomeHistory')
            .then(result => {
                console.log(result);
                setIncomeHistory(result.data)
            }).catch(err => {
                toast.error(`${err}`)
            })
    // }
}
