import React from 'react'

const AddResolveMethodModel = ({ addResolveMehodData, inputHandler, addResolveMethodHandler }) => {
    return (
        <div id="addResolveMethod" className="modal fade" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content animate" >
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase">Add Resolve Method</h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div style={{ display: 'flex', direction: 'row', paddingBottom: '2px' }}>
                                <div style={{ width: '30%' }}>Resolve Method :</div>
                                <div>
                                    <input type="text" className="form-control" value={addResolveMehodData.method} onChange={e => inputHandler(e, 'method')} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', direction: 'row' }}>
                                <div style={{ width: '30%' }}>Method Desc :</div>
                                <div>
                                    <textarea type="text" className="form-control" value={addResolveMehodData.desc} onChange={e => inputHandler(e, 'desc')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={addResolveMethodHandler}>Add</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddResolveMethodModel
