import React from "react";
import "../../components/paper.css"
// import logo from '../../../images/gusta.png'
// import logo1 from '../../../images/gusta-b.png'
import { DateStringFormat, PriceFormat } from "../../utils";

class ReturnToSupplierPrint extends React.Component {
    render() {
        const {
            order, paymentcondition, deliverycondition, supplier, purchaseOrderItems
        } = this.props;

        let allTotal = 0;

        return (
            <div id="printSection">
                <html>
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"
                    />
                    <body class="A4" style={{ fontFamily: "Times New Roman", lineHeight: 1 }}>
                        <section class="sheet  padding-10mm">
                            <table>
                                <tr>
                                    <td style={{ width: "10%" }}>
                                        <img src={`${window.location.origin}/images/logo/gusta.png`} style={{ width: '118px' }} alt="CIAO" />
                                    </td>

                                    <td style={{ width: "45%", textAlign: "center", fontSize: 25 }}>
                                        Return Order
                                    </td>

                                    <td style={{ width: "45%" }}>
                                        <table style={{ width: "100%", textAlign: "left", padding: 0, margin: 0 }} >
                                            <tr>
                                                <td style={{ width: "10%", fontSize: 12, textAlign: "left" }} >
                                                    Address :
                                                </td>
                                                <td style={{ width: "40%", fontSize: 12, textAlign: "left" }} >
                                                    No. 5, Diyawanna Gardens,
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: "20%", fontSize: 12, textAlign: "left" }} ></td>
                                                <td style={{ width: "80%", fontSize: 12, textAlign: "left" }} >
                                                    Nugegoda, Sri lanka.
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: "20%", fontSize: 12, align: "left" }} >
                                                    Contact :
                                                </td>
                                                <td style={{ width: "80%", fontSize: 12, textAlign: "left" }} >
                                                    0094769821619
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "20%", textAlign: "left" }}></td>
                                                <td style={{ width: "80%", fontSize: 12, textAlign: "left" }} >
                                                    0094773511693
                                                </td>
                                            </tr>
                                        </table>
                                    </td>

                                </tr>
                            </table>

                            {typeof supplier !== undefined && supplier != null ? (
                                <table style={{ width: "100%", textAlign: "left" }}>
                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            PURCHASE FROM
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.name}
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13 }}>
                                            ORDER NO
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13, textAlign: "right" }} >
                                            {order.orderid}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            REPRESENTATIVE
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.callerName}
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13 }}>
                                            ORDER DATE
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13, textAlign: "right" }} >
                                            {order.orderdate != null && order.orderdate !== undefined ? DateStringFormat(order.orderdate) : ""}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            ADDRESS
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.address}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            TELEPHONE
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.telephone}
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13 }}>
                                            PAYMENT CONDITION
                                        </td>
                                        <td style={{ width: "25%", fontSize: 13, textAlign: "right" }} >
                                            {paymentcondition != null && paymentcondition.description != "" ? paymentcondition.description : ""}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "15%", fontSize: 13 }}>
                                            EMAIL
                                        </td>
                                        <td style={{ width: "35%", fontSize: 13 }}>
                                            {supplier.email}
                                        </td>
                                    </tr>
                                </table>
                            ) : null}

                            <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse" }}>
                                <tr style={{ backgroundColor: "#dddddd" }}>
                                    <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 16 }} >
                                        Item
                                    </td>
                                    <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 16 }} >
                                        Return Qty
                                    </td>
                                    <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 16 }} >
                                        Price
                                    </td>
                                    <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 16 }} >
                                        Total
                                    </td>

                                </tr>
                                {purchaseOrderItems?.map((value, index, total) => {
                                    allTotal = allTotal + value.price * value.reutrnQty
                                    return (
                                        <tr>
                                            <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                {value.itemCode}
                                            </td>
                                            <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14 }} >
                                                {value.reutrnQty}
                                            </td>
                                            <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14 }} >
                                                {value.price}
                                            </td>
                                            <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14 }} >
                                                {value.price * value.reutrnQty}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                            <p style={{ "marginTop": "10px", "float": "right" }}>Total of All: {allTotal}</p>
                        </section>
                    </body>
                </html>
            </div>
        );
    }


}

export default ReturnToSupplierPrint;