import React, { useState } from 'react';
import StockExpUpdate from './StockExpUpdate';
import StockExpAdd from './StockExpAdd';
import { StockExpView, StockSearchEle } from './StockExpView';
import { Permission } from '../../../../utils';

const resetHandler = (setItemData, setExpStockItem, setShowHandler) => {
    setItemData({})
    setExpStockItem({ warehouse_ref: '', exp_date: '', qty: 0 })
    setShowHandler({ view: true, add: false, update: false })
}

const StockExpDate = ({ rowInfo, props, disabled }) => {

    const [showHandler, setShowHandler] = useState({ view: true, add: false, update: false });
    const perm = Permission('STOCK', 'Stock Item Exp{View}', 'ADD', props)

    return <div id='stockExpModal' className="modal fade" role="dialog">
        <div className="modal-dialog modal-xl">
            <div className="modal-content animate" >
                <div className="modal-header">
                    <h4 className="modal-title text-uppercase">Stock Expiry Date Check</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={() => setShowHandler({ view: true, add: false, update: false })}>&times;</button>
                </div>
                {/* <div className='card'> */}
                <div className='card-header'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <button id='btnView' className='btn btn-primary' disabled={showHandler.view} onClick={() => setShowHandler({ view: true, add: false, update: false })}>View</button>
                            <button id='btnAdd' className='btn btn-primary' disabled={perm || showHandler.add} onClick={() => setShowHandler({ view: false, add: true, update: false })}>+ Add</button>
                            <button id='btnGroupUpdate' className='btn btn-primary' disabled={showHandler.update} onClick={() => setShowHandler({ view: false, add: false, update: true })}>Group</button>
                        </div>
                        {showHandler.view ? <StockSearchEle rowInfo={rowInfo} props={props} /> : null}
                    </div>
                </div>
                <div className="modal-body">
                    {showHandler.view ?
                        <StockExpView rowInfo={rowInfo} props={props} />
                        : showHandler.add ? <StockExpAdd rowInfo={rowInfo} showHandler={showHandler} setShowHandler={setShowHandler} resetHandler={resetHandler} props={props} disabled={disabled} />
                            : <StockExpUpdate rowInfo={rowInfo} showHandler={showHandler} setShowHandler={setShowHandler} resetHandler={resetHandler} props={props} />}
                </div>
                <div className="modal-footer"></div>
            </div>
        </div>
    </div>;
};

export default StockExpDate;
