/**
 * @type {Object}
 * @property {array}  data  Supplier data
 */
const initialState = {
    data: [],
    supplierDataForSelect: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const supplierReducer = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_SUPPLIER':
            var { data } = action;

            let supplierDataForSelect = []

            data.map(supplier => {
                supplier['value'] = `${supplier.supplier._id}`
                supplier['label'] = `${supplier.supplier.name}`;

                let obj = {
                    '_id': supplier.supplier._id,
                    'label': supplier.supplier.name
                }

                supplierDataForSelect.push(obj)
            })

            newState.data = data
            newState.supplierDataForSelect = supplierDataForSelect
            break;

        default:
            break;
    }
    return newState;
}

export default supplierReducer;