/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
// import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import { getProductsData, updateProduct, deleteProduct, addProduct } from '../../../actions/Basic_data/product_actions';
import { getShelfLives, getDeliveryTypes, getSuppliers, getCurrencyData, getCategories } from '../../../actions/Basic_data/basic_data_action';
import ErrorBoundary from "../../components/ErrorBoundary";
// import { element } from "prop-types";
import Permission from '../../utils/Permission';

const ReactTableFixedColumns = withFixedColumns(Table);


class Product extends React.Component {

    statusData = [
        { '_id': 'Active', 'name': 'Active' },
        { '_id': 'Inactive', 'name': 'Inactive' }
    ];

    inStockData = [
        { '_id': true, 'name': 'true' },
        { '_id': false, 'name': 'false' }
    ];

    milkTypeData = [
        { '_id': 'Cow', 'name': 'Cow' },
        { '_id': 'Buffalo', 'name': 'Buffalo' }
    ];

    state = {
        productsData: [],
        categories: [],
        name: '',
        desc: '',
        category: '',
        upperPrice: 0,
        lowerPrice: 0,
        inStock: '',
        milkType: '',
        images: '',
        status: '',
        slug: '',
        updatedAt: Date.now(),
        createdAt: Date.now(),
        editing: null,
        deleting: null,
        currentProductImage: '',
        sortOptions: [{ id: 'products.name', desc: false }],
    };

    componentDidMount() {
        this.props.getShelfLives(this.props.token)
        this.props.getDeliveryTypes(this.props.token)
        this.props.getSuppliers(this.props.token)
        this.props.getCurrencyData(this.props.token)
        this.props.getProductsData(this.props.token)
        this.props.getCategories(this.props.token)
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        if (hours.length < 2) {
            hours = '0' + hours;
        }

        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        var date = [year, month, day].join('-');
        var time = [hours, minutes, seconds].join(':')

        return [date, time].join(' ');
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            // const fieldProps = {
            //     component: this.renderDropdownList,
            //     editing,
            //     props
            // };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    props.column.Header === 'Category' ? this.props.categories :
                        props.column.Header === 'In Stock' ? this.inStockData :
                            props.column.Header === 'Milk Type' ? this.milkTypeData :
                                this.statusData).map(data =>
                                    <option key={data._id} value={data._id}>{data.name}</option>
                                )}
            </Field> : <label>{props.value}</label>)
        }
    };

    imageColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            // const fieldProps = {
            //     component: null,
            //     editing,
            //     props
            // };

            return (editing ? <Field name={props.column.id} component="image" className='form-control'>
                <input type="file" key='images' name='images' onChange={e => this.setState({ images: e.target.files[0] })}></input>
            </Field> : <label>{props.value}</label>)
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        category: rowProps.original.categories._id,
                        images: rowProps.original.products.images,
                        status: rowProps.original.products.status,
                        milkType: rowProps.original.products.milkType
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'PRODUCT', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'PRODUCT', 'DELETE', this.props)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === 'Category') {
            this.setState({ category: value })
        } else if (column === 'Status') {
            this.setState({ status: value })
        } else if (column === 'In Stock') {
            this.setState({ inStock: value })
        } else if (column === 'Milk Type') {
            this.setState({ milkType: value })
        }
    }

    handleSubmit = values => {

        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values.products;

            data.category = this.state.category;
            data.images = this.state.images;
            data.status = this.state.status;
            data.inStock = this.state.inStock;
            data.milkType = this.state.milkType;
            data.updatedAt = this.formatDate(Date.now());

            this.props.updateProduct(data, this.props.token)

            this.setState({
                category: '',
                images: '',
                status: '',
                inStock: '',
            });
        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values.products._id
            }

            this.props.deleteProduct(data, this.props.token)
        }
    };

    addProductHandle = () => {
        var { name, category, inStock, milkType, status } = this.state;

        if (name.trim() !== ''
            && category !== ''
            && inStock !== ''
            && milkType !== ''
            && status !== '') {

            this.state.slug = '1';

            this.state.createdAt = this.formatDate(this.state.createdAt);
            this.state.updatedAt = this.formatDate(this.state.updatedAt);

            this.props.addProduct(this.state, this.props.token)

            this.setState({
                name: '',
                desc: '',
                category: '',
                upperPrice: '',
                lowerPrice: '',
                inStock: '',
                milkType: '',
                images: '',
                status: ''
            });

            document.getElementById("addProductForm").reset();

        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    productColumns = [
        { Header: "Name", accessor: "products.name", ...this.editableColumnProps },
        { Header: "Description", accessor: "products.desc", ...this.editableColumnProps },
        { Header: "Category", accessor: "categories.name", ...this.selectableColumnProps },
        { Header: "Upper Price", accessor: "products.upperPrice", ...this.editableColumnProps },
        { Header: "Lower Price", accessor: "products.lowerPrice", ...this.editableColumnProps },
        { Header: "In Stock", accessor: "products.inStock", ...this.selectableColumnProps },
        { Header: "Milk Type", accessor: "products.milkType", ...this.selectableColumnProps },
        { Header: "Status", accessor: "products.status", ...this.selectableColumnProps },
        { Header: "Image", accessor: "products.images.url", ...this.imageColumnProps },
        { Header: "Created At", accessor: "products.createdAt", filterable: true },
        { Header: "Updated At", accessor: "products.updatedAt", filterable: true },
        {
            Header: "View Image",
            accessor: "products.images.url",
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#viewImage' className='btn btn-link'
                        onClick={() => {
                            this.state.currentProductImage = row.value;
                        }}
                    >{row.value === undefined ? '' : 'View Image'}</button>
                </>
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'PRODUCT', 'ADD', this.props)} data-toggle="modal" data-target="#addProduct">+ Product</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.productColumns}
                                                    data={this.props.productsData}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>

                    <div id="viewImage" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Image</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        {(this.state.currentProductImage === undefined) ? 'No Image Found' : <img src={this.state.currentProductImage} width="100%"></img>}

                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="addProduct" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Product</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addProductForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ name: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Description :</td>
                                                        <td><input type="textarea" className="form-control" onChange={e => this.setState({ desc: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Category* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ category: e.target.value })}>
                                                                <option value={''}>- Select Category -</option>
                                                                {this.props.categories.map(category => {
                                                                    return <option value={category._id}>{category.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>In Stock* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ inStock: e.target.value })}>
                                                                <option value={''}>- In Stock? -</option>
                                                                {this.inStockData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Milk Type* :</td>
                                                        <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ milkType: e.target.value })}>
                                                            <option value={''}>- Select Milk Type -</option>
                                                            {this.milkTypeData.map(element => {
                                                                return <option value={element._id}>{element.name}</option>
                                                            })}
                                                        </select>
                                                    </tr>

                                                    <tr>
                                                        <td>Images :</td>
                                                        <td><input type="file" className="form-control" onChange={e => this.setState({ images: e.target.files[0] })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Status* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ status: e.target.value })}>
                                                                <option value={''}>- Select Status -</option>
                                                                {this.statusData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="add_stock" type="button" className="btn btn-primary" onClick={this.addProductHandle}>Add</button>
                                    {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        shelfLives: state.rBasicData.shelfLives,
        deliveryTypes: state.rBasicData.deliveryTypes,
        suppliers: state.rBasicData.suppliers,
        curriencies: state.rBasicData.currency_data,
        categories: state.rBasicData.categories,
        productsData: state.rProduct.productsData,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProductsData: (token) => { dispatch(getProductsData(token)) },
        getShelfLives: (token) => { dispatch(getShelfLives(token)) },
        getDeliveryTypes: (token) => { dispatch(getDeliveryTypes(token)) },
        getSuppliers: (token) => { dispatch(getSuppliers(token)) },
        getCurrencyData: (token) => { dispatch(getCurrencyData(token)) },
        getCategories: (token) => { dispatch(getCategories(token)) },
        updateProduct: (data, token) => { dispatch(updateProduct(data, token)) },
        deleteProduct: (data, token) => { dispatch(deleteProduct(data, token)) },
        addProduct: (data, token) => { dispatch(addProduct(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);


