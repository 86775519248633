/**
 * @file    This file defined for profit and lost details page
 * @author  Bhanuka Chathuranga
 */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
// import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Table from "react-table";
// import ReactDatePicker from 'react-datepicker';
import _ from 'lodash';
import ErrorBoundary from '../../components/ErrorBoundary';
import GridFilters from "../../components/GridFilter";
import { getCityData, getRegionData } from '../../../actions/Basic_data/basic_data_action';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Permission from '../../utils/Permission';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { ExportData, PriceFormat, selectHandler, DateNumberFormat } from '../../utils';
import * as XLSX from 'xlsx'
import ConvertDecimalPlaces from "../../utils/ConvertDecimalPlaces";
// import toast from 'react-toastify';
/**
 * @param {Object} props
 */
function PAndL(props) {

    const toDate = () => {
        let toDate = new Date().toISOString().split('T')
        let splitTime = toDate[1].split('.')
        let timeString = `23:59:59.${splitTime[1]}`
        let newToDate = `${toDate[0]}T${timeString}`
        return newToDate;
    }

    const [data, setData] = useState([]);
    const [date, setDate] = useState({ from: new Date().toISOString().split('T')[0], to: toDate(), city: [], dateStatus: "Order Date" })
    const [supplierData, setSupplierData] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disable, setDisable] = useState(true);
    const [expanded, setExpanded] = useState({});
    const [stockCostData, setStockCostData] = useState([]);
    const [stockCostTotalValue, setStockCostTotalValue] = useState(0);
    const [stockLostTotalValue, setStockLostTotalValue] = useState({});

    useEffect(() => {
        getData()
        props.getCityData(props.token);
        props.getRegionData(props)
    }, [])

    /**
     * To get data for profit and lost calculation
     * @author Bhanuka Chathuranga
     */
    const getData = () => {
        console.log('coming')
        setIsLoading(true)
        setSupplierData([])
        setOrderItems([])
        setStockCostTotalValue(0)
        setStockCostData([])
        setStockLostTotalValue({})
        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/managment_info_data/getPAndL', { date })
            .then(result => {
                var pandlData = result.data;

                axios.defaults.headers.common['authorization'] = props.token;
                axios.post('/managment_info_data/getPAndLFromHistory', { date })
                    .then(result => {
                        const pandlHistoryData = result.data;

                        if (pandlData.itemsData !== undefined) {
                            if (pandlHistoryData.itemsData !== undefined) {

                                pandlHistoryData.itemsData.map(element => {
                                    pandlData.itemsData.push(element);
                                })

                                pandlHistoryData.orders.map(element => {
                                    pandlData.orders.push(element);
                                })
                            }
                        } else {
                            pandlData = pandlHistoryData;
                        }


                        result.data = pandlData;

                        setData(result.data)
                        if (!result.data.message) {
                            var suppliers = result.data.itemsData.map(obj => {
                                // console.log(obj.supplier)
                                return obj.supplier;
                            })

                            result.data.itemsData.map((obj) => {
                                obj.orderitems.purchasePrice = (Number(obj.orderitems.purchasePrice) - (Number(obj.orderitems.purchasePrice) * Number(obj.items.purchaseDiscount || 0) / 100))
                            })

                            var orderItem = result.data.itemsData.map(obj => {
                                // console.log(obj.supplier)
                                return ({ ...obj.orderitems, orderDiscount: obj.orders.totalDiscount });
                            })

                            var orderDataArr = result.data.orders.map(obj => {
                                // console.log(obj.orders)
                                return obj.orders;
                            })

                            suppliers.map(supplier => {

                                var val = result.data.itemsData.filter(obj => obj.supplier._id === supplier._id)
                                //sale
                                var sale = val.reduce((total, valData) => {
                                    return total + valData.orderitems.price
                                }, 0).toFixed(1)

                                supplier.sale = Number(sale);

                                // cost
                                var cost = val.reduce((total, valData) => {
                                    return total + (Number(valData.orderitems.purchasePrice) * valData.orderitems.quantity)
                                }, 0).toFixed(1)
                                supplier.cost = Number(cost);

                                // profit
                                var profit = (sale - cost).toFixed(1);
                                supplier.profit = Number(profit);

                                // profitPercentage
                                var profitPercentage = (((sale - cost) / (sale)) * 100).toFixed(1);
                                supplier.profitPercentage = Number(profitPercentage);
                            });

                            if (result.data.creditnote !== undefined) {
                                orderDataArr.map(orders => {
                                    var valCredit = result.data.creditnote.filter(obj => String(obj.order_ref) === String(orders._id))
                                    var valRefund = result.data.refund.filter(obj => String(obj.order_ref) === String(orders._id))

                                    var creditNoteValue = valCredit.reduce((total, valData) => {
                                        return total + valData.price
                                    }, 0).toFixed(1)

                                    var refundValue = valRefund.reduce((total, valData) => {
                                        return total + valData.barePrice
                                    }, 0).toFixed(1)

                                    // orderCreditNoteTotalValue
                                    orders.creditNoteValue = Number(creditNoteValue);

                                    // orderRefundTotalValue
                                    orders.refundValue = Number(refundValue);
                                })
                            }else{
                                orderDataArr.map(orders => {
                                    orders.creditNoteValue = 0;
                                    orders.refundValue = 0;
                                })
                            }

                            setSupplierData(_.uniqWith(suppliers, _.isEqual));
                            setOrderItems(orderItem);
                            setIsLoading(false);
                            setDisable(false)

                        } else {
                            setData([])
                            toast.info(result.data);
                            setIsLoading(false)
                        }

                    })
                axios.post('/managment_info_data/getPAndLCostStock', { date })
                    .then(result => {
                        const pandlCostData = result.data;
                        setStockCostData(pandlCostData.stockDataArray);
                        setStockCostTotalValue(pandlCostData.stockTotalValue);
                    })

                axios.post('/managment_info_data/getPAndLLostValue', { date })
                    .then(result => {
                        const pandlLostData = result.data;
                        setStockLostTotalValue(pandlLostData)
                    })

            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
                setIsLoading(false)
            })
    }

    /**
     * To re-calculate the prices
     * @author Umesh
     */
    const exportData = () => {

        let itemData = _.filter(data.itemsData)
        let uniqItem = _.uniqBy(itemData, 'items._id')

        uniqItem.forEach(value => {
            var data = _.filter(itemData, { items: value.items });
            var qty = (_.sumBy(data, 'orderitems.quantity')).toFixed(1)

            value.qty = Number(qty);

            var sale = (_.sumBy(data, 'orderitems.price')).toFixed(1)

            value.sale = Number(sale);

            var cost = (_.sumBy(data, function (o) { return Number(o.orderitems.purchasePrice) * o.orderitems.quantity })).toFixed(1);

            value.cost = Number(cost);

            value.profit = value.sale - value.cost;

            value.profitPercentage = (value.sale - value.cost) * 100 / value.sale;
        })

        var excelData = uniqItem.map(checkData => {
            return {
                'Supplier Name': checkData.supplier.name,
                'Item Name': checkData.items.name,
                'Item Code': checkData.items.itemCode,
                'Item Qty': checkData.qty,
                'Item Sale': checkData.sale,
                'Item Cost': checkData.cost,
                'Profit': checkData.profit,
                'Profit %': Number(checkData.profitPercentage.toFixed(2)),
            }
        })
        ExportData(`Items details`, 'Items details', "items", excelData)
    }

    const exportStockCostData = () => {
        if (stockCostData.length > 0) {
            var excelData = stockCostData.map(checkData => {
                const itemQty = ConvertDecimalPlaces(checkData.inStock) * 1;
                const costPrice = Number(checkData.price).toFixed(2) * 1;
                const totalPrice = (Number(checkData.price) * ConvertDecimalPlaces(checkData.inStock)).toFixed(2) * 1;
    
                return {
                    'Item Name': checkData.item,
                    'Item Code': checkData.code,
                    'Item Qty': itemQty,
                    'Cost Price': costPrice,
                    'Total Price': totalPrice,
                };
            });
    
            const worksheet = XLSX.utils.json_to_sheet(excelData);
    
            Object.keys(worksheet).forEach(cell => {
                if (worksheet[cell].t === 'n') {  
                    worksheet[cell].s = {
                        alignment: {
                            horizontal: 'left',
                        }
                    };
                }
            });
    
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Stock Cost details');
    
            XLSX.writeFile(workbook, 'Stock_Cost_Details.xlsx');
        }
    }
    

    /**
     * To re-calculate the prices
     * @author ravindra
     */
    const recalculatePrice = () => {
        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/managment_info_data/recalulateOrderItemPurchasePrice', {})
            .then(result => {
                toast.success('success...!')
            }).catch(err => {
                console.error(err)
            })
    }

    /**
     * To handle row expansion in react-table
     * @param {Object} rowsState 
     * @param {Array} index 
     */
    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    /**
     * columns defined for react-table columns 
     * @type {Array}
     */
    const columns = [
        { Header: "Supplier Name", accessor: "name", ...GridFilters },
        {
            Header: `Sale ( ${_.sumBy(data.orders, function (o) { return o.orders.totalPrice }).toLocaleString('en', { minimumFractionDigits: 2 })})`, accessor: "sale",
            Cell: props => {
                var val = data.itemsData.filter(obj => obj.supplier._id === props.original._id)
                return <div style={{ textAlign: 'right' }}>
                    {val.reduce((total, valData) => {
                        // return total + valData.orderitems.price
                        return total + (valData.orderitems.price - (valData.orderitems.price * valData.orders.totalDiscount / 100))
                    }, 0).toFixed(1)}
                </div>
            }
        },
        {
            Header: `Cost (${((_.sumBy(orderItems, function (o) { return Number(o.purchasePrice) * o.quantity })) + (_.sumBy(data.orders, function (o) { return Number(o.orders.creditNoteValue + o.orders.refundValue) }))).toLocaleString('en', { minimumFractionDigits: 2 })})`, accessor: "cost",
            Cell: props => {
                var val = data.itemsData.filter(obj => obj.supplier._id === props.original._id)
                return <div style={{ textAlign: 'right' }}>
                    {(val.reduce((total, valData) => {
                        return total + (Number(valData.orderitems.purchasePrice) * valData.orderitems.quantity)
                    }, 0)).toFixed(1)}
                </div>
            }
        },
        {
            Header: `Profit (${((_.sumBy(data.orders, function (o) { return o.orders.totalPrice })) - (_.sumBy(orderItems, function (o) { return Number(o.purchasePrice) * o.quantity })) - (_.sumBy(data.orders, function (o) { return Number(o.orders.creditNoteValue + o.orders.refundValue) }))).toLocaleString('en', { minimumFractionDigits: 2 })})`,
            accessor: "profit",
            Cell: props => {
                var val = data.itemsData.filter(obj => obj.supplier._id === props.original._id)
                var sale = val.reduce((total, valData) => {
                    return total + valData.orderitems.price - (valData.orderitems.price * valData.orders.totalDiscount / 100)
                }, 0)
                var cost = val.reduce((total, valData) => {
                    return total + (Number(valData.orderitems.purchasePrice) * valData.orderitems.quantity)
                }, 0)
                return <div style={{ textAlign: 'right' }}>
                    {(sale - cost).toFixed(1)}
                </div>
            }
        },
        {
            Header: `Profit % (${_.sumBy(data.orders, function (o) { return o.orders.totalPrice }) === 0 ? '-0' : ((((_.sumBy(data.orders, function (o) { return o.orders.totalPrice })) - (_.sumBy(orderItems, function (o) { return Number(o.purchasePrice) * o.quantity })) - (_.sumBy(data.orders, function (o) { return Number(o.orders.creditNoteValue + o.orders.refundValue) }))) / _.sumBy(data.orders, function (o) { return o.orders.totalPrice })) * 100).toFixed(1)})`,
            accessor: "profitPercentage",
            Cell: props => {
                var val = data.itemsData.filter(obj => obj.supplier._id === props.original._id)
                var sale = val.reduce((total, valData) => {
                    return total + valData.orderitems.price - (valData.orderitems.price * valData.orders.totalDiscount / 100)
                }, 0)
                var cost = val.reduce((total, valData) => {
                    return total + (Number(valData.orderitems.purchasePrice) * valData.orderitems.quantity)
                }, 0)
                return <div style={{ textAlign: 'right' }}>
                    {(((sale - cost) / (sale)) * 100).toFixed(1)}
                </div>
            }
        },
    ];

    /**
     * sub columns defined for react-table sub component table
     * @type {Array}
     */
    const subColumns = [
        {
            Header: "Item Name", accessor: "items.name", filterable: true, ...GridFilters,
            Cell: props =>
                <>
                    <button data-toggle="modal" data-target='#orderDetailsPopup' className='btn btn-link'
                        onClick={() => {
                            axios.defaults.headers.common['authorization'] = props.token;
                            setOrderDetails([]);
                            var relatedOrderItems = _.filter(orderItems, function (o) {
                                return o.item === props.original.items._id;
                            });

                            for (var index = 0; index < relatedOrderItems.length; index++) {
                                let relatedOrderItem = relatedOrderItems[index];
                                data.orders.forEach(relatedOrder => {
                                    relatedOrder.orders.orderItems.forEach(orderItemId => {
                                        if (orderItemId === relatedOrderItem._id) {
                                            relatedOrderItems[index].orderId = relatedOrder.orders.orderId;
                                            relatedOrderItems[index].name = relatedOrder.orders.name;
                                            relatedOrderItems[index].telephone = relatedOrder.orders.telephone;
                                            relatedOrderItems[index].whatsapp = relatedOrder.orders.whatsapp;
                                            relatedOrderItems[index].address = relatedOrder.orders.address;
                                            relatedOrderItems[index].status = relatedOrder.orders.status;
                                        }
                                    });
                                });
                            }
                            setOrderDetails(relatedOrderItems);
                        }}
                    >
                        <div style={{ textAlign: 'right' }}>
                            {props.original.items.name}
                        </div>
                    </button>
                </>
        },
        { Header: "Item code", accessor: "items.itemCode", filterable: true, ...GridFilters, },
        {
            Header: "Item Qty", accessor: "qty", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {(_.sumBy(_.filter(orderItems, { item: props.original.items._id }), 'quantity')).toFixed(1)}
                </div>
        },
        {
            Header: "Item sale", accessor: "sale", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {(_.sumBy(_.filter(orderItems, { item: props.original.items._id }), function (o) { return o.price - (o.price * o.orderDiscount / 100) })).toFixed(1)}
                </div>
        },
        {
            Header: "Item cost", accessor: "cost", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {(_.sumBy(_.filter(orderItems, { item: props.original.items._id }), function (o) { return Number(o.purchasePrice) * o.quantity })).toFixed(1)}
                </div>
        },
        {
            Header: "Profit", accessor: "profit", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {((_.sumBy(_.filter(orderItems, { item: props.original.items._id }), function (o) { return o.price - (o.price * o.orderDiscount / 100) })) - (_.sumBy(_.filter(orderItems, { item: props.original.items._id }), function (o) { return Number(o.purchasePrice) * o.quantity }))).toFixed(1)}
                </div>
        },
        {
            Header: "Profit %", accessor: "profitPercentage", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {/* {console.log(props)} */}
                    {(((_.sumBy(_.filter(orderItems, { item: props.original.items._id }), function (o) { return o.price - (o.price * o.orderDiscount / 100) })) - (_.sumBy(_.filter(orderItems, { item: props.original.items._id }), function (o) { return Number(o.purchasePrice) * o.quantity }))) / (_.sumBy(_.filter(orderItems, { item: props.original.items._id }), function (o) { return o.price - (o.price * o.orderDiscount / 100) })) * 100).toFixed(1)}
                </div>
        },
    ];

    const orderColumns = [
        { Header: "Orders Name", accessor: "orders.name", ...GridFilters },
        { Header: "Order Id", accessor: "orders.orderId", ...GridFilters },
        { Header: "Telephone", accessor: "orders.telephone", ...GridFilters },
        {
            Header: "Whatsapp",
            accessor: "orders.whatsapp",
            ...GridFilters,
            filterable: true,
            Cell: row => {
                const url = "https://api.whatsapp.com/send?phone=" + row.original.orders.whatsapp;
                return <u><a href={url} target="_blank">{row.original.orders.whatsapp}</a></u>
            }

        },
        { Header: "Address", accessor: "orders.address", ...GridFilters },
        { Header: "Status", accessor: "orders.status", ...GridFilters },
        // { Header: "Total Price", accessor: "orders.tax", ...GridFilters },
        // { Header: "Total Price", accessor: "orders.deliveryCharges", ...GridFilters },
        // { Header: "Total Price", accessor: "orders.totalDiscount", ...GridFilters },
        {
            Header: "Total Price", accessor: "orders.totalPrice", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.orders.totalPrice)}
                </div>
        },
        {
            Header: "Paid Amount", accessor: "orders.amountPaid", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.orders.amountPaid)}
                </div>
        },
    ];

    const orderDetailColumns = [
        { Header: "Orders Name", accessor: "name", ...GridFilters },
        { Header: "Order Id", accessor: "orderId", ...GridFilters },
        { Header: "Telephone", accessor: "telephone", ...GridFilters },
        { Header: "Whatsapp", accessor: "whatsapp", ...GridFilters },
        { Header: "Address", accessor: "address", ...GridFilters },
        { Header: "Status", accessor: "status", ...GridFilters },
        {
            Header: "Quantity", accessor: "quantity", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.quantity}
                </div>
        },
    ];

    const stockHistoryReasonColumns = [
        { Header: "Item Name", accessor: "itemName", ...GridFilters },
        { Header: "Item Code", accessor: "itemCode", ...GridFilters },
        { Header: "Supplier", accessor: "supplier", ...GridFilters },
        { Header: "Reason", accessor: "reason", ...GridFilters },
        {
            Header: "Created Date", accessor: "createdAt", ...GridFilters,
            Cell: props => {
                if (props.original?.createdAt !== undefined) {
                    var dateTime = DateNumberFormat(props.original?.createdAt)
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        { Header: "User", accessor: "user", ...GridFilters },
        { Header: "Quantity", accessor: "stock", ...GridFilters, getProps: () => { return { style: { 'text-align': 'right' } } } },
        {
            Header: `Cost Price`,
            accessor: "Price", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.price)}
                </div>
        },
        {
            Header: `Total Price`,
            accessor: "totalPrice", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.totalPrice)}
                </div>
        },
    ];

    const stockCostColumns = [
        { Header: "Item Name", accessor: "item", ...GridFilters },
        { Header: "Item Code", accessor: "code", ...GridFilters },
        {
            Header: "Quantity", accessor: "inStock", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {ConvertDecimalPlaces(props.original.inStock)}
                </div>
        },
        {
            Header: `Cost Price`,
            accessor: "Price", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.price)}
                </div>
        },
        {
            Header: `Total Price`,
            accessor: "totalPrice", ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.price * ConvertDecimalPlaces(props.original.inStock))}
                </div>
        },
    ];

    const groupOptions = [
        { label: 'Region', options: props.region },
        { label: 'City', options: props.city },
    ]

    return (
        <div className="right_col" role="main">
            <div className="card">
                {/* gather needed data for search */}
                <div className="card-header" style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <select className="form-control form-control-sm"
                            style={{ marginRight: '10px', width: '100px', padding: "1px" }}
                            value={date.dateStatus}
                            onChange={(e) => setDate({ ...date, dateStatus: e.target.value })}>
                            <option value="Order Date">Order Date</option>
                            <option value="ETD">ETD</option>
                        </select>
                    </div>
                    <div style={{ display: 'inline-flex' }}>
                        <div style={{ width: '100%' }}>From :</div>
                        <div style={{ width: '100%' }}>
                            <input type='date' className='form-control' style={{ width: '100%' }} defaultValue={date.from} onChange={e => {
                                // console.log(e)
                                setDate({ ...date, from: new Date(e.target.valueAsDate) })
                            }} />
                        </div>
                    </div>
                    <div style={{ display: 'inline-flex' }}>
                        <div style={{ width: '100%' }}>To :</div>
                        <div style={{ width: '100%' }}>
                            <ErrorBoundary>
                                <input type='date' className='form-control' style={{ width: '100%' }} defaultValue={date.to.split('T')[0]}
                                    onChange={e => {
                                        let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                        let splitTime = toDate[1].split('.')
                                        let timeString = `23:59:59.${splitTime[1]}`
                                        let newToDate = `${toDate[0]}T${timeString}`
                                        setDate({ ...date, to: newToDate })
                                    }} />
                            </ErrorBoundary>
                        </div>
                    </div>

                    <div style={{ width: '30%' }}>
                        <ErrorBoundary>
                            <Select
                                closeMenuOnSelect={false}
                                theme={theme => ({ ...theme, borderRadius: 0 })}
                                components={makeAnimated()}
                                isMulti
                                placeholder={"Select Region or City"}
                                // options={props.city}
                                options={groupOptions}
                                onChange={e => {
                                    if (e !== null) {
                                        selectHandler(e, date, setDate, props)
                                    } else {
                                        setDate({ ...date, city: [] })
                                    }
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                    <div style={{ minWidth: '10%', paddingLeft: '20px' }}>
                        <button className='btn btn-success' disabled={isLoading} onClick={getData}>{isLoading ? <div><i class="fa fa-spinner fa-pulse" aria-hidden="true"></i> Loading...</div> : 'Show Data'}</button>
                    </div>
                    <div style={{ minWidth: '10%' }}>
                        <button className='btn btn-success' disabled={Permission('MIS', 'P and L{Price Recal}', 'VIEW', props)} onClick={() => { if (window.confirm('Do you want  to recalculate all purchase prices?')) { recalculatePrice() }; }}>Price Recal</button>
                    </div>
                    <div style={{ minWidth: '10%', paddingLeft: '20px' }}>
                        <button className='btn btn-success' disabled={disable} onClick={exportData}>Export Data</button>
                    </div>
                </div>

                {/* check data availability */}
                {isLoading
                    ? <div className="card-body">
                        <SkeletonTable columnsCount={5} dataCount={20} />
                    </div>
                    : data.length === 0
                        ? <div className="card-body">
                            No Data Found!
                        </div>
                        : <div className="card-body">
                            <div>
                                <table className='table table-bordered'>
                                    <tr>
                                        <td className="table-secondary">No of Orders :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            {data.orders.length}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="table-secondary">Undefined</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#didNotDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === `Didn't say` ? o.orders.totalPrice : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === `Didn't say` ? o.orders.totalPrice : 0 }) || 0)}
                                            </a>
                                        </td>
                                        <td className="table-secondary">Paid</td>
                                        <td className="table-secondary">Unpaid</td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary">Net Total :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{_.sumBy(data.orders, function (o) { return o.orders.subTotal - (o.orders.subTotal * o.orders.totalDiscount / 100) - Number(o.orders.creditNoteValue + o.orders.refundValue) }).toLocaleString('en', { minimumFractionDigits: 2 })}</td>
                                        <td className="table-secondary">Bank :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#bankDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Bank Transfer' ? o.orders.totalPrice : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Bank Transfer' ? o.orders.totalPrice : 0 }))}
                                            </a>
                                        </td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Bank Transfer' ? o.orders.amountPaid : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                            {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Bank Transfer' ? o.orders.amountPaid : 0 }))}
                                        </td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#bankUnpaidDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Bank Transfer' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Bank Transfer' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }))}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary">Delivery Income :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{_.sumBy(data.orders, function (o) { return o.orders.deliveryCharges }).toLocaleString('en', { minimumFractionDigits: 2 })}</td>
                                        <td className="table-secondary">Cash :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#creditDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery' ? o.orders.totalPrice : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery' ? o.orders.totalPrice : 0 }))}
                                            </a>
                                        </td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery' ? o.orders.amountPaid : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })}</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#cashUnpaidDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }))}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary">Credit Note + Refund :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{"-"}{_.sumBy(data.orders, function (o) { return Number(o.orders.creditNoteValue + o.orders.refundValue) }).toLocaleString('en', { minimumFractionDigits: 2 })}</td>
                                        <td className="table-secondary">Credit :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#creditCardDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? o.orders.totalPrice : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? o.orders.totalPrice : 0 }))}
                                            </a>
                                        </td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? o.orders.amountPaid : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })}</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#creditCardUnpaidDetails" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {/* {_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }).toLocaleString('en', { minimumFractionDigits: 2 })} */}
                                                {PriceFormat(_.sumBy(data.orders, function (o) { return o.paymentmethods.name === 'Credit Card on Delivery' ? (o.orders.totalPrice - o.orders.amountPaid) : 0 }))}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary">Total Lost Value :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#stockReasonPopup" href="#missing" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {stockLostTotalValue.stockLostValue!== undefined ? PriceFormat(stockLostTotalValue.stockLostValue.total) :  PriceFormat(0)}
                                            </a>
                                        </td>
                                        <td colspan="2" className="table-secondary">Total :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{PriceFormat(_.sumBy(data.orders, function (o) { return o.orders.amountPaid }))}</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>{PriceFormat(_.sumBy(data.orders, function (o) { return (o.orders.totalPrice - o.orders.amountPaid) }))}</td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary">Total Sale :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            {PriceFormat(_.sumBy(data.orders, function (o) { return o.orders.subTotal - (o.orders.subTotal * o.orders.totalDiscount / 100) + o.orders.deliveryCharges }))}</td>
                                        <td className="table-secondary">Total Cost Stock :</td>
                                        <td className="table-info" style={{ textAlign: 'right' }}>
                                            <a data-toggle="modal" data-target="#stockCostPopup" href="#missing" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                {stockCostTotalValue !== undefined ? PriceFormat(stockCostTotalValue) : 0.00}
                                            </a></td>
                                        <td className="table-info" style={{ textAlign: 'right' }}></td>
                                        <td className="table-info" style={{ textAlign: 'right' }}></td>
                                    </tr>
                                </table>
                            </div>
                            {/* <ErrorBoundary> */}
                            <Table
                                columns={columns}
                                expanded={expanded}
                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                data={supplierData}
                                SubComponent={row => {
                                    // console.log(supplierData)
                                    // console.log(row.original)
                                    let itemData = _.filter(data.itemsData, { supplier: { _id: row.original._id } })
                                    let uniqItem = _.uniqBy(itemData, 'items._id')
                                    // console.log(itemData);

                                    uniqItem.forEach(value => {
                                        var data = _.filter(itemData, { items: value.items });
                                        var qty = (_.sumBy(data, 'orderitems.quantity')).toFixed(1)

                                        value.qty = Number(qty);

                                        var sale = (_.sumBy(data, 'orderitems.price')).toFixed(1)

                                        value.sale = Number(sale);

                                        var cost = (_.sumBy(data, function (o) { return Number(o.orderitems.purchasePrice) * o.orderitems.quantity })).toFixed(1);

                                        value.cost = Number(cost);

                                        value.profit = value.sale - value.cost;

                                        value.profitPercentage = (value.sale - value.cost) * 100 / value.sale;
                                    });
                                    return (
                                        <div>
                                            {/* {itemData.map(d => d.items.name)} */}
                                            <Table
                                                data={uniqItem}
                                                columns={subColumns}
                                            />
                                        </div>
                                    )
                                }}
                            />
                            {/* </ErrorBoundary> */}
                        </div>}
            </div>

            {/* Did not say order details */}
            <div id='didNotDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if (o.paymentmethods.name === "Didn't say") return o.orders }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if (o.paymentmethods.name === "Didn't say") return o.orders })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bank transfer order details */}
            <div id='bankDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Bank Transfer Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {/* <table>
                                {_.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery') return o.orders }).map(data => {
                                    // console.log(data)
                                    return (<tr>
                                        <td>{data.orders.name}</td>
                                    </tr>)
                                })}
                            </table> */}
                            {
                                _.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Bank Transfer') return o.orders }).length > 0 ?
                                    <Table
                                        columns={orderColumns}
                                        data={_.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Bank Transfer') return o.orders })}
                                        defaultPageSize={10}
                                    />
                                    : <div>No Data found...!</div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Credit transfer details */}
            <div id='creditDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery') return o.orders }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Credit' || o.paymentmethods.name === 'Cash on Delivery') return o.orders })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Credit card transfer details */}
            <div id='creditCardDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Card Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Credit Card on Delivery') return o.orders }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if (o.paymentmethods.name === 'Credit Card on Delivery') return o.orders })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bank unpaid order details */}
            <div id='bankUnpaidDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Bank Unpaid Order Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if (o.paymentmethods.name === "Bank Transfer" && (o.orders.totalPrice - o.orders.amountPaid) > 0) return o.orders }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if (o.paymentmethods.name === "Bank Transfer" && (o.orders.totalPrice - o.orders.amountPaid) > 0) return o.orders })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Cash unpaid order details */}
            <div id='cashUnpaidDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Cash Unpaid Order Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if ((o.paymentmethods.name === "Cash on Delivery" || o.paymentmethods.name === "Credit") && (o.orders.totalPrice - o.orders.amountPaid) > 0) return o.orders }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if ((o.paymentmethods.name === "Cash on Delivery" || o.paymentmethods.name === "Credit") && (o.orders.totalPrice - o.orders.amountPaid) > 0) return o.orders })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Credit card unpaid order details */}
            <div id='creditCardUnpaidDetails' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Credit Card Unpaid Order Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {_.filter(data.orders, function (o) { if ((o.paymentmethods.name === "Credit Card on Delivery") && (o.orders.totalPrice - o.orders.amountPaid) > 0) return o.orders }).length > 0 ?
                                <Table
                                    columns={orderColumns}
                                    data={_.filter(data.orders, function (o) { if ((o.paymentmethods.name === "Credit Card on Delivery") && (o.orders.totalPrice - o.orders.amountPaid) > 0) return o.orders })}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Order details popup */}
            <div id='orderDetailsPopup' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Order Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            {orderDetails.length > 0 ?
                                <Table
                                    columns={orderDetailColumns}
                                    data={orderDetails}
                                    defaultPageSize={10}
                                />
                                : <div>No Data found...!</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* stock reason popup */}
            <div id='stockReasonPopup' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Stock Lost Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div class="container">
                                <ul class="nav nav-tabs">
                                    <li class="active"><a data-toggle="tab" href="#missing" style={{ fontSize: '20px' }} >Missing: <span style={{ fontSize: '15px' }} >{stockLostTotalValue.stockLostValue !== undefined ? `(${PriceFormat(stockLostTotalValue.stockLostValue.missingTotal)})` :`(${PriceFormat(0)})`}</span></a></li>
                                    <li><a data-toggle="tab" href="#damage" style={{ fontSize: '20px' }} >Damage: <span style={{ fontSize: '15px' }} >{stockLostTotalValue.stockLostValue !== undefined ? `(${PriceFormat(stockLostTotalValue.stockLostValue.damageTotal)})` : `(${PriceFormat(0)})`}</span></a></li>
                                    <li><a data-toggle="tab" href="#expired" style={{ fontSize: '20px' }} >Expired: <span style={{ fontSize: '15px' }} >{stockLostTotalValue.stockLostValue !== undefined ? `(${PriceFormat(stockLostTotalValue.stockLostValue.expireTotal)})` :`(${PriceFormat(0)})`}</span></a></li>
                                </ul>
                                {stockLostTotalValue.stockLostValue !== undefined ?
                                    <>
                                        <div className="tab-content">
                                            <div id="missing" className="tab-pane fade in active show"> {/* Add 'show' class */}
                                                {stockLostTotalValue.stockLostValue.missing.length > 0 ?
                                                    <Table
                                                        columns={stockHistoryReasonColumns}
                                                        data={stockLostTotalValue.stockLostValue.missing}
                                                        defaultPageSize={10}
                                                    />
                                                    : <div>No Data found...!</div>}
                                            </div>
                                            <div id="damage" className="tab-pane fade">
                                                {stockLostTotalValue.stockLostValue.damage.length > 0 ?
                                                    <Table
                                                        columns={stockHistoryReasonColumns}
                                                        data={stockLostTotalValue.stockLostValue.damage}
                                                        defaultPageSize={10}
                                                    />
                                                    : <div>No Data found...!</div>}
                                            </div>
                                            <div id="expired" className="tab-pane fade">
                                                {stockLostTotalValue.stockLostValue.expire.length > 0 ?
                                                    <Table
                                                        columns={stockHistoryReasonColumns}
                                                        data={stockLostTotalValue.stockLostValue.expire}
                                                        defaultPageSize={10}
                                                    />
                                                    : <div>No Data found...!</div>}
                                            </div>
                                        </div>
                                    </>
                                    : <div>No Data found...!</div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id='stockCostPopup' className="modal fade" role="dialog">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Stock Cost Details</h4>
                            <div style={{ float: 'right' }}>
                                <button className='btn btn-success' onClick={exportStockCostData}>Export Data</button>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div class="container">
                                <ul class="nav nav-tabs">
                                    <li class="active"><a data-toggle="tab" href="#totalCostStock" style={{ fontSize: '20px' }} >Total Cost Stock: <span style={{ fontSize: '15px' }} >{stockCostTotalValue !== undefined ? `(${PriceFormat(stockCostTotalValue)})` : 0.00}</span></a></li>
                                </ul>
                                {stockCostData !== undefined ?
                                    <>
                                        <div className="tab-content">
                                            {stockCostData.length > 0 ?
                                                <Table
                                                    columns={stockCostColumns}
                                                    data={stockCostData}
                                                    defaultPageSize={10}
                                                />
                                                : <div>No Data found...!</div>}
                                        </div>
                                    </>
                                    : <div>No Data found...!</div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        city: state.rBasicData.city,
        region: state.rBasicData.region,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCityData: token => { dispatch(getCityData(token)) },
        getRegionData: props => { dispatch(getRegionData(props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PAndL);


