/**
 * @file This file defined for income history page
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from "react-table";

import GridFilter from '../../components/GridFilter';
import { getIncomeHistoryData } from '../../../actions/Acc/income_history_action';
import { DateNumberFormat, PriceFormat } from '../../utils';
import ErrorBoundary from '../../components/ErrorBoundary';
import IncomeHistoryCreditNotesModal from './Modal/IncomeHistoryCreditNotesModal';

const columns = [
    { Header: "Customer Name", accessor: "name", ...GridFilter },
    { Header: "Order Id", accessor: "orderId", ...GridFilter },
    { Header: "Telephone", accessor: "telephone", ...GridFilter },
    {
        Header: "Paid Amount", accessor: "amountPaid", ...GridFilter,
        Cell: prop => <p className='number-cell-format'>{PriceFormat(prop.value)}</p>
    },
    {
        Header: "Total Amount", accessor: "totalPrice", ...GridFilter,
        Cell: prop => <p className='number-cell-format'>{PriceFormat(prop.value)}</p>
    },
    {
        Header: "Created At", accessor: "createdAt", ...GridFilter
    },
]


const getSubColumns = setCreditNotes => {
    const subColumns = [
        {
            Header: "Amount", accessor: "amount", ...GridFilter,
            Cell: props => <p className='number-cell-format'>{PriceFormat(props.value)}</p>
        },
        { Header: "Account", accessor: "accountName", ...GridFilter },
        { Header: "User", accessor: "user.first", ...GridFilter },
        {
            Header: "Recv. Date", accessor: "creditedAt", ...GridFilter,
            Cell: props => <>{DateNumberFormat(props.value)}</>
        },
        {
            Header: "Credit Note Used", accessor: "creditNotes", ...GridFilter,
            Cell: props => {
                setCreditNotes(props.value)
                return (
                    <>
                        {props.value.length > 0 ?
                            <button className='btn btn-link' data-toggle="modal" data-target="#creditNotes">
                                {props.value.reduce((total, ele) => { return total + Number(ele.price) }, 0)}
                            </button>
                            : 'no'}
                    </>
                )
            }
        },
    ]
    return subColumns
}

const creditNoteColumns = [
    { Header: "Order Id", accessor: "orderId", ...GridFilter },
    { Header: "Amount", accessor: "price", ...GridFilter },
    { Header: "Remark", accessor: "remark", ...GridFilter },
]


const IncomeHistory = props => {
    const [incomeHistoryData, setIncomeHistoryData] = useState([]);
    const [creditNotes, setCreditNotes] = useState([]);

    const [sortOptions, setSortOptions] = useState([{ id: 'createdAt', desc: true }]);

    useEffect(() => {
        getIncomeHistoryData(setIncomeHistoryData, props)
    }, [])

    return (
        <div className="right_col" role="main">
            <div className='card'>
                <div className='card-body'>
                    <Table className="-striped"
                        columns={columns}
                        data={incomeHistoryData}
                        sorted={sortOptions}
                        onSortedChange={val => {
                            console.log(val)
                            setSortOptions(val)
                        }}
                        SubComponent={row => {

                            return (
                                <Table
                                    columns={getSubColumns(setCreditNotes)}
                                    data={row.original.payments}
                                    // expanded={{ [row.index]: true }}
                                    defaultPageSize={5}
                                />
                            )
                        }
                        }
                    />
                </div>

                <ErrorBoundary>
                    <IncomeHistoryCreditNotesModal creditNoteColumns={creditNoteColumns} creditNotes={creditNotes} />
                </ErrorBoundary>

            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    token: state.rLogin.token,
    isLoading: state.rLogin.loading,
    userId: state.rLogin.id,
    loggedUser: state.rLogin.name,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
})

export default connect(mapStateToProps)(IncomeHistory)
