/**
 * @file This file defines for front end routes
 * @author Bhanuka Chathuranga
 */
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import {
  DashBoard, DeliveryPlan, DeliveryInspect, DeliveryCrew, CreditNote, ReturnOrder, DeliveredOverview, DeliveryDashboard,
  PAndL, CashBox, Ordering, OrderReconciliation, OrderingHistory, Complains, CanceledOrders, Order,
  Supplier, BackOrder, SupplierEvolution, CustomerVsSupplier, Refund, PurchasePrice, CanceledPurchase, BacklistCustomer,TransactionsReport,
  fastDeliveryDiscount, Stock, StockItem, StockExpireItem, MoveStock,
  BasicData, Item, Product, Brand, Category, DeliveryType, DeliveryCondition, DeliveryCharges,
  PaymentCondition, PaymentMethod, Currency, ShelfLife, ItemPrices, DeliveryCalender, RegionWorkingCalender,
  SeasonalDiscounts, Region, City, ResolveMethods, User, Addresses, Parameters, Permission, Notifications,
  Measurement, CreateItemHierarchy, Forbidden, Accounts, Payments, PaymentDetail, IncomeHistory, DeliveryWarehouse,
  MessagePool, ItemTag, History, Bank, DeliveryNote, MissingOrder, ReturnToSupplier, CreditNotePurchase,
  SurveyQuestions, pos, ItemOutletPrice, POSOrders, POSAccount, POSExpireStock,
  POSPandL, Companies, MoveStockCompany, DisposedItems, StockMoving, SalesPromotion,
  SalesEvolution, POSRunningReport, DeliveryConfirmation, Packaging, ErrorLog
} from '../views';

const permission = (pro, { programme, subProgramme, userProgramme }) => {
  // return new Promise((resolve, reject) => {
  var subPro = subProgramme.filter(val => {
    if (val.name.toUpperCase() === pro.toUpperCase()) {
      return programme.filter(e => {
        if (e._id === val.programme_ref) {
          return e
        }
      })
    }
  })
  // console.log(subPro);
  var index = userProgramme.map(obj => obj.programmeid).indexOf(subPro[0]?.programme_ref)
  // console.log(index);
  if (index >= 0) {
    let isPermit = userProgramme[index]?.subprogramme?.map(obj => obj.subprogrammeid).includes(subPro[0]?._id)
    // console.log(isPermit);
    return isPermit;
  } else if (subPro.length === 0) {
    let isPermit = userProgramme.map(obj => obj.programmeid)
      .includes(programme?.filter(val => { if (val.name.toUpperCase() === pro.toUpperCase()) { return val._id } })[0]?._id)
    // console.log(programme?.filter(val => { if (val.name.toUpperCase() === pro.toUpperCase()) { return val._id } }));
    // console.log(isPermit);
    return isPermit;
  }

  //   // if (isPermit === undefined || isPermit === null) {
  //   //   reject(false)
  //   // } else {
  //   resolve(false)
  //   // }
  // })
  return false
}

const PageContent = props => {

  return (
    <>
      {/* Page Content */}

      {/* Dashboard */}
      <Route path="/" exact component={permission('Dashboard', props) ? DashBoard : Forbidden}></Route>
      <Route path="/home" component={permission('Dashboard', props) ? DashBoard : Forbidden} exact></Route>

      {/* PAndL */}
      <Route path="/MIS/p and L" component={permission('p and l', props) ? PAndL : Forbidden} ></Route>
      <Route path="/MIS/cash box" component={CashBox} ></Route>
      <Route path="/MIS/message pool" component={permission('message pool', props) ? MessagePool : Forbidden} ></Route>
      <Route path="/MIS/sales evolution" component={permission('sales evolution', props) ? SalesEvolution : Forbidden} ></Route>

      {/* Delivery */}
      <Route path="/delivery/delivery plan" component={permission('Delivery Plan', props) ? DeliveryPlan : Forbidden} ></Route>
      <Route path="/delivery/delivery inspect" component={permission('Delivery Inspect', props) ? DeliveryInspect : Forbidden} ></Route>
      <Route path="/delivery/delivery crew" component={permission('Delivery Crew', props) ? DeliveryCrew : Forbidden} ></Route>
      <Route path="/delivery/delivery note" component={permission('Delivery Note', props) ? DeliveryNote : Forbidden} ></Route>
      <Route path="/delivery/credit note" component={permission('Credit Note', props) ? CreditNote : Forbidden} ></Route>
      <Route path="/delivery/return order" component={permission('Return Order', props) ? ReturnOrder : Forbidden} ></Route>
      <Route path="/delivery/missing order" component={permission('Missing Order', props) ? MissingOrder : Forbidden} ></Route>
      <Route path="/delivery/delivered overview" component={permission('Delivered Overview', props) ? DeliveredOverview : Forbidden} ></Route>
      <Route path="/delivery/delivery dashboard" component={permission('Delivery Dashboard', props) ? DeliveryDashboard : Forbidden} ></Route>
      <Route path="/delivery/delivery confirmation" component={permission('Delivery Confirmation', props) ? DeliveryConfirmation : Forbidden} ></Route>

      {/* Oredering */}
      <Route path="/orders/order" component={permission('Order', props) ? Ordering : Forbidden} exact></Route>
      <Route path="/orders/order reconciliation" component={permission('order reconciliation', props) ? OrderReconciliation : Forbidden} exact></Route>
      <Route path="/orders/order history" component={permission('order history', props) ? OrderingHistory : Forbidden} exact></Route>
      <Route path="/orders/complains" component={permission('complains', props) ? Complains : Forbidden} exact></Route>
      <Route path="/orders/Canceled Orders" component={permission('Canceled orders', props) ? CanceledOrders : Forbidden} exact></Route>
      <Route path="/orders/Backlist Customer" component={permission('Backlist Customer', props) ? BacklistCustomer : Forbidden} exact></Route>
      <Route path="/orders/Sales Promotion" component={permission('Sales Promotion', props) ? SalesPromotion : Forbidden} exact></Route>
      <Route path="/orders/fast delivery discount" component={permission('Fast Delivery Discount', props) ? fastDeliveryDiscount : Forbidden} exact></Route>

      {/* Purchasing */}
      <Route path="/purchase/orders" component={permission('Orders', props) ? Order : Forbidden} exact></Route>
      <Route path="/purchase/suppliers" component={permission('Suppliers', props) ? Supplier : Forbidden} exact></Route>
      <Route path="/purchase/back order" component={permission('Back Order', props) ? BackOrder : Forbidden} exact></Route>
      <Route path="/purchase/supplier evalution" component={permission('Supplier Evolution', props) ? SupplierEvolution : Forbidden} exact></Route>
      <Route path="/purchase/supplier vs customer" component={permission('Supplier Vs Customer', props) ? CustomerVsSupplier : Forbidden} exact></Route>
      <Route path="/purchase/refund" component={permission('Refund', props) ? Refund : Forbidden}></Route>
      <Route path="/purchase/history" component={permission('History', props) ? History : Forbidden}></Route>
      <Route path="/purchase/purchase price" component={permission('Purchase Price', props) ? PurchasePrice : Forbidden}></Route>
      <Route path="/purchase/canceled purchase" component={permission('Canceled Purchase', props) ? CanceledPurchase : Forbidden}></Route>
      <Route path="/purchase/return to supplier" component={permission('Return To Supplier', props) ? ReturnToSupplier : Forbidden}></Route>
      <Route path="/purchase/credit note purchasing" component={permission('Credit Note Purchasing', props) ? CreditNotePurchase : Forbidden}></Route>
      <Route path="/purchase/transactions report" component={permission('Transactions Report', props) ? TransactionsReport : Forbidden}></Route>

      {/* Stock */}
      <Route path="/stock/stock" component={permission('Stock', props) ? Stock : Forbidden} exact></Route>
      <Route path="/stock/stock item" component={permission('Stock item', props) ? StockItem : Forbidden} exact></Route>
      <Route path="/stock/stock expire item" component={permission('Stock Expire Item', props) ? StockExpireItem : Forbidden} exact></Route>
      <Route path="/stock/move stock" component={permission('Move Stock', props) ? MoveStock : Forbidden} exact></Route>
      <Route path="/stock/move stock company" component={permission('Move Stock Company', props) ? MoveStockCompany : Forbidden} exact></Route>
      <Route path="/stock/stock running report" component={permission('Stock Running Report', props) ? StockMoving : Forbidden} exact></Route>
      <Route path="/stock/packaging" component={permission('Packaging', props) ? Packaging : Forbidden} exact></Route>

      {/* Basic Data */}
      <Route path="/basic data" component={BasicData} exact></Route>
      <Route path="/basic data/item" component={permission('item', props) ? Item : Forbidden} exact></Route>

      <Route path="/basic data/createItemHierarchy" component={permission('createItemHierarchy', props) ? CreateItemHierarchy : Forbidden} exact></Route>

      <Route path="/basic data/product" component={permission('product', props) ? Product : Forbidden} exact></Route>
      <Route path="/basic data/brand" component={permission('brand', props) ? Brand : Forbidden} exact></Route>
      <Route path="/basic data/category" component={permission('category', props) ? Category : Forbidden} exact></Route>
      <Route path="/basic data/delivery type" component={permission('delivery type', props) ? DeliveryType : Forbidden} exact></Route>
      <Route path="/basic data/delivery condition" component={permission('delivery condition', props) ? DeliveryCondition : Forbidden} exact></Route>
      <Route path="/basic data/delivery charges" component={permission('delivery charges', props) ? DeliveryCharges : Forbidden} exact></Route>
      <Route path="/basic data/payment condition" component={permission('payment condition', props) ? PaymentCondition : Forbidden} exact></Route>
      <Route path="/basic data/payment method" component={permission('payment method', props) ? PaymentMethod : Forbidden} exact></Route>
      <Route path="/basic data/currency" component={permission('currency', props) ? Currency : Forbidden} exact></Route>
      <Route path="/basic data/shelflife" component={permission('shelflife', props) ? ShelfLife : Forbidden} exact></Route>
      <Route path="/basic data/user discounts" component={permission('user discounts', props) ? ItemPrices : Forbidden} exact></Route>
      <Route path="/basic data/delivery calender" component={permission('working calender', props) ? DeliveryCalender : Forbidden} exact></Route>
      <Route path="/basic data/add region working calender" component={RegionWorkingCalender} exact />
      <Route path="/basic data/seasonal discounts" component={permission('seasonal discounts', props) ? SeasonalDiscounts : Forbidden} exact></Route>
      <Route path="/basic data/region" component={permission('region', props) ? Region : Forbidden} exact></Route>
      <Route path="/basic data/city" component={permission('city', props) ? City : Forbidden} exact></Route>
      <Route path="/basic data/measurement" component={permission('Measurement', props) ? Measurement : Forbidden} exact></Route>
      <Route path="/basic data/resolve methods" component={permission('resolve methods', props) ? ResolveMethods : Forbidden} exact></Route>
      <Route path="/basic data/delivery warehouse" component={permission('delivery warehouse', props) ? DeliveryWarehouse : Forbidden} exact></Route>
      <Route path="/basic data/item tag" component={permission('item tag', props) ? ItemTag : Forbidden} exact></Route>
      <Route path="/basic data/survey questions" component={permission('survey questions', props) ? SurveyQuestions : Forbidden} exact></Route>
      <Route path="/basic data/companies" component={permission('companies', props) ? Companies : Forbidden} exact></Route>
      <Route path="/basic data/Error Log" component={permission('error log', props) ? ErrorLog : Forbidden} exact></Route>

      {/* Admin */}
      <Route path="/administrator/permission" component={permission('permission', props) ? Permission : Forbidden} exact></Route>
      <Route path="/administrator/user" component={permission('user', props) ? User : Forbidden} exact></Route>
      <Route path="/administrator/addresses" component={permission('addresses', props) ? Addresses : Forbidden} exact></Route>
      <Route path="/administrator/parameters" component={permission('parameters', props) ? Parameters : Forbidden} exact></Route>
      <Route path="/administrator/notifications" component={permission('notifications', props) ? Notifications : Forbidden} exact></Route>


      {/* Accounts */}
      <Route path="/acc/accounts" component={permission('accounts', props) ? Accounts : Forbidden} exact></Route>
      <Route path="/acc/payments" component={permission('payments', props) ? Payments : Forbidden} exact></Route>
      <Route path="/acc/payment detail" component={permission('payment detail', props) ? PaymentDetail : Forbidden} exact></Route>
      <Route path="/acc/income history" component={permission('income history', props) ? IncomeHistory : Forbidden} exact></Route>
      <Route path="/acc/bank" component={permission('bank', props) ? Bank : Forbidden} exact></Route>

      {/* Pos */}
      <Route path="/pos/pos" component={permission('pos', props) ? pos : Forbidden} exact></Route>
      <Route path="/pos/pos orders" component={permission('pos orders', props) ? POSOrders : Forbidden} exact></Route>
      <Route path="/pos/pos item price" component={permission('pos item price', props) ? ItemOutletPrice : Forbidden} exact></Route>
      <Route path="/pos/pos accounts" component={permission('pos accounts', props) ? POSAccount : Forbidden} exact></Route>
      <Route path="/pos/outlet stock" component={permission('outlet stock', props) ? POSExpireStock : Forbidden} exact></Route>
      <Route path="/pos/pos p and l" component={permission('pos p and l', props) ? POSPandL : Forbidden} exact></Route>
      <Route path="/pos/disposed items" component={permission('disposed items', props) ? DisposedItems : Forbidden} exact></Route>
      <Route path="/pos/pos running report" component={permission('pos running report', props) ? POSRunningReport : Forbidden} exact></Route>
      {/* /Page Content */}
    </>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: state.rLogin.isLoggedIn,
  userProgramme: state.rLogin.programme,
  programme: state.rPermission.programme,
  subProgramme: state.rPermission.subProgramme,
})

export default connect(mapStateToProps)(PageContent);