/**
 * @file    This file defined Order page for POS
 * @author  Umesh Ranthilina
 */
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import * as BS from "react-bootstrap";
import { Field } from "redux-form";
import Permission from '../../utils/Permission';
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import { connect } from 'react-redux';
import { getOrderData, getOrderItemsData, updatePosOrder } from '../../../actions/POS/pos_orders_action';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import GridFilters from "../../components/GridFilter";
import { PriceFormat } from '../../utils';
import Table from "react-table";
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";
import _ from 'lodash';
import {ExportData, DateNumberFormat } from '../../utils';
import POS_Print from '../POS/POS_Print';
import ActionsCell from "../../components/ActionCell";
import { getPOSAccountsData } from '../../../actions/POS/pos_actions';
import ActionsUpdateCell from "../../components/UpdateActionsCell";
var axios = require('axios');

function Orders(props) {

    const fromDate = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        return `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;
    }

    const toDate = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
        const timeString = `23:59:59`;
        const formattedMonth = currentMonth.toString().padStart(2, '0');
        const formattedDay = lastDayOfMonth.getDate().toString().padStart(2, '0');
        const newToDate = `${currentYear}-${formattedMonth}-${formattedDay}T${timeString}`;
        return newToDate;
    }

    const [expanded, setExpanded] = useState({});
    const [order_id, serOrder_id] = useState(null);
    const [posOrderData, setPosOrderData] = useState([]);
    const [cOrder, setCOrder] = useState(null);
    const [cOrderItems, setCOrderItems] = useState([]);
    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [isView, setIsView] = useState(false)
    const [accountId, setAccountId] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [creditCard, setCreditCardType] = useState(null)
    const [date, setDate] = useState({ from: fromDate(), to: toDate() })
    const [page] = useState(1);
    const [pageSize] = useState(10);

    const returnTypeOptions = [
        { 'value': '', 'label': 'Select Return Type' },
        { 'value': 'backToStock', 'label': 'Back To Stock' },
        { 'value': 'dispose', 'label': 'Dispose' }
    ];

    const creditCardType = [
        { '_id': '', 'name': 'None' },
        { '_id': 'Visa', 'name': 'Visa' },
        { '_id': 'Mastercard', 'name': 'Mastercard' },
        { '_id': 'Other', 'name': 'Other' },
    ];

    useEffect(() => {
        props.getOrderData({ userId: props.userId, from: date.from, to: date.to, page: page, pageSize: pageSize }, props.token)
    }, [date])

    useEffect(() => {
        if (order_id !== null) {
            props.getOrderItemsData({ orderId: order_id }, props.token)
        }
    }, [order_id])

    useEffect(() => {
        if (cOrder !== null) {
            props.getPOSAccountsData({ userId: cOrder.user, token: props.token }, props.token)
        }
    }, [cOrder])

    useEffect(() => {
        if (props.accounts.length > 0 && cOrder !== null) {
            let accountId = '';
            props.accounts.forEach(element => {
                if (String(element.warehouseId) === String(cOrder.outlet)) {
                    if (cOrder.payment_method === 'Cash' || cOrder.payment_method === 'Credit Card') {
                        if (element.warehouseAccType === 'Income Cash') {
                            accountId = element._id
                        }
                    }
                }
            });

            setAccountId(accountId)
        }
    }, [props.accounts, cOrder])

    const reload = () => {
        props.getOrderItemsData({ orderId: order_id }, props.token)
        props.getOrderData({ userId: props.userId, from: date.from, to: date.to, page: props.orderData.currentPage, pageSize: pageSize }, props.token)
    }

    const getOrderActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
            },
            updPerm: Permission('POS', 'POS ORDERS', 'UPDATE', rowProps.original.posorder.payment_method === 'Credit Card' ? props : false && props),
        }) ||
        {};

    const setStateForEdit = (column, value) => {
        if (column === 'Credit Card Type') {
            setCreditCardType(value)
        }
    }

    const selectableOrderColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;

            return (edit ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => setStateForEdit(props.column.Header, e.target.value)}>
                {
                    (
                        creditCardType
                    ).map(data =>
                        <option key={data._id} value={data._id}>{data.name}</option>
                    )}
            </Field> : <label>{props.value}</label>)
        }
    };

    const editableOrderComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const paddedValue = value ? String(value).padStart(4, '0') : '';
        const children = (!edit && <HighlightCell value={paddedValue} {...rest} />) || undefined;
        return <Component style={{ textAlign: 'right' }} type="number" min="0" max="9999"  {...input} {...rest} children={children} />;
    };

    const editableOrderColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableOrderComponent,
                edit,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };


    const columns = [
        {
            Header: "Order Id", accessor: "posorder.orderId", ...GridFilters,
        },
        {
            Header: "Telephone", accessor: "posorder.telephone", ...GridFilters,
        },
        { Header: "Payment Methods", accessor: "posorder.payment_method", ...GridFilters },
        { Header: "Outlet", accessor: "outlet.name", ...GridFilters },
        { Header: "Status", accessor: "posorder.status", ...GridFilters },
        {
            Header: "Amount Paid", accessor: "posorder.amountPaid",
            ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.posorder.amountPaid)}
                </div>
        },
        {
            Header: "Order Discount %", accessor: "posorder.discount",
            ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {Number(props.original.posorder.discount).toFixed(2)}
                </div>
        },
        {
            Header: "Total Price", accessor: "posorder.totalPrice",
            ...GridFilters,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.posorder.totalPrice)}
                </div>
        },
        {
            Header: "Created At", accessor: "posorder.created_at", ...GridFilters,
            Cell: props => {
                if (props.original?.posorder?.created_at !== undefined) {
                    var today = new Date(props.original?.posorder?.created_at)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.posorder?.created_at;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "IsOdoo", accessor: "posorder.isOdooStatus", ...GridFilters,
        },
        { Header: "Credit Card No (Last 4 digits)", maxWidth: 115, minWidth: 115, accessor: "posorder.creditCardNo", ...editableOrderColumnProps },
        { Header: "Credit Card Type", maxWidth: 120, minWidth: 120, accessor: "posorder.creditCardType", ...selectableOrderColumnProps },
        {
            Header: "Action",
            maxWidth: 200,
            minWidth: 200,
            Cell: props => (
                <div style={{ textAlign: "right" }}>
                    <button id={`invoice-${props.original.posorder.orderId}`}
                        data-toggle="modal"
                        className="btn btn-link"
                        data-target="#posReceiptPopup"
                        onClick={() => {
                            setPosOrderData([]);
                            let data = {
                                posorder: props.original.posorder,
                                user: props.original.users,
                                deliverywarehouse: props.original.outlet,
                                posorderitems: props.original.posorderitems
                            }
                            setPosOrderData(data);
                        }}
                    >
                        Invoice
                    </button>

                    <button id={`return-${props.original.posorder.orderId}`}
                        data-toggle="modal"
                        className="btn btn-link"
                        data-target="#posReturnPopup"
                        onClick={() => {
                            setCOrder(props.original.posorder)

                            let items = [];

                            props.original.posorderitems.map(item => {
                                item.returntype = '';
                                item.returnqty = 0;

                                items.push(item);
                            })

                            setCOrderItems(items)
                            setDisabled(false)
                        }}
                    >
                        Return
                    </button>
                </div>
            )
        },
        {
            Header: "",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getOrderActionProps,
            Cell: ActionsUpdateCell
        },
    ]

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const editableNumberColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field style={{ textAlign: 'right' }} name={props.column.id} min='0' max={props.original.qty} type="Number" {...fieldProps} />;

        }
    };

    const selectableColumnProps = {
        ...GridFilters,
        Cell: Props => {
            const edit = editing === Props.original;

            return (edit ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {(returnTypeOptions).map(({ value, label }) =>
                        <option
                            key={value} value={value}>
                            {label}
                        </option>
                    )}

                </Field> : <label>{Props.value}</label>)
        }
    };

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('POS', 'POS ORDERS', 'UPDATE', props),
            delPerm: Permission('POS', 'POS ORDERS', 'DELETE', false)
        }) ||
        {};

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                : true
        );
    }

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const subColumns = [
        { Header: "Item Code", accessor: "items.itemCode", ...GridFilters },
        { Header: "Name", accessor: "items.name", ...GridFilters },
        {
            Header: `Quantity - ${_.sumBy(props.orderItemsData, 'posorderitems.qty') || 0}`, accessor: "posorderitems.qty",
            filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.posorderitems.qty}
                </div>
        },

        {
            Header: "Item Discount %", accessor: "posorderitems.discount",
            filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.posorderitems.discount}
                </div>
        },
        {
            Header: "Total Price", accessor: "posorderitems.price",
            filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.posorderitems.price)}
                </div>
        },
    ]

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }



    const retItemsColumns = [
        { Header: "Item Code", accessor: "itemCode", ...GridFilters },
        { Header: "Name", accessor: "itemName", ...GridFilters },
        {
            Header: "Expiry Date", accessor: "",
            filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {formatDate(props.original.expireDate)}
                </div>
        },
        { Header: "Return Type", accessor: "returntype", ...selectableColumnProps },
        { Header: "Order Qty", accessor: "qty", getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Return Qty", accessor: "returnqty", ...editableNumberColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const exportData = () => {
        if (props.orderData.allOrders.length > 0) {
            var excelData = props.orderData.allOrders.map(checkData => {
                return {
                    'OrderId': checkData.posorder.orderId,
                    'Telephone': checkData.posorder.telephone,
                    'Payment Method': checkData.posorder.payment_method,
                    'Outlet': checkData.outlet.name,
                    'Status': checkData.posorder.status,
                    'Amount Paid': Number(checkData.posorder.amountPaid).toFixed(2),
                    'Discount': Number(checkData.posorder.discount).toFixed(2),
                    'Total Price': Number(checkData.posorder.totalPrice).toFixed(2),
                    'Created Date': checkData.posorder.created_at,
                    'isOdoo': checkData.posorder.isOdooStatus,
                    'Credit Card No': checkData.posorder.creditCardNo,
                    'Credit Card Type': checkData.posorder.creditCardType
                }
            })

            ExportData(`POS Orders`, 'Orders', "Orders", excelData)
        }
    }

    const retItemsColumns2 = [
        { Header: "Item Code", accessor: "itemCode", filterable: true },
        { Header: "Name", accessor: "itemName", filterable: true },
        {
            Header: "Expiry Date", accessor: "",
            filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {formatDate(props.original.expireDate)}
                </div>
        },
        {
            Header: "Return Type",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {calculateReturned(props.original.returnedItems, 'type')}
                </div>,
            filterable: true
        },
        {
            Header: `Returned Qty`,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {calculateReturned(props.original.returnedItems, 'returnqty')}
                </div>,
            filterable: true
        },
    ]

    const calculateReturned = (returnedItems, kind) => {
        let totalReturnedQty = 0;
        let returnedTypes = [];

        if (returnedItems) {
            returnedItems.map(returnedItem => {
                totalReturnedQty = totalReturnedQty + returnedItem.returnqty
                if (!returnedTypes.includes(returnedItem.returntype)) {
                    returnedTypes.push(returnedItem.returntype)
                }

                console.log(returnedTypes);
            })

            if (kind === 'returnqty') {
                return totalReturnedQty
            }

            return returnedTypes.join('-');
        } else {
            if (kind === 'returnqty') {
                return 0
            }

            return '';
        }


    }

    const componentRef = useRef();

    const handleSubmit = (value) => {
        if (value.itemId) {
            if (editing && !deleting) {
                if (value.returntype != '') {
                    var index = 0;
                    var cOrderItemsArray = [...cOrderItems];
                    var returnqty = value.returnqty;
                    var returntype = value.returntype;

                    for (var i = 0; i < cOrderItemsArray.length; i++) {
                        if (cOrderItemsArray[i]._id === value._id) {
                            index = i;
                        }
                    }

                    var item = cOrderItemsArray[index];
                    item.returnqty = Number(returnqty);
                    item.returntype = returntype;
                    cOrderItemsArray[index] = item;

                    setCOrderItems(cOrderItemsArray)
                } else {
                    toast.error('Please select the return type')
                }
            }
        }
    };

    const handleOrderSubmit = (values) => {

        if (editing) {
            let data = {
                'CardNo': values.posorder.creditCardNo,
                'CardType': values.posorder.creditCardType,
                'user': props.userId,
                '_id': values.posorder._id,
                'payment_method': values.posorder.payment_method,
            }

            if (values.posorder.creditCardNo !== undefined && values.posorder.creditCardType !== undefined) {
                if (values.posorder.creditCardNo !== '' && values.posorder.creditCardNo !== null && values.posorder.creditCardNo.length !== 4) {
                    toast.error('Credit Card No must be 4 digit number!')
                } else if (values.posorder.creditCardNo !== '' && values.posorder.creditCardNo !== null && (values.posorder.creditCardType === "" || values.posorder.creditCardType === null)) {
                    toast.error('Please enter the credit card type')
                } else if (values.posorder.creditCardType !== '' && values.posorder.creditCardType !== null && (values.posorder.creditCardNo === "" || values.posorder.creditCardNo === null)) {
                    toast.error('Please enter the credit card no')
                } else {
                    props.updatePosOrder({ data: data, user: props.userId, token: props.token, from: date.from, to: date.to })
                }
            } else {
                toast.error('Please enter the credit card information')
            }
        }

    };

    const returnStock = () => {
        let items = [];

        cOrderItems.map(cOrderItem => {
            if (Number(cOrderItem.returnqty) > 0) {
                items.push(cOrderItem)
            }
        })

        if (items.length > 0) {
            let data = {
                'order': cOrder,
                'items': items,
                'userId': props.userId,
                'accountId': accountId
            }
            if (accountId !== null && accountId !== undefined && accountId !== '') {
                axios.defaults.headers.common['authorization'] = props.token;
                axios.post('/pos/returnStock', data)
                    .then(result => {
                        setIsView(true);
                        reload();
                        toast.success('Items returned successfully');
                    }).catch(err => {
                        console.error(err)
                        toast.error(`${err}`)
                    })

                setDisabled(true)
            } else {
                toast.error('Please create the account..!')
            }
        } else {
            toast.error('Please fill up the mandatory fields..!')
        }
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-7">
                            <h4>POS Orders</h4>
                        </div>
                        <div style={{ display: 'flex', float: 'right' }} className="col">
                            <div className="h6">From :</div>
                            &nbsp;
                            <div><input className="form-control" type="date" value={date.from}
                                onChange={e => {
                                    setDate({ ...date, from: e.target.value });
                                }} />
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div className="h6">To :</div>
                            &nbsp;
                            <div><input className="form-control" type="date" value={date.to.split('T')[0]}
                                onChange={e => {
                                    let toDate = new Date(e.target.value);
                                    toDate.setHours(23, 59, 59);
                                    setDate({ ...date, to: toDate.toISOString() });
                                }} />
                            </div>
                            &nbsp;&nbsp;&nbsp;
                        </div>
                        <div className="col" style={{ display: 'flex' }}>
                            <button className='btn btn-success' onClick={exportData}>Export Data</button>
                            <button className="btn btn-primary" onClick={reload} >
                                <i className="fas fa-sync"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <ErrorBoundary>
                    <div className="card-body">
                        {props.isLoading
                            ? <>
                                <SkeletonTable columnsCount={9} dataCount={10} />
                            </>
                            : <FormProvider
                                form="inline"
                                onSubmit={handleOrderSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                initialValues={editing === null ? deleting : editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                columns={columns}
                                                pages={props.orderData.totalPages}
                                                data={props.orderData.result}
                                                defaultPageSize={pageSize}
                                                showFilters={true}
                                                expanded={expanded}
                                                onFetchData={(state, instance) => {
                                                    props.getOrderData({ userId: props.userId, from: date.from, to: date.to, page: state.page + 1, pageSize: state.pageSize, filtered: state.filtered, sorted: state.sorted }, props.token)
                                                }}
                                                manual
                                                defaultFilterMethod={filterCaseInsensitive}
                                                onPageChange={() => setExpanded({ expanded: {} })}
                                                onPageSizeChange={() => setExpanded({ expanded: {} })}
                                                onSortedChange={() => setExpanded({ expanded: {} })}
                                                onFilteredChange={() => setExpanded({ expanded: {} })}
                                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                                SubComponent={row => {
                                                    serOrder_id(row.original.posorder._id)

                                                    return (
                                                        <div>
                                                            {props.orderItemsData !== null ?
                                                                <React.Fragment>
                                                                    <ErrorBoundary>
                                                                        <FormProvider
                                                                            form="inline"
                                                                            enableReinitialize
                                                                        >
                                                                            {formProps => {
                                                                                return (
                                                                                    <form
                                                                                    >
                                                                                        <Table
                                                                                            columns={subColumns}
                                                                                            data={props.orderItemsData}
                                                                                            defaultPageSize={5} />
                                                                                    </form>
                                                                                )
                                                                            }}
                                                                        </FormProvider>
                                                                    </ErrorBoundary>
                                                                </React.Fragment>
                                                                :
                                                                props.orderItemsData === null ? <div>No found data...!</div> : <div>Loading...!</div>}
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </form>)
                                }}
                            </FormProvider>}
                    </div>
                </ErrorBoundary>
                <ErrorBoundary>
                    <div id="posReceiptPopup" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate">
                                <div className="modal-header">
                                    <button
                                        className='btn btn-secondary'
                                        // onKeyDown={this.onKeyPress()}
                                        onClick={() => {
                                            var DocumentContainer = document.getElementById(
                                                "printSection"
                                            );
                                            var WindowObject = window.open(
                                                "PrintWindow",
                                            );
                                            WindowObject.document.writeln(
                                                DocumentContainer.innerHTML
                                            );
                                            WindowObject.document.close();
                                            setTimeout(function () {
                                                WindowObject.focus();
                                                WindowObject.print();
                                                WindowObject.close();
                                            }, 1000);
                                            window.$("#posReceiptPopup").modal('hide')
                                        }}
                                    >
                                        PRINT
                                    </button>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <POS_Print currentOrder={posOrderData} ref={componentRef} />
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>


                <div id="posReturnPopup" className="modal fade" role="dialog">
                    <div className="modal-dialog  modal-lg">
                        <div className="modal-content animate">
                            <div className="modal-header">
                                <h2>Return Stock</h2>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className='card-header'>
                                <button className="btn btn-primary" onClick={() => reload()}>
                                    <i className="fas fa-sync"></i>
                                </button>
                                <button type='button' className='btn btn-primary' onClick={e => setIsView(false)}>+ Add</button>
                                <button type='button' className='btn btn-primary' onClick={e => setIsView(true)}>View</button>
                            </div>
                            {isView ?
                                <div className="modal-body">
                                    <ErrorBoundary>
                                        <FormProvider
                                            form="inline"
                                            enableReinitialize
                                        >
                                            {formProps => {
                                                return (
                                                    <form >
                                                        <Table className="-striped"
                                                            columns={retItemsColumns2}
                                                            data={cOrderItems}
                                                            defaultPageSize={10}
                                                        />
                                                    </form>
                                                );
                                            }}
                                        </FormProvider>
                                    </ErrorBoundary>
                                </div>
                                :
                                <div className="modal-body">
                                    <ErrorBoundary>
                                        <FormProvider
                                            form="inline"
                                            onSubmit={handleSubmit}
                                            onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                            initialValues={editing === null ? deleting : editing}
                                            enableReinitialize
                                        >
                                            {formProps => {
                                                return (
                                                    <form onSubmit={formProps.handleSubmit}>
                                                        <Table className="-striped"
                                                            columns={retItemsColumns}
                                                            data={cOrderItems}
                                                            defaultPageSize={10}
                                                        />
                                                    </form>
                                                );
                                            }}
                                        </FormProvider>
                                    </ErrorBoundary>

                                    <button disabled={disabled} style={{ marginTop: '10px', float: 'right' }} type="button" className="btn btn-primary" onClick={e => returnStock()}>
                                        Return
                                    </button>
                                </div>
                            }

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    token: state.rLogin.token,
    isLoading: state.rLogin.loading,
    userId: state.rLogin.id,
    loggedUser: state.rLogin.name,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    orderData: state.rPOS.orderData,
    orderItemsData: state.rPOS.orderItemsData,
    accounts: state.rPOS.accountData,
})

const mapDispatchToProps = dispatch => ({
    getOrderData: (user, token) => { dispatch(getOrderData(user, token)) },
    getOrderItemsData: (items, token) => { dispatch(getOrderItemsData(items, token)) },
    getPOSAccountsData: (token) => { dispatch(getPOSAccountsData(token)) },
    updatePosOrder: (data, user, token) => { dispatch(updatePosOrder(data, user, token)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(Orders);


