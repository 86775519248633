import React from 'react';

const StockReCal = ({ props }) => {
    return <div id='reCalStock' className="modal fade" role="dialog">
        <div className="modal-dialog">
            <div className="modal-content animate" >
                <div className="modal-header">
                    <h4 className="modal-title text-uppercase">Stock Recalculate</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                    <div className="alert alert-danger" role="alert">
                        WARNING : This will calculate all stocks so it may take a few minutes.
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => props.stockReCal(props)}>proceed...!</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>;
};

export default StockReCal;
