/**
 * @file This file defined for Menu Section
 * @author Bhanuka Chathuranga
 */
import React, { Component } from 'react';
import SubTitle from './SubTitle';
import { NavLink } from 'react-router-dom';

export default class MenuSection extends Component {
    render() {
        return (
            <div className="menu_section">
                <ul className="nav side-menu">
                    {this.props.subTitle1_1 === '' || this.props.subTitle1_1 === null || this.props.subTitle1_1 === undefined
                        ? <li>
                            <NavLink to={`/${this.props.title1}`}><i className={this.props.titleIcon1} /> {this.props.title1}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon1} title={this.props.title1}
                            subTitle1={this.props.subTitle1_1}
                            subTitle2={this.props.subTitle1_2}
                            subTitle3={this.props.subTitle1_3}
                            subTitle4={this.props.subTitle1_4} />
                        // : null
                    }

                    {this.props.subTitle10_1 === '' || this.props.subTitle10_1 === null || this.props.subTitle10_1 === undefined
                        ? <li>
                            <NavLink to={`/${this.props.title10}`}><i className={this.props.titleIcon10} /> {this.props.title10}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon10} title={this.props.title10}
                            subTitle1={this.props.isPermittedSubTitle10_1 ? this.props.subTitle10_1 : null}
                            subTitle2={this.props.isPermittedSubTitle10_2 ? this.props.subTitle10_2 : null}
                            subTitle3={this.props.isPermittedSubTitle10_3 ? this.props.subTitle10_3 : null}
                            subTitle4={this.props.isPermittedSubTitle10_4 ? this.props.subTitle10_4 : null}
                            subTitle5={this.props.isPermittedSubTitle10_5 ? this.props.subTitle10_5 : null}
                            subTitle6={this.props.isPermittedSubTitle10_6 ? this.props.subTitle10_6 : null}
                            subTitle7={this.props.isPermittedSubTitle10_7 ? this.props.subTitle10_7 : null} 
                            subTitle8={this.props.isPermittedSubTitle10_8 ? this.props.subTitle10_8 : null} />
                        // : null
                    }

                    {this.props.subTitle2_1 === '' || this.props.subTitle2_1 === null || this.props.subTitle2_1 === undefined ?
                        <li>
                            <NavLink to={`/${this.props.title2}`}><i className={this.props.titleIcon2} /> {this.props.title2}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon2} title={this.props.title2} isPermitted={this.props.isPermittedSubTitle2_1 || this.props.isPermittedSubTitle2_2}
                            subTitle1={this.props.isPermittedSubTitle2_1 ? this.props.subTitle2_1 : null}
                            subTitle2={this.props.isPermittedSubTitle2_2 ? this.props.subTitle2_2 : null}
                            subTitle3={this.props.isPermittedSubTitle2_3 ? this.props.subTitle2_3 : null}
                            subTitle4={this.props.isPermittedSubTitle2_4 ? this.props.subTitle2_4 : null}
                            subTitle5={this.props.subTitle2_5} />
                    }

                    {this.props.subTitle3_1 === '' || this.props.subTitle3_1 === null || this.props.subTitle3_1 === undefined ?
                        <li>
                            <NavLink to={`/${this.props.title3}`}><i className={this.props.titleIcon3} /> {this.props.title3}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon3} title={this.props.title3}
                            subTitle1={this.props.isPermittedSubTitle3_1 ? this.props.subTitle3_1 : null}
                            subTitle2={this.props.isPermittedSubTitle3_2 ? this.props.subTitle3_2 : null}
                            subTitle3={this.props.isPermittedSubTitle3_3 ? this.props.subTitle3_3 : null}
                            subTitle4={this.props.isPermittedSubTitle3_4 ? this.props.subTitle3_4 : null}
                            subTitle5={this.props.isPermittedSubTitle3_5 ? this.props.subTitle3_5 : null}
                            subTitle6={this.props.isPermittedSubTitle3_6 ? this.props.subTitle3_6 : null}
                            subTitle7={this.props.isPermittedSubTitle3_7 ? this.props.subTitle3_7 : null}
                            subTitle8={this.props.isPermittedSubTitle3_8 ? this.props.subTitle3_8 : null}
                            subTitle9={this.props.isPermittedSubTitle3_9 ? this.props.subTitle3_9 : null}
                            subTitle10={this.props.isPermittedSubTitle3_10 ? this.props.subTitle3_10 : null} />
                    }

                    {this.props.subTitle4_1 === '' || this.props.subTitle4_1 === null || this.props.subTitle4_1 === undefined ?
                        <li>
                            <NavLink to={`/${this.props.title4}`}><i className={this.props.titleIcon4} /> {this.props.title4}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon4} title={this.props.title4} isPermitted={this.props.isPermittedTitle4}
                            subTitle1={this.props.isPermittedSubTitle4_1 ? this.props.subTitle4_1 : null} //subRoute1={this.props.subRoute4_1}
                            subTitle2={this.props.isPermittedSubTitle4_2 ? this.props.subTitle4_2 : null} //subRoute2={this.props.subRoute4_2}
                            subTitle3={this.props.isPermittedSubTitle4_3 ? this.props.subTitle4_3 : null}
                            subTitle4={this.props.isPermittedSubTitle4_4 ? this.props.subTitle4_4 : null}
                            subTitle5={this.props.isPermittedSubTitle4_5 ? this.props.subTitle4_5 : null}
                            subTitle6={this.props.isPermittedSubTitle4_6 ? this.props.subTitle4_6 : null}
                            subTitle7={this.props.isPermittedSubTitle4_7 ? this.props.subTitle4_7 : null}
                            subTitle8={this.props.isPermittedSubTitle4_8 ? this.props.subTitle4_8 : null}
                        />
                    }

                    {this.props.subTitle5_1 === '' || this.props.subTitle5_1 === null || this.props.subTitle5_1 === undefined ?
                        <li>
                            <NavLink to={`/${this.props.title5}`}><i className={this.props.titleIcon5} /> {this.props.title5}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon5} title={this.props.title5}
                            subTitle1={this.props.isPermittedSubTitle5_1 ? this.props.subTitle5_1 : null}
                            subTitle2={this.props.isPermittedSubTitle5_2 ? this.props.subTitle5_2 : null}
                            subTitle3={this.props.isPermittedSubTitle5_3 ? this.props.subTitle5_3 : null}
                            subTitle4={this.props.isPermittedSubTitle5_4 ? this.props.subTitle5_4 : null}
                            subTitle5={this.props.isPermittedSubTitle5_5 ? this.props.subTitle5_5 : null}
                            subTitle6={this.props.isPermittedSubTitle5_6 ? this.props.subTitle5_6 : null}
                            subTitle7={this.props.isPermittedSubTitle5_7 ? this.props.subTitle5_7 : null}
                            subTitle8={this.props.isPermittedSubTitle5_8 ? this.props.subTitle5_8 : null}
                            subTitle9={this.props.isPermittedSubTitle5_9 ? this.props.subTitle5_9 : null}
                            subTitle10={this.props.isPermittedSubTitle5_10 ? this.props.subTitle5_10 : null}
                            subTitle11={this.props.isPermittedSubTitle5_11 ? this.props.subTitle5_11 : null}
                            subTitle12={this.props.isPermittedSubTitle5_12 ? this.props.subTitle5_12 : null} />
                    }

                    {this.props.subTitle6_1 === '' || this.props.subTitle6_1 === null || this.props.subTitle6_1 === undefined ?
                        <li>
                            <NavLink to={`/${this.props.title6}`}><i className={this.props.titleIcon6} /> {this.props.title6}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon6} title={this.props.title6}
                            subTitle1={this.props.isPermittedSubTitle6_1 ? this.props.subTitle6_1 : null}
                            subTitle2={this.props.isPermittedSubTitle6_2 ? this.props.subTitle6_2 : null}
                            subTitle3={this.props.isPermittedSubTitle6_3 ? this.props.subTitle6_3 : null}
                            subTitle4={this.props.isPermittedSubTitle6_4 ? this.props.subTitle6_4 : null}
                            subTitle5={this.props.isPermittedSubTitle6_5 ? this.props.subTitle6_5 : null}
                            subTitle6={this.props.isPermittedSubTitle6_6 ? this.props.subTitle6_6 : null}
                            subTitle7={this.props.isPermittedSubTitle6_7 ? this.props.subTitle6_7 : null}
                        />
                    }

                    {this.props.subTitle9_1 === '' || this.props.subTitle9_1 === null || this.props.subTitle9_1 === undefined ?
                        <li>
                            <NavLink to={`/${this.props.title9}`}><i className={this.props.titleIcon9} /> {this.props.title9}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon9} title={this.props.title9}
                            subTitle1={this.props.isPermittedSubTitle9_1 ? this.props.subTitle9_1 : null}
                            subTitle2={this.props.isPermittedSubTitle9_2 ? this.props.subTitle9_2 : null}
                            subTitle3={this.props.isPermittedSubTitle9_3 ? this.props.subTitle9_3 : null}
                            subTitle4={this.props.isPermittedSubTitle9_4 ? this.props.subTitle9_4 : null}
                            subTitle5={this.props.isPermittedSubTitle9_5 ? this.props.subTitle9_5 : null}
                        />
                    }

                    {this.props.subTitle7_1 === '' || this.props.subTitle7_1 === null || this.props.subTitle7_1 === undefined ?
                        <li>
                            <NavLink to={`/${this.props.title7}`}><i className={this.props.titleIcon7} /> {this.props.title7}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon7} title={this.props.title7}
                            subTitle1={this.props.isPermittedSubTitle7_1 ? this.props.subTitle7_1 : null}
                            subTitle2={this.props.isPermittedSubTitle7_2 ? this.props.subTitle7_2 : null}
                            subTitle3={this.props.isPermittedSubTitle7_3 ? this.props.subTitle7_3 : null}
                            subTitle4={this.props.isPermittedSubTitle7_4 ? this.props.subTitle7_4 : null}
                            subTitle5={this.props.isPermittedSubTitle7_5 ? this.props.subTitle7_5 : null}
                            subTitle6={this.props.isPermittedSubTitle7_6 ? this.props.subTitle7_6 : null}
                            subTitle7={this.props.isPermittedSubTitle7_7 ? this.props.subTitle7_7 : null}
                            subTitle8={this.props.isPermittedSubTitle7_8 ? this.props.subTitle7_8 : null}
                            subTitle9={this.props.isPermittedSubTitle7_9 ? this.props.subTitle7_9 : null}
                            subTitle10={this.props.isPermittedSubTitle7_10 ? this.props.subTitle7_10 : null}
                            subTitle11={this.props.isPermittedSubTitle7_11 ? this.props.subTitle7_11 : null}
                            subTitle12={this.props.isPermittedSubTitle7_12 ? this.props.subTitle7_12 : null}
                            subTitle13={this.props.isPermittedSubTitle7_13 ? this.props.subTitle7_13 : null}
                            subTitle14={this.props.isPermittedSubTitle7_14 ? this.props.subTitle7_14 : null}
                            subTitle15={this.props.isPermittedSubTitle7_15 ? this.props.subTitle7_15 : null}
                            subTitle16={this.props.isPermittedSubTitle7_16 ? this.props.subTitle7_16 : null}
                            subTitle17={this.props.isPermittedSubTitle7_17 ? this.props.subTitle7_17 : null}
                            subTitle18={this.props.isPermittedSubTitle7_18 ? this.props.subTitle7_18 : null}
                            subTitle19={this.props.isPermittedSubTitle7_19 ? this.props.subTitle7_19 : null}
                            subTitle20={this.props.isPermittedSubTitle7_20 ? this.props.subTitle7_20 : null}
                            subTitle21={this.props.isPermittedSubTitle7_21 ? this.props.subTitle7_21 : null}
                            subTitle22={this.props.isPermittedSubTitle7_22 ? this.props.subTitle7_22 : null}
                        />
                    }

                    {this.props.subTitle8_1 === '' || this.props.subTitle8_1 === null || this.props.subTitle8_1 === undefined ?
                        <li>
                            <NavLink to={`/${this.props.title8}`}><i className={this.props.titleIcon8} /> {this.props.title8}</NavLink>
                        </li>
                        : <SubTitle icon={this.props.titleIcon8} title={this.props.title8}
                            subTitle1={this.props.isPermittedSubTitle8_1 ? this.props.subTitle8_1 : null}
                            subTitle2={this.props.isPermittedSubTitle8_2 ? this.props.subTitle8_2 : null}
                            subTitle3={this.props.isPermittedSubTitle8_3 ? this.props.subTitle8_3 : null}
                            subTitle4={this.props.isPermittedSubTitle8_4 ? this.props.subTitle8_4 : null}
                            subTitle5={this.props.isPermittedSubTitle8_5 ? this.props.subTitle8_5 : null} />
                    }



                </ul>
            </div>
        )
    }
}
