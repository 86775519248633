/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";

import { getCashBoxData, updateCashBoxData, transfer, deleteCashBoxData } from '../../../actions/MIS/cash_box_actions';
import Permission from "../../utils/Permission";

const ReactTableFixedColumns = withFixedColumns(Table);


class CashBox extends React.Component {

    state = {
        cashBoxData: [],
        reason: '',
        amount: 0,
        previousPaidAmount: 0,
        editing: null,
        deleting: null,
        sortOptions: [{ id: '_id', desc: true }],
    };

    componentDidMount() {
        this.props.getCashBoxData(this.props.token);
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} type="Number" {...fieldProps} />;
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        previousPaidAmount: rowProps.original.paidamount
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('MIS', 'CASH BOX', 'UPDATE', this.props),
            delPerm: Permission('MIS', 'CASH BOX', 'DELETE', this.props)
        }) ||
        {};

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values;

            var differnceInAmount = Number(data.paidamount) - Number(this.state.previousPaidAmount);

            var cashBoxData = this.props.cashBoxData;
            var previosBalance = 0;

            cashBoxData.forEach(element => {
                var data = element;
                if (element._id === values._id) {
                    data.paidamount = Number(values.paidamount);
                }

                if (data.balance === '' || data.balance === NaN || data.balance === null || Number(data.balance) === 0) {
                    data.balance = Number(previosBalance) + Number(data.paidamount);
                } else {
                    data.balance = Number(data.balance) + Number(differnceInAmount);
                }

                data.paidamount = Number(data.paidamount);
                data.totalprice = Number(data.totalprice);

                this.props.updateCashBoxData(data, this.props.token);
                previosBalance = data.balance;
            });

            toast.success('Cash Box Updated Successfully!');
        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values._id
            }

            this.props.deleteCashBoxData(data, this.props.token);
        }
    };

    formatDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    transfer = () => {
        var { reason, amount } = this.state;

        if (reason !== '' && amount !== '') {

            const cashBoxData = this.props.cashBoxData;
            var lastRecord = cashBoxData[cashBoxData.length - 1];
            var balance = lastRecord.balance;

            var date = this.formatDate(Date.now());
            var user = this.props.loggedInUserName.first;
            var name = this.state.reason;
            var paidamount = Number((this.state.amount) * -1).toFixed(2);
            var balance = Number((balance - this.state.amount).toFixed(2));

            const data = {
                date: date,
                user: user,
                orderid: '',
                name: name,
                totalprice: '',
                paidamount: paidamount,
                balance: balance
            };

            this.props.transfer(data, this.props.token);

            this.setState({
                reason: '',
                amount: 0,
            });

            document.getElementById("transferForm").reset();

        } else {
            toast.error('Please fill both the fields!');
        }
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    columns = [
        { Header: "Date", accessor: "date", filterable: true },
        { Header: "User", accessor: "user", filterable: true },
        { Header: "Order ID", accessor: "orderid", filterable: true },
        { Header: "name", accessor: "name", filterable: true },
        { Header: "Total Price", accessor: "totalprice", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Paid Amount", accessor: "paidamount", ...this.editableNumberColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Balance", accessor: "balance", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('MIS', 'CASH BOX', 'ADD', this.props)} data-toggle="modal" data-target="#transfer">+ Transfer</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.columns}
                                                    data={this.props.cashBoxData}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>

                    <div id="transfer" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Transfer</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <form id='transferForm'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Reason* :</td>
                                                    <td><input type="text" className="form-control" onChange={e => this.setState({ reason: e.target.value })} /></td>
                                                </tr>

                                                <tr>
                                                    <td>Amount* :</td>
                                                    <td><input type="number" className="form-control" onChange={e => this.setState({ amount: e.target.value })} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </form>

                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={this.transfer}>Transfer</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        cashBoxData: state.rCashBox.cashBoxData,
        loggedInUserName: state.rLogin.name,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCashBoxData: (token) => { dispatch(getCashBoxData(token)) },
        updateCashBoxData: (data, token) => { dispatch(updateCashBoxData(data, token)) },
        transfer: (data, token) => { dispatch(transfer(data, token)) },
        deleteCashBoxData: (data, token) => { dispatch(deleteCashBoxData(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashBox);


