import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';

import { Permission } from '../../../../utils'
import ComplaintViewTable from './ComplaintViewTable';
import AddComplaint from './AddComplaint';

const resolveMethodFunc = (resolveMethod, props) => {
    let val = props.resolveMethod?.filter(ele => ele._id === resolveMethod)
    return val[0]?.slug
}

const addComplaintItem = (e, defectItems, orderItemsData, setDefectItems) => {
    let index = defectItems?.findIndex(obj => obj.items._id === e._id)
    if (index < 0) {
        let [obj, ...rest] = orderItemsData?.filter(ele => ele.items._id === e._id)
        obj.orderitems.defectQty = obj.orderitems.quantity
        obj.orderitems.supplier_bare = 100
        obj.orderitems.expQty = 0
        obj.orderitems.itemCode = obj.items.itemCode
        obj.exp_items = []
        let items = [...defectItems, obj]
        setDefectItems(items)
    } else {
        toast.info('Already added...!')
    }
}

const getStocksData = (orderId, setStockData, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    axios.get('/ordering/getStocksWithOrderItems', { headers: { authorization: token }, params: { id: orderId } })
        .then(result => {
            console.log(result);
            setStockData(result.data)
        }).catch(err => {
            console.error(err);
        })
}

const resolveFunc = [
    { id: 0, value: 'Create purchase order' },
    { id: 1, value: 'Get from stock' },
]

const CustomerComplaintModal = ({ orderItemsData,
    complaintTypeData, resolvedData, complaintItem, edit,
    remove, sortOptions, customerComplaintsColumns, filterCaseInsensitive, getTdProps,
    customerComplaintsData, setEdit, setRemove,
    reloadCustomerComplaintsData, currentOrderId,
    setShowAddCustomerComplaintForm, handleCustomerComplaintSubmit,orderData, props }) => {

    const [resolveMethod, setResolveMethod] = useState('');
    const [orderId, setOrderId] = useState('');
    const [items, setItems] = useState([]);
    const [viewHandler, setViewHandler] = useState({ view: true, add: false, update: false });
    // const [defectItems, setDefectItems] = useState([]);
    // const [returnOrderItem, setreturnOrderItem] = useState([]);
    const [isClose, setIsClose] = useState(false);
    const [stockData, setStockData] = useState([]);
    const [activeStatus, setActiveStatus] = useState(true);
    const [addcomplaint, setAddcomplaint] = useState({
        'complain': '',
        'type': '',
        'resolved': 'No',
        'resolvedMethod': '',
        'order_ref': '',
        'item': [],
        'images': '',
        'account': { isAccount: false, name: '' }
    });

    useEffect(() => {
        props.getAccountsData(props)
    }, [])

    useEffect(() => {
        if (orderItemsData !== null) {
            let arr = orderItemsData?.map(ele => ele.items)
            setItems(arr)
            setOrderId(orderItemsData[0].orderitems?.order_ref)
        }

        return () => {
            setItems([])
        }
    }, [orderItemsData])

    useEffect(() => {
        setAddcomplaint({ ...addcomplaint, 'resolved': 'No', 'account': { isAccount: false, name: '' } })
        if (resolveMethodFunc(resolveMethod, props) === 'return-order') {
            getStocksData(orderId, setStockData, props)
        }
    }, [resolveMethod])

    useEffect(() => {
        if (viewHandler.view) {
            document.getElementById('viewComplaint').classList.add('show')
            document.getElementById('addComplaint').classList.remove('show')
        }
        if (viewHandler.add) {
            document.getElementById('addComplaint').classList.add('show')
            document.getElementById('viewComplaint').classList.remove('show')
        }
    }, [viewHandler])


    return (
        <div id='customerComplaintsPopup' key='customerComplaintsPopup' className="modal fade" role="dialog">
            <div className="modal-dialog modal-xl ">
                <div className="modal-content animate ">
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase" >{activeStatus ? 'Customer Complaints' : 'Add Expire Dates'}</h4>
                        <button type="button" className="close" hidden={!activeStatus} data-dismiss="modal" onClick={() => { setViewHandler({ view: true, add: false, update: false }); props.reloadData(props); setIsClose(true) }}>&times;</button>
                    </div>
                    {activeStatus ?
                        <div className='card-header'>
                            <button type="button" disabled={Permission('ORDERS', 'COMPLAINS', 'VIEW', props)} className="btn btn-primary" onClick={() => reloadCustomerComplaintsData()}>
                                <i className="fa fa-sync"></i>
                            </button>
                            <button type="button" disabled={Permission('ORDERS', 'COMPLAINS', 'ADD', props)} className="btn btn-primary"
                                onClick={e => setViewHandler({ view: false, add: true })}>
                                + Add
                            </button>
                            <button type="button" disabled={Permission('ORDERS', 'COMPLAINS', 'VIEW', props)} className="btn btn-primary"
                                onClick={e => setViewHandler({ view: true, add: false })}>
                                View
                            </button>
                        </div>
                        : ""}
                    <div className="modal-body">
                        <div class="row">
                            <div class="col">
                                <div>
                                    <div className="collapse multi-collapse" id="addComplaint">
                                        <div class="card card-body">
                                            <AddComplaint addcomplaint={addcomplaint} edit={edit} remove={remove} setAddcomplaint={setAddcomplaint} setEdit={setEdit} setRemove={setRemove}
                                                // complaintTypeData={complaintTypeData} resolvedData={resolvedData} 
                                                stockData={stockData} setStockData={setStockData} setViewHandler={setViewHandler}
                                                resolveMethod={resolveMethod} setResolveMethod={setResolveMethod} orderItemsData={orderItemsData} items={items} complaintItem={complaintItem} addComplaintItem={addComplaintItem}
                                                currentOrderId={currentOrderId} isUpdate={false} isClose={isClose} setIsClose={setIsClose} reloadCustomerComplaintsData={reloadCustomerComplaintsData} orderData={orderData} activeStatus={activeStatus} setActiveStatus={setActiveStatus} props={props} />
                                        </div>
                                    </div>
                                    <ComplaintViewTable customerComplaintsData={customerComplaintsData} handleCustomerComplaintSubmit={handleCustomerComplaintSubmit} edit={edit}
                                        remove={remove} setEdit={setEdit} setRemove={setRemove} sortOptions={sortOptions} customerComplaintsColumns={customerComplaintsColumns}
                                        filterCaseInsensitive={filterCaseInsensitive} getTdProps={getTdProps} 
                                        // complaintTypeData={complaintTypeData} resolvedData={resolvedData} 
                                        orderItemsData={orderItemsData} orderData={orderData}
                                        reloadCustomerComplaintsData={reloadCustomerComplaintsData} viewHandler={viewHandler} setViewHandler={setViewHandler} items={items} isClose={isClose} setIsClose={setIsClose} activeStatus={activeStatus} setActiveStatus={setActiveStatus} setStockData={setStockData} props={props} />
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="modal-footer">
                        <button type="button" hidden={!activeStatus} className="btn btn-secondary" data-dismiss="modal" onClick={() => { setViewHandler({ view: true, add: false, update: false }); props.reloadData(props); setIsClose(true) }}>Close</button>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default CustomerComplaintModal
