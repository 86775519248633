/**
 * @file
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Table from "react-table";
import "react-table/react-table.css";
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import makeAnimated from 'react-select/animated';
import { getSupplierData } from '../../../actions/Purchasing/purchasing_supplier_action';
import ErrorBoundary from '../../components/ErrorBoundary';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { toast } from 'react-toastify';
import { element } from 'prop-types';
import ExportData from '../../utils/ExportData';

function CustomerVsSupplier(props) {

    const fromDate = () => {
        let fromDate = new Date().toISOString().split('T')
        let splitTime = fromDate[1].split('.')
        let timeString = `00:00:00.${splitTime[1]}`
        let newToDate = `${fromDate[0]}T${timeString}`
        return newToDate;
    }

    const toDate = () => {
        let fromDate = new Date().toISOString().split('T')
        let splitTime = fromDate[1].split('.')
        let timeString = `23:59:59.${splitTime[1]}`
        let newToDate = `${fromDate[0]}T${timeString}`
        return newToDate;
    }

    const [search, setSearch] = useState({ from: fromDate(), to: toDate(), supplier: [] })
    const [data, setData] = useState([]);
    const [orders, setOrders] = useState({ order: [], orderItems: [] })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        props.getSupplierData(props)
    }, [])

    const getData = () => {
        console.log(search);
        setIsLoading(true)
        axios.get('/purchasing/getCustomerVsSupplierData', { headers: { authorization: props.token }, params: { search } })
            .then(result => {
                console.log(result.data);
                setData(result.data)
                let uniqOrder = _.uniqBy(result.data.order, 'telephone');
                // let uniqOrderArr = [...new Set(orderItem.map(obj => ObjectId(obj.orderitems._id)))]
                setOrders({ ...orders, order: uniqOrder, orderItems: result.data.orderItem })
                setIsLoading(false)
                // setOrders({ ...orders, orderItems: result.data.orderItem })
            }).catch(error => {
                setIsLoading(false)
                console.error(error);
                // toast.error(error.ms)
            })
    }

    const exportData = async () => {
        let export_data = await Promise.all(await orders.order.map(ele => {
            let orderData = _.filter(data.order, { 'telephone': ele.telephone }).map(obj => obj.orderItems)
            var orderArr = [].concat.apply([], orderData)
            let subData = orders.orderItems.filter(val => orderArr.includes(val.orderitems._id))
            let checkVal = Object.values(subData.reduce((c, v) =>
                Object.assign(c, { [v.items._id]: { name: ele.name, "Item code": v.items.itemCode, item: v.items.name, Supplier: v.supplier.name, qty: _.sumBy(subData, function (o) { if (o.items._id === v.items._id) { return o.orderitems.quantity } }) } })
                , {})
            )

            return _.flattenDepth(checkVal, 2)
        }))

        console.log(export_data.flat());
        ExportData('CustomerVsSupplier', 'CustomerVsSupplier', 'sheet1', export_data.flat())
    }

    const columns = [
        {
            Header: "Customer Name", accessor: "name", filterable: true
        },
    ]

    const subColumns = [
        {
            Header: "Item Name", accessor: "v.items.name", filterable: true
        },
        {
            Header: "Item Code", accessor: "v.items.itemCode", filterable: true
        },
        {
            Header: "Qty", accessor: "qty", filterable: true,
            // Cell: row => {
            //     console.log();
            //     return (
            //         <div>
            //             {/* {_.sumBy(_.filter('subData', { 'items._id': row.items._id }), 'orderitems.quantity')} */}
            //         0
            //         </div>)
            // }
        },
        {
            Header: "Supplier", accessor: "v.supplier.name", filterable: true
        },
    ]

    
    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>From :</div>
                        <div><input className="form-control" type="date" value={search.from.split('T')[0]}
                            onChange={e => {
                                let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                let splitTime = toDate[1].split('.')
                                let timeString = `00:00:00.${splitTime[1]}`
                                let newToDate = `${toDate[0]}T${timeString}`
                                setSearch({ ...search, from: newToDate })
                            }} />
                        </div>
                        <div>To :</div>
                        <div><input className="form-control" type="date" value={search.to.split('T')[0]}
                            onChange={e => {
                                let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                let splitTime = toDate[1].split('.')
                                let timeString = `23:59:59.${splitTime[1]}`
                                let newToDate = `${toDate[0]}T${timeString}`
                                setSearch({ ...search, to: newToDate })
                            }} />
                        </div>
                        <div>Supplier :</div>
                        <div style={{ minWidth: '180px' }}>
                            <Select
                                theme={theme => ({ ...theme, borderRadius: 0 })}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                isMulti={true}
                                options={props.supplier}
                                // value={search.supplier}
                                onChange={e => {
                                    if (e?.length > 0) {
                                        console.log(e.length);
                                        let ids = e.map(obj => obj.supplier._id)
                                        setSearch({ ...search, supplier: ids })
                                    } else {
                                        setSearch({ ...search, supplier: [] })
                                    }
                                }}

                            />
                        </div>
                        <div>
                            <button className="btn btn-success" onClick={getData}>Find</button>
                        </div>
                        <div>
                            <button className="btn btn-success" onClick={exportData}>Export data</button>
                        </div>
                    </div>
                </div>
            </div>
            {props.isLoading || isLoading
                ? <div className="card-body">
                    <SkeletonTable />
                </div>
                : orders.order.length <= 0
                    ? <div className="card-body">
                        No Data found...!
                    </div>
                    : <div className="card-body">
                        <ErrorBoundary>
                            <Table
                                className="-striped"
                                data={orders.order}
                                columns={columns}
                                defaultPageSize={10}
                                defaultFilterMethod={filterCaseInsensitive}
                                SubComponent={row => {
                                    // console.log(row);
                                    // console.log(data);
                                    let orderData = _.filter(data.order, { 'telephone': row.original.telephone }).map(obj => obj.orderItems)
                                    // let orderData = data?.order?.filter(obj => {
                                    //     return obj.name === row.original.name
                                    // })
                                    var orderArr = [].concat.apply([], orderData)
                                    // console.log('orders', orders.order);
                                    // console.log('order data', orderData);
                                    // console.log('order array', orderArr);
                                    // console.log('all order items', orders.orderItems);
                                    // let subData = orderArr.filter(val => valorders.orderItems?.orderitems?._id)
                                    let subData = orders.orderItems.filter(val => orderArr.includes(val.orderitems._id))
                                    let checkVal = Object.values(subData.reduce((c, v) =>
                                        Object.assign(c, { [v.items._id]: { v, qty: _.sumBy(subData, function (o) { if (o.items._id === v.items._id) { return o.orderitems.quantity } }) } })
                                        , {})
                                    )
                                    // console.log('table data', subData);
                                    // console.log(checkVal);
                                    return (
                                        <ErrorBoundary>
                                            <Table
                                                className="-striped"
                                                data={checkVal}
                                                columns={subColumns}
                                                defaultPageSize={5}
                                                showPagination={checkVal.length > 6 ? true : false}
                                                defaultFilterMethod={filterCaseInsensitive}
                                            />
                                        </ErrorBoundary>
                                    )
                                }}
                            />
                        </ErrorBoundary>
                    </div>}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.rLogin.loading,
        token: state.rLogin.token,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        supplier: state.rSupplier.data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSupplierData: ({ token }) => { dispatch(getSupplierData(token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerVsSupplier)
