/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const deliveryChargesAsync = data => {
    return { type: 'FETCH_DELIVERY_CHARGES', data }
}

export const brandIdAsync = data => {
    return { type: 'FETCH_BRAND_DATA', data }
}

export const accountsPOSDataAsync = data => {
    return { type: 'FETCH_ACCOUNT_DATA', data }
}

export const stockExpireItemsDataAsync = data => {
    return { type: 'FETCH_ALL_EXPIRE_ITEMS_DATA', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const POSRunningDataAsync = data => {
    return { type: 'FETCH_POS_RUNNING_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
export const getBrands = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/deliverycharges/getDeliveryCharges')
            .then(result => {
                dispatch(deliveryChargesAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const validate = (token, data, callback) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/validate', data)
            .then(result => {
                toast.success('Order added.')
                let orderId = data.orderId
                axios.defaults.headers.common['authorization'] = token;
                axios.post('/pos/getPrintData', { orderId })
                    .then(result => {
                        callback(result.data)
                    }).catch(err => {
                        console.error(err)
                    })
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getBrandStockData = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/getStockByBrand', { data })
            .then(result => {
                dispatch(brandIdAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * @param {*} token
 * @param user  
 */
export const getPOSAccountsData = ({ token, userId }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/getAccountsData', { userId })
            .then(result => {
                dispatch(accountsPOSDataAsync(result.data));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}
/**
* @param {*} token
* @param user  
*/
export const getAllStockExpireItems = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/getAllStockExpireItems', { data })
            .then(result => {
                console.log(result.data)
                dispatch(stockExpireItemsDataAsync(result.data));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addDisposeStock = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/addDisposeStock', { data })
            .then(result => {
                toast.success('Successfully added disposed item');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getPOSStockRunningData = (data, token) => {
    return dispatch => {
        dispatch(isLoadingAsync(true));
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/pos/getStockRunningReportData',data)
            .then(result => {
                let months = getAllMonths()

                let stockMovingData = result.data

                let retData = []

                stockMovingData.map(element => {
                    let obj = {
                        'itemCode': element.itemCode,
                        'itemName': element.itemName,
                        'supplier': element.supplier,
                        'status': element.status,
                        [months[0]]: element[months[0]] ? element[months[0]] : 0,
                        [months[1]]: element[months[1]] ? element[months[1]] : 0,
                        [months[2]]: element[months[2]] ? element[months[2]] : 0,
                        [months[3]]: element[months[3]] ? element[months[3]] : 0,
                        [months[4]]: element[months[4]] ? element[months[4]] : 0,
                        [months[5]]: element[months[5]] ? element[months[5]] : 0,
                        [months[6]]: element[months[6]] ? element[months[6]] : 0,
                        [months[7]]: element[months[7]] ? element[months[7]] : 0,
                        [months[8]]: element[months[8]] ? element[months[8]] : 0,
                        [months[9]]: element[months[9]] ? element[months[9]] : 0,
                        [months[10]]: element[months[10]] ? element[months[10]] : 0,
                        [months[11]]: element[months[11]] ? element[months[11]] : 0,

                    }

                    retData.push(obj)
                })

                dispatch(isLoadingAsync(false))
                dispatch(POSRunningDataAsync(retData))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}


const getAllMonths = () => {
    let mappedMonths = getMappedMonths()
    let retItems = []

    let d = new Date()

    let month = d.getMonth()
    let currentYear = d.getFullYear()
    let previousYear = currentYear - 1

    //for previous year
    if (month !== 0) {
        for (let i = month + 1; i <= 11; i++) {
            retItems.push(mappedMonths[i] + '' + previousYear)
        }

        for (let i = 0; i <= month; i++) {
            retItems.push(mappedMonths[i] + '' + currentYear)
        }
    } else {
        for (let i = 0; i <= 11; i++) {
            retItems.push(mappedMonths[i] + '' + currentYear)
        }
    }

    return retItems

}

const getMappedMonths = () => {
    return [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]
}

