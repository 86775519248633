
/**
 * @file This file defines for Login page
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Button, CssBaseline, TextField, Link, Paper, Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { NavLink, useParams  } from 'react-router-dom';
import axios from 'axios';


import { connect } from 'react-redux';
import { login } from './actions/login_action';

import one from './images/1.png';
import two from './images/2.png';
import three from './images/3.png';
import four from './images/4.png';
import five from './images/5.png';
import six from './images/6.png';
import seven from './images/7.png';
import eight from './images/8.jpg';

const pic = [one, two, three, four, five, six, seven, eight]

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://ciao.lk/">
                CIAO
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

var randomVal = () => {

    return Math.floor(Math.random() * pic.length)
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${pic[randomVal()]})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#344955',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const getTitle = setTitle => {
    axios.get('/login/getTitle')
        .then(result => {
            setTitle(result.data.value)
        }
        ).catch(err => {
            console.error(err)
            setTitle('')
        })
}



function LoginPage(props) {

    
    const classes = useStyles();
    var [email, setEmail] = useState('');
    var [password, setPassword] = useState('');
    var [errEmail, setErrEmail] = useState('');
    var [errPass, setErrPass] = useState('');
    const [title, setTitle] = useState('');
    const [orderId, setOrderId] = useState('');

    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const submitRef = useRef(null);

    useEffect(() => {

        getTitle(setTitle)

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        // Extract the value of the 'orderId' parameter
        const orderId = urlParams.get('orderId');
        const reDirect = urlParams.get('reDirect');

        // Set the orderId state
        setOrderId(orderId);

        if (reDirect === "onlineinvoice") {
            document.getElementById('login-page').classList.toggle('visually-hidden')
            window.onload = function () {
                var btn1 = document.getElementById('io')
                btn1.click()
            }
        } else if (reDirect === "customerOrder") {
            document.getElementById('login-page').classList.toggle('visually-hidden')
            window.onload = function () {
                var btn = document.getElementById('co')
                btn.click()
            }
        }
    }, [])

    useEffect(() => {
        document.title = title
    }, [title])


    useEffect(() => {
        $('#error').hide()
    })

    var ValidateEmail = () => {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            if (password.trim().length > 0) {
                props.userLogin(email, password)
            } else {
                alert("You have entered an invalid email address!")
                console.log('false')
            }
        } else {
            alert("You have entered an invalid email address!")
            console.log('false')
        }
    }

    function emailRefHandle(e) {
        if (e.key === 'Enter') {
            passwordRef.current.focus()
        }
    }

    function passwordRefHandler(e) {
        if (e.key === 'Enter') {
            submitRef.current.focus()
        }
    }

    return (
        <Grid id='login-page' container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={6} md={8} className={classes.image} />
            <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        <NavLink id='co' to={`/onlinepurchase`} style={{ pointerEvents: 'none' }}>Sign in</NavLink>
                        <NavLink id='io' to={`/onlineinvoice/${orderId}`} style={{ pointerEvents: 'none' }}>Sign in</NavLink>
                    </Typography>
                    <div className={classes.form} noValidate>
                        {typeof InstallTrigger !== 'undefined' ?
                            <div class="alert alert-warning" role="alert">
                                <i class="fas fa-exclamation-triangle"></i> You are currently using firefox. Some functions may not support firefox.
                            </div>
                            : null}
                        <div id="error" className="alert alert-danger">
                            This is a primary alert—check it out!
                        </div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            inputRef={emailRef}
                            onKeyDown={emailRefHandle}
                            autoFocus
                            onChange={e => {
                                setEmail(e.target.value.trim());
                                if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value.trim())) {
                                    setErrEmail('')
                                } else {
                                    setErrEmail('Invalid Email')
                                }
                            }}
                            error={errEmail !== '' ? true : false}
                            helperText={errEmail}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            inputRef={passwordRef}
                            onKeyDown={passwordRefHandler}
                            autoComplete="current-password"
                            onChange={e => { setPassword(e.target.value); setErrPass('') }}
                            onBlur={() => {
                                if (password.trim().length > 3) {
                                    setErrPass('')
                                } else {
                                    setErrPass('Password length short')
                                }
                            }}
                            error={errPass !== '' ? true : false}
                            helperText={errPass}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            ref={submitRef}
                            className={classes.submit}
                            disabled={errEmail !== '' || errPass !== '' ? true : false}
                            onClick={ValidateEmail}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                            </Grid>
                            <Grid item>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </div>
                </div>
            </Grid>
        </Grid >
    );
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.rLogin.isLoggedIn,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        userLogin: (email, password) => { dispatch(login(email, password)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);