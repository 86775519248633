/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import axios from 'axios';
import "react-table/react-table.css";


import { toast } from "react-toastify";
import _, { isString } from 'lodash';

// import withFixedColumns from 'react-table-hoc-fixed-columns';
// import 'react-table-hoc-fixed-columns/lib/styles.css';

import ExportDataCSV from '../../utils/ExportDataCSV';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import Select from 'react-select';

import SearchableSelectComp from "../../components/SearchableSelectComp";

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getAccountsData, addAccount, updateAccount, deleteAccount, getTransactions, updateTransaction, deleteTransaction, addTransaction, getCustomerPayable } from '../../../actions/Acc/account_actions';
import { getCurrencies } from '../../../actions/Basic_data/currency_actions';
import { PriceFormat } from "../../utils";

import checkboxHOC from "react-table/lib/hoc/selectTable";
const CheckboxTable = checkboxHOC(Table);
// const ReactTableFixedColumns = withFixedColumns(Table);

class Accounts extends React.Component {

    oneMonthAgo = new Date();

    accountTypeData = [
        { '_id': 'Cash', 'name': 'Cash' },
        { '_id': 'Expenses', 'name': 'Expenses' },
        { '_id': 'Income', 'name': 'Income' },
        { '_id': 'Fixed Assets', 'name': 'Fixed Assets' },
        { '_id': 'Payable', 'name': 'Payable' },
    ];

    state = {
        accounts: [],
        transactions: [],
        users: [],
        orders: [],
        currencies: [],
        acc_name: '',
        typeTo: '',
        typeFrom: '',
        balance: 0,
        createdAt: Date.now(),
        effectiveDate: '',
        expanded: {},
        user: '',
        reference: '',
        accountTo: '',
        value: 0,
        flag: false,
        currentAccount: {},
        exportDateTo: '',
        exportDateFrom: '',
        currentAccountId: '',
        transactionsForAccount: [],
        selectedCurrency: '',
        accountTypeFrom: '',
        accountTypeTo: '',
        selection: [],
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'createdAt', desc: true }],
        sortOptionsTR: [{ id: 'transactions.createdAt', desc: true }],

    };

    componentDidMount() {
        this.props.getAccountsData(this.props.token);
        this.props.getTransactions(this.props.token);
        this.props.getCurrencies(this.props.token);
        this.props.getCustomerPayable(this.props.token);
        this.oneMonthAgo.setMonth(this.oneMonthAgo.getMonth() - 1)
        this.setState(
            {
                user: this.props.userId
            }
        );
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        if (hours.length < 2) {
            hours = '0' + hours;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        var date = [year, month, day].join('-');
        var time = [hours, minutes, seconds].join(':')

        if (year === 'NaN' || month === 'NaN' || day === 'NaN' || hours === 'NaN' || minutes === 'NaN' || seconds === 'NaN') {
            return '';
        }

        return [date, time].join(' ');
    }

    formatOnlyDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }


        var date = [year, month, day].join('-');

        return date;
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        if (this.isDecimal(value) && !this.isAlphaNumeric(value) && !this.isDate(value) && !this.hasWhiteSpace(value)) {
            value = Number(value).toFixed(2)
        }

        if (input.name === 'transactions.effectiveDate') {
            value = value === null || value === '' || value === undefined ? '' : this.formatOnlyDate(value);
        }

        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    isDecimal = (value) => {
        return value % 1 === 0 ? false : true;
    }

    isAlphaNumeric = (value) => {
        if (/[^a-zA-Z0-9]/.test(value)) {
            return false;
        }

        return true;
    }

    isDate = (value) => {
        var timestamp = Date.parse(value);

        if (isNaN(timestamp) === true) {
            return false;
        }

        return true;
    }

    hasWhiteSpace = (value) => {
        if (isString(value)) {
            return value.indexOf(' ') >= 0;
        }

        return false;
    }

    editableNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} type="Number" {...fieldProps} />;
        }
    };

    editableColumnPropsDate = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;

            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} type="date" {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };


    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {
                    (
                        props.column.Header === 'Currency' ? this.props.currencies :
                            this.accountTypeData
                    ).map(data =>
                        <option key={data._id} value={data._id}>{data.name}</option>
                    )}
            </Field> : <label>{props.value}</label>)
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        status: rowProps.original.status,
                        flag: false,
                        selectedCurrency: rowProps.original.currencyId
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original, flag: false })
                }
            },
            updPerm: Permission('ACC', 'ACCOUNTS', 'UPDATE', this.props),
            delPerm: Permission('ACC', 'ACCOUNTS', 'DELETE', this.props)
        }) ||
        {};

    getActionPropsTransactions = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        user: rowProps.original.transactions.user,
                        reference: rowProps.original.transactions.reference,
                        flag: true
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original, flag: true })
                }
            },
            updPerm: Permission('ACC', 'ACCOUNTS', 'UPDATE', this.props),
            delPerm: Permission('ACC', 'ACCOUNTS', 'DELETE', false)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === 'Type') {
            this.setState({ type: value })
        } else if (column === 'Currency') {
            this.setState({ selectedCurrency: value })
        }
    }

    handleSubmit = values => {
        if (this.state.flag) {
            this.handleSubmitTransaction(values);
        } else {
            if (this.state.deleting === null && this.state.editing !== null) {
                var data = values;
                data.user = this.state.user;
                data.currency = this.state.selectedCurrency;

                this.props.updateAccount(data, this.props.token);
                this.setState({ type: '', selectedCurrency: '' });
            } else if (this.state.deleting !== null && this.state.editing === null) {
                var data = {
                    '_id': values._id
                }

                this.props.deleteAccount(data, this.props.token)
            }
        }
    };

    addAccountHandle = () => {
        var { acc_name,
            type,
            balance,
            createdAt
        } = this.state;

        if (acc_name.trim() !== '' & type.trim() !== '') {

            this.state.createdAt = this.formatDate(Date.now());
            this.state.balance = Number(this.state.balance);

            this.props.addAccount(this.state, this.props.token);

            this.clearAddAccountForm();

        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    clearAddAccountForm = () => {
        document.getElementById("addAccountForm").reset();
        this.setState({
            acc_name: '',
            type: '',
            balance: 0,
            createdAt: '',
            selectedCurrency: '',
        });
    }

    addTransactionHandle = () => {
        var { currentAccount,
            accountTo,
            value,
            reference,
        } = this.state;

        var accountToArr = accountTo.split('-');

        if (accountToArr[0].trim() !== '' && value !== '' && reference.trim() !== '') {

            this.state.createdAt = Date.now();

            this.state.balance = currentAccount.balance - this.state.value;
            this.state.typeFrom = this.state.currentAccount.acc_name;
            this.state.typeTo = this.state.typeTo;
            this.state.accountTo = accountToArr[0];

            this.props.addTransaction(this.state, this.props.token);

            this.resetAddTransactionForm();

            document.getElementById("addTransactionForm").reset();
            window.$('#addTransaction').modal('hide');
            toast.success('Transaction Added Successfully!');
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    resetAddTransactionForm = () => {
        this.setState({
            accountTo: '',
            currentAccount: '',
            value: 0,
            reference: '',
            balance: '',
            createdAt: '',
            effectiveDate: '',
            expectedDate: '',
            typeFrom: '',
            typeTo: '',
            selection: []
        });

        document.getElementById("addTransactionForm").reset();
    }

    setExportDateFrom = date => {
        this.setState({
            exportDateFrom: date
        });
    }

    setExportDateTo = date => {
        this.setState({
            exportDateTo: date
        });
    }

    exportData = () => {

        if (this.state.exportDateFrom !== '' && this.state.exportDateTo !== '') {
            var exportData = this.state.transactionsForAccount.filter(element => {
                if ((this.formatOnlyDate(element.transactions.createdAt) >= this.formatOnlyDate(this.state.exportDateFrom))
                    && ((this.formatOnlyDate(element.transactions.createdAt) <= this.formatOnlyDate(this.state.exportDateTo)))) {
                    return element;
                }
            })

            if (exportData.length > 0) {
                var excelData = exportData.map(element => {
                    var currency = _.filter(this.props.currencies, function (curr) {
                        return element.accounts.ref_currency === curr._id
                    })

                    const inputDatetimeStr = String(element.transactions.effectiveDate);
                    const inputDatetime = new Date(inputDatetimeStr);
                    const month = (inputDatetime.getUTCMonth() + 1 < 10 ? '0' : '') + (inputDatetime.getUTCMonth() + 1);
                    const day = (inputDatetime.getUTCDate() < 10 ? '0' : '') + inputDatetime.getUTCDate();
                    const year = inputDatetime.getUTCFullYear();
                    const outputDateStr = `${day}/${month}/${year}`;

                    return {
                        'Transaction Date': outputDateStr,
                        'Description': element.transactions.reference.trim(),
                        'Currency': currency.length > 0 ? currency[0].code : '',
                        'Debit': element.transactions.creditType === 'debit' ? Number(element.transactions.value).toFixed(2) : '',
                        'Credit': element.transactions.creditType === 'credit' ? Number(element.transactions.value).toFixed(2) : '',
                    }
                })

                ExportDataCSV(exportData[0].accounts.acc_name, 'Transactions', 'Transactions', excelData);
                this.resetExportDataForm();
            } else {
                toast.error('No Data to export!')
            }


        } else {
            toast.error('Please fill up the date range!')
        }


    }

    resetExportDataForm = () => {
        this.setState({
            exportDateFrom: '',
            exportDateTo: '',
            currentAccount: '',
        });

        document.getElementById("exportDataForm").reset();
        window.$('#exportData').modal('hide');
    }

    handleSubmitTransaction = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values.transactions;

            data.user = this.state.user;
            data.reference = this.state.reference;

            this.props.updateTransaction(data, this.props.token);
            this.setState({
                reference: '',
                flag: false
            });
        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values.transactions._id
            }

            this.props.deleteTransaction(data, this.props.token);
            this.setState({
                flag: false
            });
        }
    };

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }
    payableColumn = [
        {
            Header: "Customer Name",
            accessor: "customerName",
            filterable: true,
        },
        {
            Header: "Order Id",
            accessor: "orderId",
            filterable: true,
        },
        {
            Header: "Value",
            accessor: "totalValue",
            Cell: (props) => <p> {PriceFormat(props.value)} </p>,
            style: {
                textAlign: "right",
            },
        },
    ]

    accountColumns = [
        { Header: "Account Name", accessor: "acc_name", ...this.editableColumnProps },
        { Header: "Type", accessor: "type", ...this.selectableColumnProps },
        {
            Header: "Balance",
            accessor: "balance",
            Cell: row => (
                <div>
                    <label>{Number(row.original.balance).toFixed(2)}</label>
                </div>
            ),
            ...GridFilters,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        {
            Header: "Created Date",
            accessor: "createdAt",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{this.formatOnlyDate(row.original.createdAt)}</label>
                </div>
            )
        },
        { Header: "Currency", accessor: "currencyName", ...this.selectableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 230,
            minWidth: 230,
            Cell: props => (
                <div style={{ textAlign: "right" }}>
                    <button
                        data-toggle="modal"
                        className="btn btn-link"
                        data-target="#addTransaction"
                        data-backdrop="static" data-keyboard="false"
                        onClick={() => {

                            axios.defaults.headers.common['authorization'] = this.props.token;

                            const data = {
                                id: props.original._id
                            }

                            axios.post('/acc/accounts/getAccountById', { data })
                                .then(result => {
                                    let accountFrom = result.data[0];

                                    this.setState(
                                        {
                                            currentAccount: {
                                                acc_name: props.original.acc_name,
                                                _id: props.original._id,
                                                type: props.original.type,
                                                balance: accountFrom.balance,
                                                createdAt: props.original.createdAt
                                            },
                                            accountTypeFrom: props.original.type
                                        }
                                    );

                                }).catch(err => {
                                    toast.error(`${err}`)
                                })



                        }}
                    >
                        + Transaction
                    </button>

                    <button
                        data-toggle="modal"
                        className="btn btn-link"
                        data-target="#exportData"
                        data-backdrop="static" data-keyboard="false"
                        onClick={() => {

                            this.state.exportDateTo = new Date();
                            this.state.exportDateFrom = new Date(this.oneMonthAgo);

                            const data = {
                                id: props.original._id
                            }

                            axios.post('/acc/accounts/getTransactionsByAccountId', { data })
                                .then(result => {
                                    this.setState({
                                        transactionsForAccount: result.data,
                                    });

                                }).catch(err => {
                                    toast.error(`${err}`)
                                })
                        }}
                    >
                        Export
                    </button>
                </div>
            )
        },
        {
            Header: "",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    transactionsColumns = [
        {
            Header: "Transaction Date",
            accessor: "transactions.effectiveDate",
            ...this.editableColumnPropsDate
        },
        {
            Header: "Effective Date",
            accessor: "transactions.createdAt",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{this.formatOnlyDate(row.original.transactions.createdAt)}</label>
                </div>
            )
        },
        { Header: "Transaction Type", accessor: "transactions.creditType", filterable: true },
        { Header: "Reason", accessor: "transactions.type", ...this.selectableColumnProps },
        { Header: "Description", accessor: "transactions.reference", filterable: true },
        { Header: "User", accessor: "users.name.first", filterable: true },
        {
            Header: "Value",
            accessor: "transactions.value",
            Cell: row => (
                <div>
                    <label>{Number(row.original.transactions.value).toFixed(2)}</label>
                </div>
            ),
            ...GridFilters,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        {
            Header: "Balance",
            accessor: "transactions.balance",
            Cell: row => (
                <div>
                    <label>{PriceFormat(row.original.transactions.balance)}</label>
                </div>
            ),
            ...GridFilters,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionPropsTransactions,
            Cell: ActionsCell
        },
    ];

    setToAccount = (accountTo) => {
        var accountToArr = accountTo.split('-');
        this.setState({
            value: 0,
            selection: [],
            accountTo: accountTo,
            typeTo: accountToArr[1],
            accountTypeTo: accountToArr[2]
        });
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];

        const keyIndex = selection.indexOf(key);

        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }

        this.setAmount(selection)
        this.setState({ selection });
    };

    setAmount = (selection) => {
        let array = [];
        selection.map((key) => {
            this.props.customerPayable.map((o) => {
                if (key.toString() === o._id.toString()) {
                    let data = {
                        "_id": key,
                        "value": o.totalValue
                    }
                    array.push(data)
                }
            })
        })

        let amount = array.reduce((total, ele) => { return total + ele.value }, 0);
        this.setState({
            value: amount
        })
    }

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    render() {
        const { toggleSelection, isSelected } = this;

        const checkboxProps = {
            isSelected,
            toggleSelection,
            selectType: "checkbox"
        };

        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button
                            className="btn btn-primary"
                            disabled={Permission('ACC', 'ACCOUNTS', 'ADD', this.props)}
                            data-toggle="modal"
                            data-target="#addAccount"
                            data-backdrop="static" data-keyboard="false"
                        >
                            + Account
                        </button>
                    </div>
                    <ErrorBoundary>
                        <div className="card-body">
                            <FormProvider
                                form="inline"
                                onSubmit={this.handleSubmit}
                                onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                sorted={this.state.sortOptions}
                                                onSortedChange={val => {
                                                    this.setState({ sortOptions: val })
                                                }}
                                                columns={this.accountColumns}
                                                data={this.props.accounts}
                                                defaultPageSize={10}
                                                expanded={this.state.expanded}
                                                onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index)}
                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                showPageJump={false}
                                                SubComponent={row => {
                                                    var subData = _.filter(this.props.transactions, function (o) {
                                                        return o.accounts._id == row.original._id
                                                    })
                                                    return (
                                                        <React.Fragment>
                                                            <ErrorBoundary>
                                                                <FormProvider
                                                                    form="inline"
                                                                    onSubmit={this.handleSubmitTransaction}
                                                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                                    enableReinitialize
                                                                >
                                                                    {formProps => {
                                                                        return (
                                                                            <form onSubmit={formProps.handleSubmitTransaction}>
                                                                                <Table className="-striped"
                                                                                    sorted={this.state.sortOptionsTR}
                                                                                    onSortedChange={val => {
                                                                                        this.setState({ sortOptionsTR: val })
                                                                                    }}
                                                                                    columns={this.transactionsColumns}
                                                                                    data={subData}
                                                                                    defaultPageSize={10}
                                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                                    showPageJump={false}
                                                                                />
                                                                            </form>
                                                                        )
                                                                    }}

                                                                </FormProvider>
                                                            </ErrorBoundary>
                                                        </React.Fragment>
                                                    );
                                                }}
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </div>
                    </ErrorBoundary>


                    <div id="addAccount" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Account</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.clearAddAccountForm()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addAccountForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Account Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ acc_name: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Type* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ type: e.target.value })}>
                                                                <option value={''}>- Select Account Type -</option>
                                                                {this.accountTypeData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Balance* :</td>
                                                        <td><input type="number" className="form-control" value={this.state.balance} onChange={e => this.setState({ balance: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Currency* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ selectedCurrency: e.target.value })}>
                                                                <option value={''}>- Select Currency -</option>
                                                                {this.props.currencies.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={this.addAccountHandle}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.clearAddAccountForm()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="addTransaction" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Transaction</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.resetAddTransactionForm()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addTransactionForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Account From* :</td>
                                                        <td><p>{this.state.currentAccount.acc_name}</p></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Account To* :</td>
                                                        <td >
                                                            {/* <select  className="form-control col-12" onChange={e => e.target.value === '' ? null : this.setToAccount(e.target.value)}>
                                                                <option value={''}>- Select Account -</option>
                                                                {this.props.accounts.map(element => {
                                                                    if (this.state.currentAccount._id !== element._id) {
                                                                        return <option value={element._id + '-' + element.acc_name + '-' + element.type}>{element.acc_name}</option>
                                                                    }
                                                                })}
                                                                
                                                            </select> */}
                                                            <SearchableSelectComp options={this.props.accountsForTrans} label={'label'} onChange={e => e.value === '' ? null : this.setToAccount(e.value)} value={this.state.accountTo} />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Balance* :</td>
                                                        <td><input type="number" disabled className="form-control col-12" value={this.state.currentAccount.balance} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Value* :</td>
                                                        <td><input type="number" className="form-control col-12" onChange={e => this.setState({ value: e.target.value })} value={this.state.value} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Reference* :</td>
                                                        <td><textarea type="text" className="form-control col-12" onChange={e => this.setState({ reference: e.target.value })} value={this.state.reference}></textarea></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Transaction Date:</td>
                                                        <td><input type="date" className="form-control col-12" onChange={e => this.setState({ effectiveDate: e.target.value })} value={this.state.effectiveDate} style={{ 'width': '100%' }} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                        &nbsp;&nbsp;&nbsp;
                                        {this.state.typeTo === "Customer Payable" ?
                                            <div>
                                                <ErrorBoundary>
                                                    <FormProvider
                                                        form="inline"
                                                        enableReinitialize
                                                    >
                                                        {formProps => {
                                                            return (
                                                                <form>
                                                                    <CheckboxTable className="-striped"
                                                                        ref={(r) => (this.checkboxTable = r)}
                                                                        columns={this.payableColumn}
                                                                        data={this.props.customerPayable}
                                                                        defaultPageSize={10}
                                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                                        showPageJump={false}
                                                                        {...checkboxProps}
                                                                    />
                                                                </form>
                                                            )
                                                        }}

                                                    </FormProvider>
                                                </ErrorBoundary>
                                            </div>
                                            : ''}
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={e => this.addTransactionHandle()}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.resetAddTransactionForm()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="exportData" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Export Data</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.resetExportDataForm()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='exportDataForm'>

                                            <div className="row">
                                                <div className="col-lg-6">

                                                    <td>Date From* :</td>
                                                    <td><DatePicker selected={this.state.exportDateFrom} onChange={this.setExportDateFrom} /></td>

                                                </div>
                                                <div className="col-ld-6">

                                                    <td>Date To* :</td>
                                                    <td><td><DatePicker selected={this.state.exportDateTo} onChange={this.setExportDateTo} /></td></td>

                                                </div>
                                            </div>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={e => this.exportData()}>Export</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.resetExportDataForm()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        accounts: state.rAccounts.accounts,
        transactions: state.rAccounts.transactions,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userId: state.rLogin.id,
        currencies: state.rCurrency.currencies,
        customerPayable: state.rAccounts.customerPayable,
        accountsForTrans: state.rAccounts.accountsForTrans
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAccountsData: (token) => { dispatch(getAccountsData(token)) },
        getTransactions: (token) => { dispatch(getTransactions(token)) },
        addAccount: (data, token) => { dispatch(addAccount(data, token)) },
        updateAccount: (data, token) => { dispatch(updateAccount(data, token)) },
        deleteAccount: (data, token) => { dispatch(deleteAccount(data, token)) },
        updateTransaction: (data, token) => { dispatch(updateTransaction(data, token)) },
        deleteTransaction: (data, token) => { dispatch(deleteTransaction(data, token)) },
        addTransaction: (data, token) => { dispatch(addTransaction(data, token)) },
        getCurrencies: (token) => { dispatch(getCurrencies(token)) },
        getCustomerPayable: (token) => { dispatch(getCustomerPayable(token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);


