/**
 * Format date
 * @param {String} date 
 * @param {String} time 
 * @returns Date String
 */
const DateFormat = (date = '', time = '00:00:00') => {
    let toDate = date === '' ? new Date().toISOString().split('T') : new Date(date).toISOString().split('T')
    let splitTime = toDate[1].split('.')
    let newToDate = `${toDate[0]}T${time}.${splitTime[1]}`
    return newToDate;
}

export default DateFormat
