import React from 'react';

const Card = ({ title = '', children }) => {
    return (
        <div class="col-sm-12 col-md-6 col-lg-4 " >
            <div class="card text-white bg-dark mb-3" style={{ marginLeft: "2vh" }}
            >
                <div class="card-header text-center">{title}</div>
                <div class="card-body" style={{ padding: "0px" }}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Card