/**
 * @file This file defined for purchase order details
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
// import set from "lodash/fp/set";
import { Field } from "redux-form";
import Table from "react-table";
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import $ from 'jquery';
import * as BS from "react-bootstrap";
import axios from 'axios';
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import withFixedColumns from 'react-table-hoc-fixed-columns';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import { ExportData } from '../../utils';

import {
  getOrderData, getPurchaseItemData, getPurchaseItemDataById, addOrder, updateOrder, deleteOrder, updateOrderItem, deleteItem,
  mergePurchaseOrders, sendToHistory, updateCustomerOrderByQty, addPurchaseOrderItem, addPurchaseNewItemData, updatePurchaseItemData,
  deletePurchaseNewItemData, getReceivedOrdersData, getPurchaseReceivedItemDataById, getNewItemsData, getAllNewItemsData, deleteAllReceiveItems, getOrderReportData

} from "../../../actions/Purchasing/purchasing_order_action";
import { getItemData, getCurrencyData, getPaymentCondition, getDeliveryCondition } from '../../../actions/Basic_data/basic_data_action';
import { getSupplierData } from '../../../actions/Purchasing/purchasing_supplier_action';

import ErrorBoundary from "../../components/ErrorBoundary";
import PurchaseOrderPrint from "../Purchasing/PurchaseOrderPrint";
import { Permission, PriceFormat, DateNumberFormat } from "../../utils";
import AddPurchaseOrderModel from "./AddPurchaseOrderModel";
import SearchSelectComponent from "../../components/SearchSelectComponent";
import { getDeliveryWarehousesForCurrentUser } from "../../../actions/Basic_data/delivery_warehouse_actions";

import ReplacementModalPO from "./ReplacementModalPO";

const ReactTableFixedColumns = withFixedColumns(Table);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const reasonData = [
  { '_id': 'Not available', 'description': 'Not available' },
  { '_id': 'Permanently Not available', 'description': 'Permanently Not available' },
  { '_id': 'Delivery issues', 'description': 'Delivery issues' },
  { '_id': 'Customer Cancel', 'description': 'Customer Cancel' },
  { '_id': 'Customer Add', 'description': 'Customer Add' },
  { '_id': 'Cancel by Gusta', 'description': 'Cancel by Gusta' },
  { '_id': 'Add by Gusta', 'description': 'Add by Gusta' },
  { '_id': 'Replacement', 'description': 'Replacement' },
]

const bool = [{ value: false, label: 'No' }, { value: true, label: 'Yes' }]

const getSupplierById = (supplier, cutOffDate, etd, data, setData, setSupplierData, setCurrency, setPaymentCondition, setDeliveryCondition, props) => {
  axios.defaults.headers.common['authorization'] = props.token;
  axios.post('/purchasing/getSupplierById', { supplier, from: cutOffDate })
    .then(result => {
      //console.log(result)
      result.data.stockData.map(stock => {

        let val = _.sumBy(stock.purchaseItem, obj => {
          if (new Date(etd) <= new Date(obj.createdAt)) {
            return obj.balance
          }
          return 0
        })

        let order_qty = _.sumBy(stock.orderItem, "quantity")
        // console.log(val)
        let qty = etd === null ?
          (Math.abs(stock.stock?.availableStock || 0) - (_.sumBy(stock.purchaseItem, 'balance')))
          : stock.orderItem.length === 0 ?
            0
            : (_.sumBy(stock.orderItem, "quantity"))

        let other_qty = (Math.abs(stock?.stock?.availableStock) - (_.sumBy(stock.purchaseItem, 'balance'))) > 0 ?
          (Math.abs(stock?.stock?.availableStock) - (_.sumBy(stock.purchaseItem, 'balance')))
          : 0

        if (stock.stock !== null && (stock.stock?.availableStock < 0 || (etd !== null && order_qty > 0))) {
          var sData = data;
          sData.push({
            purchase_ref: '',
            item_ref: stock.item._id,
            orderqty: etd !== null ? order_qty > 0 ? qty : other_qty : qty,
            recivqty: 0,
            balance: qty,
            price: Number(stock.item.purchasePrice || 0),
            // days: stock.item.days,
            purchaseDiscount: Number(stock.item.purchaseDiscount || 0),
            packArr: stock.item?.packArr,
            isPackagingGroup: stock.item?.isPackagingGroup,
            history: false,
          })
          setData(sData)
        }
      })
      setSupplierData(result.data)
      setCurrency(result.data.supplier.supplier.currency)
      setPaymentCondition(result.data.supplier.supplier.paycondition_ref)
      setDeliveryCondition(result.data.supplier.supplier.delicondition_ref)
    }).catch(err => {
      console.error(err)
    })

}

const fromDate = () => {
  let fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 1);
  let newFromDate = fromDate.toISOString().split('T')[0]
  return newFromDate;
}

const toDate = () => {
  let toDate = new Date().toISOString().split('T')
  let splitTime = toDate[1].split('.')
  let timeString = `23:59:59.${splitTime[1]}`
  let newToDate = `${toDate[0]}T${timeString}`
  return newToDate;
}
function Order(props) {
  const [data, setData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [historyOrder, setHistoryOrder] = useState(false);
  const [edit, setEdit] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [supplier, setSupplier] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [expandOrderId, setExpandOrderId] = useState(null);
  const [expandReceivedOrderId, setExpandReceivedOrderId] = useState(null);
  const [purchase, setPurchase] = useState(null);
  const [supplierData, setSupplierData] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [paymentCondition, setPaymentCondition] = useState(null);
  const [deliveryCondition, setDeliveryCondition] = useState(null);
  const [remark, setRemark] = useState('');
  const [history, setHistory] = useState(null)
  const [expanded, setExpanded] = useState({});
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [printSupplier, setPrintSupplier] = useState({});
  const [printPaymentcondition, setPrintPaymentcondition] = useState({});
  const [purchaseOrderItems, setPurchaseOrderItems] = useState({});
  const [printDeliverycondition, setPrintDeliverycondition] = useState({});
  const [numberOfpurchaseItems, setNumberOfpurchaseItems] = useState({});
  const [receivedOrder, setReceivedOrder] = useState({});
  const [isLoading, setIsLoading] = useState(null)
  const [orderQty, setOrderQty] = useState(null)
  const [updateOrderQty, setUpdateOrderQty] = useState(null)
  const [mpoStartDate, setMPOStartDate] = useState(null)
  const [mpoEndDate, setMPOEndDate] = useState(null)
  const [supplierForMerge, setSupplierForMerge] = useState('');
  const [customerOrderData, setCustomerOrderData] = useState([]);
  const [newQty, setNewQty] = useState(null);
  const [recvQtyFlag, setRecvQtyFlag] = useState(false);
  const [orderNewQty, setNewOrderQty] = useState({ qty: '' })
  const [newReason, setNewReason] = useState({ reason: '' })
  const [disable, setDisable] = useState(true)
  const [addPurchaseItem, setPurchaseItem] = useState({})
  const [addNewPurchaseItems, setNewPurchaseItems] = useState([]);
  const [purchaseSupplierId, setPurchaseSupplierId] = useState(null);
  const [receiveItemsData, setReceiveItemsData] = useState([]);
  const [receiveAllItemsData, setReceiveAllItemsData] = useState([]);
  const [buttonStatus, setButtonStatus] = useState('');
  const [purchaseItemData, setPurchaseItemData] = useState('');
  const [disableRecItems, setDisableRecItem] = useState(true)
  const [optionData, setOptionData] = useState(true)
  const [itemName, setItemName] = useState(true)
  const [warehouse, setWarehouse] = useState('');
  const [allOrderItems, setAllOrderItems] = useState([]);
  const [active, setActive] = useState(true);
  const [exportDate, setExportDate] = useState({ from: fromDate(), to: toDate() })
  const [itemId, setItemId] = useState('')
  const [disableRecQty, setDisableRecQty] = useState(false)

  const [purchaseItemToBeReplaced, setPurchaseItemToBeReplaced] = useState(null)
  const [dataForReplacement, setDataForReplacement] = useState(null)
  const [customerOrderIdForRep, setCustomerOrderIdForRep] = useState(null)
  const [customerOrderItemIdForRep, setCustomerOrderItemIdForRep] = useState(null)
  const [purchaseItemForReplacement, setPurchaseItemForReplacement] = useState([]);
  const [purchaseSupplierIdForRep, setPurchaseSupplierIdForRep] = useState(null)

  var sup = useQuery().get("supplier");
  var etd = useQuery().get("etd");
  var cutOffDate = useQuery().get("cutOffDate");
  var city = useQuery().get("city");
  // To get data when page loading
  useEffect(() => {
    props.getOrderData(props.token);
    props.getItem(props.token);
    props.getPurchaseItem(props.token);
    props.getSupplierData(props.token);
    props.getCurrencyData(props.token);
    props.getPaymentCondition(props.token);
    props.getDeliveryCondition(props.token)
    props.getDeliveryWarehousesForCurrentUser(props)
    props.getReceivedOrdersData(props.token)
  }, [])

  // when supplier id exists
  useEffect(() => {
    if (sup !== null) {
      setSupplier(sup)
      let randomNumber = Math.floor(Math.random() * 10000 + 1);
      let orderId = new Date().toISOString().slice(0, 10) + "-" + randomNumber;
      setOrderId(orderId);
      window.$('#addOrder').modal('toggle');
    }
  }, [sup])

  //supplier id change 
  useEffect(() => {
    setData([]); setSupplierData(null); setCurrency(null); setPaymentCondition(null)
    if (supplier !== '') {
      getSupplierById(supplier, cutOffDate, etd, data, setData, setSupplierData, setCurrency, setPaymentCondition, setDeliveryCondition, props)
    }
  }, [supplier])


  const setMPOStartDateMethod = (date) => {
    var formatedDate = formatDate(date, 'start');
    setMPOStartDate(formatedDate);
  }

  const setMPOEndDateMethod = (date) => {
    var formatedDate = formatDate(date, 'other');
    setMPOEndDate(formatedDate);
  }

  const mergePurchaseOrders = () => {

    if (mpoEndDate === '' || mpoStartDate === '' || mpoEndDate === null || mpoStartDate === null || supplierForMerge === '') {
      toast.error('Please select Supplier, start date and end date!');
    } else {
      var formatedDate = formatDate(mpoEndDate, 'end');

      const data = {
        supplier: supplierForMerge,
        startDate: mpoStartDate,
        endDate: formatedDate
      };

      props.mergePurchaseOrders(data, props.token);

      window.$('#mergePurchaseOrders').modal('hide');
      closeRangePopup();
      toast.success('Successfully Merged Purchase Orders!');

    }

  }

  const closeRangePopup = () => {
    setMPOStartDate(null);
    setMPOEndDate(null);
    setSupplierForMerge(null);


    $('#mergePurchaseOrders').find('input:text, select').each(function () {
      $(this).val('');
    });

    $('#sendToHistory').find('input:text, select').each(function () {
      $(this).val('');
    });
  }

  const sendToHistory = () => {

    if (mpoEndDate === '' || mpoStartDate === '' || mpoEndDate === null || mpoStartDate === null || supplierForMerge === '') {
      toast.error('Please select Supplier, start date and end date!');
    } else {
      var formatedDate = formatDate(mpoEndDate, 'end');

      const data = {
        supplier: supplierForMerge,
        startDate: mpoStartDate,
        endDate: formatedDate
      };

      props.sendToHistory(data, props.token);

      window.$('#sendToHistory').modal('hide');
      closeRangePopup();
    }
  }

  const formatDate = (date, type) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    if (type === 'end') {
      var time = "T23:59:59.000Z";
    } else {
      var time = "T00:00:00.000Z";
    }

    var finalDate = [year, month, day].join('-');

    return new Date([finalDate, time].join(''));
  }

  const getPurchaseItem = () => {
    axios.defaults.headers.common['authorization'] = props.token;
    axios.get('/purchasing/getPurchaseItemById', { headers: { authorization: props.token }, params: { id: expandOrderId } })
      .then(result => {
        let sortData = _.sortBy(result.data, ['items.product', 'items.desc', 'items.name'])
        setOptionData(sortData)
      }).catch(err => {
        console.error(err)
        toast.error(`${err}`)
      })
  }

  useEffect(() => {
    getPurchaseItem()
  }, [expandOrderId])

  useEffect(() => {
    if (expandOrderId !== null && expandOrderId !== '' && expandOrderId !== undefined) {
      props.getPurchaseItemDataById(expandOrderId, props)
    }
  }, [expandOrderId])

  useEffect(() => {
    if (expandReceivedOrderId !== null && expandReceivedOrderId !== '' && expandReceivedOrderId !== undefined) {
      props.getPurchaseReceivedItemDataById(expandReceivedOrderId, props)
    }
  }, [expandReceivedOrderId])

  useEffect(() => {
    if (props.purchaseItemById) {
      setSubData(props.purchaseItemById)
    }
  }, [props.purchaseItemById])

  const editableComponent = ({ input, editing, value, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component  {...input} {...rest} children={children} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px' }} />;
  };

  const editableNumberComponent = ({ input, editing, value, original, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component type="number"
      onChange={input.onChange}
      {...rest} children={children} min={0} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
  };

  const editableNumberComponentRecQty = ({ input, editing, value, original, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component type="number"
      onChange={input.onChange}
      {...rest} children={children} min={0} step="0.001" style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
  };


  const renderDateTimePicker = ({ input, editing, value, Props, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    return editing ? <Component type="date"  {...input} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />
      : <label style={{ color: (Props.original.purchaseorder.isExpDeliveryDateChange !== undefined && Props.original.purchaseorder.isExpDeliveryDateChange === true) ? 'red' : '' }} >{DateNumberFormat(Props.value)}</label>;

  }

  useEffect(() => {
    if (props.newAllItems) {
      setReceiveAllItemsData(props.newAllItems)
    }
  }, [props.newAllItems])

  const editableColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = edit === props.original;
      const fieldProps = {
        component: editableComponent,
        editing,
        props
      };
      return <Field name={props.column.id} {...fieldProps} />;
    }
  };

  const editableNumberPurchaseColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = edit === props.original;
      const fieldProps = {
        component: editableNumberComponent,
        editing,
        step: props.column.Header !== 'Qty' ? "1" : "0.001",
        props
      };
      return (props.original.items !== undefined) ?
        <div style={{ textAlign: 'right' }}>{props.value}</div>
        : <Field name={props.column.id} {...fieldProps} />;
    }
  };

  const editableNumberColumnProps = {
    Cell: props => {
      const editing = edit === props.original;
      const fieldProps = {
        component: editableNumberComponentRecQty,
        editing,
        props
      };
      if (props.column.Header === 'Order Qty') {
        return !editing ? <>
          <button data-toggle="modal" data-target='#updateOrderQty' className='btn btn-link  float-right pull-right'
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => {
              setUpdateOrderQty(props.original);
              setOrderQty(props.original.purchaseitem.orderqty);
            }}
          >{props.original.purchaseitem.orderqty}</button>
        </> : <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.orderqty}
        </div>;
      } else {
        return <Field name={props.column.id} max={props.original.balance} defaultValue={props.original.balance}  {...fieldProps} />;
      }
    }
  };

  const editableNumberColumnReceiveQtyProps = {
    Cell: props => {
      const editing = edit === props.original;
      const fieldProps = {
        component: editableNumberComponentRecQty,
        editing,
        props
      };
      return <Field name={props.column.id} max={props.original.balance} {...fieldProps} />;
    }
  };

  const editableQuantityColumnProps = {
    Cell: props => {
      const editing = edit === props.original;
      const fieldProps = {
        component: editableNumberComponent,
        editing,
        props
      };

      if (props.column.Header === 'New Qty' && recvQtyFlag) {
        return <Field disabled={true} name={props.column.id} {...fieldProps} />
      }

      return <Field name={props.column.id} {...fieldProps} />
    }
  };

  const selectableColumnProps = {
    ...GridFilters,
    Cell: Props => {
      const editing = edit === Props.original;

      return (editing ?
        <Field name={Props.column.id} component="select" className='form-control' value={Props.value}>
          {(Props.column.Header === 'Delivery Condition' ? props.delivery_conditons : Props.column.Header === 'Reason' ? reasonData : Props.column.Header === 'Warehouse' ? props.deliveryWarehouseData : props.payment_conditons).map(data =>
            <option key={data._id} value={data._id}>{Props.column.Header === 'Warehouse' ? data.name : data.description}</option>
          )}
        </Field> : <label>{Props.value}</label>)
    }
  };

  const searchableColumnProps = {
    ...GridFilters,
    Cell: Props => {
      const editing = edit === Props.original;
      const options = (Props.column.Header === 'Delivery Condition' ? props.delivery_conditons : props.payment_conditons).map(data => ({ label: data.description, value: data._id }));
      return (editing ?
        <Field component={SearchSelectComponent} options={options} name={Props.column.id} value={Props.value} />
        : <label>{Props.value}</label>)
    }
  };

  const checkColumnProps = {
    ...GridFilters,
    Cell: Props => {
      const editing = edit === Props.original;

      return (editing ?
        <Field name={Props.column.id} style={{ alignItems: 'center' }} component="input" type="checkbox" value={Props.value} />
        : <label>{Props.value ?
          'yes'
          : 'no'}</label>)
    }
  };

  const selectableDateColumnProps = {
    // ...GridFilters,
    Cell: Props => {
      const editing = edit === Props.original;
      const fieldProps = {
        component: renderDateTimePicker,
        editing,
        Props
      };

      return (<Field name={Props.column.id} {...fieldProps} />)
    }
  };

  const booleanColumnProps = {
    Cell: Props => {
      const editing = edit === Props.original;

      return (editing ?
        <Field name={Props.column.id} component="select" className='form-control'>
          {bool.map(data =>
            <option
              key={data.value} value={data.value}>
              {data.label}
            </option>
          )}
        </Field> : <label>{Props.value === true ? 'Yes' : 'No'}</label>)
    }
  }

  const getActionPurchaseProps = (gridState, rowProps) =>
    (rowProps && {
      mode: edit === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => setEdit(rowProps.original),
        onCancel: () => setEdit(null),
        onDelete: () => setDeleting(rowProps.original)
      },
      updPerm: Permission('PURCHASE', 'ORDERS', 'UPDATE', props),
      delPerm: Permission('PURCHASE', 'ORDERS', 'DELETE', props)
    }) ||
    {};

  const getActionProps = (gridState, rowProps) =>
    (rowProps && {
      mode: edit === rowProps.original ? "edit" : "view",
      updPerm: Permission('PURCHASE', 'ORDERS', 'UPDATE', props) || props.isLoading,
      delPerm: Permission('PURCHASE', 'ORDERS', 'DELETE', props) || props.isLoading,
      actions: {
        onEdit: () => setEdit(rowProps.original),
        onCancel: () => setEdit(null),
        onDelete: () => {
          setDeleting(rowProps.original)
          if (rowProps.original.purchaseitem != undefined) {
            let isReceived = false;
            let numberOfItems = 0;

            var purchaseOrder = _.filter(props.data, function (o) {
              return (o.purchaseorder._id === rowProps.original.purchaseitem.purchase_ref);
            })

            var purchaseOrderItems = _.filter(props.purchaseItemData, function (o) {
              if (o.purchaseitem.purchase_ref === rowProps.original.purchaseitem.purchase_ref) {
                if (o.purchaseitem.recivqty > 0) {
                  isReceived = true;
                }

                if (o.purchaseitem.orderqty - o.purchaseitem.recivqty > 0) {
                  numberOfItems++;
                }

                return true;
              } else {
                return false;
              }
            })
            setReceivedOrder(isReceived);
            setNumberOfpurchaseItems(numberOfItems);
            if (numberOfItems > 0) {
              setPurchaseOrder(purchaseOrder[0]);
            }
          }
        }
      }
    }) ||
    {};

  const getActionPropsQuantity = (gridState, rowProps) =>
    (rowProps && {
      mode: edit === rowProps.original ? "edit" : "view",
      updPerm: Permission('PURCHASE', 'ORDERS', 'UPDATE', props) || props.isLoading,
      delPerm: true,
      actions: {
        onEdit: () => { setEdit(rowProps.original); setCustomerOrderIdForRep(rowProps.original.orders._id); setCustomerOrderItemIdForRep(rowProps.original.orderitems._id) },
        onCancel: () => setEdit(null),
      }
    }) ||
    {};

  const renderDateTimePickerExpireDate = ({ input, edit, value, props, ...rest }) => {
    const Component = edit ? BS.FormControl : BS.FormControl.Static;
    return edit && <Component type="date" {...input} style={{ maxHeight: edit ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
  }

  const selectableDateColumnPropsExpDate = {
    ...GridFilters,
    Cell: props => {
      const editing = edit === props.original;
      const fieldProps = {
        component: renderDateTimePickerExpireDate,
        edit,
        props
      };

      return (editing ? <Field name={props.column.id} {...fieldProps} /> : props.value !== undefined ? <label>{DateNumberFormat(props.value)}</label> : '')
    }
  };

  const getActionPurchaseItemsProps = (gridState, rowProps) =>
    (rowProps && {
      mode: edit === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => setEdit(rowProps.original),
        onCancel: () => setEdit(null),
        onDelete: () => setDeleting(rowProps.original)
      },
      updPerm: Permission('PURCHASE', 'NEW ORDER ITEM', 'UPDATE', props),
      delPerm: rowProps.original.isDelete === true ? Permission('PURCHASE', 'NEW ORDER ITEM', 'DELETE', props) : Permission('PURCHASE', 'SPECIAL NEW ORDER ITEM', 'DELETE', props)
    }) ||
    {};


  const columns = [
    { Header: "Supplier Name", accessor: "supplier.name", ...GridFilters },
    { Header: "Order Id", accessor: "purchaseorder.orderid", ...GridFilters },
    {
      Header: "Order Date", id: "purchaseorder.orderdate", accessor: data => DateNumberFormat(data.purchaseorder.orderdate),
      ...GridFilters
    },
    {
      Header: "Exp. Delivery Date", id: "purchaseorder.expReceiveDate",
      accessor: data => DateNumberFormat(data.purchaseorder.expReceiveDate),
      ...selectableDateColumnProps
    },
    {
      Header: "Recv. Date", id: "purchaseorder.receivedate",
      accessor: data => data.purchaseorder?.receivedate?.toString()?.split('T')[0],
    },
    {
      Header: "Payment Condition",
      accessor: "paymentcondition.description",
      ...searchableColumnProps,
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            cursor: 'pointer',
            overflow: 'visible'
          },
        };
      },
    },
    { Header: "Remark", accessor: "purchaseorder.remark", ...editableColumnProps },
    {
      Header: "Paid",
      accessor: "purchaseorder.paid",
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "true") {
          return row[filter.id] === true;
        } else if (filter.value === "false") {
          return row[filter.id] === false;
        }
      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value={"all"}>Show All</option>
          <option value={"true"}>Yes</option>
          <option value={"false"}>No</option>
        </select>,
      Cell: Props => {
        return (
          <div style={{ textAlign: "center" }}>{Props.value ? 'Yes' : 'No'}</div>)
      }
    },
    {
      Header: "Payment Value",
      accessor: "purchaseorder.paymentvalue",
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: "right"
          },
        };
      },
    },
    { Header: "Payment Date", accessor: "purchaseorder.payment_date", filterable: true, Cell: prop => <>{(prop.value === '' || prop.value === undefined) ? '' : DateNumberFormat(prop.value)}</> },
    {
      Header: "Informed",
      accessor: "purchaseorder.informed",
      ...checkColumnProps
    },
    {
      Header: "Delivery Condition",
      accessor: "deliverycondition.description",
      filterable: true,
      ...searchableColumnProps,
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            cursor: 'pointer',
            overflow: 'visible'
          },
        };
      },
    },
    { Header: "Whatsapp Group", accessor: "supplier.whatsappgroup", ...GridFilters },
    {
      Header: "Total", accessor: "purchaseorder.total",
      ...GridFilters,
      Cell: row => {
        return (
          <div style={{ textAlign: "right" }}>{PriceFormat((Number(row.original.total)))}</div>)
      }
    },
    { Header: "Warehouse", accessor: "deliverywarehouse.name", ...selectableColumnProps },
    {
      Header: 'History',
      maxWidth: 130,
      minWidth: 130,
      fixed: 'right',
      Cell: row =>
        <>
          <button data-toggle="modal" data-target='#section' className='btn btn-link'
            onClick={() => {
              axios.defaults.headers.common['authorization'] = props.token;
              axios.post('/purchasing/getHistoryOrder', { data: row.original.purchaseorder })
                .then(result => {
                  var historyData = _.filter(result.data, function (o) {
                    return ((o.purchaseitem.purchase_ref === row.original.purchaseorder._id) && (o.purchaseitem.history === true))
                  })
                  setHistory(historyData)
                }).catch(err => {
                  console.error(err)
                })
            }}
          >view</button>
        </>
    },
    {
      Header: 'Print',
      maxWidth: 130,
      minWidth: 130,
      fixed: 'right',
      Cell: row =>
        <>
          <button id={`invoice-${row.original.purchaseorder.orderid}`}
            data-toggle="modal" data-target='#print' className='btn btn-link'
            onClick={() => {
              setPurchaseOrder({});
              setPrintSupplier({});
              setPrintPaymentcondition({});
              setPrintDeliverycondition({});
              setPurchaseOrderItems([]);

              setPurchaseOrder(row.original.purchaseorder);
              setPrintSupplier(row.original.supplier);
              setPrintPaymentcondition(row.original.paymentcondition);
              setPrintDeliverycondition(row.original.deliverycondition);

              var subData = _.filter(props.purchaseItemData, function (o) {
                return (o.purchaseitem.purchase_ref === row.original.purchaseorder._id)
              })
              let sortedISubData = _.sortBy(subData, ['items.product', 'items.desc', 'items.name'])

              setPurchaseOrderItems(sortedISubData);

            }}
          > Print</button>
        </>
    },
    {
      Header: "",
      maxWidth: 130,
      minWidth: 130,
      fixed: 'right',
      filterable: false,
      getProps: getActionProps,
      Cell: ActionsCell
    },
  ];

  const historyColumns = [
    { Header: "Supplier Name", accessor: "supplier.name", ...GridFilters },
    { Header: "Order Id", accessor: "purchaseorder.orderid", ...GridFilters },
    { Header: "Order Date", id: "purchaseorder.orderdate", accessor: data => data.purchaseorder?.orderdate?.toString().split('T')[0] },
    { Header: "Recv. Date", id: "purchaseorder.receivedate", accessor: data => data.purchaseorder?.receivedate?.toString().split('T')[0] },
    { Header: "Payment Condition", accessor: "paymentcondition.description", ...selectableColumnProps },
    { Header: "Remark", accessor: "purchaseorder.remark" },
    {
      Header: "Paid",
      accessor: "purchaseorder.paid",
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "true") {
          return row[filter.id] === true;
        } else if (filter.value === "false") {
          return row[filter.id] === false;
        }
      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value={"all"}>Show All</option>
          <option value={"true"}>Yes</option>
          <option value={"false"}>No</option>
        </select>,
      Cell: Props => {
        return (
          <div style={{ textAlign: "center" }}>{Props.value ? 'Yes' : 'No'}</div>)
      }
    },
    {
      Header: "Payment Value",
      accessor: "purchaseorder.paymentvalue",
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            textAlign: "right"
          },
        };
      },
    },
    { Header: "Payment Date", accessor: "purchaseorder.payment_date", filterable: true },
    { Header: "Whatsapp Group", accessor: "supplier.whatsappgroup", ...GridFilters },
    {
      Header: "Total", accessor: "purchaseorder.total", ...GridFilters,
      Cell: row => {
        var subData = _.filter(props.purchaseItemData, function (o) {
          return (o.purchaseitem.purchase_ref === row.original.purchaseorder._id)
        })

        var PriceTotal = 0;

        subData.forEach((item) => {
          PriceTotal += item.purchaseitem.orderqty * (Number(item.purchaseitem.price) - (Number(item.purchaseitem.price) * Number(item.purchaseitem?.purchaseDiscount || 0) / 100))
        });

        return (
          <div style={{ textAlign: "right" }}>{PriceFormat((Number(PriceTotal)))}</div>)
      }
    },
    { Header: "Warehouse", accessor: "deliverywarehouse.name", ...GridFilters },
  ];

  const reciveAllQty = () => {
    setDisableRecItem(true)
    let checkArr = [];
    receiveAllItemsData.map(val => {
      if (val.deliverywarehouse === undefined || val.exp_date === undefined || val.recivqty === 0) {
        checkArr.push(val)
      }
    })
    if (checkArr.length === 0) {
      if (window.confirm('Do you want to receive all items ?')) {
        setIsLoading(true)
        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/purchasing/updateReceiveAllQty', { orderId: expandOrderId, userId: props.userId })
          .then(result => {
            toast.success(result.data)
            props.getOrderData(props.token);
            props.getPurchaseItem(props.token);
            setIsLoading(false)
          }).catch(err => {
            console.error(err)
            setIsLoading(false)
          })
        reload()
      }
    } else {
      toast.warn("Please Fill the Fields")
    }
  }
  const deleteAllQty = (value) => {
    if (value == "Single") {
      purchaseItemData.status = value
      props.deleteAllReceiveItems(purchaseItemData, props.token)
    } else {
      let data = {
        "status": value,
        "purchase_ref": expandOrderId
      }

      props.deleteAllReceiveItems(data, props.token)
    }
    reload()
  }

  const closeOrderItemModel = () => {
    setActive(true)
  }

  const receiveOneQty = () => {
    setDisableRecItem(true)
    let checkArr = [];
    purchaseItemData.purchaseitem.new_items = receiveItemsData;
    purchaseItemData.userId = props.userId;
    purchaseItemData.purchaseitem.new_items.map(val => {
      if (val.deliverywarehouse === undefined || val.exp_date === undefined || val.recivqty === 0) {
        checkArr.push(val)
      }
    })
    if (checkArr.length === 0) {
      let total = purchaseItemData.purchaseitem.new_items.reduce((total, ele) => { return total + Number(ele.recivqty) }, 0)
      purchaseItemData.purchaseitem.recivqty = Number(total);
      props.updateQty(purchaseItemData, props.token);
      reload()
      setDisableRecQty(true)
    } else {
      toast.warn("Please Fill the Fields")
    }
  }

  const totalValidation = (arr) => {
    const totals = {};
    for (const item of arr) {
      const { purchaseItem_ref, recivqty, original_balance } = item;
      if (purchaseItem_ref) {
        if (totals.hasOwnProperty(purchaseItem_ref)) {
          totals[purchaseItem_ref].total += (recivqty);
        } else {
          totals[purchaseItem_ref] = { total: (recivqty), original_balance };
        }
      }
    }
    const output = Object.keys(totals).map(_id => ({ _id, total: totals[_id].total, balance: totals[_id].original_balance }));
    let total = output.reduce((total, ele) => { return total + Number(ele.total) }, 0)
    let totalBalance = output.reduce((total, ele) => { return total + Number(ele.balance) }, 0)
    if (Number(total) === Number(totalBalance) && Number(total) > 0) {
      setDisableRecItem(false)
    } else {
      setDisableRecItem(true)
    }
  }

  const purchaseNewItems = () => {
    setNewPurchaseItems([])
    axios.defaults.headers.common['authorization'] = props.token;
    let newData = {
      "supplierId": purchaseSupplierId,
      "purchaseOrderId": expandOrderId
    }
    axios.post('/purchasing/getPurchaseNewOrderItem', { data: newData })
      .then(result => {

        let itemData = result.data.map((V) => {
          return V.items
        })

        itemData.map(product => {
          product['label'] = `${product.itemCode} - ${product.name} (${product.price}${product.priceUnit})`
          product['purchaseOrder_ref'] = expandOrderId
        })

        setPurchaseItem(itemData)

      }).catch(err => {
        console.error(err)
        toast.error(`${err}`)
      })

  }

  const receiveCustomerOrder = (row) => {
    const itemId = row.original.purchaseitem._id;
    const itemRef = row.original.purchaseitem.item_ref;
    axios.defaults.headers.common['authorization'] = props.token;
    axios.post('/purchasing/getCustomerOrderByQty', { _id: itemId, item_ref: itemRef })
      .then(result => {
        setCustomerOrderData(result.data)
      }).catch(err => {
        console.log(err);
      })
  }

  const subColumns = [
    {
      Header: "Item Name", accessor: "items.name",
      ...GridFilters,
      Cell: props =>
        <div>
          {props.original.items.desc != null && props.original.items.desc != "" && props.original.items.desc !== undefined ? props.original.items.desc : props.original.items.name}
        </div>
    },
    {
      Header: "Item Code", accessor: "items.itemCode", ...GridFilters,
    },
    {
      Header: "Shortage", accessor: "orderQty",
      Cell: props => {
        let total = Number(props.original.stocks.reservationStock - (props.original.orderQty + props.original.stocks.stock));
        return <div style={{ textAlign: 'right' }}>
          {Number.isInteger(total) ? Number(total) : Number(total).toFixed(2)}
        </div>
      }
    },
    {
      Header: "Order Qty", accessor: "purchaseitem.orderqty",
      Cell: row => {
        const editing = edit === row.original;
        const fieldProps = {
          component: editableNumberComponent,
          editing,
          row
        };
        return !editing && (row.column.Header === 'Order Qty') ? <>
          <button data-toggle="modal" data-target='#updateOrderQty' className='btn btn-link  float-right pull-right'
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => {
              setPurchaseItemToBeReplaced(row.original.purchaseitem)
              setPurchaseSupplierIdForRep(row.original.POrders.supplier_ref)
              setItemName(row.original.items.name)
              const item_ref = row.original.purchaseitem.item_ref
              const purchase_ref = row.original.purchaseitem.purchase_ref

              axios.get('/purchasing/getPurchaseItemsByPurchaseAndItemRef', { headers: { authorization: props.token }, params: { item_ref, purchase_ref } })
                .then(result => {
                  const purchaseItems = result.data
                  var recqty = 0

                  purchaseItems.forEach(purchaseItem => {
                    recqty = recqty + Number(purchaseItem.recivqty)
                  });

                  if (recqty > 0) {
                    setRecvQtyFlag(true)
                  } else {
                    setRecvQtyFlag(false)
                  }

                  console.log(row.original)

                  setUpdateOrderQty(row.original);
                  setOrderQty(row.original.purchaseitem.orderqty);
                  receiveCustomerOrder(row);
                  setNewOrderQty({ qty: '' });
                  setNewReason({ reason: '' });
                  setDisable(true);

                }).catch(err => {
                  console.error(err)
                  toast.error(`${err}`)
                })


            }}
          >{row.original.purchaseitem.orderqty}</button>
        </> : <div style={{ textAlign: 'right' }}>
          {row.original.purchaseitem.orderqty}
        </div>
      }
    },
    {
      Header: "Purchase Discount", accessor: "purchaseitem.purchaseDiscount",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.purchaseDiscount}
        </div>
    },
    {
      Header: "Purchase price", accessor: "items.price",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.price === undefined || props.original.purchaseitem.price === null ?
            0
            : PriceFormat(props.original.purchaseitem.price)}
        </div>
    },
    {
      Header: "Total value", accessor: "items.price",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.price === undefined || props.original.purchaseitem.price === null ?
            0
            : PriceFormat((Number(props.original.purchaseitem.price) - (Number(props.original.purchaseitem.price) * Number(props.original.purchaseitem.purchaseDiscount || 0) / 100)) * (props.original.purchaseitem.orderqty))}
        </div>
    },
    { Header: "Price Changes", accessor: "purchaseitem.price_status", ...booleanColumnProps },
    { Header: "Unit", accessor: "items.priceUnit" },
    { Header: "Consignment", accessor: "purchaseitem.consignment", ...booleanColumnProps },
    {
      Header: "Balance Qty", accessor: "purchaseitem.balance",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.balance}
        </div>
    },
    {
      Header: "Rec. Qty", accessor: "purchaseitem.recivqty", fixed: 'right',
      Cell: row =>
        <>
          <button
            disabled={itemId !== undefined ? (String(itemId) === String(row.original.purchaseitem._id) ? disableRecQty : false) : false}
            data-toggle="modal" data-target='#receivingItemsModel' className='btn btn-link'
            onClick={() => {
              setReceiveItemsData([])
              setPurchaseItemData('')
              setButtonStatus('One')
              setPurchaseItemData(row.original)
              setItemId(row.original.purchaseitem._id)
              setDisableRecQty(false)
              let itemsValue = row.original.purchaseitem.new_items !== undefined ? row.original.purchaseitem.new_items : []

              let total = itemsValue.reduce((total, ele) => { return total + Number(ele.recivqty) }, 0)
              if (Number(total) <= Number(row.original.purchaseitem.balance) && Number(total) > 0) {
                setDisableRecItem(false)
              } else {
                setDisableRecItem(true)
              }
              let data = {
                _id: row.original.purchaseitem._id,
                itemRef: row.original.purchaseitem.item_ref,
                isAdd: true,
                isDelete: false,
                name: row.original.items.name,
                code: row.original.items.itemCode,
                recivqty: 0,
                balance: row.original.purchaseitem.balance,
                original_balance: row.original.purchaseitem.balance,
                purchase_ref: row.original.purchaseitem.purchase_ref,
                purchaseItem_ref: row.original.purchaseitem._id,
                Status: 'Single',
                createdAt: new Date()
              }

              if (itemsValue.length === 0) {
                props.addPurchaseNewItemData({ data: data }, props.token, (result) => {
                  setReceiveItemsData([...result]);
                })
              } else {
                const checkData = itemsValue.filter(item1 => String(item1.purchaseItem_ref) === String(row.original.purchaseitem._id) && (item1.isAdd === true) && (item1.isDelete === false));
                if (checkData.length === 0) {
                  props.addPurchaseNewItemData({ data: data }, props.token, (result) => {
                    setReceiveItemsData([...result]);
                  })
                }
                setReceiveItemsData([...itemsValue]);
              }
            }}
          > {row.original.purchaseitem.recivqty}</button>
        </>
    },
    {
      Header: "Rec User", accessor: "recuser.name.first", ...GridFilters,
    },
    {
      Header: "Modi User", accessor: "modiuser.name.first", ...GridFilters,
    },

    {
      // Header: <button id='receiveOrderItems' className='btn' data-toggle='modal' data-target='#addOrderItem' style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}
      //   onClick={reciveAllQty}
      // ><i className="fas fa-cart-arrow-down"></i></button>,

      Header: <div><button className='btn btn-link' data-toggle='modal' disabled={Permission('PURCHASE', 'ORDERS', 'ADD', props)} data-target="#addPurchaseOrderItems" style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }}
        onClick={purchaseNewItems}
      ><i class="fa fa-plus-square" style={{ "font-size": "20px", "color": "dark blue" }}></i></button>,
        <button id='receiveOrderItems' className='btn btn-danger' data-toggle='modal' data-target='#receivingAllItemsModel' disabled={isLoading}
          onClick={() => {
            setReceiveAllItemsData([]);
            setButtonStatus('Multi')
            let arr = subData.map((val => {
              let value = {
                _id: val.purchaseitem._id,
                itemRef: val.purchaseitem.item_ref,
                isAdd: true,
                isDelete: false,
                name: val.items.name,
                code: val.items.itemCode,
                recivqty: 0,
                original_balance: val.purchaseitem.balance,
                balance: val.purchaseitem.balance,
                purchaseItem_ref: val.purchaseitem._id,
                purchase_ref: val.purchaseitem.purchase_ref,
                createdAt: new Date(),
              }
              return value
            }))

            let data = {
              Status: 'Multi',
              arr: arr
            }
            props.addPurchaseNewItemData({ data: data }, props.token, (result) => {
              totalValidation(result)
            })
            props.getAllNewItemsData({ id: expandOrderId }, props.token)
            reload()
          }
          }>{isLoading ? <div><i class="fa fa-spinner fa-pulse" aria-hidden="true"></i> Processing...</div> : "Rec All"}</button></div>,
      maxWidth: 130,
      minWidth: 130,
      fixed: 'right',
      filterable: false,
      sortable: false,
      getProps: getActionProps,
      Cell: ActionsCell
    },
  ];

  const handleAddItem = (item) => {
    let total = 0;
    let allItemsData = []

    if (buttonStatus === 'One') {
      total = receiveItemsData.reduce((total, ele) => { return total + Number(ele.recivqty) }, 0)
    } else {
      allItemsData = receiveAllItemsData.filter(item1 => String(item1.purchaseItem_ref) === String(item.purchaseItem_ref));
      total = allItemsData.reduce((total, ele) => { return total + Number(ele.recivqty) }, 0)
    }

    let data = {
      _id: item._id,
      itemRef: item.itemRef,
      isAdd: false,
      isDelete: true,
      name: item.name,
      code: item.itemCode,
      recivqty: 0,
      original_balance: Number(item.balance),
      balance: Number(item.balance - total).toFixed(3),
      purchaseItem_ref: item.purchaseItem_ref,
      purchase_ref: item.purchase_ref,
      Status: buttonStatus === 'One' ? 'Single' : 'Multi-One',
      createdAt: new Date()
    }
    if (buttonStatus === 'One') {
      if (Number(total) < Number(item.balance)) {
        let checkArr = [];
        receiveItemsData.map(val => {
          if (val.deliverywarehouse === undefined || val.exp_date === undefined || val.recivqty === 0) {
            checkArr.push(val)
          }
        })
        if (checkArr.length === 0) {
          props.addPurchaseNewItemData({ data: data }, props.token, (result) => {
            setReceiveItemsData([...result]);
          })
          reload()
        } else {
          toast.warn("Please Fill the Fields")
        }
      } else {
        toast.warn("Rec Qty equals Balance Qty")
      }
    } else {
      if (Number(total) < Number(item.balance)) {
        let checkArr = [];
        allItemsData.map(val => {
          if (String(val.purchaseItem_ref) === String(item.purchaseItem_ref)) {
            if (val.deliverywarehouse === undefined || val.exp_date === undefined || val.recivqty === 0) {
              checkArr.push(val)
            }
          }
        })
        if (checkArr.length === 0) {
          props.addPurchaseNewItemData({ data: data }, props.token, (result) => {
          })
          reload()
        } else {
          toast.warn("Please Fill the Fields")
        }
      } else {
        toast.warn("Rec Qty equals Balance Qty")
      }
    }
  };

  const receiveColumns = [
    {
      Header: "",
      maxWidth: 70,
      minWidth: 70,
      Cell: row => {
        const item = row.original;
        return (
          <div>
            <button
              disabled={Permission('PURCHASE', 'NEW ORDER ITEM', 'ADD', props)}
              hidden={row.original.isAdd === false ? true : false}
              className='btn btn-primary btn-lg'
              onClick={() => handleAddItem(item)}
            >
              <i className="fa fa-plus-square" style={{ fontSize: "20px", color: "dark blue" }}></i>
            </button>
          </div>
        );
      }
    },
    {
      Header: "Item Name",
      maxWidth: 200,
      minWidth: 200,
      accessor: "name",
      ...GridFilters,
    },
    {
      Header: "Warehouse",
      maxWidth: 270,
      minWidth: 270,
      accessor: "deliverywarehouse.name", ...selectableColumnProps
    },
    {
      Header: "Exp. Date",
      maxWidth: 200,
      minWidth: 200,
      accessor: "exp_date", ...selectableDateColumnPropsExpDate
    },
    {
      Header: "Balance Qty",
      maxWidth: 100,
      minWidth: 100,
      accessor: "balance",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.balance}
        </div>
    },
    {
      Header: "Rec. Qty",
      maxWidth: 100,
      minWidth: 100,
      accessor: "recivqty",
      fixed: 'right', ...editableNumberColumnReceiveQtyProps
    },
    {
      Header: "Action",
      maxWidth: 150,
      minWidth: 150,
      filterable: false,
      sortable: false,
      getProps: getActionPurchaseItemsProps,
      Cell: ActionsCell
    }
  ];


  const handleReceQtySubmit = value => {
    if (props.deliveryWarehouseData.length === 1) {
      value.deliverywarehouse = props.deliveryWarehouseData[0]._id
    }

    if (edit !== null && deleting === null) {
      if (value.deliverywarehouse !== undefined && value.deliverywarehouse !== '') {
        if (value.exp_date !== undefined && value.exp_date !== '') {
          if (value.recivqty !== undefined && value.recivqty !== '') {
            const warehouseId = props.deliveryWarehouseData.length > 1 ? edit.deliverywarehouse !== undefined ?
              value.deliverywarehouse.name !== edit.deliverywarehouse.name ?
                value.deliverywarehouse.name
                : edit.deliverywarehouse._id
              : value.deliverywarehouse !== undefined ?
                value.deliverywarehouse.name
                : '' : value.deliverywarehouse

            const matchingDeliveryQWareHouse = props.deliveryWarehouseData.find(item1 => String(item1._id) === String(warehouseId));
            if (receiveItemsData.length > 0) {
              if (Number(value.balance) >= Number(value.recivqty)) {
                value.Status = "Single"
                value.deliverywarehouse = { _id: matchingDeliveryQWareHouse._id, name: matchingDeliveryQWareHouse.name }
                props.updatePurchaseItemData({ data: value }, props.token, (result) => {
                  let total = result.reduce((total, ele) => { return total + Number(ele.recivqty) }, 0)
                  if (Number(total) <= Number(value.original_balance) && Number(total) > 0) {
                    setDisableRecItem(false)
                  } else {
                    setDisableRecItem(true)
                  }
                  setReceiveItemsData([...result]);
                })
                reload()
              } else {
                toast.error("Receive quantity less than balance quantity")
              }
            }
          } else {
            toast.error("Receive quantity field is mandatory")
          }
        } else {
          toast.error("Expire date field is mandatory")
        }
      } else {
        toast.error("Warehouse field is mandatory")
      }
    } else if (deleting != null && edit === null) {
      if (value !== undefined) {
        let data = {
          _id: value._id,
          purchaseItem_ref: value.purchaseItem_ref,
          purchase_ref: value.purchase_ref,
          Status: "Single"
        }
        props.deletePurchaseNewItemData({ data: data }, props.token, (result) => {
          setReceiveItemsData([...result]);
        })
        reload()
      }
    }
  }
  const handleReceAllQtySubmit = value => {
    if (props.deliveryWarehouseData.length === 1) {
      value.deliverywarehouse = props.deliveryWarehouseData[0]._id
    }

    if (edit !== null && deleting === null) {
      if (value.deliverywarehouse !== undefined && value.deliverywarehouse !== '') {
        if (value.exp_date !== undefined && value.exp_date !== '') {
          if (value.recivqty !== undefined && value.recivqty !== '') {
            const warehouseId = props.deliveryWarehouseData.length > 1 ? edit.deliverywarehouse !== undefined ?
              value.deliverywarehouse.name !== edit.deliverywarehouse.name ?
                value.deliverywarehouse.name
                : edit.deliverywarehouse._id
              : value.deliverywarehouse !== undefined ?
                value.deliverywarehouse.name
                : '' : value.deliverywarehouse

            const matchingDeliveryQWareHouse = props.deliveryWarehouseData.find(item1 => String(item1._id) === String(warehouseId));
            if (receiveAllItemsData.length > 0) {
              if (Number(value.balance) >= Number(value.recivqty)) {

                value.Status = "Multi"
                value.deliverywarehouse = { _id: matchingDeliveryQWareHouse._id, name: matchingDeliveryQWareHouse.name }

                props.updatePurchaseItemData({ data: value }, props.token, (result) => {
                  totalValidation(result)
                })
                reload()
              } else {
                toast.error("Receive quantity less than balance quantity")
              }
            }
          } else {
            toast.error("Receive quantity field is mandatory")
          }
        } else {
          toast.error("Expire date field is mandatory")
        }
      } else {
        toast.error("Warehouse field is mandatory")
      }
    } else if (deleting != null && edit === null) {
      if (value !== undefined) {
        let data = {
          _id: value._id,
          purchaseItem_ref: value.purchaseItem_ref,
          purchase_ref: value.purchase_ref,
          Status: "Multi"
        }
        props.deletePurchaseNewItemData({ data: data }, props.token, (result) => { })
        reload()
      }
    }
  }

  const addNewPurchaseItemsColumns = [
    { Header: "Name", accessor: "name", filterable: true },
    { Header: "Item Code", accessor: "itemCode", filterable: true },
    { Header: "Purchase Price", accessor: "purchasePrice" },
    { Header: 'Qty', accessor: "qty", ...editableNumberPurchaseColumnProps },
    { Header: 'Total Price', accessor: "totalPrice" },
    {
      Header: "",
      maxWidth: 130,
      minWidth: 130,
      filterable: false,
      getProps: getActionPurchaseProps,
      Cell: ActionsCell
    },
  ]

  const orderItemsColumns = [
    { Header: "Item Name", accessor: "name" },
    { Header: "Price", accessor: "unitPrice", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Quantity", accessor: "quantity", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Discount", accessor: "discount", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Total Price", accessor: "price", getProps: () => { return { style: { 'text-align': 'right' } } } },
  ]

  const qtyColumns = [
    {
      Header: "Order ID", accessor: "orders.orderId",
      maxWidth: 150,
      minWidth: 150,
      Cell: props =>
        <>
          <button data-toggle="modal"
            data-target='#orderItemModel'
            className='btn btn-link'
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => {
              setActive(false)
              setOrderId(props.original.orders.orderId)
              setAllOrderItems(props.original.orderitemsData)
            }}
          >{props.original.orders.orderId}</button>
        </>
    },
    { Header: "Region", accessor: "regions.name" },
    {
      Header: "Etd", accessor: "orders.etd",
      Cell: props => {
        if (props.original?.orders?.etd !== undefined) {
          var date = props.original?.orders?.etd;
          var dateTime = DateNumberFormat(date)
          return <>{dateTime}</>
        } else {
          return <>{''}</>
        }
      },
    },
    { Header: "Customer Name", accessor: "orders.name" },
    { Header: "Telephone", accessor: "orders.telephone" },
    { Header: "Status", accessor: "orders.status" },
    {
      Header: "Order Qty", accessor: "orderitems.quantity", Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.orderitems.quantity}
        </div>
    },
    { Header: "New Qty", accessor: "orderitems.new_qty", ...editableQuantityColumnProps },
    { Header: "Reason", accessor: "orderitems.reason", ...selectableColumnProps },
    {
      Header: "",
      maxWidth: 130,
      minWidth: 130,
      fixed: 'right',
      filterable: false,
      getProps: getActionPropsQuantity,
      Cell: ActionsCell
    },
  ]

  const histrotySubColumns = [
    { Header: "Item Name", accessor: "items.name" },
    // {Header: "Item Description", accessor: "items.desc" },
    {
      Header: "Order Qty", accessor: "purchaseitem.orderqty",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.orderqty}
        </div>
    },
    {
      Header: "Balance Qty", accessor: "purchaseitem.balance",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {/* {props.original.purchaseitem.balance} */}
        </div>
    },
    {
      Header: "Rec. Qty", accessor: "purchaseitem.recivqty",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.recivqty}
        </div>
    },
    {
      Header: "Purchase Discount", accessor: "purchaseitem.purchaseDiscount",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.purchaseDiscount}
        </div>
    },
    {
      Header: `Unit price`, accessor: "items.purchasePrice",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.price}
        </div>
    },
    {
      Header: "Total value", accessor: "items.price",
      Cell: props =>
        <div style={{ textAlign: 'right' }}>
          {props.original.purchaseitem.price === undefined || props.original.purchaseitem.price === null ?
            0
            : PriceFormat((Number(props.original.purchaseitem.price) - (Number(props.original.purchaseitem.price) * Number(props.original.purchaseitem.purchaseDiscount || 0) / 100)) * (props.original.purchaseitem.orderqty))}
        </div>
    },
    { Header: "Rec User", accessor: "recuser.name.first", ...GridFilters, },
    { Header: "Modi User", accessor: "modiuser.name.first", ...GridFilters, },
    { Header: "Unit", accessor: "items.priceUnit" },
  ];

  const handleRowExpanded = (rowsState, index) => {
    setExpanded({
      [index[0]]: !expanded[index[0]],
    })
  }

  const hasOnlyOneItem = () => {

  }

  const setHasSameSupplier = () => {
    let atLeastOneHasSameSupplier = false
    dataForReplacement.items.map(item => {
      if (item.supplier_ref === purchaseSupplierIdForRep) {
        item.hasSameSupplier = true
        atLeastOneHasSameSupplier = true
      } else {
        item.hasSameSupplier = false
      }
    })

    dataForReplacement.atLeastOneHasSameSupplier = atLeastOneHasSameSupplier
  }

  const updateOrderQtyHandle = value => {
    if (customerOrderData.length > 0 && customerOrderData[0].orderitems.reason === 'Replacement') {
      if (purchaseItemForReplacement.length > 0) {
        setHasSameSupplier()
        console.log(dataForReplacement)
        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/purchasing/replacePurchaseItem', { dataForReplacement })
          .then(result => {
            setCustomerOrderData([])
            setCustomerOrderIdForRep(null)
            setCustomerOrderItemIdForRep(null)
            setDataForReplacement(null)
            setPurchaseItemForReplacement([])
            reload()
            toast.success('Items replaced.')
          }).catch(err => {
            console.error(err)
          })
      } else {
        toast.error('Please select the items')
      }
    } else {
      if (updateOrderQty !== null && updateOrderQty !== undefined) {
        var data = updateOrderQty;
        var custData = newQty;
        var quantity = _.sumBy(customerOrderData, function (o) { return o.orderitems.new_qty })

        if (orderQty >= 0) {
          if (orderQty < quantity) {
            if (orderQty == 0) {
              toast.error('Order qty should be greater than zero!')
            } else if (orderQty.trim() == '') {
              toast.error('Please fill up the mandatory fields!')
            } else {
              toast.error('purchase order quantity can not be less')
            }
          } else {
            data.purchaseitem.diffOrderqty = Number(orderQty) - Number(data.purchaseitem.orderqty);
            data.purchaseitem.orderqty = Number(orderQty);
            data.userId = props.userId
            props.updateCustomerOrderByQty(custData, data, props.token)
            setOrderQty('');
            setUpdateOrderQty(null);
            setNewQty(null);
            reload()
          }
        }
      }
    }
  }

  const handleSubmit = value => {
    if (edit !== null && deleting === null) {
      if (value.purchaseorder !== undefined) {
        console.log('update purchase order')
        let paycondition = edit.paymentcondition.description !== value.paymentcondition.description ? _.filter(props.payment_conditons, { _id: value.paymentcondition.description })[0]._id : value.purchaseorder.paycondition_ref
        let delcondition = edit?.deliverycondition !== undefined && edit?.deliverycondition?.description !== value?.deliverycondition?.description ?
          _.filter(props.delivery_conditons, { _id: value.deliverycondition.description })[0]._id
          : value.purchaseorder.deliveryCondition
        if (value.purchaseorder.expReceiveDate !== edit.purchaseorder.expReceiveDate) {
          value.purchaseorder.isExpDeliveryDateChange = true
        }
        let warehouseId = edit.deliverywarehouse !== undefined ?
          value.deliverywarehouse.name !== edit.deliverywarehouse.name ?
            value.deliverywarehouse.name
            : edit.deliverywarehouse._id
          : value.deliverywarehouse !== undefined ?
            value.deliverywarehouse.name
            : ''
        props.updateOrder({ ...value.purchaseorder, paycondition_ref: paycondition, deliverycondition: delcondition, warehouse: warehouseId }, props.token);
      } else if (value.purchaseitem !== undefined) {
        if (edit.purchaseitem.price_status !== value.purchaseitem.price_status) {
          value.purchaseitem.price_status = value.purchaseitem.price_status === "true" ? true : false
          value.purchaseitem.changed = true
        } else {
          value.purchaseitem.changed = false
        }
        if (edit.purchaseitem.consignment !== value.purchaseitem.consignment) {
          value.purchaseitem.consignment = value.purchaseitem.consignment === "true" ? true : false
        }
        value.purchaseitem.recivqty = 0;
        if (value.purchaseitem.recivqty >= 0) {
          if (value.purchaseitem.balance > 0 && value.purchaseitem.recivqty === 0) {
            var data = { ...value, purchaseitem: { ...value.purchaseitem, recivqty: 0 }, userId: props.userId };
            console.log('1', data);
            props.updateQty(data, props.token);
          } else {
            var data = { value, userId: props.userId };
            console.log('2', data);
            props.updateQty(data, props.token);
          }
          reload()

        } else {
          toast.warn('please enter greater than or equal to 0')
        }
      }
    } else if (deleting != null && edit === null) {
      if (value.purchaseorder !== undefined) {
        console.log('delete order')
        props.deleteOrder(value, props.token);
      } else if (value.purchaseitem !== undefined) {
        console.log('delete order item')
        value.userId = props.userId;
        value.orderData = purchaseOrder;
        props.deleteItem(value, props.token)
        //TODO use transaction
        if (numberOfpurchaseItems == 1) {
          if (receivedOrder) {
            props.updateOrder({ ...purchaseOrder.purchaseorder, history: true }, props.token);
          } else {
            props.deleteOrder(purchaseOrder, props.token);
          }
        }
      }
    }
    // setExpandOrderId(null);
    // setExpanded({
    //   null: !expanded[null]
    // })
  }

  const handleCustomerOrderAllSubmit = dataArr => {
    for (let value of dataArr) {
      if (value.orderitems.reason.trim() == '') {
        //toast.error('Please Select a Reason')
      } else {
        var quantity = _.sumBy(dataArr, function (o) { return o.orderitems.new_qty })
        setOrderQty(quantity)
        setNewQty(dataArr)
      }
      if (value.orderitems.reason.trim() === "" && Number(value.orderitems.new_qty) === Number(value.orderitems.quantity)) {
        var quantity = _.sumBy(dataArr, function (o) { return o.orderitems.new_qty })
        setOrderQty(quantity)
        setNewQty(dataArr)
      }
    }
  }

  const handleCustomerOrderSubmit = value => {
    if (value.orders?.status !== "Dispatched" && value.orders?.status !== "DispatchedMissing") {
      if (edit !== null) {
        if (value.orderitems.reason.trim() == '') {
          toast.error('Please Select a Reason')
        } else {
          var data = _.find(customerOrderData, function (o) { return o.orderitems._id === value.orderitems._id })

          if (value.orderitems.reason.trim() === 'Replacement') {

            const button = document.getElementById("replacementModalPOButton");
            button.click();
            window.$('#updateOrderQty').modal('toggle');
            setPurchaseItemForReplacement([])

            data.orderitems.new_qty = Number(0)
            data.orderitems.reason = value.orderitems.reason

            var quantity = _.sumBy(customerOrderData, function (o) { return o.orderitems.new_qty })
          } else {
            data.orderitems.new_qty = Number(value.orderitems.new_qty)
            data.orderitems.reason = value.orderitems.reason
            var quantity = _.sumBy(customerOrderData, function (o) { return o.orderitems.new_qty })
          }

          setOrderQty(quantity)
          setNewQty(customerOrderData)
        }
      }
    } else {
      toast.error('Dispatched orders cannot be deleted...!')
    }
  }

  const proceed = (data) => {
    data.cOrderId = customerOrderIdForRep
    data.orderItemId = customerOrderItemIdForRep
    setDataForReplacement(data)
    window.$('#replacementModalPO').modal('toggle');
    window.$('#updateOrderQty').modal('show');
  }

  const hideReplcementModel = () => {
    window.$('#replacementModalPO').modal('toggle');
    window.$('#updateOrderQty').modal('show');
  }

  const reload = () => {
    props.getOrderData(props.token);
    props.getItem(props.token);
    props.getPurchaseItem(props.token);
    props.getSupplierData(props.token);
    props.getCurrencyData(props.token);
    props.getPaymentCondition(props.token);
    props.getDeliveryCondition(props.token)
    props.getReceivedOrdersData(props.token)
    props.getPurchaseItemDataById(expandOrderId, props)
    props.getAllNewItemsData({ id: expandOrderId }, props.token)
  }

  const inputHandler = (e, name) => {
    if (name === "Qty") {
      let OrderDataArr = customerOrderData.map((data) => {
        if (data.orders.status !== 'Dispatched' && data.orders.status !== "DispatchedMissing") {
          let qtyValue = e.target.value !== '' ? Number(e.target.value) : data.orderitems.quantity;
          if (data.orderitems.reason !== "") {
            data.orderitems.new_qty = Number(qtyValue)
            setNewOrderQty({ qty: e.target.value })
          }
        }
        return data;
      })
      handleCustomerOrderAllSubmit(OrderDataArr)
    }
    if (name === "reasons") {
      let reason = e.target.value !== '' ? e.target.value : '';
      let OrderDataArr = customerOrderData.map((data) => {
        if (data.orders.status !== 'Dispatched' && data.orders.status !== "DispatchedMissing") {
          data.orderitems.reason = reason
          setNewReason(reason)
          setDisable(false)
          if (reason === "") {
            data.orderitems.new_qty = Number(data.orderitems.quantity)
            setNewOrderQty({ qty: '' })
            setDisable(true)
          }
        }
        return data;
      })
      handleCustomerOrderAllSubmit(OrderDataArr)
    }
  }

  const excelExportOrder = () => {
    props.getOrderReportData({ from: exportDate.from, to: exportDate.to, status: historyOrder }, props.token, (data) => {
      exportData(data, historyOrder)
    })
  }

  /**
    * Export  data to excel
    */
  const exportData = (data, historyOrder) => {
    var excelData = data.map(checkData => {
      return {
        'Supplier': checkData.supplier.name,
        'Order Id': checkData.purchaseorder.orderid,
        'Order Date': DateNumberFormat(checkData.purchaseorder.orderdate),
        'Exp. Delivery Date': checkData.purchaseorder.expReceiveDate !== undefined
          && checkData.purchaseorder.expReceiveDate !== '' ? DateNumberFormat(checkData.purchaseorder.expReceiveDate) : '',
        'Receive Date': checkData.purchaseorder.receivedate !== undefined
          && checkData.purchaseorder.receivedate !== '' ? DateNumberFormat(checkData.purchaseorder.receivedate) : '',
        'Payment Condition': checkData.paymentcondition !== undefined ? checkData.paymentcondition.description : "",
        'Remark': checkData.purchaseorder.remark,
        'paid': checkData.purchaseorder.paid,
        'paymentvalue': checkData.purchaseorder.paymentvalue,
        'Payment Date': checkData.purchaseorder.payment_date !== undefined
          && checkData.purchaseorder.payment_date !== '' ? DateNumberFormat(checkData.purchaseorder.payment_date) : '',
        'Informed': checkData.purchaseorder.informed,
        'Delivery Condition': checkData.deliverycondition !== undefined ? checkData.deliverycondition.description : "",
        'Whatsapp Group': checkData.supplier !== undefined
          && checkData.supplier.whatsappgroup !== undefined ? checkData.supplier.whatsappgroup : "",
        'History': checkData.purchaseorder.history,
        'Total Price': checkData.total,
        'Warehouse': checkData.deliverywarehouse !== undefined ? checkData.deliverywarehouse.name : "",
      }
    })
    let file_name = historyOrder == true ? "Purchase Received Order" : "Purchase Order"
    let title = historyOrder == true ? "Purchase Received Order details" : "Purchase Order details"
    let sheet_name = historyOrder == true ? "Purchase Received order" : "Purchase order"
    ExportData(file_name, title, sheet_name, excelData)
    setExportDate({ from: fromDate(), to: toDate() })
  }

  /**
   * Edited row value in add purchase order items
  * 
  * @param {Object} value 
  */
  const handlePurchaseOrderItemSubmit = value => {
    if (edit !== null) {
      var data = _.filter(addNewPurchaseItems, { '_id': value._id })
      data[0].qty = Number(value.qty)
      data[0].recivqty = 0
      data[0].totalPrice = Number(value.purchasePrice) * Number(value.qty)

    } else if (deleting !== null) {
      var data = _.remove(addNewPurchaseItems, n => { return n._id === value._id })
    }
  }

  const componentRef = useRef();

  return (
    <div className="right_col" role="main">
      <div className="card">
        <div className="card-header">
          <ErrorBoundary>
            <input type='radio' name='history' style={{ marginRight: '5px' }} checked={historyOrder ? false : true} onChange={e => { console.log(e.target.value); setHistoryOrder(false) }} /><label>Active</label>
            <input type='radio' name='history' style={{ marginLeft: '30px', marginRight: '5px' }} onChange={e => { setHistoryOrder(true) }} /><label>Recieved</label>
          </ErrorBoundary>
          <div style={{ float: 'right' }}>
            <button className="btn btn-primary" data-backdrop="static" data-keyboard="false" data-toggle="modal" disabled={props.isLoading} data-target="#sendToHistory">Send to History</button>
            <button className="btn btn-primary" data-backdrop="static" data-keyboard="false" data-toggle="modal" disabled={props.isLoading} data-target="#mergePurchaseOrders">Merge Purchase Orders</button>
            <button className="btn btn-primary"
              disabled={props.isLoading}
              onClick={() => reload()}
            // style={{ float: 'right' }}
            >
              <i className="fas fa-sync"></i>
            </button>


            <button className="btn btn-primary" //style={{ float: 'right' }}
              disabled={props.isLoading ? true : Permission('PURCHASE', 'ORDERS', 'ADD', props)}
              onClick={() => {
                setOrderId('')
                let randomNumber = Math.floor(Math.random() * 10000 + 1);
                let orderId = new Date().toISOString().slice(0, 10) + "-" + randomNumber;
                // console.log(orderId)
                setOrderId(orderId); setSupplier(0); setSupplierData(null); setData([]); setRemark('');
                window.$('#addOrder').modal('toggle');
              }}
            >+ Order</button>
            <button className="btn btn-success" data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#exportData" disabled={props.isLoading}>{historyOrder ? 'Export Data' : 'Export Data'}</button>
          </div>
        </div>
        {historyOrder ?
          // shows histroy
          <ErrorBoundary>
            <div className='card-body'>
              <Table className="-striped"
                columns={historyColumns}
                data={props.receivedOrders}
                defaultPageSize={10}
                filterable
                expanded={expanded}
                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                SubComponent={row => {
                  setExpandReceivedOrderId(row.original.purchaseorder._id)
                  return (
                    <>
                      <ErrorBoundary>
                        <Table className="-striped"
                          data={props.receivedItems}
                          columns={histrotySubColumns}
                        />
                      </ErrorBoundary>
                    </>
                  )
                }}
              />
            </div>
          </ErrorBoundary>
          :
          <div className="card-body">
            {/* {props.isLoading
               ? <>
                 <SkeletonTable columnsCount={9} dataCount={10} />
               </>
               :  */}
            <FormProvider
              form="inline"
              onSubmit={handleSubmit}
              onSubmitSuccess={() => { setEdit(null); setDeleting(null) }}
              initialValues={edit !== null ? edit : deleting}
              enableReinitialize
            >
              {formProps => {
                // console.log(formProps)
                return (
                  <form onSubmit={formProps.handleSubmit}>
                    {/* <form> */}
                    <ErrorBoundary>
                      <Table className="-striped"
                        columns={columns}
                        data={props.data}
                        defaultPageSize={10}
                        getTrProps={(state, rowInfo) => {
                          return {
                            style: {
                              fontSize: '14px',
                              background: rowInfo?.original?.purchaseorder?.remark?.includes('Return') ? '#eeeb7f' : ''
                            }
                          }
                        }}
                        // expanded={{ [row]: true }}
                        expanded={expanded}
                        onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                        SubComponent={row => {

                          setExpandOrderId(row.original.purchaseorder._id)
                          setPurchaseSupplierId(row.original.supplier._id)
                          return (
                            <React.Fragment>
                              <ErrorBoundary>
                                <FormProvider
                                  form="inline"
                                  onSubmit={handleSubmit} //other wise send two request
                                  onSubmitSuccess={() => { setEdit(null); setDeleting(null); }}
                                  initialValues={edit !== null ? edit : deleting}
                                  enableReinitialize
                                >
                                  {formProps => {
                                    return (
                                      <form onSubmit={formProps.onSubmit}>
                                        <ReactTableFixedColumns
                                          columns={subColumns}
                                          data={subData}
                                          getTrProps={(state, rowInfo) => {
                                            return {
                                              style: {
                                                fontSize: '14px',
                                              }
                                            }
                                          }}
                                          // expanded={{ [row.index]: true }}
                                          defaultPageSize={10} />
                                      </form>
                                    )
                                  }}
                                </FormProvider>
                              </ErrorBoundary>
                            </React.Fragment>
                          );
                        }}
                      />
                    </ErrorBoundary>
                  </form>
                );
              }}
            </FormProvider>
            {/* } */}
          </div>
        }
        {/* <div className="card-footer"></div> */}
      </div>

      {/* add purchase order */}
      <AddPurchaseOrderModel supplierData={supplierData} supplier={supplier} currency={currency} remark={remark} data={data} paymentCondition={paymentCondition} deliveryCondition={deliveryCondition} etd={etd} cutOffDate={cutOffDate} city={city} orderId={orderId} setData={setData} setSupplier={setSupplier} setPurchase={setPurchase} setPaymentCondition={setPaymentCondition} setCurrency={setCurrency} setRemark={setRemark} setOrderId={setOrderId}
        setDeliveryCondition={setDeliveryCondition} setWarehouse={setWarehouse} warehouse={warehouse} props={props} />

      {/* purchase order history */}
      <ErrorBoundary>
        <div id='section' className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content animate" >
              <div className="modal-header">
                <h4 className="modal-title text-uppercase">History</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                {(history === null || history.length == 0) ?
                  <>
                    No Data Found...!
                  </>
                  : <div>
                    <Table
                      className="-striped"
                      columns={[
                        { Header: "Item name", accessor: "items.name", filterable: true },
                        {
                          Header: "Receive Qty", accessor: "purchaseitem.recivqty",
                          Cell: Props =>
                            <div style={{ textAlign: 'right' }}>
                              {Props.original.purchaseitem.recivqty}
                            </div>
                        },
                        {
                          Header: "Price", accessor: "items.price",
                          Cell: Props =>
                            <div style={{ textAlign: 'right' }}>
                              {Props.original.items.purchasePrice}  {Props.original.items.priceUnit}
                            </div>
                        },
                        { Header: "Rec User", accessor: "users.name.first", filterable: true },
                      ]}
                      data={history}
                      defaultPageSize={5}
                    />
                    {/* <table>
                       <thead>
                         <th>Item name</th>
                         <th>Receive Qty</th>
                         <th>Price</th>
                         <th>Price unit</th>
                         <th>History</th>
                       </thead>
                       <tbody>
                         {history.map(data => {
                           return <tr>
                             <td>{data.items.name}</td>
                             <td>{data.purchaseitem.recivqty}</td>
                             <td>{data.items.price}</td>
                             <td>{data.items.priceUnit}</td>
                             <td>{data.purchaseitem.history}</td>
                           </tr>
                         }
                         )}
                       </tbody>
                     </table> */}
                  </div>}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>

      {/* Add new purchase order item */}
      <ErrorBoundary>
        <div id='addPurchaseOrderItems' className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content animate" >
              <div className="modal-header">
                <h4 className="modal-title text-uppercase">Add Purchase Order Items</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <Select
                  options={addPurchaseItem}
                  value={[]}
                  onChange={e => {
                    let checkId = _.filter(optionData, function (o) {
                      return o.items._id === e._id
                    })

                    let addCheckId = _.filter(addNewPurchaseItems, function (o) {
                      return o._id === e._id
                    })

                    if (checkId.length === 0 && addCheckId.length === 0) {
                      setNewPurchaseItems([...addNewPurchaseItems, { ...e, qty: 1, totalPrice: e.purchasePrice, recivqty: 0 }])
                    } else if (checkId.length !== 0) {
                      toast.warn('An existing item.')
                    } else {
                      toast.info('Already add this item.')
                    }
                  }}
                />
              </div>
              {addNewPurchaseItems.length > 0 ?
                <ErrorBoundary>
                  <div>
                    <FormProvider
                      form="inline"
                      onSubmit={handlePurchaseOrderItemSubmit}
                      onSubmitSuccess={() => { setEdit(null); setDeleting(null); }}
                      initialValues={edit !== null ? edit : deleting}
                      enableReinitialize
                    >
                      {formProps => {
                        return (
                          <form onSubmit={formProps.handleSubmit}>
                            <Table
                              columns={addNewPurchaseItemsColumns}
                              data={addNewPurchaseItems}
                              defaultPageSize={5}
                              showPagination={true}
                            />
                          </form>
                        )
                      }}
                    </FormProvider>
                  </div>
                </ErrorBoundary>
                : null}
              <div className="modal-footer">
                <button id="add_order" type="button" className="btn btn-primary" disabled={edit !== null ? true : addNewPurchaseItems.length === 0 ? true : false} data-dismiss="modal" onClick={() => {
                  const data = {
                    purchaseItem: addNewPurchaseItems,
                    purchaseOrderId: expandOrderId
                  };
                  props.addPurchaseOrderItem(data, props.token)
                  reload()
                }}>Add Purchase Order Items</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal"
                  onClick={() => setNewPurchaseItems([])}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>

      {/* print */}
      <ErrorBoundary>
        <div id="print" className="modal fade" role="dialog">
          <div className="modal-dialog  modal-xl">
            <div className="modal-content animate" >
              <div className="modal-header">
                <button className="btn btn-secondary"
                  onClick={() => {
                    var DocumentContainer = document.getElementById(
                      "printSection"
                    );
                    var WindowObject = window.open(
                      "",
                      "PrintWindow",
                      "toolbars=no,scrollbars=yes,status=no,resizable=yes"
                    );
                    WindowObject.document.writeln(
                      DocumentContainer.innerHTML
                    );
                    WindowObject.document.close();
                    setTimeout(function () {
                      WindowObject.focus();
                      WindowObject.print();
                      WindowObject.close();
                    }, 1000);
                  }}
                >
                  PRINT
                </button>

                <ReactToPrint
                  content={() => componentRef.current}
                />
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>

              <div className="modal-body">
                <PurchaseOrderPrint purchaseOrderItems={purchaseOrderItems} order={purchaseOrder} paymentcondition={printPaymentcondition} deliverycondition={printDeliverycondition} supplier={printSupplier} ref={componentRef} />
              </div>

            </div>
          </div>
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        {active ?
          <div id="updateOrderQty" className="modal fade" role="dialog">
            <div className="modal-dialog modal-xl">
              <div className="modal-content animate" >
                <div className="modal-header">
                  <h4 className="modal-title text-uppercase">Update Order Qty - {itemName}</h4>
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                  <React.Fragment>
                    <ErrorBoundary>
                      <form id='updateOrderQtyForm'>
                        <table>
                          <tbody>
                            <tr>
                              <td>Purchase Order Qty* :</td>
                              <td><input type='number' id='purchase_order_qty' className="form-control" min={0} value={orderQty} onChange={e => { $('#purchase_order_qty').removeClass('parsley-error'); setOrderQty(e.target.value) }} /></td>
                              <td>New Order Qty* :</td>
                              <td><input type='number' min={0} disabled={disable} className="form-control" value={orderNewQty.qty} onChange={e => { inputHandler(e, "Qty") }} /></td>
                              <td>Reason*</td>
                              <td>
                                <select
                                  className='form-control'
                                  value={newReason.reason}
                                  onChange={(e) => {
                                    if ("Replacement" === e.target.value) {
                                      const button = document.getElementById("replacementModalPOButton");
                                      button.click();
                                      window.$('#updateOrderQty').modal('toggle');
                                      setPurchaseItemForReplacement([])
                                    }
                                    inputHandler(e, "reasons")
                                  }}>
                                  <option value={''}>-All Reasons-</option>
                                  {reasonData.map(data => {
                                    if (data._id !== 'Replacement') {
                                      return <option key={data._id} value={data._id}>{data.description}</option>
                                    }
                                  })}
                                </select>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className='card-body'>
                          <FormProvider
                            form="inline"
                            onSubmit={handleCustomerOrderSubmit} //other wise send two request
                            onSubmitSuccess={() => { setEdit(null); setDeleting(null); }}
                            initialValues={edit !== null ? edit : deleting}
                            enableReinitialize
                          >
                            {formProps => {
                              return (
                                <form onSubmit={formProps.handleSubmit}>
                                  <Table className="-striped"
                                    columns={qtyColumns}
                                    data={customerOrderData}
                                    defaultPageSize={10}
                                    getTrProps={(state, rowInfo) => {
                                      return {
                                        style: {
                                          fontSize: '14px',
                                          background: rowInfo?.original?.orders?.status?.includes('Dispatched') ? '#FFB3B3' : ''
                                        }
                                      }
                                    }}
                                    filterable
                                  />
                                </form>
                              )
                            }}

                          </FormProvider>
                        </div>
                      </form>
                    </ErrorBoundary>
                  </React.Fragment>
                </div>
                <div className="modal-footer">
                  <button id="update_order_qty" data-dismiss="modal" type="button" className="btn btn-primary" onClick={updateOrderQtyHandle}>Update</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                    setCustomerOrderData([])
                    setCustomerOrderIdForRep(null)
                    setCustomerOrderItemIdForRep(null)
                    setDataForReplacement(null)
                  }}>Close</button>
                </div>
              </div>
            </div>
          </div> :
          <div id="orderItemModel" className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg">
              <div className="modal-content animate" >
                <div className="modal-header">
                  <h4 className="modal-title text-uppercase">Order Items - {orderId}</h4>
                  <button type="button" className="close" onClick={e => closeOrderItemModel()}>&times;</button>
                </div>
                <div className="modal-body">
                  <FormProvider
                    form="inline"
                    enableReinitialize
                  >
                    {formProps => {
                      return (
                        <form onSubmit={formProps.handleSubmit}>
                          <Table className="-striped"
                            columns={orderItemsColumns}
                            data={allOrderItems}
                            defaultPageSize={10}
                            filterable
                          />
                        </form>
                      )
                    }}

                  </FormProvider>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={e => closeOrderItemModel()}>Cancel</button>
                </div>
              </div>
            </div>
          </div>}
      </ErrorBoundary>

      <ErrorBoundary>
        <div id="mergePurchaseOrders" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content animate" >
              <div className="modal-header">
                <h4 className="modal-title text-uppercase">Merge Purchase Orders</h4>
                <button type="button" className="close" data-dismiss="modal" onClick={closeRangePopup}>&times;</button>
              </div>
              <div className="modal-body">
                <table>
                  <tbody>
                    <tr>
                      <td>Select Supplier* :</td>
                      <td>
                        <select className="form-control" style={{ width: '100%' }} onChange={e => {
                          setSupplierForMerge(e.target.value)
                        }}>
                          <option value={''}>- Select Supplier -</option>
                          <option value='all'>All</option>
                          {props.supplierData.map(data => {
                            return <option value={data.supplier._id}>{data.supplier.name}</option>
                          })}
                        </select>
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td>Select Date Range* :</td>
                      <td><DatePicker onChange={setMPOStartDateMethod} placeholderText="Start Date" selected={mpoStartDate} className="form-control" /></td>
                      <td><DatePicker onChange={setMPOEndDateMethod} placeholderText="End Date" selected={mpoEndDate} className="form-control" /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button id="" type="button" className="btn btn-primary" onClick={mergePurchaseOrders}>Merge</button>
                <button type="button" className="btn btn-secondary" onClick={closeRangePopup} data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>


      <ErrorBoundary>
        <div id="sendToHistory" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content animate" >
              <div className="modal-header">
                <h4 className="modal-title text-uppercase">Send To History</h4>
                <button type="button" className="close" data-dismiss="modal" onClick={closeRangePopup}>&times;</button>
              </div>
              <div className="modal-body">
                <table>
                  <tbody>
                    <tr>
                      <td>Select Supplier* :</td>
                      <td>
                        <select className="form-control" style={{ width: '100%' }} onChange={e => {
                          setSupplierForMerge(e.target.value)
                        }}>
                          <option value={''}>- Select Supplier -</option>
                          <option value='all'>All</option>
                          {props.supplierData.map(data => {
                            return <option value={data.supplier._id}>{data.supplier.name}</option>
                          })}
                        </select>
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td>Select Date Range* :</td>
                      <td><DatePicker onChange={setMPOStartDateMethod} placeholderText="Start Date" selected={mpoStartDate} className="form-control" /></td>
                      <td><DatePicker onChange={setMPOEndDateMethod} placeholderText="End Date" selected={mpoEndDate} className="form-control" /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button id="" type="button" className="btn btn-primary" onClick={sendToHistory}>Send To History</button>
                <button type="button" className="btn btn-secondary" onClick={closeRangePopup} data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
      <ErrorBoundary>
        <div id='receivingItemsModel' className="modal fade" role="dialog">
          <div className="modal-dialog modal-xl">
            <div className="modal-content animate">
              <div className="modal-header">
                <h4 className="modal-title text-uppercase">Receive Items</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <FormProvider
                  form="inline"
                  onSubmit={handleReceQtySubmit}
                  onSubmitSuccess={() => { setEdit(null); setDeleting(null); }}
                  initialValues={edit !== null ? edit : deleting}
                  enableReinitialize
                >
                  {formProps => {
                    return (
                      <form onSubmit={formProps.handleSubmit}>
                        <Table className="-striped"
                          columns={receiveColumns}
                          data={receiveItemsData}
                          defaultPageSize={10}
                          filterable
                        />
                      </form>
                    )
                  }}

                </FormProvider>
              </div>
              <div className="modal-footer">
                <button id="update_order_qty" disabled={disableRecItems} data-dismiss="modal" type="button" className="btn btn-primary" onClick={receiveOneQty}>Update</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => deleteAllQty("Multi")} >Close</button>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        <div id='receivingAllItemsModel' className="modal fade" role="dialog">
          <div className="modal-dialog modal-xl">
            <div className="modal-content animate">
              <div className="modal-header">
                <h4 className="modal-title text-uppercase">Receive Items</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <FormProvider
                  form="inline"
                  onSubmit={handleReceAllQtySubmit}
                  onSubmitSuccess={() => { setEdit(null); setDeleting(null); }}
                  initialValues={edit !== null ? edit : deleting}
                  enableReinitialize
                >
                  {formProps => {
                    return (
                      <form onSubmit={formProps.handleSubmit}>
                        <Table className="-striped"
                          columns={receiveColumns}
                          data={props.newAllItems}
                          defaultPageSize={10}
                          filterable
                        />
                      </form>
                    )
                  }}

                </FormProvider>
              </div>
              <div className="modal-footer">
                <button id="update_order_qty" disabled={disableRecItems} data-dismiss="modal" type="button" className="btn btn-primary" onClick={reciveAllQty}>Update</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => deleteAllQty("Multi")} >Close</button>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        <div id="exportData" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content animate" >
              <div className="modal-header">
                <h4 className="modal-title text-uppercase">{historyOrder ? "Export Recieved data" : "Export data"}</h4>
                <button type="button" className="close" data-dismiss="modal"> &times;</button>
              </div>
              <div className="modal-body">
                <div className='card'>
                  <div className='card-header' style={{ display: 'inline-flex' }}>
                    <div>From :</div><input type='date' className='form-control' style={{ width: '40%' }} value={exportDate.from}
                      onChange={e => {
                        setExportDate({ ...exportDate, from: e.target.value })
                      }} />
                    <div>To : </div><input type='date' className='form-control' style={{ width: '40%' }} value={exportDate.to.split('T')[0]}
                      onChange={e => {
                        let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                        let splitTime = toDate[1].split('.')
                        let timeString = `23:59:59.${splitTime[1]}`
                        let newToDate = `${toDate[0]}T${timeString}`
                        setExportDate({ ...exportDate, to: newToDate })
                      }} />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={excelExportOrder}>Export now</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        <button id="replacementModalPOButton" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" data-toggle='modal' data-target="#replacementModalPO" style={{ visibility: 'hidden' }}></button>

        <ReplacementModalPO itemData={props.item_data} purchaseItem={purchaseItemToBeReplaced} currentOrderItems={customerOrderData} proceed={proceed} hideReplcementModel={hideReplcementModel} setPurchaseItemForReplacement={setPurchaseItemForReplacement} purchaseItemForReplacement={purchaseItemForReplacement} props={props} />

      </ErrorBoundary>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    data: state.rOrder.data,
    purchaseItemById: state.rOrder.purchaseItemById,
    supplierData: state.rSupplier.data,
    purchaseItemData: state.rOrder.purchase_item_data,
    currency_data: state.rBasicData.currency_data,
    payment_conditons: state.rBasicData.payment_condition_data,
    delivery_conditons: state.rBasicData.delivery_condition_data,
    item_data: state.rBasicData.item_data,
    token: state.rLogin.token,
    isLoading: state.rLogin.loading,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    userId: state.rLogin.id,
    deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehousesForUser,
    receivedOrders: state.rOrder.receivedOrders,
    receivedItems: state.rOrder.receivedItems,
    newItems: state.rOrder.newItems,
    newAllItems: state.rOrder.newAllItems
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOrderData: token => { dispatch(getOrderData(token)) },
    getItem: token => { dispatch(getItemData(token)) },
    getPurchaseItem: token => { dispatch(getPurchaseItemData(token)) },
    getPurchaseItemDataById: (id, props) => { dispatch(getPurchaseItemDataById(id, props)) },
    getSupplierData: token => { dispatch(getSupplierData(token)) },
    getCurrencyData: token => { dispatch(getCurrencyData(token)) },
    getPaymentCondition: token => { dispatch(getPaymentCondition(token)) },
    getDeliveryCondition: token => { dispatch(getDeliveryCondition(token)) },
    addOrder: (state, token) => { dispatch(addOrder(state, token)) },
    addPurchaseOrderItem: (state, token) => { dispatch(addPurchaseOrderItem(state, token)) },
    updateOrder: (data, token) => { dispatch(updateOrder(data, token)) },
    deleteOrder: (data, token) => { dispatch(deleteOrder(data, token)) },
    updateQty: (data, token) => { dispatch(updateOrderItem(data, token)) },
    deleteItem: (data, token) => { dispatch(deleteItem(data, token)) },
    mergePurchaseOrders: (data, token) => { dispatch(mergePurchaseOrders(data, token)) },
    sendToHistory: (data, token) => { dispatch(sendToHistory(data, token)) },
    updateCustomerOrderByQty: (data, orderData, token) => { dispatch(updateCustomerOrderByQty(data, orderData, token)) },
    getDeliveryWarehousesForCurrentUser: (token, user) => { dispatch(getDeliveryWarehousesForCurrentUser(token, user)) },
    addPurchaseNewItemData: (data, token, callback) => { dispatch(addPurchaseNewItemData(data, token, callback)) },
    updatePurchaseItemData: (data, token, callback) => { dispatch(updatePurchaseItemData(data, token, callback)) },
    deletePurchaseNewItemData: (data, token, callback) => { dispatch(deletePurchaseNewItemData(data, token, callback)) },
    getReceivedOrdersData: token => { dispatch(getReceivedOrdersData(token)) },
    getPurchaseReceivedItemDataById: (data, token) => { dispatch(getPurchaseReceivedItemDataById(data, token)) },
    getNewItemsData: (data, token) => { dispatch(getNewItemsData(data, token)) },
    getAllNewItemsData: (data, token) => { dispatch(getAllNewItemsData(data, token)) },
    deleteAllReceiveItems: (data, token) => { dispatch(deleteAllReceiveItems(data, token)) },
    getOrderReportData: (date, token, callback) => { dispatch(getOrderReportData(date, token, callback)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Order));



