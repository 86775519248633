import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash';
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";
import { toast } from "react-toastify";
import axios from 'axios';

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import ActionsCell from "../../components/ActionCell";
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import SearchableSelectComp from '../../components/SearchableSelectComp';
import { Permission, PriceFormat, DateNumberFormat } from "../../utils";

import { getUsers } from '../../../actions/Administrator/user_actions';
import { getPriceChangeOrder, updatePurchasePrice, getNewPriceChangeItems, getPurchaseOrders } from "../../../actions/Purchasing/purchasing_price_action";

export const PurchasePrice = props => {

    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [subData, setSubData] = useState([]);
    const [orders, setOrders] = useState([]);
    const [orderId, setOrderId] = useState(null);
    const [newData, setNewData] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    // const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [currentPO, setCopyPO] = useState({});
    const [copyPO, setCurrentPO] = useState({});
    const [price, setPrice] = useState('');
    const [purchasePrice, setPurchasePrice] = useState('');
    const [priceStatus, setPriceStatus] = useState(true);
    const [historyCheck, setHistoryCheck] = useState(true);
    const [margin, setMargin] = useState(true);
    const [originalPrice, setOriginalPrice] = useState(0);
    const [originalMargin, setOriginalMargin] = useState(0);
    const [updateCustomerOrderPrice, setUpdateCustomerOrderPrice] = useState("false");

    const [sortOptions, setSortOptions] = useState([{ id: 'purchasepricechange.date', desc: true }]);

    useEffect(() => {
        props.getPriceChangeOrder(props.token)
        props.getNewPriceChangeItems(props.token)
        props.getPurchaseOrders(props.token)
    }, [])

    useEffect(() => {
        var data = [...new Map(props.priceChangeOrderData.map(item => [item.purchaseorder._id, item])).values()]
        data.map(item => {
            item['label'] = `${item.purchaseorder.orderid} - ${item.supplier.name}`
        })
        setOrders(data)
    }, [props.priceChangeOrderData])

    const getPurchaseItem = () => {
        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/purchasing/getPurchaseItemByOrder', { id: orderId })
            .then(result => {
                let sortData = _.sortBy(result.data, ['items.product', 'items.desc', 'items.name'])
                console.log(sortData)
                setSubData(sortData)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }

    useEffect(() => {
        getPurchaseItem()
    }, [orderId])

    const reload = () => {
        props.getNewPriceChangeItems(props.token)
        props.getPriceChangeOrder(props.token)
        props.getPurchaseOrders(props.token)
        getPurchaseItem()
    }

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('PURCHASE', 'PURCHASE PRICE', 'UPDATE', props),
            delPerm: Permission('PURCHASE', 'PURCHASE PRICE', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableNumberColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };

            return <Field name={props.column.id} type="Number" {...fieldProps} />;
        }
    };

    const handleSubmit = (value) => {
        if (editing !== null && deleting === null) {
            var data = _.find(subData, function (o) { return o.purchaseitem._id === value.purchaseitem._id })
            if (data.purchaseitem.price !== value.purchaseitem.price) {
                console.log('1')
                data.purchaseitem.price = Number(value.purchaseitem.price)
                data.items.price = Number(((Number(data.oldSalesPrice) / Number(data.oldPrice)) * (Number(value.purchaseitem.price))).toFixed(2))
                data.margin = Number(data.constMargin)
                data.user = props.userId
            } else if (data.items.price !== value.items.price) {
                console.log('2')
                data.items.price = Number(value.items.price)
                data.margin = Number((((value.items.price - (value.items.price * data.items.discountPercentage / 100)) - (value.purchaseitem.price - (value.purchaseitem.price * (data.items?.purchaseDiscount || 0) / 100))) / (value.items.price - (value.items.price * data.items.discountPercentage / 100)) * 100 || 0).toFixed(2))
            }
        }
        console.log(subData);
        setNewData(subData)
    }

    const updatePurchaseOrder = () => {
        if (subData !== []) {
            props.updatePurchasePrice(newData, props.token)
        }
        props.getNewPriceChangeItems(props.token)
        props.getPriceChangeOrder(props.token)
        getPurchaseItem()
        setOrderId(null)
        setSubData([])
        setSelectedOrder(null)
    }

    const clone = obj => {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    }

    const addPriceChangeHandle = () => {
        const data = currentPO;
        data.margin = Number(margin);
        data.purchaseitem.price = Number(purchasePrice)
        data.items.price = Number(price)
        data.priceStatus = priceStatus === "true" || priceStatus === true ? true : false;
        data.user = props.userId
        data.updateCustomerOrderPrice = updateCustomerOrderPrice === "true" ? true : false;

        props.updatePurchasePrice([data], props.token);

        props.getNewPriceChangeItems(props.token)
        props.getPriceChangeOrder(props.token)
        props.getPurchaseOrders(props.token)
        setPrice(0)
        setPurchasePrice(0)
        setOriginalPrice(0)
        setCurrentPO({})
        setCopyPO({})
        setOriginalMargin(0)
        getPurchaseItem()
        setPriceStatus(true)
        setUpdateCustomerOrderPrice("false")

        document.getElementById("priceChangeForm").reset();
        window.$('#priceChange').modal('hide');
    }

    const changePriceMethod = (nPrice) => {
        setPrice(Number(nPrice));
        let margin = Number((((nPrice - (nPrice * currentPO.items.discountPercentage / 100)) - (purchasePrice - (purchasePrice * (currentPO.items?.purchaseDiscount || 0) / 100))) / (nPrice - (nPrice * currentPO.items.discountPercentage / 100)) * 100 || 0).toFixed(2))
        setMargin(Number(margin));
    }

    const changePurchasePriceMethod = (nPurchasePrice) => {
        setPurchasePrice(Number(nPurchasePrice));
        let price = Number(((Number(nPurchasePrice) * 100) / (100 - Number(originalMargin))).toFixed(2))
        setMargin(Number(originalMargin));
        setPrice(Number(price))
    }

    const columns = [
        { Header: "Purchase Order ID", accessor: "purchaseorder.orderid", ...GridFilter },
        { Header: "Item Name", accessor: "items.name", ...GridFilter },
        { Header: "Supplier Name", accessor: "supplier.name", ...GridFilter },
        { Header: "Item Code", accessor: "items.itemCode", ...GridFilter },
        //{ Header: "Price", accessor: "items.price", ...GridFilter },
        {
            Header: "Margin", accessor: "margin", ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.margin}
                </div>
        },
        {
            Header: "Purchase Discount", accessor: "purchaseitem.purchaseDiscount", ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseitem.purchaseDiscount}
                </div>
        },
        {
            Header: "Purchase Price", accessor: "purchaseitem.price", ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.purchaseitem.price)}
                </div>
        },
        {
            Header: "Total Value",
            accessor: "", ...GridFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseitem.price === undefined || props.original.purchaseitem.price === null ?
                        0
                        : PriceFormat((Number(props.original.purchaseitem.price) - (Number(props.original.purchaseitem.price) * Number(props.original.purchaseitem.purchaseDiscount || 0) / 100)) * (props.original.purchaseitem.orderqty))}
                </div>
        },
        { Header: "Price Changes", id: "status", accessor: (e) => e.purchaseitem.price_status === true ? "Yes" : "No" },
        { Header: "Unit", accessor: "items.priceUnit", ...GridFilter },
        {
            Header: "Price Change",
            Cell: props =>
                <>
                    <div className="custom-control custom-switch">
                        <a data-toggle="modal" data-target="#priceChange" style={{ "color": "#007bff", "textDecoration": "underline", "cursor": "pointer" }}
                            onClick={(e) => {
                                setCopyPO({ ...props.original })
                                setCurrentPO({ ...props.original })
                                setPrice(props.original.items.price)
                                setPurchasePrice(props.original.purchaseitem.price)
                                setPriceStatus(props.original.purchaseitem.price_status)
                                setOriginalPrice(props.original.items.price)
                                setOriginalMargin(props.original.margin);
                                setMargin(props.original.margin);
                            }}
                        >+ Price Change</a>
                    </div>
                </>
        },
        // {
        //     Header: "",
        //     maxWidth: 130,
        //     minWidth: 130,
        //     fixed: 'right',
        //     filterable: false,
        //     getProps: getActionProps,
        //     Cell: ActionsCell
        // },
    ]

    const historyColumns = [
        { Header: "Item", accessor: "items.name" },
        { Header: "Item Code", accessor: "items.itemCode", ...GridFilter, getProps: () => { return { style: { 'text-align': 'center' } } }, },
        { Header: "Purchase Order", accessor: "purchaseorder.orderid", getProps: () => { return { style: { 'text-align': 'center' } } }, ...GridFilter },

        {
            Header: "Old price", accessor: "purchasepricechange.oldprice",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchasepricechange.oldprice}
                </div>
        },
        {
            Header: "New price", accessor: "purchasepricechange.newprice",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchasepricechange.newprice}
                </div>
        },
        {
            Header: "Old Sales Price", accessor: "purchasepricechange.oldsalesprice",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchasepricechange.oldsalesprice}
                </div>
        },
        {
            Header: "New Sales Price", accessor: "purchasepricechange.newsalesprice",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchasepricechange.newsalesprice}
                </div>
        },
        { Header: "User", accessor: "users.label", getProps: () => { return { style: { 'text-align': 'center' } } } },
        {
            Header: "Date", accessor: "purchasepricechange.date",
            ...GridFilter,
            Cell: prop => <>
                {DateNumberFormat(prop.value)}
            </>,
            getProps: () => { return { style: { 'text-align': 'center' } } },
        },
    ]

    const subColumns = [
        {
            Header: "Item Name", accessor: "items.name",
            ...GridFilter,
            Cell: props =>
                <div>
                    {props.original.items.desc != null && props.original.items.desc != "" && props.original.items.desc !== undefined ? props.original.items.desc : props.original.items.name}
                </div>
        },
        {
            Header: "Item Code", accessor: "items.itemCode", ...GridFilter,
        },
        {
            Header: "Price", accessor: "items.price",
            getProps: () => { return { style: { 'text-align': 'center' } } },
            ...editableNumberColumnProps
        },
        {
            Header: "Margin",
            accessor: "margin",
            getProps: () => { return { style: { 'text-align': 'center' } } },
            Cell: ({ value, original }) => {
                return (
                    <>
                        {Number((original.margin))} %
                    </>
                )
            }
        },
        {
            Header: "Purchase Discount", accessor: "purchaseitem.purchaseDiscount",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseitem.purchaseDiscount}
                </div>
        },
        {
            Header: "Purchase price", accessor: "purchaseitem.price",
            getProps: () => { return { style: { 'text-align': 'center' } } },
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseitem.price === undefined || props.original.purchaseitem.price === null ?
                        0
                        : PriceFormat(props.original.purchaseitem.price)}
                </div>,
            ...editableNumberColumnProps
        },
        {
            Header: "Total value", accessor: "items.price",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseitem.price === undefined || props.original.purchaseitem.price === null ?
                        0
                        : PriceFormat((Number(props.original.purchaseitem.price) - (Number(props.original.purchaseitem.price) * Number(props.original.purchaseitem.purchaseDiscount || 0) / 100)) * (props.original.purchaseitem.orderqty))}
                </div>
        },
        { Header: "Price Changes", id: "status", accessor: (e) => e.purchaseitem.price_status === true ? "Yes" : "No" },
        { Header: "Unit", accessor: "items.priceUnit" },
        {
            Header: "",
            maxWidth: 130,
            minWidth: 130,
            fixed: 'right',
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ];

    return (
        <div className="right_col" role="main" >
            <div className="card">
                <div className="card-header">
                    {/* <button className="btn btn-primary" data-toggle="modal" data-target="#addPurchaseOrder">
                        + Add
                    </button> */}
                    <button className="btn btn-primary" style={{ "float": "right" }}
                        disabled={props.isLoading}
                        onClick={() => reload()}
                    // style={{ float: 'right' }}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                    <input type='radio' name='history' style={{ marginRight: '5px' }} checked={historyCheck ? true : false} onChange={e => { console.log(historyCheck); setHistoryCheck(true) }} /><label>Active</label>
                    <input type='radio' name='history' style={{ marginLeft: '30px', marginRight: '5px' }} checked={historyCheck ? false : true} onChange={e => { console.log(historyCheck); setHistoryCheck(false) }} /><label>History</label>
                </div>
                {props.isLoading
                    ? <div className="card-body">
                        <SkeletonTable columnsCount={5} dataCount={10} />
                    </div>
                    :
                    historyCheck ?
                        <ErrorBoundary>
                            <div className="card-body">
                                <Table className="-striped"
                                    columns={columns}
                                    data={props.purchaseOrders}
                                    defaultPageSize={10}
                                />
                            </div>
                        </ErrorBoundary>
                        :
                        <ErrorBoundary>
                            <div className="card-body">
                                <Table className="-striped"
                                    columns={historyColumns}
                                    data={props.priceChangeItems}
                                    defaultPageSize={10}
                                    sorted={sortOptions}
                                    onSortedChange={val => {
                                        setSortOptions(val)
                                    }}
                                />
                            </div>
                        </ErrorBoundary>
                }
            </div>
            <ErrorBoundary>
                <div id="addPurchaseOrder" className="modal fade" role="dialog">
                    <div className={`modal-dialog ${subData !== [] ? 'modal-xl' : 'modal-lg'}`}>
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Change Purchase Price</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <React.Fragment>
                                    <table>
                                        <tbody>
                                            <tr style={{ width: "70%" }}>
                                                <td style={{ textAlign: "left", marginLeft: "10px" }}>Purchase Order:</td>
                                                <td style={{ width: "60%" }}>
                                                    <SearchableSelectComp options={orders} label={'label'} onChange={e => e.purchaseorder._id === '' ? null : (setOrderId(e.purchaseorder._id), setSelectedOrder(e.purchaseorder.orderid))} value={[]} />
                                                </td>
                                                <td>{selectedOrder}</td>
                                            </tr>
                                            <br />
                                        </tbody>
                                    </table>
                                </React.Fragment>
                                <ErrorBoundary>
                                    {orderId !== null ?
                                        <FormProvider
                                            form="inline"
                                            onSubmit={handleSubmit}
                                            onSubmitSuccess={() => { setEditing(null); setDeleting(null) }}
                                            initialValues={editing === null ? deleting : editing}
                                            enableReinitialize
                                        >
                                            {formProps => {
                                                return (
                                                    <form onSubmit={formProps.handleSubmit}>
                                                        <Table className="-striped"
                                                            columns={subColumns}
                                                            data={subData}
                                                            defaultPageSize={10}
                                                        />
                                                    </form>
                                                );
                                            }}
                                        </FormProvider>
                                        : null
                                    }
                                </ErrorBoundary>
                            </div>
                            <div className="modal-footer">
                                <button id="change_purchase_price" type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => { updatePurchaseOrder(); reload() }}>Add</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { setSubData([]); setOrderId(null); setSelectedOrder(null) }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

            <ErrorBoundary>
                <div id="priceChange" className="modal fade" role="dialog">
                    <div className="modal-dialog model-xl">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Price Change</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form id='priceChangeForm'>
                                    <table>
                                        <tr>
                                            <td>Purchase Order ID:</td>
                                            <td>
                                                <input disabled type="text" className="form-control" value={currentPO.purchaseorder?.orderid} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Item Name:</td>
                                            <td>
                                                <input disabled type="text" className="form-control" value={currentPO.items?.name} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Item Code:</td>
                                            <td>
                                                <input disabled type="text" className="form-control" value={currentPO.items?.itemCode} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Price:</td>
                                            <td>
                                                <input type="number" className="form-control" value={price} onChange={e => changePriceMethod(e.target.value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Margin:</td>
                                            <td>
                                                <input disabled type="text" className="form-control" value={margin} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Purchase Discount:</td>
                                            <td>
                                                <input disabled type="text" className="form-control" value={currentPO.purchaseitem?.purchaseDiscount} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Purchase Price:</td>
                                            <td>
                                                <input type="number" className="form-control" value={purchasePrice} onChange={e => changePurchasePriceMethod(e.target.value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total Value:</td>
                                            <td>
                                                <input disabled type="text" className="form-control" value={(purchasePrice === undefined || purchasePrice === null) ? 0 : PriceFormat((Number(purchasePrice) - (Number(purchasePrice) * Number(currentPO.purchaseitem?.purchaseDiscount || 0) / 100)) * (currentPO.purchaseitem?.orderqty))} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Change PO & Item Price:</td>
                                            <td>
                                                <select className="form-control" onChange={e => setPriceStatus(e.target.value)}>
                                                    <option value="true" selected>Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Unit:</td>
                                            <td>
                                                <input disabled type="text" className="form-control" value={currentPO.items?.priceUnit} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Customer Order Price</td>
                                            <td>
                                                <select className="form-control" value={updateCustomerOrderPrice} onChange={e => setUpdateCustomerOrderPrice(e.target.value)}>
                                                    <option value="true">Yes</option>
                                                    <option value="false" selected>No</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </table>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" disabled={props.isLoading ? true : false} onClick={addPriceChangeHandle}>Confirm</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userId: state.rLogin.id,
        users: state.rUser.users,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        purchaseItemData: state.rOrder.purchase_item_data,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
        priceChangeOrderData: state.rPurchasePrice.data,
        priceChangeItems: state.rPurchasePrice.items,
        purchaseOrders: state.rPurchasePrice.purchaseOrders
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: ({ token }) => { dispatch(getUsers(token)) },
        getPriceChangeOrder: token => { dispatch(getPriceChangeOrder(token)) },
        getPurchaseOrders: token => { dispatch(getPurchaseOrders(token)) },
        getNewPriceChangeItems: token => { dispatch(getNewPriceChangeItems(token)) },
        updatePurchasePrice: (data, token) => { dispatch(updatePurchasePrice(data, token)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePrice);


