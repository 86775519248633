/**
 * @file This file defined for brand details
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field } from "redux-form";
import FormProvider from '../../components/FormProvider';

import Table from "react-table";
// import $ from 'jquery';
// import * as BS from "react-bootstrap";
// import Select from 'react-select';

import ErrorBoundary from '../../components/ErrorBoundary';
import Permission from '../../utils/Permission';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import ActionsCell from '../../components/ActionCell';
import { BOOL } from '../../utils';
import { getBrandData } from '../../../actions/Basic_data/basic_data_action';
import GridFilter from '../../components/GridFilter';
import { addBrand, deleteBrand, updateBrand } from '../../../actions/Basic_data/brand_action';
// import HighlightCell from '../../components/HighlightCell';
import editableComponent from '../../components/EditableComponent';
import GridFilters from "../../components/GridFilter";
// import { isLoadingAsync } from '../../../actions/login_action';


export const Brand = props => {
    const [edit, setEdit] = useState(null);
    const [deleteing, setDeleteing] = useState(null);
    const [addBrand, setAddBrand] = useState({ name: '', desc: '', image: '', sortOrder: 0, active: true })
    const [image, setImage] = useState('');

    const consignmentOptions = [
        { 'value': 'Yes', 'label': 'Yes' },
        { 'value': 'No', 'label': 'No' }
    ];

    useEffect(() => {
        // props.isLoading(true)
        props.getBrandData(props)
    }, [])

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            // mode: (edit === rowProps.original && rowProps.original.orders.status !== 'Delivered') ? "edit" : "view",
            mode: edit === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEdit(rowProps.original),
                onCancel: () => setEdit(null),
                onDelete: () => setDeleteing(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'BRAND', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'BRAND', 'DELETE', props)
        }) ||
        {};

    // const editableComponent = ({ input, editing, value, ...rest }) => {
    //     const Component = editing ? BS.FormControl : BS.FormControl.Static;
    //     const children =
    //         (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    //     return <Component {...input} {...rest} children={children} />;
    // };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const editableNumberColumnProps = {
        ...GridFilter,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} type="Number" {...fieldProps} />;
        }
    };

    const selectableColumnProps = {
        Cell: Props => {
            const editing = edit === Props.original;

            return (editing ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {(BOOL).map(({ value, label }) =>
                        <option
                            key={value} value={value}>
                            {label}
                        </option>
                    )}

                </Field> : <label>{Props.value ? 'YES' : 'NO'}</label>)
        }
    };

    const selectableColumnConsignmentProps = {
        ...GridFilters,
        Cell: Props => {
            const editing = edit === Props.original;

            return (editing ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {(consignmentOptions).map(({ value, label }) =>
                        <option
                            key={value} value={value}>
                            {label}
                        </option>
                    )}

                </Field> : <label>{Props.value}</label>)
        }
    };

    const imageColumnProps = {
        ...GridFilter,
        Cell: Props => {
            const editing = edit === Props.original;

            return (editing ? <Field name={Props.column.id} component="image" className='form-control'>
                <input type="file" key='image' name='image' onChange={e => setImage(e.target.files[0])}></input>
            </Field> : <label>{Props.value}</label>)
        }
    };

    const columns = [
        { Header: "Name", accessor: "name", ...editableColumnProps },
        { Header: "Description", accessor: "desc", ...editableColumnProps },
        { Header: "Sort Order", accessor: "sortOrder", ...editableNumberColumnProps },
        { Header: "Consignment", accessor: "consignment", ...selectableColumnConsignmentProps },
        { Header: "Image", accessor: "image.url", ...imageColumnProps },
        {
            Header: "View Image",
            accessor: "image.url",
            filterable: false,
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#viewImage' className='btn btn-link'
                        onClick={() => {
                            setImage(row.value);
                        }}
                    >{row.value === undefined ? '' : 'View Image'}</button>
                </>
        },
        {
            Header: "Active", accessor: "active",
            filterMethod: (filter, row) => {
                // console.log(filter, row)
                if (filter.value === "all") {
                    // console.log('all');
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>,
            ...selectableColumnProps
        },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const addBrandHandle = () => {
        if (addBrand.name.trim() !== '') {
            props.addBrandData(addBrand, props)
            setAddBrand({ name: '', desc: '', image: '', sortOrder: 0, active: true })
            document.getElementById("brandImage").value = ''
            document.getElementById("brandActive").value = true
        } else {
            console.log('error');
        }
    }

    const handleBrandSubmit = value => {
        console.log(value)
        if (edit !== null) {
            if (value.active.toString() === edit.active.toString()) {
                value.isActiveChanged = false
            } else {
                value.isActiveChanged = true
            }
            if (value.image.url === edit.image.url) {
                console.log('image not changed');
                props.updateBrandData(false, value, props)
            } else {
                console.log('image changed');
                value.image = image;
                props.updateBrandData(true, value, props)
            }
        } else if (deleteing !== null) {
            props.deleteBrandData(value, props)
        }
    }

    return (
        <div className="right_col" role="main">
            <ErrorBoundary>
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" data-toggle="modal" data-target="#addBrand" style={{ float: 'left' }}
                            disabled={props.isLoading ? true : Permission('BASIC DATA', 'BRAND', 'ADD', props)}
                            onClick={() => setAddBrand({ name: '', desc: '', image: '', sortOrder: 0, active: true })}
                        >+ Brand</button>
                    </div>
                    <div className="card-body">
                        <ErrorBoundary>
                            {props.isLoading
                                ? <>
                                    <SkeletonTable columnsCount={9} dataCount={10} />
                                </>
                                : <FormProvider
                                    form="inline"
                                    onSubmit={handleBrandSubmit}
                                    onSubmitSuccess={() => { setEdit(null); setDeleteing(null) }}
                                    initialValues={edit !== null ? edit : deleteing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <Table className="-striped"
                                                    columns={columns}
                                                    data={props.data}
                                                    defaultPageSize={10}
                                                    filterable
                                                />
                                            </form>
                                        )
                                    }}
                                </FormProvider>}

                        </ErrorBoundary>
                    </div>
                </div>
            </ErrorBoundary>

            {/* add brand */}
            <ErrorBoundary>
                <div id="addBrand" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Add Brand</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <table>
                                    <tr>
                                        <td>Name: *</td>
                                        <td>
                                            <input type="text" className="form-control" value={addBrand.name} onChange={e => setAddBrand({ ...addBrand, name: e.target.value })} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Description:</td>
                                        <td>
                                            <input type="text" className="form-control" value={addBrand.desc} onChange={e => setAddBrand({ ...addBrand, desc: e.target.value })} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Sort Order:</td>
                                        <td>
                                            <input type="number" className="form-control" value={addBrand.sortOrder} onChange={e => setAddBrand({ ...addBrand, sortOrder: e.target.value })} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Active:</td>
                                        <td>
                                            <select id='brandActive' className="form-control" value={addBrand.active} onChange={e => setAddBrand({ ...addBrand, active: e.target.value })}>
                                                {BOOL?.map(({ value, label }) =>
                                                    <option key={value} value={value}>{label}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Image:</td>
                                        <td>
                                            <input id='brandImage' type="file" className="form-control-file" onChange={e => setAddBrand({ ...addBrand, image: e.target.files[0] })} />
                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" disabled={props.isLoading ? true : false} onClick={addBrandHandle}>Add</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

            {/* show images */}
            <ErrorBoundary>
                <div id="viewImage" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Image</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <React.Fragment>
                                    {(image === undefined) ? 'No Image Found' : <img alt="brand images" src={image} width="100%" />}

                                </React.Fragment>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        data: state.rBasicData.brand,
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        loggedUser: state.rLogin.name,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // isLoading: data => { dispatch(isLoadingAsync(data)) },
        getBrandData: props => { dispatch(getBrandData(props)) },
        addBrandData: (data, props) => { dispatch(addBrand(data, props)) },
        updateBrandData: (isImageUpdate, data, props) => { dispatch(updateBrand(isImageUpdate, data, props)) },
        deleteBrandData: (data, props) => { dispatch(deleteBrand(data, props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Brand)
