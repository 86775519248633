/**
 * @file This file defined for skeleton table component
 * @author Bhanuka Chathuranga
 */
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Table from "react-table";
import "react-table/react-table.css";

/**
 * This file defined for generate to row data
 * 
 * @param {Number} count row count
 * @returns row skeleton component
 */
const dataGeneretor = count => {
    let i = 0
    let data = []
    do {
        data.push({})
        i++
    } while (count > i);
    return data
}

/**
 * This function defined for generate to column data
 * 
 * @param {Number} count column count
 * @returns column skeleton component
 */
const headerGeneretor = count => {
    let i = 0
    let data = []
    do {
        data.push({ Header: <Skeleton height={30} count={1} duration={1} />, Cell: prop => <Skeleton count={1} duration={1} /> })
        i++
    } while (count > i);
    return data
}

/**
 * 
 * @returns skeleton table compoonent
 */
function SkeletonTable({ columnsCount = 3, dataCount = 10 }) {
    const columns = headerGeneretor(columnsCount)
    const data = dataGeneretor(dataCount)

    return (
        <div>
            {/* <Skeleton count={5} /> */}
            <Table
                // data={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                data={data}
                columns={columns}
                defaultPageSize={10}
                showPaginationBottom={false}
            />
        </div>
    )
}

export default SkeletonTable
