/**
 * Format date dd-MMM-YYYY
 * @param {String} date 
 * @returns Date String
 */
 const DateStringFormat = (date = '') => {
    let toDate = date === '' ? new Date(): new Date(date)
    let formatDate = toDate.toLocaleDateString('en-GB',{day:'2-digit', month:'short', year:'numeric'}).replace(/ /g, '-')
    return formatDate;
}

export default DateStringFormat
