/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getDeliveryTypes, addDeliveryType, updateDeliveryType, deleteDeliveryType } from '../../../actions/Basic_data/delivery_type_actions';

const ReactTableFixedColumns = withFixedColumns(Table);


class DeliveryType extends React.Component {

    boolData = [
        { '_id': 'true', 'name': 'true' },
        { '_id': 'false', 'name': 'false' }
    ];

    weightUnitData = [
        { '_id': 'Gram', 'name': 'Gram' },
        { '_id': 'Kilogram', 'name': 'Kilogram' },
        { '_id': 'Milliliters', 'name': 'Milliliters' },
        { '_id': 'Pieces', 'name': 'Pieces' },
    ];

    state = {
        deliveryTypes: [],
        name: '',
        desc: '',
        noOfItems: 0,
        isBundled: '',
        upperWeight: 0,
        lowerWeight: 0,
        isWeightRange: '',
        isApproxWeight: '',
        weightUnit: '',
        weight: 0,
        slug: '',
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'name', desc: false }],
    };

    componentDidMount() {
        this.props.getDeliveryTypes(this.props.token);
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };


    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDropdownList,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    props.column.Header === 'Measuring Unit' ? this.weightUnitData :
                        this.boolData).map(data =>
                            <option key={data._id} value={data._id}>{data.name}</option>
                        )}
            </Field> : <label>{props.value}</label>)
        }
    };

    imageColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: null,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} component="image" className='form-control'>
                <input type="file" key='image' name='image' onChange={e => this.setState({ image: e.target.files[0] })}></input>
            </Field> : <label>{props.value}</label>)
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        status: rowProps.original.status
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'DELIVERY TYPE', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'DELIVERY TYPE', 'DELETE', this.props)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === 'Measuring Unit') {
            this.setState({ weightUnit: value })
        } else if (column === 'Is Bundled') {
            this.setState({ isBundled: value })
        } else if (column === 'Is Weight Range') {
            this.setState({ isWeightRange: value })
        } else if (column === 'Is Approx Weight') {
            this.setState({ isApproxWeight: value })
        }
    }

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values;

            if (isNaN(data.upperWeight)) {
                toast.error('Upper weight must be integer');
                return;
            }

            if (isNaN(data.lowerWeight)) {
                toast.error('Lower weight must be integer');
                return;
            }

            if (isNaN(data.weight)) {
                toast.error('Weight must be integer');
                return;
            }

            if (data.upperWeight < data.lowerWeight) {
                toast.error('Upper weight cannot be lower than Lower Weight!');
                return;
            }

            data.isBundled = this.state.isBundled === "true" ? true : false;
            data.isWeightRange = this.state.isWeightRange === "true" ? true : false;
            data.isApproxWeight = this.state.isApproxWeight === "true" ? true : false;
            data.weightUnit = this.state.weightUnit;

            this.props.updateDeliveryType(data, this.props.token)

            this.setState({
                isBundled: '',
                isWeightRange: '',
                isApproxWeight: '',
                weightUnit: '',
            });

        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values._id
            }

            this.props.deleteDeliveryType(data, this.props.token)
        }
    };

    addDeliveryTypeHandle = () => {
        var { name,
            desc,
            noOfItems,
            isBundled,
            upperWeight,
            lowerWeight,
            isWeightRange,
            isApproxWeight,
            weightUnit,
            weight } = this.state;

        if (name.trim() !== ''
            && noOfItems !== ''
            && isBundled !== ''
            && upperWeight !== ''
            && lowerWeight !== ''
            && isWeightRange !== ''
            && isApproxWeight !== ''
            && weightUnit !== ''
            && weight !== '') {

            if (this.state.upperWeight < this.state.lowerWeight) {
                toast.error('Upper weight cannot be lower than Lower Weight!')
                return;
            }

            this.state.isBundled = this.state.isBundled === "true" ? true : false;
            this.state.isWeightRange = this.state.isWeightRange === "true" ? true : false;
            this.state.isApproxWeight = this.state.isApproxWeight === "true" ? true : false;

            this.state.slug = '1';

            this.props.addDeliveryType(this.state, this.props.token)

            this.setState({
                name: '',
                desc: '',
                isBundled: '',
                upperWeight: 0,
                lowerWeight: 0,
                isWeightRange: '',
                isApproxWeight: '',
                weightUnit: '',
                weight: 0,
            });

            document.getElementById("addDeliveryTypeForm").reset();
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    deliveryTypeColumns = [
        { Header: "Name", accessor: "name", ...this.editableColumnProps },
        { Header: "Description", accessor: "desc", maxWidth: 250, minWidth: 250, ...this.editableColumnProps },
        { Header: "No Of Items", accessor: "noOfItems", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Is Bundled", accessor: "isBundled", ...this.selectableColumnProps },
        { Header: "Upper Weight", accessor: "upperWeight", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Lower Weight", accessor: "lowerWeight", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Is Weight Range", accessor: "isWeightRange", ...this.selectableColumnProps },
        { Header: "Is Approx Weight", accessor: "isApproxWeight", ...this.selectableColumnProps },
        { Header: "Measuring Unit", accessor: "weightUnit", ...this.selectableColumnProps },
        { Header: "Measure", accessor: "weight", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'DELIVERY TYPE', 'ADD', this.props)} data-toggle="modal" data-target="#addDeliveryType">+ Delivery Type</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.deliveryTypeColumns}
                                                    data={this.props.deliveryTypes}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>

                    <div id="addDeliveryType" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Delivery Type</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addDeliveryTypeForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ name: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Description :</td>
                                                        <td><input type="textarea" className="form-control" onChange={e => this.setState({ desc: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>No of Items* :</td>
                                                        <td><input type="number" className="form-control" onChange={e => this.setState({ noOfItems: Number(e.target.value) })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is Bundled* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ isBundled: e.target.value })}>
                                                                <option value={''}>- Select Is Bundled -</option>
                                                                {this.boolData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Upper Weight* :</td>
                                                        <td><input type="number" className="form-control" onChange={e => this.setState({ upperWeight: Number(e.target.value) })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Lower Weight* :</td>
                                                        <td><input type="number" className="form-control" onChange={e => this.setState({ lowerWeight: Number(e.target.value) })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>is Weight Range* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ isWeightRange: e.target.value })}>
                                                                <option value={''}>- Select Is Weight Range -</option>
                                                                {this.boolData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is Approx Weight* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ isApproxWeight: e.target.value })}>
                                                                <option value={''}>- Select Is Approx Weight -</option>
                                                                {this.boolData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Measuring Unit* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ weightUnit: e.target.value })}>
                                                                <option value={''}>- Select Measuring Unit -</option>
                                                                {this.weightUnitData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Measure* :</td>
                                                        <td><input type="number" className="form-control" onChange={e => this.setState({ weight: Number(e.target.value) })} /></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="add_stock" type="button" className="btn btn-primary" onClick={this.addDeliveryTypeHandle}>Add</button>
                                    {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        deliveryTypes: state.rDeliveryType.deliveryTypes,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDeliveryTypes: (token) => { dispatch(getDeliveryTypes(token)) },
        addDeliveryType: (data, token) => { dispatch(addDeliveryType(data, token)) },
        updateDeliveryType: (data, token) => { dispatch(updateDeliveryType(data, token)) },
        deleteDeliveryType: (data, token) => { dispatch(deleteDeliveryType(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryType);


