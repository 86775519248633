/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const surveyQuestionsDataAsync = data => {
    return { type: 'FETCH_SURVEY_QUESTIONS', data }
}

/**
 * @param {*} token 
 */
export const getSurveyQuestionsData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/surveyQuestions/getSurveyQuestionsData')
            .then(result => {
                dispatch(surveyQuestionsDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 * @returns 
 */
export const addSurveyQuestion = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/surveyQuestions/addSurveyQuestion', { data })
            .then(result => {
                toast.success('Question added.')
                window.$('#addSurveyQuestion').modal('hide');
                dispatch(getSurveyQuestionsData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 * @returns 
 */
export const updateSurveyQuestion = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/surveyQuestions/updateSurveyQuestion', { data })
            .then(result => {
                toast.success('Question updated.')
                dispatch(getSurveyQuestionsData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const deleteSurveyQuestion = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/surveyQuestions/deleteSurveyQuestion', { data })
            .then(result => {
                toast.success('Question deleted.')
                dispatch(getSurveyQuestionsData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

