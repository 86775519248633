import { toast } from 'react-toastify';
import { cityAsync } from './basic_data_action';
var axios = require('axios');


/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const getCity = ({ token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/city/getcities')
            .then(result => {
                // console.log(result);
                dispatch(cityAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addCity = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/city/addCity', { data })
            .then(result => {
                toast.success('Successfully Added City')
                dispatch(getCity({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateCity = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/city/updateCity', { data })
            .then(result => {
                toast.success('Successfully Updated City')
                dispatch(getCity({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteCity = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/city/deleteCity', { data })
            .then(result => {
                toast.success('Successfully Deleted City')
                dispatch(getCity({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateStatus = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/city/updateStatus', { data })
            .then(result => {
                // toast.success('Successfully Updated Status')
                dispatch(getCity({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}