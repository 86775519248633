import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import Permission from '../../utils/Permission';
import ActionsCell from "../../components/ActionCell";
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import { DateNumberFormat } from '../../utils';
import GridFilters from "../../components/GridFilter";

import { getDisposedItemsData, cancelDisposedItem, approveDisposedItem } from '../../../actions/POS/disposed_items_actions';

export const DisposedItems = props => {

    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [active, setActive] = useState(true)

    useEffect(() => {
        props.getDisposedItemsData(props.token)
    }, [])

    const reload = () => {
        props.getDisposedItemsData(props.token)
    }


    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('POS', 'disposed items', 'UPDATE', props),
            delPerm: Permission('POS', 'disposed items', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };


    const activeColumns = [
        { Header: "Item Name", accessor: "items.name", filterable: true, minWidth: 150 },
        { Header: "Item Code", accessor: "items.itemCode", filterable: true },
        { Header: "Supplier", accessor: "supplier.name", filterable: true, minWidth: 150 },
        { Header: "Outlet", accessor: "deliverywarehouse.name", filterable: true, minWidth: 150 },
        { Header: "Reason", accessor: "disposeditems.reason", filterable: true, minWidth: 200 },
        { Header: "Quantity", accessor: "disposeditems.quantity", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Purchase Price", accessor: "disposeditems.purchasePrice", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Sales Price", accessor: "disposeditems.salesPrice", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Expiry Date", accessor: "disposeditems.expiryDate", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        {
            Header: "Created At", accessor: "disposeditems.createdAt", minWidth: 200,
            Cell: props => {
                if (props.original?.disposeditems?.createdAt !== undefined) {
                    var today = new Date(props.original?.disposeditems?.createdAt)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.disposeditems?.createdAt;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: 'Actions',
            maxWidth: 200,
            minWidth: 200,
            Cell: row => (
                <div style={{ textAlign: "center" }}>
                    <button
                        className="btn btn-primary"
                        onClick={e => {
                            e.preventDefault();
                            if (window.confirm('Are you sure you wish to approve this move stock request?')) {
                                const data = {
                                    _id: row.original.disposeditems._id,
                                    stockItemId: row.original.disposeditems.outletStockRef,
                                    expiryDate: row.original.disposeditems.expiryDate,
                                    quantity: row.original.disposeditems.quantity,
                                    itemId: row.original.disposeditems.itemRef,
                                    outlet: row.original.deliverywarehouse._id,
                                    user: props.userId,
                                    reason: row.original.disposeditems.reason,
                                }

                                props.approveDisposedItem(data, props.token);
                            }
                        }}
                    >
                        Approve
                    </button>

                    <button
                        className="btn btn-danger"
                        onClick={e => {
                            e.preventDefault();
                            if (window.confirm('Are you sure you wish to cancel this dispose item request?')) {
                                const data = {
                                    _id: row.original.disposeditems._id,
                                    user: props.userId
                                }

                                props.cancelDisposedItem(data, props.token);
                            }
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )
        },
    ]

    const completedColumns = [
        { Header: "Item Name", accessor: "items.name", filterable: true, minWidth: 150 },
        { Header: "Item Code", accessor: "items.itemCode", filterable: true },
        { Header: "Supplier", accessor: "supplier.name", filterable: true, minWidth: 150 },
        { Header: "Outlet", accessor: "deliverywarehouse.name", filterable: true, minWidth: 150 },
        { Header: "Reason", accessor: "disposeditems.reason", filterable: true, minWidth: 200 },
        { Header: "Quantity", accessor: "disposeditems.quantity", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Purchase Price", accessor: "disposeditems.purchasePrice", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Sales Price", accessor: "disposeditems.salesPrice", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Expiry Date", accessor: "disposeditems.expiryDate", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Approved By (User)", accessor: "completedBy.name.first", filterable: true, minWidth: 150 },
        {
            Header: "Approval Status", accessor: "disposeditems.status", ...GridFilters, minWidth: 120,
            Cell: props => {
                if (props.original?.disposeditems?.approved === true) {
                    return <p style={{color: "green"}}>Approved</p>
                } else {
                    return <p style={{color: "red"}}>Cancelled</p>
                }
            }
        },
        {
            Header: "Completed At", accessor: "disposeditems.completedAt", minWidth: 200,
            Cell: props => {
                if (props.original?.disposeditems?.completedAt !== undefined) {
                    var today = new Date(props.original?.disposeditems?.completedAt)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.disposeditems?.completedAt;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
    ]

    const handleSubmit = value => {

    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className='card-header'>
                    <div className='row'>
                        <div className="col-md-6">
                            <input type='radio' name='active' style={{ marginRight: '5px' }} checked={active ? true : false} onChange={e => { setActive(true) }} /><label>Active</label>
                            <input type='radio' name='active' style={{ marginLeft: '30px', marginRight: '5px' }} checked={active ? false : true} onChange={e => { setActive(false) }} /><label>Completed</label>
                        </div>
                        <div className="col-md-6" style={{ textAlign: 'right' }}>
                            <button className="btn btn-primary" onClick={e => reload()}><i className="fa fa-refresh"></i></button>
                        </div>
                    </div>

                </div>

                {active ?
                    <div className="card-body">
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                initialValues={editing === null ? deleting : editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                columns={activeColumns}
                                                data={props.activeDisposedItemsData}
                                                defaultPageSize={10}
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </div> : <div className="card-body">
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                initialValues={editing === null ? deleting : editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                columns={completedColumns}
                                                data={props.completedDisposedItemsData}
                                                defaultPageSize={10}
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </div>
                }


            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        userId: state.rLogin.id,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        activeDisposedItemsData: state.rDisposedItems.activeDisposedItemsData,
        completedDisposedItemsData: state.rDisposedItems.completedDisposedItemsData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDisposedItemsData: (token) => { dispatch(getDisposedItemsData(token)) },
        cancelDisposedItem: (data, token) => { dispatch(cancelDisposedItem(data, token)) },
        approveDisposedItem: (data, token) => { dispatch(approveDisposedItem(data, token)) },
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisposedItems)
