/**
 * @file This file defined for Item card component
 * @author Bhanuka Chathuranga
 */
import React from 'react'
import { PriceFormat } from '../../../utils'

/**
 * 
 * @param {Object} obj
 * @param {Object} obj.item item data
 * @param {Component} obj.children children data
 * @returns {Component} ItemCard component
 */
function ItemCard({ item, children, isFastDeliveryStatus }) {
    return (
        <div className='item-card' style={item.fastDiscountValue.length > 0 && item.is_fast_delivery && item.brands === 'Fast Delivery' && isFastDeliveryStatus !== undefined && isFastDeliveryStatus ? { borderLeftStyle: 'solid', borderLeftColor: '#f1537df8' } : {}}>
            <div className='row'>
                <div className='column center'>
                    <div><span style={{ fontWeight: 'bold' }}>{item.is_fast_delivery && item.brands === 'Fast Delivery' ? `${item.itemBrand} - ` : ''}</span>{item.desc || item.name}</div>
                    {item.discountPercentage > 0 || (item.fastDiscountValue.length > 0 && item.is_fast_delivery && item.fastStatus && !item.isCartItem) || item.originalPrice > item.price ?
                        <div style={{ paddingLeft: '8px', textDecoration: 'line-through 2px #f74b78' }}>
                            {item.priceUnit} {item.discountPercentage > 0 || (item.fastDiscountValue.length > 0 && item.is_fast_delivery && item.fastStatus && !item.isCartItem) ?
                                PriceFormat(item.price)
                                : item.originalPrice > item.price ?
                                    PriceFormat(item.originalPrice)
                                    : ''}
                        </div>
                        : null}
                </div>
                <div className='column'>
                    <div className='row btn-section'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemCard
