/**
 * @file    This file defined Order page for POS
 * @author  Umesh Ranthilina
 */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as BS from "react-bootstrap";
import { Field } from "redux-form";
import Permission from '../../utils/Permission';
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import { connect } from 'react-redux';
import Table from "react-table";
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";
import _ from 'lodash';
import { DateNumberFormat } from '../../utils';
import ActionsCell from "../../components/ActionCell";
import Select from 'react-select';
import { getParameters } from '../../../actions/Administrator/parameters_actions';
import { getDeliveryWarehouses } from "../../../actions/Basic_data/delivery_warehouse_actions";
import { addFastDeliveryDiscount, getFastDeliveryDiscount, deleteFastDeliveryDiscount, updateFastDeliveryDiscount } from "../../../actions/Ordering/ordering_order_action";
import { getItemData } from '../../../actions/Basic_data/basic_data_action';

function FastDeliveryDiscount(props) {

  const [selectedItems, setSelectedItems] = useState({});
  const [warehouseId, setWarehouseId] = useState('')
  const [discount, setDiscount] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [editing, setEditing] = useState(null);
  const [deleting, setDeleting] = useState(null);

  useEffect(() => {
    props.getItems(props.token);
    props.getParameters(props.token);
    props.getDeliveryWarehouses(props);
    props.getFastDeliveryDiscount(props.token)
  }, [])

  const formatDateNew = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const formatDate = (date) => {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    var hours = '' + d.getHours();
    var minutes = '' + d.getMinutes();
    var seconds = '' + d.getSeconds();

    if (hours.length < 2) {
      hours = '0' + hours;
    }
    if (minutes.length < 2) {
      minutes = '0' + minutes;
    }
    if (seconds.length < 2) {
      seconds = '0' + seconds;
    }

    var date = [year, month, day].join('-');
    var time = [hours, minutes, seconds].join(':')

    return [date, time].join(' ');
  }
  useEffect(() => {
    if (selectedItems !== undefined) {
      const filterWarehouse = props.parameters.filter(value => value.name === "Warehouse");

      const warehouseData = props.deliveryWarehouseData.length > 0 && filterWarehouse.length > 0
        ? props.deliveryWarehouseData.filter(value => value.name === filterWarehouse[0].value)
        : [];

      if (warehouseData.length > 0 && selectedItems !== undefined) {

        setWarehouseId(warehouseData[0]._id)
      }
    }

  }, [props.parameters && props.deliveryWarehouseData && selectedItems]);

  const onSelect = (value, name) => {
    console.log(value)
    if (name == "item") {
      setSelectedItems(value)
    }
  }

  const clear = () => {
    setSelectedItems({})
    setDiscount(" ")
    setStartDate(" ")
    setEndDate(" ")
  }

  const editableComponent = ({ input, edit, value, ...rest }) => {
    const Component = edit ? BS.FormControl : BS.FormControl.Static;
    const children =
      (!edit && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component  {...input} {...rest} children={children} />;
  };

  const editableColumnProps = {
    ...GridFilter,
    Cell: props => {
      const edit = editing === props.original;
      const fieldProps = {
        component: editableComponent,
        edit,
        props
      };
      return <Field name={props.column.id} {...fieldProps} />;
    }
  };

  const renderDateTimePicker = ({ input, edit, value, props, ...rest }) => {
    const Component = edit ? BS.FormControl : BS.FormControl.Static;
    return edit && <Component type="date" {...input} style={{ maxHeight: edit ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
  }

  const selectableDateColumnProps = {
    ...GridFilter,
    Cell: props => {
      const edit = editing === props.original;
      const fieldProps = {
        component: renderDateTimePicker,
        edit,
        props
      };

      return (edit ? <Field name={props.column.id} {...fieldProps} /> : <label>{DateNumberFormat(props.value)}</label>)
    }
  };

  const addFastDeliveryHandle = () => {
    if (selectedItems && Object.keys(selectedItems).length > 0 && discount > 0 && startDate !== undefined && startDate.trim() !== ''
      && endDate !== undefined && endDate.trim() !== '' && (new Date(startDate) < new Date(endDate))) {
      let data = {
        "itemId": selectedItems._id,
        "start_date": startDate,
        "end_date": endDate,
        "warehouse": warehouseId,
        "discount": discount,
        "user": props.userId
      }

      let checkId = _.filter(props.fastDeliveryDiscountItems, function (val) {
        if (String(val.fastdeliverydiscount.item_ref) === String(selectedItems._id) && formatDateNew(val.fastdeliverydiscount.start_date) === formatDateNew(startDate)
          && formatDateNew(val.fastdeliverydiscount.end_date) === formatDateNew(endDate))
          return val
      })

      if (checkId.length === 0) {
        props.addFastDeliveryDiscount(data, props.token)
        document.getElementById("addFastDeliveryDiscountForm").reset();
        clear();
        window.$('#addFastDeliveryDiscount').modal('hide');
      } else {
        toast.info('Already added...!')
      }
    } else {
      toast.error("Please Fill the Fields")
    }
  }

  const getActionProps = (gridState, rowProps) =>
    (rowProps && {
      mode: editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => setEditing(rowProps.original),
        onCancel: () => setEditing(null),
        onDelete: () => setDeleting(rowProps.original)
      },
      updPerm: Permission('ORDERS', 'FAST DELIVERY DISCOUNT', 'UPDATE', props),
      delPerm: Permission('ORDERS', 'FAST DELIVERY DISCOUNT', 'DELETE', props)
    }) ||
    {};

  const columns = [
    { Header: "Item Name", accessor: "items.name", ...GridFilter },
    { Header: "Item Code", accessor: "items.itemCode", ...GridFilter },
    { Header: "Outlet", accessor: "warehouse.name", ...GridFilter },
    { Header: "Discount", accessor: "fastdeliverydiscount.discount", ...editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
    {
      Header: "Start Date", accessor: "fastdeliverydiscount.start_date", ...selectableDateColumnProps,
    },
    {
      Header: "End Date", accessor: "fastdeliverydiscount.end_date", ...selectableDateColumnProps,
    },
    {
      Header: "Created By", accessor: "users.name.first", ...GridFilter,
    },
    {
      Header: "Updated By", accessor: "modifyUser.name.first", ...GridFilter,
      Cell: props => {
        if (props.original?.modifyUser !== undefined) {
          return <>{props.original?.modifyUser.name.first}</>
        } else {
          return <>{''}</>
        }
      }
    },
    {
      Header: "Created At", accessor: "fastdeliverydiscount.createdAt", ...GridFilter,
      Cell: props => {
        if (props.original?.fastdeliverydiscount?.created_at !== undefined) {
          var date = props.original?.fastdeliverydiscount?.created_at;
          var dateTime = formatDate(date || '')
          return <>{dateTime}</>
        } else {
          return <>{''}</>
        }
      }
    },
    {
      Header: "Updated At", accessor: "fastdeliverydiscount.updated_at", ...GridFilter,
      Cell: props => {
        if (props.original?.fastdeliverydiscount?.updated_at !== undefined) {
          var date = props.original?.fastdeliverydiscount?.updated_at;
          var dateTime = formatDate(date || '')
          return <>{dateTime}</>
        } else {
          return <>{''}</>
        }
      }
    },
    {
      Header: "Actions",
      maxWidth: 130,
      minWidth: 130,
      filterable: false,
      getProps: getActionProps,
      Cell: ActionsCell
    },
  ]

  const reload = () => {
    props.getItems(props.token)
    props.getParameters(props.token)
    props.getDeliveryWarehouses(props)
    props.getFastDeliveryDiscount(props.token)
  }

  const handleSubmit = (values) => {
    console.log(values)
    if (deleting === null && editing !== null) {
      if (new Date(values.fastdeliverydiscount.start_date) < new Date(values.fastdeliverydiscount.end_date)) {
        let fastDeliveryData = {
          id: values.fastdeliverydiscount._id,
          discount: Number(values.fastdeliverydiscount.discount),
          userId: props.userId,
          start_date: new Date(values.fastdeliverydiscount.start_date),
          end_date: new Date(values.fastdeliverydiscount.end_date)
        }

        props.updateFastDeliveryDiscount(fastDeliveryData, props.token)

      } else {
        toast.error("Please select the date")
      }
    } else if (deleting !== null && editing === null) {
      var data = {
        'id': values.fastdeliverydiscount._id,
        'userId': props.userId
      }
      props.deleteFastDeliveryDiscount(data, props.token)
    }
  }
  return (
    <div className="right_col" role="main">
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-11">
              <button className="btn btn-primary" disabled={Permission('ORDERS', 'FAST DELIVERY DISCOUNT', 'ADD', props)} data-toggle="modal" data-target="#addFastDeliveryDiscount" onClick={e => clear()}>+ Fast Delivery Discount Items</button>
            </div>
            <div style={{ float: 'right' }} className="col-md-1">
              <button className="btn btn-primary" onClick={() => reload()}>
                <i className="fas fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <React.Fragment>
            <ErrorBoundary>
              <FormProvider
                form="inline"
                onSubmit={handleSubmit}
                onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                initialValues={editing === null ? deleting : editing}
                enableReinitialize

              >
                {formProps => {
                  return (
                    <form onSubmit={formProps.handleSubmit}>
                      <Table className="-striped"
                        columns={columns}
                        data={props.fastDeliveryDiscountItems}
                        defaultPageSize={10}
                        showPageJump={false}
                      />
                    </form>
                  );
                }}
              </FormProvider>
            </ErrorBoundary>
          </React.Fragment>

          <div id="addFastDeliveryDiscount" className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg">
              <div className="modal-content animate" >
                <div className="modal-header">
                  <h4 className="modal-title text-uppercase">Add Fast Delivery Discount Item</h4>
                  <button type="button" className="close" data-dismiss="modal" onClick={e => clear()}>&times;</button>
                </div>
                <div className="modal-body">
                  <React.Fragment>
                    <form id='addFastDeliveryDiscountForm'>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Select Item*</label>
                        <div className="col-sm-9">
                          <Select
                            options={props.activeItems}
                            value={selectedItems}
                            theme={theme => ({ ...theme, borderRadius: 0 })}
                            onChange={(e) => onSelect(e, "item")}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Start Date</label>
                        <div className="col-sm-3">
                          <div>
                            <input id='dateExpStock' type='date' value={startDate} className='form-control' onChange={e => setStartDate(e.target.value)} />
                          </div>
                        </div>
                        <label className="col-sm-3 col-form-label">End Date</label>
                        <div className="col-sm-3">
                          <div>
                            <input id='dateExpStock' type='date' value={endDate} className='form-control' onChange={e => setEndDate(e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Discount</label>
                        <div className="col-sm-9">
                          <input type="number" className="form-control form-control" value={discount} placeholder="Discount" onChange={e => setDiscount(e.target.value)} />
                        </div>
                      </div>
                    </form>
                  </React.Fragment>
                </div>
                <div className="modal-footer">
                  <button id="add_stock" type="button" className="btn btn-primary" onClick={e => addFastDeliveryHandle()}>Add</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => clear()} >Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

const mapStateToProps = state => ({
  token: state.rLogin.token,
  isLoading: state.rLogin.loading,
  userId: state.rLogin.id,
  loggedUser: state.rLogin.name,
  userProgramme: state.rLogin.programme,
  programme: state.rPermission.programme,
  subProgramme: state.rPermission.subProgramme,
  options: state.rPermission.option,
  activeItems: state.rBasicData.item_data,
  parameters: state.rParameters.parameters,
  deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
  fastDeliveryDiscountItems: state.rOrdering.fastDeliveryDiscountItems,
})

const mapDispatchToProps = dispatch => ({
  getParameters: (token) => { dispatch(getParameters(token)) },
  getItems: token => { dispatch(getItemData(token)) },
  getDeliveryWarehouses: (token) => { dispatch(getDeliveryWarehouses(token)) },
  addFastDeliveryDiscount: (data, token) => { dispatch(addFastDeliveryDiscount(data, token)) },
  getFastDeliveryDiscount: (token) => { dispatch(getFastDeliveryDiscount(token)) },
  deleteFastDeliveryDiscount: (data, token) => { dispatch(deleteFastDeliveryDiscount(data, token)) },
  updateFastDeliveryDiscount: (data, token) => { dispatch(updateFastDeliveryDiscount(data, token)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(FastDeliveryDiscount);


