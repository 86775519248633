import { toast } from 'react-toastify';
import { getRegionData } from './basic_data_action';
var axios = require('axios');


/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addRegion = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/region/addRegion', { data })
            .then(result => {
                toast.success('Successfully Updated City')
                dispatch(getRegionData({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateRegion = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/region/updateRegion', { data })
            .then(result => {
                toast.success('Successfully Updated City')
                dispatch(getRegionData({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateFixedDate = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/region/updateFixedDate', { data })
            .then(result => {
                toast.success('Successfully Updated City')
                dispatch(getRegionData({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteRegion = (data, { token }) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/region/deleteRegion', { data })
            .then(result => {
                toast.success('Successfully Updated City')
                dispatch(getRegionData({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}