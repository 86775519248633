import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";
import { toast } from 'react-toastify';

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary';
import Permission from '../../utils/Permission';
import ActionsCell from "../../components/ActionCell";
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { BOOL } from '../../utils';

import { getRegionData } from '../../../actions/Basic_data/basic_data_action';
import { addDeliveryCrew, deleteDeliveryCrew, getDeliveryCrew, updateDeliveryCrew, resetPassword } from '../../../actions/Delivery/delivery_action';
import { getDeliveryWarehouses } from "../../../actions/Basic_data/delivery_warehouse_actions";
var bcrypt = require('bcryptjs');
// import GridFilters from "../../components/GridFilter";

// const bool = [{ value: false, label: 'No' }, { value: true, label: 'Yes' }]

export const DeliveryCrew = props => {

    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [deliverBoys, setDeliverBoys] = useState({
        name: '',
        code: '',
        telephone: '',
        region: '',
        max_qty: '',
        vehicle_no: '',
        third_party: false,
        status: false,
        image: '',
        warehouse: '',
        password: '',
        confirmPassword: ''
    })
    const [currentDCrew, setCurrentDCrew] = useState(null);
    const [resetPasswordObject, setResetPasswordObject] = useState({ newPassword: null, confirmPassword: null });
    const [currentImage, setCurrentImage] = useState(undefined);
    const [image, setImage] = useState('')

    useEffect(() => {
        props.getRegionData(props)
        props.getDeliveryCrew(props)
        props.getDeliveryWarehouses(props)
    }, [])

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            // mode: (edit === rowProps.original && rowProps.original.orders.status !== 'Delivered') ? "edit" : "view",
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('DELIVERY', 'DELIVERY CREW', 'UPDATE', props),
            delPerm: Permission('DELIVERY', 'DELIVERY CREW', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };
            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const selectableColumnProps = {
        // ...GridFilter,
        Cell: Props => {
            // console.log('original', Props.original)
            // console.log('edit', edit)
            const edit = editing === Props.original;

            return (edit ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {(Props.column.Header === 'Warehouse' ? props.deliveryWarehouseData : BOOL).map(data =>
                        <option
                            key={Props.column.Header === 'Warehouse' ? data._id : BOOL ? data.value : data} value={Props.column.Header === 'Warehouse' ? data._id : BOOL ? data.value : data}>
                            {Props.column.Header === 'Warehouse' ? data.name : BOOL ? data.label : data}
                        </option>
                    )}
                </Field> : <label>{Props.column.Header === 'Warehouse' ? Props.value : BOOL ? (Props.value ? 'YES' : 'NO') : ''}</label>)
        }
    }

    const imageColumnProps = {
        // ...GridFilters,
        Cell: props => {
            const edit = editing === props.original;
            // const fieldProps = {
            //     component: null,
            //     editing,
            //     props
            // };

            return (edit ? <Field name={props.column.id} component="image" className='form-control'>
                <input type="file" key='images' name='images' onChange={e => setImage(e.target.files[0])}></input>
            </Field> : <label>{typeof (props.value) === "string" ? props.value : undefined}</label>)
        }
    };

    const columns = [
        { Header: "Name", accessor: "deliverycrew.name", ...editableColumnProps },
        { Header: "Code", accessor: "deliverycrew.code", ...editableColumnProps },
        { Header: "Telephone", accessor: "deliverycrew.telephone", ...editableColumnProps },
        { Header: "Warehouse", accessor: "deliverywarehouse.name", ...selectableColumnProps },
        { Header: "Region", accessor: "regions.name" },
        { Header: "Vehicle No", accessor: "deliverycrew.vehicle_no", ...editableColumnProps },
        {
            Header: "Max Qty", accessor: "deliverycrew.max_qty", ...editableColumnProps,
            Cell: prop =>
                <div style={{ textAlign: 'right' }}>
                    {prop.original.deliverycrew.max_qty}
                </div>
        },
        { Header: "Images", accessor: "deliverycrew.image.url", ...imageColumnProps },
        {
            Header: "View Image",
            accessor: "deliverycrew.image.url",
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#viewImage' className='btn btn-link'
                        onClick={() => {
                            setCurrentImage(row.value);
                        }}
                    >{row.value === undefined ? '' : 'View Image'}</button>
                </>
        },
        {
            Header: "Third Party",
            accessor: "deliverycrew.third_party",
            filterMethod: (filter, row) => {
                // console.log(filter, row)
                if (filter.value === "all") {
                    // console.log('all');
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>,
            ...selectableColumnProps,
        },
        {
            Header: "Active",
            accessor: "deliverycrew.status",
            filterMethod: (filter, row) => {
                // console.log(filter, row)
                if (filter.value === "all") {
                    // console.log('all');
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"true"}>YES</option>
                    <option value={"false"}>NO</option>
                </select>,
            ...selectableColumnProps,
        },
        {
            Header: "Password",
            accessor: "deliverycrew.password",
            minWidth: 150,
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#resetPassword' className='btn btn-link'
                        onClick={() => {
                            setCurrentDCrew(row.original.deliverycrew)
                        }}
                    >Reset Password</button>
                </>
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const addDeliveryCrewHandler = () => {
        if (deliverBoys.name !== '' && deliverBoys.max_qty !== '' && deliverBoys.region !== ''
            && deliverBoys.warehouse !== '' && deliverBoys.code !== '') {
            if (deliverBoys.password !== '' && deliverBoys.confirmPassword !== '') {
                if (deliverBoys.password === deliverBoys.confirmPassword) {
                    delete (deliverBoys.confirmPassword);
                    console.log(deliverBoys)
                    deliverBoys.third_party = Boolean(deliverBoys.third_party)
                    deliverBoys.status = Boolean(deliverBoys.status)
                    console.log(deliverBoys)
                    props.addDeliveryCrew(deliverBoys, props)
                    setDeliverBoys({
                        name: '',
                        code: '',
                        telephone: '',
                        region: '',
                        max_qty: '',
                        vehicle_no: '',
                        third_party: false,
                        status: false, image: '',
                        warehouse: '',
                        password: '',
                        confirmPassword: ''
                    })
                } else {
                    toast.error('Password and Cofirm password are not the same.')
                }
            } else {
                toast.error('Please enter password.')
            }
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    const handleSubmit = value => {
        if (editing !== null) {
            let warehouseData = {
                warehouse: editing.deliverywarehouse !== undefined ?
                    value.deliverywarehouse.name !== editing.deliverywarehouse.name ?
                        value.deliverywarehouse.name
                        : editing.deliverywarehouse._id
                    : value.deliverywarehouse !== undefined ?
                        value.deliverywarehouse.name
                        : '',
            }
            props.updateDeliveryCrew(({ ...value.deliverycrew, warehouseData, image }), props)
        } else if (deleting !== null) {
            props.deleteDeliveryCrew(value, props)
        }
    }

    const resetPassword = () => {
        if (resetPasswordObject.newPassword !== null) {
            if (resetPasswordObject.newPassword === resetPasswordObject.confirmPassword) {
                let data = {
                    '_id': currentDCrew._id,
                    'password': resetPasswordObject.newPassword
                };

                props.resetPassword(data, props.token, (err, result) => {
                    if(!err) {
                        clearResetPasswordForm()
                    }
                })


            } else {
                toast.error('Password and Cofirm password are not the same.')
            }
        } else {
            toast.error('Please enter password.')
        }
    }

    const clearResetPasswordForm = () => {
        document.getElementById("resetPasswordForm").reset();
        setResetPasswordObject({
            newPassword: null,
            confirmPassword: null
        })
    }

    return (
        <div className="right_col" role="main" >
            <div className="card">
                <div className="card-header">
                    <button className="btn btn-primary" disabled={Permission('DELIVERY', 'DELIVERY CREW', 'ADD', props)} data-toggle="modal" data-target="#addDeliveryCrew"
                        onClick={() => setDeliverBoys({ name: '', code: '', telephone: '', region: '', max_qty: '', vehicle_no: '', third_party: false, status: false, image: '', warehouse: '' })}
                    >
                        + Delivery Crew
                    </button>
                </div>
                {props.isLoading
                    ? <div className="card-body">
                        <SkeletonTable columnsCount={5} dataCount={10} />
                    </div>
                    : <div className="card-body">
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                initialValues={editing === null ? deleting : editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                columns={columns}
                                                data={props.deliverBoysData}
                                                defaultPageSize={10}
                                                filterable
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </div>}
            </div>

            {/* add new delivery boy*/}
            <div id="addDeliveryCrew" className="modal fade" role="dialog" >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Add Delivery Crew</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td>Name :</td>
                                            <td>
                                                <input className='form-control' value={deliverBoys.name} autoComplete={false} onChange={e => setDeliverBoys({ ...deliverBoys, name: e.target.value })} />
                                            </td>
                                            <td>Code :</td>
                                            <td>
                                                <input className='form-control' value={deliverBoys.code} autoComplete={false} onChange={e => setDeliverBoys({ ...deliverBoys, code: e.target.value })} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Telephone :</td>
                                            <td>
                                                <input className='form-control' value={deliverBoys.telephone} autoComplete={false} onChange={e => setDeliverBoys({ ...deliverBoys, telephone: e.target.value })} />
                                            </td>
                                            <td>Region :</td>
                                            <td>
                                                <select className='form-control' value={deliverBoys.region} onChange={e => setDeliverBoys({ ...deliverBoys, region: e.target.value })}>
                                                    <option value={''}>- select region -</option>
                                                    {props.region.map(data => <option value={data._id}>{data.name}</option>)}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Max Qty :</td>
                                            <td>
                                                <input type='number' min={0} className='form-control' value={deliverBoys.max_qty} autoComplete={false} onChange={e => setDeliverBoys({ ...deliverBoys, max_qty: e.target.value })} />
                                            </td>
                                            <td>Vehicle no :</td>
                                            <td>
                                                <input className='form-control' autoComplete={false} value={deliverBoys.vehicle_no} onChange={e => setDeliverBoys({ ...deliverBoys, vehicle_no: e.target.value })} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Active :</td>
                                            <td>
                                                <select className='form-control' value={deliverBoys.status} onChange={e => setDeliverBoys({ ...deliverBoys, status: (e.target.value) })}>
                                                    {BOOL.map(data => <option value={data.value}>{data.label}</option>)}
                                                </select>
                                            </td>
                                            <td>
                                                3<sup>rd</sup> party :
                                            </td>
                                            <td>
                                                <select className='form-control' value={deliverBoys.third_party} onChange={e => setDeliverBoys({ ...deliverBoys, third_party: (e.target.value) })}>
                                                    {BOOL.map(data => <option value={data.value}>{data.label}</option>)}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Warehouse :</td>
                                            <td>
                                                <select className='form-control' value={deliverBoys.warehouse} onChange={e => setDeliverBoys({ ...deliverBoys, warehouse: e.target.value })}>
                                                    <option value={''}>- select warehouse -</option>
                                                    {props.deliveryWarehouseData.map(data => <option value={data._id}>{data.name}</option>)}
                                                </select>
                                            </td>
                                            <td>Image :</td>
                                            <td><input type="file" className="form-control-file" onChange={e => setDeliverBoys({ ...deliverBoys, image: e.target.files[0] })} /></td>
                                        </tr>
                                        <tr>
                                            <td>Password :</td>
                                            <td>
                                                <input type='password' className='form-control' autoComplete={false} value={deliverBoys.password} onChange={e => setDeliverBoys({ ...deliverBoys, password: e.target.value })} />
                                            </td>
                                            <td> Confirm Password :</td>
                                            <td>
                                                <input type='password' className='form-control' autoComplete={false} value={deliverBoys.confirmPassword} onChange={e => setDeliverBoys({ ...deliverBoys, confirmPassword: e.target.value })} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={addDeliveryCrewHandler}>Add</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div >

            <ErrorBoundary>
                <div id="viewImage" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Image</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <React.Fragment>
                                    {(currentImage === undefined) ? 'No Image Found' : <img src={currentImage} width="100%"></img>}
                                </React.Fragment>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

            <ErrorBoundary>
                <div id="resetPassword" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Reset Password</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form id='resetPasswordForm'>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    New Password:
                                                </td>
                                                <td>
                                                    <input
                                                        type='password'
                                                        className='form-control'
                                                        value={resetPasswordObject.newPassword}
                                                        onChange={e => setResetPasswordObject({
                                                            ...resetPasswordObject, newPassword: e.target.value
                                                        })}
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    Confirm Password:
                                                </td>
                                                <td>
                                                    <input
                                                        type='password'
                                                        className='form-control'
                                                        value={resetPasswordObject.confirmPassword}
                                                        onChange={e => setResetPasswordObject({
                                                            ...resetPasswordObject, confirmPassword: e.target.value
                                                        })}
                                                    />
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </form>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => clearResetPasswordForm()}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={e => resetPassword()}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

        </div >
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        id: state.rLogin.id,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        deliverBoysData: state.rDeliveryBoys.data,
        region: state.rBasicData.region,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRegionData: props => { dispatch(getRegionData(props)) },
        getDeliveryCrew: props => { dispatch(getDeliveryCrew(props)) },
        addDeliveryCrew: (data, props) => { dispatch(addDeliveryCrew(data, props)) },
        updateDeliveryCrew: (data, props) => { dispatch(updateDeliveryCrew(data, props)) },
        deleteDeliveryCrew: (data, props) => { dispatch(deleteDeliveryCrew(data, props)) },
        resetPassword: (data, token, cb) => { dispatch(resetPassword(data, token, cb)) },
        getDeliveryWarehouses: token => { dispatch(getDeliveryWarehouses(token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCrew)
