import React, { useState, useEffect } from 'react'
import { Field } from "redux-form";
import Table from 'react-table';
import * as BS from "react-bootstrap";

import ActionsCell from '../../../../components/ActionCell';
import FormProvider from '../../../../components/FormProvider'
import GridFilter from '../../../../components/GridFilter';
import { DateNumberFormat, Permission } from '../../../../utils';

const getStockData = (setData, props) => {
    var unique = [...new Map(props?.stockExpDate.exp_items_stocks?.map(item => [`${item['exp_date']}-${item['name']}`, item])).values()]
    let arr = unique?.map(ele => {
        let filterArr = props?.stockExpDate.exp_items_stocks?.filter(obj => {
            return ele.name === obj.name && ele.exp_date === obj.exp_date
        })
        let ids = filterArr?.map(obj => obj._id)
        let value = filterArr?.reduce((total, obj) => { return total + obj.qty }, 0)

        return ({ ...ele, totatlVal: value, ids: ids })
    })
    console.log(arr);
    setData(arr)
}

const renderDateTimePicker = ({ input, editing, value, props, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    return editing && <Component type="date" {...input} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
}


const getColumns = (state, setState, props) => {
    const selectableDateColumnProps = {
        ...GridFilter,
        Cell: props => {
            const editing = state.edit === props.original;
            const fieldProps = {
                component: renderDateTimePicker,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} {...fieldProps} /> : <label>{DateNumberFormat(props.value)}</label>)
        }
    };
    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: state.edit === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setState({ edit: rowProps.original, deleting: null }),
                onCancel: () => setState({ edit: null, deleting: null }),
                onDelete: () => setState({ edit: null, deleting: null })
            },
            updPerm: Permission('STOCK', 'Stock Item Exp{Group}', 'UPDATE', props),
            delPerm: true
        }) ||
        {};

    const columns = [
        { Header: "Warehouse", accessor: "name", Cell: prop => <>{prop.value}</> },
        // { Header: "Exp. Date", accessor: "exp_date", Cell: prop => <>{DateNumberFormat(prop.value)}</> },
        { Header: "Exp. Date", accessor: "exp_date", ...selectableDateColumnProps },
        { Header: "Qty", accessor: "totatlVal", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "User", accessor: "user.first" },
        { Header: "CreatedAt", accessor: "createdAt", Cell: prop => <>{DateNumberFormat(prop.value)}</> },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        }
    ]

    return columns
}

const handleOrderSubmit = (value, rowInfo, state, props) => {
    console.log(rowInfo);
    let data = { ...value, item_ref: rowInfo.original._id, stock_ref: rowInfo.original.stock_id }
    if (value !== state.edit) props.updateExpStocks(data, props)
}


const StockExpUpdate = ({ rowInfo, props }) => {

    const [state, setState] = useState({ edit: null, deleting: null });
    const [data, setData] = useState([]);

    useEffect(() => {
        getStockData(setData, props)
    }, [])

    useEffect(() => {
        getStockData(setData, props)
    }, [props.stockExpDate.exp_items_stocks])


    return (
        <div className='card card-body'>
            <FormProvider
                form="inline"
                onSubmit={value => handleOrderSubmit(value, rowInfo, state, props)}
                onSubmitSuccess={() => setState({ edit: null, deleting: null })}
                initialValues={state.edit === null ? state.deleting : state.edit}
                enableReinitialize
            >
                {formProps => {
                    return (
                        <form onSubmit={formProps.handleSubmit}>
                            <Table
                                columns={getColumns(state, setState, props)} data={data} defaultPageSize={10} />
                        </form>)
                }
                }
            </FormProvider>
        </div>
    )
}

export default StockExpUpdate