/**
 * To check permissions
 * 
 * @param {String} checkPro To check programme name
 * @param {String} checkSubPro To check sub programme name
 * @param {String} checkOpt To check option
 * @param {Object} param3 property
 * @returns {boolean}
 */
const Permission = (checkPro, checkSubPro, checkOpt, { userProgramme, programme, subProgramme, options }) => {
    var programmeId = programme?.filter(obj => {
        if (obj.name.toUpperCase() === checkPro.toUpperCase()) {
            return obj
        }
    })

    var subProgrammeId = subProgramme?.filter(obj => {
        if (obj.programme_ref === programmeId[0]?._id && obj.name.toUpperCase() === checkSubPro.toUpperCase()) {
            return obj
        }
    })

    let view = options?.filter(obj => {
        if (obj.name.toUpperCase() === 'VIEW') {
            return obj
        }
    })

    let opt = options?.filter(obj => {
        if (obj.name.toUpperCase() === checkOpt.toUpperCase()) {
            return obj
        }
    })

    var proIndex = userProgramme?.filter(val => {
        if (val.programmeid === programmeId[0]?._id && val.options === view[0]?._id) {
            return val;
        }
    })

    if (proIndex?.length > 0) {
        let subPro = proIndex[0]?.subprogramme?.filter(obj => {
            if (obj.subprogrammeid === subProgrammeId[0]?._id) {
                return obj
            }
        })
        // console.log(`sub programme : ${JSON.stringify(subPro)}`);
        return !(subPro[0]?.options.includes(view[0]?._id) && subPro[0]?.options.includes(opt[0]?._id))
    } else {
        return true
    }
}

export default Permission;