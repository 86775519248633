/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import Table from "react-table";
import "react-table/react-table.css";
// import _ from 'lodash';

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";

import { getCustomerComplaintsWithOrders } from '../../../actions/Ordering/complains_actions';
import SkeletonTable from "../../components/skeleton/SkeletonTable";

const ReactTableFixedColumns = withFixedColumns(Table);

class Complains extends React.Component {

    state = {
        customerComplaintsData: [],
        images: [],
        sortOptions: [{ id: 'customercomplaints.date', desc: true }],
    };

    componentDidMount() {
        this.props.getCustomerComplaintsWithOrders(this.props.token);
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined && row[id] !== null ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    customerComplaintsColumns = [
        { Header: 'Order ID', accessor: 'orders.orderId', filterable: true, maxWidth: 115 },
        { Header: 'Customer Name', accessor: 'orders.name', filterable: true, maxWidth: 115 },
        { Header: 'Telephone No', accessor: 'orders.telephone', filterable: true, maxWidth: 115 },
        { Header: 'Date', accessor: 'customercomplaints.date', filterable: true, maxWidth: 80 },
        { Header: 'Type', accessor: 'customercomplaints.type', filterable: true, maxWidth: 100 },
        { Header: 'Complain', accessor: 'customercomplaints.complain', filterable: true },
        { Header: 'Item', accessor: 'items.desc', filterable: true },
        { Header: 'Resolved', accessor: 'customercomplaints.resolved', filterable: true, maxWidth: 67 },
        { Header: 'Resolved Date', accessor: 'customercomplaints.resolvedDate', filterable: true, maxWidth: 80 },
        { Header: 'Resolved Method', accessor: 'resolvemethods.desc', filterable: true },
        { Header: 'User', accessor: 'users.name.first', filterable: true },
        {
            Header: 'View Images',
            maxWidth: 120,
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#viewImagesPopup' className='btn btn-link'
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({ images: [] });

                            var imagesArr = [];

                            if (row.original.customercomplaints.imagePath) {
                                row.original.customercomplaints.imagePath.forEach(image => {
                                    const imageObj = {
                                        original: image[0].url,
                                        thumbnail: image[0].url
                                    };

                                    imagesArr.push(imageObj);
                                });
                            }

                            this.setState({ images: imagesArr });
                        }}
                    >{row.original.customercomplaints.imagePath === undefined ? '' : 'View Images'}</button>
                </>
        }
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <h4>Customer Complaints </h4>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                {this.props.isLoading
                                    ? <>
                                        <SkeletonTable columnsCount={9} dataCount={10} />
                                    </>
                                    : <FormProvider
                                        form="inline"
                                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                        enableReinitialize
                                    >
                                        {formProps => {
                                            return (
                                                <form>
                                                    <ReactTableFixedColumns className="-striped"
                                                        sorted={this.state.sortOptions}
                                                        onSortedChange={val => {
                                                            this.setState({ sortOptions: val })
                                                        }}
                                                        columns={this.customerComplaintsColumns}
                                                        data={this.props.customerComplaintsData}
                                                        defaultPageSize={10}
                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                        showPageJump={false}
                                                    />
                                                </form>
                                            );
                                        }}
                                    </FormProvider>}
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>

                    <div id='viewImagesPopup' className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Images</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
                                    {(this.state.images === '' || this.state.images === null) ?
                                        <>
                                            No Data Found
                                </>
                                        :

                                        <ImageGallery items={this.state.images} />
                                    }

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close Images</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="card-footer"></div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        customerComplaintsData: state.rComplain.customerComplaintsData,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCustomerComplaintsWithOrders: (token) => { dispatch(getCustomerComplaintsWithOrders(token)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Complains);


