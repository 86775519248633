/**
 * @file This file defines for redux store
 * @author Bhanuka Chathuranga
 */
import { rootReducer } from '../reducer/Reducer';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

export const store = createStore(rootReducer
    , compose(applyMiddleware(thunk)
    , window.devToolsExtension ? window.devToolsExtension() : f => f
    )
);