import React, { useState, useEffect } from 'react';
import { Field } from "redux-form";
import Table from 'react-table';
import * as BS from "react-bootstrap";

import ActionsCell from '../../../../components/ActionCell';
import FormProvider from '../../../../components/FormProvider';
import GridFilter from '../../../../components/GridFilter';
import { Permission } from '../../../../utils';

import DateNumberFormat from '../../../../utils/DateNumberFromat';
import ConvertDecimalPlaces from '../../../../utils/ConvertDecimalPlaces';

const inputHandler = ({ target: { value } }, key, filterData, { setFilterData }) => {
    setFilterData({ ...filterData, [key]: value })
}

const DateElement = ({ label, filterData, setFilterData }) => {
    return <>
        <div style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>{label} :</div>
        <div>
            <input type='date' className='form-control' value={(filterData[label])} onChange={e => inputHandler(e, label, filterData, { setFilterData })} />
        </div>
    </>
}

const StockSearchEle = ({ rowInfo, props }) => {

    const itemId = rowInfo?.original?._id || ''
    const today = new Date()
    const [filterData, setFilterData] = useState({ item: '', warehouse: '', from: '', to: '' })

    useEffect(() => {
        props.getDeliveryWarehouses(props)
        if (itemId !== '') props.getExpDateStock({ ...filterData, item: itemId }, props)
    }, [rowInfo]);

    return <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <select className='form-control' onChange={e => inputHandler(e, 'warehouse', filterData, { setFilterData })}>
                    <option value={''}>-All Delivery Warehouse-</option>
                    {props.deliveryWarehouseData.map(ele => <option key={ele._id} value={ele._id}>{ele.name}</option>)}
                </select>
            </div>
            <div style={{ display: 'flex' }}>
                <DateElement label={'from'} filterData={filterData} setFilterData={setFilterData} />
            </div>
            <div style={{ display: 'flex' }}>
                <DateElement label={'to'} filterData={filterData} setFilterData={setFilterData} />
            </div>
            <div style={{ display: 'flex' }}>
                <button className='btn btn-primary' onClick={() => props.getExpDateStock({ ...filterData, item: itemId }, props)}>Search</button>
            </div>
        </div>
    </div>;
};

const StockExpView = ({ rowInfo, props }) => {
    const [state, setState] = useState({ edit: null, deleting: null })

    const renderDateTimePicker = ({ input, editing, value, props, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        return editing && <Component type="date" {...input} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    }

    const selectableDateColumnProps = {
        ...GridFilter,
        Cell: props => {
            const editing = state.edit === props.original;
            const fieldProps = {
                component: renderDateTimePicker,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} {...fieldProps} /> : <label>{DateNumberFormat(props.value)}</label>)
        }
    };

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: state.edit === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setState({ edit: rowProps.original, deleting: null }),
                onCancel: () => setState({ edit: null, deleting: null }),
                onDelete: () => setState({ edit: null, deleting: rowProps.original })
            },
            updPerm: Permission('STOCK', 'Stock Item Exp{View}', 'UPDATE', props),
            delPerm: Permission('STOCK', 'Stock Item Exp{View}', 'DELETE', false)
        }) ||
        {};

    const columns = [
        { Header: "Warehouse", accessor: "name", ...GridFilter, Cell: prop => <>{prop.value}</> },
        // { Header: "Exp. Date", accessor: "exp_date", ...GridFilter, Cell: prop => <>{DateNumberFormat(prop.value)}</> },
        { Header: "Exp. Date", accessor: "exp_date", ...selectableDateColumnProps },
        {
            Header: "Qty", accessor: "qty",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {ConvertDecimalPlaces(props.original.qty)}
                </div>
        },
        { Header: "User", accessor: "user.first" },
        { Header: "CreatedAt", accessor: "createdAt", Cell: prop => <>{DateNumberFormat(prop.value)}</> },
        {
            Header: '',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            fixed: "right",
            getProps: getActionProps,
            Cell: ActionsCell
        }
    ]

    const handleOrderSubmit = (value, rowInfo, state, props) => {
        const { edit, deleting } = state
        console.log('value', value, rowInfo);
        const data = { ...value, item_ref: rowInfo.original._id, stock_ref: rowInfo.original.stock_id }
        if (edit !== value && deleting === null) props.updateExpDateStock(data, props)
        else if (edit === null && deleting !== null) props.deleteExpDateStock(data, props)
    }

    return <div className='card card-body'>
        <FormProvider
            form="inline"
            onSubmit={value => handleOrderSubmit(value, rowInfo, state, props)}
            onSubmitSuccess={() => setState({ edit: null, deleting: null })}
            initialValues={state.edit === null ? state.deleting : state.edit}
            enableReinitialize
        >
            {formProps => {
                return (
                    <form onSubmit={formProps.handleSubmit}>
                        <Table
                            columns={columns}
                            data={props.stockExpDate.exp_items_stocks}
                            defaultPageSize={10} />
                    </form>)
            }
            }
        </FormProvider>
    </div>;
};

export { StockSearchEle, StockExpView };
