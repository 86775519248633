/**
 * @type {Object}
 * @property {array}  data  Item data
 */
import _ from 'lodash';

const initialState = {
    products: [],
    itemsData: [],
    activeItemsData: [],
    inActiveItemsData: [],
    newSuppliers: [],
    newItems: [],
    promotionsAndDiscounts: [],
    allItems: [],
    restockedItems: [],
    itemReportHeaderText: "",
    itemsWithHierarchy: [],
    itemsForHierarchy: [],
    itemListMsg: '',
    itemsForSelect: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerItem = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_PRODUCTS':
            // var { data } = action;
            newState.products = data;
            break;

        case 'FETCH_ITEMS_DATA':
            // var { data } = action;
            newState.itemsData = data;
            newState.activeItemsData = _.filter(data, function (o) { return o.items.status.toLowerCase() === 'active' })
            newState.inActiveItemsData = _.filter(data, function (o) { return o.items.status.toLowerCase() === 'inactive' })

            let itemsDataForSelect = [];

            data.map(element => {
                let obj = {
                    '_id': element.items._id,
                    'label': element.items.name + '- ' + element.items.itemCode,
                    'brand': element.items.brand,
                    'supplier': element.items.supplier_ref
                }

                itemsDataForSelect.push(obj)
            })

            newState.itemsForSelect = itemsDataForSelect
            break;

        case 'FETCH_NEW_SUPPLIER_DATA':
            // var { data } = action;
            newState.newSuppliers = data;
            break;

        case 'FETCH_NEW_ITEM_DATA':
            // var { data } = action;
            newState.newItems = data;
            break;

        case 'FETCH_RESTOCKED_ITEM_DATA':
            // var { data } = action;
            newState.restockedItems = data;
            break;

        case 'FETCH_PROMO_DISCOUNT_ITEM_DATA':
            // var { data } = action;
            newState.promotionsAndDiscounts = data;
            break;

        case 'FETCH_ALL_ITEM_DATA':
            // var { data } = action;
            newState.allItems = data;
            break;
        case 'FETCH_ITEM_REPORT_HEADER_TEXT':
            // var { data } = action;
            newState.itemReportHeaderText = data;
            break;

        case 'FETCH_ITEMS_FOR_HIERARCHY':
            // var { data } = action;
            var newData = [];

            data.forEach(element => {
                if (element.items.status === 'Active') {
                    var item = {
                        value: element.items._id,
                        label: element.items.desc ?
                            element.items.desc : element.brand ?
                                element.items.name + '-' + element.brand.name + '-' + element.itemCode :
                                element.items.name + '-' + element.itemCode
                    };

                    newData.push(item)
                }
            });

            newState.itemsForHierarchy = newData;
            break;

        case 'FETCH_ITEMS_WITH_HIERARCHY':
            // var { data } = action;
            var newDataArr = [];

            data.forEach(element => {
                if (element.items[0] !== undefined) {
                    if (element.items[0].status === 'Active') {
                        newDataArr.push(element)
                    }
                }
            });

            newState.itemsWithHierarchy = newDataArr;
            break;

        case 'FETCH_ITEMS_LIST_MSG':
            // var { data } = action;
            newState.itemListMsg = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerItem;
