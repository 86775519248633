import _ from 'lodash';
/**
 * @type {Object}
 * @property {array}  data  Supplier evolution data
 */
const initialState = {
    data: [],
    evolutionSuppliers: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const supplierEvolutionReducer = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_SUPPLIER_EVOLUTION':
            var { data } = action;
            var suppliers = data.map(obj => {
                // console.log(obj.supplier)
                return obj.supplier;
            })
            newState.data = data;
            newState.evolutionSuppliers = _.uniqWith(suppliers, _.isEqual)
            break;

        default:
            break;
    }
    return newState;
}

export default supplierEvolutionReducer;