/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const productsDataAsync = data => {
    return { type: 'FETCH_PRODUCTS_DATA', data }
}

/**
 * 
 * @param {*} token 
 */
export const getProductsData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/product/getProductsData')
            .then(result => {
                dispatch(productsDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateProduct = (data, token) => {
    return dispatch => {
        var images = '';
        var _id = data._id;
        if (data.images !== undefined) {
            if (!data.images.path) {
                images = data.images;
                delete data.images;
            }
        }

        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/product/updateProduct', { data })
            .then(result => {

                //upload image
                if (images !== '') {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const data = new FormData();

                    data.append("productImage", images, images.name);

                    axios.post('/basic_data/product/uploadImage', data, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const images = {
                                'url': uploadedFile.location,
                                'size': uploadedFile.size,
                                'path': 'products',
                                'filename': uploadedFile.key,
                                'mimetype': uploadedFile.mimetype
                            }

                            const data = {
                                _id: _id,
                                images: images
                            }

                            dispatch(updateImage(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                } else {
                    toast.success('Successfully Updated Product')
                    dispatch(getProductsData(token))
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateImage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/product/updateImage', { data })
            .then(result => {
                toast.success('Successfully Updated Product')
                dispatch(getProductsData(token))
            }).catch(err => {
                console.log(`${err}`)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteProduct = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/product/deleteProduct', { data })
            .then(result => {
                toast.success('Product Deleted Successfully!')
                dispatch(getProductsData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addProduct = ({ name, desc, category, upperPrice, lowerPrice, inStock, milkType, images, status, createdAt, updatedAt, slug }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/product/addProduct', {
            data: {
                name, desc, category, upperPrice, lowerPrice, inStock, milkType, status, createdAt, updatedAt, slug
            }
        })
            .then(result => {

                var _id = result.data.insertedId;

                //upload image
                if (images === '') {
                    var slug = _id;

                    const data = {
                        _id: _id,
                        slug: slug
                    }

                    dispatch(updateProductSlugWithImage(data, token))
                } else {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    const data = new FormData();

                    data.append("productImage", images, images.name);

                    axios.post('/basic_data/product/uploadImage', data, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const images = {
                                'url': uploadedFile.location,
                                'size': uploadedFile.size,
                                'path': 'products',
                                'filename': uploadedFile.key,
                                'mimetype': uploadedFile.mimetype
                            }

                            var slug = _id;

                            const data = {
                                _id: _id,
                                slug: slug,
                                images: images
                            }

                            dispatch(updateProductSlugWithImage(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                }



                //upload image ends

            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateProductSlugWithImage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/product/updateProductSlug', { data })
            .then(result => {
                window.$('#addProduct').modal('hide');
                toast.success('Product Added Successfully!')
                dispatch(getProductsData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}