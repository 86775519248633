import { toast } from 'react-toastify';
import axios from 'axios';

export const measurementAsync = data => {
    return { type: 'FETCH_MESUREMENT', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * get measurements details 
 * 
 * @param {string} token 
 */
export const getMeasurementData = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        dispatch(measurementAsync([]))
        axios.get('/basic_data/getMesurement', { headers: { authorization: token } })
            .then(result => {
                dispatch(measurementAsync(result.data))
                dispatch(isLoadingAsync(false))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * add measurements details 
 * 
 * @param {string} token 
 */
export const addMeasurementData = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/basic_data/measurement/addMesurement', { data })
            .then(result => {
                dispatch(getMeasurementData({ token }))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * add measurements details 
 * 
 * @param {string} token 
 */
export const updateMeasurementData = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/basic_data/measurement/updateMesurement', { data })
            .then(result => {
                dispatch(getMeasurementData({ token }))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * add measurements details 
 * 
 * @param {string} token 
 */
export const deleteMeasurementData = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/basic_data/measurement/deleteMesurement', { data })
            .then(result => {
                dispatch(getMeasurementData({ token }))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}