import React, { useState } from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';

const SalesEvolutionTable = ({ dataFromBackend, columnsFromBackend, setCurrentItem, setCurrentItemCode, setCurrentItemType }) => {

    const [sortOptions, setSortOptions] = useState([{ id: 'item', desc: true }])

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    const handleButtonClick = (itemData) => {
        let itemDataForGraph = [];
        for (let key in itemData) {
         
            if (!key.includes('-color')) {
                let obj = {
                    'weekNumber': key,
                    'sale': Number(itemData[key]),
                    'color': itemData[key + "-color"]
                }

                itemDataForGraph.push(obj)
            }
        }
        let data = itemDataForGraph?.filter(ele => ele.color !== undefined);
        setCurrentItemCode(itemData.item)
        setCurrentItem(data);
        setCurrentItemType(itemData.type)
    };

    const buttonColumn = [

        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ original }) => (
                <button
                    data-toggle="modal"
                    className="btn btn-link"
                    data-target="#graph"
                    onClick={() => handleButtonClick(original)}>
                    Graph
                </button>
            ),
        },
    ]

    const finalColumns = [...columnsFromBackend, ...buttonColumn]

    const columns = React.useMemo(
        () => finalColumns,
        []
    );

    return (
        <div>
            <ReactTable
                sorted={sortOptions}
                onSortedChange={val => {
                    setSortOptions(val)
                }}
                data={dataFromBackend}
                columns={columns}
                defaultPageSize={10}
                defaultFilterMethod={filterCaseInsensitive}
            />
        </div>
    );
};

export default SalesEvolutionTable;
