import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash';
import Table from "react-table";
import { toast } from "react-toastify";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import axios from 'axios';
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import { getMessagePoolUsers, deleteMessagePool,getCompleteMessagePoolUsers } from '../../../actions/MIS/message_pool_actions'
import { DateNumberFormat } from "../../utils";
import GridFilters from "../../components/GridFilter";

const ReactTableFixedColumns = withFixedColumns(Table);

export const MessagePool = props => {
    const [expanded, setExpanded] = useState({});
    const [expandDate, setExpandDate] = useState(null);
    const [expandNumber, setExpandNumber] = useState(null);
    const [messageData, setMessageData] = useState([]);
    const [users, setUsers] = useState([]);
    const [completeUsers, setCompleteUsers] = useState([]);
    const [messageActive, setMessageActive] = useState(false);

    const toDate = () => {
        let toDate = new Date().toISOString().split('T')
        let splitTime = toDate[1].split('.')
        let timeString = `23:59:59.${splitTime[1]}`
        let newToDate = `${toDate[0]}T${timeString}`
        return newToDate;
    }

    const fromDate = () => {
        let today = new Date()
        let pastMonth = today.getMonth() - 1
        today.setMonth(pastMonth)
        return today.toISOString().split('T')[0];
    }
    
    const [dateRange, setDateRange] = useState({ to: toDate(), from: fromDate() })

    useEffect(() => {
        props.getMessagePoolUsers(props.token)
    }, [])

  
    useEffect(() => {
        var sortData = _.orderBy(props.messagePoolUsers, ['isCompleted', '_id.date'], ['desc', 'desc']);
        setUsers(sortData)
    }, [props.messagePoolUsers])


    useEffect(() => {
        var sortData = _.orderBy(props.completeMessagePoolUsers, ['isCompleted', '_id.date'], ['desc', 'desc']);
        setCompleteUsers(sortData)
    }, [props.completeMessagePoolUsers])


    const getMessages = () => {
        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/mis/messagepool/getMessagesByOrderAndDate', { date: expandDate, telephone: expandNumber })
            .then(result => {
                var arr = _.uniqBy(result.data, function (o) {
                    return [o.orders._id, o.messagepool._id].join();
                })

                if(arr == ''){
                    props.getMessagePoolUsers(props.token);
                }
                setMessageData(arr)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }

    const whatsappMsg = (msg) => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            console.log('mobile');
            window.open(`whatsapp://${msg}`)
        } else {
            console.log('not mobile');
            window.open(`https://web.whatsapp.com:/${msg}`)
        }
    }

    const sendMessage = (data, telephone, msg_pool, msg_pool_1, msg_pool_2, name) => {
        let whatsappMsg_1 = msg_pool
        let whatsappMsg_2 = msg_pool_1
        let whatsappMsg_3 = msg_pool_2
        let msg, orderItemsStr
        const MOBILE_NO = telephone
        const NAME = name

        var arr1 = _.filter(data, function (o) {
            return o.messagepool.reason === 'Not available'
        })

        var arr2 = data.filter(function (e1) {
            return !arr1.includes(e1)
        })

        if (arr1.length !== 0) {
            orderItemsStr = arr1.map((item, i) => {
                return `${i + 1}. ${item.items.name}`
            }).toString().replaceAll(',', '%0a')

            if (arr2.length !== 0) {
                let orderItemsStr1 = arr2.map((item, i) => {
                    return `${i + 1}. ${item.items.name}  -  ${(item.messagepool.message).substring(19)}`
                }).toString().replaceAll(',', '%0a')

                msg = `send?phone=${MOBILE_NO}&text=Dear ${NAME[1]},${whatsappMsg_1}${whatsappMsg_2}${whatsappMsg_3}`.replace('{messageArr}', orderItemsStr).replace('{messageArr1}', orderItemsStr1)
                whatsappMsg(msg)

            } else {
                msg = `send?phone=${MOBILE_NO}&text=Dear ${NAME[1]},${whatsappMsg_1}${whatsappMsg_3}`.replace('{messageArr}', orderItemsStr)
                whatsappMsg(msg)
            }
        } else {
            orderItemsStr = arr2.map((item, i) => {
                return `${i + 1}. ${item.items.name}  -  ${(item.messagepool.message).substring(19)}`
            }).toString().replaceAll(',', '%0a')

            msg = `send?phone=${MOBILE_NO}&text=Dear ${NAME[1]},%0a%0a${whatsappMsg_2}${whatsappMsg_3}`.replace('{messageArr1}', orderItemsStr)
            whatsappMsg(msg)
        }
    }

    const setStatusData = () =>{
        setDateRange({ to: toDate(), from: fromDate() })
        props.getCompleteMessagePoolUsers({ ...props, dateRange })
    }

    useEffect(() => {
        getMessages()
    }, [expandDate, expandNumber])

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const reload = () => {
        props.getMessagePoolUsers(props.token);
        props.getCompleteMessagePoolUsers({ ...props, dateRange })
        getMessages()
    }

    const columns = [
        { Header: "Name", id: "name", accessor: data => (data.users.name).replaceAll(/[0-9]/g, '').split('-'), ...GridFilters },
        { Header: "Telephone", accessor: "users.telephone", ...GridFilters },
        {
            Header: "Date", id: "date", accessor: data => DateNumberFormat(data._id.date),
            ...GridFilters
            // ...selectableDateColumnProps
        },
        { Header: "Whatsapp", accessor: 'custTel.whatsapp' },
        { Header: "Address", accessor: "users.address" },
        { Header: "Region", accessor: "regions.name" },
        {
            Header: "Completed",
            id: "completed",
            accessor: data => data.isCompleted === false ? "Yes" : "No",
            filterable: true,
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else if (filter.value === "Yes") {
                    return row[filter.id] === "Yes";
                } else if (filter.value === "No") {
                    return row[filter.id] === "No";
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>,
            getProps: (state, row, column) => {
                return {
                    style: {
                        backgroundColor: (row && row.original.isCompleted == true) ?
                            'yellow'
                            : '',
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Send",
            Cell: row => {
                return (
                    <>
                        <button className='btn btn-link' id={`${row.original.users.telephone}-${(row.original._id.date).replaceAll('-', '')}`}
                            onClick={() => {
                                axios.defaults.headers.common['authorization'] = props.token;
                                axios.post('/mis/messagepool/getMessagesByOrderAndDate', { date: row.original._id.date, telephone: row.original.users.telephone, isCompleted: true })
                                    .then(result => {
                                        var arr = _.uniqBy(result.data, function (o) {
                                            return [o.orders._id, o.messagepool._id].join();
                                        })
                                        sendMessage(arr, (row.original.custTel.whatsapp === '' || row.original.custTel.whatsapp == '+94') ? row.original.custTel.telephone : row.original.custTel.whatsapp, row.original.message_pool, row.original.message_pool_1, row.original.message_pool_2, (row.original.users.name).slice(1).split('-'))
                                        reload();
                                    }).catch(err => {
                                        console.error(err)
                                        toast.error(`${err}`)
                                    })
                            }}
                        >Send Message</button>
                    </>
                )
            }
        }
    ];

    const subColumns = [
        {
            Header: "Type",
            accessor: "messagepool.type",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Order Id",
            accessor: "orders.orderId",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Item",
            accessor: "items.name",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Qty",
            accessor: "messagepool.qty",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "right"
                    },
                };
            },
        },
        {
            Header: "Message",
            accessor: "messagepool.message",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Reason",
            accessor: "messagepool.reason",
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
        },
        {
            Header: "Action",
            maxWidth: 100,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        textAlign: "center"
                    },
                };
            },
            Cell: row =>
                <>
                    <button className='btn btn-link'
                        onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) props.deleteMessagePool({ _id: row?.original?.messagepool?._id }, props); getMessages() }}
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </>
        },
    ]

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                <ErrorBoundary>
                   <div style={{ float: 'left' }} >
                    <input type='radio' name='messagepool' style={{ marginRight: '5px' }} checked={messageActive ? false : true} onChange={e => { console.log(e.target.value); setMessageActive(false);  setDateRange({ to: toDate(), from: fromDate() });}} /><label>Active</label>
                    <input type='radio' name='messagepool' style={{ marginLeft: '30px', marginRight: '5px' }} onChange={e => { setMessageActive(true); setStatusData(); }}/><label>Complete</label>
                 </div>
                    <div style={{ float: 'right' }}>   
                        { messageActive === true ?  
                         <div style={{ display: 'flex' ,marginLeft: '200px', marginTop : '-10px' }}>

                            <div style={{ margin: '10px'}} className="h6">From :</div>
                            <input style={{ margin: '10px'}} className="form-control" type="date" value={dateRange.from.split('T')[0]}
                            onChange={e => {
                                let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                let splitTime = toDate[1].split('.')
                                let timeString = `00:00:00.${splitTime[1]}`
                                let newToDate = `${toDate[0]}T${timeString}`
                                setDateRange({ ...dateRange, from: newToDate })
                            }} />
                           
                            <div style={{ margin: '10px'}} className="h6">To :</div>
                            <input style={{ margin: '10px'}} className="form-control" type="date" value={dateRange.to.split('T')[0]}
                            onChange={e => {
                                let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                let splitTime = toDate[1].split('.')
                                let timeString = `23:59:59.${splitTime[1]}`
                                let newToDate = `${toDate[0]}T${timeString}`
                                setDateRange({ ...dateRange, to: newToDate })
                            }} />
                           
                            <button style={{ margin: '10px'}} className='btn btn-primary' onClick={() => props.getCompleteMessagePoolUsers({ ...props, dateRange })}>Search</button>
                       
                            <button style={{ margin: '10px'}} className="btn btn-primary"
                                    disabled={props.isLoading}
                                    onClick={() => reload()}
                                >
                                    <i className="fas fa-sync"></i>
                            </button>
                        </div>
                        
                    : <div style={{ float: 'right' }}>
                    <button className="btn btn-primary"
                        disabled={props.isLoading}
                        onClick={() => reload()}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                </div>
               }
                   </div>
                 </ErrorBoundary>
                </div>
                <div>
                {messageActive ?
                    <FormProvider form="inline">
                        {formProps => {
                            return (
                                <Table className="-striped"
                                    columns={columns}
                                    data={completeUsers}
                                    defaultPageSize={10}
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            style: {
                                                fontSize: '14px'
                                            }
                                        }
                                    }}
                                    expanded={expanded}
                                    onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                    SubComponent={row => {
                                        setExpandDate(row.original._id.date)
                                        setExpandNumber(row.original.users.telephone)
                                        return (
                                            <React.Fragment>
                                                <ErrorBoundary>
                                                    <FormProvider form="inline">
                                                        {formProps => {
                                                            return (
                                                                <ReactTableFixedColumns
                                                                    columns={subColumns}
                                                                    data={messageData}
                                                                    getTrProps={(state, rowInfo) => {
                                                                        return {
                                                                            style: {
                                                                                fontSize: '14px',
                                                                            }
                                                                        }
                                                                    }}
                                                                    defaultPageSize={10} />
                                                            )
                                                        }}
                                                    </FormProvider>
                                                </ErrorBoundary>
                                            </React.Fragment>
                                        );
                                    }}
                                />
                            );
                        }}
                    </FormProvider>
                    : <FormProvider form="inline">
                    {formProps => {
                        return (
                            <Table className="-striped"
                                columns={columns}
                                data={users}
                                defaultPageSize={10}
                                getTrProps={(state, rowInfo) => {
                                    return {
                                        style: {
                                            fontSize: '14px'
                                        }
                                    }
                                }}
                                expanded={expanded}
                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                SubComponent={row => {
                                    setExpandDate(row.original._id.date)
                                    setExpandNumber(row.original.users.telephone)
                                    return (
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <FormProvider form="inline">
                                                    {formProps => {
                                                        return (
                                                            <ReactTableFixedColumns
                                                                columns={subColumns}
                                                                data={messageData}
                                                                getTrProps={(state, rowInfo) => {
                                                                    return {
                                                                        style: {
                                                                            fontSize: '14px',
                                                                        }
                                                                    }
                                                                }}
                                                                defaultPageSize={10} />
                                                        )
                                                    }}
                                                </FormProvider>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                    );
                                }}
                            />
                        );
                    }}
                </FormProvider>}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        messagePoolUsers: state.rMessagePoolUsers.messagePoolUsers,
        completeMessagePoolUsers: state.rMessagePoolUsers.completeMessagePoolUsers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMessagePoolUsers: (token) => { dispatch(getMessagePoolUsers(token)) },
        deleteMessagePool: (data, props) => { dispatch(deleteMessagePool(data, props)) },
        getCompleteMessagePoolUsers: (props) => { dispatch(getCompleteMessagePoolUsers(props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagePool);


