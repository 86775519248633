/**
 * @file This file defines to ordering action dispatch
 * @author Bhanuka Chathuranga
 */
// import $ from 'jquery';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { getDeliveryWarehousesForCurrentUser } from '../Basic_data/delivery_warehouse_actions'

// import { func } from 'prop-types';

var axios = require('axios');

export const orderingAsync = data => {
    return { type: 'FETCH_ORDERING', data }
}

export const allOrderItemsAsync = data => {
    return { type: 'FETCH_ALL_ORDER_ITEMs', data }
}

export const orderingItemsAsync = data => {
    return { type: 'FETCH_ORDERING_ITEMS', data }
}

export const orderIdsAsync = data => {
    return { type: 'FETCH_ORDERING_IDS', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const fastDeliveryDiscountAsync = data => {
    return { type: 'FETCH_FAST_DELIVERY_DISCOUNT', data }
}


export const reloadData = ({ token, userId }) => {
    return dispatch => {
        dispatch(isLoadingAsync(true))
        dispatch(orderingAsync([]))
        dispatch(getOrderingData(token))
        dispatch(getDeliveryWarehousesForCurrentUser({ token: token, userId: userId }))
    }
}

export const getBacklistCustomersAsync = data => {
    return { type: 'FETCH_BACKLIST_CUSTOMER', data }
}

/**
 * get ordering details 
 * 
 * @param {string} token 
 */
export const getOrderingData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/ordering/getOrder', { headers: { authorization: token } })
            .then(result => {
                const ordersData = result.data.reverse();
                var finalData = [];

                axios.get('/ordering/getBacklistCustomer', { headers: { authorization: token } })
                    .then(result => {
                        const backlistCustomerData = result.data;

                        axios.get('/ordering/getCustomerComplaints', { headers: { authorization: token } })
                            .then(result => {
                                const customerComplaints = result.data.customerComplaints;
                                const customerSurvey = result.data.customerSurvey;
                                var obj = {};
                                ordersData.forEach(orders => {
                                    var currentOrderTelephone = orders.orders.telephone;

                                    var relatedOrders = _.filter(ordersData, function (o) {
                                        return Number(o.orders.telephone) === Number(currentOrderTelephone) && o.orders._id !== orders.orders._id && Number(currentOrderTelephone) !== 0 && currentOrderTelephone !== null;

                                    });

                                    var customerComplaintsForThisOrder = _.filter(customerComplaints, function (o) {
                                        return o.order_ref === orders.orders._id
                                    });

                                    if (customerComplaintsForThisOrder.length > 0) {
                                        orders.orders.customerComplaints = 'Done';
                                        customerComplaintsForThisOrder.forEach(customerComplaint => {
                                            if (customerComplaint.resolved === 'No') {
                                                orders.orders.customerComplaints = 'Yes';
                                            }
                                        });


                                        if (relatedOrders.length > 0) {
                                            relatedOrders.forEach(relatedOrder => {
                                                var customerComplaintsForThisRelatedOrder = _.filter(customerComplaints, function (o) {
                                                    return o.order_ref === relatedOrder.orders._id
                                                });

                                                customerComplaintsForThisRelatedOrder.forEach(relatedCustomerComplaint => {
                                                    if (relatedCustomerComplaint.resolved === 'No' && orders.orders.customerComplaints !== 'Yes') {
                                                        orders.orders.customerComplaints = 'Other';
                                                    }
                                                })
                                            });
                                        }


                                    } else {
                                        orders.orders.customerComplaints = 'No'
                                    }


                                    var customerSurveyForThisOrder = _.filter(customerSurvey, function (o) {
                                        return o.order_ref === orders.orders._id
                                    });

                                    if (customerSurveyForThisOrder.length > 0) {
                                        orders.orders.customerSurvey = 'View';
                                    } else {
                                        orders.orders.customerSurvey = 'Create';
                                    }

                                    obj = {
                                        'orders': orders.orders,
                                        'addresses': orders.addresses,
                                        'paymentmethods': orders.paymentmethods,
                                        'cities': orders.cities,
                                        'regions': orders.regions,
                                        'deliverycrew': orders.deliverycrew,
                                        'deliverywarehouse': orders.deliverywarehouse
                                    }

                                    // finally set is from mobile
                                    if (orders.orders.isFromMobile === undefined) {
                                        orders.orders.isFromMobile = 'No';
                                    }

                                    var backlistCustomerOrder = _.filter(backlistCustomerData, function (o) {
                                        const lastDigit6Str = String(o.backlistCustomer.telephone).slice(-7);
                                        const lastDigit6OrderStr = String(orders.orders.telephone).slice(-7);
                                        return Number(lastDigit6Str) === Number(lastDigit6OrderStr) && o.backlistCustomer.status === 'Active';
                                    });

                                    obj = {
                                        'orders': orders.orders,
                                        'addresses': orders.addresses,
                                        'paymentmethods': orders.paymentmethods,
                                        'cities': orders.cities,
                                        'regions': orders.regions,
                                        'deliverycrew': orders.deliverycrew,
                                        'backlistCustomer': backlistCustomerOrder.length > 0 ? 'Yes' : 'No',
                                        'deliverywarehouse': orders.deliverywarehouse
                                    };

                                    finalData.push(obj);
                                });

                                dispatch(orderingAsync(finalData));
                                dispatch(isLoadingAsync(false))
                            })
                    }).catch(err => {
                        console.error(err)
                        toast.error(`${err}`)
                    })
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get ordering items details 
 * 
 * @param {Array} items itmes id
 * @param {string} token 
 */
export const getOrderItemsData = (items, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/getOrderItems', { items })
            .then(result => {
                console.table(result.data)
                dispatch(orderingItemsAsync(result.data))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

/**
 * get order ids 
 * 
 * @param {string} token 
 */
export const getOrderIds = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/ordering/getOrderIds')
            .then(result => {
                console.table(result.data)
                dispatch(orderIdsAsync(result.data.orders))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

/**
 * To add order 
 * 
 * @param {Array} order order
 * @param {string} token 
 */
export const addOrder = (order, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        console.log('order', order);
        axios.post('/ordering/addOrder', { order })
            .then(result => {
                console.info(result.data)
                toast.success(result.data)
                dispatch(getOrderingData(token))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

/**
 * To update order
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const updateOrder = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/updateOrder', { data })
            .then(result => {
                console.info(result.data)
                toast.success(result.data)
                dispatch(getOrderingData(token))
            }).catch(err => {
                console.log(err.response);
                if (err.response.data !== null) {
                    toast.error(`Error : ${err.response.data}`)
                } else {
                    console.error(err)
                    toast.error(`${err}`)
                }
            })
    }
}

export const updateCustomerAmount = (amount, { setAmount, setViewHandler }, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/updateCustomerAmount', { data: amount })
            .then(result => {
                toast.success('Amount Updated!');
                setAmount({ account: null, paymentMethod: '', value: 0, creditNotes: [] })
                setViewHandler({ view: false })
                dispatch(getOrderingData(token));
                console.log(result);
            }).catch(err => {
                toast.error(`${err}`)
                console.error(err);
            })

    }
}

/**
 * To update order status
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const updateOrderStatus = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/updateOrderStatus', { data })
            .then(result => {
                console.info(result.data)
                toast.success(result.data)
                dispatch(getOrderingData(token))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To delete order
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const deleteOrder = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/deleteOrder', { data })
            .then(result => {
                console.info(result.data)
                toast.success(result.data)
                dispatch(getOrderingData(token))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To add order items
 * 
 * @param {Object} orderItem order id and item ids
 * @param {string} token 
 */
export const addOrderItems = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/addOrderItems', { data })
            .then(result => {
                console.info(result.data)
                toast.success(result.data)
                dispatch(getOrderingData(token))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To update order item
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const updateOrderItem = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/updateOrderItem', { data })
            .then(result => {
                console.info(result.data)
                toast.success(result.data)
                dispatch(getOrderingData(token))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

/**
 * To update delivery charge
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const updateDeliveryCharge = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/updateDeliveryCharge', { data })
            .then(result => {
                console.info(result.data)
                toast.success(result.data)
                dispatch(getOrderingData(token))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

/**
 * To remove order item
 * 
 * @param {Object} orderItem order id and item id
 * @param {string} token 
 */
export const deleteOrderItem = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/deleteOrderItem', { data })
            .then(result => {
                console.info(result.data)
                toast.success(result.data)
                dispatch(getOrderingData(token))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

/**
 * To update order item
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const addCustomerComplaint = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        const imagePath = data.imagePath;
        delete data.imagePath;
        axios.post('/ordering/addCustomerComplaint', { data })
            .then(result => {

                var _id = result.data.insertedId;

                if (imagePath && imagePath.length > 0) {

                    imagePath.forEach(imagePath => {
                        const config = {
                            headers: {
                                "authorization": token,
                                "content-type": "multipart/form-data",
                            },
                        };

                        const data = new FormData();

                        data.append("customerComplaintImage", imagePath, imagePath.name);

                        axios.post('/ordering/uploadCustomerComplaintImage', data, config)
                            .then(result => {
                                const uploadedFile = result.data.uploadedFile;

                                const image = [
                                    {
                                        'url': '/images/customerComplaints/' + uploadedFile.filename,
                                        'size': uploadedFile.size,
                                        'path': 'customerComplaints',
                                        'filename': uploadedFile.filename,
                                        'mimetype': uploadedFile.mimetype
                                    }
                                ];

                                const data = {
                                    _id: _id,
                                    imagePath: image
                                }

                                dispatch(updateCustomerComplaintWithImages(data, token));

                            }).catch(err => {
                                console.log(err);
                            })

                    });

                    toast.success('Customer Complaint Added!');
                    dispatch(getOrderingData(token));

                } else {
                    toast.success('Customer Complaint Added!');
                    dispatch(getOrderingData(token));
                    if (data.amountPaid !== 0 && data.totalPrice !== 0 && data.amountPaid >= data.totalPrice && data.method === "Put Back") {
                        if (data.resolved === "Yes") {
                            setTimeout(() => {
                                toast.info('Credit Note Added Successfully!');
                            }, 1000);
                        }
                    }
                }

            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}


/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateCustomerComplaintWithImages = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/updateCustomerComplaintWithImages', { data })
            .then(result => {
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateCustomerComplaint = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/updateCustomerComplaint', { data })
            .then(result => {
                toast.success('Customer Complaint Updated!');
                dispatch(getOrderingData(token));
                if (data.amountPaid !== 0 && data.totalPrice !== 0 && data.amountPaid >= data.totalPrice && data.method === "Put Back") {
                    if (data.resolved === "Yes") {
                        setTimeout(() => {
                            toast.info('Credit Note Added Successfully!');
                        }, 1000);
                    }
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteCustomerComplaint = (data, token) => {
    return dispatch => {
        // console.log(data);
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/deleteCustomerComplaint', { data })
            .then(result => {
                toast.success('Customer Complaint Deleted!');
                dispatch(getOrderingData(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}


/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const getOrderItemFromId = (obj, setData, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    axios.get('/ordering/getOrderItemFromId', { params: { id: obj._id, warehouse: obj.outletId } })
        .then(result => {
            result.data.result.sort(function (a, b) {
                var shelfLifeA = a.shelfLife
                var shelfLifeB = b.shelfLife

                if (shelfLifeA === 'Frozen') {
                    shelfLifeA = 3;
                } else if (shelfLifeA === 'Chilling') {
                    shelfLifeA = 2;
                } else {
                    shelfLifeA = 1;
                }

                if (shelfLifeB === 'Frozen') {
                    shelfLifeB = 3;
                } else if (shelfLifeB === 'Chilling') {
                    shelfLifeB = 2;
                } else {
                    shelfLifeB = 1;
                }

                if (shelfLifeA < shelfLifeB) {
                    return -1;
                }
                if (shelfLifeA > shelfLifeB) {
                    return 1;
                }

                return 0;
            });

            console.log(result);
            setData(result.data.result)
        }).catch(err => {
            toast.error(`${err}`)
        })
}

/**
 * get order ids 
 * 
 * @param {string} token 
 */
export const getBacklistCustomer = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/ordering/getBacklistCustomer')
            .then(result => {
                dispatch(getBacklistCustomersAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}
/**
 * To update delivery charge
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const addBacklistCustomer = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/addBacklistCustomer', { data })
            .then(result => {
                toast.success(result.data)
                dispatch(getBacklistCustomer({ token }))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To update delivery charge
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const updateBacklistCustomer = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/updateBacklistCustomer', { data })
            .then(result => {
                toast.success(result.data)
                dispatch(getBacklistCustomer({ token }))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}


export const getAllOrderItems = (token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/ordering/getAllOrderItems')
            .then(result => {
                dispatch(allOrderItemsAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}


/**
 * get fast delivery discount items 
 * 
 * @param {string} token 
 */
export const getFastDeliveryDiscount = (token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/ordering/getFastDeliveryDiscount')
            .then(result => {
                dispatch(fastDeliveryDiscountAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * add fast delivery discount items wise
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const addFastDeliveryDiscount = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/addFastDeliveryDiscount', { data })
            .then(result => {
                toast.success('Item Added Successfully!')
                dispatch(getFastDeliveryDiscount(token))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteFastDeliveryDiscount = (data, token) => {
    return dispatch => {
        // console.log(data);
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/deleteFastDeliveryDiscount', { data })
            .then(result => {
                toast.success('Fast Delivery Discount Item Deleted!');
                dispatch(getFastDeliveryDiscount(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * To update delivery charge
 * 
 * @param {Object} data order data
 * @param {string} token 
 */
export const updateFastDeliveryDiscount = (data, token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/ordering/updateFastDeliveryDiscount', { data })
            .then(result => {
                toast.success('Item Updated Successfully!')
                dispatch(getFastDeliveryDiscount(token))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}
