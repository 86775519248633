/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const accountsDataAsync = data => {
    return { type: 'FETCH_ACCOUNTS_DATA', data }
}

export const transactionsDataAsync = data => {
    return { type: 'FETCH_TRANSACTIONS_DATA', data }
}

export const payableDataAsync = data => {
    return { type: 'FETCH_PAYABLE_DATA', data }
}
/**
 * @param {*} token 
 */
export const getAccountsData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/accounts/getAccountsData')
            .then(result => {
                dispatch(accountsDataAsync(result.data));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addAccount = ({ acc_name, type, balance, createdAt, selectedCurrency }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/acc/accounts/addAccount', {
            data: {
                acc_name, type, balance, createdAt, selectedCurrency
            }
        })
            .then(result => {
                dispatch(getAccountsData(token));
                window.$('#addAccount').modal('hide');
                toast.success('Account Added Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateAccount = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/accounts/updateAccount', { data })
            .then(result => {
                toast.success('Successfully Updated Account!')
                dispatch(getAccountsData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteAccount = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/accounts/deleteAccount', { data })
            .then(result => {
                dispatch(getAccountsData(token));
                toast.success('Account Deleted Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}


/**
 * @param {*} token 
 */
export const getTransactions = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/accounts/getTransactions')
            .then(result => {
                dispatch(transactionsDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateTransaction = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/accounts/updateTransaction', { data })
            .then(result => {
                toast.success('Successfully Updated Transaction!');
                dispatch(getTransactions(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}


/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteTransaction = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/accounts/deleteTransaction', { data })
            .then(result => {
                dispatch(getTransactions(token));
                toast.success('Transaction Deleted Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addTransaction = ({ accountTo, currentAccount, typeFrom, typeTo, balance, value, reference, user, effectiveDate, accountTypeTo, accountTypeFrom, selection }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/acc/accounts/addTransaction', {
            data: {
                accountTo, currentAccount, typeFrom, typeTo, balance, value, reference, user, effectiveDate, accountTypeTo, accountTypeFrom, selection
            }
        })
            .then(result => {
                dispatch(getTransactions(token));
                dispatch(getAccountsData(token));
                dispatch(getCustomerPayable(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * @param {*} token 
 */
 export const getCustomerPayable = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/accounts/getCustomerPayable')
            .then(result => {
                dispatch(payableDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}