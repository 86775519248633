import axios from 'axios';
import { toast } from 'react-toastify';

import { isLoadingAsync } from '../login_action';
import { getBrandData } from "./basic_data_action";

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const addBrand = (data, { token }) => {
    return dispatch => {
        dispatch(isLoadingAsync(true))
        const { image } = data
        // delete data['image']
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/brand/addBrand', { data })
            .then(async ({ data: { insertedId } }) => {
                // dispatch(uploadBrandImage(data, { token }))
                // window.$('#addBrand').modal('hide');
                // toast.success('Successfully Updated Brand...!')
                // dispatch(getBrandData({ token }))
                // console.log(result);
                // console.log(data?.image?.name);
                if (data?.image !== '') {
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    var newData = new FormData();

                    newData.append("brandImage", image, image.name);

                    await axios.post('/basic_data/brand/uploadImage', newData, config)
                        .then(result => {
                            const uploadedFile = result.data.uploadedFile;

                            const images = {
                                'url': '/images/brand/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'brand',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }

                            // var slug = _id;

                            const data = {
                                _id: insertedId,
                                image: images
                            }

                            //console.log('images brand', result);
                            dispatch(updateBrandWithImage(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                } else {
                    window.$('#addBrand').modal('hide');
                    toast.success('Successfully Added Brand...!')
                    dispatch(getBrandData({ token }))
                }
            }).catch(err => {
                window.$('#addBrand').modal('hide');
                toast.error(`${err}`)
                console.error(err);
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const uploadBrandImage = ({ image }, { token }) => {
    return dispatch => {
        const config = {
            headers: {
                "authorization": token,
                "content-type": "multipart/form-data",
            },
        };

        const data = new FormData();

        data.append("brandImage", image, image.name);

        axios.post('/basic_data/brand/uploadImage', data, config)
            .then(({ data: { insertedId, uploadedFile } }) => {
                // const uploadedFile = uploadedFile;

                const images = {
                    'url': '/images/brand/' + uploadedFile.filename,
                    'size': uploadedFile.size,
                    'path': 'brand',
                    'filename': uploadedFile.filename,
                    'mimetype': uploadedFile.mimetype
                }

                // var slug = _id;

                const data = {
                    _id: insertedId,
                    image: images
                }
                //og('images brand upload', data);
                dispatch(updateBrandWithImage(data, token))

            }).catch(err => {
                console.log(err);
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateBrandWithImage = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/brand/updateBrandImage', { data })
            .then(result => {
                window.$('#addBrand').modal('hide');
                toast.success('Brand Added Successfully!')
                dispatch(getBrandData({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateBrand = (isImageUpdate, data, { token }) => {
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/brand/updateBrand', { data })
            .then(result => {
                if (!isImageUpdate) {
                    toast.success('Successfully Updated Brand...!')
                    dispatch(getBrandData({ token }))
                } else {
                    const { _id, image } = data
                    // var valImage = { _id: data._id, image }
                    // dispatch(uploadBrandImage(valImage, { token }))
                    const config = {
                        headers: {
                            "authorization": token,
                            "content-type": "multipart/form-data",
                        },
                    };

                    var newData = new FormData();

                    newData.append("brandImage", image, image.name);

                    axios.post('/basic_data/brand/uploadImage', newData, config)
                        .then(({ data: { uploadedFile } }) => {
                            // const uploadedFile = result.data.uploadedFile;

                            const images = {
                                'url': '/images/brand/' + uploadedFile.filename,
                                'size': uploadedFile.size,
                                'path': 'brand',
                                'filename': uploadedFile.filename,
                                'mimetype': uploadedFile.mimetype
                            }

                            // var slug = _id;

                            const data = {
                                _id,
                                image: images
                            }

                            // console.log(result);
                            dispatch(updateBrandWithImage(data, token))

                        }).catch(err => {
                            console.log(err);
                        })
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteBrand = (data, { token }) => {
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/brand/deleteBrand', { data })
            .then(result => {
                toast.success('Successfully Updated Brand...!')
                dispatch(getBrandData({ token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}