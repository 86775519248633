/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const banksDataAsync = data => {
    return { type: 'FETCH_BANKS_DATA', data }
}

export const branchesDataAsync = data => {
    return { type: 'FETCH_BRANCHES_DATA', data }
}

/**
 * @param {*} token 
 */
export const getBanksData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/bank/getBanksData')
            .then(result => {
                console.log(result.data)
                dispatch(banksDataAsync(result.data));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * @param {*} token 
 */
export const getBranchesData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/bank/getBranchesData')
            .then(result => {
                dispatch(branchesDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addBank = ({ name, code, headofficetel, headofficecity, createdAt }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/acc/bank/addBank', {
            data: {
                name, code, headofficetel, headofficecity, createdAt
            }
        })
            .then(result => {
                dispatch(getBanksData(token));
                window.$('#addBank').modal('hide');
                toast.success('Bank Added Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const addBranch = ({ code, swiftcode, name, city, telnum, bankref, createdAt }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/acc/bank/addBranch', {
            data: {
                code, swiftcode, name, city, telnum, bankref, createdAt
            }
        })
            .then(result => {
                dispatch(getBranchesData(token));
                window.$('#addBranch').modal('hide');
                toast.success('Branch Added Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
 export const updateBank = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/bank/updateBank', { data })
            .then(result => {
                toast.success('Successfully Updated Bank!')
                dispatch(getBanksData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
 export const deleteBank = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/bank/deleteBank', { data })
            .then(result => {
                dispatch(getBanksData(token));
                toast.success('Bank Deleted Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
 export const updateBranch = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/bank/updateBranch', { data })
            .then(result => {
                toast.success('Successfully Updated Branch!')
                dispatch(getBranchesData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
 export const deleteBranch = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/bank/deleteBranch', { data })
            .then(result => {
                dispatch(getBranchesData(token));
                toast.success('Branch Deleted Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}
