/**
 * @type {Object}
 * @property {array}  data  Delivery Warehouse data
 */
const initialState = {
    deliveryWarehouses: [],
    deliveryWarehousesCurrentUserForAccount: [],
    deliveryWarehousesForUser: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerDeliveryWarehouse = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_DELIVERY_WAREHOUSE_DATA':
            var { data } = action;
            newState.deliveryWarehouses = data;
            break;

        case 'FETCH_DELIVERY_WAREHOUSE_ACCOUNT_USER_DATA':
            var { data } = action;
            newState.deliveryWarehousesCurrentUserForAccount = data;
            break;

        case 'FETCH_DELIVERY_WAREHOUSE_CURRENT_USER_DATA':
            var { data } = action;
            newState.deliveryWarehousesForUser = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerDeliveryWarehouse;