const ButtonClass = {
    height: '200px',
    width: '240px',
    fontSize: '70px',
    border: '1px solid #e0e0e0e',
    background: '#fff',
    color: '#a7a5a5',
    borderRadius: '20px'
};

const PaymentMethod = {
    border: '1px solid #e0e0e0',
    width: '100%',
    textAlign: 'left'
};

const ParentDiv = {
    width: '100%',
    height: '300%',
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden'
};

const SearchStyle = {
    height: '70px',
    textAlign: 'center',
    width: '50%',
    border: '0.5 px solid #e0e0e0',
    borderTopRightRadius: '40px',
    borderTopLeftRadius: '40px',
    borderBottomLeftRadius: '40px',
    borderBottomRightRadius: '40px',
    fontSize: '20px'
}

export const styles = {
    ButtonClass: ButtonClass,
    PaymentMethod: PaymentMethod,
    ParentDiv: ParentDiv,
    SearchStyle: SearchStyle
};