/**
 * @author Umesh Ranthilina
 */
import React, { Component } from 'react'
import { connect } from 'react-redux';
import Table from "react-table";
import "react-table/react-table.css";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import GridFilters from "../../components/GridFilter";
import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary"
import { getAllStockExpireItems, addDisposeStock } from '../../../actions/POS/pos_actions';
import { getDeliveryWarehousesForCurrentUser } from "../../../actions/Basic_data/delivery_warehouse_actions";
import { getUsers } from '../../../actions/Administrator/user_actions';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { PriceFormat, ExportData } from '../../utils';
import { toast } from 'react-toastify';

export class ExpireStock extends Component {
    state = {
        selectOption: '',
        checkUserType: [],
        totalValue: 0,
        reason: '',
        quantity: 0,
        maxQuantity: 0,
        currentOutletStock: null,
        currentItem: null
    }

    componentDidMount() {
        this.props.getAllStockExpireItems({ userId: this.props.userId, warehouse: '' }, this.props)
        this.props.getDeliveryWarehousesForCurrentUser(this.props)
        this.props.getUsers(this.props.token)
    }

    componentDidUpdate(prevProps) {
        const { users } = this.props;
        if (users !== prevProps.users) {
            let userData = [];

            if (users.length > 0) {
                users.map((value) => {
                    if (String(value._id) === String(this.props.userId) && value.userType === "Admin") {
                        userData.push(value)
                    }
                })
                this.setState({
                    checkUserType: userData
                })
            }
        }
    }

    onSelect = (e, name) => {
        if (e)
            this.setState({ selectOption: (e.target.value) });
        else
            this.setState({ selectOption: null });
    };

    stockReasonStatus = [
        { 'value': 'Missing Items', 'name': 'Missing Items' },
        { 'value': 'Damage Items', 'name': 'Damage Items' },
        { 'value': 'Expired Items', 'name': 'Expired Items' },
    ];

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    addDisposeStock = () => {
        if (this.state.reason !== '' && this.state.quantity > 0) {
            if (Number(this.state.maxQuantity) >= Number(this.state.quantity)) {
                const data = {
                    'outletStockRef': this.state.currentOutletStock._id,
                    'warehouseRef': this.state.currentOutletStock.warehouse_id,
                    'itemRef': this.state.currentItem.itemRef,
                    'reason': this.state.reason,
                    'quantity': Number(this.state.quantity),
                    'purchasePrice': this.state.currentItem.purchasePrice,
                    'salesPrice': this.state.currentItem.salesPrice,
                    'expiryDate': this.state.currentOutletStock.exp_date,
                    'user': this.props.userId,
                    'completed': false,
                    'approved': false
                };

                this.props.addDisposeStock(data, this.props.token)
                this.closeDisposeStockModal();
            } else {
                toast.error('Please enter less than equal quantity');
            }
        } else {
            toast.error('Please provide reason and quantity');
        }
    }

    closeDisposeStockModal = () => {
        document.getElementById("disposeItemPopupForm").reset();
        window.$('#disposeItemPopup').modal('hide');
        this.setState({
            reason: '',
            quantity: 0,
            currentItem: null,
            currentOutletStock: null
        })
    }

    exportDataToExcel = () => {
        var excelData = this.props.stockExpireData.map(checkData => {

            return {
                'Item Name': checkData.ItemName,
                'Item Code': checkData.ItemCode,
                'Supplier': checkData.supplier,
                'Outlet': checkData.name,
                'Quantity': checkData.qty,
                'Expire Date': this.formatDate(checkData.exp_date),
                'Price': checkData.price,
                'Total': Number(checkData.qty) * Number(checkData.price)
            }
        })

        ExportData(`Outlet Stock`, 'outlet stock', "outlet stock", excelData)
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        return [year, month, day].join('-');
    }

    render() {
        const { stockExpireData } = this.props;

        const columns = [
            { Header: "Item Name", accessor: "ItemName", ...GridFilters },
            { Header: "Item Code", accessor: "ItemCode", ...GridFilters },
            { Header: "Supplier", accessor: "supplier", ...GridFilters },
            { Header: "Outlet", accessor: "name", ...GridFilters },
            { Header: "Quantity", accessor: "qty", getProps: () => { return { style: { 'text-align': 'right' } } } },
            { Header: "Expire Date", accessor: "exp_date", ...GridFilters },
            {
                Header: "Cost Price", accessor: "purchasePrice", ...GridFilters,
                Cell: props =>
                    <div style={{ textAlign: 'right' }}>
                        {PriceFormat(props.original.purchasePrice)}
                    </div>
            },
            {
                Header: `Total Cost ( ${_.sumBy(stockExpireData, function (o) { return (o.purchasePrice * o.qty) }).toLocaleString('en', { minimumFractionDigits: 2 })})`,
                accessor: "purchasePrice", ...GridFilters,
                Cell: props =>
                    <div style={{ textAlign: 'right' }}>
                        {PriceFormat(props.original.purchasePrice * props.original.qty)}
                    </div>
            },
            {
                Header: 'Dispose Stock',
                Cell: props => (
                    <div style={{ textAlign: "right" }}>
                        <button
                            data-toggle="modal"
                            className="btn btn-link"
                            data-target="#disposeItemPopup"
                            data-backdrop="static" data-keyboard="false"
                            onClick={e => {
                                e.preventDefault();
                                console.log(props.original)
                                let item = {
                                    'itemRef': props.original.Item_id,
                                    'salesPrice': props.original.price,
                                    'purchasePrice': props.original.purchasePrice
                                };
                                this.setState({
                                    maxQuantity: props.original.qty,
                                    currentOutletStock: props.original,
                                    currentItem: item
                                })
                            }}
                        >
                            Dispose
                        </button>
                    </div>
                )
            },
        ]

        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header" style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'inline-flex', paddingRight: '1%' }}>
                            <div style={{ whiteSpace: 'nowrap' }}>
                                Warehouse :
                            </div>
                            <div style={{ width: '50%' }}>
                                <select className='form-control' onChange={(e) => this.onSelect(e, "warehouse")}>
                                    <option value={''}> -All Delivery Warehouse-</option>
                                    {this.props.deliveryWarehouseData.map(ele => <option key={ele._id} value={ele._id}>{ele.name}</option>)}
                                </select>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div style={{ display: 'flex' }}>
                                <button className='btn btn-primary' onClick={() => this.props.getAllStockExpireItems({ userId: this.props.userId, warehouse: this.state.selectOption }, this.props)}>Search</button>
                            </div>
                        </div>
                        <div>
                            <button style={{ float: 'right' }} className="btn btn-success" onClick={e => this.exportDataToExcel()}>Export Data</button>
                        </div>
                    </div>
                    <div className="card-body">
                        <ErrorBoundary>
                            {this.props.isLoading
                                ? <>
                                    <SkeletonTable columnsCount={9} dataCount={10} />
                                </>
                                : <FormProvider
                                    form="inline"
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form >
                                                <Table className="-striped"
                                                    columns={columns}
                                                    data={stockExpireData}
                                                    defaultPageSize={10}
                                                    getTdProps={(state, rowInfo, column) => ({
                                                        style: {
                                                            height: '50px',
                                                        },
                                                    })}
                                                    filterable
                                                />
                                            </form>
                                        )
                                    }}
                                </FormProvider>}

                        </ErrorBoundary>
                    </div>

                    <div id="disposeItemPopup" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Dispose Item Stock</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={e => this.closeDisposeStockModal()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='disposeItemPopupForm'>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>Reason :</td>
                                                        <td>
                                                            <select className='form-control' value={this.state.reason} onChange={e => this.setState({ reason: e.target.value })}>
                                                                <option value={''}>- select Reason -</option>
                                                                {this.stockReasonStatus.map(data => <option value={data._id}>{data.name}</option>)}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Quantity :</td>
                                                        <td>
                                                            <input type="number" max={this.state.maxQuantity} className="form-control" value={this.state.quantity} onChange={e => this.setState({ quantity: e.target.value })} />
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={e => this.addDisposeStock()}>Dispose</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.closeDisposeStockModal()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.rStock.data,
        items: state.rStock.items,
        itemData: state.rStock.itemData,
        token: state.rLogin.token,
        stockHistory: state.rStock.stockHistory,
        supplierData: state.rStock.supplierData,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userId: state.rLogin.id,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehousesForUser,
        users: state.rUser.users,
        isLoading: state.rLogin.loading,
        stockExpireData: state.rPOS.stockExpireData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: (token) => { dispatch(getUsers(token)) },
        getAllStockExpireItems: (data, props) => { dispatch(getAllStockExpireItems(data, props)) },
        getDeliveryWarehousesForCurrentUser: (token, user) => { dispatch(getDeliveryWarehousesForCurrentUser(token, user)) },
        addDisposeStock: (data, token) => { dispatch(addDisposeStock(data, token)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpireStock);
