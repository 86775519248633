/**
 * @file This file defined for customer order controller
 * @author Ehsan Elahi
 */
import React from 'react';
import { Route } from 'react-router';
import TV_POS from '../container/views/POS/TV_POS';

export const PosTvController = props => {

    return (
        <>
            <Route path="/postv" exact component={TV_POS} />
        </>
    )
}

export default PosTvController
