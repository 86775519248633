/**
 * @file This file defines for sideMenu
 * @author Bhanuka Chathuranga
 */
import React, { Component } from 'react'
import { connect } from 'react-redux';
import $ from "jquery"
import debounce from 'react-debouncing';
// import { NavLink } from 'react-router-dom';
import MenuSection from './MenuSection';
import logo from '../../images/logo.png'
// import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { loginOutAsync } from '../../actions/login_action';

class Sidemenu extends Component {

  constructor(props) {
    super(props);
  }
  // Sidebar
  initSidebar = () => {
    const CURRENT_URL = window.location.href.split('#')[0].split('?')[0],
      $BODY = $('#body'),
      $MENU_TOGGLE = $('#menu_toggle'),
      $SIDEBAR_MENU = $('#sidebar-menu'),
      $SIDEBAR_FOOTER = $('.sidebar-footer'),
      $LEFT_COL = $('.left_col'),
      $RIGHT_COL = $('.right_col'),
      $NAV_MENU = $('.nav_menu'),
      $FOOTER = $('footer');
    // TODO: This is some kind of easy fix, maybe we can improve this
    // console.log("init_sidebar");
    var setContentHeight = function () {
      // reset height
      $RIGHT_COL.css('min-height', $(window).height());

      var bodyHeight = $BODY.outerHeight(),
        footerHeight = $BODY.hasClass('footer_fixed')
          ? -10
          : $FOOTER.height(),
        leftColHeight = $LEFT_COL
          .eq(1)
          .height() + $SIDEBAR_FOOTER.height(),
        contentHeight = bodyHeight < leftColHeight
          ? leftColHeight
          : bodyHeight;

      // normalize content
      contentHeight -= $NAV_MENU.height() + footerHeight;

      $RIGHT_COL.css('min-height', contentHeight);
    };

    var openUpMenu = function () {
      $SIDEBAR_MENU.find('li').removeClass('active active-sm');
      $SIDEBAR_MENU.find('li ul').slideUp();
    }


    $SIDEBAR_MENU.find('a').on('click', function (ev) {
      var $li = $(this).parent();

      if ($li.is('.active')) {
        $li.removeClass('active active-sm');
        $('ul:first', $li).slideUp(function () {
          setContentHeight();
        });
      } else {
        // prevent closing menu if we are on child menu
        if (!$li.parent().is('.child_menu')) {
          openUpMenu();
        } else {
          if ($BODY.is('nav-sm')) {
            if (!$li.parent().is('child_menu')) {
              openUpMenu();
            }
          }
        }

        $li.addClass('active');

        $('ul:first', $li).slideDown(function () {
          setContentHeight();
        });
      }
    });

    //deboucing
    $(window).bind("resize", debounce(setContentHeight, 100));
    $MENU_TOGGLE.on('click', function () {
      if ($BODY.hasClass('nav-md')) {
        $SIDEBAR_MENU.find('li.active ul').hide();
        $SIDEBAR_MENU.find('li.active').addClass('active-sm').removeClass('active');
        $('#company_logo').hide()
      } else {
        $SIDEBAR_MENU.find('li.active-sm ul').show();
        $SIDEBAR_MENU.find('li.active-sm').addClass('active').removeClass('active-sm');
        $('#company_logo').show()
      }

      $BODY.toggleClass('nav-md nav-sm');

      setContentHeight();

      $('.dataTable').each(function () { $(this).dataTable().fnDraw(); });
    });


    // check active menu
    $SIDEBAR_MENU
      .find('a[href="' + CURRENT_URL + '"]')
      .parent('li')
      .addClass('current-page');

    $SIDEBAR_MENU
      .find('a')
      .filter(function () {
        return this.href == CURRENT_URL;
      })
      .parent('li')
      .addClass('current-page')
      .parents('ul')
      .slideDown(function () {
        setContentHeight();
      })
      .parent()
      .addClass('active');

    setContentHeight();

    // fixed sidebar
    if ($.fn.mCustomScrollbar) {
      $('.menu_fixed').mCustomScrollbar({
        autoHideScrollbar: true,
        theme: 'minimal',
        mouseWheel: {
          preventDefault: true
        }
      });
    }
  };
  // /Sidebar

  componentDidMount() {
    this.initSidebar();
  }

  permission = (pro, { programme, subProgramme, userProgramme }) => {
    // return new Promise((resolve, reject) => {
    var subPro = subProgramme.filter(val => {
      if (val.name.toUpperCase() === pro.toUpperCase()) {
        return programme.filter(e => {
          if (e._id === val.programme_ref) {
            return e
          }
        })
      }
    })

    var index = userProgramme.map(obj => obj.programmeid).indexOf(subPro[0]?.programme_ref)
    if (index >= 0) {
      let isPermit = userProgramme[index]?.subprogramme?.map(obj => obj.subprogrammeid).includes(subPro[0]?._id)
      return isPermit;
    } else if (subPro.length === 0) {
      let isPermit = userProgramme.map(obj => obj.programmeid)
        .includes(programme?.filter(val => { if (val.name.toUpperCase() === pro.toUpperCase()) { return val._id } })[0]?._id)

      return isPermit;
    }

    return false
  }

  render() {
    return (
      <div className="col-md-3 left_col">
        <div className="left_col scroll-view">
          <div className="navbar nav_title" style={{
            border: 0
          }}>
            <a href="/" className="site_title">
              <img id='company_logo' alt='company logo' src={logo} />
            </a>
          </div>
          <div className="clearfix" />

          <br /> {/* sidebar menu */}
          <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
            <MenuSection mainMenu="General"
              title1="Home" titleIcon1="fa fa-home"

              title2="MIS" titleIcon2="fa fa-balance-scale"
              subTitle2_1="P and L" isPermittedSubTitle2_1={this.permission('p and l', this.props)}
              subTitle2_2="Cash Box" isPermittedSubTitle2_2={this.permission('cash box', this.props)}
              subTitle2_3="Message Pool" isPermittedSubTitle2_3={this.permission('message pool', this.props)}
              subTitle2_4="Sales Evolution" isPermittedSubTitle2_4={this.permission('sales evolution', this.props)}

              title3="Delivery" titleIcon3="fa fa-truck"
              subTitle3_1="Delivery Plan" isPermittedSubTitle3_1={this.permission('delivery plan', this.props)}
              subTitle3_2="Delivery Inspect" isPermittedSubTitle3_2={this.permission('delivery inspect', this.props)}
              subTitle3_3="Delivery Crew" isPermittedSubTitle3_3={this.permission('delivery crew', this.props)}
              subTitle3_4="Delivery Note" isPermittedSubTitle3_4={this.permission('delivery note', this.props)}
              subTitle3_5="Credit Note" isPermittedSubTitle3_5={this.permission('credit note', this.props)}
              subTitle3_6="Return Order" isPermittedSubTitle3_6={this.permission('return order', this.props)}
              subTitle3_7="Missing Order" isPermittedSubTitle3_7={this.permission('missing order', this.props)}
              subTitle3_8="Delivered Overview" isPermittedSubTitle3_8={this.permission('delivered overview', this.props)}
              subTitle3_9="Delivery Dashboard" isPermittedSubTitle3_9={this.permission('delivery dashboard', this.props)}
              subTitle3_10="Delivery Confirmation" isPermittedSubTitle3_10={this.permission('delivery confirmation', this.props)}

              title4="Orders" titleIcon4="fa fa-coffee"
              subTitle4_1="Order" isPermittedSubTitle4_1={this.permission('order', this.props)}
              subTitle4_2="Order Reconciliation" isPermittedSubTitle4_2={this.permission('order reconciliation', this.props)}
              subTitle4_3="Order History" isPermittedSubTitle4_3={this.permission('order history', this.props)}
              subTitle4_4="Complains" isPermittedSubTitle4_4={this.permission('complains', this.props)}
              subTitle4_5="Canceled Orders" isPermittedSubTitle4_5={this.permission('Canceled Orders', this.props)}
              subTitle4_6="Backlist Customer" isPermittedSubTitle4_6={this.permission('Backlist Customer', this.props)}
              subTitle4_7="Sales Promotion" isPermittedSubTitle4_7={this.permission('Sales Promotion', this.props)}
              subTitle4_8="Fast Delivery Discount" isPermittedSubTitle4_8={this.permission('fast delivery discount', this.props)}

              title5="Purchase" titleIcon5="fa fa-shopping-cart"
              subTitle5_1="Orders" isPermittedSubTitle5_1={this.permission('orders', this.props)}
              subTitle5_2="Suppliers" isPermittedSubTitle5_2={this.permission('suppliers', this.props)}
              subTitle5_3="Back Order" isPermittedSubTitle5_3={this.permission('back order', this.props)}
              subTitle5_4="Supplier Evalution" isPermittedSubTitle5_4={this.permission('supplier evolution', this.props)}
              subTitle5_5="Supplier Vs Customer" isPermittedSubTitle5_5={this.permission('supplier vs customer', this.props)}
              subTitle5_6="Refund" isPermittedSubTitle5_6={this.permission('refund', this.props)}
              subTitle5_7="History" isPermittedSubTitle5_7={this.permission('history', this.props)}
              subTitle5_8="Purchase Price" isPermittedSubTitle5_8={this.permission('purchase price', this.props)}
              subTitle5_9="Canceled Purchase" isPermittedSubTitle5_9={this.permission('canceled purchase', this.props)}
              subTitle5_10="Return To Supplier" isPermittedSubTitle5_10={this.permission('return to supplier', this.props)}
              subTitle5_11="Credit Note Purchasing" isPermittedSubTitle5_11={this.permission('credit note purchasing', this.props)}
              subTitle5_12="Transactions Report" isPermittedSubTitle5_12={this.permission('transactions report', this.props)}

              title6="Stock" titleIcon6="fas fa-warehouse" route6="/stock"
              subTitle6_1="Stock" isPermittedSubTitle6_1={this.permission('stock', this.props)}
              subTitle6_2="Stock Item" isPermittedSubTitle6_2={this.permission('stock item', this.props)}
              subTitle6_3="Stock Expire Item" isPermittedSubTitle6_3={this.permission('stock expire item', this.props)}
              subTitle6_4="Move Stock" isPermittedSubTitle6_4={this.permission('move stock', this.props)}
              subTitle6_5="Move Stock Company" isPermittedSubTitle6_5={this.permission('move stock company', this.props)}
              subTitle6_6="Stock Running Report" isPermittedSubTitle6_6={this.permission('stock running report', this.props)}
              subTitle6_7="Packaging" isPermittedSubTitle6_7={this.permission('packaging', this.props)}

              title7="Basic Data" titleIcon7="fa fa-anchor"
              subTitle7_1="Item" isPermittedSubTitle7_1={this.permission('item', this.props)}
              subTitle7_2="Brand" isPermittedSubTitle7_2={this.permission('brand', this.props)}
              subTitle7_3="Category" isPermittedSubTitle7_3={this.permission('category', this.props)}
              subTitle7_4="Delivery Type" isPermittedSubTitle7_4={this.permission('delivery type', this.props)}
              subTitle7_5="Delivery Condition" isPermittedSubTitle7_5={this.permission('delivery condition', this.props)}
              subTitle7_6="Delivery Charges" isPermittedSubTitle7_6={this.permission('delivery charges', this.props)}
              subTitle7_7="Delivery Calender" isPermittedSubTitle7_7={this.permission('working calender', this.props)}
              subTitle7_8="Payment Condition" isPermittedSubTitle7_8={this.permission('payment condition', this.props)}
              subTitle7_9="Payment Method" isPermittedSubTitle7_9={this.permission('payment method', this.props)}
              subTitle7_10="Currency" isPermittedSubTitle7_10={this.permission('currency', this.props)}
              subTitle7_11="Region" isPermittedSubTitle7_11={this.permission('region', this.props)}
              subTitle7_12="City" isPermittedSubTitle7_12={this.permission('city', this.props)}
              subTitle7_13="Measurement" isPermittedSubTitle7_13={this.permission('measurement', this.props)}
              subTitle7_14="User Discounts" isPermittedSubTitle7_14={this.permission('user discounts', this.props)}
              subTitle7_15="Seasonal Discounts" isPermittedSubTitle7_15={this.permission('seasonal discounts', this.props)}
              subTitle7_16="ShelfLife" isPermittedSubTitle7_16={this.permission('shelflife', this.props)}
              subTitle7_17="Resolve Methods" isPermittedSubTitle7_17={this.permission('resolve methods', this.props)}
              subTitle7_18="Delivery Warehouse" isPermittedSubTitle7_18={this.permission('delivery warehouse', this.props)}
              subTitle7_19="Item Tag" isPermittedSubTitle7_19={this.permission('item tag', this.props)}
              subTitle7_20="Survey Questions" isPermittedSubTitle7_20={this.permission('survey questions', this.props)}
              subTitle7_21="Companies" isPermittedSubTitle7_21={this.permission('companies', this.props)}
              subTitle7_22="Error Log" isPermittedSubTitle7_22={this.permission('error log', this.props)}

              title8="Administrator" titleIcon8="fa fa-user-secret"
              subTitle8_1="User" isPermittedSubTitle8_1={this.permission('user', this.props)}
              subTitle8_2="Addresses" isPermittedSubTitle8_2={this.permission('addresses', this.props)}
              subTitle8_3="Parameters" isPermittedSubTitle8_3={this.permission('parameters', this.props)}
              subTitle8_4="Notifications" isPermittedSubTitle8_4={this.permission('notifications', this.props)}
              subTitle8_5="Permission" isPermittedSubTitle8_5={this.permission('permission', this.props)}

              title9="Acc" titleIcon9="fa fa-bank"
              subTitle9_1="Accounts" isPermittedSubTitle9_1={this.permission('accounts', this.props)}
              subTitle9_2="Payments" isPermittedSubTitle9_2={this.permission('payments', this.props)}
              subTitle9_3="Payment Detail" isPermittedSubTitle9_3={this.permission('payment detail', this.props)}
              subTitle9_4="Income History" isPermittedSubTitle9_4={this.permission('income history', this.props)}
              subTitle9_5="Bank" isPermittedSubTitle9_5={this.permission('bank', this.props)}

              title10="POS" titleIcon10="fa fa-money"
              subTitle10_1="POS" isPermittedSubTitle10_1={this.permission('pos', this.props)}
              subTitle10_2="POS Orders" isPermittedSubTitle10_2={this.permission('pos orders', this.props)}
              subTitle10_3="POS Item Price" isPermittedSubTitle10_3={this.permission('pos item price', this.props)}
              subTitle10_4="POS Accounts" isPermittedSubTitle10_4={this.permission('pos accounts', this.props)}
              subTitle10_5="Outlet Stock" isPermittedSubTitle10_5={this.permission('outlet stock', this.props)}
              subTitle10_6="POS P and L" isPermittedSubTitle10_6={this.permission('pos p and l', this.props)}
              subTitle10_7="Disposed Items" isPermittedSubTitle10_7={this.permission('disposed items', this.props)}
              subTitle10_8="POS Running Report" isPermittedSubTitle10_8={this.permission('pos running report', this.props)}
            />
          </div>
          {/* /sidebar menu */}

          {/* /menu footer buttons */}
          <div className="sidebar-footer hidden-small">
            {/* <NavLink to={'/administrator/user'}> */}
            <a data-toggle="tooltip" data-placement="top" title="Settings" >
              <span className="glyphicon glyphicon-cog" aria-hidden="true" />
            </a>
            {/* </NavLink> */}
            <a data-toggle="tooltip" data-placement="top" title="FullScreen" onClick={() => {
              if (document.fullscreenElement) {
                // fullscreen is activated
                document.exitFullscreen();
              } else {
                // fullscreen is cancelled
                document.documentElement.requestFullscreen();
              }
            }}>
              <span className="glyphicon glyphicon-fullscreen" aria-hidden="true" />
            </a>
            <a data-toggle="tooltip" data-placement="top" title="Lock">
              <span className="glyphicon glyphicon-eye-close" aria-hidden="true" />
            </a>
            <a data-toggle="tooltip" data-placement="top" title="Logout" onClick={() => this.props.logout()}>
              <span className="glyphicon glyphicon-off" aria-hidden="true" />
            </a>
          </div>
          {/* /menu footer buttons */}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.rLogin.isLoggedIn,
    name: state.rLogin.name,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => { dispatch(loginOutAsync(false)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
