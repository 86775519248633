/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getNotifications, updateNotification, deleteNotification, addNotification } from '../../../actions/Administrator/notifications_actions';

const ReactTableFixedColumns = withFixedColumns(Table);

class Notifications extends React.Component {

    typeData = [
        { '_id': 'Message', 'name': 'Message' },
        { '_id': 'Setting', 'name': 'Setting' },
        { '_id': 'Key', 'name': 'Key' },
        { '_id': 'public_setting', 'name': 'public_setting' },
    ];

    boolData = [
        { '_id': 'true', 'name': 'true' },
        { '_id': 'false', 'name': 'false' }
    ];

    state = {
        notifications: [],
        name: '',
        description: '',
        type: '',
        message: '',
        notificationData:[],
        value: '',
        isActive: false,
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'name', desc: false }],
    };

    componentDidMount() {
        this.props.getNotifications(this.props.token);
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };
        
    componentDidUpdate(prevProps) {
        const { notifications } = this.props;
        if (notifications !== prevProps.notifications) {
            let newData = notifications.map((v)=>{
                let status = '';

                if(v.isActive ===  true){
                    status = "TRUE";
                }else{
                    status = "FALSE";
                }
                let data = {
                    "_id":v._id,
                    "createdAt":v.createdAt,
                    "description":v.description,
                    "message":v.message,
                    "name":v.name,
                    "slug":v.slug,
                    "type":v.type,
                    "updatedAt":v.updatedAt,
                    "value":v.value,
                    "isActive":status
                }
               return data
            })
            this.setState({
                notificationData:newData
            })
        }
    }

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDropdownList,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' defaultValue={props.value} onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    props.column.Header === 'Type' ? this.typeData :
                        this.boolData).map(data =>
                            <option key={data._id} value={data._id}>{data.name}</option>
                        )}
            </Field> : <label>{props.value}</label>)
        }
    };

    setStateForEdit = (column, value) => {
        if (column === "Is Active") {
            value = value === "true" ? true : false;
            this.setState({ isActive: value });
        } else if (column === 'Type') {
            this.setState({ type: value });
        }
    }

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        isActive: rowProps.original.isActive,
                        type: rowProps.original.type
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('ADMINISTRATOR', 'NOTIFICATIONS', 'UPDATE', this.props),
            delPerm: Permission('ADMINISTRATOR', 'NOTIFICATIONS', 'DELETE', this.props)
        }) ||
        {};

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values;
            data.isActive = this.state.isActive;
            data.type = this.state.type;

            this.props.updateNotification(data, this.props.token)

            this.setState({
                type: ''
            });

        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values._id
            }

            this.props.deleteNotification(data, this.props.token)
        }
    };

    addNotificationHandle = () => {
        var { name, description, type, message, value, isActive } = this.state;

        if (name.trim() !== '' || type.trim() !== '' || message.trim() !== '' || value.trim() !== '') {

            this.state.isActive = this.state.isActive === "true" ? true : false;
            this.state.slug = name.replace(/ /gi, "-");
            this.state.slug = this.state.slug.replace(/'/gi, "-");
            this.state.slug = this.state.slug.replace(/_/gi, "-");

            this.props.addNotification(this.state, this.props.token)

            this.setState({
                name: '',
                description: '',
                type: '',
                message: '',
                value: '',
                slug: ''
            });

            document.getElementById("addNotificationForm").reset();
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    notificationsColumns = [
        { Header: "Name", accessor: "name", ...this.editableColumnProps },
        { Header: "Description", accessor: "description", ...this.editableColumnProps },
        { Header: "Type", accessor: "type", ...this.selectableColumnProps },
        { Header: "Message", accessor: "message", ...this.editableColumnProps },
        { Header: "Value", accessor: "value", ...this.editableColumnProps },
        { Header: "Is Active", accessor: "isActive", ...this.selectableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('ADMINISTRATOR', 'NOTIFICATIONS', 'ADD', this.props)} data-toggle="modal" data-target="#addNotification">+ Notification</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.notificationsColumns}
                                                    data={this.state.notificationData}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>


                    <div id="addNotification" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Delivery Condition</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addNotificationForm'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ name: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Description :</td>
                                                        <td><input type="textarea" className="form-control" onChange={e => this.setState({ description: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Type* :</td>
                                                        <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ type: e.target.value })}>
                                                            <option value={''}>- Select Is Active -</option>
                                                            {this.typeData.map(element => {
                                                                return <option value={element._id}>{element.name}</option>
                                                            })}
                                                        </select>
                                                    </tr>

                                                    <tr>
                                                        <td>Message* :</td>
                                                        <td><input type="textarea" className="form-control" onChange={e => this.setState({ message: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Value* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ value: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is Active :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ isActive: e.target.value })}>
                                                                <option value={''}>- Select Is Active -</option>
                                                                {this.boolData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={this.addNotificationHandle}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        notifications: state.rNotifications.notifications,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        isLoading: state.rLogin.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getNotifications: (token) => { dispatch(getNotifications(token)) },
        updateNotification: (data, token) => { dispatch(updateNotification(data, token)) },
        deleteNotification: (data, token) => { dispatch(deleteNotification(data, token)) },
        addNotification: (data, token) => { dispatch(addNotification(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);


