/**
 * @file This file defined for new searchable dropdown component
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect } from 'react'
import Select from 'react-select';

const createItemArr = (options, label, setArray) => {
    let arr = options?.map(ele => ({ ...ele, value: ele._id, label: ele[label] }))
    setArray(arr)
}

/**
 * 
 * @param {Array} options   options array
 * @param {String} label    label name 
 * @returns {Component}
 */
const SearchableSelectComp = ({ options, label, onChange, value }) => {

    const [array, setArray] = useState([]);

    useEffect(() => {
        createItemArr(options, label, setArray)
    }, [options])

    return (
        <div style={{ width: '100%' }}>
            <Select
                options={array}
                theme={theme => ({ ...theme, borderRadius: 0 })}
                onChange={onChange}
                value={array.filter(option => option.value === value)}
            />
        </div>
    )
}

export default SearchableSelectComp
