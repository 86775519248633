/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const deliveryTypesAsync = data => {
    return { type: 'FETCH_DELIVERY_TYPES', data }
}

/**
 * 
 * @param {*} token 
 */
export const getDeliveryTypes = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/deliverytype/getDeliveryTypes')
            .then(result => {
                dispatch(deliveryTypesAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addDeliveryType = ({ name, desc, noOfItems, isBundled, upperWeight, lowerWeight, isWeightRange, isApproxWeight, weightUnit, weight, slug }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/basic_data/deliverytype/addDeliveryType', {
            data: {
                name, desc, noOfItems, isBundled, upperWeight, lowerWeight, isWeightRange, isApproxWeight, weightUnit, weight, slug
            }
        })
            .then(result => {
                var _id = result.data.insertedId;
                var slug = _id;

                const data = {
                    _id: _id,
                    slug: slug
                }

                dispatch(updateDeliveryTypeSlug(data, token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateDeliveryTypeSlug = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverytype/updateDeliveryType', { data })
            .then(result => {
                window.$('#addDeliveryType').modal('hide');

                toast.success('Delivery Type Added Successfully!')
                dispatch(getDeliveryTypes(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateDeliveryType = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverytype/updateDeliveryType', { data })
            .then(result => {
                toast.success('Successfully Updated Delivery Type')
                dispatch(getDeliveryTypes(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteDeliveryType = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/deliverytype/deleteDeliveryType', { data })
            .then(result => {
                toast.success('Delivery Type Deleted Successfully!')
                dispatch(getDeliveryTypes(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}