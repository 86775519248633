/**
 * @file This file defined for canceled purchase Overview
 * @author Umesh Ranthilina
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Table from "react-table";
import FormProvider from "../../components/FormProvider";
import { getCanceledPurchaseItem } from "../../../actions/Purchasing/purchasing_order_action";
import { DateNumberFormat } from '../../utils';

export class CanceledPurchase extends Component {
  state = {

  }

  componentDidMount() {
    this.props.getCanceledPurchaseItem(this.props.token)
  }

  columns = [
    { Header: "Order Id", accessor: "canceledpurchase.orderId", filterable: true },
    { Header: "Item Name", accessor: "items.name", filterable: true },
    { Header: "Item Code", accessor: "items.itemCode", filterable: true },
    {
      Header: "Date", accessor: "canceledpurchase.createdAt", filterable: true,
      Cell: props => {
        return <>{DateNumberFormat(props.original?.canceledpurchase?.createdAt || '')}</>
      }
    },
    { Header: "Previous Quantity", accessor: "canceledpurchase.orderqty", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Current Quantity", accessor: "canceledpurchase.available_qty", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "User", accessor: "users.name.first", filterable: true },
    { Header: "Status", accessor: "canceledpurchase.status", filterable: true },
  ];

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return (
      row[id] !== undefined ?
        String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
        true
    );
  }

  render() {
    return (
      <div className="right_col" role="main">
        <div className="card">
          <div className="card-header" style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'inline-flex', paddingRight: '1%' }}>

            </div>
          </div>
          <div className="card-body">
            <FormProvider
              form="inline"
            >
              {formProps => {
                return (
                  <Table className="-striped"
                    columns={this.columns}
                    data={this.props.canceledPurchaseItem}
                    defaultPageSize={20}
                    defaultFilterMethod={this.filterCaseInsensitive}
                    showPageJump={false}
                  />
                );
              }}
            </FormProvider>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    token: state.rLogin.token,
    canceledPurchaseItem: state.rOrder.canceledPurchaseItem
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCanceledPurchaseItem: (token) => { dispatch(getCanceledPurchaseItem(token)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CanceledPurchase);