/**
 * @author Ehsan Elahi
 */

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { toast } from 'react-toastify';


import "react-table/react-table.css";
import _ from 'lodash';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SkeletonTable from '../../components/skeleton/SkeletonTable';
import GridFilter from '../../components/GridFilter';

import SalesEvolutionTable from './SEComponents/SalesEvolutionTable';


import SalesEvolutionGraphModal from './SEComponents/SalesEvolutionGraphModal';
import SalesEvolutionGraphAll from './SEComponents/SalesEvolutionGraphAll';

import { ExportData } from '../../utils';

var axios = require('axios');

const SalesEvolution = (props) => {

    const getDatePriorThreeMonths = () => {
        let date = new Date()
        date.setMonth(date.getMonth() - 3)

        return new Date(date)
    }

    const [salesData, setSalesData] = useState([])
    const [columns, setColumns] = useState([])
    const [dateFrom, setDateFrom] = useState(getDatePriorThreeMonths())
    const [dateTo, setDateTo] = useState(new Date())
    const [currentItem, setCurrentItem] = useState(null);
    const [currentItemCode, setCurrentItemCode] = useState(null);
    const [currentItemCodType, setCurrentItemType] = useState(null);
    const [graphDataAll, setGraphDataAll] = useState([])
    const [keys, setKeys] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getData = () => {
        setIsLoading(true)
        const data = {
            'dateFrom': dateFrom,
            'dateTo': dateTo
        }

        axios.defaults.headers.common['authorization'] = props.token;
        axios.post('/mis/sales_evolution/getOrderItemsWithPromotionAndDiscount', { data })
            .then(result => {
                let data = result.data

                if (data.items) {
                    let items = data.items
                    let weeks = data.weeks

                    let finalData = []

                    items.map((item, index) => {
                        let obj = {}
                        obj.item = item.item
                        obj.avg = item.avg
                        obj.type = item.type
                        obj.itemName = item.itemName
                        obj.supplier = item.supplier
                        weeks.map(week => {
                            if (String(item[`W` + week.weekNumber + '-' + week.month + '-' + week.shortYear])) {
                                obj[`W` + week.weekNumber + '-' + week.month + '-' + week.shortYear] = Number(items[index][`W` + week.weekNumber+ '-' + week.month  + '-' + week.shortYear]).toFixed(0)
                                obj[`W` + week.weekNumber + '-' + week.month + '-' + week.shortYear + '-color'] = items[index][`W` + week.weekNumber + '-' + week.month + '-' + week.shortYear + '-color']
                            } else {
                                obj[`W` + week.weekNumber + '-' + week.month + '-' + week.shortYear] = 0
                                obj[`W` + week.weekNumber + '-' + week.month + '-' + week.shortYear + '-color'] = "#fff"
                            }
                        })

                        finalData.push(obj)
                    })

                    let columns = [];

                    columns.push(
                        { 'Header': 'Item', 'accessor': 'itemName', filterable: true },
                        { 'Header': 'Item Code', 'accessor': 'item', filterable: true },
                        { 'Header': 'Supplier', 'accessor': 'supplier', filterable: true },
                        { 'Header': 'Avg Week', 'accessor': 'avg', ...GridFilter, getProps: () => { return { style: { 'text-align': 'right' } } } },
                        { 'Header': 'Type', 'accessor': 'type', filterable: true }
                    )

                    weeks.map(week => {
                        let obj = {
                            'Header': 'W' + week.weekNumber + '-' + week.month + '-' + week.shortYear,
                            'accessor': 'W' + week.weekNumber + '-' + week.month + '-' + week.shortYear,
                            ...GridFilter,
                            getProps: (state, rowInfo) => {
                                return {
                                    'style': {
                                        'background': rowInfo?.original['W' + week.weekNumber + '-' + week.month + '-' + week.shortYear + '-color'],
                                        'text-align': 'right',
                                        'fontWeight': 'bold',
                                    }
                                }
                            },
                        }

                        columns.push(obj)
                    })

                    setSalesData(finalData)
                    setColumns(columns)
                    setIsLoading(false)
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }

    const setGraphData = () => {
        let keys = []

        let graphData = salesData.map(element => {
            if (element.type === 'Discount') {
                const { avg, type, ...rest } = element
                return rest
            }
        })

        columns.map(column => {
            if (column.Header === 'Avg Week' || column.Header === 'Type' || column.Header === 'Item') {

            } else {
                keys.push(column.Header)
            }
        })

        setGraphDataAll(graphData)
        setKeys(keys)
    }

    const exportData = () => {

        if (salesData.length > 0) {
            let excelData = []
            salesData.map(item => {
                var weeklyValuesWithoutColor = [];

                for (const key in item) {
                    if (key.startsWith('W') && !key.endsWith('-color')) {
                        weeklyValuesWithoutColor.push({
                            week: key,
                            value: item[key],
                        });
                    }
                }
                let data = {
                    'Item Name': item.itemName,
                    'Item Code': item.item,
                    'Supplier': item.supplier,
                    'Average Week': item.avg,
                    'Type': item.type,
                }

                weeklyValuesWithoutColor.forEach((weekValue) => {
                    data[weekValue.week] = Number(weekValue.value);
                });

                excelData.push(data)
            });

            ExportData("Sales Evolution", 'Sales Evolution', 'sales_evolution', excelData)
        }else{
            toast.error('No Data Found...!')
        }
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-lg-2 col-md-2">
                            <DatePicker
                                onChange={e => { setDateFrom(e) }}
                                placeholderText="Date From"
                                selected={dateFrom}
                                className="form-control"
                                dateFormat='dd/MM/yyyy'
                            />
                        </div>
                        <div className="col-lg-2 col-md-2">
                            <DatePicker
                                onChange={e => setDateTo(e)}
                                placeholderText="Date To"
                                selected={dateTo}
                                className="form-control"
                                dateFormat='dd/MM/yyyy'
                            />
                        </div>
                        <div className="col-lg-3 col-md-3">
                            {
                                isLoading ?
                                    <button type="button" className="btn btn-primary" disabled >
                                        Loading...
                                    </button> :
                                    <button type="button" className="btn btn-primary" onClick={e => getData()} >
                                        Fetch Data
                                    </button>
                            }

                            <button
                                data-toggle="modal"
                                className="btn btn-primary"
                                data-target="#graphALL"
                                onClick={e => setGraphData()}
                                disabled={isLoading}
                            >
                                Graph
                            </button>
                            <button className="btn btn-success" disabled={isLoading} onClick={exportData} >Export Data</button>
                        </div>
                        <div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <div style={{ width: '20px', height: '20px', borderRadius: '10px', background: '#80ccff' }}></div>
                            <div style={{ 'fontWeight': 'bold', }}>Promotion</div>
                        </div>
                        &nbsp; &nbsp;
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <div style={{ width: '20px', height: '20px', borderRadius: '10px', background: '#90ee7f' }}></div>
                            <div style={{ 'fontWeight': 'bold', }}>Discount</div>
                        </div>
                    </div>
                </div>
                <div className="card-body" style={{ overflow: 'scroll' }}>
                    {
                        isLoading
                            ? <>
                                <SkeletonTable columnsCount={9} dataCount={10} />
                            </>
                            :
                            columns?.length > 0 ?

                                <SalesEvolutionTable
                                    dataFromBackend={salesData}
                                    columnsFromBackend={columns}
                                    setCurrentItem={setCurrentItem}
                                    setCurrentItemCode={setCurrentItemCode}
                                    setCurrentItemType={setCurrentItemType}
                                />
                                : <>No Data Found.</>

                    }
                </div>

                <SalesEvolutionGraphModal currentItem={currentItem} currentItemCode={currentItemCode} currentItemCodType={currentItemCodType} />

                <SalesEvolutionGraphAll data={graphDataAll} keys={keys} />

                <div className="card-footer"></div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        loggedInUserName: state.rLogin.name,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesEvolution);


