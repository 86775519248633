/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
// import _ from 'lodash';
import { toast } from "react-toastify";
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import { getStockItems, updateItemStocks, deleteItemStock, addItemStock, formatDate, stockReCal, getExpDateStock, updateExpDateStock, deleteExpDateStock, updateExpStocks } from '../../../actions/Stock/stock_item_actions';
import { getItems } from '../../../actions/Stock/stock_actions';
import Permission from "../../utils/Permission";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import StockExpDate from "./Modal/StockExpModal/StockExpDate";
import StockReCal from "./Modal/StockReCalModal/StockReCal";
import { getDeliveryWarehouses } from "../../../actions/Basic_data/delivery_warehouse_actions";
import ItemStockHistory from "./Modal/ItemStockHistory";
import ItemStockReport from "./Modal/ItemStockReport";
import { getStockItemReportData, getStockTotalReportData } from '../../../actions/Stock/stock_item_actions';
import { ExportData } from "../../utils";
import * as XLSX from 'xlsx';
import ConvertDecimalPlaces from "../../utils/ConvertDecimalPlaces";
const fileExtension = '.xlsx';


const getHistoryData = (comp, data) => {
    axios.defaults.headers.common['authorization'] = comp.props.token;
    axios.post('/stock/getStockHistoryByFilter', { data })
        .then(result => {
            comp.setState({ itemStockHistory: result.data });
        }).catch(err => {
            console.error(err)
        })
}



class StockItem extends React.Component {

    oneMonthAgo = new Date();

    stockReasonStatus = [
        { 'value': 'Missing Items', 'name': 'Missing Items' },
        { 'value': 'Damage Items', 'name': 'Damage Items' },
        { 'value': 'Expired Items', 'name': 'Expired Items' },
        { 'value': 'Increase Stock', 'name': 'Increase Stock' },
    ];

    state = {
        deleting: null,
        editing: null,
        stockItems: {},
        items: {},
        item: '',
        stock: 0,
        reservationStock: 0,
        minimumStock: 0,
        remark: '',
        availableStock: 0,
        slug: '',
        currentItemInFilter: '',
        startDate: '',
        endDate: '',
        itemStockHistory: [],
        reservationItemStockPopupData: [],
        sortOptions: [{ id: 'orderId', desc: true }],
        rowInfo: {},
        userId: '',
        disabled: false,
        from: new Date().toISOString().split('T')[0]
    };

    componentDidMount() {
        this.oneMonthAgo.setMonth(this.oneMonthAgo.getMonth() - 1)
        this.props.getStockItems(this.props.token)
        this.props.getItems(this.props.token)
        this.setState({
            userId: this.props.userId
        })
    }

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;

            return (editing ? <Field name={props.column.id} component="select" className='form-control'>
                {
                    (this.stockReasonStatus).map(data =>
                        <option key={data.value} value={data.value}>{data.name}</option>
                    )}
            </Field> : <label>{props.value}</label>)
        }
    };

    editableComponent = ({ input, editing, value, original, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => this.setState({ editing: rowProps.original }),
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('STOCK', 'STOCK ITEM', 'UPDATE', this.props),
            delPerm: Permission('STOCK', 'STOCK ITEM', 'DELETE', this.props),
        }) ||
        {};



    columns = [
        {
            Header: 'Name',
            accessor: "item_name",
            filterable: true,
            maxWidth: 300,
            minWidth: 300,
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#reservationItemStockPopup' className='btn btn-link'
                        onClick={() => {
                            axios.defaults.headers.common['authorization'] = this.props.token;

                            const data = {
                                'item': row.original._id
                            }

                            axios.post('/stock/getOrderItems', { data })
                                .then(result => {
                                    var orderItems = result.data;
                                    axios.get('/stock/getOrders')
                                        .then(result => {
                                            var orders = result.data;
                                            var finalData = [];

                                            orderItems.forEach(orderItemElement => {
                                                orders.forEach(orderElement => {
                                                    for (var i = 0; i < orderElement.orders.orderItems.length; i++) {
                                                        if (orderItemElement._id == orderElement.orders.orderItems[i] && orderElement.orders.status != 'Delivered' && orderElement.orders.status != 'Dispatched' && (orderItemElement.matchMissing === true ? orderElement.orders.status == 'DispatchedMissing' : orderElement.orders.status != 'DispatchedMissing')) {

                                                            var arr = {
                                                                'quantity': orderItemElement.quantity,
                                                                'orderId': orderElement.orders.orderId,
                                                                'address': orderElement.orders.address,
                                                                'telephone': orderElement.orders.telephone,
                                                                'name': orderElement.orders.name,
                                                                'city': orderElement.orderItemCities.name,
                                                                'whatsapp': orderElement.orders.whatsapp
                                                            }

                                                            finalData.push(arr);
                                                        }
                                                    }
                                                });
                                            });

                                            this.setState({ reservationItemStockPopupData: finalData });

                                        }).catch(err => {
                                            console.error(err)
                                        })
                                }).catch(err => {
                                    console.error(err)
                                })

                        }}
                    >{row.value}</button>
                </>
        },
        { Header: "Brand", accessor: "brand_name", ...GridFilters },
        { Header: "Stock", accessor: "stock", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Stock Change", accessor: "stockChange", ...this.editableColumnProps, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Reservation Stock", accessor: "reservationStock", ...this.editableColumnProps, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Available Stock", accessor: "availableStock", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Minimum Stock", accessor: "minimumStock", ...this.editableColumnProps, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Stock Unit", accessor: "stock_unit", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Reason", accessor: "reason", ...this.selectableColumnProps, maxWidth: 150, minWidth: 150, },
        {
            Header: "exp items", accessor: "isStockMatch", getProps: () => { return { style: { 'textAlign': 'right' } } },
            filterable: true,
            filterMethod: (filter, row) => {
                // console.log(filter, row)
                if (filter.value === "all") {
                    // console.log('all');
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"true"}>Yes</option>
                    <option value={"false"}>No</option>
                </select>,
            Cell: props => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <div style={{ width: '20px', height: '20px', borderRadius: '10px', background: props.value ? '#26bd26' : 'red' }}></div> <div>{ConvertDecimalPlaces(props.original.stockItemTotal)}</div>
            </div>
        },
        {
            Header: "Stock exp.", accessor: "remark", maxWidth: 80, minWidth: 80,
            Cell: prop => <>
                <button className="btn btn-link" data-toggle="modal" data-target='#stockExpModal' onClick={() => this.setState({ rowInfo: prop, disabled: false })}>
                    <i className="fa fa-list" aria-hidden="true"></i>
                </button>
            </>
        },
        {
            Header: 'Stock History',
            maxWidth: 100,
            minWidth: 100,
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#itemStockHistory' className='btn btn-link'
                        onClick={() => {
                            this.setState({
                                currentItemInFilter: row.original._id,
                                // startDate: new Date(this.oneMonthAgo),
                                // endDate: new Date()
                            });

                            this.state.startDate = new Date(this.oneMonthAgo);
                            this.state.endDate = new Date();

                            const data = {
                                'item': row.original._id,
                                'startDate': formatDate(this.state.startDate),
                                'endDate': formatDate(this.state.endDate)
                            }

                            getHistoryData(this, data)

                            const dataReport = {
                                'item': row.original._id,
                                'startDate': formatDate(this.state.startDate),
                                'endDate': formatDate(this.state.endDate),
                                'token': this.props.token
                            };

                            this.props.getStockItemReportData({
                                ...dataReport
                            })
                        }}
                    >
                        <i className="fa fa-history" aria-hidden="true"></i>
                    </button>
                </>
        },
        {
            Header: "Actions",
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    reservationItemStockPopupColumns = [
        { Header: "Order ID", accessor: "orderId", filterable: true },
        { Header: "Quantity", accessor: "quantity", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Customer Name", accessor: "name", filterable: true },
        { Header: "Telephone", accessor: "telephone", filterable: true },
        {
            Header: 'Whatsapp',
            maxWidth: 130,
            minWidth: 130,
            filterable: true,
            Cell: row => {

                const url = "https://api.whatsapp.com/send?phone=" + row.original.whatsapp;
                return <u><a href={url} target="_blank">{row.original.whatsapp}</a></u>
            }
        },
        { Header: "Address", accessor: "address", filterable: true },
        { Header: "City", accessor: "city", filterable: true },
    ];


    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {

            var stock = values.stock + Number(values.stockChange);

            var availableStock = stock - values.reservationStock;

            if (values.stock_id !== null) {
                if (values.reason !== "" && values.reason !== null) {
                    var data = {
                        '_id': values.stock_id,
                        'stock': Number(stock),
                        'availableStock': Number(availableStock),
                        'reservationStock': Number(values.reservationStock),
                        'remark': values.reason,
                        'reason': values.reason,
                        'item_id': values._id,
                        'previousStock': Number(values.previousStock),
                        'minimumStock': Number(values.minimumStock),
                        'user': this.props.userId
                    };

                    this.props.updateItemStocks(data, this.props.token)
                } else {
                    toast.error('Stock items reason is mandatory!')
                }
            }

        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values.stock_id
            }

            const resStock = values.reservationStock;

            if (resStock > 0) {
                toast.info('Stock with reservation stock data cannot be deleted!');
                return;
            }

            this.props.deleteItemStock(data, this.props.token)
        }

    };

    addItemStockHandle = () => {
        var { stock, reservationStock, minimumStock, item, remark } = this.state;

        if (stock !== '' && item !== '') {
            if (isNaN(stock)) {
                toast.error('Stock must be a number!')
            } else if (isNaN(reservationStock)) {
                toast.error('reservationStock must be a number!')
            } else {

                this.state.availableStock = this.state.stock - this.state.reservationStock;

                this.state.reservationStock = Number(this.state.reservationStock)

                this.state.slug = '1';

                this.props.addItemStock(this.state, this.props.token)

                this.setState({
                    stock: '',
                    reservationStock: 0,
                    minimumStock: 0,
                    item: '',
                    remark: '',
                    availableStock: ''
                });

                this.props.getItems(this.props.token)

                document.getElementById("addItemStockForm").reset();
            }
        } else {
            toast.error('Stock and Item are mandatory!')
        }
    }

    exportData = () => {
        let newData = this.props.stockItems;
        let a = [''];

        var unique = [...new Map(this.props?.stockItems?.map(item => [`${item['supplierName']}`, item])).values()]
        unique.forEach(tableData => {
            const processdTableData = this.processTableData(tableData, newData);
            const ws = XLSX.utils.json_to_sheet(processdTableData);
            const header = [{ SupplierName: tableData.supplierName + " Data " }]
            a = a.concat(header).concat(XLSX.utils.sheet_to_json(ws, { header: 1 }))

        });

        const worksheet = XLSX.utils.json_to_sheet(a, { skipHeader: true });
        const new_workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Stock Item")
        XLSX.writeFile(new_workbook, 'Stock details' + fileExtension);
    }

    exportStockData = () => {
        this.props.getStockTotalReportData(this.state.from, this.props.token, (result) => {
            this.exportStockTotalData(result)
        })
    }

    exportStockTotalData = (data) => {
        if (data.length > 0) {

            var excelData = data.map(checkData => {

                return {
                    'Item Name': checkData.itemName,
                    'Item Code': checkData.itemCode,
                    'Stock': Number(checkData.inStock),
                    'Price': Number(checkData.price).toFixed(2),
                    'Purchase Price': Number(checkData.purchasePrice).toFixed(2),
                    'Total Stock Value': Number(checkData.price * checkData.inStock).toFixed(2),
                }
            })
            ExportData("Total Stock Value", "Total Stock Value", "Total Stock Value", excelData)
        }
    }
    processTableData = (tableData, allExportedData) => {

        let array = [];
        allExportedData.map((item, index) => {
            if (tableData.supplierName === item.supplierName) {
                let data = {
                    'Item Name': item.item_name,
                    'Stock': item.stock,
                    'Available Stock': item.availableStock,
                }
                array.push(data)
            }
        });

        let sortResult = _.sortBy(array, ['supplierName']);

        sortResult.push({ "Item Name": "", "Stock": "", "Available Stock": "" });
        return sortResult;
    }

    filterStockHistoryData = () => {
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;

        if (startDate == '') {
            toast.error('Date From is Empty')
        }

        if (endDate == '') {
            toast.error('Date To is Empty')
        }

        if (startDate != '' && endDate != '') {
            var startDate = new Date(Date.parse(startDate)).toISOString();

            var endDate = new Date(Date.parse(endDate)).toISOString();

            const data = {
                'startDate': formatDate(startDate),
                'endDate': formatDate(endDate),
                'item': this.state.currentItemInFilter
            };

            getHistoryData(this, data)
            // axios.defaults.headers.common['authorization'] = this.props.token;
            // axios.post('/stock/getStockHistoryByFilter', { data })
            //     .then(result => {
            //         this.setState({ itemStockHistory: result.data });
            //     }).catch(err => {
            //         toast.error(`${err}`)
            //     })
        }
    }

    filterStockReportData = () => {
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;

        if (startDate == '') {
            toast.error('Date From is Empty')
        }

        if (endDate == '') {
            toast.error('Date To is Empty')
        }

        if (startDate != '' && endDate != '') {
            var startDate = new Date(Date.parse(startDate)).toISOString();

            var endDate = new Date(Date.parse(endDate)).toISOString();

            const data = {
                'startDate': formatDate(startDate),
                'endDate': formatDate(endDate),
                'item': this.state.currentItemInFilter,
                'token': this.props.token
            };

            this.props.getStockItemReportData({
                ...data
            })
        }
    }



    setStartDate = date => {
        this.setState({
            startDate: date
        });
    }

    setEndDate = date => {
        this.setState({
            endDate: date
        });
    }

    render() {
        return (
            <div className="right_col" role="main" >
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={this.props.isLoading} type="button" onClick={() => this.props.getStockItems(this.props.token)}><i className="fa fa-sync"></i></button>
                        <button className="btn btn-primary" disabled={this.props.isLoading ? true : Permission('STOCK', 'STOCK ITEM', 'ADD', this.props)} data-toggle="modal" data-target="#addItemStock">+ Stock</button>
                        <button className="btn btn-success" disabled={this.props.isLoading ? true : Permission('STOCK', 'Stock Item{Stock Recal}', 'VIEW', this.props)} data-toggle="modal" data-target="#reCalStock">Stock Recal</button>
                        <button className="btn btn-success" onClick={this.exportData} data-target="#reCalStock">Export Data</button>
                        {/* <button className="btn btn-success" disabled={this.props.isLoading ? true : Permission('STOCK', 'Stock Item', 'VIEW', this.props)} data-toggle="modal" data-target="#stockTotal">Export Total Report</button> */}
                    </div>

                    {this.props.isLoading
                        ? <div className="card-body">
                            <SkeletonTable columnsCount={9} dataCount={10} />
                        </div>
                        : <div className="card-body">
                            <React.Fragment>
                                <ErrorBoundary>
                                    <FormProvider
                                        form="inline"
                                        onSubmit={this.handleSubmit}
                                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                        enableReinitialize
                                    >
                                        {formProps => {
                                            return (
                                                <form onSubmit={formProps.handleSubmit}>
                                                    <Table className="-striped"
                                                        columns={this.columns}
                                                        data={this.props.stockItems}
                                                        defaultPageSize={10}
                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                        showPageJump={false}
                                                    />
                                                </form>
                                            );
                                        }}
                                    </FormProvider>
                                </ErrorBoundary>
                            </React.Fragment>
                        </div>}
                    {/* <div className="card-footer"></div> */}

                    <div id="addItemStock" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Stock</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addItemStockForm'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Item* :</td>
                                                        <td>
                                                            <Select
                                                                options={this.props.items}
                                                                onChange={e => e === '' ? null : this.setState({ item: e.value })}
                                                                defaultValue=''
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Stock* :</td>
                                                        <td><input type="text" className="form-control minWidthCustom" onChange={e => this.setState({ stock: Number(e.target.value) })} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reservation Stock  :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ reservationStock: Number(e.target.value) })} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Minimum Stock  :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ minimumStock: Number(e.target.value) })} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Remark :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ remark: e.target.value })} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="add_stock" type="button" className="btn btn-primary" onClick={this.addItemStockHandle}>Add</button>
                                    {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div id='itemStockHistory' className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Stock History</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-header">
                                    <h6 className="modal-title text-uppercase">Filter</h6>
                                    <span className='pull-left'>
                                        Date From:
                                        <DatePicker selected={this.state.startDate} onChange={this.setStartDate} maxDate={new Date()} />
                                    </span>

                                    <span className='pull-left'>
                                        Date To:
                                        <DatePicker selected={this.state.endDate} onChange={this.setEndDate} maxDate={new Date()} />
                                    </span>
                                    <span>
                                        <button type="button" className="btn btn-primary" onClick={this.filterStockHistoryData}>Filter</button>
                                    </span>
                                </div>
                                <div className="modal-body">
                                    {(this.state.itemStockHistory === null || this.state.itemStockHistory.length == 0) ?
                                        <>
                                            No Data Found...!
                                        </>
                                        :
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <FormProvider
                                                    form="inline"
                                                >
                                                    {formProps => {
                                                        return (
                                                            <form>
                                                                <Table className="-striped"
                                                                    columns={this.stockHistoryColumns}
                                                                    data={this.state.itemStockHistory}
                                                                    defaultPageSize={10}
                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                />
                                                            </form>
                                                        )
                                                    }}

                                                </FormProvider>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <ErrorBoundary>
                        <ItemStockHistory state={this.state} filterCaseInsensitive={this.filterCaseInsensitive}
                            filterStockHistoryData={this.filterStockHistoryData} setStartDate={this.setStartDate} setEndDate={this.setEndDate} filterStockReportData={this.filterStockReportData} detailData={this.props.stockItemsReportData} />
                    </ErrorBoundary>

                    <div id='reservationItemStockPopup' className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Reservation Stock</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    {(this.state.reservationItemStockPopupData === null || this.state.reservationItemStockPopupData.length == 0) ?
                                        <>
                                            No Data Found...!
                                        </>
                                        :
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <FormProvider
                                                    form="inline"
                                                >
                                                    {formProps => {
                                                        return (
                                                            <form>
                                                                <Table className="-striped"
                                                                    sorted={this.state.sortOptions}
                                                                    onSortedChange={val => {
                                                                        this.setState({ sortOptions: val })
                                                                    }}
                                                                    columns={this.reservationItemStockPopupColumns}
                                                                    data={this.state.reservationItemStockPopupData}
                                                                    defaultPageSize={10}
                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                />
                                                            </form>
                                                        )
                                                    }}

                                                </FormProvider>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ErrorBoundary>
                        <div id="stockTotal" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Stock Total Report</h4>
                                        <button type="button" className="close" data-dismiss="modal"> &times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='card'>
                                            <div className='card-header' style={{ display: 'inline-flex' }}>
                                                <ErrorBoundary>
                                                    <div>
                                                        Date :
                                                    </div>
                                                    <input type='date' name="dateFrom" className='form-control' style={{ width: '40%' }} defaultValue={this.state.from}
                                                        onChange={e => {
                                                            this.setState({ from: new Date(e.target.valueAsDate) })
                                                        }} />
                                                </ErrorBoundary>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.exportStockData}>Export Data</button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <StockReCal props={this.props} />
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <StockExpDate rowInfo={this.state.rowInfo} props={this.props} disabled={this.state.disabled} />
                    </ErrorBoundary>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    token: state.rLogin.token,
    isLoading: state.rLogin.loading,
    stockItems: state.rStockItem.stockItems,
    stockExpDate: state.rStockItem.stockItemsWithExpDate,
    items: state.rStock.items,
    deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
    user: state.rLogin.name,
    userId: state.rLogin.id,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    stockItemsReportData: state.rStockItem.stockItemsReportData,
})

const mapDispatchToProps = dispatch => ({
    getStockItems: token => { dispatch(getStockItems(token)) },
    updateItemStocks: (data, token) => { dispatch(updateItemStocks(data, token)) },
    deleteItemStock: (data, token) => { dispatch(deleteItemStock(data, token)) },
    addItemStock: (data, token) => { dispatch(addItemStock(data, token)) },
    getItems: token => { dispatch(getItems(token)) },
    formatDate: date => { dispatch(formatDate(date)) },
    stockReCal: props => { dispatch(stockReCal(props)) },
    getDeliveryWarehouses: props => { dispatch(getDeliveryWarehouses(props)) },
    getExpDateStock: (data, props) => dispatch(getExpDateStock(data, props)),
    updateExpDateStock: (data, props) => dispatch(updateExpDateStock(data, props)),
    deleteExpDateStock: (data, props) => dispatch(deleteExpDateStock(data, props)),
    updateExpStocks: (data, props) => dispatch(updateExpStocks(data, props)),
    getStockItemReportData: (data, callback) => { dispatch(getStockItemReportData(data, callback)) },
    getStockTotalReportData: (date, token, callback) => { dispatch(getStockTotalReportData(date, token, callback)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(StockItem);


