/**
 * @file This file defined for user permission view
 * @author Bhanuka Chathuranga
 */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash';
import Select from 'react-select';
import Table from "react-table";
import { motion } from "framer-motion";
import "react-table/react-table.css";
import '../../../css/button.css'

import { getUsers } from '../../../actions/Administrator/user_actions';
import { copyPermission, getOptionData, getProgrammeData, getSubProgrammeData, getUserProgrammeData, updateProPermission, updateSubProPermission } from '../../../actions/Administrator/permission_action';
import ErrorBoundary from '../../components/ErrorBoundary';
import SkeletonTable from '../../components/skeleton/SkeletonTable';


function Permission(props) {

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({});
    const [copyUsers, setCopyUsers] = useState([]);
    const [copyUser, setCopyUser] = useState([]);
    const [expanded, setExpanded] = useState({});
    const [rSelectCustomer, setRSelectCustomer] = useState([]);

    useEffect(() => {
        props.getUsers(props)
        props.getProgramme(props)
        // props.getSubProgramme(props)
        // props.getOptions(props)
        // props.getUserProgramme(props)
    }, [])

    useEffect(() => {
        // Check user type and skip normal and corporate users
        var data = _.filter(props.users, function (o) {
            // if (o.userType !== "Normal") if (o.userType !== "Corporate") return o
            if (o.isAdmin) return o
        })
        setUsers(data)
    }, [props.users])

    useEffect(() => {
        props.getUserProgramme({ id: user._id, token: props.token })
    }, [user])

    var columns = [
        { Header: "Programme", accessor: "name" },
        {
            Header: "View",
            Cell: prop => {
                var option = _.filter(props.options, { name: "view" })
                var user_pro = _.filter(props.userProgramme[0]?.programme, { 'programmeid': prop.original?._id, 'options': option[0]?._id })
                // console.log(option)
                // console.log(user_pro)
                return <>
                    <motion.button className="btn btn-sm" style={{ backgroundColor: 'rgba(0,0,0,0)', border: 'rgba(0,0,0,0)' }}
                        whileHover={{ scale: 1.2, rotateX: 180 }}
                        onClick={() => {
                            if (user_pro.length > 0) {
                                props.addProPermission(prop, { ...props, id: user?._id }, false)
                            } else {
                                props.addProPermission(prop, { ...props, id: user?._id }, true)
                            }
                        }}
                    >
                        {user_pro?.length > 0 ?
                            <i className="fas fa-thumbs-up "></i>
                            : <i className="fas fa-thumbs-down"></i>}
                    </motion.button>
                    {/* <i class="far fa-thumbs-down"></i> */}
                </>
            }
        },
    ];

    const subColumns = [
        { Header: "Sub Programme", accessor: "name" },
        {
            Header: "View",
            Cell: prop => {
                var option = _.filter(props.options, { 'name': "view" })
                var user_sub_pro = _.filter(
                    _.filter(props.userProgramme[0]?.programme, { 'programmeid': prop.original?.programme_ref })[0]?.subprogramme,
                    { 'subprogrammeid': prop.original?._id })
                var isAuth = user_sub_pro[0]?.options.includes(option[0]._id)
                // console.log(user_sub_pro)
                // console.log(option)
                return <>
                    <button className="btn btn-sm"
                        onClick={() => props.changeSubProPermission(prop, { ...props, id: user?._id }, option, !isAuth)}
                    // onClick={() => console.log(isAuth)}
                    >
                        {user_sub_pro.length > 0 && isAuth ?
                            <i className="fas fa-thumbs-up"></i>
                            : <i className="fas fa-thumbs-down"></i>}
                    </button>
                    {/* <i class="far fa-thumbs-down"></i> */}
                </>
            }
        },
        {
            Header: "Add",
            Cell: prop => {
                var option = _.filter(props.options, { 'name': "add" })
                var user_sub_pro = _.filter(
                    _.filter(props.userProgramme[0]?.programme, { 'programmeid': prop.original?.programme_ref })[0]?.subprogramme,
                    { 'subprogrammeid': prop.original?._id })
                var isAuth = user_sub_pro[0]?.options.includes(option[0]._id)

                // console.log(prop)
                // console.log(option)
                return <>
                    <button className="btn btn-sm"
                        onClick={() => props.changeSubProPermission(prop, { ...props, id: user?._id }, option, !isAuth)}
                    >
                        {user_sub_pro.length > 0 && isAuth ?
                            <i className="fas fa-thumbs-up"></i>
                            : <i className="fas fa-thumbs-down"></i>}
                    </button>
                    {/* <i class="far fa-thumbs-down"></i> */}
                </>
            }
        },
        {
            Header: "Edit",
            Cell: prop => {
                var option = _.filter(props.options, { 'name': "update" })
                var user_sub_pro = _.filter(
                    _.filter(props.userProgramme[0]?.programme, { 'programmeid': prop.original?.programme_ref })[0]?.subprogramme,
                    { 'subprogrammeid': prop.original?._id })
                var isAuth = user_sub_pro[0]?.options.includes(option[0]._id)

                // console.log(prop)
                // console.log(option)
                return <>
                    <button className="btn btn-sm"
                        onClick={() => props.changeSubProPermission(prop, { ...props, id: user?._id }, option, !isAuth)}
                    >
                        {user_sub_pro.length > 0 && isAuth ?
                            <i className="fas fa-thumbs-up"></i>
                            : <i className="fas fa-thumbs-down"></i>}
                    </button>
                    {/* <i class="far fa-thumbs-down"></i> */}
                </>
            }
        },
        {
            Header: "Delete",
            Cell: prop => {
                var option = _.filter(props.options, { 'name': "delete" })
                var user_sub_pro = _.filter(
                    _.filter(props.userProgramme[0]?.programme, { 'programmeid': prop.original?.programme_ref })[0]?.subprogramme,
                    { 'subprogrammeid': prop.original?._id })
                var isAuth = user_sub_pro[0]?.options.includes(option[0]._id)

                // console.log(prop)
                // console.log(option)
                return <>
                    <button className="btn btn-sm"
                        onClick={() => props.changeSubProPermission(prop, { ...props, id: user?._id }, option, !isAuth)}
                    >
                        {user_sub_pro.length > 0 && isAuth ?
                            <i className="fas fa-thumbs-up"></i>
                            : <i className="fas fa-thumbs-down"></i>}
                    </button>
                    {/* <i class="far fa-thumbs-down"></i> */}
                </>
            }
        },
    ];

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    /**
     * To handle copy users
     * 
     * @param {Object} user selected user
     * @param {Array[Object]} users user list
     */
    const removeUser = (user, users) => {
        let valUser = users.filter(usr => { if (usr._id !== user._id) return usr })
        return valUser;
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', width: '50%' }}>
                            <div style={{ width: '20%' }}>User :</div>
                            <div style={{ width: '50%' }}>
                                <Select
                                    options={users}
                                    // value={[]}
                                    theme={theme => ({ ...theme, borderRadius: 0 })}
                                    onChange={e => setUser(e)} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', direction: 'column' }}>
                            {Object.keys(user).length === 0 && user.constructor === Object ?
                                null
                                : <div style={{}}>User Type : {user.userType}</div>}
                        </div>

                        <div style={{}}>
                            <button className="btn btn-primary" disabled={Object.keys(user).length === 0 && user.constructor === Object ? true : false} data-toggle="modal" data-target="#copyPermission"
                                onClick={() => {
                                    let val = removeUser(user, users)
                                    setCopyUser([]);
                                    setRSelectCustomer([])
                                    setCopyUsers(val)
                                }}
                            >
                                Copy User Permissions
                            </button>
                        </div>
                    </div>
                </div>
                {props.userProgramme.length > 0
                    ? <div className="card-body">
                        <ErrorBoundary>
                            <Table
                                columns={columns}
                                data={props.programme}
                                defaultPageSize={props.programme.length}
                                showPaginationBottom={false}
                                expanded={expanded}
                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                getTrProps={(state, rowInfo, column) => {
                                    // if (rowInfo === undefined) {
                                    //     return {};
                                    // }
                                    // console.log(state);
                                    return {
                                        // "data-qnt": rowInfo.original.show === true ? 1 : 0
                                        "data-qnt": 0
                                    };
                                }}
                                SubComponent={row => {
                                    // console.log(row);
                                    var subData = props.subProgramme.filter(sData => {
                                        if (row.original._id === sData.programme_ref) {
                                            return sData
                                        }
                                    })
                                    if (subData.length > 0) {
                                        return (
                                            <ErrorBoundary>
                                                <Table
                                                    columns={subColumns}
                                                    data={subData}
                                                    defaultPageSize={subData.length}
                                                    showPaginationBottom={false}
                                                />
                                            </ErrorBoundary>)
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                    : props.isLoading
                        ? <div className="card-body" style={{ height: '100%' }}>
                            {/* loading...! */}
                            <SkeletonTable />
                        </div>
                        : null}

                {/* copy permissions */}
                <ErrorBoundary>
                    <div id='copyPermission' className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Copy Permission</h4>
                                    <button type="button" className="close" data-dismiss="modal"> &times;</button>
                                </div>
                                <div className="modal-body">
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <div style={{ width: '50%' }}>
                                            <Select
                                                options={copyUsers}
                                                value={rSelectCustomer}
                                                onChange={e => {
                                                    let copyArr = copyUser;
                                                    let { _id, name, userType } = e
                                                    let obj = { _id, name, userType }
                                                    copyArr.push(obj)
                                                    let newArr = _.uniqWith(copyArr, _.isEqual)
                                                    setCopyUser(newArr)
                                                }} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '20px' }}>
                                            Copy {user?.name?.first} {user?.name?.last} to
                                            {copyUser?.map(usr => <div id={usr._id} key={usr._id}>{usr.name.first} {usr.name.last}</div>)}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" disabled={copyUser.length > 0 ? false : true} className="btn btn-primary" data-dismiss="modal" onClick={() => props.copyPermission(copyUser,user, props)}>Copy now</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>

            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        id: state.rLogin.id,
        users: state.rUser.users,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userProgramme: state.rPermission.userProgramme,
        isLoading: state.rLogin.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: ({ token }) => { dispatch(getUsers(token)) },
        getProgramme: prop => { dispatch(getProgrammeData(prop)) },
        getSubProgramme: prop => { dispatch(getSubProgrammeData(prop)) },
        getOptions: prop => { dispatch(getOptionData(prop)) },
        getUserProgramme: prop => { dispatch(getUserProgrammeData(prop)) },
        addProPermission: (prop, props, isAdd) => { dispatch(updateProPermission(prop, props, isAdd)) },
        changeSubProPermission: (prop, props, option, isAuth) => { dispatch(updateSubProPermission(prop, props, option, isAuth)) },
        copyPermission: (users,user, props) => { dispatch(copyPermission(users,user, props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Permission);

