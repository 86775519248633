/**
 * @file This file defined for brand details
 * @author Ehsan Elahi
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field } from "redux-form";
import FormProvider from '../../components/FormProvider';

import Table from "react-table";

import * as BS from "react-bootstrap";
import "react-table/react-table.css";

import ErrorBoundary from '../../components/ErrorBoundary';
import Permission from '../../utils/Permission';
import ActionsCell from '../../components/ActionCell';
import GridFilter from '../../components/GridFilter';
import HighlightCell from "../../components/HighlightCell";
import { getSurveyQuestionsData, addSurveyQuestion, updateSurveyQuestion, deleteSurveyQuestion } from '../../../actions/Basic_data/survey_questions_actions';
import { toast } from 'react-toastify';


export const SurveyQuestions = props => {

    const [question, setQuestion] = useState(null);
    const [optionOneText, setOptionOneText] = useState(null);
    const [optionOneNumber, setOptionOneNumber] = useState(null);
    const [optionTwoText, setOptionTwoText] = useState(null);
    const [optionTwoNumber, setOptionTwoNumber] = useState(null);
    const [optionThreeText, setOptionThreeText] = useState(null);
    const [optionThreeNumber, setOptionThreeNumber] = useState(null);
    const [sortOptions, setSortOptions] = useState([{ id: 'createdAt', desc: true }]);
    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);

    useEffect(() => {
        props.getSurveyQuestionsData(props.token);
    }, [])

    const activeOptions = [
        { 'value': 'Yes', 'label': 'Yes' },
        { 'value': 'No', 'label': 'No' }
    ];

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'SURVEY QUESTIONS', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'SURVEY QUESTIONS', 'DELETE', props)
        }) ||
        {};

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const editableNumberColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };

            return <Field style={{ 'float': 'right' }} name={props.column.id} {...fieldProps} />;
        }
    };

    const selectableColumnProps = {
        ...GridFilter,
        Cell: Props => {
            const edit = editing === Props.original;

            return (edit ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {(activeOptions).map(({ value, label }) =>
                        <option
                            key={value} value={value}>
                            {label}
                        </option>
                    )}

                </Field> : <label>{Props.value}</label>)
        }
    };

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(String(row[id]).toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    const formatDate = (date) => {
        if (date === null || date === '') {
            return '';
        }
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var hours = d.getHours();
        var minutes = d.getMinutes();
        var seconds = d.getSeconds();

        var formatedDate = [year, month, day].join('-');
        var formatedTime = [hours, minutes, seconds].join(':')

        return [formatedDate, formatedTime].join(' ');
    }

    const columns = [
        { Header: 'Question', accessor: 'question', filterable: true, ...editableColumnProps },
        { Header: 'Option 1', accessor: 'options[0].text', filterable: true, ...editableColumnProps },
        { Header: 'Value 1', accessor: 'options[0].value', filterable: true, ...editableNumberColumnProps },
        { Header: 'Option 2', accessor: 'options[1].text', filterable: true, ...editableColumnProps },
        { Header: 'Value 2', accessor: 'options[1].value', filterable: true, ...editableNumberColumnProps },
        { Header: 'Option 3', accessor: 'options[2].text', filterable: true, ...editableColumnProps },
        { Header: 'Value 3', accessor: 'options[2].value', filterable: true, ...editableNumberColumnProps },
        { Header: 'Active', accessor: 'active', filterable: true, ...selectableColumnProps },
        {
            Header: 'Created At',
            accessor: 'createdAt',
            filterable: true,
            Cell: row => {
                return <label>{formatDate(row.original.createdAt)}</label>
            }
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const handleSubmit = values => {

        if (editing !== null && deleting === null) {
            const data = values;
            props.updateSurveyQuestion(data, props.token);
        } else if (editing === null && deleting !== null) {
            const data = {
                '_id': values._id
            };

            props.deleteSurveyQuestion(data, props.token);
        }

    }

    const close = () => {
        document.getElementById("addSurveyQuestionForm").reset();
        setQuestion(null);
        setOptionOneText(null);
        setOptionOneNumber(null);
        setOptionTwoText(null);
        setOptionTwoNumber(null);
        setOptionThreeText(null);
        setOptionThreeNumber(null);
    }

    const addSurveyQuestion = () => {
        console.log(question, optionOneNumber, optionOneText, optionTwoNumber, optionTwoText, optionThreeNumber, optionThreeText);
        if (question !== null && question !== ''
            && optionOneText !== null && optionOneText !== ''
            && optionOneNumber !== null && optionOneNumber !== ''
            && optionTwoText !== null && optionTwoText !== ''
            && optionTwoNumber !== null && optionTwoNumber !== ''
            && optionThreeText !== null && optionThreeText !== ''
            && optionThreeNumber !== null && optionThreeNumber !== '') {
            var options = [
                { 'text': optionOneText, 'value': optionOneNumber },
                { 'text': optionTwoText, 'value': optionTwoNumber },
                { 'text': optionThreeText, 'value': optionThreeNumber }
            ];

            const data = {
                'question': question,
                'options': options,
                'active': 'Yes'
            };

            props.addSurveyQuestion(data, props.token)
            close();

        } else {
            toast.error('Please fill all the fields.')
        }
    }

    return (
        <div className="right_col" role="main">
            <ErrorBoundary>
                <div className="card">
                    <div className="card-header">
                        <button
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#addSurveyQuestion"
                            style={{ float: 'left' }}
                            data-backdrop="static" data-keyboard="false"
                            disabled={Permission('BASIC DATA', 'SURVEY QUESTIONS', 'ADD', props)}
                        >+ Survey Question</button>
                    </div>
                    <div className="card-body">
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null) }}
                                initialValues={editing !== null ? editing : deleting}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                sorted={sortOptions}
                                                onSortedChange={val => {
                                                    setSortOptions(val)
                                                }}
                                                columns={columns}
                                                data={props.surveyQuestions}
                                                defaultPageSize={10}
                                                defaultFilterMethod={filterCaseInsensitive}
                                                showPageJump={false}
                                                filterable
                                            />
                                        </form>
                                    )
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </div>
                </div>
            </ErrorBoundary>

            <div id="addSurveyQuestion" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Add Survey Question</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={e => close()}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <React.Fragment>
                                <form id='addSurveyQuestionForm'>
                                    <table style={{ "width": "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td>Question* :</td>
                                                <td><textarea type="text" placeholder='Question...' className="form-control" onChange={e => setQuestion(e.target.value)} ></textarea></td>
                                            </tr>
                                            <tr style={{ "marginTop": '15px' }}>
                                                <td>Options*</td>
                                                <td>
                                                    <input style={{ "width": "48%", "float": "left" }} type="text" placeholder='Text Value' className='form-control' onChange={e => setOptionOneText(e.target.value)} />
                                                    <input style={{ "width": "48%", "float": "right" }} type="number" placeholder='Number Value' className='form-control' onChange={e => setOptionOneNumber(e.target.value)} />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td>
                                                    <input style={{ "width": "48%", "float": "left" }} type="text" placeholder='Text Value' className='form-control' onChange={e => setOptionTwoText(e.target.value)} />
                                                    <input style={{ "width": "48%", "float": "right" }} type="number" placeholder='Number Value' className='form-control' onChange={e => setOptionTwoNumber(e.target.value)} />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td>
                                                    <input style={{ "width": "48%", "float": "left" }} type="text" placeholder='Text Value' className='form-control' onChange={e => setOptionThreeText(e.target.value)} />
                                                    <input style={{ "width": "48%", "float": "right" }} type="number" placeholder='Number Value' className='form-control' onChange={e => setOptionThreeNumber(e.target.value)} />
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </form>
                            </React.Fragment>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={e => addSurveyQuestion()}>Add</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => close()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        surveyQuestions: state.rSurveyQuestions.surveyQuestions,
        token: state.rLogin.token,
        loggedUser: state.rLogin.name,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSurveyQuestionsData: token => { dispatch(getSurveyQuestionsData(token)) },
        addSurveyQuestion: (data, token) => { dispatch(addSurveyQuestion(data, token)) },
        updateSurveyQuestion: (data, token) => { dispatch(updateSurveyQuestion(data, token)) },
        deleteSurveyQuestion: (data, token) => { dispatch(deleteSurveyQuestion(data, token)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestions)
