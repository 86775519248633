import React from "react";
import { useDrop } from "react-dnd";
// import ITEM_TYPE from "../data/types";

// const statuses = [{
//     status: "Delivered",
//     icon: "⭕️",
//     color: "#EB5A46"
// }, {
//     status: "Initiated",
//     icon: "🔆️",
//     color: "#00C2E0"
// }, {
//     status: "in review",
//     icon: "📝",
//     color: "#C377E0"
// }, {
//     status: "done",
//     icon: "✅",
//     color: "#3981DE"
// }];

const DropWrapper = ({ onDrop, children, status }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'item',
        canDrop: (item, monitor) => {
            // const itemIndex = statuses.findIndex(si => si.status === item.orders.status);
            // const statusIndex = statuses.findIndex(si => si.status === status);
            return  true;
        },
        drop: (item, monitor) => {
            onDrop(item, monitor, status);
        },
        collect: monitor => ({
            isOver: monitor.isOver()
        })
    });

    return (
        <div ref={drop} style={{ flex: '1 25%', width: '!00%', height: '100%' }}>
            {React.cloneElement(children, { isOver })}
        </div>
    )
};

export default DropWrapper;