import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash';
import Table from "react-table";
import * as BS from "react-bootstrap";
import { Field } from "redux-form";
import { toast } from "react-toastify";
import $ from 'jquery';
import axios from 'axios';

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from '../../components/ErrorBoundary'
import ActionsCell from "../../components/ActionCell";
import UpdateActionsCell from "../../components/UpdateActionsCell";
import RTSActionCell from '../../components/RTSActionCell';
import GridFilter from '../../components/GridFilter';
import HighlightCell from '../../components/HighlightCell';
import SearchableSelectComp from '../../components/SearchableSelectComp';
import { Permission } from "../../utils";
import ConvertDecimalPlaces from "../../utils/ConvertDecimalPlaces";
import ReactToPrint from "react-to-print";
import ReturnToSupplierPrint from './ReturnToSupplierPrint';

import {
    getReceivedPurchaseOrders,
    getHistoryPurchaseOrders,
    getReturnToSupplierData,
    addReturnToSupplier,
    getReturnToSupplierItemsData,
    updateReturnToSupplier,
    deleteReturnToSupplierItem,
    deleteReturnToSupplier,
    completeReturnToSupplier,
    completeReturnToSupplierHistoryPO
} from '../../../actions/Purchasing/return_to_supplier_actions';

import { getPurchaseItemData } from "../../../actions/Purchasing/purchasing_order_action";
import { getDeliveryWarehousesForCurrentUser } from "../../../actions/Basic_data/delivery_warehouse_actions";

export const ReturnToSupplier = props => {

    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null)
    const [selectedPurchaseOrderHistory, setSelectedPurchaseOrderHistory] = useState(null)
    const [purchaseItemsData, setPurchaseItemsData] = useState([]);
    const [purchaseItemsHistoryData, setPurchaseItemsHistoryData] = useState([]);
    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [currentReturnToSupplier, setCurrentReturnToSupplier] = useState(null);
    const [sortOptions, setSortOptions] = useState([{ id: 'returntosupplier.created_at', desc: true }]);
    const [expanded, setExpanded] = useState({});
    const [active, setActive] = useState(true);
    const [warehouseData, setWarehouse] = useState(null);
    const [stockQty, setStockQty] = useState('');
    const [stockItems, setStockItems] = useState([]);
    const [expireDates, setExpireDates] = useState([]);
    const [receivedQy, setReceivedQty] = useState([]);
    const [purchaseItemId, setPurchaseItemId] = useState([]);
    const [dataForPrint, setDataForPrint] = useState([]);
    const [disableValue, setDisable] = useState(true);
    const [activeStatus, setActiveStatus] = useState(true);

    const componentRef = useRef();

    useEffect(() => {
        props.getReceivedPurchaseOrders(props.token)
        props.getReturnToSupplierData(props.token)
        props.getReturnToSupplierItemsData(props.token)
        props.getHistoryPurchaseOrders(props.token)
        props.getPurchaseItemData(props.token)
        props.getDeliveryWarehousesForCurrentUser(props)
    }, [])

    const reload = () => {
        props.getReceivedPurchaseOrders(props.token)
        props.getReturnToSupplierData(props.token)
        props.getReturnToSupplierItemsData(props.token)
        props.getHistoryPurchaseOrders(props.token)
        props.getPurchaseItemData(props.token)
        props.getDeliveryWarehousesForCurrentUser(props)
    }

    const getItemsActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => {
                    setEditing(null)
                    let warehouse = rowProps.original.purchaseitem !== undefined
                        ? (rowProps.original.purchaseitem.warehouse !== undefined ? rowProps.original.purchaseitem.warehouse : '')
                        : (rowProps.original.purchaseitemhistories.warehouse !== undefined ? rowProps.original.purchaseitemhistories.warehouse : '')

                    if (warehouse === "" && !warehouse) {
                        setStockQty(0)
                    }
                },
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('PURCHASE', 'RETURN TO SUPPLIER', 'UPDATE', props),
            delPerm: Permission('PURCHASE', 'RETURN TO SUPPLIER', 'DELETE', false)
        }) ||
        {};


    const getItemsActionExpIItemsProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => {
                    setEditing(null)
                },
             
            },
            updPerm: Permission('PURCHASE', 'RETURN TO SUPPLIER', 'UPDATE', props),
        }) ||
        {};
    const getReturnToSupplierActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('PURCHASE', 'RETURN TO SUPPLIER', 'UPDATE', false),
            delPerm: Permission('PURCHASE', 'RETURN TO SUPPLIER', 'DELETE', props)
        }) ||
        {};


    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const editableNumberNewColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original;
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            };

            if (props.column.Header === 'Return Quantity') {
                return <Field name={props.column.id} step='0.001' min='0' max={props.original.receiveQty} type="Number" {...fieldProps} />;
            }
        }
    };

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const setActiveHandle = (value) => {
        if (value === true) {
            setPurchaseItemsHistoryData([])
            setSelectedPurchaseOrderHistory([]);
        } else {
            setPurchaseItemsData([])
            setSelectedPurchaseOrder([])
        }

        setActive(value)
    }

    const formatDate = (date) => {
        if (date === null || date === '') {
            return '';
        }
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var hours = d.getHours();
        var minutes = d.getMinutes();
        var seconds = d.getSeconds();

        var formatedDate = [year, month, day].join('-');
        var formatedTime = [hours, minutes, seconds].join(':')

        return [formatedDate, formatedTime].join(' ');
    }

    // has warehouse id
    const hasWarehouseId = (data, warehouse, qty) => {
        for (const item of data) {
            if (String(item.warehouse_ref) === String(warehouse) && item.exp_items.length > 0) {

                let total = item.exp_items.reduce((total, ele) => { return total + Number(ele.qty) }, 0)
                let status = item.exp_items.length > 0 ? true : false
                return {
                    qty: total,
                    status: status,
                    expire_items: item.exp_items,
                };
            }
        }
        return {
            qty: 0,
            status: false,
            expire_items: [],
        };
    };

    const handleSubmit = (value) => {
        if (value.items && value.purchaseitem && !value.returntosupplieritems) { //edit for ADD RETURN TO SUPPLIER item edit
            var index = 0;
            var purchaseItemsDataArray = [...purchaseItemsData];
            var newRetQty = value.purchaseitem.returnedqty;
            var reason = value.purchaseitem.reason;

            if (warehouseData !== undefined && warehouseData !== null && warehouseData !== '') {

                for (var i = 0; i < purchaseItemsDataArray.length; i++) {
                    if (purchaseItemsDataArray[i].purchaseitem._id === value.purchaseitem._id) {
                        index = i;
                    }
                }

                var warehouse = _.filter(props.deliveryWarehouseData, function (o) {
                    return String(o._id) === String(warehouseData)
                })

                let is_warehouse = value.stockitems !== undefined && hasWarehouseId(value.stockitems, warehouseData, value.purchaseitem.returnedqty);

                if (is_warehouse.status) {
                    var item = purchaseItemsDataArray[index];
                    item.purchaseitem.returnedqty = Number(newRetQty);
                    item.purchaseitem.reason = reason;
                    item.stock = stockQty;
                    item.purchaseitem.warehouse = warehouse.length > 0 ? warehouse[0].name : ''
                    item.purchaseitem.warehouseId = warehouse.length > 0 ? warehouse[0]._id : ''
                    purchaseItemsDataArray[index] = item;

                    setPurchaseItemsData(purchaseItemsDataArray)
                } else {
                    // clear submit value from table
                    var item = purchaseItemsDataArray[index];
                    item.purchaseitem.returnedqty = 0;
                    item.purchaseitem.reason = '';
                    item.stock = 0;
                    item.purchaseitem.warehouse = ''
                    item.purchaseitem.warehouseId = ''
                    purchaseItemsDataArray[index] = item;
                    setStockItems([])
                    setExpireDates([])
                    setStockQty('')
                    setPurchaseItemsData(purchaseItemsDataArray)
                    toast.error('Expiry stock not enough')
                }

                setPurchaseItemsData(purchaseItemsDataArray)
            } else {
                toast.error('Please Select an warehouse')
            }
        } else if (value.returntosupplieritems) { //main return to supplier item edit/delete
            if (deleting === null && editing !== null) {

            } else {
                const data = {
                    _id: value.returntosupplieritems._id
                }

                props.deleteReturnToSupplierItem(data, props.token)
            }
        } else if (value.returntosupplier) {

            if (deleting !== null && editing === null) {
                const data = {
                    _id: value.returntosupplier._id
                };

                props.deleteReturnToSupplier(data, props.token);

            }
        } else if (value.purchaseitemhistories) {
            var index = 0;
            var purchaseItemsDataArray = [...purchaseItemsHistoryData];
            var newRetQty = value.purchaseitemhistories.returnedqty;
            var reason = value.purchaseitemhistories.reason;

            if (warehouseData !== undefined && warehouseData !== null && warehouseData !== '') {

                for (var i = 0; i < purchaseItemsDataArray.length; i++) {
                    if (purchaseItemsDataArray[i].purchaseitemhistories._id === value.purchaseitemhistories._id) {
                        index = i;
                    }
                }

                var warehouse = _.filter(props.deliveryWarehouseData, function (o) {
                    return String(o._id) === String(warehouseData)
                })

                let is_warehouse = value.stockitems !== undefined && hasWarehouseId(value.stockitems, warehouseData, value.purchaseitemhistories.returnedqty);

                if (is_warehouse.status) {
                    var item = purchaseItemsDataArray[index];
                    item.purchaseitemhistories.returnedqty = Number(newRetQty);
                    item.purchaseitemhistories.reason = reason;
                    item.stock = stockQty;
                    item.purchaseitemhistories.warehouse = warehouse.length > 0 ? warehouse[0].name : ''
                    item.purchaseitemhistories.warehouseId = warehouse.length > 0 ? warehouse[0]._id : ''
                    purchaseItemsDataArray[index] = item;

                    setPurchaseItemsHistoryData(purchaseItemsDataArray)
                } else {
                    // clear submit value from table
                    var item = purchaseItemsDataArray[index];
                    item.purchaseitemhistories.returnedqty = 0;
                    item.purchaseitemhistories.reason = '';
                    item.stock = 0;
                    item.purchaseitemhistories.warehouse = ''
                    item.purchaseitemhistories.warehouseId = ''
                    purchaseItemsDataArray[index] = item;
                    setPurchaseItemsHistoryData(purchaseItemsDataArray)
                    setStockItems([])
                    setExpireDates([])
                    setStockQty('')
                    toast.error('Expiry stock not enough')
                }
            } else {
                toast.error('Please select the warehouse')
            }
        }
    }

    const hasWarehouseValue = (data, warehouse) => {
        for (const item of data) {
            if (String(item.warehouse_ref) === String(warehouse) && item.exp_items.length > 0) {
                let total = item.exp_items.reduce((total, ele) => { return total + Number(ele.qty) }, 0)
                return {
                    qty: ConvertDecimalPlaces(total),
                    stock_items: item.exp_items,
                    item_data: [item]
                };
            }
        }
        return {
            qty: 0,
            stock_items: [],
            item_data: []
        };
    };

    const setStateForEdit = (column, value, props) => {
        if (column === 'Warehouse') {
            setWarehouse(value)
            let data = hasWarehouseValue(props.stockitems, value)
            setStockQty(data.qty)
            setStockItems(data.item_data)
        }
    }

    const selectableColumnProps = {
        ...GridFilter,
        Cell: Props => {
            const edit = editing === Props.original;

            return (edit ?
                <Field name={Props.column.id} component="select" className='form-control' onChange={e => setStateForEdit(Props.column.Header, e.target.value, Props.original)}>
                    <option value="1">Select an Warehouse</option> {/* Add an empty string option */}
                    {(props.deliveryWarehouseData).map(({ value, label }) =>
                        <option key={value} value={value}>
                            {label}
                        </option>
                    )}
                </Field> : <label>{Props.value}</label>
            );
        }
    };

    const formatDateValue = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    //group the expire dates
    const groupExpireDates = (arr) => {
        const groupedData = {};

        arr.forEach(obj => {
            const { exp_date, qty } = obj;
            const date = new Date(exp_date);
            const formattedDate = formatDateValue(date);

            if (groupedData.hasOwnProperty(formattedDate)) {
                groupedData[formattedDate].qty += qty;
            } else {
                groupedData[formattedDate] = { exp_date: formattedDate, qty };
            }
        });

        const groupedArray = Object.values(groupedData);
        return groupedArray;
    };

    const returnToSupplierColumns = [
        {
            Header: "Order ID",
            Cell: row => {
                return <label>{row.original.purchaseorder.orderid}</label>
            },
            filterable: true
        },
        { Header: "Supplier", accessor: "supplier.name", ...GridFilter },
        { Header: "Completed", accessor: "returntosupplier.completed", ...GridFilter },
        {
            Header: "Created Date",
            accessor: "returntosupplier.created_at",
            Cell: row => {
                return <label>{formatDate(row.original.returntosupplier.created_at)}</label>
            },
            filterable: true
        },
        {
            Header: "",
            Cell: row => {
                {
                    return (
                        <div>
                            {row.original.returntosupplier.completed === "No" ?
                                <button className='btn btn-primary'
                                    onClick={e => {
                                        setCurrentReturnToSupplier(row.original)
                                        reload()
                                    }
                                    }
                                    data-toggle="modal"
                                    data-target="#completeReturnToSupplier"
                                    data-backdrop="static"
                                    data-keyboard="false"
                                >
                                    Complete
                                </button> : <button className='btn btn-primary'
                                    disabled
                                >
                                    <i className='fa fa-check'></i> &nbsp;
                                    Completed
                                </button>
                            }
                            <button
                                className='btn btn-primary'
                                data-toggle="modal"
                                data-target="#printRTS"
                                data-backdrop="static"
                                data-keyboard="false"
                                onClick={e => {

                                    var params = { _id: row.original.returntosupplier.order_ref };

                                    //get purchase order
                                    axios.defaults.headers.common['authorization'] = props.token;
                                    axios.post('/purchasing/getOrderById', params)
                                        .then(result => {
                                            let printData = result.data[0]
                                            if (printData === undefined) {

                                                axios.defaults.headers.common['authorization'] = props.token;
                                                axios.post('/purchasing/getHistoryOrderById', params)
                                                    .then(result => {

                                                        printData = result.data[0]

                                                        var rtsItems = _.filter(props.returnToSupplierItemsData, function (o) {
                                                            return o.returntosupplieritems.ref_returntosupplier === row.original.returntosupplier._id
                                                        })

                                                        let rtsItemsArray = [];

                                                        rtsItems.forEach(rtsItem => {
                                                            let obj = {
                                                                'itemCode': rtsItem.items.desc + '-' + rtsItem.items.itemCode,
                                                                'reutrnQty': rtsItem.returntosupplieritems.qty,
                                                                'price': rtsItem.returntosupplieritems.purchaseprice
                                                            };

                                                            rtsItemsArray.push(obj)

                                                        });

                                                        printData.purchaseItems = rtsItemsArray
                                                        printData.returnToSupplier = row.original.returntosupplier

                                                        setDataForPrint(printData);
                                                    }).catch(err => {
                                                        console.error(err)
                                                        toast.error(`${err}`)
                                                    })

                                            } else {
                                                var rtsItems = _.filter(props.returnToSupplierItemsData, function (o) {
                                                    return o.returntosupplieritems.ref_returntosupplier === row.original.returntosupplier._id
                                                })

                                                let rtsItemsArray = [];

                                                rtsItems.forEach(rtsItem => {
                                                    let obj = {
                                                        'itemCode': rtsItem.items.desc + '-' + rtsItem.items.itemCode,
                                                        'reutrnQty': rtsItem.returntosupplieritems.qty,
                                                        'price': rtsItem.returntosupplieritems.purchaseprice
                                                    };

                                                    rtsItemsArray.push(obj)

                                                });

                                                printData.purchaseItems = rtsItemsArray
                                                printData.returnToSupplier = row.original.returntosupplier

                                                setDataForPrint(printData);
                                            }
                                        }).catch(err => {
                                            console.error(err)
                                            toast.error(`${err}`)
                                        })

                                }
                                }
                            >
                                Print
                            </button>
                        </div>
                    )
                }
            }
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getReturnToSupplierActionProps,
            Cell: RTSActionCell
        }
    ]

    const returnToSupplierItemsColumns = [
        { Header: "Item", accessor: "items.name", ...GridFilter },
        { Header: "Warehouse", accessor: "deliverywarehouse.name", ...GridFilter },
        { Header: "Expiry Stock", accessor: "stock", ...GridFilter, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Qty", accessor: "returntosupplieritems.qty", getProps: () => { return { style: { 'textAlign': 'right' } } }

        },
        { Header: "Purchase Price", accessor: "items.purchasePrice", ...GridFilter, getProps: () => { return { style: { 'textAlign': 'right' } } } },
    ]

    const itemsColumns = [
        { Header: "Item Name", accessor: "items.name", ...GridFilter },
        { Header: "Item Code", accessor: "items.itemCode", ...GridFilter },
        { Header: "Warehouse", accessor: "purchaseitem.warehouse", ...selectableColumnProps, maxWidth: 150, minWidth: 150, },
        {
            Header: "Expiry Stock", accessor: "stock", ...GridFilter,
            Cell: props => {
                if (stockItems.length > 0) {

                    var rtsItems = _.filter(stockItems, function (o) {
                        return String(o.item_ref) === String(props.original.items._id)
                    })
                    return <div style={{ textAlign: 'right' }}>{rtsItems.length > 0 ? stockQty : props.original.stock}</div>
                } else {
                    return <div style={{ textAlign: 'right' }}> {props.original.stock}</div>
                }
            }
        },
        { Header: "Purchase Price", accessor: "items.purchasePrice", ...GridFilter, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Received Qty", accessor: "purchaseitem.recivqty", ...GridFilter, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Return Qty",
            accessor: "purchaseitem.returnedqty",
            Cell: props =>
                <button
                    disabled={props.original.purchaseitem.warehouse !== undefined && props.original.purchaseitem.warehouse !== "" ? false : true}
                    className='btn btn-link  float-right pull-right'

                    onClick={() => {
                        if (props.original.stockitems.length > 0) {

                            let receiveQty = props.original.purchaseitem !== undefined
                                ? props.original.purchaseitem.recivqty
                                : 0

                            var rtsItems = _.filter(props.original.stockitems, function (o) {
                                return String(props.original.purchaseitem.warehouseId) === String(o.warehouse_ref)
                            })

                            if (rtsItems.length > 0) {
                                let groupItems = groupExpireDates(rtsItems[0].exp_items)

                                groupItems.map(val => {
                                    val.returnQty = props.original.purchaseitem.returnedqty
                                    val.itemId = props.original.items._id
                                    val.receiveQty = props.original.purchaseitem.recivqty
                                })
                                setDisable(true)
                                setActiveStatus(false)
                                setExpireDates(props.original.expItemsData !== undefined ? props.original.expItemsData : groupItems)
                                setReceivedQty(props.original.purchaseitem.recivqty)
                                setPurchaseItemId(props.original.purchaseitem._id)
                            }
                        }
                    }}
                >{props.original.purchaseitem.returnedqty}</button>

        },
        { Header: "Reason", accessor: "purchaseitem.reason", ...editableColumnProps, },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getItemsActionProps,
            Cell: ActionsCell
        }
    ];

    const itemsColumnsHistory = [
        { Header: "Item Name", accessor: "items.name", ...GridFilter },
        { Header: "Item Code", accessor: "items.itemCode", ...GridFilter },
        { Header: "Warehouse", accessor: "purchaseitemhistories.warehouse", ...selectableColumnProps, maxWidth: 150, minWidth: 150, },
        {
            Header: "Expiry Stock", accessor: "stock", ...GridFilter,
            Cell: props => {
                if (stockItems.length > 0) {
                    var rtsItems = _.filter(stockItems, function (o) {
                        return String(o.item_ref) === String(props.original.items._id)
                    })
                    return <div style={{ textAlign: 'right' }}>
                        {rtsItems.length > 0 ? stockQty : props.original.stock}
                    </div>
                } else {
                    return <div style={{ textAlign: 'right' }}>
                        {props.original.stock}
                    </div>
                }
            }
        },
        { Header: "Purchase Price", accessor: "items.purchasePrice", ...GridFilter, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Received Qty", accessor: "purchaseitemhistories.recivqty", ...GridFilter, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Return Qty",
            accessor: "purchaseitemhistories.returnedqty",
            Cell: props =>
                <button
                    disabled={props.original.purchaseitemhistories.warehouse !== undefined && props.original.purchaseitemhistories.warehouse !== "" ? false : true}
                    className='btn btn-link  float-right pull-right'

                    onClick={() => {
                        if (props.original.stockitems.length > 0) {

                            let receiveQty = props.original.purchaseitemhistories !== undefined
                                ? props.original.purchaseitemhistories.recivqty
                                : 0

                            var rtsItems = _.filter(props.original.stockitems, function (o) {
                                return String(props.original.purchaseitemhistories.warehouseId) === String(o.warehouse_ref)
                            })

                            if (rtsItems.length > 0) {
                                let groupItems = groupExpireDates(rtsItems[0].exp_items)

                                groupItems.map(val => {
                                    val.returnQty = props.original.purchaseitemhistories.returnedqty
                                    val.itemId = props.original.items._id
                                    val.receiveQty = props.original.purchaseitemhistories.recivqty
                                })

                                setDisable(true)
                                setActiveStatus(false)
                                setExpireDates(props.original.expItemsData !== undefined ? props.original.expItemsData : groupItems)
                                setReceivedQty(props.original.purchaseitemhistories.recivqty)
                                setPurchaseItemId(props.original.purchaseitemhistories._id)
                            }
                        }
                    }}
                >{props.original.purchaseitemhistories.returnedqty}</button>
        },
        { Header: "Reason", accessor: "purchaseitemhistories.reason", ...editableColumnProps, },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getItemsActionProps,
            Cell: ActionsCell
        }
    ];

    const print = () => {

    }

    const addReturnToStock = () => {
        if (active) {
            if (selectedPurchaseOrder && purchaseItemsData.length > 0) {
                var checkReceivedStatus = purchaseItemsData.some(ele => ele.purchaseitem.returnedqty > 0)
                if (checkReceivedStatus) {
                    const data = {
                        purchaseOrder: selectedPurchaseOrder,
                        purchaseItems: purchaseItemsData,
                        history: false
                    };

                    props.addReturnToSupplier(data, props.token)
                    clear();
                } else {
                    toast.error(`Nothing to add.`)
                }
            } else {
                toast.error(`Nothing to add.`)
            }
        } else {
            if (selectedPurchaseOrderHistory && purchaseItemsHistoryData.length > 0) {
                var checkReceivedStatus = purchaseItemsHistoryData.some(ele => ele.purchaseitemhistories.returnedqty > 0)
                if (checkReceivedStatus) {
                    const data = {
                        purchaseOrder: selectedPurchaseOrderHistory,
                        purchaseItems: purchaseItemsHistoryData,
                        history: true
                    };

                    props.addReturnToSupplier(data, props.token)

                    clear();
                } else {
                    toast.error(`Nothing to add.`)
                }

            } else {
                toast.error(`Nothing to add.`)
            }
        }

    }

    const clear = () => {
        setPurchaseItemsData([]);
        setPurchaseItemsHistoryData([]);
        setSelectedPurchaseOrder(null);
        setSelectedPurchaseOrderHistory(null);
        setActiveHandle(true);
        setStockQty("")
        setStockItems([])
        setExpireDates([])
        setActiveStatus(true)
    }

    const setSelectedPurchaseOrderHandle = (value) => {
        setSelectedPurchaseOrder(value);

        const data = {
            purchase_ref: value._id,
        }

        axios.get('/purchasing/getPurchaseItemByPurchaseRef', { headers: { authorization: props.token }, params: data })
            .then(result => {
                setPurchaseItemsData(result.data)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }

    const setSelectedPurchaseOrderHistoryHandle = (value) => {
        setSelectedPurchaseOrderHistory(value);

        const data = {
            purchase_ref: value._id,
        }

        axios.get('/purchasing/getPurchaseItemHistoryByPurchaseRef', { headers: { authorization: props.token }, params: data })
            .then(result => {
                setPurchaseItemsHistoryData(result.data)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }

    const completeReturnToStock = () => {
        if (currentReturnToSupplier) {
            var returnToSupplierItems = _.filter(props.returnToSupplierItemsData, function (o) {
                return o.returntosupplieritems.ref_returntosupplier.toString() === currentReturnToSupplier.returntosupplier._id.toString()
            })

            var checkStockStatus = returnToSupplierItems.every(ele => ele.stock > 0)
            var checkReceivedStatus = returnToSupplierItems.every(ele => ele.purchaseitemhistories !== undefined ? ele.purchaseitemhistories.recivqty : ele.purchaseitem.recivqty >= ele.returntosupplieritems.qty)

            if (checkStockStatus) {
                if (checkReceivedStatus) {
                    currentReturnToSupplier.returntosupplieritems = returnToSupplierItems;
                    currentReturnToSupplier.userId = props.userId

                    if (currentReturnToSupplier.returntosupplier.history_po) {
                        currentReturnToSupplier.purchaseorderhistories = currentReturnToSupplier.purchaseorder
                        props.completeReturnToSupplierHistoryPO(currentReturnToSupplier, props.token);
                    } else {
                        props.completeReturnToSupplier(currentReturnToSupplier, props.token);
                    }
                    clearCompleteModal();
                } else {
                    toast.warn("Please check the received qty ")
                }
            } else {
                toast.warn("Expiry stock not enough")
            }
        } else {
            toast.error("Current supplier id is null refresh the page")
        }
    }

    const clearCompleteModal = () => {
        setCurrentReturnToSupplier(null);
    }

    const closePrintModal = () => {
        setDataForPrint([]);
    }

    const handleSubmitExpDates = (value) => {
        if (Number(value.returnQty) !== "" && Number(value.returnQty) !== null) {
            if (Number(value.qty) >= Number(value.returnQty)) {
                var index = 0;
                var newRetQty = Number(value.returnQty)
                var expireDatesArray = [...expireDates];

                for (var i = 0; i < expireDatesArray.length; i++) {
                    if (formatDateValue(expireDatesArray[i].exp_date) === String(value.exp_date)) {
                        index = i;
                    }
                }

                var item = expireDatesArray[index];
                item.returnQty = Number(newRetQty);
                expireDatesArray[index] = item;

                let newRetQtyValue = expireDatesArray?.reduce((total, item) => { return total + Number(item.returnQty) }, 0)

                if (value.receiveQty >= Number(newRetQtyValue)) {
                    setDisable(false)
                } else {
                    setDisable(true)
                    toast.error("Returns qty should be equal or less than receiving qty")
                }

                setExpireDates(expireDatesArray)
            } else {
                toast.error("stock expiry not enough")
            }
        } else {
            toast.error("Fields are mandatory")
        }
    }

    const expireDatesAddReturnStock = () => {
        var index = 0;

        let newRetQty = expireDates?.reduce((total, item) => { return total + Number(item.returnQty) }, 0)

        var purchaseItemsDataArray = [...purchaseItemsData];

        for (var i = 0; i < purchaseItemsDataArray.length; i++) {
            if (purchaseItemsDataArray[i].purchaseitem._id === purchaseItemId) {
                index = i;
            }
        }

        var item = purchaseItemsDataArray[index];
        item.purchaseitem.returnedqty = Number(newRetQty);
        item.expItemsData = expireDates
        purchaseItemsDataArray[index] = item;

        setPurchaseItemsData(purchaseItemsDataArray)
        setActiveStatus(true)
    }

    const expireDatesAddHistoryReturnStock = () => {
        var index = 0;

        let newRetQty = expireDates?.reduce((total, item) => { return total + Number(item.returnQty) }, 0)

        var purchaseItemsDataArray = [...purchaseItemsHistoryData];

        for (var i = 0; i < purchaseItemsDataArray.length; i++) {
            if (purchaseItemsDataArray[i].purchaseitemhistories._id === purchaseItemId) {
                index = i;
            }
        }

        var item = purchaseItemsDataArray[index];
        item.purchaseitemhistories.returnedqty = Number(newRetQty);
        item.expItemsData = expireDates
        purchaseItemsDataArray[index] = item;

        setPurchaseItemsHistoryData(purchaseItemsDataArray)
        setActiveStatus(true)
    }


    const addReturnStock = () => {
        setActiveStatus(true)
    }
    const ExpireColumns = [
        { Header: "Exp Date", accessor: "exp_date", ...GridFilter },
        { Header: "Quantity", accessor: "qty", ...GridFilter, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Return Quantity", accessor: "returnQty", ...editableNumberNewColumnProps, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getItemsActionExpIItemsProps,
            Cell: UpdateActionsCell
        }
    ];

    return (
        <div className="right_col" role="main" >
            <div className="card">
                <div className='card-header'>
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                        <button className="btn btn-primary" data-toggle="modal" data-target="#addReturnToStock" data-backdrop="static" data-keyboard="false">+ Add</button>
                        <button className="btn btn-primary" onClick={e => reload()}><i className="fa fa-refresh"></i></button>
                    </div>
                </div>
                <div className='card-body'>
                    <React.Fragment>
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                initialValues={editing !== null ? editing : deleting}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table
                                                columns={returnToSupplierColumns}
                                                data={props.returnToSupplierData}
                                                defaultPageSize={10}
                                                sorted={sortOptions}
                                                defaultFilterMethod={filterCaseInsensitive}
                                                expanded={expanded}
                                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                                onSortedChange={val => {
                                                    setSortOptions(val)
                                                }}
                                                SubComponent={row => {
                                                    var subData = _.filter(props.returnToSupplierItemsData, function (o) {
                                                        return o.returntosupplieritems.ref_returntosupplier === row.original.returntosupplier._id
                                                    })

                                                    return (
                                                        <div>
                                                            <React.Fragment>
                                                                <ErrorBoundary>
                                                                    <FormProvider
                                                                        form="inline"
                                                                        onSubmit={handleSubmit}
                                                                        onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                                                        initialValues={editing !== null ? editing : deleting}
                                                                        enableReinitialize
                                                                    >
                                                                        {formProps => {
                                                                            return (
                                                                                <form onSubmit={formProps.handleSubmit}>
                                                                                    <Table className="-striped"
                                                                                        columns={returnToSupplierItemsColumns}
                                                                                        data={subData}
                                                                                        defaultPageSize={10}
                                                                                        defaultFilterMethod={filterCaseInsensitive}
                                                                                        showPageJump={false}
                                                                                    />
                                                                                </form>
                                                                            )
                                                                        }}

                                                                    </FormProvider>
                                                                </ErrorBoundary>
                                                            </React.Fragment>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </form>
                                    )
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </React.Fragment>
                </div>
            </div>

            <div id="addReturnToStock" className="modal fade" role="dialog">
                {activeStatus ?
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Add Return To Supplier</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={e => clear()}>&times;</button>
                            </div>
                            <div className='modal-header'>
                                <span>
                                    <input type="radio" checked={active ? true : false} name='active' onClick={e => setActiveHandle(true)} /> <span style={{ "marginRight": "15px" }}>Active</span>
                                    <input type="radio" checked={active ? false : true} name='active' onClick={e => setActiveHandle(false)} /> <span>History</span>
                                </span>
                            </div>
                            <div className="modal-body">
                                {active ?
                                    <React.Fragment>
                                        <form id='addReturnToStockForm'>
                                            <table style={{ 'width': '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>Select Purchase Order* :</td>
                                                        <td style={{ 'width': '75%' }}>
                                                            <SearchableSelectComp
                                                                options={props.purchaseOrders}
                                                                label={'name'}
                                                                value={selectedPurchaseOrder !== null && selectedPurchaseOrder !== undefined ? selectedPurchaseOrder.value : ''}
                                                                onChange={e => setSelectedPurchaseOrderHandle(e)} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <form id='addReturnToStockFormHistory'>
                                            <table style={{ 'width': '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Select Purchase Order History* : <br />
                                                            <small>Last Three Months Data</small>
                                                        </td>
                                                        <td style={{ 'width': '75%' }}>
                                                            <SearchableSelectComp
                                                                options={props.purchaseOrdersHistory}
                                                                label={'name'}
                                                                value={selectedPurchaseOrderHistory !== null && selectedPurchaseOrderHistory !== undefined ? selectedPurchaseOrderHistory.value : ''}
                                                                onChange={e => setSelectedPurchaseOrderHistoryHandle(e)} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                }

                                {purchaseItemsData.length > 0 ?
                                    <div className="card-body" >
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <FormProvider
                                                    form="inline"
                                                    onSubmit={handleSubmit}
                                                    onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                                    initialValues={editing !== null ? editing : deleting}
                                                    enableReinitialize
                                                >
                                                    {formProps => {
                                                        return (
                                                            <form onSubmit={formProps.handleSubmit}>
                                                                <Table className="-striped"
                                                                    columns={itemsColumns}
                                                                    data={purchaseItemsData}
                                                                    defaultPageSize={10}
                                                                    defaultFilterMethod={filterCaseInsensitive}
                                                                    showPageJump={false}
                                                                />
                                                            </form>
                                                        );
                                                    }}
                                                </FormProvider>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                    </div>
                                    :
                                    <></>
                                }

                                {purchaseItemsHistoryData.length > 0 ?
                                    <div className="card-body">
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <FormProvider
                                                    form="inline"
                                                    onSubmit={handleSubmit}
                                                    onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                                    initialValues={editing !== null ? editing : deleting}
                                                    enableReinitialize
                                                >
                                                    {formProps => {
                                                        return (
                                                            <form onSubmit={formProps.handleSubmit}>
                                                                <Table className="-striped"
                                                                    columns={itemsColumnsHistory}
                                                                    data={purchaseItemsHistoryData}
                                                                    defaultPageSize={10}
                                                                    defaultFilterMethod={filterCaseInsensitive}
                                                                    showPageJump={false}
                                                                />
                                                            </form>
                                                        );
                                                    }}
                                                </FormProvider>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className="modal-footer">
                                <button id="add_stock" type="button" className="btn btn-primary" onClick={e => { addReturnToStock() }}>Add</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => clear()}>Close</button>
                            </div>
                        </div>
                    </div>
                    : <div className="modal-dialog modal-lg">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Expire Dates</h4>
                                <button type="button" className="close" onClick={e => addReturnStock()}>&times;</button>
                            </div>
                            <div className="modal-header">
                                <h6 className="modal-title">Received Qty - {receivedQy}</h6>
                            </div>
                            <div className="card-body">
                                <React.Fragment>
                                    <ErrorBoundary>
                                        <FormProvider
                                            form="inline"
                                            onSubmit={handleSubmitExpDates}
                                            onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                                            initialValues={editing !== null ? editing : deleting}
                                            enableReinitialize
                                        >
                                            {formProps => {
                                                return (
                                                    <form onSubmit={formProps.handleSubmit}>
                                                        <Table className="-striped"
                                                            columns={ExpireColumns}
                                                            data={expireDates}
                                                            defaultPageSize={5}
                                                            defaultFilterMethod={filterCaseInsensitive}
                                                            showPageJump={false}
                                                        />
                                                    </form>
                                                );
                                            }}
                                        </FormProvider>
                                    </ErrorBoundary>
                                </React.Fragment>
                            </div>
                            <div className="modal-footer">
                                <button id="add_stock" type="button" className="btn btn-primary" disabled={disableValue} onClick={e => { active === true ? expireDatesAddReturnStock() : expireDatesAddHistoryReturnStock() }}>Add</button>
                                <button type="button" className="btn btn-secondary" onClick={e => addReturnStock()}>Cancel</button>
                            </div>
                        </div>
                    </div>}
            </div>

            <div id="completeReturnToSupplier" className="modal fade" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Complete Return To Supplier?</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={e => clearCompleteModal()}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <label style={{ "fontSize": "14px" }}>Are you sure you want to complete this?</label>
                        </div>
                        <div className="modal-footer">
                            <button id="add_stock" type="button" className="btn btn-primary" onClick={e => { reload(); completeReturnToStock() }}>Proceed</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => clearCompleteModal()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="printRTS" className="modal fade" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <button className='btn btn-secondary'
                                onClick={() => {
                                    var DocumentContainer = document.getElementById(
                                        "printSection"
                                    );
                                    var WindowObject = window.open(
                                        "",
                                        "PrintWindow",
                                        "toolbars=no,scrollbars=yes,status=no,resizable=yes"
                                    );
                                    WindowObject.document.writeln(
                                        DocumentContainer.innerHTML
                                    );
                                    WindowObject.document.close();
                                    setTimeout(function () {
                                        WindowObject.focus();
                                        WindowObject.print();
                                        WindowObject.close();
                                    }, 1000);
                                }}
                            >
                                PRINT
                            </button>
                            <ReactToPrint
                                content={() => componentRef.current}
                            />
                            <button type="button" className="close" data-dismiss="modal" onClick={e => closePrintModal()}>&times;</button>
                        </div>
                        <div className='modal-body'>
                            <ReturnToSupplierPrint purchaseOrderItems={dataForPrint.purchaseItems} order={dataForPrint.purchaseorder ? dataForPrint.purchaseorder : dataForPrint.purchaseorderhistories} paymentcondition={dataForPrint.paymentcondition} deliverycondition={dataForPrint.deliverycondition} returnToSupplier={dataForPrint.returnToSupplier} supplier={dataForPrint.supplier} ref={componentRef} />
                        </div>
                        {/* <div className="modal-body">
                            <div id="printSection">
                                <html>
                                    <link
                                        rel="stylesheet"
                                        href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"
                                    />
                                    <body className="A5" style={{ fontFamily: "Times New Roman", lineHeight: 1 }}>
                                        <section class="sheet  padding-10mm">
                                            <div className='row'>
                                                <h4>Order Infomation</h4>
                                            </div>
                                            <table className='table table-striped' style={{
                                                width: "100%",
                                                textAlign: "center",
                                                padding: 0,
                                                margin: "auto"
                                            }}>
                                                <thead>
                                                    <tr>
                                                        <th>Order ID</th>
                                                        <th>Supplier</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{dataForPrint?.rts?.purchaseorder ? dataForPrint.rts.purchaseorder.orderid : dataForPrint?.rts?.purchaseorderhistories?.orderid}</td>
                                                        <td>{dataForPrint?.rts?.supplier?.name}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className='row'>
                                                <h4>Order Items Infomation</h4>
                                            </div>
                                            <table className='table table-striped' style={{
                                                width: "100%",
                                                textAlign: "center",
                                                padding: 0,
                                                margin: "auto"
                                            }}>
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Qty</th>
                                                        <th>Purchase Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        dataForPrint?.items?.map(item => {
                                                            return (<tr>
                                                                <td>{item.items.name + ' - ' + item.items.itemCode}</td>
                                                                <td>{item.purchaseitem ? item.purchaseitem.returnqty : item.purchaseitemhistories.returnqty}</td>
                                                                <td>{item.items.purchasePrice}</td>
                                                            </tr>)
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </section>
                                    </body>
                                </html>
                            </div>


                        </div> */}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => closePrintModal()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        userId: state.rLogin.id,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        purchaseOrders: state.rReturnToSupplier.purchaseOrders,
        returnToSupplierData: state.rReturnToSupplier.returnToSupplierData,
        returnToSupplierItemsData: state.rReturnToSupplier.returnToSupplierItemsData,
        purchaseOrdersHistory: state.rReturnToSupplier.purchaseOrdersHistory,
        purchaseItemData: state.rOrder.purchase_item_data,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehousesForUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReceivedPurchaseOrders: (token) => { dispatch(getReceivedPurchaseOrders(token)) },
        getReturnToSupplierData: (token) => { dispatch(getReturnToSupplierData(token)) },
        addReturnToSupplier: (data, token) => { dispatch(addReturnToSupplier(data, token)) },
        getReturnToSupplierItemsData: (token) => { dispatch(getReturnToSupplierItemsData(token)) },
        updateReturnToSupplier: (data, token) => { dispatch(updateReturnToSupplier(data, token)) },
        deleteReturnToSupplierItem: (data, token) => { dispatch(deleteReturnToSupplierItem(data, token)) },
        deleteReturnToSupplier: (data, token) => { dispatch(deleteReturnToSupplier(data, token)) },
        completeReturnToSupplier: (data, token) => { dispatch(completeReturnToSupplier(data, token)) },
        getHistoryPurchaseOrders: (token) => { dispatch(getHistoryPurchaseOrders(token)) },
        completeReturnToSupplierHistoryPO: (data, token) => { dispatch(completeReturnToSupplierHistoryPO(data, token)) },
        getPurchaseItemData: token => { dispatch(getPurchaseItemData(token)) },
        getDeliveryWarehousesForCurrentUser: (token, user) => { dispatch(getDeliveryWarehousesForCurrentUser(token, user)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnToSupplier);


