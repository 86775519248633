import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getItemById } from '../../../../../actions/Basic_data/item_actions';
import SearchableSelectComp from '../../../../components/SearchableSelectComp';
import { toast } from 'react-toastify';


const inputHandler = ({ target: { value } }, label, expStockItem, setExpStockItem) => {
  setExpStockItem({ ...expStockItem, [label]: value })
  // resetValidation(expStockItem)
}

const resetValidation = stockItem => {
  // console.log(stockItem);
  let errMsg = document.getElementById('errorMsg');
  let numStock = document.getElementById('txtStock');
  let dateExp = document.getElementById('dateExpStock');

  errMsg.innerText = '';
  numStock.style.borderColor = null;
  dateExp.style.borderColor = null;

  if (stockItem.warehouse_ref === '' && stockItem.exp_date === '' && Number(stockItem.qty === '' ? 0 : stockItem.qty || 0) === 0) {

    errMsg.innerText = 'Enter correct warehouse, exp_date & quantity...!';
    numStock.style.borderColor = 'red';
    dateExp.style.borderColor = 'red';
  } else if (stockItem.warehouse_ref === '' && stockItem.exp_date === '') {

    errMsg.innerText = 'Enter correct warehouse & exp_date...!';
    dateExp.style.borderColor = 'red';
  } else if (stockItem.exp_date === '' && Number(stockItem.qty === '' ? 0 : stockItem.qty || 0) === 0) {

    errMsg.innerText = 'Enter correct quantity...!';
    numStock.style.borderColor = 'red';
    dateExp.style.borderColor = 'red';
  } else if (stockItem.warehouse_ref === '') errMsg.innerText = 'Enter correct warehouse...!';
  else if (stockItem.exp_date === '') {
    dateExp.style.borderColor = 'red';
    errMsg.innerText = 'Enter correct exp_date...!';
  }
  else if (Number(stockItem.qty === '' ? 0 : stockItem.qty || 0) === 0) {
    errMsg.innerText = 'Enter correct quantity...!';
    numStock.style.borderColor = 'red';
  } else {
    errMsg.innerText = ''
    numStock.style.borderColor = null;
    dateExp.style.borderColor = null;
  }
}

const addHandler = (rowInfo, expStockItem, { resetHandler, setItemData, setExpStockItem, setShowHandler, setDisable }, { user, token, userId, getStockItems }) => {
  if (expStockItem.warehouse_ref !== '' && expStockItem.exp_date !== '' && Number(expStockItem.qty === '' ? 0 : expStockItem.qty || 0) !== 0) {
    // console.log(expStockItem);
    axios.defaults.headers.common['authorization'] = token;
    axios.post('/stock/addExpDateStock', { data: { ...expStockItem, item: rowInfo.items._id, stock: rowInfo.stocks._id, user: user, userId: userId } })
      .then(result => {
        console.log(result);
        resetHandler(setItemData, setExpStockItem, setShowHandler)
        getStockItems(token)
      }).catch(err => {
        console.error(err);
        toast.error(err.msg)
      })
    document.getElementById('btnAddExp').disabled = true
  } else resetValidation(expStockItem)
}

const stockValidation = (e, { expStockItem, setExpStockItem }, itemData, inputHandler) => {
  document.getElementById('btnAddExp').disabled = false
  inputHandler(e, 'qty', expStockItem, setExpStockItem)
}

const StockExpAdd = ({ rowInfo, showHandler, resetHandler, setShowHandler, props, disabled }) => {

  const [expStockItem, setExpStockItem] = useState({ warehouse_ref: '', exp_date: '', qty: 0, isLot: false });
  const [itemData, setItemData] = useState({});
  const [disableData, setDisable] = useState(disabled);

  const initRender = useRef(true);

  useEffect(() => {
    // if (showHandler.add) 
    getItemById(rowInfo.original._id, setItemData, props)
  }, [showHandler.add]);

  useEffect(() => {
    if (initRender.current) initRender.current = false;
    else resetValidation(expStockItem)
  }, [expStockItem])



  return <div className='card card-body'>
    <table>
      <tbody>
        <tr>
          <td>Delivery Warehouse :</td>
          <td>
            <SearchableSelectComp options={props.deliveryWarehouseData} value={expStockItem.warehouse_ref} label={'name'} onChange={e => inputHandler({ target: { value: e._id } }, 'warehouse_ref', expStockItem, setExpStockItem)} />
          </td>
          <td>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {itemData?.items?.name} - {itemData?.items?.itemCode}
            </div>
          </td>
          <td>
            {itemData?.stocks?.stock}
          </td>
          <td>
            {itemData?.qty}
          </td>
        </tr>
        <tr>
          <td>Exp. Date :</td>
          <td>
            <input id='dateExpStock' type='date' className='form-control' onChange={e => inputHandler(e, 'exp_date', expStockItem, setExpStockItem)} />
          </td>
          <td style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div className="form-check">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!expStockItem.isLot} onChange={() => setExpStockItem({ ...expStockItem, isLot: false })} />
              <label className="form-check-label" for="flexRadioDefault1">
                Stock as a qty
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={expStockItem.isLot} onChange={() => setExpStockItem({ ...expStockItem, isLot: true })} />
              <label className="form-check-label" for="flexRadioDefault2">
                Stock as a lot
              </label>
            </div>
          </td>
        </tr>
        <tr>
          {expStockItem.isLot ?
            <>
              <td>Lot Qty :</td>
              <td>
                <div style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
                  <input id='txtStock' type='number' className='form-control' onChange={e => stockValidation(e, { expStockItem, setExpStockItem }, itemData, inputHandler)} />
                  {itemData?.items?.unit}
                </div>
              </td>
              {/* <td> {itemData?.items?.unit}</td> */}
            </>
            : <>
              <td>Qty :</td>
              <td>
                <div style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
                  <input id='txtStock' type='number' className='form-control' onChange={e => stockValidation(e, { expStockItem, setExpStockItem }, itemData, inputHandler)} />
                  x {itemData?.measurement?.note}
                </div>
              </td>
              {/* <td> x {itemData?.measurement?.note}</td> */}
            </>}
          <td>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <p id='errorMsg' style={{ color: 'red' }}></p>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <div style={{ float: 'right', paddingTop: '10px' }}>
              <button id='btnAddExp' type="button" className="btn btn-primary" disabled={disableData}
                onClick={() => addHandler(itemData, expStockItem, { resetHandler, setItemData, setExpStockItem, setShowHandler, setDisable }, props)}
              >Save</button>
              {/* <button type="button" className="btn btn-primary" onClick={() => console.log(itemData)}>Save</button> */}
              <button type="button" className="btn btn-info"
                onClick={() => resetHandler(setItemData, setExpStockItem, setShowHandler)}
              >Close</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>;
};

export default StockExpAdd;
