/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";

import axios from 'axios';

import { toast } from "react-toastify";
import _ from 'lodash';

import $ from 'jquery';

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import DeleteActionCell from "../../components/deleteActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';
import SearchableSelectComp from '../../components/SearchableSelectComp'
import { DateNumberFormat } from "../../utils";
import StockRequestPrint from "./StockRequestPrint";

import {
    getStockRequestData,
    getWarehouseData,
    addMoveStockRequest,
    getStockRequestItemData,
    updateMoveStockRequest,
    deleteMoveStockRequest,
    updateMoveStockRequestItem,
    deleteMoveStockRequestItem,
    approveStockRequest
} from "../../../actions/Stock/move_stock_actions";

const ReactTableFixedColumns = withFixedColumns(Table);


class MoveStock extends React.Component {

    state = {
        stockRequestDataActive: [],
        stockRequestDataCompleted: [],
        stockRequestItemData: [],
        selectedStockItems: [],
        checkedStockItems: [],
        warehouseData: [],
        totalRequestedQty: 0,
        updateExpStock: [],
        items: [],
        stockItems: [],
        fromWarehouseAdd: null,
        toWarehouseAdd: null,
        fromWarehouse: null,
        toWarehouse: null,
        selectedItems: [],
        description: null,
        active: true,
        expandedActive: {},
        expandedCompleted: {},
        printData: {},
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'createdAt', desc: false }],
    };

    componentDidMount() {
        this.props.getStockRequestData(this.props.token)
        this.props.getWarehouseData(this.props.token)
        this.props.getStockRequestItemData(this.props.token)
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            if (props.column.Header === 'Recieved Qty') {
                return <Field name={props.column.id} max={props.original.stockrequestitem.requestedqty} type="Number" {...fieldProps} />;
            } else if (props.column.Header === 'Selected Qty') {
                if (this.state.checkedStockItems.includes(props.original)) {
                    return <Field name={props.column.id} max={props.original.totalVal} type="Number" {...fieldProps} />;
                } else {
                    return <Field disabled name={props.column.id} max={props.original.totalVal} type="Number" {...fieldProps} />;
                }

            }
            else {
                return <Field name={props.column.id} type="Number" {...fieldProps} />;
            }

        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };


    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    this.props.warehouseData).map(data =>
                        <option key={data._id} value={data._id}>{data.name}</option>
                    )}
            </Field> : <label>{props.value}</label>)
        }
    };

    renderDateTimePicker = ({ input, editing, value, props, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        return editing && <Component type="date" {...input} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    }

    selectableDateColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDateTimePicker,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} {...fieldProps} /> : <label>{DateNumberFormat(props.value)}</label>)
        }
    }


    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        status: rowProps.original.status,
                        toWarehouse: rowProps.original.stockrequest?.towarehouse,
                        fromWarehouse: rowProps.original.stockrequest?.fromwarehouse,
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('STOCK', 'MOVE STOCK', 'UPDATE', this.props),
            delPerm: Permission('STOCK', 'MOVE STOCK', 'DELETE', this.props)
        }) ||
        {};

    getActionPropsSES = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('STOCK', 'SELECT EXP STOCK', 'UPDATE', this.props),
            delPerm: Permission('STOCK', 'SELECT EXP STOCK', 'DELETE', false)
        }) ||
        {};

    getActionPropsSpecific = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            delPerm: Permission('STOCK', 'SELECT EXP STOCK', 'DELETE', this.props)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === 'Warehouse From') {
            this.setState({ fromWarehouse: value })
        } else if (column === 'Warehouse To') {
            this.setState({ toWarehouse: value })
        }
    }

    reload = () => {
        this.props.getStockRequestData(this.props.token)
        this.props.getStockRequestItemData(this.props.token)
    }

    handleSubmit = values => {
        if (values.itemCode) { //items in add stock request
            if (this.state.deleting === null && this.state.editing !== null) {

                var index = 0;
                var selectedItemsArray = this.state.selectedItems;
                var newQty = values.requestedQty;

                for (var i = 0; i < selectedItemsArray.length; i++) {
                    if (this.state.selectedItems[i]._id === values._id) {
                        index = i;
                    }
                }

                var item = this.state.selectedItems[index];
                item.requestedQty = Number(newQty);
                selectedItemsArray[index] = item;

                this.setState({ selectedItems: selectedItemsArray })

            } else if (this.state.deleting !== null && this.state.editing === null) {
                var index = 0;
                var selectedItemsArray = this.state.selectedItems;

                for (var i = 0; i < selectedItemsArray.length; i++) {
                    if (this.state.selectedItems[i]._id === values._id) {
                        index = i;
                    }
                }

                selectedItemsArray.splice(index, 1)

                this.setState({ selectedItems: selectedItemsArray })
            }

        } else if (values.stockrequest) { //stock request

            if (this.state.deleting === null && this.state.editing !== null) {

                const data = {
                    _id: values.stockrequest._id,
                    description: values.stockrequest.description,
                    fromwarehouse: this.state.fromWarehouse,
                    towarehouse: this.state.toWarehouse,
                }

                this.props.updateMoveStockRequest(this.props.token, data);

                this.setState({
                    fromwarehouse: null,
                    toWarehouse: null
                })

            } else if (this.state.deleting !== null && this.state.editing === null) {

                const data = {
                    _id: values.stockrequest._id
                }

                this.props.deleteMoveStockRequest(this.props.token, data);
            }
        } else if (values.stockrequestitem) { //stock request item

            if (this.state.deleting === null && this.state.editing !== null) {

                const data = {
                    _id: values.stockrequestitem._id,
                    requestedqty: values.stockrequestitem.requestedqty,
                    receivedqty: values.stockrequestitem.receivedqty
                }

                this.props.updateMoveStockRequestItem(this.props.token, data);

            } else if (this.state.deleting !== null && this.state.editing === null) {

                const data = {
                    _id: values.stockrequestitem._id
                }

                this.props.deleteMoveStockRequestItem(this.props.token, data)

            }

        } else if (values.item_id) { //exp stock modal
            if (this.state.deleting === null && this.state.editing !== null) {

                var index = 0;
                var selectedStockItemsArray = [...this.state.selectedStockItems];
                var newQty = values.selectedQty;

                for (var i = 0; i < selectedStockItemsArray.length; i++) {
                    if (selectedStockItemsArray[i]._id === values._id) {
                        index = i;
                    }
                }

                var item = selectedStockItemsArray[index];
                item.selectedQty = Number(newQty);
                selectedStockItemsArray[index] = item;

                this.setState({ selectedStockItems: selectedStockItemsArray })
            }
        }
    };

    handleSubmitApplyValue = (value) =>{
        let arr = this.state.selectedItems.filter(item => String(item._id) !== String(value._id));
        let checkedArr = this.state.checkedStockItems.filter(item => String(item.item_id) !== String(value._id));
        let total = arr.reduce((total, ele) => { return total + Number(ele.requestedQty) }, 0)

        this.setState({
            selectedItems: arr,
            totalRequestedQty: Number(total).toFixed(1),
            checkedStockItems: checkedArr
        })
    }

    addStockRequestHandle = () => {
        var { fromWarehouseAdd, toWarehouseAdd } = this.state;

        var selectedItemsArray = [];

        var flag = false;

        this.state.selectedItems.map(item => {
            var obj = {
                itemId: item._id,
                requestedQty: item.requestedQty
            }

            var checkedArr = _.filter(this.state.checkedStockItems, function (csi) {
                return csi.item_id === item._id
            })

            obj.checkedExpStock = checkedArr;

            var total = 0;

            checkedArr.forEach(ele => {
                total = total + ele.totalVal;
            })

            if (Number(total) < Number(item.requestedQty)) {
                flag = true;
            }

            if (!flag && checkedArr.length > 0) {
                selectedItemsArray.push(obj)
            }
        })

        if (flag) {
            toast.error('Requested qty is greater than the total qty.')
            return
        }

        if (selectedItemsArray.length > 0 && fromWarehouseAdd !== null && toWarehouseAdd !== null) {
            const data = {
                fromWarehouse: fromWarehouseAdd,
                toWarehouse: toWarehouseAdd,
                user: this.props.userId,
                description: this.state.description,
                selectedItems: selectedItemsArray
            }

            this.props.addMoveStockRequest(this.props.token, data);
            this.clear();

        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    clear = () => {
        document.getElementById("addStockRequestForm").reset();

        $('.stockExp').prop('checked', false)

        this.setState({
            fromWarehouseAdd: null,
            toWarehouseAdd: null,
            selectedItems: [],
            description: null,
            checkedStockItems: [],
            selectedStockItems: [],
            totalRequestedQty: 0,
            items: []
        })
    }

    handleRowExpandedActive = (rowsState, index) => {
        this.setState({
            expandedActive: {
                [index[0]]: !this.state.expandedActive[index[0]],
            },
        });
    }

    handleRowExpandedCompleted = (rowsState, index) => {
        this.setState({
            expandedCompleted: {
                [index[0]]: !this.state.expandedCompleted[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    formatDate = (date) => {
        if (date === null || date === '') {
            return '';
        }
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    setFromWareHouse = (value) => {
        this.setState({
            selectedItems: [],
            selectedStockItems: [],
            totalRequestedQty: 0,
            checkedStockItems: [],
            fromWarehouseAdd: value
        })

        const data = {
            _id: value
        }

        axios.defaults.headers.common['authorization'] = this.props.token;
        axios.post('/move_stock/getStockItems', { data })
            .then(result => {

                var matchedItems = []
                result.data.map(value => {
                    if (value.stockitems.exp_items !== undefined && value.stockitems.exp_items.length > 0) {
                        matchedItems.push(value)
                    }
                })

                var items = matchedItems.map(element => {
                    var obj = {
                        _id: element.items._id,
                        displayName: element.items.name + '-' + element.items.itemCode,
                        name: element.items.name,
                        itemCode: element.items.itemCode
                    }
                    return obj;
                })

                this.setState({
                    stockItems: result.data,
                    items: items
                })
            }).catch(err => {
                toast.error(`${err}`)
            })

    }

    activeStockRequestColumns = [
        { Header: "Request Number", accessor: "stockrequest.requestnumber", filterable: true },
        { Header: "Description", accessor: "stockrequest.description", ...this.editableColumnProps },
        { Header: "Warehouse From", accessor: "fromwarehouse.name", ...this.selectableColumnProps },
        { Header: "Warehouse To", accessor: "towarehouse.name", ...this.selectableColumnProps },
        {
            Header: "Date Requested",
            accessor: "stockrequest.requestdate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequest.requestdate)}</label>
            }
        },
        { Header: "User Requested", accessor: "userrequested.name.first", filterable: true },
        {
            Header: "Date Recieved",
            accessor: "stockrequest.recieveddate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequest.recieveddate)}</label>
            }
        },
        {
            Header: "Approve",
            Cell: row =>
                <>
                    <button
                        disabled={Permission('STOCK', 'STOCK MOVING APPROVED', 'VIEW', this.props)}
                        className='btn btn-primary'
                        style={{ "float": "center" }}
                        onClick={() => {
                            if (window.confirm('Are you sure you wish to approve this move stock request?')) {
                                const data = {
                                    _id: row.original.stockrequest._id,
                                    user: this.props.userId
                                }

                                this.props.approveStockRequest(this.props.token, data);
                            }
                        }}
                    >Approve</button>
                </>
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        }
    ];

    subColumnsActive = [
        { Header: "Item Code", accessor: "items.itemCode", filterable: true },
        { Header: "Name", accessor: "items.name", filterable: true },
        { Header: "Requested Qty", accessor: "stockrequestitem.requestedqty", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Recieved Qty", accessor: "stockrequestitem.receivedqty", ...this.editableNumberColumnProps, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        }
    ];

    subColumnsCompleted = [
        { Header: "Item Code", accessor: "items.itemCode", filterable: true },
        { Header: "Name", accessor: "items.name", filterable: true },
        { Header: "Requested Qty", accessor: "stockrequestitem.requestedqty", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        { Header: "Recieved Qty", accessor: "stockrequestitem.receivedqty", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } }
    ];

    completedStockRequestColumns = [
        { Header: "Request Number", accessor: "stockrequest.requestnumber", ...this.editableColumnProps },
        { Header: "Description", accessor: "stockrequest.description", ...this.editableColumnProps },
        { Header: "Warehouse From", accessor: "fromwarehouse.name", ...this.editableColumnProps },
        { Header: "Warehouse To", accessor: "towarehouse.name", ...this.editableColumnProps },
        {
            Header: "Date Requested",
            accessor: "stockrequest.requestdate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequest.requestdate)}</label>
            }
        },
        { Header: "User Requested", accessor: "userrequested.name.first", ...this.editableColumnProps },
        {
            Header: "Date Recieved",
            accessor: "stockrequest.recieveddate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequest.recieveddate)}</label>
            }
        },
        {
            Header: "Date Approved",
            accessor: "stockrequest.approvedate",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.stockrequest.approvedate)}</label>
            }
        },
        { Header: "User Approved", accessor: "userapproved.name.first", ...this.editableColumnProps },
        {
            Header: "Print",
            Cell: row =>
                <>
                    <button
                        className='btn btn-primary'
                        style={{ "float": "center" }}
                        data-toggle='modal'
                        data-target='#stockRequestPopup'
                        onClick={e => {

                            var subData = _.filter(this.props.stockRequestItemData, function (o) {
                                return o.stockrequestitem.refstockrequest === row.original.stockrequest._id
                            })

                            const printData = {
                                'request': row.original,
                                'items': subData
                            };

                            this.setState({
                                printData: printData
                            })
                        }}
                    >Print</button>
                </>
        },
    ];

    itemsColumns = [
        { Header: "Item Code", accessor: "itemCode", filterable: true },
        { Header: "Item Name", accessor: "name", filterable: true },
        { Header: "Requested Qty", accessor: "requestedQty", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 80,
            minWidth: 80,
            filterable: false,
            getProps: this.getActionPropsSpecific,
            Cell: DeleteActionCell
        },
    ];

    expSotckColumns = [
        {
            Header: "Select",
            Cell: row => (
                <div>
                    <input type="checkbox" className="stockExp" id={'stockExp-' + row.original._id} value={row.original} style={{ width: '100%' }} onChange={(e) => { this.setExpStockCheck(row.original) }} />
                </div>
            )
        },
        {
            Header: "Exp Date",
            accessor: "exp_date",
            filterable: true,
            Cell: row => {
                return <label>{this.formatDate(row.original.exp_date)}</label>
            }
        },
        { Header: "Qty", accessor: "totalVal", filterable: true, getProps: () => { return { style: { 'textAlign': 'right' } } } },
        {
            Header: "Selected Qty",
            accessor: "selectedQty",
            ...this.editableNumberColumnProps,
            getProps: () => { return { style: { 'textAlign': 'right' } } }
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionPropsSES,
            Cell: ActionsCell
        },
    ];

    addSelectedItem = (e) => {
        e.requestedQty = 0;
        var stockItem = _.filter(this.state.stockItems, function (sItem) {
            return sItem.stockitems.item_ref === e._id
        })

        stockItem = stockItem[0].stockitems;

        if (stockItem.exp_items.length > 0) {

            var unique = [...new Map(stockItem.exp_items.map(item => [`${item['exp_date']}`, item])).values()]

            let arr = unique?.map(ele => {
                ele.item_id = stockItem.item_ref;
                let filterArr = stockItem.exp_items.filter(obj => {
                    return ele.name === obj.name && ele.exp_date === obj.exp_date
                })
                let ids = filterArr?.map(obj => obj._id)
                let value = filterArr?.reduce((total, obj) => { return total + obj.qty }, 0)

                return ({ ...ele, totalVal: value, selectedQty: value, ids: ids })
            })

            if (!this.state.selectedItems.includes(e)) {
                document.getElementById('expStock').style.display = 'block';
                this.setState({
                    selectedItems: [...this.state.selectedItems, e],
                    selectedStockItems: arr
                })
            } else {
                toast.info('Item already added.')
            }



        } else {
            toast.error('No exp items to move.')
        }
    }

    closeExpStockModal = () => {

        var totalRequestedQty = 0;

        this.state.checkedStockItems.map(sItem => {
            totalRequestedQty = Number(totalRequestedQty) + Number(sItem.selectedQty)
        })

        var theIndex = 0;

        this.state.selectedItems.map((sItem, index) => {
            var total = 0;
            this.state.checkedStockItems.map(csItem => {
                if (sItem._id.toString() === csItem.item_id.toString()) {
                    total = total + csItem.selectedQty;
                }
            })

            this.state.selectedItems[index].requestedQty = total;

            var copy = [...this.state.selectedItems];
            var item = copy[index];

            item.requestedQty = total;

            copy[index] = item;

            this.setState({ selectedItems: copy })

        })

        this.setState({
            totalRequestedQty: Number(totalRequestedQty).toFixed(1)
        })

        $('.stockExp').prop('checked', false)
        document.getElementById('expStock').style.display = 'none';
    }

    setExpStockCheck = (value) => {
        if ($('#stockExp-' + value._id).is(':checked')) {
            this.state.checkedStockItems.push(value);
        } else {
            for (var i = 0; i < this.state.checkedStockItems.length; i++) {
                if (this.state.checkedStockItems[i]._id === value._id) {
                    this.state.checkedStockItems.splice(i, 1);
                }
            }
        }
    }

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6">
                                <input type='radio' name='active' style={{ marginRight: '5px' }} checked={this.state.active ? true : false} onChange={e => { this.setState({ active: true }) }} /><label>Active</label>
                                <input type='radio' name='active' style={{ marginLeft: '30px', marginRight: '5px' }} checked={this.state.active ? false : true} onChange={e => { this.setState({ active: false }) }} /><label>Completed</label>
                            </div>
                            <div className="col-md-6" style={{ textAlign: 'right' }}>
                                <button className="btn btn-primary" disabled={Permission('STOCK', 'MOVE STOCK', 'ADD', this.props)} data-toggle="modal" data-target="#addStockRequest">+ Stock Request</button>
                                <button className="btn btn-primary" disabled={Permission('STOCK', 'MOVE STOCK', 'VIEW', this.props)} onClick={e => this.reload()}><i className="fa fa-refresh"></i></button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            this.state.active ?
                                <div className="card-body">
                                    <ErrorBoundary>
                                        <FormProvider
                                            form="inline"
                                            onSubmit={this.handleSubmit}
                                            onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                            initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                            enableReinitialize
                                        >
                                            {formProps => {
                                                return (
                                                    <form onSubmit={formProps.handleSubmit}>
                                                        <Table className="-striped"
                                                            sorted={this.state.sortOptions}
                                                            onSortedChange={val => {
                                                                this.setState({ sortOptions: val })
                                                            }}
                                                            columns={this.activeStockRequestColumns}
                                                            data={this.props.stockRequestDataActive}
                                                            defaultPageSize={10}
                                                            defaultFilterMethod={this.filterCaseInsensitive}
                                                            showPageJump={false}
                                                            expanded={this.state.expandedActive}
                                                            onExpandedChange={(newExpanded, index, event) => this.handleRowExpandedActive(newExpanded, index)}
                                                            SubComponent={row => {
                                                                var subData = _.filter(this.props.stockRequestItemData, function (o) {
                                                                    return o.stockrequestitem.refstockrequest === row.original.stockrequest._id
                                                                })

                                                                return (
                                                                    <div>
                                                                        <React.Fragment>
                                                                            <ErrorBoundary>
                                                                                <FormProvider
                                                                                    form="inline"
                                                                                    onSubmit={this.handleSubmit}
                                                                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                                                    enableReinitialize
                                                                                >
                                                                                    {formProps => {
                                                                                        return (
                                                                                            <form onSubmit={formProps.handleSubmit}>
                                                                                                <Table className="-striped"
                                                                                                    columns={this.subColumnsActive}
                                                                                                    data={subData}
                                                                                                    defaultPageSize={10}
                                                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                                                    showPageJump={false}
                                                                                                />
                                                                                            </form>
                                                                                        )
                                                                                    }}

                                                                                </FormProvider>
                                                                            </ErrorBoundary>
                                                                        </React.Fragment>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </form>
                                                );
                                            }}
                                        </FormProvider>
                                    </ErrorBoundary>
                                </div>
                                :
                                <div className="card-body">
                                    <React.Fragment>
                                        <ErrorBoundary>
                                            <FormProvider
                                                form="inline"
                                                onSubmit={this.handleSubmit}
                                                onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                enableReinitialize
                                            >
                                                {formProps => {
                                                    return (
                                                        <form onSubmit={formProps.handleSubmit}>
                                                            <Table className="-striped"
                                                                sorted={this.state.sortOptions}
                                                                onSortedChange={val => {
                                                                    this.setState({ sortOptions: val })
                                                                }}
                                                                columns={this.completedStockRequestColumns}
                                                                data={this.props.stockRequestDataCompleted}
                                                                defaultPageSize={10}
                                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                                showPageJump={false}
                                                                expanded={this.state.expandedCompleted}
                                                                onExpandedChange={(newExpanded, index, event) => this.handleRowExpandedCompleted(newExpanded, index)}
                                                                SubComponent={row => {
                                                                    var subData = _.filter(this.props.stockRequestItemData, function (o) {
                                                                        return o.stockrequestitem.refstockrequest === row.original.stockrequest._id
                                                                    })

                                                                    return (
                                                                        <div>
                                                                            <React.Fragment>
                                                                                <ErrorBoundary>
                                                                                    <FormProvider
                                                                                        form="inline"
                                                                                        onSubmit={this.handleSubmit}
                                                                                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                                                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                                                        enableReinitialize
                                                                                    >
                                                                                        {formProps => {
                                                                                            return (
                                                                                                <form onSubmit={formProps.handleSubmit}>
                                                                                                    <Table className="-striped"
                                                                                                        columns={this.subColumnsCompleted}
                                                                                                        data={subData}
                                                                                                        defaultPageSize={10}
                                                                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                                                                        showPageJump={false}
                                                                                                    />
                                                                                                </form>
                                                                                            )
                                                                                        }}

                                                                                    </FormProvider>
                                                                                </ErrorBoundary>
                                                                            </React.Fragment>
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                        </form>
                                                    );
                                                }}
                                            </FormProvider>
                                        </ErrorBoundary>
                                    </React.Fragment>
                                </div>
                        }

                        <div id="addStockRequest" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Add Stock Request</h4>
                                        <button type="button" className="close" data-dismiss="modal" onClick={e => this.clear()}>&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <React.Fragment>
                                            <form id='addStockRequestForm'>
                                                <table style={{ 'width': '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>From Warehouse* :</td>
                                                            <td>
                                                                <select className="form-control" onChange={e => e.target.value === '' ? null : this.setFromWareHouse(e.target.value)}>
                                                                    <option value={''}>- Select From Warehouse -</option>
                                                                    {this.props.warehouseData.map(element => {
                                                                        return <option value={element._id}>{element.name}</option>
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>To Warehouse* :</td>
                                                            <td>
                                                                <select className="form-control" onChange={e => e.target.value === '' ? null : this.setState({ toWarehouseAdd: e.target.value })}>
                                                                    <option value={''}>- Select To Warehouse -</option>
                                                                    {this.props.warehouseData.map(element => {
                                                                        return <option value={element._id}>{element.name}</option>
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Description :</td>
                                                            <td>
                                                                <textarea className="form-control" onChange={e => this.setState({ description: e.target.value })}></textarea>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Select Item :</td>
                                                            <td>
                                                                <SearchableSelectComp
                                                                    options={this.state.items}
                                                                    label={'displayName'}
                                                                    onChange={e => this.addSelectedItem(e)} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Requested Qty :</td>
                                                            <td>
                                                                <input type="text" className="form-control" disabled={true} value={this.state.totalRequestedQty} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                        </React.Fragment>

                                        {this.state.selectedItems.length > 0 ?
                                            <div className="card-body">
                                                <React.Fragment>
                                                    <ErrorBoundary>
                                                        <FormProvider
                                                            form="inline"
                                                            onSubmit={this.handleSubmitApplyValue}
                                                            onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                            initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                            enableReinitialize
                                                        >
                                                            {formProps => {
                                                                return (
                                                                    <form onSubmit={formProps.handleSubmit}>
                                                                        <Table className="-striped"
                                                                            columns={this.itemsColumns}
                                                                            data={this.state.selectedItems}
                                                                            defaultPageSize={10}
                                                                            defaultFilterMethod={this.filterCaseInsensitive}
                                                                            showPageJump={false}
                                                                        />
                                                                    </form>
                                                                );
                                                            }}
                                                        </FormProvider>
                                                    </ErrorBoundary>
                                                </React.Fragment>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    <div className="modal-footer">
                                        <button id="add_stock" type="button" className="btn btn-primary" onClick={e => { this.addStockRequestHandle() }}>Add</button>
                                        {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.clear()}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div id="expStock" className="modal" role="dialog">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Select Expiry Stock</h4>
                                        <button type="button" className="close" data-dismiss="modal" onClick={e => this.closeExpStockModal()}>&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        {this.state.selectedStockItems.length > 0 ?
                                            <div className="card-body">
                                                <React.Fragment>
                                                    <ErrorBoundary>
                                                        <FormProvider
                                                            form="inline"
                                                            onSubmit={this.handleSubmit}
                                                            onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                            initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                        >
                                                            {formProps => {
                                                                return (
                                                                    <form onSubmit={formProps.handleSubmit}>
                                                                        <Table className="-striped"
                                                                            columns={this.expSotckColumns}
                                                                            data={this.state.selectedStockItems}
                                                                            defaultPageSize={10}
                                                                            defaultFilterMethod={this.filterCaseInsensitive}
                                                                            showPageJump={false}
                                                                        />
                                                                    </form>
                                                                );
                                                            }}
                                                        </FormProvider>
                                                    </ErrorBoundary>
                                                </React.Fragment>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={Permission('STOCK', 'SELECT EXP STOCK', 'ADD', this.props)} onClick={e => this.closeExpStockModal()}>Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ErrorBoundary>
                            <div id="stockRequestPopup" className="modal fade" role="dialog">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content animate">
                                        <div className="modal-header">
                                            <button
                                                className='btn btn-secondary'
                                                onClick={() => {
                                                    var DocumentContainer = document.getElementById(
                                                        "printSection"
                                                    );
                                                    var WindowObject = window.open(
                                                        "PrintWindow",
                                                    );
                                                    WindowObject.document.writeln(
                                                        DocumentContainer.innerHTML
                                                    );
                                                    WindowObject.document.close();
                                                    setTimeout(function () {
                                                        WindowObject.focus();
                                                        WindowObject.print();
                                                        WindowObject.close();
                                                    }, 1000);
                                                    window.$("#stockRequestPopup").modal('hide')
                                                    this.props.history.push('/Stock/Move Stock')
                                                }}
                                            >
                                                PRINT
                                            </button>
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <StockRequestPrint printData={this.state.printData} />
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ErrorBoundary>

                    </div>

                    <div className="card-footer"></div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        stockRequestDataActive: state.rMoveStock.stockRequestDataActive,
        stockRequestDataCompleted: state.rMoveStock.stockRequestDataCompleted,
        warehouseData: state.rMoveStock.warehouseData,
        stockRequestItemData: state.rMoveStock.stockRequestItemData,
        token: state.rLogin.token,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userId: state.rLogin.id,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getStockRequestData: (token) => { dispatch(getStockRequestData(token)) },
        getWarehouseData: (token) => { dispatch(getWarehouseData(token)) },
        addMoveStockRequest: (token, data) => { dispatch(addMoveStockRequest(token, data)) },
        getStockRequestItemData: (token) => { dispatch(getStockRequestItemData(token)) },
        updateMoveStockRequest: (token, data) => { dispatch(updateMoveStockRequest(token, data)) },
        deleteMoveStockRequest: (token, data) => { dispatch(deleteMoveStockRequest(token, data)) },
        updateMoveStockRequestItem: (token, data) => { dispatch(updateMoveStockRequestItem(token, data)) },
        deleteMoveStockRequestItem: (token, data) => { dispatch(deleteMoveStockRequestItem(token, data)) },
        approveStockRequest: (token, data) => { dispatch(approveStockRequest(token, data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveStock);


