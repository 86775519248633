import React from 'react'
import Skeleton from 'react-loading-skeleton';

function SkeltonBtn() {
    return (
        <>
            <div style={{ display: 'inline-block', width: '100%', padding: '2px' }}>
                <Skeleton count={1} height={'6vh'} width={'100%'} duration={1} />
            </div>
        </>
    )
}

export default SkeltonBtn