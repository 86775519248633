/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const paymentDetailDataAsync = data => {
    return { type: 'FETCH_PAYMENT_DETAIL_DATA', data }
}

export const innerPaymentDetaiDataAsync = data => {
    return { type: 'FETCH_INNER_PAYMENT_DETAIL_DATA', data }
}

/**
 * 
 * @param {*} token 
 * @returns 
 */
export const getPaymentDetailData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/paymentdetail/getPaymentDetailData')
            .then(result => {
                dispatch(paymentDetailDataAsync(result.data));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 * @returns 
 */
export const getInnerPaymentDetailData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/paymentdetail/getInnerPaymentDetailData')
            .then(result => {
                console.log(result.data)
                dispatch(innerPaymentDetaiDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 * @returns 
 */
export const cancelPayment = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/paymentdetail/cancelPayment', { data })
            .then(result => {
                dispatch(paymentDetailDataAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

