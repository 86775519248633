/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getDeliveryCharges, addDeliveryCharges, updateDeliveryCharges, deleteDeliveryCharges } from '../../../actions/Basic_data/delivery_charges_actions';
import { getRegionData } from "../../../actions/Basic_data/basic_data_action";

const ReactTableFixedColumns = withFixedColumns(Table);

class DeliveryCharges extends React.Component {

    userTypeData = [
        { '_id': 'All', 'name': 'All' },
        { '_id': 'Normal', 'name': 'Normal' },
        { '_id': 'Admin', 'name': 'Admin' },
        { '_id': 'Data Operator', 'name': 'Data Operator' },
        { '_id': 'Corporate', 'name': 'Corporate' },
        { '_id': 'Delivery', 'name': 'Delivery' },
    ];

    state = {
        deliveryChargesData: [],
        deliveryCharges: 0,
        thresholdValue: 0,
        userType: '',
        region: '',
        slug: '',
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'userType', desc: false }],
    };

    componentDidMount() {
        this.props.getDeliveryCharges(this.props.token);
        this.props.getRegionData(this.props)
    }

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDropdownList,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(props.column.Header === 'Region' ? this.props.region : this.userTypeData).map(data =>
                    <option key={data._id} value={data._id}>{data.name}</option>
                )}
            </Field> : <label>{props.value}</label>)
        }
    };

    setStateForEdit = (column, value) => {
        if (column === 'User Type') {
            this.setState({ userType: value })
        }

        if (column === 'Region') {
            this.setState({ region: value })
        }
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        userType: rowProps.original.userType
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'DELIVERY CHARGES', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'DELIVERY CHARGES', 'DELETE', this.props)
        }) ||
        {};

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values.deliverycharges;
            // console.log(data);
            if (isNaN(data.deliveryCharges)) {
                toast.error('Deliver Charges must be integer');
                return;
            }

            if (isNaN(data.thresholdValue)) {
                toast.error('Threshold Value must be integer');
                return;
            }

            var flag = false;
            var regionName = '';

            this.props.deliveryChargesData.forEach(element => {
                if (element.deliverycharges.userType === data.userType && element.deliverycharges.region === values.regions.name && element.deliverycharges._id !== values.deliverycharges._id) {
                    // if (element.deliverycharges.userType === this.state.userType && element.deliverycharges.region === this.state.region) {
                    flag = true;
                    console.log(element, values);
                    regionName = element.regions.name
                    // }
                }

                if (element.deliverycharges.userType === data.userType && element.deliverycharges.region === values.regions._id && element.deliverycharges._id !== values.deliverycharges._id) {
                    // if (element.deliverycharges.userType === this.state.userType && element.deliverycharges.region === this.state.region) {
                    flag = true;
                    console.log(element, values);
                    regionName = element.regions.name
                    // }
                }
            });

            if (flag) {
                toast.error('Delivery Charges for user type "' + data.userType + '" & "' + regionName + '" already exist!');
                this.setState({
                    userType: '',
                    region: ''
                });
                return;
            }

            data.userType = this.state.userType;
            data.region = this.state.region === '' ? values.deliverycharges.region : this.state.region;

            this.props.updateDeliveryCharges(data, this.props.token)

            this.setState({
                userType: '',
                region: ''
            });

        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values.deliverycharges._id
            }

            this.props.deleteDeliveryCharges(data, this.props.token)
        }
    };

    addDeliveryChargesHandle = () => {
        var { deliveryCharges, thresholdValue, userType, region } = this.state;

        if (deliveryCharges !== '' && thresholdValue !== '' && userType.trim() !== '') {

            this.state.slug = 1;

            var flag = false;

            this.props.deliveryChargesData.forEach(element => {
                if (element.deliverycharges.userType === userType && element.deliverycharges.region === region) {
                    flag = true;
                }

                // if (element.deliverycharges.userType === userType && element.deliverycharges.region === '') {
                //     flag = true;
                // }
            });

            if (flag) {
                toast.error('Delivery Charges for user type "' + userType + '" already exist!');
                return;
            }

            this.props.addDeliveryCharges(this.state, this.props.token)

            this.setState({
                deliveryCharges: 0,
                thresholdValue: 0,
                userType: '',
                region: ''
            });

            document.getElementById("addDeliveryChargesForm").reset();
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    deliveryChargesColumns = [
        { Header: "Delivery Charges", accessor: "deliverycharges.deliveryCharges", ...this.editableColumnProps },
        { Header: "Threshold Value", accessor: "deliverycharges.thresholdValue", ...this.editableColumnProps },
        { Header: "User Type", accessor: "deliverycharges.userType", ...this.selectableColumnProps },
        { Header: "Region", accessor: "regions.name", ...this.selectableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'DELIVERY CHARGES', 'ADD', this.props)} data-toggle="modal" data-target="#addDeliveryCharges"
                            onClick={() => {
                                this.setState({
                                    deliveryCharges: 0,
                                    thresholdValue: 0,
                                    userType: '',
                                    region: ''
                                })
                                document.getElementById("addDeliveryChargesForm").reset();
                            }}
                        >+ Delivery Charges</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.deliveryChargesColumns}
                                                    data={this.props.deliveryChargesData}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>


                    <div id="addDeliveryCharges" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Delivery Condition</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addDeliveryChargesForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Delivery Charges* :</td>
                                                        <td><input type="number" className="form-control" onChange={e => this.setState({ deliveryCharges: Number(e.target.value) })} /></td>
                                                    </tr>


                                                    <tr>
                                                        <td>Threshold Value* :</td>
                                                        <td><input type="number" className="form-control" onChange={e => this.setState({ thresholdValue: Number(e.target.value) })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>User Type* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ userType: e.target.value })}>
                                                                <option value={''}>- Select User Type -</option>
                                                                {this.userTypeData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Region :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ region: e.target.value })}>
                                                                <option value={''}>- Select User Type -</option>
                                                                {this.props.region.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="add_stock" type="button" className="btn btn-primary" onClick={this.addDeliveryChargesHandle}>Add</button>
                                    {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        deliveryChargesData: state.rDeliveryCharges.deliveryChargesData,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        region: state.rBasicData.region,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRegionData: props => { dispatch(getRegionData(props)) },
        getDeliveryCharges: (token) => { dispatch(getDeliveryCharges(token)) },
        addDeliveryCharges: (data, token) => { dispatch(addDeliveryCharges(data, token)) },
        updateDeliveryCharges: (data, token) => { dispatch(updateDeliveryCharges(data, token)) },
        deleteDeliveryCharges: (data, token) => { dispatch(deleteDeliveryCharges(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCharges);


