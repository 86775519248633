import React from 'react';
import { connect } from 'react-redux';
import "../../components/paper.css"
import '../../../css/invoice_pdf.css'
import { PriceFormat } from '../../utils'
import { DateNumberFormat } from '../../utils';

class POS_Print extends React.Component {

    render() {
        const { currentOrder } = this.props;
        var page = [];

        if (currentOrder.posorder !== undefined && currentOrder.posorder != null) {
            var today = new Date(currentOrder.posorder.created_at)
            var time = today.toLocaleTimeString();
            var date = currentOrder.posorder.created_at;
            var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
        }

        if (currentOrder.posorder !== undefined && currentOrder.posorder != null) {
            currentOrder.posorderitems.forEach((item) => {
                item.amount = PriceFormat(Number((item.price - (item.price * item.discount / 100)) * item.qty))
                const sentence = item.itemName;
                const words = sentence.split(" ");
                let abbreviated;
                if (words.length > 3) {
                    abbreviated = words.slice(0, 4).join(" ");
                } else {
                    abbreviated = sentence;
                }
                item.itemName = abbreviated
                page.push(item);
            });
        }

        return (
            <div id="printSection">
                <html>
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"
                    />
                    <body style={{ fontFamily: "Arial", lineHeight: 1, width: "290px", color: 'black' }}>
                        <section class="sheet  padding-5mm" style={{padding:'15px'}}>
                            <table style={{
                                width: "100%",
                                textAlign: "center",
                                padding: 0,
                                margin: "auto"
                            }}>
                                <tr>
                                    <td style={{ width: "80%" }}>
                                        {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ? (
                                            <table style={{ width: "90%", padding: 0, margin: 0 }}>
                                                <tr>
                                                    <td style={{ width: "50%", fontSize: 48, textAlign: "center" }}>
                                                        GUSTA
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", fontSize: 17, textAlign: "center" }}>
                                                        {currentOrder.deliverywarehouse.name !== undefined ? currentOrder.deliverywarehouse.name : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", fontSize: 17, textAlign: "center" }}>
                                                        {currentOrder.deliverywarehouse.telephone !== undefined ? currentOrder.deliverywarehouse.telephone : ''}
                                                    </td>
                                                </tr>
                                            </table>
                                        ) : null}
                                          {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ?
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td style={{ width: "100%", fontSize: 36, textAlign: "left" }}>
                                                        ------------------
                                                    </td>
                                                </tr>
                                            </table>
                                            : null}
                                        {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ? (
                                            <table style={{ width: "100%", textAlign: "left" }}>
                                                <tr>
                                                    <td style={{ width: "25%", fontSize: 15, fontWeight: 'bold' }}>
                                                        Order No:
                                                    </td>
                                                    <td style={{ width: "45%", fontSize: 15 }}>
                                                        {currentOrder.posorder.orderId !== undefined ? currentOrder.posorder.orderId : ''}
                                                    </td>
                                                </tr>
                                            </table>
                                        ) : null}
                                        {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ? (
                                            <table style={{ width: "100%", textAlign: "left" }}>
                                                <tr>
                                                    <td style={{ width: "25%", fontSize: 15, fontWeight: 'bold' }}>
                                                        User:
                                                    </td>
                                                    <td style={{ width: "45%", fontSize: 15 }}>
                                                        {currentOrder.user.name.first !== undefined ? currentOrder.user.name.first : ''}
                                                    </td>
                                                </tr>
                                            </table>
                                        ) : null}
                                        {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ? (
                                            <table style={{ width: "100%", textAlign: "left" }}>
                                                <tr>
                                                    <td style={{ width: "100%", fontSize: 15, }}>
                                                        {dateTime}
                                                    </td>
                                                </tr>
                                            </table>
                                        ) : null}
                                        {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ?
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td style={{ width: "100%", fontSize: 36, textAlign: "left" }}>
                                                        ------------------
                                                    </td>
                                                </tr>
                                            </table>
                                            : null}
                                    </td>
                                </tr>
                            </table>
                            <section>
                                {page.length > 0
                                    ? page.map((value, index, total) => {
                                        return (
                                            <div style={{ marginTop: '5px' }}>
                                                <table style={{ width: "90%", marginTop: "1%" }}>
                                                    <tr>
                                                        <td style={{ width: "100%", fontSize: 15, textAlign: "left" }}>
                                                            {value.itemName !== undefined ? value.itemName : ''}
                                                        </td>
                                                    </tr>
                                                </table>
                                                <table style={{ width: "83%", marginTop: "1%" }}>
                                                    <tr style={{ width: "20%", textAlign: "left" }}>
                                                        <td style={{ fontSize: 15 }}>
                                                            {value.qty + "   x  " + PriceFormat(value.price - (value.price * value.discount / 100))}
                                                        </td>
                                                        <td style={{ fontSize: 15, textAlign: "right" }}>
                                                            {value.amount !== undefined ? value.amount : ''}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        );
                                    })
                                    : null}
                            </section>
                            <section>
                                {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null && currentOrder.posorder.discount > 0 ?
                                    <table style={{ width: "83%", marginTop: "5%" }}>
                                        <tr style={{ width: "20%", textAlign: "left" }}>
                                            <td style={{ fontSize: 15 }}>
                                                Discount:{currentOrder.posorder.discount} %
                                            </td>
                                            <td style={{ fontSize: 15, textAlign: "right" }}>
                                                {currentOrder.posorder.originalTotal !== undefined ? PriceFormat(currentOrder.posorder.originalTotal * (currentOrder.posorder.discount / 100) * -1) : 0}
                                            </td>
                                        </tr>
                                    </table>
                                    : null}
                            </section>
                            <section >
                                {page.length > 0 ?
                                    <table style={{ width: "90%", marginTop: "2%" }}>
                                        <tr>
                                            <td style={{ width: "100%", fontSize: 13, textAlign: "left" }}>
                                                NO OF ITEMS - {page.length}
                                            </td>
                                        </tr>
                                    </table>
                                    : null}
                            </section>

                            <section>
                                {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ?
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td style={{ width: "100%", fontSize: 36, textAlign: "left" }}>
                                                ------------------
                                            </td>
                                        </tr>
                                    </table>
                                    : null}
                            </section>

                            <section>
                                {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ?
                                    <table style={{ width: "85%", marginTop: "3%" }}>
                                        <tr>
                                            <td style={{ width: "65%", fontWeight: 'bold', fontSize: 20, textAlign: "left" }}>
                                                TOTAL:
                                            </td>
                                            <td style={{ width: "20%", fontSize: 20, fontWeight: 'bold' }}>
                                                {currentOrder.posorder.totalPrice !== undefined ? PriceFormat(currentOrder.posorder.totalPrice): ''}
                                            </td>
                                        </tr>
                                    </table>
                                    : null}
                            </section>

                            <section>
                                {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ?
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td style={{ width: "100%", fontSize: 36, textAlign: "left" }}>
                                                ------------------
                                            </td>
                                        </tr>
                                    </table>
                                    : null}
                            </section>

                            <section>
                                {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ?
                                    <table style={{ width: "83%", marginTop: "3%" }}>
                                        <tr>
                                            <td style={{ width: "50%", fontSize: 15, textAlign: "left" }}>
                                                {currentOrder.posorder.payment_method}
                                            </td>
                                            <td style={{ width: "10%", fontSize: 15, textAlign: "right" }}>
                                                {currentOrder.posorder.cash !== undefined ? PriceFormat(currentOrder.posorder.cash) : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "50%", fontSize: 15, textAlign: "left" }}>
                                                Change
                                            </td>
                                            <td style={{ width: "10%", fontSize: 15, textAlign: "right" }}>
                                                {currentOrder.posorder.change !== undefined ? PriceFormat(currentOrder.posorder.change) : ''}
                                            </td>
                                        </tr>
                                    </table>
                                    : null}
                            </section>
                            <section>
                                {typeof currentOrder.posorder !== undefined && currentOrder.posorder != null ?
                                    <table style={{ width: "90%" }}>
                                        <tr>
                                            <td style={{ width: "100%", fontSize: 20, fontWeight: 'bold', textAlign: "center" }}>
                                                *****************
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "100%", fontSize: 12, fontWeight: 'bold', textAlign: "center" }}>
                                                THANK YOU COME AGAIN
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "100%", fontSize: 20, fontWeight: 'bold', textAlign: "center" }}>
                                                *****************
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ marginTop: '10px' }}>
                                            </td>
                                        </tr>
                                    </table>
                                    : null}
                            </section>
                        </section>
                    </body>
                </html>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
    }
}

export default connect(mapStateToProps)(POS_Print);
