/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const cashBoxDataAsync = data => {
    return { type: 'FETCH_CASH_BOX_DATA', data }
}

/**
 * @param {*} token 
 */
export const getCashBoxData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/mis/cashbox/getCashBoxData')
            .then(result => {
                var data = [];
                result.data.forEach(element => {
                    var obj = {
                        '_id': element._id,
                        'date': element.date,
                        'user': element.user,
                        'orderid': element.orderid,
                        'name': element.name,
                        'totalprice': Number(element.totalprice).toFixed(2),
                        'paidamount': Number(element.paidamount).toFixed(2),
                        'balance': Number(element.balance).toFixed(2)
                    }

                    data.push(obj);
                });
                dispatch(cashBoxDataAsync(data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * @param {*} token 
 */
export const updateCashBoxData = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/mis/cashbox/updateCashBoxData', { data })
            .then(result => {
                dispatch(getCashBoxData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * @param {*} token 
 */
export const transfer = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/mis/cashbox/transfer', { data })
            .then(result => {
                window.$('#transfer').modal('hide');
                toast.success('Transfer Successful!');
                dispatch(getCashBoxData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * @param {*} token 
 */
export const deleteCashBoxData = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/mis/cashbox/deleteCashBoxData', { data })
            .then(result => {
                toast.success('Cash Box Deleted Successfully!');
                dispatch(getCashBoxData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}