/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";

import axios from 'axios';

import { toast } from "react-toastify";
import _ from 'lodash';

import $ from 'jquery';

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import { ExportData } from "../../utils";

import {
    getPOSStockRunningData
} from "../../../actions/POS/pos_actions";
import { getDeliveryWarehousesForCurrentUser } from "../../../actions/Basic_data/delivery_warehouse_actions";

const ReactTableFixedColumns = withFixedColumns(Table);


class POS_Running_Report extends React.Component {

    state = {
        sortOptions: [{ id: 'itemCode', desc: false }],
        selectOption: '',
    };

    componentDidMount() {
        this.props.getPOSStockRunningData({ userId: this.props.userId, warehouse: this.state.selectOption }, this.props.token)
        this.props.getDeliveryWarehousesForCurrentUser(this.props)
    }

    reload = () => {
        this.props.getPOSStockRunningData({ userId: this.props.userId, warehouse: this.state.selectOption }, this.props.token)
        this.props.getDeliveryWarehousesForCurrentUser(this.props)

    }

    getColumns = () => {
        let months = this.getAllMonths()
        let columns = [
            {
                Header: "Item Code",
                accessor: "itemCode",
                filterable: true
            },
            {
                Header: "Item Name",
                accessor: "itemName",
                filterable: true
            },
            {
                Header: "Supplier",
                accessor: "supplier",
                filterable: true
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: true
            },
            {
                Header: months[0],
                accessor: months[0],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[1],
                accessor: months[1],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[2],
                accessor: months[2],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[3],
                accessor: months[3],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[4],
                accessor: months[4],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[5],
                accessor: months[5],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[6],
                accessor: months[6],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[7],
                accessor: months[7],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[8],
                accessor: months[8],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[9],
                accessor: months[9],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[10],
                accessor: months[10],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
            {
                Header: months[11],
                accessor: months[11],
                filterable: true,
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
        ]

        return columns
    }

    removeByValue = (val, arr) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === val) {
                arr.splice(i, 1);
                i--;
            }
        }

        return arr;
    }

    getAllMonths = () => {
        let mappedMonths = this.getMappedMonths()
        let retItems = []

        let d = new Date()

        let month = d.getMonth()
        let currentYear = d.getFullYear()
        let previousYear = currentYear - 1

        //for previous year
        if (month !== 0) {
            for (let i = month + 1; i <= 11; i++) {
                retItems.push(mappedMonths[i] + '' + previousYear)
            }

            for (let i = 0; i <= month; i++) {
                retItems.push(mappedMonths[i] + '' + currentYear)
            }
        } else {
            for (let i = 0; i <= 11; i++) {
                retItems.push(mappedMonths[i] + '' + currentYear)
            }
        }

        return retItems

    }

    getMappedMonths = () => {
        return [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ]
    }

    exportDataToExcel = () => {
        let months = this.getAllMonths()

        let excelData = []

        this.props.posRunningData.map(element => {
            let obj = {
                'Item Code': element.itemCode,
                'Item Name': element.itemName,
                'Supplier': element.supplier,
                'Status': element.status,
                [months[0]]: element[months[0]] ? element[months[0]] : 0,
                [months[1]]: element[months[1]] ? element[months[1]] : 0,
                [months[2]]: element[months[2]] ? element[months[2]] : 0,
                [months[3]]: element[months[3]] ? element[months[3]] : 0,
                [months[4]]: element[months[4]] ? element[months[4]] : 0,
                [months[5]]: element[months[5]] ? element[months[5]] : 0,
                [months[6]]: element[months[6]] ? element[months[6]] : 0,
                [months[7]]: element[months[7]] ? element[months[7]] : 0,
                [months[8]]: element[months[8]] ? element[months[8]] : 0,
                [months[9]]: element[months[9]] ? element[months[9]] : 0,
                [months[10]]: element[months[10]] ? element[months[10]] : 0,
                [months[11]]: element[months[11]] ? element[months[11]] : 0,

            }

            excelData.push(obj)
        })

        ExportData(`pos Running Report Data`, 'pos running report', "pos running report", excelData)
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    onSelect = (e, name) => {
        if (e)
            this.setState({ selectOption: (e.target.value) });
        else
            this.setState({ selectOption: null });
    };


    render() {

        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-8" style={{ display: 'inline-flex', paddingRight: '1%' }}>
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    Warehouse :
                                </div>
                                <div style={{ width: '30%' }}>
                                    <select className='form-control' onChange={(e) => this.onSelect(e, "warehouse")}>
                                        <option value={''}> -All Delivery Warehouse-</option>
                                        {this.props.deliveryWarehouseData.map(ele => <option key={ele._id} value={ele._id}>{ele.name}</option>)}
                                    </select>
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <div style={{ display: 'flex' }}>
                                    <button className='btn btn-primary' disabled={this.props.isLoading} onClick={() => this.props.getPOSStockRunningData({ userId: this.props.userId, warehouse: this.state.selectOption }, this.props)}>Search</button>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ textAlign: 'right' }}>
                                <button className="btn btn-primary" disabled={(Permission('POS', 'POS RUNNING REPORT', 'VIEW', this.props)) || this.props.isLoading} onClick={e => this.reload()}><i className="fa fa-refresh"></i></button>
                                <button style={{ float: 'right' }} className="btn btn-success" disabled={this.props.isLoading} onClick={e => this.exportDataToExcel()}>Export Data</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {this.props.isLoading
                            ? <>
                                <SkeletonTable columnsCount={9} dataCount={10} />
                            </>
                            :
                            <div className="card-body">
                                <ErrorBoundary>
                                    <FormProvider
                                        form="inline"
                                        onSubmit={this.handleSubmit}
                                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                        enableReinitialize
                                    >
                                        {formProps => {
                                            let columns = this.getColumns();
                                            return (
                                                <form onSubmit={formProps.handleSubmit}>
                                                    <Table className="-striped"
                                                        sorted={this.state.sortOptions}
                                                        onSortedChange={val => {
                                                            this.setState({ sortOptions: val })
                                                        }}
                                                        columns={columns}
                                                        data={this.props.posRunningData}
                                                        defaultPageSize={15}
                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                        showPageJump={false}

                                                    />
                                                </form>
                                            );
                                        }}
                                    </FormProvider>
                                </ErrorBoundary>
                            </div>
                        }
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        posRunningData: state.rPOS.posRunningData,
        token: state.rLogin.token,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userId: state.rLogin.id,
        isLoading: state.rLogin.loading,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehousesForUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPOSStockRunningData: (data,token) => { dispatch(getPOSStockRunningData(data,token)) },
        getDeliveryWarehousesForCurrentUser: (token, user) => { dispatch(getDeliveryWarehousesForCurrentUser(token, user)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POS_Running_Report);


