import React from "react";

/**
 * @type {Object}
 * @property {array}  data  Product data
 */
const initialState = {
    data: [],
    weeks: [],
    currentItem: {}
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerSalesEvolution = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_ORDER_ITEMS_WITH_PROMOTION_AND_DISCOUNT':
            var { data } = action;

            if (data.items) {
                newState.data = data

                let items = data.items
                let weeks = data.weeks

                let finalData = []

                items.map((item, index) => {
                    let obj = {}
                    obj.item = item.item
                    obj.avg = item.avg
                    obj.type = item.type
                    weeks.map(week => {
                        if (item[`W` + week.weekNumber + '-' + week.shortYear]) {
                            obj[`W` + week.weekNumber + '-' + week.shortYear] = Number(items[index][`W` + week.weekNumber + '-' + week.shortYear]).toFixed(2)
                        } else {
                            obj[`W` + week.weekNumber + '-' + week.shortYear] = 0
                        }
                    })

                    finalData.push(obj)
                })

                let columns = [];

                columns.push(
                    { 'Header': 'Item', 'accessor': 'item' },
                    { 'Header': 'Avg Week', 'accessor': 'avg' },
                    { 'Header': 'Type', 'accessor': 'type' }
                )


                weeks.map(week => {
                    let obj = {
                        'Header': 'W' + week.weekNumber + '-' + week.shortYear,
                        'accessor': 'W' + week.weekNumber + '-' + week.shortYear

                    }

                    columns.push(obj)
                })


                newState.data = finalData
                newState.columns = columns
            }
            break;

        default:
            break;
    }
    return newState;
}

export default reducerSalesEvolution;