/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const stockItemsAsync = data => {
    return { type: 'FETCH_STOCK_ITEMS', data }
}

export const stockItemsWithExpDateAsync = data => {
    return { type: 'FETCH_STOCK_ITEMS_EXP_DATES', data }
}

export const stockItemsWithExpDateAsyncPack = data => {
    return { type: 'FETCH_STOCK_ITEMS_EXP_DATES_PACK', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const stockItemsReportAsync = data => {
    return { type: 'FETCH_STOCK_ITEMS_REPORT_DATA', data }
}

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * 
 * @param {*} token 
 */
export const getStockItems = (token) => {
    return dispatch => {
        // dispatch(isLoadingAsync(true))
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/stock_item/getStockItems')
            .then(result => {
                // console.log(result.data);
                // const finalData = [];

                // result.data.forEach(element => {

                //     let resData;

                //     if (element.stocks) {
                //         resData = {
                //             '_id': element.items._id,
                //             'item_name': (element.items.desc) ? element.items.desc + ' - ' + element.items.itemCode : element.items.name + ' - ' + element.items.itemCode,
                //             'brand_name': element.brand?.name,
                //             'stock_id': element.stocks._id,
                //             'stock': element.stocks.stock,
                //             'previousStock': element.stocks.stock,
                //             'stockChange': 0,
                //             'availableStock': element.stocks.availableStock,
                //             'reservationStock': element.stocks.reservationStock,
                //             'remark': element.stocks.remark,
                //             'stock_unit': element.items.unit,
                //         };
                //     }

                //     if (resData) {
                //         finalData.push(resData)
                //     }
                // });

                dispatch(stockItemsAsync(result.data))
                dispatch(isLoadingAsync(false))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateItemStocks = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/updateStocks', { data })
            .then(result => {
                //save in stock histories

                var stockId = data._id;
                delete data["_id"];

                var item = data.item_id;
                delete data["item_id"];

                data.stockId = stockId;
                data.stockDifference = Number(data.stock - data.previousStock);

                delete data["previousStock"];
                delete data["stockChange"];

                data.date = formatDate(new Date());
                data.item = item;

                data.slug = '1';

                if (data.stockDifference !== 0) {
                    dispatch(updateItemStockHistory(data, token))
                } else {
                    toast.success('Successfully Updated Stock')
                    dispatch(getStockItems(token))
                }
            }).catch(err => {
                toast.error(err)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateItemStockHistory = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/updateStockHistory', { data })
            .then(result => {
                window.$('#addItemStock').modal('hide');
                const data = {
                    '_id': result.data.insertedId,
                    'slug': result.data.insertedId
                }

                dispatch(updateItemStockHistorySlug(data, token))
            }).catch(err => {
                toast.error(err)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateItemStockHistorySlug = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/updateStockHistorySlug', { data })
            .then(result => {
                toast.success('Successfully Added / Updated Stock')
                dispatch(getStockItems(token))
            }).catch(err => {
                toast.error(err)
            })
    }
}


/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deleteItemStock = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/deleteStock', { data })
            .then(result => {
                toast.success('Stock Deleted Successfully!')
                dispatch(getStockItems(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addItemStock = ({ stock, reservationStock, minimumStock, item, remark, availableStock, slug, userId }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock/addStock', { data: { stock, reservationStock, minimumStock, item, remark, availableStock, slug } })
            .then(result => {
                var stockId = result.data.insertedId;

                const data = {
                    'stock': stock,
                    'reservationStock': reservationStock,
                    'item': item,
                    'remark': remark,
                    'availableStock': availableStock,
                    'stockDifference': 0,
                    'stockId': stockId,
                    'date': formatDate(new Date()),
                    'user': userId
                }

                dispatch(updateItemStockSlug(data, token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updateItemStockSlug = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        const slugData = {
            '_id': data.stockId,
            'slug': data.stockId
        };
        axios.post('/stock/updateSlug', { slugData })
            .then(result => {
                data.slug = '1';
                dispatch(updateItemStockHistory(data, token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * To re-calculte stocks
 * 
 * @param {String} token 
 */
export const stockReCal = ({ token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.post('/stock/calReStock')
            .then(result => {
                console.log(result);
                toast.success('Re calculted successfully...!')
                dispatch(getStockItems(token))
            }).catch(err => {
                dispatch(isLoadingAsync(false))
                toast.error(`${err}`)
            })
    }
}

export const getExpDateStock = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/stock/getExpDateStock', { data })
            .then(result => {
                dispatch(stockItemsWithExpDateAsync(result.data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const getExpDateStockPack = (data, { token }, selectedItem = {}) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/stock/getExpDateStock', { data })
            .then(result => {
                let data = {
                    'data': result.data,
                    'selectedItem': selectedItem
                }
                dispatch(stockItemsWithExpDateAsyncPack(data))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const updateExpDateStock = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/stock/updateExpDateStock', { data })
            .then(result => {
                console.log(result);
                toast.success('Exp stock updated successfully...!')
                let newData = { item: data.item_ref, warehouse: '', to: '', from: '' }
                dispatch(getExpDateStock(data = newData, { token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const deleteExpDateStock = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/stock/deleteExpDateStock', { data })
            .then(result => {
                console.log(result);
                toast.success('Exp stock deleted successfully...!')
                let newData = { item: data.item_ref, warehouse: '', to: '', from: '' }
                dispatch(getExpDateStock(data = newData, { token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

export const updateExpStocks = (data, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/stock/updateExpStocks', { data })
            .then(result => {
                console.log(result);
                toast.success('Exp stocks deleted successfully...!')
                let newData = { item: data.item_ref, warehouse: '', to: '', from: '' }
                dispatch(getExpDateStock(data = newData, { token }))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 */
export const getStockItemReportData = (data, callback = "") => {
    let body = {
        startDate: data.startDate,
        endDate: data.endDate,
        item: data.item
    }

    let token = data.token;
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/stock_item/getStockItemReportData', body)
            .then(result => {
                dispatch(stockItemsReportAsync(result.data))
                if(callback){
                    callback("");
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}



export const getStockTotalReportData = (date, token, callback = "") => {
    console.log(date)
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/stock/getSpecificDateInStockValue', {dateVal: date} )
            .then(result => {
                callback(result.data)
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}
