/**
 * @file This file defines to order action dispatch
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const creditNoteDataAsync = data => {
    return { type: 'FETCH_CREDIT_NOTE_DATA', data }
}

/**
 * 
 * 
 * @param {string} token 
 */
export const getCreditNoteData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getCreditNoteData')
            .then(result => {
                dispatch(creditNoteDataAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

