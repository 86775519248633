import React from 'react';
import Select from 'react-select';

const SearchSelectComponent = (props) => {
    const { input: { value, onChange, onBlur }, options, identifier } = props;

    return (
        <Select
            {...props}
            options={options}
            defaultvalue={value}
            onChange={identifier === "Class" ? onChange : e => onChange(e.value)}
        />
    )
};

export default SearchSelectComponent;