/**
 * 
 */
 const initialState = {
    banksData: [],
    branchesData: [],
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerBanks = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_BANKS_DATA':
            newState.banksData = data;
            break;

        case 'FETCH_BRANCHES_DATA':
            // var { data } = action;
            newState.branchesData = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerBanks;
