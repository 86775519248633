/**
 * @file This file defined for Item hierarchy
 * @author Bhanuka Chathuranga
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Select from 'react-select';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { SortableTreeWithoutDndContext as SortableTree, removeNodeAtPath } from "react-sortable-tree";

import ExternalNodeComponent from '../../components/ExternalNodeComponent';
import { externalNodeType, useQuery, Permission } from '../../utils';
import ErrorBoundary from "../../components/ErrorBoundary";
import { getCategoryData } from '../../../actions/Basic_data/category_actions';
import { createItemHierarchy, getItemHierarchy, copyHierarchyToItems } from '../../../actions/Basic_data/item_hierarchy_action';
import { getItemsForHierarchy, getItemsWithHierarchy } from '../../../actions/Basic_data/item_actions';

import 'react-sortable-tree/style.css';
import '../../../css/content.css';


const CreateItemHierarchy = props => {
    // treeData structru should be [{ title: "Chicken", value: '1', expanded: true, children: [{ title: "Egg" }, { title: "Egg123" }, { title: "Egg123" }] }]
    const [treeData, setTreeData] = useState([]);
    const [category, setCategory] = useState([]);
    const [itemsWithHierarchy, setItemsWithHierarchy] = useState([]);
    // const [itemsForHierarchy, setItemsForHierarchy] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');

    const getNodeKey = ({ treeIndex }) => treeIndex;

    var item = useQuery().get('item');
    var name = useQuery().get('name');

    useEffect(() => {
        props.getCategoryData(props)
        props.getHierarchy({ item }, setTreeData, props)
        props.getItemsForHierarchy(props)
        props.getItemsWithHierarchy(props)

    }, [])

    useEffect(() => {
        setCategory(props.categoryData)
        setItemsWithHierarchy(props.itemsWithHierarchy)
    }, [props.categoryData])

    useEffect(() => {
    }, [item])

    useEffect(() => {
        var filtered = props?.categoryData?.filter(val => {
            if (search === '') {
                return val
            } else if (val.name.toLowerCase().includes(search.toLocaleLowerCase())) {
                return val
            }
        })
        setCategory(filtered)

    }, [search])

    /**
     * To create or update new category hierarchy
     */
    const createItemHierarchyHandler = () => {
        props.createHierarchy({ item, treeData }, setTreeData, props)
        props.getCategoryData(props)
    }

    const copyHierarchyDataToItems = () => {
        if (treeData.length > 0) {
            var selectedItemsIds = selectedItems.map(val => val.value);
            if (selectedItemsIds.length > 0) {
                const data = {
                    treeData: treeData,
                    selectedItemsIds: selectedItemsIds
                }

                props.copyHierarchyToItems(data, setTreeData, props);
                props.getCategoryData(props);

                window.$('#copyHierarchy').modal('hide');
                toast.success('Hierarchy Copied to Selected Items!');
            } else {
                toast.error('Please Select Items first!');
            }
        } else {
            toast.info('There is no Heirarchy to Copy!');
        }
    }

    const copyHierarchyDataFromItem = () => {
        var filtered = props.itemsWithHierarchy.filter(val => {
            return val.items[0]._id == selectedItem;
        });

        var treeData = [];

        treeData.push(filtered[0].categoryitem.category[0]);

        window.$('#copyHierarchyFrom').modal('hide');

        props.createHierarchy({ item, treeData }, setTreeData, props);
        props.getCategoryData(props);
    }

    return (
        <div className="right_col" role="main">
            <div className='card'>
                <div className='card-header' style={{ display: 'flex' }}>
                    <button className='btn btn-link' onClick={() => {
                        props.history.push('/basic data/item')
                    }}>
                        <i class="fas fa-arrow-left"></i> Back
                    </button>

                    <button className='btn btn-primary' disabled={Permission('BASIC DATA', 'CREATEITEMHIERARCHY', 'ADD', props)} onClick={createItemHierarchyHandler}>
                        save
                    </button>
                    <div style={{ marginLeft: '15px' }}>
                        Item name : {name}
                    </div>
                    <div>
                        <button className="btn btn-primary" style={{ marginLeft: 55 + 'em' }} data-toggle="modal" data-target="#copyHierarchy">Copy Hierarchy To</button>
                    </div>
                    <div>
                        <button className="btn btn-primary" style={{ marginLeft: 0 }} data-toggle="modal" data-target="#copyHierarchyFrom">Copy Hierarchy From</button>
                    </div>
                </div>
            </div>

            {/* Drag and Drop area */}
            <DndProvider backend={HTML5Backend}>
                <div className='card' style={{ flexDirection: 'row', height: '83vh' }}>
                    <div className='content-header' style={{ height: '99%' }}>
                        <input type="text" className='form-control' onChange={e => setSearch(e.target.value)} placeholder='Search... ' />
                        {<div>
                            {
                                category.map(ele => {
                                    return <ExternalNodeComponent node={{ title: `${ele.name}`, _id: `${ele._id}` }} />
                                })
                            }
                        </div>}
                    </div>

                    {/* Dropable area */}
                    <div className='content-body' style={{ height: '99%' }}>
                        <SortableTree
                            treeData={treeData}
                            onChange={treeData => {
                                // console.log(treeData)
                                setTreeData(treeData)
                            }}
                            dndType={externalNodeType}
                            generateNodeProps={({ node, path }) => ({
                                buttons: [
                                    <button className="btn btn-link btn-sm"
                                        disabled={Permission('BASIC DATA', 'CREATEITEMHIERARCHY', 'DELETE', props)}
                                        onClick={() => {
                                            // console.log(treeData, path, getNodeKey);
                                            setTreeData(removeNodeAtPath({
                                                treeData,
                                                path,
                                                getNodeKey
                                            })
                                            )
                                        }
                                        }
                                    >
                                        <i className="fas fa-trash"></i>
                                    </button>,
                                ]
                            })}
                        />
                    </div>
                    {/* Dropable area */}
                </div>
            </DndProvider>
            {/* Drag and Drop area */}

            <ErrorBoundary>
                <div id="copyHierarchy" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Select Items</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <Select
                                        options={props.itemsForHierarchy}
                                        value={selectedItems}
                                        theme={theme => ({ ...theme, borderRadius: 0 })}
                                        isMulti
                                        onChange={e => {
                                            setSelectedItems(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button id="copy_hierarchy" type="button" className="btn btn-primary" onClick={copyHierarchyDataToItems}>Copy</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>

                </div>
            </ErrorBoundary>

            <ErrorBoundary>
                <div id="copyHierarchyFrom" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content animate" >
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Select Item</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <select className="form-control" onChange={e => e.target.value === '' ? null : setSelectedItem(e.target.value)}>
                                        <option value="" selected>Select Item</option>
                                        {props.itemsWithHierarchy.map(element => {
                                            return <option value={element.items[0]._id}>
                                                {element.items[0].desc ? 
                                                element.items[0].desc : 
                                                element.brand ? 
                                                element.items[0].name + '-' + element.brand.name + '-' + element.items[0].itemCode :
                                                element.items[0].name + '-' + element.items[0].itemCode
                                                }
                                            </option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button id="copy_hierarchy" type="button" className="btn btn-primary" onClick={copyHierarchyDataFromItem}>Copy</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>

                </div>
            </ErrorBoundary>
        </div>
    )
}

const mapStateToProps = state => ({
    token: state.rLogin.token,
    categoryData: state.rCategory.activeCategory,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    isLoading: state.rLogin.loading,
    itemsWithHierarchy: state.rItem.itemsWithHierarchy,
    itemsForHierarchy: state.rItem.itemsForHierarchy
})

const mapDispatchToProps = dispatch => {
    return {
        getCategoryData: ({ token }) => { dispatch(getCategoryData(token)) },
        getHierarchy: (data, func, props) => { dispatch(getItemHierarchy(data, func, props)) },
        createHierarchy: (data, func, props) => { dispatch(createItemHierarchy(data, func, props)) },
        getItemsForHierarchy: ({ token }) => { dispatch(getItemsForHierarchy(token)) },
        copyHierarchyToItems: (data, func, props) => { dispatch(copyHierarchyToItems(data, func, props)) },
        getItemsWithHierarchy: ({ token }) => { dispatch(getItemsWithHierarchy(token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateItemHierarchy)
