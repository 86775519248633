/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import Table from "react-table";
import "react-table/react-table.css";

import _ from 'lodash';

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css';

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";

import { PriceFormat } from "../../utils";
import GridFilters from "../../components/GridFilter";

import { getPurchaseHistoryData, getItemHistoryData } from '../../../actions/Purchasing/purchasing_history_action';

class PurchasingHistory extends React.Component {

    state = {
        purchaseHistoryData: [],
        itemHistoryData: [],
        expanded: {},
        sortOptions: [{ id: 'duedate', desc: true }],
        sortOptionsIH: [{ id: 'createdAt', desc: true }],
    };

    componentDidMount() {
        this.props.getPurchaseHistoryData(this.props.token);
        this.props.getItemHistoryData(this.props.token)
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        if (hours.length < 2) {
            hours = '0' + hours;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        var date = [year, month, day].join('-');
        var time = [hours, minutes, seconds].join(':')

        if (year === 'NaN' || month === 'NaN' || day === 'NaN' || hours === 'NaN' || minutes === 'NaN' || seconds === 'NaN') {
            return '';
        }

        return [date, time].join(' ');
    }

    formatOnlyDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }


        var date = [year, month, day].join('-');

        return date;
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    purchasingHistoryColumns = [
        { Header: "Supplier Name", accessor: "supplier.name", ...GridFilters },
        { Header: "Order Id", accessor: "purchaseorderhistories.orderid", ...GridFilters },
        {
            Header: "Order Date",
            accessor: "purchaseorderhistories.orderdate",
            Cell: row => (
                <div>
                    <label>{this.formatDate(row.original.purchaseorderhistories.orderdate)}</label>
                </div>
            ),
            ...GridFilters
        },
        { Header: "Exp. Delivery Date", accessor: "purchaseorderhistories.expReceiveDate", filterable: true },
        { Header: "Payment Condition", accessor: "paymentcondition.description", filterable: true },
        { Header: "Remark", accessor: "purchaseorderhistories.remark", filterable: true },
        {
            Header: "Paid",
            accessor: "purchaseorderhistories.paid",
            filterable: true,
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else if (filter.value === "true") {
                    return row[filter.id] === true;
                } else if (filter.value === "false") {
                    return row[filter.id] === false;
                }
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value={"all"}>Show All</option>
                    <option value={"true"}>Yes</option>
                    <option value={"false"}>No</option>
                </select>,
            Cell: Props => {
                return (
                    <div style={{ textAlign: "center" }}>{Props.value ? 'Yes' : 'No'}</div>)
            }
        },
        {
            Header: "Payment Value", accessor: "purchaseorderhistories.paymentvalue", filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseorderhistories.paymentvalue}
                </div>
        },
        {
            Header: "Payment Date",
            accessor: "purchaseorderhistories.payment_date",
            filterable: true,
            Cell: row => (
                <div>
                    <label>{this.formatDate(row.original.purchaseorderhistories.payment_date)}</label>
                </div>
            )
        },
        { Header: "Informed", accessor: "purchaseorderhistories.informed", filterable: true },
        { Header: "Delivery Condition", accessor: "deliverycondition.description", filterable: true },
        { Header: "Whatsapp Group", accessor: "supplier.whatsappgroup", filterable: true },
        {
            Header: "Total", accessor: "purchaseorderhistories.total", filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {PriceFormat(props.original.purchaseorderhistories.total)}
                </div>
        },
        { Header: "Warehouse", accessor: "deliverywarehouse.name", ...GridFilters },
    ];

    itemHistoryColumns = [
        { Header: "Item Name", accessor: "items.name", filterable: true },
        { Header: "Item Code", accessor: "items.itemCode", filterable: true },
        {
            Header: "Shortage",
            accessor: "items.code",
            filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {Number(props.original.stocks.reservationStock - (props.original.purchaseitemhistories.orderqty + props.original.stocks.stock)).toFixed(2)}
                </div>
        },
        {
            Header: "Order Qty", accessor: "purchaseitemhistories.orderqty", filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseitemhistories.orderqty}
                </div>
        },
        {
            Header: "Purchase Discount", accessor: "purchaseitemhistories.purchaseDiscount", filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseitemhistories.purchaseDiscount}
                </div>
        },
        {
            Header: "Purchase Price", accessor: "purchaseitemhistories.price", filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseitemhistories.price === undefined || props.original.purchaseitemhistories.price === null ?
                        0
                        : PriceFormat(props.original.purchaseitemhistories.price)}
                </div>
        },
        {
            Header: "Total Value",
            accessor: "items.code",
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.purchaseitemhistories.price === undefined || props.original.purchaseitemhistories.price === null ?
                        0
                        : PriceFormat((Number(props.original.purchaseitemhistories.price) - (Number(props.original.purchaseitemhistories.price) * Number(props.original.purchaseitemhistories.purchaseDiscount || 0) / 100)) * (props.original.purchaseitemhistories.orderqty))}
                </div>,
            filterable: true
        },
        { Header: "Unit", accessor: "items.priceUnit", filterable: true },
        { Header: "Balance Qty", accessor: "purchaseitemhistories.balance", filterable: true },
        { Header: "Rec User", accessor: "recuser.name.first", filterable: true },
        { Header: "Modi User", accessor: "modiuser.name.first", filterable: true },
        { Header: "Rec. Qty", accessor: "purchaseitemhistories.recivqty", filterable: true },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <h3>Purchasing History</h3>
                    </div>
                    <ErrorBoundary>
                        <div className="card-body">
                            <FormProvider
                                form="inline"
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form>
                                            <Table className="-striped"
                                                sorted={this.state.sortOptions}
                                                onSortedChange={val => {
                                                    this.setState({ sortOptions: val })
                                                }}
                                                columns={this.purchasingHistoryColumns}
                                                data={this.props.purchaseHistoryData}
                                                defaultPageSize={20}
                                                expanded={this.state.expanded}
                                                onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index)}
                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                showPageJump={false}
                                                SubComponent={row => {
                                                    var subData = _.filter(this.props.itemHistoryData, function (o) {
                                                        return o.purchaseitemhistories.purchase_ref === row.original.purchaseorderhistories._id
                                                    })
                                                    return (
                                                        <React.Fragment>
                                                            <ErrorBoundary>
                                                                <FormProvider
                                                                    form="inline"
                                                                    enableReinitialize
                                                                >
                                                                    {formProps => {
                                                                        return (
                                                                            <form >
                                                                                <Table className="-striped"
                                                                                    sorted={this.state.sortOptionsIH}
                                                                                    onSortedChange={val => {
                                                                                        this.setState({ sortOptionsIH: val })
                                                                                    }}
                                                                                    columns={this.itemHistoryColumns}
                                                                                    data={subData}
                                                                                    defaultPageSize={10}
                                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                                    showPageJump={false}
                                                                                />
                                                                            </form>
                                                                        )
                                                                    }}

                                                                </FormProvider>
                                                            </ErrorBoundary>
                                                        </React.Fragment>
                                                    );
                                                }}
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </div>
                    </ErrorBoundary>






                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        purchaseHistoryData: state.rPurchasingHistory.purchaseHistoryData,
        itemHistoryData: state.rPurchasingHistory.itemHistoryData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPurchaseHistoryData: (token) => { dispatch(getPurchaseHistoryData(token)) },
        getItemHistoryData: (token) => { dispatch(getItemHistoryData(token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingHistory);


