import React from 'react';
import { connect } from 'react-redux';
import "../../components/paper.css"
import '../../../css/invoice_pdf.css'

class StockRequestPrint extends React.Component {

    render() {
        const { printData } = this.props;

        const formatDate = (date) => {
            var d = new Date(date);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = d.getFullYear();

            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }

            var date = [year, month, day].join('-');

            return date
        }

        const total = () => {
            let totalValue = 0;
            printData.items.map(itemObj => {
                let totalPrice = Number(itemObj.items.price) * Number(itemObj.stockrequestitem.requestedqty)
                totalValue = totalValue + totalPrice
            })

            return totalValue;
        }

        return (

            <div id="printSection">
                <html>
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"
                    />
                    <body className="A5" style={{ fontFamily: "Times New Roman", lineHeight: 1 }}>
                        <section class="sheet  padding-10mm">
                            <table style={{ width: "100%", textAlign: "center" }}>
                                <tr>
                                    <td style={{ width: "50%", fontSize: 20, textAlign: "center" }}>
                                        STOCK TRANSFER REPORT
                                    </td>
                                </tr>
                            </table>
                            <br />
                            <br />
                            {printData.request !== undefined ? (
                                <table style={{ width: "100%", textAlign: "left" }}>
                                    <tr>
                                        <td style={{ width: "20%", fontSize: 13 }}>
                                            Request Number
                                        </td>
                                        <td style={{ width: "40%", fontSize: 13 }}>
                                            {printData.request.stockrequest.requestnumber}
                                        </td>
                                        <td style={{ width: "20%", fontSize: 13 }}>
                                            Date
                                        </td>
                                        <td style={{ width: "30%", fontSize: 13 }}>
                                            {formatDate(printData.request.stockrequest.approvedate)}
                                        </td>
                                    </tr>
                                    <br />
                                    <tr>
                                        <td style={{ width: "20%", fontSize: 15, textAlign: "center", fontWeight:'bold' }}>
                                            Warehouse
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "20%", fontSize: 13 }}>
                                            Warehouse From
                                        </td>
                                        <td style={{ width: "40%", fontSize: 13 }}>
                                            {printData.request.fromwarehouse.name}
                                        </td>
                                        <td style={{ width: "20%", fontSize: 13 }}>
                                            Warehouse To
                                        </td>
                                        <td style={{ width: "30%", fontSize: 13, textAlign: "right" }}>
                                            {printData.request.towarehouse.name}
                                        </td>
                                    </tr>
                                </table>
                            ) : null}
                            <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse", marginTop: "10px" }}>
                                {printData.request !== undefined ? (
                                    <tr style={{ backgroundColor: "#dddddd" }}>
                                        <td style={{ border: "1px solid #dddddd", width: "30%", fontSize: 16 }}>
                                            Item Name
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "5%", fontSize: 14 }}>
                                            Unit
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "5%", fontSize: 16 }}>
                                            Price
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "5%", fontSize: 16 }}>
                                            Quantity
                                        </td>
                                        <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 16 }}>
                                            Value.
                                        </td>
                                    </tr>) : null}
                                {printData.request !== undefined &&
                                    printData.items.map((itemObj, index, total) => {
                                        return (
                                            <tr>
                                                <td style={{ border: "1px solid #dddddd", width: "8%", fontSize: 14 }} >
                                                    {itemObj.items.name}
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "left" }} >
                                                    {itemObj.items.priceUnit}
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "left" }} >
                                                    {itemObj.items.price}
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "10%", fontSize: 14, textAlign: "right" }} >
                                                    {itemObj.stockrequestitem.requestedqty}
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                    {Number(itemObj.items.price) * Number(itemObj.stockrequestitem.requestedqty)}
                                                </td>
                                            </tr>
                                        );
                                    })}

                                <br />
                                {printData.request !== undefined &&
                                    <>
                                        {printData.request !== undefined ? (
                                            <tr>
                                                <td colSpan="4" style={{ border: "1px solid #dddddd", fontSize: 14, textAlign: "right" }} >
                                                    Total:
                                                </td>
                                                <td style={{ border: "1px solid #dddddd", width: "15%", fontSize: 14, textAlign: "right" }} >
                                                    {total()}
                                                </td>
                                            </tr>

                                        ) : null}
                                    </>
                                }
                            </table>
                            <br />
                            <br />
                            {printData.request !== undefined ? (
                                <table style={{ width: "100%", textAlign: "left" }}>
                                    <tr>
                                        <td style={{ width: "20%", fontSize: 13 }}>
                                            Received By
                                        </td>
                                        <td style={{ width: "50%", fontSize: 13 }}>
                                            {printData.request.userapproved.name.first}
                                        </td>
                                        <td style={{ width: "20%", fontSize: 13, textAlign: "left" }}>
                                            Date
                                        </td>
                                        <td style={{ width: "30%", fontSize: 13, textAlign: "left" }}>
                                            {formatDate(printData.request.stockrequest.approvedate)}
                                        </td>
                                    </tr>
                                </table>
                            ) : null}
                        </section>
                    </body>
                </html>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
    }
}

export default connect(mapStateToProps)(StockRequestPrint);
