import { toast } from 'react-toastify';
var axios = require('axios');

export const getMissingValueAsync = data => {
    return { type: 'FETCH_MISSING_VALUE', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}



export const getMissingValue = (data, callback = "") => {
    let body = {
        selectedDate: data.selectedDate
    }

    let token = data.token;

    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/ordering/getCancelAndMissingData', body)
            .then(result => {
                dispatch(getMissingValueAsync(result.data))
                if (callback) {
                    callback("");
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}