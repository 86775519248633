/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import Table from "react-table";
import "react-table/react-table.css";
import _ from 'lodash';
import moment from "moment";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";

import { getCanceledOrders, getCanceledOrderItems, getCancelAndMissionReportData } from '../../../actions/Ordering/canceled_orders_actions';
import GridFilter from "../../components/GridFilter";
import * as XLSX from 'xlsx';
import { ExportData } from "../../utils";
const fileExtension = '.xlsx';

class CanceledOrders extends React.Component {

    state = {
        canceledOrders: [],
        canceledOrderItems: [],
        expanded: {},
        sortOptions: [{ id: 'canceledorders.orderId', desc: true }],
        fromDate: '',
        toDate: '',
        cancelAndMissingOrders: [],
    };

    componentDidMount() {
        let Dates = new Date();
        this.setState({
            fromDate: moment(Dates.setMonth(Dates.getMonth() - 1)).format("YYYY-MM-DD"),
            toDate: moment(Date.now()).format("YYYY-MM-DD"),
        })
        this.props.getCanceledOrders(this.props.token);
        this.props.getCanceledOrderItems(this.props.token);
    }

    componentDidUpdate(prevProps) {
        const { canceledOAndMissionOrderItems } = this.props;
        if (canceledOAndMissionOrderItems !== prevProps.canceledOAndMissionOrderItems) {
            
            this.setState({
                cancelAndMissingOrders: canceledOAndMissionOrderItems
            })
        }
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined && row[id] !== null ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    onChangeDateValue = (e, name) => {
        if (name === "fromDate") {
            let fromDateFormatted = moment(e.target.value).format("YYYY-MM-DD");
            this.setState({
                fromDate: fromDateFormatted,
            })
        }
        if (name === "toDate") {
            let ToDateFormatted = moment(e.target.value).format("YYYY-MM-DD");
            this.setState({
                toDate: ToDateFormatted,
            })
        }
    }

    exportData = () => {
        let b= [''];
        var allCost = 0

        var excelData = this.state.cancelAndMissingOrders.map(checkData => {
            allCost = checkData.allTotal;
            return {
                'Supplier': checkData.supplier,
                'Item Name': checkData.ItemName,
                'Item Code': checkData.ItemCode,
                'Quantity': checkData.Qty,
                'Value': checkData.Value,
                'Cost': checkData.Cost,
            }
        })
        const ws = XLSX.utils.json_to_sheet(excelData);
        const header1 = [{ SupplierName: "       From ETD:  " + this.state.fromDate + "  -  " + "  To ETD:  " + this.state.toDate + "     " + "    Total:     " + allCost }]
        b = b.concat(header1).concat(XLSX.utils.sheet_to_json(ws, { header1: 1 }))
        const worksheet = XLSX.utils.json_to_sheet(b, { skipHeader: true });
        const new_workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Canceled and Missing Item")
        XLSX.writeFile(new_workbook, 'Canceled and Missing item details' + fileExtension);
     }

    handleSubmit = () => {
        this.props.getCancelAndMissionReportData({
            from: this.state.fromDate,
            to: this.state.toDate,
            token: this.props.token
        }, () => {
            this.exportData()
        })
    }

    closeRangePopup = () => {
        let Dates = new Date();
        this.setState({
            fromDate: moment(Dates.setMonth(Dates.getMonth() - 1)).format("YYYY-MM-DD"),
            toDate: moment(Date.now()).format("YYYY-MM-DD"),
        })
    }

    ordersColumns = [
        { Header: 'Order ID', accessor: 'canceledorders.orderId', filterable: true, maxWidth: 115 },
        { Header: 'Reason', accessor: 'canceledorders.reason', filterable: true },
        { Header: 'Name', accessor: 'canceledorders.name', filterable: true },
        { Header: "Telephone", accessor: "canceledorders.telephone", ...GridFilter, filterable: true },
        { Header: 'Whatsapp', accessor: 'canceledorders.whatsapp', filterable: true },
        { Header: 'Address', accessor: 'canceledorders.address', filterable: true },
        { Header: 'City', accessor: 'cities.name', filterable: true },
        { Header: 'Payment Method', accessor: 'paymentmethods.name', filterable: true },
        { Header: 'Status', accessor: 'canceledorders.status', filterable: true },
        {
            Header: 'Amount Paid', accessor: 'canceledorders.amountPaid',...GridFilter, filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorders.amountPaid}
                </div>
        },
        {
            Header: 'Delivery Charges', accessor: 'canceledorders.deliveryCharges', filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorders.deliveryCharges}
                </div>
        },
        {
            Header: 'Tax', accessor: 'canceledorders.tax', filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorders.tax}
                </div>
        },
        {
            Header: 'Order Discount %', accessor: 'canceledorders.totalDiscount', filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorders.totalDiscount}
                </div>
        },
        {
            Header: 'Total Price', accessor: 'canceledorders.totalPrice', filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorders.totalPrice}
                </div>
        },
        { Header: 'Etd', accessor: 'canceledorders.etd', filterable: true },
        { Header: 'Note', accessor: 'canceledorders.note', filterable: true },
        { Header: 'User', accessor: 'users.name.first', filterable: true },
        {
            Header: 'Delete date', accessor: 'canceledorders.deleteAt', filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorders.deleteAt === undefined ? '' : new Date(props.original?.canceledorders?.deleteAt).toISOString()}
                </div>
        },
    ];

    orderItemsColumns = [
        { Header: 'Item Code', accessor: 'items.itemCode', filterable: true },
        { Header: 'Name', accessor: 'items.name', filterable: true },
        { Header: 'Reason', accessor: 'canceledorderitems.reason', filterable: true },
        {
            Header: 'Per Item Price', accessor: 'canceledorderitems.unitPrice', filterable: true, Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorderitems.unitPrice}
                </div>
        },
        {
            Header: 'Quantity', accessor: "canceledorderitems.quantity", filterable: true, Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorderitems.quantity}
                </div>
        },
        {
            Header: 'Item Discount', accessor: 'canceledorderitems.discount', filterable: true, Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorderitems.discount}
                </div>
        },
        {
            Header: 'Total Price', accessor: 'canceledorderitems.unitPrice', filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorderitems.unitPrice}
                </div>
        },
        { Header: 'User', accessor: 'users.name.first', filterable: true },
        {
            Header: 'Delete date', accessor: 'canceledorderitems.deleteAt', filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.canceledorderitems.deleteAt === undefined ? '' : new Date(props.original.canceledorderitems?.deleteAt).toISOString()}
                </div>
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header" style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'inline-flex', paddingRight: '1%' }}>
                            <h4>Canceled Orders </h4>
                        </div>
                        <div style={{ display: 'flex' }}>
                            &nbsp;&nbsp;&nbsp;
                            <div style={{ display: 'flex' }}>
                                <button className="btn btn-success" data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#cancelAndMissingOrders">Export Data</button>

                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form>
                                                <Table className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.ordersColumns}
                                                    data={this.props.canceledOrders}
                                                    defaultPageSize={10}
                                                    expanded={this.state.expanded}
                                                    onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index)}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                    SubComponent={row => {
                                                        var finalData = [];
                                                        _.filter(this.props.canceledOrderItems, function (o) {
                                                            row.original.canceledorders.orderItems.forEach(element => {
                                                                if (o.canceledorderitems._id === element) {
                                                                    finalData.push(o);
                                                                }
                                                            });
                                                        })
                                                        return (
                                                            <React.Fragment>
                                                                <ErrorBoundary>
                                                                    <FormProvider
                                                                        form="inline"
                                                                        enableReinitialize
                                                                    >
                                                                        {formProps => {
                                                                            return (
                                                                                <Table className="-striped"
                                                                                    columns={this.orderItemsColumns}
                                                                                    data={finalData}
                                                                                    defaultPageSize={10}
                                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                                    showPageJump={false}
                                                                                />
                                                                            )
                                                                        }}

                                                                    </FormProvider>
                                                                </ErrorBoundary>
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>
                </div>
                <ErrorBoundary>
                    <div id="cancelAndMissingOrders" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Cancel And Missing Quantity</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeRangePopup}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>From ETD* :</td>
                                                <td><input className="form-control" type="date" value={this.state.fromDate}
                                                    onChange={e => {
                                                        this.onChangeDateValue(e, "fromDate")
                                                    }} /></td>
                                                <td>To ETD* :</td>
                                                <td><input className="form-control" type="date" value={this.state.toDate}
                                                    onChange={e => {
                                                        this.onChangeDateValue(e, "toDate")
                                                    }} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={() => this.handleSubmit()}>Export Data</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.closeRangePopup()}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        canceledOrders: state.rCanceledOrders.canceledOrders,
        canceledOrderItems: state.rCanceledOrders.canceledOrderItems,
        canceledOAndMissionOrderItems: state.rCanceledOrders.canceledOAndMissionOrderItems,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCanceledOrders: (token) => { dispatch(getCanceledOrders(token)) },
        getCanceledOrderItems: (token) => { dispatch(getCanceledOrderItems(token)) },
        getCancelAndMissionReportData: (data, callback) => { dispatch(getCancelAndMissionReportData(data, callback)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CanceledOrders);


