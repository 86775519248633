/**
 * @type {Object}
 * @property {Boolean}  isLoggedIn  check admin or not
 * @property {String}   email   an email
 * @property {Object}   name    include first and last name
 * @property {String}   userType    
 * @property {String}   token   a token
 */
const initialState = {
    id: '',
    isLoggedIn: false,
    email: '',
    name: '',
    userType: '',
    password: '',
    programme: [],
    token: 'undefined',
    userImage: undefined,
    loading: false
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const logInReducer = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'LOG_IN':
            var { isAdmin, email, name, userImage, _id } = action.data.result
            var { token, userPerm } = action.data;
            newState.id = _id;
            newState.isLoggedIn = isAdmin;
            newState.email = email;
            newState.name = name;
            newState.token = token;
            newState.userImage = userImage;
            newState.programme = userPerm.programme;
            // newState.isLoggedIn = true;
            break;

        case 'LOG_OUT':
            newState.isLoggedIn = false;
            newState.email = '';
            newState.name = '';
            newState.token = '';
            break;

        case 'IS_LOADING':
            var { data } = action
            newState.loading = data;
            break;

        default:
            break;
    }
    return newState;
}

export default logInReducer;