import React from 'react'
import Table from "react-table";

const IncomeHistoryCreditNotesModal = ({ creditNoteColumns, creditNotes }) => {
    return (
        <div id="creditNotes" className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content animate">
                    <div className="modal-header">
                        <h4 className="modal-title text-uppercase">Credit Notes</h4>
                        <button type="button" className="close" data-dismiss="modal"> &times;</button>
                    </div>
                    <div className='modal-body'>
                        <div className='card'>
                            <Table className="-striped"
                                columns={creditNoteColumns}
                                data={creditNotes}
                                defaultPageSize={5}
                            />
                        </div>
                    </div>
                    <div className='modal-footer'>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default IncomeHistoryCreditNotesModal
