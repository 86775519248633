/**
 * @file This file defined for TV Dashboard controller
 * @author Umesh Ranthilina
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import $ from 'jquery';
import { styles } from './styles'

import { validate, getBrandStockData} from '../../../actions/POS/pos_actions';
import ErrorBoundary from '../../components/ErrorBoundary';
import { DateNumberFormat } from '../../utils';

import POS_Print from '../POS/POS_Print';
import { getPOSAccountsData } from '../../../actions/POS/pos_actions';
import { getDeliveryWarehousesForCurrentUser } from "../../../actions/Basic_data/delivery_warehouse_actions";

var axios = require('axios');

export class TV_POS extends Component {
    constructor(props) {
        super(props)
        if (this.props.location.data === undefined) {
            this.props.history.push('/POS/POS')
        }
        this.state = {
            items: [],
            cart: [],
            total: 0,
            totalWithoutDiscount: 0,
            ctp: false,
            remaining: 0,
            change: 0,
            summary: {
                paymentMethod: 'Cash',
                enteredAmount: ''
            },
            customer: '',
            search: '',
            disableValidateButton: true,
            posOrderData: {},
            posAccountData: [],
            remark: '',
            orderDiscount: 0,
            originalTotal: 0,
            discountTotal: 0,
        }
    }

    componentDidMount() {
        let data = {
            'warehouse': this.props.location.data.warehouseId
        }
        this.props.getBrandStockData(data, this.props.token)
        this.props.getPOSAccountsData(this.props);
        this.props.getDeliveryWarehousesForCurrentUser(this.props)
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps) {
        const { accounts } = this.props;
        if (accounts !== prevProps.accounts) {
            let checkAccountData = []

            if (accounts !== undefined) {
                if (accounts.length > 0) {
                    accounts.forEach(element => {
                        if (element.warehouseId.toString() === this.props.location.data.warehouseId) {
                            checkAccountData.push(element)
                        }
                    });
                }
            }

            this.setState({
                posAccountData: checkAccountData
            })
        }
    }

    addToCart = (item) => {
        let flag = false;
        let cartArr = this.state.cart;
        let tot = this.state.total;
        let totalWithoutDiscount = this.state.totalWithoutDiscount;

        if (item.totalExpQty !== 0 && item.totalExpQty !== undefined) {
            cartArr.map(c => {
                if (item.outletPrice) {
                    console.log(item)
                    if (c._id === item._id && c.expireDate === item.expireDate) {
                        flag = true;
                        this.increaseQty(item);
                    }
                } else {
                    if (c._id === item._id && c.expireDate === item.expireDate) {
                        flag = true;
                        this.increaseQty(item);
                    }
                }
            })

            if (!flag) {
                item.selectedQty = 1;

                let itemPrice = item.outletPrice ?
                    item.outlet.toString() === this.props.location.data.warehouseId.toString() ?
                        item.outletPrice - (item.outletPrice * item.outletDiscount / 100)
                        :
                        item.price - (item.price * item.discountPercentage / 100)
                    :
                    item.price - (item.price * item.discountPercentage / 100);


                let itemPriceWithoutDiscount = item.outletPrice ?
                    item.outlet.toString() === this.props.location.data.warehouseId.toString() ?
                        item.outletPrice
                        :
                        item.price
                    :
                    item.price;

                tot = tot + itemPrice;
                totalWithoutDiscount = totalWithoutDiscount + itemPriceWithoutDiscount;
                this.setState({ total: tot, originalTotal: tot, totalWithoutDiscount: totalWithoutDiscount })
                cartArr.push(item);
            }
        } else {
            toast.info('Stock is not available.')
        }
        this.setState({ cart: cartArr });
    }

    getItemsByBrandId = (brandId) => {
        $('div[name="brand-buttons"]').css('background', '#fff')
        $('div[name="brand-buttons"]').css('color', '#2A3F54')
        $('div[id="' + brandId + '"]').css('background', '#2A3F54')
        $('div[id="' + brandId + '"]').css('color', '#fff')
        $('#searchBar').val('')
        axios.defaults.headers.common['authorization'] = this.props.token;
        axios.post('/pos/getItemsByBrandId', { data: { 'brandId': brandId, 'warehouseId': this.props.location.data.warehouseId } })
            .then(result => {
                this.setState({ items: result.data })
            }).catch(err => {
                toast.error(`${err}`)
            })
    }

    slideLeft = () => {
        document.getElementById('brandContainer').scrollLeft += 1800;
    }

    slideRight = () => {
        document.getElementById('brandContainer').scrollLeft -= 1800;
    }

    increaseQty = (item) => {
        let cartArr = this.state.cart;
        cartArr.map(c => {
            if (item.outletPrice) {
                if (c._id === item._id && c.expireDate === item.expireDate) {
                    if (c.selectedQty === item.totalExpQty || item.totalExpQty === undefined || item.totalExpQty === '' || item.totalExpQty === 0) {
                        toast.info('Stock is not available.')
                    } else {
                        c.selectedQty = c.selectedQty + 1
                    }
                }
            } else {
                if (c._id === item._id && c.expireDate === item.expireDate) {
                    if (c.selectedQty === item.totalExpQty) {
                        toast.info('Stock is not available.')
                    } else {
                        c.selectedQty = c.selectedQty + 1
                    }
                }
            }
        })

        this.setState({ cart: cartArr })
        this.calculateTotal()
    }

    decreaseQty = (item) => {
        let cartArr = this.state.cart;
        cartArr.map(c => {
            if (item.outletPrice) {
                if (c._id === item._id && c.expireDate === item.expireDate) {
                    c.selectedQty = (c.selectedQty - 1 > 0) ? c.selectedQty - 1 : this.removeFromCart(item._id)
                }
            } else {
                if (c._id === item._id && c.expireDate === item.expireDate) {
                    c.selectedQty = (c.selectedQty - 1 > 0) ? c.selectedQty - 1 : this.removeFromCart(item._id)
                }
            }
        })

        this.setState({ cart: cartArr })
        this.calculateTotal()
    }

    removeFromCart = (itemId) => {
        let indexToFind = 0;
        let cartArr = this.state.cart;
        cartArr.map((value, index) => {
            if (value._id === itemId) {
                indexToFind = index;
            }
        })

        cartArr.splice(indexToFind, 1);

        this.setState({
            cart: cartArr
        })

        this.calculateTotal();
    }

    calculateTotal = () => {
        let cartArr = this.state.cart;
        let total = 0;
        let totalWithoutDiscount = 0;
        cartArr.map(c => {

            let itemPrice = c.outletPrice ?
                c.outlet.toString() === this.props.location.data.warehouseId.toString() ?
                    c.outletPrice - (c.outletPrice * c.outletDiscount / 100)
                    :
                    c.price - (c.price * c.discountPercentage / 100)
                :
                c.price - (c.price * c.discountPercentage / 100);


            let itemPriceWithoutDiscount = c.outletPrice ?
                c.outlet.toString() === this.props.location.data.warehouseId.toString() ?
                    c.outletPrice
                    :
                    c.price
                :
                c.price;

            let calculatedPrice = Number(itemPrice) * Number(c.selectedQty);
            let calculatedPriceWithOutDiscount = Number(itemPriceWithoutDiscount) * Number(c.selectedQty);

            total = total + calculatedPrice;
            totalWithoutDiscount = totalWithoutDiscount + calculatedPriceWithOutDiscount;
        })

        this.setState({ total: total, totalWithoutDiscount: totalWithoutDiscount, originalTotal: total })
    }

    changeEnteredAmouont = (amount) => {
        let change = Number(amount) - Number(this.state.total)
        let remaining = Number(this.state.total) - Number(amount)
        let disableValidateButton = true

        if (remaining <= 5) {
            disableValidateButton = false
        }

        this.setState({
            summary: {
                enteredAmount: amount,
                paymentMethod: this.state.summary.paymentMethod
            },
            remaining: remaining > 0 ? remaining : 0,
            change: change > 0 ? change : 0,
            disableValidateButton: disableValidateButton
        })
    }

    concatEnteredAmount = (value) => {
        let enteredAmount = this.state.summary.enteredAmount;

        enteredAmount = enteredAmount + '' + value;

        this.setState({
            summary: {
                enteredAmount: enteredAmount,
                paymentMethod: this.state.summary.paymentMethod
            }
        })

        this.changeEnteredAmouont(enteredAmount);
    }

    setPaymentMethod = (value) => {
        this.setState({
            summary: {
                paymentMethod: value,
                enteredAmount: '',
            }
        })
    }

    clearOneVal = () => {
        let enteredAmount = this.state.summary.enteredAmount;

        enteredAmount = enteredAmount.substring(0, enteredAmount.length - 1);

        this.setState({
            enteredAmount: enteredAmount,
            paymentMethod: this.state.summary.paymentMethod
        })

        this.changeEnteredAmouont(enteredAmount);
    }

    validate = () => {
        if (Number(this.state.summary.enteredAmount) < 0) {
            if (this.state.summary.enteredAmount < 0) {
                toast.error('Please enter amount.')
            }
        } else if (Number(this.state.summary.enteredAmount) === 0) {
            if (this.state.remark === '') {
                toast.error('Please enter remark.')
            } else {
                this.validateData()
            }
        } else {
           this.validateData()
        }
    }

    handleDiscountValueChange = (e) => {
        const orderDiscountValue = Number(e.target.value);
        const { originalTotal } = this.state;

        let newTotal = originalTotal;
        let discountAmount = 0

        if (orderDiscountValue >= 0 && orderDiscountValue <= 100 ) {
            discountAmount = (orderDiscountValue / 100) * originalTotal;
            newTotal = originalTotal - discountAmount;
        }else{
            newTotal = originalTotal
        }

        this.setState({ orderDiscount: orderDiscountValue, total: Number(newTotal).toFixed(2), discountTotal: discountAmount });
    }

    validateData = () => {
        if (this.state.remaining <= 5) {
            let randomNumber = Math.floor(Math.random() * 10000 + 1);
            let orderId = new Date().toISOString().slice(0, 10) + "-" + randomNumber;


            const itemData = [];

            this.state.cart.map(cartItem => {
                let obj = {
                    'itemId': cartItem._id,
                    'itemCode': cartItem.itemCode,
                    'itemName': cartItem.desc,
                    'price': cartItem.outletPrice ? cartItem.outletPrice : cartItem.price,
                    'qty': cartItem.selectedQty,
                    'discount': cartItem.outletDiscount ? cartItem.outletDiscount : cartItem.discountPercentage,
                    'purchasePrice': cartItem.purchasePrice,
                    'stockItem': cartItem.stockItem,
                    'expireDate': cartItem.expireDate,
                    'outlet': this.props.location.data.warehouseId,
                    'user': this.props.userId,
                    'orderId': orderId,
                };

                itemData.push(obj)
            })

            let amountPaid = this.state.summary.enteredAmount;
            let cash = this.state.summary.enteredAmount;

            if (Number(this.state.total) - Number(this.state.summary.enteredAmount) <= 5) {
                amountPaid = this.state.total
            }

            if (Number(this.state.total) < Number(this.state.summary.enteredAmount)) {
                cash = this.state.summary.enteredAmount
            }

            let discount = (this.state.orderDiscount >=0 && this.state.orderDiscount <=100) ? this.state.orderDiscount : 0;

            let accountData = '';

            if (this.state.posAccountData.length > 0) {
                this.state.posAccountData.map((acc) => {
                    if (this.state.summary.paymentMethod === 'Cash') {
                        if (acc.warehouseAccType === 'Income Cash') {
                            accountData = acc
                        }
                    } else if (this.state.summary.paymentMethod === 'Credit Card') {
                        if (acc.warehouseAccType === 'Income Credit') {
                            accountData = acc
                        }
                    } else if (this.state.summary.paymentMethod === 'Normal') {
                        if (acc.warehouseAccType === 'Normal') {
                            accountData = acc
                        }
                    }
                })
            }

            const posOrder = {
                'orderId': orderId,
                'tel_phone': this.state.customer,
                'outlet': this.props.location.data.warehouseId,
                'payment_method': this.state.summary.paymentMethod,
                'status': 'Paid',
                'discount': Number(discount).toFixed(2),
                'amountPaid': 0,
                'originalTotal': this.state.originalTotal,
                'discountTotal': this.state.discountTotal,
                'amountPaid': 0,
                'total_price': amountPaid,
                'created_at': new Date(),
                'user': this.props.userId,
                'items': itemData,
                'cash': Number(cash),
                'change': Number(this.state.change).toFixed(2),
                'userData': this.props.loggedUser,
                'accounts': accountData
            };

            if (accountData !== '' && accountData !== undefined && accountData !== null) {
                this.props.validate(this.props.token, posOrder, (result) => {
                    this.setState({
                        posOrderData: result,
                    })
                    window.$("#posReceiptPopup").modal('toggle')
                });
            } else {
                toast.error('Please create an account for ' + posOrder.payment_method + '.!')
            }
            this.clearForm();
        }
    }  

    clearForm = () => {
        this.setState({
            items: [],
            cart: [],
            total: 0,
            totalWithoutDiscount: 0,
            ctp: false,
            remaining: 0,
            change: 0,
            summary: {
                paymentMethod: 'Cash',
                enteredAmount: ''
            },
            customer: '',
            remark: '',
            orderDiscount: 0,
            originalTotal: 0,
            discountTotal: 0,
        })
    }

    backClearForm = () => {
        this.setState({
            summary: {
                paymentMethod: 'Cash',
                enteredAmount: ''
            },
            disableValidateButton: true,
            orderDiscount: 0,
            discountTotal: 0,
            total: this.state.originalTotal
        })
    }

    searchItems = (event) => {
        if (event.keyCode === 13 && event.target.value !== '') {
            $('div[name="brand-buttons"]').css('background', '#fff')
            $('div[name="brand-buttons"]').css('color', '#2A3F54')

            let search = event.target.value;

            axios.defaults.headers.common['authorization'] = this.props.token;
            axios.post('/pos/searchItems', { data: { 'search': search, 'warehouseId': this.props.location.data.warehouseId } })
                .then(result => {
                    this.setState({ items: result.data })
                }).catch(err => {
                    toast.error(`${err}`)
                })

        }
    }

    // onKeyPress() {
    //     document.addEventListener('keypress', this.handleKeyPress);
    // }

    // handleKeyPress(event) {
    //     if (event.key === 'Enter') {
    //         var DocumentContainer = document.getElementById(
    //             "printSection"
    //         );
    //         if (DocumentContainer) {
    //             var WindowObject = window.open(
    //                 "PrintWindow",
    //             );
    //             WindowObject.document.writeln(
    //                 DocumentContainer.innerHTML
    //             );
    //             WindowObject.document.close();
    //             setTimeout(function () {
    //                 WindowObject.focus();
    //                 WindowObject.print();
    //                 WindowObject.close();
    //             }, 1000);
    //             window.$("#posReceiptPopup").modal('hide');
    //             this.props.history.push('/POS/POS');
    //         } 
    //     }
    // }

    render() {
        return (
            <div style={styles.ParentDiv}>
                <div style={{ width: '100%', height: '300%' }}>
                    {
                        !this.state.ctp ?
                            <div style={{ width: '100%', color: "white" }}>
                                <h1 style={{ marginLeft: '20px' }}>POS</h1>
                            </div> :
                            <div style={{ width: '100%', color: "white", textAlign: 'center' }}>
                                <button className='btn btn-primary' style={{ marginLeft: '5px', float: 'left', marginTop: '5px' }} onClick={e => { this.setState({ ctp: false }); this.backClearForm() }}><i className='fa fa-caret-left'></i> Back</button>
                                <h1>Payment</h1>
                            </div>
                    }

                    {
                        !this.state.ctp ?
                            <div className='upper-row'>
                                <div className='row' style={{ background: '#fff', paddingTop: '15px', paddingBottom: '10px' }}>
                                    <div style={{ paddingLeft: '30px', overflow: 'auto', width: '100%', height: '60px', overflowX: 'scroll' }} id='brandContainer'>
                                        <button id='slideLeft' className='btn btn-primary' style={{ position: 'absolute' }} onClick={e => this.slideRight()}>&#9664;</button>
                                        <div style={{ paddingLeft: '50px', width: 'max-content' }}>
                                            {this.props.brandData.map(brand => {
                                                return <div className='btn btn-default'
                                                    style={{
                                                        border: '1px solid',
                                                        borderRadius: '20px',
                                                        display: 'inline-block',
                                                        marginBottom: '14px',
                                                        cursor: 'pointer',
                                                        color: '#2A3F54',
                                                        fontWeight: 'bold',
                                                        margin: '5px',
                                                        width: '250px'
                                                    }}
                                                    id={brand._id}
                                                    name="brand-buttons"
                                                    onClick={e => this.getItemsByBrandId(brand._id)}
                                                >{brand.name}</div>
                                            })}
                                        </div>
                                        <button id='slideRight' className='btn btn-primary' style={{ position: 'absolute', top: '82px', right: '40px' }} onClick={e => this.slideLeft()}>&#9654;</button>
                                    </div>
                                </div>
                                <div className='row' style={{ paddingLeft: '35%', background: '#fff', paddingBottom: '10px' }}>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Search...'
                                        style={styles.SearchStyle}
                                        onKeyUp={e => this.searchItems(e)}
                                        id="searchBar"
                                    />
                                </div>
                                <div className='row'>
                                    <div id='items' style={this.state.cart.length > 0 ? { background: '#e0e0e0', padding: '50px', width: '70%' } : { background: '#e0e0e0', padding: '50px', width: '100%' }}>
                                        {this.state.items.map(item => {
                                            return <div className='col-lg-3 col-md-3' style={{ marginBottom: '1%', cursor: 'pointer' }} onClick={e => this.addToCart(item)}>
                                                <div className="card" style={this.state.cart.length > 0 ? { border: '3px solid #2a3f54', height: '500px', borderRadius: '1rem' } : { border: '3px solid #2a3f54', height: '500px', borderRadius: '1rem' }}>
                                                    <img style={{ height: '250px' }} src={item.images?.length > 0 ? item.images[0].url : ''} className="card-img-top img-fluid img-thumbnail" />
                                                    <div className="card-body">
                                                        <h5 className="card-title">{item.desc}</h5>
                                                        <p className="card-text">Item Code: {item.itemCode}</p>
                                                        <h5 className='card-price font-weight-bold'>Rs: {item.outletPrice ? item.outlet.toString() === this.props.location.data.warehouseId.toString() ? Number(item.outletPrice).toFixed(2) : Number(item.price).toFixed(2) : Number(item.price).toFixed(2)}</h5>
                                                        <p className='card-price'>Discount: {item.outletDiscount ? item.outlet.toString() === this.props.location.data.warehouseId.toString() ? item.outletDiscount : item.discountPercentage : item.discountPercentage}%</p>
                                                        <p className='card-price'>{item.expireDate ? item.outlet.toString() === this.props.location.data.warehouseId.toString() ? 'Expiry: ' + DateNumberFormat(item.expireDate) : '' : ''}</p>
                                                        <p className="card-text">{item.totalExpQty ? 'Stock:' + item.totalExpQty : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    {
                                        this.state.cart.length > 0 ?
                                            <div className='cart-row' style={{ width: '30%', background: '#ffffff', paddingLeft: '2%' }}>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-6'>
                                                        <h1 style={{ marginTop: '1%' }}>Current Order</h1>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6'>
                                                        <button className='btn btn-primary' style={{ float: 'right', marginTop: '3%' }} onClick={e => this.setState({ ctp: true })}>Continue to Payment</button>
                                                    </div>

                                                </div>
                                                <div className='row' style={{ marginTop: '1%', fontSize: '20px' }} >
                                                    <div className='row'>
                                                        <div className='col-lg-10 col-md-10'>
                                                            Sub Total:
                                                        </div>
                                                        <div className='col-lg-2 col-md-2'>
                                                            {Number(this.state.total).toFixed(2)}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-10 col-md-10'>
                                                            Total:
                                                        </div>
                                                        <div className='col-lg-2 col-md-2'>
                                                            {Number(this.state.total).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row' style={{ marginTop: '8%' }}>
                                                    {
                                                        this.state.cart.map(cartItem => {
                                                            return <div className='row'>
                                                                <div className='row'>
                                                                    <div className='col-lg-4 col-md-4'>
                                                                        <img src={cartItem.image ? cartItem.images[0].url : ''} />
                                                                    </div>
                                                                    <div className='col-lg-8 cold-md-8'>
                                                                        <h2 style={{ fontWeight: 'bold' }}>{cartItem.desc}</h2>
                                                                        <div className='row'>
                                                                            <div className='col-lg-6 col-md-6' style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                                                {
                                                                                    cartItem.outletPrice ?
                                                                                        this.props.location.data.warehouseId.toString() === cartItem.outlet.toString() ?
                                                                                            Number(cartItem.outletPrice - (cartItem.outletPrice * cartItem.outletDiscount / 100)).toFixed(2)
                                                                                            :
                                                                                            Number(cartItem.price - (cartItem.price * cartItem.discountPercentage / 100)).toFixed(2)
                                                                                        :
                                                                                        Number(cartItem.price - (cartItem.price * cartItem.discountPercentage / 100)).toFixed(2)

                                                                                }
                                                                            </div>
                                                                            <div className='col-lg-6 cold-md-6'>
                                                                                <div className='row'>
                                                                                    <div className='col-lg-4 col-md-4'>
                                                                                        <button className='btn btn-success' style={{ fontWeight: 'bold' }} onClick={e => this.increaseQty(cartItem)}><i className='fa fa-plus'></i></button>
                                                                                    </div>
                                                                                    <div className='col-lg-4 col-md-4'>
                                                                                        <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>{cartItem.selectedQty}</p>
                                                                                    </div>
                                                                                    <div className='col-lg-4 col-md-4'>
                                                                                        <button className='btn btn-danger' style={{ fontWeight: 'bold' }} onClick={e => this.decreaseQty(cartItem)}><i className='fa fa-minus'></i></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <button
                                                                        className='btn btn-warning'
                                                                        style={{ color: '#fff', width: '100%', border: '1px solid #e0e0e0' }}
                                                                        onClick={e => this.removeFromCart(cartItem._id)}
                                                                    >
                                                                        Remove From Cart
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : <div></div>
                                    }
                                </div>
                            </div> :
                            <div className='upper-row'>
                                <div className='container'>
                                    <div className='row' style={{ background: '#fff', paddingTop: '15px', paddingBottom: '10px', paddingLeft: '20px' }}>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className='row' style={{ background: '#e0e0e0', padding: '5px 0px 5px 10px', fontWeight: 'bold', fontSize: '30px' }}>
                                                Payment Method
                                            </div>
                                            <div className='row' style={{ marginTop: '20px' }}>
                                                <button className={this.state.summary.paymentMethod === 'Cash' ? 'btn btn-success text-white font-weight-bold btn-lg' : 'btn btn-default btn-lg'} style={styles.PaymentMethod} onClick={e => this.setPaymentMethod('Cash')}>Cash</button>
                                            </div>
                                            <div className='row'>
                                                <button className={this.state.summary.paymentMethod === 'Credit Card' ? 'btn btn-success text-white font-weight-bold btn-lg' : 'btn btn-default btn-lg'} style={styles.PaymentMethod} onClick={e => this.setPaymentMethod('Credit Card')}>Credit Card </button>
                                            </div>
                                            <div className='row' style={{ background: '#e0e0e0', padding: '5px 0px 5px 10px', fontWeight: 'bold', fontSize: '30px' }}>
                                                Summary
                                            </div>
                                            <div className='row' style={{ fontSize: '20px', paddingTop: '10px' }}>
                                                <div className='col-lg-6 col-md-6'>
                                                    <label style={{ fontSize: '25px', color: 'black' }}>{this.state.summary.paymentMethod}</label>
                                                </div>
                                                <div className='col-lg-6 col-md-6' style={{ textAlign: 'right' }}>
                                                    <label style={{ fontSize: '25px', color: 'black' }}>{Number(this.state.summary.enteredAmount)}</label>
                                                </div>
                                            </div>
                                            <div className='row' style={{ background: '#e0e0e0', padding: '5px 0px 5px 10px', fontWeight: 'bold', fontSize: '30px' }}>
                                                Discount
                                            </div>
                                            <div className='row' style={{ fontSize: '20px', paddingTop: '10px' }}>
                                                <div className='col-lg-8 col-md-8'>
                                                    <label style={{ fontSize: '25px', color: 'black' }}>Discount</label>
                                                </div>
                                                <div className='col-lg-4 col-md-4'>
                                                    <div style={{ display: 'flex', alignItems: 'right' }}>
                                                        <input
                                                            style={{
                                                                fontSize: '25px', color: 'black', textAlign: 'right', outline: 'none', border: 'none',
                                                                background: 'none', WebkitAppearance: 'none', MozAppearance: 'textfield'
                                                            }}
                                                            type="number"
                                                            className="form-control"
                                                            id="orderDiscount"
                                                            onChange={this.handleDiscountValueChange}
                                                            value={this.state.orderDiscount}
                                                            min="0"
                                                            max="100"
                                                        />
                                                        <span style={{ fontSize: '25px', color: 'black', marginLeft: '5px', marginTop: '5px' }}>%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row' style={{ marginTop: '100%' }}>
                                                <button
                                                    className='btn btn-default btn-lg'
                                                    style={{ border: '1px solid #52d0ed', width: '100%', padding: '2%', background: '#52d0ed', color: 'black', fontWeight: 'bold' }}
                                                    onClick={e => this.validate()}
                                                    disabled={this.state.disableValidateButton}
                                                >
                                                    Validate
                                                    <i className='fa fa-caret-right' style={{ marginLeft: '3px', fontSize: '2patan   randa0px' }}></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6' style={{ fontSize: '16px' }}>
                                            <div className='row' style={{ background: '#fff' }}>
                                                <div className='col-lg-6 col-md-6'>
                                                    <label style={{ fontSize: '20px', color: 'black' }} className='label'>Remaining: {Number(this.state.remaining).toFixed(2)}</label> <br />
                                                    <small style={{ fontSize: '20px', color: 'black' }}>Total Due: {Number(this.state.total).toFixed(2)}</small>
                                                </div>
                                                <div className='col-lg-6 col-md-6' style={{ textAlign: 'right' }}>
                                                    <label style={{ fontSize: '20px', color: 'black' }} className='label'>Change: {Number(this.state.change).toFixed(2)}</label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <input
                                                    onChange={e => this.changeEnteredAmouont(e.target.value)}
                                                    className='form-control '
                                                    type="number"
                                                    placeholder='Enter Amount'
                                                    style={{ height: '100px', color: 'black', fontSize: '20px' }}
                                                    autoFocus
                                                    value={this.state.summary.enteredAmount}
                                                />
                                            </div>
                                            <div className='row' style={{ padding: '5%', paddingTop: '1%', border: '2px solid #e0e0e0', background: 'none' }}>
                                                <div className='row'>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(1)} style={styles.ButtonClass}>1</button>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(2)} style={styles.ButtonClass}>2</button>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(3)} style={styles.ButtonClass}>3</button>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(4)} style={styles.ButtonClass}>4</button>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(5)} style={styles.ButtonClass}>5</button>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(6)} style={styles.ButtonClass}>6</button>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(7)} style={styles.ButtonClass}>7</button>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(8)} style={styles.ButtonClass}>8</button>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(9)} style={styles.ButtonClass}>9</button>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount('.')} style={styles.ButtonClass}>.</button>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.concatEnteredAmount(0)} style={styles.ButtonClass}>0</button>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4'>
                                                        <button onClick={e => this.clearOneVal()} style={styles.ButtonClass}><i className='fa fa-arrow-left'></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className='row' style={{ marginBottom: '5px' }}>
                                                <i style={{ position: 'absolute', top: '13px', left: '10px' }} className='fa fa-user'></i>
                                                <input style={{ paddingLeft: '24px', fontSize: '20px', color: 'black' }} className='form-control' type='text' value={this.state.customer} onChange={e => this.setState({ customer: e.target.value })} placeholder='Telephone' />
                                            </div>
                                            <div className='row' style={{ marginBottom: '5px' }}>
                                                <i style={{ position: 'absolute', top: '13px', left: '10px' }} className='fa fa-user'></i>
                                                <textarea slot='5' style={{ paddingLeft: '24px', fontSize: '20px', color: 'black' }} className='form-control' type='text' value={this.state.remark} onChange={e => this.setState({ remark: e.target.value })} placeholder='Remark' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }


                </div>
                <ErrorBoundary>
                    <div id="posReceiptPopup" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate">
                                <div className="modal-header">
                                    <button
                                        className='btn btn-secondary'
                                        // onKeyDown={this.onKeyPress()}
                                        onClick={() => {
                                            var DocumentContainer = document.getElementById(
                                                "printSection"
                                            );
                                            var WindowObject = window.open(
                                                "PrintWindow",
                                            );
                                            WindowObject.document.writeln(
                                                DocumentContainer.innerHTML
                                            );
                                            WindowObject.document.close();
                                            setTimeout(function () {
                                                WindowObject.focus();
                                                WindowObject.print();
                                                WindowObject.close();
                                            }, 1000);
                                            window.$("#posReceiptPopup").modal('hide')
                                            this.props.history.push('/POS/POS')

                                        }}
                                    >
                                        PRINT
                                    </button>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <POS_Print currentOrder={this.state.posOrderData} ref={el => (this.componentRef = el)} />
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userId: state.rLogin.id,
        brandData: state.rBasicData.brandData,
        loggedUser: state.rLogin.name,
        accounts: state.rPOS.accountData,
        deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehousesForUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        validate: (token, data, callback) => { dispatch(validate(token, data, callback)) },
        getBrandStockData: (data, token) => { dispatch(getBrandStockData(data, token)) },
        getPOSAccountsData: (token) => { dispatch(getPOSAccountsData(token)) },
        getDeliveryWarehousesForCurrentUser: (token, user) => { dispatch(getDeliveryWarehousesForCurrentUser(token, user)) },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TV_POS)
