const BOOL = [{ value: false, label: 'No' }, { value: true, label: 'Yes' }]
const type = ['Product', 'Communication', 'Delivery', 'Pricing']
const externalNodeType = 'yourNodeType';
const MOBILE_NO = 94771330707
const COOKIE_MAX_AGE = 60 * 60 * 24 * 30
const STOCK_UNIT = ['L', 'Kg', 'Pcs']
const FROM = ['Local', 'Imported']
const WIDTH = window.innerWidth
const TYPE_DATA = [
    { '_id': 'Working Day', 'name': 'Working Day' },
    { '_id': 'Holiday', 'name': 'Holiday' }
];
const WEEKDAYDATA = [
    { '_id': 'Monday', 'name': 'Monday' },
    { '_id': 'Tuesday', 'name': 'Tuesday' },
    { '_id': 'Wednesday', 'name': 'Wednesday' },
    { '_id': 'Thursday', 'name': 'Thursday' },
    { '_id': 'Friday', 'name': 'Friday' },
    { '_id': 'Saturday', 'name': 'Saturday' },
    { '_id': 'Sunday', 'name': 'Sunday' },
];
const TIMEDATA = [
    { '_id': '00:00', 'name': '00:00' },
    { '_id': '00:30', 'name': '00:30' },
    { '_id': '01:00', 'name': '01:00' },
    { '_id': '01:30', 'name': '01:30' },
    { '_id': '02:00', 'name': '02:00' },
    { '_id': '02:30', 'name': '02:30' },
    { '_id': '03:00', 'name': '03:00' },
    { '_id': '03:30', 'name': '03:30' },
    { '_id': '04:00', 'name': '04:00' },
    { '_id': '04:30', 'name': '04:30' },
    { '_id': '05:00', 'name': '05:00' },
    { '_id': '05:30', 'name': '05:30' },
    { '_id': '06:00', 'name': '06:00' },
    { '_id': '06:30', 'name': '06:30' },
    { '_id': '07:00', 'name': '07:00' },
    { '_id': '07:30', 'name': '07:30' },
    { '_id': '08:00', 'name': '08:00' },
    { '_id': '08:30', 'name': '08:30' },
    { '_id': '09:00', 'name': '09:00' },
    { '_id': '09:30', 'name': '09:30' },
    { '_id': '10:00', 'name': '10:00' },
    { '_id': '10:30', 'name': '10:30' },
    { '_id': '11:00', 'name': '11:00' },
    { '_id': '11:30', 'name': '11:30' },
    { '_id': '12:00', 'name': '12:00' },
    { '_id': '12:30', 'name': '12:30' },
    { '_id': '13:00', 'name': '13:00' },
    { '_id': '13:30', 'name': '13:30' },
    { '_id': '14:00', 'name': '14:00' },
    { '_id': '14:30', 'name': '14:30' },
    { '_id': '15:00', 'name': '15:00' },
    { '_id': '15:30', 'name': '15:30' },
    { '_id': '16:00', 'name': '16:00' },
    { '_id': '16:30', 'name': '16:30' },
    { '_id': '17:00', 'name': '17:00' },
    { '_id': '17:30', 'name': '17:30' },
    { '_id': '18:00', 'name': '18:00' },
    { '_id': '18:30', 'name': '18:30' },
    { '_id': '19:00', 'name': '19:00' },
    { '_id': '19:30', 'name': '19:30' },
    { '_id': '20:00', 'name': '20:00' },
    { '_id': '20:30', 'name': '20:30' },
    { '_id': '21:00', 'name': '21:00' },
    { '_id': '21:30', 'name': '21:30' },
    { '_id': '22:00', 'name': '22:00' },
    { '_id': '22:30', 'name': '22:30' },
    { '_id': '23:00', 'name': '23:00' },
    { '_id': '23:30', 'name': '23:30' }

];

const complaintTypeData = [
    { '_id': 'Product Defect', 'name': 'Product Defect' },
    { '_id': 'Price difference', 'name': 'Price difference' },
    { '_id': 'Refund', 'name': 'Refund' },
    { '_id': 'Wrong Item', 'name': 'Wrong Item' },
    { '_id': 'Item Missing', 'name': 'Item Missing' },
    { '_id': 'Delivery Issues', 'name': 'Delivery Issues' },
    { '_id': 'Quality Taste Issues', 'name': 'Quality Taste Issues' },
];

const resolvedData = [
    { '_id': 'Yes', 'name': 'Yes' },
    { '_id': 'No', 'name': 'No' },
];

const DeliveredStatus = ['Dispatched', 'Delivered']

export {
    BOOL,
    type,
    externalNodeType,
    complaintTypeData,
    resolvedData,
    MOBILE_NO,
    COOKIE_MAX_AGE,
    STOCK_UNIT,
    FROM,
    WIDTH,
    TYPE_DATA,
    WEEKDAYDATA,
    TIMEDATA,
    DeliveredStatus
};