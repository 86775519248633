/**
 * @type {Object}
 * @property {array}  data  User
 */
const initialState = {
    users: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerUser = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_USERS':
            var { data } = action;
            data.map(user => {
                user['value'] = `${user._id}`
                user['label'] = `${user.name.first} ${user.name.last === undefined ? '' : user.name.last}`;
            })
            newState.users = data;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerUser;