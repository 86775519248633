/**
 * @type {Object}
 * @property {array}  data  Product data
 */
const initialState = {
    data: [],
    deliveredOverviewData: [],
    deliveryDashboardData: [],
    delivereyDataForConfirmationHistory: [],
    delivereyDataForConfirmationActive: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const reducerDeliveryBoys = (state = initialState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case 'FETCH_DELIVERY_BOYS':
            var { data } = action;
            newState.data = data;
            break;

        case 'FETCH_DELIVERY_OVERVIEW_DATA': 
            var { data } = action;
            newState.deliveredOverviewData = data;
            break;

        case 'FETCH_DELIVERY_DASHBOARD_DATA': 
            var { data } = action;
            newState.deliveryDashboardData = data;
            break;

        case 'FETCH_DELIVERY_DATA_FOR_CONFIRMATION':
            var { data } = action;

            let delivereyDataForConfirmationHistory = []
            let delivereyDataForConfirmationActive = []

            data.map(element => {
                if(element.orders.deliveryConfirmed) {
                    delivereyDataForConfirmationHistory.push(element)
                } else {
                    delivereyDataForConfirmationActive.push(element)
                }
            })

            
            newState.delivereyDataForConfirmationHistory = delivereyDataForConfirmationHistory;
            newState.delivereyDataForConfirmationActive = delivereyDataForConfirmationActive;
            break;

        default:
            break;
    }
    return newState;
}

export default reducerDeliveryBoys;