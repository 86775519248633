/**
 * @file This file defined for dashboard controller
 * @author Bhanuka Chathuranga
 */
import React, { Component } from 'react';
import Sidemenu from '../container/components/Sidemenu';
import Navigation from '../container/components/Navigation';
import PageContent from '../container/components/PageContent';
import Footer from '../container/components/Footer';
import TVController from './TVController';
import PosTvController from './PosTvController';

export default class Controller extends Component {
    render() {
        return (
            <div className="main_container">
                {window.location.pathname === '/tvLine' ? <TVController /> : window.location.pathname === '/postv' ? <PosTvController /> : <div>
                    <Sidemenu />
                    <Navigation></Navigation>
                    <PageContent></PageContent>
                    <Footer></Footer>
                </div>}
            </div>
        )
    }
}
