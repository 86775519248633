import React from 'react'
import * as BS from "react-bootstrap";
import HighlightCell from './HighlightCell';

const editableComponent = ({ input, editing, value, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    const children =
        (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component {...input} {...rest} children={children} />;
};

export default editableComponent;