/**
 * @author Ehsan Elahi
 */


import _ from 'lodash';
import { toast } from 'react-toastify';
import $ from 'jquery';
var axios = require('axios');

export const paymentsDataAsync = data => {
    return { type: 'FETCH_PAYMENTS_DATA', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const getReportDataAsync = data => {
    return { type: 'FETCH_REPORT_DATA', data }
}

/**
 * @param {*} token 
 */
export const getPaymentsData = (token) => {
    return dispatch => {
        dispatch(isLoadingAsync(true));
        axios.defaults.headers.common['authorization'] = token;
        var dateRangeArr = getPreviousMonthAndYear();

        var finalArray = [];

        axios.get('/acc/payments/getPaymentsData')
            .then(result => {
                var resData = result.data.pOrders;
                var paymentDetails = result.data.paymentDetail;

                resData.forEach(element => {

                    if (element.purchaseorder.paid === false) {

                        var flag = false;

                        for (var i = 0; i < 6; i++) {

                            var poMonth = new Date(element.purchaseorder.duedate).getMonth();
                            var poYear = new Date(element.purchaseorder.duedate).getFullYear();

                            var drMonth = new Date(dateRangeArr[i].start).getMonth();

                            var drYear = new Date(dateRangeArr[i].start).getFullYear();

                            if ((poMonth === drMonth && poYear === drYear)) {

                                var innerObj = [];

                                flag = true;

                                var price = element.purchaseitem.purchaseDiscount ?
                                    (element.purchaseitem.price * element.purchaseitem.recivqty) - (element.purchaseitem.price * element.purchaseitem.recivqty * (element.purchaseitem.purchaseDiscount / 100)) :
                                    element.purchaseitem.price * element.purchaseitem.recivqty;

                                innerObj = {
                                    "supplier_id": element.supplier._id,
                                    "supplier_name": element.supplier.name,
                                    "month": dateRangeArr[i].monthName,
                                    "price": price,
                                    "po_id": element.purchaseorder._id
                                }

                                finalArray.push(innerObj);
                            }
                        }

                        if (flag === false) {

                            let price = element.purchaseitem.purchaseDiscount ?
                                (element.purchaseitem.price * element.purchaseitem.recivqty) - (element.purchaseitem.price * element.purchaseitem.recivqty * (element.purchaseitem.purchaseDiscount / 100)) :
                                element.purchaseitem.price * element.purchaseitem.recivqty;

                            innerObj = {
                                "supplier_id": element.supplier._id,
                                "supplier_name": element.supplier.name,
                                "month": "other",
                                "price": price,
                                "po_id": element.purchaseorder._id
                            }

                            finalArray.push(innerObj);
                        }
                    }

                });

                var paymentDataArrayF = [];

                var month1Total = 0;
                var month2Total = 0;
                var month3Total = 0;
                var month4Total = 0;
                var month5Total = 0;
                var month6Total = 0;
                var otherTotal = 0;
                var outstandingTotal = 0;

                axios.defaults.headers.common['authorization'] = token;
                axios.get('/purchasing/getSuppliersOnly')
                    .then(result => {
                        const suppliers = result.data;

                        suppliers.forEach(supplier => {
                            var paymentDataArray = {};

                            var supSpecificData = _.filter(finalArray, function (o) {
                                return o.supplier_id === supplier._id
                            });

                            var month1Price = 0;
                            var month2Price = 0;
                            var month3Price = 0;
                            var month4Price = 0;
                            var month5Price = 0;
                            var month6Price = 0;
                            var otherPrice = 0;

                            var month1POs = [];
                            var month2POs = [];
                            var month3POs = [];
                            var month4POs = [];
                            var month5POs = [];
                            var month6POs = [];
                            var otherPOs = [];

                            supSpecificData.forEach(supSpecificDatum => {
                                if (supSpecificDatum.month === dateRangeArr[0].monthName) {
                                    month1Price = Number(month1Price) + Number(supSpecificDatum.price);
                                    month1POs.push(supSpecificDatum.po_id);
                                    month1Total = month1Total + supSpecificDatum.price;
                                } else if (supSpecificDatum.month === dateRangeArr[1].monthName) {
                                    month2Price = Number(month2Price) + Number(supSpecificDatum.price);
                                    month2POs.push(supSpecificDatum.po_id);
                                    month2Total = month2Total + supSpecificDatum.price;
                                } else if (supSpecificDatum.month === dateRangeArr[2].monthName) {
                                    month3Price = Number(month3Price) + Number(supSpecificDatum.price);
                                    month3POs.push(supSpecificDatum.po_id);
                                    month3Total = month3Total + supSpecificDatum.price;
                                } else if (supSpecificDatum.month === dateRangeArr[3].monthName) {
                                    month4Price = Number(month4Price) + Number(supSpecificDatum.price);
                                    month4POs.push(supSpecificDatum.po_id);
                                    month4Total = month4Total + supSpecificDatum.price;
                                } else if (supSpecificDatum.month === dateRangeArr[4].monthName) {
                                    month5Price = Number(month5Price) + Number(supSpecificDatum.price);
                                    month5POs.push(supSpecificDatum.po_id);
                                    month5Total = month5Total + supSpecificDatum.price;
                                } else if (supSpecificDatum.month === dateRangeArr[5].monthName) {
                                    month6Price = Number(month6Price) + Number(supSpecificDatum.price);
                                    month6POs.push(supSpecificDatum.po_id);
                                    month6Total = month6Total + supSpecificDatum.price;
                                } else {
                                    otherPrice = Number(otherPrice) + Number(supSpecificDatum.price);
                                    otherPOs.push(supSpecificDatum.po_id);
                                    otherTotal = otherTotal + supSpecificDatum.price;
                                }
                            });

                            var month1Paid = getPaid(month1POs, paymentDetails);
                            var month2Paid = getPaid(month2POs, paymentDetails);
                            var month3Paid = getPaid(month3POs, paymentDetails);
                            var month4Paid = getPaid(month4POs, paymentDetails);
                            var month5Paid = getPaid(month5POs, paymentDetails);
                            var month6Paid = getPaid(month6POs, paymentDetails);
                            var otherPaid = getPaid(otherPOs, paymentDetails);

                            paymentDataArray['supplier'] = supplier.name;
                            paymentDataArray['supplier_id'] = supplier._id;
                            paymentDataArray['supplier_account'] = supplier.account_ref;
                            paymentDataArray[dateRangeArr[0].monthName] = Number(month1Price - month1Paid).toFixed(2);
                            paymentDataArray[dateRangeArr[1].monthName] = Number(month2Price - month2Paid).toFixed(2);
                            paymentDataArray[dateRangeArr[2].monthName] = Number(month3Price - month3Paid).toFixed(2);
                            paymentDataArray[dateRangeArr[3].monthName] = Number(month4Price - month4Paid).toFixed(2);
                            paymentDataArray[dateRangeArr[4].monthName] = Number(month5Price - month5Paid).toFixed(2);
                            paymentDataArray[dateRangeArr[5].monthName] = Number(month6Price - month6Paid).toFixed(2);
                            paymentDataArray['Other'] = Number(otherPrice - otherPaid).toFixed(2);
                            paymentDataArray['Outstanding'] = Number((month1Price + month2Price + month3Price + month4Price + month5Price + month6Price + otherPrice
                                - month1Paid - month2Paid - month3Paid - month4Paid - month5Paid - month6Paid - otherPaid)).toFixed(2);

                            outstandingTotal = outstandingTotal + Number(paymentDataArray['Outstanding']);

                            month1Total = month1Total - month1Paid;
                            month2Total = month2Total - month2Paid;
                            month3Total = month3Total - month3Paid;
                            month4Total = month4Total - month4Paid;
                            month5Total = month5Total - month5Paid;
                            month6Total = month6Total - month6Paid;
                            otherTotal = otherTotal - otherPaid;


                            if (paymentDataArray['Outstanding'] > 0) {
                                paymentDataArrayF.push(paymentDataArray);
                            }

                        });

                        var totalObj = {
                            "month1Total": month1Total.toFixed(2),
                            "month2Total": month2Total.toFixed(2),
                            "month3Total": month3Total.toFixed(2),
                            "month4Total": month4Total.toFixed(2),
                            "month5Total": month5Total.toFixed(2),
                            "month6Total": month6Total.toFixed(2),
                            "otherTotal": otherTotal.toFixed(2),
                            "outstandingTotal": outstandingTotal.toFixed(2),
                        };

                        var otherObj = {
                            monthName: 'Other'
                        };

                        var otherObj2 = {
                            monthName: 'Outstanding'
                        };

                        dateRangeArr.push(otherObj);
                        dateRangeArr.push(otherObj2);

                        const data = {
                            months: dateRangeArr,
                            payments: paymentDataArrayF,
                            totals: totalObj
                        };

                        dispatch(isLoadingAsync(false));
                        dispatch(paymentsDataAsync(data));

                    })
                    .catch(err => {
                        console.log(err);
                    })

            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

function getPaid(arr, paymentDetails) {

    arr = getUniqueValues(arr);

    var paid = 0;
    for (var k = 0; k < arr.length; k++) {
        var currentPO = arr[k];

        var filteredPD = _.filter(paymentDetails, function (o) {
            return o.ref_purchase === currentPO;
        });

        filteredPD.forEach(element => {
            paid = paid + Number(element.value);
        });
    }

    return paid;
}

function getUniqueValues(arr) {
    return $.grep(arr, function (v, i) {
        return $.inArray(v, arr) === i;
    });
}

function getPreviousMonthAndYear() {
    var months = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var today = new Date();
    var d;
    var month;
    var year;
    var dateRange;
    var dateRangeArr = [];

    for (var i = 4; i >= -1; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = months[d.getMonth()];
        var monthName = monthNames[d.getMonth()];

        year = d.getFullYear();

        dateRange = getMonthDateRange(year, month, monthName);
        dateRangeArr.push(dateRange);
    }

    return dateRangeArr;
}

function getMonthDateRange(year, month, monthName) {
    month = Number(month) + 1;
    var dateStr = year + '-' + month + '-01';
    var startDate = new Date(dateStr);
    var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

    var obj = {
        monthIndex: month,
        monthName: monthName,
        start: formatDate(startDate),
        end: formatDate(endDate)
    };

    return obj;
}

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * 
 * @param {*} token 
 * @returns 
 */
export const getNotCompletedPaymentsData = (token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/payments/getNotCompletedPaymentsData')
            .then(result => {
                dispatch(paymentsDataAsync(result.data));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addPayment = ({ type, value, user, payment, reference, completed, createdAt, totalBalance }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/acc/payments/addPayment', {
            data: {
                type, value, user, payment, reference, completed, createdAt, totalBalance
            }
        })
            .then(result => {
                dispatch(getPaymentsData(token));
                window.$('#addPayment').modal('hide');
                toast.success('Payment Added Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updatePayment = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/payments/updatePayment', { data })
            .then(result => {
                toast.success('Successfully Updated Payment!')
                dispatch(getPaymentsData(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deletePayment = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/payments/deletePayment', { data })
            .then(result => {
                dispatch(getPaymentsData(token));
                toast.success('Payment Deleted Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addPaymentSchedule = ({ plandate, value, paid, user, paydate, refPayment, createdAt }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;

        axios.post('/acc/payments/addPaymentSchedule', {
            data: {
                plandate, value, paid, user, paydate, refPayment, createdAt
            }
        })
            .then(result => {
                toast.success('Payment Schedule Added Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updatePaymentSchedule = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/payments/updatePaymentSchedule', { data })
            .then(result => {
                toast.success('Successfully Updated Payment Schedule!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deletePaymentSchedule = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/acc/payments/deletePaymentSchedule', { data })
            .then(result => {
                dispatch(getPaymentsData(token));
                toast.success('Payment Schedule Deleted Successfully!');
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} token 
 * @returns 
 */
export const getReportData = ({token}, callback) => {
    console.log(token)
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/acc/payments/getAllPurchaseOrders')
            .then(result => {
                console.log(result.data)
                dispatch(getReportDataAsync(result.data));
                if(callback){
                    callback("")
                }
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}
