/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const stockMovingDataAsync = data => {
    return { type: 'FETCH_STOCK_MOVING_DATA', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * 
 * @param {*} token 
 */
export const getStockMovingData = (token) => {
    return dispatch => {
        dispatch(isLoadingAsync(true));
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/stock_moving/getStockMovingData')
            .then(result => {
                let months = getAllMonths()

                let stockMovingData = result.data

                let retData = []

                stockMovingData.map(element => {
                    let obj = {
                        'itemCode': element.itemCode,
                        'itemName': element.itemName,
                        'supplier': element.supplier,
                        'status': element.status,
                        [months[0]]: element[months[0]] ? element[months[0]] : 0,
                        [months[1]]: element[months[1]] ? element[months[1]] : 0,
                        [months[2]]: element[months[2]] ? element[months[2]] : 0,
                        [months[3]]: element[months[3]] ? element[months[3]] : 0,
                        [months[4]]: element[months[4]] ? element[months[4]] : 0,
                        [months[5]]: element[months[5]] ? element[months[5]] : 0,
                        [months[6]]: element[months[6]] ? element[months[6]] : 0,
                        [months[7]]: element[months[7]] ? element[months[7]] : 0,
                        [months[8]]: element[months[8]] ? element[months[8]] : 0,
                        [months[9]]: element[months[9]] ? element[months[9]] : 0,
                        [months[10]]: element[months[10]] ? element[months[10]] : 0,
                        [months[11]]: element[months[11]] ? element[months[11]] : 0,

                    }

                    retData.push(obj)
                })

                dispatch(isLoadingAsync(false))
                dispatch(stockMovingDataAsync(retData))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}


const getAllMonths = () => {
    let mappedMonths = getMappedMonths()
    let retItems = []

    let d = new Date()

    let month = d.getMonth()
    let currentYear = d.getFullYear()
    let previousYear = currentYear - 1

    //for previous year
    if (month !== 0) {
        for (let i = month + 1; i <= 11; i++) {
            retItems.push(mappedMonths[i] + '' + previousYear)
        }

        for (let i = 0; i <= month; i++) {
            retItems.push(mappedMonths[i] + '' + currentYear)
        }
    } else {
        for (let i = 0; i <= 11; i++) {
            retItems.push(mappedMonths[i] + '' + currentYear)
        }
    }

    return retItems

}

const getMappedMonths = () => {
    return [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]
}