/**
 * @file This file defines for backlist customer details
 * @author Umesh Ranthilina
 */

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Table from "react-table";
import "react-table/react-table.css";
import Permission from "../../utils/Permission";
import { toast } from "react-toastify";
import { addBacklistCustomer, getBacklistCustomer, updateBacklistCustomer } from '../../../actions/Ordering/ordering_order_action';
import FormProvider from "../../components/FormProvider";
import GridFilter from '../../components/GridFilter';
import { DateNumberFormat } from '../../utils';
import ActionsCell from "../../components/UpdateActionsCell";
import { Field } from "redux-form";

const statusData = [{ value: 'Active', label: 'Active' }, { value: 'Inactive', label: 'Inactive' }]
const reasonTypeData = [{ value: 'Payment', label: 'Payment' }, { value: 'Missing', label: 'Missing' },{ value: 'Damage', label: 'Damage' },{ value: 'Delivery', label: 'Delivery' },{ value: 'Behavior', label: 'Behavior' }]

export const BacklistCustomer = props => {

    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [backlistCustomer, setBacklistCustomer] = useState({ telephone: '', customerName: '', reason: '', status: '' , type: ''})

    const addBacklistCustomerHandler = () => {
        if (backlistCustomer.telephone !== '' && backlistCustomer.customerName !== '' && backlistCustomer.type !== '' && backlistCustomer.status !== '') {
            if (isNaN(backlistCustomer.telephone)) {
                toast.error('telephone must be a number!')
            } else {
                const data = {
                    ...backlistCustomer,
                    userId: props.userId
                };
                props.addBacklistCustomer(data, props.token)
            }
        } else {
            toast.error('Backlist customer details are mandatory!')
        }
    }

    useEffect(() => {
        props.getBacklistCustomer(props)
    }, [])

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
            },
            updPerm: Permission('ORDERS', 'BACKLIST CUSTOMER', 'UPDATE', props),
        }) ||
        {};

    const selectableColumnProps = {
        ...GridFilter,
        Cell: Props => {
            const edit = editing === Props.original;
            console.log(Props.column.Header)
            return (edit ?
                <Field name={Props.column.id} component="select" className='form-control'>
                    {(Props.column.Header === 'Status' ? statusData : '').map(data =>
                        <option
                            key={Props.column.Header === 'Status' ? data.value : ''} value={Props.column.Header === 'Status' ? data.value : ''}>
                            {Props.column.Header === 'Status' ? data.label : ''}
                        </option>
                    )}
                </Field> : <label>{statusData ? Props.value : ''}</label>)
        }
    }

    const columns = [
        { Header: "Telephone", accessor: "backlistCustomer.telephone", ...GridFilter },
        { Header: "Customer", accessor: "backlistCustomer.customerName", ...GridFilter },
        { Header: "Reason Type", accessor: "backlistCustomer.type", ...GridFilter },
        { Header: "Reason", accessor: "backlistCustomer.reason", ...GridFilter },
        { Header: "User", accessor: "users.name.first", ...GridFilter },
        {
            Header: "created At", accessor: "backlistCustomer.createdAt", ...GridFilter,
            Cell: props => {
                if (props.original?.backlistCustomer?.createdAt !== undefined) {
                    var date = props.original?.backlistCustomer?.createdAt;
                    var dateTime = DateNumberFormat(date || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        }, {
            Header: "Active/Inactive Date", accessor: "backlistCustomer.activeDate", ...GridFilter,
            Cell: props => {
                if (props.original?.backlistCustomer?.status !== undefined) {
                    if (props.original?.backlistCustomer?.activeDate !== undefined && props.original?.backlistCustomer?.status === 'Active') {
                        var today = new Date(props.original?.backlistCustomer?.activeDate)
                        var time = today.toLocaleTimeString();
                        var date = props.original?.backlistCustomer?.activeDate;
                        var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                        return <>{dateTime}</>
                    } else {
                        var today = new Date(props.original?.backlistCustomer?.inactiveDate)
                        var time = today.toLocaleTimeString();
                        var date = props.original?.backlistCustomer?.inactiveDate;
                        var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                        return <>{dateTime}</>
                    }
                } else {
                    return <>{''}</>
                }
            }
        },
        { Header: "Status", accessor: "backlistCustomer.status", ...selectableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const handleSubmit = values => {
        if (deleting === null && editing !== null) {
            var data = {
                '_id': values.backlistCustomer._id,
                'status': values.backlistCustomer.status === undefined ? '' : values.backlistCustomer.status,
                'userId': props.userId
            };

            props.updateBacklistCustomer(data, props.token);
        }
    };

    return (
        <div className="right_col" role="main" >
            <div className="card">
                <div className="card-header">
                    <button className="btn btn-primary" disabled={Permission('ORDERS', 'BACKLIST CUSTOMER', 'ADD', props)} data-toggle="modal" data-target="#addBacklistCustomer"
                        onClick={() => setBacklistCustomer({ telephone: '', customerName: '', reason: '', status: '', type: '' })}
                    >
                        + Backlist Customer
                    </button>
                </div>
                <div className="card-body">
                    <FormProvider
                        form="inline"
                        onSubmit={handleSubmit}
                        onSubmitSuccess={() => { setEditing(null); setDeleting(null); }}
                        initialValues={editing === null ? deleting : editing}
                        enableReinitialize

                    >
                        {formProps => {
                            return (
                                <form onSubmit={formProps.handleSubmit}>
                                    <Table className="-striped"
                                        columns={columns}
                                        data={props.backlistCustomers}
                                        defaultPageSize={10}
                                        showPageJump={false}
                                    />
                                </form>
                            );
                        }}
                    </FormProvider>
                </div>
            </div>

            <div id="addBacklistCustomer" className="modal fade" role="dialog" >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Add Backlist Customer</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td>Telephone :</td>
                                            <td>
                                                <input className='form-control' value={backlistCustomer.telephone} autoComplete={false} onChange={e => setBacklistCustomer({ ...backlistCustomer, telephone: e.target.value })} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Customer Name :</td>
                                            <td>
                                                <input className='form-control' value={backlistCustomer.customerName} autoComplete={false} onChange={e => setBacklistCustomer({ ...backlistCustomer, customerName: e.target.value })} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Reason Type :</td>
                                            <td>
                                                <select className='form-control' value={backlistCustomer.type} onChange={e => setBacklistCustomer({ ...backlistCustomer, type: e.target.value })}>
                                                    <option value={''}>- Select Type -</option>
                                                    {reasonTypeData.map(data => <option value={data.value}>{data.label}</option>)}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Reason :</td>
                                            <td colSpan={3}>
                                                <textarea className='form-control' value={backlistCustomer.reason} autoComplete={false} onChange={e => setBacklistCustomer({ ...backlistCustomer, reason: e.target.value })} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Status :</td>
                                            <td>
                                                <select className='form-control' value={backlistCustomer.status} onChange={e => setBacklistCustomer({ ...backlistCustomer, status: e.target.value })}>
                                                    <option value={''}>- Select Status -</option>
                                                    {statusData.map(data => <option value={data.value}>{data.label}</option>)}
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={addBacklistCustomerHandler}>Add</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        userProgramme: state.rLogin.programme,
        isLoading: state.rLogin.loading,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userId: state.rLogin.id,
        backlistCustomers: state.rOrdering.backlistCustomers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addBacklistCustomer: (data, token) => { dispatch(addBacklistCustomer(data, token)) },
        getBacklistCustomer: props => { dispatch(getBacklistCustomer(props)) },
        updateBacklistCustomer: (data, token) => { dispatch(updateBacklistCustomer(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BacklistCustomer)