/**
 * @author Ehsan Elahi
 */

import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import { toast } from "react-toastify";
import _ from 'lodash';

// import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import { Permission, TYPE_DATA as typeData, WEEKDAYDATA as weekDayData, TIMEDATA as timeDropDownData, DateNumberFormat } from '../../utils';

import { getWorkingCalenderData, addWorkingCalender, updateWorkingCalender, deleteWorkingCalender, addRegionToDeliveryCalender, deleteRegionDeliveryCalender, updateRegionDeliveryCalender } from '../../../actions/Basic_data/working_calender_actions';
import { NavLink } from "react-router-dom";
import { getRegionData } from "../../../actions/Basic_data/basic_data_action";

// const ReactTableFixedColumns = withFixedColumns(Table);

function DeliveryCalender(props) {

    // const [workingCalenderData, setWorkingCalenderData] = useState('');
    const [day, setDay] = useState('');
    const [type, setType] = useState('');
    const [weekDay, setWeekDay] = useState('');
    // const [hours, setHours] = useState(0);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    // const [disabledButton, setDisabledButton] = useState(true);
    // const [sortOptions, setSortOptions] = useState();
    const [deliveryDate, setDeliveryDate] = useState('');
    const [id, setId] = useState('');
    const [region, setRegion] = useState('');
    const [cutOffDate, setCutOffDate] = useState('');
    const [cutOffTime, setCutOffTime] = useState('');
    const [expanded, setExpanded] = useState({});
    const [dayRegion, setDayRegion] = useState('')


    useEffect(() => {
        props.getWorkingCalenderData(props.token)
        props.getRegionData(props)
    }, [])

    useEffect(() => {
        var dayRegion = _.filter(props.workingCalenderData, { _id: id })[0]?.day
        setDayRegion(dayRegion)
    }, [id])

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    // const editableColumnProps = {
    //     ...GridFilters,
    //     Cell: props => {
    //         const edit = editing === props.original;
    //         const fieldProps = {
    //             component: editableComponent,
    //             edit,
    //             props
    //         };

    //         return <Field name={props.column.id} {...fieldProps} />;
    //     }
    // };

    const editableDateColumnProps = {
        ...GridFilters,
        Cell: Props => {
            const edit = editing === Props.original;
            // const fieldProps = {
            //     component: this.editableComponent,
            //     editing,
            //     props
            // };
            return (edit ?
                <DatePicker name={Props.column.id} onChange={setDayForUpdate} selected={day === '' ? new Date(Props.value) : day} className="form-control" maxDate={new Date(deliveryDate)} />
                // <input type='date' name={props.column.id} className='form-control' minDate={Date.now()} />
                : <label>{Props.value}</label>)
        }
    };

    // const editableSelectComponent = ({ input, editing, value, ...rest }) => {
    //     const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
    //     const children =
    //         (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    //     return <Component {...input} {...rest} children={children} />;
    // };

    const selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const edit = editing === props.original;
            // const fieldProps = {
            //     component: this.renderDropdownList,
            //     editing,
            //     props
            // };

            return (edit ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    props.column.Header === 'Week Day' ? weekDayData :
                        props.column.Header === 'Start Time' ? timeDropDownData :
                            props.column.Header === 'End Time' ? timeDropDownData :
                                props.column.Header === 'Time' ? timeDropDownData :
                                    typeData).map(data =>
                                        <option key={data._id} value={data._id}>{data.name}</option>
                                    )}
            </Field> : <label>{props.value}</label>)
        }
    };

    const setStateForEdit = (column, value) => {
        if (column === 'Type') {
            // this.setState({ type: value })
            setType(value)
        } else if (column === 'Week Day') {
            this.setState({ weekDay: value })
            setWeekDay(value)
        } else if (column === 'Start Time') {
            // this.setState({ startTime: value })
            setStartTime(value)
        } else if (column === 'End Time') {
            // this.setState({ endTime: value })
            setEndTime(value)
        }
    }

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    setEditing(rowProps.original)
                    // setDay(new Date(rowProps.original.day))
                },
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'WORKING CALENDER', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'WORKING CALENDER', 'DELETE', props)
        }) ||
        {};

    // const getRegionActionProps = (gridState, rowProps) => {
    //     return (rowProps && {
    //         mode: editing === rowProps.original ? "edit" : "view",
    //         actions: {
    //             onEdit: () => {
    //                 setEditing(rowProps.original)
    //                 setDay(new Date(rowProps.original.day))
    //             },
    //             onCancel: () => setEditing(null),
    //             onDelete: () => deleting(rowProps.original)
    //         },
    //         updPerm: Permission('BASIC DATA', 'WORKING CALENDER', 'UPDATE', props),
    //         delPerm: Permission('BASIC DATA', 'WORKING CALENDER', 'DELETE', props)
    //     }) ||
    //         {}
    // };

    const handleSubmit = values => {

        if (deleting === null && editing !== null) {
            if (editing.name) {
                // console.log('region', values);
                let data = { ...values }
                data.cutOffDate = day === '' ? values.cutOffDate : formatDate(day)
                props.updateRegionDeliveryCalender(data, props)
                setDayForUpdate('')
            } else {
                console.log(values);
                let data = { ...values };

                // data.day = formatDate(day);
                // data.type = this.state.type;
                // data.weekDay = this.state.weekDay;
                // data.startTime = this.state.startTime;
                // data.endTime = this.state.endTime;
                data.hours = calculateTimeDifference(data.startTime, data.endTime);

                if (data.type === 'Holiday') {
                    data.hours = 0;
                    data.startTime = '';
                    data.endTime = '';
                }

                props.updateWorkingCalender(data, props.token)

                toast.success('Successfully Updated Working Calender!')

                // this.setState({
                //     day: '',
                //     type: '',
                //     weekDay: '',
                //     startTime: '',
                //     endTime: '',
                // });
            }
        } else if (deleting !== null && editing === null) {

            if (values.name) {
                // console.log('test');
                let data = {
                    id: values.id,
                    '_id': values.region
                }
                props.deleteRegionDeliveryCalender(data, props)
            } else {
                // console.log('delte');
                let data = {
                    '_id': values._id
                }

                props.deleteWorkingCalender(data, props.token)
            }
        }
    };

    const addDeliveryRegion = (id, { region, cutOffDate, cutOffTime }) => {
        // console.log(region);
        if (region !== '' && cutOffDate !== '' && cutOffTime !== '') {
            let data = { id, region, cutOffDate: formatDate(cutOffDate), cutOffTime }
            data.create_user = props.loggedUser
            data.userId = props.userId
            props.addRegionToDeliveryCalender(data, props)
            setRegion('')
            setCutOffDate('')
            setCutOffTime('')
        }
    }

    const formatDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const setDayForUpdate = date => {
        setDay(date)
    }


    // const setSaturdayType = (hours, index) => {

    //     switch (index) {
    //         case 1:
    //             if (Number(hours) > 0 && this.state.w1WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w1Type: 'Working Day'
    //                 });
    //             } else if (this.state.w1WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w1Type: 'Holiday'
    //                 });
    //             }
    //             break;
    //         case 2:
    //             if (Number(hours) > 0 && this.state.w2WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w2Type: 'Working Day'
    //                 });
    //             } else if (this.state.w2WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w2Type: 'Holiday'
    //                 });
    //             }
    //             break;
    //         case 3:
    //             if (Number(hours) > 0 && this.state.w3WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w3Type: 'Working Day'
    //                 });
    //             } else if (this.state.w3WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w3Type: 'Holiday'
    //                 });
    //             }
    //             break;
    //         case 4:
    //             if (Number(hours) > 0 && this.state.w4WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w4Type: 'Working Day'
    //                 });
    //             } else if (this.state.w4WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w4Type: 'Holiday'
    //                 });
    //             }
    //             break;
    //         case 5:
    //             if (Number(hours) > 0 && this.state.w5WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w5Type: 'Working Day'
    //                 });
    //             } else if (this.state.w5WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w5Type: 'Holiday'
    //                 });
    //             }
    //             break;
    //         case 6:
    //             if (Number(hours) > 0 && this.state.w6WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w6Type: 'Working Day'
    //                 });
    //             } else if (this.state.w6WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w6Type: 'Holiday'
    //                 });
    //             }
    //             break;
    //         case 7:
    //             if (Number(hours) > 0 && this.state.w7WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w7Type: 'Working Day'
    //                 });
    //             } else if (this.state.w7WeekDay === 'Saturday') {
    //                 this.setState({
    //                     w7Type: 'Holiday'
    //                 });
    //             }
    //             break;
    //         default:
    //             break
    //     }

    // }

    const calculateTimeDifference = (startTime, endTime) => {
        var startTimeArr = startTime?.split(":");
        var endTimeArr = endTime?.split(":");

        var hours = endTimeArr[0] - startTimeArr[0];
        var minutes = endTimeArr[1] - startTimeArr[1];

        if (minutes === -30) {
            minutes = 0.5;
            hours = hours - 1;
        }

        if (minutes === 30) {
            minutes = 0.5;
        }

        return Number(hours) + Number(minutes);
    }


    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    const workingCalenderColumns = [
        { Header: "Day", accessor: "day", ...GridFilters },
        { Header: "Type", accessor: "type", ...selectableColumnProps },
        { Header: "Week Day", accessor: "weekDay" },
        { Header: "Hours", accessor: "hours", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Start Time", accessor: "startTime", ...selectableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "End Time", accessor: "endTime", ...selectableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { id: "isDelivered", Header: "isDelivered", accessor: d => d.region.length > 0 ? "Yes" : "No", filterable: true, getProps: () => { return { style: { 'text-align': 'center' } } } },
        {
            Header: "Create Date/Time", accessor: "create_date", ...GridFilters,
            Cell: props => {
                if (props.original?.create_date !== undefined && props.original?.create_date !== null && props.original?.create_date !== '') {
                    var today = new Date(props.original?.create_date)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.create_date;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "User", accessor: "create_user.first", ...GridFilters,
            Cell: props => {
                if (props.original?.create_user !== undefined && props.original?.create_user !== null && props.original?.create_user !== '') {
                    return <>{props.original?.create_user.first}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ];

    const regionCalenderColumns = [
        { Header: "Name", accessor: "name" },
        { Header: "Day", accessor: "cutOffDate", ...editableDateColumnProps },
        // { Header: "Day", accessor: "cutOffDate", ...editableColumnProps },
        { Header: "Time", accessor: "cutOffTime", ...selectableColumnProps },
        {
            Header: "Create Date/Time", accessor: "create_date", ...GridFilters,
            Cell: props => {
                if (props.original?.create_date !== undefined && props.original?.create_date !== null && props.original?.create_date !== '') {
                    var today = new Date(props.original?.create_date)
                    var time = today.toLocaleTimeString();
                    var date = props.original?.create_date;
                    var dateTime = DateNumberFormat(date || '') + "-" + (time || '')
                    return <>{dateTime}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        {
            Header: "User", accessor: "create_user.first", ...GridFilters,
            Cell: props => {
                if (props.original?.create_user !== undefined && props.original?.create_user !== null && props.original?.create_user !== '') {
                    return <>{props.original?.create_user.first}</>
                } else {
                    return <>{''}</>
                }
            }
        },
        // { Header: "Time", accessor: "cutOffTime", ...editableColumnProps },
        {
            Header: <button id='addDelivRegion' className='btn' data-toggle='modal' data-target='#addDeliveryRegion' style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0 }} >+</button>,
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            sortable: false,
            getProps: getActionProps,
            // getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    {/* <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'WORKING CALENDER', 'ADD', this.props)} data-toggle="modal" data-target="#addWorkingCalender">+ Working Calender</button> */}
                    <NavLink to={'/basic data/add region working calender'} className='btn btn-primary'>+ Delivery Calender</NavLink>
                </div>
                <div className="card-body">
                    <React.Fragment>
                        <ErrorBoundary>
                            <FormProvider
                                form="inline"
                                onSubmit={handleSubmit}
                                onSubmitSuccess={() => { setEditing(null); setDeleting(null) }}
                                initialValues={editing === null ? deleting : editing}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                defaultSorted={[
                                                    {
                                                        id: "day",
                                                        desc: true
                                                    }
                                                ]}
                                                columns={workingCalenderColumns}
                                                data={props.workingCalenderData}
                                                defaultPageSize={10}
                                                expanded={expanded}
                                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                                defaultFilterMethod={filterCaseInsensitive}
                                                showPageJump={false}
                                                getTrProps={(state, rowInfo) => {
                                                    if (rowInfo?.original?.region?.length > 0) {
                                                        return {
                                                            style: {
                                                                background: '#90ee7f',
                                                                color: '#000'
                                                            }
                                                        }
                                                    } else {
                                                        return {}
                                                    }
                                                }}
                                                SubComponent={row => {
                                                    // this.setState({id:row.original?.id})
                                                    setId(row.original?._id)
                                                    setDeliveryDate(row.original.day)
                                                    const regionData = row.original?.region
                                                    // ?.map(obj => {
                                                    //     let val = _.filter(this.props.region, { _id: obj.region })
                                                    //     return ({ ...obj, id: row?.original?._id, name: val[0].name })
                                                    // })
                                                    // var da = this.setRegionData(row.original)
                                                    // id = row.original?._id
                                                    // setRegionData(row)

                                                    return (
                                                        <div>
                                                            <>
                                                                <ErrorBoundary>
                                                                    <FormProvider
                                                                        form="inline"
                                                                        // onSubmit={this.props.onSubmit}
                                                                        // onSubmit={handleSubmit}
                                                                        onSubmitSuccess={() => { setEditing(null); setDeleting(null) }}
                                                                        // initialValues={editing === null ? deleting : editing}
                                                                        initialValues={editing}
                                                                        enableReinitialize
                                                                    >
                                                                        {formProps => {
                                                                            return (
                                                                                <form onSubmit={formProps.handleSubmit}>
                                                                                    <Table
                                                                                        columns={regionCalenderColumns}
                                                                                        // data={[]}
                                                                                        data={regionData}
                                                                                        // data={deliveryRegion}
                                                                                        // expanded={{ [row.index]: true }}
                                                                                        defaultPageSize={5} />
                                                                                </form>
                                                                            )
                                                                        }}
                                                                    </FormProvider>
                                                                </ErrorBoundary>

                                                            </>
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </form>
                                    );
                                }}
                            </FormProvider>
                        </ErrorBoundary>
                    </React.Fragment>
                </div>
            </div>

            <ErrorBoundary>
                <div id='addDeliveryRegion' className="modal fade" role="dialog">
                    <div className="modal-dialog" role='document'>
                        <div className="modal-content animate">
                            <div className="modal-header">
                                <h4 className="modal-title text-uppercase">Add delivery region</h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">

                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        {/* <tr>{id} {dayRegion}</tr> */}
                                        <tr>
                                            <td style={{ width: '30%' }}>
                                                Region* :
                                            </td>
                                            <td>
                                                <select className='form-control' value={region} onChange={e => setRegion(e.target.value)}>
                                                    <option key={''} value={''}>- Select Region -</option>
                                                    {props.region.map(obj => <option key={obj._id} value={obj._id}>{obj.name}</option>)}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Cut off date* :
                                            </td>
                                            <td>
                                                <DatePicker onChange={e => setCutOffDate(e)} placeholderText="End Date" selected={cutOffDate} className="form-control" maxDate={new Date(dayRegion)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Cut off time* :
                                            </td>
                                            <td>
                                                <select className='form-control' value={cutOffTime} onChange={e => setCutOffTime(e.target.value)}>
                                                    <option key={''} value={''}>- Select Cutoff Time -</option>
                                                    {timeDropDownData.map(element => {
                                                        return <option key={element._id} value={element._id}>{element.name}</option>
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '30%' }}>Region* :</div>
                                        <div>
                                            <select className='form-control' value={region} onChange={e => setRegion(e.target.value)}>
                                                <option key={''} value={''}>- Select Region -</option>
                                                {props.region.map(obj => <option key={obj._id} value={obj._id}>{obj.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '30%' }}>Cut off date :</div>
                                        <div>
                                            <DatePicker onChange={e => setCutOffDate(e)} placeholderText="End Date" selected={cutOffDate} className="form-control" maxDate={Date.now()} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '30%' }}>Cut off time :</div>
                                        <div>
                                            <select className='form-control' value={cutOffTime} onChange={e => setCutOffTime(e.target.value)}>
                                                <option key={''} value={''}>- Select Region -</option>
                                                {timeDropDownData.map(element => {
                                                    return <option key={element._id} value={element._id}>{element.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => addDeliveryRegion(id, { region, cutOffDate, cutOffTime })}>Update now</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>

        </div >
    );
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        workingCalenderData: state.rWorkingCalender.workingCalenderData,
        region: state.rBasicData.region,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        loggedUser: state.rLogin.name,
        userId: state.rLogin.id,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getWorkingCalenderData: (token) => { dispatch(getWorkingCalenderData(token)) },
        getRegionData: props => { dispatch(getRegionData(props)) },
        addWorkingCalender: (data, token) => { dispatch(addWorkingCalender(data, token)) },
        updateWorkingCalender: (data, token) => { dispatch(updateWorkingCalender(data, token)) },
        deleteWorkingCalender: (data, token) => { dispatch(deleteWorkingCalender(data, token)) },
        addRegionToDeliveryCalender: (data, props) => { dispatch(addRegionToDeliveryCalender(data, props)) },
        updateRegionDeliveryCalender: (data, props) => { dispatch(updateRegionDeliveryCalender(data, props)) },
        deleteRegionDeliveryCalender: (data, props) => { dispatch(deleteRegionDeliveryCalender(data, props)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCalender);
