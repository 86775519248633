/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";

import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import { getItemData, getUserData, getCurrencyData } from '../../../actions/Basic_data/basic_data_action';
import { getItemPrices, updateItemPrice, deleteItemPrice, addItemPrice } from '../../../actions/Basic_data/item_prices_actions';
import ErrorBoundary from "../../components/ErrorBoundary";
import { element, string } from "prop-types";
import Permission from '../../utils/Permission';
import SearchSelectComponent from "../../components/SearchSelectComponent";
import SearchableSelectComp from "../../components/SearchableSelectComp";

const ReactTableFixedColumns = withFixedColumns(Table);

class ItemPrices extends React.Component {

    state = {
        itemPrices: [],
        items: [],
        users: [],
        currencies: [],
        priceUnit: '',
        discountPercentage: 0,
        item: '',
        user: '',
        slug: '',
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'itemprices.priceUnit', desc: false }],
    };

    componentDidMount() {
        this.props.getItemPrices(this.props.token);
        this.props.getItemData(this.props.token);
        this.props.getUserData(this.props.token);
        this.props.getCurrencyData(this.props.token);
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.renderDropdownList,
                editing,
                props
            };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' defaultValue={props.value} onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    props.column.Header === 'Price Unit' ? this.props.currencies :
                            this.props.users).map(data =>
                                <option key={data._id} value={props.column.Header === 'Price Unit' ? data.name : data._id}>{props.column.Header === 'User' ? data.name.first : data.name}</option>
                            )}
            </Field> : <label>{props.value}</label>)
        }
    };

    searchableColumnProps = {
        ...GridFilters,
        Cell: props => {
            let identifer = "Class"
            const editing = this.state.editing === props.original;
            const options = this.props.items.map(data => ({ label: data.name, value: data._id }));

            return (editing ?
                <Field name={props.column.id} component={SearchSelectComponent} options={options} value={props.value} onChange={e => this.setStateForEdit(props.column.Header, e.value)} identifier={identifer} />
                : <label>{props.value}</label>)
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        user: rowProps.original.users._id,
                        item: rowProps.original.items._id,
                        priceUnit: rowProps.original.itemprices.priceUnit
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'USER DISCOUNTS', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'USER DISCOUNTS', 'DELETE', this.props)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === "Item") {
            this.setState({ item: value });
        } else if (column === "User") {
            this.setState({ user: value });
        } else if (column === "Price Unit") {
            this.setState({ priceUnit: value });
        }
    }

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values.itemprices;

            data.priceUnit = this.state.priceUnit;
            data.user = this.state.user;
            data.item = this.state.item;
            data.discountPercentage = Number(data.discountPercentage);

            this.props.updateItemPrice(data, this.props.token)

            this.setState({
                priceUnit: '',
                user: '',
                item: ''
            });

        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values.itemprices._id
            }

            this.props.deleteItemPrice(data, this.props.token)
        }
    };

    addItemPriceHandle = () => {
        var { priceUnit, discountPercentage, item, user } = this.state;

        if (priceUnit.trim() !== '' || item.trim() !== '' || user.trim() !== '') {

            this.state.slug = 1;

            this.props.addItemPrice(this.state, this.props.token)

            this.setState({
                priceUnit: '',
                discountPercentage: '',
                user: '',
                item: ''
            });

            document.getElementById("addItemPriceForm").reset();
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined' && content !== '') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

        return true;
    };

    itemPricesColumns = [
        { Header: "Price Unit", accessor: "itemprices.priceUnit", ...this.selectableColumnProps },
        { Header: "Discount Percentage", accessor: "itemprices.discountPercentage", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
        {
            Header: "Item",
            accessor: "items.name",
            minWidth: 150,
            ...this.searchableColumnProps,
            getProps: (state, rowInfo, column) => {
                return {
                    style: {
                        cursor: 'pointer',
                        overflow: 'visible',
                    },
                };
            },
        },
        { Header: "User", accessor: "users.name.first", ...this.selectableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'USER DISCOUNTS', 'ADD', this.props)} data-toggle="modal" data-target="#addItemPrice">+ User Discounts</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null, shelflife: '', deliverytype: '', supplier: '' })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.itemPricesColumns}
                                                    data={this.props.itemPrices}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>

                    <ErrorBoundary>
                        <div id="addItemPrice" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Add Item Price</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <React.Fragment>
                                            <form id='addItemPriceForm'>
                                                <table>
                                                    <tbody>

                                                        <tr>
                                                            <td>Price Unit* :</td>
                                                            <td>
                                                                <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ priceUnit: e.target.value })}>
                                                                    <option value={''}>- Select Price Unit -</option>
                                                                    {this.props.currencies.map(element => {
                                                                        return <option value={element.name}>{element.name}</option>
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Item* :</td>
                                                            <td> 
                                                                {/* <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ item: e.target.value })}>
                                                                    <option value={''}>- Select Item -</option>
                                                                    {this.props.items.map(element => {
                                                                        return <option value={element._id}>{element.name}</option>
                                                                    })}
                                                                </select> */}
                                                                <SearchableSelectComp options={this.props.items} label={'name'} value={this.state.item} onChange={e => e.value === '' ? null : this.setState({ item: e.value })}/>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>User* :</td>
                                                            <td>
                                                                <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ user: e.target.value })}>
                                                                    <option value={''}>- Select User -</option>
                                                                    {this.props.users.map(element => {
                                                                        return <option value={element._id}>{element.name.first}</option>
                                                                    })}
                                                                </select>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                    <div className="modal-footer">
                                        <button id="" type="button" className="btn btn-primary" onClick={this.addItemPriceHandle}>Add</button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>



                    <div className="card-footer"></div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        itemPrices: state.rItemPrices.itemPrices,
        users: state.rBasicData.users,
        items: state.rBasicData.item_data,
        currencies: state.rBasicData.currency_data,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getItemPrices: (token) => { dispatch(getItemPrices(token)) },
        getItemData: (token) => { dispatch(getItemData(token)) },
        getUserData: (token) => { dispatch(getUserData(token)) },
        getCurrencyData: (token) => { dispatch(getCurrencyData(token)) },
        updateItemPrice: (data, token) => { dispatch(updateItemPrice(data, token)) },
        deleteItemPrice: (data, token) => { dispatch(deleteItemPrice(data, token)) },
        addItemPrice: (data, token) => { dispatch(addItemPrice(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemPrices);


