/**
 * @file This file defined for order history form
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Field } from "redux-form";

import Table from "react-table";
// import $ from 'jquery';
import * as BS from "react-bootstrap";
// import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
// import jwt from 'jsonwebtoken';

import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormProvider from "../../components/FormProvider";
// import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";

import ErrorBoundary from "../../components/ErrorBoundary";
import { getOrderHistoryData, getOrderHistoryReportData } from '../../../actions/Ordering/ordering_order_history_action';
import { getPaymentMethodData, getCityData, getRegionData } from '../../../actions/Basic_data/basic_data_action';
// import { toast } from 'react-toastify';
import NumberRangeColumnFilter from '../../components/column_filter/NumberRangeColumnFilter'
import dotenv from 'dotenv'
import NumberGridFilter from '../../components/NumberGridFilter';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { ExportData } from '../../utils';

dotenv.config()
const status = ['Initiated', 'Processed', 'Dispatched', 'Delivered'];

const toDate = () => {
    let toDate = new Date().toISOString().split('T')
    let splitTime = toDate[1].split('.')
    let timeString = `23:59:59.${splitTime[1]}`
    let newToDate = `${toDate[0]}T${timeString}`
    return newToDate;
}

function OrderingHistory(props) {

    // const [selectColumn, setSelectColumn] = useState('');
    // This state defined for react select field clear
    // const [total, setTotal] = useState({ qty: 0, value: 0 });
    const [order_id, setOrder_id] = useState(null);
    const [order, setOrder] = useState(null);
    const [orderItems, setOrderItems] = useState(null);
    const [orderItemsData, setOrderItemsData] = useState(null);
    const [edit, setEdit] = useState(null)
    const [remove, setRemove] = useState(null)
    const [expanded, setExpanded] = useState({});
    const [exportRegion, setExportRegion] = useState(null);
    const [exportDataStatus, setExportDataStatus] = useState(false);

    const fromDate = () => {
        let fromDate = new Date();
        fromDate.setFullYear(fromDate.getFullYear() - 1);
        let newFromDate = fromDate.toISOString().split('T')[0]
        return newFromDate;
    }

    const [date, setDate] = useState({ from: fromDate(), to: toDate() })
    const [exportDate, setExportDate] = useState({ from: fromDate(), to: toDate() })

    useEffect(() => {
        props.getOrderHistoryData(props.token, date);
        props.getPaymentMethodData(props.token);
        props.getRegionData(props)
    }, [])

    useEffect(() => {
        if (orderItems !== null) {
            axios.defaults.headers.common['authorization'] = props.token;
            axios.post('/orderHistory/getOrderItemHistory', { orderItems })
                .then(result => {
                    console.table(result.data)
                    setOrderItemsData(result.data)
                }).catch(err => {
                    console.error(err)
                })
        }
    }, [orderItems])

    const getData = () => {
        props.getOrderHistoryData(props.token, date);
    }

    // const getActionProps = (gridState, rowProps) =>
    //     (rowProps && {
    //         mode: edit === rowProps.original ? "edit" : "view",
    //         actions: {
    //             onEdit: () => setEdit(rowProps.original),
    //             onCancel: () => setEdit(null),
    //             onDelete: () => setRemove(rowProps.original),
    //         }
    //     }) ||
    //     {};

    const editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component  {...input} {...rest} children={children} />;
    };

    const editableNumberComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component type="number"  {...input} {...rest} children={children} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />;
    };

    const renderDateTimePicker = ({ input, editing, value, Props, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        return editing && Props.original.orderhistories.status !== 'Delivered' ? <Component type="datetime-local"  {...input} style={{ maxHeight: editing ? '35px' : '10px', margin: '0px', padding: '0px', textAlign: 'right' }} />
            : <label>{new Date(Props.value).toLocaleDateString()} - {new Date(Props.value).toLocaleTimeString()}</label>;

    }

    const editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableComponent,
                editing,
                props
            };
            return (props.original.orderhistories !== undefined && (props.original.orderhistories.status === 'Delivered' && props.column.Header === 'Address')) ? <>{props.value}</> : <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const editableNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = edit === props.original;
            const fieldProps = {
                component: editableNumberComponent,
                editing,
                props
            };
            return (props.original.orderhistories !== undefined && (props.original.orderhistories.status === 'Delivered' && props.column.Header !== 'Amount Paid' && props.column.Header !== 'Delivery Charges')) ? <div style={{ textAlign: 'right' }}>{props.value}</div> : <Field name={props.column.id} {...fieldProps} />;
        }
    };

    const selectableColumnProps = {
        ...GridFilters,
        Cell: Props => {
            const editing = edit === Props.original;
            // const fieldProps = {
            //     component: editableSelectComponent,
            //     editing,
            //     Props
            // };

            return ((editing && Props.original.orderhistories !== undefined && (Props.column.Header !== 'Status' || Props.original.orderhistories.status !== 'Delivered')) ?
                <Field name={Props.column.id} component="select" className='form-control' value={Props.value}>
                    {(Props.column.Header === 'Payment Mehtods' ? props.paymentMethods : Props.column.Header === 'City' ? props.city : status).map(data =>
                        <option key={Props.column.Header === 'Payment Mehtods' || Props.column.Header === 'City' ? data._id : data} value={Props.column.Header === 'Payment Mehtods' || Props.column.Header === 'City' ? data._id : data}>{Props.column.Header === 'Payment Mehtods' || Props.column.Header === 'City' ? data.name : data}</option>
                    )}
                </Field> : <label>{Props.value}</label>)

        }
    };

    // const editableSelectComponent = ({ Props }) => {
    //     return <><select defaultValue={Props.value} className='form-control'>
    //         {(Props.column.Header === 'Payment Mehtods' ? props.paymentMethods : status).map(data =>
    //             <option key={Props.column.Header === 'Payment Mehtods' ? data._id : data} value={Props.column.Header === 'Payment Mehtods' ? data._id : data}>{Props.column.Header === 'Payment Mehtods' ? data.name : data}</option>
    //         )}
    //     </select></>
    // }

    const selectableDateColumnProps = {
        // ...GridFilters,
        Cell: Props => {
            const editing = edit === Props.original;
            const fieldProps = {
                component: renderDateTimePicker,
                editing,
                Props
            };

            return (<Field name={Props.column.id} {...fieldProps} />)
        }
    };

    const handleRowExpanded = (rowsState, index) => {
        setExpanded({
            [index[0]]: !expanded[index[0]],
        })
    }

    const columns = [
        {
            Header: "Order Id", accessor: "orderhistories.orderId", ...GridFilters,
        },
        { Header: "Name", accessor: "orderhistories.name", ...GridFilters },
        { Header: "Telephone", accessor: "orderhistories.telephone", ...NumberGridFilter },
        { Header: "Whatsapp", accessor: "orderhistories.whatsapp", ...editableColumnProps },
        { Header: "Address", accessor: "orderhistories.address", ...editableColumnProps },
        { Header: "City", accessor: "cities.name", ...selectableColumnProps },
        { Header: "Payment Mehtods", accessor: "paymentmethods.name", ...selectableColumnProps },
        { Header: "Status", accessor: "orderhistories.status", ...selectableColumnProps },
        { Header: "Pick up", accessor: "orderhistories.orderPickUp" },
        {
            Header: "Amount Paid", accessor: "orderhistories.amountPaid", ...editableNumberColumnProps
        },
        {
            Header: "Delivery Charges", accessor: "orderhistories.deliveryCharges", ...editableNumberColumnProps
        },
        {
            Header: "Tax", accessor: "orderhistories.tax", ...editableNumberColumnProps
        },
        {
            Header: "Order Discount %", accessor: "orderhistories.totalDiscount", ...editableNumberColumnProps
        },
        {
            Header: "Total Price", accessor: "orderhistories.totalPrice",
            // filterable: true,
            NumberRangeColumnFilter,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.orderhistories.totalPrice}
                </div>
        },
        {
            Header: "Etd.", accessor: "orderhistories.etd",
            ...selectableDateColumnProps
        },
        {
            Header: "Note", accessor: "orderhistories.note", ...editableColumnProps
        },
        {
            Header: "Mobile Order",
            accessor: "orderhistories.isFromMobile",
            filterable: true,
            getProps: (state, row, column) => {
                return {
                    style: {
                        backgroundColor: (row && (row.original.orderhistories.isFromMobile === "Yes")) ? 'yellow' : '',
                    }
                }
            },
            Cell: row =>
                <div>
                    {row.original.orderhistories.isFromMobile}
                </div>

        },
    ]

    const subColumns = [
        { Header: "Item Code", accessor: "items.itemCode", filterable: true },
        { Header: "Name", accessor: "items.name", filterable: true },
        {
            Header: "Per Item Price", accessor: "items.price", filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.items.price}
                </div>
        },
        { Header: `Quantity - ${_.sumBy(orderItemsData, 'orderitemhistories.quantity') || 0}`, accessor: "orderitemhistories.quantity", ...editableNumberColumnProps },
        { Header: "Item Discount %", accessor: "orderitemhistories.discount", ...editableNumberColumnProps },
        {
            Header: "Price", accessor: "orderitemhistories.price", filterable: true,
            Cell: props =>
                <div style={{ textAlign: 'right' }}>
                    {props.original.orderitemhistories.price}
                </div>
        },
    ]

    const handleOrderSubmit = value => {
    }

    const excelExportOrderHistory = () => {
        props.getOrderHistoryReportData({ from: exportDate.from, to: exportDate.to, status: exportDataStatus, region: exportRegion }, props.token, (data) => {
            exportData(data)
        })
    }

    /**
     * Export history data to excel
     */
    const exportData = (data) => {
        var excelData = data.map(checkData => {
            return {
                'Order Id': checkData.orderhistories.orderId,
                'Name': checkData.orderhistories.name,
                'Telephone': checkData.orderhistories.telephone,
                'Whatsapp no': checkData.orderhistories.whatsapp,
                'Address': checkData.orderhistories.address,
                'City': checkData.cities !== undefined ? checkData.cities.name : "",
                'Region': checkData.region !== undefined ? checkData.region.name : "",
                'Payment Method': checkData.paymentmethods.name,
                'Status': checkData.orderhistories.status,
                'Amount Paid': checkData.orderhistories.amountPaid,
                'Delivery Charge': checkData.orderhistories.deliveryCharges,
                'Tax': checkData.orderhistories.tax,
                'Order Discount': checkData.orderhistories.totalDiscount,
                'Total Price': checkData.orderhistories.totalPrice,
                'Note': checkData.orderhistories.note,
            }
        })
        ExportData(`Order History`, 'Order history details', "order history", excelData)
        setExportRegion('');
        setExportDataStatus(false);
        setExportDate({ from: fromDate(), to: toDate() })
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header" style={{ display: 'inline-flex' }}>
                    <div style={{ width: '30%', display: 'inline-flex' }}>
                        From :
                        <input type='date' className='form-control' style={{ width: '50%' }} defaultValue={date.from} onChange={e => {
                            console.log(e)
                            setDate({ ...date, from: new Date(e.target.valueAsDate) })
                        }} />
                        :&nbsp;
                        To :
                        <ErrorBoundary>
                            <input type='date' className='form-control' style={{ width: '50%' }} defaultValue={date.to.split('T')[0]}
                                onChange={e => {
                                    let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                    let splitTime = toDate[1].split('.')
                                    let timeString = `23:59:59.${splitTime[1]}`
                                    let newToDate = `${toDate[0]}T${timeString}`
                                    setDate({ ...date, to: newToDate })
                                }} />
                        </ErrorBoundary>
                    </div>
                    :&nbsp;
                    <div style={{ width: '30%' }}>
                        <button className='btn btn-success' disabled={props.isLoading} onClick={getData}>
                            {props.isLoading ? <div><i class="fa fa-spinner fa-pulse" aria-hidden="true"></i> Loading...</div> : 'Show Data'}
                        </button>
                        :&nbsp;
                        <button className="btn btn-info" disabled={props.isLoading} data-toggle="modal" data-target="#exportData" >Export Data</button>
                    </div>
                </div>

                <ErrorBoundary>
                    <div className="card-body">
                        {props.isLoading
                            ? <>
                                <SkeletonTable columnsCount={9} dataCount={10} />
                            </>
                            : <FormProvider
                                form="inline"
                                onSubmit={handleOrderSubmit}
                                onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                initialValues={edit !== null ? edit : remove}
                                enableReinitialize
                            >
                                {formProps => {
                                    return (
                                        <form onSubmit={formProps.handleSubmit}>
                                            <Table className="-striped"
                                                columns={columns}
                                                data={props.data}
                                                defaultPageSize={10}
                                                expanded={expanded}
                                                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index)}
                                                SubComponent={row => {
                                                    setOrder_id(row.original.orderhistories._id)
                                                    setOrder(row.original.orderhistories)
                                                    setOrderItems(row.original.orderhistories.orderItems)
                                                    return (
                                                        <div>
                                                            {orderItemsData !== null ?
                                                                <React.Fragment>
                                                                    <ErrorBoundary>
                                                                        <FormProvider
                                                                            form="inline"
                                                                            onSubmitSuccess={() => { setEdit(null); setRemove(null) }}
                                                                            initialValues={edit}
                                                                            enableReinitialize
                                                                        >
                                                                            {formProps => {
                                                                                return (
                                                                                    <form onSubmit={formProps.handleSubmit}
                                                                                    >
                                                                                        <Table
                                                                                            columns={row.original.orderhistories.status === 'Delivered' ? subColumns : [...subColumns]}
                                                                                            data={orderItemsData}
                                                                                            defaultPageSize={5} />
                                                                                    </form>
                                                                                )
                                                                            }}
                                                                        </FormProvider>
                                                                    </ErrorBoundary>
                                                                </React.Fragment>
                                                                :
                                                                orderItemsData === null ? <div>No found data...!</div> : <div>Loading...!</div>}
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </form>)
                                }}
                            </FormProvider>}
                    </div>
                </ErrorBoundary>

                <ErrorBoundary>
                    <div id="exportData" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Export data</h4>
                                    <button type="button" className="close" data-dismiss="modal"> &times;</button>
                                </div>
                                <div className="modal-body">
                                    <input type="radio" style={{ marginLeft: '10px', marginRight: '5px' }} checked={exportDataStatus ? false : true} onChange={e => { setExportDataStatus(false) }} /><label>Order Date</label>
                                    <input type="radio" style={{ marginLeft: "10px", marginRight: '5px' }} checked={exportDataStatus ? true : false} onChange={e => { setExportDataStatus(true) }} /> <label> Etd Date </label>
                                    <div className='card'>
                                        <div className='card-header' style={{ display: 'inline-flex' }}>
                                            <div>From :</div><input type='date' className='form-control' style={{ width: '40%' }} value={exportDate.from}
                                                onChange={e => {
                                                    setExportDate({ ...exportDate, from: e.target.value })
                                                }} />
                                            <div>To : </div><input type='date' className='form-control' style={{ width: '40%' }} value={exportDate.to.split('T')[0]}
                                                onChange={e => {
                                                    let toDate = new Date(e.target.valueAsDate).toISOString().split('T')
                                                    let splitTime = toDate[1].split('.')
                                                    let timeString = `23:59:59.${splitTime[1]}`
                                                    let newToDate = `${toDate[0]}T${timeString}`
                                                    setExportDate({ ...exportDate, to: newToDate })
                                                }} />
                                        </div>
                                        <div className='card-header' style={{ display: "inline-flex" }}>
                                            <div>Region :</div>
                                            <select value={exportRegion} className='form-control' onChange={e => setExportRegion(e.target.value)} style={{ width: "30%" }}>
                                                <option value={''}>All</option>
                                                {props.region.map(region => {
                                                    return <option key={region._id} value={region._id}>{region.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={excelExportOrderHistory}>Export now</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        data: state.rOrderHistory.orderHistory,
        paymentMethods: state.rBasicData.payment_method_data,
        city: state.rBasicData.city,
        token: state.rLogin.token,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        region: state.rBasicData.region,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderHistoryData: (token, date) => { dispatch(getOrderHistoryData(token, date)) },
        getPaymentMethodData: token => { dispatch(getPaymentMethodData(token)) },
        getCity: token => { dispatch(getCityData(token)) },
        getRegionData: props => { dispatch(getRegionData(props)) },
        getOrderHistoryReportData: (date, token, callback) => { dispatch(getOrderHistoryReportData(date, token, callback)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderingHistory);

