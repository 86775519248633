import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import '@fortawesome/fontawesome-free/css/all.min.css';
import App from './client/App';
import { store } from '../src/client/store/Store'
import * as serviceWorker from './client/serviceWorkerRegistration';

global.__basedir = __dirname;

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
           <App />
      </Switch>
    </Router>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
