/**
 * @author Ehsan Elahi
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const paymentMethodsAsync = data => {
    return { type: 'FETCH_PAYMENT_METHODS_DATA', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

/**
 * 
 * @param {*} token 
 */
export const getPaymentMethods = (token) => {
    return dispatch => {
        dispatch(isLoadingAsync(true))
        paymentMethodsAsync([])
        axios.defaults.headers.common['authorization'] = token;
        axios.get('/basic_data/paymentmethod/getPaymentMethods')
            .then(result => {
                dispatch(paymentMethodsAsync(result.data))
                dispatch(isLoadingAsync(false))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const updatePaymentMethod = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/paymentmethod/updatePaymentMethod', { data })
            .then(result => {
                toast.success('Successfully Updated Payment Method')
                dispatch(getPaymentMethods(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} data 
 * @param {*} token 
 */
export const deletePaymentMethod = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/paymentmethod/deletePaymentMethod', { data })
            .then(result => {
                toast.success('Payment Method Deleted Successfully!')
                dispatch(getPaymentMethods(token))
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * 
 * @param {*} param0 
 * @param {*} token 
 */
export const addPaymentMethod = ({ name, userType, isActive, slug, description }, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/paymentmethod/addPaymentMethod', {
            data: {
                name,
                userType,
                isActive,
                slug,
                description,
            }
        })
            .then(result => {
                window.$('#addPaymentMethod').modal('hide');
                toast.success('Successfully Added Payment Condition');
                dispatch(getPaymentMethods(token));
            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}