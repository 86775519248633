/**
 * @file This file defines to order action dispatch
 * @author Bhanuka Chathuranga
 */
import { toast } from 'react-toastify';
var axios = require('axios');

export const orderAsync = data => {
    return { type: 'FETCH_ORDER', data }
}

export const receivedOrderAsync = data => {
    return { type: 'FETCH_RECEIVED_ORDER', data }
}

export const purchaseItemAsync = data => {
    return { type: 'FETCH_PURCHASE_ITEM', data }
}

export const purchaseItemByIdAsync = data => {
    return { type: 'FETCH_PURCHASE_ITEM_BY_ID', data }
}

export const isLoadingAsync = data => {
    return { type: 'IS_LOADING', data }
}

export const purchaseCanceledItemAsync = data => {
    return { type: 'FETCH_CANCELED_PURCHASE_ITEM', data }
}

export const purchaseReceivedItemByIdAsync = data => {
    return { type: 'FETCH_PURCHASE_RECEIVED_ITEM_BY_ID', data }
}

export const getNewItemsAsync = data => {
    return { type: 'FETCH_NEW_ITEMS', data }
}

export const getNewAllItemsAsync = data => {
    return { type: 'FETCH_NEW_ALL_ITEMS', data }
}

/**
 * get purchasing order details 
 * 
 * @param {string} token 
 */
export const getOrderData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        // dispatch(orderAsync([]))
        axios.get('/purchasing/getOrder', { headers: { authorization: token } })
            .then(result => {
                // console.table(result.data)
                dispatch(isLoadingAsync(false))
                dispatch(orderAsync(result.data.reverse()))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get purchasing order details 
 * 
 * @param {string} token 
 */
export const getPurchaseItemData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getPurchaseItem', { headers: { authorization: token } })
            .then(result => {
                dispatch(purchaseItemAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get purchasing order details by Id
 * 
 * @param {string} token 
 */
export const getPurchaseItemDataById = (id, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getPurchaseItemById', { headers: { authorization: token }, params: { id } })
            .then(result => {
                dispatch(purchaseItemByIdAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get purchasing received order details by Id
 * 
 * @param {string} token 
 */
export const getPurchaseReceivedItemDataById = (id, { token }) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getReceivedItemById', { headers: { authorization: token }, params: { id } })
            .then(result => {
                dispatch(purchaseReceivedItemByIdAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To add new order 
 * 
 * @param {string} token 
 */
export const addOrder = (state, token) => {



    var paymentCondition = state.paymentCondition;

    const data = {
        _id: paymentCondition
    };


    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/basic_data/paymentcondition/getPaymentConditionById', { data })
            .then(result => {
                var description = result.data[0].description;
                var desArr = description.split(" ");
                var paid = false;

                const totalData = state.data;
                var total = 0;

                totalData.forEach(element => {
                    var totalPrice = Number(element.orderqty) * Number(element.price);
                    total = total + totalPrice;
                });

                state.total = total;

                var days = desArr[0];
                if (days === "Cash") {
                    days = 0;
                    paid = true;
                }

                var duedate = new Date();
                duedate.setDate(duedate.getDate() + Number(days));

                state.duedate = duedate;
                state.paid = paid;

                console.log(state);

                axios.defaults.headers.common['authorization'] = token;
                axios.post('/purchasing/addOrder', { state })
                    .then(result => {
                        toast.success('Added order...!')
                        dispatch(getOrderData(token))
                        dispatch(getPurchaseItemData(token))
                    }).catch(err => {
                        console.error(err)
                    })



            }).catch(err => {
                toast.error(`${err}`)
            })
    }
}

/**
 * get purchasing order update details 
 * 
 * @param {string} token 
 */
export const updateOrder = (data, token) => {

    return dispatch => {
        // console.log(data)
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/updateOrder', { data })
            .then(result => {
                // console.table(result.data)
                toast.success('Update order...!')
                // $('#addSupplier').modal('toggle')
                dispatch(getOrderData(token))
                dispatch(getPurchaseItemData(token))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

/**
 * get purchasing order delete details 
 * 
 * @param {string} token 
 */
export const deleteOrder = (data, token) => {

    return dispatch => {
        // console.log(data)
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/deleteOrder', { data: data.purchaseorder })
            .then(result => {
                // console.table(result.data)
                toast.success(result.data)
                // $('#addSupplier').modal('toggle')
                dispatch(getOrderData(token))
                dispatch(getPurchaseItemData(token))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

/**
 * get purchasing order item update details 
 * 
 * @param {string} token 
 */
export const updateOrderItem = (data, token) => {

    return dispatch => {
        // console.log(data)
        dispatch(isLoadingAsync(true))
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/updateOrderItem', { data })
            .then(result => {
                // console.table(result.data)
                toast.success('Update order...!')
                // $('#addSupplier').modal('toggle')
                dispatch(getOrderData(token))
                dispatch(getPurchaseItemDataById(data.POrders._id, { token: token }))
                dispatch(getPurchaseItemData(token))
                dispatch(isLoadingAsync(false))
            }).catch(err => {
                console.error(err)
                toast.error(`${err.response.data}`)
            })
    }
}

export const updateCustomerOrderByQty = (data, orderData, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/updateCustomerOrderByQty', { data, orderData })
            .then(result => {
                toast.success('Update order...!')
                dispatch(getOrderData(token))
                dispatch(getPurchaseItemData(token))
                dispatch(getPurchaseItemDataById(data.POrders._id, { token: token }))
            }).catch(err => {
                console.error(err)
            })
    }
}

/**
 * get purchasing order item delete 
 * 
 * @param {string} token 
 */
export const deleteItem = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/deleteItem', { data })
            .then(result => {
                // console.table(result.data)
                toast.success(result.data)
                // $('#addSupplier').modal('toggle')
                dispatch(getOrderData(token))
                dispatch(getPurchaseItemData(token))
                dispatch(getPurchaseItemDataById(data.POrders._id, { token: token }))
            }).catch(err => {
                console.error(err)
                // toast.error(`${err}`)
            })
    }
}

export const mergePurchaseOrders = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/mergePurchaseOrders', { data })
            .then(result => {
                dispatch(getOrderData(token));
                dispatch(getPurchaseItemData(token));
            }).catch(err => {
                console.error(err);
            })
    }
}

export const sendToHistory = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/sendToHistory', { data })
            .then(result => {
                toast.success('Successfully Sent to History!');
                dispatch(getOrderData(token));
                dispatch(getPurchaseItemData(token));
            }).catch(err => {
                console.error(err);
            })
    }
}

/**
 * get canceled purchasing order details
 * 
 * @param {string} token 
 */
export const getCanceledPurchaseItem = (token) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.get('/purchasing/getCanceledPurchase')
            .then(result => {
                dispatch(purchaseCanceledItemAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * To add new purchase order item 
 * 
 * @param {string} token 
 */

export const addPurchaseOrderItem = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/addPurchaseOrderItem', { data })
            .then(result => {
                toast.success('Added order item...!')
                dispatch(getOrderData(token))
                dispatch(getPurchaseItemData(token))
                dispatch(getPurchaseItemDataById(data.purchaseOrderId, { token: token }))
            }).catch(err => {
                console.error(err);
                toast.error(`${err}`)
            })
    }
}

export const updatePurchaseItemData = (data, token, callback) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/updatePurchaseItemData',data)
            .then(result => {
                callback(result.data)
                toast.success('update successfully...!')
                dispatch(getOrderData(token))
                dispatch(getPurchaseItemDataById(data.data.purchase_ref, { token: token }))
                dispatch(getNewItemsData({ id: data.data.purchaseItem_ref }, token))
                dispatch(getAllNewItemsData({ id: data.data.purchase_ref }, token))
            }).catch(err => {
                console.error(err);
                toast.error(`${err}`)
            })
    }
}

export const addPurchaseNewItemData = (data, token, callback) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/addPurchaseNewItemData',data)
            .then(result => {
                callback(result.data)
                //toast.success('update successfully...!')
                dispatch(getOrderData(token))
                if (data.data.Status !== "Multi") {
                    dispatch(getPurchaseItemDataById(data.data.purchase_ref, { token: token }))
                    dispatch(getNewItemsData({ id: data.data.purchaseItem_ref }, token))
                    dispatch(getAllNewItemsData({ id: data.data.purchase_ref }, token))
                }
            }).catch(err => {
                console.error(err);
                toast.error(`${err}`)
            })
    }
}

export const deletePurchaseNewItemData = (data, token,callback) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/deletePurchaseNewItemData',data)
            .then(result => {
                callback(result.data)
                toast.success('Delete successfully...!')
                dispatch(getOrderData(token))
                dispatch(getPurchaseItemDataById(data.data.purchase_ref, { token: token }))
                dispatch(getNewItemsData({ id: data.data.purchaseItem_ref }, token))
                dispatch(getAllNewItemsData({ id: data.data.purchase_ref }, token))
            }).catch(err => {
                console.error(err);
                toast.error(`${err}`)
            })
    }
}

/**
 * get purchasing order details 
 * 
 * @param {string} token 
 */
export const getReceivedOrdersData = token => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        dispatch(isLoadingAsync(true))
        axios.get('/purchasing/receivedOrders', { headers: { authorization: token } })
            .then(result => {
                dispatch(isLoadingAsync(false))
                dispatch(receivedOrderAsync(result.data.reverse()))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get purchasing egt new items details by Id
 * 
 * @param {string} token 
 */

export const getNewItemsData = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/getNewItemsData', { data })
            .then(result => {
                dispatch(getNewItemsAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * get purchasing egt new all items details by Id
 * 
 * @param {string} token 
 */

export const getAllNewItemsData = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/getAllNewItemsData', { data })
            .then(result => {
                dispatch(getNewAllItemsAsync(result.data))
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

/**
 * delete purchasing get new all items details by Id
 * 
 * @param {string} token 
 */

export const deleteAllReceiveItems = (data, token) => {
    return dispatch => {
        axios.defaults.headers.common['authorization'] = token;
        axios.post('/purchasing/deleteAllReceiveItems', { data })
            .then(result => {
                if(data.status == "Single"){
                    dispatch(getNewItemsData({ id: data.purchaseitem._id }, token))
                }else{
                    dispatch(getAllNewItemsData({ id: data.purchase_ref}, token))
                }
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}


/**
 * get order history report details 
 * 
 * @param {Array} data to/from
 * @param {string} token 
 */
export const getOrderReportData = (data, token, callback) => {
    axios.defaults.headers.common['authorization'] = token;
    return dispatch => {
        axios.post('/purchasing/getOrderReportData', { data })
            .then(result => {
                callback(result.data)
            }).catch(err => {
                console.error(err)
                toast.error(`${err}`)
            })
    }
}

