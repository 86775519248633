/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import Table from "react-table";
import "react-table/react-table.css";

import _, { isSet } from 'lodash';

import axios from 'axios';

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { toast } from 'react-toastify';
import $ from 'jquery';
import SearchableSelectComp from '../../components/SearchableSelectComp';

import { getPaymentsData, getNotCompletedPaymentsData, addPayment, updatePayment, deletePayment, addPaymentSchedule, updatePaymentSchedule, deletePaymentSchedule, getReportData } from '../../../actions/Acc/payments_actions';
import { getAccountsData } from '../../../actions/Acc/account_actions';
import * as XLSX from 'xlsx';

import checkboxHOC from "react-table/lib/hoc/selectTable";
import ConvertDecimalPlaces from "../../utils/ConvertDecimalPlaces";
const CheckboxTable = checkboxHOC(Table);
const fileExtension = '.xlsx';

class Payments extends React.Component {

    paymentTypes = [
        { '_id': '1', 'label': 'Bank', 'value': 'Bank' },
        { '_id': '2', 'label': 'Cash', 'value': 'Cash' },
    ];

    state = {
        paymentsData: [],
        purcahseOrderData: [],
        totals: [],
        accounts: [],
        currentSupplier: '',
        currentSupplierId: '',
        currentMonth: '',
        currentAccount: '',
        amountsArr: [],
        totalAmount: 0,
        checkedArr: [],
        paidChecksArr: [],
        noteToTransaction: '',
        availableAmount: 0,
        accountType: '',
        supplierAccount: '',
        refundBy: 0,
        refundsData: [],
        refundDataToPush: [],
        disablePayButton: true,
        selection: [],
        selectAll: false,
        totalRefundValue: 0,
        creditNoteBy: 0,
        creditNotePurchaseData: [],
        totalCreditNotePurchase: 0,
        creditNoteDataToPush: [],
        editing: null,
        deleting: null,
        sortOptions: [{ id: 'orderdate', desc: false }],
        sortOptionsPO: [{ id: 'dueDate', desc: false }],
        sortOptionsRF: [{ id: 'createdAt', desc: false }],
        reportDataArr: [],
        active: true,
        paymentMethod: ''

    };

    toggleSelection = (key, shift, row) => {
        /*
          Implementation of how to manage the selection state is up to the developer.
          This implementation uses an array stored in the component state.
          Other implementations could use object keys, a Javascript Set, or Redux... etc.
        */
        // start off with the existing state
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        console.log(keyIndex);
        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }
        this.setCheck(key);
        // update the state
        if (selection.length > 0) {
            this.setState({
                disablePayButton: false
            });
        } else {
            this.setState({
                disablePayButton: true
            });
        }
        this.setState({ selection });
    };

    // toggleAll = () => {
    //     /*
    //       'toggleAll' is a tricky concept with any filterable table
    //       do you just select ALL the records that are in your data?
    //       OR
    //       do you only select ALL the records that are in the current filtered data?

    //       The latter makes more sense because 'selection' is a visual thing for the user.
    //       This is especially true if you are going to implement a set of external functions
    //       that act on the selected information (you would not want to DELETE the wrong thing!).

    //       So, to that end, access to the internals of ReactTable are required to get what is
    //       currently visible in the table (either on the current page or any other page).

    //       The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
    //       ReactTable and then get the internal state and the 'sortedData'. 
    //       That can then be iterrated to get all the currently visible records and set
    //       the selection state.
    //     */
    //     const selectAll = this.state.selectAll ? false : true;
    //     const selection = [];
    //     if (selectAll) {
    //         // we need to get at the internals of ReactTable
    //         const wrappedInstance = this.checkboxTable.getWrappedInstance();
    //         // the 'sortedData' property contains the currently accessible records based on the filter and sort
    //         const currentRecords = wrappedInstance.getResolvedState().sortedData;
    //         // we just push all the IDs onto the selection array
    //         currentRecords.forEach(item => {
    //             selection.push(item._original._id);
    //             this.setCheck(item._original._id);
    //         });
    //     }
    //     if (selection.length > 0) {
    //         this.setState({
    //             disablePayButton: false
    //         });
    //     } else {
    //         this.setState({
    //             disablePayButton: true
    //         });
    //     }
    //     this.setState({ selectAll, selection });
    // };

    isSelected = key => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        return this.state.selection.includes(key);
    };

    logSelection = () => {
        console.log("selection:", this.state.selection);
    };

    componentDidMount() {
        this.props.getPaymentsData(this.props.token);
        this.props.getAccountsData(this.props.token);

        this.setState({ user: this.props.userId });
    }

    componentDidUpdate(prevProps) {
        const { reportData } = this.props;
        if (reportData !== prevProps.reportData) {

            this.setState({
                reportDataArr: reportData
            })
        }
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(String(row[id]).toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    getMonthName = (param) => {
        var months = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        var today = new Date();
        var d;
        var month;
        var year;
        var dateRange;
        var dateRangeArr = [];

        for (var i = 4; i >= -1; i -= 1) {
            d = new Date(today.getFullYear(), today.getMonth() - i, 1);
            month = months[d.getMonth()];
            var monthName = monthNames[d.getMonth()];

            year = d.getFullYear();

            dateRange = this.getMonthDateRange(year, month, monthName);
            dateRangeArr.push(dateRange);
        }

        var otherObj = {
            monthName: 'Other'
        };

        var otherObj2 = {
            monthName: 'Outstanding'
        };

        dateRangeArr.push(otherObj);
        dateRangeArr.push(otherObj2);

        return dateRangeArr[param].monthName;
    }

    getMonthDateRange = (year, month, monthName) => {
        month = Number(month) + 1;
        var dateStr = year + '-' + month + '-01';
        var startDate = new Date(dateStr);
        var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

        var obj = {
            monthIndex: month,
            monthName: monthName,
            start: this.formatDate(startDate),
            end: this.formatDate(endDate)
        };

        return obj;
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    getRefundData = (data) => {
        axios.defaults.headers.common['authorization'] = this.props.token;
        axios.post('/acc/payments/getRefundsDetail', { data })
            .then(result => {
                this.setState({
                    refundsData: result.data.result,
                    totalRefundValue: result.data.total
                });
            }).catch(err => {
                console.log(err);
            })
    }

    getCreditNotePurchaseData = (data) => {
        axios.defaults.headers.common['authorization'] = this.props.token;
        axios.post('/acc/payments/getCreditNotePurchaseDetail', { data })
            .then(result => {
                this.setState({
                    creditNotePurchaseData: result.data.result,
                    totalCreditNotePurchase: result.data.total
                });
            }).catch(err => {
                console.log(err);
            })
    }

    getColumns = () => {
        return [
            {
                Header: 'Supplier',
                filterable: true,
                accessor: 'supplier',
                Cell: row =>
                    <>
                        <a data-toggle="modal" data-target='#purchaseOrders' className='btn btn-link' data-backdrop="static" data-keyboard="false" style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                const data = {
                                    _id: row.original.supplier_id
                                };

                                const payableAccountArr = this.props.cashPayableAccounts.filter(obj => obj.label === "Supplier Payable")

                                this.setState({
                                    currentSupplier: row.original.supplier,
                                    currentSupplierId: row.original.supplier_id,
                                    supplierAccount: row.original.supplier_account,
                                    currentMonth: '',
                                    amountsArr: [],
                                    checkedArr: [],
                                    totalAmount: 0,
                                    noteToTransaction: '',
                                    currentAccount: payableAccountArr.length > 0 ? payableAccountArr[0]._id: '',
                                    accountType: payableAccountArr.length > 0 ? 'Payable' : ''
                                });

                                axios.defaults.headers.common['authorization'] = this.props.token;
                                axios.post('/acc/payments/getPurchaseOrderBySupplierId', { data })
                                    .then(result => {
                                        this.setState({
                                            purcahseOrderData: result.data
                                        });
                                    }).catch(err => {
                                        console.log(err);
                                    })

                                const rfData = {
                                    supplier: row.original.supplier_id
                                };

                                this.getRefundData(rfData);
                                this.getCreditNotePurchaseData(rfData);
                            }}
                        >{
                                row.original.supplier
                            }</a>
                    </>
            },
            {
                Header: this.getMonthName(0) + '(' + this.props.totals.month1Total + ')',
                accessor: this.getMonthName(0),
                getProps: () => { return { style: { 'text-align': 'right' } } },
                Cell: row =>
                    <>
                        <a data-toggle="modal" data-target='#purchaseOrders' className='btn btn-link' data-backdrop="static" data-keyboard="false" style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                const data = {
                                    _id: row.original.supplier_id,
                                    month: this.getMonthName(0)
                                };

                                const payableAccountArr = this.props.cashPayableAccounts.filter(obj => obj.label === "Supplier Payable")

                                this.setState({
                                    currentSupplier: row.original.supplier,
                                    currentSupplierId: row.original.supplier_id,
                                    supplierAccount: row.original.supplier_account,
                                    currentMonth: this.getMonthName(0),
                                    amountsArr: [],
                                    checkedArr: [],
                                    totalAmount: 0,
                                    noteToTransaction: '',
                                    currentAccount: payableAccountArr.length > 0 ? payableAccountArr[0]._id: '',
                                    accountType: payableAccountArr.length > 0 ? 'Payable' : ''
                                });

                                axios.defaults.headers.common['authorization'] = this.props.token;
                                axios.post('/acc/payments/getPurchaseOrderBySupplierIdAndMonth', { data })
                                    .then(result => {
                                        this.setState({
                                            purcahseOrderData: result.data
                                        });
                                    }).catch(err => {
                                        console.log(err);
                                    })

                                const rfData = {
                                    supplier: row.original.supplier_id
                                };

                                this.getRefundData(rfData);
                                this.getCreditNotePurchaseData(rfData);
                            }}
                        >{
                                this.getMonthName(0) === 'January' ? row.original.January :
                                    this.getMonthName(0) === 'February' ? row.original.February :
                                        this.getMonthName(0) === 'March' ? row.original.March :
                                            this.getMonthName(0) === 'April' ? row.original.April :
                                                this.getMonthName(0) === 'May' ? row.original.May :
                                                    this.getMonthName(0) === 'June' ? row.original.June :
                                                        this.getMonthName(0) === 'July' ? row.original.July :
                                                            this.getMonthName(0) === 'August' ? row.original.August :
                                                                this.getMonthName(0) === 'September' ? row.original.September :
                                                                    this.getMonthName(0) === 'October' ? row.original.October :
                                                                        this.getMonthName(0) === 'November' ? row.original.November :
                                                                            this.getMonthName(0) === 'December' ? row.original.December : ''
                            }</a>
                    </>
            },
            {
                Header: this.getMonthName(1) + '(' + this.props.totals.month2Total + ')',
                accessor: this.getMonthName(1),
                getProps: () => { return { style: { 'text-align': 'right' } } },
                Cell: row =>
                    <>
                        <a data-toggle="modal" data-target='#purchaseOrders' className='btn btn-link' data-backdrop="static" data-keyboard="false" style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                const data = {
                                    _id: row.original.supplier_id,
                                    month: this.getMonthName(1)
                                };

                                const payableAccountArr = this.props.cashPayableAccounts.filter(obj => obj.label === "Supplier Payable")

                                this.setState({
                                    currentSupplier: row.original.supplier,
                                    currentSupplierId: row.original.supplier_id,
                                    supplierAccount: row.original.supplier_account,
                                    currentMonth: this.getMonthName(1),
                                    amountsArr: [],
                                    checkedArr: [],
                                    totalAmount: 0,
                                    noteToTransaction: '',
                                    currentAccount: payableAccountArr.length > 0 ? payableAccountArr[0]._id: '',
                                    accountType: payableAccountArr.length > 0 ? 'Payable' : ''
                                });

                                axios.defaults.headers.common['authorization'] = this.props.token;
                                axios.post('/acc/payments/getPurchaseOrderBySupplierIdAndMonth', { data })
                                    .then(result => {
                                        this.setState({
                                            purcahseOrderData: result.data
                                        });
                                    }).catch(err => {
                                        console.log(err);
                                    })

                                const rfData = {
                                    supplier: row.original.supplier_id
                                };

                                this.getRefundData(rfData);
                                this.getCreditNotePurchaseData(rfData);
                            }}
                        >{
                                this.getMonthName(1) === 'January' ? row.original.January :
                                    this.getMonthName(1) === 'February' ? row.original.February :
                                        this.getMonthName(1) === 'March' ? row.original.March :
                                            this.getMonthName(1) === 'April' ? row.original.April :
                                                this.getMonthName(1) === 'May' ? row.original.May :
                                                    this.getMonthName(1) === 'June' ? row.original.June :
                                                        this.getMonthName(1) === 'July' ? row.original.July :
                                                            this.getMonthName(1) === 'August' ? row.original.August :
                                                                this.getMonthName(1) === 'September' ? row.original.September :
                                                                    this.getMonthName(1) === 'October' ? row.original.October :
                                                                        this.getMonthName(1) === 'November' ? row.original.November :
                                                                            this.getMonthName(1) === 'December' ? row.original.December : ''
                            }</a>
                    </>
            },
            {
                Header: this.getMonthName(2) + '(' + this.props.totals.month3Total + ')',
                accessor: this.getMonthName(2),
                getProps: () => { return { style: { 'text-align': 'right' } } },
                Cell: row =>
                    <>
                        <a data-toggle="modal" data-target='#purchaseOrders' className='btn btn-link' data-backdrop="static" data-keyboard="false" style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                const data = {
                                    _id: row.original.supplier_id,
                                    month: this.getMonthName(2)
                                };

                                const payableAccountArr = this.props.cashPayableAccounts.filter(obj => obj.label === "Supplier Payable")

                                this.setState({
                                    currentSupplier: row.original.supplier,
                                    currentSupplierId: row.original.supplier_id,
                                    supplierAccount: row.original.supplier_account,
                                    currentMonth: this.getMonthName(2),
                                    amountsArr: [],
                                    checkedArr: [],
                                    totalAmount: 0,
                                    noteToTransaction: '',
                                    currentAccount: payableAccountArr.length > 0 ? payableAccountArr[0]._id: '',
                                    accountType: payableAccountArr.length > 0 ? 'Payable' : ''
                                });

                                axios.defaults.headers.common['authorization'] = this.props.token;
                                axios.post('/acc/payments/getPurchaseOrderBySupplierIdAndMonth', { data })
                                    .then(result => {
                                        this.setState({
                                            purcahseOrderData: result.data
                                        });
                                    }).catch(err => {
                                        console.log(err);
                                    })

                                const rfData = {
                                    supplier: row.original.supplier_id
                                };

                                this.getRefundData(rfData);
                                this.getCreditNotePurchaseData(rfData);
                            }}
                        >{
                                this.getMonthName(2) === 'January' ? row.original.January :
                                    this.getMonthName(2) === 'February' ? row.original.February :
                                        this.getMonthName(2) === 'March' ? row.original.March :
                                            this.getMonthName(2) === 'April' ? row.original.April :
                                                this.getMonthName(2) === 'May' ? row.original.May :
                                                    this.getMonthName(2) === 'June' ? row.original.June :
                                                        this.getMonthName(2) === 'July' ? row.original.July :
                                                            this.getMonthName(2) === 'August' ? row.original.August :
                                                                this.getMonthName(2) === 'September' ? row.original.September :
                                                                    this.getMonthName(2) === 'October' ? row.original.October :
                                                                        this.getMonthName(2) === 'November' ? row.original.November :
                                                                            this.getMonthName(2) === 'December' ? row.original.December : ''
                            }</a>
                    </>
            },
            {
                Header: this.getMonthName(3) + '(' + this.props.totals.month4Total + ')',
                accessor: this.getMonthName(3),
                getProps: () => { return { style: { 'text-align': 'right' } } },
                Cell: row =>
                    <>
                        <a data-toggle="modal" data-target='#purchaseOrders' className='btn btn-link' data-backdrop="static" data-keyboard="false" style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                const data = {
                                    _id: row.original.supplier_id,
                                    month: this.getMonthName(3)
                                };

                                const payableAccountArr = this.props.cashPayableAccounts.filter(obj => obj.label === "Supplier Payable")

                                this.setState({
                                    currentSupplier: row.original.supplier,
                                    currentSupplierId: row.original.supplier_id,
                                    supplierAccount: row.original.supplier_account,
                                    currentMonth: this.getMonthName(3),
                                    amountsArr: [],
                                    checkedArr: [],
                                    totalAmount: 0,
                                    noteToTransaction: '',
                                    currentAccount: payableAccountArr.length > 0 ? payableAccountArr[0]._id: '',
                                    accountType: payableAccountArr.length > 0 ? 'Payable' : ''
                                });

                                axios.defaults.headers.common['authorization'] = this.props.token;
                                axios.post('/acc/payments/getPurchaseOrderBySupplierIdAndMonth', { data })
                                    .then(result => {
                                        this.setState({
                                            purcahseOrderData: result.data
                                        });
                                    }).catch(err => {
                                        console.log(err);
                                    })

                                const rfData = {
                                    supplier: row.original.supplier_id
                                };

                                this.getRefundData(rfData);
                                this.getCreditNotePurchaseData(rfData);
                            }}
                        >{
                                this.getMonthName(3) === 'January' ? row.original.January :
                                    this.getMonthName(3) === 'February' ? row.original.February :
                                        this.getMonthName(3) === 'March' ? row.original.March :
                                            this.getMonthName(3) === 'April' ? row.original.April :
                                                this.getMonthName(3) === 'May' ? row.original.May :
                                                    this.getMonthName(3) === 'June' ? row.original.June :
                                                        this.getMonthName(3) === 'July' ? row.original.July :
                                                            this.getMonthName(3) === 'August' ? row.original.August :
                                                                this.getMonthName(3) === 'September' ? row.original.September :
                                                                    this.getMonthName(3) === 'October' ? row.original.October :
                                                                        this.getMonthName(3) === 'November' ? row.original.November :
                                                                            this.getMonthName(3) === 'December' ? row.original.December : ''
                            }</a>
                    </>
            },
            {
                Header: this.getMonthName(4) + '(' + this.props.totals.month5Total + ')',
                accessor: this.getMonthName(4),
                getProps: () => { return { style: { 'text-align': 'right' } } },
                Cell: row =>
                    <>
                        <a data-toggle="modal" data-target='#purchaseOrders' className='btn btn-link' data-backdrop="static" data-keyboard="false" style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                const data = {
                                    _id: row.original.supplier_id,
                                    month: this.getMonthName(4)
                                };

                                const payableAccountArr = this.props.cashPayableAccounts.filter(obj => obj.label === "Supplier Payable")

                                this.setState({
                                    currentSupplier: row.original.supplier,
                                    currentSupplierId: row.original.supplier_id,
                                    supplierAccount: row.original.supplier_account,
                                    currentMonth: this.getMonthName(4),
                                    amountsArr: [],
                                    checkedArr: [],
                                    totalAmount: 0,
                                    noteToTransaction: '',
                                    currentAccount: payableAccountArr.length > 0 ? payableAccountArr[0]._id: '',
                                    accountType: payableAccountArr.length > 0 ? 'Payable' : ''
                                });

                                axios.defaults.headers.common['authorization'] = this.props.token;
                                axios.post('/acc/payments/getPurchaseOrderBySupplierIdAndMonth', { data })
                                    .then(result => {
                                        this.setState({
                                            purcahseOrderData: result.data
                                        });
                                    }).catch(err => {
                                        console.log(err);
                                    })

                                const rfData = {
                                    supplier: row.original.supplier_id
                                };

                                this.getRefundData(rfData);
                                this.getCreditNotePurchaseData(rfData);
                            }}
                        >{
                                this.getMonthName(4) === 'January' ? row.original.January :
                                    this.getMonthName(4) === 'February' ? row.original.February :
                                        this.getMonthName(4) === 'March' ? row.original.March :
                                            this.getMonthName(4) === 'April' ? row.original.April :
                                                this.getMonthName(4) === 'May' ? row.original.May :
                                                    this.getMonthName(4) === 'June' ? row.original.June :
                                                        this.getMonthName(4) === 'July' ? row.original.July :
                                                            this.getMonthName(4) === 'August' ? row.original.August :
                                                                this.getMonthName(4) === 'September' ? row.original.September :
                                                                    this.getMonthName(4) === 'October' ? row.original.October :
                                                                        this.getMonthName(4) === 'November' ? row.original.November :
                                                                            this.getMonthName(4) === 'December' ? row.original.December : ''
                            }</a>
                    </>
            },
            {
                Header: this.getMonthName(5) + '(' + this.props.totals.month6Total + ')',
                accessor: this.getMonthName(5),
                getProps: () => { return { style: { 'text-align': 'right' } } },
                Cell: row =>
                    <>
                        <a data-toggle="modal" data-target='#purchaseOrders' className='btn btn-link' data-backdrop="static" data-keyboard="false" style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                const data = {
                                    _id: row.original.supplier_id,
                                    month: this.getMonthName(5)
                                };

                                const payableAccountArr = this.props.cashPayableAccounts.filter(obj => obj.label === "Supplier Payable")

                                this.setState({
                                    currentSupplier: row.original.supplier,
                                    currentSupplierId: row.original.supplier_id,
                                    supplierAccount: row.original.supplier_account,
                                    currentMonth: this.getMonthName(5),
                                    amountsArr: [],
                                    checkedArr: [],
                                    totalAmount: 0,
                                    noteToTransaction: '',
                                    currentAccount: payableAccountArr.length > 0 ? payableAccountArr[0]._id: '',
                                    accountType: payableAccountArr.length > 0 ? 'Payable' : ''
                                });

                                axios.defaults.headers.common['authorization'] = this.props.token;
                                axios.post('/acc/payments/getPurchaseOrderBySupplierIdAndMonth', { data })
                                    .then(result => {
                                        this.setState({
                                            purcahseOrderData: result.data
                                        });
                                    }).catch(err => {
                                        console.log(err);
                                    })

                                const rfData = {
                                    supplier: row.original.supplier_id
                                };

                                this.getRefundData(rfData);
                                this.getCreditNotePurchaseData(rfData);
                            }}
                        >{
                                this.getMonthName(5) === 'January' ? row.original.January :
                                    this.getMonthName(5) === 'February' ? row.original.February :
                                        this.getMonthName(5) === 'March' ? row.original.March :
                                            this.getMonthName(5) === 'April' ? row.original.April :
                                                this.getMonthName(5) === 'May' ? row.original.May :
                                                    this.getMonthName(5) === 'June' ? row.original.June :
                                                        this.getMonthName(5) === 'July' ? row.original.July :
                                                            this.getMonthName(5) === 'August' ? row.original.August :
                                                                this.getMonthName(5) === 'September' ? row.original.September :
                                                                    this.getMonthName(5) === 'October' ? row.original.October :
                                                                        this.getMonthName(5) === 'November' ? row.original.November :
                                                                            this.getMonthName(5) === 'December' ? row.original.December : ''
                            }</a>
                    </>
            },

            {
                Header: this.getMonthName(6) + '(' + this.props.totals.otherTotal + ')',
                accessor: this.getMonthName(6),
                getProps: () => { return { style: { 'text-align': 'right' } } },
                Cell: row =>
                    <>
                        <a data-toggle="modal" data-target='#purchaseOrders' className='btn btn-link' data-backdrop="static" data-keyboard="false" style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => {
                                const data = {
                                    _id: row.original.supplier_id,
                                    month: this.getMonthName(6)
                                };

                                const payableAccountArr = this.props.cashPayableAccounts.filter(obj => obj.label === "Supplier Payable")

                                this.setState({
                                    currentSupplier: row.original.supplier,
                                    currentSupplierId: row.original.supplier_id,
                                    supplierAccount: row.original.supplier_account,
                                    currentMonth: this.getMonthName(6),
                                    amountsArr: [],
                                    checkedArr: [],
                                    totalAmount: 0,
                                    noteToTransaction: '',
                                    currentAccount: payableAccountArr.length > 0 ? payableAccountArr[0]._id: '',
                                    accountType: payableAccountArr.length > 0 ? 'Payable' : ''
                                });

                                axios.defaults.headers.common['authorization'] = this.props.token;
                                axios.post('/acc/payments/getPurchaseOrderBySupplierIdAndMonth', { data })
                                    .then(result => {
                                        this.setState({
                                            purcahseOrderData: result.data
                                        });
                                    }).catch(err => {
                                        console.log(err);
                                    })

                                const rfData = {
                                    supplier: row.original.supplier_id
                                };

                                this.getRefundData(rfData);
                                this.getCreditNotePurchaseData(rfData);
                            }}
                        >{
                                row.original.Other
                            }</a>
                    </>
            },
            {
                Header: this.getMonthName(7) + '(' + this.props.totals.outstandingTotal + ')',
                accessor: this.getMonthName(7),
                getProps: () => { return { style: { 'text-align': 'right' } } }
            },
        ];
    }

    setAmount = (purchaseOrderId, amount, balance) => {

        amount = Number(amount).toFixed(3);
        balance = Number(balance).toFixed(3);

        if (Number(amount) > Number(balance)) {
            this.setState({
                disablePayButton: true
            });
        } else {
            this.setState({
                disablePayButton: false
            });

            const amountObj = {
                purchaseOrderId: purchaseOrderId,
                amount: Number(amount)
            };

            var totalAmount = 0;

            if (this.state.amountsArr.length > 0) {
                for (var i = 0; i < this.state.amountsArr.length; i++) {
                    if (this.state.amountsArr[i].purchaseOrderId === amountObj.purchaseOrderId) {
                        this.state.amountsArr.splice(i, 1);
                    }
                }
                this.state.amountsArr.push(amountObj);
            } else {
                this.state.amountsArr.push(amountObj);
            }

            this.state.amountsArr.forEach(e => {
                totalAmount = totalAmount + Number(e.amount);
            });

            this.setState({
                totalAmount: totalAmount
            });
        }
    }

    setCheck = (purchaseOrderId) => {
        var checkObj = {
            purchaseOrderId: purchaseOrderId
        };

        if (this.state.selection.includes(purchaseOrderId)) {
            $('#paid-' + purchaseOrderId).prop('checked', false);
            $('#paid-' + purchaseOrderId).prop('disabled', true);
            $('#amount-' + purchaseOrderId).prop('disabled', true);
            $('#amount-' + purchaseOrderId).prop('value', '');

            for (var i = 0; i < this.state.checkedArr.length; i++) {
                if (this.state.checkedArr[i].purchaseOrderId === purchaseOrderId) {
                    this.state.checkedArr.splice(i, 1);
                }
            }
        } else {
            $('#paid-' + purchaseOrderId).prop('disabled', false);
            $('#amount-' + purchaseOrderId).prop('disabled', false);

            this.state.checkedArr.push(checkObj);
        }

        this.setAmount(purchaseOrderId, 0);
        this.setPaidCheck(purchaseOrderId);
    }

    setPaidCheck = (id) => {
        var amount = Number($('#amount-' + id).val());
        var balance = Number($('#balance-' + id).html());
        if ($('#paid-' + id).is(':checked')) {
            var obj = {
                purchaseOrderId: id,
                paid: true,
            };


            if (amount === '' || amount < 1) {
                $('#amount-' + id).prop('value', balance);
                this.setAmount(id, balance);
            }

            this.state.paidChecksArr.push(obj);

        } else {
            for (var i = 0; i < this.state.paidChecksArr.length; i++) {
                if (this.state.paidChecksArr[i].purchaseOrderId === id) {
                    this.state.paidChecksArr.splice(i, 1);

                    if (amount === balance) {
                        $('#amount-' + id).prop('value', '');
                        this.setAmount(id, 0);
                    }
                }
            }
        }
    }

    setRefundCheck = (refundData) => {
        if ($('#refund-' + refundData._id).is(':checked')) {
            var total = this.state.refundBy;

            total = Number(total) + Number(refundData.total);
            this.state.refundDataToPush.push(refundData);

            this.setState({
                refundBy: total
            });
        } else {
            var total = this.state.refundBy;

            total = Number(total) - Number(refundData.total);

            this.setState({
                refundBy: total
            });

            for (var i = 0; i < this.state.refundDataToPush.length; i++) {
                if (this.state.refundDataToPush[i]._id === refundData._id) {
                    this.state.refundDataToPush.splice(i, 1);
                }
            }
        }
    }

    setCNoteCheck = (cNoteData) => {
        if ($('#cnote-' + cNoteData._id).is(':checked')) {
            var total = this.state.creditNoteBy;

            total = Number(total) + Number(cNoteData.value);
            this.state.creditNoteDataToPush.push(cNoteData);

            this.setState({
                creditNoteBy: total
            });
        } else {
            var total = this.state.creditNoteBy;

            total = Number(total) - Number(cNoteData.value);

            this.setState({
                creditNoteBy: total
            });

            for (var i = 0; i < this.state.creditNoteDataToPush.length; i++) {
                if (this.state.creditNoteDataToPush[i]._id === cNoteData._id) {
                    this.state.creditNoteDataToPush.splice(i, 1);
                }
            }
        }
    }

    formatOnlyDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }


        var date = [year, month, day].join('-');

        return date;
    }

    purcahseOrderColumns = [
        // {
        //     Header: "Select",
        //     Cell: row => (
        //         <div>
        //             <input type="checkbox" id={'select-' + row.original._id} style={{ width: '100%' }} onChange={(e) => { this.setCheck(row.original._id) }} />
        //         </div>
        //     )
        // },
        {
            Header: "Due Date",
            accessor: "dueDate",
            filterable: true,
            Cell: row => {
                return this.formatOnlyDate(row.original.dueDate)
            }
        },
        { Header: "Purchase No.", accessor: "orderId", filterable: true },
        { Header: "Remark", accessor: "remark", filterable: true },
        { Header: "consignment", accessor: "consignment", filterable: true },
        {
            Header: "Value",
            accessor: "value",
            Cell: row => (
                <div>
                    <label>{Number(row.original.value).toFixed(2)}</label>
                </div>
            ),
            filterable: true,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        {
            Header: "Balance",
            accessor: "balance",
            Cell: row => (
                <div>
                    <label id={'balance-' + row.original._id}>{ConvertDecimalPlaces(row.original.balance)}</label>
                </div>
            ),
            filterable: true,
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: row => (
                <div>
                    <input disabled type="number" max={row.original.balance} id={'amount-' + row.original._id} style={{ width: '100%', color: this.state.disablePayButton === true ? 'red' : '' }} onChange={(e) => { this.setAmount(row.original._id, e.target.value, row.original.balance) }} />
                </div>
            ),
            getProps: () => { return { style: { 'text-align': 'right' } } }
        },
        {
            Header: "Paid",
            accessor: "paid",
            Cell: row => (
                <div>
                    <input disabled type="checkbox" id={'paid-' + row.original._id} onChange={e => { this.setPaidCheck(row.original._id) }} />
                </div>
            )
        },
    ];

    refundColumns = [
        {
            Header: "Select",
            Cell: row => (
                <div>
                    <input type="checkbox" id={'refund-' + row.original._id} value={row.original.total} style={{ width: '100%' }} onChange={(e) => { this.setRefundCheck(row.original) }} />
                </div>
            )
        },
        { Header: "Order Number", accessor: "orderId", filterable: true },
        { Header: "Purchase Price", accessor: "purchasePrice", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Defect Qty", accessor: "defectQty", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Bare", accessor: "bare", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Total", accessor: "total", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },

    ];

    creditNotePurchaseColumns = [
        {
            Header: "Select",
            Cell: row => (
                <div>
                    <input type="checkbox" id={'cnote-' + row.original._id} value={row.original.value} style={{ width: '100%' }} onChange={(e) => { this.setCNoteCheck(row.original) }} />
                </div>
            )
        },
        // { Header: "Order Number", accessor: "orderId", filterable: true },
        { Header: "Value", accessor: "value", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
        { Header: "Remark", accessor: "remark", filterable: true },
        {
            Header: "Credit Date",
            accessor: "credit_date",
            filterable: true,
            Cell: row => {
                return <label>{this.formatOnlyDate(row.original.credit_date)}</label>
            }
        },
    ];

    pay = () => {
        this.setState({
            active: false
        })
    }

    close = () => {
        this.setState({
            active: true
        })
    }

    done = () => {

        var selectedAccountArr = this.state.currentAccount.split('-');

        const checksArr = this.state.checkedArr;
        const amountsArr = this.state.amountsArr;
        const totalAmount = this.state.totalAmount;
        const selectedAccount = selectedAccountArr[0];
        const noteToTransaction = this.state.currentSupplier + '-' + this.state.noteToTransaction;
        const user = this.state.user;
        const supplier = this.state.currentSupplierId;
        const paidChecksArr = this.state.paidChecksArr;
        const supplierAccount = this.state.supplierAccount;
        const refundBy = this.state.refundBy;
        const creditNoteBy = this.state.creditNoteBy;
        const refundDataToPush = this.state.refundDataToPush;
        const creditNoteDataToPush = this.state.creditNoteDataToPush;
        const isCompleted = this.state.accountType === 'Payable' ? false : true;
        const completedUser = this.state.accountType === 'Payable' ? '' : this.state.user;
        const completedDate = this.state.accountType === 'Payable' ? '' : 'date';
        const approved = this.state.accountType === 'Payable' ? 'No' : 'Yes';

        if (this.state.paymentMethod !== undefined && this.state.paymentMethod !== "") {

            const method = this.paymentTypes.filter(obj => obj._id === this.state.paymentMethod)

            this.setState({
                disablePayButton: true
            });

            if (checksArr.length > 0) {
                if (totalAmount > 0) {

                    if (selectedAccount === '' || noteToTransaction === '' || supplierAccount === '') {
                        toast.error(`Please Select Account, Supplier Account and Note to Transaction!`);
                        return;
                    }

                    this.removeDiv();

                    this.setState({
                        disablePayButton: true
                    });

                    var purchaseOrderIdsArray = [];

                    amountsArr.forEach(e => {
                        checksArr.forEach(c => {
                            if (c.purchaseOrderId === e.purchaseOrderId) {
                                if (e.amount > 0) {
                                    e.paid = false;
                                    purchaseOrderIdsArray.push(e);
                                }
                            }
                        })
                    });

                    if (paidChecksArr.length > 0) {
                        paidChecksArr.forEach(c => {
                            purchaseOrderIdsArray.forEach(e => {
                                if (c.purchaseOrderId === e.purchaseOrderId) {
                                    if (c.paid === true) {
                                        e.paid = true;
                                    } else {
                                        e.paid = false;
                                    }
                                }

                            });
                        });
                    }

                    const data = {
                        purchaseOrderIdsArray: purchaseOrderIdsArray,
                        totalAmount: totalAmount,
                        selectedAccount: selectedAccount,
                        noteToTransaction: noteToTransaction.trim(),
                        user: user,
                        supplier: supplier,
                        supplierAccount: supplierAccount,
                        refundBy: refundBy,
                        creditNoteBy: creditNoteBy,
                        refundDataToPush: refundDataToPush,
                        creditNoteDataToPush: creditNoteDataToPush,
                        isCompleted: isCompleted,
                        completedUser: completedUser,
                        completedDate: completedDate,
                        approved: approved,
                        paymentMethod: method.length > 0? method[0].value: ''
                    };

                    axios.defaults.headers.common['authorization'] = this.props.token;
                    axios.post('/acc/payments/makePayment', { data })
                        .then(result => {
                            this.props.getPaymentsData(this.props.token);
                            toast.success("Payment Added!");
                        }).catch(err => {
                            console.log(err);
                            toast.error(err.response.data);
                        })
                    this.closePurchaseOrderPopup();
                } else {
                    toast.error(`Total Amount should be greater than zero!`);
                    return;
                }
            } else {
                toast.error(`Please check some checkboxes!`);
                return;
            }
        } else {
            toast.error(`Please select the payment method!`);
            return;
        }

    }

    clearInputFields = () => {
        $('#purchaseOrders').find('input:text, input:password, select, input[type="number"]').each(function () {
            $(this).val('');
        });

        $('#purchaseOrders').find('input[type="checkbox"]').removeAttr('checked');
    }

    closePurchaseOrderPopup = () => {
        this.setState({
            amountsArr: [],
            checkedArr: [],
            totalAmount: 0,
            noteToTransaction: '',
            availableAmount: 0,
            supplierAccount: '',
            refundBy: 0,
            creditNoteBy: 0,
            refundDataToPush: [],
            creditNoteDataToPush: [],
            creditNotePurchaseData: [],
            refundsData: [],
            accountType: '',
            selection: [],
            totalRefundValue: 0,
            totalCreditNotePurchase: 0,
            currentAccount: '',
            paymentMethod: '',
            active:true
        });

        this.clearInputFields();
    }

    removeDiv = () => {
        $(".modal-backdrop.fade.show").remove();
    }

    setCurrentAccount = (element) => {
        var elementArr = element.split('-');
        this.setState({
            currentAccount: element,
            availableAmount: elementArr[1],
            accountType: elementArr[2]
        });
    }

    setSupplierAccount = (supplierAccount) => {
        this.setState({
            supplierAccount: supplierAccount
        });
    }

    setModelOpenClass = () => {
        setTimeout(function () {
            $('body').attr('class', 'modal-open');
        }, 1000);
    }

    handleExportData = () => {
        this.props.getReportData({
            token: this.props.token
        }, () => {
            this.exportData()
        })
    }

    exportData = () => {
        let b = [''];

        var excelData = this.state.reportDataArr.map(checkData => {

            return {
                'Supplier': checkData.supplier,
                'Order Id': checkData.orderId,
                'Value': checkData.value,
                'Balance': checkData.balance,
                'Paid': checkData.paid,
            }
        })

        const ws = XLSX.utils.json_to_sheet(excelData);
        b = b.concat(XLSX.utils.sheet_to_json(ws, { header1: -1 }))
        const worksheet = XLSX.utils.json_to_sheet(b, { skipHeader: true });
        const new_workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Payment Orders")
        XLSX.writeFile(new_workbook, 'Payment' + fileExtension);
    }

    render() {

        const { toggleSelection, isSelected } = this;

        const checkboxProps = {
            isSelected,
            toggleSelection,
            selectType: "checkbox"
        };

        return (
            <div className="right_col" role="main">

                {this.props.isLoading
                    ? <>
                        <SkeletonTable columnsCount={9} dataCount={10} />
                    </>
                    :

                    <div className="card">
                        <div className="card-header" style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'inline-flex', paddingRight: '1%' }}>
                                <h4>Payments </h4>
                            </div>
                            <div style={{ display: 'flex' }}>
                                &nbsp;&nbsp;&nbsp;
                                <div style={{ display: 'flex' }}>
                                    <button className="btn btn-success" data-backdrop="static" data-keyboard="false" onClick={() => this.handleExportData()}>Export Data</button>

                                </div>
                            </div>
                        </div>

                        <ErrorBoundary>
                            <div className="card-body">
                                <FormProvider
                                    form="inline"
                                    enableReinitialize
                                >
                                    {formProps => {
                                        var paymentColumns = this.getColumns();
                                        return (
                                            <form>
                                                <Table className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={paymentColumns}
                                                    data={this.props.paymentsData}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </div>
                        </ErrorBoundary>


                        <div id="purchaseOrders" className="modal fade" role="dialog">
                            {this.state.active === true ?
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content animate" >
                                        <div className="modal-header">
                                            <h4 className="modal-title text-uppercase">Purchase Orders for {this.state.currentSupplier} {this.state.currentMonth !== '' ? 'in ' + this.state.currentMonth : ''}</h4>
                                            <button type="button" className="close" data-dismiss="modal" onClick={e => this.closePurchaseOrderPopup()}>&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div class="col-md-6">
                                                    {/* <select className="form-control" onChange={e => e.target.value === '' ? null : this.setCurrentAccount(e.target.value)}>
                                                    <option value={''}>- Select Account -</option>
                                                    {this.props.accounts.map(element => {
                                                        if (element.type === 'Cash' || element.type === 'Payable') {
                                                            var balance = element.balance < 0 ? '0' : element.balance;
                                                            return <option value={(element._id + '-' + balance + '-' + element.type)}>{element.acc_name}</option>
                                                        }
                                                    })}
                                                </select> */}
                                                    <SearchableSelectComp options={this.props.cashPayableAccounts} label={'label'} onChange={e => e.value === '' ? null : this.setCurrentAccount(e.value)} value={this.state.currentAccount} />
                                                </div>
                                                <div class="col-md-6">
                                                    <h5>Available Amount : {Number(this.state.availableAmount).toFixed(2)}</h5>
                                                    <p style={{ 'color': 'red', 'display': (this.state.totalAmount > this.state.availableAmount && this.state.accountType !== 'Payable') ? 'block' : 'none' }}>Account balance is not enough.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-body" style={{ 'width': '50%' }}>
                                            {/* <select className="form-control" onChange={e => e.target.value === '' ? null : this.setSupplierAccount(e.target.value)}>
                                            <option value={''}>- Select Supplier Account -</option>
                                            {this.props.accounts.map(element => {
                                                if (element.type === 'Cash' || element.type === 'Expenses') {
                                                    return <option value={element._id} selected={this.state.supplierAccount === element._id}>{element.acc_name}</option>
                                                }
                                            })}
                                        </select> */}
                                            <SearchableSelectComp options={this.props.cashExpnsesAccounts} label={'acc_name'} onChange={e => e.value === '' ? null : this.setSupplierAccount(e.value)} value={this.state.supplierAccount} />
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h5>Total Amount : <span style={{ 'color': (this.state.totalAmount > this.state.availableAmount && this.state.accountType !== 'Payable' ? 'red' : '') }}>{this.state.totalAmount}</span></h5>
                                                    <h5>Transaction Amount : {Number(this.state.totalAmount - this.state.refundBy - this.state.creditNoteBy).toFixed(2)}</h5>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h5>Refund By</h5>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <input type="text" className="form-control" disabled value={this.state.refundBy} />
                                                            <br />
                                                            {
                                                                this.state.totalRefundValue === 0 ? <label>Refund</label> :
                                                                    <a href="#" data-toggle="modal" data-target='#refundModal'
                                                                        className='btn btn-link'
                                                                        data-controls-modal="refundModal" data-backdrop="static" data-keyboard="false"
                                                                        onClick={() => {
                                                                            var supplier = this.state.currentSupplierId;

                                                                            const data = {
                                                                                supplier: supplier
                                                                            };

                                                                            axios.defaults.headers.common['authorization'] = this.props.token;
                                                                            axios.post('/acc/payments/getRefundsDetail', { data })
                                                                                .then(result => {
                                                                                    this.setState({
                                                                                        refundsData: result.data.result,
                                                                                        totalRefundValue: result.data.total
                                                                                    });
                                                                                }).catch(err => {
                                                                                    console.log(err);
                                                                                })
                                                                        }}
                                                                    >Refund</a>
                                                            }

                                                            &nbsp;

                                                            <label style={{ "fontSize": "16px", "fontWeight": "bold" }}>Total: {this.state.totalRefundValue}</label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h5>Credit Note</h5>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <input type="text" className="form-control" disabled value={this.state.creditNoteBy} />
                                                            <br />
                                                            {
                                                                this.state.totalCreditNotePurchase === 0 ? <label>Credit Note</label> :
                                                                    <a href="#" data-toggle="modal" data-target='#creditNoteModal'
                                                                        className='btn btn-link'
                                                                        data-controls-modal="creditNoteModal" data-backdrop="static" data-keyboard="false"
                                                                        onClick={() => {
                                                                            var supplier = this.state.currentSupplierId;

                                                                            const data = {
                                                                                supplier: supplier
                                                                            };

                                                                            axios.defaults.headers.common['authorization'] = this.props.token;
                                                                            axios.post('/acc/payments/getCreditNotePurchaseDetail', { data })
                                                                                .then(result => {
                                                                                    this.setState({
                                                                                        creditNotePurchaseData: result.data.result,
                                                                                        totalCreditNotePurchase: result.data.total
                                                                                    });
                                                                                }).catch(err => {
                                                                                    console.log(err);
                                                                                })
                                                                        }}
                                                                    >Credit Note</a>
                                                            }

                                                            &nbsp;

                                                            <label style={{ "fontSize": "16px", "fontWeight": "bold" }}>Total: {this.state.totalCreditNotePurchase}</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <input className="form-control" placeholder="Note To Transaction" type="text" onChange={(e) => this.setState({ noteToTransaction: e.target.value })} />
                                        </div>
                                        <div className="modal-body">
                                            <React.Fragment>
                                                <ErrorBoundary>
                                                    <FormProvider
                                                        form="inline"
                                                        onSubmit={this.handleSubmit}
                                                        onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                                        initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                                        enableReinitialize
                                                    >
                                                        {formProps => {
                                                            return (
                                                                <form onSubmit={formProps.handleSubmit}>
                                                                    <CheckboxTable className="-striped"
                                                                        ref={(r) => (this.checkboxTable = r)}
                                                                        sorted={this.state.sortOptionsPO}
                                                                        onSortedChange={val => {
                                                                            this.setState({ sortOptionsPO: val })
                                                                        }}
                                                                        columns={this.purcahseOrderColumns}
                                                                        data={this.state.purcahseOrderData}
                                                                        defaultPageSize={10}
                                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                                        showPageJump={false}
                                                                        {...checkboxProps}
                                                                    />
                                                                </form>
                                                            )
                                                        }}

                                                    </FormProvider>
                                                </ErrorBoundary>
                                            </React.Fragment>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" disabled={(this.state.totalAmount > this.state.availableAmount && this.state.accountType !== 'Payable') || (this.state.checkedArr.length < 1) || (this.state.disablePayButton === true)} onClick={() => this.pay()} >Pay</button>
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.closePurchaseOrderPopup()}>Close</button>
                                        </div>
                                    </div>
                                </div>
                                : <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content animate" >
                                        <div className="modal-header">
                                            <h4 className="modal-title text-uppercase">Message</h4>
                                            <button type="button" className="close" onClick={e => this.close()}>&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h6>Select the payment method</h6>
                                                </div>
                                                <div className="col-md-6">
                                                    <SearchableSelectComp options={this.paymentTypes} label={'label'} onChange={e => this.setState({ paymentMethod: e.value })} value={this.state.paymentMethod} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" disabled={(this.state.totalAmount > this.state.availableAmount && this.state.accountType !== 'Payable') || (this.state.checkedArr.length < 1) || (this.state.disablePayButton === true)} onClick={() => this.done()}>Done</button>
                                            <button type="button" className="btn btn-secondary" onClick={e => this.close()}>Close</button>
                                        </div>
                                    </div>
                                </div>}
                        </div>


                        <div id="refundModal" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Refunds Detail</h4>
                                        <button type="button" className="close" data-dismiss="modal" onClick={e => this.setModelOpenClass()}>&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <FormProvider
                                                    form="inline"
                                                >
                                                    {formProps => {
                                                        return (
                                                            <form>
                                                                <Table className="-striped"
                                                                    sorted={this.state.sortOptionsRF}
                                                                    onSortedChange={val => {
                                                                        this.setState({ sortOptionsRF: val })
                                                                    }}
                                                                    columns={this.refundColumns}
                                                                    data={this.state.refundsData}
                                                                    defaultPageSize={10}
                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                    showPageJump={false}
                                                                />
                                                            </form>
                                                        )
                                                    }}

                                                </FormProvider>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={e => this.setModelOpenClass()}>Submit</button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.setModelOpenClass()}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="creditNoteModal" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content animate" >
                                    <div className="modal-header">
                                        <h4 className="modal-title text-uppercase">Credit Note Detail</h4>
                                        <button type="button" className="close" data-dismiss="modal" onClick={e => this.setModelOpenClass()}>&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <React.Fragment>
                                            <ErrorBoundary>
                                                <FormProvider
                                                    form="inline"
                                                >
                                                    {formProps => {
                                                        return (
                                                            <form>
                                                                <Table className="-striped"
                                                                    sorted={this.state.sortOptionsRF}
                                                                    onSortedChange={val => {
                                                                        this.setState({ sortOptionsRF: val })
                                                                    }}
                                                                    columns={this.creditNotePurchaseColumns}
                                                                    data={this.state.creditNotePurchaseData}
                                                                    defaultPageSize={10}
                                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                                    showPageJump={false}
                                                                />
                                                            </form>
                                                        )
                                                    }}

                                                </FormProvider>
                                            </ErrorBoundary>
                                        </React.Fragment>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={e => this.setModelOpenClass()}>Submit</button>
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={e => this.setModelOpenClass()}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="card-footer"></div>
                    </div>

                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        paymentsData: state.rPayments.paymentsData,
        monthsData: state.rPayments.monthsData,
        totals: state.rPayments.totals,
        isLoading: state.rLogin.loading,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option,
        userId: state.rLogin.id,
        accounts: state.rAccounts.accounts,
        cashPayableAccounts: state.rAccounts.cashPayableAccounts,
        cashExpnsesAccounts: state.rAccounts.cashExpnsesAccounts,
        reportData: state.rPayments.reportData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPaymentsData: (token) => { dispatch(getPaymentsData(token)) },
        getNotCompletedPaymentsData: (token) => { dispatch(getNotCompletedPaymentsData(token)) },
        addPayment: (data, token) => { dispatch(addPayment(data, token)) },
        updatePayment: (data, token) => { dispatch(updatePayment(data, token)) },
        deletePayment: (data, token) => { dispatch(deletePayment(data, token)) },
        addPaymentSchedule: (data, token) => { dispatch(addPaymentSchedule(data, token)) },
        updatePaymentSchedule: (data, token) => { dispatch(updatePaymentSchedule(data, token)) },
        deletePaymentSchedule: (data, token) => { dispatch(deletePaymentSchedule(data, token)) },
        getAccountsData: (token) => { dispatch(getAccountsData(token)) },
        getReportData: (token, callback) => { dispatch(getReportData(token, callback)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments);


