/**
 * @file This file defined for customer order controller
 * @author Umesh Ranthilina
 */
import React from 'react';
import { Route } from 'react-router';
import TV_Dashboard from '../container/views/Delivery/TV_Dashboard';

export const TVController = props => {

    return (
        <>
            <Route path="/tvLine" exact component={TV_Dashboard} />
        </>
    )
}

export default TVController
