import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Table from "react-table"
import * as BS from "react-bootstrap"
import { Field } from "redux-form"

import ActionsCell from "../../components/ActionCell"
import GridFilter from '../../components/GridFilter'
import HighlightCell from '../../components/HighlightCell'
import FormProvider from "../../components/FormProvider"
import ErrorBoundary from '../../components/ErrorBoundary'
import { Permission } from '../../utils'
import { getCompanies, addCompany, updateCompany, deleteCompany } from '../../../actions/Basic_data/comapny_actions'
import { toast } from 'react-toastify'

export const Companies = (props) => {


    const [editing, setEditing] = useState(null)
    const [deleting, setDeleting] = useState(null)

    const [name, setName] = useState(null)
    const [api, setApi] = useState(null)
    const [apiToken, setApiToken] = useState(null)
    const [warehouse, setWarehouse] = useState(null)

    useEffect(() => {
        props.getCompanies(props.token)
    }, [])

    const getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => setEditing(rowProps.original),
                onCancel: () => setEditing(null),
                onDelete: () => setDeleting(rowProps.original)
            },
            updPerm: Permission('BASIC DATA', 'COMPANIES', 'UPDATE', props),
            delPerm: Permission('BASIC DATA', 'COMPANIES', 'DELETE', props)
        }) ||
        {}

    const editableComponent = ({ input, edit, value, ...rest }) => {
        const Component = edit ? BS.FormControl : BS.FormControl.Static
        const children =
            (!edit && <HighlightCell value={value} {...rest} />) || undefined
        return <Component  {...input} {...rest} children={children} />
    }

    const editableColumnProps = {
        ...GridFilter,
        Cell: props => {
            const edit = editing === props.original
            const fieldProps = {
                component: editableComponent,
                edit,
                props
            }
            return <Field name={props.column.id} {...fieldProps} />
        }
    }

    const columns = [
        { Header: "Name", accessor: "name", ...editableColumnProps },
        { Header: "Warehosue", accessor: "warehouse", ...editableColumnProps },
        { Header: "API", accessor: "api", ...editableColumnProps },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: getActionProps,
            Cell: ActionsCell
        },
    ]

    const addCompanyHandler = () => {
        if (name !== null && name !== '' && api !== null && api !== '' && warehouse !== null && warehouse !== '') {
            const data = {
                'name': name,
                'api': api,
                'apiToken': apiToken,
                'warehouse': warehouse
            }

            props.addCompany(data, props.token)
            clearForm()
        } else {
            toast.error('Please fill all the form fields.')
        }
    }

    const clearForm = () => {
        setName(null)
        setApi(null)
        setApiToken(null)
        document.getElementById("addCompanyForm").reset()
    }

    const handleSubmit = values => {
        if (deleting === null && editing !== null) {
            let data = values
            data.updated_at = new Date()

            props.updateCompany(data, props.token)
        } else if (deleting !== null && editing === null) {
            let data = {
                '_id': values._id
            }

            props.deleteCompany(data, props.token)
        }
    }

    return (
        <div className="right_col" role="main">
            <div className="card">
                <div className="card-header">
                    <button
                        className="btn btn-primary"
                        disabled={Permission('BASIC DATA', 'COMPANIES', 'ADD', props)}
                        data-toggle="modal"
                        data-target="#addCompany"
                    >
                        + Company
                    </button>
                </div>
                <div className="card-body">
                    <ErrorBoundary>
                        <FormProvider
                            form="inline"
                            onSubmit={handleSubmit}
                            onSubmitSuccess={() => { setEditing(null); setDeleting(null) }}
                            initialValues={editing === null ? deleting : editing}
                            enableReinitialize
                        >
                            {formProps => {
                                return (
                                    <form onSubmit={formProps.handleSubmit}>
                                        <Table className="-striped"
                                            columns={columns}
                                            data={props.companies}
                                            defaultPageSize={10}
                                        />
                                    </form>
                                )
                            }}
                        </FormProvider>
                    </ErrorBoundary>
                </div>
            </div>


            <div id="addCompany" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content animate" >
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase">Add Company</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <React.Fragment>
                                <form id='addCompanyForm'>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>Name* :</td>
                                                <td><input type="text" value={name} className="form-control" onChange={e => setName(e.target.value)} /></td>
                                            </tr>

                                            <tr>
                                                <td>API* :</td>
                                                <td><input type='text' value={api} className="form-control" onChange={e => setApi(e.target.value)} /></td>
                                            </tr>

                                            <tr>
                                                <td>Warehouse* :</td>
                                                <td><input type='text' value={warehouse} className="form-control" onChange={e => setWarehouse(e.target.value)} /></td>
                                            </tr>

                                            <tr>
                                                <td>Token* :</td>
                                                <td><input type='text' value={apiToken} className="form-control" onChange={e => setApiToken(e.target.value)} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </React.Fragment>
                        </div>
                        <div className="modal-footer">
                            <button id="" type="button" className="btn btn-primary" onClick={e => addCompanyHandler()}>Add</button>
                            <button type="button" onClick={e => clearForm()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    token: state.rLogin.token,
    id: state.rLogin.id,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    companies: state.rBasicData.companies,
})

const mapDispatchToProps = dispatch => ({
    getCompanies: (token) => { dispatch(getCompanies(token)) },
    addCompany: (data, token) => { dispatch(addCompany(data, token)) },
    updateCompany: (data, token) => { dispatch(updateCompany(data, token)) },
    deleteCompany: (data, token) => { dispatch(deleteCompany(data, token)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
