/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';

import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
import _ from 'lodash';
import { toast } from "react-toastify";
import axios from 'axios';
import Select from 'react-select';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import { getProductItems, updateStocks, deleteStock, addStock, formatDate, getItems, getSupplierData, updateItemData } from '../../../actions/Stock/stock_actions';
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from "../../utils/Permission";
import { DeliveredStatus } from "../../utils/ConstData";


class Stock extends React.Component {

  stockReasonStatus = [
    { 'value': 'Missing Items', 'name': 'Missing Items' },
    { 'value': 'Damage Items', 'name': 'Damage Items' },
    { 'value': 'Expired Items', 'name': 'Expired Items' },
    { 'value': 'Increase Stock', 'name': 'Increase Stock' },
  ];

  oneMonthAgo = new Date();

  state = {
    data: [],
    editing: null,
    deleting: null,
    itemData: [],
    supplierData: [],
    stock: 0,
    reservationStock: 0,
    remark: '',
    item: '',
    items: [],
    availableStock: 0,
    productId: '',
    stockHistory: [],
    expanded: {},
    slug: '',
    startDate: '',
    endDate: '',
    currentItemInFilter: '',
    reservationStockPopupData: [],
    sortOptions: [{ id: 'orderId', desc: true }],
    userId:''
  };

  componentDidMount() {
    this.oneMonthAgo.setMonth(this.oneMonthAgo.getMonth() - 1)
    this.props.getSupplierData(this.props.token)
    this.props.getProductItems(this.props.token)
    this.props.getItems(this.props.token)
    this.setState({
      userId: this.props.userId
  })
  }

  reload(){
    this.props.getSupplierData(this.props.token)
    this.props.getProductItems(this.props.token)
    this.props.getItems(this.props.token)
  }

  selectableColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = this.state.editing === props.original;

      return (editing ? <Field name={props.column.id} component="select" className='form-control'>
        {
          (this.stockReasonStatus).map(data =>
            <option key={data.value} value={data.value}>{data.name}</option>
          )}
      </Field> : <label>{props.value}</label>)
    }
  };

  editableComponent = ({ input, editing, value, original, ...rest }) => {
    const Component = editing ? BS.FormControl : BS.FormControl.Static;
    const children =
      (!editing && <HighlightCell value={value} {...rest} />) || undefined;
    return <Component {...input} {...rest} children={children} />;
  };

  editableColumnProps = {
    ...GridFilters,
    Cell: props => {
      const editing = this.state.editing === props.original;
      const fieldProps = {
        component: this.editableComponent,
        editing,
        props
      };

      return <Field name={props.column.id} {...fieldProps} />;
    }
  };

  getActionProps = (gridState, rowProps) =>
    (rowProps && {
      mode: this.state.editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => this.setState({ editing: rowProps.original }),
        onCancel: () => this.setState({ editing: null }),
        onDelete: () => {
          this.setState({ deleting: rowProps.original })
        }
      },
      updPerm: Permission('STOCK', 'STOCK', 'UPDATE', this.props),
      delPerm: Permission('STOCK', 'STOCK', 'DELETE', this.props),
    }) ||
    {};



  columns = [
    { Header: "Name", accessor: "name", filterable: true },
    { Header: "Reservation Stock", accessor: "reservationStock", filterable: true },
  ];

  reservationStockPopupColumns = [
    { Header: "Order ID", accessor: "orderId", filterable: true },
    { Header: "Quantity", accessor: "quantity", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Customer Name", accessor: "name", filterable: true },
    { Header: "Telephone", accessor: "telephone", filterable: true },
    {
      Header: 'Whatsapp',
      maxWidth: 130,
      minWidth: 130,
      filterable: true,
      Cell: row => {

        const url = "https://api.whatsapp.com/send?phone=" + row.original.whatsapp;
        return <u><a href={url} target="_blank">{row.original.whatsapp}</a></u>
      }
    },
    { Header: "Address", accessor: "address", filterable: true },
    { Header: "City", accessor: "city", filterable: true },
  ];

  stockHistoryColumns = [
    { Header: "Stock", accessor: "stock", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Available Stock", accessor: "availableStock", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Reservation Stock", accessor: "reservationStock", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Stock Difference", accessor: "stockDifference", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Remark", accessor: "remark", filterable: true },
    { Header: "Date", accessor: "date", filterable: true },
  ];

  itemColumns = [
    {
      Header: 'Name',
      accessor: "item_name",
      filterable: true,
      maxWidth: 250,
      minWidth: 250,
      Cell: row =>
        <>
          <button data-toggle="modal" data-target='#reservationStockPopup' className='btn btn-link'
            onClick={() => {
              axios.defaults.headers.common['authorization'] = this.props.token;

              const data = {
                'item': row.original._id
              }

              axios.post('/stock/getOrderItems', { data })
                .then(result => {
                  var orderItems = result.data;
                  axios.get('/stock/getOrders')
                    .then(result => {
                      var orders = result.data;
                      var finalData = [];

                      orderItems.forEach(orderItemElement => {
                        orders.forEach(orderElement => {
                          for (var i = 0; i < orderElement.orders.orderItems.length; i++) {
                            if (orderItemElement._id === orderElement.orders.orderItems[i] && !DeliveredStatus.includes(orderElement.orders.status)) {

                              var arr = {
                                'quantity': orderItemElement.quantity,
                                'orderId': orderElement.orders.orderId,
                                'address': orderElement.orders.address,
                                'telephone': orderElement.orders.telephone,
                                'name': orderElement.orders.name,
                                'city': orderElement.cities.name,
                                'whatsapp': orderElement.orders.whatsapp
                              }

                              finalData.push(arr);
                            }
                          }
                        });
                      });

                      this.setState({ reservationStockPopupData: finalData });

                    }).catch(err => {
                      console.error(err)
                    })
                }).catch(err => {
                  console.error(err)
                })

            }}
          >{row.value}</button>
        </>
    },
    { Header: "Purchase Price", accessor: "purchasePrice", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Sale Price", accessor: "price", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Stock", accessor: "stock", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Available Stock", accessor: "availableStock", filterable: true, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Stock Unit", accessor: "stockUnit", ...this.editableColumnProps, getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Reservation Stock", accessor: "reservationStock", getProps: () => { return { style: { 'text-align': 'right' } } } },
    { Header: "Reason", accessor: "reason", ...this.selectableColumnProps },
    {
      Header: 'Stock History',
      maxWidth: 130,
      minWidth: 130,
      Cell: row =>
        <>
          <button data-toggle="modal" data-target='#stockHistory' className='btn btn-link'
            onClick={() => {
              axios.defaults.headers.common['authorization'] = this.props.token;
              this.setState({ currentItemInFilter: row.original._id });

              this.state.startDate = new Date(this.oneMonthAgo);
              this.state.endDate = new Date();

              const data = {
                'item': row.original._id,
                'startDate': formatDate(this.state.startDate),
                'endDate': formatDate(this.state.endDate)
              }

              axios.post('/stock/getStockHistoryByFilter', { data })
                .then(result => {
                  this.setState({ stockHistory: result.data });
                }).catch(err => {
                  console.error(err)
                })
            }}
          >view</button>
        </>
    },
    {
      Header: "Actions",
      maxWidth: 130,
      minWidth: 130,
      filterable: false,
      getProps: this.getActionProps,
      Cell: ActionsCell
    },
  ];


  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return (
      row[id] !== undefined ?
        String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
        true
    );
  }

  handleSubmit = values => {

    if (this.state.deleting === null && this.state.editing !== null) {
      var availableStock = values.stock - values.reservationStock;

      if (values.stock_id !== null) {
        if (values.reason !== "" && values.reason !== null && values.reason !== undefined) {
          var data = {
            '_id': values.stock_id,
            'stock': Number(values.stock),
            'availableStock': Number(availableStock),
            'reservationStock': Number(values.reservationStock),
            'item_id': values._id,
            'previousStock': Number(values.previousStock),
            'user': this.props.userId,
            'reason': values.reason,
            'remark': values.reason,
          };

          var itemUpdateData = {
            '_id': values._id,
            'purchasePrice': values.purchasePrice,
            'price': values.price
          };

          this.props.updateStocks(data, this.props.token);
          this.props.updateItemData(itemUpdateData, this.props.token);
        }else{
          this.reload()
          toast.error('Stock items reason is mandatory!')
        }
      }
    } else if (this.state.deleting !== null && this.state.editing === null) {
      var data = {
        '_id': values.stock_id
      }

      const resStock = values.reservationStock;

      if (resStock > 0) {
        toast.info('Stock with reservation stock data cannot be deleted!');
        return;
      }

      this.props.deleteStock(data, this.props.token)
    }

  };

  addStockHandle = () => {
    var { stock, reservationStock, item, remark } = this.state;

    if (stock !== '' && item !== '') {
      if (isNaN(stock)) {
        toast.error('Stock must be a number!')
      } else if (isNaN(reservationStock)) {
        toast.error('reservationStock must be a number!')
      } else {

        this.state.availableStock = this.state.stock - this.state.reservationStock;

        this.state.reservationStock = Number(this.state.reservationStock)

        this.state.slug = '1';

        this.props.addStock(this.state, this.props.token)

        this.setState({
          stock: '',
          reservationStock: '',
          item: '',
          remark: '',
          availableStock: ''
        });

        this.props.getItems(this.props.token)

        document.getElementById("addStockForm").reset();
      }
    } else {
      toast.error('Stock and Item are mandatory!')
    }
  }

  handleRowExpanded = (rowsState, index) => {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]],
      },
    });
  }

  setStartDate = date => {
    this.setState({
      startDate: date
    });
  }

  setEndDate = date => {
    this.setState({
      endDate: date
    });
  }

  filterStockHistoryData = () => {
    var startDate = this.state.startDate;
    var endDate = this.state.endDate;

    if (startDate === '') {
      toast.error('Date From is Empty')
    }

    if (endDate === '') {
      toast.error('Date To is Empty')
    }

    if (startDate !== '' && endDate !== '') {
      var startDate = new Date(Date.parse(startDate)).toISOString();

      var endDate = new Date(Date.parse(endDate)).toISOString();

      const data = {
        'startDate': formatDate(startDate),
        'endDate': formatDate(endDate),
        'item': this.state.currentItemInFilter
      };

      axios.defaults.headers.common['authorization'] = this.props.token;
      axios.post('/stock/getStockHistoryByFilter', { data })
        .then(result => {
          this.setState({ stockHistory: result.data });
        }).catch(err => {
          toast.error(`${err}`)
        })
    }
  }

  render() {
    return (
      <div className="right_col" role="main">
        <div className="card">
          <div className="card-header">
            <button className="btn btn-primary" disabled={Permission('STOCK', 'STOCK', 'ADD', this.props)} data-toggle="modal" data-target="#addStock">+ Stock</button>
          </div>
          <div className="card-body">
            <FormProvider
              form="inline"
            >
              {formProps => {
                return (
                  <Table className="-striped"
                    columns={this.columns}
                    data={this.props.supplierData}
                    defaultPageSize={10}
                    expanded={this.state.expanded}
                    onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index)}
                    defaultFilterMethod={this.filterCaseInsensitive}
                    showPageJump={false}
                    SubComponent={row => {
                      var subData = _.filter(this.props.itemData, function (o) {
                        return o.supplier_ref === row.original._id && o.stock_id !== null
                      })
                      return (
                        <React.Fragment>
                          <ErrorBoundary>
                            <FormProvider
                              form="inline"
                              onSubmit={this.handleSubmit}
                              onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                              initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                              enableReinitialize
                            >
                              {formProps => {
                                return (
                                  <form onSubmit={formProps.handleSubmit}>
                                    <Table className="-striped"
                                      columns={this.itemColumns}
                                      data={subData}
                                      defaultPageSize={10}
                                      defaultFilterMethod={this.filterCaseInsensitive}
                                      showPageJump={false}
                                    />
                                  </form>
                                )
                              }}

                            </FormProvider>
                          </ErrorBoundary>
                        </React.Fragment>
                      );
                    }}
                  />
                );
              }}
            </FormProvider>
          </div>
          <div className="card-footer"></div>

          <div id="addStock" className="modal fade" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content animate" >
                <div className="modal-header">
                  <h4 className="modal-title text-uppercase">Add Stock</h4>
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                  <React.Fragment>
                    <form id='addStockForm'>
                      <table>
                        <tbody>
                          <tr>
                            <td>Item* :</td>
                            <td>
                              <Select
                                options={this.props.items}
                                onChange={e => e.value === '' ? null : this.setState({ item: e.value })}
                                defaultValue=''
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Stock* :</td>
                            <td><input type="text" className="form-control minWidthCustom" onChange={e => this.setState({ stock: Number(e.target.value) })} /></td>
                          </tr>
                          <tr>
                            <td>Reservation Stock  :</td>
                            <td><input type="text" className="form-control" onChange={e => this.setState({ reservationStock: Number(e.target.value) })} /></td>
                          </tr>
                          <tr>
                            <td>Remark :</td>
                            <td><input type="text" className="form-control" onChange={e => this.setState({ remark: e.target.value })} /></td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </React.Fragment>
                </div>
                <div className="modal-footer">
                  <button id="add_stock" type="button" className="btn btn-primary" onClick={this.addStockHandle}>Add</button>
                  {/* <Button id='add_supplier' click={this.props.addSupplier(this.state, this.props.token)} value={'add'}/> */}
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>

          <div id='stockHistory' className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg">
              <div className="modal-content animate" >
                <div className="modal-header">
                  <h4 className="modal-title text-uppercase">Stock History</h4>
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-header">
                  <h6 className="modal-title text-uppercase">Filter</h6>
                  <span className='pull-left'>
                    Date From:
                    <DatePicker selected={this.state.startDate} onChange={this.setStartDate} maxDate={new Date()} />
                  </span>

                  <span className='pull-left'>
                    Date To:
                    <DatePicker selected={this.state.endDate} onChange={this.setEndDate} maxDate={new Date()} />
                  </span>
                  <span>
                    <button type="button" className="btn btn-primary" onClick={this.filterStockHistoryData}>Filter</button>
                  </span>
                </div>
                <div className="modal-body">
                  {(this.state.stockHistory === null || this.state.stockHistory.length === 0) ?
                    <>
                      No Data Found...!
                    </>
                    :
                    <React.Fragment>
                      <ErrorBoundary>
                        <FormProvider
                          form="inline"
                        >
                          {formProps => {
                            return (
                              <form>
                                <Table className="-striped"
                                  columns={this.stockHistoryColumns}
                                  data={this.state.stockHistory}
                                  defaultPageSize={10}
                                  defaultFilterMethod={this.filterCaseInsensitive}
                                />
                              </form>
                            )
                          }}

                        </FormProvider>
                      </ErrorBoundary>
                    </React.Fragment>
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>

          <div id='reservationStockPopup' className="modal fade" role="dialog">
            <div className="modal-dialog modal-lg">
              <div className="modal-content animate" >
                <div className="modal-header">
                  <h4 className="modal-title text-uppercase">Reservation Stock</h4>
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                  {(this.state.reservationStockPopupData === null || this.state.reservationStockPopupData.length === 0) ?
                    <>
                      No Data Found...!
                    </>
                    :
                    <React.Fragment>
                      <ErrorBoundary>
                        <FormProvider
                          form="inline"
                        >
                          {formProps => {
                            return (
                              <form>
                                <Table className="-striped"
                                  sorted={this.state.sortOptions}
                                  onSortedChange={val => {
                                    this.setState({ sortOptions: val })
                                  }}
                                  columns={this.reservationStockPopupColumns}
                                  data={this.state.reservationStockPopupData}
                                  defaultPageSize={10}
                                  defaultFilterMethod={this.filterCaseInsensitive}
                                />
                              </form>
                            )
                          }}

                        </FormProvider>
                      </ErrorBoundary>
                    </React.Fragment>
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.rStock.data,
    items: state.rStock.items,
    itemData: state.rStock.itemData,
    token: state.rLogin.token,
    stockHistory: state.rStock.stockHistory,
    supplierData: state.rStock.supplierData,
    userProgramme: state.rLogin.programme,
    programme: state.rPermission.programme,
    subProgramme: state.rPermission.subProgramme,
    options: state.rPermission.option,
    userId: state.rLogin.id,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSupplierData: (token) => { dispatch(getSupplierData(token)) },
    getItems: (token) => { dispatch(getItems(token)) },
    getProductItems: (token) => { dispatch(getProductItems(token)) },
    updateStocks: (data, token) => { dispatch(updateStocks(data, token)) },
    deleteStock: (data, token) => { dispatch(deleteStock(data, token)) },
    addStock: (data, token) => { dispatch(addStock(data, token)) },
    formatDate: (date) => { dispatch(formatDate(date)) },
    updateItemData: (data, token) => { dispatch(updateItemData(data, token)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stock);


