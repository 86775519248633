import _ from 'lodash';

/**
 * @type {Object}
 * @property {array}  data  Order data
 */
const initialState = {
    data: [],
    purchase_item_data: [],
    purchaseItemById: [],
    canceledPurchaseItem: [],
    receivedOrders: [],
    receivedItems: [],
    newItems: [],
    newAllItems: []
};

/**
 * 
 * @param {Object} state    Initial state of store 
 * @param {Object} action   action data
 * @returns {Object} newState 
 */
const orderReducer = (state = initialState, action) => {
    const newState = { ...state }
    var { data } = action;
    switch (action.type) {
        case 'FETCH_ORDER':
            newState.data = data;
            break;

        case 'FETCH_PURCHASE_ITEM':
            // var { data } = action;
            newState.purchase_item_data = data;
            break;

        case 'FETCH_PURCHASE_ITEM_BY_ID':
            // var { data } = action;
            newState.purchaseItemById = data;
            break;

        case 'FETCH_CANCELED_PURCHASE_ITEM':
            newState.canceledPurchaseItem = data;
            break;

        case 'FETCH_RECEIVED_ORDER':
            newState.receivedOrders = data;
            break;

        case 'FETCH_PURCHASE_RECEIVED_ITEM_BY_ID':
            // var { data } = action;
            newState.receivedItems = data;
            break;

        case 'FETCH_NEW_ITEMS':
            newState.newItems = data;
            break;

        case 'FETCH_NEW_ALL_ITEMS':
            newState.newAllItems = data;
            break;

        default:
            break;
    }
    return newState;
}

export default orderReducer;