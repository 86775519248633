/**
 * @author Ehsan Elahi
 */

import React from "react";
import { connect } from 'react-redux';
import { Field } from "redux-form";
import Table from "react-table";
import * as BS from "react-bootstrap";
import "react-table/react-table.css";
// import _ from 'lodash';
import { toast } from "react-toastify";
import withFixedColumns from 'react-table-hoc-fixed-columns';

import FormProvider from "../../components/FormProvider";
import ActionsCell from "../../components/ActionCell";
import HighlightCell from "../../components/HighlightCell";
import GridFilters from "../../components/GridFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import Permission from '../../utils/Permission';

import { getCategoryData, addCategory, updateCategory, deleteCategory } from '../../../actions/Basic_data/category_actions';

import 'react-table-hoc-fixed-columns/lib/styles.css';

const ReactTableFixedColumns = withFixedColumns(Table);


class Category extends React.Component {

    statusData = [
        { '_id': 'Active', 'name': 'Active' },
        { '_id': 'Inactive', 'name': 'Inactive' }
    ];

    userTypeData = [
        { '_id': 'All', 'name': 'All' },
        { '_id': 'Normal', 'name': 'Normal' },
        { '_id': 'Admin', 'name': 'Admin' },
        { '_id': 'Data Operator', 'name': 'Data Operator' },
        { '_id': 'Corporate', 'name': 'Corporate' },
        { '_id': 'Delivery', 'name': 'Delivery' },
    ];

    state = {
        categoryData: [],
        name: '',
        desc: '',
        userType: '',
        image: '',
        status: '',
        sortOrder: '',
        slug: '',
        updatedAt: Date.now(),
        createdAt: Date.now(),
        editing: null,
        deleting: null,
        currentCategoryImage: '',
        sortOptions: [{ id: 'sortOrder', desc: false }],
    };

    componentDidMount() {
        this.props.getCategoryData(this.props.token)
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        if (hours.length < 2) {
            hours = '0' + hours;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        let newDate = [year, month, day].join('-');
        let time = [hours, minutes, seconds].join(':')

        return [newDate, time].join(' ');
    }

    editableComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.FormControl : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };

    editableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} {...fieldProps} />;
        }
    };

    editableNumberColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            const fieldProps = {
                component: this.editableComponent,
                editing,
                props
            };

            return <Field name={props.column.id} type="Number" {...fieldProps} />;
        }
    };

    editableSelectComponent = ({ input, editing, value, ...rest }) => {
        const Component = editing ? BS.DropdownButton : BS.FormControl.Static;
        const children =
            (!editing && <HighlightCell value={value} {...rest} />) || undefined;
        return <Component {...input} {...rest} children={children} />;
    };


    selectableColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            // const fieldProps = {
            //     component: this.renderDropdownList,
            //     editing,
            //     props
            // };

            return (editing ? <Field name={props.column.id} component="select" className='form-control' onBlur={e => this.setStateForEdit(props.column.Header, e.target.value)}>
                {(
                    props.column.Header === 'User Type' ? this.userTypeData :
                        this.statusData).map(data =>
                            <option key={data._id} value={data._id}>{data.name}</option>
                        )}
            </Field> : <label>{props.value}</label>)
        }
    };

    imageColumnProps = {
        ...GridFilters,
        Cell: props => {
            const editing = this.state.editing === props.original;
            // const fieldProps = {
            //     component: null,
            //     editing,
            //     props
            // };

            return (editing ? <Field name={props.column.id} component="image" className='form-control'>
                <input type="file" key='image' name='image' onChange={e => this.setState({ image: e.target.files[0] })}></input>
            </Field> : <label>{props.value}</label>)
        }
    };

    getActionProps = (gridState, rowProps) =>
        (rowProps && {
            mode: this.state.editing === rowProps.original ? "edit" : "view",
            actions: {
                onEdit: () => {
                    this.setState({
                        editing: rowProps.original,
                        status: rowProps.original.status
                    })
                },
                onCancel: () => this.setState({ editing: null }),
                onDelete: () => {
                    this.setState({ deleting: rowProps.original })
                }
            },
            updPerm: Permission('BASIC DATA', 'CATEGORY', 'UPDATE', this.props),
            delPerm: Permission('BASIC DATA', 'CATEGORY', 'DELETE', this.props)
        }) ||
        {};

    setStateForEdit = (column, value) => {
        if (column === 'Status') {
            this.setState({ status: value })
        } else if (column === 'User Type') {
            this.setState({ userType: value })
        }
    }

    handleSubmit = values => {
        if (this.state.deleting === null && this.state.editing !== null) {
            var data = values;
            data.image = this.state.image;
            data.status = this.state.status;
            data.sortOrder = Number(data.sortOrder);

            data.updatedAt = this.formatDate(Date.now());

            this.props.updateCategory(data, this.props.token)

            this.setState({
                image: '',
                status: '',
                userType: ''
            });
        } else if (this.state.deleting !== null && this.state.editing === null) {
            var data = {
                '_id': values._id
            }

            this.props.deleteCategory(data, this.props.token)
        }
    };

    addCategoryHandle = () => {
        var { name,
            userType,
            status,
            // desc,
            // image,
            // sortOrder 
        } = this.state;

        if (name.trim() !== ''
            && userType !== ''
            && status !== '') {

            this.state.slug = '1';
            this.state.createdAt = this.formatDate(this.state.createdAt);
            this.state.updatedAt = this.formatDate(this.state.updatedAt);
            this.state.sortOrder = Number(this.state.sortOrder);

            this.props.addCategory(this.state, this.props.token)

            this.setState({
                name: '',
                desc: '',
                userType: '',
                image: '',
                status: '',
                sortOrder: '',
            });

            document.getElementById("addCategoryForm").reset();
        } else {
            toast.error('Please fill up the mandatory fields!')
        }
    }

    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    categoryColumns = [
        { Header: "Name", accessor: "name", ...this.editableColumnProps },
        { Header: "Description", accessor: "desc", ...this.editableColumnProps },
        { Header: "User Type", accessor: "userType", ...this.selectableColumnProps },
        { Header: "Status", accessor: "status", ...this.selectableColumnProps },
        { Header: "Sort Order", accessor: "sortOrder", ...this.editableNumberColumnProps },
        { Header: "Image", accessor: "image.url", ...this.imageColumnProps },
        {
            Header: "View Image",
            accessor: "image.url",
            Cell: row =>
                <>
                    <button data-toggle="modal" data-target='#viewImage' className='btn btn-link'
                        onClick={() => {
                            this.state.currentCategoryImage = row.value;
                        }}
                    >{row.value === undefined ? '' : 'View Image'}</button>
                </>
        },
        { Header: "Created At", accessor: "createdAt", filterable: true },
        { Header: "Updated At", accessor: "updatedAt", filterable: true },
        {
            Header: "Actions",
            fixed: 'right',
            maxWidth: 130,
            minWidth: 130,
            filterable: false,
            getProps: this.getActionProps,
            Cell: ActionsCell
        },
    ];

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary" disabled={Permission('BASIC DATA', 'CATEGORY', 'ADD', this.props)} data-toggle="modal" data-target="#addCategory">+ Category</button>
                    </div>
                    <div className="card-body">
                        <React.Fragment>
                            <ErrorBoundary>
                                <FormProvider
                                    form="inline"
                                    onSubmit={this.handleSubmit}
                                    onSubmitSuccess={() => this.setState({ editing: null, deleting: null })}
                                    initialValues={this.state.editing === null ? this.state.deleting : this.state.editing}
                                    enableReinitialize
                                >
                                    {formProps => {
                                        return (
                                            <form onSubmit={formProps.handleSubmit}>
                                                <ReactTableFixedColumns className="-striped"
                                                    sorted={this.state.sortOptions}
                                                    onSortedChange={val => {
                                                        this.setState({ sortOptions: val })
                                                    }}
                                                    columns={this.categoryColumns}
                                                    data={this.props.categoryData}
                                                    defaultPageSize={10}
                                                    defaultFilterMethod={this.filterCaseInsensitive}
                                                    showPageJump={false}
                                                />
                                            </form>
                                        );
                                    }}
                                </FormProvider>
                            </ErrorBoundary>
                        </React.Fragment>
                    </div>

                    <div id="viewImage" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Image</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        {(this.state.currentCategoryImage === undefined) ? 'No Image Found' : <img src={this.state.currentCategoryImage} alt={'category_image'} width="100%" />}

                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="addCategory" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content animate" >
                                <div className="modal-header">
                                    <h4 className="modal-title text-uppercase">Add Category</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <React.Fragment>
                                        <form id='addCategoryForm'>
                                            <table>
                                                <tbody>

                                                    <tr>
                                                        <td>Name* :</td>
                                                        <td><input type="text" className="form-control" onChange={e => this.setState({ name: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Description :</td>
                                                        <td><input type="textarea" className="form-control" onChange={e => this.setState({ desc: e.target.value })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>User Type* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ userType: e.target.value })}>
                                                                <option value={''}>- Select User Type -</option>
                                                                {this.userTypeData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Image :</td>
                                                        <td><input type="file" className="form-control" onChange={e => this.setState({ image: e.target.files[0] })} /></td>
                                                    </tr>

                                                    <tr>
                                                        <td>Status* :</td>
                                                        <td>
                                                            <select className="form-control" onBlur={e => e.target.value === '' ? null : this.setState({ status: e.target.value })}>
                                                                <option value={''}>- Select Status -</option>
                                                                {this.statusData.map(element => {
                                                                    return <option value={element._id}>{element.name}</option>
                                                                })}
                                                            </select>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Sort Order :</td>
                                                        <td><input type="number" className="form-control" onChange={e => this.setState({ sortOrder: e.target.value })} /></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </form>
                                    </React.Fragment>
                                </div>
                                <div className="modal-footer">
                                    <button id="" type="button" className="btn btn-primary" onClick={this.addCategoryHandle}>Add</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.rLogin.token,
        categoryData: state.rCategory.categoryData,
        userProgramme: state.rLogin.programme,
        programme: state.rPermission.programme,
        subProgramme: state.rPermission.subProgramme,
        options: state.rPermission.option
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCategoryData: (token) => { dispatch(getCategoryData(token)) },
        addCategory: (data, token) => { dispatch(addCategory(data, token)) },
        updateCategory: (data, token) => { dispatch(updateCategory(data, token)) },
        deleteCategory: (data, token) => { dispatch(deleteCategory(data, token)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);


