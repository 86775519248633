/**
 * @author Umesh Ranthilina
 */

import React, { Component } from 'react'
import moment from "moment";
import { connect } from 'react-redux';
import Table from "react-table";
import "react-table/react-table.css";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";

import FormProvider from "../../components/FormProvider";
import ErrorBoundary from "../../components/ErrorBoundary";
import { DateNumberFormat } from '../../utils';

import { getStockExpireItemData } from '../../../actions/Stock/stock_expire_action';
import { getDeliveryWarehouses } from "../../../actions/Basic_data/delivery_warehouse_actions";
import * as XLSX from 'xlsx';
const fileExtension = '.xlsx';

export class StockExpireItem extends Component {
    state = {
        expireDate: '',
        selectOption: '',
        expanded: {},
        Item: [],
        warehouseData: [],
        stockExpItems: [],
    }

    componentDidMount() {
        this.props.getDeliveryWarehouses(this.props)
        this.setState({
            expireDate: moment(Date.now()).format("YYYY-MM-DD"),
        })
        this.props.getStockExpireItemData({ date: moment(Date.now()).format("YYYY-MM-DD"), warehouse: '' }, this.props)
    }

    onChangeDateValue = (e) => {
        let dateFormatted = moment(e.target.value).format("YYYY-MM-DD");
        this.setState({ expireDate: dateFormatted })
    }

    componentDidUpdate(prevProps) {
        const { stockExpireItems } = this.props;
        if (stockExpireItems !== prevProps.stockExpireItems) {

            var unique = [...new Map(this.props?.stockExpireItems.expireItem?.map(item => [`${item['exp_date']}-${item['ItemName']}`, item])).values()]
            let arr = unique?.map(ele => {
                let filterArr = stockExpireItems.expireItem.filter(obj => {
                    return ele.exp_date === obj.exp_date && ele.ItemName === obj.ItemName
                })
                let ids = filterArr.map(obj => obj._id)
                let value = filterArr.reduce((total, item) => total + item.qty, 0)
                return ({ ...ele, totatlVal: value, ids: ids })
            })
            this.setState({
                Item: stockExpireItems.items,
                warehouseData: stockExpireItems.warehouse,
                stockExpItems: arr
            })
        }
    }

    onSelect = (e, name) => {
        if (e)
            this.setState({ selectOption: (e.target.value) });
        else
            this.setState({ selectOption: null });
    };

    columns = [
        { Header: "Item Name", accessor: "name", filterable: true },
        { Header: "Item Code", accessor: "itemCode", filterable: true },
        { Header: "Qty", accessor: "itemTotal" },
    ];

    warehouseColumns = [
        { Header: "Warehouse Name", accessor: "name", filterable: true },
        { Header: "Qty", accessor: "warehouseTotal" },
    ];

    stockExpireItemColumns = [
        { Header: "Expire Date", accessor: "exp_date", Cell: prop => <>{DateNumberFormat(prop.value)}</> },
        { Header: "Qty", accessor: "totatlVal" },
    ];
    handleRowExpanded = (rowsState, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                :
                true
        );
    }

    exportData = () => {
        let allExportedData = this.props.stockExpireItems;
        let a = [''];

        var unique = [...new Map(this.props?.stockExpireItems.expireItem?.map(item => [`${item['name']}`, item])).values()]
        unique.forEach(tableData => {
            const processdTableData = this.processTableData(tableData, allExportedData);
            const ws = XLSX.utils.json_to_sheet(processdTableData);
            const header = [{ itemName: tableData.name + " Data " }]
            a = a.concat(header).concat(XLSX.utils.sheet_to_json(ws, { header: 1 }))

        });

        const worksheet = XLSX.utils.json_to_sheet(a, { skipHeader: true });
        const new_workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(new_workbook, worksheet, " Expire_stock_item")
        XLSX.writeFile(new_workbook, 'Expire Stock Item' + fileExtension);
    }

    processTableData = (tableData, allExportedData) => {

        let array = [];
        allExportedData.items.map((item, index) => {
            if (tableData.name === item.warehouse_name) {
                this.state.stockExpItems.map((o) => {
                    if (item.name === o.ItemName) {
                       let data = {
                            "Item Name": item.name,
                            "Item Code": item.itemCode,
                            "Expire Date": moment(o.exp_date).format("YYYY-MM-DD"),
                            "Quantity": o.totatlVal,
                            "ShelfLife": o.ShelfLife,
                            "% Shelf Remain": o.ShelfRemainValue + " %"
                        }
                        array.push(data)
                    }
                })
            }
        });

        const results = array.filter(element => {
            if (Object.keys(element).length !== 0) {
                return true;
            }
            return false;
        });

        let sortResult = _.sortBy(results, ['Item Name', 'Expire Date']);

        sortResult.push({ "Item Name": "", "Item Code": "", "Expire Date": "", "Quantity": "" });
        return sortResult;
    }

    render() {
        return (
            <div className="right_col" role="main">
                <div className="card">
                    <div className="card-header" style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'inline-flex', paddingRight: '1%' }}>
                            <div style={{ whiteSpace: 'nowrap' }}>
                                To Date :
                            </div>
                            <div>
                                <input type='date' className='form-control' defaultValue={this.state.expireDate} onChange={this.onChangeDateValue} />
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div style={{ whiteSpace: 'nowrap' }}>
                                Warehouse :
                            </div>
                            <div style={{ width: '50%' }}>
                                <select className='form-control' onChange={(e) => this.onSelect(e, "warehouse")}>
                                    <option value={''}>-All Delivery Warehouse-</option>
                                    {this.props.deliveryWarehouseData.map(ele => <option key={ele._id} value={ele._id}>{ele.name}</option>)}
                                </select>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div style={{ display: 'flex' }}>
                                <button className='btn btn-primary' onClick={() => this.props.getStockExpireItemData({ date: this.state.expireDate, warehouse: this.state.selectOption }, this.props)}>Search</button>
                            </div>

                        </div>
                        <div style={{ float: 'right' }}>
                            <button className="btn btn-success"
                                data-toggle="modal" data-target='#summaryModal'
                                onClick={this.exportData}
                            >
                                Export Data
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <FormProvider
                            form="inline"
                        >
                            {formProps => {
                                return (
                                    <Table className="-striped"
                                        columns={this.columns}
                                        data={this.state.Item}
                                        defaultPageSize={20}
                                        expanded={this.state.expanded}
                                        onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index)}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        showPageJump={false}
                                        SubComponent={row => {
                                            var subData = _.filter(this.state.warehouseData, function (o) {
                                                return o.item_id === row.original._id
                                            })

                                            return (
                                                <React.Fragment>
                                                    <ErrorBoundary>
                                                        <FormProvider
                                                            form="inline"
                                                        >
                                                            {formProps => {
                                                                return (
                                                                    <Table className="-striped"
                                                                        columns={this.warehouseColumns}
                                                                        data={subData}
                                                                        defaultPageSize={10}
                                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                                        showPageJump={false}
                                                                        SubComponent={row => {
                                                                            var subData = _.filter(this.state.stockExpItems, function (o) {
                                                                                return o.warehouse_id === row.original._id && o.Item_id === row.original.item_id
                                                                            })

                                                                            return (
                                                                                <React.Fragment>
                                                                                    <ErrorBoundary>
                                                                                        <FormProvider
                                                                                            form="inline"
                                                                                        >
                                                                                            {formProps => {
                                                                                                return (
                                                                                                    <Table className="-striped"
                                                                                                        columns={this.stockExpireItemColumns}
                                                                                                        data={subData}
                                                                                                        defaultPageSize={10}
                                                                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                                                                        showPageJump={false}
                                                                                                    />
                                                                                                )
                                                                                            }}

                                                                                        </FormProvider>
                                                                                    </ErrorBoundary>
                                                                                </React.Fragment>
                                                                            );
                                                                        }}
                                                                    />
                                                                )
                                                            }}

                                                        </FormProvider>
                                                    </ErrorBoundary>
                                                </React.Fragment>
                                            );
                                        }}
                                    />
                                );
                            }}
                        </FormProvider>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    token: state.rLogin.token,
    deliveryWarehouseData: state.rDeliveryWarehouse.deliveryWarehouses,
    stockExpireItems: state.rStockItem.stockExpireItems,
})

const mapDispatchToProps = dispatch => ({
    getDeliveryWarehouses: token => { dispatch(getDeliveryWarehouses(token)) },
    getStockExpireItemData: (data, props) => dispatch(getStockExpireItemData(data, props)),
})


export default connect(mapStateToProps, mapDispatchToProps)(StockExpireItem);
